import { css } from 'styled-components';

const gcodeStyles = (instanceId, styleProps) => css`
  .cm-s-canvas${instanceId} {
    border-radius: 0.2em;
    height: ${styleProps.height};
    min-height: ${styleProps.minHeight === undefined
      ? styleProps.height
      : styleProps.minHeight};
    line-height: 1.5;
    background: ${(props) =>
      props.theme.name === 'dark'
        ? props.theme.colors.elevatedBackgroundSecondary
        : props.theme.colors.elevatedBackgroundPrimary};

    .CodeMirror-cursor {
      border-color: ${(props) => props.theme.colors.textPrimary};
    }

    .CodeMirror-gutters {
      background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
      border-right-color: ${(props) => props.theme.colors.grey3};
    }

    .CodeMirror-lines {
      padding: 0.75rem 0;
    }

    .CodeMirror-line {
      padding: 0 0.75rem;
    }

    .CodeMirror-linenumber {
      padding: 0 0.75rem;
      width: 1.875rem;
    }

    .CodeMirror-scroll {
      min-height: ${styleProps.minHeight === undefined
        ? styleProps.height
        : styleProps.minHeight};
    }

    .cm-command {
      font-weight: ${(props) => props.theme.fonts.weightBold};
      color: ${(props) => props.theme.colors.greenDark};
    }

    .cm-param {
      color: ${(props) => props.theme.colors.purpleDark};
    }

    .cm-message {
      color: ${(props) => props.theme.colors.blueDark};
    }

    .cm-comment {
      font-style: italic;
      color: ${(props) => props.theme.colors.grey5};
    }

    .cm-variable {
      color: ${(props) => props.theme.colors.textPrimary};
      font-weight: ${(props) => props.theme.fonts.weightBold};
    }

    .cm-error {
      color: ${(props) => props.theme.colors.redDark};
      font-weight: ${(props) => props.theme.fonts.weightBold};
      text-decoration: underline;
    }
  }
`;

export default gcodeStyles;

import React, { Component } from 'react';

import { Container, UploadButton } from './uploadButtons.styles';
import { Icon } from '../../../shared';
import Icons from '../../../themes/icons';
import { Body1 } from '../../../shared/typography/typography';

class UploadButtons extends Component {
  constructor(props) {
    super(props);
    this.singleMaterialInputRef = React.createRef();
    this.multiMaterialInputRef = React.createRef();
  }

  handleUpload(e, single) {
    const fileUpload = { e, single };
    this.props.onHandleUpload(fileUpload);
    e.target.value = '';
  }

  render() {
    return (
      <Container>
        <UploadButton
          onClick={() => this.singleMaterialInputRef.current.click()}>
          <Icon src={Icons.project.addSingle} />
          <Body1 noSpacing>Drop single-material models here</Body1>
          <input
            type='file'
            multiple
            accept='.stl'
            title='Upload single-color models'
            ref={this.singleMaterialInputRef}
            onChange={(e) => this.handleUpload(e, true)}
          />
        </UploadButton>
        <UploadButton
          onClick={() => this.multiMaterialInputRef.current.click()}>
          <Icon src={Icons.project.addMultiple} />
          <Body1 noSpacing>Drop multi-material models here</Body1>
          <input
            ref={this.multiMaterialInputRef}
            type='file'
            multiple
            accept='.stl'
            title='Upload multi-material models'
            onChange={(e) => this.handleUpload(e, false)}
          />
        </UploadButton>
      </Container>
    );
  }
}

export default UploadButtons;

import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import { handleGenericError } from '../common';
import { makeRequest } from './horizon';
import { actions } from '../../reducers/iot/iot';
import { InterfaceUtils } from '../../utils';

export default function* connectPort(action) {
  const { device, connectTo, settingsData } = action.payload;

  try {
    const path = `${connectTo}/connect`;

    yield call(
      makeRequest,
      device.id,
      path,
      {
        method: 'job',
        query: settingsData,
      },
      'connectPort'
    );
    yield put(actions.connectPortSuccess());

    InterfaceUtils.emitToast('info', `Successfully connected to ${connectTo}!`);
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

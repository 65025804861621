import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NewProjectButton from './newProjectButton.jsx';

import slicerActions from '../../reducers/slicer/actions';

const mapStateToProps = (state) => ({
  newProjectOptions: state.auth.newProjectOptions,
  getPrintersPending: state.printers.status.getPrintersPending,
  getPrintersSuccess: state.printers.status.getPrintersSuccess,
  printers: state.printers.printers,
  getMaterialsPending: state.slicer.status.getMaterialsPending,
  getMaterialsSuccess: state.slicer.status.getMaterialsSuccess,
  materials: state.slicer.materials,
  getDevicesPending: state.iot.status.getDevicesPending,
  getDevicesSuccess: state.iot.status.getDevicesSuccess,
  devices: state.iot.devices,
  createProjectPending: state.slicer.status.createProjectPending,
  createProjectSuccess: state.slicer.status.createProjectSuccess,
  currentProject: state.slicer.currentProject,
});

const mapDispatchToProps = (dispatch) => ({
  createProject: (projectOptions) =>
    dispatch(slicerActions.createProjectRequest(projectOptions)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewProjectButton)
);

import { subscribe, unsubscribe } from './iotWorker';
import { getDeviceBroadcastTopicFilter } from './topics';
import logger from './logger';

export const subscribeToDevice = async (deviceId) => {
  logger.dev('subscribeToDevice');
  const topicFilter = getDeviceBroadcastTopicFilter(deviceId);
  return subscribe([topicFilter]);
};

export const unsubscribeFromDevice = async (deviceId) => {
  logger.dev('unsubscribeFromDevice');
  const topicFilter = getDeviceBroadcastTopicFilter(deviceId);
  return unsubscribe([topicFilter]);
};

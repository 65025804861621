import 'regenerator-runtime/runtime';
import { all, call, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import { handleGenericError, getIotState } from '../common';
import { actions } from '../../reducers/iot/iot';
import { waitForGetDevices } from '../slicer/project/loadProject';
import {
  CLIENT_INIT_FAILURE_MESSAGE,
  DEVICE_TIMEOUT_MESSAGE,
} from '../../utils/iot-worker/constants';

export default function* checkDeviceHeartbeat(action) {
  try {
    yield call(waitForGetDevices);
    const { devices } = yield select(getIotState);
    yield all(
      _.map(devices, (device) => put(actions.getDeviceStateRequest(device.id)))
    );
    yield put(actions.checkDeviceHeartbeatSuccess());
  } catch (err) {
    const ignoredErrors = [DEVICE_TIMEOUT_MESSAGE, CLIENT_INIT_FAILURE_MESSAGE];
    if (err && ignoredErrors.includes(err.message)) {
      // device is not online -- don't display an error toast
      yield put(actions.checkDeviceHeartbeatFailure(err));
    } else {
      yield call(handleGenericError, action, err);
    }
  }
}

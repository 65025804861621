import styled from 'styled-components';

export const H1 = styled.h1`
  font-family: Inter, sans-serif;
  font-size: 3.25rem;
  font-weight: 500;
  line-height: 4rem;
  margin: 0;

  color: ${(props) => {
    if (props.grey) return props.theme.colors.textSecondary;
    return props.theme.colors.textPrimary;
  }};
`;

export const H2 = styled.h2`
  font-family: Inter, sans-serif;
  font-size: 2.625rem;
  font-weight: 500;
  line-height: 3.25rem;
  margin: 0;
`;

export const H3 = styled.h3`
  font-family: Inter, sans-serif;
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 2.875rem;
  margin: 0;
`;

export const H4 = styled.h4`
  font-family: Inter, sans-serif;
  font-size: 1.875rem;
  font-weight: 500;
  line-height: 2.25rem;
  margin: 0;
`;

export const H5 = styled.h5`
  font-family: Inter, sans-serif;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.625rem;
  margin: 0;
  letter-spacing: 0.006875rem;

  color: ${(props) => {
    if (props.grey) return props.theme.colors.textSecondary;
    return props.theme.colors.textPrimary;
  }};
`;

export const H5OneLine = styled(H5)`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
`;

export const H6 = styled.h6`
  font-family: Inter, sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin: 0;
  color: ${(props) => {
    if (props.grey) return props.theme.colors.textSecondary;
    if (props.red) return props.theme.colors.redDefault;
    return props.theme.colors.textPrimary;
  }};
`;

export const H6OneLine = styled(H6)`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
  width: 100%;
`;

export const Subtitle1 = styled.h6`
  font-family: Inter, sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
  margin: 0;

  color: ${(props) => {
    if (props.grey) return props.theme.colors.textSecondary;
    if (props.red) return props.theme.colors.redDefault;
    return props.theme.colors.textPrimary;
  }};
`;

export const Subtitle1OneLine = styled(Subtitle1)`
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
`;

export const Subtitle2 = styled.h6`
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0.005rem;
  margin: 0;

  color: ${(props) => {
    if (props.grey) return props.theme.colors.textSecondary;
    if (props.red) return props.theme.colors.redDefault;
    if (props.white) return props.theme.colors.whiteDefault;
    if (props.green) return props.theme.colors.greenDefault;
    return props.theme.colors.textPrimary;
  }};
`;

export const Subtitle2OneLine = styled(Subtitle2)`
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
`;

export const Body1 = styled.p`
  font-family: Inter, sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.125rem;
  margin: 0;
  margin-bottom: ${(props) => (props.noSpacing ? '0' : '0.5em')};

  color: ${(props) => {
    if (props.disabled) return props.theme.colors.textTertiary;
    if (props.grey) return props.theme.colors.textSecondary;
    if (props.green) return props.theme.colors.greenDefault;
    if (props.red) return props.theme.colors.redDefault;
    if (props.black) return props.theme.colors.blackDefault;
    return props.theme.colors.textPrimary;
  }};
`;

export const Body1OneLine = styled(Body1)`
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
`;

export const Body2 = styled.p`
  font-family: Inter, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.004375rem;
  margin: 0;
  margin-bottom: ${(props) => (props.noSpacing ? '0' : '0.375em')};

  color: ${(props) => {
    if (props.disabled) return props.theme.colors.textTertiary;
    if (props.grey) return props.theme.colors.textSecondary;
    if (props.red) return props.theme.colors.redDefault;
    return props.theme.colors.textPrimary;
  }};
`;

// export const Body2OneLine = styled(Body2)`
//   display: inline-block;
//   width: 100%;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   overflow: hidden;
//   vertical-align: top;
// `;

export const Caption = styled.p`
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.0121875rem;
  margin: 0;
  margin-bottom: ${(props) => (props.noSpacing ? '0' : '0.625em')};

  color: ${(props) => {
    if (props.disabled) return props.theme.colors.textTertiary;
    if (props.grey) return props.theme.colors.textSecondary;
    if (props.red) return props.theme.colors.redDefault;
    if (props.green) return props.theme.colors.greenDefault;
    if (props.white) return props.theme.colors.whiteDefault;
    return props.theme.colors.textPrimary;
  }};
`;

export const CaptionOneLine = styled(Caption)`
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
`;

export const ButtonText = styled.p`
  font-family: Inter, sans-serif;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.125rem;
  margin: 0;
  color: ${(props) => props.theme.colors.textPrimary};
  overflow: hidden;
  vertical-align: top;
`;

// export const Code = styled.button`
//   /* font-family: Monaco */
//   font-size: 0.9375rem;
//   font-weight: 400;
//   line-height: 1.125rem;
//   margin: 0;
//   margin-bottom: ${props => (props.noSpacing ? '0' : '0.25em')};
// `;

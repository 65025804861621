import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import _ from 'lodash';

import {
  Content,
  ImportHeadingContainer,
  PrinterTitleWrapper,
  PrinterSelectionContainer,
  ImportSelectionItem,
  BoxSelectionWrapper,
  ImportSelectionLabelContainer,
  PrinterSelectionRenameContainer,
  StylesSelectionContainer,
  CheckboxList,
  CheckboxItem,
  PrinterImageContainer,
  ButtonContainer,
} from './importPrinter.styles';

import {
  Body1,
  BoxSelection,
  Button,
  Caption,
  Checkbox,
  Dropdown,
  Input,
  Image,
  LoadingOverlay,
  Page,
  Subtitle2,
  Subtitle1OneLine,
  Modal,
} from '../../../shared';

import { sharedPrinterNotfoundDescription } from './metadata';
import NotFound from '../../notFound/notFound.jsx';

import { SortUtils, FormatUtils } from '../../../utils';

import Routes from '../../../router/routes';
import Home from '../../home/home.container';

class ImportPrinter extends Component {
  constructor(props) {
    super(props);
    const { shareId } = props.match.params;
    this.state = {
      showLogin: false,
      shareId,
      importPrinter: false,
      printerId: '',
      importStyles: [],
      printerNotFound: false,
      printerDataLoaded: false,
      newPrinterName: '',
      printerNameError: false,
      createdPrinterId: '',
    };
  }

  componentDidMount() {
    const { shareId } = this.state;
    this.props.getSharedPrinter(shareId);
    if (!this.props.isAuthenticated) {
      this.props.updateNavStack([
        {
          text: 'Shared Files',
          route: Routes.toImportSharedPrinter(shareId),
          id: shareId,
        },
      ]);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      printers,
      history,
      createPrinterPending,
      createPrinterSuccess,
      getSharedPrinterPending,
      getSharedPrinterSuccess,
      importSharedPrinterPending,
      importSharedPrinterSuccess,
    } = this.props;

    const sharedPrinterLoadSucceeded =
      prevProps.getSharedPrinterPending &&
      !getSharedPrinterPending &&
      getSharedPrinterSuccess;
    const sharedPrinterLoadFailed =
      prevProps.getSharedPrinterPending &&
      !getSharedPrinterPending &&
      !getSharedPrinterSuccess;
    const sharedPrinterCreated =
      prevProps.createPrinterPending &&
      !createPrinterPending &&
      createPrinterSuccess;
    const sharedPrinterFullyImported =
      prevProps.importSharedPrinterPending &&
      !importSharedPrinterPending &&
      importSharedPrinterSuccess;

    if (sharedPrinterLoadFailed) {
      this.setState({ printerNotFound: true });
      if (this.props.isAuthenticated) this.props.setSharedPrinterNotFound();
    }

    if (sharedPrinterLoadSucceeded) {
      const { machineSettings, ownerUsername } =
        this.props.currentSharedPrinter;
      this.setState({
        newPrinterName: `${machineSettings.name} by ${ownerUsername}`,
      });
    }

    if (sharedPrinterCreated) {
      // printer creation is one of multiple steps when importing as a new printer,
      // so we need to keep track of the new printer ID now for redirecting once
      // all import steps are completed
      const createdPrinter = _.find(
        printers,
        (printer) => !prevProps.printers[printer.id]
      );
      if (createdPrinter) {
        this.setState({ createdPrinterId: createdPrinter.id });
      }
    }

    if (sharedPrinterFullyImported) {
      if (this.state.createdPrinterId) {
        // redirect to newly created printer
        history.push(Routes.toViewPrinter(this.state.createdPrinterId));
      } else if (this.state.printerId) {
        // redirect to printer onto which styles were imported
        history.push(Routes.toViewPrinter(this.state.printerId));
      } else {
        // else block should never be reached, but just in case it is,
        // simply close the modal
        this.props.toggleImportSharedPrinterModal();
      }
    }

    if (
      prevProps.loginPending &&
      !this.props.loginPending &&
      this.props.loginSuccess
    ) {
      this.setState({ showLogin: false });
    }
  }

  importPrinter() {
    const { importPrinter, importStyles, printerId, newPrinterName } =
      this.state;
    const validSelections =
      (importPrinter || printerId) && !_.isEmpty(importStyles);

    if (validSelections) {
      this.props.importSharedPrinter(
        importPrinter,
        importStyles,
        printerId,
        newPrinterName
      );
    }
  }

  handleLoginClick() {
    this.setState({ showLogin: true });
  }

  handleStyleSelect(style) {
    const { importStyles } = this.state;
    const styleAlreadySelected = _.includes(importStyles, style);
    const styleRemovedFromSelected = _.difference(importStyles, [style]);
    const styleAddedToSelected = [...importStyles, style];

    const updatedStyles = styleAlreadySelected
      ? styleRemovedFromSelected
      : styleAddedToSelected;
    this.setState({ importStyles: updatedStyles });
  }

  selectExistingPrinter(printerId) {
    this.setState({ printerId });
  }

  toggleImportPrinter(value) {
    if (!value && this.state.importPrinter) {
      this.setState({
        importPrinter: value,
        printerId: '',
      });
    } else {
      this.setState({ importPrinter: value });
    }
  }

  renderButtons() {
    const { importPrinter, importStyles, printerId } = this.state;
    const { toggleImportSharedPrinterModal, isAuthenticated } = this.props;
    const selectionsNotEmpty =
      (importPrinter || printerId) && !_.isEmpty(importStyles);

    return (
      <ButtonContainer>
        <Button
          primary={!isAuthenticated}
          onClick={() =>
            isAuthenticated
              ? toggleImportSharedPrinterModal()
              : this.handleLoginClick()
          }>
          {isAuthenticated ? 'Cancel' : 'Login'}
        </Button>
        <Button
          primary
          disabled={!isAuthenticated || !selectionsNotEmpty}
          onClick={() => this.importPrinter()}>
          Import
        </Button>
      </ButtonContainer>
    );
  }

  renderSingleStyleSelection(style, key) {
    const styleSelected = _.includes(this.state.importStyles, style);

    return (
      <CheckboxItem key={key}>
        <Checkbox
          label={style.name}
          value={styleSelected}
          disabled={!this.props.isAuthenticated}
          onChange={() => this.handleStyleSelect(style)}
        />
      </CheckboxItem>
    );
  }

  renderStylesSelection() {
    const styleOptions = SortUtils.sortByName(
      this.props.currentSharedPrinter.styles
    ).map((style, key) => this.renderSingleStyleSelection(style, key));

    return (
      <StylesSelectionContainer>
        <Subtitle2>Select styles to import</Subtitle2>
        <CheckboxList>{styleOptions}</CheckboxList>
      </StylesSelectionContainer>
    );
  }

  renderExistingPrinterSelection() {
    const printerOptions = SortUtils.sortByName(
      this.props.printers,
      false,
      'machineSettings.name'
    ).map((printer) => ({
      label: printer.machineSettings.name,
      value: printer.id,
    }));

    return (
      <PrinterSelectionRenameContainer>
        <Dropdown
          label='Select existing printer profile'
          allowFilter
          minHeight='0'
          listHeight='10rem'
          options={printerOptions}
          value={this.state.printerId}
          disabled={!this.props.isAuthenticated || _.isEmpty(printerOptions)}
          onChange={(value) => this.selectExistingPrinter(value)}
        />
      </PrinterSelectionRenameContainer>
    );
  }

  renderPrinterSelection() {
    const { currentSharedPrinter } = this.props;
    const { machineSettings } = currentSharedPrinter;
    const { importPrinter } = this.state;

    const boxOptions = [
      { label: 'Create new printer', value: true },
      { label: 'Do not import', value: false },
    ];

    return (
      <PrinterSelectionContainer>
        <ImportSelectionItem>
          <ImportSelectionLabelContainer>
            <Subtitle2>Printer profile</Subtitle2>
            <Body1 grey noSpacing>
              {`(${machineSettings.name})`}
            </Body1>
          </ImportSelectionLabelContainer>
          <BoxSelectionWrapper>
            <BoxSelection
              maxColumns={2}
              value={importPrinter}
              options={boxOptions}
              disabled={!this.props.isAuthenticated}
              onClick={(value) => this.toggleImportPrinter(value)}
            />
          </BoxSelectionWrapper>
          {!importPrinter
            ? this.renderExistingPrinterSelection()
            : this.renderNewPrinterNameInput()}
        </ImportSelectionItem>
      </PrinterSelectionContainer>
    );
  }

  renderPrinterImage() {
    const { icon } = this.props.currentSharedPrinter.machineSettings;

    return (
      <PrinterImageContainer>
        <Image
          alt='Printer icon'
          src={this.props.theme.images.printerIcons[icon]}
        />
      </PrinterImageContainer>
    );
  }

  renderHeading() {
    const { currentSharedPrinter } = this.props;
    const { machineSettings, ownerUsername, dateModified } =
      currentSharedPrinter;
    const date = FormatUtils.isoToDateString(dateModified);

    return (
      <ImportHeadingContainer>
        <PrinterTitleWrapper>
          <Subtitle1OneLine>{machineSettings.name}</Subtitle1OneLine>
        </PrinterTitleWrapper>
        <Body1>{`Shared by ${ownerUsername}`}</Body1>
        <Caption grey>{`Last updated ${date}`}</Caption>
      </ImportHeadingContainer>
    );
  }

  renderImportCard() {
    return (
      <Content>
        {this.renderHeading()}
        {this.renderPrinterImage()}
        {this.renderPrinterSelection()}
        {this.renderStylesSelection()}
        {this.renderButtons()}
      </Content>
    );
  }

  renderPageNotFound() {
    if (this.props.isAuthenticated) return null;
    return (
      <NotFound
        history={this.props.history}
        extraDescriptionText={sharedPrinterNotfoundDescription}
      />
    );
  }

  renderOverlaySpinner(dataIsReady) {
    const { importSharedPrinterPending } = this.props;
    const { importPrinter, importStyles } = this.state;
    const importOverlayText = importPrinter
      ? `Importing Shared Printer And ${FormatUtils.pluralize(
          'Style',
          importStyles.length
        )}`
      : `Importing ${importStyles.length} Shared ${FormatUtils.pluralize(
          'Style',
          importStyles.length
        )}`;

    if (importSharedPrinterPending)
      return <LoadingOverlay label={importOverlayText} />;
    if (!dataIsReady) return <LoadingOverlay label='Loading Shared Printer' />;
    return null;
  }

  renderMainPanelContent() {
    const { isAuthenticated, getSharedPrinterSuccess, getPrintersSuccess } =
      this.props;
    const dataIsReady = isAuthenticated
      ? getSharedPrinterSuccess && getPrintersSuccess
      : getSharedPrinterSuccess;
    return (
      <>
        {this.renderOverlaySpinner(dataIsReady)}
        {dataIsReady && <Modal width='40rem'>{this.renderImportCard()}</Modal>}
      </>
    );
  }

  handlePrinterNameChange(name, error = false) {
    this.setState({
      newPrinterName: name,
      printerNameError: error,
    });
  }

  renderNewPrinterNameInput() {
    const { newPrinterName, printerNameError, importPrinter } = this.state;
    return (
      <PrinterSelectionRenameContainer>
        <Input
          label='New printer name'
          disabled={!importPrinter}
          value={newPrinterName}
          isInvalid={printerNameError}
          onChangeSuccess={(value) =>
            this.handlePrinterNameChange(value, false)
          }
          onChangeFailure={(value) => this.handlePrinterNameChange(value, true)}
        />
      </PrinterSelectionRenameContainer>
    );
  }

  render() {
    if (this.state.showLogin) {
      const match = { pathname: this.props.match.url };
      return <Home match={match} />;
    }
    if (this.state.printerNotFound) {
      return this.renderPageNotFound();
    }
    if (!this.props.isAuthenticated) {
      return <Page>{this.renderMainPanelContent()}</Page>;
    }
    return this.renderMainPanelContent();
  }
}

export default withTheme(ImportPrinter);

import styled, { css } from 'styled-components';

import { Breakpoints } from '../../../../themes';

export const Container = styled.div`
  max-height: calc(100vh - 22.5em);
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr repeat(2, min-content);
  grid-template-rows: repeat(3, min-content);

  @media (max-height: ${Breakpoints.smallWidth}) {
    max-height: calc(100vh - 12.5em);
  }

  @media (max-height: ${Breakpoints.smallHeight}) {
    max-height: calc(100vh - 10em);
  }
`;

export const FieldsContainer = styled.div`
  margin-top: 0.5em;
  grid-column: 1 / -1;
  grid-row: 2 / -1;
`;

export const FieldRow = styled.div`
  display: flex;
  padding: 0.3em 0;
  position: relative;
`;

export const OffsetButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
  flex-direction: column;
  margin-left: 0.4em;
`;

export const OffsetButtonWrapper = styled.div`
  width: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  padding: 0;

  &:not(:last-child) {
    margin-bottom: 0.4em;
  }
`;

export const MirrorButtons = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, min-content);
  grid-gap: 0.5em;
`;

export const MirrorButtonWrapper = styled.div`
  grid-column: span 1;
  grid-row: 2 / 3;
`;

export const MirrorLabelWrapper = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / 2;
`;

export const ToolLabelWrapper = styled.div`
  grid-column: 1 / span 2;
  grid-row: 1 / 2;
  display: flex;
  align-items: center;
`;

export const ResetButtonWrapper = styled.div`
  grid-column: span 1 / -1;
  grid-row: 1 / 2;
  font-size: 0.8em;
  align-self: flex-start;
  font-weight: ${(props) => props.theme.fonts.weightRegular};
`;

export const ContextButtonWrapper = styled.div`
  grid-column: 1 / -1;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 0.8rem;
  &:first-child {
    margin-top: 0rem;
  }
  &:last-child {
    margin-bottom: 0rem;
  }
`;

export const NumberFieldWrapper = styled.div`
  flex: 1;
  width: 4em;
  display: grid;
`;

export const CheckboxWrapper = styled.div`
  grid-column: 1 / -1;
`;

const AxisStyles = css`
  position: absolute;
  width: 1.4em;
  height: 0.2em;
  top: 1.05em;
  left: 1.25em;
  opacity: 0.5;
  z-index: 30;
  border-bottom: solid 0.15em;
`;

export const XStyles = styled.span`
  ${AxisStyles};
  border-color: #ff0000;
`;

export const YStyles = styled.span`
  ${AxisStyles};
  border-color: #2ecc71;
`;

export const ZStyles = styled.span`
  ${AxisStyles};
  border-color: #0000ff;
`;

import React from 'react';

const perimeterCount = (
  <>
    Specify the number of perimeter loops around the outside of your model.
    Increasing this value will increase strength and improve surface finish but
    will increase print times.
  </>
);

const perimeterOrder = (
  <>
    Choose the order in which perimeters are printed. For example, inside-out
    will print the innermost perimeter first and the outermost perimeter last.
    Inside-out provides better surface finish, while outside-in results in
    better dimensional accuracy.
  </>
);

const perimeterSpeed = (
  <>
    Specify the print speed used during perimeters. Printing perimeters slower
    will provide a more uniform and higher quality surface around the outside of
    your part, however print times will also increase.
  </>
);

const externalPerimeterSpeed = (
  <>
    <>
      Specify the print speed used during outermost perimeters. Printing
      perimeters slower will provide a more uniform and higher quality surface
      around the outside of your part, however print times will also increase.
    </>
    <>Only available when using Slic3r.</>
  </>
);

const perimeterExtrusionWidth = (
  <>
    <>Specify the extrusion width used for perimeters.</>
    <>Only available when using Slic3r.</>
  </>
);

const externalPerimeterExtrusionWidth = (
  <>
    <>Specify the extrusion width used for outermost perimeters.</>
    <>Only available when using Slic3r.</>
  </>
);

const coastDistance = (
  <>
    <>
      Coasting allows the printer to slow down and stop extruding as it nears
      the end of an extrusion line. This helps to reduce built-up pressure in
      the extruder and help to control unwanted stringing or blobs of plastic in
      your print. However, increasing this setting too much may lead to gaps or
      poor extrusion at the end of an extrusion line.
    </>
    <>Only available when using KISSlicer.</>
  </>
);

const seamOnCorners = (
  <>
    Seams are created where the start of a perimeter meets with its end. Turning
    on this setting will attempt to place the seam on a corner of your part
    where possible. Placing seams on the corners of your part improves the
    visual appearance of perimeters.
  </>
);

const seamAngle = (
  <>
    Seams are created where the start of a perimeter meets with its end. This
    setting specifies the location where these seams are placed. The angle is
    defined from the x-axis of your printer and increases counter-clockwise from
    0° to 360°. For most printers, this means that an angle of 45° will place
    the seam at the back-right of your part.
  </>
);

const seamJitter = (
  <>
    <>
      This setting allows randomness to be introduced in the placement of seams.
      By increasing this value, some seams will be placed further from the
      specified seam angle. A value of 0° will align all seams along the same
      angle whereas a value of 90° will put half of the seams randomly between 0
      and 45° counter-clockwise from the seam angle, and the other half 0 and
      45° clockwise.
    </>
    <>Only available when using KISSlicer.</>
  </>
);

export default {
  perimeterCount,
  perimeterOrder,
  perimeterSpeed,
  externalPerimeterSpeed,
  perimeterExtrusionWidth,
  externalPerimeterExtrusionWidth,
  coastDistance,
  seamOnCorners,
  seamAngle,
  seamJitter,
};

import chroma from 'chroma-js';
import _ from 'lodash';

import SortUtils, {
  MaterialSortMode,
  MaterialSortDirection,
  MaterialSortDefault,
} from '../sort/sort';
import { paletteConstants } from '../../constants';

const Colors = [
  { name: 'Light Red', hex: '#fbcccc' },
  { name: 'Light Red', hex: '#f89999' },
  { name: 'Light Red', hex: '#FF6666' },
  { name: 'Red', hex: '#f13232' },
  { name: 'Red', hex: '#ef1919' },
  { name: 'Red', hex: '#EE0000' },
  { name: 'Dark Red', hex: '#d60000' },
  { name: 'Dark Red', hex: '#a60000' },
  { name: 'Dark Red', hex: '#8e0000' },
  { name: 'Dark Red', hex: '#5f0000' },
  { name: 'Light Orange', hex: '#ffd8b2' },
  { name: 'Light Orange', hex: '#ffbf7f' },
  { name: 'Light Orange', hex: '#ffb266' },
  { name: 'Light Orange', hex: '#ffab91' },
  { name: 'Light Orange', hex: '#ff8a65' },
  { name: 'Orange', hex: '#ff9932' },
  { name: 'Orange', hex: '#ff8c19' },
  { name: 'Orange', hex: '#ff8000' },
  { name: 'Orange', hex: '#ff7043' },
  { name: 'Orange', hex: '#ff6e40' },
  { name: 'Orange', hex: '#ff5722' },
  { name: 'Dark Orange', hex: '#eb7332' },
  { name: 'Dark Orange', hex: '#e86219' },
  { name: 'Dark Orange', hex: '#E65100' },
  { name: 'Dark Orange', hex: '#c44800' },
  { name: 'Dark Orange', hex: '#bf360c' },
  { name: 'Dark Orange', hex: '#f4511e' },
  { name: 'Dark Orange', hex: '#e64a19' },
  { name: 'Dark Orange', hex: '#d84315' },
  { name: 'Dark Orange', hex: '#ff3d00' },
  { name: 'Dark Orange', hex: '#dd2c00' },
  { name: 'Light Yellow', hex: '#fff8e1' },
  { name: 'Light Yellow', hex: '#fff9c4' },
  { name: 'Light Yellow', hex: '#ffecb3' },
  { name: 'Light Yellow', hex: '#fde5ab' },
  { name: 'Light Yellow', hex: '#fddf96' },
  { name: 'Light Yellow', hex: '#ffe082' },
  { name: 'Light Yellow', hex: '#fcd77a' },
  { name: 'Light Yellow', hex: '#fff59d' },
  { name: 'Light Yellow', hex: '#fff176' },
  { name: 'Yellow', hex: '#fbcb54' },
  { name: 'Yellow', hex: '#fbc642' },
  { name: 'Yellow', hex: '#FBC02D' },
  { name: 'Yellow', hex: '#ffee58' },
  { name: 'Yellow', hex: '#ffeb3b' },
  { name: 'Yellow', hex: '#fdd835' },
  { name: 'Yellow', hex: '#ffff00' },
  { name: 'Yellow', hex: '#ffea00' },
  { name: 'Yellow', hex: '#ffd600' },
  { name: 'Dark Yellow', hex: '#e1ac28' },
  { name: 'Dark Yellow', hex: '#c89924' },
  { name: 'Dark Yellow', hex: '#af861f' },
  { name: 'Dark Yellow', hex: '#fbc02d' },
  { name: 'Dark Yellow', hex: '#f8a825' },
  { name: 'Dark Yellow', hex: '#c17900' },
  { name: 'Light Green', hex: '#e8f5e9' },
  { name: 'Light Green', hex: '#c8e6c9' },
  { name: 'Light Green', hex: '#a5d6a7' },
  { name: 'Green', hex: '#4caf50' },
  { name: 'Green', hex: '#43a047' },
  { name: 'Green', hex: '#388e3c' },
  { name: 'Dark Green', hex: '#007700' },
  { name: 'Dark Green', hex: '#005500' },
  { name: 'Dark Green', hex: '#003300' },
  { name: 'Dark Green', hex: '#001100' },
  { name: 'Light Blue', hex: '#f3f9fe' },
  { name: 'Light Blue', hex: '#bbdefb' },
  { name: 'Light Blue', hex: '#90caf9' },
  { name: 'Blue', hex: '#42a5f5' },
  { name: 'Blue', hex: '#2196f3' },
  { name: 'Blue', hex: '#1976d2' },
  { name: 'Blue', hex: '#2962ff' },
  { name: 'Blue', hex: '#536dfe' },
  { name: 'Blue', hex: '#3d5afe' },
  { name: 'Blue', hex: '#304ffe' },
  { name: 'Blue', hex: '#0000ff' },
  { name: 'Dark Blue', hex: '#1565c0' },
  { name: 'Dark Blue', hex: '#0d47a1' },
  { name: 'Dark Blue', hex: '#0d27a1' },
  { name: 'Dark Blue', hex: '#0026ca' },
  { name: 'Dark Blue', hex: '#0043ca' },
  { name: 'Dark Blue', hex: '#072760' },
  { name: 'Light Indigo', hex: '#d8dcf0' },
  { name: 'Light Indigo', hex: '#bbc2e5' },
  { name: 'Light Indigo', hex: '#9fa8da' },
  { name: 'Indigo', hex: '#7986cb' },
  { name: 'Indigo', hex: '#5c6bc0' },
  { name: 'Indigo', hex: '#3f51b5' },
  { name: 'Dark Indigo', hex: '#303f9f' },
  { name: 'Dark Indigo', hex: '#283593' },
  { name: 'Dark Indigo', hex: '#1A237E' },
  { name: 'Dark Indigo', hex: '#0f154b' },
  { name: 'Light Purple', hex: '#e1bee7' },
  { name: 'Light Purple', hex: '#ce93d8' },
  { name: 'Light Purple', hex: '#ba68c8' },
  { name: 'Purple', hex: '#ab47bc' },
  { name: 'Purple', hex: '#9c27b0' },
  { name: 'Purple', hex: '#8e24aa' },
  { name: 'Purple', hex: '#aa00ff' },
  { name: 'Dark Purple', hex: '#7b1fa2' },
  { name: 'Dark Purple', hex: '#6a1b9a' },
  { name: 'Dark Purple', hex: '#4a148c' },
  { name: 'Dark Purple', hex: '#330e62' },
  { name: 'Light Cyan', hex: '#f2fbfc' },
  { name: 'Light Cyan', hex: '#ccf1f6' },
  { name: 'Light Cyan', hex: '#b2ebf2' },
  { name: 'Cyan', hex: '#80deea' },
  { name: 'Cyan', hex: '#4dd0e1' },
  { name: 'Cyan', hex: '#00bcd4' },
  { name: 'Dark Cyan', hex: '#00acc1' },
  { name: 'Dark Cyan', hex: '#0097a7' },
  { name: 'Dark Cyan', hex: '#00838f' },
  { name: 'Dark Cyan', hex: '#006064' },
  { name: 'Light Pink', hex: '#facfde' },
  { name: 'Light Pink', hex: '#f9c0d3' },
  { name: 'Light Pink', hex: '#f6a0bd' },
  { name: 'Pink', hex: '#e7769f' },
  { name: 'Pink', hex: '#f1719c' },
  { name: 'Pink', hex: '#F06292' },
  { name: 'Dark Pink', hex: '#df487f' },
  { name: 'Dark Pink', hex: '#D81B60' },
  { name: 'Dark Pink', hex: '#ac154c' },
  { name: 'Dark Pink', hex: '#811039' },
  { name: 'Light Magenta', hex: '#ffb2ff' },
  { name: 'Light Magenta', hex: '#ff99ff' },
  { name: 'Light Magenta', hex: '#ff66ff' },
  { name: 'Magenta', hex: '#ff4cff' },
  { name: 'Magenta', hex: '#ff00ff' },
  { name: 'Magenta', hex: '#e500e5' },
  { name: 'Dark Magenta', hex: '#cc00cc' },
  { name: 'Dark Magenta', hex: '#b200b2' },
  { name: 'Dark Magenta', hex: '#990099' },
  { name: 'Dark Magenta', hex: '#7f007f' },
  { name: 'Light Lime', hex: '#f7f9e1' },
  { name: 'Light Lime', hex: '#f0f4c3' },
  { name: 'Light Lime', hex: '#E6EE9C' },
  { name: 'Lime', hex: '#dce775' },
  { name: 'Lime', hex: '#CDDC39' },
  { name: 'Lime', hex: '#c0ca33' },
  { name: 'Dark Lime', hex: '#9e9d24' },
  { name: 'Dark Lime', hex: '#8e8d20' },
  { name: 'Dark Lime', hex: '#6e6d19' },
  { name: 'Dark Lime', hex: '#4f4e12' },
  { name: 'Light Brown', hex: '#c5a289' },
  { name: 'Light Brown', hex: '#ad7c59' },
  { name: 'Light Brown', hex: '#a26a42' },
  { name: 'Brown', hex: '#96572a' },
  { name: 'Brown', hex: '#8b4513' },
  { name: 'Brown', hex: '#7d3e11' },
  { name: 'Dark Brown', hex: '#6f370f' },
  { name: 'Dark Brown', hex: '#61300d' },
  { name: 'Dark Brown', hex: '#53290b' },
  { name: 'Dark Brown', hex: '#371b07' },
  { name: 'White', hex: '#FFFFFF' },
  { name: 'Light Grey', hex: '#EEEEEE' },
  { name: 'Light Grey', hex: '#DDDDDD' },
  { name: 'Light Grey', hex: '#CCCCCC' },
  { name: 'Light Grey', hex: '#BBBBBB' },
  { name: 'Grey', hex: '#AAAAAA' },
  { name: 'Grey', hex: '#999999' },
  { name: 'Grey', hex: '#888888' },
  { name: 'Grey', hex: '#777777' },
  { name: 'Dark Grey', hex: '#666666' },
  { name: 'Dark Grey', hex: '#555555' },
  { name: 'Dark Grey', hex: '#444444' },
  { name: 'Dark Grey', hex: '#333333' },
  { name: 'Dark Grey', hex: '#222222' },
  { name: 'Black', hex: '#111111' },
  { name: 'Black', hex: '#000000' },
];

const Utils = {
  getClosestColor: (myColor) => {
    const color = chroma(myColor);
    const alpha = color.alpha();
    const colors = Colors.map((item) => {
      const closestColor = item;
      closestColor.distance = chroma.distance(color, chroma(item.hex));
      return closestColor;
    }).sort((a, b) => a.distance - b.distance);
    if (alpha < 1) {
      return `Translucent ${colors[0].name}`;
    }
    return colors[0].name;
  },
  isColorBright: (color) => {
    const rgba = chroma(color).rgba();
    if ((rgba[0] * 299 + rgba[1] * 587 + rgba[2] * 114) / 1000 > 220) {
      return true;
    }
    return false;
  },
  getListLabel: (material) => {
    if (material.id === '0') {
      return material.name;
    }
    return `${material.type} | ${material.name}`;
  },
  getDefaultSpliceSettings: (spliceCore) => {
    switch (spliceCore) {
      case 'SC':
      case 'SCP':
      case 'SCS':
      case 'SCSP':
      case 'P3-SC':
      case 'P3-SCP':
        return {
          heatFactor: 0,
          compressionFactor: 0,
          coolingFactor: 0,
        };
      case 'P':
        return {
          heatFactor: 0,
          compressionFactor: 0,
          reverse: false,
        };
      default:
        throw new Error(`Unknown splice core '${spliceCore}'`);
    }
  },
  mapSpliceSettings: (array) =>
    _.reduce(
      array,
      (map, item) => {
        const { ingoingId, outgoingId, spliceSettings } = item;
        const key = `${ingoingId}-${outgoingId}`;
        return {
          ...map,
          [key]: spliceSettings,
        };
      },
      {}
    ),
  populateMissingSpliceSettings: (sparseMap, materialIds) => {
    const allSpliceSettings = {};
    materialIds.forEach((ingoingId) => {
      materialIds.forEach((outgoingId) => {
        const key = `${ingoingId}-${outgoingId}`;
        if (sparseMap[key]) {
          allSpliceSettings[key] = sparseMap[key];
        } else {
          allSpliceSettings[key] = paletteConstants.SPLICE_CORES.reduce(
            (acc, spliceCore) => {
              acc[spliceCore] = Utils.getDefaultSpliceSettings(spliceCore);
              return acc;
            },
            {}
          );
        }
      });
    });
    return allSpliceSettings;
  },
  /**
   * sort an array of materials in the order specified by the sortBy values
   * @param materialsList array of materials
   * @param sortBy object containing a sorting mode and direction, both integers
   * @returns array of sorted materials
   */
  sortMaterials: (materialsList, sortBy = MaterialSortDefault) => {
    if (_.isEmpty(materialsList)) return [];

    const { mode, direction } = sortBy;
    const descending = direction === MaterialSortDirection.DESCENDING;

    switch (mode) {
      case MaterialSortMode.NAME: {
        return SortUtils.sortByName(materialsList, descending);
      }
      case MaterialSortMode.TYPE: {
        return SortUtils.sortMaterialProfilesByType(materialsList, descending);
      }
      case MaterialSortMode.DATE_MODIFIED: {
        return SortUtils.sortByModified(materialsList, descending, 'timestamp');
      }
      case MaterialSortMode.DATE_CREATED: {
        return SortUtils.sortByCreated(materialsList, descending);
      }
      default: {
        return SortUtils.sortByName(materialsList, descending);
      }
    }
  },
};

export default Utils;

import { delay } from 'redux-saga';
import { call } from 'redux-saga/effects';

import API from '../canvas-api';
import logger from './logger';

export function* getCognitoCredentials(userId) {
  // get a Cognito token via our API
  const response = yield call(API, {
    path: `users/${userId}/iot/credentials`,
  });
  if (response === null) return null;
  const { endpoint, clientId, region, identityId, token } = response;
  return {
    endpoint,
    clientId,
    region,
    identityId,
    token,
  };
}

const MAX_RETRY_DELAY = 30 * 1000; // 30 seconds

export function* updateCognitoCredentials(userId, clientId) {
  // get a Cognito token via our API
  let attempt = 0;
  let response;
  while (true) {
    try {
      logger.info('requesting IoT credential refresh');
      response = yield call(API, {
        path: `users/${userId}/iot/credentials?clientId=${clientId}`,
      });
      if (response === null) return null;
      logger.info('success response!');
      break;
    } catch (err) {
      logger.error('error response :(');
      // retry the API request, with a slight backoff each time
      attempt++;
      const retryDelay = Math.min(MAX_RETRY_DELAY, 2000 * attempt * attempt);
      yield delay(retryDelay);
    }
  }
  const { region, identityId, token } = response;
  return {
    region,
    identityId,
    token,
  };
}

import styled from 'styled-components';

export const HeaderStylesWrapper = styled.div`
  grid-column: span 2;
  grid-row: 1 / 2;
  max-width: 70%;
  overflow: hidden;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const SideBarContentWrapper = styled.div`
  ${(props) => !props.show && ' display: none;'}
`;

import { connect } from 'react-redux';

import { actions } from '../../../../reducers/notifications/notifications';
import NotificationList from './notificationList.jsx';

const mapStateToProps = (state) => ({
  notifications: state.notifications.notifications,
});

const mapDispatchToProps = (dispatch) => ({
  updateNotification: (notificationId, read) =>
    dispatch(actions.updateNotificationRequest(notificationId, read)),
  deleteNotification: (notificationId) =>
    dispatch(actions.deleteNotificationRequest(notificationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);

import _ from 'lodash';
import slicerTypes from './types';
import slicerActions from './actions';

import slicerInitialState, { defaultToolpath } from './initialState';
import Helpers from './helpers';

import { SlicerUtils, TreeUtils } from '../../utils';

export const types = slicerTypes;
export const actions = slicerActions;
export const initialState = slicerInitialState;

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.RESET_SLICER_STORE: {
      return initialState;
    }
    case types.LOAD_PROJECTS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          loadProjectsPending: true,
          loadProjectsSuccess: false,
        },
      };
    }
    case types.LOAD_PROJECTS_SUCCESS: {
      const { projects } = payload;
      // avoid overwriting the current project, if it's already loaded
      let loadedProjects = projects;
      if (state.currentProject) {
        loadedProjects = _.omit(projects, state.currentProject);
      }
      return {
        ...state,
        projects: {
          ...state.projects,
          ...loadedProjects,
        },
        status: {
          ...state.status,
          loadProjectsPending: false,
          loadProjectsSuccess: true,
        },
      };
    }
    case types.LOAD_PROJECTS_FAILURE: {
      return {
        ...state,
        projects: {},
        status: {
          ...state.status,
          loadProjectsPending: false,
          loadProjectsSuccess: false,
        },
      };
    }
    case types.LOAD_PROJECT_REQUEST: {
      return {
        ...state,
        currentProject: payload.projectId,
        status: {
          ...state.status,
          loadProjectPending: true,
          loadProjectSuccess: false,
          updateProjectSettingsSuccess: false,
          createSpliceSettingsSuccess: false,
        },
      };
    }
    case types.LOAD_PROJECT_SUCCESS: {
      const { project, models } = payload;
      const { projects } = state;
      return {
        ...state,
        models,
        projects: Helpers.updateProjectInMap(projects, project),
        toolpath: defaultToolpath,
        status: {
          ...state.status,
          loadProjectPending: false,
          loadProjectSuccess: true,
        },
      };
    }
    case types.LOAD_PROJECT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          loadProjectPending: false,
          loadProjectSuccess: false,
        },
      };
    }
    case types.UNLOAD_CURRENT_PROJECT: {
      Helpers.disposeTower(state.transitionTower);
      Helpers.disposeModels(state.models);
      return {
        ...state,
        models: initialState.models,
        transitionTower: initialState.transitionTower,
        currentProject: initialState.currentProject,
        remapperContext: initialState.remapperContext,
      };
    }
    case types.SET_INPUT_REMAPPER_CONTEXT: {
      return {
        ...state,
        remapperContext: payload.remapperContext,
      };
    }
    case types.CLEAR_INPUT_REMAPPER_CONTEXT: {
      return {
        ...state,
        remapperContext: null,
      };
    }
    case types.REMAP_PROJECT_INPUTS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          remapProjectInputsPending: true,
          remapProjectInputsSuccess: false,
        },
      };
    }
    case types.REMAP_PROJECT_INPUTS_SUCCESS: {
      const { project, models } = payload;
      const { projects } = state;
      return {
        ...state,
        models,
        projects: Helpers.invalidateAndUpdateProjectInMap(projects, project),
        remapperContext: null,
        status: {
          ...state.status,
          remapProjectInputsPending: false,
          remapProjectInputsSuccess: true,
        },
        currentProject: project.id,
      };
    }
    case types.REMAP_PROJECT_INPUTS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          remapProjectInputsPending: false,
          remapProjectInputsSuccess: false,
        },
      };
    }
    case types.CREATE_PROJECT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          createProjectPending: true,
          createProjectSuccess: false,
        },
      };
    }
    case types.CREATE_PROJECT_SUCCESS: {
      const { project } = payload;
      return {
        ...state,
        projects: {
          ...state.projects,
          [project.id]: project,
        },
        status: {
          ...state.status,
          createProjectPending: false,
          createProjectSuccess: true,
        },
        currentProject: project.id,
      };
    }
    case types.CREATE_PROJECT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          createProjectPending: false,
          createProjectSuccess: false,
        },
      };
    }
    case types.CREATE_CALIBRATION_PROJECT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          createCalibrationProjectPending: true,
          createCalibrationProjectSuccess: false,
        },
      };
    }
    case types.CREATE_CALIBRATION_PROJECT_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          createCalibrationProjectPending: false,
          createCalibrationProjectSuccess: true,
        },
      };
    }
    case types.CREATE_CALIBRATION_PROJECT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          createCalibrationProjectPending: false,
          createCalibrationProjectSuccess: false,
        },
      };
    }
    case types.DELETE_PROJECTS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteProjectsPending: true,
          deleteProjectsSuccess: false,
        },
      };
    }
    case types.DELETE_PROJECTS_SUCCESS: {
      const projects = Helpers.deleteKeyFromMap(
        state.projects,
        payload.projectIds
      );
      return {
        ...state,
        projects,
        currentProject: initialState.currentProject,
        status: {
          ...state.status,
          deleteProjectsPending: false,
          deleteProjectsSuccess: true,
        },
      };
    }
    case types.DELETE_PROJECTS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteProjectsPending: false,
          deleteProjectsSuccess: false,
        },
      };
    }
    case types.RESET_NEW_PROJECT_FLAGS: {
      return {
        ...state,
        status: {
          ...state.status,
          createProjectSuccess: false,
        },
      };
    }
    case types.SET_INTERSECT_MODE: {
      return {
        ...state,
        intersectMode: payload.mode,
      };
    }
    case types.UPLOAD_STL_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          uploadStlPending: true,
          uploadStlSuccess: false,
        },
      };
    }
    case types.UPLOAD_STL_SUCCESS: {
      const { models } = payload;
      const { models: existingModels } = state;
      const newModelTree = TreeUtils.sort([...existingModels, ...models]);
      return {
        ...state,
        models: newModelTree,
        status: {
          ...state.status,
          uploadStlPending: false,
          uploadStlSuccess: true,
          uploadProgress: 0,
        },
      };
    }
    case types.UPLOAD_STL_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          uploadStlPending: false,
          uploadStlSuccess: false,
        },
      };
    }
    case types.UPDATE_UPLOAD_PROGRESS: {
      return {
        ...state,
        status: {
          ...state.status,
          uploadProgress: payload.progress,
        },
      };
    }
    case types.CENTER_PROJECT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          centerProjectPending: true,
          centerProjectSuccess: false,
        },
      };
    }
    case types.CENTER_PROJECT_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          centerProjectPending: false,
          centerProjectSuccess: true,
        },
      };
    }
    case types.CENTER_PROJECT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          centerProjectPending: false,
          centerProjectSuccess: false,
        },
      };
    }
    case types.REPACK_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          repackPending: true,
          repackSuccess: false,
        },
      };
    }
    case types.REPACK_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          repackPending: false,
          repackSuccess: true,
        },
      };
    }
    case types.REPACK_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          repackPending: false,
          repackSuccess: false,
        },
      };
    }
    case types.DUPLICATE_MODEL_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          duplicateModelPending: true,
          duplicateModelSuccess: false,
        },
      };
    }
    case types.DUPLICATE_MODEL_SUCCESS: {
      const { models } = payload;
      return {
        ...state,
        models,
        status: {
          ...state.status,
          duplicateModelPending: false,
          duplicateModelSuccess: true,
        },
      };
    }
    case types.DUPLICATE_MODEL_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          duplicateModelPending: false,
          duplicateModelSuccess: false,
        },
      };
    }
    case types.DELETE_MODELS_REQUEST: {
      const { modelIds } = payload;
      const newModelTree = Helpers.deleteModels(state.models, modelIds);
      return {
        ...state,
        models: newModelTree,
        status: {
          ...state.status,
          deleteModelsPending: true,
          deleteModelsSuccess: false,
        },
      };
    }
    case types.DELETE_MODELS_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteModelsPending: false,
          deleteModelsSuccess: true,
        },
      };
    }
    case types.DELETE_MODELS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteModelsPending: false,
          deleteModelsSuccess: false,
        },
      };
    }
    case types.UPDATE_MODEL_TRANSFORMS_REQUEST: {
      const { models } = state;
      const { transformData } = payload;
      const updatedModels = Helpers.updateModelTransforms(
        models,
        transformData,
        true
      );
      return {
        ...state,
        models: updatedModels,
        status: {
          ...state.status,
          updateModelTransformsPending: true,
          updateModelTransformsSuccess: false,
        },
      };
    }
    case types.UPDATE_MODEL_TRANSFORMS_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          updateModelTransformsPending: false,
          updateModelTransformsSuccess: true,
        },
      };
    }
    case types.UPDATE_MODEL_TRANSFORMS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updateModelTransformsPending: false,
          updateModelTransformsSuccess: false,
        },
      };
    }
    case types.UPDATE_MODEL_NAME_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          updateModelNamePending: true,
          updateModelNameSuccess: false,
        },
      };
    }
    case types.UPDATE_MODEL_NAME_SUCCESS: {
      const { modelId, newModelName } = payload;
      const newModelTree = Helpers.updateModelName(
        state.models,
        modelId,
        newModelName
      );
      return {
        ...state,
        models: newModelTree,
        status: {
          ...state.status,
          updateModelNamePending: false,
          updateModelNameSuccess: true,
        },
      };
    }
    case types.UPDATE_MODEL_NAME_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updateModelNamePending: false,
          updateModelNameSuccess: false,
        },
      };
    }
    case types.UPDATE_MODEL_GROUP_NAME_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          updateGroupNamePending: true,
          updateGroupNameSuccess: false,
        },
      };
    }
    case types.UPDATE_MODEL_GROUP_NAME_SUCCESS: {
      const { oldGroupName, newGroupName } = payload;
      const newModelTree = Helpers.updateModelGroupName(
        state.models,
        oldGroupName,
        newGroupName
      );
      return {
        ...state,
        models: newModelTree,
        status: {
          ...state.status,
          updateGroupNamePending: false,
          updateGroupNameSuccess: true,
        },
      };
    }
    case types.UPDATE_MODEL_GROUP_NAME_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updateGroupNamePending: false,
          updateGroupNameSuccess: false,
        },
      };
    }
    case types.GROUP_MODELS_REQUEST: {
      const { models: existingModels } = state;
      const { models: groupedModels, groupName } = payload;
      const newModelTree = Helpers.groupModels(
        existingModels,
        groupedModels,
        groupName
      );
      return {
        ...state,
        models: newModelTree,
        status: {
          ...state.status,
          groupModelsPending: true,
          groupModelsSuccess: false,
        },
      };
    }
    case types.GROUP_MODELS_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          groupModelsPending: false,
          groupModelsSuccess: true,
        },
      };
    }
    case types.GROUP_MODELS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          groupModelsPending: false,
          groupModelsSuccess: false,
        },
      };
    }
    case types.UNGROUP_MODELS_REQUEST: {
      const { models: existingModels } = state;
      const { models: ungroupedModels, groupNames } = payload;
      const newModelTree = Helpers.ungroupModels(
        existingModels,
        ungroupedModels,
        groupNames
      );
      return {
        ...state,
        models: newModelTree,
        status: {
          ...state.status,
          ungroupModelsPending: true,
          ungroupModelsSuccess: false,
        },
      };
    }
    case types.UNGROUP_MODELS_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          ungroupModelsPending: false,
          ungroupModelsSuccess: true,
        },
      };
    }
    case types.UNGROUP_MODELS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          ungroupModelsPending: false,
          ungroupModelsSuccess: false,
        },
      };
    }
    case types.ALIGN_AND_GROUP_MODELS_REQUEST: {
      const { models: existingModels } = state;
      const { models: groupedModels, newCenter, groupName } = payload;
      const newModelTree = Helpers.alignAndGroupModels(
        existingModels,
        groupedModels,
        newCenter,
        groupName
      );
      return {
        ...state,
        models: newModelTree,
        status: {
          ...state.status,
          alignAndGroupModelsPending: true,
          alignAndGroupModelsSuccess: false,
        },
      };
    }
    case types.ALIGN_AND_GROUP_MODELS_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          alignAndGroupModelsPending: false,
          alignAndGroupModelsSuccess: true,
        },
      };
    }
    case types.ALIGN_AND_GROUP_MODELS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          alignAndGroupModelsPending: false,
          alignAndGroupModelsSuccess: false,
        },
      };
    }
    case types.UPDATE_TOWER: {
      const transitionTower = Helpers.updateTower(state, payload.tower);
      return {
        ...state,
        transitionTower,
      };
    }
    case types.TOGGLE_TOWER_VISIBILITY: {
      const transitionTower = Helpers.toggleTowerVisibility(
        state.transitionTower
      );
      return {
        ...state,
        transitionTower,
      };
    }
    case types.TOGGLE_MODEL_ACTIVE_STATE: {
      const { modelId } = payload;
      const models = Helpers.toggleModelActiveState(state.models, modelId);
      return {
        ...state,
        models,
      };
    }
    case types.SET_MODEL_VISIBILITY_REQUEST: {
      const { modelId, visible } = payload;
      const models = Helpers.setModelVisibility(state.models, modelId, visible);
      return {
        ...state,
        models,
      };
    }
    case types.SET_MODEL_VISIBILITY_SUCCESS: {
      return state;
    }
    case types.SET_MODEL_VISIBILITY_FAILURE: {
      return state;
    }
    case types.ACTIVATE_MODELS: {
      const { modelIds } = payload;
      const models = Helpers.activateModels(state.models, modelIds);
      return {
        ...state,
        models,
      };
    }
    case types.DEACTIVATE_ALL_MODELS: {
      const models = Helpers.deactivateAllModels(state.models);
      const transitionTower = Helpers.deactivateTower(state.transitionTower);
      return {
        ...state,
        models,
        transitionTower,
      };
    }
    case types.SHOW_ALL_MODELS: {
      TreeUtils.map(state.models, (model) => {
        // eslint-disable-next-line no-param-reassign
        model.mesh.visible = true;
      });
      if (state.transitionTower && state.transitionTower.visible) {
        // eslint-disable-next-line no-param-reassign
        state.transitionTower.mesh.visible = true;
      }
      return {
        ...state,
        allModelsHidden: false,
      };
    }
    case types.HIDE_ALL_MODELS: {
      Helpers.hideModelsFromScene(state.models);
      Helpers.hideTowerFromScene(state.transitionTower);
      return {
        ...state,
        allModelsHidden: true,
      };
    }
    case types.TOGGLE_TOWER_ACTIVE_STATE: {
      const transitionTower = Helpers.toggleTowerActiveState(
        state.transitionTower
      );
      return {
        ...state,
        transitionTower,
      };
    }
    case types.ACTIVATE_TOWER: {
      const transitionTower = Helpers.activateTower(state.transitionTower);
      return {
        ...state,
        transitionTower,
      };
    }
    case types.DEACTIVATE_TOWER: {
      const transitionTower = Helpers.deactivateTower(state.transitionTower);
      return {
        ...state,
        transitionTower,
      };
    }
    case types.UPDATE_TOWER_POSITION_OR_SIZE_REQUEST: {
      return state;
    }
    case types.UPDATE_TOWER_POSITION_OR_SIZE_SUCCESS: {
      return state;
    }
    case types.UPDATE_TOWER_POSITION_OR_SIZE_FAILURE: {
      return state;
    }
    case types.GET_MATERIALS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getMaterialsPending: true,
          getMaterialsSuccess: false,
        },
      };
    }
    case types.GET_MATERIALS_SUCCESS: {
      const materials = Helpers.composeMaterialsMap(
        state.materials,
        payload.materials
      );
      return {
        ...state,
        materials,
        status: {
          ...state.status,
          getMaterialsPending: false,
          getMaterialsSuccess: true,
        },
      };
    }
    case types.GET_MATERIALS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getMaterialsPending: false,
          getMaterialsSuccess: false,
        },
      };
    }
    case types.SELECT_MATERIAL: {
      const materials = Helpers.selectMaterial(
        state.materials,
        payload.material.id
      );
      return {
        ...state,
        materials,
      };
    }
    case types.CREATE_MATERIAL_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          createMaterialPending: true,
          createMaterialSuccess: false,
        },
      };
    }
    case types.CREATE_MATERIAL_SUCCESS: {
      const { materials } = payload;
      return {
        ...state,
        materials,
        status: {
          ...state.status,
          createMaterialPending: false,
          createMaterialSuccess: true,
        },
      };
    }
    case types.CREATE_MATERIAL_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          createMaterialPending: false,
          createMaterialSuccess: false,
        },
      };
    }
    case types.EDIT_MATERIAL_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          editMaterialPending: true,
          editMaterialSuccess: false,
        },
      };
    }
    case types.EDIT_MATERIAL_SUCCESS: {
      const { materials } = payload;
      return {
        ...state,
        materials,
        status: {
          ...state.status,
          editMaterialPending: false,
          editMaterialSuccess: true,
        },
      };
    }
    case types.EDIT_MATERIAL_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          editMaterialPending: false,
          editMaterialSuccess: false,
        },
      };
    }
    case types.DELETE_MATERIALS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteMaterialsPending: true,
          deleteMaterialsSuccess: false,
        },
      };
    }
    case types.DELETE_MATERIALS_SUCCESS: {
      const { materials } = payload;
      return {
        ...state,
        materials,
        status: {
          ...state.status,
          deleteMaterialsPending: false,
          deleteMaterialsSuccess: true,
        },
      };
    }
    case types.DELETE_MATERIALS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteMaterialsPending: false,
          deleteMaterialsSuccess: false,
        },
      };
    }
    case types.UPDATE_PROJECT_COLORS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          updateProjectColorsPending: true,
          updateProjectColorsSuccess: false,
        },
      };
    }
    case types.UPDATE_PROJECT_COLORS_SUCCESS: {
      const { project, models } = payload;
      return {
        ...state,
        models,
        projects: Helpers.invalidateAndUpdateProjectInMap(
          state.projects,
          project
        ),
        status: {
          ...state.status,
          updateProjectColorsPending: false,
          updateProjectColorsSuccess: true,
        },
      };
    }
    case types.UPDATE_PROJECT_COLORS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updateProjectColorsPending: false,
          updateProjectColorsSuccess: false,
        },
      };
    }
    case types.UPDATE_VARIABLE_TRANSITION_LENGTHS_REQUEST: {
      const { projectId, values } = payload;
      const project = state.projects[projectId];
      return {
        ...state,
        projects: {
          ...state.projects,
          [projectId]: {
            ...project,
            variableTransitionLengths: values,
          },
        },
        status: {
          ...state.status,
          updateVariableTransitionLengthsPending: true,
          updateVariableTransitionLengthsSuccess: false,
        },
      };
    }
    case types.UPDATE_VARIABLE_TRANSITION_LENGTHS_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          updateVariableTransitionLengthsPending: false,
          updateVariableTransitionLengthsSuccess: true,
        },
      };
    }
    case types.UPDATE_VARIABLE_TRANSITION_LENGTHS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updateVariableTransitionLengthsPending: false,
          updateVariableTransitionLengthsSuccess: false,
        },
      };
    }
    case types.APPLY_MATERIAL_REQUEST: {
      const { models } = state;
      const { materialIndex, modelsPaintData } = payload;
      const modelIds = _.map(modelsPaintData, (model) => model.id);
      const modelsToUpdate = _.reduce(
        modelIds,
        (acc, modelId) => {
          const model = TreeUtils.searchById(modelId, models);
          return [...acc, model];
        },
        []
      );
      const updatedModels = Helpers.applyColorToModels(
        modelsToUpdate,
        materialIndex
      );
      const newModelTree = TreeUtils.map(models, (node) => {
        if (_.includes(modelIds, node.id)) {
          const currentModel = _.find(
            updatedModels,
            (updatedModel) => updatedModel.id === node.id
          );
          return currentModel;
        }
        return node;
      });
      return {
        ...state,
        models: newModelTree,
        status: {
          ...state.status,
          applyMaterialPending: true,
          applyMaterialSuccess: false,
        },
      };
    }
    case types.APPLY_MATERIAL_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          applyMaterialPending: false,
          applyMaterialSuccess: true,
        },
      };
    }
    case types.APPLY_MATERIAL_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          applyMaterialPending: false,
          applyMaterialSuccess: false,
        },
      };
    }
    case types.CHANGE_MATERIAL_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          changeMaterialPending: true,
          changeMaterialSuccess: false,
        },
      };
    }
    case types.CHANGE_MATERIAL_SUCCESS: {
      const { projects, currentProject } = state;
      const project = projects[currentProject];
      const { materialIds } = payload.project;
      const updatedProject = Helpers.updateProjectMaterials(
        project,
        materialIds
      );
      return {
        ...state,
        projects: Helpers.invalidateAndUpdateProjectInMap(
          projects,
          updatedProject
        ),
        status: {
          ...state.status,
          changeMaterialPending: false,
          changeMaterialSuccess: true,
        },
      };
    }
    case types.CHANGE_MATERIAL_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          changeMaterialPending: false,
          changeMaterialSuccess: false,
        },
      };
    }
    case types.GET_SPLICE_SETTINGS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getSpliceSettingsPending: true,
          getSpliceSettingsSuccess: false,
        },
      };
    }
    case types.GET_SPLICE_SETTINGS_SUCCESS: {
      const { spliceSettings } = payload;
      return {
        ...state,
        spliceSettings,
        status: {
          ...state.status,
          getSpliceSettingsPending: false,
          getSpliceSettingsSuccess: true,
        },
      };
    }
    case types.GET_SPLICE_SETTINGS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getSpliceSettingsPending: false,
          getSpliceSettingsSuccess: false,
        },
      };
    }
    case types.CREATE_SPLICE_SETTINGS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          createSpliceSettingsPending: true,
          createSpliceSettingsSuccess: false,
        },
      };
    }
    case types.CREATE_SPLICE_SETTINGS_SUCCESS: {
      const { spliceSettings } = payload;
      return {
        ...state,
        spliceSettings,
        status: {
          ...state.status,
          createSpliceSettingsPending: false,
          createSpliceSettingsSuccess: true,
        },
      };
    }
    case types.CREATE_SPLICE_SETTINGS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          createSpliceSettingsPending: false,
          createSpliceSettingsSuccess: false,
        },
      };
    }
    case types.INVALIDATE_SLICE: {
      const project = state.projects[payload.projectId];
      return {
        ...state,
        projects: Helpers.invalidateAndUpdateProjectInMap(
          state.projects,
          project
        ),
      };
    }
    case types.GET_SLICE_JOBS_REQUEST: {
      return {
        ...state,
        sliceJobs: initialState.sliceJobs,
        status: {
          ...state.status,
          getSliceJobsPending: true,
          getSliceJobsSuccess: false,
        },
      };
    }
    case types.GET_SLICE_JOBS_SUCCESS: {
      return {
        ...state,
        sliceJobs: payload.sliceJobs,
        status: {
          ...state.status,
          getSliceJobsPending: false,
          getSliceJobsSuccess: true,
        },
      };
    }
    case types.GET_SLICE_JOBS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getSliceJobsPending: false,
          getSliceJobsSuccess: false,
        },
      };
    }
    case types.DISPATCH_SLICE_JOB_REQUEST: {
      const { projectId } = payload;
      const project = state.projects[projectId];

      return {
        ...state,
        sliceJobs: {
          ...state.sliceJobs,
          [project.id]: {
            name: project.name,
            progress: 0,
            status: 'Preparing to slice',
          },
        },
        status: {
          ...state.status,
          dispatchSliceJobPending: true,
          dispatchSliceJobSuccess: false,
        },
      };
    }
    case types.DISPATCH_SLICE_JOB_SUCCESS: {
      const { project, jobInfo } = payload;
      return {
        ...state,
        projects: Helpers.updateProjectInMap(state.projects, project),
        sliceJobs: {
          ...state.sliceJobs,
          [project.id]: jobInfo,
        },
        status: {
          ...state.status,
          dispatchSliceJobPending: false,
          dispatchSliceJobSuccess: true,
        },
      };
    }
    case types.DISPATCH_SLICE_JOB_FAILURE: {
      const { projectId } = payload;

      return {
        ...state,
        sliceJobs: Helpers.deleteKeyFromMap(state.sliceJobs, projectId),
        status: {
          ...state.status,
          dispatchSliceJobPending: false,
          dispatchSliceJobSuccess: false,
        },
      };
    }
    case types.UPDATE_SLICE_JOB: {
      const { projectId, jobInfo } = payload;
      return {
        ...state,
        sliceJobs: {
          ...state.sliceJobs,
          [projectId]: {
            ...state.sliceJobs[projectId],
            ...jobInfo,
          },
        },
      };
    }
    case types.COMPLETE_SLICE_JOB: {
      const { projectId, slicedProject } = payload;

      let existingProjects = state.projects;
      if (slicedProject) {
        existingProjects = Helpers.updateProjectInMap(
          state.projects,
          slicedProject
        );
      }

      return {
        ...state,
        sliceJobs: Helpers.deleteKeyFromMap(state.sliceJobs, projectId),
        projects: existingProjects,
        status: {
          ...state.status,
        },
      };
    }
    case types.CANCEL_SLICE_JOB_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          cancelSliceJobPending: true,
          cancelSliceJobSuccess: false,
        },
      };
    }
    case types.CANCEL_SLICE_JOB_SUCCESS: {
      const { projectId } = payload;

      return {
        ...state,
        sliceJobs: Helpers.deleteKeyFromMap(state.sliceJobs, projectId),
        status: {
          ...state.status,
          cancelSliceJobPending: false,
          cancelSliceJobSuccess: true,
        },
      };
    }
    case types.CANCEL_SLICE_JOB_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          cancelSliceJobPending: false,
          cancelSliceJobSuccess: false,
        },
      };
    }
    case types.LOAD_PROJECT_STATS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          loadProjectStatsPending: true,
          loadProjectStatsSuccess: false,
        },
      };
    }
    case types.LOAD_PROJECT_STATS_SUCCESS: {
      return {
        ...state,
        projects: Helpers.updateProjectInMap(state.projects, payload.project),
        status: {
          ...state.status,
          loadProjectStatsPending: false,
          loadProjectStatsSuccess: true,
        },
      };
    }
    case types.LOAD_PROJECT_STATS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          loadProjectStatsPending: false,
          loadProjectStatsSuccess: false,
        },
      };
    }
    case types.LOAD_TOOLPATH_PTP_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          loadToolpathPtpPending: true,
          loadToolpathPtpSuccess: false,
        },
      };
    }
    case types.LOAD_TOOLPATH_PTP_PROGRESS: {
      const { percent } = payload;
      return {
        ...state,
        status: {
          ...state.status,
          loadToolpathPtpProgress: percent,
        },
      };
    }
    case types.LOAD_TOOLPATH_PTP_SUCCESS: {
      const { version, ptp } = payload;
      const existingPtp = state.toolpath.ptp || {
        legend: {},
        buffers: {},
      };
      return {
        ...state,
        toolpath: {
          ...state.toolpath,
          version,
          ptp: {
            legend: {
              ...existingPtp.legend,
              ...ptp.legend,
            },
            buffers: {
              ...existingPtp.buffers,
              ...ptp.buffers,
            },
          },
        },
        status: {
          ...state.status,
          loadToolpathPtpPending: false,
          loadToolpathPtpSuccess: true,
        },
      };
    }
    case types.LOAD_TOOLPATH_PTP_FAILURE: {
      return {
        ...state,
        toolpath: defaultToolpath,
        status: {
          ...state.status,
          loadToolpathPtpPending: false,
          loadToolpathPtpSuccess: false,
        },
      };
    }
    case types.LOAD_TOOLPATH_PTP_CANCEL: {
      return {
        ...state,
        status: {
          ...state.status,
          loadToolpathPtpPending: false,
          loadToolpathPtpSuccess: false,
          loadToolpathPtpProgress: 0,
        },
      };
    }
    case types.UNLOAD_TOOLPATH_PTP: {
      return {
        ...state,
        toolpath: defaultToolpath,
        status: {
          ...state.status,
          loadToolpathPtpPending: false,
          loadToolpathPtpSuccess: false,
          loadToolpathPtpProgress: 0,
        },
      };
    }
    case types.CREATE_TOOLPATH_MESHES_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          createToolpathMeshesPending: true,
          createToolpathMeshesSuccess: false,
        },
      };
    }
    case types.CREATE_TOOLPATH_MESHES_PROGRESS: {
      const { percent } = payload;
      return {
        ...state,
        status: {
          ...state.status,
          createToolpathMeshesProgress: percent,
        },
      };
    }
    case types.CREATE_TOOLPATH_MESHES_SUCCESS: {
      const { toolpath } = payload;
      return {
        ...state,
        toolpath: {
          ...state.toolpath,
          ...toolpath,
        },
        status: {
          ...state.status,
          createToolpathMeshesPending: false,
          createToolpathMeshesSuccess: true,
        },
      };
    }
    case types.CREATE_TOOLPATH_MESHES_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          createToolpathMeshesPending: false,
          createToolpathMeshesSuccess: false,
        },
      };
    }
    case types.CREATE_TOOLPATH_MESHES_CANCEL: {
      return {
        ...state,
        status: {
          ...state.status,
          createToolpathMeshesPending: false,
          createToolpathMeshesSuccess: false,
          createToolpathMeshesProgress: 0,
        },
      };
    }
    case types.SET_TOOLPATH_VIEW_TYPE:
    case types.SET_TOOLPATH_SHOW_TRAVEL:
    case types.SET_TOOLPATH_SHOW_RETRACTS:
    case types.SET_TOOLPATH_SHOW_RESTARTS:
    case types.SET_TOOLPATH_LAYER_RANGE: {
      return {
        ...state,
        toolpath: {
          ...state.toolpath,
          ...payload,
        },
      };
    }
    case types.DOWNLOAD_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          downloadPending: true,
          downloadSuccess: false,
        },
      };
    }
    case types.DOWNLOAD_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          downloadPending: false,
          downloadSuccess: true,
        },
      };
    }
    case types.DOWNLOAD_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          downloadPending: false,
          downloadSuccess: false,
          message: 'Failed to retrieve download url',
        },
      };
    }
    case types.UPDATE_PROJECT_STYLE_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          updateProjectStylePending: true,
          updateProjectStyleSuccess: false,
        },
      };
    }
    case types.UPDATE_PROJECT_STYLE_SUCCESS: {
      const currentProject = state.projects[state.currentProject];
      const updatedProject = {
        ...currentProject,
        style: {
          ...currentProject.style,
          ...payload.updatedSettings,
        },
      };
      return {
        ...state,
        projects: Helpers.invalidateAndUpdateProjectInMap(
          state.projects,
          updatedProject
        ),
        status: {
          ...state.status,
          updateProjectStylePending: false,
          updateProjectStyleSuccess: true,
        },
      };
    }
    case types.UPDATE_PROJECT_STYLE_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updateProjectStylePending: false,
          updateProjectStyleSuccess: false,
        },
      };
    }
    case types.UPDATE_PROJECT_NAME_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          updateProjectNamePending: true,
          updateProjectNameSuccess: false,
        },
      };
    }
    case types.UPDATE_PROJECT_NAME_SUCCESS: {
      return {
        ...state,
        projects: Helpers.updateProjectInMap(state.projects, payload.project),
        status: {
          ...state.status,
          updateProjectNamePending: false,
          updateProjectNameSuccess: true,
        },
      };
    }
    case types.UPDATE_PROJECT_NAME_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updateProjectNamePending: false,
          updateProjectNameSuccess: false,
        },
      };
    }
    case types.UPDATE_PROJECT_SETTINGS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          updateProjectSettingsPending: true,
          updateProjectSettingsSuccess: false,
        },
      };
    }
    case types.UPDATE_PROJECT_SETTINGS_SUCCESS: {
      const { project } = payload;

      const mergedProject = {
        ...state.projects[state.currentProject],
        ...project,
      };
      return {
        ...state,
        projects: Helpers.invalidateAndUpdateProjectInMap(
          state.projects,
          mergedProject
        ),
        remapperContext: null,
        status: {
          ...state.status,
          updateProjectSettingsPending: false,
          updateProjectSettingsSuccess: true,
        },
      };
    }
    case types.UPDATE_PROJECT_SETTINGS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updateProjectSettingsPending: false,
          updateProjectSettingsSuccess: false,
        },
      };
    }
    case types.UPDATE_PROJECT_THUMBNAIL_REQUEST: {
      const { projectId } = payload;
      // temporary until timestamp from API is returned
      const timestamp = new Date().toISOString();
      return {
        ...state,
        projects: {
          ...state.projects,
          [projectId]: {
            ...state.projects[projectId],
            modified: timestamp,
          },
        },
        status: {
          ...state.status,
          updateProjectThumbnailPending: true,
          updateProjectThubmnailSuccess: false,
        },
      };
    }
    case types.UPDATE_PROJECT_THUMBNAIL_SUCCESS: {
      const { projectId, thumbnailDataURL, timestamp } = payload;
      return {
        ...state,
        projects: {
          ...state.projects,
          [projectId]: {
            ...state.projects[projectId],
            modified: timestamp,
          },
        },
        thumbnails: {
          ...state.thumbnails,
          [projectId]: thumbnailDataURL,
        },
        status: {
          ...state.status,
          updateProjectThumbnailPending: false,
          updateProjectThumbnailSuccess: true,
        },
      };
    }
    case types.UPDATE_PROJECT_THUMBNAIL_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updateProjectThumbnailPending: false,
          updateProjectThumbnailSuccess: false,
        },
      };
    }
    case types.GET_PROJECT_THUMBNAIL_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getProjectThumbnailPending: true,
          getProjectThubmnailSuccess: false,
        },
      };
    }
    case types.GET_PROJECT_THUMBNAIL_SUCCESS: {
      const { projectId, thumbnailDataURL } = payload;
      return {
        ...state,
        thumbnails: {
          ...state.thumbnails,
          [projectId]: thumbnailDataURL,
        },
        status: {
          ...state.status,
          getProjectThumbnailPending: false,
          getProjectThumbnailSuccess: true,
        },
      };
    }
    case types.GET_PROJECT_THUMBNAIL_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getProjectThumbnailPending: false,
          getProjectThumbnailSuccess: false,
        },
      };
    }
    case types.DELETE_PROJECT_THUMBNAIL_REQUEST: {
      const { projectId } = payload;
      // temporary until timestamp from API is returned
      const timestamp = new Date().toISOString();
      return {
        ...state,
        projects: {
          ...state.projects,
          [projectId]: {
            ...state.projects[projectId],
            modified: timestamp,
          },
        },
        status: {
          ...state.status,
          deleteProjectThumbnailPending: true,
          deleteProjectThubmnailSuccess: false,
        },
      };
    }
    case types.DELETE_PROJECT_THUMBNAIL_SUCCESS: {
      const { projectId, timestamp } = payload;
      const updatedThumbnails = Helpers.deleteKeyFromMap(
        state.thumbnails,
        projectId
      );
      return {
        ...state,
        projects: {
          ...state.projects,
          [projectId]: {
            ...state.projects[projectId],
            modified: timestamp,
          },
        },
        thumbnails: updatedThumbnails,
        status: {
          ...state.status,
          deleteProjectThumbnailPending: false,
          deleteProjectThumbnailSuccess: true,
        },
      };
    }
    case types.DELETE_PROJECT_THUMBNAIL_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteProjectThumbnailPending: false,
          deleteProjectThumbnailSuccess: false,
        },
      };
    }
    case types.DUPLICATE_PROJECT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          duplicateProjectPending: true,
          duplicateProjectSuccess: false,
        },
      };
    }
    case types.DUPLICATE_PROJECT_SUCCESS: {
      const { project } = payload;
      return {
        ...state,
        projects: {
          ...state.projects,
          [project.id]: project,
        },
        status: {
          ...state.status,
          duplicateProjectPending: false,
          duplicateProjectSuccess: true,
        },
      };
    }
    case types.DUPLICATE_PROJECT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          duplicateProjectPending: false,
          duplicateProjectSuccess: false,
        },
      };
    }
    case types.CREATE_FOLDER_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          createFolderPending: true,
          createFolderSuccess: false,
        },
      };
    }
    case types.CREATE_FOLDER_SUCCESS: {
      const updatedProjects = Helpers.updateProjectInMap(
        state.projects,
        payload.project
      );
      return {
        ...state,
        projects: updatedProjects,
        status: {
          ...state.status,
          createFolderPending: false,
          createFolderSuccess: true,
        },
      };
    }
    case types.CREATE_FOLDER_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          createFolderPending: false,
          createFolderSuccess: false,
        },
      };
    }
    case types.UPDATE_PROJECTS_PARENT_REQUEST: {
      const { projectIds, parentId } = payload;
      const { projects } = state;

      const updatedProjects = _.mapValues(projects, (project) => {
        if (_.includes(projectIds, project.id)) {
          return { ...project, parentId };
        }
        return project;
      });

      return {
        ...state,
        projects: updatedProjects,
        status: {
          ...state.status,
          updateProjectsParentPending: true,
          updateProjectsParentSuccess: false,
        },
      };
    }
    case types.UPDATE_PROJECTS_PARENT_SUCCESS: {
      const { projects } = state;
      const updatedProjects = { ...projects, ...payload.projects };
      return {
        ...state,
        projects: updatedProjects,
        status: {
          ...state.status,
          updateProjectsParentPending: false,
          updateProjectsParentSuccess: true,
        },
      };
    }
    case types.UPDATE_PROJECTS_PARENT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updateProjectsParentPending: false,
          updateProjectsParentSuccess: false,
        },
      };
    }
    // facepainter
    case types.REGISTER_BRUSH: {
      const rgba = state.projects[state.currentProject].colors[0];
      const hexString = SlicerUtils.rgbaArrayToHexString(rgba);
      return {
        ...state,
        brush: {
          ...state.brush,
          type: 'facet',
          color: hexString,
          mesh: payload.brush,
        },
      };
    }
    case types.MARK_MODEL_TO_COLOR: {
      return {
        ...state,
        status: {
          ...state.status,
          modelToColorId: payload.modelId,
        },
      };
    }
    case types.SELECT_BRUSH_COLOR: {
      state.brush.mesh.material.color.set(payload.color);
      return {
        ...state,
        brush: {
          ...state.brush,
          type: state.brush.type ? state.brush.type : 'facet',
          color: payload.color,
        },
      };
    }
    case types.SELECT_BRUSH_TYPE: {
      const rgba = state.projects[state.currentProject].colors[0];
      const hexString = SlicerUtils.rgbaArrayToHexString(rgba);
      if (!state.brush.color) {
        state.brush.mesh.material.color.set(hexString);
      }
      return {
        ...state,
        brush: {
          ...state.brush,
          type: payload.type,
          color: state.brush.color ? state.brush.color : hexString,
        },
      };
    }
    case types.SELECT_BRUSH_SIZE: {
      const { size } = payload;
      state.brush.mesh.scale.set(size, size, size);
      return {
        ...state,
        brush: {
          ...state.brush,
          size,
        },
      };
    }
    case types.TOGGLE_PAINTING: {
      // eslint-disable-next-line no-param-reassign
      return {
        ...state,
        status: {
          ...state.status,
          isPainting: payload.onOrOff,
        },
      };
    }
    case types.AUTO_SEGMENT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          autoSegmentPending: true,
        },
      };
    }
    case types.AUTO_SEGMENT_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          autoSegmentPending: false,
        },
      };
    }
    case types.BUILD_FACES_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          buildFacesPending: true,
        },
      };
    }
    case types.BUILD_FACES_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          buildFacesPending: false,
        },
      };
    }
    case types.GENERATE_ATLAS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          generateAtlasPending: true,
        },
      };
    }
    case types.GENERATE_ATLAS_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          generateAtlasPending: false,
        },
      };
    }
    case types.RESET_REGIONS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          resetRegionsPending: true,
        },
      };
    }
    case types.RESET_REGIONS_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          resetRegionsPending: false,
        },
      };
    }
    case types.TOGGLE_SEGMENT_BOUNDARIES: {
      return {
        ...state,
        status: {
          ...state.status,
          showSegmentBoundaries: payload.onOrOff,
        },
      };
    }
    case types.RESTORE_COLORS_WITH_RLE: {
      const { modelId, originalRle } = payload;
      const newModelTree = Helpers.restoreColorsWithRLE(
        state.models,
        modelId,
        originalRle
      );
      return {
        ...state,
        models: newModelTree,
        status: {
          ...state.status,
          isPainting: initialState.status.isPainting,
          modelToColorId: initialState.status.modelToColorId,
          showSegmentBoundaries: initialState.status.showSegmentBoundaries,
        },
      };
    }
    case types.COMPOSE_COLORS_RLE_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          composeColorsRLEPending: true,
        },
      };
    }
    case types.COMPOSE_COLORS_RLE_SUCCESS: {
      const { modelId, rle, inPlace } = payload;
      const newModelTree = inPlace
        ? Helpers.updateModelRLE(state.models, modelId, rle)
        : state.models;
      return {
        ...state,
        models: newModelTree,
        status: {
          ...state.status,
          composeColorsRLEPending: false,
        },
      };
    }
    case types.SAVE_MODEL_PAINT_DATA_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          saveModelPaintDataPending: true,
          saveModelPaintDataSuccess: false,
        },
      };
    }
    case types.SAVE_MODEL_PAINT_DATA_SUCCESS: {
      const { modelId, stampMap, rle } = payload;
      const newModelTree = Helpers.updateModelPaintData(
        state.models,
        modelId,
        stampMap,
        rle
      );
      return {
        ...state,
        models: newModelTree,
        status: {
          ...state.status,
          saveModelPaintDataPending: false,
          saveModelPaintDataSuccess: true,
        },
      };
    }
    case types.SAVE_MODEL_PAINT_DATA_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          saveModelPaintDataPending: false,
          saveModelPaintDataSuccess: false,
        },
      };
    }
    case types.DELETE_MODEL_PAINT_DATA_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteModelPaintDataPending: true,
          deleteModelPaintDataSuccess: false,
        },
      };
    }
    case types.DELETE_MODEL_PAINT_DATA_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteModelPaintDataPending: false,
          deleteModelPaintDataSuccess: true,
        },
      };
    }
    case types.DELETE_MODEL_PAINT_DATA_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteModelPaintDataPending: false,
          deleteModelPaintDataSuccess: false,
        },
      };
    }
    case types.UPDATE_STAMP_TRANSFORMS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          updateStampTransformsPending: true,
          updateStampTransformsSuccess: false,
        },
      };
    }
    case types.UPDATE_STAMP_TRANSFORMS_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          updateStampTransformsPending: false,
          updateStampTransformsSuccess: true,
        },
      };
    }
    case types.UPDATE_STAMP_TRANSFORMS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updateStampTransformsPending: false,
          updateStampTransformsSuccess: false,
        },
      };
    }

    // Sharing
    case types.CREATE_SHARED_PROJECT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          createSharedProjectPending: true,
          createSharedProjectSuccess: false,
        },
      };
    }
    case types.CREATE_SHARED_PROJECT_SUCCESS: {
      const { projectId, shareData } = payload;
      return {
        ...state,
        projects: {
          ...state.projects,
          [projectId]: {
            ...state.projects[projectId],
            modified: shareData.dateShared,
            shareData,
          },
        },
        status: {
          ...state.status,
          createSharedProjectPending: false,
          createSharedProjectSuccess: true,
        },
      };
    }
    case types.CREATE_SHARED_PROJECT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          createSharedProjectPending: false,
          createSharedProjectSuccess: false,
        },
      };
    }
    case types.DELETE_SHARED_PROJECT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteSharedProjectPending: true,
          deleteSharedProjectSuccess: false,
        },
      };
    }
    case types.DELETE_SHARED_PROJECT_SUCCESS: {
      const { projectId, timestamp } = payload;
      return {
        ...state,
        projects: {
          ...state.projects,
          [projectId]: {
            ...state.projects[projectId],
            modified: timestamp,
            shareData: {
              dateShared: timestamp,
            },
          },
        },
        status: {
          ...state.status,
          deleteSharedProjectPending: false,
          deleteSharedProjectSuccess: true,
        },
      };
    }
    case types.DELETE_SHARED_PROJECT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteSharedProjectPending: false,
          deleteSharedProjectSuccess: false,
        },
      };
    }
    case types.UPDATE_SHARED_PROJECT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          updateSharedProjectPending: true,
          updateSharedProjectSuccess: false,
        },
      };
    }
    case types.UPDATE_SHARED_PROJECT_SUCCESS: {
      const { projectId, shareData } = payload;
      return {
        ...state,
        projects: {
          ...state.projects,
          [projectId]: {
            ...state.projects[projectId],
            modified: shareData.dateShared,
            shareData,
          },
        },
        status: {
          ...state.status,
          updateSharedProjectPending: false,
          updateSharedProjectSuccess: true,
        },
      };
    }
    case types.UPDATE_SHARED_PROJECT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updateSharedProjectPending: false,
          updateSharedProjectSuccess: false,
        },
      };
    }
    case types.GET_SHARED_PROJECT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getSharedProjectPending: true,
          getSharedProjectSuccess: false,
        },
      };
    }
    case types.GET_SHARED_PROJECT_SUCCESS: {
      return {
        ...state,
        currentSharedProject: payload.sharedProject,
        status: {
          ...state.status,
          getSharedProjectPending: false,
          getSharedProjectSuccess: true,
        },
      };
    }
    case types.GET_SHARED_PROJECT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getSharedProjectPending: false,
          getSharedProjectSuccess: false,
        },
      };
    }
    case types.IMPORT_SHARED_PROJECT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          importSharedProjectPending: true,
          importSharedProjectSuccess: false,
        },
      };
    }
    case types.IMPORT_SHARED_PROJECT_SUCCESS: {
      const { project } = payload;
      return {
        ...state,
        currentProject: project.id,
        projects: {
          ...state.projects,
          [project.id]: project,
        },
        status: {
          ...state.status,
          importSharedProjectPending: false,
          importSharedProjectSuccess: true,
        },
      };
    }
    case types.IMPORT_SHARED_PROJECT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          importSharedProjectPending: false,
          importSharedProjectSuccess: false,
        },
      };
    }
    case types.GET_SHARED_PROJECT_THUMBNAIL_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getSharedProjectThumbnailPending: true,
          getSharedProjectThumbnailSuccess: false,
        },
      };
    }
    case types.GET_SHARED_PROJECT_THUMBNAIL_SUCCESS: {
      const { thumbnailDataUrl } = payload;
      return {
        ...state,
        currentSharedProject: {
          ...state.currentSharedProject,
          thumbnailDataUrl,
        },
        status: {
          ...state.status,
          getSharedProjectThumbnailPending: false,
          getSharedProjectThumbnailSuccess: true,
        },
      };
    }
    case types.GET_SHARED_PROJECT_THUMBNAIL_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getSharedProjectThumbnailPending: false,
          getSharedProjectThumbnailSuccess: false,
        },
      };
    }
    case types.GET_SHARED_PROJECT_MODELS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getSharedProjectModelsPending: true,
          getSharedProjectModelsSuccess: false,
        },
      };
    }
    case types.GET_SHARED_PROJECT_MODELS_SUCCESS: {
      const { models } = payload;
      return {
        ...state,
        models,
        status: {
          ...state.status,
          getSharedProjectModelsPending: false,
          getSharedProjectModelsSuccess: true,
        },
      };
    }
    case types.GET_SHARED_PROJECT_MODELS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getSharedProjectModelsPending: false,
          getSharedProjectModelsSuccess: false,
        },
      };
    }
    case types.GET_POLAR_PRINTERS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getPolarPrintersPending: true,
          getPolarPrintersSuccess: false,
        },
      };
    }
    case types.GET_POLAR_PRINTERS_SUCCESS: {
      const { printers } = payload;
      return {
        ...state,
        integrations: {
          ...state.integrations,
          polarCloud: {
            ...state.integrations.polarCloud,
            printers,
          },
        },
        status: {
          ...state.status,
          getPolarPrintersPending: false,
          getPolarPrintersSuccess: true,
        },
      };
    }
    case types.GET_POLAR_PRINTERS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getPolarPrintersPending: false,
          getPolarPrintersSuccess: false,
        },
      };
    }
    case types.DISPATCH_PRINT_TO_POLAR_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          dispatchPrintToPolarPending: true,
          dispatchPrintToPolarSuccess: false,
        },
      };
    }
    case types.DISPATCH_PRINT_TO_POLAR_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          dispatchPrintToPolarPending: false,
          dispatchPrintToPolarSuccess: true,
        },
      };
    }
    case types.DISPATCH_PRINT_TO_POLAR_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          dispatchPrintToPolarPending: false,
          dispatchPrintToPolarSuccess: false,
        },
      };
    }
    case types.SAVE_MODEL_CUSTOM_SUPPORTS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          saveModelCustomSupportsPending: true,
          saveModelCustomSupportsSuccess: false,
        },
      };
    }
    case types.SAVE_MODEL_CUSTOM_SUPPORTS_SUCCESS: {
      const { supportsDataPerModel } = payload;
      const newModelTree = Helpers.updateModelCustomSupportsData(
        state.models,
        supportsDataPerModel
      );
      return {
        ...state,
        models: newModelTree,
        status: {
          ...state.status,
          saveModelCustomSupportsPending: false,
          saveModelCustomSupportsSuccess: true,
        },
      };
    }
    case types.SAVE_MODEL_CUSTOM_SUPPORTS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          saveModelCustomSupportsPending: false,
          saveModelCustomSupportsSuccess: false,
        },
      };
    }
    case types.DISPATCH_PRINT_TO_DEVICE_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          dispatchPrintToDevicePending: true,
          dispatchPrintToDeviceSuccess: false,
        },
      };
    }
    case types.DISPATCH_PRINT_TO_DEVICE_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          dispatchPrintToDevicePending: false,
          dispatchPrintToDeviceSuccess: true,
        },
      };
    }
    case types.DISPATCH_PRINT_TO_DEVICE_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          dispatchPrintToDevicePending: false,
          dispatchPrintToDeviceSuccess: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};

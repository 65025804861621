import 'regenerator-runtime/runtime';
import _ from 'lodash';
import { delay } from 'redux-saga';
import { call, fork, put, race, select, take } from 'redux-saga/effects';

import { getCognitoCredentials, updateCognitoCredentials } from './credentials';
import { getUserTopicFilters } from './topics';
import logger from './logger';

import { handleGenericError, getAuthState, getIotState } from '../common';
import { actions, types } from '../../reducers/iot/iot';

import {
  createWorker,
  terminateWorker,
  getChannel,
  initClient,
  updateCredentials,
  subscribe,
} from './iotWorker';
import workerTypes from '../../utils/iot-worker/types';
import { OFFLINE_RECONNECT_TIMEOUT } from '../../utils/iot-worker/constants';

let channel;

export function* waitForInitConnection() {
  const { status } = yield select(getIotState);

  if (!status.initConnectionPending) return status.initConnectionSuccess;

  // wait for the call to finish
  const { success } = yield race({
    success: take(types.INIT_CONNECTION_SUCCESS),
    failure: take(types.INIT_CONNECTION_FAILURE),
  });
  return !!success;
}

function* backgroundCredentialRefresh(userId, clientId) {
  try {
    const credentialsResponse = yield call(
      updateCognitoCredentials,
      userId,
      clientId
    );
    if (credentialsResponse === null) return;
    const { region, identityId, token } = credentialsResponse;
    yield call(updateCredentials, region, identityId, token);
  } finally {
    // cancelled
  }
}

function* channelListener(userId) {
  try {
    let firstConnect = true;
    while (true) {
      const message = yield take(channel);
      const { type, payload } = message;
      switch (type) {
        case workerTypes.STATUS: {
          const { online } = payload;
          yield put(actions.setConnectedStatus(online));
          if (online && firstConnect) {
            yield put(actions.checkDeviceHeartbeatRequest());
            firstConnect = false;
          }
          break;
        }
        case workerTypes.MESSAGE: {
          yield put(actions.receiveMessage(payload.topic, payload.message));
          break;
        }
        case workerTypes.REFRESH: {
          yield fork(backgroundCredentialRefresh, userId, payload.clientId);
          break;
        }
        case workerTypes.REINITIALIZE: {
          // trigger this saga again and return immediately
          if (channel) {
            channel.close();
            channel = null;
          }
          yield put(actions.initConnectionRequest());
          return;
        }
        default:
          break;
      }
    }
  } finally {
    // cancelled
    logger.dev('channel listener cancelled');
  }
}

export default function* initConnection(action) {
  const { userId } = yield select(getAuthState);
  if (!userId) return;
  logger.dev('initConnection()');
  try {
    // close any existing channel
    if (channel) {
      channel.close();
      channel = null;
    }

    terminateWorker();
    createWorker();

    // wrap the worker in a new channel to start receiving updates
    logger.dev('creating channel');
    channel = getChannel();
    yield fork(channelListener, userId);

    // create a new MQTT client
    {
      logger.dev('getting credentials');
      const credentialsResponse = yield call(getCognitoCredentials, userId);
      if (credentialsResponse === null) return;
      const { endpoint, clientId, region, identityId, token } =
        credentialsResponse;
      logger.dev('calling initClient');
      yield call(initClient, endpoint, clientId, region, identityId, token);
    }

    logger.dev('subscribing to things now');
    // if any devices are already loaded, subscribe to their topics now
    // (redundant on first load, as getDevices will wait for initConnectionSuccess
    // and then subscribe, but not redundant on re-initializing connection!)
    const { devices } = yield select(getIotState);
    const deviceIds = _.map(devices, (device) => device.id);
    const topicFilters = getUserTopicFilters(userId, deviceIds);
    yield call(subscribe, topicFilters);

    yield put(actions.initConnectionSuccess());
  } catch (e) {
    logger.error('outer catch block', e);
    terminateWorker();
    if (channel) {
      channel.close();
      channel = null;
    }
    yield put(actions.setConnectedStatus(false));
    if (e.status) {
      // API response error during credential fetch
      yield call(handleGenericError, action, e);
    } else {
      // network connectivity or other error
      yield delay(OFFLINE_RECONNECT_TIMEOUT);
      yield put(actions.initConnectionRequest());
    }
  }
}

export function* closeConnection() {
  logger.dev('closeConnection()');
  // set channel to null before calling channel.close()
  // for logic within channel loop's finally block
  const channelRef = channel;
  channel = null;
  if (channelRef) {
    channelRef.close();
  }
  terminateWorker();
  yield put(actions.setConnectedStatus(false));
}

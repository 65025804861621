import { fieldTypes, fieldSteps } from '../../../../../constants';
import tooltips from './tooltips.jsx';

const { EXTRUSION_WIDTH_STEP, SPEED_STEP } = fieldSteps;

export const infillStyles = [
  { label: 'Straight', value: 0 },
  { label: 'Octagonal', value: 1 },
  { label: 'Rounded', value: 2 },
  { label: 'Cellular', value: 3 },
];

const Metadata = {
  style: {
    label: 'Style',
    tags: [],
    fields: [
      {
        name: 'infillDensity',
        label: 'Infill density',
        tags: [],
        tooltip: tooltips.infillDensity,
        disabled: (style) => style.spiralVaseMode,
        variants: [
          {
            units: '%',
            type: fieldTypes.number,
            min: 0,
            max: 100,
            step: 1,
          },
        ],
      },
      {
        name: 'infillStyle',
        label: 'Infill style',
        tags: ['infill type'],
        tooltip: tooltips.infillStyle,
        disabled: (style) => style.spiralVaseMode,
        variants: [
          {
            type: fieldTypes.boxSelection,
            options: infillStyles,
          },
        ],
      },
      {
        name: 'spiralVaseMode',
        label: 'Vase mode',
        tags: [],
        tooltip: tooltips.spiralVaseMode,
        variants: [
          {
            type: fieldTypes.checkbox,
          },
        ],
      },
    ],
  },
  extrusion: {
    label: 'Extrusion',
    tags: [],
    fields: [
      {
        name: 'infillExtrusionWidth',
        label: 'Infill extrusion width',
        tags: ['extrusion thickness'],
        tooltip: tooltips.infillExtrusionWidth,
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: EXTRUSION_WIDTH_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            units: '%',
            optionLabel: '% of extrusion width',
            tags: [],
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: 1,
            toBaseUnits: (value, style) =>
              Math.round(value * style.extrusionWidth) / 100,
            fromBaseUnits: (value, style) =>
              Math.round((value / style.extrusionWidth) * 100),
          },
          {
            type: fieldTypes.auto,
            toBaseUnits: (value, style) =>
              Math.round(style.extrusionWidth * 120) / 100,
            fromBaseUnits: () => 'auto',
          },
        ],
      },
      {
        name: 'infillSpeed',
        label: 'Infill print speed',
        tags: [],
        tooltip: tooltips.infillSpeed,
        variants: [
          {
            units: 'mm/s',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: SPEED_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            units: '%',
            optionLabel: '% of solid layer speed',
            tags: [],
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: 1,
            toBaseUnits: (value, style) =>
              Math.round((value * style.solidLayerSpeed) / 10) / 10,
            fromBaseUnits: (value, style) =>
              Math.round((value / style.solidLayerSpeed) * 100),
          },
        ],
      },
      {
        name: 'infillPerimeterOverlap',
        label: 'Infill-perimeter overlap',
        tags: ['solid layer overlap'],
        tooltip: tooltips.infillPerimeterOverlap,
        variants: [
          {
            units: '%',
            type: fieldTypes.number,
            min: 0,
            step: 1,
          },
        ],
      },
    ],
  },
};

export default Metadata;

import styled, { css } from 'styled-components';

export const StyledImage = styled.img`
  user-select: none;
  pointer-events: none;
  max-width: 100%;

  ${(props) =>
    props.invert &&
    css`
      filter: invert(100%);
    `}
`;

import styled from 'styled-components';

import { Breakpoints } from '../../../themes';

export const Content = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, min-content);
`;

export const PrinterTitleWrapper = styled.div`
  margin-bottom: 0.4rem;
  max-width: 20rem;
  @media (max-width: ${Breakpoints.ex}) {
    max-width: 15rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap:  wrap-reverse; 
  grid-row: 4;
  grid-column: span 5;
  gap: 0.5rem;
  justify-content: flex-end;
  @media (max-width: ${Breakpoints.smallWidth}) {
    justify-content: flex-end;
    grid-row: 5;
    grid-column: span 5;  
`;

export const ImportHeadingContainer = styled.div`
  grid-row: 1;
  grid-column: span 3;
  display: flex;
  flex-direction: column;
  min-width: 5rem;

  @media (max-width: ${Breakpoints.smallWidth}) {
    justify-content: center;
    grid-row: 2;
    grid-column: span 5;
  }
`;

export const ImportSelectionItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  @media (max-width: ${Breakpoints.smallWidth}) {
    justify-content: center;
  }
`;

export const ImportSelectionLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  @media (max-width: ${Breakpoints.smallWidth}) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const BoxSelectionWrapper = styled.div`
  display: flex;
  @media (max-width: ${Breakpoints.smallWidth}) {
    flex-direction: column;
  }
`;

export const PrinterSelectionContainer = styled.div`
  grid-row: 2;
  grid-column: span 5;

  @media (max-width: ${Breakpoints.smallWidth}) {
    grid-row: 3;
  }
`;

export const PrinterSelectionRenameContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 3.625rem;
  gap: 0.5rem;
  @media (max-width: ${Breakpoints.smallWidth}) {
    flex-direction: column;
    width: 100%;
  }
`;

export const StylesSelectionContainer = styled.div`
  grid-row: 3;
  grid-column: span 5;
  @media (max-width: ${Breakpoints.smallWidth}) {
    grid-row: 4;
    grid-column: span 5;
    height: 100%;
    margin-bottom: 0;
    width: 100%;
  }
`;

export const CheckboxList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 8rem;
  max-height: 10rem;
  overflow-y: auto;

  @media (max-width: ${Breakpoints.smallWidth}) {
    max-height: 8rem;
    height: 100%;
    overflow-x: hidden;
    span {
      white-space: normal;
    }
  }
`;

export const CheckboxItem = styled.li`
  display: flex;
  align-items: center;
  margin: 0.2rem 0;
  width: 100%;
`;

export const PrinterImageContainer = styled.div`
  padding: 0.5rem 0;
  grid-row: 1;
  grid-column: 4 / span 2;
  background: ${(props) => props.theme.colors.grey2};
  display: flex;
  justify-content: center;
  height: 9rem;

  @media (max-width: ${Breakpoints.smallWidth}) {
    height: 7rem;
    grid-column: 2 / span 3;
  }

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    height: 7rem;
    grid-column: span 5;
  }
`;

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

import {
  Container,
  MaterialLabel,
  NoResult,
  ResultsGroup,
  SearchResultsContainer,
} from './globalSearchBar.styles';

import AdvancedButton from '../../../advancedButton/advancedButton.jsx';
import Routes from '../../../../router/routes';
import SearchBar from '../../../searchBar/searchBar.jsx';
import { Icons } from '../../../../themes';
import {
  DeviceUtils,
  FormatUtils,
  MaterialUtils,
  PrinterUtils,
  ProjectUtils,
} from '../../../../utils';
import { MaterialSortMode, PrinterSortMode } from '../../../../utils/sort/sort';
import { Body1OneLine } from '../../../typography/typography';
import Badge from '../../../badge/badge.jsx';

const RESULTS_LIMIT = 5;

const limitResults = (list, showAll) => {
  if (showAll) {
    return list;
  }
  return list.slice(0, RESULTS_LIMIT);
};

const GlobalSearchBar = () => {
  const history = useHistory();
  const theme = useTheme();

  const { materials, projects } = useSelector((state) => state.slicer);
  const { printers } = useSelector((state) => state.printers);
  const { devices, deviceStates } = useSelector((state) => state.iot);
  const { loadProjectsPending, loadProjectPending, getMaterialsPending } =
    useSelector((state) => state.slicer.status);
  const { getPrintersPending } = useSelector((state) => state.printers.status);
  const { getDevicesPending } = useSelector((state) => state.iot.status);

  const containerRef = useRef(null);
  const [filterBy, setFilterBy] = useState('');
  const [showAll, setShowAll] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [folderResults, setFolderResults] = useState([]);
  const [projectResults, setProjectResults] = useState([]);
  const [deviceResults, setDeviceResults] = useState([]);
  const [printerResults, setPrinterResults] = useState([]);
  const [materialResults, setMaterialResults] = useState([]);

  const dataIsLoading =
    loadProjectsPending ||
    loadProjectPending ||
    getMaterialsPending ||
    getPrintersPending ||
    getDevicesPending;

  const noResultExceedingLimit =
    folderResults.length < RESULTS_LIMIT &&
    projectResults.length < RESULTS_LIMIT &&
    deviceResults.length < RESULTS_LIMIT &&
    printerResults.length < RESULTS_LIMIT &&
    materialResults.length < RESULTS_LIMIT;

  const noResultFound =
    folderResults.length === 0 &&
    projectResults.length === 0 &&
    deviceResults.length === 0 &&
    printerResults.length === 0 &&
    materialResults.length === 0;

  useEffect(() => {
    if (filterBy) return;
    setShowAll(false);
    setShowDropdown(true);
  }, [filterBy]);

  // event listeners
  useEffect(() => {
    const onWindowMouseDown = (e) => {
      // listen to on margin clicks
      if (containerRef.current?.contains(e.target)) return;
      setShowDropdown(false);
    };

    window.addEventListener('mousedown', onWindowMouseDown, false);

    return () => {
      window.removeEventListener('mousedown', onWindowMouseDown, false);
    };
  }, []);

  const redirectTo = (route, state) => {
    setFilterBy('');
    history.push(route, state);
  };

  const onKeyDown = (e) => {
    e.stopPropagation();
  };

  const onFilterChange = (value) => {
    const normalizedFilter = value.toLowerCase().trimStart();
    const filterFunction = (item) => {
      const a = item.toLowerCase().trim();
      return a.includes(normalizedFilter);
    };

    const foldersArray = Object.values(projects).filter(
      (item) => item.directory
    );
    const projectsArray = Object.values(projects).filter(
      (item) => !item.directory
    );

    const filteredFolders = foldersArray.filter((folder) =>
      filterFunction(folder.name)
    );
    const filteredProjects = projectsArray.filter((project) =>
      filterFunction(project.name)
    );
    const filteredDevices = Object.values(devices).filter((device) =>
      filterFunction(device.name)
    );
    const filteredPrinters = Object.values(printers).filter((printer) =>
      filterFunction(printer.machineSettings.name)
    );
    const filteredMaterials = Object.values(materials).filter((material) => {
      return (
        material.id !== '0' &&
        (filterFunction(material.name) || filterFunction(material.type))
      );
    });

    const sortedFolders = ProjectUtils.sortFolders(filteredFolders);
    const sortedProjects = ProjectUtils.sortProjects(filteredProjects, []);
    const sortedDevices = DeviceUtils.sortDevices(filteredDevices);
    const sortedPrinters = PrinterUtils.sortPrinters(filteredPrinters, {
      mode: PrinterSortMode.DATE_MODIFIED,
    });
    const sortedMaterials = MaterialUtils.sortMaterials(filteredMaterials, {
      mode: MaterialSortMode.DATE_MODIFIED,
    });

    setFolderResults(sortedFolders);
    setProjectResults(sortedProjects);
    setDeviceResults(sortedDevices);
    setPrinterResults(sortedPrinters);
    setMaterialResults(sortedMaterials);
    setFilterBy(value);
  };

  const renderFolderResults = () => {
    if (folderResults.length === 0) return null;
    const renderFolderItem = (folder) => (
      <AdvancedButton
        item
        key={folder.id}
        icon={Icons.basic.folder}
        label={folder.name}
        description=''
        onClick={() => redirectTo(Routes.toFolder(folder.id))}
      />
    );

    return (
      <ResultsGroup>
        {limitResults(folderResults, showAll).map((folder) =>
          renderFolderItem(folder)
        )}
      </ResultsGroup>
    );
  };

  const renderProjectResults = () => {
    if (projectResults.length === 0) return null;
    const renderProjectItem = (project) => (
      <AdvancedButton
        item
        key={project.id}
        icon={Icons.project.project}
        label={project.name}
        description={`${FormatUtils.isoToRelativeTime(project.modified)} ago`}
        onClick={() => redirectTo(Routes.toSlicer(project.id))}
      />
    );

    return (
      <ResultsGroup>
        {limitResults(projectResults, showAll).map((project) =>
          renderProjectItem(project)
        )}
      </ResultsGroup>
    );
  };

  const renderDeviceResults = () => {
    if (deviceResults.length === 0) return null;
    const renderDeviceItem = (device) => {
      const deviceState = deviceStates[device.id];
      const isOnline = DeviceUtils.isOnline(deviceState);

      return (
        <AdvancedButton
          item
          key={device.id}
          icon={DeviceUtils.getDeviceIcon(device, isOnline)}
          iconColor={isOnline ? theme.colors.greenDark : theme.colors.grey4}
          label={device.name}
          description={isOnline ? 'Online' : 'Offline'}
          onClick={() => redirectTo(Routes.toViewDevice(device.id))}
        />
      );
    };

    return (
      <ResultsGroup>
        {limitResults(deviceResults, showAll).map((device) =>
          renderDeviceItem(device)
        )}
      </ResultsGroup>
    );
  };

  const renderPrinterResults = () => {
    if (printerResults.length === 0) return null;
    const renderPrinterItem = (printer) => (
      <AdvancedButton
        item
        key={printer.id}
        icon={Icons.fdm.extruding}
        label={printer.machineSettings.name}
        description={`${FormatUtils.isoToRelativeTime(printer.modified)} ago`}
        onClick={() => redirectTo(Routes.toViewPrinter(printer.id))}
      />
    );

    return (
      <ResultsGroup>
        {limitResults(printerResults, showAll).map((printer) =>
          renderPrinterItem(printer)
        )}
      </ResultsGroup>
    );
  };

  const renderMaterialResults = () => {
    if (materialResults.length === 0) return null;
    const renderMaterialItem = (material) => {
      const materialLabel = (
        <MaterialLabel>
          <Badge>{material.type}</Badge>
          <Body1OneLine noSpacing>{material.name}</Body1OneLine>
        </MaterialLabel>
      );

      return (
        <AdvancedButton
          item
          key={material.id}
          icon={Icons.fdm.material}
          customLabel={materialLabel}
          description={`${FormatUtils.isoToRelativeTime(
            material.timestamp
          )} ago`}
          onClick={(e) => {
            e.stopPropagation();
            redirectTo(Routes.toManageMaterials(), { selectedId: material.id });
          }}
        />
      );
    };

    return (
      <ResultsGroup>
        {limitResults(materialResults, showAll).map((material) =>
          renderMaterialItem(material)
        )}
      </ResultsGroup>
    );
  };

  const renderShowAllItem = () => {
    if (noResultExceedingLimit || showAll) return null;
    return (
      <ResultsGroup>
        <AdvancedButton
          item
          icon={Icons.basic.arrowRight}
          label='Show all results'
          description=''
          onClick={() => setShowAll(true)}
        />
      </ResultsGroup>
    );
  };

  const renderNoResultItem = () => {
    if (!noResultFound) return null;
    return (
      <NoResult>
        <Body1OneLine noSpacing>
          No results for &ldquo;{filterBy}&rdquo;
        </Body1OneLine>
      </NoResult>
    );
  };

  const renderSearchBar = () => (
    <SearchBar
      placeholder='Search Canvas'
      value={filterBy}
      onKeyDown={onKeyDown}
      onChange={onFilterChange}
      disabled={dataIsLoading}
      containerRef={containerRef}
      setShowDropdown={setShowDropdown}
    />
  );

  const renderResults = () => {
    const shouldShowResults = filterBy && showDropdown;
    return (
      <SearchResultsContainer showResults={shouldShowResults}>
        {shouldShowResults && renderShowAllItem()}
        {shouldShowResults && renderFolderResults()}
        {shouldShowResults && renderProjectResults()}
        {shouldShowResults && renderDeviceResults()}
        {shouldShowResults && renderPrinterResults()}
        {shouldShowResults && renderMaterialResults()}
        {shouldShowResults && renderNoResultItem()}
      </SearchResultsContainer>
    );
  };

  return (
    <Container ref={containerRef}>
      {renderSearchBar()}
      {renderResults()}
    </Container>
  );
};

export default GlobalSearchBar;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import AppRouter from './router/router.container';
import store from './reducers/store';
import { MonitoringUtils } from './utils';

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <AppRouter />
    </Provider>,
    document.getElementById('root')
  );
};

MonitoringUtils.sentryInit();

unregisterServiceWorker();
render();

import { connect } from 'react-redux';
import _ from 'lodash';

import PlaceView from './placeView.jsx';
import actions from '../../../reducers/slicer/actions';
import THREEactions from '../../../reducers/three/actions';
import authActions from '../../../reducers/auth/actions';

const handleAutoArrangeContext = (models) =>
  !_.isEmpty(models) ? 'translate' : '';

const mapStateToProps = (state) => {
  const project = state.slicer.projects[state.slicer.currentProject];
  return {
    intersectMode: state.slicer.intersectMode,
    materials: state.slicer.materials,
    materialListView: state.auth.preferences.slicerMaterialsListView,
    models: state.slicer.models,
    transitionTower: state.slicer.transitionTower,
    context: handleAutoArrangeContext(state.slicer.models),
    project,
    printers: state.printers.printers,
    devices: state.iot.devices,
    scene: state.three.scene,
    transformsPending: state.slicer.status.updateModelTransformsPending,
    transformsSuccess: state.slicer.status.updateModelTransformsSuccess,
    gizmo: state.three.gizmo,
    sliceJobs: state.slicer.sliceJobs,
    dispatchSliceJobPending: state.slicer.status.dispatchSliceJobPending,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateMaterialListView: (listView) =>
    dispatch(
      authActions.editAccountRequest({
        preferences: {
          slicerMaterialsListView: listView,
        },
      })
    ),
  highlightModels: () => dispatch(THREEactions.highlightModels()),
  removeModelHighlights: () => dispatch(THREEactions.removeModelHighlights()),
  attachGizmo: (selectedMeshes, gizmoMode, onMouseUp) =>
    dispatch(
      THREEactions.attachGizmoRequest(selectedMeshes, gizmoMode, onMouseUp)
    ),
  updateGizmo: () => dispatch(THREEactions.updateGizmoRequest()),
  detachGizmo: () => dispatch(THREEactions.detachGizmoRequest()),
  toggleModelActiveState: (modelId) =>
    dispatch(actions.toggleModelActiveState(modelId)),
  toggleTowerActiveState: () => dispatch(actions.toggleTowerActiveState()),
  deactivateTower: () => dispatch(actions.deactivateTower()),
  deactivateAllModels: () => dispatch(actions.deactivateAllModels()),
  updateModelTransforms: (transformData) =>
    dispatch(actions.updateModelTransformsRequest(transformData)),
  setTowerPosition: (position) =>
    dispatch(actions.updateTowerPositionOrSizeRequest(position, 'position')),
  setTowerSize: (size) =>
    dispatch(actions.updateTowerPositionOrSizeRequest(size, 'size')),
  selectMaterial: (material) => dispatch(actions.selectMaterial(material)),
  createMaterial: (material) =>
    dispatch(actions.createMaterialRequest(material)),
  editMaterial: (material) => dispatch(actions.editMaterialRequest(material)),
  deleteMaterials: (materialIds) =>
    dispatch(actions.deleteMaterialsRequest(materialIds)),
  applyMaterial: (modelsPaintData, materialIndex) =>
    dispatch(actions.applyMaterialRequest(modelsPaintData, materialIndex)),
  activateModels: (modelIds) => dispatch(actions.activateModels(modelIds)),
  setIntersectMode: (mode) => dispatch(actions.setIntersectMode(mode)),
  toggleMovingSwatch: () => dispatch(THREEactions.toggleMovingSwatch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlaceView);

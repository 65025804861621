import types from './types';

const actions = {
  resetSlicerStore: () => ({
    type: types.RESET_SLICER_STORE,
    payload: {},
  }),
  loadProjectsRequest: () => ({
    type: types.LOAD_PROJECTS_REQUEST,
    payload: {},
  }),
  loadProjectsSuccess: (projects) => ({
    type: types.LOAD_PROJECTS_SUCCESS,
    payload: { projects },
  }),
  loadProjectsFailure: (error) => ({
    type: types.LOAD_PROJECTS_FAILURE,
    payload: { error },
  }),
  loadProjectRequest: (projectId) => ({
    type: types.LOAD_PROJECT_REQUEST,
    payload: { projectId },
  }),
  loadProjectSuccess: (project, models) => ({
    type: types.LOAD_PROJECT_SUCCESS,
    payload: { project, models },
  }),
  loadProjectFailure: (error) => ({
    type: types.LOAD_PROJECT_FAILURE,
    payload: { error },
  }),
  unloadCurrentProject: () => ({
    type: types.UNLOAD_CURRENT_PROJECT,
    payload: {},
  }),
  setInputRemapperContext: (remapperContext) => ({
    type: types.SET_INPUT_REMAPPER_CONTEXT,
    payload: { remapperContext },
  }),
  clearInputRemapperContext: () => ({
    type: types.CLEAR_INPUT_REMAPPER_CONTEXT,
    payload: {},
  }),
  remapProjectInputsRequest: (projectId, inputMap, reloadRequired) => ({
    type: types.REMAP_PROJECT_INPUTS_REQUEST,
    payload: { projectId, inputMap, reloadRequired },
  }),
  remapProjectInputsSuccess: (project, models) => ({
    type: types.REMAP_PROJECT_INPUTS_SUCCESS,
    payload: { project, models },
  }),
  remapProjectInputsFailure: (error) => ({
    type: types.REMAP_PROJECT_INPUTS_FAILURE,
    payload: { error },
  }),
  createProjectRequest: (projectOptions) => ({
    type: types.CREATE_PROJECT_REQUEST,
    payload: {
      projectOptions,
    },
  }),
  createProjectSuccess: (project) => ({
    type: types.CREATE_PROJECT_SUCCESS,
    payload: { project },
  }),
  createProjectFailure: (error) => ({
    type: types.CREATE_PROJECT_FAILURE,
    payload: { error },
  }),
  createCalibrationProjectRequest: (projectOptions) => ({
    type: types.CREATE_CALIBRATION_PROJECT_REQUEST,
    payload: {
      projectOptions,
    },
  }),
  createCalibrationProjectSuccess: () => ({
    type: types.CREATE_CALIBRATION_PROJECT_SUCCESS,
    payload: {},
  }),
  createCalibrationProjectFailure: (error) => ({
    type: types.CREATE_CALIBRATION_PROJECT_FAILURE,
    payload: { error },
  }),
  deleteProjectsRequest: (projects) => ({
    type: types.DELETE_PROJECTS_REQUEST,
    payload: { projects },
  }),
  deleteProjectsSuccess: (projectIds) => ({
    type: types.DELETE_PROJECTS_SUCCESS,
    payload: { projectIds },
  }),
  deleteProjectsFailure: (error) => ({
    type: types.DELETE_PROJECTS_FAILURE,
    payload: { error },
  }),
  resetNewProjectFlags: () => ({
    type: types.RESET_NEW_PROJECT_FLAGS,
    payload: {},
  }),
  setIntersectMode: (mode) => ({
    type: types.SET_INTERSECT_MODE,
    payload: { mode },
  }),
  uploadStlRequest: (files, isGroup) => ({
    type: types.UPLOAD_STL_REQUEST,
    payload: { files, isGroup },
  }),
  uploadStlSuccess: (models) => ({
    type: types.UPLOAD_STL_SUCCESS,
    payload: { models },
  }),
  uploadStlFailure: (error) => ({
    type: types.UPLOAD_STL_FAILURE,
    payload: { error },
  }),
  updateUploadProgress: (progress) => ({
    type: types.UPDATE_UPLOAD_PROGRESS,
    payload: { progress },
  }),
  centerProjectRequest: (projectId = '', printerId = '') => ({
    type: types.CENTER_PROJECT_REQUEST,
    payload: { projectId, printerId },
  }),
  centerProjectSuccess: () => ({
    type: types.CENTER_PROJECT_SUCCESS,
    payload: {},
  }),
  centerProjectFailure: (error) => ({
    type: types.CENTER_PROJECT_FAILURE,
    payload: { error },
  }),
  repackRequest: (newTower) => ({
    type: types.REPACK_REQUEST,
    payload: { newTower },
  }),
  repackSuccess: (models) => ({
    type: types.REPACK_SUCCESS,
    payload: { models },
  }),
  repackFailure: (error) => ({
    type: types.REPACK_FAILURE,
    payload: { error },
  }),
  deleteModelsRequest: (modelIds) => ({
    type: types.DELETE_MODELS_REQUEST,
    payload: { modelIds },
  }),
  deleteModelsSuccess: () => ({
    type: types.DELETE_MODELS_SUCCESS,
    payload: {},
  }),
  deleteModelsFailure: (error) => ({
    type: types.DELETE_MODELS_FAILURE,
    payload: { error },
  }),
  duplicateModelRequest: (modelIds, count) => ({
    type: types.DUPLICATE_MODEL_REQUEST,
    payload: { modelIds, count },
  }),
  duplicateModelSuccess: (models) => ({
    type: types.DUPLICATE_MODEL_SUCCESS,
    payload: { models },
  }),
  duplicateModelFailure: (error) => ({
    type: types.DUPLICATE_MODEL_FAILURE,
    payload: { error },
  }),
  updateModelTransformsRequest: (transformData) => ({
    type: types.UPDATE_MODEL_TRANSFORMS_REQUEST,
    payload: { transformData },
  }),
  updateModelTransformsSuccess: () => ({
    type: types.UPDATE_MODEL_TRANSFORMS_SUCCESS,
    payload: {},
  }),
  updateModelTransformsFailure: (error) => ({
    type: types.UPDATE_MODEL_TRANSFORMS_FAILURE,
    payload: { error },
  }),
  updateStampTransformsRequest: (modelsWithStamps) => ({
    type: types.UPDATE_STAMP_TRANSFORMS_REQUEST,
    payload: { modelsWithStamps },
  }),
  updateStampTransformsSuccess: () => ({
    type: types.UPDATE_STAMP_TRANSFORMS_SUCCESS,
    payload: {},
  }),
  updateStampTransformsFailure: (error) => ({
    type: types.UPDATE_STAMP_TRANSFORMS_FAILURE,
    payload: { error },
  }),
  updateModelNameRequest: (modelId, newModelName) => ({
    type: types.UPDATE_MODEL_NAME_REQUEST,
    payload: { modelId, newModelName },
  }),
  updateModelNameSuccess: (modelId, newModelName) => ({
    type: types.UPDATE_MODEL_NAME_SUCCESS,
    payload: { modelId, newModelName },
  }),
  updateModelNameFailure: (error) => ({
    type: types.UPDATE_MODEL_NAME_FAILURE,
    payload: { error },
  }),
  updateModelGroupNameRequest: (oldGroupName, newGroupName, models) => ({
    type: types.UPDATE_MODEL_GROUP_NAME_REQUEST,
    payload: { oldGroupName, newGroupName, models },
  }),
  updateModelGroupNameSuccess: (oldGroupName, newGroupName, models) => ({
    type: types.UPDATE_MODEL_GROUP_NAME_SUCCESS,
    payload: { oldGroupName, newGroupName, models },
  }),
  updateModelGroupNameFailure: (error) => ({
    type: types.UPDATE_MODEL_GROUP_NAME_FAILURE,
    payload: { error },
  }),
  groupModelsRequest: (models, groupName) => ({
    type: types.GROUP_MODELS_REQUEST,
    payload: { models, groupName },
  }),
  groupModelsSuccess: () => ({
    type: types.GROUP_MODELS_SUCCESS,
    payload: {},
  }),
  groupModelsFailure: (error) => ({
    type: types.GROUP_MODELS_FAILURE,
    payload: { error },
  }),
  ungroupModelsRequest: (models, groupNames) => ({
    type: types.UNGROUP_MODELS_REQUEST,
    payload: { models, groupNames },
  }),
  ungroupModelsSuccess: () => ({
    type: types.UNGROUP_MODELS_SUCCESS,
    payload: {},
  }),
  ungroupModelsFailure: (error) => ({
    type: types.UNGROUP_MODELS_FAILURE,
    payload: { error },
  }),
  alignAndGroupModelsRequest: (models, groupName, newCenter) => ({
    type: types.ALIGN_AND_GROUP_MODELS_REQUEST,
    payload: { models, groupName, newCenter },
  }),
  alignAndGroupModelsSuccess: () => ({
    type: types.ALIGN_AND_GROUP_MODELS_SUCCESS,
    payload: {},
  }),
  alignAndGroupModelsFailure: (error) => ({
    type: types.ALIGN_AND_GROUP_MODELS_FAILURE,
    payload: { error },
  }),
  updateTower: (tower) => ({
    type: types.UPDATE_TOWER,
    payload: { tower },
  }),
  updateTowerFromServer: (projectId, tower) => ({
    type: types.UPDATE_TOWER_FROM_SERVER,
    payload: { projectId, tower },
  }),
  toggleTowerVisibility: () => ({
    type: types.TOGGLE_TOWER_VISIBILITY,
    payload: {},
  }),
  repositionTower: (projectId, tower) => ({
    type: types.REPOSITION_TOWER,
    payload: { projectId, tower },
  }),
  toggleModelActiveState: (modelId) => ({
    type: types.TOGGLE_MODEL_ACTIVE_STATE,
    payload: { modelId },
  }),
  setModelVisibilityRequest: (modelId, visible) => ({
    type: types.SET_MODEL_VISIBILITY_REQUEST,
    payload: { modelId, visible },
  }),
  setModelVisibilitySuccess: () => ({
    type: types.SET_MODEL_VISIBILITY_SUCCESS,
    payload: {},
  }),
  setModelVisibilityFailure: (error) => ({
    type: types.SET_MODEL_VISIBILITY_FAILURE,
    payload: { error },
  }),
  activateModels: (modelIds) => ({
    type: types.ACTIVATE_MODELS,
    payload: { modelIds },
  }),
  deactivateAllModels: () => ({
    type: types.DEACTIVATE_ALL_MODELS,
    payload: {},
  }),
  showAllModels: () => ({
    type: types.SHOW_ALL_MODELS,
    payload: {},
  }),
  hideAllModels: () => ({
    type: types.HIDE_ALL_MODELS,
    payload: {},
  }),
  toggleTowerActiveState: () => ({
    type: types.TOGGLE_TOWER_ACTIVE_STATE,
    payload: {},
  }),
  activateTower: () => ({
    type: types.ACTIVATE_TOWER,
    payload: {},
  }),
  deactivateTower: () => ({
    type: types.DEACTIVATE_TOWER,
    payload: {},
  }),
  updateTowerPositionOrSizeRequest: (xyz, positionOrSize) => ({
    type: types.UPDATE_TOWER_POSITION_OR_SIZE_REQUEST,
    payload: { xyz, positionOrSize },
  }),
  updateTowerPositionOrSizeSuccess: () => ({
    type: types.UPDATE_TOWER_POSITION_OR_SIZE_SUCCESS,
    payload: {},
  }),
  updateTowerPositionOrSizeFailure: (error) => ({
    type: types.UPDATE_TOWER_POSITION_OR_SIZE_FAILURE,
    payload: { error },
  }),
  selectMaterial: (material) => ({
    type: types.SELECT_MATERIAL,
    payload: { material },
  }),
  getMaterialsRequest: () => ({
    type: types.GET_MATERIALS_REQUEST,
    payload: {},
  }),
  getMaterialsSuccess: (materials) => ({
    type: types.GET_MATERIALS_SUCCESS,
    payload: { materials },
  }),
  getMaterialsFailure: (error) => ({
    type: types.GET_MATERIALS_FAILURE,
    payload: { error },
  }),
  createMaterialRequest: (material) => ({
    type: types.CREATE_MATERIAL_REQUEST,
    payload: { material },
  }),
  createMaterialSuccess: (materials) => ({
    type: types.CREATE_MATERIAL_SUCCESS,
    payload: { materials },
  }),
  createMaterialFailure: (error) => ({
    type: types.CREATE_MATERIAL_FAILURE,
    payload: { error },
  }),
  editMaterialRequest: (material) => ({
    type: types.EDIT_MATERIAL_REQUEST,
    payload: { material },
  }),
  editMaterialSuccess: (materials) => ({
    type: types.EDIT_MATERIAL_SUCCESS,
    payload: { materials },
  }),
  editMaterialFailure: (error) => ({
    type: types.EDIT_MATERIAL_FAILURE,
    payload: { error },
  }),
  deleteMaterialsRequest: (materialIds) => ({
    type: types.DELETE_MATERIALS_REQUEST,
    payload: { materialIds },
  }),
  deleteMaterialsSuccess: (materials) => ({
    type: types.DELETE_MATERIALS_SUCCESS,
    payload: { materials },
  }),
  deleteMaterialsFailure: (error) => ({
    type: types.DELETE_MATERIALS_FAILURE,
    payload: { error },
  }),
  updateProjectColorsRequest: (project, index, color, alpha, updateRLE) => ({
    type: types.UPDATE_PROJECT_COLORS_REQUEST,
    payload: {
      project,
      index,
      color,
      alpha,
      updateRLE,
    },
  }),
  updateProjectColorsSuccess: (project, models) => ({
    type: types.UPDATE_PROJECT_COLORS_SUCCESS,
    payload: { project, models },
  }),
  updateProjectColorsFailure: (error) => ({
    type: types.UPDATE_PROJECT_COLORS_FAILURE,
    payload: { error },
  }),
  updateVariableTransitionLengthsRequest: (projectId, values) => ({
    type: types.UPDATE_VARIABLE_TRANSITION_LENGTHS_REQUEST,
    payload: { projectId, values },
  }),
  updateVariableTransitionLengthsSuccess: () => ({
    type: types.UPDATE_VARIABLE_TRANSITION_LENGTHS_SUCCESS,
    payload: {},
  }),
  updateVariableTransitionLengthsFailure: (error) => ({
    type: types.UPDATE_VARIABLE_TRANSITION_LENGTHS_FAILURE,
    payload: { error },
  }),
  applyMaterialRequest: (modelsPaintData, materialIndex) => ({
    type: types.APPLY_MATERIAL_REQUEST,
    payload: { modelsPaintData, materialIndex },
  }),
  applyMaterialSuccess: () => ({
    type: types.APPLY_MATERIAL_SUCCESS,
    payload: {},
  }),
  applyMaterialFailure: (error) => ({
    type: types.APPLY_MATERIAL_FAILURE,
    payload: { error },
  }),
  changeMaterialRequest: (materialIds) => ({
    type: types.CHANGE_MATERIAL_REQUEST,
    payload: { materialIds },
  }),
  changeMaterialSuccess: (project) => ({
    type: types.CHANGE_MATERIAL_SUCCESS,
    payload: { project },
  }),
  changeMaterialFailure: (error) => ({
    type: types.CHANGE_MATERIAL_FAILURE,
    payload: { error },
  }),
  getSpliceSettingsRequest: () => ({
    type: types.GET_SPLICE_SETTINGS_REQUEST,
    payload: {},
  }),
  getSpliceSettingsSuccess: (spliceSettings) => ({
    type: types.GET_SPLICE_SETTINGS_SUCCESS,
    payload: { spliceSettings },
  }),
  getSpliceSettingsFailure: (error) => ({
    type: types.GET_SPLICE_SETTINGS_FAILURE,
    payload: { error },
  }),
  createSpliceSettingsRequest: (spliceSettingsList) => ({
    type: types.CREATE_SPLICE_SETTINGS_REQUEST,
    payload: { spliceSettingsList },
  }),
  createSpliceSettingsSuccess: (spliceSettings) => ({
    type: types.CREATE_SPLICE_SETTINGS_SUCCESS,
    payload: { spliceSettings },
  }),
  createSpliceSettingsFailure: (error) => ({
    type: types.CREATE_SPLICE_SETTINGS_FAILURE,
    payload: { error },
  }),

  updateProjectStyleRequest: (style) => ({
    type: types.UPDATE_PROJECT_STYLE_REQUEST,
    payload: { style },
  }),
  updateProjectStyleSuccess: (updatedSettings) => ({
    type: types.UPDATE_PROJECT_STYLE_SUCCESS,
    payload: { updatedSettings },
  }),
  updateProjectStyleFailure: (error) => ({
    type: types.UPDATE_PROJECT_STYLE_FAILURE,
    payload: { error },
  }),

  updateProjectNameRequest: (
    projectId,
    newProjectName,
    allowMakeNameUnique = false
  ) => ({
    type: types.UPDATE_PROJECT_NAME_REQUEST,
    payload: { projectId, newProjectName, allowMakeNameUnique },
  }),
  updateProjectNameSuccess: (project) => ({
    type: types.UPDATE_PROJECT_NAME_SUCCESS,
    payload: { project },
  }),
  updateProjectNameFailure: (error) => ({
    type: types.UPDATE_PROJECT_NAME_FAILURE,
    payload: { error },
  }),
  updateProjectSettingsRequest: (
    projectId,
    settings,
    reloadRequired = false
  ) => ({
    type: types.UPDATE_PROJECT_SETTINGS_REQUEST,
    payload: {
      projectId,
      settings,
      reloadRequired,
    },
  }),
  updateProjectSettingsSuccess: (project) => ({
    type: types.UPDATE_PROJECT_SETTINGS_SUCCESS,
    payload: { project },
  }),
  updateProjectSettingsFailure: (error) => ({
    type: types.UPDATE_PROJECT_SETTINGS_FAILURE,
    payload: { error },
  }),
  invalidateSlice: (projectId) => ({
    type: types.INVALIDATE_SLICE,
    payload: { projectId },
  }),
  getSliceJobsRequest: () => ({
    type: types.GET_SLICE_JOBS_REQUEST,
    payload: {},
  }),
  getSliceJobsSuccess: (sliceJobs) => ({
    type: types.GET_SLICE_JOBS_SUCCESS,
    payload: { sliceJobs },
  }),
  getSliceJobsFailure: (error) => ({
    type: types.GET_SLICE_JOBS_FAILURE,
    payload: { error },
  }),
  dispatchSliceJobRequest: (projectId) => ({
    type: types.DISPATCH_SLICE_JOB_REQUEST,
    payload: { projectId },
  }),
  dispatchSliceJobSuccess: (project, jobInfo) => ({
    type: types.DISPATCH_SLICE_JOB_SUCCESS,
    payload: { project, jobInfo },
  }),
  dispatchSliceJobFailure: (projectId, error) => ({
    type: types.DISPATCH_SLICE_JOB_FAILURE,
    payload: { projectId, error },
  }),
  cancelSliceJobRequest: (projectId) => ({
    type: types.CANCEL_SLICE_JOB_REQUEST,
    payload: { projectId },
  }),
  cancelSliceJobSuccess: (projectId) => ({
    type: types.CANCEL_SLICE_JOB_SUCCESS,
    payload: { projectId },
  }),
  cancelSliceJobFailure: (error) => ({
    type: types.CANCEL_SLICE_JOB_FAILURE,
    payload: { error },
  }),
  updateSliceJob: (projectId, jobInfo) => ({
    type: types.UPDATE_SLICE_JOB,
    payload: { projectId, jobInfo },
  }),
  completeSliceJob: (projectId, slicedProject) => ({
    type: types.COMPLETE_SLICE_JOB,
    payload: { projectId, slicedProject },
  }),
  getSlicePreviewData: () => ({
    type: types.GET_SLICE_PREVIEW_DATA,
    payload: {},
  }),
  loadProjectStatsRequest: (projectId) => ({
    type: types.LOAD_PROJECT_STATS_REQUEST,
    payload: { projectId },
  }),
  loadProjectStatsSuccess: (project) => ({
    type: types.LOAD_PROJECT_STATS_SUCCESS,
    payload: { project },
  }),
  loadProjectStatsFailure: (error) => ({
    type: types.LOAD_PROJECT_STATS_FAILURE,
    payload: { error },
  }),
  loadToolpathPtpRequest: (
    viewType = null,
    singleBuffer = '',
    isColorBuffer = false
  ) => ({
    type: types.LOAD_TOOLPATH_PTP_REQUEST,
    payload: { viewType, singleBuffer, isColorBuffer },
  }),
  loadToolpathPtpProgress: (percent) => ({
    type: types.LOAD_TOOLPATH_PTP_PROGRESS,
    payload: { percent },
  }),
  loadToolpathPtpSuccess: (version, ptp) => ({
    type: types.LOAD_TOOLPATH_PTP_SUCCESS,
    payload: { version, ptp },
  }),
  loadToolpathPtpFailure: () => ({
    type: types.LOAD_TOOLPATH_PTP_FAILURE,
    payload: {},
  }),
  loadToolpathPtpCancel: () => ({
    type: types.LOAD_TOOLPATH_PTP_CANCEL,
    payload: {},
  }),
  unloadToolpath: () => ({
    type: types.UNLOAD_TOOLPATH_PTP,
    payload: {},
  }),
  createToolpathMeshesRequest: (
    viewType,
    resetBounds = true,
    replaceExtrusions = false
  ) => ({
    type: types.CREATE_TOOLPATH_MESHES_REQUEST,
    payload: { viewType, resetBounds, replaceExtrusions },
  }),
  createToolpathMeshesProgress: (percent) => ({
    type: types.CREATE_TOOLPATH_MESHES_PROGRESS,
    payload: { percent },
  }),
  createToolpathMeshesSuccess: (toolpath) => ({
    type: types.CREATE_TOOLPATH_MESHES_SUCCESS,
    payload: { toolpath },
  }),
  createToolpathMeshesFailure: () => ({
    type: types.CREATE_TOOLPATH_MESHES_FAILURE,
    payload: {},
  }),
  createToolpathMeshesCancel: () => ({
    type: types.CREATE_TOOLPATH_MESHES_CANCEL,
    payload: {},
  }),
  setToolpathViewType: (viewType) => ({
    type: types.SET_TOOLPATH_VIEW_TYPE,
    payload: { viewType },
  }),
  setToolpathShowTravel: (showTravel) => ({
    type: types.SET_TOOLPATH_SHOW_TRAVEL,
    payload: { showTravel },
  }),
  setToolpathShowRetracts: (showRetracts) => ({
    type: types.SET_TOOLPATH_SHOW_RETRACTS,
    payload: { showRetracts },
  }),
  setToolpathShowRestarts: (showRestarts) => ({
    type: types.SET_TOOLPATH_SHOW_RESTARTS,
    payload: { showRestarts },
  }),
  setToolpathLayerRange: (minLayer, maxLayer) => ({
    type: types.SET_TOOLPATH_LAYER_RANGE,
    payload: { minLayer, maxLayer },
  }),
  downloadRequest: (projectId) => ({
    type: types.DOWNLOAD_REQUEST,
    payload: { projectId },
  }),
  downloadSuccess: () => ({
    type: types.DOWNLOAD_SUCCESS,
    payload: {},
  }),
  downloadFailure: (error) => ({
    type: types.DOWNLOAD_FAILURE,
    payload: { error },
  }),
  updateProjectThumbnailRequest: (projectId) => ({
    type: types.UPDATE_PROJECT_THUMBNAIL_REQUEST,
    payload: { projectId },
  }),
  updateProjectThumbnailSuccess: (projectId, thumbnailDataURL, timestamp) => ({
    type: types.UPDATE_PROJECT_THUMBNAIL_SUCCESS,
    payload: { projectId, thumbnailDataURL, timestamp },
  }),
  updateProjectThumbnailFailure: (error) => ({
    type: types.UPDATE_PROJECT_THUMBNAIL_FAILURE,
    payload: { error },
  }),
  getProjectThumbnailRequest: (projectId) => ({
    type: types.GET_PROJECT_THUMBNAIL_REQUEST,
    payload: { projectId },
  }),
  getProjectThumbnailSuccess: (projectId, thumbnailDataURL) => ({
    type: types.GET_PROJECT_THUMBNAIL_SUCCESS,
    payload: { projectId, thumbnailDataURL },
  }),
  getProjectThumbnailFailure: (error) => ({
    type: types.GET_PROJECT_THUMBNAIL_FAILURE,
    payload: { error },
  }),
  deleteProjectThumbnailRequest: (projectId) => ({
    type: types.DELETE_PROJECT_THUMBNAIL_REQUEST,
    payload: { projectId },
  }),
  deleteProjectThumbnailSuccess: (projectId, timestamp) => ({
    type: types.DELETE_PROJECT_THUMBNAIL_SUCCESS,
    payload: { projectId, timestamp },
  }),
  deleteProjectThumbnailFailure: (error) => ({
    type: types.DELETE_PROJECT_THUMBNAIL_FAILURE,
    payload: { error },
  }),
  duplicateProjectRequest: (projectId) => ({
    type: types.DUPLICATE_PROJECT_REQUEST,
    payload: { projectId },
  }),
  duplicateProjectSuccess: (project) => ({
    type: types.DUPLICATE_PROJECT_SUCCESS,
    payload: { project },
  }),
  duplicateProjectFailure: (error) => ({
    type: types.DUPLICATE_PROJECT_FAILURE,
    payload: { error },
  }),
  createFolderRequest: (name, parentId = null) => ({
    type: types.CREATE_FOLDER_REQUEST,
    payload: { name, parentId },
  }),
  createFolderSuccess: (project) => ({
    type: types.CREATE_FOLDER_SUCCESS,
    payload: { project },
  }),
  createFolderFailure: (error) => ({
    type: types.CREATE_FOLDER_FAILURE,
    payload: { error },
  }),
  updateProjectsParentRequest: (projectIds, parentId = null) => ({
    type: types.UPDATE_PROJECTS_PARENT_REQUEST,
    payload: { projectIds, parentId },
  }),
  updateProjectsParentSuccess: (projects) => ({
    type: types.UPDATE_PROJECTS_PARENT_SUCCESS,
    payload: { projects },
  }),
  updateProjectsParentFailure: (error) => ({
    type: types.UPDATE_PROJECTS_PARENT_FAILURE,
    payload: { error },
  }),
  // FacePainter
  registerBrush: (brush) => ({
    type: types.REGISTER_BRUSH,
    payload: { brush },
  }),
  markModelToColor: (modelId) => ({
    type: types.MARK_MODEL_TO_COLOR,
    payload: { modelId },
  }),
  cancelPaintOperation: () => ({
    type: types.CANCEL_WORKER,
    payload: {},
  }),
  selectBrushColor: (color) => ({
    type: types.SELECT_BRUSH_COLOR,
    payload: { color },
  }),
  selectBrushType: (type) => ({
    type: types.SELECT_BRUSH_TYPE,
    payload: { type },
  }),
  selectBrushSize: (size) => ({
    type: types.SELECT_BRUSH_SIZE,
    payload: { size },
  }),
  togglePainting: (onOrOff) => ({
    type: types.TOGGLE_PAINTING,
    payload: { onOrOff },
  }),
  autoSegmentRequest: (
    angleThresholdTriangle,
    angleThresholdSegment,
    sizeThreshold
  ) => ({
    type: types.AUTO_SEGMENT_REQUEST,
    payload: { angleThresholdTriangle, angleThresholdSegment, sizeThreshold },
  }),
  autoSegmentSuccess: () => ({
    type: types.AUTO_SEGMENT_SUCCESS,
    payload: {},
  }),
  toggleSegmentBoundaries: (onOrOff) => ({
    type: types.TOGGLE_SEGMENT_BOUNDARIES,
    payload: { onOrOff },
  }),
  buildFacesRequest: (modelId) => ({
    type: types.BUILD_FACES_REQUEST,
    payload: { modelId },
  }),
  buildFacesSuccess: () => ({
    type: types.BUILD_FACES_SUCCESS,
    payload: {},
  }),
  resetRegionsRequest: () => ({
    type: types.RESET_REGIONS_REQUEST,
    payload: {},
  }),
  resetRegionsSuccess: () => ({
    type: types.RESET_REGIONS_SUCCESS,
    payload: {},
  }),
  generateAtlasRequest: (resolution, maxTextureSize, maxTextures) => ({
    type: types.GENERATE_ATLAS_REQUEST,
    payload: { resolution, maxTextureSize, maxTextures },
  }),
  generateAtlasSuccess: () => ({
    type: types.GENERATE_ATLAS_SUCCESS,
    payload: {},
  }),
  composeColorsRLERequest: (inPlace) => ({
    type: types.COMPOSE_COLORS_RLE_REQUEST,
    payload: { inPlace },
  }),
  composeColorsRLESuccess: (modelId, rle, inPlace) => ({
    type: types.COMPOSE_COLORS_RLE_SUCCESS,
    payload: { modelId, rle, inPlace },
  }),
  restoreColorsWithRLE: (modelId, originalRle) => ({
    type: types.RESTORE_COLORS_WITH_RLE,
    payload: { modelId, originalRle },
  }),
  saveModelPaintDataRequest: (modelId, paintData) => ({
    type: types.SAVE_MODEL_PAINT_DATA_REQUEST,
    payload: {
      modelId,
      paintData,
    },
  }),
  saveModelPaintDataSuccess: (modelId, stampMap, rle) => ({
    type: types.SAVE_MODEL_PAINT_DATA_SUCCESS,
    payload: {
      modelId,
      stampMap,
      rle,
    },
  }),
  saveModelPaintDataFailure: (error) => ({
    type: types.SAVE_MODEL_PAINT_DATA_FAILURE,
    payload: { error },
  }),
  deleteModelPaintDataRequest: (modelId, deleteRLE, deleteTexture) => ({
    type: types.DELETE_MODEL_PAINT_DATA_REQUEST,
    payload: { modelId, deleteRLE, deleteTexture },
  }),
  deleteModelPaintDataSuccess: () => ({
    type: types.DELETE_MODEL_PAINT_DATA_SUCCESS,
    payload: {},
  }),
  deleteModelPaintDataFailure: (error) => ({
    type: types.DELETE_MODEL_PAINT_DATA_FAILURE,
    payload: { error },
  }),

  // Sharing
  createSharedProjectRequest: (
    projectId,
    shareModels,
    sharePrinter,
    shareStyle,
    shareMaterials
  ) => ({
    type: types.CREATE_SHARED_PROJECT_REQUEST,
    payload: {
      projectId,
      shareModels,
      sharePrinter,
      shareStyle,
      shareMaterials,
    },
  }),
  createSharedProjectSuccess: (projectId, shareData) => ({
    type: types.CREATE_SHARED_PROJECT_SUCCESS,
    payload: { projectId, shareData },
  }),
  createSharedProjectFailure: (error) => ({
    type: types.CREATE_SHARED_PROJECT_FAILURE,
    payload: { error },
  }),
  deleteSharedProjectRequest: (projectId) => ({
    type: types.DELETE_SHARED_PROJECT_REQUEST,
    payload: { projectId },
  }),
  deleteSharedProjectSuccess: (projectId, timestamp) => ({
    type: types.DELETE_SHARED_PROJECT_SUCCESS,
    payload: { projectId, timestamp },
  }),
  deleteSharedProjectFailure: (error) => ({
    type: types.DELETE_SHARED_PROJECT_FAILURE,
    payload: { error },
  }),
  updateSharedProjectRequest: (
    projectId,
    shareModels,
    sharePrinter,
    shareStyle,
    shareMaterials
  ) => ({
    type: types.UPDATE_SHARED_PROJECT_REQUEST,
    payload: {
      projectId,
      shareModels,
      sharePrinter,
      shareStyle,
      shareMaterials,
    },
  }),
  updateSharedProjectSuccess: (projectId, shareData) => ({
    type: types.UPDATE_SHARED_PROJECT_SUCCESS,
    payload: { projectId, shareData },
  }),
  updateSharedProjectFailure: (error) => ({
    type: types.UPDATE_SHARED_PROJECT_FAILURE,
    payload: { error },
  }),
  getSharedProjectRequest: (shareId) => ({
    type: types.GET_SHARED_PROJECT_REQUEST,
    payload: { shareId },
  }),
  getSharedProjectSuccess: (sharedProject) => ({
    type: types.GET_SHARED_PROJECT_SUCCESS,
    payload: { sharedProject },
  }),
  getSharedProjectFailure: (error) => ({
    type: types.GET_SHARED_PROJECT_FAILURE,
    payload: { error },
  }),
  importSharedProjectRequest: (sharedProject, importOptions) => ({
    type: types.IMPORT_SHARED_PROJECT_REQUEST,
    payload: { sharedProject, importOptions },
  }),
  importSharedProjectSuccess: (project) => ({
    type: types.IMPORT_SHARED_PROJECT_SUCCESS,
    payload: { project },
  }),
  importSharedProjectFailure: (error) => ({
    type: types.IMPORT_SHARED_PROJECT_FAILURE,
    payload: { error },
  }),
  getSharedProjectThumbnailRequest: (shareId) => ({
    type: types.GET_SHARED_PROJECT_THUMBNAIL_REQUEST,
    payload: { shareId },
  }),
  getSharedProjectThumbnailSuccess: (thumbnailDataUrl) => ({
    type: types.GET_SHARED_PROJECT_THUMBNAIL_SUCCESS,
    payload: { thumbnailDataUrl },
  }),
  getSharedProjectThumbnailFailure: (error) => ({
    type: types.GET_SHARED_PROJECT_THUMBNAIL_FAILURE,
    payload: { error },
  }),
  getSharedProjectModelsRequest: (shareId, models, colors = null) => ({
    type: types.GET_SHARED_PROJECT_MODELS_REQUEST,
    payload: { shareId, models, colors },
  }),
  getSharedProjectModelsSuccess: (models) => ({
    type: types.GET_SHARED_PROJECT_MODELS_SUCCESS,
    payload: { models },
  }),
  getSharedProjectModelsFailure: (error) => ({
    type: types.GET_SHARED_PROJECT_MODELS_FAILURE,
    payload: { error },
  }),

  // Supports
  saveModelCustomSupportsRequest: (supportsDataPerModel) => ({
    type: types.SAVE_MODEL_CUSTOM_SUPPORTS_REQUEST,
    payload: { supportsDataPerModel },
  }),
  saveModelCustomSupportsSuccess: (supportsDataPerModel) => ({
    type: types.SAVE_MODEL_CUSTOM_SUPPORTS_SUCCESS,
    payload: { supportsDataPerModel },
  }),
  saveModelCustomSupportsFailure: (error) => ({
    type: types.SAVE_MODEL_CUSTOM_SUPPORTS_FAILURE,
    payload: { error },
  }),

  // Integrations
  getPolarPrintersRequest: () => ({
    type: types.GET_POLAR_PRINTERS_REQUEST,
    payload: {},
  }),
  getPolarPrintersSuccess: (printers) => ({
    type: types.GET_POLAR_PRINTERS_SUCCESS,
    payload: { printers },
  }),
  getPolarPrintersFailure: (error) => ({
    type: types.GET_POLAR_PRINTERS_FAILURE,
    payload: { error },
  }),
  dispatchPrintToPolarRequest: (projectId, polarPrinterSerialNumber) => ({
    type: types.DISPATCH_PRINT_TO_POLAR_REQUEST,
    payload: { projectId, polarPrinterSerialNumber },
  }),
  dispatchPrintToPolarSuccess: () => ({
    type: types.DISPATCH_PRINT_TO_POLAR_SUCCESS,
    payload: {},
  }),
  dispatchPrintToPolarFailure: (error) => ({
    type: types.DISPATCH_PRINT_TO_POLAR_FAILURE,
    payload: { error },
  }),
  dispatchPrintToDeviceRequest: (projectId, deviceId) => ({
    type: types.DISPATCH_PRINT_TO_DEVICE_REQUEST,
    payload: { projectId, deviceId },
  }),
  dispatchPrintToDeviceSuccess: () => ({
    type: types.DISPATCH_PRINT_TO_DEVICE_SUCCESS,
    payload: {},
  }),
  dispatchPrintToDeviceFailure: (error) => ({
    type: types.DISPATCH_PRINT_TO_DEVICE_FAILURE,
    payload: { error },
  }),
};

export default actions;

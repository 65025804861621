import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { hot } from 'react-hot-loader/root';

import { getTheme } from '../themes';
import { BrowserWarning, ToastManager } from '../shared';
import { Container } from './router.styles';
import {
  MonitoringUtils,
  NotificationUtils,
  ProjectUtils,
  ResponsiveUtils,
} from '../utils';

// routes and routing
import SoftRedirectRoute from './softRedirectRoute.jsx';
import Loader from './loader.jsx';
import Routes from './routes';

// route target components
import Onboarding from '../views/onboarding/onboarding.container';
import Home from '../views/home/home.container';
import AccountManager from '../views/accountManager/accountManager.container';
import NotFound from '../views/notFound/notFound.jsx';
import ErrorPage from '../views/errorPage/errorPage.jsx';
import TermsOfService from '../views/legal/termsOfService.jsx';
import PrivacyPolicy from '../views/legal/privacyPolicy.jsx';
import PrinterScript from '../views/printerScript/printerScript.jsx';
import PrinterScriptDocs from '../views/printerScript/docs/docs.jsx';
import PrinterScriptPlayground from '../views/printerScript/playground/playground.jsx';
import PrinterScriptVars from '../views/printerScript/variables/variables.jsx';
import PrinterScriptLegacy from '../views/printerScript/legacy/legacy.jsx';
import PrinterManager from '../views/printerManager/printerManager.container';
import MaterialManager from '../views/materialManager/materialManager.container';
import Slicer from '../views/slicer/slicer.container';
import ViewPrinter from '../views/printerManager/viewPrinter/viewPrinter.container';
import ProjectManager from '../views/projectManager/projectManager.container';
import ImportSharedProject from '../views/import/importProject/importProject.container';
import ImportSharedPrinter from '../views/import/importPrinter/importPrinter.container';
import UserAuthorization from '../views/auth/userAuthorization.container';
import DeviceManager from '../views/deviceManager/deviceManager.container';
import DeviceActivation from '../views/deviceManager/deviceActivation/deviceActivation.container';
import ViewDevice from '../views/deviceManager/viewDevice/viewDevice.container';

class RouterApp extends Component {
  constructor(props) {
    super(props);
    const theme =
      props.theme === 'os'
        ? ResponsiveUtils.getUserOSThemePreference()
        : props.theme;
    this.state = {
      hasError: false,
      userOSThemePreference: theme, // either 'light' or 'dark'
    };
    this.mediaQueryMatch = window.matchMedia('(prefers-color-scheme: dark)');
    this.onPrefersColorSchemeChange =
      this.onPrefersColorSchemeChange.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    const { componentStack } = errorInfo;
    const captureContext = { contexts: { react: { componentStack } } };
    MonitoringUtils.sentryManualReport(error, false, captureContext);
  }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      // todo: remove (doesn't work on Firefox)
      NotificationUtils.requestNativePermission();

      if (this.props.theme === 'os') {
        // listen for any changes to user's OS theme
        this.mediaQueryMatch.addListener(this.onPrefersColorSchemeChange);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.theme !== 'os' && this.props.theme === 'os') {
      // change preference to user OS theme
      this.setState({
        userOSThemePreference: ResponsiveUtils.getUserOSThemePreference(),
      });
      // listen for any changes to user's OS theme
      this.mediaQueryMatch.addListener(this.onPrefersColorSchemeChange);
    }

    if (prevProps.theme === 'os' && this.props.theme !== 'os') {
      // remove listener for changes to user's OS theme
      this.mediaQueryMatch.removeListener(this.onPrefersColorSchemeChange);
    }
  }

  onPrefersColorSchemeChange(e) {
    this.setState(
      { userOSThemePreference: e.matches ? 'dark' : 'light' },
      () => {
        // this callback is a hacky way to update the print bed color
        // if a user changes their OS theme pref while in the slicer
        // todo: move this logic into the slicer, or the THREE saga
        const { currentProject, printers, projects } = this.props;
        if (currentProject) {
          const currentPrinter = ProjectUtils.getProjectPrinter(
            projects[currentProject],
            printers
          );
          this.props.updatePrintBed(currentPrinter);
        }
      }
    );
  }

  renderPrivateRoute(path, component, exact = true) {
    return (
      <SoftRedirectRoute
        exact={exact}
        path={path}
        shouldRedirect={this.props.isAuthenticated}
        updateNavStack={this.props.updateNavStack}
        redirectComponent={Home}
        component={component}
      />
    );
  }

  renderPublicRoute(path, ComponentToRender, exact = true) {
    return (
      <Route
        exact={exact}
        path={path}
        render={(props) => (
          <ComponentToRender
            updateNavStack={this.props.updateNavStack}
            {...props}
          />
        )}
      />
    );
  }

  renderCustomPublicPrivateRoute(
    path,
    PrivateComponentToRender,
    PublicComponentToRender,
    exact = true
  ) {
    if (this.props.isAuthenticated) {
      return this.renderPrivateRoute(path, PrivateComponentToRender, exact);
    }
    return this.renderPublicRoute(path, PublicComponentToRender, exact);
  }

  renderNotFoundRoute(component) {
    return <Route component={component} />;
  }

  onErrorAcknowledge() {
    this.setState({ hasError: false });
  }

  renderErrorRoute() {
    return (
      <Route
        render={(props) => (
          <ErrorPage
            onErrorAcknowledge={() => this.onErrorAcknowledge()}
            {...props}
          />
        )}
      />
    );
  }

  renderRouter() {
    if (this.state.hasError) {
      return this.renderErrorRoute();
    }
    return (
      <Switch>
        {/* Public/account views */}
        {this.renderPublicRoute(Routes.toHome(), Home)}
        {this.renderPrivateRoute(Routes.toManageAccounts(), AccountManager)}
        {this.renderPublicRoute(Routes.toTermsOfService(), TermsOfService)}
        {this.renderPublicRoute(Routes.toPrivacyPolicy(), PrivacyPolicy)}

        {/* PrinterScript views */}
        {this.renderPrivateRoute(Routes.toPrinterScript(), PrinterScript)}
        {this.renderPrivateRoute(
          Routes.toPrinterScriptDocs(),
          PrinterScriptDocs
        )}
        {this.renderPrivateRoute(
          Routes.toPrinterScriptVariables(),
          PrinterScriptVars
        )}
        {this.renderPrivateRoute(
          Routes.toPrinterScriptPlayground(),
          PrinterScriptPlayground
        )}
        {this.renderPrivateRoute(
          Routes.toPrinterScriptLegacy(),
          PrinterScriptLegacy
        )}

        {/* Authorization views */}
        {this.renderPrivateRoute(
          Routes.toUserAuthorization(),
          UserAuthorization
        )}

        {/* Project views */}
        {this.renderPrivateRoute(Routes.toManageProjects(), ProjectManager)}
        {this.renderPrivateRoute(Routes.toFolder(), ProjectManager)}
        {this.renderPrivateRoute(Routes.toSlicer(), Slicer, false)}
        {this.renderCustomPublicPrivateRoute(
          Routes.toImportSharedProject(),
          ProjectManager,
          ImportSharedProject
        )}

        {/* Printer views */}
        {this.renderPrivateRoute(Routes.toManagePrinters(), PrinterManager)}
        {this.renderPrivateRoute(Routes.toViewPrinter(), ViewPrinter)}
        {this.renderCustomPublicPrivateRoute(
          Routes.toImportSharedPrinter(),
          PrinterManager,
          ImportSharedPrinter
        )}

        {/* Material views */}
        {this.renderPrivateRoute(Routes.toManageMaterials(), MaterialManager)}

        {/* Device views */}
        {this.renderPrivateRoute(Routes.toDeviceActivation(), DeviceActivation)}
        {this.renderPrivateRoute(Routes.toManageDevices(), DeviceManager)}
        {this.renderPrivateRoute(Routes.toViewDevice(), ViewDevice)}

        {/* 404 view */}
        {this.renderNotFoundRoute(NotFound)}
      </Switch>
    );
  }

  render() {
    // if user has theme set to 'os', set the preference to their OS's theme
    // otherwise use their saved theme
    const themePreference =
      this.props.theme === 'os'
        ? this.state.userOSThemePreference
        : this.props.theme;
    const theme = getTheme(themePreference);
    return (
      <ThemeProvider theme={theme}>
        <Router>
          <Container>
            <Loader />
            <BrowserWarning />
            <ToastManager />
            {this.props.isAuthenticated ? <Onboarding /> : null}
            {this.renderRouter()}
          </Container>
        </Router>
      </ThemeProvider>
    );
  }
}

export default process.env.NODE_ENV === 'development'
  ? hot(RouterApp)
  : RouterApp;

import { connect } from 'react-redux';
import _ from 'lodash';

import { actions } from '../../reducers/slicer/slicer';
import { actions as authActions } from '../../reducers/auth/auth';
import MaterialManager from './materialManager.jsx';

const mapStateToProps = (state) => ({
  sortDirection: state.auth.preferences.materialsSortDirection,
  materials: _.omitBy(
    state.slicer.materials,
    (material) => material.id === '0'
  ),
  sortBy: {
    mode: state.auth.preferences.materialsSortMode,
    direction: state.auth.preferences.materialsSortDirection,
  },
  getMaterialsSuccess: state.slicer.status.getMaterialsSuccess,
  getMaterialsPending: state.slicer.status.getMaterialsPending,
  deleteMaterialsSuccess: state.slicer.status.deleteMaterialsSuccess,
  deleteMaterialsPending: state.slicer.status.deleteMaterialsPending,
  createSpliceSettingsPending: state.slicer.status.createSpliceSettingsPending,
});

const mapDispatchToProps = (dispatch) => ({
  updatePreferences: (sortMode, sortDirection) =>
    dispatch(
      authActions.editAccountRequest({
        preferences: {
          materialsSortMode: sortMode,
          materialsSortDirection: sortDirection,
        },
      })
    ),
  createMaterial: (material) =>
    dispatch(actions.createMaterialRequest(material)),
  editMaterial: (material) => dispatch(actions.editMaterialRequest(material)),
  deleteMaterials: (materialIds) =>
    dispatch(actions.deleteMaterialsRequest(materialIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaterialManager);

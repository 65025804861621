import React from 'react';

import { Body1 } from '../../../../shared/typography/typography';
import { Image } from '../../../../shared';

const metadata = {
  title: 'View pings and camera feed',
  proceedButtonLabel: 'Continue to Canvas',
  content: (theme) => (
    <>
      <Body1 noSpacing>
        Palette 3 users can now view ping and pong history on Canvas from a
        print, as well as a camera feed when a compatible webcam is connected
        and streaming is enabled.
      </Body1>
      <Image
        alt='View pings and camera feed'
        src={theme.images.onboarding.pingsAndWebcam}
      />
    </>
  ),
};

export default metadata;

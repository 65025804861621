import styled, { keyframes } from 'styled-components';

const shadow = keyframes`
  from { background-color: rgba(0, 0, 0, 0); }
`;

export const ShadowContainer = styled.div`
  position: fixed;
  top: 3.5rem;
  left: 0;
  height: calc(100% - 3.5rem);
  width: 100%;
  background-color: ${(props) => props.theme.colors.overlay};
  animation: ${shadow} 0.25s cubic-bezier(0.4, 0, 0.2, 1);
`;

export const Margin = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Content = styled.div`
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 16.5rem;
  background-color: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  box-shadow: inset 0 6px 4px -4px rgba(0, 0, 0, 0.08),
    inset 0 2px 2px -2px rgba(0, 0, 0, 0.12);
`;

import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import { handleGenericError } from '../common';
import { makeRequest } from './horizon';
import { actions } from '../../reducers/iot/iot';

export default function* cancelPrint(action) {
  const { device } = action.payload;

  try {
    const deviceType = device.type;

    yield call(
      makeRequest,
      device.id,
      deviceType === 'canvas-hub' ? 'printer/cancel' : 'print/cancel',
      {
        method: 'post',
        query: {
          hard: false,
        },
      },
      'cancelPrint'
    );
    yield put(actions.cancelPrintSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import { handleGenericError } from '../common';
import { actions } from '../../reducers/printers/printers';
import { PrinterUtils } from '../../utils';

const formatParsedProfile = (parsedData) => ({
  machineSettings: parsedData.machineSettings,
  styles: parsedData.styles,
  warnings: parsedData.warnings,
});

export default function* parseProfile(action) {
  const { file } = action.payload;
  try {
    const parsedData = yield call(PrinterUtils.parseSlicerProfile, file);
    const formattedPrinter = formatParsedProfile(parsedData);
    yield put(actions.parseProfileSuccess(formattedPrinter));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import 'regenerator-runtime/runtime';
import { call, put, race, select, take } from 'redux-saga/effects';

import { getPaginatedItems } from '../../canvas-api';

import { handleGenericError, getAuthState, getSlicerState } from '../../common';
import { actions, types } from '../../../reducers/slicer/slicer';

export function* waitForGetMaterials() {
  const { status } = yield select(getSlicerState);

  // if data is loaded already, just return true
  if (status.getMaterialsSuccess) return true;

  // make new request, if there isn't one pending already
  if (!status.getMaterialsPending) {
    yield put(actions.getMaterialsRequest());
  }

  // take success or failure and return
  const { success } = yield race({
    success: take(types.GET_MATERIALS_SUCCESS),
    failure: take(types.GET_MATERIALS_FAILURE),
  });

  return !!success;
}

export default function* getMaterials(action) {
  try {
    const { userId } = yield select(getAuthState);
    const materials = yield call(
      getPaginatedItems,
      `materials/user/${userId}`,
      'materials'
    );
    if (materials === null) return;
    yield put(actions.getMaterialsSuccess(materials));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import 'regenerator-runtime/runtime';
import { takeLatest, all } from 'redux-saga/effects';

import { types } from '../../reducers/printers/printers';

// import sagas
import createPrinter from './createPrinter';
import createPrinterStyles from './createPrinterStyles';
import deletePrinters from './deletePrinters';
import deletePrinterStyles from './deletePrinterStyles';
import duplicatePrinter from './duplicatePrinter';
import getPrinterPresets from './getPrinterPresets';
import getPrinters from './getPrinters';
import updatePrinter from './updatePrinter';
import updatePrinterStyles from './updatePrinterStyles';
import parseProfile from './parseProfile';
import createSharedPrinter from './createSharedPrinter';
import deleteSharedPrinter from './deleteSharedPrinter';
import updateSharedPrinter from './updateSharedPrinter';
import getSharedPrinter from './getSharedPrinter';
import importSharedPrinter from './importSharedPrinter';
import queryPrinterTags from './queryPrinterTags';
import createPrinterTag from './createPrinterTag';
import getPrinterTag from './getPrinterTag';
import updateCalibrationSettings from './updateCalibrationSettings';

export default function* printersSaga() {
  yield all([
    takeLatest(types.DUPLICATE_PRINTER_REQUEST, duplicatePrinter),
    takeLatest(types.CREATE_PRINTER_REQUEST, createPrinter),
    takeLatest(types.CREATE_STYLE_PROFILES_REQUEST, createPrinterStyles),
    takeLatest(types.DELETE_PRINTERS_REQUEST, deletePrinters),
    takeLatest(types.DELETE_STYLES_REQUEST, deletePrinterStyles),
    takeLatest(types.GET_PRINTER_PRESETS_REQUEST, getPrinterPresets),
    takeLatest(types.GET_PRINTERS_REQUEST, getPrinters),
    takeLatest(types.UPDATE_PRINTER_REQUEST, updatePrinter),
    takeLatest(types.UPDATE_STYLE_REQUEST, updatePrinterStyles),
    takeLatest(types.PARSE_PROFILE_REQUEST, parseProfile),
    takeLatest(types.CREATE_SHARED_PRINTER_REQUEST, createSharedPrinter),
    takeLatest(types.DELETE_SHARED_PRINTER_REQUEST, deleteSharedPrinter),
    takeLatest(types.UPDATE_SHARED_PRINTER_REQUEST, updateSharedPrinter),
    takeLatest(types.GET_SHARED_PRINTER_REQUEST, getSharedPrinter),
    takeLatest(types.IMPORT_SHARED_PRINTER_REQUEST, importSharedPrinter),
    takeLatest(types.QUERY_PRINTER_TAGS_REQUEST, queryPrinterTags),
    takeLatest(types.CREATE_PRINTER_TAG_REQUEST, createPrinterTag),
    takeLatest(types.GET_PRINTER_TAG_REQUEST, getPrinterTag),
    takeLatest(
      types.UPDATE_CALIBRATION_SETTINGS_REQUEST,
      updateCalibrationSettings
    ),
  ]);
}

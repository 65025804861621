import styled, { css } from 'styled-components';

import { Breakpoints, Shadows } from '../../themes';
import { Body1OneLine, Caption } from '../typography/typography';

export const DefaultContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: ${(props) => (props.minHeight ? props.minHeight : '2.5rem')};
  margin: 0;
  padding: 0;
  justify-content: space-around;
  border-radius: 0.25rem;
  background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  border: 1px solid ${(props) => props.theme.colors.grey3};

  ${(props) =>
    props.borderlessContainer &&
    css`
      background: transparent;
      border: none;
    `};

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        border-color: ${props.theme.colors.greenDefault};
      }
    `};

  ${(props) =>
    props.disabled &&
    css`
      background: ${props.theme.colors.elevatedBackgroundTertiary};
      color: ${props.theme.colors.textTertiary};
    `};

  ${(props) =>
    props.disabled &&
    props.borderlessContainer &&
    css`
      background: transparent;
      border: none;
    `}
  ${(props) =>
    props.showOptions &&
    css`
      border-color: ${props.theme.colors.greenDefault};
    `};
`;

export const DefaultOptions = styled.div`
  ${(props) => `
   position:${props.strategy};
   top:${props.y}px; 
   left:${props.x}px;
   min-width:${`${props.containerWidth}px`};
   max-width:${`${props.maxOptionsWidth}px`};
   `}
  background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  ${Shadows.d2};
  box-sizing: border-box;
  border-radius: 0.25rem;
  user-select: none;
  z-index: 5000;
  @media (max-width: ${Breakpoints.smallWidth}) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
`;

export const DefaultOptionsList = styled.div`
  max-height: ${(props) => props.listHeight};
  overflow-y: auto;
  border-radius: 0.2rem;
  @media (max-height: ${Breakpoints.mediumHeight}) {
    // flip threshold 8rem
    max-height: ${(props) => Math.max(8 * 16, props.maxOptionsHeight)}px;
  }
`;

export const OptionLabel = styled(Body1OneLine)`
  margin-right: 0.5rem;
`;

export const OptionSubLabel = styled(Caption)`
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.textTertiary
      : props.theme.colors.textSecondary};
  margin-top: 0.125rem;
`;

export const DefaultOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const DefaultOption = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  cursor: pointer;
  // padding for the dropdown menu options
  padding: ${(props) =>
    props.isGroupLabel ? '0.5rem' : '0.5rem 1.5rem 0.5rem 0.75rem'};

  ${(props) =>
    props.nowrap &&
    css`
      white-space: nowrap;
    `}

  &:hover {
    background-color: ${(props) =>
      props.theme.colors.elevatedBackgroundSecondary};
  }

  ${(props) =>
    props.isCurrent &&
    css`
      border-color: ${props.theme.colors.greenDefault};
      background-color: ${props.theme.colors.greenDefault};
      ${OptionLabel} {
        color: ${props.theme.colors.whiteDefault};
      }

      &:hover {
        background-color: ${props.theme.colors.greenDefault};
        border-bottom-color: ${props.theme.colors.greenDefault};
      }

      & ${OptionSubLabel} {
        color: ${props.theme.colors.whiteDefault};
      }
    `}

  ${(props) =>
    props.isGroupLabel &&
    css`
      cursor: auto;
      border-bottom: ${props.groupUnderline
        ? `1px solid ${props.theme.colors.grey3}`
        : 'none'};

      ${OptionLabel} {
        font-weight: ${props.theme.fonts.weightBold};
        color: ${props.theme.colors.textSecondary};
      }
      &:hover {
        background: ${props.theme.colors.elevatedBackgroundPrimary};
      }
    `}

  ${(props) =>
    props.hideLabel &&
    css`
      padding: 0;
    `}

  ${(props) =>
    props.disabled &&
    css`
      ${OptionLabel} {
        color: ${props.theme.colors.textTertiary};
      }
      background: ${props.theme.colors.elevatedBackgroundTertiary};
      cursor: auto;

      &:hover {
        background: ${props.theme.colors.elevatedBackgroundTertiary};
      }
    `}

  @media (max-width: ${Breakpoints.smallWidth}) {
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
  }

  ${(props) =>
    props.highlightLabel &&
    css`
      ${OptionLabel} {
        color: ${props.theme.colors.greenDefault};
        font-weight: ${props.theme.fonts.weightBold};
      }
    `};
`;

export const DefaultSelect = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  // changes text color for the selected option only (NOT dropdown menu options)
  color: ${(props) => {
    if (props.disabled) return props.theme.colors.textTertiary;
    if (props.grey) return props.theme.colors.textSecondary;
    return props.theme.colors.textPrimary;
  }};
  //padding for the selected option only (NOT dropdown menu options)
  padding: ${(props) => {
    if (props.hasLabel) return '1.5125rem 0.625rem 0.5125rem 0.625rem';
    if (props.noPadding) return '0';
    return '0.45rem 0.5rem 0.45rem 0.625rem';
  }};

  ${(props) =>
    props.hasLabel &&
    css`
      align-items: flex-end;
    `}

  ${(props) =>
    props.borderlessSelect &&
    css`
      padding: 0;
      &::before {
        content: '${props.text}';
        margin-bottom: 0.2rem;
      }
    `}
`;

export const DefaultSelectedOptionText = styled(Body1OneLine)`
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  color: inherit;
  text-align: left;
  margin: 0 auto 0 0;
  max-width: min-content;

  &:empty {
    padding: 0;
  }
`;

export const DefaultIconContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 0.5rem;
  display: flex;
  ${(props) =>
    props.hasLabel &&
    css`
      position: relative;
      bottom: -0.2rem;
    `}

  ${(props) =>
    props.showOption &&
    css`
      transform: rotate(180deg);
    `};
  }
`;

export const DefaultDescriptionIconContainer = styled.div`
  padding: 0.125rem;
  margin-right: 0.5rem;
  width: min-content;
  ${(props) => props.hide && `display:none;`}
`;

export const DefaultFieldLabelWrapper = styled.div`
  position: absolute;
  text-align: left;
  top: 0.5rem;
  left: 0.625rem;
  right: 0.5rem;
  pointer-events: none;
`;

export const FilterBarContainer = styled.div`
  position: relative;
`;

export const FilterResetIconContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0.25rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  pointer-events: all;
  margin-right: 0.25rem;
`;

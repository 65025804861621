import styled, { css } from 'styled-components';

import { Breakpoints } from '../../../../../../themes';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.overlay};
`;

export const StampPlacerLabelWrapper = styled.div`
  bottom: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: min-content;

  & > p {
    color: ${(props) => props.theme.colors.grey3};
  }

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: none;
  }
`;

export const ResizableStampContainer = styled.div`
  position: absolute;
  width: ${(props) => props.defaultWidth}px;
  height: ${(props) => props.defaultWidth / props.aspectRatio}px;
  left: calc(50% - ${(props) => props.defaultWidth / 2}px);
  top: calc(50% - ${(props) => props.defaultWidth / props.aspectRatio / 2}px);
  max-width: ${(props) => props.maxWidth}px;
  cursor: move;
  transition: opacity 0.2s;

  ${(props) =>
    props.isOrientingModel &&
    css`
      opacity: 0.3;
    `}

  @media (max-width: ${Breakpoints.mediumWidth}), (max-height: ${Breakpoints.mediumHeight}) {
    width: 300px;
    height: ${(props) => 300 / props.aspectRatio}px;
    left: calc(50% - ${300 / 2}px);
    top: calc(50% - ${(props) => 300 / props.aspectRatio / 2}px);
  }

  @media (max-width: ${Breakpoints.extraSmallWidth}),
    (max-height: ${Breakpoints.extraSmallHeight}) {
    width: 150px;
    height: ${(props) => 150 / props.aspectRatio}px;
    left: calc(50% - ${150 / 2}px);
    top: calc(50% - ${(props) => 150 / props.aspectRatio / 2}px);
  }
`;

export const ResizableStampImage = styled.img`
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid ${(props) => props.theme.colors.yellowDefault};
  box-sizing: border-box;
`;

const resizerHandle = css`
  width: 10px;
  height: 10px;
  border-radius: 50%; /*magic to turn square into circle*/
  background: white;
  border: 2px solid ${(props) => props.theme.colors.yellowDefault};
  position: absolute;

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    width: calc(10px * 2);
    height: calc(10px * 2);
  }
`;

export const ResizerTopLeft = styled.div`
  ${resizerHandle};
  left: -5px;
  top: -5px;
  cursor: nwse-resize; /*resizer cursor*/

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    left: calc(-5px * 2);
    top: calc(-5px * 2);
  }
`;

export const ResizerTopRight = styled.div`
  ${resizerHandle};
  right: -5px;
  top: -5px;
  cursor: nesw-resize;

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    right: calc(-5px * 2);
    top: calc(-5px * 2);
  }
`;

export const ResizerBottomLeft = styled.div`
  ${resizerHandle};
  left: -5px;
  bottom: -5px;
  cursor: nesw-resize;

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    left: calc(-5px * 2);
    bottom: calc(-5px * 2);
  }
`;

export const ResizerBottomRight = styled.div`
  ${resizerHandle};
  right: -5px;
  bottom: -5px;
  cursor: nwse-resize;

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    right: calc(-5px * 2);
    bottom: calc(-5px * 2);
  }
`;

export const RotateHandle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%; /*magic to turn square into circle*/
  background: white;
  border: 2px solid ${(props) => props.theme.colors.yellowDefault};
  position: absolute;
  top: -40px;
  left: calc(50% - 5px);
  cursor: grab;

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 28px;
    background: ${(props) => props.theme.colors.yellowDefault};
    width: 2px;
    left: calc(50% - 1px);
    top: 10px;
  }

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    width: calc(10px * 2);
    height: calc(10px * 2);
    left: calc(50% - 5px * 2);

    &::after {
      height: 16px;
      top: 22px;
    }
  }
`;

const viewButtonStyles = css`
  position: absolute;
  display: flex;

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: none;
  }
`;

export const PlaceStampButtonWrapper = styled.div`
  ${viewButtonStyles};
  top: 7.5em;
  right: 1em;
`;

export const CancelStampButtonWrapper = styled.div`
  ${viewButtonStyles};
  top: 10.5em;
  right: 1em;
`;

export const MobileButtonsContainer = styled.div`
  position: absolute;
  bottom: 1em;
  right: 1em;
  display: flex;
`;

import _ from 'lodash';
import { END, eventChannel } from 'redux-saga';
import { all, call, put, select, take } from 'redux-saga/effects';

import { getPrinterState, getSlicerState } from '../../common';
import API, { CanvasApiError, methods } from '../../canvas-api';
import { actions } from '../../../reducers/slicer/slicer';
import Constants from '../../constants';
import {
  FileUtils,
  InterfaceUtils,
  MatrixUtils,
  ProjectUtils,
  SceneUtils,
  SlicerUtils,
  TreeUtils,
} from '../../../utils';
import ProgressTracker from '../../../classes/UploadProgressTracker';

// event channel message types
const types = {
  PROGRESS: 'PROGRESS',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const uploadModelPart = async (url, slice, handleProgress, xhrs) =>
  new Promise((fulfill, reject) => {
    const request = new XMLHttpRequest();
    xhrs.push(request);
    const onFailure = (err) => {
      reject(err);
    };
    const onReadyStateChange = () => {
      if (request.readyState === XMLHttpRequest.DONE) {
        if (request.status >= 200 && request.status <= 299) {
          try {
            const eTag = request.getResponseHeader('ETag').slice(1, -1); // remove leading and trailing quotes
            fulfill(eTag);
          } catch (err) {
            reject(new Error(Constants.UPLOAD_ERROR_MESSAGE));
          }
        } else if (request.status === 504) {
          const err = {
            status: request.status,
            message: Constants.TIMEOUT_ERROR_MESSAGE,
          };
          reject(new CanvasApiError(err));
        } else {
          let message;
          try {
            message = JSON.parse(request.responseText);
          } catch (err) {
            message = Constants.UPLOAD_ERROR_MESSAGE;
          }
          reject(new Error(message));
        }
      }
    };
    const onProgress = (event) => {
      if (event.lengthComputable) {
        handleProgress(event.loaded);
      }
    };
    request.addEventListener('timeout', onFailure);
    request.addEventListener('error', onFailure);
    request.addEventListener('readystatechange', onReadyStateChange);
    request.upload.addEventListener('progress', onProgress);

    request.open(methods.PUT, url);
    request.send(slice);
  });

const uploadModelParts = async (modelId, content, urls, progress, xhrs) => {
  // split content to upload each part in parallel
  const slices = [];
  let startIdx = 0;
  for (let i = 0; i < urls.length; i++) {
    const { contentLength } = urls[i];
    slices.push(new DataView(content, startIdx, contentLength));
    startIdx += contentLength;
  }

  return Promise.all(
    _.map(slices, async (slice, idx) => {
      const { url } = urls[idx];
      const handleProgress = (loaded) => {
        progress.updateProgress(modelId, idx, loaded);
      };
      return uploadModelPart(url, slice, handleProgress, xhrs);
    })
  );
};

const getUploadChannel = (projectId, fileContents, initiateResponse) =>
  eventChannel((emit) => {
    // prepare to receive combined upload progress
    const perModelPartSizes = _.map(initiateResponse.models, (model) => ({
      id: model.id,
      partSizes: _.map(model.urls, (partData) => partData.contentLength),
    }));
    const onProgress = (progress) => {
      emit({
        type: types.PROGRESS,
        value: progress,
      });
    };
    const progress = new ProgressTracker(perModelPartSizes, onProgress);

    // collect XHR instances here so they can all be aborted as needed
    const xhrs = [];

    const perModelETags = {};
    Promise.all(
      _.map(initiateResponse.models, async (modelResponse, idx) => {
        const { uploadId, urls, ...model } = modelResponse;
        perModelETags[model.id] = await uploadModelParts(
          model.id,
          fileContents[idx],
          urls,
          progress,
          xhrs
        );
      })
    )
      .then(() => {
        emit({
          type: types.SUCCESS,
          value: perModelETags,
        });
        emit(END);
      })
      .catch((err) => {
        emit({
          type: types.ERROR,
          value: err,
        });
        emit(END);
      });

    return () => {
      _.forEach(xhrs, (xhr) => xhr.abort());
    };
  });

function* handleUploadSuccess(
  projectId,
  files,
  isGroup,
  newModels,
  updatedTower
) {
  const {
    projects,
    models: existingModels,
    transitionTower: currentTower,
  } = yield select(getSlicerState);
  const { printers } = yield select(getPrinterState);
  const project = projects[projectId];
  const printer = ProjectUtils.getProjectPrinter(project, printers);

  // create meshes using STL loader
  const meshes = yield call(SlicerUtils.createMeshes, files, project.colors);
  const newModelTree = TreeUtils.formatModelTree(newModels);
  for (let i = 0; i < newModels.length; i++) {
    const modelId = newModels[i].id;
    const mesh = meshes[i];
    mesh.name = modelId;
    const model = TreeUtils.searchById(modelId, newModelTree);
    model.mesh = mesh;
    model.material = mesh.material;
  }

  // move model/group to center of bed
  const boundingBox = SlicerUtils.getCombinedBoundingBox(
    TreeUtils.flattenDeep(newModelTree)
  );
  const zOffset = SlicerUtils.offsetMeshesZ(meshes);
  const { bedSize, originOffset } = printer.machineSettings;
  const bedMiddle = SceneUtils.getBedMiddle(bedSize, originOffset);
  const meshesMiddle = {
    x: (boundingBox.max.x + boundingBox.min.x) / 2,
    y: (boundingBox.max.y + boundingBox.min.y) / 2,
  };
  const xOffset = bedMiddle.x - meshesMiddle.x;
  const yOffset = bedMiddle.y - meshesMiddle.y;
  // drop model/group to bed permanently
  let updateTransforms = false;
  let transformData;
  if (xOffset !== 0 || yOffset !== 0 || zOffset !== 0) {
    const reducer = (acc, item) => {
      let node = item;
      if (node.type === 'group') {
        return acc.concat(TreeUtils.reduce(node.children, reducer, []));
      }
      if (_.includes(meshes, node.mesh)) {
        node.transforms.translate = {
          x: xOffset,
          y: yOffset,
          z: zOffset,
        };
        MatrixUtils.updateMeshTransforms(node.mesh, node.transforms);
        node = _.pick(node, ['id', 'transforms']);
        acc.push(node);
      }
      return acc;
    };
    // wait for success action to add new models to store,
    // before updating transforms for these new models
    transformData = TreeUtils.reduce(newModelTree, reducer, []);
    updateTransforms = true;
  }
  yield put(actions.uploadStlSuccess(newModelTree));
  if (updateTransforms) {
    yield put(actions.updateModelTransformsRequest(transformData));
  }

  yield put(actions.invalidateSlice(projectId));
  yield put(actions.updateTowerFromServer(projectId, updatedTower));
  if (
    !_.isEmpty(existingModels) || // models already exist in project, or
    (!isGroup && newModels.length > 1) || // multiple ungrouped models uploaded, or
    (!currentTower && updatedTower) // transition tower was created
  ) {
    yield put(actions.repackRequest());
  }
  // update thumbnail
  yield put(actions.updateProjectThumbnailRequest(projectId));
}

function* handleUploadError(error) {
  yield put(actions.uploadStlFailure());
  const message = error.message
    ? `Upload error: ${error.message}`
    : Constants.UPLOAD_ERROR_MESSAGE;
  InterfaceUtils.emitToast('error', message);
}

export default function* uploadStl(action) {
  const { files, isGroup } = action.payload;
  let projectId;
  let fileContents;
  let initiateResponse;
  try {
    projectId = (yield select(getSlicerState)).currentProject;

    // load STLs now, to account for true file sizes
    // in case of ASCII-to-binary conversion
    fileContents = yield all(
      _.map(files, (file) => call(FileUtils.loadStl, file))
    );

    // begin a multi-part upload for each model
    const initiateBody = {
      models: _.map(files, (file, idx) => ({
        name: file.name,
        contentLength: fileContents[idx].byteLength,
      })),
      group: isGroup,
    };
    initiateResponse = yield call(API, {
      method: methods.PUT,
      path: `projects/${projectId}/models`,
      body: initiateBody,
    });
    if (initiateResponse === null) return;
  } catch (err) {
    yield call(handleUploadError, err);
    return;
  }

  // for each model, upload all parts in parallel
  // and use a channel to receive progress updates
  const channel = getUploadChannel(projectId, fileContents, initiateResponse);
  let perModelETags = {};
  let error = false;
  try {
    while (true) {
      const { type, value } = yield take(channel);
      if (type === types.PROGRESS) {
        yield put(actions.updateUploadProgress(value));
      } else if (type === types.SUCCESS) {
        perModelETags = value;
      } else if (type === types.ERROR) {
        yield call(handleUploadError, value);
        error = true;
      }
    }
  } finally {
    if (!error) {
      yield put(actions.updateUploadProgress(100));
      // now finalize all uploads
      try {
        let updatedTower = null;
        const newModels = yield all(
          _.map(
            initiateResponse.models,
            function* finalizeModelUpload(modelResponse) {
              const { uploadId, urls, ...model } = modelResponse;
              const eTags = perModelETags[model.id];
              const body = {
                model,
                uploadId,
                parts: _.map(eTags, (eTag, idx) => ({
                  partNumber: urls[idx].partNumber,
                  eTag,
                })),
              };
              const response = yield call(API, {
                method: methods.PUT,
                path: `projects/${projectId}/models/${model.id}`,
                body,
              });
              if (response === null) return null;
              if (_.has(response, 'transitionTower')) {
                // all newly-updated models use extruder 0, so we can guarantee that these
                // parallel API calls produce an idempotent tower update
                // (so the towers in all responses should be the same each time)
                updatedTower = response.transitionTower;
              }
              return response.model;
            }
          )
        );

        if (_.every(newModels, (model) => model !== null)) {
          yield call(
            handleUploadSuccess,
            projectId,
            files,
            isGroup,
            newModels,
            updatedTower
          );
        }
      } catch (err) {
        yield call(handleUploadError, err);
      }
    }
  }
}

import {
  ProjectSortDefault,
  PrinterSortDefault,
  StyleSortDefault,
  MaterialSortDefault,
  DeviceSortDefault,
  FileSortDefault,
} from '../../utils/sort/sort';
import { defaultColors } from '../slicer/initialState';
import { slicers } from '../../constants';

const initialState = {
  autoLogout: false,
  username: '',
  userId: '',
  userEmail: '',
  usernameOrEmail: '',
  token: '',
  refreshToken: '',
  loginHistory: [],
  viewOptions: {
    gridLines: false,
    boundingBox: false,
    originAxes: false,
    orthographic: false,
    percentScale: false,
  },
  newProjectOptions: {
    defaultSlicer: slicers.KISSlicer,
    defaultStyle: 'auto',
    defaultPrinter: 'auto',
    defaultMaterial: '0',
    defaultColors,
    showDialog: false,
    defaultDeviceConfig: null,
  },
  preferences: {
    projectsListView: false,
    projectsSortMode: ProjectSortDefault.mode,
    projectsSortDirection: ProjectSortDefault.direction,
    printersSortMode: PrinterSortDefault.mode,
    printersSortDirection: PrinterSortDefault.direction,
    stylesSortMode: StyleSortDefault.mode,
    stylesSortDirection: StyleSortDefault.direction,
    materialsSortMode: MaterialSortDefault.mode,
    materialsSortDirection: MaterialSortDefault.direction,
    slicerMaterialsListView: false,
    devicesSortMode: DeviceSortDefault.mode,
    devicesSortDirection: DeviceSortDefault.direction,
    deviceFilesSortMode: FileSortDefault.mode,
    deviceFilesSortDirection: FileSortDefault.direction,
    theme: 'light',
    includeThumbnailInZip: false,
    preferUnzipped: false,
  },
  userIntegrations: null,
  allIntegrations: null,
  status: {
    signupPending: false,
    signupSuccess: false,
    loginPending: false,
    loginSuccess: false,
    logoutAllSessionsPending: false,
    logoutAllSessionsSuccess: false,
    refreshUserTokenPending: false,
    refreshUserTokenSuccess: false,
    deleteAccountPending: false,
    deleteAccountSuccess: false,
    editAccountPending: false,
    editAccountSuccess: false,
    getAccountPending: false,
    getAccountSuccess: false,
    requestPasswordResetPending: false,
    requestPasswordResetSuccess: false,
    resetPasswordPending: false,
    resetPasswordSuccess: false,
    authorizeIntegrationPending: false,
    authorizeIntegrationSuccess: false,
    linkIntegrationPending: false,
    linkIntegrationSuccess: false,
    unlinkIntegrationPending: false,
    unlinkIntegrationSuccess: false,
    getIntegrationsPending: false,
    getIntegrationsSuccess: false,
  },
};

export default initialState;

import _ from 'lodash';

import { defaultToolpath } from './initialState';

import { SlicerUtils, TreeUtils, MatrixUtils } from '../../utils';

const Helpers = {
  invalidateSlice: (project) => ({
    ...project,
    sliced: false,
    stats: {},
    toolpath: defaultToolpath,
  }),
  deleteKeyFromMap: (map, key) => _.omit(map, key),
  updateProjectInMap: (existingProjects, project) => ({
    ...existingProjects,
    [project.id]: project,
  }),
  invalidateAndUpdateProjectInMap: (existingProjects, project) => {
    const invalidatedProject = Helpers.invalidateSlice(project);
    return Helpers.updateProjectInMap(existingProjects, invalidatedProject);
  },
  updateModelTransforms: (models, transformData, updateMeshes = false) =>
    TreeUtils.map(models, (model) => {
      const modelTransformUpdates = _.find(
        transformData,
        (modelData) => modelData.id === model.id
      );
      if (modelTransformUpdates) {
        const updatedModel = {
          ...model,
          transforms: {
            ...model.transforms,
            ...modelTransformUpdates.transforms,
          },
        };
        if (updateMeshes) {
          MatrixUtils.updateMeshTransforms(
            updatedModel.mesh,
            updatedModel.transforms
          );
        }
        return updatedModel;
      }
      return model;
    }),
  disposeModels: (models) => {
    TreeUtils.map(models, (model) => {
      // dispose of stamps if any
      const stampsToDelete = [...model.mesh.children];
      stampsToDelete.forEach((stampGroup) => {
        SlicerUtils.removeStampGroup(stampGroup, model.mesh);
      });

      // dispose of model mesh
      model.mesh.geometry.dispose();
      model.mesh.material.dispose();
    });
  },
  disposeTower: (tower) => {
    if (tower && tower.mesh) {
      tower.mesh.geometry.dispose();
      tower.mesh.material.dispose();
    }
  },
  addTowerToScene: (tower) => {
    if (tower && tower.visible) {
      // eslint-disable-next-line no-param-reassign
      tower.mesh.visible = true;
    }
  },
  hideModelsFromScene: (models) => {
    TreeUtils.map(models, (item) => {
      const model = item;
      model.active = false;
      model.mesh.visible = false;
    });
  },
  hideTowerFromScene: (tower) => {
    if (tower && tower.visible) {
      // eslint-disable-next-line no-param-reassign
      tower.mesh.visible = false;
    }
  },
  formatTowerResponse: (tower) => {
    if (!tower) return null;
    return {
      ...tower,
      position: {
        x: tower.position[0],
        y: tower.position[1],
        z: 0.5,
      },
      size: {
        x: tower.size[0],
        y: tower.size[1],
        z: 1,
      },
      brims: tower.brims
        ? {
            ...tower.brims,
            size: {
              x: tower.brims.size[0],
              y: tower.brims.size[1],
              z: 1,
            },
          }
        : undefined,
    };
  },
  updateTower: (state, newTower) => {
    const existingTower = state.transitionTower;
    if (!newTower) {
      return null;
    }
    const visible = existingTower ? existingTower.visible : true;
    const active = existingTower ? existingTower.active : false;
    let formattedTower = newTower;
    if (Array.isArray(newTower.position)) {
      formattedTower = Helpers.formatTowerResponse(newTower);
    }
    const updatedTower = {
      ...existingTower,
      ...formattedTower,
      visible,
      active,
    };
    const mesh = SlicerUtils.createTowerMesh(updatedTower);
    mesh.visible = !state.allModelsHidden && visible;
    return { ...updatedTower, mesh };
  },
  toggleTowerVisibility: (tower) => {
    const visible = !tower.visible;
    // eslint-disable-next-line no-param-reassign
    tower.mesh.visible = visible;
    return { ...tower, visible };
  },
  toggleTowerActiveState: (tower) => {
    const active = !tower.active;
    // eslint-disable-next-line no-param-reassign
    tower.mesh.material.uniforms.highlighted.value = active;
    return { ...tower, active };
  },
  activateTower: (tower) => {
    if (!tower) return null;
    // eslint-disable-next-line no-param-reassign
    tower.mesh.material.uniforms.highlighted.value = true;
    return { ...tower, active: true };
  },
  deactivateTower: (tower) => {
    if (!tower) return null;
    // eslint-disable-next-line no-param-reassign
    tower.mesh.material.uniforms.highlighted.value = false;
    return { ...tower, active: false };
  },
  activateModels: (models, modelIds) =>
    TreeUtils.map(models, (model) => {
      if (_.includes(modelIds, model.id)) {
        // eslint-disable-next-line no-param-reassign
        model.mesh.material.uniforms.highlighted.value = true;
        // update stamp highlights as well
        model.mesh.children.forEach((stampGroup) => {
          const [stampMesh] = stampGroup.children;
          stampMesh.material.uniforms.highlighted.value = true;
        });
        return { ...model, active: true };
      }
      return model;
    }),
  deactivateAllModels: (models) =>
    TreeUtils.map(models, (model) => {
      // eslint-disable-next-line no-param-reassign
      model.mesh.material.uniforms.highlighted.value = false;
      // update stamp highlights as well
      model.mesh.children.forEach((stampGroup) => {
        const [stampMesh] = stampGroup.children;
        stampMesh.material.uniforms.highlighted.value = false;
      });
      return { ...model, isNew: false, active: false };
    }),
  toggleModelActiveState: (models, modelId) =>
    TreeUtils.map(models, (model) => {
      if (model.id === modelId) {
        const updatedModel = { ...model, active: !model.active };
        if (updatedModel.mesh) {
          const highlighted = updatedModel.visible && updatedModel.active;
          updatedModel.mesh.material.uniforms.highlighted.value = highlighted;
          // update stamp highlights as well
          updatedModel.mesh.children.forEach((stampGroup) => {
            const [stampMesh] = stampGroup.children;
            stampMesh.material.uniforms.highlighted.value = highlighted;
          });
        }
        return updatedModel;
      }
      return model;
    }),
  setModelVisibility: (models, modelId, visible) =>
    TreeUtils.map(models, (model) => {
      if (model.id === modelId) {
        const updatedModel = { ...model, visible };
        if (updatedModel.mesh) {
          updatedModel.mesh.visible = updatedModel.visible;
        }
        return updatedModel;
      }
      return model;
    }),
  selectMaterial: (materials, materialId) =>
    _.mapValues(materials, (item) => {
      const material = item;
      material.active = material.id === materialId;
      return material;
    }),
  deleteModel: (models, modelId) => TreeUtils.removeById(modelId, models),
  deleteModels: (models, modelIds) =>
    _.reduce(
      modelIds,
      (acc, modelId) => TreeUtils.removeById(modelId, acc),
      models
    ),
  updateModelName: (models, modelId, newModelName) => {
    const unsorted = TreeUtils.map(models, (model) => {
      if (model.id === modelId) {
        return {
          ...model,
          name: newModelName,
        };
      }
      return model;
    });
    const newModelTree = TreeUtils.sort(unsorted);
    return newModelTree;
  },
  updateModelGroupName: (models, oldGroupName, newGroupName) => {
    const unsorted = models
      .filter((model) => model.type === 'group')
      .map((model) => {
        if (model.name === oldGroupName) {
          return {
            ...model,
            name: newGroupName,
          };
        }
        return model;
      });
    const newGroups = TreeUtils.sort(unsorted);
    const otherModels = models.filter((model) => model.type !== 'group');
    return [...newGroups, ...otherModels];
  },
  groupModels: (existingModels, groupedModels, groupName) => {
    const newGroup = Helpers.composeNewGroup(groupName, groupedModels);
    const remainingModels = _.difference(existingModels, groupedModels);
    return TreeUtils.sort([newGroup, ...remainingModels]);
  },
  ungroupModels: (existingModels, ungroupedModels, groupNames) => {
    const remainingModels = _.filter(
      existingModels,
      (item) => !groupNames.includes(item.name)
    );
    return TreeUtils.sort([...ungroupedModels, ...remainingModels]);
  },
  transformChildren: (groupedModels, newCenter) =>
    _.map(groupedModels, (item) => {
      const model = item;
      model.transforms = {
        ...model.transforms,
        translate: newCenter,
      };
      MatrixUtils.updateMeshTransforms(model.mesh, model.transforms);
      return model;
    }),
  composeNewGroup: (groupName, childrenNodes) => ({
    name: groupName,
    type: 'group',
    children: childrenNodes,
  }),
  alignAndGroupModels: (
    existingModels,
    groupedModels,
    newCenter,
    groupName
  ) => {
    const transformedChildren = Helpers.transformChildren(
      groupedModels,
      newCenter
    );
    const newGroup = Helpers.composeNewGroup(groupName, transformedChildren);
    const remainingModels = _.difference(existingModels, groupedModels);
    return TreeUtils.sort([newGroup, ...remainingModels]);
  },
  restoreColorsWithRLE: (models, modelId, originalRle) =>
    TreeUtils.map(models, (item) => {
      const model = item;
      if (model.id === modelId) {
        const colorAttr = model.mesh.geometry.attributes.color;
        const colorAttrLength = colorAttr.count / 3;
        const modelExtruder = model.extruder;

        // first replace RLE with original
        model.rle = originalRle;

        if (model.rle) {
          // colored model; set RLE-parsed extruder index
          let numFacesToColor;
          let extruderIndex;
          let currentRunIndex = 0;
          numFacesToColor = model.rle[currentRunIndex];
          extruderIndex = model.rle[currentRunIndex + 1];

          for (let i = 0; i < colorAttrLength; i++) {
            if (numFacesToColor <= 0) {
              currentRunIndex++;
              if (currentRunIndex * 2 <= model.rle.length) {
                numFacesToColor = model.rle[currentRunIndex * 2];
                extruderIndex = model.rle[currentRunIndex * 2 + 1];
              }
            }

            colorAttr.setXYZ(
              i * 3,
              extruderIndex,
              extruderIndex,
              extruderIndex
            );
            numFacesToColor--;
          }
        } else {
          // regular model; set model extruder index
          for (let i = 0; i < colorAttrLength; i++) {
            colorAttr.setXYZ(
              i * 3,
              modelExtruder,
              modelExtruder,
              modelExtruder
            );
          }
        }

        colorAttr.needsUpdate = true;
      }
      return model;
    }),
  applyColorToModels: (models, materialIndex) =>
    TreeUtils.map(models, (model) => {
      // color drag-and-drop
      const colorAttr = model.mesh.geometry.attributes.color;
      const colorAttrLength = colorAttr.count / 3;

      for (let i = 0; i < colorAttrLength; i++) {
        colorAttr.setXYZ(i * 3, materialIndex, materialIndex, materialIndex);
      }
      colorAttr.needsUpdate = true;

      // clear stamps map on model
      model.mesh.remove(...model.mesh.children);
      // eslint-disable-next-line no-param-reassign
      model.stamps = {};
      // eslint-disable-next-line no-param-reassign
      model.textured = false;

      // clear triangle RLE on model
      // eslint-disable-next-line no-param-reassign
      model.rle = null;
      // eslint-disable-next-line no-param-reassign
      model.colored = false;

      return {
        ...model,
        extruder: materialIndex,
      };
    }),
  composeMaterialsMap: (existingMaterials, newMaterials) => {
    const materialsMap = {};
    _.forEach(newMaterials, (item) => {
      materialsMap[item.id] = item;
    });
    return {
      ...existingMaterials,
      ...materialsMap,
    };
  },
  updateProjectMaterials: (project, newMaterialIds) => ({
    ...project,
    materialIds: newMaterialIds,
  }),
  updateModelRLE: (models, modelId, rle) =>
    TreeUtils.map(models, (item) => {
      const model = item;
      if (model.id === modelId) {
        if (rle.length > 2) {
          model.colored = true;
          model.rle = rle;
        } else {
          model.colored = false;
          model.rle = null;
        }
      }
      return model;
    }),
  updateModelPaintData: (models, modelId, stampMap, rle) =>
    TreeUtils.map(models, (item) => {
      const model = { ...item };
      if (model.id === modelId) {
        if (rle.length > 2) {
          model.colored = true;
          model.rle = rle;
        } else {
          model.colored = false;
          model.rle = null;
        }

        const hasStamps = !_.isEmpty(stampMap);
        if (hasStamps) {
          SlicerUtils.applyStamps(model.mesh, stampMap);
          model.textured = true;
          model.stamps = stampMap;
        } else {
          model.textured = false;
          model.stamps = {};
        }
      }
      return model;
    }),
  updateModelCustomSupportsData: (models, dataPerModel) =>
    TreeUtils.map(models, (item) => {
      const model = item;
      const supportData = dataPerModel.find(
        (data) => data.modelId === model.id
      );

      if (supportData) {
        const { supportsRle } = supportData;
        if (supportsRle.length > 2) {
          model.supportsRle = supportsRle;
          model.hasCustomSupports = !!supportsRle;
        } else if (supportsRle.length === 2 && supportsRle[1] === 0) {
          model.supportsRle = null;
          model.hasCustomSupports = false;
        }
      }
      return model;
    }),
};

export default Helpers;

import React from 'react';
import Metadata from './extrusion.metadata';
import StyleSettingsFormSection from '../styleSettingsFormSection.jsx';

import { Container, Col } from '../forms.styles';

class Content extends StyleSettingsFormSection {
  static isSectionMatched(props, filterBy) {
    return (
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.general,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.gapFill,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.retraction,
        filterBy
      )
    );
  }

  render() {
    return (
      <Container>
        <Col>
          {this.renderFieldGroup(Metadata.general)}
          {this.renderFieldGroup(Metadata.gapFill)}
        </Col>
        <Col>{this.renderFieldGroup(Metadata.retraction)}</Col>
      </Container>
    );
  }
}

export default Content;

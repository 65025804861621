import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import { TaskStatus } from '../../../reducers/onboarding/constants';
import { taskListOrder, taskMetadata } from './metadata';

import TaskList from './taskList/taskList.jsx';
import { Container } from './taskManager.styles';

class TaskManager extends Component {
  constructor(props) {
    super(props);
    const renderedTasks = this.getTasksForRender(props);
    this.state = {
      activeTask: renderedTasks[0] || null,
      bottomOffset: '1em',
    };
  }

  componentDidMount() {
    this.setOffset();
  }

  componentDidUpdate(prevProps) {
    const renderedTasks = this.getTasksForRender(this.props);
    if (this.state.activeTask === null) {
      // if no tasks were rendered, but now a task (or multiple) is rendered,
      // automatically un-collapse the first available task
      const previousRenderedTasks = this.getTasksForRender(prevProps);
      if (renderedTasks.length > 0 && previousRenderedTasks.length === 0) {
        this.setState({
          activeTask: renderedTasks[0],
        });
      }
    } else if (!_.includes(renderedTasks, this.state.activeTask)) {
      // if the currently-active task list gets completed,
      // automatically uncollapse the next task list
      this.setState({
        activeTask: renderedTasks[0] || null,
      });
    }

    const pathnameChanged =
      prevProps.location.pathname !== this.props.location.pathname;
    if (pathnameChanged) {
      this.setOffset();
    }
  }

  setOffset() {
    const enteredSlicer = this.props.location.pathname.includes('/slicer/');
    if (enteredSlicer) {
      this.setState({
        bottomOffset: '7em',
      });
    }
  }

  getTasksForRender(props) {
    const { taskStatus } = props;
    return _.filter(taskListOrder, (identifier) => {
      // don't render task lists that haven't been initiated
      if (!taskStatus[identifier].started) {
        return false;
      }
      // only render tasks containing at least one incomplete step
      // (and ignore steps that aren't present in front-end metadata, as
      // they probably used to exist but have since been removed)
      return _.some(
        taskStatus[identifier].steps,
        (status, stepName) =>
          status === TaskStatus.INCOMPLETE &&
          taskMetadata[identifier].steps[stepName] !== undefined
      );
    });
  }

  onTaskListHeaderClick(identifier) {
    if (this.state.activeTask === identifier) {
      // header for active task was clicked -- collapse all tasks
      this.setState({
        activeTask: null,
      });
    } else {
      // header for inactive task was clicked -- activate it
      this.setState({
        activeTask: identifier,
      });
    }
  }

  render() {
    const { history, location, match, taskStatus, setTaskStepStatus } =
      this.props;
    const { activeTask, bottomOffset } = this.state;
    const tasksToRender = this.getTasksForRender(this.props);
    return (
      <Container bottomOffset={bottomOffset}>
        {_.map(tasksToRender, (identifier) => {
          const meta = taskMetadata[identifier];
          return (
            <TaskList
              key={identifier}
              history={history}
              location={location}
              match={match}
              identifier={identifier}
              title={meta.title}
              steps={meta.steps}
              stepOrder={meta.stepOrder}
              taskStatus={taskStatus[identifier]}
              collapsed={activeTask !== identifier}
              onHeaderClick={() => this.onTaskListHeaderClick(identifier)}
              setTaskStepStatus={setTaskStepStatus}
            />
          );
        })}
      </Container>
    );
  }
}

export default withRouter(TaskManager);

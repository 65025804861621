import React from 'react';
import { MiniSwatchContainer, Swatch } from './miniSwatch.styles';
import { MaterialUtils } from '../../utils';

function MiniSwatch({ label = '', selected = false, color = null }) {
  return (
    <MiniSwatchContainer>
      {label && label}
      {color && (
        <Swatch
          color={color}
          isBright={MaterialUtils.isColorBright(color)}
          selected={selected}
        />
      )}
    </MiniSwatchContainer>
  );
}

export default MiniSwatch;

import React from 'react';

import { ImageContainer, HereToHelpImage } from '../taskManager.styles';

import { Body1 } from '../../../../shared/typography/typography';

export const hereToHelp = {
  title: 'We are here to help',
  content: (
    <>
      <Body1>
        To find support articles or talk to our customer success team, click on
        the link in the side panel.
      </Body1>
      <ImageContainer>
        <HereToHelpImage />
      </ImageContainer>
    </>
  ),
};

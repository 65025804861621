import _ from 'lodash';
import { subMinutes } from 'date-fns';
import * as d3 from 'd3';

const Helpers = {
  updateDeviceInMap: (existingDevices, device) => ({
    ...existingDevices,
    [device.id]: device,
  }),
  deleteDevicesFromMap: (existingDevices, deviceIds) => {
    const updatedDevices = _.omit(existingDevices, deviceIds);
    return updatedDevices;
  },
  composeDevicesMap: (devicesArray) => {
    const devices = {};
    _.forEach(devicesArray, (device) => {
      devices[device.id] = device;
    });
    return devices;
  },

  transformTemperatures: (temperatures) => ({
    time: new Date(),
    extruder: temperatures?.nozzle?.[0]?.actual,
    extruderTarget: temperatures?.nozzle?.[0]?.target,
    printBed: temperatures?.bed?.actual,
    printBedTarget: temperatures?.bed?.target,
    chamber: temperatures?.chamber?.actual,
    chamberTarget: temperatures?.chamber?.target,
  }),

  limitTemperatureHistory: (temperatureHistory, numberOfMinutes) => {
    if (temperatureHistory.length === 0) {
      return temperatureHistory;
    }

    const times = temperatureHistory.map((d) => d.time);
    const endTime = d3.max(times);
    const startTime = subMinutes(endTime, numberOfMinutes);

    const old = temperatureHistory.filter(({ time }) => time < startTime);
    const lastOld = old.length > 0 ? old[old.length - 1] : null;
    const included = temperatureHistory.filter(({ time }) => time >= startTime);

    return [
      ...(lastOld === null
        ? []
        : [
            {
              ...lastOld,
              time: startTime,
            },
          ]),
      ...included,
    ];
  },
};

export default Helpers;

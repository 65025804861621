import TWEEN from '@tweenjs/tween.js';

let frameId = null; // requestAnimationFrame handler
let needsRender = false; // do we need to render the scene?
let tweening = false; // should we update tweens?

let currentRenderer = null;
let currentScene = null;
let currentCamera = null;

export const setCamera = (camera) => {
  currentCamera = camera;
};

export const startTween = () => {
  tweening = true;
};

export const stopTween = () => {
  tweening = false;
};

function animate() {
  if (tweening || needsRender) {
    currentRenderer.render(currentScene, currentCamera);
    needsRender = false;
  }
  if (tweening) {
    TWEEN.update();
  }
  frameId = requestAnimationFrame(animate);
}

export const startAnimation = (renderer, scene, camera) => {
  currentRenderer = renderer;
  currentScene = scene;
  currentCamera = camera;
  needsRender = true;
  animate();
};

export const setRenderFlag = () => {
  needsRender = true;
};

export const stopAnimation = () => {
  cancelAnimationFrame(frameId);
  frameId = null;
};

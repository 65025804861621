import styled, { css } from 'styled-components';

export const IconContainer = styled.div`
  height: 100%;
  min-width: 3.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextsContainer = styled.div`
  flex-grow: 1;
  padding: 0.5rem 0;
  min-width: 0;
  height: auto;

  display: flex;
  flex-direction: column;
  text-align: left;
  ${(props) => !props.noGap && 'gap: 0.25rem;'};
`;

export const StyledButton = styled.div`
  width: 100%;
  min-height: ${(props) => (props.item ? '3.25rem' : '3.75rem')};

  display: flex;
  align-items: center;
  justify-content: left;

  cursor: pointer;
  box-sizing: border-box;
  border-radius: 0.25rem;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};

  &:hover {
    border-color: ${(props) => props.theme.colors.grey3};
    background: ${(props) => props.theme.colors.elevatedBackgroundSecondary};
  }

  ${(props) =>
    props.item &&
    css`
      border: none;
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      pointer-events: none;

      border-color: ${props.theme.colors.grey3};
      background: ${props.theme.colors.elevatedBackgroundTertiary};
    `}
`;

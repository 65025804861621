import React from 'react';

import {
  Container,
  Col,
  VariableTransitionLengthButtons,
} from '../forms.styles';

import Metadata from './supports.metadata';
import StyleSettingsFormSection from '../styleSettingsFormSection.jsx';
import { Button } from '../../../../../shared';

class Content extends StyleSettingsFormSection {
  static isSectionMatched(props, filterBy) {
    return (
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.general,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.placement,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.interfaces,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.reinforcements,
        filterBy
      )
    );
  }

  renderCustomSupportsButtons() {
    if (!this.props.projectProps) return null;
    const { onClickConfigureCustomSupports } = this.props.projectProps;
    return (
      <VariableTransitionLengthButtons>
        <Button
          expand
          disabled={
            !this.props.style.useSupport || !this.props.style.useCustomSupports
          }
          onClick={() => onClickConfigureCustomSupports()}>
          Configure custom supports
        </Button>
      </VariableTransitionLengthButtons>
    );
  }

  render() {
    return (
      <Container>
        <Col>
          {this.renderFieldGroup(Metadata.general)}
          {this.renderCustomSupportsButtons()}
          {this.renderFieldGroup(Metadata.placement)}
        </Col>
        <Col>
          {this.renderFieldGroup(Metadata.interfaces)}
          {this.renderFieldGroup(Metadata.reinforcements)}
        </Col>
      </Container>
    );
  }
}

export default Content;

export const Firmware = {
  FIRMWARE_5D_REL: 1,
  FIRMWARE_5D_ABS: 2,
  FIRMWARE_FLASHFORGE: 10,
  FIRMWARE_GRIFFIN: 11,
};

export const PerimeterOrder = {
  PERIMETER_FIRST: 0,
  LOOP_THEN_PERIMETER: 1,
  PERIMETER_LAST: 2,
};

export const SolidFillStyles = {
  STRAIGHT: 0,
  MONOTONIC: 1,
  CONCENTRIC: 2,
};

export const InfillStyles = {
  STRAIGHT: 0,
  OCTAGONAL: 1,
  ROUNDED: 2,
  CELLULAR: 3,
  DYNAMIC: 4,
};

export const SupportStyles = {
  OFF: 0,
  ROUGH: 2,
  DENSE: 4,
  ULTRA: 6,
};

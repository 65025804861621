import React, { Component } from 'react';
import _ from 'lodash';

import RedirectToPrinterModal from './redirectToPrinter.jsx';
import NewProjectModal from './newProjectModal.jsx';

import Button from '../button/button.jsx';
import LoadingOverlay from '../loadingOverlay/loadingOverlay.jsx';

import { PrinterUtils } from '../../utils';

import Routes from '../../router/routes';
import Icons from '../../themes/icons';

class NewProjectButton extends Component {
  static defaultProps = {
    parentId: null, // when creating the project within a folder (null means top level)
    printerId: '',
    styleId: '',
    materialId: '',
    name: '',
    primary: true,
    expand: false,
    disabled: false, // force disabling from outside (button may disable itself anyway if needed)
    withIcon: false,
    deviceConfig: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      showNewProjectModal: false,
      showRedirectToPrinterModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.createProjectPending &&
      !this.props.createProjectPending &&
      this.props.createProjectSuccess
    ) {
      this.props.history.push(Routes.toSlicer(this.props.currentProject));
    }
  }

  autoCreateProject() {
    // new logic to automatically create project, with printerId
    const {
      name,
      parentId,
      materialId,
      styleId,
      newProjectOptions,
      printers,
      printerId,
    } = this.props;

    const projectName = name || 'New Project';
    const slicer = newProjectOptions.defaultSlicer;
    const projectMaterialId = materialId || newProjectOptions.defaultMaterial;
    const projectPrinterId = printerId || newProjectOptions.defaultPrinter;
    const projectStyleId = styleId || newProjectOptions.defaultStyle;

    const potentialPrinters = _.filter(
      this.props.printers,
      (printer) => printer.styles.length > 0
    );
    if (_.keys(potentialPrinters).length === 0) {
      this.setState({ showRedirectToPrinterModal: true });
      return;
    }
    const defaultPrinterAndStyle = PrinterUtils.getDefaultPrinterAndStyle(
      projectPrinterId,
      projectStyleId,
      printers
    );

    let deviceConfig = newProjectOptions.defaultDeviceConfig;
    if (
      printers[defaultPrinterAndStyle.printerId].machineSettings.extension ===
      'mcfx'
    ) {
      deviceConfig = null;
    }

    const projectOptions = {
      name: projectName,
      slicer,
      deviceConfig,
      printerId: defaultPrinterAndStyle.printerId,
      styleId: defaultPrinterAndStyle.styleId,
      materialId: projectMaterialId,
      parentId,
    };
    this.props.createProject(projectOptions);
  }

  onNewProjectButtonClick(e) {
    e.stopPropagation();
    // show modal to prompt user input for new project options
    if (this.props.newProjectOptions.showDialog) {
      const potentialPrinters = _.filter(
        this.props.printers,
        (printer) => printer.styles.length > 0
      );
      if (_.keys(potentialPrinters).length === 0) {
        this.setState({ showRedirectToPrinterModal: true });
      } else {
        this.setState({ showNewProjectModal: true });
      }
      return;
    }
    // handle automatic creation of project that does not require user input
    this.autoCreateProject();
  }

  onConfirmNewProjectOptions(projectOptions) {
    // handle creation of project with user input for new project options
    this.props.createProject({
      ...projectOptions,
      parentId: this.props.parentId,
    });
  }

  handleRedirectToPrinter() {
    this.setState({ showRedirectToPrinterModal: false }, () => {
      this.props.history.push(Routes.toManagePrinters(), {
        hasNoPrinter: true,
      });
    });
  }

  renderOverlaySpinner() {
    if (this.props.createProjectPending) {
      return <LoadingOverlay label='Creating Project' />;
    }
    return null;
  }

  renderRedirectToPrinterModal() {
    if (!this.state.showRedirectToPrinterModal) {
      return null;
    }
    return (
      <RedirectToPrinterModal
        onCancel={() => this.setState({ showRedirectToPrinterModal: false })}
        onConfirm={() => this.handleRedirectToPrinter()}
      />
    );
  }

  renderNewProjectModal() {
    if (!this.state.showNewProjectModal) {
      return null;
    }
    return (
      <NewProjectModal
        slicer={this.props.newProjectOptions.defaultSlicer}
        printerId={
          this.props.printerId || this.props.newProjectOptions.defaultPrinter
        }
        styleId={
          this.props.styleId || this.props.newProjectOptions.defaultStyle
        }
        materialId={
          this.props.materialId || this.props.newProjectOptions.defaultMaterial
        }
        printers={this.props.printers}
        materials={this.props.materials}
        devices={this.props.devices}
        onCancel={() => this.setState({ showNewProjectModal: false })}
        onConfirm={(options) => this.onConfirmNewProjectOptions(options)}
        deviceConfig={this.props.newProjectOptions.defaultDeviceConfig}
      />
    );
  }

  renderNewProjectButton() {
    const disabled =
      this.props.disabled ||
      !this.props.getPrintersSuccess ||
      (this.props.newProjectOptions.showDialog &&
        !this.props.getMaterialsSuccess);
    return (
      <Button
        primary={this.props.primary}
        expand={this.props.expand}
        disabled={disabled}
        icon={this.props.withIcon && Icons.basic.plus}
        onClick={(e) => this.onNewProjectButtonClick(e)}>
        New project
      </Button>
    );
  }

  render() {
    return (
      <>
        {this.renderNewProjectButton()}
        {this.renderNewProjectModal()}
        {this.renderRedirectToPrinterModal()}
        {this.renderOverlaySpinner()}
      </>
    );
  }
}

export default NewProjectButton;

import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import 'webrtc-adapter';

import {
  Container,
  Overlay,
  StyledVideo,
  ResumeButtonWrapper,
  AspectRatioContainer,
  Letterbox,
} from './playback.styles';
import { Button, Progress, Subtitle2 } from '../../../../../shared';
import { usePrevious } from '../../../../../hooks';
import { MonitoringUtils } from '../../../../../utils';

const ICE_GATHERING_TIMEOUT = 10_000;
const KEEP_ALIVE_INTERVAL = 20_000;
const KEEP_ALIVE_LIMIT = 90_000;

const Playback = (props) => {
  const {
    deviceId,
    webRtcConnectionOffer,
    initWebRtcConnectionPending,
    initWebRtcConnectionSuccess,
    establishWebRtcConnectionPending,
    establishWebRtcConnectionSuccess,
  } = props;

  const theme = useTheme();

  const playerRef = useRef(null); // ref for <video> element
  const connection = useRef(null); // RTCPeerConnection
  const webRtcConnected = useRef(false);
  const keepAliveInterval = useRef(null); // setInterval ID
  const keepAliveLimitTimeout = useRef(null); // setTimeout ID
  const iceGatheringTimeout = useRef(null); // setTimeout ID
  const iceGatheringCandidateFound = useRef(false);
  const iceGatheringCompleted = useRef(false);

  const [initialized, setInitialized] = useState(false); // for spinner
  const [inactive, setInactive] = useState(false); // stream timed out
  const [disconnected, setDisconnected] = useState(false); // stream got disconnected
  const [failure, setFailure] = useState(''); // error establishing connection

  const keepAliveRequest = () => {
    const { sessionId } = webRtcConnectionOffer;
    props.keepAlive(deviceId, sessionId);
  };

  const cancelKeepAlive = () => {
    if (!keepAliveLimitTimeout.current) return;
    setInactive(true);
    keepAliveLimitTimeout.current = null;
    if (keepAliveInterval.current) {
      clearInterval(keepAliveInterval.current);
      keepAliveInterval.current = null;
    }
    iceGatheringCandidateFound.current = false;
    iceGatheringCompleted.current = false;
  };

  const stopKeepAlive = () => {
    if (!keepAliveInterval.current) return;
    clearInterval(keepAliveInterval.current);
    keepAliveInterval.current = null;
    clearTimeout(stopKeepAlive);
    keepAliveLimitTimeout.current = null;
  };

  const startKeepAlive = () => {
    if (keepAliveInterval.current) return;
    keepAliveInterval.current = setInterval(
      keepAliveRequest,
      KEEP_ALIVE_INTERVAL
    );
    keepAliveLimitTimeout.current = setTimeout(
      cancelKeepAlive,
      KEEP_ALIVE_LIMIT
    );
  };

  const teardown = () => {
    stopKeepAlive();
    if (iceGatheringTimeout.current) {
      clearTimeout(iceGatheringTimeout.current);
      iceGatheringTimeout.current = null;
    }
    if (connection.current) {
      connection.current.close();
    }
    props.removeConnection();
  };

  useEffect(() => {
    // componentDidMount
    props.initConnection(deviceId);
    window.addEventListener('beforeunload', teardown);
    return () => {
      // componentWillUnmount
      window.removeEventListener('beforeunload', teardown);
      teardown();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const establishConnection = () => {
    if (iceGatheringCompleted.current) return;
    iceGatheringCompleted.current = true;
    if (iceGatheringCandidateFound.current) {
      const answer = connection.current.localDescription;
      const connectionAnswer = {
        sessionId: webRtcConnectionOffer.sessionId,
        answer: {
          sdp: answer.sdp,
          type: answer.type,
        },
      };
      props.establishConnection(deviceId, connectionAnswer);
    } else {
      // no ICE candidates found in a timely manner
      setFailure('Failed to establish stream');
    }
  };

  const createConnectionAnswer = async () => {
    try {
      const { offer } = webRtcConnectionOffer;
      const desc = new RTCSessionDescription(offer);
      await connection.current.setRemoteDescription(desc);
      const answer = await connection.current.createAnswer();
      await connection.current.setLocalDescription(answer);
      // limit ICE gathering to ICE_GATHERING_TIMEOUT
      iceGatheringTimeout.current = setTimeout(
        establishConnection,
        ICE_GATHERING_TIMEOUT
      );
    } catch (err) {
      teardown();
      setFailure('Failed to initialize connection');
      MonitoringUtils.sentryManualReport(err);
    }
  };

  const onConnectionStateChange = () => {
    if (!connection.current) return;
    if (connection.current.connectionState === 'disconnected') {
      setDisconnected(true);
    } else if (connection.current.connectionState === 'connected') {
      setDisconnected(false);
    }
  };

  const onIceGatheringStateChange = () => {
    if (!connection.current) return;
    if (connection.current.iceGatheringState === 'complete') {
      if (iceGatheringTimeout.current) {
        clearTimeout(iceGatheringTimeout.current);
        iceGatheringTimeout.current = null;
      }
      establishConnection();
    }
  };

  const trackHandler = (event) => {
    const player = playerRef.current;
    if (player.srcObject !== event.streams[0]) {
      const [stream] = event.streams;
      player.srcObject = stream;
    }
  };

  const onIceCandidate = (event) => {
    if (!connection.current) return;
    if (event.candidate) {
      iceGatheringCandidateFound.current = true;
    }
  };

  const handleInitiateConnectionSuccess = () => {
    if (connection.current !== null) return;

    const iceServers = [{ urls: process.env.REACT_APP_STUN_URL }];

    const { turnConfig } = webRtcConnectionOffer;
    if (turnConfig) {
      iceServers.push({
        urls: turnConfig.uris,
        credential: turnConfig.password,
        username: turnConfig.username,
      });
    }

    connection.current = new RTCPeerConnection({ iceServers });

    connection.current.addEventListener(
      'connectionstatechange',
      onConnectionStateChange
    );
    connection.current.addEventListener(
      'icegatheringstatechange',
      onIceGatheringStateChange
    );
    connection.current.addEventListener('icecandidate', onIceCandidate);
    connection.current.addEventListener('track', trackHandler);

    createConnectionAnswer();
  };

  // handle initWebRtcConnection success/failure
  const prevInitWebRtcConnectionPending = usePrevious(
    initWebRtcConnectionPending
  );
  useEffect(() => {
    if (prevInitWebRtcConnectionPending && !initWebRtcConnectionPending) {
      if (initWebRtcConnectionSuccess) {
        handleInitiateConnectionSuccess();
      } else {
        setFailure('Failed to initialize connection');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    prevInitWebRtcConnectionPending,
    initWebRtcConnectionPending,
    initWebRtcConnectionSuccess,
  ]);

  const handleEstablishConnectionSuccess = () => {
    if (webRtcConnected.current) return;
    webRtcConnected.current = true;
    startKeepAlive();
    setInitialized(true);
  };

  // handle establishWebRtcConnection success/failure
  const prevEstablishWebRtcConnectionPending = usePrevious(
    establishWebRtcConnectionPending
  );
  useEffect(() => {
    if (
      prevEstablishWebRtcConnectionPending &&
      !establishWebRtcConnectionPending
    ) {
      if (establishWebRtcConnectionSuccess) {
        handleEstablishConnectionSuccess();
      } else {
        setFailure('Failed to establish stream');
        props.removeConnection();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    prevEstablishWebRtcConnectionPending,
    establishWebRtcConnectionPending,
    establishWebRtcConnectionSuccess,
  ]);

  const onResumeClick = () => {
    if (connection.current) {
      connection.current.removeEventListener(
        'connectionstatechange',
        onConnectionStateChange
      );
      connection.current.removeEventListener(
        'icegatheringstatechange',
        onIceGatheringStateChange
      );
      connection.current.removeEventListener('icecandidate', onIceCandidate);
      connection.current.removeEventListener('track', trackHandler);
      connection.current = null;
    }
    webRtcConnected.current = false;
    iceGatheringCandidateFound.current = false;
    iceGatheringCompleted.current = false;
    props.initConnection(deviceId);
    setInactive(false);
    setInitialized(false);
    setFailure('');
  };

  const renderInitializingOverlay = () => {
    if (initialized || failure) return null;
    return (
      <Overlay>
        <Progress circular />
      </Overlay>
    );
  };

  const renderInactiveOverlay = () => {
    if (!inactive || !disconnected || failure) return null;
    return (
      <Overlay>
        <Subtitle2 white>Are you still there?</Subtitle2>
        <ResumeButtonWrapper>
          <Button
            minimal
            buttonTextColor={theme.colors.greenDefault}
            onClick={onResumeClick}>
            Resume Streaming
          </Button>
        </ResumeButtonWrapper>
      </Overlay>
    );
  };

  const renderFailureOverlay = () => {
    if (!failure) return null;
    return (
      <Overlay>
        <Subtitle2 white>{failure}</Subtitle2>
        <ResumeButtonWrapper>
          <Button
            minimal
            buttonTextColor={theme.colors.greenDefault}
            onClick={onResumeClick}>
            Retry
          </Button>
        </ResumeButtonWrapper>
      </Overlay>
    );
  };

  return (
    <Container>
      <Letterbox>
        <AspectRatioContainer>
          {renderInitializingOverlay()}
          {renderInactiveOverlay()}
          {renderFailureOverlay()}
          <StyledVideo ref={playerRef} autoPlay playsInline />
        </AspectRatioContainer>
      </Letterbox>
    </Container>
  );
};

export default Playback;

import { connect } from 'react-redux';

import { actions } from '../../reducers/onboarding/onboarding';
import Onboarding from './onboarding.jsx';

const mapStateToProps = (state) => ({
  getOnboardingStatePending: state.onboarding.status.getOnboardingStatePending,
  getOnboardingStateSuccess: state.onboarding.status.getOnboardingStateSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getOnboardingState: () => dispatch(actions.getOnboardingStateRequest()),
  resetOnboardingStore: () => dispatch(actions.resetOnboardingStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);

import styled from 'styled-components';

export const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: ${(props) =>
    props.greenDefault
      ? props.theme.colors.greenDefault
      : props.theme.colors.purpleDefault};
`;

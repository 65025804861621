import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';

import {
  CaptionWrapper,
  Container,
  IconWrapper,
  JobInfoWrapper,
  ProgressRingContainer,
  TimeContainer,
} from './deviceStatus.styles';

import Icon from '../icon/icon.jsx';
import Progress from '../progress/progress.jsx';

import { Icons } from '../../themes';

import { DeviceUtils, FormatUtils } from '../../utils';

import Routes from '../../router/routes';

import {
  Body1OneLine,
  Caption,
  CaptionOneLine,
} from '../typography/typography';

class DeviceStatus extends Component {
  onContainerClick() {
    const { allowContainerClick, history, device } = this.props;
    if (allowContainerClick) {
      history.push(Routes.toViewDevice(device.id));
    }
  }

  onPauseOrResumePrint(e, printPaused) {
    e.stopPropagation();
    if (printPaused) {
      this.props.onResumePrint();
    } else {
      this.props.onPausePrint();
    }
  }

  renderTimeRemaining() {
    const { currentDeviceState } = this.props;
    const secondsRemaining =
      DeviceUtils.getPrintTimeRemaining(currentDeviceState);
    if (secondsRemaining <= 0) {
      return null;
    }
    const timeRemaining = FormatUtils.formatDuration(secondsRemaining, true);
    return <TimeContainer>{`${timeRemaining} left`}</TimeContainer>;
  }

  renderCurrentPrintInfo() {
    const { currentDeviceState, device } = this.props;
    const printName = DeviceUtils.getPrintName(currentDeviceState, device);
    return (
      <>
        <Body1OneLine noSpacing>{printName}</Body1OneLine>
      </>
    );
  }

  renderActiveDeviceMeta() {
    const { currentDeviceState, pendingRequests, device } = this.props;

    const isPrinting = DeviceUtils.isPrinting(currentDeviceState, device);
    const isPrintPaused = DeviceUtils.isPrintPaused(currentDeviceState, device);
    const isPrintPausing = DeviceUtils.isPrintPausing(
      currentDeviceState,
      device
    );
    const isPrintCancelling = DeviceUtils.isPrintCancelling(
      currentDeviceState,
      device
    );
    const isPrintFailed = DeviceUtils.isPrintFailed(currentDeviceState, device);
    const isPending =
      pendingRequests.pausePrint ||
      pendingRequests.resumePrint ||
      pendingRequests.cancelPrint;
    const printProgress = DeviceUtils.getPrintProgress(currentDeviceState);

    let statusText = 'Ready';
    if (isPrinting) {
      statusText = this.renderTimeRemaining();
    }
    if (isPrintPausing) statusText = 'Pausing…';
    if (isPrintCancelling) statusText = 'Cancelling…';
    if (isPrintPaused) statusText = 'Paused';
    if (isPrintFailed) statusText = 'Failed';

    let printName;
    if (isPrinting) {
      printName = this.renderCurrentPrintInfo();
    }

    return (
      <Container onClick={() => this.onContainerClick()}>
        <JobInfoWrapper>
          <Body1OneLine noSpacing>{printName}</Body1OneLine>
          <CaptionWrapper>
            {statusText && (
              <Caption grey={!isPrintFailed} red={isPrintFailed} noSpacing>
                {statusText}
              </Caption>
            )}
            <CaptionOneLine grey noSpacing>
              {device.name}
            </CaptionOneLine>
          </CaptionWrapper>
        </JobInfoWrapper>
        {isPrinting && (
          <ProgressRingContainer>
            <Progress
              circular
              determinate
              radius={24}
              stroke={4}
              progress={printProgress}
              primaryColor={
                isPrintFailed
                  ? this.props.theme.colors.redDefault
                  : this.props.theme.colors.greenDefault
              }>
              {!isPrintFailed && (
                <IconWrapper
                  onClick={(e) => this.onPauseOrResumePrint(e, isPrintPaused)}>
                  <Icon
                    src={isPrintPaused ? Icons.fdm.play : Icons.fdm.pause}
                    color={
                      isPending || isPrintPausing || isPrintCancelling
                        ? this.props.theme.colors.grey4
                        : this.props.theme.colors.greenDefault
                    }
                  />
                </IconWrapper>
              )}
            </Progress>
          </ProgressRingContainer>
        )}
      </Container>
    );
  }

  render() {
    const { currentDeviceState } = this.props;

    const isOnline = DeviceUtils.isOnline(currentDeviceState);
    const isConnected = DeviceUtils.isConnectedToPrinter(currentDeviceState);

    if (isOnline && isConnected) {
      return this.renderActiveDeviceMeta();
    }
    return null;
  }
}

export default withRouter(withTheme(DeviceStatus));

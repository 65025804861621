import React from 'react';
import { withTheme } from 'styled-components';
import { ShareMarkContainer } from './shareMark.styles';
import Icon from '../icon/icon.jsx';
import { Icons } from '../../themes';

const ShareMark = ({
  positionTop = '0.2rem', // set icon's position down from the top edge
  positionRight = '0.2rem', // set icon's position to the left of the right edge
  ...props
}) => (
  <ShareMarkContainer
    selected={props.selected}
    positionTop={positionTop}
    positionRight={positionRight}>
    <Icon
      src={Icons.basic.share}
      color={
        props.updated
          ? props.theme.colors.yellowDark
          : props.theme.colors.greenDefault
      }
    />
  </ShareMarkContainer>
);

export default withTheme(ShareMark);

import React, { Component } from 'react';
import _ from 'lodash';

import { Container, ContextButtonWrapper } from '../transformContents.styles';

import { TransformUtils, TreeUtils } from '../../../../../utils';

import { Button } from '../../../../../shared';

class ContextContents extends Component {
  componentWillUnmount() {
    if (this.props.intersectMode !== 'object') {
      this.props.setIntersectMode('object');
    }
  }

  onAutoArrangeClick() {
    this.props.repack();
  }

  onDropToBedClick() {
    const { models, selectedModels } = this.props;
    const droppedToBedModels = TransformUtils.dropToBed(selectedModels, models);
    this.props.updateModelTransforms(
      _.map(droppedToBedModels, (model) => ({
        id: model.id,
        transforms: _.pick(model.transforms, ['translate']),
      }))
    );
  }

  onLayFaceClick() {
    const { intersectMode } = this.props;
    if (intersectMode === 'object') {
      this.props.deactivateAllModels();
      this.props.setIntersectMode('face');
    } else if (intersectMode === 'face') {
      this.props.setIntersectMode('object');
    }
  }

  onCustomSupportsClick() {
    const { project } = this.props;

    if (project.style.useSupport && project.style.useCustomSupports) {
      // proceed to support view right away
      this.props.onEnterSupport();
    } else {
      // first save setting to use custom supports
      // then proceed to support view
      this.props.updateProjectStyle({
        useSupport: true,
        useCustomSupports: true,
      });
      this.props.onEnterSupport();
    }
  }

  onRemapInputsClick() {
    const { project, models } = this.props;

    const availableInputCount = project.colors.length;
    const existingInputsSeen = TreeUtils.getExtrudersUsed(
      models,
      project.style,
      availableInputCount
    );

    const remapperContext = {
      projectId: project.id,
      colors: project.colors,
      printerId: project.printerId,
      styleId: project.styleId,
      style: project.style,
      existingInputsSeen,
      availableInputCount,
      triggeredManually: true,
    };

    this.props.setInputRemapperContext(remapperContext);
  }

  renderButtons() {
    const { models, selectedModels, project } = this.props;
    const hasModels = !_.isEmpty(models);
    const hasSelectedModels = !_.isEmpty(selectedModels);
    return (
      <>
        <ContextButtonWrapper>
          <Button
            clean
            expand
            compact
            disabled={!hasModels}
            onClick={() => this.onAutoArrangeClick()}>
            Auto-arrange
          </Button>
        </ContextButtonWrapper>
        <ContextButtonWrapper>
          <Button
            clean
            expand
            compact
            disabled={!hasModels}
            toggled={this.props.intersectMode === 'face'}
            onClick={(e) => this.onLayFaceClick(e)}>
            Lay face to bed…
          </Button>
        </ContextButtonWrapper>
        <ContextButtonWrapper>
          <Button
            clean
            expand
            compact
            disabled={!hasModels || !hasSelectedModels}
            onClick={() => this.onDropToBedClick()}>
            Drop models to bed
          </Button>
        </ContextButtonWrapper>
        <ContextButtonWrapper>
          <Button
            clean
            expand
            compact
            disabled={!hasModels}
            onClick={() => this.onCustomSupportsClick()}>
            Custom supports…
          </Button>
        </ContextButtonWrapper>
        <ContextButtonWrapper>
          <Button
            clean
            expand
            compact
            disabled={project.colors.length === 1}
            onClick={() => this.onRemapInputsClick()}>
            Remap inputs
          </Button>
        </ContextButtonWrapper>
      </>
    );
  }

  render() {
    return <Container>{this.renderButtons()}</Container>;
  }
}

export default ContextContents;

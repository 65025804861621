const types = {
  RECEIVE_LOCAL_STORAGE_UPDATE: 'AUTH/RECEIVE_LOCAL_STORAGE_UPDATE',
  SET_AUTO_LOGOUT: 'AUTH/SET_AUTO_LOGOUT',
  SIGNUP_REQUEST: 'AUTH/SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'AUTH/SIGNUP_SUCCESS',
  SIGNUP_FAILURE: 'AUTH/SIGNUP_FAILURE',
  LOGIN_REQUEST: 'AUTH/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'AUTH/LOGIN_SUCCESS',
  LOGIN_FAILURE: 'AUTH/LOGIN_FAILURE',
  LOGOUT: 'AUTH/LOGOUT',
  LOGOUT_ALL_SESSIONS_REQUEST: 'AUTH/LOGOUT_ALL_SESSIONS_REQUEST',
  LOGOUT_ALL_SESSIONS_SUCCESS: 'AUTH/LOGOUT_ALL_SESSIONS_SUCCESS',
  LOGOUT_ALL_SESSIONS_FAILURE: 'AUTH/LOGOUT_ALL_SESSIONS_FAILURE',
  REFRESH_USER_TOKEN_REQUEST: 'AUTH/REFRESH_USER_TOKEN_REQUEST',
  REFRESH_USER_TOKEN_SUCCESS: 'AUTH/REFRESH_USER_TOKEN_SUCCESS',
  REFRESH_USER_TOKEN_FAILURE: 'AUTH/REFRESH_USER_TOKEN_FAILURE',
  DELETE_ACCOUNT_REQUEST: 'AUTH/DELETE_ACCOUNT_REQUEST',
  DELETE_ACCOUNT_SUCCESS: 'AUTH/DELETE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT_FAILURE: 'AUTH/DELETE_ACCOUNT_FAILURE',
  EDIT_ACCOUNT_REQUEST: 'AUTH/EDIT_ACCOUNT_REQUEST',
  EDIT_ACCOUNT_SUCCESS: 'AUTH/EDIT_ACCOUNT_SUCCESS',
  EDIT_ACCOUNT_FAILURE: 'AUTH/EDIT_ACCOUNT_FAILURE',
  GET_ACCOUNT_REQUEST: 'AUTH/GET_ACCOUNT_REQUEST',
  GET_ACCOUNT_SUCCESS: 'AUTH/GET_ACCOUNT_SUCCESS',
  GET_ACCOUNT_FAILURE: 'AUTH/GET_ACCOUNT_FAILURE',
  REQUEST_PASSWORD_RESET_REQUEST: 'AUTH/REQUEST_PASSWORD_RESET_REQUEST',
  REQUEST_PASSWORD_RESET_SUCCESS: 'AUTH/REQUEST_PASSWORD_RESET_SUCCESS',
  REQUEST_PASSWORD_RESET_FAILURE: 'AUTH/REQUEST_PASSWORD_RESET_FAILURE',
  RESET_PASSWORD_REQUEST: 'AUTH/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'AUTH/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'AUTH/RESET_PASSWORD_FAILURE',
  TOGGLE_HELP_PACKAGE: 'AUTH/TOGGLE_HELP_PACKAGE',
  AUTHORIZE_INTEGRATION_REQUEST: 'AUTHORIZE_INTEGRATION_REQUEST',
  AUTHORIZE_INTEGRATION_SUCCESS: 'AUTHORIZE_INTEGRATION_SUCCESS',
  AUTHORIZE_INTEGRATION_FAILURE: 'AUTHORIZE_INTEGRATION_FAILURE',
  LINK_INTEGRATION_REQUEST: 'AUTH/LINK_INTEGRATION_REQUEST',
  LINK_INTEGRATION_SUCCESS: 'AUTH/LINK_INTEGRATION_SUCCESS',
  LINK_INTEGRATION_FAILURE: 'AUTH/LINK_INTEGRATION_FAILURE',
  UNLINK_INTEGRATION_REQUEST: 'AUTH/UNLINK_INTEGRATION_REQUEST',
  UNLINK_INTEGRATION_SUCCESS: 'AUTH/UNLINK_INTEGRATION_SUCCESS',
  UNLINK_INTEGRATION_FAILURE: 'AUTH/UNLINK_INTEGRATION_FAILURE',
  GET_INTEGRATIONS_REQUEST: 'AUTH/GET_INTEGRATIONS_REQUEST',
  GET_INTEGRATIONS_SUCCESS: 'AUTH/GET_INTEGRATIONS_SUCCESS',
  GET_INTEGRATIONS_FAILURE: 'AUTH/GET_INTEGRATIONS_FAILURE',
};

export default types;

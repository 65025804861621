import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';
import { InterfaceUtils } from '../../../utils';

export default function* createSharedProject(action) {
  try {
    const { projectId, shareModels, sharePrinter, shareStyle, shareMaterials } =
      action.payload;

    const response = yield call(API, {
      method: methods.PUT,
      path: `projects/${projectId}/share`,
      body: {
        shareModels,
        sharePrinter,
        shareStyle,
        shareMaterials,
      },
    });
    if (response === null) return;

    const sharedProjectData = response.sharedProject;
    const { id, dateModified } = sharedProjectData;
    const shareData = {
      shareId: id,
      dateShared: dateModified,
      shareModels,
      sharePrinter,
      shareStyle,
      shareMaterials,
    };
    yield put(actions.createSharedProjectSuccess(projectId, shareData));
    InterfaceUtils.emitToast('success', 'Project has been successfully shared');
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

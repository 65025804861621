import styled from 'styled-components';
import { Breakpoints } from '../../../../themes';

export const MetadataContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${Breakpoints.smallWidth}) {
    grid-template-columns: minmax(0, 1fr);
    margin-right: 0;
  }
`;

export const PrinterSettingsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  grid-gap: 0.5rem;
  align-items: center;

  @media (max-width: ${Breakpoints.smallWidth}) {
    grid-template-columns: 1fr;
    margin-right: 0;
  }
`;

export const PaletteSettingsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr;
  grid-gap: 0.5em;
  align-items: center;

  @media (max-width: ${Breakpoints.smallWidth}) {
    grid-template-columns: 1fr;
    margin-right: 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const MetadataItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;

  @media (max-width: ${Breakpoints.smallWidth}) {
    margin-right: 0;
  }
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

import styled from 'styled-components';
import { Breakpoints } from '../../../../themes';

export const ImportHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 5rem;

  @media (max-width: ${Breakpoints.smallWidth}) {
    justify-content: center;
  }
`;

export const ProjectTitleWrapper = styled.div`
  margin-bottom: 0.4rem;
  max-width: 23rem;

  @media (max-width: ${Breakpoints.smallWidth}) {
    max-width: 15rem;
  }
`;

import createSagaMiddleWare from 'redux-saga';

import authSaga from './auth';
import slicerSaga from './slicer';
import threeSaga from './three';
import printersSaga from './printers';
import onboardingSaga from './onboarding';
import notificationsSaga from './notifications';
import iotSaga from './iot';

const sagaMiddleWare = createSagaMiddleWare();

const runSagas = () => {
  sagaMiddleWare.run(authSaga);
  sagaMiddleWare.run(onboardingSaga);
  sagaMiddleWare.run(slicerSaga);
  sagaMiddleWare.run(threeSaga);
  sagaMiddleWare.run(printersSaga);
  sagaMiddleWare.run(notificationsSaga);
  sagaMiddleWare.run(iotSaga);
};

export { sagaMiddleWare as sagas, runSagas };

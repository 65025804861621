/* eslint-disable */
// Generated from SequenceParser.g4 by ANTLR 4.10.1
// jshint ignore: start
import antlr4 from 'antlr4';

// This class defines a complete generic visitor for a parse tree produced by SequenceParser.

export default class SequenceParserVisitor extends antlr4.tree.ParseTreeVisitor {

	// Visit a parse tree produced by SequenceParser#sequence.
	visitSequence(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by SequenceParser#statements.
	visitStatements(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by SequenceParser#ifBlock.
	visitIfBlock(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by SequenceParser#optionalElseBlock.
	visitOptionalElseBlock(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by SequenceParser#whileBlock.
	visitWhileBlock(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by SequenceParser#statement.
	visitStatement(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by SequenceParser#assignment.
	visitAssignment(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by SequenceParser#gCode.
	visitGCode(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by SequenceParser#gCodeText.
	visitGCodeText(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by SequenceParser#gCodeEscapedText.
	visitGCodeEscapedText(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by SequenceParser#gCodeSubExpression.
	visitGCodeSubExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by SequenceParser#identExpr.
	visitIdentExpr(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by SequenceParser#floatExpr.
	visitFloatExpr(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by SequenceParser#unaryOpExpr.
	visitUnaryOpExpr(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by SequenceParser#intExpr.
	visitIntExpr(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by SequenceParser#functionCall.
	visitFunctionCall(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by SequenceParser#binaryOpExpr.
	visitBinaryOpExpr(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by SequenceParser#boolExpr.
	visitBoolExpr(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by SequenceParser#parenExpr.
	visitParenExpr(ctx) {
	  return this.visitChildren(ctx);
	}



}

import _ from 'lodash';
import { all, call } from 'redux-saga/effects';

import API, { methods } from '../../../canvas-api';
import { fetchS3, uploadAllPartsToS3 } from '../../../s3';

import { TextureUtils, StampUtils } from '../../../../utils';

function* uploadStamp(
  projectId,
  modelId,
  stampMeta,
  initResponse,
  imageRle,
  imageBuffer,
  depthBuffer,
  inputCount
) {
  const stampId = initResponse.id;
  const imageUrls = initResponse.image;
  const depthUrls = initResponse.depth;

  // upload image and depth files
  const [imageETags, depthETags] = yield all([
    call(uploadAllPartsToS3, imageUrls.urls, imageBuffer),
    call(uploadAllPartsToS3, depthUrls.urls, depthBuffer),
  ]);

  // finalize upload
  const completeResponse = yield call(API, {
    method: methods.PUT,
    path: `projects/${projectId}/models/${modelId}/stamps/${stampId}`,
    body: {
      image: {
        uploadId: imageUrls.uploadId,
        parts: _.map(imageETags, (eTag, idx) => ({
          partNumber: imageUrls.urls[idx].partNumber,
          eTag,
        })),
      },
      depth: {
        uploadId: depthUrls.uploadId,
        parts: _.map(depthETags, (eTag, idx) => ({
          partNumber: depthUrls.urls[idx].partNumber,
          eTag,
        })),
      },
      ...stampMeta,
    },
  });
  if (completeResponse === null) return null;
  const stampTextureBuffer = StampUtils.buildStampImageFromRLE(
    imageRle,
    stampMeta.imageDimensions
  );
  const extrudersUsed = TextureUtils.getExtrudersUsedFromRLE(
    imageRle,
    inputCount
  );
  return {
    ...completeResponse.stamp,
    stampRLE: imageRle,
    extrudersUsed,
    stampTextureBuffer,
    depthTextureBuffer: depthBuffer,
  };
}

function* createStamps(projectId, modelId, newStamps, inputCount) {
  const imageRles = [];
  const imageBuffers = [];
  const depthBuffers = [];
  const stampMetas = [];
  const requestStamps = _.map(newStamps, (stamp) => {
    const { image, depth, meta } = stamp;
    const imageBuffer = StampUtils.getStampRLEBuffer(image);
    imageRles.push(image);
    imageBuffers.push(imageBuffer);
    depthBuffers.push(depth);
    stampMetas.push(meta);
    return {
      ...meta,
      image: {
        contentLength: imageBuffer.byteLength,
      },
      depth: {
        contentLength: depth.byteLength,
      },
    };
  });
  const body = {
    stamps: requestStamps,
  };
  const initResponse = yield call(API, {
    method: methods.PUT,
    path: `projects/${projectId}/models/${modelId}/stamps`,
    body,
  });
  if (initResponse === null) return null;
  const finalizeResponses = yield all(
    _.map(initResponse.stamps, (stampResponse, idx) => {
      const rle = imageRles[idx];
      const image = imageBuffers[idx];
      const depth = depthBuffers[idx];
      const meta = stampMetas[idx];
      return call(
        uploadStamp,
        projectId,
        modelId,
        meta,
        stampResponse,
        rle,
        image,
        depth,
        inputCount
      );
    })
  );
  if (_.some(finalizeResponses, (response) => response === null)) return null;
  return finalizeResponses;
}

function* updateStamp(projectId, modelId, stampId, stampMeta) {
  return yield call(API, {
    method: methods.POST,
    path: `projects/${projectId}/models/${modelId}/stamps/${stampId}`,
    body: stampMeta,
  });
}

function* deleteStamp(projectId, modelId, stampId) {
  return yield call(API, {
    method: methods.DELETE,
    path: `projects/${projectId}/models/${modelId}/stamps/${stampId}`,
  });
}

export function* deleteAllStamps(projectId, modelId) {
  return yield call(API, {
    method: methods.DELETE,
    path: `projects/${projectId}/models/${modelId}/stamps`,
  });
}

export function* updateModelStampData(
  projectId,
  model,
  stampFiles,
  inputCount
) {
  const modelId = model.id;
  if (stampFiles) {
    // add, update, and/or delete stamps individually
    const remainingStampIds = new Set();
    const stampsToCreate = [];
    const calls = [];
    // add or update desired stamps, depending if an ID already exists
    _.forEach(stampFiles, (stampFile) => {
      if (stampFile.id) {
        // update
        remainingStampIds.add(stampFile.id);
        calls.push(
          call(updateStamp, projectId, modelId, stampFile.id, stampFile.meta)
        );
      } else {
        // create
        stampsToCreate.push(stampFile);
      }
    });
    // delete any stamps that have been removed from the model
    if (model.stamps) {
      _.forEach(_.keys(model.stamps), (stampId) => {
        if (!remainingStampIds.has(stampId)) {
          calls.push(call(deleteStamp, projectId, modelId, stampId));
        }
      });
    }
    if (stampsToCreate.length > 0) {
      calls.push(
        call(createStamps, projectId, modelId, stampsToCreate, inputCount)
      );
    }
    const responses = yield all(calls);
    if (_.some(responses, (response) => response === null)) return null;

    // merge all the response data into the new stamps map
    // (no handling necessary for deleteStamp responses)
    const updatedStamps = {};
    _.forEach(responses, (response) => {
      if (_.isArray(response)) {
        // createStamps response -- attach data as appropriate
        _.forEach(response, (stamp) => {
          const { id } = stamp;
          updatedStamps[id] = stamp;
        });
      } else if (response.stamp && response.stamp.id) {
        // updateStamp response
        const { id } = response.stamp;
        updatedStamps[id] = {
          ...model.stamps[id],
          ...response.stamp,
        };
      }
    });
    return updatedStamps;
  }
  if (model.stamps && !_.isEmpty(model.stamps)) {
    // no desired stamps, delete all existing ones
    return yield call(deleteAllStamps, projectId, modelId);
  }
  // no existing stamps, and no new stamps -- no-op
  return {};
}

function* loadStampImage(stamp, inputCount) {
  // load stamp image
  const stampImageResponse = yield call(fetchS3, stamp.stampTexture);
  const stampImageBuffer = yield call([stampImageResponse, 'arrayBuffer']);
  const stampRLE = StampUtils.parseStampRLEBuffer(stampImageBuffer);
  const extrudersUsed = TextureUtils.getExtrudersUsedFromRLE(
    stampRLE,
    inputCount
  );
  const stampTextureBuffer = StampUtils.buildStampImageFromRLE(
    stampRLE,
    stamp.meta.imageDimensions
  );
  return {
    extrudersUsed,
    stampRLE,
    stampTextureBuffer,
  };
}

function* loadStampDepthTexture(stamp) {
  // load stamp depth buffer
  const stampDepthResponse = yield call(fetchS3, stamp.depthTexture);
  return yield call([stampDepthResponse, 'arrayBuffer']);
}

export function* loadStampData(stamp, inputCount) {
  const [imageData, depthTextureBuffer] = yield all([
    call(loadStampImage, stamp, inputCount),
    call(loadStampDepthTexture, stamp),
  ]);
  const { extrudersUsed, stampRLE, stampTextureBuffer } = imageData;
  return {
    extrudersUsed,
    stampRLE,
    stampTextureBuffer,
    depthTextureBuffer,
    meta: stamp.meta,
  };
}

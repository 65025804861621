import 'regenerator-runtime/runtime';
import _ from 'lodash';
import { call, put, all } from 'redux-saga/effects';

import API, { methods } from '../canvas-api';
import { handleGenericError } from '../common';
import { actions } from '../../reducers/printers/printers';

export function* createStyleCall(printerId, style) {
  const response = yield call(API, {
    method: methods.PUT,
    path: `printers/${printerId}/styles`,
    body: style,
  });
  if (response === null) return null;

  const { printer } = response;
  return printer.styles;
}

export default function* createPrinterStyles(action) {
  try {
    const { printerId, styleProfiles: styles } = action.payload;
    const createdStyles = yield all(
      _.map(styles, (style) => call(createStyleCall, printerId, style))
    );

    if (_.some(createdStyles, (style) => style === null)) return;

    const flattenedStyles = _.flatten(createdStyles);
    const uniqueStyles = _.uniqBy(flattenedStyles, (style) => style.id);

    yield put(actions.createStyleProfilesSuccess(printerId, uniqueStyles));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import React, { Component } from 'react';
import _ from 'lodash';

import {
  CaptionWrapper,
  CheckboxWrapper,
  MinMaxTransitionLength,
  ColorStrengthTable,
  ColorStrengthHeader,
  ColorStrengthRow,
  ColorStrengthHeaderCell,
  ColorStrengthCell,
  ColorSwatchWrapper,
  InfoContainer,
  InfoHighlightWrapper,
  RadioButtonWrapper,
  VerticalTextOuter,
  VerticalTextInner,
  PairTable,
  PairHeaderCell,
  PairRow,
  PairCell,
  ButtonsContainer,
  TransitionLengthConfigWrapper,
  PairFieldInputContainer,
  MinTransitionLengthInputWrapper,
  MaxTransitionLengthInputWrapper,
} from './transitionLength.styles';

import Metadata from '../../../printerManager/configureStyle/forms/transition/transition.metadata';

import {
  AbstractInput,
  Button,
  Checkbox,
  ColorSwatch,
  Icon,
  Input,
  Link,
  MiniSwatch,
  RadioButton,
  ToolTipWrapper,
} from '../../../../shared';

import { Body1, Caption } from '../../../../shared/typography/typography';
import icons from '../../../../themes/icons';
import links from '../../../../themes/links';

const TRANSITION_LENGTH_MIN =
  Metadata.transitionSettings.fields[0].variants[0].min;
const TRANSITION_LENGTH_MAX =
  Metadata.transitionSettings.fields[0].variants[0].max;
const TRANSITION_LENGTH_STEP =
  Metadata.transitionSettings.fields[0].variants[0].step;

const tooltips = {
  outgoing: (
    <div>
      When transitioning, the outgoing material is the material that is finished
      being used. For example, when transitioning from black to white, black is
      outgoing.
    </div>
  ),
  ingoing: (
    <div>
      When transitioning, the ingoing material is the material that will be used
      next. For example, when transitioning from black to white, white is
      ingoing.
    </div>
  ),
};

const VerticalText = (props) => (
  <VerticalTextOuter>
    <VerticalTextInner>{props.children}</VerticalTextInner>
  </VerticalTextOuter>
);

class TransitionLength extends Component {
  static defaultProps = {
    advancedMode: false,
    saveTransitionLength: false,
    onTransitionLengthInputChanged: () => {},
    onTransitionLengthInputError: () => {},
    toggleOnSave: () => {},
  };

  static getNewState(props) {
    const { project, driveColors } = props;
    const defaultTransitionLength = props.project.style.transitionLength;
    const driveCount = driveColors.length;
    const transitionLength =
      project.initialTransitionLength || defaultTransitionLength;

    const state = {
      values: {
        minTransitionLength: transitionLength,
        maxTransitionLength: transitionLength,
        driveColorStrengths: [],
        transitionLengths: [],
      },
      errors: {
        minTransitionLength: false,
        maxTransitionLength: true,
        transitionLengths: [],
      },
    };
    // drive color strengths
    for (let i = 0; i < driveCount; i++) {
      state.values.driveColorStrengths.push(0);
    }
    // transition lengths
    for (let i = 0; i < driveCount; i++) {
      const pairRow = [];
      const errorRow = [];
      for (let j = 0; j < driveCount; j++) {
        if (i === j) {
          pairRow.push(null);
        } else {
          pairRow.push(transitionLength);
        }
        errorRow.push(false);
      }
      state.values.transitionLengths.push(pairRow);
      state.errors.transitionLengths.push(errorRow);
    }
    return state;
  }

  static getExistingState(props) {
    // generate new state and replace the existing option
    const newState = this.getNewState(props);
    const {
      driveColorStrengths,
      minTransitionLength,
      maxTransitionLength,
      transitionLengths,
    } = props;
    return {
      values: {
        minTransitionLength:
          minTransitionLength || newState.values.minTransitionLength,
        maxTransitionLength:
          maxTransitionLength || newState.values.maxTransitionLength,
        driveColorStrengths:
          driveColorStrengths || newState.values.driveColorStrengths,
        transitionLengths:
          transitionLengths || newState.values.transitionLengths,
      },
      errors: {
        minTransitionLength: false,
        maxTransitionLength: false,
        transitionLengths: newState.errors.transitionLengths,
      },
    };
  }

  static getInitialState(props) {
    if (props.initialTransitionLength) {
      // initialTransitionLength does not have a default prop, and is only
      // passed in when the other values don't yet exist on the project
      // - this allows us to create a better initial state than with defaultProps alone
      return TransitionLength.getNewState(props);
    }
    return TransitionLength.getExistingState(props);
  }

  constructor(props) {
    super(props);
    const initialState = TransitionLength.getInitialState(props);
    this.state = {
      ...initialState,
      initialState, // store it as well, for resetting on mode change
      advancedMode: props.advancedMode,
      focusField: null,
      enableTransitionLengths: !this.props.initialTransitionLength,
    };
    this.onVariableTransitionLengthUpdate =
      this.onVariableTransitionLengthUpdate.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.saveTransitionLength !== this.props.saveTransitionLength &&
      this.props.saveTransitionLength
    ) {
      this.onVariableTransitionLengthSave();
      this.props.toggleOnSave();
    }
    this.inputsUpdated();
    this.inputErrors();
  }

  onModeChange() {
    const { values, errors } = this.state.initialState;
    this.setState({
      advancedMode: !this.state.advancedMode,
      values,
      errors,
    });
  }

  onToggleTransitionLengths() {
    this.setState({
      enableTransitionLengths: !this.state.enableTransitionLengths,
    });
  }

  renderAboutTransitionLengths() {
    return (
      <InfoContainer>
        <InfoHighlightWrapper>
          <Icon src={icons.project.project} />
          <Body1 noSpacing>
            Transition lengths are associated with individual projects. They are
            applied on a per-project basis.
          </Body1>
        </InfoHighlightWrapper>
        <div>
          <Body1>
            Variable transition lengths are useful for prints when you&apos;re
            using filaments that are very different from each other in terms of
            color strength.
          </Body1>
          <Link external href={links.variableTransitionLengths}>
            Learn more about variable transition lengths
          </Link>
        </div>
        <Checkbox
          label='Use variable transition lengths'
          value={this.state.enableTransitionLengths}
          onChange={() => this.onToggleTransitionLengths()}
        />
      </InfoContainer>
    );
  }

  renderModeCheckbox() {
    return (
      <CheckboxWrapper>
        <Checkbox
          label='Advanced configuration options'
          value={this.state.advancedMode}
          onChange={() => this.onModeChange()}
          disabled={!this.state.enableTransitionLengths}
        />
      </CheckboxWrapper>
    );
  }

  onMinMaxChange(fieldName, value, error = false) {
    this.setState({
      values: {
        ...this.state.values,
        [fieldName]: value,
      },
      errors: {
        ...this.state.errors,
        [fieldName]: error,
      },
    });
  }

  onMinMaxChangeSuccess(fieldName, value) {
    this.onMinMaxChange(fieldName, value, false);
  }

  onMinMaxChangeFailure(fieldName, value) {
    this.onMinMaxChange(fieldName, value, true);
  }

  onVariableTransitionLengthUpdate() {
    const initialState = TransitionLength.getInitialState(this.props);
    this.setState = {
      ...initialState,
      initialState, // store it as well, for resetting on mode change
      advancedMode: this.props.advancedMode,
      focusField: null,
    };
  }

  onVariableTransitionLengthSave() {
    const fieldName = this.getNextSaveError();
    if (fieldName) {
      this.focusField(fieldName);
      return;
    }
    const { project } = this.props;
    const { enableTransitionLengths } = this.state;
    const toSaveState = {
      advancedMode: this.state.advancedMode,
      minTransitionLength: this.state.values.minTransitionLength,
      maxTransitionLength: this.state.values.maxTransitionLength,
      driveColorStrengths: this.state.values.driveColorStrengths,
      transitionLengths: this.state.values.transitionLengths,
    };
    this.props.updateVariableTransitionLengths(
      project.id,
      enableTransitionLengths ? toSaveState : null
    );
    const { advancedMode, ...updatedValues } = toSaveState;
    this.setState({
      initialState: {
        errors: this.state.errors,
        values: updatedValues,
      }, // store it for resetting on mode change
    });
  }

  resetVariableTransitionLengths() {
    const initialState = TransitionLength.getNewState(this.props);
    this.setState({
      ...initialState,
      initialState, // store it for resetting on mode change
      advancedMode: this.state.advancedMode,
      focusField: null,
    });
  }

  renderMinMaxTransitionLengthInput(isMin) {
    const fieldName = isMin ? 'minTransitionLength' : 'maxTransitionLength';
    const label = isMin
      ? 'Minimum transition length'
      : 'Maximum transition length';
    const TransitionInputWrapper = isMin
      ? MinTransitionLengthInputWrapper
      : MaxTransitionLengthInputWrapper;
    // if minTransitionLength is valid, use it as the lower bound for maxTransitionLength
    const min =
      !isMin && !this.state.errors.minTransitionLength
        ? this.state.values.minTransitionLength
        : TRANSITION_LENGTH_MIN;
    // if maxTransitionLength is valid, use it as the upper bound for minTransitionLength
    const max =
      isMin && !this.state.errors.maxTransitionLength
        ? this.state.values.maxTransitionLength
        : TRANSITION_LENGTH_MAX;
    return (
      <TransitionInputWrapper>
        <Input
          type='number'
          label={label}
          units='mm'
          min={this.state.enableTransitionLengths ? min : null}
          gte={isMin}
          max={this.state.enableTransitionLengths ? max : null}
          lte={!isMin}
          step={TRANSITION_LENGTH_STEP}
          value={this.state.values[fieldName]}
          isInvalid={
            this.state.enableTransitionLengths && this.state.errors[fieldName]
          }
          forceFocus={this.state.focusField === fieldName}
          onChangeSuccess={(value) =>
            this.onMinMaxChangeSuccess(fieldName, value)
          }
          onChangeFailure={(value) =>
            this.onMinMaxChangeFailure(fieldName, value)
          }
          disabled={!this.state.enableTransitionLengths}
        />
      </TransitionInputWrapper>
    );
  }

  renderMinMaxTransitionLength() {
    return (
      <MinMaxTransitionLength>
        {this.renderMinMaxTransitionLengthInput(true)}
        {this.renderMinMaxTransitionLengthInput(false)}
      </MinMaxTransitionLength>
    );
  }

  onChangeDriveStrength(drive, e) {
    const value = parseInt(e.target.value, 10);
    const strengths = [...this.state.values.driveColorStrengths];
    strengths[drive] = value;
    this.setState({
      values: {
        ...this.state.values,
        driveColorStrengths: strengths,
      },
    });
  }

  renderRadioButton(drive, value, strength) {
    const groupName = `driveColorStrengthsExt${drive}`;
    return (
      <ColorStrengthCell>
        <RadioButtonWrapper>
          <RadioButton
            key={value}
            groupName={groupName}
            value={value}
            checked={strength === value}
            onChange={(e) => this.onChangeDriveStrength(drive, e)}
            disabled={!this.state.enableTransitionLengths}
          />
        </RadioButtonWrapper>
      </ColorStrengthCell>
    );
  }

  renderColorStrengthSelection(drive, strength) {
    return (
      <ColorStrengthRow key={drive}>
        <ColorStrengthCell>
          <ColorSwatchWrapper>
            <ColorSwatch
              static
              color={this.props.driveColors[drive]}
              index={drive}
              label={drive + 1}
              forceShowLabel
            />
          </ColorSwatchWrapper>
        </ColorStrengthCell>
        {this.renderRadioButton(drive, -1, strength)}
        {this.renderRadioButton(drive, 0, strength)}
        {this.renderRadioButton(drive, 1, strength)}
      </ColorStrengthRow>
    );
  }

  renderColorStrengths() {
    return (
      <ColorStrengthTable>
        <thead>
          <ColorStrengthHeader>
            <ColorStrengthHeaderCell />
            <ColorStrengthHeaderCell>Weak</ColorStrengthHeaderCell>
            <ColorStrengthHeaderCell>—</ColorStrengthHeaderCell>
            <ColorStrengthHeaderCell>Strong</ColorStrengthHeaderCell>
          </ColorStrengthHeader>
        </thead>
        <tbody>
          {_.map(this.state.values.driveColorStrengths, (strength, drive) =>
            this.renderColorStrengthSelection(drive, strength)
          )}
        </tbody>
      </ColorStrengthTable>
    );
  }

  renderSimpleMode() {
    if (this.state.advancedMode) return null;
    return (
      <>
        {this.renderMinMaxTransitionLength()}
        {this.renderColorStrengths()}
      </>
    );
  }

  renderMiniSwatchWithLabel(drive) {
    const color = this.props.driveColors[drive];
    return <MiniSwatch color={color} label={drive + 1} />;
  }

  renderTopLegend() {
    const rowCount = this.props.driveColors.length;
    const topLegend = [
      <PairHeaderCell key='header' rowSpan={rowCount + 1}>
        <ToolTipWrapper tooltip={tooltips.outgoing}>
          <VerticalText>Outgoing (from)</VerticalText>
        </ToolTipWrapper>
      </PairHeaderCell>,
      <PairCell key='corner' />,
    ];
    for (let ingoingDrive = 0; ingoingDrive < rowCount; ingoingDrive++) {
      topLegend.push(
        <PairCell key={ingoingDrive}>
          {this.renderMiniSwatchWithLabel(ingoingDrive)}
        </PairCell>
      );
    }
    return <PairRow>{topLegend}</PairRow>;
  }

  onChangePairLength(ingoingDrive, outgoingDrive, value, error = false) {
    const transitionLengths = _.cloneDeep(this.state.values.transitionLengths);
    const transitionLengthErrors = _.cloneDeep(
      this.state.errors.transitionLengths
    );
    transitionLengths[ingoingDrive][outgoingDrive] = value;
    transitionLengthErrors[ingoingDrive][outgoingDrive] = error;
    this.setState({
      values: {
        ...this.state.values,
        transitionLengths,
      },
      errors: {
        ...this.state.errors,
        transitionLengths: transitionLengthErrors,
      },
    });
  }

  onChangePairLengthSuccess(ingoingDrive, outgoingDrive, value) {
    this.onChangePairLength(ingoingDrive, outgoingDrive, value, false);
  }

  onChangePairLengthFailure(ingoingDrive, outgoingDrive, value) {
    this.onChangePairLength(ingoingDrive, outgoingDrive, value, true);
  }

  renderPairInput(ingoingDrive, outgoingDrive) {
    return (
      <AbstractInput
        type='number'
        min={TRANSITION_LENGTH_MIN}
        max={TRANSITION_LENGTH_MAX}
        step={TRANSITION_LENGTH_STEP}
        value={this.state.values.transitionLengths[ingoingDrive][outgoingDrive]}
        isInvalid={
          this.state.errors.transitionLengths[ingoingDrive][outgoingDrive]
        }
        forceFocus={
          this.state.focusField ===
          `transitionLengths[${ingoingDrive}][${outgoingDrive}]`
        }
        StyledContainer={PairFieldInputContainer}
        onChangeSuccess={(value) =>
          this.onChangePairLengthSuccess(ingoingDrive, outgoingDrive, value)
        }
        onChangeFailure={(value) =>
          this.onChangePairLengthFailure(ingoingDrive, outgoingDrive, value)
        }
        disabled={!this.state.enableTransitionLengths}
      />
    );
  }

  renderPairRow(outgoingDrive) {
    const rowCount = this.props.driveColors.length;
    const row = [];
    row.push(
      <PairCell key='sideLegend'>
        {this.renderMiniSwatchWithLabel(outgoingDrive)}
      </PairCell>
    );
    for (let j = 0; j < rowCount; j++) {
      if (outgoingDrive === j) {
        row.push(<PairCell key={j}>—</PairCell>);
      } else {
        row.push(
          <PairCell key={j}>{this.renderPairInput(j, outgoingDrive)}</PairCell>
        );
      }
    }
    return <PairRow key={outgoingDrive}>{row}</PairRow>;
  }

  renderPairTable() {
    const rowCount = this.props.driveColors.length;
    const rows = [];
    for (let i = 0; i < rowCount; i++) {
      rows.push(this.renderPairRow(i));
    }
    return (
      <PairTable>
        <tbody>
          <PairRow>
            <PairHeaderCell />
            <PairHeaderCell colSpan={rowCount + 1}>
              <ToolTipWrapper tooltip={tooltips.ingoing}>
                Ingoing (to)
              </ToolTipWrapper>
            </PairHeaderCell>
          </PairRow>
          {this.renderTopLegend()}
          {rows}
        </tbody>
      </PairTable>
    );
  }

  renderAdvancedMode() {
    if (!this.state.advancedMode) return null;
    return (
      <>
        <CaptionWrapper>
          <Caption>Transition lengths by combination (mm)</Caption>
        </CaptionWrapper>
        {this.renderPairTable()}
      </>
    );
  }

  focusField(fieldName) {
    this.setState({ focusField: fieldName }, () => {
      this.setState({ focusField: null });
    });
  }

  getNextSaveError() {
    // prefer to start with errors on the current view
    if (this.state.advancedMode) {
      const driveCount = this.state.values.transitionLengths.length;
      for (let i = 0; i < driveCount; i++) {
        for (let j = 0; j < driveCount; j++) {
          if (i !== j && this.state.errors.transitionLengths[j][i]) {
            return `transitionLengths[${j}][${i}]`;
          }
        }
      }
    } else {
      if (this.state.errors.minTransitionLength) {
        return 'minTransitionLength';
      }
      if (this.state.errors.maxTransitionLength) {
        return 'maxTransitionLength';
      }
      if (
        this.state.values.maxTransitionLength <=
        this.state.values.minTransitionLength
      ) {
        return 'maxTransitionLength';
      }
    }
    return null;
  }

  inputErrors() {
    const { minTransitionLength, maxTransitionLength, transitionLengths } =
      this.state.errors;
    const { advancedMode, enableTransitionLengths } = this.state;
    if (!enableTransitionLengths) {
      // don't show errors if the transition lengths are disabled
      this.props.onTransitionLengthInputError(false);
    } else {
      this.props.onTransitionLengthInputError(
        // don't check minTransitionLength or maxTransitionLength errors on advanced mode
        (!advancedMode ? minTransitionLength || maxTransitionLength : false) ||
          transitionLengths.some((transitionLength) =>
            transitionLength.some((val) => val === true)
          )
      );
    }
  }

  inputsUpdated() {
    const {
      minTransitionLength,
      maxTransitionLength,
      transitionLengths,
      driveColorStrengths,
    } = this.state.values;
    let inputsUpdated =
      this.props.advancedMode !== this.state.advancedMode ||
      this.state.initialState.values.minTransitionLength !==
        minTransitionLength ||
      this.state.initialState.values.maxTransitionLength !==
        maxTransitionLength;
    if (this.props.transitionLengths) {
      inputsUpdated =
        inputsUpdated ||
        !_.isEqual(this.props.transitionLengths, transitionLengths);
    }
    if (this.props.driveColorStrengths) {
      inputsUpdated =
        inputsUpdated ||
        !_.isEqual(this.props.driveColorStrengths, driveColorStrengths);
    }
    if (
      !this.props.initialTransitionLength !== this.state.enableTransitionLengths
    ) {
      inputsUpdated = true;
    } else if (
      !this.state.enableTransitionLengths &&
      !!this.props.initialTransitionLength
    ) {
      inputsUpdated = false;
    }
    this.props.onTransitionLengthInputChanged(inputsUpdated);
  }

  disableResetAllButton() {
    const newState = TransitionLength.getNewState(this.props);
    return _.isEqual(newState.values, this.state.values);
  }

  renderButtons() {
    return (
      <ButtonsContainer>
        <Button
          disabled={
            this.disableResetAllButton() || !this.state.enableTransitionLengths
          }
          secondary
          onClick={() => this.resetVariableTransitionLengths()}>
          Reset to default values
        </Button>
      </ButtonsContainer>
    );
  }

  render() {
    return (
      <>
        {this.renderAboutTransitionLengths()}
        <TransitionLengthConfigWrapper>
          {this.renderModeCheckbox()}
          {this.renderSimpleMode()}
          {this.renderAdvancedMode()}
        </TransitionLengthConfigWrapper>
        {this.renderButtons()}
      </>
    );
  }
}

export default TransitionLength;

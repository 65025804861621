import styled from 'styled-components';
import { Breakpoints } from '../../../../themes';
import { floatingPanelStyles } from '../headerBarRight/headerBarRight.styles';

export const NotificationCardsContainer = styled.div`
  ${floatingPanelStyles};
  width: 18rem;
  max-height: calc(100vh - 4rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    min-width: calc(100% - 3rem);
  }
`;

export const NotificationCard = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: repeat(3, auto);
  grid-gap: 0.5rem;
  align-items: center;

  :not(:last-child) {
    border-bottom: 2px solid ${(props) => props.theme.colors.grey2};
  }
`;

export const NotificationTitle = styled.span`
  grid-column: 1 / span 1;
  grid-row: 1 / 2;
  font-weight: ${(props) => props.theme.fonts.weightBold};
  user-select: none;
  color: ${(props) =>
    props.read
      ? props.theme.colors.textSecondary
      : props.theme.colors.textPrimary};
`;

export const NotificationContent = styled.span`
  grid-column: 1 / -1;
  grid-row: 2 / 3;
  font-size: 0.9rem;
  user-select: none;
  color: ${(props) =>
    props.read
      ? props.theme.colors.textSecondary
      : props.theme.colors.textPrimary};
`;

export const NotificationSent = styled.span`
  grid-column: 1 / -1;
  grid-row: 3 / -1;
  font-size: 0.9rem;
  user-select: none;
  color: ${(props) =>
    props.read
      ? props.theme.colors.textSecondary
      : props.theme.colors.greenDefault};
`;

export const IconsContainer = styled.div`
  grid-column: 2 / span 1;
  grid-row: 1 / 2;
  align-self: start;
  justify-self: end;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: end;
`;

export const ReadUnreadIcon = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  transition: color 0.2s;
  border: solid 0.15rem ${(props) => props.theme.colors.grey3};
  background-color: ${(props) =>
    props.read ? 'transparent' : props.theme.colors.grey3};
  margin-right: 0.5rem;
  &:hover {
    border-color: ${(props) => props.theme.colors.grey5};
    background-color: ${(props) =>
      props.read ? 'transparent' : props.theme.colors.grey5};
  }
`;

export const IconWrapper = styled.div`
  padding: 0;
  cursor: pointer;
`;

export const NotificationsPlaceholder = styled.div`
  ${floatingPanelStyles};
  width: 18rem;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.grey4};
`;

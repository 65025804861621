import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { UploaderContainer, FileUploaderOverlay } from './modelUploader.styles';

import { SlicerContext } from '../slicer.context';
import UploadModal from '../uploadModal/uploadModal.jsx';
import Icons from '../../../themes/icons';
import { FileUploader } from '../../../shared';
import { ProjectUtils } from '../../../utils';
import { actions as slicerActions } from '../../../reducers/slicer/slicer';

const ModelUploader = ({
  endFileDrag = () => {},
  toggleUploadModal = () => {},
}) => {
  const dispatch = useDispatch();

  const {
    currentProject,
    projects,
    sliceJobs,
    status: slicerStatus,
  } = useSelector((state) => state.slicer);

  const {
    dragStart,
    uploadType,
    showUploadModal,
    currentView,
    showProjectSettingsModal,
    showTransitionLengthModal,
  } = useContext(SlicerContext);

  const getFrequentName = (nameArray) => {
    let allTokens = [];
    const frequentTokens = [];

    nameArray.forEach((fileName) => {
      const nameTokens = fileName.split(/[\s_.-]+/);
      nameTokens.splice(-1, 1);
      allTokens = allTokens.concat(nameTokens);
    });
    for (let i = 0; i < allTokens.length; i++) {
      let count = 0;
      for (let j = 0; j < allTokens.length; j++) {
        if (j !== i) {
          if (allTokens[i] === allTokens[j]) {
            count++;
          }
        }
      }
      if (count > 0) {
        if (!frequentTokens.includes(allTokens[i])) {
          frequentTokens.push(allTokens[i]);
        }
      }
    }
    if (!_.isEmpty(frequentTokens)) {
      return frequentTokens.join(' ');
    }
    return '';
  };

  const getFirstSTLName = (nameArray) => {
    const singleProjectName = nameArray[0].split(/[\s_.-]+/);
    singleProjectName.splice(-1, 1);
    return singleProjectName.join(' ');
  };

  const getProjectNameFromModels = (nameArray) => {
    const project = projects[currentProject];
    let projectName = '';
    if (project.name.match(/^\s*New Project(?:\s+\(\d+\))?\s*$/)) {
      projectName = getFirstSTLName(nameArray);
      const frequentName = getFrequentName(nameArray);
      if (nameArray.length > 1 && frequentName) {
        projectName = frequentName;
      }
    }
    return projectName;
  };

  const uploadStlsToServer = (files, isGroup) => {
    const filesArray = _.values(files);
    const fileNames = _.map(filesArray, (item) => item.name);

    const projectNameFromModels = getProjectNameFromModels(fileNames);
    if (projectNameFromModels) {
      const ALLOW_MAKE_NAME_UNIQUE = true;
      dispatch(
        slicerActions.updateProjectNameRequest(
          currentProject,
          projectNameFromModels,
          ALLOW_MAKE_NAME_UNIQUE
        )
      );
    }

    dispatch(slicerActions.uploadStlRequest(filesArray, isGroup));
  };

  const handleModalUpload = (fileUpload) => {
    const { files } = fileUpload.e.target;
    if (fileUpload.single) {
      uploadStlsToServer(files, false);
    } else {
      uploadStlsToServer(files, true);
    }
  };

  const dropFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    endFileDrag();
    // convert files object to array
    const filesArray = _.values(e.target.files);
    const validFiles = _.filter(
      filesArray,
      (file) => file.name.toLowerCase().split('.').pop() === 'stl'
    );
    if (!_.isEmpty(validFiles)) {
      const IS_GROUP_UPLOAD = uploadType === 'multi';
      uploadStlsToServer(validFiles, IS_GROUP_UPLOAD);
    }
    e.target.value = '';
  };

  const renderSingleMatUploader = () => {
    let primaryLabel = 'Single-material models';
    let secondaryLabel =
      'Drag models here to upload for single-material printing.';
    if (showUploadModal) {
      primaryLabel = '';
      secondaryLabel = '';
    }
    return (
      <UploaderContainer uploadType={uploadType}>
        <FileUploader
          multiple
          floating={true}
          hovered={uploadType === 'single'}
          accept={['.stl']}
          icon={Icons.project.addSingle}
          primaryLabel={primaryLabel}
          secondaryLabel={secondaryLabel}
          onChange={dropFile}
        />
      </UploaderContainer>
    );
  };

  const renderMultiMatUploader = () => {
    let primaryLabel = 'Multi-material models';
    let secondaryLabel =
      'Drag models here to upload and group together for multi-material printing.';
    if (showUploadModal) {
      primaryLabel = '';
      secondaryLabel = '';
    }
    return (
      <UploaderContainer uploadType={uploadType}>
        <FileUploader
          multiple
          floating={true}
          hovered={uploadType === 'multi'}
          accept={['.stl']}
          icon={Icons.project.addMultiple}
          primaryLabel={primaryLabel}
          secondaryLabel={secondaryLabel}
          onChange={dropFile}
        />
      </UploaderContainer>
    );
  };

  const renderFileUploaders = () => {
    const isProjectSlicing =
      slicerStatus.dispatchSliceJobPending ||
      ProjectUtils.isProjectSlicing(sliceJobs, currentProject);
    const isUploadingModels =
      slicerStatus.uploadStlPending && slicerStatus.uploadProgress <= 100;

    if (
      currentView !== 'place' ||
      isProjectSlicing ||
      isUploadingModels ||
      showProjectSettingsModal ||
      showTransitionLengthModal
    ) {
      return null;
    }
    return (
      <FileUploaderOverlay visible={dragStart}>
        {renderSingleMatUploader()}
        {renderMultiMatUploader()}
      </FileUploaderOverlay>
    );
  };

  const renderUploadModal = () => {
    if (
      slicerStatus.loadProjectPending ||
      slicerStatus.uploadPending ||
      !showUploadModal ||
      currentView !== 'place'
    )
      return null;
    return (
      <UploadModal
        onToggleModal={toggleUploadModal}
        onHandleModalUpload={handleModalUpload}
      />
    );
  };

  return (
    <>
      {renderFileUploaders()}
      {renderUploadModal()}
    </>
  );
};

export default ModelUploader;

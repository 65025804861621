import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../canvas-api';
import { handleGenericError } from '../common';
import { actions } from '../../reducers/auth/auth';

export default function* signup(action) {
  try {
    const { username, email, password, newsletter, betaToken } = action.payload;
    let formData = {
      username,
      email,
      password,
      newsletter,
    };
    if (betaToken) {
      formData = { ...formData, signupKey: betaToken };
    }

    const { token, refreshToken, user } = yield call(API, {
      method: methods.PUT,
      path: 'users',
      token: null,
      body: formData,
    });

    // finally, store new user's data in store
    yield put(actions.signupSuccess(token, refreshToken, user));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import 'regenerator-runtime/runtime';
import { call, put, take, select } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { types, actions } from '../../../reducers/slicer/slicer';

function* applyMaterialCall(modelData, materialIndex) {
  const { id, deleteRLE, deleteTexture } = modelData;
  const { currentProject: projectId } = yield select(getSlicerState);

  if (deleteRLE || deleteTexture) {
    // overriding color/texture; first make call to delete delete paint data
    yield put(
      actions.deleteModelPaintDataRequest(id, deleteRLE, deleteTexture)
    );
    // wait for the call to finish
    yield take(types.DELETE_MODEL_PAINT_DATA_SUCCESS);
  }

  return yield call(API, {
    method: methods.POST,
    path: `projects/${projectId}/models/${id}`,
    body: {
      extruder: materialIndex,
    },
  });
}

export default function* applyMaterial(action) {
  const { currentProject: projectId } = yield select(getSlicerState);
  const { modelsPaintData, materialIndex } = action.payload;

  try {
    let transitionTower;

    // use for loop to apply materials to models in sequence
    // the LAST response's tower is the correct one
    for (let i = 0; i < modelsPaintData.length; i++) {
      const modelData = modelsPaintData[i];
      const response = yield call(applyMaterialCall, modelData, materialIndex);
      if (response === null) return;
      ({ transitionTower } = response);
    }

    // NOTE: the createCalibrationProject saga is highly dependent on
    //  applyMaterialSuccess coming before updateTowerFromServer
    //  -- make sure to test that workflow if changing anything here!
    yield put(actions.applyMaterialSuccess());
    yield put(actions.invalidateSlice(projectId));
    yield put(actions.updateTowerFromServer(projectId, transitionTower));
    // update thumbnail
    yield put(actions.updateProjectThumbnailRequest(projectId));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

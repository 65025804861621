import React from 'react';
import Metadata from './infill.metadata';
import StyleSettingsFormSection from '../styleSettingsFormSection.jsx';

import { Container, Col } from '../forms.styles';

class Content extends StyleSettingsFormSection {
  static isSectionMatched(props, filterBy) {
    return (
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.style,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.extrusion,
        filterBy
      )
    );
  }

  render() {
    return (
      <Container>
        <Col>{this.renderFieldGroup(Metadata.style)}</Col>
        <Col>{this.renderFieldGroup(Metadata.extrusion)}</Col>
      </Container>
    );
  }
}

export default Content;

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import { SceneUtils, TreeUtils, SlicerUtils } from '../../../utils';

import API, { methods } from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

// initialize scene, camera, canvas and renderer for snapshot
const scene = SceneUtils.createScene();
const camera = SceneUtils.createCamera();
camera.aspect = 1280 / 720;
camera.updateProjectionMatrix();
const canvas = document.createElement('canvas');
const renderer = SceneUtils.createRendererForSnapshot(canvas);
renderer.setSize(1280, 720);

// add lighting and camera to scene
const ambientLight = SceneUtils.createAmbientLight();
const pointLight = SceneUtils.createPointLight();
scene.add(ambientLight);
camera.add(pointLight);
scene.add(camera);

// initialize other variables
let callInProgress = false; // used to throttle thumbnail requests
let queuedAction = null; // used to throttle thumbnail requests

const generateThumbnail = (models) => {
  const meshes = [];
  // add meshes to scene
  TreeUtils.map(models, (model) => {
    if (model.mesh && !!model.visible) {
      // must clone of mesh and remove highlight (if any)
      const meshClone = model.mesh.clone();
      meshClone.material = model.mesh.material.clone();
      meshClone.visible = true;
      scene.add(meshClone);
      meshes.push(meshClone);
      SlicerUtils.applyStamps(meshClone, model.stamps);
    }
  });

  // get bounding sphere that hold the models to get optimal camera positioning
  const boundingBox = SlicerUtils.getCombinedBoundingBox(models);
  const boundingSphere =
    SlicerUtils.getBoundingSphereFromBoundingBox(boundingBox);
  const cameraCoords = SceneUtils.getCameraSnapshotPosition(boundingSphere);

  camera.position.set(cameraCoords.x, cameraCoords.y, cameraCoords.z);
  camera.updateProjectionMatrix();
  camera.lookAt(boundingSphere.center);

  // render scene
  renderer.render(scene, camera);

  // prepare the clean image file
  const imgDataURL = renderer.domElement.toDataURL('image/png');
  const prefixRegex = /^data:(?:.+)?(?:;base64)?,/gi;
  const cleanImgData = _.replace(imgDataURL, prefixRegex, '');

  // remove meshes from scene to clear up three scene for next thumbnail
  meshes.forEach((mesh) => {
    scene.remove(mesh);
  });

  return cleanImgData;
};

function* update(action) {
  try {
    const { models } = yield select(getSlicerState);
    const { projectId } = action.payload;
    const thumbnailImg = generateThumbnail(models);

    const response = yield call(API, {
      method: methods.POST,
      path: `projects/${projectId}/thumbnail`,
      body: { content: thumbnailImg },
    });
    if (response === null) return;

    // update thumbnail in slicer reducer
    const thumbnailDataURL = `data:image/png;base64,${thumbnailImg}`;
    yield put(
      actions.updateProjectThumbnailSuccess(
        projectId,
        thumbnailDataURL,
        response.timestamp
      )
    );
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

export default function* updateProjectThumbnail(action) {
  if (callInProgress) {
    queuedAction = action;
  } else {
    callInProgress = true;
    yield call(update, action);
    while (queuedAction) {
      const nextAction = queuedAction;
      queuedAction = null;
      yield call(update, nextAction);
    }
    callInProgress = false;
  }
}

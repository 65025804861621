import styled from 'styled-components';

import { Breakpoints, Shadows } from '../../themes';

export const DefaultContainer = styled.div`
  text-align: left;
  padding: ${(props) => (props.selected ? '0.14em 0.2em' : '0.2em 0.26em')};
  height: 4em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => {
    if (props.hovered && !props.selected) {
      if (props.canDrop) {
        return props.theme.colors.greenLight;
      }
      return props.theme.colors.redDefault;
    }
    return props.theme.colors.elevatedBackgroundPrimary;
  }};
  ${Shadows.d1};
  border: 0.1em solid transparent;
  border-radius: 0.2em;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.2s;
  border-color: ${(props) =>
    props.selected ? props.theme.colors.greenDefault : 'transparent'};
  border-width: ${(props) => (props.selected ? '0.16em' : '0.10em')};
  box-sizing: border-box;
  opacity: ${(props) => (props.opacity ? props.opacity : 1)};

  &:hover {
    border-color: ${(props) =>
      props.selected
        ? props.theme.colors.greenDefault
        : props.theme.colors.greenLight};
  }

  @media (max-width: ${Breakpoints.largeWidth}) {
    height: 100%;
  }
`;

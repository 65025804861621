import _ from 'lodash';

import iotTypes from './types';
import iotActions from './actions';
import iotInitialState, {
  initialDeviceState,
  initialPendingRequestState,
} from './initialState';
import Helpers from './helpers';

export const types = iotTypes;
export const actions = iotActions;
export const initialState = iotInitialState;

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.RESET_IOT_STORE: {
      return initialState;
    }
    case types.INIT_CONNECTION_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          initConnectionPending: true,
          initConnectionSuccess: false,
        },
      };
    }
    case types.INIT_CONNECTION_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          initConnectionPending: false,
          initConnectionSuccess: true,
        },
      };
    }
    case types.INIT_CONNECTION_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          initConnectionPending: false,
          initConnectionSuccess: false,
        },
      };
    }
    case types.SET_CONNECTED_STATUS: {
      const { connected } = payload;
      return {
        ...state,
        connected,
      };
    }
    case types.GET_DEVICES_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getDevicesPending: true,
          getDevicesSuccess: false,
        },
      };
    }
    case types.GET_DEVICES_SUCCESS: {
      const { devices } = payload;
      const existingDeviceStates = { ...state.deviceStates };
      const existingPendingRequests = { ...state.pendingRequests };
      _.forEach(devices, (device) => {
        // initialize device state object for devices that are offline
        if (!existingDeviceStates[device.id]) {
          existingDeviceStates[device.id] = initialDeviceState;
        }
        // initialize pending request flags for devices not yet seen
        if (!existingPendingRequests[device.id]) {
          existingPendingRequests[device.id] = initialPendingRequestState;
        }
      });

      return {
        ...state,
        devices: Helpers.composeDevicesMap(devices),
        deviceStates: existingDeviceStates,
        pendingRequests: existingPendingRequests,
        status: {
          ...state.status,
          getDevicesPending: false,
          getDevicesSuccess: true,
        },
      };
    }
    case types.GET_DEVICES_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getDevicesPending: false,
          getDevicesSuccess: false,
        },
      };
    }
    case types.ACTIVATE_DEVICE_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          activateDevicePending: true,
          activateDeviceSuccess: false,
        },
      };
    }
    case types.ACTIVATE_DEVICE_SUCCESS: {
      const { device } = payload;
      return {
        ...state,
        devices: Helpers.updateDeviceInMap(state.devices, device),
        deviceStates: {
          ...state.deviceStates,
          [device.id]: initialDeviceState,
        },
        pendingRequests: {
          ...state.pendingRequests,
          [device.id]: initialPendingRequestState,
        },
        status: {
          ...state.status,
          activateDevicePending: false,
          activateDeviceSuccess: true,
        },
      };
    }
    case types.ACTIVATE_DEVICE_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          activateDevicePending: false,
          activateDeviceSuccess: false,
        },
      };
    }
    case types.UNLINK_DEVICES_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          unlinkDevicesPending: true,
          unlinkDevicesSuccess: false,
        },
      };
    }
    case types.UNLINK_DEVICES_SUCCESS: {
      const { deviceIds } = payload;
      return {
        ...state,
        devices: Helpers.deleteDevicesFromMap(state.devices, deviceIds),
        deviceStates: _.omit(state.deviceStates, deviceIds),
        pendingRequests: _.omit(state.pendingRequests, deviceIds),
        status: {
          ...state.status,
          unlinkDevicesPending: false,
          unlinkDevicesSuccess: true,
        },
      };
    }
    case types.UNLINK_DEVICES_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          unlinkDevicesPending: false,
          unlinkDevicesSuccess: false,
        },
      };
    }
    case types.SET_DEVICE_CONNECTED_STATUS: {
      const { deviceId, connected } = payload;
      return {
        ...state,
        deviceStates: {
          ...state.deviceStates,
          [deviceId]: {
            ...state.deviceStates[deviceId],
            connected,
          },
        },
      };
    }
    case types.MARK_PENDING_DEVICE_REQUEST: {
      const { deviceId, flag } = payload;
      return {
        ...state,
        pendingRequests: {
          ...state.pendingRequests,
          [deviceId]: {
            ...state.pendingRequests[deviceId],
            [flag]: true,
          },
        },
      };
    }
    case types.CLEAR_PENDING_DEVICE_REQUEST: {
      const { deviceId, flag } = payload;
      return {
        ...state,
        pendingRequests: {
          ...state.pendingRequests,
          [deviceId]: {
            ...state.pendingRequests[deviceId],
            [flag]: false,
          },
        },
      };
    }
    case types.CHECK_DEVICE_HEARTBEAT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          checkDeviceHeartbeatPending: true,
          checkDeviceHeartbeatSuccess: false,
        },
      };
    }
    case types.CHECK_DEVICE_HEARTBEAT_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          checkDeviceHeartbeatPending: false,
          checkDeviceHeartbeatSuccess: true,
        },
      };
    }
    case types.CHECK_DEVICE_HEARTBEAT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          checkDeviceHeartbeatPending: false,
          checkDeviceHeartbeatSuccess: false,
        },
      };
    }
    case types.GET_DEVICE_STATE_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getDeviceStatePending: true,
          getDeviceStateSuccess: false,
        },
      };
    }
    case types.GET_DEVICE_STATE_SUCCESS: {
      const { deviceId, stateId, deviceState } = payload;
      const currentDeviceState = state.deviceStates[deviceId];
      const temperatures = deviceState?.printer?.data?.temperature ?? [];
      const temperatureHistory = [
        ...(currentDeviceState?.temperatureHistory || []),
        Helpers.transformTemperatures(temperatures),
      ];

      return {
        ...state,
        deviceStates: {
          ...state.deviceStates,
          [deviceId]: {
            ...currentDeviceState,
            stateId,
            deviceState,
            temperatureHistory: Helpers.limitTemperatureHistory(
              temperatureHistory,
              10
            ),
          },
        },
        status: {
          ...state.status,
          getDeviceStatePending: false,
          getDeviceStateSuccess: true,
        },
      };
    }
    case types.GET_DEVICE_STATE_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getDeviceStatePending: false,
          getDeviceStateSuccess: false,
        },
      };
    }
    case types.UPDATE_DEVICE_STATE_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          updateDeviceStatePending: false,
          updateDeviceStateSuccess: false,
        },
      };
    }
    case types.UPDATE_DEVICE_STATE_SUCCESS: {
      const { deviceId, stateId, deviceState } = payload;
      const currentDeviceState = state.deviceStates[deviceId];
      const temperatures = deviceState.printer
        ? deviceState.printer.data.temperature
        : [];
      const temperatureHistory = [
        ...(currentDeviceState?.temperatureHistory || []),
        Helpers.transformTemperatures(temperatures),
      ];

      return {
        ...state,
        deviceStates: {
          ...state.deviceStates,
          [deviceId]: {
            ...state.deviceStates[deviceId],
            stateId,
            deviceState,
            temperatureHistory: Helpers.limitTemperatureHistory(
              temperatureHistory,
              10
            ),
          },
        },
        status: {
          ...state.status,
          updateDeviceStatePending: false,
          updateDeviceStateSuccess: true,
        },
      };
    }
    case types.UPDATE_DEVICE_STATE_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updateDeviceStatePending: false,
          updateDeviceStateSuccess: false,
        },
      };
    }
    case types.UPDATE_DEVICE_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          updateDevicePending: true,
          updateDeviceSuccess: false,
        },
      };
    }
    case types.UPDATE_DEVICE_SUCCESS: {
      return {
        ...state,
        devices: Helpers.updateDeviceInMap(state.devices, payload.device),
        status: {
          ...state.status,
          updateDevicePending: false,
          updateDeviceSuccess: true,
        },
      };
    }
    case types.UPDATE_DEVICE_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updateDevicePending: false,
          updateDeviceSuccess: false,
        },
      };
    }
    case types.SET_DEVICE_AVAILABLE_PORTS: {
      const { deviceId, availablePorts } = payload;
      return {
        ...state,
        deviceStates: {
          ...state.deviceStates,
          [deviceId]: {
            ...state.deviceStates[deviceId],
            availablePorts,
          },
        },
      };
    }
    case types.SET_DEVICE_STORAGE_DRIVES: {
      const { deviceId, storageDrives } = payload;
      return {
        ...state,
        deviceStates: {
          ...state.deviceStates,
          [deviceId]: {
            ...state.deviceStates[deviceId],
            storageDrives,
          },
        },
      };
    }
    case types.SET_DEVICE_FILES_CONTEXT: {
      const { deviceId, filesContext } = payload;
      return {
        ...state,
        deviceStates: {
          ...state.deviceStates,
          [deviceId]: {
            ...state.deviceStates[deviceId],
            filesContext,
          },
        },
      };
    }
    case types.CLEAR_DEVICE_STORAGE_DRIVES: {
      const { deviceId } = payload;
      return {
        ...state,
        deviceStates: {
          ...state.deviceStates,
          [deviceId]: {
            ...state.deviceStates[deviceId],
            storageDrives: [],
          },
        },
      };
    }
    case types.CLEAR_DEVICE_FILES_CONTEXT: {
      const { deviceId } = payload;
      return {
        ...state,
        deviceStates: {
          ...state.deviceStates,
          [deviceId]: {
            ...state.deviceStates[deviceId],
            filesContext: null,
          },
        },
      };
    }
    case types.CONNECT_PORT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          connectPortPending: true,
          connectPortSuccess: false,
        },
      };
    }
    case types.CONNECT_PORT_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          connectPortPending: false,
          connectPortSuccess: true,
        },
      };
    }
    case types.CONNECT_PORT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          connectPortPending: false,
          connectPortSuccess: false,
        },
      };
    }
    case types.DISCONNECT_PORT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          disconnectPortPending: true,
          disconnectPortSuccess: false,
        },
      };
    }
    case types.DISCONNECT_PORT_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          disconnectPortPending: false,
          disconnectPortSuccess: true,
        },
      };
    }
    case types.DISCONNECT_PORT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          disconnectPortPending: false,
          disconnectPortSuccess: false,
        },
      };
    }
    case types.SCAN_PORTS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          scanPortsPending: true,
          scanPortsSuccess: false,
        },
      };
    }
    case types.SCAN_PORTS_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          scanPortsPending: false,
          scanPortsSuccess: true,
        },
      };
    }
    case types.SCAN_PORTS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          scanPortsPending: false,
          scanPortsSuccess: false,
        },
      };
    }
    case types.START_PRINT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          startPrintPending: true,
          startPrintSuccess: false,
        },
      };
    }
    case types.START_PRINT_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          startPrintPending: false,
          startPrintSuccess: true,
        },
      };
    }
    case types.START_PRINT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          startPrintPending: false,
          startPrintSuccess: false,
        },
      };
    }
    case types.PAUSE_PRINT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          pausePrintPending: true,
          pausePrintSuccess: false,
        },
      };
    }
    case types.PAUSE_PRINT_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          pausePrintPending: false,
          pausePrintSuccess: true,
        },
      };
    }
    case types.PAUSE_PRINT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          pausePrintPending: false,
          pausePrintSuccess: false,
        },
      };
    }
    case types.RESUME_PRINT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          resumePrintPending: true,
          resumePrintSuccess: false,
        },
      };
    }
    case types.RESUME_PRINT_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          resumePrintPending: false,
          resumePrintSuccess: true,
        },
      };
    }
    case types.RESUME_PRINT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          resumePrintPending: false,
          resumePrintSuccess: false,
        },
      };
    }
    case types.CANCEL_PRINT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          cancelPrintPending: true,
          cancelPrintSuccess: false,
        },
      };
    }
    case types.CANCEL_PRINT_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          cancelPrintPending: false,
          cancelPrintSuccess: true,
        },
      };
    }
    case types.CANCEL_PRINT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          cancelPrintPending: false,
          cancelPrintSuccess: false,
        },
      };
    }
    case types.GET_STORAGE_DRIVES_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getStorageDrivesPending: true,
          getStorageDrivesSuccess: false,
        },
      };
    }
    case types.GET_STORAGE_DRIVES_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          getStorageDrivesPending: false,
          getStorageDrivesSuccess: true,
        },
      };
    }

    case types.GET_STORAGE_DRIVES_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getStorageDrivesPending: false,
          getStorageDrivesSuccess: false,
        },
      };
    }
    case types.GET_DRIVE_PATH_FILES_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getDrivePathFilesPending: true,
          getDrivePathFilesSuccess: false,
        },
      };
    }
    case types.GET_DRIVE_PATH_FILES_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          getDrivePathFilesPending: false,
          getDrivePathFilesSuccess: true,
        },
      };
    }
    case types.GET_DRIVE_PATH_FILES_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getDrivePathFilesPending: false,
          getDrivePathFilesSuccess: false,
        },
      };
    }
    case types.EDIT_DEVICE_FILE_PATH_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          editDeviceFilePathPending: true,
          editDeviceFilePathSuccess: false,
        },
      };
    }
    case types.EDIT_DEVICE_FILE_PATH_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          editDeviceFilePathPending: false,
          editDeviceFilePathSuccess: true,
        },
      };
    }
    case types.EDIT_DEVICE_FILE_PATH_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          editDeviceFilePathPending: false,
          editDeviceFilePathSuccess: false,
        },
      };
    }
    case types.FORGET_DEVICE_FILES: {
      return {
        ...state,
        status: {
          ...state.status,
          getStorageDrivesPending: false,
          getStorageDrivesSuccess: false,
          getDrivePathFilesPending: false,
          getDrivePathFilesSuccess: false,
          editDeviceFilePathPending: false,
          editDeviceFilePathSuccess: false,
        },
      };
    }
    case types.MOVE_EXTRUDER_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          moveExtruderPending: true,
          moveExtruderSuccess: false,
        },
      };
    }
    case types.MOVE_EXTRUDER_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          moveExtruderPending: false,
          moveExtruderSuccess: true,
        },
      };
    }
    case types.MOVE_EXTRUDER_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          moveExtruderPending: false,
          moveExtruderSuccess: false,
        },
      };
    }
    case types.HOME_EXTRUDER_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          homeExtruderPending: true,
          homeExtruderSuccess: false,
        },
      };
    }
    case types.HOME_EXTRUDER_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          homeExtruderPending: false,
          homeExtruderSuccess: true,
        },
      };
    }
    case types.HOME_EXTRUDER_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          homeExtruderPending: false,
          homeExtruderSuccess: false,
        },
      };
    }
    case types.FEED_FILAMENT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          feedFilamentPending: true,
          feedFilamentSuccess: false,
        },
      };
    }
    case types.FEED_FILAMENT_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          feedFilamentPending: false,
          feedFilamentSuccess: true,
        },
      };
    }
    case types.FEED_FILAMENT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          feedFilamentPending: false,
          feedFilamentSuccess: false,
        },
      };
    }
    case types.CONTROL_FAN_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          controlFanPending: true,
          controlFanSuccess: false,
        },
      };
    }
    case types.CONTROL_FAN_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          controlFanPending: false,
          controlFanSuccess: true,
        },
      };
    }
    case types.CONTROL_FAN_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          controlFanPending: false,
          controlFanSuccess: false,
        },
      };
    }
    case types.CONTROL_MOTOR_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          controlMotorPending: true,
          controlMotorSuccess: false,
        },
      };
    }
    case types.CONTROL_MOTOR_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          controlMotorPending: false,
          controlMotorSuccess: true,
        },
      };
    }
    case types.CONTROL_MOTOR_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          controlMotorPending: false,
          controlMotorSuccess: false,
        },
      };
    }
    case types.SEND_COMMAND_REQUEST: {
      return {
        ...state,
        commandHistory: [
          ...state.commandHistory,
          {
            type: 'sent',
            command: payload.command,
          },
        ],
        status: {
          ...state.status,
          sendCommandPending: true,
          sendCommandSuccess: false,
        },
      };
    }
    case types.SEND_COMMAND_SUCCESS: {
      return {
        ...state,
        commandHistory: [
          ...state.commandHistory,
          {
            type: 'received',
            command: payload.ackOrNack,
          },
        ],
        status: {
          ...state.status,
          sendCommandPending: false,
          sendCommandSuccess: true,
        },
      };
    }
    case types.SEND_COMMAND_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          sendCommandPending: false,
          sendCommandSuccess: false,
        },
      };
    }
    case types.CLEAR_COMMAND_HISTORY: {
      return {
        ...state,
        commandHistory: [],
      };
    }
    case types.SET_TARGET_TEMPERATURE_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          setTargetTemperaturePending: true,
          setTargetTemperatureSuccess: false,
        },
      };
    }
    case types.SET_TARGET_TEMPERATURE_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          setTargetTemperaturePending: false,
          setTargetTemperatureSuccess: true,
        },
      };
    }
    case types.SET_TARGET_TEMPERATURE_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          setTargetTemperaturePending: false,
          setTargetTemperatureSuccess: false,
        },
      };
    }

    case types.INIT_WEBRTC_CONNECTION_REQUEST: {
      return {
        ...state,
        webRtcConnectionOffer: null,
        status: {
          ...state.status,
          initWebRtcConnectionPending: true,
          initWebRtcConnectionSuccess: false,
          establishWebRtcConnectionPending: false,
          establishWebRtcConnectionSuccess: false,
          webRtcKeepAlivePending: false,
          webRtcKeepAliveSuccess: false,
        },
      };
    }
    case types.INIT_WEBRTC_CONNECTION_SUCCESS: {
      const { connectionOffer } = payload;
      return {
        ...state,
        webRtcConnectionOffer: connectionOffer,
        status: {
          ...state.status,
          initWebRtcConnectionPending: false,
          initWebRtcConnectionSuccess: true,
        },
      };
    }
    case types.INIT_WEBRTC_CONNECTION_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          initWebRtcConnectionPending: false,
          initWebRtcConnectionSuccess: false,
        },
      };
    }
    case types.ESTABLISH_WEBRTC_CONNECTION_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          establishWebRtcConnectionPending: true,
          establishWebRtcConnectionSuccess: false,
        },
      };
    }
    case types.ESTABLISH_WEBRTC_CONNECTION_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          establishWebRtcConnectionPending: false,
          establishWebRtcConnectionSuccess: true,
        },
      };
    }
    case types.ESTABLISH_WEBRTC_CONNECTION_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          establishWebRtcConnectionPending: false,
          establishWebRtcConnectionSuccess: false,
        },
      };
    }
    case types.WEBRTC_KEEP_ALIVE_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          webRtcKeepAlivePending: true,
          webRtcKeepAliveSuccess: false,
        },
      };
    }
    case types.WEBRTC_KEEP_ALIVE_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          webRtcKeepAlivePending: false,
          webRtcKeepAliveSuccess: true,
        },
      };
    }
    case types.WEBRTC_KEEP_ALIVE_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          webRtcKeepAlivePending: false,
          webRtcKeepAliveSuccess: false,
        },
      };
    }
    case types.REMOVE_WEBRTC_CONNECTION_OFFER: {
      return {
        ...state,
        webRtcConnectionOffer: null,
        status: {
          ...state.status,
          initWebRtcConnectionPending: false,
          initWebRtcConnectionSuccess: false,
          establishWebRtcConnectionPending: false,
          establishWebRtcConnectionSuccess: false,
          webRtcKeepAlivePending: false,
          webRtcKeepAliveSuccess: false,
        },
      };
    }

    default: {
      return state;
    }
  }
};

import styled from 'styled-components';

export const AutoSupportsFields = styled.div`
  button {
    margin: 0;
    padding: 0;
  }
`;

export const FieldsContentWrapper = styled.div`
  display: grid;
  grid-row-gap: 0.5em;

  div {
    margin-top: 0;
    padding: 0;
  }
`;

export const AutoSupportsFieldsFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const FooterLabels = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;
  margin-bottom: 1em;
  margin-top: 0.5em;
`;

export const ContextToolsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin: 0 0.75rem 0.75rem 0.75rem;
`;

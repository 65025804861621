import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';

import {
  ThumbnailContainerList,
  ThumbnailSpinnerContainer,
  ProjectThumbnailWrapper,
  ProjectThumbnailImage,
} from '../projectManager.styles';

import { Progress } from '../../../shared';

import { Caption } from '../../../shared/typography/typography';
import { ThumbnailContainerGrid } from '../projectCard/projectCard.styles';

const renderSpinner = (listView) => {
  const Container = listView ? ThumbnailContainerList : ThumbnailContainerGrid;
  return (
    <Container>
      <ThumbnailSpinnerContainer>
        <Progress circular />
      </ThumbnailSpinnerContainer>
    </Container>
  );
};

class ProjectThumbnail extends Component {
  componentDidMount() {
    const { project, thumbnailImage } = this.props;
    if (project.thumbnail && !thumbnailImage) {
      // thumbnail exists but needs to be loaded
      this.props.loadThumbnail(project.id);
    }
  }

  renderThumbnail() {
    const { project, thumbnailImage, listView } = this.props;
    const Container = listView
      ? ThumbnailContainerList
      : ThumbnailContainerGrid;
    return (
      <Container>
        <ProjectThumbnailImage
          alt={`${project.name} thumbnail preview`}
          src={thumbnailImage}
        />
      </Container>
    );
  }

  renderNoContent() {
    const Container = this.props.listView
      ? ThumbnailContainerList
      : ThumbnailContainerGrid;
    return (
      <Container>
        <ProjectThumbnailWrapper>
          <Caption noSpacing grey>
            No preview available
          </Caption>
        </ProjectThumbnailWrapper>
      </Container>
    );
  }

  render() {
    const { project, thumbnailImage, listView } = this.props;
    if (thumbnailImage) {
      // thumbnail exists and is loaded
      return this.renderThumbnail();
    }
    if (project.thumbnail) {
      // thumbnail exists but is not loaded
      return renderSpinner(listView);
    }
    // no preview available
    return this.renderNoContent();
  }
}

class LazyProjectThumbnail extends Component {
  render() {
    if (this.props.triggerCardReRender) return null;
    return (
      <LazyLoad
        once // remove listener after first trigger
        resize // respond to resize events
        scrollContainer='#main-panel' // listen to scroll events on Page
        debounce={300} // ms
        throttle={100} // ms
        placeholder={renderSpinner(this.props.listView)}>
        <ProjectThumbnail {...this.props} />
      </LazyLoad>
    );
  }
}

export default LazyProjectThumbnail;

import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../canvas-api';
import { handleGenericError } from '../common';
import { actions } from '../../reducers/printers/printers';

export default function* deleteSharedPrinter(action) {
  try {
    const { printerId } = action.payload;

    const response = yield call(API, {
      method: methods.DELETE,
      path: `printers/${printerId}/share`,
    });
    if (response === null) return;
    const { timestamp } = response;

    yield put(actions.deleteSharedPrinterSuccess(printerId, timestamp));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

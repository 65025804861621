import { call, put } from 'redux-saga/effects';

import { makeRequest } from './horizon';
import { actions } from '../../reducers/iot/iot';

export default function* establishWebRtcConnection(action) {
  try {
    const { deviceId, connectionAnswer } = action.payload;
    yield call(
      makeRequest,
      deviceId,
      'webcam/establish-connection',
      {
        method: 'post',
        timeoutMs: 30 * 1000,
        query: {
          connectionAnswer,
        },
      },
      'establishWebRtcConnection'
    );
    yield put(actions.establishWebRtcConnectionSuccess(deviceId));
  } catch (err) {
    yield put(actions.establishWebRtcConnectionFailure(err));
  }
}

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import { withTheme } from 'styled-components';

import {
  InputWrapper,
  Container,
  AuthPanel,
  PhotoPanel,
  Panels,
  TabButtons,
  Tab,
  TabTitleWrapper,
  LinkButtonWrapper,
  LoginTabFooter,
  LogoWrapper,
  DiscoverCanvasButton,
  TabButtonWrapper,
  MainButtonWrapper,
} from './home.styles';

import Fields from './fields.metadata';
import tooltips from './tooltips.jsx';
import Routes from '../../router/routes';
import { InterfaceUtils, ValidateUtils } from '../../utils';
import { fieldTypes } from '../../constants';
import { Links, Icons } from '../../themes';

import {
  Button,
  Checkbox,
  Icon,
  Image,
  Input,
  Link,
  Progress,
  Subtitle1,
  Caption,
  TabButton,
} from '../../shared';
import { getRandomImageIndex } from './random-image';

const getRandomImageURL = () => {
  const randomIndex = getRandomImageIndex();
  return `https://s3.amazonaws.com/mosaic-resources/canvas/homepage/${randomIndex}.jpg`;
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgUrl: '',
      currentTab: 'login',
      focusField: null,
      values: {
        email: '',
        username: '',
        password: '',
        confirmPassword: '',
        resetCode: '',
        betaToken: '',
        newsletter: true,
      },
      errors: {
        email: true,
        username: true,
        password: true,
        confirmPassword: true,
        resetCode: true,
        betaToken: true,
      },
    };
  }

  componentDidMount() {
    const imgUrl = getRandomImageURL();
    this.setState({ imgUrl });
  }

  changeTab(tabName, resetFields = '') {
    if (tabName === this.state.currentTab) return;
    const newState = {
      currentTab: tabName,
      values: {
        ...this.state.values,
      },
      errors: {
        ...this.state.errors,
      },
    };
    if (resetFields === 'password' || resetFields === 'all') {
      newState.values.password = '';
      newState.values.confirmPassword = '';
      newState.values.resetCode = '';
      newState.errors.password = true;
      newState.errors.confirmPassword = true;
      newState.errors.resetCode = true;
    }
    if (resetFields === 'all') {
      newState.values.email = '';
      newState.values.username = '';
      newState.values.betaToken = '';
      newState.values.newsletter = true;
      newState.errors.email = true;
      newState.errors.username = true;
      newState.errors.betaToken = true;
    }
    this.setState(newState);
  }

  onChangeSuccess(fieldName, value) {
    this.setState({
      values: {
        ...this.state.values,
        [fieldName]: value,
      },
      errors: {
        ...this.state.errors,
        [fieldName]: false,
      },
    });
  }

  onChangeFailure(fieldName) {
    this.setState({
      errors: {
        ...this.state.errors,
        [fieldName]: true,
      },
    });
  }

  onCheckboxChange(fieldName) {
    this.setState({
      values: {
        ...this.state.values,
        [fieldName]: !this.state.values[fieldName],
      },
    });
  }

  focusField(fieldName) {
    this.setState({ focusField: fieldName }, () => {
      this.setState({ focusField: null });
    });
  }

  onLogInClick(e) {
    e.preventDefault();
    if (!this.state.values.email) {
      this.focusField('email');
    } else if (!this.state.values.password) {
      this.focusField('password');
    } else {
      const { email, password } = this.state.values;
      this.props.loginRequest(email, password);
    }
  }

  onRegisterClick(e) {
    e.preventDefault();
    const focusField = ValidateUtils.getNextFormErrorName(
      Fields.register,
      this.state.errors
    );
    if (focusField) {
      this.focusField(focusField);
    } else {
      const { email, username, password, betaToken, newsletter } =
        this.state.values;
      this.props.signUpRequest(
        username,
        email,
        password,
        betaToken,
        newsletter
      );
    }
  }

  onRequestPasswordResetClick(e) {
    e.preventDefault();
    const focusField = ValidateUtils.getNextFormErrorName(
      Fields.forgotPassword,
      this.state.errors
    );
    if (focusField) {
      this.focusField(focusField);
    } else {
      this.props.requestPasswordReset(this.state.values.email);
      this.changeTab('resetPassword', 'password');
    }
  }

  onResetPasswordClick(e) {
    e.preventDefault();
    const focusField = ValidateUtils.getNextFormErrorName(
      Fields.resetPassword,
      this.state.errors
    );
    if (focusField) {
      this.focusField(focusField);
    } else {
      this.props.resetPasswordRequest(
        this.state.values.email,
        this.state.values.resetCode,
        this.state.values.password
      );
    }
  }

  getToolTipText(fieldName) {
    if (this.state.currentTab !== 'register') return null;
    switch (fieldName) {
      case 'email': {
        return tooltips.email;
      }
      case 'username': {
        return tooltips.username;
      }
      case 'password': {
        return tooltips.password;
      }
      default: {
        return '';
      }
    }
  }

  renderTabButtons() {
    return (
      <TabButtons>
        <TabButtonWrapper>
          {this.renderTabButton('Login', 'login')}
        </TabButtonWrapper>
        <TabButtonWrapper>
          {this.renderTabButton('Register', 'register')}
        </TabButtonWrapper>
      </TabButtons>
    );
  }

  renderTabButton(label, tabName) {
    return (
      <TabButton
        noBorder
        height='1.4rem'
        onClick={() => this.changeTab(tabName, 'all')}
        active={this.state.currentTab === tabName}>
        {label}
      </TabButton>
    );
  }

  renderNewsletterCheckbox() {
    return (
      <Checkbox
        label='Subscribe to our newsletter'
        onChange={() => this.onCheckboxChange('newsletter')}
        value={this.state.values.newsletter}
      />
    );
  }

  renderCurrentTab() {
    switch (this.state.currentTab) {
      case 'login': {
        return this.renderLoginTab();
      }
      case 'register': {
        return this.renderRegisterTab();
      }
      case 'forgotPassword': {
        return this.renderForgotPasswordTab();
      }
      case 'resetPassword': {
        return this.renderResetPasswordTab();
      }
      default: {
        return this.renderLoginTab();
      }
    }
  }

  renderLoginTab() {
    return (
      <Tab onSubmit={(e) => this.onLogInClick(e)}>
        <TabTitleWrapper>
          <Subtitle1>Sign in to Canvas</Subtitle1>
          <Caption grey>Mosaic’s 3D printing software platform</Caption>
        </TabTitleWrapper>
        <InputWrapper>
          {this.renderFields(Fields.login)}
          <Checkbox
            label='Remember me'
            value={!this.props.autoLogout}
            onChange={(e) => this.props.setAutoLogout(!e.target.checked)}
          />
        </InputWrapper>

        <LoginTabFooter>
          <MainButtonWrapper>
            <Button primary onClick={(e) => this.onLogInClick(e)}>
              Login
            </Button>
          </MainButtonWrapper>
          <LinkButtonWrapper>
            <Button
              link
              onClick={() => this.changeTab('forgotPassword', 'password')}>
              Forgot password?
            </Button>
          </LinkButtonWrapper>
        </LoginTabFooter>
      </Tab>
    );
  }

  renderRegisterTab() {
    return (
      <Tab onSubmit={(e) => this.onRegisterClick(e)}>
        <TabTitleWrapper>
          <Subtitle1>Register for Canvas</Subtitle1>
        </TabTitleWrapper>

        <InputWrapper>
          {this.renderFields(Fields.register)}
          {this.renderNewsletterCheckbox()}
        </InputWrapper>

        <MainButtonWrapper>
          <Button primary onClick={(e) => this.onRegisterClick(e)}>
            Register
          </Button>
        </MainButtonWrapper>
        <Caption grey>
          By clicking the Register button, you accept our{' '}
          <Link external href={Routes.toTermsOfService()}>
            Terms of Service
          </Link>{' '}
          and our{' '}
          <Link external href={Routes.toPrivacyPolicy()}>
            Privacy Policy
          </Link>
        </Caption>
      </Tab>
    );
  }

  renderForgotPasswordTab() {
    return (
      <Tab onSubmit={(e) => this.onRequestPasswordResetClick(e)}>
        <TabTitleWrapper>
          <Subtitle1>Forgot Password</Subtitle1>
          <Caption grey>
            Enter your username or email address. We&apos;ll email you a code
            that you can use to reset your password.
          </Caption>
        </TabTitleWrapper>
        {this.renderFields(Fields.forgotPassword)}
        <MainButtonWrapper>
          <Button
            primary
            title='Request password reset code'
            onClick={(e) => this.onRequestPasswordResetClick(e)}>
            Next
          </Button>
        </MainButtonWrapper>
        <LinkButtonWrapper>
          <Button
            link
            onClick={() => this.changeTab('resetPassword', 'password')}>
            Already received a reset code?
          </Button>
        </LinkButtonWrapper>
      </Tab>
    );
  }

  renderResetPasswordTab() {
    return (
      <Tab onSubmit={(e) => this.onResetPasswordClick(e)}>
        <TabTitleWrapper>
          <Subtitle1 left bold large>
            Reset Password
          </Subtitle1>
          <Caption grey>
            Enter the reset code that we emailed you and set a new password.
          </Caption>
        </TabTitleWrapper>

        <InputWrapper>{this.renderFields(Fields.resetPassword)}</InputWrapper>
        <MainButtonWrapper>
          <Button
            primary
            title='Reset password'
            onClick={(e) => this.onResetPasswordClick(e)}>
            Submit
          </Button>
        </MainButtonWrapper>
      </Tab>
    );
  }

  renderFields(fields) {
    return _.map(fields, (field, fieldName) =>
      this.renderField(field, fieldName)
    );
  }

  renderField(field, fieldName) {
    switch (field.type) {
      case fieldTypes.text:
        return this.renderTextField(field, fieldName);
      case fieldTypes.email:
        return this.renderEmailField(field, fieldName);
      case fieldTypes.password:
        return this.renderPasswordField(field, fieldName);
      default:
        return null;
    }
  }

  renderTextField(field, fieldName) {
    let autoCapitalize = true;
    if (field.autoCapitalize !== undefined) {
      ({ autoCapitalize } = field);
    }
    return (
      <Input
        type='text'
        key={`${this.state.currentTab}_${fieldName}`}
        forceFocus={this.state.focusField === fieldName}
        autoCapitalize={autoCapitalize}
        label={field.label}
        value={this.state.values[fieldName]}
        validator={field.validator}
        infoTooltip={this.getToolTipText(fieldName)}
        allowEmpty={true}
        onChangeSuccess={(value) => this.onChangeSuccess(fieldName, value)}
        onChangeFailure={() => this.onChangeFailure(fieldName)}
      />
    );
  }

  renderEmailField(field, fieldName) {
    return (
      <Input
        type='email'
        key={`${this.state.currentTab}_${fieldName}`}
        forceFocus={this.state.focusField === fieldName}
        label={field.label}
        value={this.state.values[fieldName]}
        infoTooltip={this.getToolTipText(fieldName)}
        allowEmpty={true}
        onChangeSuccess={(value) => this.onChangeSuccess(fieldName, value)}
        onChangeFailure={() => this.onChangeFailure(fieldName)}
      />
    );
  }

  renderPasswordField(field, fieldName) {
    return (
      <Input
        type='password'
        key={`${this.state.currentTab}_${fieldName}`}
        forceFocus={this.state.focusField === fieldName}
        autoComplete={
          this.state.currentTab === 'login'
            ? 'current-password'
            : 'new-password'
        }
        label={field.label}
        value={this.state.values[fieldName]}
        validator={(value) => field.validator(value, this.state)}
        infoTooltip={this.getToolTipText(fieldName)}
        onChangeSuccess={(value) => this.onChangeSuccess(fieldName, value)}
        onChangeFailure={() => this.onChangeFailure(fieldName)}
      />
    );
  }

  renderAuthPanel() {
    return (
      <AuthPanel>
        {this.renderTabButtons()}
        {this.renderCurrentTab()}
      </AuthPanel>
    );
  }

  renderPhotoPanel() {
    if (!this.state.imgUrl) {
      return (
        <PhotoPanel url={this.state.imgUrl}>
          <Progress circular />
        </PhotoPanel>
      );
    }
    return (
      <PhotoPanel url={this.state.imgUrl}>
        <LogoWrapper
          target='_blank'
          rel='noopener noreferrer'
          href={Links.mosaicHomepage}>
          <Image
            alt='Mosaic logo'
            src={this.props.theme.images.mosaicLogoIconWhite}
          />
        </LogoWrapper>
        <DiscoverCanvasButton
          target='_blank'
          rel='noopener noreferrer'
          href={Links.canvasHomepage}>
          <Caption noSpacing>Discover Canvas</Caption>
          <Icon size={Icon.sizes.SMALL} src={Icons.basic.linkExternal} />
        </DiscoverCanvasButton>
      </PhotoPanel>
    );
  }

  renderPanels() {
    return (
      <Panels>
        {this.renderAuthPanel()}
        {this.renderPhotoPanel()}
      </Panels>
    );
  }

  render() {
    const { match, isAuthenticated } = this.props;
    if (isAuthenticated && !match.pathname) {
      return <Redirect to={Routes.toManageProjects()} />;
    }
    return (
      <Container>
        {InterfaceUtils.getLoadingSpinner(this.props)}
        {this.renderPanels()}
      </Container>
    );
  }
}

export default withTheme(Home);

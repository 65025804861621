import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import { updateTowerPositionAndSize } from './updateTowerPositionAndSize';
import { getSlicerState, getPrinterState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

import {
  PrinterUtils,
  ProjectUtils,
  SlicerUtils,
  TowerUtils,
} from '../../../utils';

export default function* repositionTower(action) {
  const { tower, projectId, newStyle } = action.payload;
  const newTower = SlicerUtils.formatFields(tower);
  try {
    const {
      projects,
      models,
      transitionTower: existingTower,
    } = yield select(getSlicerState);
    // configure tower with new spec
    let newArea = newTower.size.x * newTower.size.y;
    if (newTower.brims) {
      newArea = newTower.brims.size.x * newTower.brims.size.y;
    }
    let existingArea = 0;
    if (existingTower) {
      if (existingTower.brims) {
        existingArea = existingTower.brims.size.x * existingTower.brims.size.y;
      } else {
        existingArea = existingTower.size.x * existingTower.size.y;
      }
    }
    if (!existingTower || existingArea < newArea) {
      const { printers } = yield select(getPrinterState);
      const project = projects[projectId];
      const printer = ProjectUtils.getProjectPrinter(project, printers);
      const printerProfile = PrinterUtils.formatMachineSettings(
        printer.machineSettings
      );
      const styleProfile = newStyle || project.style;
      const modelsBBox = SlicerUtils.getCombinedBoundingBox(models);

      const towerPadding = TowerUtils.getTowerPadding(styleProfile);
      modelsBBox.min.x -= towerPadding;
      modelsBBox.min.y -= towerPadding;
      modelsBBox.max.x += towerPadding;
      modelsBBox.max.y += towerPadding;

      const repositionedTower = TowerUtils.positionOnBed(
        modelsBBox,
        newTower,
        printerProfile,
        styleProfile
      );
      if (repositionedTower) {
        yield call(updateTowerPositionAndSize, repositionedTower, projectId);
      } else {
        // could not find good placement for tower; try running repack
        const formattedTower = SlicerUtils.formatFields(newTower);
        yield put(actions.repackRequest(formattedTower));
      }
    } else {
      yield put(actions.updateTower(newTower));
    }
  } catch (e) {
    yield put(actions.updateTower(newTower));
  }
}

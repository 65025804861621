import React from 'react';

const zOffset = (
  <>
    Offset all Z values in the print by this amount. Adjusting this setting can
    help with first layer adhesion or if your printer’s bed is not positioned at
    a Z height of 0. Positive values will will move the entire print up. Note
    that custom sequences are not affected by this setting.
  </>
);

const firstLayerSpeed = (
  <>
    Specify the speed to print the first layer. Slower speeds can help the
    plastic stick better to the print bed. However, this will also increase
    print time.
  </>
);

const firstLayerSizeCompensation = (
  <>
    <>
      Inset all perimeters on the first layer by this distance. This setting can
      be used to compensate for any squishing that may occur on the first layer.
    </>
    <>Only available when using Slic3r.</>
  </>
);

const useBrim = (
  <>
    Enable or disable the skirt or brim. A skirt is an extra line printed around
    the outside of the part and helps to prime the nozzle and clear out previous
    colors. A brim touches the outside of the part and helps to stop the model
    from warping or peeling.
  </>
);

const brimLoops = (
  <>Specify the number of outlines to be used for the skirt or brim.</>
);

const brimLayers = (
  <>
    Specify how many layers to print the skirt or brim. More layers will improve
    a brims adhesion to the part, however will make it more challenging to
    remove.
  </>
);

const brimGap = (
  <>
    Specify the distance away from the part to print the skirt. Setting this
    value to 0 will make a brim instead of a skirt.
  </>
);

const useRaft = (
  <>
    Enable or disable raft creation. A raft is a flat structure created under
    the models to help them stick to the print bed. After the print is complete,
    the raft can be detached from the models.
  </>
);

const raftXYInflation = (
  <>
    Specify the amount that the raft should extend horizontally past the outside
    of the models.
  </>
);

const lowerRaftSpeed = (
  <>
    <>Specify the print speed for the bottom layer of the raft.</>
    <>Only available when using KISSlicer.</>
  </>
);

const upperRaftSpeed = (
  <>
    <>Specify the print speed for the top layer of the raft.</>
    <>Only available when using KISSlicer.</>
  </>
);

const raftZGap = (
  <>
    <>
      Specify the height of the gap between the top of the raft and the bottom
      of the models. This gap helps the model to separate from the raft.
    </>
    <>Only available when using KISSlicer.</>
  </>
);

const lowerRaftLayerHeight = (
  <>
    <>Specify the layer height of the bottom layer of the raft.</>
    <>Only available when using KISSlicer.</>
  </>
);

const upperRaftLayerHeight = (
  <>
    <>Specify the layer height of the top layer of the raft.</>
    <>Only available when using KISSlicer.</>
  </>
);

const lowerRaftExtrusionWidth = (
  <>
    <>Specify the extrusion width of the bottom layer of the raft.</>
    <>Only available when using KISSlicer.</>
  </>
);

const upperRaftExtrusionWidth = (
  <>
    <>Specify the extrusion width of the top layer of the raft.</>
    <>Only available when using KISSlicer.</>
  </>
);

const lowerRaftDensity = (
  <>Specify the density of print lines for the bottom layer of the raft.</>
);

const upperRaftDensity = (
  <>
    <>Specify the density of print lines for the top layer of the raft.</>
    <>Only available when using KISSlicer.</>
  </>
);

const useRaftInterfaces = (
  <>
    <>
      If checked, support interfaces will be placed between the top of the raft
      and the bottom of the model. This can be useful if printing with rafts as
      well as soluble support material, allowing less soluble material to be
      used overall.
    </>
    <>Only available when using KISSlicer.</>
  </>
);

export default {
  zOffset,
  firstLayerSpeed,
  firstLayerSizeCompensation,
  useBrim,
  brimLoops,
  brimLayers,
  brimGap,
  useRaft,
  raftXYInflation,
  lowerRaftSpeed,
  upperRaftSpeed,
  raftZGap,
  lowerRaftLayerHeight,
  upperRaftLayerHeight,
  lowerRaftExtrusionWidth,
  upperRaftExtrusionWidth,
  lowerRaftDensity,
  upperRaftDensity,
  useRaftInterfaces,
};

import 'regenerator-runtime/runtime';
import { call, put, select, all } from 'redux-saga/effects';
import _ from 'lodash';

import API, { methods, handleBatchCallErrorResponses } from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

function* updateParentCall(projectId, parentId, existingProject) {
  try {
    const response = yield call(API, {
      method: methods.POST,
      path: `projects/${projectId}`,
      body: { parentId },
    });
    if (response === null) return null;
    const updatedProject = {
      ...existingProject,
      ...response.project,
    };
    return { status: 200, updatedProject };
  } catch (error) {
    return error;
  }
}

export default function* updateProjectsParent(action) {
  const { projectIds, parentId } = action.payload;
  const { projects } = yield select(getSlicerState);
  try {
    const responses = yield all(
      _.map(projectIds, (projectId) =>
        call(updateParentCall, projectId, parentId, projects[projectId])
      )
    );
    if (_.some(responses, (response) => response === null)) return;

    const updatedResponses = _.filter(
      responses,
      (response) => response.status === 200
    );
    const updatedProjects = _.map(
      updatedResponses,
      (response) => response.updatedProject
    );
    const updatedProjectsMap = _.keyBy(updatedProjects, 'id');

    yield put(actions.updateProjectsParentSuccess(updatedProjectsMap));

    handleBatchCallErrorResponses(responses);
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

const types = {
  RESET_IOT_STORE: 'IOT/RESET_IOT_STORE',

  INIT_CONNECTION_REQUEST: 'IOT/INIT_CONNECTION_REQUEST',
  INIT_CONNECTION_SUCCESS: 'IOT/INIT_CONNECTION_SUCCESS',
  INIT_CONNECTION_FAILURE: 'IOT/INIT_CONNECTION_FAILURE',

  CLOSE_CONNECTION: 'IOT/CLOSE_CONNECTION',

  SET_CONNECTED_STATUS: 'IOT/SET_CONNECTED_STATUS',

  RECEIVE_MESSAGE: 'IOT/RECEIVE_MESSAGE',

  GET_DEVICES_REQUEST: 'IOT/GET_DEVICES_REQUEST',
  GET_DEVICES_SUCCESS: 'IOT/GET_DEVICES_SUCCESS',
  GET_DEVICES_FAILURE: 'IOT/GET_DEVICES_FAILURE',

  UPDATE_DEVICE_REQUEST: 'IOT/UPDATE_DEVICE_REQUEST',
  UPDATE_DEVICE_SUCCESS: 'IOT/UPDATE_DEVICE_SUCCESS',
  UPDATE_DEVICE_FAILURE: 'IOT/UPDATE_DEVICE_FAILURE',

  ACTIVATE_DEVICE_REQUEST: 'IOT/ACTIVATE_DEVICE_REQUEST',
  ACTIVATE_DEVICE_SUCCESS: 'IOT/ACTIVATE_DEVICE_SUCCESS',
  ACTIVATE_DEVICE_FAILURE: 'IOT/ACTIVATE_DEVICE_FAILURE',

  UNLINK_DEVICES_REQUEST: 'IOT/UNLINK_DEVICES_REQUEST',
  UNLINK_DEVICES_SUCCESS: 'IOT/UNLINK_DEVICES_SUCCESS',
  UNLINK_DEVICES_FAILURE: 'IOT/UNLINK_DEVICES_FAILURE',

  MARK_PENDING_DEVICE_REQUEST: 'IOT/MARK_PENDING_DEVICE_REQUEST',
  CLEAR_PENDING_DEVICE_REQUEST: 'IOT/CLEAR_PENDING_DEVICE_REQUEST',

  SET_DEVICE_CONNECTED_STATUS: 'IOT/SET_DEVICE_CONNECTED_STATUS',

  CHECK_DEVICE_HEARTBEAT_REQUEST: 'IOT/CHECK_DEVICE_HEARTBEAT_REQUEST',
  CHECK_DEVICE_HEARTBEAT_SUCCESS: 'IOT/CHECK_DEVICE_HEARTBEAT_SUCCESS',
  CHECK_DEVICE_HEARTBEAT_FAILURE: 'IOT/CHECK_DEVICE_HEARTBEAT_FAILURE',

  GET_DEVICE_STATE_REQUEST: 'IOT/GET_DEVICE_STATE_REQUEST',
  GET_DEVICE_STATE_SUCCESS: 'IOT/GET_DEVICE_STATE_SUCCESS',
  GET_DEVICE_STATE_FAILURE: 'IOT/GET_DEVICE_STATE_FAILURE',

  UPDATE_DEVICE_STATE_REQUEST: 'IOT/UPDATE_DEVICE_STATE_REQUEST',
  UPDATE_DEVICE_STATE_SUCCESS: 'IOT/UPDATE_DEVICE_STATE_SUCCESS',
  UPDATE_DEVICE_STATE_FAILURE: 'IOT/UPDATE_DEVICE_STATE_FAILURE',

  SET_DEVICE_STORAGE_DRIVES: 'IOT/SET_DEVICE_STORAGE_DRIVES',
  SET_DEVICE_FILES_CONTEXT: 'IOT/SET_DEVICE_FILES_CONTEXT',

  CLEAR_DEVICE_STORAGE_DRIVES: 'IOT/CLEAR_DEVICE_STORAGE_DRIVES',
  CLEAR_DEVICE_FILES_CONTEXT: 'IOT/CLEAR_DEVICE_FILES_CONTEXT',

  SET_DEVICE_AVAILABLE_PORTS: 'IOT/SET_DEVICE_AVAILABLE_PORTS',

  CONNECT_PORT_REQUEST: 'IOT/CONNECT_PORT_REQUEST',
  CONNECT_PORT_SUCCESS: 'IOT/CONNECT_PORT_SUCCESS',
  CONNECT_PORT_FAILURE: 'IOT/CONNECT_PORT_FAILURE',

  DISCONNECT_PORT_REQUEST: 'IOT/DISCONNECT_PORT_REQUEST',
  DISCONNECT_PORT_SUCCESS: 'IOT/DISCONNECT_PORT_SUCCESS',
  DISCONNECT_PORT_FAILURE: 'IOT/DISCONNECT_PORT_FAILURE',

  SCAN_PORTS_REQUEST: 'IOT/SCAN_PORTS_REQUEST',
  SCAN_PORTS_SUCCESS: 'IOT/SCAN_PORTS_SUCCESS',
  SCAN_PORTS_FAILURE: 'IOT/SCAN_PORTS_FAILURE',

  START_PRINT_REQUEST: 'IOT/START_PRINT_REQUEST',
  START_PRINT_SUCCESS: 'IOT/START_PRINT_SUCCESS',
  START_PRINT_FAILURE: 'IOT/START_PRINT_FAILURE',

  PAUSE_PRINT_REQUEST: 'IOT/PAUSE_PRINT_REQUEST',
  PAUSE_PRINT_SUCCESS: 'IOT/PAUSE_PRINT_SUCCESS',
  PAUSE_PRINT_FAILURE: 'IOT/PAUSE_PRINT_FAILURE',

  RESUME_PRINT_REQUEST: 'IOT/RESUME_PRINT_REQUEST',
  RESUME_PRINT_SUCCESS: 'IOT/RESUME_PRINT_SUCCESS',
  RESUME_PRINT_FAILURE: 'IOT/RESUME_PRINT_FAILURE',

  CANCEL_PRINT_REQUEST: 'IOT/CANCEL_PRINT_REQUEST',
  CANCEL_PRINT_SUCCESS: 'IOT/CANCEL_PRINT_SUCCESS',
  CANCEL_PRINT_FAILURE: 'IOT/CANCEL_PRINT_FAILURE',

  GET_STORAGE_DRIVES_REQUEST: 'IOT/GET_STORAGE_DRIVES_REQUEST',
  GET_STORAGE_DRIVES_SUCCESS: 'IOT/GET_STORAGE_DRIVES_SUCCESS',
  GET_STORAGE_DRIVES_FAILURE: 'IOT/GET_STORAGE_DRIVES_FAILURE',

  GET_DRIVE_PATH_FILES_REQUEST: 'IOT/GET_DRIVE_PATH_FILES_REQUEST',
  GET_DRIVE_PATH_FILES_SUCCESS: 'IOT/GET_DRIVE_PATH_FILES_SUCCESS',
  GET_DRIVE_PATH_FILES_FAILURE: 'IOT/GET_DRIVE_PATH_FILES_FAILURE',

  EDIT_DEVICE_FILE_PATH_REQUEST: 'IOT/EDIT_DEVICE_FILE_PATH_REQUEST',
  EDIT_DEVICE_FILE_PATH_SUCCESS: 'IOT/EDIT_DEVICE_FILE_PATH_SUCCESS',
  EDIT_DEVICE_FILE_PATH_FAILURE: 'IOT/EDIT_DEVICE_FILE_PATH_FAILURE',

  FORGET_DEVICE_FILES: 'IOT/FORGET_DEVICE_FILES',

  MOVE_EXTRUDER_REQUEST: 'IOT/MOVE_EXTRUDER_REQUEST',
  MOVE_EXTRUDER_SUCCESS: 'IOT/MOVE_EXTRUDER_SUCCESS',
  MOVE_EXTRUDER_FAILURE: 'IOT/MOVE_EXTRUDER_FAILURE',

  HOME_EXTRUDER_REQUEST: 'IOT/HOME_EXTRUDER_REQUEST',
  HOME_EXTRUDER_SUCCESS: 'IOT/HOME_EXTRUDER_SUCCESS',
  HOME_EXTRUDER_FAILURE: 'IOT/HOME_EXTRUDER_FAILURE',

  FEED_FILAMENT_REQUEST: 'IOT/FEED_FILAMENT_REQUEST',
  FEED_FILAMENT_SUCCESS: 'IOT/FEED_FILAMENT_SUCCESS',
  FEED_FILAMENT_FAILURE: 'IOT/FEED_FILAMENT_FAILURE',

  CONTROL_FAN_REQUEST: 'IOT/CONTROL_FAN_REQUEST',
  CONTROL_FAN_SUCCESS: 'IOT/CONTROL_FAN_SUCCESS',
  CONTROL_FAN_FAILURE: 'IOT/CONTROL_FAN_FAILURE',

  CONTROL_MOTOR_REQUEST: 'IOT/CONTROL_MOTOR_REQUEST',
  CONTROL_MOTOR_SUCCESS: 'IOT/CONTROL_MOTOR_SUCCESS',
  CONTROL_MOTOR_FAILURE: 'IOT/CONTROL_MOTOR_FAILURE',

  SEND_COMMAND_REQUEST: 'IOT/SEND_COMMAND_REQUEST',
  SEND_COMMAND_SUCCESS: 'IOT/SEND_COMMAND_SUCCESS',
  SEND_COMMAND_FAILURE: 'IOT/SEND_COMMAND_FAILURE',

  CLEAR_COMMAND_HISTORY: 'IOT/CLEAR_COMMAND_HISTORY',

  SET_TARGET_TEMPERATURE_REQUEST: 'IOT/SET_TARGET_TEMPERATURE_REQUEST',
  SET_TARGET_TEMPERATURE_SUCCESS: 'IOT/SET_TARGET_TEMPERATURE_SUCCESS',
  SET_TARGET_TEMPERATURE_FAILURE: 'IOT/SET_TARGET_TEMPERATURE_FAILURE',

  INIT_WEBRTC_CONNECTION_REQUEST: 'IOT/INIT_WEBRTC_CONNECTION_REQUEST',
  INIT_WEBRTC_CONNECTION_SUCCESS: 'IOT/INIT_WEBRTC_CONNECTION_SUCCESS',
  INIT_WEBRTC_CONNECTION_FAILURE: 'IOT/INIT_WEBRTC_CONNECTION_FAILURE',
  ESTABLISH_WEBRTC_CONNECTION_REQUEST:
    'IOT/ESTABLISH_WEBRTC_CONNECTION_REQUEST',
  ESTABLISH_WEBRTC_CONNECTION_SUCCESS:
    'IOT/ESTABLISH_WEBRTC_CONNECTION_SUCCESS',
  ESTABLISH_WEBRTC_CONNECTION_FAILURE:
    'IOT/ESTABLISH_WEBRTC_CONNECTION_FAILURE',
  WEBRTC_KEEP_ALIVE_REQUEST: 'IOT/WEBRTC_KEEP_ALIVE_REQUEST',
  WEBRTC_KEEP_ALIVE_SUCCESS: 'IOT/WEBRTC_KEEP_ALIVE_SUCCESS',
  WEBRTC_KEEP_ALIVE_FAILURE: 'IOT/WEBRTC_KEEP_ALIVE_FAILURE',
  REMOVE_WEBRTC_CONNECTION_OFFER: 'IOT/REMOVE_WEBRTC_CONNECTION_OFFER',
};

export default types;

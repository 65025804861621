import _ from 'lodash';
import { withTheme } from 'styled-components';
import * as THREE from 'three';
import React, { Component } from 'react';
import { sphereIntersectTriangle } from 'three-mesh-bvh/src/Utils/MathUtilities';

import {
  AutoSegmentButtonWrapper,
  ToolboxContainer,
  ToolboxContent,
  ViewButtonsContainer,
  ViewButtonsMobileContainer,
  ControlButtonsContainer,
  UndoRedoButtonsContainer,
  MobileToolbarLeftWrapper,
  ToolboxMobileContainer,
  ExtruderButtonsContainer,
  StampControlContainer,
  StampControlPanelWrapper,
  StampList,
  PlaceholderWrapper,
  StampItem,
  StampLabelWrapper,
  ViewButtonWrapper,
  StampControlMainButtonWrapper,
  UndoRedoButtonWrapper,
  CancelSaveButtonMobileWrapper,
  IconWrapper,
  BrushSizeWrapper,
  AutoSegmentMenuWrapper,
} from './paintView.styles';

import HeaderBar from '../../../shared/page/headerBar/headerBar.jsx';
import MaterialTool from '../placeView/tools/material/material.container';
import BrushSize from './tools/brush/brushSize.jsx';
import StampPrompt from './tools/stamp/stampPrompt.jsx';
import AutoSegmentMenu from './tools/autoSegment/autoSegment.jsx';

import {
  Body1,
  ColorSwatch,
  ToolCollapsiblePanel,
  ConfirmationModal,
  Button,
  Icon,
  ActionButton,
} from '../../../shared';

import {
  FacesUtils,
  InterfaceUtils,
  SceneUtils,
  SlicerUtils,
  StampUtils,
  TextureUtils,
} from '../../../utils';

import UndoManager from '../../../classes/UndoManager';
import { Icons } from '../../../themes';
import types from '../../../reducers/slicer/types';
import { getWorker } from '../../../sagas/slicer/models/useFacesWorker';
import {
  getIntersectionForPainting,
  updateMouse,
} from '../../../sagas/three/raycast';
import { setRenderFlag } from '../../../sagas/three/animationFrame';

// warn users when auto-segmenting models with at least this many triangles
const TRI_COUNT_WARN_THRESHOLD = 500000;

class PaintView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalStampIds: _.keys(props.currentModel.stamps),
      originalRLE: props.currentModel.rle,
      activeExtruder: true,
      currentExtruderIndex: 0,
      prevExtruderIndex: -1,
      disableBoundariesToggle: true,
      showAutoSegmentControl: false,
      prevShowSegmentBoundaries: false,
      showAutoSegmentWarningModal: false,
      autoSegmentParams: null,
      showToolContent: true,
      isOnSmallDevice: false,
      facesWithPrevColors: [],
      facesWithNextColors: [],
      savingForExit: false,
      stampToDelete: null,
      showBasicControlMobile: false,
      showMaterialContentMobile: false,
      showAutoSegmentContent: false,
      showCancelModal: false,
      showStampPrompt: false,
      stampStep: '',
      showDeleteModal: false,
      stampStates: _.reduce(
        props.currentModel.mesh.children,
        (stamps, stampGroup) => {
          const [currentStamp] = stampGroup.children;
          return {
            ...stamps,
            [currentStamp.uuid]: {
              visible: stampGroup.visible,
              // TODO: add future property for naming stamps here?
            },
          };
        },
        {}
      ),
      hasUndo: false,
      hasRedo: false,
      toolboxNavigation: [],
    };
    this.intersection = null;
    this.boundaries = null;
    this.undoManager = new UndoManager();
    this.brush = SceneUtils.createBrush();
    this.dot = SceneUtils.createDot();
    this.sphereMarker = SceneUtils.createSphereMarker();
    this.canvas = null;
    this.onUnload = this.onUnload.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  updateStampStep(stampStep) {
    this.setState({ stampStep });
  }

  isUndoRedoDisabled() {
    return (
      this.props.buildFacesPending ||
      this.props.autoSegmentPending ||
      this.props.resetRegionsPending ||
      this.state.savingForExit
    );
  }

  isUndoDisabled() {
    return this.isUndoRedoDisabled() || !this.state.hasUndo;
  }

  isRedoDisabled() {
    return this.isUndoRedoDisabled() || !this.state.hasRedo;
  }

  isDotVisible() {
    const { type } = this.props.brush;
    return type === 'facet' || type === 'sphere' || type === 'fill';
  }

  isBrushVisible() {
    const { type } = this.props.brush;
    return type === 'sphere';
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload);
    window.addEventListener('mousedown', this.onMouseDown);
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
    window.addEventListener('touchstart', this.onTouchStart);
    window.addEventListener('touchend', this.onTouchEnd);
    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('keyup', this.onKeyUp);
    window.addEventListener('resize', this.onResize);
    this.onResize();

    this.props.setCurrentTool('facet');
    this.props.addBrush(this.brush, this.dot);
    this.props.registerBrush(this.brush);
    const rgba = this.props.project.colors[this.state.currentExtruderIndex];
    const hexString = SlicerUtils.rgbaArrayToHexString(rgba);
    this.props.selectBrushColor(hexString);
    this.props.removeModelsFromScene();
    this.props.removeTowerFromScene();
    this.props.hidePrintBed();
    this.props.addModelToScene(this.props.currentModel);
    this.props.buildFaces(this.props.currentModel.id);
    this.setupWorker();

    // generate atlas
    this.generateAtlas();

    this.configureUndoManager();
  }

  componentWillUnmount() {
    this.undoManager = null;
    window.removeEventListener('beforeunload', this.onUnload);
    window.removeEventListener('mousedown', this.onMouseDown);
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
    window.removeEventListener('touchstart', this.onTouchStart);
    if (this.canvas)
      this.canvas.removeEventListener('touchmove', this.onTouchMove);
    window.removeEventListener('touchend', this.onTouchEnd);
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('keyup', this.onKeyUp);
    window.removeEventListener('resize', this.onResize);
    this.props.cancelPaintOperation();
    this.props.setCurrentTool(null);
    this.props.removeBrush(this.brush, this.dot);
    this.props.removeBoundaries(this.boundaries);
    this.props.removeModelFromScene(this.props.currentModel);
    this.props.showPrintBed();
    this.props.addModelsToScene();
    if (this.props.transitionTower) {
      this.props.addTowerToScene(this.props.transitionTower);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentTool, showSegmentBoundaries, brush } = this.props;
    const { prevShowSegmentBoundaries } = this.state;

    if (this.isUndoRedoDisabled()) {
      document.body.style.cursor = 'progress';
    } else {
      document.body.style.cursor = 'initial';
    }

    if (
      prevProps.saveModelPaintDataPending &&
      !this.props.saveModelPaintDataPending
    ) {
      if (this.props.saveModelPaintDataSuccess && this.state.savingForExit) {
        this.props.onExitView();
      } else if (this.state.autoSegmentParams) {
        const { angleTri, angleSegment, minFace } =
          this.state.autoSegmentParams;
        this.setState({
          showAutoSegmentWarningModal: false,
          autoSegmentParams: null,
        });
        if (this.props.saveModelPaintDataSuccess) {
          this.props.autoSegment(angleTri, angleSegment, minFace);
        }
      }
    }

    if (currentTool === 'sphere' || currentTool === 'fill') {
      if (showSegmentBoundaries !== prevShowSegmentBoundaries) {
        // toggled segment boundaries
        // update prev show segment boundaries
        this.setState({ prevShowSegmentBoundaries: showSegmentBoundaries });
      }
    }

    if (
      (currentTool === 'material' || currentTool === 'stamp') &&
      brush.type !== ''
    ) {
      this.props.selectBrushType('');
    }
    if (prevProps.brush !== this.props.brush) {
      const { size } = this.props.brush;
      this.brush.scale.set(size, size, size);
    }
    if (this.props.brush.type === '') {
      this.brush.visible = false;
      this.dot.visible = false;
    } else if (prevProps.brush.type !== this.props.brush.type) {
      this.brush.visible = this.isBrushVisible();
      this.dot.visible = this.isDotVisible();
    }
    // check if mobile toolbar is rendered
    if (prevState.isOnSmallDevice !== this.state.isOnSmallDevice) {
      this.setState({
        showBasicControlMobile: false,
        showMaterialContentMobile: false,
        toolboxNavigation: [],
      });
      this.selectTool(null);
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.isPainting) {
      return false;
    }
    return true;
  }

  onUnload(e) {
    if (this.state.hasUndo || this.state.hasRedo) {
      e.returnValue = 'Leave site? Changes you made may not be saved.';
    }
  }

  onKeyUp(e) {
    if (
      (e.key === 'z' && (e.ctrlKey || e.metaKey) && e.shiftKey) ||
      (e.key === 'y' && (e.ctrlKey || e.metaKey))
    ) {
      // Redo -- Ctrl+Shift+Z or Ctrl+Y
      if (!this.isRedoDisabled()) {
        this.undoManager.redo();
      }
    } else if (e.key === 'z' && (e.ctrlKey || e.metaKey)) {
      // Undo -- Ctrl+Z
      if (!this.isUndoDisabled()) {
        this.undoManager.undo();
      }
    }
  }

  onPointerDown(e) {
    const { brush, currentModel } = this.props;

    // if does not meet conditions for painting, don't do anything;
    const onCanvas = e.target.nodeName === 'CANVAS';
    if (!onCanvas) return;
    const modifierKeyPressed = e.altKey || e.ctrlKey || e.metaKey;
    if (
      modifierKeyPressed ||
      !currentModel ||
      !this.intersection ||
      this.props.resetRegionsPending ||
      !this.isDotVisible()
    ) {
      return;
    }

    // otherwise, proceed to relevant paint mode
    this.props.togglePainting(true);
    this.props.disableControls();
    if (brush.type === 'fill') {
      if (!e.shiftKey) {
        this.colorFillRegion();
      } else {
        this.colorFillAll();
      }
    } else if (brush.type === 'facet') {
      this.colorFacetMode();
    } else if (brush.type === 'sphere') {
      getWorker().postMessage({
        type: types.MARK_INTERSECTED_SEGMENT,
        payload: {
          faceIndex: this.intersection.faceIndex,
        },
      });
      this.colorSphereMode();
    }
  }

  onResize() {
    const mobileToolbarHeight =
      document.getElementById('mobileToolbar').clientHeight;
    this.setState({ isOnSmallDevice: mobileToolbarHeight !== 0 });
  }

  onMouseDown(e) {
    if (e.button > 0) return;
    this.onPointerDown(e);
  }

  onTouchStart(e) {
    e.stopPropagation();
    if (e.touches.length > 1) return;
    const onCanvas = e.target.nodeName === 'CANVAS';
    if (!onCanvas) return;
    this.canvas = e.target;
    this.intersection = getIntersectionForPainting(
      this.props.currentModel.mesh
    );
    this.onPointerDown(e);
    this.canvas.addEventListener('touchmove', this.onTouchMove);
  }

  onPointerMove() {
    const intersection = getIntersectionForPainting(
      this.props.currentModel.mesh
    );
    if (intersection) {
      this.brush.position.copy(intersection.point);
      this.brush.visible = this.isBrushVisible();
      this.dot.position.copy(intersection.point);
      this.dot.visible = this.isDotVisible();
    } else {
      this.brush.visible = false;
      this.dot.visible = false;
    }
    setRenderFlag();
    this.intersection = intersection;
    const { isPainting, brush } = this.props;
    if (!isPainting || !intersection) return;
    if (brush.type === 'facet') {
      this.colorFacetMode();
    } else if (brush.type === 'sphere') {
      this.colorSphereMode();
    }
  }

  onMouseMove() {
    this.onPointerMove();
  }

  onTouchMove(e) {
    updateMouse(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
    this.onPointerMove();
  }

  onPointerUp() {
    const { facesWithPrevColors, facesWithNextColors } = this.state;
    const { isPainting, brush } = this.props;
    if (!isPainting) return;
    this.registerUndoRedo(facesWithPrevColors, facesWithNextColors);
    if (brush.type === 'fill') {
      this.props.resetRegions();
    }
    this.props.togglePainting(false);
    this.props.enableControls();
    getWorker().postMessage({ type: types.UNMARK_INTERSECTED_SEGMENT });
    this.setState({
      facesWithPrevColors: [],
      facesWithNextColors: [],
    });
  }

  onMouseUp() {
    this.onPointerUp();
  }

  onTouchEnd() {
    this.onPointerUp();
    this.brush.visible = false;
    this.dot.visible = false;
    if (this.canvas)
      this.canvas.removeEventListener('touchmove', this.onTouchMove);
  }

  onKeyDown(e) {
    const { project, materials } = this.props;
    if (!project || _.isEmpty(materials)) return;
    const extruderNumbers = new Array(project.colors.length)
      .fill(0)
      .map((val, index) => (index + 1).toString());
    if (extruderNumbers.includes(e.key)) {
      const index = Number(e.key) - 1;
      const material = materials[project.materialIds[index]];
      const hex = SlicerUtils.rgbaArrayToHexString(project.colors[index]);
      this.selectExtruderButton(hex, material, index);
    }
  }

  configureUndoManager() {
    this.undoManager.setCallback(() => {
      this.setState({
        hasUndo: this.undoManager.hasUndo(),
        hasRedo: this.undoManager.hasRedo(),
      });
    });
  }

  generateAtlas() {
    const { renderer } = this.props;

    const { maxTextureSize, maxTextures } = renderer.capabilities;

    const resolution = 10;
    this.props.generateAtlas(resolution, maxTextureSize, maxTextures);
  }

  setupWorker() {
    getWorker().onmessage = (event) => {
      const { type, payload } = event.data;
      const { currentModel } = this.props;
      const brushColorIndex = this.findBrushColorIndex();

      let facesWithPrevColors = [];
      let facesWithNextColors = [];

      if (type === 'error') {
        InterfaceUtils.emitToast('error', payload);
      }

      if (type === types.BUILD_FACES_JOB_FINISHED) {
        this.props.buildFacesSuccess();
      }

      if (type === types.AUTO_SEGMENT_JOB_FINISHED) {
        const { bufferAttribute } = payload;
        this.boundaries = FacesUtils.createSegmentBoundaries(bufferAttribute);
        this.setState(
          {
            showSegmentBoundaries: true,
            disableBoundariesToggle: false,
          },
          () => {
            this.props.addBoundaries(this.boundaries);
          }
        );

        this.props.autoSegmentSuccess();
      }

      if (type === types.RESET_REGIONS_JOB_STARTED) {
        this.props.disableControls();
      }

      if (type === types.RESET_REGIONS_JOB_FINISHED) {
        this.props.enableControls();
        this.props.resetRegionsSuccess();
      }

      if (
        type === types.FACES_TO_COLOR_FACET_MODE ||
        type === types.FACES_TO_COLOR_SPHERE_MODE ||
        type === types.FACES_TO_COLOR_FILL_REGION_MODE ||
        type === types.FACES_TO_COLOR_FILL_ALL_MODE
      ) {
        const [facesToColor] = payload;
        facesWithPrevColors = [];
        facesWithNextColors = [];
        for (let i = 0; i < facesToColor.length; i += 2) {
          facesWithPrevColors.push({
            index: facesToColor[i],
            color: facesToColor[i + 1],
          });
          this.colorFace(facesToColor[i]);
          facesWithNextColors.push({
            index: facesToColor[i],
            color: brushColorIndex,
          });
        }
        currentModel.mesh.geometry.attributes.color.needsUpdate = true;
        setRenderFlag();

        this.recordBeforeAfterFaceColors(
          facesWithPrevColors,
          facesWithNextColors
        );
      }

      if (type === types.COMPOSE_COLORS_RLE_JOB_FINISHED) {
        // this only updates the RLE in the front-end
        this.props.composeColorsRLESuccess(
          currentModel.id,
          payload.rle,
          payload.inPlace
        );

        // the RLE will be saved to the server when saving from paint view
        if (!payload.inPlace) {
          // paint data to be saved
          const paintData = {};

          // set model triangle colors
          paintData.rle = payload.rle;

          // model has stamps
          if (currentModel.mesh.children.length) {
            paintData.uvFile = this.getUVBinary();
            paintData.imgFile = this.getAtlasBinary();
            paintData.stampFiles = StampUtils.getStampFiles(currentModel);
          }
          this.props.savePaintData(currentModel.id, paintData);
        }
      }

      if (type === types.GENERATE_ATLAS_JOB_FINISHED) {
        const { atlasFaceUvs, dimensionsByAtlas, faceToAtlasMap } = payload;

        this.dimensionsByAtlas = dimensionsByAtlas;
        this.atlasUvs = new THREE.BufferAttribute(atlasFaceUvs, 2);

        // map faces to material
        const indices = currentModel.mesh.geometry.index;
        currentModel.mesh.geometry.clearGroups();
        for (let i = 0; i < indices.count / 3; i++) {
          const vertIndex = indices.getX(i * 3 + 0);
          const faceIndex = Math.floor(vertIndex / 3);
          const atlasIndex = faceToAtlasMap.get(faceIndex);
          currentModel.mesh.geometry.addGroup(i * 3, 3, atlasIndex);
        }
        this.props.generateAtlasSuccess();
      }
    };
  }

  findBrushColorIndex() {
    const { brush, project } = this.props;
    return _.findIndex(project.colors, (color) => {
      const hex = SlicerUtils.rgbaArrayToHexString(color);
      return hex.toLowerCase() === brush.color.toLowerCase();
    });
  }

  colorFacetMode() {
    const { faceIndex } = this.intersection;
    const brushColor = this.findBrushColorIndex();

    getWorker().postMessage({
      type: types.FACES_TO_COLOR_FACET_MODE,
      payload: {
        faceIndex,
        brushColor,
      },
    });
  }

  colorSphereMode() {
    const { brush, currentModel, showSegmentBoundaries } = this.props;
    const { point } = this.intersection;
    const { mesh } = currentModel;
    this.sphereMarker.set(point, brush.size);

    // Collect faces in sphere
    const collected = [];
    mesh.geometry.boundsTree.shapecast(
      mesh,
      (box) => {
        box.applyMatrix4(mesh.matrix);
        return this.sphereMarker.intersectsBox(box);
      },
      (tri, firstVertexIndex) => {
        tri.a.applyMatrix4(mesh.matrix);
        tri.b.applyMatrix4(mesh.matrix);
        tri.c.applyMatrix4(mesh.matrix);
        if (sphereIntersectTriangle(this.sphereMarker, tri)) {
          collected.push(
            Math.floor(mesh.geometry.index.array[firstVertexIndex] / 3)
          );
        }
        return false;
      }
    );

    const brushColor = this.findBrushColorIndex();

    // Send collected face indices to worker and filter out only relevant faces
    getWorker().postMessage({
      type: types.FACES_TO_COLOR_SPHERE_MODE,
      payload: {
        collected,
        showSegmentBoundaries,
        brushColor,
      },
    });
  }

  colorFillRegion() {
    const { showSegmentBoundaries } = this.props;
    const { faceIndex } = this.intersection;
    const brushColor = this.findBrushColorIndex();

    getWorker().postMessage({
      type: types.FACES_TO_COLOR_FILL_REGION_MODE,
      payload: {
        faceIndex,
        showSegmentBoundaries,
        brushColor,
      },
    });
  }

  colorFillAll() {
    const brushColor = this.findBrushColorIndex();

    getWorker().postMessage({
      type: types.FACES_TO_COLOR_FILL_ALL_MODE,
      payload: {
        brushColor,
      },
    });
  }

  colorGivenFaces(faceInfos) {
    const { currentModel } = this.props;
    const colorAttr = currentModel.mesh.geometry.attributes.color;

    getWorker().postMessage({
      type: types.COLOR_GIVEN_FACES,
      payload: {
        faceInfos,
      },
    });

    faceInfos.forEach(({ index, color }) => {
      colorAttr.setXYZ(index * 3, color, color, color);
    });

    colorAttr.needsUpdate = true;
    this.props.resetRegions();
  }

  colorFace(faceIndex, otherColor = null) {
    const { currentExtruderIndex } = this.state;
    const { brush, currentModel } = this.props;
    const colorAttr = currentModel.mesh.geometry.attributes.color;
    if (brush.color) {
      const extruderIndex = otherColor || currentExtruderIndex;
      colorAttr.setXYZ(
        faceIndex * 3,
        extruderIndex,
        extruderIndex,
        extruderIndex
      );
    }
  }

  recordBeforeAfterFaceColors(facesWithPrevColors, facesWithNextColors) {
    this.setState({
      facesWithPrevColors: [
        ...this.state.facesWithPrevColors,
        ...facesWithPrevColors,
      ],
      facesWithNextColors: [
        ...this.state.facesWithNextColors,
        ...facesWithNextColors,
      ],
    });
  }

  selectNullTool(index) {
    this.setState(
      {
        activeExtruder: false,
        currentExtruderIndex: -1,
        prevExtruderIndex: index,
        showStampPrompt: false,
      },
      () => {
        this.props.setCurrentTool(null);
        this.props.selectBrushType('');
      }
    );
  }

  deselectStampTool() {
    this.setState({
      showBasicControlMobile: true,
    });
    this.selectTool('');
  }

  selectTool(tool) {
    const { currentTool } = this.props;
    const { toolboxNavigation } = this.state;
    // toggle off tool or tool set to null. Do not toggle material tool.
    if ((tool === currentTool && tool !== 'material') || tool === '') {
      this.selectNullTool(this.state.currentExtruderIndex);
    } else {
      this.props.setCurrentTool(tool); // update current tool
      if (tool === 'stamp') {
        this.props.selectBrushType(''); // deselect brush type
        this.setState({
          activeExtruder: false, // hide extruder
          showBasicControlMobile: false,
          toolboxNavigation: [
            ...toolboxNavigation,
            () => this.deselectStampTool(),
          ],
        });
      } else if (tool === 'material') {
        this.props.selectBrushType(''); // deselect brush type
        this.setState({ activeExtruder: true }); // show extruder
      } else {
        // tool is a paint tool
        if (tool === 'fill') this.props.resetRegions();
        if (tool === 'sphere' || tool === 'fill')
          this.setState({ showAutoSegmentControl: true });
        this.props.selectBrushType(tool); // select brush type
        this.setState({ activeExtruder: true }); // show extruder
        if (this.state.currentExtruderIndex === -1) {
          this.setState({ currentExtruderIndex: this.state.prevExtruderIndex });
        }
      }
    }
    this.handleShowBoundaries(tool, currentTool);
  }

  handleShowBoundaries(tool, currentTool) {
    const { showSegmentBoundaries } = this.props;
    const { prevShowSegmentBoundaries } = this.state;
    if ((tool === 'sphere' || tool === 'fill') && tool !== currentTool) {
      // sphere or fill tool and not toggled
      this.setState({ showAutoSegmentControl: true }); // show auto segment panel
      if (currentTool !== 'sphere' || currentTool !== 'fill') {
        // current tool not sphere or fill
        // show prev segment boundaries
        if (prevShowSegmentBoundaries) {
          this.props.addBoundaries(this.boundaries);
        } else {
          this.props.removeBoundaries();
        }
      }
    } else {
      // not sphere and not fill tool or toggled
      this.setState({ showAutoSegmentControl: false }); // hide auto segment panel
      if (currentTool === 'sphere' || currentTool === 'fill') {
        // current tool is sphere or fill
        // update prev segment boundaries
        this.setState({ prevShowSegmentBoundaries: showSegmentBoundaries });
        this.props.removeBoundaries();
      }
    }
  }

  toggleMaterialTool() {
    const { showBasicControlMobile, showMaterialContentMobile } = this.state;
    this.setState({
      showMaterialContentMobile: !showMaterialContentMobile,
      showBasicControlMobile: !showBasicControlMobile,
    });
  }

  handleExtruderDoubleClick(index) {
    const {
      showBasicControlMobile,
      showMaterialContentMobile,
      toolboxNavigation,
    } = this.state;
    if (showBasicControlMobile && !showMaterialContentMobile) {
      this.toggleMaterialTool();
      this.setState({
        toolboxNavigation: [
          ...toolboxNavigation,
          () => this.toggleMaterialTool(),
        ],
      });
    }
    this.selectMaterial(index);
  }

  registerUndoRedo(facesWithPrevColors, facesWithNextColors) {
    // record BEFORE/AFTER face colors
    this.undoManager.add({
      undo: {
        data: facesWithPrevColors,
        fn: this.colorGivenFaces.bind(this),
      },
      redo: {
        data: facesWithNextColors,
        fn: this.colorGivenFaces.bind(this),
      },
    });
  }

  undo(e) {
    e.stopPropagation();
    this.undoManager.undo();
    setRenderFlag();
  }

  redo(e) {
    e.stopPropagation();
    this.undoManager.redo();
    setRenderFlag();
  }

  showCancelModal() {
    this.setState({ showCancelModal: true });
  }

  hideCancelModal() {
    this.setState({ showCancelModal: false });
  }

  showDeleteModal(stampMesh) {
    this.setState({
      showDeleteModal: true,
      stampToDelete: stampMesh,
    });
  }

  hideDeleteModal() {
    this.setState({
      showDeleteModal: false,
      stampToDelete: null,
    });
  }

  getUVBinary() {
    const { currentModel } = this.props;
    return TextureUtils.writeUVsAsBinary(
      this.atlasUvs,
      currentModel.mesh.geometry.groups
    );
  }

  getAtlasBinary() {
    const { currentModel, project, camera } = this.props;
    const atlasImages = TextureUtils.drawAtlasWithStamps(
      currentModel,
      project,
      camera,
      this.dimensionsByAtlas,
      this.atlasUvs
    );
    return TextureUtils.writeAtlasAsBinary(atlasImages, project.colors);
  }

  savePaint(exitAfter = true) {
    this.props.composeColorsRLEAndSave();
    this.setState({
      savingForExit: exitAfter,
    });
  }

  colorDataWasChanged() {
    return this.state.hasUndo || this.state.hasRedo;
  }

  stampDataWasChanged() {
    const { currentModel } = this.props;
    const { originalStampIds } = this.state;
    const stampIdsInScene = _.map(
      currentModel.mesh.children,
      (stampGroup) => stampGroup.children[0].name
    );
    return !_.isEmpty(_.xor(originalStampIds, stampIdsInScene));
  }

  cancelPaint() {
    if (this.colorDataWasChanged() || this.stampDataWasChanged()) {
      this.showCancelModal();
    } else {
      this.leavePaintView();
    }
  }

  leavePaintView() {
    this.hideCancelModal();
    this.props.restoreColorsWithRLE(
      this.props.currentModel.id,
      this.state.originalRLE
    );
    this.props.onExitView();
  }

  renderCancelModal() {
    if (!this.state.showCancelModal) return null;

    const paintDataChanged = this.colorDataWasChanged();
    const stampDataChanged = this.stampDataWasChanged();
    let cancelData = '';
    if (paintDataChanged && stampDataChanged) cancelData = 'paint and stamp';
    else if (paintDataChanged) cancelData = 'paint';
    else if (stampDataChanged) cancelData = 'stamp';

    return (
      <ConfirmationModal
        isWarning
        primaryLabel='You have unsaved changes'
        secondaryLabel={`Are you sure you would like to exit without saving your ${cancelData} data?`}
        cancelLabel='Close'
        confirmLabel='Exit'
        onClickCancel={() => this.hideCancelModal()}
        onClickConfirm={() => this.leavePaintView()}
      />
    );
  }

  renderPaintUndoRedoButtons() {
    const tooltipProps = {
      rightAlign: true,
      bottomDirection: true,
    };

    return (
      <UndoRedoButtonsContainer>
        <UndoRedoButtonWrapper>
          <ActionButton
            icon={Icons.basic.undo}
            title='Undo'
            tooltipProps={tooltipProps}
            onClick={(e) => this.undo(e)}
            disabled={this.isUndoDisabled()}
          />
        </UndoRedoButtonWrapper>
        <UndoRedoButtonWrapper>
          <ActionButton
            icon={Icons.basic.redo}
            title='Redo'
            tooltipProps={tooltipProps}
            onClick={(e) => this.redo(e)}
            disabled={this.isRedoDisabled()}
          />
        </UndoRedoButtonWrapper>
      </UndoRedoButtonsContainer>
    );
  }

  renderViewButtons() {
    return (
      <ViewButtonsContainer>
        <ViewButtonWrapper>
          <Button onClick={() => this.cancelPaint()}>Cancel</Button>
        </ViewButtonWrapper>
        <ViewButtonWrapper>
          <Button primary onClick={() => this.savePaint()}>
            Save and return
          </Button>
        </ViewButtonWrapper>
      </ViewButtonsContainer>
    );
  }

  renderViewButtonsMobile() {
    return (
      <ViewButtonsMobileContainer>
        <CancelSaveButtonMobileWrapper>
          <ActionButton
            warning
            icon={Icons.basic.x}
            onClick={() => this.cancelPaint()}
          />
        </CancelSaveButtonMobileWrapper>
        <CancelSaveButtonMobileWrapper>
          <ActionButton
            primary
            icon={Icons.basic.check}
            onClick={() => this.savePaint()}
          />
        </CancelSaveButtonMobileWrapper>
      </ViewButtonsMobileContainer>
    );
  }

  goBackCleanup() {
    // pop the stack and run the clean up function once back button is clicked
    const { toolboxNavigation } = this.state;
    toolboxNavigation.pop()();
    this.setState({
      toolboxNavigation: [...toolboxNavigation],
    });
  }

  goBackCleanupButton() {
    const { isOnSmallDevice } = this.state;
    if (!isOnSmallDevice) return null;
    return (
      <ActionButton
        onClick={() => this.goBackCleanup()}
        icon={Icons.basic.x}
        title='Close'
        minimal
      />
    );
  }

  renderMobileToolbarLeft() {
    const { toolboxNavigation } = this.state;
    if (toolboxNavigation.length !== 0) return null;
    return (
      <MobileToolbarLeftWrapper>
        <ActionButton
          onClick={() => this.toggleToolboxMobile()}
          icon={Icons.basic.pencil}
          title='Paint'
        />
      </MobileToolbarLeftWrapper>
    );
  }

  renderAutoSegmentControlMobile() {
    const { showAutoSegmentContent } = this.state;
    const isSmallScreen = true;
    if (showAutoSegmentContent) {
      return this.renderAutoSegmentControl(isSmallScreen);
    }
    return null;
  }

  renderAutoSegmentButton() {
    const {
      showAutoSegmentControl,
      showAutoSegmentContent,
      toolboxNavigation,
    } = this.state;

    const deSelectAutoSegment = () => {
      this.setState({
        showAutoSegmentContent: false,
        showBasicControlMobile: true,
      });
    };

    if (showAutoSegmentControl) {
      return (
        <AutoSegmentButtonWrapper>
          <ActionButton
            icon={Icons.project.autoSegment}
            title='Create Regions'
            primary={showAutoSegmentContent}
            onClick={() =>
              this.setState({
                showAutoSegmentContent: true,
                showBasicControlMobile: false,
                toolboxNavigation: [
                  ...toolboxNavigation,
                  () => deSelectAutoSegment(),
                ],
              })
            }
          />
        </AutoSegmentButtonWrapper>
      );
    }
    return null;
  }

  renderFacetButton(currentTool) {
    return (
      <ActionButton
        icon={Icons.project.triangle}
        title='Facet'
        primary={currentTool === 'facet'}
        onClick={() => this.selectTool('facet')}
      />
    );
  }

  renderSphereButton(currentTool) {
    return (
      <ActionButton
        icon={Icons.project.circle}
        title='Sphere'
        primary={currentTool === 'sphere'}
        onClick={() => this.selectTool('sphere')}
      />
    );
  }

  renderFillButton(currentTool) {
    return (
      <ActionButton
        icon={Icons.project.bucket}
        title='Fill'
        primary={currentTool === 'fill'}
        onClick={() => this.selectTool('fill')}
      />
    );
  }

  renderStampButton(currentTool) {
    return (
      <ActionButton
        icon={Icons.project.stamp}
        title='Stamp'
        primary={currentTool === 'stamp'}
        onClick={() => this.selectTool('stamp')}
      />
    );
  }

  renderPaintControls() {
    const { currentTool } = this.props;
    return (
      <ControlButtonsContainer>
        {this.renderFacetButton(currentTool)}
        {this.renderSphereButton(currentTool)}
        {this.renderFillButton(currentTool)}
        {this.renderStampButton(currentTool)}
      </ControlButtonsContainer>
    );
  }

  renderPaintControlsMobile() {
    const { currentTool } = this.props;
    return (
      <>
        <ControlButtonsContainer>
          {this.renderFacetButton(currentTool)}
          {this.renderSphereButton(currentTool)}
          {this.renderFillButton(currentTool)}
          {this.renderStampButton(currentTool)}
        </ControlButtonsContainer>
        {this.renderAutoSegmentButton()}
      </>
    );
  }

  renderExtruderButtons() {
    const { currentTool } = this.props;
    const { activeExtruder, currentExtruderIndex } = this.state;
    if (currentTool === 'stamp') {
      return null;
    }
    const { project, materials } = this.props;
    return (
      <ExtruderButtonsContainer>
        {_.map(project.materialIds, (materialId, index) => {
          const material = materials[materialId];
          const color = project.colors[index];
          const hex = SlicerUtils.rgbaArrayToHexString(color);
          return (
            <ColorSwatch
              key={index}
              label={index + 1}
              color={color}
              static={true}
              onDoubleClick={() => this.handleExtruderDoubleClick(index)}
              onSelect={() => this.selectExtruderButton(hex, material, index)}
              selected={activeExtruder && index === currentExtruderIndex}
            />
          );
        })}
      </ExtruderButtonsContainer>
    );
  }

  renderToolboxContent() {
    const content = this.renderMaterialContent();
    if (!content) return null;
    return <ToolboxContent>{content}</ToolboxContent>;
  }

  renderMaterialContent() {
    const { currentTool } = this.props;
    const { currentExtruderIndex, showToolContent, toolboxNavigation } =
      this.state;
    let content = null;
    if (
      currentTool === 'material' &&
      currentExtruderIndex > -1 &&
      showToolContent
    ) {
      content = (
        <MaterialTool
          currentView='paint'
          project={this.props.project}
          currentExtruderIndex={currentExtruderIndex}
          closeMaterialTool={() => {
            this.selectTool('');
            if (toolboxNavigation.length > 0) this.goBackCleanup();
          }}
        />
      );
    }
    return content;
  }

  selectMaterial(index) {
    this.selectTool('material');
    this.setState({ currentExtruderIndex: index });
  }

  selectExtruderButton(hex, material, index) {
    if (this.props.currentTool === 'material') {
      this.selectMaterial(index);
    } else if (this.props.brush.type !== '') {
      this.props.selectBrushColor(hex);
      this.props.selectMaterial(material);
      this.setState({
        activeExtruder: true,
        currentExtruderIndex: index,
      });
    } else {
      this.setState({
        activeExtruder: false,
        currentExtruderIndex: -1,
      });
    }
  }

  renderBrushSize() {
    const { currentTool, brush } = this.props;
    let brushSize = null;
    if (currentTool === 'sphere') {
      brushSize = (
        <BrushSizeWrapper>
          <BrushSize
            brush={brush}
            onSelectSize={(size) => this.props.selectBrushSize(size)}
          />
        </BrushSizeWrapper>
      );
    }
    return brushSize;
  }

  renderBasicControl() {
    return (
      <ToolCollapsiblePanel
        label='Paint'
        isCollapsed={false}
        scroll={false}
        onOpen={() => this.setState({ showToolContent: true })}
        onClose={() => this.setState({ showToolContent: false })}>
        {this.renderPaintControls()}
        {this.renderExtruderButtons()}
        {this.renderBrushSize()}
      </ToolCollapsiblePanel>
    );
  }

  renderBasicControlMobile() {
    const { showBasicControlMobile, showMaterialContentMobile } = this.state;
    let basicControlMobile = null;
    if (showBasicControlMobile) {
      basicControlMobile = (
        <ToolCollapsiblePanel
          label='Paint'
          isCollapsed={false}
          scroll={false}
          forceOpen={this.state.isOnSmallDevice}
          headerCustomButton={this.goBackCleanupButton()}
          onOpen={() => this.setState({ showToolContent: true })}
          onClose={() => this.setState({ showToolContent: false })}>
          {this.renderPaintControlsMobile()}
          {this.renderExtruderButtons()}
          {this.renderBrushSize()}
        </ToolCollapsiblePanel>
      );
    } else if (showMaterialContentMobile) {
      basicControlMobile = <>{this.renderMaterialContent()}</>;
    }
    return basicControlMobile;
  }

  toggleToolboxMobile() {
    const {
      showBasicControlMobile,
      showMaterialContentMobile,
      toolboxNavigation,
    } = this.state;

    if (showBasicControlMobile) {
      if (!showMaterialContentMobile) {
        this.setState({
          showBasicControlMobile: false,
        });
      }
    } else if (showMaterialContentMobile) {
      this.setState({
        showBasicControlMobile: true,
        showMaterialContentMobile: false,
        // add cleanup function once go back button is clicked
        toolboxNavigation: [
          ...toolboxNavigation,
          () => this.toggleToolboxMobile(),
        ],
      });
    } else {
      this.setState({
        showBasicControlMobile: true,
        toolboxNavigation: [
          ...toolboxNavigation,
          () => this.toggleToolboxMobile(),
        ],
      });
    }
    if (!showBasicControlMobile === false)
      this.setState({ showAutoSegmentMobile: false });
  }

  autoSegment(angleTri, angleSegment, minFace) {
    const { currentModel } = this.props;
    const vertCount = currentModel.mesh.geometry.attributes.position.count;
    const triCount = Math.floor(vertCount / 3);
    if (triCount >= TRI_COUNT_WARN_THRESHOLD) {
      this.setState({
        showAutoSegmentWarningModal: true,
        autoSegmentParams: {
          angleTri,
          angleSegment,
          minFace,
        },
      });
      return;
    }
    this.props.removeBoundaries(this.boundaries);
    this.props.autoSegment(angleTri, angleSegment, minFace);
  }

  renderAutoSegmentControl(isSmallScreen) {
    const { showSegmentBoundaries } = this.props;
    const {
      autoSegmentParams,
      showAutoSegmentControl,
      showAutoSegmentWarningModal,
      disableBoundariesToggle,
    } = this.state;
    if (showAutoSegmentControl) {
      return (
        <AutoSegmentMenuWrapper isSmallScreen={isSmallScreen}>
          <AutoSegmentMenu
            isCollapsed={!isSmallScreen}
            disabled={disableBoundariesToggle}
            showSegmentBoundaries={showSegmentBoundaries}
            showWarningModal={showAutoSegmentWarningModal}
            forceOpen={isSmallScreen}
            headerCustomButton={this.goBackCleanupButton()}
            onToggleBoundaries={(visible) =>
              this.toggleSegmentBoundaries(visible)
            }
            onAutoSegment={(angleTri, angleSegment, minFace) => {
              this.autoSegment(angleTri, angleSegment, minFace);
            }}
            onIgnoreWarning={() => {
              const { angleTri, angleSegment, minFace } = autoSegmentParams;
              this.setState(
                {
                  autoSegmentParams: null,
                  showAutoSegmentWarningModal: false,
                },
                () => {
                  this.props.autoSegment(angleTri, angleSegment, minFace);
                }
              );
            }}
            onAcceptWarning={() => {
              this.setState(
                {
                  showAutoSegmentWarningModal: false,
                },
                () => this.savePaint(false)
              );
            }}
            onCancelWarning={() =>
              this.setState({
                autoSegmentParams: null,
                showAutoSegmentWarningModal: false,
              })
            }
          />
        </AutoSegmentMenuWrapper>
      );
    }
    return null;
  }

  toggleStampPrompt() {
    this.setState({
      showStampPrompt: !this.state.showStampPrompt,
    });
  }

  toggleStampVisibility(stampGroup) {
    // eslint-disable-next-line no-param-reassign
    stampGroup.visible = !stampGroup.visible;

    // update stampStates to have component re-render immediately after changing the mesh visibility
    const [currentStamp] = stampGroup.children;
    this.setState(
      {
        stampStates: {
          ...this.state.stampStates,
          [currentStamp.uuid]: { visible: stampGroup.visible },
        },
      },
      () => {
        setRenderFlag();
      }
    );
  }

  deleteStamp(stampGroup) {
    const { currentModel } = this.props;
    SlicerUtils.removeStampGroup(stampGroup, currentModel.mesh);
    SlicerUtils.updateStampOrder(currentModel.mesh);
    this.hideDeleteModal();
    setRenderFlag();
  }

  renderStampItem(stampMesh, index) {
    return (
      <StampItem key={index} visible={stampMesh.visible}>
        <StampLabelWrapper>
          <Body1 grey={!stampMesh.visible}>Stamp {index + 1}</Body1>
        </StampLabelWrapper>
        <IconWrapper onClick={() => this.toggleStampVisibility(stampMesh)}>
          <Icon
            src={stampMesh.visible ? Icons.basic.eyeOpen : Icons.basic.eyeClose}
          />
        </IconWrapper>
        <IconWrapper onClick={() => this.showDeleteModal(stampMesh)}>
          <Icon src={Icons.basic.trash} />
        </IconWrapper>
      </StampItem>
    );
  }

  renderStampControl() {
    if (this.props.currentTool !== 'stamp') return null;
    const { currentModel } = this.props;
    const stamps = _.map(currentModel.mesh.children, (stamp, index) =>
      this.renderStampItem(stamp, index)
    );
    const placeholder = (
      <PlaceholderWrapper>
        <Body1 grey>No stamps to display</Body1>
      </PlaceholderWrapper>
    );

    const stampUploadButton = (
      <StampControlMainButtonWrapper>
        <Button
          minWidth='100%'
          primary
          onClick={() => this.toggleStampPrompt()}>
          Upload image
        </Button>
      </StampControlMainButtonWrapper>
    );

    return (
      <StampControlPanelWrapper>
        <ToolCollapsiblePanel
          label='Stamps'
          isCollapsed={false}
          scroll={false}
          footerContent={stampUploadButton}
          forceOpen={this.state.isOnSmallDevice}
          headerCustomButton={this.goBackCleanupButton()}
          triggerCollapse={
            this.state.stampStep === 'place' && this.state.isOnSmallDevice
          }>
          <StampControlContainer>
            <StampList>{!_.isEmpty(stamps) ? stamps : placeholder}</StampList>
          </StampControlContainer>
        </ToolCollapsiblePanel>
      </StampControlPanelWrapper>
    );
  }

  renderStampPrompt() {
    return (
      <>
        {this.renderStampControl()}
        <StampPrompt
          {...this.props}
          showDeleteModal={this.state.showDeleteModal}
          showStampPrompt={this.state.showStampPrompt}
          updatePaintView={(stampStep) => this.updateStampStep(stampStep)}
          onReset={() => this.toggleStampPrompt()}
          onDeleteCancel={() => this.hideDeleteModal()}
          onDeleteConfirm={() => this.deleteStamp(this.state.stampToDelete)}
        />
      </>
    );
  }

  toggleSegmentBoundaries(visible) {
    if (visible && this.boundaries) {
      this.props.addBoundaries(this.boundaries);
    } else {
      this.props.removeBoundaries();
    }
    this.setState({ prevShowSegmentBoundaries: visible });
  }

  renderMobileToolbox() {
    return (
      <ToolboxMobileContainer id='mobileToolbar'>
        {this.renderMobileToolbarLeft()}
        {this.renderBasicControlMobile()}
        {this.renderAutoSegmentControlMobile()}
      </ToolboxMobileContainer>
    );
  }

  renderToolbox() {
    return (
      <ToolboxContainer>
        {this.renderBasicControl()}
        {this.renderAutoSegmentControl()}
        {this.renderToolboxContent()}
      </ToolboxContainer>
    );
  }

  render() {
    return (
      <>
        <HeaderBar />
        {this.renderStampPrompt()}
        {this.renderToolbox()}
        {this.renderMobileToolbox()}
        {this.renderPaintUndoRedoButtons()}
        {this.renderViewButtons()}
        {this.renderViewButtonsMobile()}
        {this.renderCancelModal()}
      </>
    );
  }
}

export default withTheme(PaintView);

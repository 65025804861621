import React from 'react';

import { FormatUtils } from '../../../../utils';

import {
  ImportHeadingContainer,
  ProjectTitleWrapper,
} from './importHeading.styles';

import { Body1, CaptionOneLine, Subtitle1OneLine } from '../../../../shared';

const ImportHeading = (props) => {
  const { sharedProject } = props;
  const { name, ownerUsername, dateModified } = sharedProject;
  const date = FormatUtils.isoToDateString(dateModified);
  return (
    <ImportHeadingContainer>
      <ProjectTitleWrapper>
        <Subtitle1OneLine>{name}</Subtitle1OneLine>
      </ProjectTitleWrapper>
      <Body1>{`Shared by ${ownerUsername}`}</Body1>
      <CaptionOneLine grey>{`Last updated ${date}`}</CaptionOneLine>
    </ImportHeadingContainer>
  );
};

export default ImportHeading;

import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SlicerContext } from '../slicer.context';

import { DropdownWrapper } from './viewOptions.styles';

import { Dropdown } from '../../../shared';

import { ProjectUtils } from '../../../utils';

import { actions as authActions } from '../../../reducers/auth/auth';
import { actions as threeActions } from '../../../reducers/three/three';

const OPTION_GRID_LINES = 0;
const OPTION_BOUNDING_BOX = 1;
const OPTION_ORIGIN_AXES = 2;
const OPTION_CAMERA_MODE = 3;
const OPTION_RESET_CAMERA = 4;

const ViewOptions = ({ resetCamera = () => {} }) => {
  const { currentView, modelToColor } = useContext(SlicerContext);

  const dispatch = useDispatch();
  const { viewOptions } = useSelector((state) => state.auth);
  const { printers } = useSelector((state) => state.printers);
  const { projects, currentProject } = useSelector((state) => state.slicer);

  const onToggleViewOption = (field) => {
    const project = projects[currentProject];
    const printer = ProjectUtils.getProjectPrinter(project, printers);

    const newValue = !viewOptions[field];
    dispatch(
      authActions.editAccountRequest({
        viewOptions: { [field]: newValue },
      })
    );

    if (field === 'orthographic') {
      dispatch(
        threeActions.replaceCameraRequest(
          newValue,
          currentView === 'paint',
          modelToColor
        )
      );
    } else {
      dispatch(threeActions.updatePrintBedRequest(printer));
    }
  };

  const getGridLinesOption = () => {
    const label = viewOptions.gridLines
      ? 'Hide grid lines'
      : 'Show grid lines (50/5 mm)';
    return { label, value: OPTION_GRID_LINES };
  };

  const getBoundingBoxOption = () => {
    const toggleLabel = viewOptions.boundingBox ? 'Hide' : 'Show';
    const label = `${toggleLabel} bounding box`;
    return { label, value: OPTION_BOUNDING_BOX };
  };

  const getOriginAxesOption = () => {
    const toggleLabel = viewOptions.originAxes ? 'Hide' : 'Show';
    const label = `${toggleLabel} printer origin`;
    return { label, value: OPTION_ORIGIN_AXES };
  };

  const getCameraModeOption = () => {
    const toggleLabel = viewOptions.orthographic
      ? 'perspective'
      : 'orthographic';
    const label = `Switch to ${toggleLabel} camera`;
    return { label, value: OPTION_CAMERA_MODE };
  };

  const getResetCameraPositionOption = () => {
    const label = 'Reset camera position';
    return { label, value: OPTION_RESET_CAMERA };
  };

  const onOptionToggle = (value) => {
    switch (value) {
      case OPTION_GRID_LINES:
        onToggleViewOption('gridLines');
        break;
      case OPTION_BOUNDING_BOX:
        onToggleViewOption('boundingBox');
        break;
      case OPTION_ORIGIN_AXES:
        onToggleViewOption('originAxes');
        break;
      case OPTION_CAMERA_MODE:
        onToggleViewOption('orthographic');
        break;
      case OPTION_RESET_CAMERA:
      default:
        resetCamera();
        break;
    }
  };

  const getDropdownOptions = () => {
    let options;
    if (currentView === 'paint') {
      options = [getCameraModeOption(), getResetCameraPositionOption()];
    } else {
      options = [
        getGridLinesOption(),
        getBoundingBoxOption(),
        getOriginAxesOption(),
        getCameraModeOption(),
        getResetCameraPositionOption(),
      ];
    }
    return options;
  };

  return (
    <DropdownWrapper>
      <Dropdown
        borderlessContainer
        minHeight='2rem'
        grey
        borderlessSelect
        customLabel={'View Options'}
        dropUp
        onChange={(value) => onOptionToggle(value)}
        options={getDropdownOptions()}
      />
    </DropdownWrapper>
  );
};

export default ViewOptions;

import styled, { css } from 'styled-components';

export const StyledToolTipContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChartContainer = styled.div`
  padding-top: 0.5rem;
  &:empty {
    padding-top: 0.25rem;
  }
`;

export const Legends = styled.div`
  width: 100%;
  display: flex;
`;

export const Legend = styled.div`
  display: flex;
  margin-right: 0.5rem;
  align-items: center;
`;

const swatchStyles = css`
  width: 1rem;
  height: ${(props) => (props.line ? '0.2rem' : '1rem')};
  margin-right: 0.3rem;
  border-radius: 0.2rem;
`;

export const GreenSwatch = styled.div`
  ${swatchStyles};
  background: ${(props) => props.theme.colors.greenLight};
`;

export const GreySwatch = styled.div`
  ${swatchStyles};
  background: ${(props) => props.theme.colors.grey5};
`;

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import { applyChange } from 'deep-diff';

import { handleGenericError, getIotState } from '../common';
import { actions } from '../../reducers/iot/iot';

export default function* updateDeviceState(action) {
  const { deviceId, stateId: newStateId, delta } = action.payload;

  try {
    const { deviceStates } = yield select(getIotState);

    const currentDeviceState = deviceStates[deviceId];

    const { stateId: oldStateId, deviceState: oldDeviceState } =
      currentDeviceState;

    // if old stateId is null, either the device does not support
    // state deltas or the full device state is being retrieved
    // for the first time
    if (oldStateId === null) return;

    // state deltas are broadcast with sequential integer IDs
    // - if we didn't increment by 1, we missed a delta broadcast
    //   and need to re-request the entire state
    if (newStateId - oldStateId !== 1) {
      yield put(actions.getDeviceStateRequest(deviceId));
      return;
    }

    // if IDs are correct, update the state
    const newDeviceState = _.cloneDeep(oldDeviceState);
    // apply each diff item to cloned current state; this mutates the target object

    for (let i = 0; i < delta.length; i++) {
      const currentDiff = delta[i];
      applyChange(newDeviceState, {}, currentDiff);
    }

    yield put(
      actions.updateDeviceStateSuccess(deviceId, newStateId, newDeviceState)
    );
  } catch (err) {
    yield call(handleGenericError, action, err);
  }
}

import React from 'react';

import { Body1, ConfirmationModal, Link } from '../../../../shared';
import { Links } from '../../../../themes';

const ConnectedWarningModal = ({
  connectedModeWarning,
  printer,
  onConfirm = () => {},
}) => {
  const { extension } = printer.machineSettings;

  // use specific warning messages based on the type of incompatibility
  let incompatibility = '';
  if (connectedModeWarning.firmwareIncompatible) {
    incompatibility =
      "Palette and Canvas Hub are not compatible with this printer's firmware.";
  } else {
    incompatibility = `Palette and Canvas Hub are not compatible with .${extension} files in Connected mode.`;
  }

  // steps to continue slicing (X3G has specific instructions)
  let instructions = '';
  if (extension === 'x3g') {
    instructions = (
      <Body1>
        You can switch your Palette profile to Accessory mode, or switch your
        printer profile to use .gcode and set up the{' '}
        <Link external href={Links.octoprintGpxPlugin}>
          GPX plugin
        </Link>{' '}
        on Canvas Hub.
      </Body1>
    );
  } else {
    instructions =
      'Please configure the project to use accessory mode before slicing.';
  }
  return (
    <ConfirmationModal
      primaryLabel='Connected mode not compatible'
      secondaryLabel={incompatibility}
      tertiaryLabel={instructions}
      cancelLabel='Close'
      confirmLabel='Okay'
      onClickCancel={onConfirm}
      onClickConfirm={onConfirm}
    />
  );
};

export default ConnectedWarningModal;

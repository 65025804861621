import styled from 'styled-components';
import { Breakpoints } from '../../themes';

export const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap || '1rem'};
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${Breakpoints.mediumWidth}) {
    align-items: start;
  }
`;

export const MainFormWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.stackedFormItem ? 'column' : 'row')};
  align-items: ${(props) => (props.stackedFormItem ? 'flex-start' : 'center')};
  justify-content: space-between;
  gap: 1rem;

  @media (max-width: ${Breakpoints.mediumWidth}) {
    flex-direction: column;
    align-items: start;
    width: fit-content;
    gap: 0;
  }
`;

export const TextWrapper = styled.div`
  flex-direction: column;
  justify-content: center;
  flex-basis: 70%;
`;

export const FormItemTitle = styled.div`
  margin-bottom: 0.25rem;
  width: fit-content;
`;

export const FormItemDescription = styled.div`
  width: fit-content;

  @media (max-width: ${Breakpoints.mediumWidth}) {
    margin-bottom: 0.75rem;
  }
`;

export const FormItemContent = styled.div`
  min-width: fit-content;
  width: ${(props) => (props.stackedFormItem ? '100%' : '12.5rem')};
`;

export const ContentWrapper = styled.div`
  margin-left: ${(props) => props.adjustContentPosition};

  @media (max-width: ${Breakpoints.mediumWidth}) {
    margin-left: 0;
  }
`;

export const SubForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 0.25rem;
  margin-top: ${(props) => (props.noSubformSpacing ? '0' : '1rem')};
  background-color: ${(props) =>
    props.theme.colors.elevatedBackgroundSecondary};

  @media (max-width: ${Breakpoints.mediumWidth}) {
    align-self: stretch;
  }
`;

export const SubFormItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  @media (max-width: ${Breakpoints.mediumWidth}) {
    flex-direction: column;
    align-items: start;
    justify-content: stretch;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

export const SubformContentWrapper = styled.div`
  margin-left: ${(props) => props.adjustSubformContentPosition};

  @media (max-width: ${Breakpoints.mediumWidth}) {
    margin-left: 0;
  }
`;

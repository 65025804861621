const types = {
  RESET_PRINTER_STORE: 'PRINTER/RESET_PRINTER_STORE',

  PARSE_PROFILE_REQUEST: 'PRINTER/PARSE_PROFILE_REQUEST',
  PARSE_PROFILE_SUCCESS: 'PRINTER/PARSE_PROFILE_SUCCESS',
  PARSE_PROFILE_FAILURE: 'PRINTER/PARSE_PROFILE_FAILURE',

  RESET_PARSED_PROFILE: 'PRINTER/RESET_PARSED_PROFILE',

  CREATE_PRINTER_REQUEST: 'PRINTER/CREATE_PRINTER_REQUEST',
  CREATE_PRINTER_SUCCESS: 'PRINTER/CREATE_PRINTER_SUCCESS',
  CREATE_PRINTER_FAILURE: 'PRINTER/CREATE_PRINTER_FAILURE',

  UPDATE_PRINTER_REQUEST: 'PRINTER/UPDATE_PRINTER_REQUEST',
  UPDATE_PRINTER_SUCCESS: 'PRINTER/UPDATE_PRINTER_SUCCESS',
  UPDATE_PRINTER_FAILURE: 'PRINTER/UPDATE_PRINTER_FAILURE',

  DUPLICATE_PRINTER_REQUEST: 'PRINTER/DUPLICATE_PRINTER_REQUEST',
  DUPLICATE_PRINTER_SUCCESS: 'PRINTER/DUPLICATE_PRINTER_SUCCESS',
  DUPLICATE_PRINTER_FAILURE: 'PRINTER/DUPLICATE_PRINTER_FAILURE',

  CREATE_STYLE_PROFILES_REQUEST: 'PRINTER/CREATE_STYLE_PROFILES_REQUEST',
  CREATE_STYLE_PROFILES_SUCCESS: 'PRINTER/CREATE_STYLE_PROFILES_SUCCESS',
  CREATE_STYLE_PROFILES_FAILURE: 'PRINTER/CREATE_STYLE_PROFILES_FAILURE',

  UPDATE_STYLE_REQUEST: 'PRINTER/UPDATE_STYLE_REQUEST',
  UPDATE_STYLE_SUCCESS: 'PRINTER/UPDATE_STYLE_SUCCESS',
  UPDATE_STYLE_FAILURE: 'PRINTER/UPDATE_STYLE_FAILURE',

  GET_PRINTERS_REQUEST: 'PRINTER/GET_PRINTERS_REQUEST',
  GET_PRINTERS_SUCCESS: 'PRINTER/GET_PRINTERS_SUCCESS',
  GET_PRINTERS_FAILURE: 'PRINTER/GET_PRINTERS_FAILURE',

  GET_PRINTER_PRESETS_REQUEST: 'PRINTER/GET_PRINTER_PRESETS_REQUEST',
  GET_PRINTER_PRESETS_SUCCESS: 'PRINTER/GET_PRINTER_PRESETS_SUCCESS',
  GET_PRINTER_PRESETS_FAILURE: 'PRINTER/GET_PRINTER_PRESETS_FAILURE',

  DELETE_STYLES_REQUEST: 'PRINTER/DELETE_STYLES_REQUEST',
  DELETE_STYLES_SUCCESS: 'PRINTER/DELETE_STYLES_SUCCESS',
  DELETE_STYLES_FAILURE: 'PRINTER/DELETE_STYLES_FAILURE',

  DELETE_PRINTERS_REQUEST: 'PRINTER/DELETE_PRINTERS_REQUEST',
  DELETE_PRINTERS_SUCCESS: 'PRINTER/DELETE_PRINTERS_SUCCESS',
  DELETE_PRINTERS_FAILURE: 'PRINTER/DELETE_PRINTERS_FAILURE',

  // Sharing
  CREATE_SHARED_PRINTER_REQUEST: 'PRINTER/CREATE_SHARED_PRINTER_REQUEST',
  CREATE_SHARED_PRINTER_SUCCESS: 'PRINTER/CREATE_SHARED_PRINTER_SUCCESS',
  CREATE_SHARED_PRINTER_FAILURE: 'PRINTER/CREATE_SHARED_PRINTER_FAILURE',

  DELETE_SHARED_PRINTER_REQUEST: 'PRINTER/DELETE_SHARED_PRINTER_REQUEST',
  DELETE_SHARED_PRINTER_SUCCESS: 'PRINTER/DELETE_SHARED_PRINTER_SUCCESS',
  DELETE_SHARED_PRINTER_FAILURE: 'PRINTER/DELETE_SHARED_PRINTER_FAILURE',

  UPDATE_SHARED_PRINTER_REQUEST: 'PRINTER/UPDATE_SHARED_PRINTER_REQUEST',
  UPDATE_SHARED_PRINTER_SUCCESS: 'PRINTER/UPDATE_SHARED_PRINTER_SUCCESS',
  UPDATE_SHARED_PRINTER_FAILURE: 'PRINTER/UPDATE_SHARED_PRINTER_FAILURE',

  GET_SHARED_PRINTER_REQUEST: 'PRINTER/GET_SHARED_PRINTER_REQUEST',
  GET_SHARED_PRINTER_SUCCESS: 'PRINTER/GET_SHARED_PRINTER_SUCCESS',
  GET_SHARED_PRINTER_FAILURE: 'PRINTER/GET_SHARED_PRINTER_FAILURE',

  IMPORT_SHARED_PRINTER_REQUEST: 'PRINTER/IMPORT_SHARED_PRINTER_REQUEST',
  IMPORT_SHARED_PRINTER_SUCCESS: 'PRINTER/IMPORT_SHARED_PRINTER_SUCCESS',
  IMPORT_SHARED_PRINTER_FAILURE: 'PRINTER/IMPORT_SHARED_PRINTER_FAILURE',

  QUERY_PRINTER_TAGS_REQUEST: 'PRINTER/QUERY_PRINTER_TAGS_REQUEST',
  QUERY_PRINTER_TAGS_SUCCESS: 'PRINTER/QUERY_PRINTER_TAGS_SUCCESS',
  QUERY_PRINTER_TAGS_FAILURE: 'PRINTER/QUERY_PRINTER_TAGS_FAILURE',

  CREATE_PRINTER_TAG_REQUEST: 'PRINTER/CREATE_PRINTER_TAG_REQUEST',
  CREATE_PRINTER_TAG_SUCCESS: 'PRINTER/CREATE_PRINTER_TAG_SUCCESS',
  CREATE_PRINTER_TAG_FAILURE: 'PRINTER/CREATE_PRINTER_TAG_FAILURE',

  GET_PRINTER_TAG_REQUEST: 'PRINTER/GET_PRINTER_TAG_REQUEST',
  GET_PRINTER_TAG_SUCCESS: 'PRINTER/GET_PRINTER_TAG_SUCCESS',
  GET_PRINTER_TAG_FAILURE: 'PRINTER/GET_PRINTER_TAG_FAILURE',

  CLEAR_PRINTER_TAG: 'PRINTER/CLEAR_PRINTER_TAG',

  UPDATE_CALIBRATION_SETTINGS_REQUEST:
    'PRINTER/UPDATE_CALIBRATION_SETTINGS_REQUEST',
  UPDATE_CALIBRATION_SETTINGS_SUCCESS:
    'PRINTER/UPDATE_CALIBRATION_SETTINGS_SUCCESS',
  UPDATE_CALIBRATION_SETTINGS_FAILURE:
    'PRINTER/UPDATE_CALIBRATION_SETTINGS_FAILURE',
};

export default types;

import { connect } from 'react-redux';

import { actions } from '../../reducers/slicer/slicer';
import { actions as authActions } from '../../reducers/auth/auth';
import ProjectManager from './projectManager.jsx';

const mapStateToProps = (state) => ({
  listView: state.auth.preferences.projectsListView,
  sortBy: {
    mode: state.auth.preferences.projectsSortMode,
    direction: state.auth.preferences.projectsSortDirection,
  },
  printers: state.printers.printers,
  projectsAndFolders: state.slicer.projects,
  thumbnails: state.slicer.thumbnails,
  loadProjectsPending: state.slicer.status.loadProjectsPending,
  loadProjectsSuccess: state.slicer.status.loadProjectsSuccess,
  getMaterialsPending: state.slicer.status.getMaterialsPending,
  getMaterialsSuccess: state.slicer.status.getMaterialsSuccess,
  updateProjectPending: state.slicer.status.updateProjectNamePending,
  deleteProjectsPending: state.slicer.status.deleteProjectsPending,
  deleteProjectsSuccess: state.slicer.status.deleteProjectsSuccess,
  createProjectPending: state.slicer.status.createProjectPending,
  createProjectSuccess: state.slicer.status.createProjectSuccess,
  updateProjectNamePending: state.slicer.status.updateProjectNamePending,
  updateProjectNameSuccess: state.slicer.status.updateProjectNameSuccess,
  duplicateProjectPending: state.slicer.status.duplicateProjectPending,
  duplicateProjectSuccess: state.slicer.status.duplicateProjectSuccess,
  createFolderPending: state.slicer.status.createFolderPending,
  createFolderSuccess: state.slicer.status.createFolderSuccess,
  updateProjectsParentPending: state.slicer.status.updateProjectsParentPending,
  updateProjectsParentSuccess: state.slicer.status.updateProjectsParentSuccess,
  createSharedProjectPending: state.slicer.status.createSharedProjectPending,
  createSharedProjectSuccess: state.slicer.status.createSharedProjectSuccess,
  deleteSharedProjectPending: state.slicer.status.deleteSharedProjectPending,
  deleteSharedProjectSuccess: state.slicer.status.deleteSharedProjectSuccess,
  updateSharedProjectPending: state.slicer.status.updateSharedProjectPending,
  updateSharedProjectSuccess: state.slicer.status.updateSharedProjectSuccess,
  currentProject: state.slicer.currentProject,
  navStack: state.nav.navStack,
  signupSuccess: state.auth.status.signupSuccess,
  getPrintersPending: state.printers.status.getPrintersPending,
  getPrintersSuccess: state.printers.status.getPrintersSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  updatePreferences: (listView, sortBy) =>
    dispatch(
      authActions.editAccountRequest({
        preferences: {
          projectsListView: listView,
          projectsSortMode: sortBy.mode,
          projectsSortDirection: sortBy.direction,
        },
      })
    ),
  loadThumbnail: (projectId) =>
    dispatch(actions.getProjectThumbnailRequest(projectId)),
  deleteProjects: (projectIds) =>
    dispatch(actions.deleteProjectsRequest(projectIds)),
  updateProjectName: (projectId, projectName) =>
    dispatch(actions.updateProjectNameRequest(projectId, projectName)),
  duplicateProject: (projectId) =>
    dispatch(actions.duplicateProjectRequest(projectId)),
  createFolder: (name, parentId = null) =>
    dispatch(actions.createFolderRequest(name, parentId)),
  updateProjectsParent: (projectIds, parentId = null) =>
    dispatch(actions.updateProjectsParentRequest(projectIds, parentId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectManager);

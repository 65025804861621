import { connect } from 'react-redux';

import ConfigurePrinter from '../configurePrinter.jsx';
import { actions } from '../../../../reducers/printers/printers';

const mapStateToProps = (state) => ({
  mode: 'import',
  tags: state.printers.tags,
  printerTag: state.printers.currentPrinterTag,
  printers: state.printers.printers,
  printer: state.printers.parsedProfile,
  createPrinterPending: state.printers.status.createPrinterPending,
  createPrinterSuccess: state.printers.status.createPrinterSuccess,
  updatePrinterPending: state.printers.status.updatePrinterPending,
  updatePrinterSuccess: state.printers.status.updatePrinterSuccess,
  getPrinterTagPending: state.printers.status.getPrinterTagPending,
  queryPrinterTagsPending: state.printers.status.queryPrinterTagsPending,
  createPrinterTagPending: state.printers.status.createPrinterTagPending,
});

const mapDispatchToProps = (dispatch) => ({
  createPrinter: (printerData, styles) =>
    dispatch(actions.createPrinterRequest(printerData, styles)),
  resetParsedProfile: () => dispatch(actions.resetParsedProfile()),
  queryPrinterTags: (queryString) =>
    dispatch(actions.queryPrinterTagsRequest(queryString)),
  createPrinterTag: (tagName) =>
    dispatch(actions.createPrinterTagRequest(tagName)),
  getPrinterTag: (tagId) => dispatch(actions.getPrinterTagRequest(tagId)),
  clearPrinterTag: () => dispatch(actions.clearPrinterTag()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurePrinter);

import styled from 'styled-components';

export const RemapUIContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InputCountsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InputCountContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.25rem;
`;

export const ReduceOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.75rem;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 0.25rem;
  background-color: ${(props) =>
    props.theme.colors.elevatedBackgroundSecondary};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

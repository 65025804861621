import 'regenerator-runtime/runtime';
import { call, put, all } from 'redux-saga/effects';
import _ from 'lodash';

import API, { methods } from '../canvas-api';
import { handleGenericError } from '../common';
import { actions } from '../../reducers/printers/printers';
import { prepareMachineSettingsData, sanitizePrinterData } from './common';
import { createStyleCall } from './createPrinterStyles';

export default function* createPrinter(action) {
  try {
    const { printerData, styles } = action.payload;
    const machineSettings = prepareMachineSettingsData(
      printerData.machineSettings
    );

    const body = {
      ...machineSettings,
      tagId: printerData.tagId,
      modded: printerData.modded,
    };

    const response = yield call(API, {
      method: methods.PUT,
      path: 'printers',
      body,
    });
    if (response === null) return;

    if (!_.isEmpty(styles)) {
      // also create style profiles
      const createdStyles = yield all(
        _.map(styles, (style) =>
          call(createStyleCall, response.printer.id, style)
        )
      );

      if (_.some(createdStyles, (style) => style === null)) return;

      const flattenedStyles = _.flatten(createdStyles);
      const uniqueStyles = _.uniqBy(flattenedStyles, (style) => style.id);

      response.printer.styles = uniqueStyles;
    }

    const sanitizedPrinter = sanitizePrinterData(response.printer);
    yield put(actions.createPrinterSuccess(sanitizedPrinter));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

export default function* saveModelCustomSupports(action) {
  try {
    const { currentProject: projectId } = yield select(getSlicerState);
    const { supportsDataPerModel } = action.payload;

    let transitionTower = null;

    // save support rle for each model
    for (let i = 0; i < supportsDataPerModel.length; i++) {
      const { modelId, supportsRle } = supportsDataPerModel[i];

      const response = yield call(API, {
        method: methods.POST,
        path: `projects/${projectId}/models/${modelId}/supports`,
        body: { supportsRle },
      });
      if (response === null) return;
      if (response.transitionTower) {
        ({ transitionTower } = response);
      }
    }

    // update/remove tower
    yield put(actions.updateTowerFromServer(projectId, transitionTower));

    yield put(actions.saveModelCustomSupportsSuccess(supportsDataPerModel));

    yield put(actions.invalidateSlice(projectId));

    // update thumbnail
    yield put(actions.updateProjectThumbnailRequest(projectId));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

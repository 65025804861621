import React from 'react';
import AbstractCard from '../abstractCard/abstractCard.jsx';
import { StyledContainer } from './styleProfileCard.styles';

const StyleProfileCard = (props) => {
  return (
    <AbstractCard
      {...props}
      StyledLayoutContainer={StyledContainer}
      height='5.75rem'>
      {props.children}
    </AbstractCard>
  );
};

export default StyleProfileCard;

import { connect } from 'react-redux';

import ContextContents from './context.jsx';

import { actions } from '../../../../../reducers/slicer/slicer';

import { ProjectUtils, SlicerUtils } from '../../../../../utils';

const mapStateToProps = (state) => {
  const selectedModels = SlicerUtils.getSelectedModels(state.slicer.models);
  const project = state.slicer.projects[state.slicer.currentProject];
  const printer = ProjectUtils.getProjectPrinter(
    project,
    state.printers.printers
  );
  return {
    models: state.slicer.models,
    selectedModels,
    intersectMode: state.slicer.intersectMode,
    project,
    printer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  repack: () => dispatch(actions.repackRequest()),
  updateModelTransforms: (transformData) =>
    dispatch(actions.updateModelTransformsRequest(transformData)),
  deactivateAllModels: () => dispatch(actions.deactivateAllModels()),
  setIntersectMode: (mode) => dispatch(actions.setIntersectMode(mode)),
  updateProjectStyle: (style) =>
    dispatch(actions.updateProjectStyleRequest(style)),
  setInputRemapperContext: (remapperContext) =>
    dispatch(actions.setInputRemapperContext(remapperContext)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContextContents);

import React from 'react';

import Metadata from './firstLayer.metadata';
import StyleSettingsFormSection from '../styleSettingsFormSection.jsx';

import { Container, Col } from '../forms.styles';

class Content extends StyleSettingsFormSection {
  static isSectionMatched(props, filterBy) {
    return (
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.zOffset,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.speed,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.sizeCompensation,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.skirt,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.raft,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.raft.subgroups.raft1,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.raft.subgroups.raft2,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.raft.subgroups.raft3,
        filterBy
      )
    );
  }

  render() {
    return (
      <>
        <Container>
          <Col>
            {this.renderFieldGroup(Metadata.zOffset)}
            {this.renderFieldGroup(Metadata.speed)}
            {this.renderFieldGroup(Metadata.sizeCompensation)}
          </Col>
          <Col>{this.renderFieldGroup(Metadata.skirt)}</Col>
        </Container>
        <Container>
          <Col>{this.renderFieldGroup(Metadata.raft)}</Col>
          <Col />
        </Container>
        <Container>
          <Col>{this.renderFieldGroup(Metadata.raft.subgroups.raft1)}</Col>
          <Col>{this.renderFieldGroup(Metadata.raft.subgroups.raft2)}</Col>
        </Container>
        <Container>
          <Col>{this.renderFieldGroup(Metadata.raft.subgroups.raft3)}</Col>
          <Col />
        </Container>
      </>
    );
  }
}

export default Content;

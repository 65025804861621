import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../canvas-api';
import { handleGenericError } from '../common';
import { actions } from '../../reducers/notifications/notifications';

export default function* updateNotification(action) {
  try {
    const { notificationId, read } = action.payload;
    const response = yield call(API, {
      method: methods.POST,
      path: `notifications/${notificationId}`,
      body: {
        read,
      },
    });
    if (response === null) return;
    yield put(actions.updateNotificationSuccess(response.notification));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import styled from 'styled-components';

import { Breakpoints } from '../../../../themes';

export const SliderContainer = styled.div`
  position: fixed;
  right: 1rem;
  top: 5.5rem;
  bottom: 6.5rem;
  z-index: 1;
  pointer-events: none;

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: none;
  }
`;

export const SliderLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  text-align: right;
`;

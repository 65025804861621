import React, { Component } from 'react';
import _ from 'lodash';
import { withTheme } from 'styled-components';

import {
  Container,
  FieldRow,
  OffsetButtons,
  ToolLabelWrapper,
  FieldsContainer,
  CheckboxWrapper,
  XStyles,
  YStyles,
  ZStyles,
  OffsetButtonWrapper,
  ResetButtonWrapper,
  NumberFieldWrapper,
} from '../transformContents.styles';

import {
  Button,
  Checkbox,
  Icon,
  Input,
  Subtitle2,
} from '../../../../../shared';

import { FormatUtils, TowerUtils, TransformUtils } from '../../../../../utils';
import {
  computeAxisValues,
  getBlankAxisValues,
  isOffsetDisabled,
  SCALE_STEP_MODEL_PERCENT,
  SCALE_STEP_MODEL_MM,
  OFFSET_PERCENT,
  OFFSET_MM,
} from './math';

import { Icons } from '../../../../../themes';

class ScaleContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentScales: getBlankAxisValues(),
      currentDimensions: getBlankAxisValues(),
    };
  }

  componentDidMount() {
    this.computeAxisValues();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pivot !== this.props.pivot && this.props.pivot !== null) {
      // gizmo attached/updated
      this.computeAxisValues();
    } else if (prevProps.pivot !== null && this.props.pivot === null) {
      // gizmo detached
      this.resetAxisValues();
    }
  }

  computeAxisValues() {
    const { selectedModels, selectedTower } = this.props;
    const { scales, dimensions } = computeAxisValues(
      selectedModels,
      selectedTower
    );
    this.setState({
      currentScales: scales,
      currentDimensions: dimensions,
    });
  }

  resetAxisValues() {
    this.setState({
      currentScales: getBlankAxisValues(),
      currentDimensions: getBlankAxisValues(),
    });
  }

  handleKeyDown(e) {
    e.stopPropagation();
  }

  handleKeyUp(e) {
    e.stopPropagation();
  }

  onPercentChangeSuccess(axis, newScale) {
    this.setState(
      {
        currentScales: {
          ...this.state.currentScales,
          [axis]: newScale,
        },
      },
      () => {
        this.setScale({ [axis]: newScale });
      }
    );
  }

  onDimensionChangeSuccess(axis, newDimension) {
    const { currentDimensions, currentScales } = this.state;
    const scale =
      (newDimension / currentDimensions[axis]) * currentScales[axis];
    this.setState(
      {
        currentDimensions: {
          ...currentDimensions,
          [axis]: newDimension,
        },
      },
      () => {
        this.setScale({ [axis]: scale });
      }
    );
  }

  onOffset(axis, offsetDirection) {
    const { viewOptions, selectedTower } = this.props;

    let scale;
    if (viewOptions.percentScale) {
      // offset by 10%
      const offsetValue = OFFSET_PERCENT * offsetDirection;
      const oldScale = Math.abs(this.state.currentScales[axis]);
      scale = oldScale + offsetValue;

      // if selecting tower, need to calculate delta from its size
      if (selectedTower) {
        this.updateTowerDimensions(axis, 1 + offsetValue);
        return;
      }
    } else {
      // offset by 10mm
      const offsetValue = OFFSET_MM * offsetDirection;
      const oldDimension = this.state.currentDimensions[axis];
      const newDimension = oldDimension + offsetValue;
      scale = (newDimension / oldDimension) * this.state.currentScales[axis];

      // if selecting tower, need to calculate delta from its size
      if (selectedTower) {
        const delta = newDimension / oldDimension;
        this.updateTowerDimensions(axis, delta);
        return;
      }
    }
    this.setScale({ [axis]: scale });
  }

  onResetClick() {
    const { selectedModels, selectedTower, models } = this.props;
    if (selectedTower) {
      const dimensions = { ...selectedTower.size };
      const area = dimensions.x * dimensions.y;
      const squareLength = Math.sqrt(area);
      const aspect = TowerUtils.getGoldenSqrt();
      dimensions.x = squareLength / aspect;
      dimensions.y = squareLength * aspect;
      this.setState(
        {
          currentDimensions: {
            ...this.state.currentDimensions,
            x: FormatUtils.roundTo(dimensions.x, 2),
            y: FormatUtils.roundTo(dimensions.y, 2),
          },
        },
        () => {
          this.props.setTowerSize(dimensions);
        }
      );
    } else if (!_.isEmpty(selectedModels)) {
      const updatedModels = TransformUtils.updateModelsScale(
        { x: 1, y: 1, z: 1 },
        selectedModels,
        false
      );
      const droppedToBedModels = TransformUtils.dropToBed(
        updatedModels,
        models
      );
      this.props.updateModelTransforms(
        _.map(droppedToBedModels, (model) => ({
          id: model.id,
          transforms: _.pick(model.transforms, ['scale', 'translate']),
        }))
      );
    }
  }

  onUnitToggle() {
    this.props.setPercentScale(!this.props.viewOptions.percentScale);
  }

  onUniformToggle() {
    this.props.setUniformScale(!this.props.uniformScale);
  }

  updateTowerDimensions(axis, delta) {
    const { selectedTower } = this.props;
    if (selectedTower) {
      const dimensions = { ...selectedTower.size };

      if (axis === 'x') {
        dimensions.x *= delta;
        dimensions.y /= delta;
      } else {
        dimensions.x /= delta;
        dimensions.y *= delta;
      }
      this.setState(
        {
          currentDimensions: {
            ...this.state.currentDimensions,
            x: FormatUtils.roundTo(dimensions.x, 2),
            y: FormatUtils.roundTo(dimensions.y, 2),
          },
        },
        () => {
          this.props.setTowerSize(this.state.currentDimensions);
        }
      );
    }
  }

  setScale(scales) {
    const { selectedModels, selectedTower, models, uniformScale } = this.props;

    if (selectedTower) {
      this.props.setTowerSize(this.state.currentDimensions);
    } else if (!_.isEmpty(selectedModels)) {
      const updatedModels = TransformUtils.updateModelsScale(
        scales,
        selectedModels,
        uniformScale
      );
      const droppedToBedModels = TransformUtils.dropToBed(
        updatedModels,
        models
      );
      this.props.updateModelTransforms(
        _.map(droppedToBedModels, (model) => ({
          id: model.id,
          transforms: _.pick(model.transforms, ['scale', 'translate']),
        }))
      );
    }
  }

  renderAxes() {
    const labels = ['x', 'y', 'z'];
    const axes = _.map(labels, (label, index) => this.renderAxis(label, index));
    return <FieldsContainer>{axes}</FieldsContainer>;
  }

  renderAxis(axis, index) {
    const { viewOptions } = this.props;
    return (
      <FieldRow key={index}>
        {this.renderAxisColorFlag(index)}
        {viewOptions.percentScale
          ? this.renderPercentScaleField(axis)
          : this.renderDimensionScaleField(axis)}
        {this.renderOffsetButtons(axis)}
      </FieldRow>
    );
  }

  renderAxisColorFlag(index) {
    let style = null;
    if (index === 0) style = <XStyles />;
    else if (index === 1) style = <YStyles />;
    else if (index === 2) style = <ZStyles />;
    return style;
  }

  renderPercentScaleField(axis) {
    const { selectedTower } = this.props;
    let value = this.state.currentScales[axis];
    const isDisabled = selectedTower || typeof value !== 'number';
    if (typeof value === 'number') {
      value = FormatUtils.roundTo(Math.abs(value) * 100, 2);
    }
    return (
      <NumberFieldWrapper>
        <Input
          label={axis.toUpperCase()}
          type='number'
          disabled={isDisabled}
          value={selectedTower ? '' : value}
          min={0}
          gte={false}
          step={SCALE_STEP_MODEL_PERCENT}
          units='%'
          revertOnBlur
          stopPropagationOnKeyDown
          onChangeSuccess={(newValue) =>
            this.onPercentChangeSuccess(axis, newValue / 100)
          }
          onKeyDown={(e) => this.handleKeyDown(e, axis, false)}
          onKeyUp={(e) => this.handleKeyUp(e)}
        />
      </NumberFieldWrapper>
    );
  }

  renderDimensionScaleField(axis) {
    const { selectedTower } = this.props;
    const dimension = this.state.currentDimensions[axis];
    const isDisabled = selectedTower || typeof dimension !== 'number';
    return (
      <NumberFieldWrapper>
        <Input
          type='number'
          label={axis.toUpperCase()}
          disabled={isDisabled}
          value={selectedTower ? '' : dimension}
          min={0}
          gte={false}
          step={SCALE_STEP_MODEL_MM}
          units='mm'
          onChangeSuccess={(newValue) =>
            this.onDimensionChangeSuccess(axis, newValue)
          }
          revertOnBlur
          stopPropagationOnKeyDown
          onKeyDown={(e) => this.handleKeyDown(e, axis, true)}
          onKeyUp={(e) => this.handleKeyUp(e)}
        />
      </NumberFieldWrapper>
    );
  }

  renderOffsetButtons(axis) {
    const { selectedModels, selectedTower, viewOptions, theme } = this.props;
    const { currentScales, currentDimensions } = this.state;
    const disabledButtons = isOffsetDisabled(
      axis,
      selectedModels,
      selectedTower,
      viewOptions.percentScale,
      currentScales,
      currentDimensions
    );
    const isMinusDisabled = disabledButtons.minus;
    const isPlusDisabled = disabledButtons.plus;
    const unit = viewOptions.percentScale ? '%' : ' mm';

    return (
      <OffsetButtons>
        <OffsetButtonWrapper>
          <Button
            expand
            minWidth='0'
            height='1.9em' // todo
            title={`Scale by +10${unit}`}
            disabled={isPlusDisabled}
            icon={Icons.basic.plus}
            iconSize={Icon.sizes.MINI}
            iconColor={theme.colors.grey6}
            onClick={() => this.onOffset(axis, 1)}></Button>
        </OffsetButtonWrapper>
        <OffsetButtonWrapper>
          <Button
            expand
            minWidth='0'
            height='1.9em' // todo
            title={`Scale by –10${unit}`}
            disabled={isMinusDisabled}
            icon={Icons.basic.minus}
            iconSize={Icon.sizes.MINI}
            iconColor={theme.colors.grey6}
            onClick={() => this.onOffset(axis, -1)}></Button>
        </OffsetButtonWrapper>
      </OffsetButtons>
    );
  }

  renderLabel() {
    return (
      <ToolLabelWrapper>
        <Subtitle2>Scale</Subtitle2>
      </ToolLabelWrapper>
    );
  }

  renderResetButton() {
    const isDisabled =
      _.isEmpty(this.props.selectedModels) || this.props.selectedTower;

    return (
      <ResetButtonWrapper>
        <Button
          minWidth='0'
          height='2em'
          disabled={isDisabled}
          onClick={(e) => this.onResetClick(e)}>
          Reset
        </Button>
      </ResetButtonWrapper>
    );
  }

  renderUnitToggle() {
    return (
      <CheckboxWrapper>
        <Checkbox
          label='Use % based scaling'
          value={this.props.viewOptions.percentScale}
          onChange={() => this.onUnitToggle()}
        />
      </CheckboxWrapper>
    );
  }

  renderUniformToggle() {
    return (
      <CheckboxWrapper>
        <Checkbox
          label='Uniform scale'
          value={this.props.uniformScale}
          onChange={() => this.onUniformToggle()}
        />
      </CheckboxWrapper>
    );
  }

  render() {
    return (
      <Container>
        {this.renderLabel()}
        {this.renderResetButton()}
        {this.renderAxes()}
        {this.renderUnitToggle()}
        {this.renderUniformToggle()}
      </Container>
    );
  }
}

export default withTheme(ScaleContent);

import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import { handleGenericError } from '../common';
import { makeRequest } from './horizon';
import { actions } from '../../reducers/iot/iot';

export default function* setTargetTemperature(action) {
  const { device, temperatureData } = action.payload;

  try {
    yield call(
      makeRequest,
      device.id,
      'printer/temperature',
      {
        method: 'post',
        query: temperatureData,
      },
      'setTargetTemperature'
    );

    yield put(actions.setTargetTemperatureSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import _ from 'lodash';
import { call, put, race, select, take } from 'redux-saga/effects';

import { getAuthState } from './common';
import { actions, types } from '../reducers/auth/auth';
import { InterfaceUtils } from '../utils';

export class CanvasApiError extends Error {
  constructor(apiResponse) {
    const { status, type, message, ...payload } = apiResponse;

    const optionalType = type ? ` ${type}` : '';
    const msg = `${message} (${status}${optionalType})`;
    super(msg);
    this.name = 'CanvasApiError';
    this.status = status;
    this.type = type;
    Object.entries(payload).forEach(([key, value]) => {
      this[key] = value;
    });
  }
}

const getAbsoluteUrl = (path) => `${process.env.REACT_APP_API_HOST}/${path}`;

const getHeaders = (
  token,
  contentType = 'application/json',
  extraHeaders = {}
) => {
  const commonHeaders = {
    'Content-Type': contentType,
  };
  if (token) {
    commonHeaders.Authorization = `Bearer ${token}`;
  }
  return {
    ...commonHeaders,
    ...extraHeaders,
  };
};

export const methods = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE',
};

export const errorTypes = {
  bodyInvalid: 'body_invalid',
  paramMissing: 'param_missing',
  paramInvalid: 'param_invalid',
  paramTaken: 'param_taken',
  unauthorized: 'unauthorized',
  tokenInvalid: 'token_invalid',
  tokenExpired: 'token_expired',
  credentialsInvalid: 'credentials_invalid',
  forbidden: 'forbidden',
  notFound: 'not_found',
  inUse: 'in_use',
  requireDecision: 'require_decision',
  fileTypeInvalid: 'file_type_invalid',
  serverError: 'server_error',
};

const defaultApiCallParams = {
  method: methods.GET, // default to GET
  token: 'user-store', // "keyword" to pull user access token from store
  headers: {}, // should not include "common headers"
};

function* waitForTokenRefresh() {
  const { success } = yield race({
    success: take(types.REFRESH_USER_TOKEN_SUCCESS),
    failure: take(types.REFRESH_USER_TOKEN_FAILURE),
  });
  return !!success;
}

function* dispatchRefreshTokenRequest() {
  // token expiry error -- request a token refresh
  // 1. get the current refresh token from the store
  const { refreshToken, status } = yield select(getAuthState);
  if (!status.refreshUserTokenPending) {
    // 2. dispatch the request
    yield put(actions.refreshUserTokenRequest(refreshToken));
  }
  // 3. listen for either success or failure
  const refreshSuccess = yield call(waitForTokenRefresh);

  return refreshSuccess;
}

/**
 * Make a request to the Canvas API
 * @param {object} params Request parameters
 * @param {string} [params.method='GET'] Request method
 * @param {string} params.path Endpoint path, with parameters substituted
 * @param {string|null} [params.token='user-store'] Auth token to use for the request
 *   - if falsy, no token will be used
 *   - if 'user-store', user access token will be retrieved from the store and used
 * @param {any} [params.body] Request body
 * @param {AbortSignal} [params.signal] AbortSignal object to abort request
 */
function* canvasApiCall(params = {}) {
  const merged = {
    ...defaultApiCallParams,
    ...params,
  };
  if (!merged.path) {
    throw new Error('Endpoint path is required');
  }

  const { status } = yield select(getAuthState);

  // do not wait for token refresh, if we do not need token
  if (merged.token === 'user-store' && status.refreshUserTokenPending) {
    const refreshSuccess = yield call(waitForTokenRefresh);
    if (!refreshSuccess) {
      return null;
    }
  }

  const contentType = 'application/json';
  const url = getAbsoluteUrl(merged.path);
  let token = null;
  if (merged.token) {
    if (merged.token === 'user-store') {
      // retrieve user's access token from the store
      ({ token } = yield select(getAuthState));
    } else {
      // use token value directly from params
      ({ token } = merged);
    }
    // token does not exist in store
    if (!token && !status.refreshUserTokenPending) {
      const tokenRefreshed = yield call(dispatchRefreshTokenRequest);
      if (tokenRefreshed) {
        return yield call(canvasApiCall, params);
      }
      // token refresh failed -- cancel this call
      // (the refresh saga will handle logout)
      return null;
    }
  }
  const response = yield call(fetch, url, {
    method: merged.method,
    headers: getHeaders(token, contentType, merged.headers),
    body: JSON.stringify(merged.body),
    signal: merged.signal,
  });
  if (response.ok) {
    // 200-299 response -- always JSON
    return yield call([response, 'json']);
  }
  if (response.status >= 300 && response.status <= 399) {
    // 300-399 response
    return yield call([response, 'text']);
  }
  // 400+ response
  let error = {};
  try {
    error = yield call([response, 'json']);
    if (
      merged.token === 'user-store' &&
      error.type === errorTypes.tokenExpired
    ) {
      const tokenRefreshed = yield call(dispatchRefreshTokenRequest);
      if (tokenRefreshed) {
        return yield call(canvasApiCall, params);
      }
      // token refresh failed -- cancel this call
      // (the refresh saga will handle logout)
      return null;
    }
  } catch (err) {
    error = {
      status: 400,
      message: 'Unexpected response format',
    };
  }
  throw new CanvasApiError(error);
}

export default canvasApiCall;

export const handleConflictResponses = (responses) => {
  const conflictResponses = _.filter(
    responses,
    (response) => response.status === 409
  );

  if (!_.isEmpty(conflictResponses)) {
    let msg = '';
    _.forEach(conflictResponses, (conflictResponse, index) => {
      if (index > 0) msg += '\n\n';
      msg += conflictResponse.message;
      _.forEach(conflictResponse.values, (value) => {
        msg += '\n';
        msg += '\t';
        msg += value.name;
      });
    });
    InterfaceUtils.emitToast('warn', msg);
  }
};

export const handleBatchCallErrorResponses = (responses) => {
  // if received error responses while making batch API call, only throw one of them
  // 409 error (conflict) is handled differently via handleConflictResponses util
  const errors = _.filter(
    responses,
    (response) =>
      !response.status || (response.status >= 400 && response.status !== 409)
  );
  if (!_.isEmpty(errors)) {
    throw errors[0];
  }
};

export function* getPaginatedItems(path, responseField) {
  let lastEvaluatedKey = null;
  const items = [];
  do {
    const queryString = lastEvaluatedKey
      ? `?exclusiveStartKey=${encodeURIComponent(
          JSON.stringify(lastEvaluatedKey)
        )}`
      : '';

    const response = yield call(canvasApiCall, {
      path: `${path}${queryString}`,
    });

    // check for non success (propagate)
    if (response === null) return null;
    items.push(...response[responseField]);
    ({ lastEvaluatedKey } = response);
  } while (lastEvaluatedKey);
  return items;
}

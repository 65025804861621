import React from 'react';

import { Body1 } from '../../../../shared/typography/typography';

import { hereToHelp } from './common.metadata';
import { ImageContainer, HubImage } from '../taskManager.styles';

const metadata = {
  title: 'Palette 2',
  stepOrder: ['addHubToSetup', 'hereToHelp'],
  steps: {
    addHubToSetup: {
      title: 'Add a Canvas Hub',
      content: (
        <>
          <Body1>
            Adding Canvas Hub allows you to control your Palette 2 and printer
            directly from Canvas.
          </Body1>
          <ImageContainer>
            <HubImage />
          </ImageContainer>
        </>
      ),
    },
    hereToHelp,
  },
};

export default metadata;

// prefix for this thread's logs
const THREAD = 'main';

// log client events to the console
// (useful for debugging event handling behaviour)
const IOT_LOGGING_ENABLED = false;

// log developer notes to the console
// (useful for debugging order of function calls, etc.)
const IOT_LOGGING_DEV = false;

const logDevInfo = (...args) => {
  if (IOT_LOGGING_ENABLED && IOT_LOGGING_DEV) {
    // eslint-disable-next-line no-console
    console.info(THREAD, new Date(), ...args);
  }
};

const logInfo = (...args) => {
  if (IOT_LOGGING_ENABLED) {
    // eslint-disable-next-line no-console
    console.log(THREAD, new Date(), ...args);
  }
};

const logWarning = (...args) => {
  if (IOT_LOGGING_ENABLED) {
    // eslint-disable-next-line no-console
    console.warn(THREAD, new Date(), ...args);
  }
};

const logError = (...args) => {
  if (IOT_LOGGING_ENABLED) {
    // eslint-disable-next-line no-console
    console.error(THREAD, new Date(), ...args);
  }
};

export default {
  dev: logDevInfo,
  info: logInfo,
  warn: logWarning,
  error: logError,
};

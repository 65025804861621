import 'regenerator-runtime/runtime';
import { call, put, select, all } from 'redux-saga/effects';
import _ from 'lodash';

import API, { methods } from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

function* updateModelPath(model, newGroupName) {
  const { currentProject: projectId } = yield select(getSlicerState);
  const newModelPath = `|${newGroupName}|${model.name}`;
  const response = yield call(API, {
    method: methods.POST,
    path: `projects/${projectId}/models/${model.id}`,
    body: {
      path: newModelPath,
    },
  });
  return response;
}

export default function* updateModelGroupName(action) {
  try {
    const { oldGroupName, newGroupName, models } = action.payload;

    const responses = yield all(
      _.map(models, (model) => call(updateModelPath, model, newGroupName))
    );

    if (_.some(responses, (response) => response === null)) return;

    yield put(
      actions.updateModelGroupNameSuccess(oldGroupName, newGroupName, models)
    );
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

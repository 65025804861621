import styled, { css } from 'styled-components';
import { Breakpoints } from '../../themes';
import { ButtonText } from '../typography/typography';

export const StyledContainer = styled.div`
  border: none;
  padding: 0;
  min-height: 2rem;
  min-width: 0;
  box-shadow: none;
  color: ${(props) => props.theme.colors.textPrimary};
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      pointer-events: none;
      color: ${props.theme.colors.textTertiary};
    `}

  @media (max-width: ${Breakpoints.smallWidth}) {
    z-index: 1;
  }
`;

export const StyledSelect = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  padding: 0.515rem 1rem 0.515rem 0.75rem;
  border-radius: 0.25rem;
  background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  &:hover {
    background: ${(props) => props.theme.colors.elevatedBackgroundSecondary};
  }
`;

export const StyledSelectedOptionText = styled(ButtonText)`
  color: inherit;
  display: block;
  text-align: left;
  margin: 0;
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

export const ClearTagButtonWrapper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 0.5rem;
`;

export const DropdownContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
`;

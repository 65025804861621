import React from 'react';
import { Route } from 'react-router-dom';

const SoftRedirectRoute = ({
  component: Component,
  redirectComponent: RedirectComponent,
  shouldRedirect,
  updateNavStack,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      shouldRedirect ? (
        <Component {...props} updateNavStack={updateNavStack} />
      ) : (
        <RedirectComponent {...props} updateNavStack={updateNavStack} />
      )
    }
  />
);

export default SoftRedirectRoute;

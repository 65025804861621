import { fieldTypes, fieldSteps, slicers } from '../../../../../constants';
import tooltips from './tooltips.jsx';

const { EXTRUSION_WIDTH_STEP, LAYER_HEIGHT_MIN, LAYER_HEIGHT_STEP } =
  fieldSteps;
const { KISSlicer } = slicers;

const Metadata = {
  layerHeight: {
    tags: [],
    fields: [
      {
        name: 'layerHeight',
        label: (style) =>
          style.useVariableLayerHeight
            ? 'Minimum layer height'
            : 'Layer height',
        tags: ['layer thickness', 'resolution'],
        tooltip: tooltips.layerHeight,
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: LAYER_HEIGHT_MIN,
            gte: true,
            step: LAYER_HEIGHT_STEP,
          },
        ],
      },
      {
        name: 'firstLayerHeight',
        label: 'First layer height',
        tags: ['first layer thickness'],
        tooltip: tooltips.firstLayerHeight,
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: LAYER_HEIGHT_MIN,
            gte: true,
            step: LAYER_HEIGHT_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            units: '%',
            optionLabel: '% of layer height',
            tags: [],
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: 1,
            toBaseUnits: (value, style) =>
              Math.round(value * style.layerHeight) / 100,
            fromBaseUnits: (value, style) =>
              Math.round((value / style.layerHeight) * 100),
          },
        ],
      },
    ],
  },
  stepover: {
    name: 'useVariableLayerHeight',
    label: 'Variable layer heights',
    tags: ['variable layer thickness'],
    tooltip: tooltips.useVariableLayerHeight,
    slicers: [KISSlicer],
    disabled: (style, slicer) => slicer && slicer !== KISSlicer,
    isChecked: (style) => style.useVariableLayerHeight,
    updateFieldsOnToggle: (checked, updateField) => {
      updateField('useVariableLayerHeight', checked);
    },
    fields: [
      {
        name: 'maxLayerHeight',
        label: 'Maximum layer height',
        tags: ['maximum layer thickness'],
        tooltip: tooltips.maxLayerHeight,
        disabled: (style, slicer) =>
          !style.useVariableLayerHeight || (slicer && slicer !== KISSlicer),
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: LAYER_HEIGHT_MIN,
            gte: true,
            step: LAYER_HEIGHT_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            units: '%',
            optionLabel: '% of minimum layer height',
            tags: [],
            type: fieldTypes.number,
            min: 100,
            gte: false,
            step: 1,
            toBaseUnits: (value, style) =>
              Math.round(value * style.layerHeight) / 100,
            fromBaseUnits: (value, style) =>
              Math.round((value / style.layerHeight) * 100),
          },
        ],
      },
      {
        name: 'supportedStepover',
        label: 'Supported step width',
        tags: [],
        tooltip: tooltips.supportedStepover,
        disabled: (style, slicer) =>
          !style.useVariableLayerHeight || (slicer && slicer !== KISSlicer),
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: EXTRUSION_WIDTH_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            units: '%',
            optionLabel: '% of extrusion width',
            tags: [],
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: 1,
            toBaseUnits: (value, style) =>
              Math.round(value * style.extrusionWidth) / 100,
            fromBaseUnits: (value, style) =>
              Math.round((value / style.extrusionWidth) * 100),
          },
          {
            type: fieldTypes.auto,
            toBaseUnits: (value, style) =>
              Math.round((style.extrusionWidth / 2) * 100) / 100,
            fromBaseUnits: () => 'auto',
          },
        ],
      },
      {
        name: 'unsupportedStepover',
        label: 'Unsupported step width',
        tags: [],
        tooltip: tooltips.unsupportedStepover,
        disabled: (style, slicer) =>
          !style.useVariableLayerHeight || (slicer && slicer !== KISSlicer),
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: EXTRUSION_WIDTH_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            units: '%',
            optionLabel: '% of extrusion width',
            tags: [],
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: 1,
            toBaseUnits: (value, style) =>
              Math.round(value * style.extrusionWidth) / 100,
            fromBaseUnits: (value, style) =>
              Math.round((value / style.extrusionWidth) * 100),
          },
          {
            type: fieldTypes.auto,
            toBaseUnits: (value, style) =>
              Math.round((style.extrusionWidth / 2) * 100) / 100,
            fromBaseUnits: () => 'auto',
          },
        ],
      },
    ],
  },
};

export default Metadata;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, min-content);
  grid-column-gap: 0.5em;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;

export const ColorNameLabelWrapper = styled.div`
  grid-column: 1 / -2;
  grid-row: 1 / 2;
  display: flex;
  align-items: center;
`;

export const CloseButtonWrapper = styled.div`
  grid-column: -2 / -1;
  grid-row: 1 / 2;
  justify-self: end;
`;

export const SaveButtonWrapper = styled.div`
  grid-column: 1 / -1;
  grid-row: 4 / 5;
  justify-self: end;
  margin-top: 1em;
`;

export const HexFieldInputWrapper = styled.div`
  margin-top: 0.5rem;
  grid-row: 3 / 4;
  grid-column: 1 / span 2;
`;

export const RGBAInputWrapper = styled.div`
  margin-top: 0.5rem;
`;

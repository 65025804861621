import React, { Component } from 'react';
import { UnControlled as CodeMirror } from 'react-codemirror2';

import Input from '../input/input.jsx';
import { Container } from './codearea.styles';

import GCodeMode, { showHint as showGCodeHint } from './gcode.mode';
import gcodeStyles from './gcode.mode.styles';

import PrinterScriptMode, {
  showHint as showPSHint,
} from './printerscript.mode';
import printerScriptStyles from './printerscript.mode.styles';

import 'codemirror/addon/hint/show-hint';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/hint/show-hint.css';

const modes = {
  gcode: {
    mode: GCodeMode,
    name: GCodeMode.name,
    styles: gcodeStyles,
    onKeyDown: showGCodeHint,
  },
  printerscript: {
    mode: PrinterScriptMode,
    name: PrinterScriptMode.name,
    styles: printerScriptStyles,
    onKeyDown: showPSHint,
  },
};

const registerKeys = (editor) => {
  editor.setOption('extraKeys', {
    Tab: (cm) => {
      const spaces = Array(cm.getOption('indentUnit') + 1).join(' ');
      cm.replaceSelection(spaces);
    },
  });
};

class Codearea extends Component {
  static defaultProps = {
    ...Input.defaultProps,
    height: '8em',
    autoResize: false,
    tabSize: 2,
    mode: modes.gcode.name,
    onChange: (/* value, cursor */) => {},
  };

  static instanceCount = 0;

  constructor(props) {
    super(props);
    this.instanceId = Codearea.instanceCount;
    Codearea.instanceCount++;
    this.editor = null;
    this.codeMirrorOptions = {
      lineNumbers: true,
      mode: props.mode || modes.gcode.name,
      theme: `canvas${this.instanceId}`,
      tabSize: props.tabSize,
      smartIndent: true,
      lineWrapping: true,
    };
    if (props.autoResize) {
      this.codeMirrorOptions.viewportMargin = Infinity;
    }
    this.state = {
      cursor: props.cursor || {
        line: 0,
        ch: 0,
      },
    };
  }

  componentDidUpdate(prevProps) {
    const { cursor } = this.props;
    if (cursor && cursor !== prevProps.cursor) {
      this.setState({
        cursor: {
          ...this.state.cursor,
          ...cursor,
        },
      });
    }
  }

  render() {
    const styleProps = { ...this.props };
    if (this.props.autoResize) {
      styleProps.height = 'auto';
      styleProps.minHeight = this.props.height;
    }
    return (
      <Container
        modeStyles={modes[this.props.mode].styles(this.instanceId, styleProps)}>
        <CodeMirror
          defineMode={modes[this.props.mode].mode}
          options={this.codeMirrorOptions}
          value={this.props.value}
          cursor={this.state.cursor}
          editorDidMount={(editor) => {
            registerKeys(editor);
            this.editor = editor;
            if (this.props.cursor) {
              this.editor.focus();
            }
          }}
          onKeyDown={modes[this.props.mode].onKeyDown}
          onChange={(editor, state, value) => {
            if (!this.editor || editor !== this.editor) return;
            if (!state.origin) return;
            const cursor = editor.getCursor();
            this.setState({ cursor }, () => {
              this.props.onChange(value, cursor);
            });
          }}
        />
      </Container>
    );
  }
}

export default Codearea;

import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  user-select: none;
`;

import styled, { css } from 'styled-components';
import {
  darkTextColor,
  lightTextColor,
} from '../../../../shared/colorSwatch/colorSwatch.styles';

export const Swatches = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.count}, 1fr);
  grid-gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const SourceInputs = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const TargetInputContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const TargetInputSelection = styled.div`
  position: absolute;
  top: calc(100% + 0.5rem);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.grey4};
  background: ${(props) => props.theme.colors.elevatedBackgroundSecondary};
  z-index: 1;

  ${(props) =>
    props.align === 'left' &&
    css`
      left: 0;
      right: auto;
    `};
  ${(props) =>
    props.align === 'right' &&
    css`
      left: auto;
      right: 0;
    `};
`;

export const TargetInputIcon = styled.div`
  position: absolute;
  top: calc(50% - 1rem);
  left: calc(50% - 1rem);
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

export const TargetInputOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.6rem;
  height: 1.6rem;
  color: ${(props) => (props.bright ? darkTextColor : lightTextColor)};
  background-color: ${(props) =>
    `rgb(${props.color[0]}, ${props.color[1]}, ${props.color[2]})`};
  cursor: pointer;

  :hover {
    opacity: 0.9;
  }
`;

export const TargetInputs = styled.div`
  display: flex;
  flex-direction: column;
`;

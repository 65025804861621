import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  width: ${(props) => props.widthPx}px;
  height: ${(props) => props.heightPx}px;
  min-width: ${(props) => props.widthPx}px;
  min-height: ${(props) => props.heightPx}px;
  overflow: hidden;
`;

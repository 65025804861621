import React, { Component } from 'react';
import _ from 'lodash';
import { withTheme } from 'styled-components';

import {
  Container,
  FieldRow,
  OffsetButtons,
  FieldsContainer,
  ToolLabelWrapper,
  ResetButtonWrapper,
  XStyles,
  YStyles,
  ZStyles,
  OffsetButtonWrapper,
  NumberFieldWrapper,
} from '../transformContents.styles';

import { Button, Icon, Input } from '../../../../../shared';

import { SceneUtils, TransformUtils } from '../../../../../utils';
import { Icons } from '../../../../../themes';

import { Subtitle2 } from '../../../../../shared/typography/typography';

class TranslationContents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPositions: { x: '', y: '', z: '' },
    };
  }

  componentDidMount() {
    this.computeAxisValues();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pivot !== this.props.pivot && this.props.pivot !== null) {
      // gizmo attached/updated
      this.computeAxisValues();
    } else if (prevProps.pivot !== null && this.props.pivot === null) {
      // gizmo detached
      this.resetAxisValues();
    }
  }

  computeAxisValues() {
    const { selectedModels, selectedTower, pivot } = this.props;

    let axisPositions = { x: '', y: '', z: '' };

    if (selectedTower || !_.isEmpty(selectedModels)) {
      axisPositions = { ...pivot };
    }

    this.setState({
      currentPositions: { ...axisPositions },
    });
  }

  resetAxisValues() {
    this.setState({
      currentPositions: { x: '', y: '', z: '' },
    });
  }

  handleKeyDown(e, axis) {
    if (!(e.keyCode === 40 || e.keyCode === 38)) return;
    e.preventDefault();
    e.stopPropagation();
    const sign = e.keyCode === 40 ? -1 : 1;
    const magnitude = e.shiftKey ? 5 : 1;
    this.setTranslation(axis, sign * magnitude, true);
  }

  handleKeyUp(e) {
    e.stopPropagation();
  }

  onChangeSuccess(axis, newValue) {
    this.setState(
      {
        currentPositions: {
          ...this.state.currentPositions,
          [axis]: newValue,
        },
      },
      () => {
        this.setTranslation(axis, newValue, false);
      }
    );
  }

  renderAxes() {
    const labels = ['x', 'y', 'z'];
    const axes = _.map(labels, (label, index) => this.renderAxis(label, index));
    return <FieldsContainer>{axes}</FieldsContainer>;
  }

  renderAxis(axis, index) {
    return (
      <FieldRow key={index}>
        {this.renderNumberField(axis)}
        {this.renderOffsetButtons(axis)}
        {this.renderAxisColorFlag(index)}
      </FieldRow>
    );
  }

  renderAxisColorFlag(index) {
    let style = null;
    if (index === 0) style = <XStyles />;
    else if (index === 1) style = <YStyles />;
    else if (index === 2) style = <ZStyles />;
    return style;
  }

  renderNumberField(axis) {
    let value = this.state.currentPositions[axis];
    const isDisabled = typeof value !== 'number';
    if (!isDisabled) {
      value = Math.round(value * 1000) / 1000;
    }
    return (
      <NumberFieldWrapper>
        <Input
          label={axis.toUpperCase()}
          type='number'
          units='mm'
          value={value}
          disabled={isDisabled}
          onChangeSuccess={(newValue) => this.onChangeSuccess(axis, newValue)}
          revertOnBlur
          stopPropagationOnKeyDown
          onKeyDown={(e) => this.handleKeyDown(e, axis)}
          onKeyUp={(e) => this.handleKeyUp(e)}
        />
      </NumberFieldWrapper>
    );
  }

  renderOffsetButtons(axis) {
    const isDisabled =
      (this.props.selectedTower && axis === 'z') ||
      _.isEmpty(this.props.selectedModels);

    return (
      <OffsetButtons>
        <OffsetButtonWrapper>
          <Button
            expand
            minWidth='0'
            height='1.9em'
            title='Move by +10 mm'
            disabled={isDisabled}
            icon={Icons.basic.plus}
            iconSize={Icon.sizes.MINI}
            iconColor={this.props.theme.colors.grey6}
            onClick={() => this.setTranslation(axis, 10, true)}></Button>
        </OffsetButtonWrapper>
        <OffsetButtonWrapper>
          <Button
            expand
            minWidth='0'
            height='1.9em'
            title='Move by –10 mm'
            disabled={isDisabled}
            icon={Icons.basic.minus}
            iconSize={Icon.sizes.MINI}
            iconColor={this.props.theme.colors.grey6}
            onClick={() => this.setTranslation(axis, -10, true)}></Button>
        </OffsetButtonWrapper>
      </OffsetButtons>
    );
  }

  renderLabel() {
    return (
      <ToolLabelWrapper>
        <Subtitle2>Move</Subtitle2>
      </ToolLabelWrapper>
    );
  }

  renderResetButton() {
    const isDisabled =
      _.isEmpty(this.props.selectedModels) && !this.props.selectedTower;
    return (
      <ResetButtonWrapper>
        <Button
          minWidth='0'
          height='2em'
          disabled={isDisabled}
          onClick={() => this.resetTranslation()}>
          Reset
        </Button>
      </ResetButtonWrapper>
    );
  }

  resetTranslation() {
    const { selectedModels, selectedTower, bedSize, originOffset } = this.props;
    const bedMiddle = SceneUtils.getBedMiddle(bedSize, originOffset);

    if (selectedTower) {
      selectedTower.mesh.position.x = bedMiddle.x;
      selectedTower.mesh.position.y = bedMiddle.y;
      const position = {
        x: bedMiddle.x,
        y: bedMiddle.y,
        z: selectedTower.position.z,
      };
      this.props.setTowerPosition(position);
    }

    if (!_.isEmpty(selectedModels)) {
      const delta = TransformUtils.getOffsetToBedCenter(
        selectedModels,
        bedMiddle
      );
      const updatedTranslations = _.map(selectedModels, (model) => ({
        id: model.id,
        transforms: {
          translate: {
            x: model.transforms.translate.x + delta.x,
            y: model.transforms.translate.y + delta.y,
            z: model.transforms.translate.z + delta.z,
          },
        },
      }));
      this.props.updateModelTransforms(updatedTranslations);
    }
  }

  setTranslation(axis, translation, isOffset) {
    const { selectedModels, selectedTower } = this.props;

    let delta;
    if (isOffset) {
      delta = translation;
    } else {
      delta = translation - this.props.pivot[axis];
    }

    if (selectedTower) {
      const position = {
        ...selectedTower.position,
        [axis]: selectedTower.position[axis] + delta,
      };
      this.props.setTowerPosition(position);
    }

    if (!_.isEmpty(selectedModels)) {
      this.props.updateModelTransforms(
        _.map(selectedModels, (model) => {
          const transforms = {
            translate: {
              ...model.transforms.translate,
              [axis]: model.mesh.position[axis] + delta,
            },
          };
          return { id: model.id, transforms };
        })
      );
    }
  }

  render() {
    return (
      <Container>
        {this.renderLabel()}
        {this.renderResetButton()}
        {this.renderAxes()}
      </Container>
    );
  }
}

export default withTheme(TranslationContents);

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import { getThreeState, handleGenericError } from '../common';
import actions from '../../reducers/three/actions';
import { setRenderFlag } from './animationFrame';

export default function* detachGizmo(action) {
  try {
    const { scene, gizmo } = yield select(getThreeState);

    if (scene) {
      gizmo.detach();
    }
    yield put(actions.detachGizmoSuccess());
    setRenderFlag();
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import styled from 'styled-components';

import { Breakpoints, Shadows } from '../../../../../themes';

import { Body1, Caption } from '../../../../../shared';
import {
  DefaultOption,
  DefaultSelect,
} from '../../../../../shared/abstractDropdown/abstractDropdown.styles';

export const Container = styled.div`
  display: flex;
  width: 13rem;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  background-color: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  ${Shadows.d1};
  display: flex;
  border-radius: 4px;
`;

export const ColorSwatch = styled.div`
  flex: none;
  flex-shrink: 0;
  margin-right: 0.5rem;
  background: ${(props) => props.color};
  border-radius: 0.25rem;
  box-sizing: border-box;
  height: 1.25rem;
  width: 1.25rem;
  ${(props) =>
    props.isBright &&
    props.theme.name !== 'dark' &&
    ` border: 1px solid ${props.theme.colors.grey4};`}
`;

export const CustomColorSwatch = styled(ColorSwatch)`
  background: conic-gradient(
    from 180deg at 50% 50%,
    #ee1c1c 0deg,
    #f8ab37 125.63deg,
    #ffe248 174.38deg,
    #e3df46 223.13deg,
    #0075ff 360deg
  );
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.75rem;
`;

export const ColorSelectionWrapper = styled.div`
  margin: 0 0.75rem;
`;

export const MaterialSelectionWrapper = styled.div`
  margin: 0.5rem 0.75rem 0.75rem 0.75rem;
`;

export const NewMaterialOption = styled(Body1)`
  color: ${(props) => props.theme.colors.greenDefault};
  margin: 0;
`;

export const StyledMaterialOption = styled(DefaultOption)`
  padding: 0.75rem;
`;

export const StyledMaterialOptionWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const StyleOverrideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0.75rem 0.75rem 0 0.75rem;
  margin-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  & ${Caption} {
    margin-bottom: 0.25rem;
  }
  & ${Body1} {
    margin-bottom: 0.75rem;
  }
  @media (max-height: ${Breakpoints.largeHeight}) {
    max-height: 27rem;
  }
  @media (max-height: ${Breakpoints.mediumHeight}) {
    max-height: 12rem;
  }
  @media (max-height: ${Breakpoints.smallHeight}) {
    max-height: 10rem;
  }
`;

export const StyledMaterialSelect = styled(DefaultSelect)`
  justify-content: flex-start;
  div:last-child {
    margin-left: auto;
  }
`;

import styled, { css } from 'styled-components';

import { Breakpoints } from '../../../../themes';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  @media (max-width: ${Breakpoints.mediumWidth}) {
    flex-direction: column;
  }
`;

export const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  max-width: calc(50% - 1rem);
  @media (max-width: ${Breakpoints.mediumWidth}) {
    max-width: none;
  }
  &:empty {
    display: none;
  }
`;

export const FieldRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 0.25rem 0;
  position: relative;
  text-align: left;
`;

export const FieldGroupLabel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 1.5rem;
  font-size: 0.9rem;
  font-weight: ${(props) => props.theme.fonts.weightBold};
  flex: 1;
  margin-bottom: 0.25rem;
  color: ${(props) => props.theme.colors.textPrimary};
`;

export const FieldGroup = styled.div`
  padding: 0.2rem 0.4rem 0;
  box-sizing: border-box;
  ${(props) =>
    !props.isEmpty &&
    css`
      margin-bottom: 0.5rem;
      padding-bottom: 0.2rem;
    `}
`;

export const GroupCheckboxWrapper = styled.div`
  margin-right: 0.5rem;
`;

export const FieldOverrideContainer = styled.div`
  width: 100%;
  font-size: 0.75rem;
  text-align: left;
  padding-left: 0.75rem;
`;

export const FieldOverrideMiniSwatchContainer = styled.span`
  display: inline-block;
  box-sizing: content-box;
  width: 1rem;
  height: 1rem;
  position: relative;
  top: 0.1rem;
  margin-right: 0.3rem;
  margin-left: 0.2rem;
  &:first-child {
    margin-left: 0;
  }
`;

export const HighlightsContainer = styled.div`
  display: flex;
  position: absolute;
  right: 3.25rem;

  @media (max-width: ${Breakpoints.mediumWidth}) {
    display: none;
  }
`;

export const Highlight = styled.div`
  display: flex;
  margin-left: 0.5rem;
`;

export const HighlightTextWrapper = styled.div`
  margin-right: 0.3rem;
  p {
    font-weight: 600;
  }
`;

export const VariableTransitionLengthButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 0.8rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
`;

export const GroupBadgesContainer = styled.div`
  position: absolute;
  right: 0;
  z-index: 10;
`;

export const CheckboxBadgesContainer = styled.div`
  float: right;
  margin-top: 0.15rem;
`;

export const InputBadgesContainer = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  z-index: 10;
`;

export const BadgeWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
`;

import * as THREE from 'three';

// A convenience call to set the vert, colors, normals,
// widths, heights, faces, and attributes for a Meshline THREE object geometry
// Returns a THREE.Geometry
// example setup:
// for a two line segments A -> B, B -> C
// Vertices array should mbe duplicated
// [ Ax, Ay, Az, Ax, Ay, Az, Bx, By, Bz, Bx, By, Bz, Cx, Cy, Cz, Cx, Cy, Cz ]
// normals will be the direction of the line segment.
// one example [ Bx-Ax, By-Ay, Bz-Az ...]
// Faces must be formed as a rectangle
// [ 0, 2, 1,  2, 1, 3,  2, 4, 3,  4, 3, 5]

export function getMeshLineGeometry(
  geometryArray,
  colorArray,
  widthArray,
  heightArray,
  normalArray,
  indicesArray,
  isLerpedColorArray = false
) {
  const side = [];

  if (geometryArray instanceof Float32Array === false) {
    throw new Error('Invalid vert type');
  }

  if (widthArray instanceof Float32Array === false) {
    throw new Error('Invalid width type');
  }

  if (heightArray instanceof Float32Array === false) {
    throw new Error('Invalid height type');
  }

  if (normalArray instanceof Float32Array === false) {
    throw new Error('Invalid normal type');
  }

  if (colorArray instanceof Float32Array === false) {
    throw new Error('Invalid color type');
  }

  if (indicesArray instanceof Uint32Array === false) {
    throw new Error('Invalid indices type');
  }

  let colorCount = colorArray.length;
  let colorItemSize = 3;
  if (isLerpedColorArray) {
    colorCount *= 3; // one float per vert
    colorItemSize = 1;
  }

  if (
    colorCount !== geometryArray.length ||
    colorCount !== normalArray.length ||
    widthArray.length * 3 !== geometryArray.length ||
    widthArray.length !== heightArray.length
  ) {
    throw new Error('Array lengths do not match');
  }

  // Go though the set and build the data
  const gLength = geometryArray.length / 6;

  for (let j = 0; j < gLength; j++) {
    side.push(1);
    side.push(-1);
  }

  const geometry = new THREE.BufferGeometry();
  geometry.addAttribute(
    'position',
    new THREE.BufferAttribute(geometryArray, 3)
  );
  geometry.addAttribute(
    'color',
    new THREE.BufferAttribute(colorArray, colorItemSize)
  );
  geometry.addAttribute('direction', new THREE.BufferAttribute(normalArray, 3));
  geometry.addAttribute(
    'side',
    new THREE.BufferAttribute(new Int8Array(side), 1)
  );
  geometry.addAttribute('width', new THREE.BufferAttribute(widthArray, 1));
  geometry.addAttribute('height', new THREE.BufferAttribute(heightArray, 1));
  geometry.setIndex(new THREE.BufferAttribute(indicesArray, 1));

  return geometry;
}

// eslint-disable-next-line no-sparse-arrays
THREE.ShaderChunk['meshline_vert'] = `

    #if NUM_POINT_LIGHTS > 0
     struct PointLight {
       vec3 position;
    };
    uniform PointLight pointLights[ NUM_POINT_LIGHTS ];
    #endif
    uniform vec3 ambientLightColor;

    attribute mediump vec3 direction;
    attribute lowp float side;
    attribute mediump float width;
    attribute mediump float height;
    attribute lowp vec3 color;

    uniform lowp float opacity;
    uniform mediump float near;
    uniform mediump float far;
    uniform mediump float bottom;
    uniform mediump float top;

    varying lowp float vUV;
    varying lowp vec4 vColor;
    varying lowp vec3 N, H;
    varying lowp vec3 lightDir;
    varying lowp vec3 ambient;

    vec3 fix( vec4 i, float aspect ) {

        vec3 res = i.xyz / abs(i.w);
        res.x *= aspect;
        return res;
    }

    void main() {

        // Discard the vert if its below or above the clipping verticals
        if (position.z < bottom || position.z > top) {
            vColor = vec4(0,0,0,-1);
        } else {
           float aspect = projectionMatrix[1][1] / projectionMatrix[0][0];
           mat4 m = projectionMatrix * modelViewMatrix;

           vec3 boundedColor = max( vec3(0.2), color );
           vColor = vec4( boundedColor, opacity );
           vUV = clamp(-side, 0.0, 1.0);

           vec4 finalPosition = m * vec4( position, 1.0 );

           lightDir = normalize(  pointLights[0].position - (modelViewMatrix * vec4(position, 1.0)).xyz );
           ambient = ambientLightColor;

           // Get the direction of the line in screen space
           vec3 dirM = fix(m * vec4(direction + position, 1.0), aspect) - fix(m * vec4(position, 1.0), aspect);

           //And the Perpendicular
           vec2 normal = normalize(vec2( -dirM.y, dirM.x ));

           // Get the angle of the world up relative to the camera
           float dotZ = abs( dot(normalize(position - cameraPosition), vec3(0.0, 0.0, 1.0)) );

           // normal.y *= aspect;
           normal.x /= aspect;

           float dist = (width * dotZ ) + (height * sin(acos(dotZ)));
           normal.x *= (width * 0.5);
           normal.y *= (dist * 0.5);

           vec4 persp = projectionMatrix * vec4(0,1,0,0);
           vec2 offset = normal * side * persp.y;
           finalPosition.xy += offset.xy;

           gl_Position = finalPosition;

           vec3 nDir = normalize(direction);
           H = normalize( (modelViewMatrix * vec4(nDir + position, 1.0) - modelViewMatrix * vec4(position, 1.0)).xyz);
           N = cross(H, normalize(vec3(normal.xy,0.0)));
        }
    }
`;

THREE.ShaderChunk['meshline_vert_lerp'] = `

    #if NUM_POINT_LIGHTS > 0
     struct PointLight {
       vec3 position;
    };
    uniform PointLight pointLights[ NUM_POINT_LIGHTS ];
    #endif
    uniform vec3 ambientLightColor;

    attribute mediump vec3 direction;
    attribute lowp float side;
    attribute mediump float width;
    attribute mediump float height;
    attribute lowp float color;

    uniform lowp float opacity;
    uniform mediump float near;
    uniform mediump float far;
    uniform mediump float bottom;
    uniform mediump float top;
    uniform lowp vec3 minColor;
    uniform lowp vec3 maxColor;

    varying lowp float vUV;
    varying lowp vec4 vColor;
    varying lowp vec3 N, H;
    varying lowp vec3 lightDir;
    varying lowp vec3 ambient;

    vec3 fix( vec4 i, float aspect ) {

        vec3 res = i.xyz / abs(i.w);
        res.x *= aspect;
        return res;
    }

    void main() {

        // Discard the vert if its below or above the clipping verticals
        if (position.z < bottom || position.z > top) {
            vColor = vec4(0,0,0,-1);
        } else {
           float aspect = projectionMatrix[1][1] / projectionMatrix[0][0];
           mat4 m = projectionMatrix * modelViewMatrix;

           vec3 trueColor = mix(minColor, maxColor, color);
           vColor = vec4( trueColor, opacity );
           vUV = clamp(-side, 0.0, 1.0);

           vec4 finalPosition = m * vec4( position, 1.0 );

           lightDir = normalize(  pointLights[0].position - (modelViewMatrix * vec4(position, 1.0)).xyz );
           ambient = ambientLightColor;

           // Get the direction of the line in screen space
           vec3 dirM = fix(m * vec4(direction + position, 1.0), aspect) - fix(m * vec4(position, 1.0), aspect);

           //And the Perpendicular
           vec2 normal = normalize(vec2( -dirM.y, dirM.x ));

           // Get the angle of the world up relative to the camera
           float dotZ = abs( dot(normalize(position - cameraPosition), vec3(0.0, 0.0, 1.0)) );

           // normal.y *= aspect;
           normal.x /= aspect;

           float dist = (width * dotZ ) + (height * sin(acos(dotZ)));
           normal.x *= (width * 0.5);
           normal.y *= (dist * 0.5);

           vec4 persp = projectionMatrix * vec4(0,1,0,0);
           vec2 offset = normal * side * persp.y;
           finalPosition.xy += offset.xy;

           gl_Position = finalPosition;

           vec3 nDir = normalize(direction);
           H = normalize( (modelViewMatrix * vec4(nDir + position, 1.0) - modelViewMatrix * vec4(position, 1.0)).xyz);
           N = cross(H, normalize(vec3(normal.xy,0.0)));
        }
    }
`;

THREE.ShaderChunk['meshline_frag'] = `
    uniform lowp float alphaTest;

    varying lowp float vUV;
    varying lowp vec4 vColor;
    varying lowp vec3 ambient;
    varying lowp vec3 N, H;
    varying lowp vec3 lightDir;

    mat3 rotationMatrix(vec3 axis, float angle)
    {
        float s = sin(angle);
        float c = cos(angle);
        float oc = 1.0 - c;

        return mat3(oc * axis.x * axis.x + c, oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,
          oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,
          oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c);
    }

    float lambert(vec3 N, vec3 L) {
       vec3 nrmN = normalize(N);
       vec3 nrmL = normalize(L);
       float result = dot(nrmN, nrmL);
       return max(result, 0.0);
    }

    void main() {

        vec4 c = vColor;
        if( c.a < alphaTest ) discard;

        float angle = asin((vUV * 2.0) - 1.0);
        mat3 m = rotationMatrix(H,angle);
        vec3 norm = m * N;

        // write Total Color:
        float lamb = lambert(norm, lightDir);
        vec3 result = (c.xyz * lamb) + (ambient * (1.0-lamb) * 0.318319274232055);
        gl_FragColor = vec4(result, c.a);

    }
`;

const DEFAULT_TOP = 16384;
const DEFAULT_BOTTOM = -16384;

export function MeshLineMaterial(parameters) {
  const uniforms = THREE.UniformsUtils.merge([
    THREE.UniformsLib['ambient'],
    THREE.UniformsLib['lights'],
    {
      opacity: { value: 1 },
      sizeAttenuation: { value: 1 },
      near: { value: 1 },
      far: { value: 1 },
      top: { value: DEFAULT_TOP }, // support mediump precision
      bottom: { value: DEFAULT_BOTTOM }, // support mediump precision
      alphaTest: { value: 0 },
    },
  ]);

  THREE.ShaderMaterial.call(this, {
    uniforms,
    vertexShader: THREE.ShaderChunk.meshline_vert,
    fragmentShader: THREE.ShaderChunk.meshline_frag,
    depthTest: true,
    depthWrite: true,
    transparent: false,
    lights: true,
  });

  this.type = 'MeshLineMaterial';
  this.side = THREE.DoubleSide;

  this.setValues(parameters);
}

MeshLineMaterial.prototype = Object.create(THREE.ShaderMaterial.prototype);
MeshLineMaterial.prototype.constructor = MeshLineMaterial;

export function MeshLineMaterialLerp(parameters) {
  const uniforms = THREE.UniformsUtils.merge([
    THREE.UniformsLib['ambient'],
    THREE.UniformsLib['lights'],
    {
      opacity: { value: 1 },
      sizeAttenuation: { value: 1 },
      near: { value: 1 },
      far: { value: 1 },
      top: { value: DEFAULT_TOP }, // support mediump precision
      bottom: { value: DEFAULT_BOTTOM }, // support mediump precision
      alphaTest: { value: 0 },
      minColor: { value: new THREE.Vector3(0, 0, 0) },
      maxColor: { value: new THREE.Vector3(1, 1, 1) },
    },
  ]);

  THREE.ShaderMaterial.call(this, {
    uniforms,
    vertexShader: THREE.ShaderChunk.meshline_vert_lerp,
    fragmentShader: THREE.ShaderChunk.meshline_frag,
    depthTest: true,
    depthWrite: true,
    transparent: false,
    lights: true,
  });

  this.type = 'MeshLineMaterialLerp';
  this.side = THREE.DoubleSide;

  this.setValues(parameters);
}

MeshLineMaterialLerp.prototype = Object.create(THREE.ShaderMaterial.prototype);
MeshLineMaterialLerp.prototype.constructor = MeshLineMaterialLerp;

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';
import { InterfaceUtils } from '../../../utils';

export default function* updateProjectName(action) {
  try {
    const { projects } = yield select(getSlicerState);
    const { projectId, newProjectName, allowMakeNameUnique } = action.payload;

    const response = yield call(API, {
      method: methods.POST,
      path: `projects/${projectId}/name`,
      body: {
        name: newProjectName,
        allowMakeNameUnique,
      },
    });
    if (response === null) return;

    const existingProject = projects[projectId];
    const project = {
      ...existingProject,
      modified: response.project.modified,
      name: response.project.name,
    };
    yield put(actions.updateProjectNameSuccess(project));
    if (!allowMakeNameUnique) {
      InterfaceUtils.emitToast(
        'success',
        'Project has been successfully renamed'
      );
    }
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

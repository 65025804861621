import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../canvas-api';
import { handleGenericError } from '../common';
import { actions } from '../../reducers/printers/printers';

export default function* createSharedPrinter(action) {
  try {
    const { printerId, styleIds } = action.payload;
    const body = {
      printerId,
      styleIds,
    };

    const response = yield call(API, {
      method: methods.POST,
      path: `printers/${printerId}/share`,
      body,
    });
    if (response === null) return;

    const sharedPrinterData = response.sharedPrinter;
    const { id, dateModified } = sharedPrinterData;
    const shareData = {
      shareId: id,
      dateShared: dateModified,
      sharedStyleIds: styleIds,
    };
    yield put(actions.updateSharedPrinterSuccess(printerId, shareData));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import React from 'react';
import _ from 'lodash';

import RadioButton from '../radioButton/radioButton.jsx';

import { List, StyledLabel } from './radioList.styles';

import { Body1 } from '../typography/typography';

const RadioListItem = (props) => (
  <StyledLabel>
    <RadioButton
      value={props.value}
      checked={props.checked}
      disabled={props.disabled}
      onChange={props.onChange}
    />
    <Body1 noSpacing disabled={props.disabled}>
      {props.label}
    </Body1>
  </StyledLabel>
);

const RadioList = (props) => (
  <List>
    {_.map(props.options, (option) => (
      <RadioListItem
        key={option.value}
        label={option.label}
        value={props.value}
        checked={props.value === option.value}
        disabled={props.disabled || option.disabled}
        onChange={() => props.onChange(option.value)}
      />
    ))}
  </List>
);

export default RadioList;

import React, { useState, useRef } from 'react';
import { useTheme } from 'styled-components';

import { StyledSearchBar, StyledInput, IconWrapper } from './searchBar.styles';

import { Icons } from '../../themes';
import Icon from '../icon/icon.jsx';

const SearchBar = ({
  disabled,
  placeholder = '',
  value,
  onKeyDown,
  onChange, // i.e. setValue
  containerRef,
  setShowDropdown,
}) => {
  const theme = useTheme();

  const inputRef = useRef(null);
  const [focused, setFocused] = useState(false);

  const onKeyDownHandler = (event) => {
    if (onKeyDown) {
      onKeyDown(event);
    }
    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      onChange('');
    }
  };

  const onMouseDown = (e) => {
    if (!containerRef) return;
    if (containerRef.current?.contains(e.target)) return;
    if (!setShowDropdown) return;
    setShowDropdown(false);
  };

  const focusOnInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const onResetClick = (event) => {
    event.stopPropagation();
    onChange('');
    focusOnInput();
  };

  const renderIcon = () => {
    const icon = value ? Icons.basic.x : Icons.basic.search;
    const iconColor = focused ? theme.colors.grey6 : theme.colors.grey5;

    return (
      <IconWrapper onClick={(event) => onResetClick(event)}>
        <Icon src={icon} color={iconColor} />
      </IconWrapper>
    );
  };

  const showDropdown = () => {
    if (!setShowDropdown) return;
    setShowDropdown(true);
  };

  const renderInput = () => (
    <StyledInput
      ref={inputRef}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onFocus={() => {
        setFocused(true);
        showDropdown();
      }}
      onBlur={() => setFocused(false)}
      onKeyDown={onKeyDownHandler}
      onMouseDown={onMouseDown}
      onChange={(e) => onChange(e.target.value)}
    />
  );

  return (
    <StyledSearchBar disabled={disabled}>
      {renderIcon()}
      {renderInput()}
    </StyledSearchBar>
  );
};

export default SearchBar;

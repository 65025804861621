import React, { Component } from 'react';

import { SliderContainer, SliderLabel } from './toolpathSlider.styles';

import { Slider } from '../../../../shared';

import { Caption } from '../../../../shared/typography/typography';

export default class ToolpathSlider extends Component {
  constructor(props) {
    super(props);
    this.onLayerChange = this.onLayerChange.bind(this);
    this.getLayerSliderText = this.getLayerSliderText.bind(this);
  }

  onLayerChange(e, values, activeThumb) {
    // user dragging lower thumb
    if (activeThumb === 0) {
      const min = values[0];
      let { maxLayer: max } = this.props.toolpath;
      if (min <= this.props.toolpath.layerCount - 1) {
        if (min >= max && max < this.props.toolpath.layerCount) {
          max = min + 1;
        }
        this.props.setToolpathLayerRange(min, max);
      }
    }
    // user dragging upper thumb
    else {
      const max = values[1];
      let { minLayer: min } = this.props.toolpath;
      if (max >= 2) {
        if (max <= min && min > 0) {
          min = max - 1;
        }
        this.props.setToolpathLayerRange(min, max);
      }
    }
  }

  getLayerSliderText(layerIndex) {
    const { layerHeights } = this.props.toolpath;
    // layerIndex starts with 1
    let layerNumber = layerIndex;
    let layerZ = layerHeights[layerIndex - 1];
    // adjust layer number and layer z for the layer height arrays starting with 0
    if (layerHeights[0] === 0) {
      layerNumber = layerIndex === 1 ? layerIndex : layerIndex - 1;
      layerZ = layerHeights[layerNumber];
    }
    return (
      <SliderLabel>
        <Caption grey noSpacing>
          Layer {layerNumber}
        </Caption>
        <Caption grey noSpacing>
          Z = {layerZ} mm
        </Caption>
      </SliderLabel>
    );
  }

  renderLayerSlider() {
    return (
      <Slider
        vertical
        value={[this.props.toolpath.minLayer, this.props.toolpath.maxLayer]}
        min={1}
        max={this.props.toolpath.layerCount}
        step={1}
        onChange={this.onLayerChange}
        valueLabelFormatter={this.getLayerSliderText}
      />
    );
  }

  render() {
    return <SliderContainer> {this.renderLayerSlider()} </SliderContainer>;
  }
}

import styled, { css } from 'styled-components';
import { Breakpoints } from '../../../../themes';

export const ClosedBar = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  z-index: 999;
`;

export const MenuButtonWrapper = styled.div`
  ${(props) => props.pageComponent && 'display: none;'}

  @media (max-width: ${Breakpoints.largeWidth}) {
    ${(props) => props.pageComponent && 'display: block;'}
  }
`;

export const TopLogoWrapper = styled.div`
  margin-left: 1rem;

  @media (max-width: ${Breakpoints.largeWidth}) {
    display: none;
  }
`;

export const SideBarToggleIconWrapper = styled.div`
  cursor: pointer;
  margin-left: 1rem;
  display: block;

  @media (max-width: ${Breakpoints.smallWidth}) {
    ${(props) =>
      !props.active &&
      css`
        display: none;
      `}
  }
`;

export const BackButtonWrapper = styled.div`
  cursor: pointer;
  display: none;

  @media (max-width: ${Breakpoints.smallWidth}) {
    display: block;
    margin: 0 0 0 1rem;
  }
`;

export const OpenModalWrapper = styled.div`
  > div {
    justify-content: flex-start;
    > div {
      padding: 0;
      max-height: 100%;
    }
  }

  ${(props) => props.pageComponent && 'display:none;'}

  @media (max-width: ${Breakpoints.largeWidth}) {
    ${(props) => props.pageComponent && 'display: block;'}
  }
`;

export const OpenModalContent = styled.div`
  display: flex;
  padding: 0;
  height: 100%;
`;

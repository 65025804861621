import 'regenerator-runtime/runtime';
import { all, call, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import { getPaginatedItems } from '../canvas-api';
import { handleGenericError, getAuthState } from '../common';
import { actions } from '../../reducers/iot/iot';
import { subscribeToDevice } from './subscriptions';
import { waitForInitConnection } from './initConnection';

export default function* getDevices(action) {
  try {
    const { userId } = yield select(getAuthState);
    const devices = yield call(
      getPaginatedItems,
      `devices/user/${userId}`,
      'devices'
    );
    if (devices === null) return;
    yield put(actions.getDevicesSuccess(devices));
    const connectionInitialized = yield call(waitForInitConnection);
    if (connectionInitialized) {
      yield all(_.map(devices, (device) => call(subscribeToDevice, device.id)));
    }
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import API, { methods, errorTypes } from '../canvas-api';
import { getAuthState, handleGenericError } from '../common';
import { actions } from '../../reducers/auth/auth';
import { InterfaceUtils } from '../../utils';

export default function* refreshUserToken(action) {
  try {
    const { refreshToken, userId } = yield select(getAuthState);

    const response = yield call(API, {
      method: methods.POST,
      path: `users/${userId}/token`,
      token: null,
      body: {
        token: refreshToken,
      },
    });

    const { accessToken } = response;
    const newRefreshToken = response.refreshToken;
    yield put(actions.refreshUserTokenSuccess(accessToken, newRefreshToken));
  } catch (e) {
    // logout users if token refresh failed
    if (e.type === errorTypes.credentialsInvalid) {
      yield put(actions.logout());
      InterfaceUtils.emitToast('info', 'Your session has expired.');
      return;
    }
    yield call(handleGenericError, action, e);
  }
}

import 'regenerator-runtime/runtime';
import { call, select } from 'redux-saga/effects';

import { getSlicerState, handleGenericError } from '../common';

import { SlicerUtils, TreeUtils } from '../../utils';
import { setRenderFlag } from './animationFrame';

export default function* removeModelHighlights(action) {
  try {
    const { models, transitionTower } = yield select(getSlicerState);
    const meshes = [];
    const flattened = TreeUtils.flattenDeep(models);

    // put back the cursor to default
    document.body.style.cursor = 'auto';

    // include model meshes
    flattened.forEach((model) => {
      if (!model.active) meshes.push(model.mesh);
    });

    // include tower mesh if any
    if (transitionTower && !transitionTower.active)
      meshes.push(transitionTower.mesh);

    SlicerUtils.removeMeshHighlights(meshes);
    setRenderFlag();
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

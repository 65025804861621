import React, { Component } from 'react';

import { Container } from './iotConnectionBar.styles';

import { Body1 } from '../../../typography/typography';

class IotConnectionBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // state is used so that if we're not connected from the start,
      // we don't show this UI (e.g. on page load) -- basically we
      // only want to show this UI if we've actually been disconnected
      connected: true,
    };
  }

  componentDidUpdate(prevProps) {
    const { connected } = this.props;
    if (prevProps.connected !== this.props.connected) {
      this.setState({ connected });
    }
  }

  render() {
    return (
      <Container connected={this.state.connected}>
        <Body1 black noSpacing>
          Reconnecting…
        </Body1>
      </Container>
    );
  }
}

export default IotConnectionBar;

// commonly used media queries (not exhaustive, add more as needed)
const mediaQueries = {
  maxWidth: 'max-width',
  maxHeight: 'max-height',
};

/**
 * determines if document matches the media query string at the moment the page was loaded
 * more details here: https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
 * @param mediaQueryString a valid media query string
 * @returns a boolean
 */
const matchMediaQuery = (mediaQueryString) =>
  window.matchMedia(mediaQueryString).matches;

/**
 * determines if document matches the given max width breakpoint at the moment the page was loaded
 * @param mediaQueryString a valid breakpoint string
 * @returns a boolean
 */
const matchMaxWidth = (breakpoint) =>
  matchMediaQuery(`(${mediaQueries.maxWidth}: ${breakpoint})`);

/**
 * determines if document matches the given max height breakpoint at the moment the page was loaded
 * @param mediaQueryString a valid breakpoint string
 * @returns a boolean
 */
const matchMaxHeight = (breakpoint) =>
  matchMediaQuery(`(${mediaQueries.maxHeight}: ${breakpoint})`);

/**
 * determines if user's OS theme preference is dark or light
 * @returns a string of the preferred theme (dark = 'dark', light = 'light')
 */
const getUserOSThemePreference = () => {
  if (window.matchMedia) {
    if (matchMediaQuery('(prefers-color-scheme: dark)')) {
      return 'dark';
    }
    return 'light';
  }
  return 'light';
};

export default {
  matchMaxWidth,
  matchMaxHeight,
  getUserOSThemePreference,
};

import styled from 'styled-components';

import {
  defaultModalButtonStyles,
  defaultModalInputStyles,
} from '../../../shared/modal/modal.styles';

import { Breakpoints } from '../../../themes';

export const GroupLabel = styled.div``;

export const ObjectBrowserWrapper = styled.div`
  position: absolute;
  z-index: 1;
  width: 15rem;
`;

export const ModalButtonWrapper = styled.div`
  ${defaultModalButtonStyles}
  margin-left: 0.5rem;
`;

export const ModelInputWrapper = styled.div`
  ${defaultModalInputStyles}
`;

export const ActionButtonContainer = styled.div`
  padding: 0.75rem;
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-column-gap: 0.5rem;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
`;

export const ModelsList = styled.div`
  flex-grow: 1;
  height: auto;
`;

export const ObjectsPlaceholderLabelWrapper = styled.div`
  text-align: center;
  padding: 4rem 1rem;
  p {
    white-space: normal;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  max-height: calc(100vh - 29.25rem);
  overflow: auto;
  @media (max-height: ${Breakpoints.smallHeight}) {
    min-height: 3rem;
  }
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    max-height: calc(100vh - 15.75rem);
  }
`;

import React, { Component } from 'react';

import { ButtonWrapper, InputWrapper } from './renameModal.styles';

import ModalHeader from '../modal/modalHeader.jsx';
import ModalFooter from '../modal/modalFooter.jsx';

import Button from '../button/button.jsx';
import Input from '../input/input.jsx';
import Modal from '../modal/modal.jsx';

import { Subtitle1OneLine } from '../typography/typography';

class RenameModal extends Component {
  static defaultProps = {
    title: '',
    initialValue: '',
    validator: null,
    onCancel: () => {},
    onSave: () => {},
    onMarginClick: () => {},
  };

  constructor(props) {
    super(props);
    const { initialValue } = props;
    this.state = {
      focusField: false,
      inputValue: initialValue,
      inputError: false,
    };
  }

  handleMarginClick(e) {
    this.props.onMarginClick(e);
  }

  focusField() {
    this.setState({ focusField: true }, () => {
      this.setState({ focusField: false });
    });
  }

  onInputChange(value, error = false) {
    this.setState({
      inputValue: value,
      inputError: error,
    });
  }

  onInputChangeSuccess(value) {
    this.onInputChange(value, false);
  }

  onInputChangeFailure(value) {
    this.onInputChange(value, true);
  }

  onCancel(e) {
    e.stopPropagation();
    this.props.onCancel();
  }

  onSave(e) {
    e.stopPropagation();

    if (this.state.inputError) {
      this.focusField();
    } else {
      this.props.onSave(this.state.inputValue);
    }
  }

  render() {
    const { title, validator } = this.props;
    const { inputValue, inputError } = this.state;

    return (
      <Modal
        width={'19.3125rem'}
        onMarginClick={(e) => this.handleMarginClick(e)}>
        <ModalHeader>
          <Subtitle1OneLine>{title}</Subtitle1OneLine>
        </ModalHeader>
        <InputWrapper>
          <Input
            type='text'
            label='Name'
            autoFocus
            forceFocus={this.state.focusField}
            value={inputValue}
            isInvalid={inputError}
            validator={validator}
            onChangeSuccess={(value) => this.onInputChangeSuccess(value)}
            onChangeFailure={(value) => this.onInputChangeFailure(value)}
          />
        </InputWrapper>
        <ModalFooter>
          <ButtonWrapper>
            <Button title='Cancel' onClick={(e) => this.onCancel(e)}>
              Cancel
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              primary
              disabled={inputError}
              title='Save'
              onClickKeyTrigger='Enter'
              onClick={(e) => this.onSave(e)}>
              Save
            </Button>
          </ButtonWrapper>
        </ModalFooter>
      </Modal>
    );
  }
}

export default RenameModal;

import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API from '../../canvas-api';
import { handleGenericError } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';
import { FileUtils } from '../../../utils';
import { fetchS3 } from '../../s3';

export default function* getSharedProjectThumbnail(action) {
  try {
    const { shareId } = action.payload;

    const apiResponse = yield call(API, {
      path: `projects/shared/${shareId}/thumbnail`,
      token: null,
    });
    const signedUrl = apiResponse.thumbnail;

    const response = yield call(fetchS3, signedUrl);
    const arrayBuffer = yield call([response, 'arrayBuffer']);
    const base64String = FileUtils.arrayBufferToBase64(arrayBuffer);

    const completeThumbnailString = `data:image/png;base64,${base64String}`;
    yield put(
      actions.getSharedProjectThumbnailSuccess(completeThumbnailString)
    );
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

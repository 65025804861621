import styled from 'styled-components';

function determineBorderColor(props) {
  let borderColor = null;
  if (props.selected) {
    borderColor = '#FFFFFF';
  } else if (props.isBright && props.theme.name !== 'dark') {
    borderColor = props.theme.colors.grey4;
  }
  return borderColor;
}
export const Swatch = styled.span`
  display: inline-block;
  margin: auto 0 auto 0.3em;
  width: 1em;
  height: 1em;
  box-sizing: border-box;
  border: ${(props) => {
    const borderColor = determineBorderColor(props);
    return borderColor ? `solid 0.5px ${borderColor}` : 'none';
  }};
  border-radius: 50%;
  background-color: ${(props) =>
    `rgb(${props.color[0]}, ${props.color[1]}, ${props.color[2]})`};
`;

export const MiniSwatchContainer = styled.span`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

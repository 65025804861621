import { connect } from 'react-redux';

import { actions as slicerActions } from '../../../../reducers/slicer/slicer';
import SpliceSettings from './spliceSettings.jsx';

const mapStateToProps = (state) => ({
  materials: state.slicer.materials,
  spliceSettings: state.slicer.spliceSettings,
  createSpliceSettingsPending: state.slicer.status.createSpliceSettingsPending,
  createSpliceSettingsSuccess: state.slicer.status.createSpliceSettingsSuccess,
  getSpliceSettingsPending: state.slicer.status.getSpliceSettingsPending,
  getSpliceSettingsSuccess: state.slicer.status.getSpliceSettingsSuccess,
  projects: state.slicer.projects,
  printers: state.printers.printers,
  devices: state.iot.devices,
});

const mapDispatchToProps = (dispatch) => ({
  getSpliceSettings: () => dispatch(slicerActions.getSpliceSettingsRequest()),
  createSpliceSettings: (spliceSettingsList) =>
    dispatch(slicerActions.createSpliceSettingsRequest(spliceSettingsList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpliceSettings);

import { fieldTypes, fieldSteps, slicers } from '../../../../../constants';
import tooltips from './tooltips.jsx';

const { EXTRUSION_WIDTH_STEP, SPEED_STEP } = fieldSteps;
const { KISSlicer, Slic3r } = slicers;

const Metadata = {
  perimeters: {
    label: 'Perimeters',
    tags: ['loops'],
    fields: [
      {
        name: 'perimeterCount',
        label: 'Number of perimeters',
        tags: ['loops'],
        tooltip: tooltips.perimeterCount,
        variants: [
          {
            type: fieldTypes.number,
            min: 0,
            step: 1,
          },
        ],
      },
      {
        name: 'perimeterOrder',
        label: 'Perimeter order',
        tags: ['loops'],
        tooltip: tooltips.perimeterOrder,
        variants: [
          {
            type: fieldTypes.boxSelection,
            horizontal: true,
            options: [
              { label: 'Inside-out', value: 2 },
              { label: 'Outside-in', value: 0 },
            ],
          },
        ],
      },
      {
        name: 'perimeterSpeed',
        label: 'Perimeter speed',
        tags: ['loops'],
        tooltip: tooltips.perimeterSpeed,
        variants: [
          {
            units: 'mm/s',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: SPEED_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            units: '%',
            optionLabel: '% of solid layer speed',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: 1,
            toBaseUnits: (value, style) =>
              Math.round((value * style.solidLayerSpeed) / 100),
            fromBaseUnits: (value, style) =>
              Math.round((value / style.solidLayerSpeed) * 100),
          },
          {
            type: fieldTypes.auto,
            toBaseUnits: (value, style) => style.solidLayerSpeed,
            fromBaseUnits: () => 'auto',
          },
        ],
      },
      {
        name: 'externalPerimeterSpeed',
        label: 'Outer perimeter speed',
        tags: ['loops'],
        tooltip: tooltips.externalPerimeterSpeed,
        variants: [
          {
            units: 'mm/s',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: SPEED_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            units: '%',
            optionLabel: '% of solid layer speed',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: 1,
            toBaseUnits: (value, style) =>
              Math.round((value * style.solidLayerSpeed) / 100),
            fromBaseUnits: (value, style) =>
              Math.round((value / style.solidLayerSpeed) * 100),
          },
          {
            type: fieldTypes.auto,
            toBaseUnits: (value, style) => style.solidLayerSpeed,
            fromBaseUnits: () => 'auto',
          },
        ],
      },
      {
        name: 'perimeterExtrusionWidth',
        label: 'Perimeter extrusion width',
        tags: ['loops'],
        tooltip: tooltips.perimeterExtrusionWidth,
        slicers: [Slic3r],
        disabled: (style, slicer) => slicer && slicer !== Slic3r,
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: EXTRUSION_WIDTH_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            units: '%',
            optionLabel: '% of extrusion width',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: 1,
            toBaseUnits: (value, style) =>
              Math.round((value * style.extrusionWidth) / 100),
            fromBaseUnits: (value, style) =>
              Math.round((value / style.extrusionWidth) * 100),
          },
          {
            type: fieldTypes.auto,
            toBaseUnits: (value, style) => style.extrusionWidth,
            fromBaseUnits: () => 'auto',
          },
        ],
      },
      {
        name: 'externalPerimeterExtrusionWidth',
        label: 'Outer perimeter extrusion width',
        tags: ['loops'],
        tooltip: tooltips.externalPerimeterExtrusionWidth,
        slicers: [Slic3r],
        disabled: (style, slicer) => slicer && slicer !== Slic3r,
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: EXTRUSION_WIDTH_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            units: '%',
            optionLabel: '% of extrusion width',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: 1,
            toBaseUnits: (value, style) =>
              Math.round((value * style.extrusionWidth) / 100),
            fromBaseUnits: (value, style) =>
              Math.round((value / style.extrusionWidth) * 100),
          },
          {
            type: fieldTypes.auto,
            toBaseUnits: (value, style) => style.extrusionWidth,
            fromBaseUnits: () => 'auto',
          },
        ],
      },
    ],
  },
  seams: {
    label: 'Perimeter seams',
    tags: ['loops'],
    fields: [
      {
        name: 'coastDistance',
        label: 'Coast distance',
        tags: ['wipe'],
        tooltip: tooltips.coastDistance,
        slicers: [KISSlicer],
        disabled: (style, slicer) => slicer && slicer !== KISSlicer,
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            step: EXTRUSION_WIDTH_STEP,
          },
        ],
      },
      {
        name: 'seamOnCorners',
        label: 'Place seams on corners when possible',
        tags: [],
        tooltip: tooltips.seamOnCorners,
        variants: [
          {
            type: fieldTypes.checkbox,
          },
        ],
      },
      {
        name: 'seamAngle',
        label: 'Seam angle',
        tags: [],
        disabled: (style) => style.seamOnCorners,
        tooltip: tooltips.seamAngle,
        variants: [
          {
            units: '°',
            type: fieldTypes.number,
            min: 0,
            max: 360,
            lte: false,
            step: 1,
          },
        ],
      },
      {
        name: 'seamJitter',
        label: 'Seam randomization spread',
        tags: [],
        slicers: [KISSlicer],
        disabled: (style, slicer) =>
          style.seamOnCorners || (slicer && slicer !== KISSlicer),
        tooltip: tooltips.seamJitter,
        variants: [
          {
            units: '°',
            type: fieldTypes.number,
            min: 0,
            max: 360,
            step: 1,
          },
        ],
      },
    ],
  },
};

export default Metadata;

import { connect } from 'react-redux';

import { actions } from '../../../reducers/slicer/slicer';
import ConfigureMaterial from './configureMaterial.jsx';

const mapStateToProps = (state) => ({
  materials: state.slicer.materials,
  createMaterialPending: state.slicer.status.createMaterialPending,
  createMaterialSuccess: state.slicer.status.createMaterialSuccess,
  editMaterialPending: state.slicer.status.editMaterialPending,
  editMaterialSuccess: state.slicer.status.editMaterialSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  createMaterial: (material) =>
    dispatch(actions.createMaterialRequest(material)),
  editMaterial: (material) => dispatch(actions.editMaterialRequest(material)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureMaterial);

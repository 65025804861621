import React from 'react';
import { useTheme } from 'styled-components';

import Icon from '../icon/icon.jsx';
import {
  Body1,
  Body1OneLine,
  Caption,
  CaptionOneLine,
} from '../typography/typography';

import {
  IconContainer,
  StyledButton,
  TextsContainer,
} from './advancedButton.styles';

const AdvancedButton = ({
  icon,
  iconColor = null,
  label,
  customLabel = null, // JSX
  description = null,
  item = false,
  disabled = false,
  onClick = () => {},
}) => {
  const theme = useTheme();

  const getIconColor = () => {
    if (iconColor) return iconColor;
    if (disabled) return theme.colors.grey4;
    return theme.colors.grey6;
  };

  const renderIcon = () => (
    <IconContainer>
      <Icon src={icon} color={getIconColor()} />
    </IconContainer>
  );

  const renderContent = () => {
    if (item) {
      return (
        <TextsContainer noGap={!description}>
          {customLabel && customLabel}
          {!customLabel && (
            <Body1OneLine noSpacing disabled={disabled}>
              {label}
            </Body1OneLine>
          )}
          <CaptionOneLine grey noSpacing disabled={disabled}>
            {description}
          </CaptionOneLine>
        </TextsContainer>
      );
    }

    return (
      <TextsContainer noGap={!description}>
        {customLabel && customLabel}
        {!customLabel && (
          <Body1 noSpacing disabled={disabled}>
            {label}
          </Body1>
        )}
        <Caption grey noSpacing disabled={disabled}>
          {description}
        </Caption>
      </TextsContainer>
    );
  };

  return (
    <StyledButton item={item} disabled={disabled} onClick={onClick}>
      {renderIcon()}
      {renderContent()}
    </StyledButton>
  );
};

export default AdvancedButton;

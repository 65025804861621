export const initialDeviceState = {
  connected: false, // always assume device is offline at first
  stateId: null,
};

export const initialPendingRequestState = {
  scanPorts: false,
  connectPort: false,
  disconnectPort: false,

  getStorageDrives: false,
  getDrivePathFiles: false,
  editFilePath: false,

  sendCommand: false,
  dispatchPrintToDevice: false,

  setTargetTemperature: false,
  controlFan: false,
  controlMotor: false,
  feedFilament: false,
  homeExtruder: false,
  moveExtruder: false,

  startPrint: false,
  pausePrint: false,
  resumePrint: false,
  cancelPrint: false,

  initWebRtcConnection: false,
  establishWebRtcConnection: false,
  webRtcKeepAlive: false,
};

const initialState = {
  connected: false,
  devices: {},
  deviceStates: {},
  pendingRequests: {},
  webRtcConnectionOffer: null,
  commandHistory: [],
  status: {
    initConnectionPending: false,
    initConnectionSuccess: false,
    getDevicesPending: false,
    getDevicesSuccess: false,
    activateDevicePending: false,
    activateDeviceSuccess: false,
    unlinkDevicesPending: false,
    unlinkDevicesSuccess: false,
    checkDeviceHeartbeatPending: false,
    checkDeviceHeartbeatSuccess: false,
    getDeviceStatePending: false,
    getDeviceStateSuccess: false,
    updateDeviceStatePending: false,
    updateDeviceStateSuccess: false,
    updateDevicePending: false,
    updateDeviceSuccess: false,
    connectPortPending: false,
    connectPortSuccess: false,
    disconnectPortPending: false,
    disconnectPortSuccess: false,
    scanPortsPending: false,
    scanPortsSuccess: false,
    pausePrintPending: false,
    pausePrintSuccess: false,
    resumePrintPending: false,
    resumePrintSuccess: false,
    cancelPrintPending: false,
    cancelPrintSuccess: false,
    startPrintSuccess: false,
    startPrintPending: false,
    getStorageDrivesPending: false,
    getStorageDrivesSuccess: false,
    getDrivePathFilesPending: false,
    getDrivePathFilesSuccess: false,
    editDeviceFilePathPending: false,
    editDeviceFilePathSuccess: false,
    moveExtruderPending: false,
    moveExtruderSuccess: false,
    homeExtruderPending: false,
    homeExtruderSuccess: false,
    feedFilamentPending: false,
    feedFilamentSuccess: false,
    controlFanPending: false,
    controlFanSuccess: false,
    controlMotorPending: false,
    controlMotorSuccess: false,
    sendCommandPending: false,
    sendCommandSuccess: false,
    setTargetTemperaturePending: false,
    setTargetTemperatureSuccess: false,
    initWebRtcConnectionPending: false,
    initWebRtcConnectionSuccess: false,
    establishWebRtcConnectionPending: false,
    establishWebRtcConnectionSuccess: false,
    webRtcKeepAlivePending: false,
    webRtcKeepAliveSuccess: false,
  },
};

export default initialState;

import styled, { css } from 'styled-components';
import { Breakpoints } from '../../../themes';
import { SlicerUtils } from '../../../utils';

export const ExampleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  border: solid 1px ${(props) => props.theme.colors.grey4};
  background-color: ${(props) => props.theme.colors.grey2};
  border-radius: 0.3em;
  overflow: hidden;
  @media (min-width: ${Breakpoints.smallWidth}) {
    flex-direction: row;
  }
`;

export const ExampleColumn = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  border-top: solid 1px ${(props) => props.theme.colors.grey4};
  @media (min-width: ${Breakpoints.smallWidth}) {
    border-top: none;
    border-left: solid 1px ${(props) => props.theme.colors.grey4};
  }
`;

export const ExampleHeader = styled.div`
  font-size: 0.7em;
  color: ${(props) => props.theme.colors.grey5};
  font-weight: ${(props) => props.theme.fonts.weightRegular};
  font-family: ${(props) => props.theme.fonts.familyBody};
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.3em 0.5em;
  pointer-events: none;
  user-select: none;
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.fonts.weightBold};
  background-color: ${(props) => props.theme.colors.grey3};
  border-bottom: solid 1px ${(props) => props.theme.colors.grey4};
  border-right: solid 1px ${(props) => props.theme.colors.grey4};
  border-bottom-right-radius: 0.3em;
`;

export const StyledPre = styled.pre`
  white-space: pre-wrap;
  flex-basis: 0;
  flex-grow: 1;
  font-size: 1.1em;
  position: relative;
  margin: 0;
  padding: 0;
`;

const errorOutputStyles = css`
  color: ${(props) => (props.error ? props.theme.colors.redDark : null)};
  font-weight: ${(props) =>
    props.error
      ? props.theme.fonts.weightBold
      : props.theme.fonts.weightRegular};
`;

export const StyledCode = styled.code`
  display: block;
  margin: 0;
  padding: 2.5em 1em 1em;
  line-height: 1.4;
  ${errorOutputStyles};
`;

export const StyledOutputCode = styled.code`
  display: block;
  margin: 0;
  padding: 1em;
  line-height: 1.4;
  ${errorOutputStyles};
`;

export const StyledSamp = styled.samp`
  white-space: pre-wrap;
  flex-basis: 0;
  flex-grow: 1;
  font-size: 1.1em;
  position: relative;
  display: block;
  padding: 2.5em 1em 1em;
  line-height: 1.4;
  ${errorOutputStyles};
`;

export const Locals = styled.ul`
  flex-basis: 0;
  flex-grow: 1;
  font-size: 1.1em;
  font-family: ${(props) => props.theme.fonts.familyMono};
  list-style: none;
  margin: 0;
  padding: 2.5em 1em 1em;
  line-height: 1.4;
  position: relative;
  ${(props) =>
    props.border && `border-top: solid 1px ${props.theme.colors.grey4}`};
`;

export const Local = styled.li`
  line-height: 1.4 !important;
  margin-bottom: 0 !important;
`;

export const InlineCode = styled.code`
  font-size: 1.15em;
  font-family: ${(props) => props.theme.fonts.familyMono};
  padding: 0.1em 0.2em;
  border-radius: 0.2em;
  background-color: rgba(
    ${(props) =>
      SlicerUtils.hexStringToRGBA(props.theme.colors.yellowDefault, 0.3).join(
        ', '
      )}
  );
`;

export const BlockCodePre = styled.pre`
  font-size: 1.15em;
  margin: 2em 0;
  padding: 0;
`;

export const BlockCodeCode = styled.code`
  font-family: ${(props) => props.theme.fonts.familyMono};
  padding: 0.5em 0.6em;
  border-radius: 0.2em;
  background-color: rgba(
    ${(props) =>
      SlicerUtils.hexStringToRGBA(props.theme.colors.yellowDefault, 0.3).join(
        ', '
      )}
  );
`;

export const FunctionTable = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  text-align: left;
  margin-bottom: 1em;
`;

const functionCellStyles = css`
  padding: 0.4em 0.6em;
  border: solid 1px ${(props) => props.theme.colors.grey4};
  vertical-align: top;
`;

export const FunctionTH = styled.th`
  ${functionCellStyles};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`;

export const FunctionTD = styled.td`
  ${functionCellStyles};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`;

import CodeMirror from 'codemirror';

export const vars = [
  '<X>',
  '<Y>',
  '<Z>',
  '<NEXTX>',
  '<NEXTY>',
  '<E>',
  '<DESTRING>',
  '<TEMP>',
  '<BED>',
  '<LAYER>',
  '<TELAPSED>',
  '<TREMAIN>',
  '<TTOTAL>',
];

export const varDefinitions = [
  'Current X position',
  'Current Y position',
  'Current Z position',
  'Next X position',
  'Next Y position',
  'Current extruder position',
  'Retract length',
  'Extruder temperature',
  'Print bed temperature',
  'Current layer number',
  'Time elapsed',
  'Time remaining',
  'Total time',
];

/* eslint-disable no-param-reassign */
const gcodeMode = (/* config */) => {
  const mode = {};

  mode.lineComment = ';';

  // stateful modes must provide an initial state
  mode.startState = () => ({
    command: null,
  });

  mode.copyState = (state) => ({ ...state });

  const tokenComment = (stream, state) => {
    stream.skipToEnd();
    state.command = null;
    return 'comment';
  };

  const tokenVariable = (stream, state) => {
    while (!stream.eol()) {
      const char = stream.next();
      if (char === '>') {
        if (stream.eol()) {
          state.command = null;
        }
        const varName = stream.current();
        if (!vars.includes(varName)) {
          return 'error';
        }
        return 'variable';
      }
      if (char === ';' || char === ' ') {
        stream.backUp(1);
        break;
      }
    }
    state.command = null;
    return 'error';
  };

  const tokenCommand = (stream, state) => {
    const ateDigits = stream.eatWhile(/[0-9]/); // consume numeric part of command
    const command = stream.current(); // beginning of token to current position
    const commandLetter = command[0].toUpperCase();
    const validLetters = 'GMT';
    if (!ateDigits || !validLetters.includes(commandLetter)) {
      return 'error';
    }
    if (stream.eol()) {
      state.command = null;
    } else {
      state.command = command;
    }
    return 'command';
  };

  const tokenParam = (stream, state) => {
    stream.eatWhile(/[^\s;<]/);
    // check for duplicate G-code parameters
    const param = stream.current();
    const hasVarSquare = param.includes('[') || param.includes(']');
    const hasVarCurly = param.includes('{') || param.includes('}');
    if (hasVarSquare || hasVarCurly) {
      if (stream.eol()) {
        state.command = null;
      }
      return 'error';
    }
    if (stream.eol()) {
      state.command = null;
    }
    return 'param';
  };

  const tokenM117 = (stream, state) => {
    stream.eatWhile(/[^;]/); // read until a comment or EOL
    if (stream.eol()) {
      state.command = null;
    }
    return 'message';
  };

  // read one token from the stream,
  // optionally mutate our state,
  // and return a style string (or `null` for tokens that do not have to be styled)
  mode.token = (stream, state) => {
    // ignore leading whitespace
    if (stream.eatSpace()) {
      if (stream.eol()) {
        state.command = null;
      }
      return null;
    }
    if (stream.eol()) {
      state.command = null;
      return null;
    }
    const char = stream.next();
    if (char === ';') {
      // comment until EOL
      return tokenComment(stream, state);
    }
    if (char === '<') {
      // variable until >
      return tokenVariable(stream, state);
    }
    if (char === '*') {
      return 'error';
    }
    if (state.command) {
      if (state.command === 'M117') {
        stream.eatSpace();
        return tokenM117(stream, state);
      }
      return tokenParam(stream, state);
    }
    return tokenCommand(stream, state);
  };

  return mode;
};
/* eslint-enable no-param-reassign */

const GCodeMode = {
  name: 'gcode',
  fn: gcodeMode,
};

export default GCodeMode;

export const showHint = (editor /* , event */) => {
  CodeMirror.showHint(
    editor,
    () => {
      const cursor = editor.getCursor();
      const token = editor.getTokenAt(cursor);
      const { start } = token;
      const end = cursor.ch;
      const { line } = cursor;
      const currentWord = token.string;
      let matches = [];
      if (
        currentWord.length > 0 &&
        currentWord[0] === '<' &&
        currentWord.slice(-1) !== '>'
      ) {
        matches = vars.filter((varName) => varName.includes(currentWord));
      }
      return {
        list: matches,
        from: CodeMirror.Pos(line, start),
        to: CodeMirror.Pos(line, end),
      };
    },
    {
      completeSingle: false,
    }
  );
};

import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import { handleGenericError } from '../common';
import { makeRequest } from './horizon';
import { actions } from '../../reducers/iot/iot';

export default function* homeExtruder(action) {
  const { device, axes } = action.payload;

  try {
    yield call(
      makeRequest,
      device.id,
      'printer/home',
      {
        method: 'post',
        query: {
          axes,
        },
      },
      'homeExtruder'
    );
    yield put(actions.homeExtruderSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import 'regenerator-runtime/runtime';
import { takeLatest, all } from 'redux-saga/effects';

import { types } from '../../reducers/three/three';

// import sagas
import initScene from './initScene';
import destroyScene from './destroyScene';

import updatePrintBed from './updatePrintBed';
import showPrintBed from './showPrintBed';
import hidePrintBed from './hidePrintBed';

import resetCamera from './resetCamera';
import tweenCamera from './tweenCamera';
import replaceCamera from './replaceCamera';

import addModelsToScene from './addModelsToScene';
import removeModelsFromScene from './removeModelsFromScene';
import addModelToScene from './addModelToScene';
import removeModelFromScene from './removeModelFromScene';
import highlightModels from './highlightModels';
import removeModelHighlights from './removeModelHighlights';
import addTowerToScene from './addTowerToScene';
import removeTowerFromScene from './removeTowerFromScene';

import attachGizmo from './attachGizmo';
import updateGizmo from './updateGizmo';
import detachGizmo from './detachGizmo';

import addToolpath from './addToolpath';
import updateToolpath from './updateToolpath';
import removeToolpath from './removeToolpath';

import addBrush from './addBrush';
import removeBrush from './removeBrush';
import addBoundaries from './addBoundaries';
import removeBoundaries from './removeBoundaries';

export default function* threeSaga() {
  yield all([
    takeLatest(types.INIT_SCENE_REQUEST, initScene),
    takeLatest(types.DESTROY_SCENE_REQUEST, destroyScene),

    takeLatest(types.UPDATE_PRINT_BED_REQUEST, updatePrintBed),
    takeLatest(types.SHOW_PRINT_BED, showPrintBed),
    takeLatest(types.HIDE_PRINT_BED, hidePrintBed),

    takeLatest(types.RESET_CAMERA, resetCamera),
    takeLatest(types.TWEEN_CAMERA, tweenCamera),
    takeLatest(types.REPLACE_CAMERA_REQUEST, replaceCamera),

    takeLatest(types.ADD_MODELS_TO_SCENE, addModelsToScene),
    takeLatest(types.REMOVE_MODELS_FROM_SCENE, removeModelsFromScene),
    takeLatest(types.ADD_MODEL_TO_SCENE, addModelToScene),
    takeLatest(types.REMOVE_MODEL_FROM_SCENE, removeModelFromScene),
    takeLatest(types.HIGHLIGHT_MODELS, highlightModels),
    takeLatest(types.REMOVE_MODEL_HIGHLIGHTS, removeModelHighlights),
    takeLatest(types.ADD_TOWER_TO_SCENE, addTowerToScene),
    takeLatest(types.REMOVE_TOWER_FROM_SCENE, removeTowerFromScene),

    takeLatest(types.ATTACH_GIZMO_REQUEST, attachGizmo),
    takeLatest(types.UPDATE_GIZMO_REQUEST, updateGizmo),
    takeLatest(types.DETACH_GIZMO_REQUEST, detachGizmo),

    takeLatest(types.ADD_TOOLPATH, addToolpath),
    takeLatest(types.UPDATE_TOOLPATH, updateToolpath),
    takeLatest(types.REMOVE_TOOLPATH, removeToolpath),

    takeLatest(types.ADD_BRUSH, addBrush),
    takeLatest(types.REMOVE_BRUSH, removeBrush),
    takeLatest(types.ADD_BOUNDARIES_REQUEST, addBoundaries),
    takeLatest(types.REMOVE_BOUNDARIES_REQUEST, removeBoundaries),
  ]);
}

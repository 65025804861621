import styled, { css } from 'styled-components';
import { Breakpoints } from '../../../../themes';

export const Container = styled.div`
  padding: 0 1.25rem;
  display: flex;
`;

export const PrintSummary = styled.div`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 0.25rem;
  overflow: hidden;
  display: grid;
  justify-content: center;
  padding: 1.25rem;

  @media (max-width: ${Breakpoints.smallWidth}) {
    padding: 1.25rem 0.5rem;
  }
`;

export const ContentWrapper = styled.div`
  width: 20rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    width: 100%;
  }
`;

export const PrintName = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  justify-self: center;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;
`;

export const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  text-align: center;
`;

export const PrintProgress = styled.div`
  position: relative;
  grid-column: 1 / -1;
  grid-row: 2 / 3;
  width: 100%;
  justify-self: center;
  border-radius: 0.25rem;
  margin: 1rem 0 0.5rem 0;
`;

export const PlaceholderTextWrapper = styled.div`
  text-align: center;
`;

export const PlaceholderIconWrapper = styled.div`
  margin-bottom: 0.2rem;
`;

const timeBarStyles = css`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.5rem;
  background: ${(props) => {
    if (props.isPaused || props.isCancelled) return props.theme.colors.grey4;
    if (props.isFailed) return props.theme.colors.redDefault;
    return props.theme.colors.greenDefault;
  }};
  border-radius: 0.1rem;
`;

export const TimeTotalBar = styled.div`
  ${timeBarStyles};
  background: ${(props) => props.theme.colors.grey3};
`;

export const TimeElapsedBar = styled.div.attrs((props) => ({
  style: { width: `${props.w}%` },
}))`
  ${timeBarStyles};
`;

export const PrintControlButtons = styled.div`
  grid-column: 1 / -1;
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) => (props.single ? '1fr' : '1fr 1fr')};
  grid-column-gap: 0.5rem;
  margin-top: 1rem;

  @media (max-width: ${Breakpoints.smallWidth}) {
    grid-column-gap: 0.5rem;
  }
`;

import styled from 'styled-components';
import { Breakpoints, Shadows } from '../../../themes';

export const StyledHeaderBar = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 995;
  background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  ${Shadows.d1};
`;

export const FilterBarContainer = styled.div`
  margin: 0 1rem;
  width: 21rem;

  @media (max-width: ${Breakpoints.largeWidth}) {
    ${(props) => props.pageComponent && 'flex-grow: 1;'}
    ${(props) => !props.pageComponent && 'width: 13rem'}
  }

  @media (max-width: ${Breakpoints.smallWidth}) {
    flex-grow: 1;
  }
`;

export const LeftBar = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;

  @media (max-width: ${Breakpoints.largeWidth}) {
    ${(props) => props.pageComponent && 'width: 100%;'}
  }
  @media (max-width: ${Breakpoints.smallWidth}) {
    width: 100%;
  }
`;

export const RightBar = styled.div`
  ${(props) => !props.pageComponent && 'width: 33rem;'}
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: ${Breakpoints.largeWidth}) {
    ${(props) => !props.pageComponent && 'width: auto;'}
  }
`;

export const MiddleBar = styled.div`
  min-width: 0;
  align-items: center;

  @media (max-width: ${Breakpoints.smallWidth}) {
    display: none;
  }
`;

import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import { handleGenericError } from '../common';
import { actions } from '../../reducers/iot/iot';
import { makeRequest } from './horizon';
import {
  CLIENT_INIT_FAILURE_MESSAGE,
  DEVICE_TIMEOUT_MESSAGE,
} from '../../utils/iot-worker/constants';

export default function* getDeviceState(action) {
  const { deviceId } = action.payload;
  try {
    const response = yield call(makeRequest, deviceId, 'state', {
      method: 'get',
    });

    const { state } = response.body;
    const stateId = response.body.id || null;
    yield put(actions.getDeviceStateSuccess(deviceId, stateId, state));
  } catch (err) {
    const ignoredErrors = [DEVICE_TIMEOUT_MESSAGE, CLIENT_INIT_FAILURE_MESSAGE];
    if (err && ignoredErrors.includes(err.message)) {
      // device is not online -- don't display an error toast
      yield put(actions.getDeviceStateFailure(err));
    } else {
      yield call(handleGenericError, action, err);
    }
  }
}

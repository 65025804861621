import styled, { css } from 'styled-components';

import { Breakpoints } from '../../../../../../themes';

export const StampImageContainers = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, min-content);

  @media (max-width: ${Breakpoints.mediumWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: flex;
    flex-direction: column;
  }
`;

export const ProjectColorsContainer = styled.div`
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  display: grid;
`;

export const ProjectColorsPalette = styled.div`
  margin: 0;
  grid-column: 1 / -1;
  grid-row: span 1;
  display: grid;
  grid-template-columns: repeat(8, min-content);
  grid-gap: 0.25rem;

  @media (max-width: ${Breakpoints.mediumWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    grid-template-columns: repeat(4, min-content);
    grid-template-rows: repeat(2, 1fr);
  }
`;

export const ColorPalette = styled.div`
  margin: 2.2rem 0.4rem;
  grid-column: span 1;
  grid-row: 1 / -1;
  display: grid;
  grid-gap: 0.2rem;
  grid-template-columns: min-content;
  align-content: start;

  @media (max-width: ${Breakpoints.mediumWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: flex;
    grid-row: 2 / span 1;
    flex-wrap: wrap;
    margin: 0 0 0.5rem 0;
    padding-right: 0.5rem;
  }

  @media (max-height: ${Breakpoints.extraSmallHeight}) {
    margin: 0 0 0.5rem 0;
  }
`;

const colorSwatchStyles = css`
  width: 2rem;
  height: 2rem;
  border-radius: 0.25rem;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  background: ${(props) =>
    `rgba(${props.color[0]}, ${props.color[1]}, ${props.color[2]}, 255)`};
`;

export const ColorSwatch = styled.div`
  ${colorSwatchStyles};
`;

export const ProjectColorSwatch = styled.div`
  ${colorSwatchStyles};
  cursor: pointer;
`;

const transparentPattern = css`
  background-position: 0px 0px, 10px 10px;
  background-size: 20px 20px;
  background-image: linear-gradient(
      45deg,
      #eee 25%,
      transparent 25%,
      transparent 75%,
      #eee 75%,
      #eee 100%
    ),
    linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);
`;

export const ImageToggleButtonWrapper = styled.div`
  float: right;
  position: relative;
  margin-left: auto;
  margin-top: -3rem;
  display: none;

  @media (max-width: ${Breakpoints.mediumWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: flex;
  }

  @media (max-height: ${Breakpoints.extraSmallHeight}) {
    margin: 2rem auto 0 0;
  }
`;

export const ImageContainer = styled.div`
  grid-column: span 1;
  grid-row: span 1;
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: repeat(2, min-content);

  @media (max-width: ${Breakpoints.mediumWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: ${(props) => (props.show ? 'grid' : 'none')};
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, min-content);
  }
`;

export const ImageBackground = styled.div`
  grid-column: span 1;
  grid-row: 2 / -1;
  width: 20rem;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  border: 1px solid ${(props) => props.theme.colors.greenDefault};
  ${transparentPattern};

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: ${Breakpoints.mediumWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    width: 100%;
    grid-row: span 1 / -1;
  }

  @media (max-height: ${Breakpoints.smallHeight}) {
    height: 10rem;
  }

  @media (max-height: ${Breakpoints.extraSmallHeight}) {
    height: 7rem;
  }
`;

export const StampPickerModalContent = styled.div`
  text-align: left;

  @media (max-height: ${Breakpoints.extraSmallHeight}) {
    display: flex;
  }
`;

export const StampColorPickerDescriptionSection = styled.div`
  @media (max-height: ${Breakpoints.extraSmallHeight}) {
    margin-right: 1rem;
  }
`;

export const StampColorPickerImageSection = styled.div`
  @media (max-height: ${Breakpoints.extraSmallHeight}) {
    display: flex;
    flex-direction: column;
  }
`;

export const SubtitleWrapper = styled.div`
  margin-bottom: 1rem;
`;

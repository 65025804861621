import styled from 'styled-components';

export const UL = styled.ul`
  margin: 0.25rem;
  padding-left: 1.5rem;
  list-style-type: square;
  color: ${(props) => {
    if (props.grey) return props.theme.colors.textSecondary;
    return props.theme.colors.textPrimary;
  }};

  /*
  & > li {
    ...
  }
   */
`;

import types from './types';

const actions = {
  resetIoTStore: () => ({
    type: types.RESET_IOT_STORE,
    payload: {},
  }),
  initConnectionRequest: () => ({
    type: types.INIT_CONNECTION_REQUEST,
    payload: {},
  }),
  initConnectionSuccess: () => ({
    type: types.INIT_CONNECTION_SUCCESS,
    payload: {},
  }),
  initConnectionFailure: (error) => ({
    type: types.INIT_CONNECTION_FAILURE,
    payload: { error },
  }),
  closeConnection: () => ({
    type: types.CLOSE_CONNECTION,
    payload: {},
  }),
  setConnectedStatus: (connected) => ({
    type: types.SET_CONNECTED_STATUS,
    payload: { connected },
  }),
  receiveMessage: (topic, message) => ({
    type: types.RECEIVE_MESSAGE,
    payload: { topic, message },
  }),

  getDevicesRequest: () => ({
    type: types.GET_DEVICES_REQUEST,
    payload: {},
  }),
  getDevicesSuccess: (devices) => ({
    type: types.GET_DEVICES_SUCCESS,
    payload: { devices },
  }),
  getDevicesFailure: (error) => ({
    type: types.GET_DEVICES_FAILURE,
    payload: { error },
  }),
  activateDeviceRequest: (activationCode) => ({
    type: types.ACTIVATE_DEVICE_REQUEST,
    payload: { activationCode },
  }),
  activateDeviceSuccess: (device) => ({
    type: types.ACTIVATE_DEVICE_SUCCESS,
    payload: { device },
  }),
  activateDeviceFailure: (error) => ({
    type: types.ACTIVATE_DEVICE_FAILURE,
    payload: { error },
  }),
  unlinkDevicesRequest: (deviceIds) => ({
    type: types.UNLINK_DEVICES_REQUEST,
    payload: { deviceIds },
  }),
  unlinkDevicesSuccess: (deviceIds) => ({
    type: types.UNLINK_DEVICES_SUCCESS,
    payload: { deviceIds },
  }),
  unlinkDevicesFailure: (error) => ({
    type: types.UNLINK_DEVICES_FAILURE,
    payload: { error },
  }),
  setDeviceConnectedStatus: (deviceId, connected) => ({
    type: types.SET_DEVICE_CONNECTED_STATUS,
    payload: { deviceId, connected },
  }),
  markPendingDeviceRequest: (deviceId, flag) => ({
    type: types.MARK_PENDING_DEVICE_REQUEST,
    payload: { deviceId, flag },
  }),
  clearPendingDeviceRequest: (deviceId, flag) => ({
    type: types.CLEAR_PENDING_DEVICE_REQUEST,
    payload: { deviceId, flag },
  }),
  checkDeviceHeartbeatRequest: () => ({
    type: types.CHECK_DEVICE_HEARTBEAT_REQUEST,
    payload: {},
  }),
  checkDeviceHeartbeatSuccess: () => ({
    type: types.CHECK_DEVICE_HEARTBEAT_SUCCESS,
    payload: {},
  }),
  checkDeviceHeartbeatFailure: (error) => ({
    type: types.CHECK_DEVICE_HEARTBEAT_FAILURE,
    payload: { error },
  }),
  getDeviceStateRequest: (deviceId) => ({
    type: types.GET_DEVICE_STATE_REQUEST,
    payload: { deviceId },
  }),
  getDeviceStateSuccess: (deviceId, stateId, deviceState) => ({
    type: types.GET_DEVICE_STATE_SUCCESS,
    payload: { deviceId, stateId, deviceState },
  }),
  getDeviceStateFailure: (error) => ({
    type: types.GET_DEVICE_STATE_FAILURE,
    payload: { error },
  }),
  updateDeviceStateRequest: (deviceId, stateId, delta) => ({
    type: types.UPDATE_DEVICE_STATE_REQUEST,
    payload: { deviceId, stateId, delta },
  }),
  updateDeviceStateSuccess: (deviceId, stateId, deviceState) => ({
    type: types.UPDATE_DEVICE_STATE_SUCCESS,
    payload: { deviceId, stateId, deviceState },
  }),
  updateDeviceStateFailure: (error) => ({
    type: types.UPDATE_DEVICE_STATE_FAILURE,
    payload: { error },
  }),
  updateDeviceRequest: (deviceId, deviceData) => ({
    type: types.UPDATE_DEVICE_REQUEST,
    payload: { deviceId, deviceData },
  }),
  updateDeviceSuccess: (device) => ({
    type: types.UPDATE_DEVICE_SUCCESS,
    payload: { device },
  }),
  updateDeviceFailure: (error) => ({
    type: types.UPDATE_DEVICE_FAILURE,
    payload: { error },
  }),
  setDeviceStorageDrives: (deviceId, storageDrives) => ({
    type: types.SET_DEVICE_STORAGE_DRIVES,
    payload: { deviceId, storageDrives },
  }),
  setDeviceFilesContext: (deviceId, filesContext) => ({
    type: types.SET_DEVICE_FILES_CONTEXT,
    payload: { deviceId, filesContext },
  }),
  clearDeviceStorageDrives: (deviceId) => ({
    type: types.CLEAR_DEVICE_STORAGE_DRIVES,
    payload: { deviceId },
  }),
  clearDeviceFilesContext: (deviceId) => ({
    type: types.CLEAR_DEVICE_FILES_CONTEXT,
    payload: { deviceId },
  }),
  setDeviceAvailablePorts: (deviceId, availablePorts) => ({
    type: types.SET_DEVICE_AVAILABLE_PORTS,
    payload: { deviceId, availablePorts },
  }),
  connectPortRequest: (device, connectTo, settingsData) => ({
    type: types.CONNECT_PORT_REQUEST,
    payload: { device, connectTo, settingsData },
  }),
  connectPortSuccess: () => ({
    type: types.CONNECT_PORT_SUCCESS,
    payload: {},
  }),
  connectPortFailure: (error) => ({
    type: types.CONNECT_PORT_FAILURE,
    payload: { error },
  }),
  disconnectPortRequest: (device, disconnectFrom) => ({
    type: types.DISCONNECT_PORT_REQUEST,
    payload: { device, disconnectFrom },
  }),
  disconnectPortSuccess: () => ({
    type: types.DISCONNECT_PORT_SUCCESS,
    payload: {},
  }),
  disconnectPortFailure: (error) => ({
    type: types.DISCONNECT_PORT_FAILURE,
    payload: { error },
  }),
  scanPortsRequest: (device) => ({
    type: types.SCAN_PORTS_REQUEST,
    payload: { device },
  }),
  scanPortsSuccess: () => ({
    type: types.SCAN_PORTS_SUCCESS,
    payload: {},
  }),
  scanPortsFailure: (error) => ({
    type: types.SCAN_PORTS_FAILURE,
    payload: { error },
  }),
  startPrintRequest: (device, filePath) => ({
    type: types.START_PRINT_REQUEST,
    payload: { device, filePath },
  }),
  startPrintSuccess: (device) => ({
    type: types.START_PRINT_SUCCESS,
    payload: { device },
  }),
  startPrintFailure: (error) => ({
    type: types.START_PRINT_FAILURE,
    payload: { error },
  }),
  pausePrintRequest: (device) => ({
    type: types.PAUSE_PRINT_REQUEST,
    payload: { device },
  }),
  pausePrintSuccess: (device) => ({
    type: types.PAUSE_PRINT_SUCCESS,
    payload: { device },
  }),
  pausePrintFailure: (error) => ({
    type: types.PAUSE_PRINT_FAILURE,
    payload: { error },
  }),
  resumePrintRequest: (device) => ({
    type: types.RESUME_PRINT_REQUEST,
    payload: { device },
  }),
  resumePrintSuccess: (device) => ({
    type: types.RESUME_PRINT_SUCCESS,
    payload: { device },
  }),
  resumePrintFailure: (error) => ({
    type: types.RESUME_PRINT_FAILURE,
    payload: { error },
  }),
  cancelPrintRequest: (device) => ({
    type: types.CANCEL_PRINT_REQUEST,
    payload: { device },
  }),
  cancelPrintSuccess: (device) => ({
    type: types.CANCEL_PRINT_SUCCESS,
    payload: { device },
  }),
  cancelPrintFailure: (error) => ({
    type: types.CANCEL_PRINT_FAILURE,
    payload: { error },
  }),
  getStorageDrivesRequest: (device) => ({
    type: types.GET_STORAGE_DRIVES_REQUEST,
    payload: { device },
  }),
  getStorageDrivesSuccess: () => ({
    type: types.GET_STORAGE_DRIVES_SUCCESS,
    payload: {},
  }),
  getStorageDrivesFailure: (error) => ({
    type: types.GET_STORAGE_DRIVES_FAILURE,
    payload: { error },
  }),
  getDrivePathFilesRequest: (device, fullDrivePath) => ({
    type: types.GET_DRIVE_PATH_FILES_REQUEST,
    payload: { device, fullDrivePath },
  }),
  getDrivePathFilesSuccess: () => ({
    type: types.GET_DRIVE_PATH_FILES_SUCCESS,
    payload: {},
  }),
  getDrivePathFilesFailure: (error) => ({
    type: types.GET_DRIVE_PATH_FILES_FAILURE,
    payload: { error },
  }),
  editDeviceFilePathRequest: (device, filePathData) => ({
    type: types.EDIT_DEVICE_FILE_PATH_REQUEST,
    payload: { device, filePathData },
  }),
  editDeviceFilePathSuccess: () => ({
    type: types.EDIT_DEVICE_FILE_PATH_SUCCESS,
    payload: {},
  }),
  editDeviceFilePathFailure: (error) => ({
    type: types.EDIT_DEVICE_FILE_PATH_FAILURE,
    payload: { error },
  }),
  forgetDeviceFiles: (device) => ({
    type: types.FORGET_DEVICE_FILES,
    payload: { device },
  }),
  moveExtruderRequest: (device, movementData) => ({
    type: types.MOVE_EXTRUDER_REQUEST,
    payload: { device, movementData },
  }),
  moveExtruderSuccess: () => ({
    type: types.MOVE_EXTRUDER_SUCCESS,
    payload: {},
  }),
  moveExtruderFailure: (error) => ({
    type: types.MOVE_EXTRUDER_FAILURE,
    payload: { error },
  }),
  homeExtruderRequest: (device, axes) => ({
    type: types.HOME_EXTRUDER_REQUEST,
    payload: { device, axes },
  }),
  homeExtruderSuccess: () => ({
    type: types.HOME_EXTRUDER_SUCCESS,
    payload: {},
  }),
  homeExtruderFailure: (error) => ({
    type: types.HOME_EXTRUDER_FAILURE,
    payload: { error },
  }),
  feedFilamentRequest: (device, feedData) => ({
    type: types.FEED_FILAMENT_REQUEST,
    payload: { device, feedData },
  }),
  feedFilamentSuccess: () => ({
    type: types.FEED_FILAMENT_SUCCESS,
    payload: {},
  }),
  feedFilamentFailure: (error) => ({
    type: types.FEED_FILAMENT_FAILURE,
    payload: { error },
  }),
  controlFanRequest: (device, controlData) => ({
    type: types.CONTROL_FAN_REQUEST,
    payload: { device, controlData },
  }),
  controlFanSuccess: () => ({
    type: types.CONTROL_FAN_SUCCESS,
    payload: {},
  }),
  controlFanFailure: (error) => ({
    type: types.CONTROL_FAN_FAILURE,
    payload: { error },
  }),
  controlMotorRequest: (device, controlData) => ({
    type: types.CONTROL_MOTOR_REQUEST,
    payload: { device, controlData },
  }),
  controlMotorSuccess: () => ({
    type: types.CONTROL_MOTOR_SUCCESS,
    payload: {},
  }),
  controlMotorFailure: (error) => ({
    type: types.CONTROL_MOTOR_FAILURE,
    payload: { error },
  }),
  sendCommandRequest: (device, command) => ({
    type: types.SEND_COMMAND_REQUEST,
    payload: { device, command },
  }),
  sendCommandSuccess: (ackOrNack) => ({
    type: types.SEND_COMMAND_SUCCESS,
    payload: { ackOrNack },
  }),
  sendCommandFailure: (error) => ({
    type: types.SEND_COMMAND_FAILURE,
    payload: { error },
  }),
  clearCommandHistory: () => ({
    type: types.CLEAR_COMMAND_HISTORY,
    payload: {},
  }),
  setTargetTemperatureRequest: (device, temperatureData) => ({
    type: types.SET_TARGET_TEMPERATURE_REQUEST,
    payload: { device, temperatureData },
  }),
  setTargetTemperatureSuccess: () => ({
    type: types.SET_TARGET_TEMPERATURE_SUCCESS,
    payload: {},
  }),
  setTargetTemperatureFailure: (error) => ({
    type: types.SET_TARGET_TEMPERATURE_FAILURE,
    payload: { error },
  }),
  initWebRtcConnectionRequest: (deviceId) => ({
    type: types.INIT_WEBRTC_CONNECTION_REQUEST,
    payload: { deviceId },
  }),
  initWebRtcConnectionSuccess: (deviceId, connectionOffer) => ({
    type: types.INIT_WEBRTC_CONNECTION_SUCCESS,
    payload: { deviceId, connectionOffer },
  }),
  initWebRtcConnectionFailure: (error) => ({
    type: types.INIT_WEBRTC_CONNECTION_FAILURE,
    payload: { error },
  }),
  establishWebRtcConnectionRequest: (deviceId, connectionAnswer) => ({
    type: types.ESTABLISH_WEBRTC_CONNECTION_REQUEST,
    payload: { deviceId, connectionAnswer },
  }),
  establishWebRtcConnectionSuccess: (deviceId) => ({
    type: types.ESTABLISH_WEBRTC_CONNECTION_SUCCESS,
    payload: { deviceId },
  }),
  establishWebRtcConnectionFailure: (error) => ({
    type: types.ESTABLISH_WEBRTC_CONNECTION_FAILURE,
    payload: { error },
  }),
  webRtcKeepAliveRequest: (deviceId, sessionId) => ({
    type: types.WEBRTC_KEEP_ALIVE_REQUEST,
    payload: { deviceId, sessionId },
  }),
  webRtcKeepAliveSuccess: (deviceId) => ({
    type: types.WEBRTC_KEEP_ALIVE_SUCCESS,
    payload: { deviceId },
  }),
  webRtcKeepAliveFailure: (error) => ({
    type: types.WEBRTC_KEEP_ALIVE_FAILURE,
    payload: { error },
  }),
  removeWebRtcConnectionOffer: () => ({
    type: types.REMOVE_WEBRTC_CONNECTION_OFFER,
    payload: {},
  }),
};

export default actions;

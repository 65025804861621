import React from 'react';

import Modal from '../modal/modal.jsx';
import { H6OneLine } from '../typography/typography';

import {
  ButtonsContainer,
  HeaderWrapper,
  ModalContent,
  SidebarWrapper,
} from './settingsModal.styles';

const SettingsModal = ({
  width = '72rem',
  height = '45rem',

  headerButtons = null,
  headerTitle = null,
  sideBarContent = null,
}) => {
  const renderHeader = () => {
    return (
      <HeaderWrapper>
        <H6OneLine>{headerTitle}</H6OneLine>
        <ButtonsContainer>{headerButtons}</ButtonsContainer>
      </HeaderWrapper>
    );
  };

  const renderSideBarContent = () => {
    return <SidebarWrapper>{sideBarContent}</SidebarWrapper>;
  };

  return (
    <Modal noScroll noPadding width={width} height={height}>
      <ModalContent>
        {renderHeader()}
        {renderSideBarContent()}
      </ModalContent>
    </Modal>
  );
};

export default SettingsModal;

import styled from 'styled-components';

import { Breakpoints, Shadows } from '../../themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.systemBackgroundPrimary};
  background-image: url(${(props) => props.theme.images.homepagePattern});
  background-size: 20rem auto;

  @media (max-height: ${Breakpoints.smallHeight}) {
    overflow-y: auto;
    padding: 2rem 0;
    min-height: min-content;
  }
`;

export const Panels = styled.div`
  background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  width: 50rem;
  display: flex;
  border-radius: 0.25rem;
  ${Shadows.d1};

  @media (max-width: ${Breakpoints.mediumWidth}) {
    flex-direction: column-reverse;
    width: 90%;
  }

  @media (max-height: ${Breakpoints.smallHeight}) {
    min-height: min-content;
  }
`;

export const AuthPanel = styled.div`
  flex: 1;
`;

export const PhotoPanel = styled.div`
  flex: 1;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-image: ${(props) => (props.url ? `url('${props.url}')` : '')};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) => (props.url ? 'space-between' : 'center')};
  position: relative;

  @media (max-width: ${Breakpoints.mediumWidth}) {
    min-height: 3rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
`;

export const LogoWrapper = styled.a`
  display: block;
  align-self: flex-end;
  margin: 1rem;
  opacity: 0.8;
  transition: opacity 0.2s;
  &:hover {
    opacity: 1;
  }
`;

export const DiscoverCanvasButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  background: ${(props) => props.theme.colors.grey1};
  opacity: 0.85;
  align-self: flex-end;
  justify-self: flex-end;
  border-radius: 5rem;
  text-decoration: none;
  color: ${(props) => props.theme.colors.textPrimary};
  margin: 1rem;
  transition: opacity 0.2s;
  &:hover {
    opacity: 1;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 0.5rem;
`;

export const TabButtons = styled.div`
  display: flex;
  padding: 1rem;
`;

export const TabButtonWrapper = styled.div`
  margin-left: 0.5rem;
`;

export const Tab = styled.form`
  padding: 0 2rem 1rem 2rem;
  display: flex;
  flex-direction: column;
`;

export const TabTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
`;

export const LoginTabFooter = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainButtonWrapper = styled.div`
  margin: 1rem 0;
`;

export const LinkButtonWrapper = styled.div`
  color: ${(props) => props.theme.colors.grey5};
  align-self: flex-start;
`;

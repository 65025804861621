import 'regenerator-runtime/runtime';
import _ from 'lodash';
import { call, put, select, all } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

function* groupModelCall(model, groupName) {
  const { currentProject: projectId } = yield select(getSlicerState);
  return yield call(API, {
    method: methods.POST,
    path: `projects/${projectId}/models/${model.id}`,
    body: {
      path: `|${groupName}|${model.name}`,
    },
  });
}

export function* groupModelsCall(models, groupName) {
  return yield all(
    _.map(models, (model) => call(groupModelCall, model, groupName))
  );
}

export default function* groupModels(action) {
  try {
    const { currentProject: projectId } = yield select(getSlicerState);
    const { models, groupName } = action.payload;

    const responses = yield call(groupModelsCall, models, groupName);
    if (_.some(responses, (response) => response === null)) return;

    yield put(actions.groupModelsSuccess());
    yield put(actions.invalidateSlice(projectId));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

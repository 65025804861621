import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';

import {
  ConfigurationsSection,
  TextWrapper,
  FilesSectionWrapper,
  SectionsWrapper,
} from './viewDevice.styles';

import { Button, LoadingOverlay, Page, Subtitle1 } from '../../../shared';

import ControlsSection from './controls/controls.container';
import FilesSection from './files/files.container';
import PrintSection from './print/print.jsx';

import Routes from '../../../router/routes';
import { ButtonsContainer } from '../../../shared/managerView/managerView.styles';
import { DeviceUtils } from '../../../utils';
import { Icons } from '../../../themes';

import DeviceConnectionSettingsModal from './modals/deviceConnectionSettings.jsx';
import DeviceMetadataModal from './modals/deviceMetadataModal/deviceMetadataModal.jsx';

class ViewDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceLoaded: false,
      showConnectionSettingsModal: false,
      showDeviceMetadataModal: false,
    };
  }

  getDevice() {
    const { deviceId } = this.props.match.params;
    const device = this.props.devices[deviceId];
    if (!device) return null;
    return device;
  }

  handleDeviceLoaded(device) {
    this.setState({ deviceLoaded: true }, () => {
      this.props.updateNavStack([
        { text: 'My Devices', route: Routes.toManageDevices() },
        { text: device.name, route: Routes.toViewDevice(device.id) },
      ]);
    });
  }

  componentDidMount() {
    const device = this.getDevice();
    if (device) {
      this.handleDeviceLoaded(device);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.deviceId !== prevProps.match.params.deviceId ||
      !this.state.deviceLoaded
    ) {
      const device = this.getDevice();
      if (device) {
        this.handleDeviceLoaded(device);
      }
    }
  }

  areActionsDisabled() {
    const device = this.getDevice();
    return (
      !device ||
      !this.props.connected ||
      !this.props.deviceStates[device.id].connected
    );
  }

  renderPrintSection() {
    const device = this.getDevice();
    if (!device) return null;

    const currentDeviceState = this.props.deviceStates[device.id];
    const pendingRequests = this.props.pendingRequests[device.id];

    return (
      <PrintSection
        device={device}
        connected={this.props.connected}
        currentDeviceState={currentDeviceState}
        pendingRequests={pendingRequests}
        onPausePrint={() => this.props.pausePrint(device)}
        onResumePrint={() => this.props.resumePrint(device)}
        onCancelPrint={() => this.props.cancelPrint(device)}
      />
    );
  }

  renderControlsSection() {
    const { deviceStates } = this.props;

    const device = this.getDevice();
    if (!device) return null;

    const currentDeviceState = deviceStates[device.id];

    return (
      <ControlsSection
        device={device}
        currentDeviceState={currentDeviceState}
      />
    );
  }

  renderFilesSection() {
    const { deviceStates } = this.props;

    const device = this.getDevice();
    if (!device) return null;

    const currentDeviceState = deviceStates[device.id];

    return (
      <FilesSectionWrapper>
        <FilesSection device={device} currentDeviceState={currentDeviceState} />
      </FilesSectionWrapper>
    );
  }

  renderConfigurationSection() {
    if (!this.state.deviceLoaded) return null;
    return (
      <ConfigurationsSection>
        <TextWrapper>
          <Subtitle1>Status and controls</Subtitle1>
        </TextWrapper>
        {this.renderPrintSection()}
        {this.renderControlsSection()}
      </ConfigurationsSection>
    );
  }

  renderMenuButtons() {
    const device = this.getDevice();
    if (!device) return null;

    const currentDeviceState = this.props.deviceStates[device.id];
    const isDeviceOnline = DeviceUtils.isOnline(currentDeviceState);
    const isTitaniumOnline = this.props.connected;

    return (
      <ButtonsContainer>
        <Button
          primary
          icon={Icons.basic.info}
          onClick={() =>
            this.setState({
              showDeviceMetadataModal: true,
            })
          }>
          Device Info
        </Button>
        <Button
          secondary
          disabled={!isDeviceOnline || !isTitaniumOnline}
          onClick={() =>
            this.setState({
              showConnectionSettingsModal: true,
            })
          }>
          Connection Settings
        </Button>
      </ButtonsContainer>
    );
  }

  renderConnectionSettingsModal() {
    const { deviceStates, connected } = this.props;
    const device = this.getDevice();

    if (!device) return null;

    const pendingRequests = this.props.pendingRequests[device.id];
    const currentDeviceState = deviceStates[device.id];
    const isDeviceOnline = DeviceUtils.isOnline(currentDeviceState);
    const isTitaniumOnline = connected;

    if (!this.state.showConnectionSettingsModal) return null;

    if (!isDeviceOnline || !isTitaniumOnline) {
      return this.setState({ showConnectionSettingsModal: false });
    }

    const onClose = () => {
      this.setState({
        showConnectionSettingsModal: false,
      });
    };

    return (
      <DeviceConnectionSettingsModal
        device={device}
        currentDeviceState={currentDeviceState}
        pendingRequests={pendingRequests}
        onClose={onClose}
      />
    );
  }

  renderDeviceMetadataModal() {
    const { deviceStates, connected } = this.props;
    const device = this.getDevice();

    if (!device) return null;

    const currentDeviceState = deviceStates[device.id];
    const isDeviceOnline = DeviceUtils.isOnline(currentDeviceState);
    const isTitaniumOnline = connected;

    if (!this.state.showDeviceMetadataModal) return null;

    const onClose = () => {
      this.setState({
        showDeviceMetadataModal: false,
      });
    };
    return (
      <DeviceMetadataModal
        device={device}
        deviceStates={deviceStates}
        titaniumOnline={isTitaniumOnline}
        deviceOnline={isDeviceOnline}
        onClose={onClose}
      />
    );
  }

  renderLoadingUI() {
    if (this.state.deviceLoaded) return null;
    return <LoadingOverlay label='Loading device' />;
  }

  render() {
    return (
      <Page menuButtons={this.renderMenuButtons()} hideDivider>
        {this.renderConnectionSettingsModal()}
        {this.renderDeviceMetadataModal()}
        <SectionsWrapper>
          {this.renderLoadingUI()}
          {this.renderConfigurationSection()}
          {this.renderFilesSection()}
        </SectionsWrapper>
      </Page>
    );
  }
}

export default withRouter(withTheme(ViewDevice));

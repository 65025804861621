import styled, { css } from 'styled-components';
import { Breakpoints } from '../../themes';
import { Body1 } from '../typography/typography';

export const Container = styled.div.attrs((props) => {
  const optionCount = props.optionCount || 1;
  const { maxColumns } = props;
  let numRows = 1;
  let numColumns = optionCount;
  if (maxColumns < optionCount && maxColumns > 0) {
    numColumns = maxColumns;
    numRows = Math.ceil(optionCount / numColumns);
  }
  return {
    numRows,
    numColumns,
  };
})`
  position: relative;
  display: grid;
  grid-template-rows: repeat(${(props) => props.numRows || 1}, 1fr);
  grid-template-columns: repeat(${(props) => props.numColumns || 1}, 1fr);
  grid-gap: 0.375rem;
  padding: 0;
  box-sizing: border-box;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    ${(props) =>
      props.maxColumns &&
      css`
        grid-template-rows: repeat(${props.optionCount || 1}, 1fr);
        grid-template-columns: 1fr;
      `}
    ${(props) =>
      props.noStacking &&
      css`
        grid-template-columns: repeat(${props.maxColumns}, 1fr);
        grid-template-rows: 1fr;
      `}
    width: 100%;
    align-self: center;
  }
`;

export const Box = styled.div`
  margin: 0;
  padding: ${(props) => props.boxPadding};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0.25rem;
  min-height: 1.875rem;
  background-color: ${(props) => {
    if (props.disabled) {
      return props.theme.colors.elevatedBackgroundTertiary;
    }
    return props.active
      ? props.theme.colors.greenDefault
      : props.theme.colors.elevatedBackgroundPrimary;
  }};

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  ${Body1} {
    color: ${(props) => {
      if (props.disabled) {
        return props.theme.colors.textTertiary;
      }
      return props.active
        ? props.theme.colors.whiteDefault
        : props.theme.colors.textPrimary;
    }};
  }
  border: 1px solid
    ${(props) => {
      if (props.active) {
        return props.disabled
          ? props.theme.colors.grey3
          : props.theme.colors.greenDefault;
      }
      return props.theme.colors.grey3;
    }};

  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  transition: 0.2s all;
  transition-property: background-color, border-color, color, opacity;
`;

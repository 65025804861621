import _ from 'lodash';

import { defaultPrinter } from '../../reducers/printers/initialState';
import { defaultMaterials } from '../../reducers/slicer/initialState';

export default class ProfileParser {
  cleanGCode(gcode) {
    // eslint-disable-next-line no-control-regex
    return gcode.replace(/[^\x00-\x7F]/g, '');
  }

  parse() {
    const printer = ProfileParser.getDefaultPrinter();
    return {
      machineSettings: printer.machineSettings,
      styles: [printer.styles[0]],
      materials: [],
      warnings: {},
    };
  }

  static getDefaultPrinter() {
    return _.cloneDeep(defaultPrinter);
  }

  static getDefaultMaterial() {
    return _.cloneDeep(defaultMaterials['0']);
  }
}

import React, { Component } from 'react';
import { ModalButtonWrapper, ModelInputWrapper } from '../objectBrowser.styles';

import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalFooter,
} from '../../../../shared';

import { Subtitle1 } from '../../../../shared/typography/typography';

class DuplicateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusField: false,
      count: 1,
      inputError: false,
    };
  }

  focusField() {
    this.setState({ focusField: true }, () => {
      this.setState({ focusField: false });
    });
  }

  onInputChange(value, error = false) {
    this.setState({
      count: value,
      inputError: error,
    });
  }

  onInputChangeSuccess(value) {
    this.onInputChange(value, false);
  }

  onInputChangeFailure(value) {
    this.onInputChange(value, true);
  }

  onCancel() {
    this.props.onCancel();
  }

  onSave() {
    if (this.state.inputError) {
      this.focusField();
    } else {
      this.props.onConfirm(this.props.selectedItem, this.state.count);
    }
  }

  render() {
    const { count, inputError } = this.state;

    return (
      <Modal>
        <ModalHeader>
          <Subtitle1>Duplicate</Subtitle1>
        </ModalHeader>
        <ModelInputWrapper>
          <Input
            type='number'
            label='Count'
            autoFocus
            max={50}
            min={1}
            step={1}
            forceFocus={this.state.focusField}
            value={count}
            isInvalid={inputError}
            onChangeSuccess={(value) => this.onInputChangeSuccess(value)}
            onChangeFailure={(value) => this.onInputChangeFailure(value)}
          />
        </ModelInputWrapper>
        <ModalFooter>
          <ModalButtonWrapper>
            <Button title='Cancel' onClick={() => this.onCancel()}>
              Cancel
            </Button>
          </ModalButtonWrapper>
          <ModalButtonWrapper>
            <Button
              primary
              title='Duplicate'
              onClickKeyTrigger='Enter'
              onClick={() => this.onSave()}>
              Duplicate
            </Button>
          </ModalButtonWrapper>
        </ModalFooter>
      </Modal>
    );
  }
}

export default DuplicateModal;

import styled from 'styled-components';

export const HeaderLabelWrapper = styled.div`
  display: flex;
  flex: 0;
  margin: 0 ${(props) => (props.icon ? 1 : 0.5)}rem;
  ${(props) =>
    props.defaultCollapsiblePanel && props.icon ? 'margin: 0 0.8125rem;' : ''}
  justify-content: left;
  max-width: 60%;
  text-align: left;
  gap: 0.25rem;
`;

export const CollapseIconWrapper = styled.div`
  position: absolute;
  right: ${(props) => (props.defaultCollapsiblePanel ? 0.8125 : 0.5)}rem;
`;

export const HeaderCustomButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  gap: 0.5rem;
  right: 0rem;
  top: 0rem;
  margin: 0.625rem 0.75rem 0.625rem 0rem;
`;

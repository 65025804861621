import _ from 'lodash';
import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API from '../canvas-api';

import { handleGenericError } from '../common';
import { actions } from '../../reducers/auth/auth';

export default function* getIntegrations(action) {
  try {
    const response = yield call(API, {
      path: 'users/integrations',
    });
    if (response === null) return;

    const integrations = _.map(response.integrations, (integration) => ({
      ...integration,
      image: `data:image/svg+xml;base64,${integration.image}`,
    }));
    yield put(actions.getIntegrationsSuccess(integrations));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import { connect } from 'react-redux';

import SelectStyleImports from '../selectStyleImports.jsx';
import { actions } from '../../../../reducers/printers/printers';

const mapStateToProps = (state) => ({
  mode: 'import',
  title: 'Import Style Profiles',
  printerTag: state.printers.currentPrinterTag,
  printers: state.printers.printers,
  parsedProfile: state.printers.parsedProfile,
  createStylesPending: state.printers.status.createStylesPending,
  requestSuccess: state.printers.status.createStylesSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  createStyleProfiles: (printerId, styleProfiles) =>
    dispatch(actions.createStyleProfilesRequest(printerId, styleProfiles)),
  resetParsedProfile: () => dispatch(actions.resetParsedProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectStyleImports);

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError, getAuthState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';
import { defaultColors } from '../../../reducers/slicer/initialState';
import { paletteConstants } from '../../../constants';

export default function* createProject(action) {
  try {
    const { newProjectOptions } = yield select(getAuthState);
    const { projectOptions } = action.payload;
    const {
      name,
      slicer,
      printerId,
      styleId,
      materialId,
      parentId,
      deviceConfig,
    } = projectOptions;

    let colors = newProjectOptions.defaultColors;
    if (newProjectOptions.defaultColors === 'auto') {
      // use default colors set by frontend
      colors = defaultColors;
    }
    const body = {
      name,
      styleId,
      colors,
      printerId,
      deviceConfig,
    };

    // specify this project's slicer, if present
    if (slicer) {
      body.slicer = slicer;
    }

    // include parentId, if available
    if (parentId) {
      body.parentId = parentId;
    }
    if (materialId !== '0') {
      body.materialIds = new Array(paletteConstants.MAX_INPUT_COUNT).fill(
        materialId
      );
    }

    const response = yield call(API, {
      method: methods.PUT,
      path: 'projects',
      body,
    });
    if (response === null) return;

    yield put(actions.createProjectSuccess(response.project));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import React from 'react';

const extrusionWidth = (
  <>
    Specify the width of a printed line. A good value to start with is the
    diameter of your nozzle while also ensuring that it is also greater than
    your layer height.
  </>
);

const extrusionMultiplier = (
  <>
    Use this setting to increase or decrease the amount of material that will be
    printed during a given print line. This can be used to compensate for
    differences in materials such as expansion or contraction.
  </>
);

const useGapFill = (
  <>
    Enabling gap fill allows the slicer to attempt to fill in small gaps and
    thin walls.
  </>
);

const gapFillMinLength = (
  <>
    <>
      Gap fill extrusions must be longer than this length to be printed.
      Decreasing this value will lead to more gaps being filled in but increases
      print time and retractions. In some cases, extrusion moves that are too
      small will result in poor print quality.
    </>
    <>Only available when using KISSlicer.</>
  </>
);

const useRetracts = (
  <>
    Enable or disable retractions. Retractions are when the extruder pulls back
    on the filament to reduce pressure in the nozzle and stop the flow of
    plastic. Retractions happen before the print head moves without extruding to
    minimize stringing and oozing.
  </>
);

const retractLength = (
  <>
    Set the distance the filament should be retracted before the print head
    moves. A greater distance will limit stringing, but too large a value and
    the extruder may grind on the filament or cause a nozzle clog.
  </>
);

const retractSpeed = <>Set the speed to be used when retracting filament.</>;

const retractDisableThreshold = (
  <>
    Any travel movement after a print line lower than this value will not
    retract or wipe.
  </>
);

const retractForceThreshold = (
  <>
    <>
      Any travel movement after a print line greater than this value will always
      retract and wipe.
    </>
    <>Only available when using KISSlicer.</>
  </>
);

const wipeLength = (
  <>
    Before moving, the extruder will travel this distance over an already
    printed line. This helps to wipe off the nozzle leading to less stringing,
    blobbing and oozing as the extruder moves.
  </>
);

const zLift = (
  <>
    Specify the distance that the extruder should lift up off the print at the
    end of a print line. This helps to prevent the nozzle running into the part
    or dragging across top surfaces. Z-lifts can create small deposits of
    filament that lead to a rougher edge where top surfaces and perimeters meet.
  </>
);

export default {
  extrusionWidth,
  extrusionMultiplier,
  useGapFill,
  gapFillMinLength,
  useRetracts,
  retractLength,
  retractSpeed,
  retractDisableThreshold,
  retractForceThreshold,
  wipeLength,
  zLift,
};

import 'regenerator-runtime/runtime';
import { all, takeLatest, throttle } from 'redux-saga/effects';

import { types } from '../../reducers/auth/auth';

// import each saga
import signup from './signup';
import login from './login';
import logout from './logout';
import logoutAllSessions from './logoutAllSessions';
import refreshUserToken from './refreshUserToken';
import getAccount from './getAccount';
import editAccount from './editAccount';
import deleteAccount from './deleteAccount';
import requestPasswordReset from './requestPasswordReset';
import resetPassword from './resetPassword';
import authorizeIntegration from './authorizeIntegration';
import linkIntegration from './linkIntegration';
import unlinkIntegration from './unlinkIntegration';
import getIntegrations from './getIntegrations';

export default function* authSaga() {
  yield all([
    takeLatest(types.SIGNUP_REQUEST, signup),
    takeLatest(types.LOGIN_REQUEST, login),
    takeLatest(types.LOGOUT, logout),
    takeLatest(types.LOGOUT_ALL_SESSIONS_REQUEST, logoutAllSessions),
    throttle(10000, types.REFRESH_USER_TOKEN_REQUEST, refreshUserToken),
    takeLatest(types.GET_ACCOUNT_REQUEST, getAccount),
    takeLatest(types.EDIT_ACCOUNT_REQUEST, editAccount),
    takeLatest(types.DELETE_ACCOUNT_REQUEST, deleteAccount),
    takeLatest(types.REQUEST_PASSWORD_RESET_REQUEST, requestPasswordReset),
    takeLatest(types.RESET_PASSWORD_REQUEST, resetPassword),
    takeLatest(types.AUTHORIZE_INTEGRATION_REQUEST, authorizeIntegration),
    takeLatest(types.LINK_INTEGRATION_REQUEST, linkIntegration),
    takeLatest(types.UNLINK_INTEGRATION_REQUEST, unlinkIntegration),
    takeLatest(types.GET_INTEGRATIONS_REQUEST, getIntegrations),
  ]);
}

import React from 'react';
import { InlineCode, BlockCodePre, BlockCodeCode } from './styles';

export { InlineCode };

export const BlockCode = (props) => (
  <BlockCodePre>
    <BlockCodeCode>{props.children}</BlockCodeCode>
  </BlockCodePre>
);

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { actions } from '../../../reducers/onboarding/onboarding';
import TaskManager from './taskManager.jsx';

const mapStateToProps = (state) => ({
  taskStatus: state.onboarding.tasks,
});

const mapDispatchToProps = (dispatch) => ({
  setTaskStepStatus: (task, status) =>
    dispatch(actions.setTaskStepStatusRequest(task, status)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TaskManager)
);

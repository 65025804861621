import styled, { css } from 'styled-components';

import { Image } from '../../../shared';
import { Breakpoints } from '../../../themes';

export const DropdownContainer = styled.div`
  grid-column: span 1;

  @media (max-width: ${Breakpoints.smallWidth}) {
    grid-column: span 2;
  }
`;

export const BedGraphic = styled.div`
  width: 10rem;
  height: 10rem;
  position: relative;
  background: ${(props) => props.theme.colors.grey4};
  border-radius: ${(props) => (props.circular ? '50%' : '0')};

  @media (max-width: ${Breakpoints.mediumWidth}) {
    margin: 1rem 0;
  }
  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    width: calc(100vw - 12rem);
    height: calc(100vw - 12rem);
  }
`;

export const BedGraphicContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  padding-bottom: 2rem;
  padding-top: 1rem;
  border: 1px solid ${(props) => props.theme.colors.grey3};
`;

export const BedUnitXWrapper = styled.div`
  position: absolute;
  width: 4rem;
  height: 2rem;
  bottom: -2rem;
  left: calc(50% - 2rem);
`;

export const BedUnitYWrapper = styled.div`
  position: absolute;
  width: 4rem;
  height: 2rem;
  top: calc(50% - 1rem);
  transform: rotate(-90deg);
  left: calc(100% - 1rem);
`;

export const OriginMarker = styled.div`
  background: ${(props) => props.theme.colors.whiteDefault};
  border: 1px solid ${(props) => props.theme.colors.grey4};
  border-radius: 50%;
  width: 0.6rem;
  height: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: calc(${(props) => props.y && props.y}% - 0.3rem);
  left: calc(${(props) => props.x && props.x}% - 0.3rem);

  ::before {
    content: '';
    font-size: 1rem;
  }
`;

export const IconsContainer = styled.div`
  padding-top: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: center;
  grid-gap: 0.5rem;
`;

export const IconOption = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  grid-column: span 1;
  width: 7rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.4;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    width: 6rem;
    height: 6rem;
  }

  &:hover {
    opacity: 1;
  }

  ${(props) =>
    props.isCurrent &&
    css`
      border-color: ${props.theme.colors.greenDefault};
      opacity: 1;
    `};
`;

export const PrinterIcon = styled(Image)`
  width: 80%;
  height: 80%;
`;

export const TextFieldInputWrapper = styled.div`
  @media (max-width: ${Breakpoints.smallWidth}) {
    grid-column: span 2;
  }
`;

export const CheckboxContainer = styled.div`
  grid-column: span 2;
  align-self: center;
`;

export const GCodeLine = styled.li`
  font-family: ${(props) => props.theme.fonts.familyMono};
  font-size: 1.2rem;
`;

export const SequenceWarningsButtonWrapper = styled.div`
  align-self: flex-end;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SequenceEditorWrapper = styled.div`
  margin-right: 1.5rem;
`;

import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

export default function* updateSharedProject(action) {
  try {
    const { projectId, shareModels, sharePrinter, shareStyle, shareMaterials } =
      action.payload;
    const response = yield call(API, {
      method: methods.POST,
      path: `projects/${projectId}/share`,
      body: {
        shareModels,
        sharePrinter,
        shareStyle,
        shareMaterials,
      },
    });
    if (response === null) return;

    const { id, dateModified } = response.sharedProject;
    const shareData = {
      shareId: id,
      dateShared: dateModified,
      shareModels,
      sharePrinter,
      shareStyle,
      shareMaterials,
    };
    yield put(actions.updateSharedProjectSuccess(projectId, shareData));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import API from '../../canvas-api';
import { handleGenericError, getAuthState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

export default function* getPolarPrinters(action) {
  try {
    const { userId } = yield select(getAuthState);
    const response = yield call(API, {
      path: `users/${userId}/integrations/polar/printers`,
    });
    if (response === null) return;
    yield put(actions.getPolarPrintersSuccess(response.polarPrinters));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

// Icons 2.3

// basic
import addCard from './icons/add-card.svg';
import alert from './icons/alert.svg';
import arrowDown from './icons/arrow-down.svg';
import arrowLeft from './icons/arrow-left.svg';
import arrowRight from './icons/arrow-right.svg';
import arrowUp from './icons/arrow-up.svg';
import bellRinging from './icons/bell-ringing.svg';
import bell from './icons/bell.svg';
import beta from './icons/beta.svg';
import bubbles from './icons/bubbles.svg';
import camera from './icons/camera.svg';
import chart from './icons/chart.svg';
import checkCircle from './icons/check-circle.svg';
import check from './icons/check.svg';
import checkboxChecked from './icons/checkbox-checked.svg';
import checkboxEmpty from './icons/checkbox-empty.svg';
import chevronDown from './icons/chevron-down.svg';
import chevronLeft from './icons/chevron-left.svg';
import chevronRight from './icons/chevron-right.svg';
import chevronUp from './icons/chevron-up.svg';
import cloud from './icons/cloud.svg';
import copy from './icons/copy.svg';
import danger from './icons/danger.svg';
import trash from './icons/trash.svg';
import email from './icons/email.svg';
import error from './icons/error.svg';
import eyeClose from './icons/eye-close.svg';
import eyeOpen from './icons/eye-open.svg';
import file from './icons/file.svg';
import first from './icons/first.svg';
import folderMove from './icons/folder-move.svg';
import folder from './icons/folder.svg';
import grid from './icons/grid.svg';
import help from './icons/help.svg';
import image from './icons/image.svg';
import importIcon from './icons/import.svg';
import info from './icons/info.svg';
import integration from './icons/integration.svg';
import key from './icons/key.svg';
import last from './icons/last.svg';
import linkExternal from './icons/link-external.svg';
import link from './icons/link.svg';
import list from './icons/list.svg';
import unlock from './icons/unlock.svg';
import logout from './icons/logout.svg';
import menu from './icons/menu.svg';
import minus from './icons/minus.svg';
import more from './icons/more.svg';
import pencil from './icons/pencil.svg';
import plus from './icons/plus.svg';
import power from './icons/power.svg';
import publish from './icons/publish.svg';
import radio from './icons/radio.svg';
import radioChecked from './icons/radio-checked.svg';
import receipt from './icons/receipt.svg';
import redo from './icons/redo.svg';
import refresh from './icons/refresh.svg';
import restore from './icons/restore.svg';
import save from './icons/save.svg';
import unlink from './icons/unlink.svg';
import upload from './icons/upload.svg';
import search from './icons/search.svg';
import send from './icons/send.svg';
import settings from './icons/settings.svg';
import share from './icons/share.svg';
import subdir from './icons/subdir.svg';
import swap from './icons/swap.svg';
import toggleOff from './icons/toggle-off.svg';
import toggleOn from './icons/toggle-on.svg';
import tune from './icons/tune.svg';
import triangleDown from './icons/triangle-down.svg';
import triangleUp from './icons/triangle-up.svg';
import undo from './icons/undo.svg';
import x from './icons/x.svg';

// mosaic
import array from './icons/array.svg';
import element from './icons/element.svg';
import elementArray from './icons/element-array.svg';
import filament from './icons/filament.svg';
import hub from './icons/hub.svg';
import mp from './icons/mp.svg';
import pPlus from './icons/p-plus.svg';
import p2 from './icons/p2.svg';
import p2Accessory from './icons/p2-accessory.svg';
import p3 from './icons/p3.svg';
import p3Accessory from './icons/p3-accessory.svg';
import p from './icons/p.svg';
import usb from './icons/usb.svg';

// fdm
import addMaterial from './icons/add-material.svg';
import cart from './icons/cart.svg';
import cartIn from './icons/cart-in.svg';
import extrude from './icons/extrude.svg';
import extruderDown from './icons/extruder-down.svg';
import extruderLeft from './icons/extruder-left.svg';
import extruderRight from './icons/extruder-right.svg';
import extruderUp from './icons/extruder-up.svg';
import extruder from './icons/extruder.svg';
import extruding from './icons/extruding.svg';
import fan from './icons/fan.svg';
import firstLayer from './icons/first-layer.svg';
import gantry from './icons/gantry.svg';
import gcode from './icons/gcode.svg';
import heating from './icons/heating.svg';
import home from './icons/home.svg';
import infill from './icons/infill.svg';
import material from './icons/material.svg';
import object from './icons/object.svg';
import layers from './icons/layers.svg';
import maintenance from './icons/maintenance.svg';
import pause from './icons/pause.svg';
import perimeter from './icons/perimeter.svg';
import pickup from './icons/pickup.svg';
import play from './icons/play.svg';
import setup from './icons/setup.svg';
import styleProfile from './icons/style-profile.svg';
import support from './icons/support.svg';
import temperature from './icons/temperature.svg';
import terminal from './icons/terminal.svg';
import transition from './icons/transition.svg';

// project
import addModal from './icons/add-modal.svg';
import addMultiple from './icons/add-multi-material.svg';
import addSingle from './icons/add-single-material.svg';
import align from './icons/align.svg';
import autoSegment from './icons/auto-segment.svg';
import brush from './icons/brush.svg';
import bucket from './icons/bucket.svg';
import circle from './icons/circle.svg';
import editModal from './icons/edit-modal.svg';
import inbox from './icons/inbox.svg';
import materials from './icons/materials.svg';
import move from './icons/move.svg';
import newProject from './icons/new-project.svg';
import project from './icons/project.svg';
import projects from './icons/projects.svg';
import rotate from './icons/rotate.svg';
import scale from './icons/scale.svg';
import sliced from './icons/sliced.svg';
import stamp from './icons/stamp.svg';
import submission from './icons/submission.svg';
import triangle from './icons/triangle.svg';

// user
import member from './icons/member.svg';
import shared from './icons/shared.svg';
import user from './icons/user.svg';

const Icons = {
  basic: {
    addCard,
    alert,
    arrowDown,
    arrowLeft,
    arrowRight,
    arrowUp,
    bellRinging,
    bell,
    beta,
    bubbles,
    camera,
    chart,
    checkCircle,
    check,
    checkboxChecked,
    checkboxEmpty,
    chevronDown,
    chevronLeft,
    chevronRight,
    chevronUp,
    cloud,
    copy,
    danger,
    trash,
    email,
    error,
    eyeClose,
    eyeOpen,
    file,
    first,
    folderMove,
    folder,
    grid,
    help,
    image,
    importIcon,
    info,
    integration,
    last,
    key,
    linkExternal,
    link,
    list,
    unlock,
    logout,
    menu,
    minus,
    more,
    pencil,
    plus,
    power,
    publish,
    radio,
    radioChecked,
    receipt,
    redo,
    refresh,
    restore,
    save,
    unlink,
    upload,
    search,
    send,
    settings,
    share,
    subdir,
    swap,
    toggleOff,
    toggleOn,
    triangleDown,
    triangleUp,
    tune,
    undo,
    x,
  },
  mosaic: {
    array,
    element,
    elementArray,
    filament,
    hub,
    mp,
    pPlus,
    p,
    p2,
    p2Accessory,
    p3,
    p3Accessory,
    usb,
  },
  fdm: {
    addMaterial,
    cart,
    cartIn,
    extrude,
    extruderDown,
    extruderLeft,
    extruderRight,
    extruderUp,
    extruder,
    extruding,
    fan,
    firstLayer,
    gantry,
    gcode,
    heating,
    home,
    infill,
    layers,
    maintenance,
    material,
    object,
    pause,
    perimeter,
    pickup,
    play,
    setup,
    styleProfile,
    support,
    temperature,
    terminal,
    transition,
  },
  project: {
    addModal,
    addMultiple,
    addSingle,
    align,
    autoSegment,
    brush,
    bucket,
    circle,
    editModal,
    inbox,
    materials,
    move,
    newProject,
    project,
    projects,
    rotate,
    scale,
    sliced,
    stamp,
    submission,
    triangle,
  },
  user: {
    member,
    shared,
    user,
  },
};
export default Icons;

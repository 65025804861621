import styled from 'styled-components';
import { Breakpoints } from '../../../themes';
import { Body1, Caption } from '../../../shared/typography/typography';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  padding: 0 1em;

  #svg-wrapper {
    padding: 2em 1em;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      overflow: visible;
      transform: translateX(-1em);
    }

    g {
      transition: all ease-in-out 100ms;
      path,
      polygon {
        transition: all ease-in-out 100ms;
      }
    }
  }

  #svg-wrapper.single {
    g {
      path,
      polygon {
        fill: #1dbc94;
      }
    }

    #yellow {
      transform: translate3d(-0.5em, -0.5em, 0);
    }
    #purple {
      transform: translate3d(0.5em, -0.5em, 0);
    }
    #teal {
      transform: translate3d(0.1em, 0.1em, 0);
    }
  }

  @media (max-width: ${Breakpoints.extraSmallWidth}),
    (max-height: ${Breakpoints.extraSmallHeight}) {
    #svg-wrapper {
      padding: 0.5em 1em;

      svg {
        height: 5em;
        transform: translateX(-0.5em);
      }
    }
  }
`;

export const MaterialInfo = styled.div`
  ${Body1}, ${Caption} {
    color: ${(props) => props.theme.colors.textSecondary};
  }
  &.focused ${Body1}, &.focused ${Caption} {
    color: ${(props) => props.theme.colors.textPrimary};
  }
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition-property: color;

  @media (max-width: ${Breakpoints.extraSmallWidth}),
    (max-height: ${Breakpoints.extraSmallHeight}) {
    padding: 1em 0;
  }
`;

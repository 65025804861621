import 'regenerator-runtime/runtime';
import { put, select } from 'redux-saga/effects';

import { getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';
import {
  createExtrusionMesh,
  createTravelMesh,
  createRetractMesh,
  createRestartMesh,
  getDisplayLegend,
  getSortedLayerHeights,
} from '../../../utils/ptp/ptpLoader';

let channel = null;

const getRetractRestartDiameter = (style) => {
  let maxLayerHeight = style.layerHeight;
  if (style.firstLayerHeight.units === 'mm') {
    maxLayerHeight = Math.max(maxLayerHeight, style.firstLayerHeight.value);
  } else if (style.firstLayerHeight.units === '%') {
    maxLayerHeight = Math.max(
      maxLayerHeight,
      (style.layerHeight * style.firstLayerHeight.value) / 100
    );
  }
  if (style.useVariableLayerHeight) {
    if (style.maxLayerHeight.units === 'mm') {
      maxLayerHeight = Math.max(maxLayerHeight, style.maxLayerHeight.value);
    } else if (style.maxLayerHeight.units === '%') {
      maxLayerHeight = Math.max(
        maxLayerHeight,
        (style.layerHeight * style.maxLayerHeight.value) / 100
      );
    }
  }
  return Math.max(maxLayerHeight, style.extrusionWidth);
};

export default function* createToolpathMeshes(action) {
  const { currentProject, projects, toolpath } = yield select(getSlicerState);
  const { style } = projects[currentProject];
  const { viewType, resetBounds, replaceExtrusions } = action.payload;
  const retractDiameter = getRetractRestartDiameter(style);
  try {
    const result = {
      viewType,
      legend: getDisplayLegend(toolpath, viewType),
      layerHeights: getSortedLayerHeights(toolpath),
    };
    result.layerCount = result.layerHeights.length;
    if (
      (!toolpath.extrusions || replaceExtrusions) &&
      toolpath.ptp.buffers.position
    ) {
      result.extrusions = createExtrusionMesh(toolpath, viewType);
    }
    if (resetBounds) {
      result.minLayer = 0;
      result.maxLayer = result.layerCount;
    }
    if (
      toolpath.showTravel &&
      !toolpath.travels &&
      toolpath.ptp.buffers.travelPosition
    ) {
      result.travels = createTravelMesh(toolpath);
    }
    if (
      toolpath.showRetracts &&
      !toolpath.retracts &&
      toolpath.ptp.buffers.retractPosition
    ) {
      result.retracts = createRetractMesh(toolpath, retractDiameter);
    }
    if (
      toolpath.showRestarts &&
      !toolpath.restarts &&
      toolpath.ptp.buffers.restartPosition
    ) {
      result.restarts = createRestartMesh(toolpath, retractDiameter);
    }
    yield put(actions.createToolpathMeshesProgress(100));
    yield put(actions.createToolpathMeshesSuccess(result));
  } catch (err) {
    yield put(actions.createToolpathMeshesFailure());
  }
}

// eslint-disable-next-line require-yield
export function* cancelCreateToolpathMeshes() {
  if (channel) {
    channel.close();
    channel = null;
  }
}

export const ImportStylesOptions = {
  DO_NOT_IMPORT: 0,
  IMPORT_STYLE_PROFILE: 1,
  USE_ESSENTIALS: 2,
};

export const ImportPrinterOptions = {
  IMPORT_PRINTER_PROFILE: 0,
  USE_EXISTING_PRINTER_PROFILE: 1,
};

export const ProjectNotFoundDescription =
  'Sorry, the shared project you requested could not be found.  Please make sure that the project owner has not turned off sharing and that you have the correct share link.';

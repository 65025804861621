import 'regenerator-runtime/runtime';
import _ from 'lodash';
import { all, call, put, race, select, take } from 'redux-saga/effects';

import API, { errorTypes } from '../../canvas-api';
import { getPrinterState, getIotState, handleGenericError } from '../../common';
import { sanitizeStyleData } from '../../printers/common';
import { actions } from '../../../reducers/slicer/slicer';
import {
  actions as iotActions,
  types as iotTypes,
} from '../../../reducers/iot/iot';
import {
  actions as printerActions,
  types as printerTypes,
} from '../../../reducers/printers/printers';
import { SlicerUtils, TreeUtils } from '../../../utils';
import { loadStampData } from '../models/helpers/stamps';

function* waitForGetPrinters() {
  const { status } = yield select(getPrinterState);

  // if data is loaded already, just return true
  if (status.getPrintersSuccess) return true;

  // make new request, if there isn't one pending already
  if (!status.getPrintersPending) {
    yield put(printerActions.getPrintersRequest());
  }

  // take success or failure and return
  const { success } = yield race({
    success: take(printerTypes.GET_PRINTERS_SUCCESS),
    failure: take(printerTypes.GET_PRINTERS_FAILURE),
  });

  return !!success;
}

export function* waitForGetDevices() {
  const { status } = yield select(getIotState);

  // if data is loaded already, just return true
  if (status.getDevicesSuccess) return true;

  // make new request, if there isn't one pending already
  if (!status.getDevicesPending) {
    yield put(iotActions.getDevicesRequest());
  }

  // take success or failure and return
  const { success } = yield race({
    success: take(iotTypes.GET_DEVICES_SUCCESS),
    failure: take(iotTypes.GET_DEVICES_FAILURE),
  });

  return !!success;
}

export function* getModelFile(projectId, model, colors) {
  let colorsRle = null;
  let supportsRle = null;
  const response = yield call(API, {
    path: `projects/${projectId}/models/${model.id}`,
  });
  if (response === null) return null;
  const { url: signedUrl } = response;

  if (model.colored) {
    const colorsResponse = yield call(API, {
      path: `projects/${projectId}/models/${model.id}/colors`,
    });
    if (colorsResponse === null) return null;
    colorsRle = colorsResponse.colors;
  }

  if (model.textured) {
    const stampsResponse = yield call(API, {
      path: `projects/${projectId}/models/${model.id}/stamps`,
    });
    if (stampsResponse === null) return null;
    // initialize stamps map
    const stamps = {};
    const stampsArray = _.values(stampsResponse.stamps);

    const stampData = yield all(
      _.map(stampsArray, (currentStamp) =>
        call(loadStampData, currentStamp, colors.length)
      )
    );
    for (let i = 0; i < stampsArray.length; i++) {
      const { id } = stampsArray[i];
      stamps[id] = stampData[i];
    }

    // attach stamps map on model
    // eslint-disable-next-line no-param-reassign
    model.stamps = stamps;
  }

  if (model.hasCustomSupports) {
    const supportsResponse = yield call(API, {
      path: `projects/${projectId}/models/${model.id}/supports`,
    });
    if (supportsResponse === null) return null;
    ({ supportsRle } = supportsResponse);
  }

  return yield SlicerUtils.requestMesh(
    signedUrl,
    model,
    colors,
    colorsRle,
    supportsRle
  );
}

function* loadProjectById(projectId) {
  const response = yield call(API, {
    path: `projects/${projectId}`,
  });
  if (response === null) return null;

  const { models, colors } = response.project;

  const meshes = yield all(
    _.map(models, (model) => call(getModelFile, projectId, model, colors))
  );
  if (_.some(meshes, (mesh) => mesh === null)) return null;

  const modelTree = TreeUtils.addMeshesToModelTree(
    TreeUtils.formatModelTree(models),
    meshes
  );
  return {
    ...response.project,
    models: modelTree,
    towerRepackRequired: response.repackRequired,
  };
}

export default function* loadProject(action) {
  const { projectId } = action.payload;
  try {
    const response = yield call(loadProjectById, projectId);
    if (response === null) return;
    const { models, transitionTower, towerRepackRequired, ...project } =
      response;
    project.style = sanitizeStyleData(project.style);
    yield put(actions.loadProjectSuccess(project, models));

    if (towerRepackRequired) {
      // tower was created that did not exist previously -- auto-position it
      yield put(
        actions.repositionTower(projectId, transitionTower, project.style)
      );
    } else {
      yield put(actions.updateTower(transitionTower));
    }

    yield put(actions.loadProjectStatsRequest(projectId));
  } catch (e) {
    if (e.status === 409 && e.type === errorTypes.requireDecision) {
      // require remapping

      // load required resources
      const resourceResponses = yield all([
        call(waitForGetPrinters),
        call(waitForGetDevices),
      ]);

      // if any of the resources failed to load, dispatch failure action and exit saga
      if (_.some(resourceResponses, (response) => response === false)) {
        yield put(actions.loadProjectFailure(e));
        return;
      }

      // finally, trigger input remapper with context data
      const remapperContext = {
        projectId,
        ...e.payload,
      };
      yield put(actions.setInputRemapperContext(remapperContext));
      yield put(actions.loadProjectFailure(null));
    } else {
      yield call(handleGenericError, action, e);
    }
  }
}

import 'regenerator-runtime/runtime';
import { all, call, put, select, take } from 'redux-saga/effects';
import _ from 'lodash';

import API, { methods } from '../../canvas-api';
import {
  handleGenericError,
  getPrinterState,
  getSlicerState,
} from '../../common';
import { actions, types } from '../../../reducers/slicer/slicer';
import {
  actions as THREEactions,
  types as THREEtypes,
} from '../../../reducers/three/three';
import { ProjectUtils, TreeUtils } from '../../../utils';
import { getModelFile } from './loadProject';

function* renameProject(projectId, name) {
  const response = yield call(API, {
    method: methods.POST,
    path: `projects/${projectId}/name`,
    body: {
      name,
      allowMakeNameUnique: false,
    },
  });
  if (response === null) return null;
  return response.project.name;
}

/**
 * Saga to handle update of project settings
 * - choice of associated printer
 * - choice of associated printer style profile
 * - set of values for style settings local to project
 * Note: not to be confused with `updateProjectStyle` saga,
 *   which handles silently updating style settings only
 */
export default function* updateProjectSettings(action) {
  try {
    const { projectId, settings, reloadRequired } = action.payload;
    const { name, slicer, printerId, styles, deviceConfig } = settings;
    let finalProjectName;

    if (name) {
      const newName = yield call(renameProject, projectId, name);
      if (newName === null) return;
      finalProjectName = newName;
    }

    const body = {
      style: styles,
    };

    // include printerId, if available
    if (printerId) {
      body.printerId = printerId;
      body.styleId = styles.id;
    }

    if (deviceConfig || deviceConfig === null) {
      body.deviceConfig = deviceConfig;
    }

    if (slicer) {
      body.slicer = slicer;
    }

    const response = yield call(API, {
      method: methods.POST,
      path: `projects/${projectId}`,
      body,
    });
    if (response === null) return;

    const { projects } = yield select(getSlicerState);
    const { printers } = yield select(getPrinterState);

    const prevProject = projects[projectId];
    const { project } = response;
    if (finalProjectName) {
      project.name = finalProjectName;
    }

    if (reloadRequired) {
      // this saga was triggered before project was initialized.
      // finish the action, and exit this saga early
      // Slicer will take care of reloading the project
      yield put(actions.updateProjectSettingsSuccess(project));
      return;
    }

    if (prevProject.colors.length !== project.colors.length) {
      // reload meshes to update model colors
      const meshes = yield all(
        _.map(project.models, (model) =>
          call(getModelFile, projectId, model, project.colors)
        )
      );
      const { models } = yield select(getSlicerState);
      const modelTree = TreeUtils.addMeshesToModelTree(models, meshes);
      yield put(THREEactions.removeModelsFromScene());
      yield put(actions.loadProjectSuccess(project, modelTree));
      yield put(THREEactions.addModelsToScene(modelTree));
      yield put(actions.updateProjectThumbnailRequest(projectId));
    }

    yield put(actions.updateProjectSettingsSuccess(project));

    yield put(
      actions.updateTowerFromServer(projectId, project.transitionTower)
    );
    const { transitionTower: currentTower } = yield select(getSlicerState);
    if (currentTower || project.transitionTower) {
      // only wait for tower to update if there was a tower before or if there is a tower now
      yield take(types.UPDATE_TOWER);
    }

    if (prevProject.printerId !== project.printerId) {
      const prevPrinter = ProjectUtils.getProjectPrinter(prevProject, printers);
      const printer = ProjectUtils.getProjectPrinter(project, printers);
      const {
        bedSize: prevBedSize,
        originOffset: prevOriginOffset,
        circular: prevCircular,
      } = prevPrinter.machineSettings;
      const { bedSize, originOffset, circular } = printer.machineSettings;

      // only need to re-center project if bed size/origin changed
      const bedDimensionsChanged =
        !_.isEqual(bedSize, prevBedSize) ||
        !_.isEqual(originOffset, prevOriginOffset);
      // also need to update bed mesh in visualizer if shape changed
      const bedMeshChanged = bedDimensionsChanged || prevCircular !== circular;
      const centerProject = bedDimensionsChanged;

      if (bedMeshChanged) {
        yield put(THREEactions.updatePrintBedRequest(printer, centerProject));
        yield take(THREEtypes.UPDATE_PRINT_BED_SUCCESS);
      }

      if (centerProject) {
        yield put(actions.centerProjectRequest(projectId, printer.id));
        yield take(types.CENTER_PROJECT_SUCCESS);
        yield put(THREEactions.resetCamera(false));
      }
    }
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import styled, { css } from 'styled-components';
import { Shadows } from '../../themes';

export const Container = styled.div`
  ${Shadows.d1};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: ${(props) => (props.forceOpen ? 'default' : 'pointer')};
  padding: 0 0 0 0.25rem;
  height: 2.75rem;
  background-color: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  border-bottom: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 0.25rem 0.25rem 0 0;
  transition: background 0.2s;
`;

export const Content = styled.div`
  flex-direction: column;
  position: relative;
  display: flex;
  box-sizing: border-box;
  max-height: ${(props) => (props.isCollapsed ? 0 : props.uncollapsedHeight)}px;
  height: ${(props) => (props.isCollapsed ? 0 : props.uncollapsedHeight)}px;
  transition: height, max-height;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1);
  overflow-y: hidden;
  background-color: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  border-radius: 0 0 0.25rem 0.25rem;
  ${(props) => props.footerContent && `border-radius:0`};
  ${(props) =>
    props.scroll &&
    css`
      overflow-y: ${props.isCollapsed ? 'hidden' : 'auto'};
    `};
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  ${(props) => props.isCollapsed && 'height: 0'};
  overflow: ${(props) => (props.isCollapsed ? 'hidden' : 'visible')};
  background-color: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  border-radius: 0 0 0.25rem 0.25rem;
`;

import React, { Component } from 'react';
import Div100vh from 'react-div-100vh';
import _ from 'lodash';

import {
  UploadButtonWrapper,
  div100vhStyles,
  Container,
  Header,
  ProfilesPanelWrapper,
} from './slicer.styles';

import Visualizer from './visualizer/visualizer.jsx';
import PlaceView from './placeView/placeView.container';
import PaintView from './paintView/paintView.container';
import SliceView from './sliceView/sliceView.container';
import SupportView from './supportView/supportView.container';

import ProjectSettings from './projectSettings/projectSettings.container';
import ProfilesPanel from './profilesPanel/profilesPanel.jsx';
import ViewOptions from './viewOptions/viewOptions.jsx';
import SpliceTransitionModal from './spliceTransitionModal/spliceTransitionModal.jsx';
import InputRemapperModal from './inputRemapperModal/inputRemapperModal.container';
import ModelUploader from './modelUploader/modelUploader.jsx';
import PaletteCalibrationSettings from './profilesPanel/calibrationSettingsModal.jsx';

import { SlicerContext } from './slicer.context';

import { Button, ProgressOverlay, RenameModal } from '../../shared';

import { InterfaceUtils, TreeUtils, ProjectUtils } from '../../utils';

import Icons from '../../themes/icons';
import Routes from '../../router/routes';

class Slicer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authInitialized: false,
      sceneInitialized: false,
      printerInitialized: false,
      materialsInitialized: false,
      devicesInitialized: false,
      projectInitialized: false,
      projectLoadError: false,
      modelsInScene: false,
      currentView: 'place',
      currentTool: null,
      currentExtruderIndex: -1,
      uniformScale: true,
      modelToColor: null,
      showUploadModal: false,
      showProjectSettingsModal: false,
      showSpliceTransitionModal: false,
      showProfilesPanelMobile: false,
      dragStart: false,
      uploadType: null,
      showRenameModal: false,
      showCalibrationModal: false,
    };
    this.canvasRef = React.createRef();
    this.documentStyle = null; // will be used for saving a copy of the current document style

    this.startFileDrag = this.startFileDrag.bind(this);
    this.endFileDrag = this.endFileDrag.bind(this);

    this.setCurrentTool = this.setCurrentTool.bind(this);
    this.setUniformScale = this.setUniformScale.bind(this);
    this.toggleTransitionLengthModal =
      this.toggleTransitionLengthModal.bind(this);
    this.toggleUploadModal = this.toggleUploadModal.bind(this);

    this.handleViewChange = this.handleViewChange.bind(this);
    this.enterPaintView = this.enterPaintView.bind(this);
    this.exitPaintView = this.exitPaintView.bind(this);
    this.enterSupportView = this.enterSupportView.bind(this);
    this.exitSupportView = this.exitSupportView.bind(this);
  }

  componentDidMount() {
    // app already loads all data other than the project, so use them if ready
    if (this.props.getPrintersSuccess) {
      this.setState({ printerInitialized: true });
    }
    if (this.props.getDevicesSuccess) {
      this.setState({ devicesInitialized: true });
    }
    // router loads account data
    if (this.props.getAccountSuccess) {
      this.setState({ authInitialized: true });
    }
    if (this.props.getMaterialsSuccess) {
      this.setState({ materialsInitialized: true });
    }

    // save a copy of previous body styles
    this.documentStyle = { ...document.body.style };
    // change the current document style to prevent any scrolling in the slicer,
    // primarily for convenience of touch devices
    document.body.style.position = 'fixed';
    document.body.style.overflow = 'hidden';

    const { projectId } = this.props.match.params;
    const isProjectSlicing =
      this.props.dispatchSliceJobPending ||
      ProjectUtils.isProjectSlicing(this.props.sliceJobs, projectId);

    if (isProjectSlicing) {
      this.setState({ currentView: 'slice' });
    }
  }

  componentWillUnmount() {
    this.props.destroyScene();
    this.props.unloadToolpath();
    this.props.unloadCurrentProject();
    // restore document styles upon exiting slicer to enable scrolling
    document.body.style = this.documentStyle;
  }

  componentDidUpdate(prevProps) {
    const { projectId } = this.props.match.params;

    // wait for user settings to be updated
    if (!this.state.authInitialized) {
      if (
        (prevProps.getAccountPending && !this.props.getAccountPending) ||
        this.props.getAccountSuccess
      ) {
        this.setState({ authInitialized: true });
      }
      return;
    }

    // wait for scene to initialize
    if (!this.state.sceneInitialized) {
      if (!prevProps.initScenePending && !this.props.initScenePending) {
        this.props.initScene(this.canvasRef.current);
      } else if (prevProps.initScenePending && !this.props.initScenePending) {
        this.setState({ sceneInitialized: true });
      }
    }
    // scene is fully ready to be populated

    // wait for devices to initialize
    if (!this.state.devicesInitialized) {
      if (!_.isEmpty(this.props.devices) || this.props.getDevicesSuccess) {
        // devices already loaded
        this.setState({ devicesInitialized: true });
      } else if (
        prevProps.getDevicesPending &&
        !this.props.getDevicesPending &&
        this.props.getDevicesSuccess
      ) {
        // devices done loading
        this.setState({ devicesInitialized: true });
      }
    }
    // devices data exists and is fully ready to be used

    // wait for printer to initialize
    // app already loads all printers, so simply wait for it to be ready
    if (!this.state.printerInitialized) {
      if (!_.isEmpty(this.props.printers) || this.props.getPrintersSuccess) {
        // printers already loaded
        this.setState({ printerInitialized: true });
      } else if (
        prevProps.getPrintersPending &&
        !this.props.getPrintersPending &&
        this.props.getPrintersSuccess
      ) {
        // printers done loading
        this.setState({ printerInitialized: true });
      }
      return;
    }
    // printer data exists and is fully ready to be used

    // wait for materials to initialize
    if (!this.state.materialsInitialized) {
      if (
        _.keys(this.props.materials).length > 1 ||
        this.props.getMaterialsSuccess
      ) {
        // materials already loaded
        this.setState({ materialsInitialized: true });
      } else if (
        prevProps.getMaterialsPending &&
        !this.props.getMaterialsPending &&
        this.props.getMaterialsSuccess
      ) {
        // materials done loading
        this.setState({ materialsInitialized: true });
      }
    }
    // material data exists and is fully ready to be used

    // wait for project to initialize
    if (!this.state.projectInitialized) {
      const projectInputsRemapped =
        prevProps.remapProjectInputsPending &&
        !this.props.remapProjectInputsPending &&
        this.props.remapProjectInputsSuccess;

      const projectSettingsUpdated =
        prevProps.updateProjectSettingsPending &&
        !this.props.updateProjectSettingsPending &&
        this.props.updateProjectSettingsSuccess;

      if (projectInputsRemapped || projectSettingsUpdated) {
        // the project has not yet been initialized;
        // if project inputs were remapped, or if project settings were updated,
        // reset error state to allow project to be loaded again
        this.setState({ projectLoadError: false });
        return;
      }

      if (this.state.projectLoadError) return;
      if (prevProps.loadProjectPending && !this.props.loadProjectPending) {
        // project, printers and devices done loading
        if (this.props.loadProjectSuccess) {
          this.setState({ projectInitialized: true }, () => {
            this.updateSlicerNavStack();

            const project = this.props.projects[projectId];
            const { printers } = this.props;
            const printer = ProjectUtils.getProjectPrinter(project, printers);
            this.props.updatePrintBed(printer);
          });
        } else {
          this.setState({ projectLoadError: true });
        }
      } else if (!this.props.loadProjectPending) {
        this.props.loadProject(projectId);
      }
      return;
    }

    // handle tower updates in scene
    if (this.props.transitionTower !== prevProps.transitionTower) {
      const { transitionTower } = this.props;
      const towerWithoutMesh = _.omit(transitionTower, ['mesh']);
      const prevTowerWithoutMesh = _.omit(prevProps.transitionTower, ['mesh']);
      if (!_.isEqual(towerWithoutMesh, prevTowerWithoutMesh)) {
        this.props.removeTowerFromScene();
        if (this.props.transitionTower && this.state.currentView === 'place') {
          this.props.addTowerToScene(this.props.transitionTower);
        }
      }
    }

    if (this.props.updatePrintBedPending) {
      return;
    }
    if (prevProps.updatePrintBedPending && !this.props.updatePrintBedPending) {
      this.resetCamera(false);
    }
    // project data exists and is fully ready to be used

    const project = this.props.projects[projectId];
    const prevProject = prevProps.projects[prevProps.match.params.projectId];

    const isProjectSlicing =
      this.props.dispatchSliceJobPending ||
      ProjectUtils.isProjectSlicing(this.props.sliceJobs, projectId);

    if (isProjectSlicing && this.state.currentView !== 'slice') {
      this.setState({ currentView: 'slice' });
      return;
    }

    // handle pageTitle changes depends on project's slice status changing
    if (this.props.sliceJobs !== prevProps.sliceJobs) {
      this.updateSlicerNavStack(isProjectSlicing);
    }

    // handle changes specific to PlaceView
    if (this.state.currentView === 'place') {
      // add models to scene
      if (!this.state.modelsInScene) {
        this.setState({ modelsInScene: true }, () => {
          const resetCamera = true;
          const needToCenter =
            this.props.location.state && this.props.location.state.needToCenter;
          this.props.addModelsToScene(resetCamera, needToCenter);
          if (prevProps.transitionTower) {
            this.props.addTowerToScene(prevProps.transitionTower);
          }
        });
        return;
      }

      // handle model upload/deletion in scene
      const previousModelsCount = TreeUtils.flattenDeep(
        prevProps.models
      ).length;
      const currentModelCount = TreeUtils.flattenDeep(this.props.models).length;
      if (currentModelCount !== previousModelsCount) {
        TreeUtils.map(this.props.models, (model) => {
          const prevModel = TreeUtils.searchById(model.id, prevProps.models);
          if (!prevModel) {
            // model was uploaded
            this.props.addModelToScene(model);
          }
        });
        TreeUtils.map(prevProps.models, (model) => {
          const currModel = TreeUtils.searchById(model.id, this.props.models);
          if (!currModel) {
            // model was deleted
            this.props.removeModelFromScene(model);
          }
        });
        this.setState({ showUploadModal: _.isEmpty(this.props.models) });
      }
    }

    // handle name updates in UI
    if (project && project.name !== prevProject?.name) {
      this.updateSlicerNavStack();
    }

    // handle current project changing without Slicer unmounting
    // - new project was created from sidebar
    // - user navigated to a different project via search bar
    if (prevProps.match.params.projectId !== projectId) {
      this.setState({ projectInitialized: false, modelsInScene: false }, () => {
        this.props.removeModelsFromScene(this.props.models);
        this.props.removeTowerFromScene();
        const { printers } = this.props;
        const updatedPrinter = ProjectUtils.getProjectPrinter(
          project,
          printers
        );
        this.props.updatePrintBed(updatedPrinter);
        this.props.unloadCurrentProject();
        this.props.loadProject(projectId);

        this.updateSlicerNavStack();
        this.setState({ currentView: 'place' });
      });
    }
  }

  isDataInitialized() {
    const {
      authInitialized,
      sceneInitialized,
      printerInitialized,
      materialsInitialized,
      devicesInitialized,
      projectInitialized,
    } = this.state;
    return (
      authInitialized &&
      sceneInitialized &&
      printerInitialized &&
      materialsInitialized &&
      devicesInitialized &&
      projectInitialized
    );
  }

  updateSlicerNavStack(renameDisabled = false) {
    const { projectId } = this.props.match.params;
    const project = this.props.projects[projectId];

    const navStack = [];

    if (project.parentName) {
      navStack.push({
        text: project.parentName,
        route: Routes.toFolder(project.parentId),
      });
    }

    navStack.push({
      text: project.name,
      onClick: () => this.showRenameModal(),
      disabled: renameDisabled,
    });

    this.props.updateNavStack(navStack);
  }

  toggleUploadModal() {
    this.setState({ showUploadModal: !this.state.showUploadModal });
  }

  toggleProjectSettings() {
    this.setState({
      showProjectSettingsModal: !this.state.showProjectSettingsModal,
    });
  }

  enterPaintView(modelToColor) {
    if (modelToColor === null) {
      InterfaceUtils.emitToast(
        'warn',
        'Failed to enter paint mode. Please try again in a few moments.'
      );
    } else {
      this.setState(
        {
          modelToColor,
          currentView: 'paint',
        },
        () => {
          this.resetCamera();
        }
      );
    }
  }

  exitPaintView() {
    this.setState({ currentView: 'place' }, () => {
      this.resetCamera();
    });
  }

  enterSupportView() {
    this.setState({
      currentView: 'support',
      showProjectSettingsModal: false,
      showCalibrationModal: false,
    });
  }

  exitSupportView() {
    this.setState({ currentView: 'place' });
  }

  handleViewChange(newView) {
    this.setState({ currentView: newView });
  }

  setUniformScale(enabled) {
    this.setState({ uniformScale: enabled });
  }

  showRenameModal() {
    if (
      !this.state.showProjectSettingsModal &&
      !this.state.showCalibrationModal
    ) {
      this.setState({ showRenameModal: true });
    }
  }

  hideRenameModal() {
    this.setState({ showRenameModal: false });
  }

  static isFileType(e) {
    if (e.dataTransfer.types) {
      for (let i = 0; i < e.dataTransfer.types.length; i++) {
        if (e.dataTransfer.types[i] === 'Files') {
          return true;
        }
      }
    }
    return false;
  }

  toggleProfilesPanelMobile() {
    const { showProfilesPanelMobile } = this.state;
    this.setState({ showProfilesPanelMobile: !showProfilesPanelMobile });
  }

  startFileDrag(e) {
    if (!Slicer.isFileType(e)) return;
    let uploadType = 'single';
    if (e.nativeEvent.clientX / window.innerWidth >= 0.5) {
      uploadType = 'multi';
    }
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      uploadType,
      dragStart: true,
    });
  }

  endFileDrag() {
    if (!this.state.dragStart) return;
    this.setState({
      uploadType: null,
      dragStart: false,
    });
  }

  renderHeader() {
    const { currentView } = this.state;
    if (currentView === 'paint') return null;
    return (
      <Header>
        {this.renderUploadButton()}
        {this.renderProfilesPanel()}
      </Header>
    );
  }

  renderProfilesPanel() {
    const { currentView, showProfilesPanelMobile } = this.state;
    if (currentView !== 'place') return null;
    const { projectId } = this.props.match.params;
    const project = this.props.projects[projectId];
    return (
      <ProfilesPanelWrapper showProfilesPanelMobile={showProfilesPanelMobile}>
        <ProfilesPanel
          printers={this.props.printers}
          models={this.props.models}
          project={project}
          printerId={project.printerId}
          styleId={project.styleId}
          deviceConfig={project.deviceConfig}
          onOpenProjectSettings={() => this.toggleProjectSettings()}
          onCloseMobile={() => this.toggleProfilesPanelMobile()}
          toggleCalibrationModal={() => this.toggleCalibrationModal()}
        />
      </ProfilesPanelWrapper>
    );
  }

  setCurrentTool(tool) {
    this.setState({
      currentTool: tool,
    });
  }

  setCurrentExtruderIndex(currentExtruderIndex) {
    this.setState({
      currentExtruderIndex,
    });
  }

  renderCurrentView() {
    const { currentView, currentTool, modelToColor, currentExtruderIndex } =
      this.state;
    const isModalShown =
      this.state.showUploadModal ||
      this.state.showProjectSettingsModal ||
      this.state.showCalibrationModal ||
      this.state.showSpliceTransitionModal;
    if (currentView === 'place') {
      return (
        <PlaceView
          currentTool={currentTool}
          isModalShown={isModalShown}
          uniformScale={this.state.uniformScale}
          setCurrentTool={this.setCurrentTool}
          onEnterPaint={this.enterPaintView}
          onEnterSupport={this.enterSupportView}
          changeView={this.handleViewChange}
          setUniformScale={this.setUniformScale}
          onToggleSpliceTransitionModal={this.toggleTransitionLengthModal}
          toggleUploadModal={() => this.toggleUploadModal()}
          showProfilesPanelMobile={this.state.showProfilesPanelMobile}
          toggleProfilesPanelMobile={() => {
            this.toggleProfilesPanelMobile();
          }}
          toggleProjectSettings={() => this.toggleProjectSettings()}
          currentExtruderIndex={currentExtruderIndex}
          setCurrentExtruderIndex={(val) => this.setCurrentExtruderIndex(val)}
        />
      );
    }
    if (currentView === 'paint') {
      return (
        <PaintView
          currentTool={currentTool}
          currentModel={modelToColor}
          setCurrentTool={this.setCurrentTool}
          onExitView={this.exitPaintView}
        />
      );
    }
    if (currentView === 'slice') {
      return <SliceView changeView={this.handleViewChange} />;
    }
    if (currentView === 'support') {
      return (
        <SupportView
          currentTool={currentTool}
          setCurrentTool={this.setCurrentTool}
          onExitView={this.exitSupportView}
        />
      );
    }
    return null;
  }

  renderUploadButton() {
    if (this.state.currentView !== 'place') return null;
    const isDisabled = this.props.loadProjectPending;
    return (
      <UploadButtonWrapper>
        <Button
          primary
          onClick={() => this.toggleUploadModal()}
          disabled={isDisabled}
          icon={Icons.project.addModal}>
          Add models
        </Button>
      </UploadButtonWrapper>
    );
  }

  handleRenameProject(renameValue, project) {
    if (renameValue.trim() !== project.name) {
      this.props.updateProjectName(project.id, renameValue);
    }
    this.hideRenameModal();
  }

  renderRenameModal() {
    if (!this.state.showRenameModal) return null;

    const { match, projects } = this.props;
    const { projectId } = match.params;
    const project = projects[projectId];
    return (
      <RenameModal
        initialValue={project.name}
        title={'Rename Project'}
        onMarginClick={() => this.hideRenameModal()}
        onCancel={() => this.hideRenameModal()}
        onSave={(renameValue) => this.handleRenameProject(renameValue, project)}
      />
    );
  }

  renderProjectSettingsModal() {
    if (!this.state.showProjectSettingsModal) return null;
    return (
      <ProjectSettings
        onClickClose={() => this.toggleProjectSettings()}
        onToggleTransitionLengthModal={() => this.toggleTransitionLengthModal()}
        onEnterSupportView={() => this.enterSupportView()}
      />
    );
  }

  toggleTransitionLengthModal() {
    this.setState({
      showSpliceTransitionModal: !this.state.showSpliceTransitionModal,
    });
  }

  renderTransitionLengthModal() {
    if (!this.state.showSpliceTransitionModal) return null;
    const { projectId } = this.props.match.params;
    const project = this.props.projects[projectId];
    let variableTransitionLengths = {
      initialTransitionLength: project.style.transitionLength,
    };
    if (project.variableTransitionLengths) {
      ({ variableTransitionLengths } = project);
    }
    const { currentExtruderIndex, showProjectSettingsModal } = this.state;
    return (
      <SpliceTransitionModal
        currentExtruderIndex={currentExtruderIndex}
        project={project}
        onClickClose={() => this.toggleTransitionLengthModal()}
        driveColors={project.colors}
        variableTransitionLengths={variableTransitionLengths}
        defaultActiveTab={showProjectSettingsModal && 1}
      />
    );
  }

  renderInputRemapperModal() {
    const { remapperContext, printers } = this.props;
    if (!remapperContext) return null;

    const printer = printers[remapperContext.printerId];

    return (
      <InputRemapperModal
        remapperContext={remapperContext}
        projectInitialized={this.isDataInitialized()}
        printer={printer}
      />
    );
  }

  resetCamera(allowTween = true) {
    this.props.resetCamera(
      allowTween,
      this.state.currentView === 'paint',
      this.state.modelToColor
    );
  }

  renderLoadingSpinner() {
    const {
      match,
      sliceJobs,
      uploadPending,
      uploadProgress,
      dispatchSliceJobPending,
    } = this.props;

    const { projectLoadError } = this.state;
    const initSuccess = this.isDataInitialized() && !projectLoadError;

    const { projectId } = match.params;

    const isProjectSlicing =
      dispatchSliceJobPending ||
      ProjectUtils.isProjectSlicing(sliceJobs, projectId);
    const isUploadingModels = uploadPending && uploadProgress <= 100;

    if (initSuccess && (isProjectSlicing || isUploadingModels)) return null;

    return InterfaceUtils.getLoadingSpinner(this.props);
  }

  renderSliceProgress() {
    const { match, sliceJobs, dispatchSliceJobPending, cancelSliceJobPending } =
      this.props;
    const { projectId } = match.params;

    const isProjectSlicing =
      dispatchSliceJobPending ||
      ProjectUtils.isProjectSlicing(sliceJobs, projectId);
    const sliceProgress = ProjectUtils.getSliceJobProgress(
      sliceJobs,
      projectId
    );
    const sliceStatus = ProjectUtils.getSliceJobStatus(sliceJobs, projectId);

    if (!isProjectSlicing) return null;

    let label = 'Slicing';
    if (sliceProgress === null) {
      label = 'Determining progress';
    } else if (sliceStatus) {
      label = sliceStatus;
    }

    return (
      <ProgressOverlay
        coverHeaderBar={false}
        progress={sliceProgress}
        onCancel={() => this.props.cancelSlice(projectId)}
        isCancelDisabled={dispatchSliceJobPending || cancelSliceJobPending}
        label={label}
      />
    );
  }

  renderUploadProgress() {
    const { uploadPending, uploadProgress } = this.props;

    const isUploadingModels = uploadPending && uploadProgress <= 100;

    if (!isUploadingModels) return null;

    return <ProgressOverlay progress={uploadProgress} label='Uploading' />;
  }

  renderModelUploader() {
    return (
      <ModelUploader
        endFileDrag={this.endFileDrag}
        toggleUploadModal={this.toggleUploadModal}
      />
    );
  }

  toggleCalibrationModal() {
    this.setState({ showCalibrationModal: !this.state.showCalibrationModal });
  }

  renderCalibrationSettingsModal() {
    const { projectId } = this.props.match.params;
    const project = this.props.projects[projectId];
    const { showCalibrationModal } = this.state;
    if (!showCalibrationModal) return null;
    return (
      <PaletteCalibrationSettings
        toggleModal={() => this.toggleCalibrationModal()}
        printerId={project.printerId}
      />
    );
  }

  render() {
    const { projectLoadError } = this.state;
    const initSuccess = this.isDataInitialized() && !projectLoadError;
    return (
      <Div100vh style={div100vhStyles}>
        <Container
          onDragEnter={this.startFileDrag}
          onMouseOut={this.endFileDrag}>
          <SlicerContext.Provider value={this.state}>
            {this.renderLoadingSpinner()}
            {this.renderInputRemapperModal()}
            {initSuccess && this.renderCalibrationSettingsModal()}
            {initSuccess && this.renderRenameModal()}
            {initSuccess && this.renderModelUploader()}
            {initSuccess && this.renderSliceProgress()}
            {initSuccess && this.renderUploadProgress()}
            {initSuccess && this.renderHeader()}
            {initSuccess && this.renderCurrentView()}
            {initSuccess && this.renderProjectSettingsModal()}
            {initSuccess && this.renderTransitionLengthModal()}
            <ViewOptions resetCamera={() => this.resetCamera()} />
            <Visualizer ref={this.canvasRef} />
          </SlicerContext.Provider>
        </Container>
      </Div100vh>
    );
  }
}

export default Slicer;

import styled, { css } from 'styled-components';
import { Breakpoints } from '../../themes';

export const DisplayContainer = styled.div`
  ${(props) => !props.show && 'display: none;'};

  @media (max-width: ${Breakpoints.largeWidth}) {
    display: block;
  }
`;

export const Container = styled.div`
  position: relative;
`;

export const ActionStyledButton = styled.button`
  cursor: pointer;
  color: ${(props) => props.theme.colors.textPrimary};
  background-color: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: ${(props) => (props.noRadius ? '0' : '0.25rem')};
  display: flex;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  padding: 0;
  align-items: center;
  justify-content: center;

  ${(props) =>
    !props.disabled &&
    !props.primary &&
    !props.minimal &&
    !props.warning &&
    css`
      &:hover {
        border: 1px solid ${props.theme.colors.grey3};
        background-color: ${props.theme.colors.elevatedBackgroundSecondary};
      }
    `}

  ${(props) =>
    props.clean &&
    css`
      border-color: transparent;
      background: transparent;
    `}

  ${(props) =>
    props.minimal &&
    css`
      border-color: transparent;
      background: transparent;
    `}

  ${(props) =>
    props.primary &&
    !props.warning &&
    css`
      background-color: ${props.theme.colors.greenDefault};
      border: none;
    `};

  ${(props) =>
    props.warning &&
    !props.primary &&
    css`
      background-color: ${props.theme.colors.redDark};
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
    `};
`;

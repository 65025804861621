import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

export default function* createFolder(action) {
  try {
    const { payload: body } = action;

    const response = yield call(API, {
      method: methods.PUT,
      path: 'projects/directory',
      body,
    });
    if (response === null) return;

    yield put(actions.createFolderSuccess(response.project));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

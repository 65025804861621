import types from './types';

const actions = {
  initSceneRequest: (canvas) => ({
    type: types.INIT_SCENE_REQUEST,
    payload: { canvas },
  }),
  initSceneSuccess: (
    scene,
    camera,
    renderer,
    controls,
    ambientLight,
    pointLight,
    gizmo
  ) => ({
    type: types.INIT_SCENE_SUCCESS,
    payload: {
      scene,
      camera,
      renderer,
      controls,
      ambientLight,
      pointLight,
      gizmo,
    },
  }),
  initSceneFailure: (error) => ({
    type: types.INIT_SCENE_FAILURE,
    payload: { error },
  }),
  destroySceneRequest: () => ({
    type: types.DESTROY_SCENE_REQUEST,
    payload: {},
  }),
  destroySceneSuccess: () => ({
    type: types.DESTROY_SCENE_SUCCESS,
    payload: {},
  }),
  destroySceneFailure: (error) => ({
    type: types.DESTROY_SCENE_FAILURE,
    payload: { error },
  }),
  enableControls: () => ({
    type: types.ENABLE_CONTROLS,
    payload: {},
  }),
  disableControls: () => ({
    type: types.DISABLE_CONTROLS,
    payload: {},
  }),
  resetCamera: (allowTween = true, paintMode = false, modelToColor = null) => ({
    type: types.RESET_CAMERA,
    payload: { allowTween, paintMode, modelToColor },
  }),
  tweenCamera: (position, zoom, lookAt) => ({
    type: types.TWEEN_CAMERA,
    payload: { position, zoom, lookAt },
  }),
  replaceCameraRequest: (
    orthographic,
    paintMode = false,
    modelToColor = null
  ) => ({
    type: types.REPLACE_CAMERA_REQUEST,
    payload: { orthographic, paintMode, modelToColor },
  }),
  replaceCameraSuccess: (camera) => ({
    type: types.REPLACE_CAMERA_SUCCESS,
    payload: { camera },
  }),
  replaceCameraFailure: (error) => ({
    type: types.REPLACE_CAMERA_FAILURE,
    payload: { error },
  }),
  updatePrintBedRequest: (printer, render = true) => ({
    type: types.UPDATE_PRINT_BED_REQUEST,
    payload: { printer, render },
  }),
  updatePrintBedSuccess: (
    printerHeight,
    printBed,
    printBedBoundingBox,
    printBedGrid,
    originAxes
  ) => ({
    type: types.UPDATE_PRINT_BED_SUCCESS,
    payload: {
      printerHeight,
      printBed,
      printBedBoundingBox,
      printBedGrid,
      originAxes,
    },
  }),
  updatePrintBedFailure: (error) => ({
    type: types.UPDATE_PRINT_BED_FAILURE,
    payload: { error },
  }),
  showPrintBed: () => ({
    type: types.SHOW_PRINT_BED,
    payload: {},
  }),
  hidePrintBed: () => ({
    type: types.HIDE_PRINT_BED,
    payload: {},
  }),
  addModelsToScene: (
    resetCamera = false,
    centerModels = false,
    models = null
  ) => ({
    type: types.ADD_MODELS_TO_SCENE,
    payload: { resetCamera, centerModels, models },
  }),
  removeModelsFromScene: (models = null) => ({
    type: types.REMOVE_MODELS_FROM_SCENE,
    payload: { models },
  }),
  addModelToScene: (model) => ({
    type: types.ADD_MODEL_TO_SCENE,
    payload: { model },
  }),
  removeModelFromScene: (model) => ({
    type: types.REMOVE_MODEL_FROM_SCENE,
    payload: { model },
  }),
  addTowerToScene: (tower) => ({
    type: types.ADD_TOWER_TO_SCENE,
    payload: { tower },
  }),
  removeTowerFromScene: () => ({
    type: types.REMOVE_TOWER_FROM_SCENE,
    payload: {},
  }),
  highlightModels: () => ({
    type: types.HIGHLIGHT_MODELS,
    payload: {},
  }),
  removeModelHighlights: () => ({
    type: types.REMOVE_MODEL_HIGHLIGHTS,
    payload: {},
  }),
  hoverModels: (hoveredModels = []) => ({
    type: types.HOVER_MODELS,
    payload: { hoveredModels },
  }),
  attachGizmoRequest: (selectedMeshes, gizmoMode, onMouseUp) => ({
    type: types.ATTACH_GIZMO_REQUEST,
    payload: { selectedMeshes, gizmoMode, onMouseUp },
  }),
  attachGizmoSuccess: (gizmoCenter) => ({
    type: types.ATTACH_GIZMO_SUCCESS,
    payload: { gizmoCenter },
  }),
  updateGizmoRequest: () => ({
    type: types.UPDATE_GIZMO_REQUEST,
    payload: {},
  }),
  updateGizmoSuccess: (gizmoCenter) => ({
    type: types.UPDATE_GIZMO_SUCCESS,
    payload: { gizmoCenter },
  }),
  attachGizmoFailure: (error) => ({
    type: types.ATTACH_GIZMO_FAILURE,
    payload: { error },
  }),
  detachGizmoRequest: () => ({
    type: types.DETACH_GIZMO_REQUEST,
    payload: {},
  }),
  detachGizmoSuccess: () => ({
    type: types.DETACH_GIZMO_SUCCESS,
    payload: {},
  }),
  detachGizmoFailure: (error) => ({
    type: types.DETACH_GIZMO_FAILURE,
    payload: { error },
  }),
  addToolpath: (toolpath) => ({
    type: types.ADD_TOOLPATH,
    payload: { toolpath },
  }),
  updateToolpath: (toolpath, prevToolpath) => ({
    type: types.UPDATE_TOOLPATH,
    payload: { toolpath, prevToolpath },
  }),
  removeToolpath: (toolpath) => ({
    type: types.REMOVE_TOOLPATH,
    payload: { toolpath },
  }),
  addBrush: (brush, dot) => ({
    type: types.ADD_BRUSH,
    payload: { brush, dot },
  }),
  removeBrush: (brush, dot) => ({
    type: types.REMOVE_BRUSH,
    payload: { brush, dot },
  }),
  addBoundariesRequest: (boundaries) => ({
    type: types.ADD_BOUNDARIES_REQUEST,
    payload: { boundaries },
  }),
  addBoundariesSuccess: (boundaries) => ({
    type: types.ADD_BOUNDARIES_SUCCESS,
    payload: { boundaries },
  }),
  addBoundariesFailure: (error) => ({
    type: types.ADD_BOUNDARIES_FAILURE,
    payload: { error },
  }),
  removeBoundariesRequest: () => ({
    type: types.REMOVE_BOUNDARIES_REQUEST,
    payload: {},
  }),
  removeBoundariesSuccess: () => ({
    type: types.REMOVE_BOUNDARIES_SUCCESS,
    payload: {},
  }),
  removeBoundariesFailure: (error) => ({
    type: types.REMOVE_BOUNDARIES_FAILURE,
    payload: { error },
  }),
  toggleMovingSwatch: () => ({
    type: types.TOGGLE_MOVING_SWATCH,
    payload: {},
  }),
};

export default actions;

import styled from 'styled-components';

const HEIGHT = (9 / 16) * 100;

export const Container = styled.div`
  width: 100%;
  height: min-content;
  background: ${(props) => props.theme.colors.blackDefault};
  margin: 1rem;
  border-radius: 4px;
  overflow: hidden;
`;

export const Letterbox = styled.div`
  width: 100%;
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
`;

export const AspectRatioContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${HEIGHT}%;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  background: ${(props) => props.theme.colors.blackDefault};
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
`;

export const ResumeButtonWrapper = styled.div`
  margin-top: 1rem;
`;

export const StyledVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;

import { fieldTypes, fieldSteps } from '../../../constants';
import Routes from '../../../router/routes';

const { EXTRUSION_WIDTH_STEP, RETRACT_STEP, SPEED_STEP } = fieldSteps;

export const materialStyleFieldNames = [
  ['useExtrusionMultiplier', 'extrusionMultiplier'],
  ['useRetractLength', 'retractLength'],
  ['useRetractSpeed', 'retractSpeed'],
  ['useWipeLength', 'wipeLength'],
  ['useMaxPrintSpeed', 'maxPrintSpeed'],
  ['usePrintTemperature', 'printTemperature'],
  ['useFirstLayerPrintTemperature', 'firstLayerPrintTemperature'],
  ['useBedTemperature', 'bedTemperature'],
  ['useUseFan', 'useFan'],
  ['useEnableFanAtLayer', 'enableFanAtLayer'],
  ['useFanSpeed', 'fanSpeed'],
  ['usePerimeterFanSpeed', 'perimeterFanSpeed'],
  ['useTowerSpeed', 'towerSpeed'],
  ['useMaxBridgingSpeed', 'maxBridgingSpeed'],
];

const Fields = {
  baseFields: [
    {
      name: 'name',
      value: (styles) => styles.name,
      variants: [
        {
          type: fieldTypes.text,
        },
      ],
    },
    {
      name: 'diameter',
      value: (styles) => styles.diameter,
      variants: [
        {
          type: fieldTypes.number,
          units: 'mm',
          min: 0,
          gte: false,
          step: 0.01,
        },
      ],
    },
    {
      name: 'type',
      value: (styles) => styles.type,
      variants: [
        {
          type: fieldTypes.dropdown,
          options: [
            {
              label: 'Rigid',
              items: [
                { label: 'PLA', value: 'PLA' },
                { label: 'ABS', value: 'ABS' },
                { label: 'Nylon', value: 'Nylon' },
                { label: 'PET', value: 'PET' },
                { label: 'PETG', value: 'PETG' },
              ],
            },
            {
              label: 'Flexible',
              items: [
                { label: 'TPU', value: 'TPU' },
                { label: 'TPE', value: 'TPE' },
              ],
            },
            {
              label: 'Soluble',
              items: [
                { label: 'PVA', value: 'PVA' },
                { label: 'PVB', value: 'PVB' },
                { label: 'HIPS', value: 'HIPS' },
              ],
            },
            {
              label: 'Other',
              items: [{ label: 'Other', value: 'Other' }],
            },
          ],
        },
      ],
    },
  ],
  formGeneralFields: [
    {
      title: 'Material name',
      description: 'Give the material a name.',
      content: 'material-name-input',
    },
    {
      title: 'Diameter',
      description: 'The diameter of the filament. A common diameter is 1.75mm.',
      content: 'diameter-input',
    },
    {
      title: 'Filament type',
      description: 'Choose the type of the filament being used.',
      content: 'filament-type-dropdown',
    },
  ],
  overrideFields: [
    {
      name: 'extrusionMultiplier',
      label: 'Extrusion multiplier',
      value: (styles) => styles.extrusionMultiplier,
      variants: [
        {
          units: '%',
          type: fieldTypes.number,
          min: 0,
          gte: false,
          step: 1,
        },
      ],
    },
    {
      name: 'retractLength',
      label: 'Retraction distance',
      value: (styles) => styles.retractLength,
      variants: [
        {
          units: 'mm',
          type: fieldTypes.number,
          min: 0,
          lte: false,
          step: RETRACT_STEP,
        },
      ],
    },
    {
      name: 'retractSpeed',
      label: 'Retraction speed',
      value: (styles) => styles.retractSpeed,
      variants: [
        {
          units: 'mm/s',
          type: fieldTypes.number,
          min: 0,
          gte: false,
          step: SPEED_STEP,
        },
      ],
    },
    {
      name: 'wipeLength',
      label: 'Wipe distance',
      value: (styles) => styles.wipeLength,
      variants: [
        {
          units: 'mm',
          type: fieldTypes.number,
          min: 0,
          step: EXTRUSION_WIDTH_STEP,
        },
      ],
    },
    {
      name: 'maxPrintSpeed',
      label: 'Maximum print speed',
      value: (styles) => styles.maxPrintSpeed,
      variants: [
        {
          units: 'mm/s',
          type: fieldTypes.number,
          min: 0,
          gte: false,
          step: SPEED_STEP,
          toBaseUnits: (value) => value,
          fromBaseUnits: (value) => value,
        },
        {
          units: 'mm3/s',
          type: fieldTypes.number,
          min: 0,
          gte: false,
          step: SPEED_STEP,
          toBaseUnits: (value) => value,
          fromBaseUnits: (value) => value,
        },
      ],
    },
    {
      name: 'printTemperature',
      label: 'Print temperature',
      value: (styles) => styles.printTemperature,
      variants: [
        {
          units: 'C',
          type: fieldTypes.number,
          min: 0,
          gte: false,
          step: 1,
        },
      ],
    },
    {
      name: 'firstLayerPrintTemperature',
      label: 'First layer temperature',
      value: (styles) => styles.firstLayerPrintTemperature,
      variants: [
        {
          units: 'C',
          type: fieldTypes.number,
          min: 0,
          gte: false,
          step: 1,
          toBaseUnits: (value) => value,
          fromBaseUnits: (value) => value,
        },
        {
          type: fieldTypes.auto,
          toBaseUnits: () => 200,
          fromBaseUnits: () => 'auto',
        },
      ],
    },
    {
      name: 'bedTemperature',
      label: 'Bed temperature',
      value: (styles) => styles.bedTemperature,
      variants: [
        {
          units: 'C',
          type: fieldTypes.number,
          min: 0,
          gte: false,
          step: 1,
        },
      ],
    },
    {
      name: 'useFan',
      label: 'Use cooling fan',
      value: (styles) => styles.useFan,
      variants: [
        {
          type: fieldTypes.checkbox,
        },
      ],
    },
    {
      name: 'enableFanAtLayer',
      label: 'Start fan at layer',
      value: (styles) => styles.enableFanAtLayer,
      variants: [
        {
          type: fieldTypes.number,
          min: 0,
          step: 1,
        },
      ],
    },
    {
      name: 'fanSpeed',
      label: 'General fan speed',
      value: (styles) => styles.fanSpeed,
      variants: [
        {
          units: '%',
          type: fieldTypes.number,
          min: 0,
          max: 100,
          step: 1,
        },
      ],
    },
    {
      name: 'perimeterFanSpeed',
      label: 'Perimeter fan speed',
      value: (styles) => styles.perimeterFanSpeed,
      variants: [
        {
          units: '%',
          type: fieldTypes.number,
          min: 0,
          max: 100,
          step: 1,
          toBaseUnits: (value) => value,
          fromBaseUnits: (value) => value,
        },
        {
          type: fieldTypes.auto,
          toBaseUnits: () => 100,
          fromBaseUnits: () => 'auto',
        },
      ],
    },
    {
      name: 'towerSpeed',
      label: 'Tower print speed',
      value: (styles) => styles.towerSpeed,
      variants: [
        {
          units: 'mm/s',
          type: fieldTypes.number,
          min: 0,
          gte: false,
          step: SPEED_STEP,
          toBaseUnits: (value) => value,
          fromBaseUnits: (value) => value,
        },
      ],
    },
    {
      name: 'maxBridgingSpeed',
      label: 'Maximum bridging speed',
      value: (styles) => styles.maxBridgingSpeed,
      variants: [
        {
          units: 'mm/s',
          type: fieldTypes.number,
          min: 0,
          gte: false,
          step: SPEED_STEP,
          toBaseUnits: (value) => value,
          fromBaseUnits: (value) => value,
        },
      ],
    },
  ],
  sequenceFields: {
    label: '',
    fields: [
      {
        label: 'Custom material change sequence',
        name: 'materialChangeSequence',
        type: fieldTypes.codearea,
        modes: ['gcode', 'printerscript'],
        helpLink: (mode) =>
          `${
            mode === 'gcode'
              ? Routes.toPrinterScriptLegacy()
              : Routes.toPrinterScriptVariables()
          }#material-change`,
      },
    ],
  },
  sidebarFields: [
    {
      text: 'General',
    },
    {
      text: 'Style overrides',
    },
    {
      text: 'Sequences',
    },
  ],
};

export default Fields;

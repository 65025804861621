import { connect } from 'react-redux';
import _ from 'lodash';

import { actions } from '../../../reducers/slicer/slicer';
import ObjectBrowser from './objectBrowser.jsx';

const mapStateToProps = (state) => ({
  scene: state.slicer.scene,
  models: state.slicer.models,
  config: state.slicer.config,
  isRepackPending: state.slicer.status.repackPending,
  isRepackSuccess: state.slicer.status.repackSuccess,
  uploadStlSuccess: state.slicer.status.uploadStlSuccess,
  hasTower:
    state.slicer.transitionTower && !_.isEmpty(state.slicer.transitionTower),
  isTowerActive:
    state.slicer.transitionTower && state.slicer.transitionTower.active,
  isTowerVisible:
    state.slicer.transitionTower && state.slicer.transitionTower.visible,
});

const mapDispatchToProps = (dispatch) => ({
  deleteModels: (modelIds) => dispatch(actions.deleteModelsRequest(modelIds)),
  duplicateModel: (modelIds, count) =>
    dispatch(actions.duplicateModelRequest(modelIds, count)),
  toggleTowerActiveState: () => dispatch(actions.toggleTowerActiveState()),
  toggleModelActiveState: (modelId) =>
    dispatch(actions.toggleModelActiveState(modelId)),
  setModelVisibility: (modelId, visible) =>
    dispatch(actions.setModelVisibilityRequest(modelId, visible)),
  updateModelName: (modelId, newModelName) =>
    dispatch(actions.updateModelNameRequest(modelId, newModelName)),
  updateModelGroupName: (oldName, newName, models) =>
    dispatch(actions.updateModelGroupNameRequest(oldName, newName, models)),
  groupModels: (models, groupName) =>
    dispatch(actions.groupModelsRequest(models, groupName)),
  ungroupModels: (models, groupNames) =>
    dispatch(actions.ungroupModelsRequest(models, groupNames)),
  alignAndGroupModels: (models, groupName, newCenter) =>
    dispatch(actions.alignAndGroupModelsRequest(models, groupName, newCenter)),
  activateModels: (modelIds) => dispatch(actions.activateModels(modelIds)),
  deactivateAllModels: () => dispatch(actions.deactivateAllModels()),
  activateTower: () => dispatch(actions.activateTower()),
  deactivateTower: () => dispatch(actions.deactivateTower()),
  toggleTowerVisibility: () => dispatch(actions.toggleTowerVisibility()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ObjectBrowser);

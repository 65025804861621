/* eslint-disable max-len */
import { takeLatest, takeEvery, throttle, all } from 'redux-saga/effects';

import Constants from '../constants';
import { types } from '../../reducers/slicer/slicer';
import { types as authTypes } from '../../reducers/auth/auth';

// import each saga
import loadProjects from './project/loadProjects';
import loadProject from './project/loadProject';
import centerProject from './project/centerProject';
import repack from './project/repack';
import createProject from './project/createProject';
import createCalibrationProject from './project/createCalibrationProject';
import deleteProjects from './project/deleteProjects';
import uploadStl from './models/uploadStl';
import deleteModels from './models/deleteModels';
import duplicateModel from './models/duplicateModel';
import updateModelTransforms from './models/updateModelTransforms';
import updateStampTransforms from './models/updateStampTransforms';
import updateModelName from './models/updateModelName';
import updateModelGroupName from './models/updateModelGroupName';
import groupModels from './models/groupModels';
import ungroupModels from './models/ungroupModels';
import alignAndGroupModels from './models/alignAndGroupModels';
import setModelVisibility from './models/setModelVisibility';
import useFacesWorker from './models/useFacesWorker';
import saveModelPaintData from './models/saveModelPaintData';
import deleteModelPaintData from './models/deleteModelPaintData';
import saveModelCustomSupports from './models/saveModelCustomSupports';
import updateProjectName from './project/updateProjectName';
import updateProjectSettings from './project/updateProjectSettings';
import dispatchSliceJob, { cancelSliceJob } from './project/slice';
import getSliceJobs from './project/getSliceJobs';
import getSlicePreviewData from './project/getSlicePreviewData';
import download from './project/download';
import loadProjectStats from './project/loadProjectStats';
import loadToolpathPtp, {
  cancelLoadToolpathPtp,
} from './project/loadToolpathPtp';
import createToolpathMeshes, {
  cancelCreateToolpathMeshes,
} from './project/createToolpathMeshes';
import updateProjectStyle from './project/updateProjectStyle';
import getMaterials from './materials/getMaterials';
import createMaterial from './materials/createMaterial';
import deleteMaterials from './materials/deleteMaterials';
import editMaterial from './materials/editMaterial';
import changeMaterial from './materials/changeMaterial';
import updateProjectColors from './materials/updateProjectColors';
import updateVariableTransitionLengths from './project/updateVariableTransitionLengths';
import applyMaterial from './materials/applyMaterial';
import getSpliceSettings from './materials/getSpliceSettings';
import createSpliceSettings from './materials/createSpliceSettings';
import updateTowerPositionOrSize from './tower/updateTowerPositionOrSize';
import updateTowerFromServer from './tower/updateTowerFromServer';
import repositionTower from './tower/repositionTower';
import updateProjectThumbnail from './project/updateProjectThumbnail';
import getProjectThumbnail, {
  clearThumbnailQueue,
} from './project/getProjectThumbnail';
import deleteProjectThumbnail from './project/deleteProjectThumbnail';
import duplicateProject from './project/duplicateProject';
import createFolder from './project/createFolder';
import updateProjectsParent from './project/updateProjectsParent';
import createSharedProject from './project/createSharedProject';
import deleteSharedProject from './project/deleteSharedProject';
import updateSharedProject from './project/updateSharedProject';
import getSharedProject from './project/getSharedProject';
import importSharedProject from './project/importSharedProject';
import getSharedProjectThumbnail from './project/getSharedProjectThumbnail';
import getSharedProjectModels from './project/getSharedProjectModels';
import setToolpathOptions from './project/setToolpathOptions';
import getPolarPrinters from './project/getPolarPrinters';
import dispatchPrintToPolar from './project/dispatchPrintToPolar';
import dispatchPrintToDevice from './project/dispatchPrintToDevice';
import remapProjectInputs from './project/remapProjectInputs';

export default function* slicerSaga() {
  yield all([
    takeLatest(types.LOAD_PROJECTS_REQUEST, loadProjects),
    takeLatest(types.LOAD_PROJECT_REQUEST, loadProject),
    takeLatest(types.CENTER_PROJECT_REQUEST, centerProject),
    throttle(Constants.THROTTLE_DEFAULT, types.REPACK_REQUEST, repack),
    takeLatest(types.CREATE_PROJECT_REQUEST, createProject),
    takeLatest(
      types.CREATE_CALIBRATION_PROJECT_REQUEST,
      createCalibrationProject
    ),
    takeLatest(types.DELETE_PROJECTS_REQUEST, deleteProjects),
    takeLatest(types.UPLOAD_STL_REQUEST, uploadStl),
    takeLatest(types.DELETE_MODELS_REQUEST, deleteModels),
    takeLatest(types.DUPLICATE_MODEL_REQUEST, duplicateModel),
    takeEvery(types.UPDATE_MODEL_TRANSFORMS_REQUEST, updateModelTransforms),
    takeEvery(types.UPDATE_STAMP_TRANSFORMS_REQUEST, updateStampTransforms),
    takeLatest(types.UPDATE_MODEL_NAME_REQUEST, updateModelName),
    takeLatest(types.UPDATE_MODEL_GROUP_NAME_REQUEST, updateModelGroupName),
    takeEvery(types.GROUP_MODELS_REQUEST, groupModels),
    takeEvery(types.UNGROUP_MODELS_REQUEST, ungroupModels),
    takeEvery(types.ALIGN_AND_GROUP_MODELS_REQUEST, alignAndGroupModels),
    takeEvery(types.SET_MODEL_VISIBILITY_REQUEST, setModelVisibility),
    takeLatest(types.UPDATE_PROJECT_NAME_REQUEST, updateProjectName),
    takeLatest(types.UPDATE_PROJECT_SETTINGS_REQUEST, updateProjectSettings),
    takeEvery(types.DISPATCH_SLICE_JOB_REQUEST, dispatchSliceJob),
    takeEvery(types.CANCEL_SLICE_JOB_REQUEST, cancelSliceJob),
    takeLatest(types.GET_SLICE_JOBS_REQUEST, getSliceJobs),
    takeLatest(types.GET_SLICE_PREVIEW_DATA, getSlicePreviewData),
    takeLatest(types.DOWNLOAD_REQUEST, download),
    takeLatest(types.LOAD_PROJECT_STATS_REQUEST, loadProjectStats),
    takeLatest(types.LOAD_TOOLPATH_PTP_REQUEST, loadToolpathPtp),
    takeLatest(types.LOAD_TOOLPATH_PTP_CANCEL, cancelLoadToolpathPtp),
    takeLatest(types.CREATE_TOOLPATH_MESHES_REQUEST, createToolpathMeshes),
    takeLatest(types.CREATE_TOOLPATH_MESHES_CANCEL, cancelCreateToolpathMeshes),
    takeLatest(types.SET_TOOLPATH_VIEW_TYPE, setToolpathOptions),
    takeLatest(types.SET_TOOLPATH_SHOW_TRAVEL, setToolpathOptions),
    takeLatest(types.SET_TOOLPATH_SHOW_RETRACTS, setToolpathOptions),
    takeLatest(types.SET_TOOLPATH_SHOW_RESTARTS, setToolpathOptions),
    takeEvery(types.UPDATE_PROJECT_STYLE_REQUEST, updateProjectStyle),
    takeLatest(types.GET_MATERIALS_REQUEST, getMaterials),
    takeLatest(types.CREATE_MATERIAL_REQUEST, createMaterial),
    takeLatest(types.DELETE_MATERIALS_REQUEST, deleteMaterials),
    takeLatest(types.EDIT_MATERIAL_REQUEST, editMaterial),
    takeLatest(types.CHANGE_MATERIAL_REQUEST, changeMaterial),
    throttle(
      Constants.THROTTLE_DEFAULT,
      types.UPDATE_PROJECT_COLORS_REQUEST,
      updateProjectColors
    ),
    takeLatest(types.APPLY_MATERIAL_REQUEST, applyMaterial),
    takeLatest(
      types.UPDATE_VARIABLE_TRANSITION_LENGTHS_REQUEST,
      updateVariableTransitionLengths
    ),
    takeEvery(types.GET_SPLICE_SETTINGS_REQUEST, getSpliceSettings),
    takeEvery(types.CREATE_SPLICE_SETTINGS_REQUEST, createSpliceSettings),
    takeLatest(
      types.UPDATE_TOWER_POSITION_OR_SIZE_REQUEST,
      updateTowerPositionOrSize
    ),
    takeLatest(types.UPDATE_TOWER_FROM_SERVER, updateTowerFromServer),
    takeLatest(types.REPOSITION_TOWER, repositionTower),
    takeEvery(types.UPDATE_PROJECT_THUMBNAIL_REQUEST, updateProjectThumbnail),
    takeEvery(types.GET_PROJECT_THUMBNAIL_REQUEST, getProjectThumbnail),
    takeEvery(authTypes.LOGOUT, clearThumbnailQueue),
    takeLatest(types.DELETE_PROJECT_THUMBNAIL_REQUEST, deleteProjectThumbnail),
    takeEvery(types.DUPLICATE_PROJECT_REQUEST, duplicateProject),
    takeLatest(types.CREATE_FOLDER_REQUEST, createFolder),
    takeLatest(types.UPDATE_PROJECTS_PARENT_REQUEST, updateProjectsParent),
    takeLatest(types.CANCEL_WORKER, useFacesWorker),
    takeLatest(types.GENERATE_ATLAS_REQUEST, useFacesWorker),
    takeLatest(types.BUILD_FACES_REQUEST, useFacesWorker),
    takeLatest(types.AUTO_SEGMENT_REQUEST, useFacesWorker),
    takeLatest(types.RESET_REGIONS_REQUEST, useFacesWorker),
    takeLatest(types.COMPOSE_COLORS_RLE_REQUEST, useFacesWorker),
    takeLatest(types.SAVE_MODEL_PAINT_DATA_REQUEST, saveModelPaintData),
    takeLatest(types.DELETE_MODEL_PAINT_DATA_REQUEST, deleteModelPaintData),
    takeLatest(types.CREATE_SHARED_PROJECT_REQUEST, createSharedProject),
    takeLatest(types.DELETE_SHARED_PROJECT_REQUEST, deleteSharedProject),
    takeLatest(types.UPDATE_SHARED_PROJECT_REQUEST, updateSharedProject),
    takeLatest(types.GET_SHARED_PROJECT_REQUEST, getSharedProject),
    takeLatest(types.IMPORT_SHARED_PROJECT_REQUEST, importSharedProject),
    takeLatest(
      types.GET_SHARED_PROJECT_THUMBNAIL_REQUEST,
      getSharedProjectThumbnail
    ),
    takeLatest(types.GET_SHARED_PROJECT_MODELS_REQUEST, getSharedProjectModels),
    takeLatest(
      types.SAVE_MODEL_CUSTOM_SUPPORTS_REQUEST,
      saveModelCustomSupports
    ),
    takeLatest(types.GET_POLAR_PRINTERS_REQUEST, getPolarPrinters),
    takeLatest(types.DISPATCH_PRINT_TO_POLAR_REQUEST, dispatchPrintToPolar),
    takeLatest(types.DISPATCH_PRINT_TO_DEVICE_REQUEST, dispatchPrintToDevice),
    takeLatest(types.REMAP_PROJECT_INPUTS_REQUEST, remapProjectInputs),
  ]);
}

import 'regenerator-runtime/runtime';
import _ from 'lodash';
import { call, put, select, all } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';
import { MaterialUtils } from '../../../utils';

function* createSpliceSettingCall(pair, settings) {
  const { ingoingId, outgoingId } = pair;

  const response = yield call(API, {
    method: methods.POST,
    path: `splice-settings/${ingoingId}/${outgoingId}`,
    body: settings,
  });
  if (response === null) return null;
  return response.spliceSettings;
}

const combineSpliceSettingsMap = (existingSettings, newSettings) => {
  const newSettingsMap = MaterialUtils.mapSpliceSettings(newSettings);
  return {
    ...existingSettings,
    ...newSettingsMap,
  };
};

export default function* createSpliceSettings(action) {
  const { spliceSettings: existingSettings, currentProject } = yield select(
    getSlicerState
  );
  const { spliceSettingsList: changedSettings } = action.payload;
  try {
    const newSettings = yield all(
      _.map(changedSettings, (singleIdPairSettings) => {
        const { ingoingId, outgoingId, settings } = singleIdPairSettings;
        const pair = {
          ingoingId,
          outgoingId,
        };
        return call(createSpliceSettingCall, pair, settings);
      })
    );
    if (_.some(newSettings, (setting) => setting === null)) return;

    const spliceSettingsMap = combineSpliceSettingsMap(
      existingSettings,
      newSettings
    );
    yield put(actions.createSpliceSettingsSuccess(spliceSettingsMap));
    if (currentProject) yield put(actions.invalidateSlice(currentProject));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

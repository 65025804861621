import styled, { css } from 'styled-components';
import { Breakpoints } from '../../themes';

export const Sequences = styled.div`
  display: grid;
  grid-gap: 1rem;
`;

export const Sequence = styled.div`
  position: relative;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  min-height: 1.875rem;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    display: flex;
    flex-direction: column;
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CodeareaWrapper = styled.div`
  margin-top: 0.5em;
`;

export const ModesList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.8em;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    margin-top: 0.5rem;
  }
`;

export const ModeLabel = styled.li`
  display: flex;
  algin-items: center;
  margin-right: 0.375rem;
  padding: 0.3125rem 0.75rem;
  user-select: none;
  cursor: pointer;
  ${(props) =>
    (props.disabled || props.active) &&
    css`
      pointer-events: none;
    `};
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.125rem;
  color: ${(props) =>
    props.active
      ? props.theme.colors.whiteDefault
      : props.theme.colors.textPrimary};
  border: ${(props) => (props.active ? 'none' : 'solid')} 1px
    ${(props) => props.theme.colors.grey3};
  background-color: ${(props) =>
    props.active
      ? props.theme.colors.greenDefault
      : props.theme.colors.elevatedBackgroundPrimary};
  border-radius: 0.2em;
`;

export const ModeHelp = styled.li`
  margin: 0;
  padding: 0.3125rem;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
`;

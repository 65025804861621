import styled, { css } from 'styled-components';

import { Breakpoints } from '../../../themes';

export const SelectionPanels = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  color: ${(props) => props.theme.colors.textPrimary};

  @media (max-width: ${Breakpoints.smallWidth}) {
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

const panelStyles = css`
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

export const MakersPanel = styled.div`
  ${panelStyles}
`;

export const ModelsPanel = styled.div`
  ${panelStyles}
`;

export const PanelList = styled.ul`
  flex: 1;
  overflow-y: auto;
  margin: 0.2rem 0;
  min-height: 13rem;
  border: 1px solid ${(props) => props.theme.colors.grey4};
  border-radius: 0.2rem;
  padding: 0.2rem;
  background: ${(props) =>
    props.isDisabled
      ? props.theme.colors.elevatedBackgroundTertiary
      : props.theme.colors.elevatedBackgroundPrimary};
  opacity: ${(props) => (props.isDisabled ? 0.8 : 1)};
  transition: all 0.2s;
  position: relative;

  @media (max-width: ${Breakpoints.smallWidth}) {
    min-height: 10rem;
  }
`;

const selectedStyle = css`
  color: ${(props) => props.theme.colors.whiteDefault};
  background: ${(props) => props.theme.colors.greenDefault};
  &:hover {
    background: ${(props) => props.theme.colors.greenDefault};
  }
`;

export const PanelListItem = styled.li`
  list-style: none;
  padding: 0.2rem 0.4rem;
  cursor: pointer;
  border-radius: 0.2rem;
  transition: all 0.2s;
  transition-property: background, color;

  &:hover {
    background: ${(props) => props.theme.colors.elevatedBackgroundSecondary};
  }

  ${(props) => props.isSelected && selectedStyle};
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

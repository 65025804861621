import { connect } from 'react-redux';

import AccountManager from './accountManager.jsx';

import actions from '../../reducers/auth/actions';

const mapStateToProps = (state) => ({
  username: state.auth.username,
  userEmail: state.auth.userEmail,
  loginHistory: state.auth.loginHistory,
  preferences: state.auth.preferences,
  getAccountPending: state.auth.status.getAccountPending,
  editAccountPending: state.auth.status.editAccountPending,
  deleteAccountPending: state.auth.status.deleteAccountPending,
  deleteAccountSuccess: state.auth.status.deleteAccountSuccess,
  requestSuccess: state.auth.status.editAccountSuccess,
  newProjectOptions: state.auth.newProjectOptions,
  getPrintersPending: state.printers.status.getPrintersPending,
  getPrintersSuccess: state.printers.status.getPrintersSuccess,
  printers: state.printers.printers,
  getMaterialsPending: state.slicer.status.getMaterialsPending,
  getMaterialsSuccess: state.slicer.status.getMaterialsSuccess,
  materials: state.slicer.materials,
  userIntegrations: state.auth.userIntegrations,
  allIntegrations: state.auth.allIntegrations,
  getIntegrationsPending: state.auth.status.getIntegrationsPending,
  getIntegrationsSuccess: state.auth.status.getIntegrationsSuccess,
  linkIntegrationPending: state.auth.status.linkIntegrationPending,
  unlinkIntegrationPending: state.auth.status.unlinkIntegrationPending,
  getDevicesPending: state.iot.status.getDevicesPending,
  getDevicesSuccess: state.iot.status.getDevicesSuccess,
  devices: state.iot.devices,
});

const mapDispatchToProps = (dispatch) => ({
  editAccountRequest: (updatedInfo) =>
    dispatch(actions.editAccountRequest(updatedInfo)),
  deleteAccountRequest: () => dispatch(actions.deleteAccountRequest()),
  logout: () => dispatch(actions.logout()),
  logoutAllSessions: () => dispatch(actions.logoutAllSessionsRequest()),
  linkIntegration: (integrationId) =>
    dispatch(actions.linkIntegrationRequest(integrationId)),
  unlinkIntegration: (integrationId) =>
    dispatch(actions.unlinkIntegrationRequest(integrationId)),
  getIntegrations: () => dispatch(actions.getIntegrationsRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountManager);

import _ from 'lodash';
import { format, formatDistanceToNow } from 'date-fns';

import { Dates } from '../../themes';
import { conversionFactors } from '../../constants';

const Utils = {
  roundTo: (value, decimalPlaces) => {
    const factor = 10 ** decimalPlaces;
    return Math.round(value * factor) / factor;
  },
  pluralize: (string, count, zeroSingular = false) => {
    if (zeroSingular) {
      return Math.abs(count) <= 1 ? string : `${string}s`;
    }
    return Math.abs(count) === 1 ? string : `${string}s`;
  },
  getCssColor: (color) => {
    if (_.isArray(color)) {
      if (color.length === 4) {
        const [r, g, b, a] = color;
        return `rgba(${r}, ${g}, ${b}, ${a})`;
      }
      const [r, g, b] = color;
      return `rgb(${r}, ${g}, ${b})`;
    }
    if (typeof color === 'string' && color[0] !== '#') {
      return `#${color}`;
    }
    return color;
  },
  /**
   * parse a URL query parameters string
   * @param queryParams string containing the query parameters
   * @returns an object with the parameters as keys and the URI decoded values as values
   * NOTE: we are building our own query params parser instead of using the URLSearchParams Web API
   * because the Web API is not compatible with Internet Explorer
   */
  parseQueryParams: (queryParams) => {
    if (!queryParams) return {};
    const params = queryParams.replace('?', '').split('&');
    return _.reduce(
      params,
      (paramsMap, param) => {
        const map = { ...paramsMap };
        const [key, value] = param.split('=');
        map[key] = decodeURIComponent(value);
        return map;
      },
      {}
    );
  },
  isoToDateString: (timestamp) => format(new Date(timestamp), Dates.dateFormat),
  isoToDateTimeString: (timestamp) =>
    format(new Date(timestamp), Dates.dateTimeFormat),
  isoToRelativeTime: (timestamp) => formatDistanceToNow(new Date(timestamp)),
  formatDuration: (durationInSeconds, shortUnits = false) => {
    let value;
    let units;
    if (durationInSeconds < conversionFactors.SECONDS_PER_MINUTE) {
      // less than 1 minute
      value = Math.round(durationInSeconds);
      units = shortUnits ? 'sec' : Utils.pluralize('second', value);
    } else if (durationInSeconds < conversionFactors.SECONDS_PER_HOUR) {
      // less than 1 hour
      value = Math.round(
        durationInSeconds / conversionFactors.SECONDS_PER_MINUTE
      );
      units = shortUnits ? 'min' : Utils.pluralize('minute', value);
    } else if (durationInSeconds < conversionFactors.SECONDS_PER_DAY) {
      // less than 1 day
      value = Math.round(
        durationInSeconds / conversionFactors.SECONDS_PER_HOUR
      );
      units = shortUnits ? 'h' : Utils.pluralize('hour', value);
    } else {
      // 1 day or more
      value = Math.round(durationInSeconds / conversionFactors.SECONDS_PER_DAY);
      units = shortUnits ? 'd' : Utils.pluralize('day', value);
    }
    return `${value} ${units}`;
  },
  formatUnits: (units, withLeadingSpace = false) => {
    if (!units) return units;
    const leadingSpace = withLeadingSpace ? ' ' : '';
    switch (units) {
      case '%':
        return '%'; // no leading space
      case 'C':
        return `${leadingSpace}°C`;
      case 'mm3/s':
        return `${leadingSpace}mm³/s`;
      default:
        return `${leadingSpace}${units}`;
    }
  },
};

export default Utils;

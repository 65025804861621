import React, { Component } from 'react';
import { withTheme } from 'styled-components';

import ReactTooltip from 'react-tooltip';

import { uuidv4 } from './uuid';
import Portal from '../portal/portal.jsx';

import { Container, StyledTooltip } from './toolTipWrapper.styles';
import { Caption } from '../typography/typography';

class ToolTipWrapper extends Component {
  static defaultProps = {
    tooltip: null,
    error: false,
    wide: false,
  };

  constructor(props) {
    super(props);
    this.id = uuidv4();
  }

  manuallyShow() {
    ReactTooltip.show(this.container);
  }

  manuallyHide() {
    ReactTooltip.hide(this.container);
  }

  renderTooltipContent() {
    if (typeof this.props.tooltip === 'string') {
      return <Caption noSpacing>{this.props.tooltip}</Caption>;
    }
    return this.props.tooltip;
  }

  renderToolTip() {
    if (!this.props.tooltip) return null;

    // default styling
    let backgroundColor = this.props.theme.colors.grey6;
    let borderColor = this.props.theme.colors.grey6;
    let textColor = this.props.theme.colors.grey1;

    // wide tooltip
    if (this.props.wide) {
      backgroundColor = this.props.theme.colors.elevatedBackgroundPrimary;
      borderColor = this.props.theme.colors.grey4;
      textColor = this.props.theme.colors.textPrimary;
    }

    // error tooltip
    if (this.props.error) {
      backgroundColor = this.props.theme.colors.redDark;
      borderColor = this.props.theme.colors.redDark;
      textColor = this.props.theme.colors.whiteDefault;
    }

    return (
      <Portal testId='tooltip'>
        <StyledTooltip
          border
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          textColor={textColor}
          wide={this.props.wide}
          error={this.props.error}
          id={this.id}
          effect='solid'>
          {this.renderTooltipContent()}
        </StyledTooltip>
      </Portal>
    );
  }

  render() {
    return (
      <Container
        data-tip={!!this.props.tooltip}
        data-for={this.id}
        ref={(ref) => {
          this.container = ref;
        }}>
        {this.renderToolTip()}
        {this.props.children}
      </Container>
    );
  }
}

export default withTheme(ToolTipWrapper);

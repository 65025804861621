import React from 'react';

import { ConfirmationModal } from '../../../../shared';

const StampWarningModal = ({
  slicer = 'Slic3r',
  onConfirm = () => {},
  onCancel = () => {},
}) => (
  <ConfirmationModal
    primaryLabel={`Stamps not supported by ${slicer}`}
    secondaryLabel={`This project contains stamps, but stamping is not currently supported by ${slicer}.`}
    tertiaryLabel='You may proceed to slice, but stamps will be ignored. To use them, please select a different slicer.'
    cancelLabel='Cancel'
    confirmLabel='Continue'
    onClickCancel={onCancel}
    onClickConfirm={onConfirm}
  />
);

export default StampWarningModal;

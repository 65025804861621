import styled, { css } from 'styled-components';
import { Shadows } from '../../../../themes';

export const Container = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 20em;
  height: 2.5em;
  box-sizing: border-box;
  margin-top: 0.3em;
  padding: 0.3em 1em;
  border-radius: 0.2em;
  text-align: center;
  background-color: ${(props) => props.theme.colors.yellowDefault};
  ${Shadows.d1};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s;
  opacity: ${(props) => (props.connected ? 0 : 1)};
  ${(props) =>
    props.connected &&
    css`
      pointer-events: none;
    `};
`;

import React, { Component } from 'react';
import _ from 'lodash';
import { withTheme } from 'styled-components';

import {
  Container,
  FieldRow,
  OffsetButtons,
  FieldsContainer,
  ToolLabelWrapper,
  MirrorButtons,
  MirrorLabelWrapper,
  XStyles,
  YStyles,
  ZStyles,
  OffsetButtonWrapper,
  MirrorButtonWrapper,
  ResetButtonWrapper,
  NumberFieldWrapper,
} from '../transformContents.styles';

import { Button, Icon, Input } from '../../../../../shared';

import { SlicerUtils, TransformUtils } from '../../../../../utils';

import { Icons } from '../../../../../themes';

import { Subtitle2 } from '../../../../../shared/typography/typography';

class RotationContents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRotations: { x: '', y: '', z: '' },
    };
  }

  componentDidMount() {
    this.computeAxisValues();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pivot !== this.props.pivot && this.props.pivot !== null) {
      // gizmo attached/updated
      this.computeAxisValues();
    } else if (prevProps.pivot !== null && this.props.pivot === null) {
      // gizmo detached
      this.resetAxisValues();
    }
  }

  radianToDegreeClamped(radian) {
    const rawValue = SlicerUtils.radiansToDegrees(radian);
    return SlicerUtils.clampDegreesToCircle(rawValue);
  }

  checkEqualRotation(selectedModels) {
    const firstModelRotation = {
      x: this.radianToDegreeClamped(selectedModels[0].transforms.rotate.x),
      y: this.radianToDegreeClamped(selectedModels[0].transforms.rotate.y),
      z: this.radianToDegreeClamped(selectedModels[0].transforms.rotate.z),
    };
    return _.every(selectedModels, (model) => {
      const thisModelRotation = {
        x: this.radianToDegreeClamped(model.transforms.rotate.x),
        y: this.radianToDegreeClamped(model.transforms.rotate.y),
        z: this.radianToDegreeClamped(model.transforms.rotate.z),
      };
      return _.isEqual(thisModelRotation, firstModelRotation);
    });
  }

  computeAxisValues() {
    const { selectedModels, selectedTower } = this.props;

    let axisRotations = { x: '', y: '', z: '' };

    /*
     * determine the value to display in text fields
     * - if all selected models have the same transform value display it in the corresponding field
     * - if not, display a field with no value
     */

    // compute axis values iff tower is not selected
    if (!selectedTower && selectedModels.length > 0) {
      const areRotationsEqual = this.checkEqualRotation(selectedModels);
      if (areRotationsEqual) {
        axisRotations = { ...selectedModels[0].transforms.rotate };
      } else {
        axisRotations = { x: '', y: '', z: '' };
      }
    }

    _.forEach(['x', 'y', 'z'], (axis) => {
      if (typeof axisRotations[axis] === 'number') {
        axisRotations[axis] = this.radianToDegreeClamped(axisRotations[axis]);
      }
    });

    this.setState({
      currentRotations: { ...axisRotations },
    });
  }

  resetAxisValues() {
    this.setState({
      currentRotations: { x: '', y: '', z: '' },
    });
  }

  handleKeyDown(e, axis) {
    if (!(e.keyCode === 40 || e.keyCode === 38)) return;
    e.preventDefault();
    e.stopPropagation();
    const sign = e.keyCode === 40 ? -1 : 1;
    const magnitude = e.shiftKey ? 5 : 1;
    this.setRotation({ [axis]: sign * magnitude }, true);
  }

  handleKeyUp(e) {
    e.stopPropagation();
  }

  onChangeSuccess(axis, newValue) {
    this.setState(
      {
        currentRotations: {
          ...this.state.currentRotations,
          [axis]: newValue,
        },
      },
      () => {
        this.setRotation({ [axis]: newValue });
      }
    );
  }

  onFlipClick(axis) {
    const { selectedModels, models } = this.props;
    if (_.isEmpty(selectedModels)) return;
    const updatedModels = TransformUtils.flipModels(axis, selectedModels);
    const droppedToBedModels = TransformUtils.dropToBed(updatedModels, models);
    this.props.updateModelTransforms(
      _.map(droppedToBedModels, (model) => ({
        id: model.id,
        transforms: _.pick(model.transforms, ['scale', 'translate']),
      }))
    );
  }

  onResetClick() {
    this.setRotation({ x: 0, y: 0, z: 0 });
  }

  setRotation(rotations, isOffset = false) {
    const { selectedModels, models } = this.props;
    if (_.isEmpty(selectedModels)) return;
    const updatedModels = TransformUtils.updateModelsRotation(
      rotations,
      selectedModels,
      isOffset
    );
    const droppedToBedModels = TransformUtils.dropToBed(updatedModels, models);
    this.props.updateModelTransforms(
      _.map(droppedToBedModels, (model) => ({
        id: model.id,
        transforms: _.pick(model.transforms, ['rotate', 'translate']),
      }))
    );
  }

  renderLabel() {
    return (
      <ToolLabelWrapper>
        <Subtitle2>Rotate</Subtitle2>
      </ToolLabelWrapper>
    );
  }

  renderAxes() {
    const labels = ['x', 'y', 'z'];
    const axes = _.map(labels, (label, index) => this.renderAxis(label, index));
    return <FieldsContainer>{axes}</FieldsContainer>;
  }

  renderAxis(axis, index) {
    return (
      <FieldRow key={index}>
        {this.renderAxisColorFlag(index)}
        {this.renderNumberField(axis, index)}
        {this.renderOffsetButtons(axis)}
      </FieldRow>
    );
  }

  renderAxisColorFlag(index) {
    let style = null;
    if (index === 0) style = <XStyles />;
    else if (index === 1) style = <YStyles />;
    else if (index === 2) style = <ZStyles />;
    return style;
  }

  renderNumberField(axis) {
    let value = this.state.currentRotations[axis];
    const isDisabled = typeof value !== 'number';
    if (!isDisabled) {
      value = Math.round(value * 1000) / 1000;
    }
    return (
      <NumberFieldWrapper>
        <Input
          label={axis.toUpperCase()}
          type='number'
          disabled={isDisabled}
          value={value}
          min={0}
          max={360}
          units='°'
          onChangeSuccess={(newValue) => this.onChangeSuccess(axis, newValue)}
          revertOnBlur
          stopPropagationOnKeyDown
          onKeyDown={(e) => this.handleKeyDown(e, axis)}
          onKeyUp={(e) => this.handleKeyUp(e)}
        />
      </NumberFieldWrapper>
    );
  }

  renderOffsetButtons(axis) {
    const isDisabled =
      _.isEmpty(this.props.selectedModels) || this.props.selectedTower;
    return (
      <OffsetButtons>
        <OffsetButtonWrapper>
          <Button
            expand
            minWidth='0'
            height='1.9em'
            title='Rotate by +90°'
            disabled={isDisabled}
            icon={Icons.basic.plus}
            iconSize={Icon.sizes.MINI}
            iconColor={this.props.theme.colors.grey6}
            onClick={() => this.setRotation({ [axis]: 90 }, true)}></Button>
        </OffsetButtonWrapper>
        <OffsetButtonWrapper>
          <Button
            expand
            minWidth='0'
            height='1.9em'
            title='Rotate by –90°'
            disabled={isDisabled}
            icon={Icons.basic.minus}
            iconSize={Icon.sizes.MINI}
            iconColor={this.props.theme.colors.grey6}
            onClick={() => this.setRotation({ [axis]: -90 }, true)}></Button>
        </OffsetButtonWrapper>
      </OffsetButtons>
    );
  }

  renderMirrorButtons() {
    const isDisabled =
      _.isEmpty(this.props.selectedModels) || this.props.selectedTower;
    return (
      <MirrorButtons>
        <MirrorLabelWrapper>
          <Subtitle2>Mirror</Subtitle2>
        </MirrorLabelWrapper>
        <MirrorButtonWrapper>
          <Button
            expand
            minWidth='0'
            title='Flip on X axis'
            disabled={isDisabled}
            onClick={() => this.onFlipClick('x')}>
            X
          </Button>
        </MirrorButtonWrapper>
        <MirrorButtonWrapper>
          <Button
            expand
            minWidth='0'
            title='Flip on Y axis'
            disabled={isDisabled}
            onClick={() => this.onFlipClick('y')}>
            Y
          </Button>
        </MirrorButtonWrapper>
        <MirrorButtonWrapper>
          <Button
            expand
            minWidth='0'
            title='Flip on Z axis'
            disabled={isDisabled}
            onClick={() => this.onFlipClick('z')}>
            Z
          </Button>
        </MirrorButtonWrapper>
      </MirrorButtons>
    );
  }

  renderResetButton() {
    const isDisabled =
      _.isEmpty(this.props.selectedModels) || this.props.selectedTower;
    return (
      <ResetButtonWrapper>
        <Button
          minWidth='0'
          height='2em'
          disabled={isDisabled}
          onClick={(e) => this.onResetClick(e)}>
          Reset
        </Button>
      </ResetButtonWrapper>
    );
  }

  render() {
    return (
      <Container>
        {this.renderLabel()}
        {this.renderResetButton()}
        {this.renderAxes()}
        {this.renderMirrorButtons()}
      </Container>
    );
  }
}

export default withTheme(RotationContents);

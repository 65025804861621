import styled from 'styled-components';

import { Breakpoints } from '../../themes';

export const FilterBar = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  gap: 0.25rem;
  align-items: center;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    align-self: flex-start;
  }
`;

export const FilterBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: ${Breakpoints.largeWidth}) {
    top: 12.5rem;
  }

  @media (max-width: ${Breakpoints.smallWidth}) {
    top: 11.5rem;
  }
`;

export const SortDropdownWrapper = styled.div`
  margin-left: 0.5rem;
  margin-right: 1rem;
`;

// reusable styles and styled components intended for use
// in places where ManagerView is rendered

export const ButtonsContainer = styled.div`
  width: 100%;
  margin-top: 0;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;
`;

// ButtonWraper is being used by other components which are passing buttons as a part of props
export const ButtonWrapper = styled.div`
  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    margin: 0;
    width: 100%;
  }

  @media (max-width: ${Breakpoints.largeWidth}) {
    margin-left: 0;
  }
`;

export const Placeholders = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-gap: 2rem;
`;

export const PlaceholderMessages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-top: 4rem;
`;

export const ChildrenWrapper = styled.div`
  margin-top: 0.5rem;
`;

import React from 'react';
import _ from 'lodash';
import { withTheme } from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';

import {
  MenuItem,
  Menu,
  AppMenuIconWrapper,
  AppMenu,
  CopyrightTextWrapper,
  NewProjectButtonWrapper,
  SideBarContent,
  SideBarLogoWrapper,
  SupportMenu,
  SupportMenuItem,
  TitleWrapper,
} from './drawer.styles';

import NewProjectButton from '../../newProjectButton/newProjectButton.container';
import Icon from '../../icon/icon.jsx';
import Image from '../../image/image.jsx';
import Button from '../../button/button.jsx';

import { Body1, ButtonText, Caption } from '../../typography/typography';
import { MenuMeta, SupportMeta } from '../menu.metadata';

const Drawer = (props) => {
  const location = useLocation();
  const history = useHistory();

  const renderNewProjectButton = () => {
    return (
      <NewProjectButtonWrapper>
        <NewProjectButton expand />
      </NewProjectButtonWrapper>
    );
  };

  const renderMenuItem = (meta) => {
    return (
      <MenuItem
        isCurrent={location?.pathname.startsWith(
          `/${meta.label.toLowerCase()}`
        )}
        onClick={() => history.push(meta.route)}>
        <AppMenuIconWrapper>
          <Icon src={meta.iconName} color={props.theme.grey6} />
        </AppMenuIconWrapper>
        <ButtonText>{meta.label}</ButtonText>
      </MenuItem>
    );
  };

  const renderMenu = (menu) => {
    return (
      <Menu>
        {menu.title && (
          <TitleWrapper>
            <Body1 grey>{menu.title}</Body1>
          </TitleWrapper>
        )}
        {_.map(menu.items, (item, idx) => (
          <React.Fragment key={idx}>{renderMenuItem(item)}</React.Fragment>
        ))}
      </Menu>
    );
  };

  const renderAppMenu = () => {
    return (
      <AppMenu>
        {_.map(MenuMeta, (meta, idx) => (
          <React.Fragment key={idx}>{renderMenu(meta)}</React.Fragment>
        ))}
      </AppMenu>
    );
  };

  const renderSupportMenuItems = () => {
    return (
      <>
        {_.map(SupportMeta, (item, idx) => (
          <SupportMenuItem key={idx}>
            <Button link onClick={() => window.open(item.link, '_blank')}>
              {item.label}
            </Button>
          </SupportMenuItem>
        ))}
      </>
    );
  };

  const renderSupportMenu = () => {
    return <SupportMenu>{renderSupportMenuItems()}</SupportMenu>;
  };

  const renderSideBarImage = () => {
    return (
      <SideBarLogoWrapper>
        <Image alt='Logo' src={props.theme.images.mosaicLogoFull} />
      </SideBarLogoWrapper>
    );
  };

  const renderCopyright = () => {
    return (
      <CopyrightTextWrapper>
        <Caption grey>
          &copy; {new Date().getFullYear()} Mosaic Manufacturing Ltd.
        </Caption>
      </CopyrightTextWrapper>
    );
  };

  const renderSideBarContent = () => {
    return (
      <SideBarContent>
        {renderNewProjectButton()}
        {renderAppMenu()}
        {renderSupportMenu()}
        {renderSideBarImage()}
        {renderCopyright()}
      </SideBarContent>
    );
  };

  return <>{renderSideBarContent()}</>;
};

export default withTheme(Drawer);

import styled from 'styled-components';

import { Breakpoints, Shadows } from '../../../themes';

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${(props) => props.theme.colors.overlay};
  z-index: 98;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all linear 120ms;
  transition-property: opacity;
`;

export const Container = styled.div`
  display: block;
  width: 100%;
  max-width: 43.75em; /* ~700px */
  background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  border-radius: 0.2em;
  ${Shadows.d1};
  z-index: 99;

  @media (max-width: ${Breakpoints.smallWidth}) {
    width: 100%;
    margin-top: 3em;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1em 1em 0;
  box-sizing: border-box;
`;

import _ from 'lodash';
import * as THREE from 'three';

import MatrixUtils from '../matrix/matrix4';
import TreeUtils from '../modelTree/modelTree';
import SlicerUtils from '../slicer/slicer';

const Utils = {
  correctTranslation: (model, pivot, newTransforms) => {
    const modelMatrix = MatrixUtils.fromModelTransforms(model.transforms);
    let inverse;
    try {
      inverse = MatrixUtils.getInverse(modelMatrix);
    } catch (err) {
      // degenerate modelMatrix, cannot be inverted
      return {
        ...newTransforms,
        translate: { ...model.transforms.translate },
      };
    }
    const modelCoordsPivot = MatrixUtils.multiplyByVector3(inverse, pivot);
    const newMatrix = MatrixUtils.fromModelTransforms(newTransforms);
    const newPivot = MatrixUtils.multiplyByVector3(newMatrix, modelCoordsPivot);
    const delta = newPivot.clone().sub(pivot);
    return {
      ...newTransforms,
      translate: {
        x: -delta.x,
        y: -delta.y,
        z: -delta.z,
      },
    };
  },
  dropToBed: (selectedModels, models) => {
    const getSelectedModelById = (id) =>
      _.find(selectedModels, (model) => model.id === id);
    const updatedModelIds = [];
    const modelsToUpdate = [];
    _.forEach(selectedModels, (model) => {
      if (!_.includes(updatedModelIds, model.id)) {
        const parent = TreeUtils.getParent(models, model);
        if (parent === null) {
          // not a member of a group
          const zOffset = -SlicerUtils.getMinZ([model.mesh]);
          let { transforms } = model;
          if (zOffset !== 0) {
            transforms = {
              ...model.transforms,
              translate: {
                ...model.transforms.translate,
                z: model.transforms.translate.z + zOffset,
              },
            };
            MatrixUtils.updateMeshTransforms(model.mesh, transforms);
          }
          updatedModelIds.push(model.id);
          modelsToUpdate.push({
            ...model,
            transforms,
          });
        } else {
          // member of a group -- drop entire group to bed
          const childMeshes = _.map(parent.children, (child) => child.mesh);
          const zOffset = -SlicerUtils.getMinZ(childMeshes);
          if (zOffset === 0) {
            updatedModelIds.push(model.id);
            modelsToUpdate.push(model);
          } else {
            _.forEach(parent.children, (child) => {
              const selectedChild = getSelectedModelById(child.id);
              const nodeToUpdate = selectedChild || child;
              if (!_.includes(updatedModelIds, nodeToUpdate.id)) {
                const transforms = {
                  ...nodeToUpdate.transforms,
                  translate: {
                    ...nodeToUpdate.transforms.translate,
                    z: nodeToUpdate.transforms.translate.z + zOffset,
                  },
                };
                MatrixUtils.updateMeshTransforms(nodeToUpdate.mesh, transforms);
                updatedModelIds.push(nodeToUpdate.id);
                modelsToUpdate.push({
                  ...nodeToUpdate,
                  transforms,
                });
              }
            });
          }
        }
      }
    });
    return modelsToUpdate;
  },
  getOffsetToBedCenter: (models, bedMiddle) => {
    const bbox = SlicerUtils.getCombinedBoundingBox(models);
    const minZ = bbox.min.z;
    const { x, y } = SlicerUtils.getBoundingBoxCenter(bbox);
    return {
      x: bedMiddle.x - x,
      y: bedMiddle.y - y,
      z: -minZ,
    };
  },
  updateModelsRotation(rotations, selectedModels, isOffset) {
    const boundedRotations = _.mapValues(
      rotations,
      SlicerUtils.clampDegreesToCircle
    );
    let newRotations = _.mapValues(
      boundedRotations,
      SlicerUtils.degreesToRadians
    );
    const combinedBBox = SlicerUtils.getCombinedBoundingBox(selectedModels);
    const pivot = SlicerUtils.getBoundingBoxCenter(combinedBBox);
    return _.mapValues(selectedModels, (model) => {
      const newTransforms = {
        ...model.transforms,
        rotate: { ...model.transforms.rotate },
        translate: { x: 0, y: 0, z: 0 },
      };
      if (isOffset) {
        const offsetsInRadians = _.mapValues(
          rotations,
          SlicerUtils.degreesToRadians
        );
        const currentRotations = model.transforms.rotate;
        newRotations = _.mapValues(offsetsInRadians, (offsetInRadians, axis) =>
          SlicerUtils.clampRadiansToCircle(
            currentRotations[axis] + offsetInRadians
          )
        );
      }
      newTransforms.rotate = {
        ...newTransforms.rotate,
        ...newRotations,
      };
      const finalTransforms = Utils.correctTranslation(
        model,
        pivot,
        newTransforms
      );
      MatrixUtils.updateMeshTransforms(model.mesh, finalTransforms);
      return {
        ...model,
        transforms: finalTransforms,
      };
    });
  },
  flipModels(axis, selectedModels) {
    const axisScales = Utils.getAxisScales(selectedModels);
    const scale = axisScales[axis] * -1;
    const combinedBBox = SlicerUtils.getCombinedBoundingBox(selectedModels);
    const pivot = SlicerUtils.getBoundingBoxCenter(combinedBBox);
    const updatedModels = _.map(selectedModels, (model) => {
      const newTransforms = {
        ...model.transforms,
        scale: { ...model.transforms.scale },
        translate: { x: 0, y: 0, z: 0 },
      };
      newTransforms.scale[axis] = scale;
      const finalTransforms = Utils.correctTranslation(
        model,
        pivot,
        newTransforms
      );
      MatrixUtils.updateMeshTransforms(model.mesh, finalTransforms);
      return {
        ...model,
        transforms: finalTransforms,
      };
    });
    return updatedModels;
  },
  getAxisScales(selectedModels) {
    let axisScales = {
      x: 0,
      y: 0,
      z: 0,
    };
    if (selectedModels.length === 1) {
      axisScales = { ...selectedModels[0].transforms.scale };
    } else {
      ['x', 'y', 'z'].forEach((axis) => {
        axisScales[axis] = selectedModels.reduce((acc, model) => {
          if (acc === null) return acc;
          if (acc === undefined) return model.transforms.scale[axis];
          if (model.transforms.scale[axis] === acc) return acc;
          return '';
        }, undefined);
      });
    }
    return axisScales;
  },
  updateModelsScale(scales, selectedModels, uniform) {
    const combinedBBox = SlicerUtils.getCombinedBoundingBox(selectedModels);
    const pivot = SlicerUtils.getBoundingBoxCenter(combinedBBox);
    return _.map(selectedModels, (model) => {
      const newTransforms = {
        ...model.transforms,
        translate: { x: 0, y: 0, z: 0 },
      };
      if (uniform && _.keys(scales).length === 1) {
        const axis = _.keys(scales)[0];
        const currentAxisScale = model.transforms.scale[axis];
        const newAxisScale = scales[axis];
        const multiplierOfPrevious = newAxisScale / currentAxisScale;
        newTransforms.scale = _.mapValues(
          model.transforms.scale,
          (oldValue) => oldValue * multiplierOfPrevious
        );
      } else {
        newTransforms.scale = {
          ...model.transforms.scale,
          ...scales,
        };
      }
      const finalTransforms = Utils.correctTranslation(
        model,
        pivot,
        newTransforms
      );
      MatrixUtils.updateMeshTransforms(model.mesh, finalTransforms);
      return {
        ...model,
        transforms: finalTransforms,
      };
    });
  },
  layFaceToBed: (models, intersection) => {
    const { object: mesh } = intersection;
    const model = TreeUtils.searchById(mesh.name, models);
    const modelsToRotate = [];

    // check if model is part of group
    const parent = TreeUtils.getParent(models, model);

    if (parent === null) {
      // not a member of group
      modelsToRotate.push(model);
    } else {
      // member of group
      _.forEach(parent.children, (child) => {
        modelsToRotate.push(child);
      });
    }

    // compute model center before applying transforms
    const beforeBox = SlicerUtils.getBoundingBox(
      _.map(modelsToRotate, (item) => item.mesh)
    );
    const beforeCenter = new THREE.Vector3();
    beforeBox.getCenter(beforeCenter);

    // rotate models
    const rotatedModels = _.map(modelsToRotate, (item) => {
      const startPosition = new THREE.Vector3().copy(item.transforms.translate);

      // compute required rotation angles
      const faceNormal = new THREE.Vector3()
        .copy(intersection.face.normal)
        .applyEuler(item.mesh.rotation);
      const targetNormal = new THREE.Vector3(0, 0, -1);

      const faceQuat = new THREE.Quaternion().setFromUnitVectors(
        faceNormal,
        targetNormal
      );
      const rotation = new THREE.Euler().setFromQuaternion(faceQuat);

      const afterQuat = new THREE.Quaternion()
        .setFromUnitVectors(faceNormal, targetNormal)
        .multiply(item.mesh.quaternion);

      const newRotation = new THREE.Euler().setFromQuaternion(afterQuat);

      const updatedRotation = {
        // eslint-disable-next-line no-underscore-dangle
        x: SlicerUtils.clampRadiansToCircle(newRotation._x),
        // eslint-disable-next-line no-underscore-dangle
        y: SlicerUtils.clampRadiansToCircle(newRotation._y),
        // eslint-disable-next-line no-underscore-dangle
        z: SlicerUtils.clampRadiansToCircle(newRotation._z),
      };

      // pivot position around combined bbox center
      const updatedPosition = new THREE.Vector3()
        .subVectors(startPosition, beforeCenter)
        .applyEuler(rotation)
        .add(beforeCenter);

      const updatedModel = {
        ...item,
        transforms: {
          ...item.transforms,
          translate: updatedPosition,
          rotate: updatedRotation,
        },
      };

      MatrixUtils.updateMeshTransforms(item.mesh, updatedModel.transforms);
      return updatedModel;
    });

    // drop to bed after rotating
    const droppedModels = Utils.dropToBed(rotatedModels, models);
    return droppedModels;
  },
};

export default Utils;

import React from 'react';
import * as d3 from 'd3';
import { withTheme } from 'styled-components';

class TrendLine extends React.Component {
  static defaultProps = {
    data: null,
    currentValue: 0,
    margin: {
      top: 10,
      right: 5,
      bottom: 30,
      left: 50,
    },
  };

  constructor(props) {
    super(props);
    this.svgRef = React.createRef();
  }

  componentDidMount() {
    this.createChart();
  }

  createChart() {
    if (!this.props.data) return;

    const { currentValue, margin, data, theme } = this.props;

    const { domain, bins } = data;

    const width = 300 - margin.left - margin.right;
    const height = 150 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    const svg = d3
      .select(this.svgRef.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // X axis: scale and draw:
    const x = d3
      .scaleLinear()
      .domain([domain.xMin, domain.xMax])
      .range([0, width]);

    svg
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x));

    // Y axis: scale:
    const y = d3
      .scaleLinear()
      .domain([0, d3.max(bins, (d) => d.usage)])
      .range([height, 0]);

    // Line generator
    const line = d3
      .line()
      .x((d) => x((d.x1 + d.x0) / 2))
      .y((d) => y(d.usage))
      .curve(d3.curveMonotoneX); // apply smoothing to the line

    // Append the path to the svg element
    svg
      .append('path')
      .datum(bins)
      .attr('d', line)
      .style('stroke', theme.colors.grey5)
      .style('stroke-width', 3)
      .style('fill', 'none');

    // show reference line for current value
    svg
      .append('line')
      .style('stroke', theme.colors.greenLight)
      .style('stroke-width', 3)
      .attr('x1', x(currentValue))
      .attr('y1', y(0))
      .attr('x2', x(currentValue))
      .attr('y2', y(height));
  }

  render() {
    if (!this.props.data) return null;
    return <svg ref={this.svgRef} />;
  }
}

export default withTheme(TrendLine);

import _ from 'lodash';

import { defaultPrinter } from '../../reducers/printers/initialState';
import { SlicerUtils } from '../../utils';

const fixInvalidSupportDensities = (supportDensity) => {
  if (supportDensity <= 2) return 2;
  if (supportDensity <= 4) return 4;
  return 6;
};

// front-end to back-end (prepare)
export const prepareMachineSettingsData = (machineSettingsData) => ({
  ...machineSettingsData,
  bedSize: SlicerUtils.vector3ObjToAr(machineSettingsData.bedSize),
  originOffset: SlicerUtils.vector2ObjToAr(machineSettingsData.originOffset),
  sideTransitionCoordinates: SlicerUtils.vector2ObjToAr(
    machineSettingsData.sideTransitionCoordinates
  ),
});

// back-end to front-end (sanitize)
export const sanitizeMachineSettingsData = (machineSettingsData) => ({
  ...machineSettingsData,
  bedSize: SlicerUtils.vector3ArrToObj(machineSettingsData.bedSize),
  originOffset: SlicerUtils.vector2ArrToObj(machineSettingsData.originOffset),
  sideTransitionCoordinates: SlicerUtils.vector2ArrToObj(
    machineSettingsData.sideTransitionCoordinates
  ),
});

export const sanitizeStyleData = (styleData) => ({
  ..._.omit(defaultPrinter.styles[0], ['id', 'name', 'icon']),
  ...styleData,
  supportDensity: fixInvalidSupportDensities(styleData.supportDensity),
});

export const sanitizePrinterData = (printerData) => ({
  ...printerData,
  machineSettings: sanitizeMachineSettingsData(printerData.machineSettings),
  styles: _.map(printerData.styles, (style) => sanitizeStyleData(style)),
});

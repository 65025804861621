import React, { useMemo } from 'react';
import _ from 'lodash';

import { DeviceUtils } from '../../../../../utils';

import {
  Container,
  Frame,
  FrameItem,
  FrameItemNumber,
  FrameItemValue,
  Section,
} from './logs.styles';
import { Body1 } from '../../../../../shared';

const Logs = ({ deviceState }) => {
  const pings = useMemo(() => {
    return DeviceUtils.getPingHistory(deviceState);
  }, [deviceState]);
  const pongs = useMemo(() => {
    return DeviceUtils.getPongHistory(deviceState);
  }, [deviceState]);

  const getReversedList = (values) => {
    const list = _.map(values, ({ number, value }) => (
      <FrameItem key={number}>
        <FrameItemNumber>
          <Body1 grey noSpacing>
            {number}
          </Body1>
        </FrameItemNumber>
        <FrameItemValue>
          <Body1 noSpacing>
            {value === 0 ? 'MISSED' : `${value.toFixed(2)}%`}
          </Body1>
        </FrameItemValue>
      </FrameItem>
    ));
    return _.reverse(list);
  };

  const renderList = (label, values) => {
    if (values.length === 0) {
      return (
        <Section>
          <Body1 grey noSpacing>
            No {label.toLowerCase()} detected
          </Body1>
        </Section>
      );
    }
    return (
      <Section>
        <Body1 grey>{label}</Body1>
        <Frame>{getReversedList(values)}</Frame>
      </Section>
    );
  };

  return (
    <Container>
      {renderList('Pings', pings)}
      {renderList('Pongs', pongs)}
    </Container>
  );
};

export default Logs;

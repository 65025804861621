import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import _ from 'lodash';

import {
  Container,
  ControlBar,
  DropdownWrapper,
  SortControls,
  ActionButtons,
  FilesList,
  CurrentPathContainer,
  SubDirIcon,
  PathInfo,
  FileItem,
  FileName,
  FileType,
  FileTimestamp,
  PaginationControls,
  PaginationButtonContainer,
  PageNumber,
  StartPrintButtonWrapper,
  StyledIconContainer,
  ActionButtonWrapper,
  TextWrapper,
} from './files.styles';

import {
  Dropdown,
  Icon,
  RenameModal,
  Button,
  ToolTipWrapper,
  ActionButton,
  Subtitle1,
  Body1,
} from '../../../../shared';

import { Icons } from '../../../../themes';

import { DeviceUtils, FormatUtils, InterfaceUtils } from '../../../../utils';

import { FileSortMode, FileSortDirection } from '../../../../utils/sort/sort';

import Placeholder from '../placeholder.jsx';

const sortOptions = [
  { label: 'Name', value: FileSortMode.NAME },
  { label: 'Date modified', value: FileSortMode.DATE_MODIFIED },
];

class FilesSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItems: [],
      pages: [],
      pageIndex: 0,
      currentStorageDrive: '',
      currentDrivePath: '',
      showRenameModal: false,
    };

    this.onWindowClick = this.onWindowClick.bind(this);
    this.onWindowKeydown = this.onWindowKeydown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.onWindowClick);
    window.addEventListener('keydown', this.onWindowKeydown);

    const isOnline = DeviceUtils.isOnline(this.props.currentDeviceState);
    if (isOnline) {
      if (this.props.getStorageDrivesSuccess) {
        this.loadDrivePathFiles();
      } else if (!this.props.getStorageDrivesPending) {
        this.props.getStorageDrives(this.props.device);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      device,
      currentDeviceState,
      getStorageDrivesPending,
      getDrivePathFilesPending,
    } = this.props;
    if (!currentDeviceState) return;

    const isOnline = DeviceUtils.isOnline(currentDeviceState);
    if (
      isOnline &&
      !currentDeviceState.storageDrives &&
      !currentDeviceState.filesContext &&
      !getStorageDrivesPending &&
      !getDrivePathFilesPending
    ) {
      this.props.getStorageDrives(device);
    }

    const filesChanged = !_.isEqual(
      currentDeviceState.filesContext,
      prevProps.currentDeviceState.filesContext
    );
    const sortOptionsChanged = !_.isEqual(this.props.sortBy, prevProps.sortBy);

    const storageDrivesUpdated =
      !prevProps.getStorageDrivesSuccess && this.props.getStorageDrivesSuccess;

    if (storageDrivesUpdated) {
      this.loadDrivePathFiles();
    }

    if (filesChanged || sortOptionsChanged) {
      this.updateDisplayedItems();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
    window.removeEventListener('keydown', this.onWindowKeydown);
    this.props.forgetDeviceFiles(this.props.device);
  }

  handleRefresh() {
    this.props.forgetDeviceFiles(this.props.device);
    this.props.getStorageDrives(this.props.device);
  }

  loadDrivePathFiles() {
    const availableDrives = DeviceUtils.getAvailableStorageDrives(
      this.props.currentDeviceState
    );
    const driveOptions = this.formatStorageDriveOptions(availableDrives);

    if (!_.isEmpty(driveOptions)) {
      const currentStorageDrive =
        this.state.currentStorageDrive || driveOptions[0].value;
      const currentDrivePath = this.state.currentDrivePath || '/';
      this.setState(
        {
          currentStorageDrive,
          currentDrivePath,
        },
        () => {
          const fullDrivePath = this.formatFullDrivePath(
            currentStorageDrive,
            currentDrivePath
          );
          this.props.getDrivePathFiles(this.props.device, fullDrivePath);
        }
      );
    }
  }

  updateDisplayedItems() {
    const { currentDeviceState } = this.props;
    const filesAndFolders =
      DeviceUtils.getCurrentDrivePathFiles(currentDeviceState);

    if (_.isEmpty(filesAndFolders)) {
      this.setState({
        pageIndex: 0,
        pages: [],
        selectedItems: [],
      });
      return;
    }

    const sortedItems = this.handleSortItems(filesAndFolders);
    const chunks = this.chunkItems(sortedItems);
    const displayedItems = chunks[0];
    const newState = {
      pageIndex: 0,
      pages: chunks,
      selectedItems: [],
    };
    _.forEach(this.state.selectedItems, (key) => {
      if (_.find(displayedItems, (item) => item.name === key)) {
        newState.selectedItems.push(key);
      }
    });
    this.setState(newState);
  }

  onWindowClick() {
    const { showRenameModal } = this.state;
    if (showRenameModal) return;
    this.handleDeselectItem();
  }

  onWindowKeydown(event) {
    if (event.key === 'Escape') {
      this.handleDeselectItem();
    }
  }

  formatFullDrivePath(storageDrive, drivePath) {
    let driveRootPath = '';

    // special case: device local storage
    if (storageDrive === 'device/') {
      driveRootPath = 'device';
    } else {
      driveRootPath = storageDrive;
    }

    return `${driveRootPath}${drivePath}`;
  }

  formatStorageDriveOptions(availableDrives) {
    // special case: device local storage

    const drives = [
      {
        label: 'Device',
        value: 'device/',
      },
    ];

    _.each(availableDrives, (driveName) => {
      if (driveName !== 'device/')
        drives.push({ label: driveName, value: driveName });
    });
    return drives;
  }

  chunkItems(items) {
    const pageSize = 10;
    return _.chunk(items, pageSize);
  }

  handleSortItems(allItems) {
    const allFolders = _.filter(allItems, (item) =>
      item.name ? item.name.endsWith('/') : false
    );
    const allFiles = _.filter(allItems, (item) =>
      item.name ? !item.name.endsWith('/') : false
    );

    const sortedFolders = DeviceUtils.sortFilesOrFolders(
      allFolders,
      this.props.sortBy
    );
    const sortedFiles = DeviceUtils.sortFilesOrFolders(
      allFiles,
      this.props.sortBy
    );

    return [...sortedFolders, ...sortedFiles];
  }

  handleSelectListItem(e, itemName) {
    e.stopPropagation();
    const { pages, pageIndex, selectedItems } = this.state;

    const updatedSelectedItems = InterfaceUtils.handleSelectableItemClick(
      e,
      pages[pageIndex],
      selectedItems,
      itemName,
      'name'
    );
    this.setState({ selectedItems: updatedSelectedItems });
  }

  handleDeselectItem() {
    const { showRenameModal } = this.state;
    if (showRenameModal) {
      this.setState({ showRenameModal: false });
    } else {
      this.setState({ selectedItems: [] });
    }
  }

  handleSortModeChange(sortMode) {
    this.props.updatePreferences(sortMode, this.props.sortBy.direction);
  }

  handleStorageDriveChange(storageDrive) {
    const currentDrivePath = '/';
    this.setState(
      {
        currentStorageDrive: storageDrive,
        currentDrivePath,
      },
      () => {
        const fullDrivePath = this.formatFullDrivePath(
          storageDrive,
          currentDrivePath
        );
        this.props.getDrivePathFiles(this.props.device, fullDrivePath);
      }
    );
  }

  toggleSortDirection(e) {
    e.stopPropagation();
    this.props.updatePreferences(
      this.props.sortBy.mode,
      this.props.sortBy.direction * -1
    );
  }

  handleOpenFolder(e, folderName) {
    e.stopPropagation();
    const { currentStorageDrive, currentDrivePath } = this.state;

    const updatedDrivePath = `${currentDrivePath}${folderName}`;

    this.setState(
      {
        currentDrivePath: updatedDrivePath,
      },
      () => {
        const fullDrivePath = this.formatFullDrivePath(
          currentStorageDrive,
          updatedDrivePath
        );
        this.props.getDrivePathFiles(this.props.device, fullDrivePath);
      }
    );
  }

  handleFolderNavigateUp(e) {
    e.stopPropagation();
    const { currentStorageDrive, currentDrivePath } = this.state;

    // find next index of '/', while not including the one at the end of current path
    const nextPathIndex = currentDrivePath.slice(0, -1).lastIndexOf('/');

    let updatedDrivePath;
    if (nextPathIndex >= 0) {
      updatedDrivePath = currentDrivePath.slice(0, nextPathIndex + 1);
    }

    this.setState(
      {
        currentDrivePath: updatedDrivePath,
      },
      () => {
        const fullDrivePath = this.formatFullDrivePath(
          currentStorageDrive,
          updatedDrivePath
        );
        this.props.getDrivePathFiles(this.props.device, fullDrivePath);
      }
    );
  }

  showRenameModal(e) {
    e.stopPropagation();
    this.setState({
      showRenameModal: true,
    });
  }

  hideRenameModal() {
    this.setState({
      showRenameModal: false,
    });
  }

  handleRenameFile(newFileName) {
    const { selectedItems, currentStorageDrive, currentDrivePath } = this.state;

    const [targetFilePath] = selectedItems;
    const extensionStartIndex = targetFilePath.lastIndexOf('.');
    const targetFileName = targetFilePath.slice(0, extensionStartIndex);
    const targetFileType = targetFilePath.slice(extensionStartIndex + 1);

    if (newFileName.trim() !== targetFileName) {
      const fullDrivePath = this.formatFullDrivePath(
        currentStorageDrive,
        currentDrivePath
      );
      const previousFullPath = `${fullDrivePath}${targetFilePath}`;
      const newFullPath = `${fullDrivePath}${newFileName}.${targetFileType}`;

      const filePathData = {
        path: previousFullPath,
        newPath: newFullPath,
      };
      this.props.editFilePath(this.props.device, filePathData);
    }
    this.hideRenameModal();
  }

  handleStartPrintWithFile(e) {
    e.stopPropagation();
    const { selectedItems, currentStorageDrive, currentDrivePath } = this.state;

    const { device } = this.props;

    const [targetFilePath] = selectedItems;
    const fullDrivePath = this.formatFullDrivePath(
      currentStorageDrive,
      currentDrivePath
    );
    const fullFilePath = `${fullDrivePath}${targetFilePath}`;

    this.props.startPrint(device, fullFilePath);
  }

  renderStorageDriveSelection() {
    const { currentDeviceState, getStorageDrivesPending } = this.props;

    const availableDrives =
      DeviceUtils.getAvailableStorageDrives(currentDeviceState);
    const driveOptions = this.formatStorageDriveOptions(availableDrives);

    return (
      <DropdownWrapper>
        <ToolTipWrapper tooltip='Storage drive'>
          <Dropdown
            StyledIconContainer={StyledIconContainer}
            minHeight='0'
            value={this.state.currentStorageDrive}
            options={driveOptions}
            disabled={getStorageDrivesPending || _.isEmpty(driveOptions)}
            onChange={(value) => this.handleStorageDriveChange(value)}
          />
        </ToolTipWrapper>
      </DropdownWrapper>
    );
  }

  renderSortDropdown() {
    return (
      <DropdownWrapper>
        <ToolTipWrapper tooltip='Sort by'>
          <Dropdown
            StyledIconContainer={StyledIconContainer}
            minHeight='0'
            value={this.props.sortBy.mode}
            options={sortOptions}
            disabled={_.isEmpty(this.state.pages)}
            onChange={(value) => this.handleSortModeChange(value)}
          />
        </ToolTipWrapper>
      </DropdownWrapper>
    );
  }

  renderSortDirectionToggle() {
    const sortDirection = this.props.sortBy.direction;
    const arrowDirectionIcon =
      sortDirection === FileSortDirection.ASCENDING
        ? Icons.basic.arrowDown
        : Icons.basic.arrowUp;
    return (
      <ActionButton
        width='1.725rem'
        icon={arrowDirectionIcon}
        title='Toggle sort direction'
        disabled={_.isEmpty(this.state.pages)}
        onClick={(e) => this.toggleSortDirection(e)}
      />
    );
  }

  renderSortControls() {
    return (
      <SortControls>
        {this.renderSortDropdown()}
        {this.renderSortDirectionToggle()}
        {this.renderStorageDriveSelection()}
      </SortControls>
    );
  }

  renderStartPrintWithFileButton() {
    const { selectedItems } = this.state;
    const { currentDeviceState, device } = this.props;

    const pendingRequests = this.props.pendingRequests[device.id];

    const isActivelyPrinting = DeviceUtils.isActivelyPrinting(
      currentDeviceState,
      device
    );
    const isConnected = DeviceUtils.isConnectedToPrinter(currentDeviceState);
    const isPending = pendingRequests.startPrint;
    const oneFileSelected =
      selectedItems.length === 1 && !selectedItems[0].endsWith('/');

    let tooltip = '';
    let mcfDisabled = false;
    if (oneFileSelected) {
      // check device connection for palette in deviceConnectionSettings
      if (
        selectedItems[0].endsWith('.mcf.gcode') &&
        device.type === 'canvas-hub'
      ) {
        mcfDisabled = !DeviceUtils.isConnectedToAccessory(currentDeviceState);
      }
      if (!isConnected) {
        tooltip = 'Printer is not connected';
      } else if (mcfDisabled) {
        tooltip = 'Palette 2 is not connected';
      }
    }

    return (
      <ToolTipWrapper tooltip={tooltip}>
        <StartPrintButtonWrapper>
          <Button
            primary
            height='100%'
            minWidth='7.1em'
            disabled={
              !isConnected ||
              !oneFileSelected ||
              isActivelyPrinting ||
              isPending ||
              mcfDisabled
            }
            onClick={(e) => this.handleStartPrintWithFile(e, selectedItems[0])}>
            {isPending ? 'Starting…' : 'Start print'}
          </Button>
        </StartPrintButtonWrapper>
      </ToolTipWrapper>
    );
  }

  renderEditFileButton() {
    const { selectedItems, currentStorageDrive, currentDrivePath } = this.state;
    const { currentDeviceState, device } = this.props;

    const oneItemSelected = selectedItems.length === 1;
    const folderSelected = oneItemSelected && selectedItems[0].endsWith('/');

    const [targetFilePath] = selectedItems;
    const fullDrivePath = this.formatFullDrivePath(
      currentStorageDrive,
      currentDrivePath
    );
    const selectedFilePath = `${fullDrivePath}${targetFilePath}`;

    const isActivelyPrinting = DeviceUtils.isActivelyPrinting(
      currentDeviceState,
      device
    );
    const printingFilePath = DeviceUtils.getPrintPath(currentDeviceState);
    const isActivelyPrintingSelectedFile =
      isActivelyPrinting && printingFilePath === selectedFilePath;

    return (
      <ActionButtonWrapper>
        <ActionButton
          width='1.875rem'
          icon={Icons.basic.pencil}
          title='Edit file name'
          disabled={
            !oneItemSelected || folderSelected || isActivelyPrintingSelectedFile
          }
          onClick={(e) => this.showRenameModal(e)}
        />
      </ActionButtonWrapper>
    );
  }

  renderRefreshButton() {
    const { getStorageDrivesPending, getDrivePathFilesPending } = this.props;

    const isLoading = getStorageDrivesPending || getDrivePathFilesPending;

    return (
      <ActionButtonWrapper>
        <ActionButton
          width='1.875rem'
          icon={Icons.basic.refresh}
          title='Refresh list'
          disabled={isLoading}
          onClick={() => this.handleRefresh()}
        />
      </ActionButtonWrapper>
    );
  }

  renderActionButtons() {
    return (
      <ActionButtons>
        {this.renderStartPrintWithFileButton()}
        {this.renderEditFileButton()}
        {this.renderRefreshButton()}
      </ActionButtons>
    );
  }

  renderControlBar() {
    return (
      <ControlBar>
        {this.renderSortControls()}
        {this.renderActionButtons()}
      </ControlBar>
    );
  }

  renderFileItem(item) {
    const extensionStartIndex = item.name.lastIndexOf('.');
    const fileName = item.name.slice(0, extensionStartIndex);
    const fileType = item.name.slice(extensionStartIndex + 1);

    const itemSelected = _.includes(this.state.selectedItems, item.name);

    return (
      <FileItem
        key={item.name}
        selected={itemSelected}
        onClick={(e) => this.handleSelectListItem(e, item.name)}>
        <Icon
          src={Icons.basic.file}
          color={this.props.theme.colors.textTertiary}
        />
        <FileName>{fileName}</FileName>
        <FileType>{fileType}</FileType>
        <FileTimestamp>
          {FormatUtils.isoToDateTimeString(item.dateModified)}
        </FileTimestamp>
      </FileItem>
    );
  }

  renderFolderItem(item) {
    const folderName = item.name.slice(0, -1);

    const itemSelected = _.includes(this.state.selectedItems, item.name);

    return (
      <FileItem
        key={item.name}
        selected={itemSelected}
        onClick={(e) => this.handleSelectListItem(e, item.name)}
        onDoubleClick={(e) => this.handleOpenFolder(e, item.name)}>
        <Icon
          src={Icons.basic.folder}
          color={this.props.theme.colors.textTertiary}
        />
        <FileName>{folderName}</FileName>
        <FileType>Folder</FileType>
        <FileTimestamp>
          {FormatUtils.isoToDateTimeString(item.dateModified)}
        </FileTimestamp>
      </FileItem>
    );
  }

  formatPath(path) {
    if (path === '/') return path;
    if (path.endsWith('/')) return path.slice(0, -1);
    return path;
  }

  renderCurrentPath() {
    const { currentStorageDrive, currentDrivePath } = this.state;
    if (!currentStorageDrive || !currentDrivePath) return null;

    return (
      <CurrentPathContainer>
        <Body1>{this.formatPath(currentDrivePath)}</Body1>
        {currentDrivePath === '/' ? (
          <PathInfo>
            <Body1 grey noSpacing>
              Root
            </Body1>
          </PathInfo>
        ) : (
          <PathInfo onClick={(e) => this.handleFolderNavigateUp(e)}>
            <SubDirIcon>
              <Icon
                src={Icons.basic.subdir}
                color={this.props.theme.colors.textTertiary}
              />
            </SubDirIcon>
            <Body1 grey noSpacing>
              Go up
            </Body1>
          </PathInfo>
        )}
      </CurrentPathContainer>
    );
  }

  renderPaginationControls() {
    const { pageIndex, pages } = this.state;

    if (_.size(pages) <= 1) return null;

    return (
      <PaginationControls>
        <PaginationButtonContainer>
          <ActionButton
            clean
            icon={Icons.basic.first}
            title='First page'
            disabled={pageIndex === 0}
            onClick={() => this.setState({ pageIndex: 0 })}
          />
        </PaginationButtonContainer>
        <PaginationButtonContainer>
          <ActionButton
            clean
            icon={Icons.basic.chevronLeft}
            title='Previous page'
            disabled={pageIndex === 0}
            onClick={() => this.setState({ pageIndex: pageIndex - 1 })}
          />
        </PaginationButtonContainer>
        <PageNumber>
          Page {pageIndex + 1} of {pages.length}
        </PageNumber>
        <PaginationButtonContainer>
          <ActionButton
            clean
            icon={Icons.basic.chevronRight}
            title='Next page'
            disabled={pageIndex + 1 === pages.length}
            onClick={() => this.setState({ pageIndex: pageIndex + 1 })}
          />
        </PaginationButtonContainer>
        <PaginationButtonContainer>
          <ActionButton
            clean
            icon={Icons.basic.last}
            title='Last page'
            disabled={pageIndex + 1 === pages.length}
            onClick={() => this.setState({ pageIndex: pages.length - 1 })}
          />
        </PaginationButtonContainer>
      </PaginationControls>
    );
  }

  renderFilesList() {
    const { getStorageDrivesPending, getDrivePathFilesPending } = this.props;
    const { pages, pageIndex } = this.state;

    const isEmpty = _.isEmpty(pages);
    let placeholderText = '';
    if (getDrivePathFilesPending) {
      placeholderText = 'Loading files…';
    } else if (getStorageDrivesPending) {
      placeholderText = 'Loading storage drives…';
    } else if (isEmpty) {
      placeholderText = 'No files to display';
    }

    return (
      <FilesList>
        {this.renderCurrentPath()}
        {placeholderText ? (
          <Placeholder>{placeholderText}</Placeholder>
        ) : (
          <>
            {_.map(pages[pageIndex], (item) =>
              item.name.endsWith('/')
                ? this.renderFolderItem(item)
                : this.renderFileItem(item)
            )}
          </>
        )}
        {this.renderPaginationControls()}
      </FilesList>
    );
  }

  validateFileName(value) {
    if (value.length === 0) {
      return {
        isValid: false,
        reason: 'Enter a file name',
      };
    }
    if (this.props.device.type === 'canvas-hub' && value.includes(' ')) {
      return {
        isValid: false,
        reason: 'File name cannot contain spaces',
      };
    }
    return true;
  }

  renderRenameModal() {
    const { selectedItems, showRenameModal } = this.state;
    if (!showRenameModal) return null;

    const [targetFilePath] = selectedItems;
    if (!targetFilePath) return null;

    const targetFileName = targetFilePath.slice(
      0,
      targetFilePath.lastIndexOf('.')
    );

    return (
      <RenameModal
        validator={(value) => this.validateFileName(value)}
        initialValue={targetFileName}
        title={`Rename ${targetFileName}`}
        onCancel={() => this.setState({ showRenameModal: false })}
        onSave={(newName) => this.handleRenameFile(newName)}
        onMarginClick={() => this.hideRenameModal()}
      />
    );
  }

  renderFileControls() {
    return (
      <>
        {this.renderControlBar()}
        {this.renderFilesList()}
        {this.renderRenameModal()}
      </>
    );
  }

  renderOfflinePlaceholder() {
    return <Placeholder>Offline</Placeholder>;
  }

  render() {
    const { currentDeviceState } = this.props;
    const isOnline = DeviceUtils.isOnline(currentDeviceState);
    return (
      <Container>
        <TextWrapper>
          <Subtitle1>Files</Subtitle1>
        </TextWrapper>
        {isOnline ? this.renderFileControls() : this.renderOfflinePlaceholder()}
      </Container>
    );
  }
}

export default withTheme(FilesSection);

import styled, { css } from 'styled-components';

import { Breakpoints } from '../../../themes';

export const Content = styled.div`
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const IconField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: flex-start;
  gap: 0.25rem;
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const NameField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InputWrapper = styled.div`
  width: 12.5rem;
`;

export const HeaderLabelWrapper = styled.div`
  grid-column: span 3;

  @media (max-width: ${Breakpoints.smallWidth}) {
    span {
      white-space: normal;
    }
  }
`;

export const IconOption = styled.div`
  border-radius: 50%;
  background: ${(props) => props.theme.colors.greenDefault};
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.2s;
  transition-property: opacity;

  &:hover {
    opacity: 1;
  }

  ${(props) =>
    props.isCurrent &&
    css`
      opacity: 1;
    `};
`;

export const StyleRows = styled.div`
  grid-column: 1 / -1;
`;

export const StyleRow = styled.div`
  margin-bottom: 0.5rem;
`;

export const StyleRowLabel = styled.span``;

export const StyleRowLabelTitle = styled.span`
  font-weight: ${(props) => props.theme.fonts.weightBold};
  margin-right: 1rem;
`;

export const StyleRowLabelHighlights = styled.span`
  color: ${(props) => props.theme.colors.textSecondary};
`;

export const ActionButtonWrapper = styled.div`
  grid-column: 3;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

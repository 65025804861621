import * as THREE from 'three';

const travelVertShader = `
  uniform mediump float bottom;
  uniform mediump float top;
  uniform lowp vec3 color;
  varying lowp vec4 c;

  void main() {
      if (position.z < bottom || position.z > top) {
          c = vec4( 0,0,0,-1);
      } else {
          c = vec4(color, 1);
      }
      vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
      gl_Position = projectionMatrix * modelViewPosition;
  }
`;

const travelFragShader = `
  varying lowp vec4 c;
  uniform lowp float alphaTest;

  void main() {
      if (c.a < alphaTest) discard;
      gl_FragColor = vec4(c.xyz, 0.5);
  }
`;

const travelMaterial = new THREE.ShaderMaterial({
  uniforms: THREE.UniformsUtils.merge([
    {
      top: { value: 16384 }, // support mediump precision
      bottom: { value: -16384 }, // support mediump precision
      color: { value: new THREE.Vector3(0.819, 0.819, 0.819) },
      alphaTest: { value: 0 },
    },
  ]),
  transparent: true,
  fragmentShader: travelFragShader,
  vertexShader: travelVertShader,
});

const makeTravelGeometry = (verts) => {
  if (!(verts instanceof Float32Array)) {
    throw new Error('Invalid vert type');
  }
  const geometry = new THREE.BufferGeometry();
  geometry.addAttribute('position', new THREE.BufferAttribute(verts, 3));
  return new THREE.LineSegments(geometry, travelMaterial);
};

export default makeTravelGeometry;

import React, { Component } from 'react';
import _ from 'lodash';
import { withTheme } from 'styled-components';

import {
  FolderImage,
  SmallFolderImageContainer,
  FolderMoveList,
  FolderMoveItem,
  HeaderWrapper,
  FolderMoveWrapper,
  ModalButtonWrapper,
  MoveToFolderNameWrapper,
  FolderMoveCancelButtonWrapper,
  MoveUpButtonWrapper,
} from '../projectManager.styles';

import { ProjectUtils } from '../../../utils';

import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ActionButton,
  Subtitle1,
  Body1,
} from '../../../shared';

import { Icons } from '../../../themes';

class MoveToFolderModal extends Component {
  constructor(props) {
    super(props);
    const pathFolderId = ProjectUtils.getCurrentFolderPathId(
      props.history.location.pathname
    );
    this.state = {
      selectedMoveFolderId: '', // folder that is currently clicked and selected
      currentMoveFolderId: pathFolderId, // folder which the modal is currently displaying
    };
  }

  handleClickMoveFolder(e, folderId, cannotMoveTo) {
    e.stopPropagation();
    if (cannotMoveTo) return;
    this.setState({ selectedMoveFolderId: folderId });
  }

  handleDoubleClickMoveFolder(e, folderId, cannotMoveTo) {
    e.stopPropagation();
    if (cannotMoveTo) return;
    this.setState({
      selectedMoveFolderId: '',
      currentMoveFolderId: folderId,
    });
  }

  changeCurrentMoveFolderId(folderId) {
    this.setState({
      selectedMoveFolderId: '',
      currentMoveFolderId: folderId || null,
    });
  }

  deselectSelectedMoveFolderId() {
    this.setState({ selectedMoveFolderId: '' });
  }

  renderFolderMoveItem(folder) {
    const { selectedProjectIds } = this.props;
    const { selectedMoveFolderId } = this.state;

    const moveFolderSelected = selectedMoveFolderId === folder.id;
    const cannotMoveTo = _.includes(selectedProjectIds, folder.id);

    return (
      <FolderMoveItem
        key={folder.id}
        id={folder.id}
        selected={moveFolderSelected}
        cannotMoveTo={cannotMoveTo}
        onClick={(e) => this.handleClickMoveFolder(e, folder.id, cannotMoveTo)}
        onDoubleClick={(e) =>
          this.handleDoubleClickMoveFolder(e, folder.id, cannotMoveTo)
        }>
        <SmallFolderImageContainer>
          <FolderImage
            alt='Small folder icon'
            src={this.props.theme.images.folder}
          />
        </SmallFolderImageContainer>
        <MoveToFolderNameWrapper>
          <Body1 cannotMoveTo={cannotMoveTo} noSpacing>
            {folder.name}
          </Body1>
        </MoveToFolderNameWrapper>
      </FolderMoveItem>
    );
  }

  renderFolderMoveList() {
    const { folders } = this.props;
    const { currentMoveFolderId } = this.state;
    const foldersToDisplay = _.reduce(
      folders,
      (content, folder) => {
        const { parentId } = folder;
        const isChildOfCurrentFolder =
          currentMoveFolderId && currentMoveFolderId === parentId;
        const isAtTopMostLevel = !currentMoveFolderId && !parentId;

        if (isChildOfCurrentFolder || isAtTopMostLevel) {
          return [...content, folder];
        }
        return content;
      },
      []
    );
    return (
      <FolderMoveList>
        {_.map(foldersToDisplay, (folder) => this.renderFolderMoveItem(folder))}
      </FolderMoveList>
    );
  }

  renderMoveUpOneLevelButton() {
    const { projectsAndFolders } = this.props;
    const { currentMoveFolderId } = this.state;
    if (!currentMoveFolderId) return null;
    const { parentId } = projectsAndFolders[currentMoveFolderId];
    return (
      <MoveUpButtonWrapper>
        <ActionButton
          clean
          icon={Icons.basic.subdir}
          title='Go up'
          onClick={() => this.changeCurrentMoveFolderId(parentId)}
        />
      </MoveUpButtonWrapper>
    );
  }

  render() {
    const { projectsAndFolders, history, onCancel, onConfirm } = this.props;
    const { selectedMoveFolderId, currentMoveFolderId } = this.state;

    const currentFolderName = currentMoveFolderId
      ? projectsAndFolders[currentMoveFolderId].name
      : 'My Projects';
    const moveToLabel = selectedMoveFolderId ? 'Move' : 'Move Here';

    const pathFolderId = ProjectUtils.getCurrentFolderPathId(
      history.location.pathname
    );
    const inCurrentMoveFolder =
      !selectedMoveFolderId && currentMoveFolderId === pathFolderId;
    const inSelectedMoveFolder =
      selectedMoveFolderId && selectedMoveFolderId === pathFolderId;

    const folderIdToMoveTo = selectedMoveFolderId || currentMoveFolderId;

    return (
      <Modal onPanelClick={(e) => this.deselectSelectedMoveFolderId(e)}>
        <HeaderWrapper>
          <ModalHeader>
            {this.renderMoveUpOneLevelButton()}
            <Subtitle1>{currentFolderName}</Subtitle1>
          </ModalHeader>
        </HeaderWrapper>
        <FolderMoveWrapper
          onClick={(e) => this.deselectSelectedMoveFolderId(e)}>
          {this.renderFolderMoveList()}
        </FolderMoveWrapper>
        <ModalFooter>
          <FolderMoveCancelButtonWrapper>
            <Button
              title='Cancel'
              onClick={(e) => {
                e.stopPropagation();
                onCancel();
              }}>
              Cancel
            </Button>
          </FolderMoveCancelButtonWrapper>
          <ModalButtonWrapper>
            <Button
              primary
              title='Move To Folder'
              disabled={inCurrentMoveFolder || inSelectedMoveFolder}
              onClickKeyTrigger='Enter'
              onClick={() => onConfirm(folderIdToMoveTo)}>
              {moveToLabel}
            </Button>
          </ModalButtonWrapper>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withTheme(MoveToFolderModal);

import { fieldTypes } from '../../../../../constants';
import tooltips from './tooltips.jsx';

export default [
  {
    name: 'angleThresholdTriangle',
    label: 'Face angle threshold',
    tooltip: tooltips.angleThresholdTriangle,
    variants: [
      {
        units: '°',
        type: fieldTypes.number,
        min: 0,
        gte: false,
        max: 360,
        step: 1,
      },
    ],
  },
  {
    name: 'angleThresholdSegment',
    label: 'Region angle threshold',
    tooltip: tooltips.angleThresholdSegment,
    variants: [
      {
        units: '°',
        type: fieldTypes.number,
        min: 0,
        gte: false,
        max: 360,
        step: 1,
      },
    ],
  },
  {
    name: 'sizeThreshold',
    label: 'Minimum region size',
    tooltip: tooltips.sizeThreshold,
    variants: [
      {
        units: 'faces',
        type: fieldTypes.number,
        min: 0,
        gte: false,
        step: 1,
      },
    ],
  },
];

/* eslint-disable */
// Generated from SequenceParser.g4 by ANTLR 4.10.1
// jshint ignore: start
import antlr4 from 'antlr4';

// This class defines a complete listener for a parse tree produced by SequenceParser.
export default class SequenceParserListener extends antlr4.tree.ParseTreeListener {

	// Enter a parse tree produced by SequenceParser#sequence.
	enterSequence(ctx) {
	}

	// Exit a parse tree produced by SequenceParser#sequence.
	exitSequence(ctx) {
	}


	// Enter a parse tree produced by SequenceParser#statements.
	enterStatements(ctx) {
	}

	// Exit a parse tree produced by SequenceParser#statements.
	exitStatements(ctx) {
	}


	// Enter a parse tree produced by SequenceParser#ifBlock.
	enterIfBlock(ctx) {
	}

	// Exit a parse tree produced by SequenceParser#ifBlock.
	exitIfBlock(ctx) {
	}


	// Enter a parse tree produced by SequenceParser#optionalElseBlock.
	enterOptionalElseBlock(ctx) {
	}

	// Exit a parse tree produced by SequenceParser#optionalElseBlock.
	exitOptionalElseBlock(ctx) {
	}


	// Enter a parse tree produced by SequenceParser#whileBlock.
	enterWhileBlock(ctx) {
	}

	// Exit a parse tree produced by SequenceParser#whileBlock.
	exitWhileBlock(ctx) {
	}


	// Enter a parse tree produced by SequenceParser#statement.
	enterStatement(ctx) {
	}

	// Exit a parse tree produced by SequenceParser#statement.
	exitStatement(ctx) {
	}


	// Enter a parse tree produced by SequenceParser#assignment.
	enterAssignment(ctx) {
	}

	// Exit a parse tree produced by SequenceParser#assignment.
	exitAssignment(ctx) {
	}


	// Enter a parse tree produced by SequenceParser#gCode.
	enterGCode(ctx) {
	}

	// Exit a parse tree produced by SequenceParser#gCode.
	exitGCode(ctx) {
	}


	// Enter a parse tree produced by SequenceParser#gCodeText.
	enterGCodeText(ctx) {
	}

	// Exit a parse tree produced by SequenceParser#gCodeText.
	exitGCodeText(ctx) {
	}


	// Enter a parse tree produced by SequenceParser#gCodeEscapedText.
	enterGCodeEscapedText(ctx) {
	}

	// Exit a parse tree produced by SequenceParser#gCodeEscapedText.
	exitGCodeEscapedText(ctx) {
	}


	// Enter a parse tree produced by SequenceParser#gCodeSubExpression.
	enterGCodeSubExpression(ctx) {
	}

	// Exit a parse tree produced by SequenceParser#gCodeSubExpression.
	exitGCodeSubExpression(ctx) {
	}


	// Enter a parse tree produced by SequenceParser#identExpr.
	enterIdentExpr(ctx) {
	}

	// Exit a parse tree produced by SequenceParser#identExpr.
	exitIdentExpr(ctx) {
	}


	// Enter a parse tree produced by SequenceParser#floatExpr.
	enterFloatExpr(ctx) {
	}

	// Exit a parse tree produced by SequenceParser#floatExpr.
	exitFloatExpr(ctx) {
	}


	// Enter a parse tree produced by SequenceParser#unaryOpExpr.
	enterUnaryOpExpr(ctx) {
	}

	// Exit a parse tree produced by SequenceParser#unaryOpExpr.
	exitUnaryOpExpr(ctx) {
	}


	// Enter a parse tree produced by SequenceParser#intExpr.
	enterIntExpr(ctx) {
	}

	// Exit a parse tree produced by SequenceParser#intExpr.
	exitIntExpr(ctx) {
	}


	// Enter a parse tree produced by SequenceParser#functionCall.
	enterFunctionCall(ctx) {
	}

	// Exit a parse tree produced by SequenceParser#functionCall.
	exitFunctionCall(ctx) {
	}


	// Enter a parse tree produced by SequenceParser#binaryOpExpr.
	enterBinaryOpExpr(ctx) {
	}

	// Exit a parse tree produced by SequenceParser#binaryOpExpr.
	exitBinaryOpExpr(ctx) {
	}


	// Enter a parse tree produced by SequenceParser#boolExpr.
	enterBoolExpr(ctx) {
	}

	// Exit a parse tree produced by SequenceParser#boolExpr.
	exitBoolExpr(ctx) {
	}


	// Enter a parse tree produced by SequenceParser#parenExpr.
	enterParenExpr(ctx) {
	}

	// Exit a parse tree produced by SequenceParser#parenExpr.
	exitParenExpr(ctx) {
	}



}

import React from 'react';

import Modal from '../modal/modal.jsx';
import ModalHeader from '../modal/modalHeader.jsx';
import ModalBody from '../modal/modalBody.jsx';
import ModalFooter from '../modal/modalFooter.jsx';
import { ModalContent, ButtonWrapper } from './confirmationModal.styles';

import Button from '../button/button.jsx';

import { Subtitle1OneLine, Body1, Body2 } from '../typography/typography';

const ConfirmationModal = ({
  isWarning = false,
  primaryLabel = 'Are you sure?',
  secondaryLabel = 'Are you really sure?',
  tertiaryLabel = '',
  cancelLabel = 'Cancel',
  confirmLabel = 'Delete',
  onClickCancel = () => {},
  onClickConfirm = () => {},
  onMarginClick = () => {},
}) => (
  <Modal data-test='modal' width='19.3125rem' onMarginClick={onMarginClick}>
    <ModalContent>
      <ModalHeader>
        <Subtitle1OneLine>{primaryLabel}</Subtitle1OneLine>
      </ModalHeader>
      <ModalBody>
        <Body1 noSpacing>{secondaryLabel}</Body1>
        {tertiaryLabel && (
          <Body2 noSpacing red={isWarning}>
            {tertiaryLabel}
          </Body2>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          title={cancelLabel}
          onClick={(e) => {
            e.stopPropagation();
            onClickCancel();
          }}>
          {cancelLabel}
        </Button>
        <ButtonWrapper>
          <Button
            warning={isWarning}
            primary={!isWarning}
            title={confirmLabel}
            onClickKeyTrigger='Enter'
            onClick={() => onClickConfirm()}>
            {confirmLabel}
          </Button>
        </ButtonWrapper>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default ConfirmationModal;

import styled from 'styled-components';
import { DefaultIconContainer } from '../abstractDropdown/abstractDropdown.styles';

export const Container = styled.div`
  position: relative;
`;

export const VariantToggleWrapper = styled.div`
  position: absolute;
  bottom: 0.5rem;
  right: 0.625rem;
  z-index: ${(props) => (props.showOptions ? 20 : 2)};
`;

export const DropDownIconContainer = styled(DefaultIconContainer)`
  margin-left: 0;
`;

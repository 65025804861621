import { connect } from 'react-redux';

import ScaleContents from './scale.jsx';
import actions from '../../../../../reducers/slicer/actions';
import authActions from '../../../../../reducers/auth/actions';
import { SlicerUtils } from '../../../../../utils';

const mapStateToProps = (state) => {
  const { models, transitionTower } = state.slicer;
  const selectedTower =
    transitionTower && transitionTower.active ? transitionTower : null;
  const selectedModels = SlicerUtils.getSelectedModels(models);

  return {
    viewOptions: state.auth.viewOptions,
    models,
    selectedModels,
    selectedTower,
    pivot: state.three.gizmoCenter,
    updateGizmoPending: state.three.status.updateGizmoPending,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateModelTransforms: (transformData) => {
    dispatch(actions.updateModelTransformsRequest(transformData));
  },
  setTowerSize: (size) => {
    dispatch(actions.updateTowerPositionOrSizeRequest(size, 'size'));
  },
  setPercentScale: (percentScale) =>
    dispatch(
      authActions.editAccountRequest({
        viewOptions: { percentScale },
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScaleContents);

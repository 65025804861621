import React, { useContext, useState, useRef } from 'react';

import {
  Container,
  MobileButtonsContainer,
  StampPlacerLabelWrapper,
  ResizableStampContainer,
  ResizableStampImage,
  RotateHandle,
  ResizerTopLeft,
  ResizerTopRight,
  ResizerBottomLeft,
  ResizerBottomRight,
  PlaceStampButtonWrapper,
  CancelStampButtonWrapper,
} from './placer.styles';

import { CancelSaveButtonMobileWrapper } from '../../../paintView.styles';

import { StampingContext } from '../stamping.context';

import { Icons } from '../../../../../../themes';

import { ActionButton, Button } from '../../../../../../shared';

import { Body1 } from '../../../../../../shared/typography/typography';

import { useWindowEvent } from '../../../../../../hooks';

const MINIMUM_IMAGE_WIDTH = 20;
const MINIMUM_IMAGE_HEIGHT = 20;

const StampPlacer = ({
  onPlaceStamp = () => {},
  onCancel = () => {},
  toggleModelOrientationMode = () => {},
  setStampRotation = () => {},
}) => {
  const { stampImageRef, quantizedCanvas, canvas, isOrientingModel } =
    useContext(StampingContext);

  const currentHandleRef = useRef(null);
  const rotateHandleRef = useRef(null);
  const topLeftResizerRef = useRef(null);
  const topRightResizerRef = useRef(null);
  const bottomLeftResizerRef = useRef(null);
  const bottomRightResizerRef = useRef(null);

  const [originalMouseX, setOriginalMouseX] = useState(0);
  const [originalMouseY, setOriginalMouseY] = useState(0);

  const [boxLeft, setBoxLeft] = useState(0);
  const [boxTop, setBoxTop] = useState(0);
  const [boxWidth, setBoxWidth] = useState(0);
  const [boxHeight, setBoxHeight] = useState(0);
  const [boxCenterX, setBoxCenterX] = useState(0);
  const [boxCenterY, setBoxCenterY] = useState(0);
  const [boxOffsetX, setBoxOffsetX] = useState(0);
  const [boxOffsetY, setBoxOffsetY] = useState(0);

  const onPointerDown = (e) => {
    const placerRefs = [
      stampImageRef.current,
      rotateHandleRef.current,
      topLeftResizerRef.current,
      topRightResizerRef.current,
      bottomLeftResizerRef.current,
      bottomRightResizerRef.current,
    ];

    if (!placerRefs.includes(e.target)) return;

    currentHandleRef.current = e.target;

    const newBoxLeft = stampImageRef.current.offsetLeft;
    const newBoxTop = stampImageRef.current.offsetTop;
    const newBoxWidth = stampImageRef.current.offsetWidth;
    const newBoxHeight = stampImageRef.current.offsetHeight;

    setBoxLeft(newBoxLeft);
    setBoxTop(newBoxTop);
    setBoxWidth(newBoxWidth);
    setBoxHeight(newBoxHeight);

    setBoxCenterX(newBoxLeft + newBoxWidth / 2);
    setBoxCenterY(newBoxTop + newBoxHeight / 2);

    setBoxOffsetX(newBoxLeft - e.clientX);
    setBoxOffsetY(newBoxTop - e.clientY);

    setOriginalMouseX(e.clientX);
    setOriginalMouseY(e.clientY);
  };

  const onPointerMove = (e) => {
    if (!currentHandleRef.current) return;

    if (currentHandleRef.current === stampImageRef.current) {
      // translate
      stampImageRef.current.style.left = `${e.clientX + boxOffsetX}px`;
      stampImageRef.current.style.top = `${e.clientY + boxOffsetY}px`;
    } else if (currentHandleRef.current === rotateHandleRef.current) {
      // rotate
      const rad = Math.atan2(e.clientX - boxCenterX, -(e.clientY - boxCenterY));
      const deg = rad * (180 / Math.PI);
      setStampRotation(deg);
      stampImageRef.current.style.transform = `rotate(${deg}deg)`;
    } else {
      // scale
      let newTop = stampImageRef.current.style.top;
      let newLeft = stampImageRef.current.style.left;
      let newWidth = stampImageRef.current.style.width;
      let newHeight = stampImageRef.current.style.height;

      const diffX = e.clientX - originalMouseX;
      const diffY = e.clientY - originalMouseY;
      const boxAspectRatio = boxWidth / boxHeight;

      // hold down SHIFT to ignore current aspect ratio of image
      if (currentHandleRef.current === bottomRightResizerRef.current) {
        // bottom right
        newWidth = boxWidth + diffX;
        newHeight = e.shiftKey ? boxHeight + diffY : newWidth / boxAspectRatio;
      } else if (currentHandleRef.current === bottomLeftResizerRef.current) {
        // bottom left
        newWidth = boxWidth - diffX;
        newHeight = e.shiftKey ? boxHeight + diffY : newWidth / boxAspectRatio;
        newLeft = boxLeft + diffX;
      } else if (currentHandleRef.current === topRightResizerRef.current) {
        // top right
        newWidth = boxWidth + diffX;
        newHeight = e.shiftKey ? boxHeight - diffY : newWidth / boxAspectRatio;
        newTop = boxTop + (boxHeight - newHeight);
      } else if (currentHandleRef.current === topLeftResizerRef.current) {
        // top left
        newWidth = boxWidth - diffX;
        newHeight = e.shiftKey ? boxHeight - diffY : newWidth / boxAspectRatio;
        newTop = boxTop + (boxHeight - newHeight);
        newLeft = boxLeft + diffX;
      }

      if (newWidth < MINIMUM_IMAGE_WIDTH || newHeight < MINIMUM_IMAGE_HEIGHT)
        return;

      // update image
      stampImageRef.current.style.top = `${newTop}px`;
      stampImageRef.current.style.left = `${newLeft}px`;
      stampImageRef.current.style.width = `${newWidth}px`;
      stampImageRef.current.style.height = `${newHeight}px`;
    }
  };

  const onPointerUp = () => {
    currentHandleRef.current = null;
  };

  const onTouchStart = (e) => {
    onPointerDown(e.touches[0]);
  };

  const onTouchMove = (e) => {
    onPointerMove(e.touches[0]);
  };

  const onTouchEnd = () => {
    onPointerUp();
  };

  const onKeyDown = (e) => {
    if (e.key === ' ' && !isOrientingModel) {
      toggleModelOrientationMode(true);
    }
  };

  const onKeyUp = (e) => {
    if (e.key === ' ') {
      toggleModelOrientationMode(false);
    }
  };

  useWindowEvent([
    ['mousedown', onPointerDown],
    ['mousemove', onPointerMove],
    ['mouseup', onPointerUp],

    ['touchstart', onTouchStart],
    ['touchmove', onTouchMove],
    ['touchend', onTouchEnd],

    ['keydown', onKeyDown],
    ['keyup', onKeyUp],
  ]);

  const renderStampImage = () => {
    const defaultWidth = Math.min(quantizedCanvas.width, 500);
    const aspectRatio = quantizedCanvas.width / quantizedCanvas.height;

    return (
      <ResizableStampContainer
        ref={stampImageRef}
        defaultWidth={defaultWidth}
        aspectRatio={aspectRatio}
        isOrientingModel={isOrientingModel}
        maxWidth={canvas.width}>
        <ResizableStampImage alt='Stamp' src={quantizedCanvas.toDataURL()} />
        <RotateHandle ref={rotateHandleRef} />
        <ResizerTopLeft ref={topLeftResizerRef} />
        <ResizerTopRight ref={topRightResizerRef} />
        <ResizerBottomLeft ref={bottomLeftResizerRef} />
        <ResizerBottomRight ref={bottomRightResizerRef} />
      </ResizableStampContainer>
    );
  };

  const renderPlaceStampButtons = () => (
    <>
      <PlaceStampButtonWrapper>
        <Button primary onClick={onPlaceStamp}>
          Place Stamp
        </Button>
      </PlaceStampButtonWrapper>
      <CancelStampButtonWrapper>
        <Button onClick={onCancel}>Cancel</Button>
      </CancelStampButtonWrapper>
    </>
  );

  const renderPlaceStampButtonsMobile = () => (
    <MobileButtonsContainer>
      <CancelSaveButtonMobileWrapper>
        <ActionButton warning icon={Icons.basic.x} onClick={onCancel} />
      </CancelSaveButtonMobileWrapper>
      <CancelSaveButtonMobileWrapper>
        <ActionButton primary icon={Icons.basic.check} onClick={onPlaceStamp} />
      </CancelSaveButtonMobileWrapper>
    </MobileButtonsContainer>
  );

  return (
    <Container>
      {renderStampImage()}
      {renderPlaceStampButtons()}
      {renderPlaceStampButtonsMobile()}
      <StampPlacerLabelWrapper>
        <Body1>Press and hold SPACEBAR to orient the model</Body1>
      </StampPlacerLabelWrapper>
    </Container>
  );
};

export default StampPlacer;

import styled from 'styled-components';

export const Container = styled.div`
  font-family: Monaco;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  box-sizing: border-box;
  border-radius: 0.25rem;
  border: solid 1px ${(props) => props.theme.colors.grey3};
  cursor: text;

  * {
    outline: none;
  }

  ${(props) => props.modeStyles}
`;

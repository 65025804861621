import * as THREE from 'three';

const Utils = {
  identity: () => new THREE.Matrix4(),
  rotateX: (theta, matrix = Utils.identity()) => {
    matrix.makeRotationX(theta);
    return matrix;
  },
  rotateY: (theta, matrix = Utils.identity()) => {
    matrix.makeRotationY(theta);
    return matrix;
  },
  rotateZ: (theta, matrix = Utils.identity()) => {
    matrix.makeRotationZ(theta);
    return matrix;
  },
  scale: (x, y, z, matrix = Utils.identity()) => {
    matrix.makeScale(x, y, z);
    return matrix;
  },
  translate: (x, y, z, matrix = Utils.identity()) => {
    matrix.makeTranslation(x, y, z);
    return matrix;
  },
  fromModelTransforms: (transforms, matrix = Utils.identity()) => {
    matrix.multiply(Utils.rotateX(transforms.rotate.x));
    matrix.multiply(Utils.rotateY(transforms.rotate.y));
    matrix.multiply(Utils.rotateZ(transforms.rotate.z));
    matrix.multiply(
      Utils.scale(transforms.scale.x, transforms.scale.y, transforms.scale.z)
    );
    matrix.setPosition(
      new THREE.Vector3(
        transforms.translate.x,
        transforms.translate.y,
        transforms.translate.z
      )
    );
    return matrix;
  },
  updateMeshTransforms: (mesh, transforms) => {
    /* eslint-disable no-param-reassign */
    mesh.matrix = Utils.fromModelTransforms(transforms);
    mesh.matrix.decompose(mesh.position, mesh.quaternion, mesh.scale);
    mesh.updateMatrixWorld(true);
    mesh.userData.boundingBoxWorld = null;
    /* eslint-enable no-param-reassign */
  },
  getInverse: (matrix) => {
    const inverse = new THREE.Matrix4();
    return inverse.getInverse(matrix, true);
  },
  multiplyByVector3: (matrix, vector) => {
    const m = matrix.clone();
    const v4 = [vector.x, vector.y, vector.z, 1];
    const result = [0, 0, 0, 1];
    for (let i = 0; i < 4; i++) {
      // row i of the matrix, also element i of the result
      for (let j = 0; j < 4; j++) {
        // column j of the matrix
        result[i] += m.elements[4 * j + i] * v4[j];
      }
    }
    return new THREE.Vector3(result[0], result[1], result[2]);
  },
};

export default Utils;

import styled from 'styled-components';
import { Breakpoints } from '../../themes';

export const PrintScriptWrapper = styled.div`
  display: flex;
  width: 100%;
  background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 30rem;

  @media (max-width: ${Breakpoints.largeWidth}) {
    width: 100%;
  }
`;

export const Article = styled.article`
  max-width: 60rem;
  margin: 0 auto;
  padding-bottom: 5rem;
  color: ${(props) => props.theme.colors.textPrimary};

  p {
    line-height: 1.5;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  section {
    margin-bottom: 3rem;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    font-weight: ${(props) => props.theme.fonts.weightBold};
  }

  h3 {
    font-weight: ${(props) => props.theme.fonts.weightBold};
  }

  strong {
    font-weight: ${(props) => props.theme.fonts.weightBold};
  }

  a[href] {
    color: ${(props) => props.theme.colors.greenDark};
    text-decoration: none;
  }
`;

export const Header = styled.header`
  margin-bottom: 3rem;
`;

export const Nav = styled.nav`
  margin-bottom: 4rem;

  ol {
    margin-top: 0;
    margin-bottom: 2rem;
    margin-left: 0;
    padding-left: 0;
    counter-reset: section;
    list-style-type: none;
  }

  ol ol {
    margin-bottom: 0;
  }

  li {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.75;
  }

  ol > li {
    padding-left: 1.5rem;
  }

  ol > li::before {
    display: inline-block;
    margin-right: 1rem;
    counter-increment: section;
    content: counters(section, '.');
  }
`;

export const Main = styled.main`
  ol,
  ul {
    margin-bottom: 2rem;
    margin-left: 0;
    padding-left: 1.25rem;
  }

  li {
    margin-bottom: 0.5rem;
    line-height: 1.4;
  }

  li h4 {
    display: inline-block;
    margin: 0;
    font-size: 1rem;
    font-weight: ${(props) => props.theme.fonts.weightBold};
    text-transform: uppercase;
  }

  li:first-child {
    margin-top: 1rem;
  }
`;

export const SectionNumber = styled.span`
  display: inline-block;
  margin-right: 1rem;
`;

export const SubsectionNumber = styled.span`
  display: inline-block;
  margin-right: 1rem;
`;

export const SectionLink = styled.a`
  display: inline-block;
  position: relative;
  font-size: 1rem;
  margin-left: 0.5rem;
  float: right;
  font-weight: ${(props) => props.theme.fonts.weightRegular};
`;

export const Heightless = styled.span`
  line-height: 0;
`;

/* eslint-disable */
// Generated from SequenceLexer.g4 by ANTLR 4.10.1
// jshint ignore: start
import antlr4 from 'antlr4';


const serializedATN = [4,0,49,324,6,-1,6,-1,6,-1,6,-1,6,-1,2,0,7,0,2,1,7,
1,2,2,7,2,2,3,7,3,2,4,7,4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,2,10,7,
10,2,11,7,11,2,12,7,12,2,13,7,13,2,14,7,14,2,15,7,15,2,16,7,16,2,17,7,17,
2,18,7,18,2,19,7,19,2,20,7,20,2,21,7,21,2,22,7,22,2,23,7,23,2,24,7,24,2,
25,7,25,2,26,7,26,2,27,7,27,2,28,7,28,2,29,7,29,2,30,7,30,2,31,7,31,2,32,
7,32,2,33,7,33,2,34,7,34,2,35,7,35,2,36,7,36,2,37,7,37,2,38,7,38,2,39,7,
39,2,40,7,40,2,41,7,41,2,42,7,42,2,43,7,43,2,44,7,44,2,45,7,45,2,46,7,46,
2,47,7,47,2,48,7,48,2,49,7,49,2,50,7,50,1,0,1,0,1,1,1,1,1,2,1,2,1,2,1,3,
1,3,1,3,1,3,1,3,1,4,1,4,1,4,1,4,1,4,1,4,1,5,1,5,1,5,1,5,1,5,1,6,1,6,1,6,
1,6,1,6,1,6,1,7,1,7,1,8,1,8,1,9,1,9,1,9,1,10,1,10,1,11,1,11,1,12,1,12,1,
12,1,13,1,13,1,13,1,14,1,14,1,14,1,15,1,15,1,15,1,16,1,16,1,16,1,17,1,17,
1,17,1,18,1,18,1,19,1,19,1,20,1,20,1,21,1,21,1,22,1,22,1,23,1,23,1,24,1,
24,1,25,1,25,1,26,1,26,1,27,1,27,1,28,1,28,1,28,1,28,1,28,1,28,1,28,1,28,
1,28,3,28,195,8,28,1,29,1,29,1,29,1,29,1,30,1,30,1,30,1,30,1,31,1,31,1,31,
1,31,1,31,1,32,1,32,5,32,212,8,32,10,32,12,32,215,9,32,1,33,3,33,218,8,33,
1,33,4,33,221,8,33,11,33,12,33,222,1,34,3,34,226,8,34,1,34,5,34,229,8,34,
10,34,12,34,232,9,34,1,34,1,34,4,34,236,8,34,11,34,12,34,237,1,35,4,35,241,
8,35,11,35,12,35,242,1,35,1,35,1,36,1,36,1,36,1,36,1,36,1,36,1,37,1,37,1,
37,1,37,1,37,1,37,1,38,1,38,1,38,1,38,1,38,1,39,4,39,265,8,39,11,39,12,39,
266,1,39,1,39,1,40,1,40,1,40,1,40,1,40,1,40,1,41,1,41,1,41,1,41,1,41,1,42,
4,42,283,8,42,11,42,12,42,284,1,42,1,42,1,43,1,43,1,43,1,43,1,44,1,44,1,
44,1,44,1,45,1,45,1,46,4,46,300,8,46,11,46,12,46,301,1,46,3,46,305,8,46,
1,47,1,47,1,47,1,47,1,48,1,48,1,48,1,48,1,49,1,49,1,50,4,50,318,8,50,11,
50,12,50,319,1,50,3,50,323,8,50,0,0,51,5,0,7,0,9,1,11,2,13,3,15,4,17,5,19,
6,21,7,23,8,25,9,27,10,29,11,31,12,33,13,35,14,37,15,39,16,41,17,43,18,45,
19,47,20,49,21,51,22,53,23,55,24,57,25,59,26,61,27,63,28,65,29,67,30,69,
31,71,32,73,33,75,34,77,35,79,36,81,37,83,38,85,39,87,40,89,41,91,42,93,
43,95,44,97,45,99,46,101,47,103,48,105,49,5,0,1,2,3,4,10,2,0,43,43,45,45,
1,0,48,57,3,0,65,90,95,95,97,122,4,0,48,57,65,90,95,95,97,122,2,0,9,10,32,
32,1,0,10,10,1,0,47,47,1,0,42,42,4,0,10,10,39,39,92,92,123,123,4,0,10,10,
34,34,92,92,123,123,333,0,9,1,0,0,0,0,11,1,0,0,0,0,13,1,0,0,0,0,15,1,0,0,
0,0,17,1,0,0,0,0,19,1,0,0,0,0,21,1,0,0,0,0,23,1,0,0,0,0,25,1,0,0,0,0,27,
1,0,0,0,0,29,1,0,0,0,0,31,1,0,0,0,0,33,1,0,0,0,0,35,1,0,0,0,0,37,1,0,0,0,
0,39,1,0,0,0,0,41,1,0,0,0,0,43,1,0,0,0,0,45,1,0,0,0,0,47,1,0,0,0,0,49,1,
0,0,0,0,51,1,0,0,0,0,53,1,0,0,0,0,55,1,0,0,0,0,57,1,0,0,0,0,59,1,0,0,0,0,
61,1,0,0,0,0,63,1,0,0,0,0,65,1,0,0,0,0,67,1,0,0,0,0,69,1,0,0,0,0,71,1,0,
0,0,0,73,1,0,0,0,0,75,1,0,0,0,0,77,1,0,0,0,0,79,1,0,0,0,1,81,1,0,0,0,1,83,
1,0,0,0,2,85,1,0,0,0,2,87,1,0,0,0,2,89,1,0,0,0,3,91,1,0,0,0,3,93,1,0,0,0,
3,95,1,0,0,0,3,97,1,0,0,0,4,99,1,0,0,0,4,101,1,0,0,0,4,103,1,0,0,0,4,105,
1,0,0,0,5,107,1,0,0,0,7,109,1,0,0,0,9,111,1,0,0,0,11,114,1,0,0,0,13,119,
1,0,0,0,15,125,1,0,0,0,17,130,1,0,0,0,19,136,1,0,0,0,21,138,1,0,0,0,23,140,
1,0,0,0,25,143,1,0,0,0,27,145,1,0,0,0,29,147,1,0,0,0,31,150,1,0,0,0,33,153,
1,0,0,0,35,156,1,0,0,0,37,159,1,0,0,0,39,162,1,0,0,0,41,165,1,0,0,0,43,167,
1,0,0,0,45,169,1,0,0,0,47,171,1,0,0,0,49,173,1,0,0,0,51,175,1,0,0,0,53,177,
1,0,0,0,55,179,1,0,0,0,57,181,1,0,0,0,59,183,1,0,0,0,61,194,1,0,0,0,63,196,
1,0,0,0,65,200,1,0,0,0,67,204,1,0,0,0,69,209,1,0,0,0,71,217,1,0,0,0,73,225,
1,0,0,0,75,240,1,0,0,0,77,246,1,0,0,0,79,252,1,0,0,0,81,258,1,0,0,0,83,264,
1,0,0,0,85,270,1,0,0,0,87,276,1,0,0,0,89,282,1,0,0,0,91,288,1,0,0,0,93,292,
1,0,0,0,95,296,1,0,0,0,97,304,1,0,0,0,99,306,1,0,0,0,101,310,1,0,0,0,103,
314,1,0,0,0,105,322,1,0,0,0,107,108,7,0,0,0,108,6,1,0,0,0,109,110,7,1,0,
0,110,8,1,0,0,0,111,112,5,105,0,0,112,113,5,102,0,0,113,10,1,0,0,0,114,115,
5,101,0,0,115,116,5,108,0,0,116,117,5,115,0,0,117,118,5,101,0,0,118,12,1,
0,0,0,119,120,5,119,0,0,120,121,5,104,0,0,121,122,5,105,0,0,122,123,5,108,
0,0,123,124,5,101,0,0,124,14,1,0,0,0,125,126,5,116,0,0,126,127,5,114,0,0,
127,128,5,117,0,0,128,129,5,101,0,0,129,16,1,0,0,0,130,131,5,102,0,0,131,
132,5,97,0,0,132,133,5,108,0,0,133,134,5,115,0,0,134,135,5,101,0,0,135,18,
1,0,0,0,136,137,5,40,0,0,137,20,1,0,0,0,138,139,5,41,0,0,139,22,1,0,0,0,
140,141,5,123,0,0,141,142,5,123,0,0,142,24,1,0,0,0,143,144,5,123,0,0,144,
26,1,0,0,0,145,146,5,125,0,0,146,28,1,0,0,0,147,148,5,38,0,0,148,149,5,38,
0,0,149,30,1,0,0,0,150,151,5,124,0,0,151,152,5,124,0,0,152,32,1,0,0,0,153,
154,5,61,0,0,154,155,5,61,0,0,155,34,1,0,0,0,156,157,5,33,0,0,157,158,5,
61,0,0,158,36,1,0,0,0,159,160,5,60,0,0,160,161,5,61,0,0,161,38,1,0,0,0,162,
163,5,62,0,0,163,164,5,61,0,0,164,40,1,0,0,0,165,166,5,61,0,0,166,42,1,0,
0,0,167,168,5,33,0,0,168,44,1,0,0,0,169,170,5,45,0,0,170,46,1,0,0,0,171,
172,5,43,0,0,172,48,1,0,0,0,173,174,5,42,0,0,174,50,1,0,0,0,175,176,5,47,
0,0,176,52,1,0,0,0,177,178,5,37,0,0,178,54,1,0,0,0,179,180,5,60,0,0,180,
56,1,0,0,0,181,182,5,62,0,0,182,58,1,0,0,0,183,184,5,44,0,0,184,60,1,0,0,
0,185,186,5,92,0,0,186,187,5,123,0,0,187,195,5,123,0,0,188,189,5,92,0,0,
189,195,5,39,0,0,190,191,5,92,0,0,191,195,5,34,0,0,192,193,5,92,0,0,193,
195,5,92,0,0,194,185,1,0,0,0,194,188,1,0,0,0,194,190,1,0,0,0,194,192,1,0,
0,0,195,62,1,0,0,0,196,197,5,39,0,0,197,198,1,0,0,0,198,199,6,29,0,0,199,
64,1,0,0,0,200,201,5,34,0,0,201,202,1,0,0,0,202,203,6,30,1,0,203,66,1,0,
0,0,204,205,5,125,0,0,205,206,5,125,0,0,206,207,1,0,0,0,207,208,6,31,2,0,
208,68,1,0,0,0,209,213,7,2,0,0,210,212,7,3,0,0,211,210,1,0,0,0,212,215,1,
0,0,0,213,211,1,0,0,0,213,214,1,0,0,0,214,70,1,0,0,0,215,213,1,0,0,0,216,
218,3,5,0,0,217,216,1,0,0,0,217,218,1,0,0,0,218,220,1,0,0,0,219,221,3,7,
1,0,220,219,1,0,0,0,221,222,1,0,0,0,222,220,1,0,0,0,222,223,1,0,0,0,223,
72,1,0,0,0,224,226,3,5,0,0,225,224,1,0,0,0,225,226,1,0,0,0,226,230,1,0,0,
0,227,229,3,7,1,0,228,227,1,0,0,0,229,232,1,0,0,0,230,228,1,0,0,0,230,231,
1,0,0,0,231,233,1,0,0,0,232,230,1,0,0,0,233,235,5,46,0,0,234,236,3,7,1,0,
235,234,1,0,0,0,236,237,1,0,0,0,237,235,1,0,0,0,237,238,1,0,0,0,238,74,1,
0,0,0,239,241,7,4,0,0,240,239,1,0,0,0,241,242,1,0,0,0,242,240,1,0,0,0,242,
243,1,0,0,0,243,244,1,0,0,0,244,245,6,35,3,0,245,76,1,0,0,0,246,247,5,47,
0,0,247,248,5,47,0,0,248,249,1,0,0,0,249,250,6,36,4,0,250,251,6,36,5,0,251,
78,1,0,0,0,252,253,5,47,0,0,253,254,5,42,0,0,254,255,1,0,0,0,255,256,6,37,
6,0,256,257,6,37,5,0,257,80,1,0,0,0,258,259,5,10,0,0,259,260,1,0,0,0,260,
261,6,38,2,0,261,262,6,38,5,0,262,82,1,0,0,0,263,265,8,5,0,0,264,263,1,0,
0,0,265,266,1,0,0,0,266,264,1,0,0,0,266,267,1,0,0,0,267,268,1,0,0,0,268,
269,6,39,5,0,269,84,1,0,0,0,270,271,5,42,0,0,271,272,5,47,0,0,272,273,1,
0,0,0,273,274,6,40,2,0,274,275,6,40,5,0,275,86,1,0,0,0,276,277,5,42,0,0,
277,278,8,6,0,0,278,279,1,0,0,0,279,280,6,41,5,0,280,88,1,0,0,0,281,283,
8,7,0,0,282,281,1,0,0,0,283,284,1,0,0,0,284,282,1,0,0,0,284,285,1,0,0,0,
285,286,1,0,0,0,286,287,6,42,5,0,287,90,1,0,0,0,288,289,5,39,0,0,289,290,
1,0,0,0,290,291,6,43,2,0,291,92,1,0,0,0,292,293,3,23,9,0,293,294,1,0,0,0,
294,295,6,44,7,0,295,94,1,0,0,0,296,297,3,61,28,0,297,96,1,0,0,0,298,300,
8,8,0,0,299,298,1,0,0,0,300,301,1,0,0,0,301,299,1,0,0,0,301,302,1,0,0,0,
302,305,1,0,0,0,303,305,3,25,10,0,304,299,1,0,0,0,304,303,1,0,0,0,305,98,
1,0,0,0,306,307,5,34,0,0,307,308,1,0,0,0,308,309,6,47,2,0,309,100,1,0,0,
0,310,311,3,23,9,0,311,312,1,0,0,0,312,313,6,48,7,0,313,102,1,0,0,0,314,
315,3,61,28,0,315,104,1,0,0,0,316,318,8,9,0,0,317,316,1,0,0,0,318,319,1,
0,0,0,319,317,1,0,0,0,319,320,1,0,0,0,320,323,1,0,0,0,321,323,3,25,10,0,
322,317,1,0,0,0,322,321,1,0,0,0,323,106,1,0,0,0,19,0,1,2,3,4,194,213,217,
222,225,230,237,242,266,284,301,304,319,322,8,5,3,0,5,4,0,4,0,0,0,1,0,5,
1,0,6,0,0,5,2,0,5,0,0];


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

export default class SequenceLexer extends antlr4.Lexer {

    static grammarFileName = "SequenceLexer.g4";
    static channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];
	static modeNames = [ "DEFAULT_MODE", "LINE_COMMENT", "BLOCK_COMMENT", "GCODE_SQ",
                      "GCODE_DQ" ];
	static literalNames = [ null, "'if'", "'else'", "'while'", "'true'", "'false'",
                         "'('", "')'", "'{{'", "'{'", "'}'", "'&&'", "'||'",
                         "'=='", "'!='", "'<='", "'>='", "'='", "'!'", "'-'",
                         "'+'", "'*'", "'/'", "'%'", "'<'", "'>'", "','",
                         null, null, null, "'}}'", null, null, null, null,
                         "'//'", "'/*'", "'\\n'", null, "'*/'" ];
	static symbolicNames = [ null, "IF", "ELSE", "WHILE", "TRUE", "FALSE",
                          "L_PAREN", "R_PAREN", "LL_BRACE", "L_BRACE", "R_BRACE",
                          "AND", "OR", "EQ", "N_EQ", "LT_EQ", "GT_EQ", "ASSIGN",
                          "NOT", "MINUS", "PLUS", "TIMES", "DIV", "MOD",
                          "LT", "GT", "COMMA", "GCODE_ESCAPE", "OPEN_GCODE_SQ",
                          "OPEN_GCODE_DQ", "EXIT_EXPR", "IDENTIFIER", "INT",
                          "FLOAT", "WS", "OPEN_LINE_COMMENT", "OPEN_BLOCK_COMMENT",
                          "CLOSE_LINE_COMMENT", "LINE_COMMENT_TEXT", "CLOSE_BLOCK_COMMENT",
                          "ESCAPED_CLOSE_BLOCK_COMMENT", "BLOCK_COMMENT_TEXT",
                          "CLOSE_GCODE_SQ", "ENTER_EXPR_SQ", "ESCAPE_SEQUENCE_SQ",
                          "TEXT_SQ", "CLOSE_GCODE_DQ", "ENTER_EXPR_DQ",
                          "ESCAPE_SEQUENCE_DQ", "TEXT_DQ" ];
	static ruleNames = [ "SIGN", "DIGIT", "IF", "ELSE", "WHILE", "TRUE", "FALSE",
                      "L_PAREN", "R_PAREN", "LL_BRACE", "L_BRACE", "R_BRACE",
                      "AND", "OR", "EQ", "N_EQ", "LT_EQ", "GT_EQ", "ASSIGN",
                      "NOT", "MINUS", "PLUS", "TIMES", "DIV", "MOD", "LT",
                      "GT", "COMMA", "GCODE_ESCAPE", "OPEN_GCODE_SQ", "OPEN_GCODE_DQ",
                      "EXIT_EXPR", "IDENTIFIER", "INT", "FLOAT", "WS", "OPEN_LINE_COMMENT",
                      "OPEN_BLOCK_COMMENT", "CLOSE_LINE_COMMENT", "LINE_COMMENT_TEXT",
                      "CLOSE_BLOCK_COMMENT", "ESCAPED_CLOSE_BLOCK_COMMENT",
                      "BLOCK_COMMENT_TEXT", "CLOSE_GCODE_SQ", "ENTER_EXPR_SQ",
                      "ESCAPE_SEQUENCE_SQ", "TEXT_SQ", "CLOSE_GCODE_DQ",
                      "ENTER_EXPR_DQ", "ESCAPE_SEQUENCE_DQ", "TEXT_DQ" ];

    constructor(input) {
        super(input)
        this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.PredictionContextCache());
    }

    get atn() {
        return atn;
    }
}

SequenceLexer.EOF = antlr4.Token.EOF;
SequenceLexer.IF = 1;
SequenceLexer.ELSE = 2;
SequenceLexer.WHILE = 3;
SequenceLexer.TRUE = 4;
SequenceLexer.FALSE = 5;
SequenceLexer.L_PAREN = 6;
SequenceLexer.R_PAREN = 7;
SequenceLexer.LL_BRACE = 8;
SequenceLexer.L_BRACE = 9;
SequenceLexer.R_BRACE = 10;
SequenceLexer.AND = 11;
SequenceLexer.OR = 12;
SequenceLexer.EQ = 13;
SequenceLexer.N_EQ = 14;
SequenceLexer.LT_EQ = 15;
SequenceLexer.GT_EQ = 16;
SequenceLexer.ASSIGN = 17;
SequenceLexer.NOT = 18;
SequenceLexer.MINUS = 19;
SequenceLexer.PLUS = 20;
SequenceLexer.TIMES = 21;
SequenceLexer.DIV = 22;
SequenceLexer.MOD = 23;
SequenceLexer.LT = 24;
SequenceLexer.GT = 25;
SequenceLexer.COMMA = 26;
SequenceLexer.GCODE_ESCAPE = 27;
SequenceLexer.OPEN_GCODE_SQ = 28;
SequenceLexer.OPEN_GCODE_DQ = 29;
SequenceLexer.EXIT_EXPR = 30;
SequenceLexer.IDENTIFIER = 31;
SequenceLexer.INT = 32;
SequenceLexer.FLOAT = 33;
SequenceLexer.WS = 34;
SequenceLexer.OPEN_LINE_COMMENT = 35;
SequenceLexer.OPEN_BLOCK_COMMENT = 36;
SequenceLexer.CLOSE_LINE_COMMENT = 37;
SequenceLexer.LINE_COMMENT_TEXT = 38;
SequenceLexer.CLOSE_BLOCK_COMMENT = 39;
SequenceLexer.ESCAPED_CLOSE_BLOCK_COMMENT = 40;
SequenceLexer.BLOCK_COMMENT_TEXT = 41;
SequenceLexer.CLOSE_GCODE_SQ = 42;
SequenceLexer.ENTER_EXPR_SQ = 43;
SequenceLexer.ESCAPE_SEQUENCE_SQ = 44;
SequenceLexer.TEXT_SQ = 45;
SequenceLexer.CLOSE_GCODE_DQ = 46;
SequenceLexer.ENTER_EXPR_DQ = 47;
SequenceLexer.ESCAPE_SEQUENCE_DQ = 48;
SequenceLexer.TEXT_DQ = 49;

SequenceLexer.LINE_COMMENT = 1;
SequenceLexer.BLOCK_COMMENT = 2;
SequenceLexer.GCODE_SQ = 3;
SequenceLexer.GCODE_DQ = 4;





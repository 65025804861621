import { SceneUtils } from '../../utils';

let raycaster = null;
let mouse = null;
let currentScene = null;
let currentCamera = null;

// canvas position, for DOM coords to GL coords
let x = 0;
let y = 0;
let w = window.innerWidth;
let h = window.innerHeight;

export const initializeRaycaster = (
  scene,
  camera,
  top,
  left,
  width,
  height
) => {
  raycaster = SceneUtils.createRaycaster();
  mouse = SceneUtils.createMouse();
  currentScene = scene;
  currentCamera = camera;
  x = left;
  y = top;
  w = width;
  h = height;
};

export const updateCamera = (camera) => {
  currentCamera = camera;
  raycaster.setFromCamera(mouse, currentCamera);
};

export const setCanvasDimensions = (top, left, width, height) => {
  x = left;
  y = top;
  w = width;
  h = height;
};

export const updateMouse = (clientX, clientY) => {
  mouse.x = ((clientX - x) / w) * 2 - 1;
  mouse.y = (-(clientY - y) / h) * 2 + 1;
  raycaster.setFromCamera(mouse, currentCamera);
};

export const destroyRaycaster = () => {
  raycaster = null;
  mouse = null;
  currentScene = null;
  currentCamera = null;
  x = 0;
  y = 0;
  w = window.innerWidth;
  h = window.innerHeight;
};

export const getIntersection = () =>
  raycaster.intersectObjects(currentScene.children);

export const getIntersectionForPainting = (mesh) => {
  const intersections = raycaster.intersectObject(mesh);
  if (intersections.length === 0 || !intersections[0]) return null;
  const [intersection] = intersections;
  const { index } = mesh.geometry;
  const vertexIndex = index.array[intersection.faceIndex * 3];
  intersection.faceIndex = Math.floor(vertexIndex / 3);
  return intersection;
};

export const getIntersectionForSupport = (meshes) => {
  const intersections = raycaster.intersectObjects(meshes);
  if (intersections.length === 0 || !intersections[0]) return null;
  const [intersection] = intersections;
  const { index } = intersection.object.geometry;
  const vertexIndex = index.array[intersection.faceIndex * 3];
  // eslint-disable-next-line no-param-reassign
  intersection.faceIndex = Math.floor(vertexIndex / 3);
  return intersection;
};

import 'regenerator-runtime/runtime';
import { call, select } from 'redux-saga/effects';
import _ from 'lodash';

import { getSlicerState, getThreeState, handleGenericError } from '../common';

import { SlicerUtils, TreeUtils } from '../../utils';

import types from '../../reducers/three/types';
import { getIntersection } from './raycast';
import { setRenderFlag } from './animationFrame';

export default function* highlightModels(action) {
  try {
    const { models, intersectMode, transitionTower } = yield select(
      getSlicerState
    );
    const { hoveredModels, status } = yield select(getThreeState);
    if (intersectMode === 'object' && !_.isEmpty(hoveredModels)) {
      const meshes = [];
      _.forEach(hoveredModels, (node) => {
        if (node === 'tower') {
          // only include tower if not dragging color swatch
          if (!status.isMovingSwatch) meshes.push(transitionTower.mesh);
        } else if (node.type === 'group') {
          // include all children
          node.children.forEach((child) => meshes.push(child.mesh));
        } else {
          // not part of a group; just include this model
          meshes.push(node.mesh);
        }
      });
      SlicerUtils.highlightMeshes(meshes);
    }
    const [intersection] = getIntersection();
    if (intersection) {
      // set cursor to pointer
      document.body.style.cursor = 'pointer';

      const { object } = intersection;
      if (intersectMode === 'object') {
        // highlight whole object
        const meshes = [];

        if (object.name !== types.TOWER_MESH_NAME) {
          if (status.isMovingSwatch) {
            // dragging color swatch; just highlight object directly below cursor
            meshes.push(object);
          } else {
            // intersected regular model
            const currentModel = TreeUtils.searchById(object.name, models);
            const parent = TreeUtils.getParent(models, currentModel);
            if (parent) {
              // this model is part of a group; include all children
              parent.children.forEach((child) => meshes.push(child.mesh));
            } else {
              // not part of a group; just include this model
              meshes.push(object);
            }
          }
        } else if (
          object.name === types.TOWER_MESH_NAME &&
          !status.isMovingSwatch
        ) {
          // intersected tower; include tower only if not dragging color swatch
          meshes.push(object);
        }

        SlicerUtils.highlightMeshes(meshes);
      } else if (intersectMode === 'face') {
        // highlight intersected face
        if (object.name !== types.TOWER_MESH_NAME) {
          SlicerUtils.highlightFace(intersection);
        }
      }
    }
    setRenderFlag();
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SelectPreset from './selectPreset.jsx';
import { actions } from '../../../reducers/printers/printers';

const mapStateToProps = (state) => ({
  printers: state.printers.printers,
  presets: state.printers.presets,
  getPrinterPresetsPending: state.printers.status.getPrinterPresetsPending,
  getPrinterPresetsSuccess: state.printers.status.getPrinterPresetsSuccess,
  createPrinterPending: state.printers.status.createPrinterPending,
  createPrinterSuccess: state.printers.status.createPrinterSuccess,
  createStylesPending: state.printers.status.createStylesPending,
  createStylesSuccess: state.printers.status.createStylesSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getPrinterPresets: () => dispatch(actions.getPrinterPresetsRequest()),
  createPrinter: (printerData) =>
    dispatch(actions.createPrinterRequest(printerData)),
  createStyleProfiles: (printerId, styles) =>
    dispatch(actions.createStyleProfilesRequest(printerId, styles)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SelectPreset)
);

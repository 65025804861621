import React from 'react';
import { useTheme } from 'styled-components';

import { modalOrder, modalMetadata } from './metadata';

import { Button, Subtitle1, Modal } from '../../../shared';

import { ButtonsContainer, ModalContent } from './modalManager.styles';

const ModalManager = (props) => {
  const theme = useTheme();
  const { modals } = props;

  for (let i = 0; i < modalOrder.length; i++) {
    const identifier = modalOrder[i];
    if (modals[identifier].started && !modals[identifier].completed) {
      const meta = modalMetadata[identifier];
      return (
        <Modal width='34rem' height='min-content'>
          <ModalContent>
            <Subtitle1>{meta.title}</Subtitle1>
            {meta.content(theme)}
            <ButtonsContainer>
              <Button primary onClick={() => props.completeModal(identifier)}>
                {meta.proceedButtonLabel || 'Next'}
              </Button>
            </ButtonsContainer>
          </ModalContent>
        </Modal>
      );
    }
  }
  return null;
};

export default ModalManager;

import React from 'react';
import { Body1, Caption } from '../typography/typography';
import {
  ContentWrapper,
  FormItem,
  FormItemContent,
  FormItemDescription,
  FormItemTitle,
  MainFormWrapper,
  StyledFormContainer,
  SubForm,
  SubformContentWrapper,
  SubFormItem,
  TextWrapper,
} from './form.styles';

const Form = ({
  items = [],
  gap = '1rem', // set a custom gap between the form items
}) => {
  function renderDescriptionWithNewLines(description) {
    if (!description) return null;
    if (typeof description !== 'string') return description;
    const lines = description.split('\n');
    return lines.map((str, index) => (
      <Caption key={index} grey noSpacing>
        {str}
      </Caption>
    ));
  }

  return (
    <StyledFormContainer gap={gap}>
      {items.map(
        (
          {
            title, //  provide title for the form item
            description, // provide caption for the form item
            content, // pass component to be displayed (e.g input, dropdown, button...)
            adjustContentPosition = '0rem', // optional - helps to align content to the right edge
            stackedFormItem = false, // optional - if true content renders underneath title & description (inline by default)
            noSubformSpacing = false, // remove top margin from subform
            children = [],
          },
          index
        ) => (
          <FormItem key={index}>
            {!title && !description && !content ? null : (
              <MainFormWrapper stackedFormItem={stackedFormItem}>
                <TextWrapper>
                  <FormItemTitle>
                    <Body1 noSpacing>{title}</Body1>
                  </FormItemTitle>
                  <FormItemDescription>
                    {renderDescriptionWithNewLines(description)}
                  </FormItemDescription>
                </TextWrapper>
                <FormItemContent stackedFormItem={stackedFormItem}>
                  <ContentWrapper adjustContentPosition={adjustContentPosition}>
                    {content}
                  </ContentWrapper>
                </FormItemContent>
              </MainFormWrapper>
            )}
            {children.length > 0 && (
              <SubForm noSubformSpacing={noSubformSpacing}>
                {children.map(
                  (
                    {
                      subformTitle,
                      subformDescription,
                      subformContent,
                      adjustSubformContentPosition = '0rem',
                    },
                    childIndex
                  ) => (
                    <SubFormItem key={childIndex}>
                      <TextWrapper>
                        <FormItemTitle>
                          <Body1 noSpacing>{subformTitle}</Body1>
                        </FormItemTitle>
                        <FormItemDescription>
                          <Caption grey noSpacing>
                            {subformDescription}
                          </Caption>
                        </FormItemDescription>
                      </TextWrapper>
                      <FormItemContent>
                        <SubformContentWrapper
                          adjustSubformContentPosition={
                            adjustSubformContentPosition
                          }>
                          {subformContent}
                        </SubformContentWrapper>
                      </FormItemContent>
                    </SubFormItem>
                  )
                )}
              </SubForm>
            )}
          </FormItem>
        )
      )}
    </StyledFormContainer>
  );
};

export default Form;

import * as THREE from 'three';
import SlicerUtils from '../slicer/slicer';

const TOWER_MIN_DIMENSION = 15;
const MODEL_EDGE_CLEARANCE = 5;
const PRINT_BED_EDGE_CLEARANCE = 8;

const Utils = {
  getLineLength: (x1, y1, x2, y2) => Math.sqrt((y2 - y1) ** 2 + (x2 - x1) ** 2),
  getGoldenRatio: () => (1 + Math.sqrt(5)) / 2,
  getGoldenSqrt: () => Math.sqrt(Utils.getGoldenRatio()),
  formatObjToArr: (tower) => ({
    ...tower,
    brims: {
      ...tower.brims,
      size: [tower.brims.size.x, tower.brims.size.y, tower.brims.size.z],
    },
    size: [tower.size.x, tower.size.y, 1],
  }),
  attemptTowerPlacementRectangular: (
    modelBoundingBox,
    towerX,
    towerY,
    bedSize,
    originOffsets,
    trySides
  ) => {
    /* eslint-disable no-param-reassign */
    if (!trySides) {
      trySides = {
        north: true,
        south: true,
        west: true,
        east: true,
      };
    }
    // models bounding box middle XY
    const { x: midX, y: midY } =
      SlicerUtils.getBoundingBoxCenter(modelBoundingBox);
    const xBedMin = -originOffsets.x;
    const xBedMax = bedSize.x - originOffsets.x;
    const yBedMin = -originOffsets.y;
    const yBedMax = bedSize.y - originOffsets.y;
    const lengths = {
      modelBoundingBox,
      towerX,
      towerY,
      midX,
      midY,
    };
    let boundingBox = new THREE.Box3();
    boundingBox.min.z = 0;
    boundingBox.max.z = 0;

    function checkNorthSouthNudge() {
      if (boundingBox.min.y < yBedMin + PRINT_BED_EDGE_CLEARANCE) {
        // if tower extends off south edge of bed, nudge it north
        const nudge = yBedMin - boundingBox.min.y + PRINT_BED_EDGE_CLEARANCE;
        boundingBox.min.y += nudge;
        boundingBox.max.y += nudge;
      } else if (boundingBox.max.y > yBedMax - PRINT_BED_EDGE_CLEARANCE) {
        // if tower extends off north edge of bed, nudge it south
        const nudge = boundingBox.max.y - yBedMax + PRINT_BED_EDGE_CLEARANCE;
        boundingBox.min.y -= nudge;
        boundingBox.max.y -= nudge;
      }
    }

    function checkEastWestNudge() {
      if (boundingBox.min.x < xBedMin + PRINT_BED_EDGE_CLEARANCE) {
        // if tower extends off west edge of bed, nudge it east
        const nudge = xBedMin - boundingBox.min.x + PRINT_BED_EDGE_CLEARANCE;
        boundingBox.min.x += nudge;
        boundingBox.max.x += nudge;
      } else if (boundingBox.max.x > xBedMax - PRINT_BED_EDGE_CLEARANCE) {
        // if tower extends off east edge of bed, nudge it west
        const nudge = boundingBox.max.x - xBedMax + PRINT_BED_EDGE_CLEARANCE;
        boundingBox.min.x -= nudge;
        boundingBox.max.x -= nudge;
      }
    }

    if (trySides.west) {
      if (
        modelBoundingBox.min.x - MODEL_EDGE_CLEARANCE - towerX >
        xBedMin + PRINT_BED_EDGE_CLEARANCE
      ) {
        // we can fit the tower to the west of the part
        boundingBox = Utils.shapeBBox(boundingBox, lengths, 'west');
        checkNorthSouthNudge();
        return boundingBox;
      }
    }
    if (trySides.north) {
      if (
        modelBoundingBox.max.y + MODEL_EDGE_CLEARANCE + towerY <
        yBedMax - PRINT_BED_EDGE_CLEARANCE
      ) {
        // we can fit the tower to the north of the part
        boundingBox = Utils.shapeBBox(boundingBox, lengths, 'north');
        checkEastWestNudge();
        return boundingBox;
      }
    }
    if (trySides.east) {
      if (
        modelBoundingBox.max.x + MODEL_EDGE_CLEARANCE + towerX <
        xBedMax - PRINT_BED_EDGE_CLEARANCE
      ) {
        // we can fit the tower to the east of the part
        boundingBox = Utils.shapeBBox(boundingBox, lengths, 'east');
        checkNorthSouthNudge();
        return boundingBox;
      }
    }
    if (trySides.south) {
      if (
        modelBoundingBox.min.y - MODEL_EDGE_CLEARANCE - towerY >
        yBedMin + PRINT_BED_EDGE_CLEARANCE
      ) {
        // we can fit the tower to the south of the part
        boundingBox = Utils.shapeBBox(boundingBox, lengths, 'south');
        checkEastWestNudge();
        return boundingBox;
      }
    }
    return null;
    /* eslint-enable no-param-reassign */
  },
  attemptTowerPlacementCircular: (
    modelBoundingBox,
    towerX,
    towerY,
    bedSize,
    originOffsets,
    trySides
  ) => {
    /* eslint-disable no-param-reassign */
    if (!trySides) {
      trySides = {
        north: true,
        south: true,
        west: true,
        east: true,
      };
    }
    const bedRadius = bedSize.x / 2;
    // bed middle XY
    const midX = bedRadius - originOffsets.x;
    const midY = bedRadius - originOffsets.y;
    const lengths = {
      modelBoundingBox,
      towerX,
      towerY,
      midX,
      midY,
    };
    let furthestCornerDistance;
    let boundingBox = new THREE.Box3();
    boundingBox.min.z = 0;
    boundingBox.max.z = 0;
    if (trySides.west) {
      // try west
      boundingBox = Utils.shapeBBox(boundingBox, lengths, 'west');
      furthestCornerDistance = Math.max(
        Utils.getLineLength(boundingBox.min.x, boundingBox.min.y, midX, midY),
        Utils.getLineLength(boundingBox.min.x, boundingBox.max.y, midX, midY)
      );
      if (furthestCornerDistance + PRINT_BED_EDGE_CLEARANCE <= bedRadius) {
        return boundingBox;
      }
    }
    if (trySides.north) {
      // try north
      boundingBox = Utils.shapeBBox(boundingBox, lengths, 'north');
      furthestCornerDistance = Math.max(
        Utils.getLineLength(boundingBox.min.x, boundingBox.max.y, midX, midY),
        Utils.getLineLength(boundingBox.max.x, boundingBox.max.y, midX, midY)
      );
      if (furthestCornerDistance + PRINT_BED_EDGE_CLEARANCE <= bedRadius) {
        return boundingBox;
      }
    }
    if (trySides.east) {
      // try east
      boundingBox = Utils.shapeBBox(boundingBox, lengths, 'east');
      furthestCornerDistance = Math.max(
        Utils.getLineLength(boundingBox.max.x, boundingBox.min.y, midX, midY),
        Utils.getLineLength(boundingBox.max.x, boundingBox.max.y, midX, midY)
      );
      if (furthestCornerDistance + PRINT_BED_EDGE_CLEARANCE <= bedRadius) {
        return boundingBox;
      }
    }
    if (trySides.south) {
      // try south
      boundingBox = Utils.shapeBBox(boundingBox, lengths, 'south');
      furthestCornerDistance = Math.max(
        Utils.getLineLength(boundingBox.min.x, boundingBox.min.y, midX, midY),
        Utils.getLineLength(boundingBox.max.x, boundingBox.min.y, midX, midY)
      );
      if (furthestCornerDistance + PRINT_BED_EDGE_CLEARANCE <= bedRadius) {
        return boundingBox;
      }
    }
    return null;
    /* eslint-enable no-param-reassign */
  },
  positionOnBed(modelsBBox, tower, printerProfile, styleProfile) {
    const { extrusionWidth } = styleProfile;
    const brimCount = tower.brims.count;
    let boundingBox = null;
    let shortSide = tower.size.x;
    let longSide = tower.size.y;
    // try west/east with initial tower size
    if (printerProfile.circular) {
      if (longSide + 2 * PRINT_BED_EDGE_CLEARANCE <= printerProfile.bedSize.y) {
        boundingBox = Utils.attemptTowerPlacementCircular(
          modelsBBox,
          shortSide + brimCount * 2 * extrusionWidth,
          longSide + brimCount * 2 * extrusionWidth,
          printerProfile.bedSize,
          printerProfile.originOffset,
          {
            north: false,
            south: false,
            west: true,
            east: true,
          }
        );
      }
    } else if (
      longSide + 2 * PRINT_BED_EDGE_CLEARANCE <=
      printerProfile.bedSize.y
    ) {
      boundingBox = Utils.attemptTowerPlacementRectangular(
        modelsBBox,
        shortSide + brimCount * 2 * extrusionWidth,
        longSide + brimCount * 2 * extrusionWidth,
        printerProfile.bedSize,
        printerProfile.originOffset,
        {
          north: false,
          south: false,
          west: true,
          east: true,
        }
      );
    }
    if (boundingBox !== null) {
      boundingBox = Utils.unapplyBrims(boundingBox, brimCount, extrusionWidth);
      return Utils.updateTowerPlacement(tower, boundingBox);
    }
    // try north/south with initial tower size
    if (printerProfile.circular) {
      if (longSide + 2 * PRINT_BED_EDGE_CLEARANCE <= printerProfile.bedSize.x) {
        boundingBox = Utils.attemptTowerPlacementCircular(
          modelsBBox,
          longSide + brimCount * 2 * extrusionWidth,
          shortSide + brimCount * 2 * extrusionWidth,
          printerProfile.bedSize,
          printerProfile.originOffset,
          {
            north: true,
            south: true,
            west: false,
            east: false,
          }
        );
      }
    } else if (
      longSide + 2 * PRINT_BED_EDGE_CLEARANCE <=
      printerProfile.bedSize.x
    ) {
      boundingBox = Utils.attemptTowerPlacementRectangular(
        modelsBBox,
        longSide + brimCount * 2 * extrusionWidth,
        shortSide + brimCount * 2 * extrusionWidth,
        printerProfile.bedSize,
        printerProfile.originOffset,
        {
          north: true,
          south: true,
          west: false,
          east: false,
        }
      );
    }
    if (boundingBox !== null) {
      boundingBox = Utils.unapplyBrims(boundingBox, brimCount, extrusionWidth);
      return Utils.updateTowerPlacement(tower, boundingBox);
    }
    let tryPortrait = true;
    let tryLandscape = true;
    while (tryPortrait || tryLandscape) {
      longSide /= 0.9;
      shortSide *= 0.9;
      // the minimum thickness of the tower is 1.5 cm
      if (longSide < TOWER_MIN_DIMENSION || shortSide < TOWER_MIN_DIMENSION) {
        break;
      }
      if (tryPortrait) {
        if (printerProfile.circular) {
          if (
            longSide + 2 * PRINT_BED_EDGE_CLEARANCE >
            printerProfile.bedSize.y
          ) {
            tryPortrait = false;
          } else {
            boundingBox = Utils.attemptTowerPlacementCircular(
              modelsBBox,
              shortSide + brimCount * 2 * extrusionWidth,
              longSide + brimCount * 2 * extrusionWidth,
              printerProfile.bedSize,
              printerProfile.originOffset
            );
          }
        } else if (
          longSide + 2 * PRINT_BED_EDGE_CLEARANCE >
          printerProfile.bedSize.y
        ) {
          tryPortrait = false;
        } else {
          boundingBox = Utils.attemptTowerPlacementRectangular(
            modelsBBox,
            shortSide + brimCount * 2 * extrusionWidth,
            longSide + brimCount * 2 * extrusionWidth,
            printerProfile.bedSize,
            printerProfile.originOffset
          );
        }
        if (boundingBox !== null) {
          boundingBox = Utils.unapplyBrims(
            boundingBox,
            brimCount,
            extrusionWidth
          );
          return Utils.updateTowerPlacement(tower, boundingBox);
        }
      }
      if (tryLandscape) {
        if (printerProfile.circular) {
          if (
            longSide + 2 * PRINT_BED_EDGE_CLEARANCE >
            printerProfile.bedSize.x
          ) {
            tryLandscape = false;
          } else {
            boundingBox = Utils.attemptTowerPlacementCircular(
              modelsBBox,
              longSide + brimCount * 2 * extrusionWidth,
              shortSide + brimCount * 2 * extrusionWidth,
              printerProfile.bedSize,
              printerProfile.originOffset
            );
          }
        } else if (
          longSide + 2 * PRINT_BED_EDGE_CLEARANCE >
          printerProfile.bedSize.x
        ) {
          tryLandscape = false;
        } else {
          boundingBox = Utils.attemptTowerPlacementRectangular(
            modelsBBox,
            longSide + brimCount * 2 * extrusionWidth,
            shortSide + brimCount * 2 * extrusionWidth,
            printerProfile.bedSize,
            printerProfile.originOffset
          );
        }
        if (boundingBox !== null) {
          boundingBox = Utils.unapplyBrims(
            boundingBox,
            brimCount,
            extrusionWidth
          );
          return Utils.updateTowerPlacement(tower, boundingBox);
        }
      }
    }
    return null;
  },
  shapeBBox: (boundingBox, lengths, trySide) => {
    /* eslint-disable no-param-reassign */
    const { modelBoundingBox, towerX, towerY, midX, midY } = lengths;
    const halfX = towerX / 2;
    const halfY = towerY / 2;
    if (trySide === 'north') {
      boundingBox.min.x = midX - halfX;
      boundingBox.max.x = midX + halfX;
      boundingBox.min.y = modelBoundingBox.max.y + MODEL_EDGE_CLEARANCE;
      boundingBox.max.y = boundingBox.min.y + towerY;
    } else if (trySide === 'south') {
      boundingBox.min.x = midX - halfX;
      boundingBox.max.x = midX + halfX;
      boundingBox.max.y = modelBoundingBox.min.y - MODEL_EDGE_CLEARANCE;
      boundingBox.min.y = boundingBox.max.y - towerY;
    } else if (trySide === 'east') {
      boundingBox.min.x = modelBoundingBox.max.x + MODEL_EDGE_CLEARANCE;
      boundingBox.max.x = boundingBox.min.x + towerX;
      boundingBox.min.y = midY - halfY;
      boundingBox.max.y = midY + halfY;
    } else if (trySide === 'west') {
      boundingBox.max.x = modelBoundingBox.min.x - MODEL_EDGE_CLEARANCE;
      boundingBox.min.x = boundingBox.max.x - towerX;
      boundingBox.min.y = midY - halfY;
      boundingBox.max.y = midY + halfY;
    }
    return boundingBox;
    /* eslint-enable no-param-reassign */
  },
  unapplyBrims: (boundingBox, brimCount, extrusionWidth) => {
    /* eslint-disable no-param-reassign */
    boundingBox.min.x += brimCount * extrusionWidth;
    boundingBox.max.x -= brimCount * extrusionWidth;
    boundingBox.min.y += brimCount * extrusionWidth;
    boundingBox.max.y -= brimCount * extrusionWidth;
    return boundingBox;
    /* eslint-enable no-param-reassign */
  },
  updateTowerPlacement: (tower, bbox) => {
    /* eslint-disable no-param-reassign */
    const newPosition = SlicerUtils.getBoundingBoxCenter(bbox);
    const newSize = {
      x: bbox.max.x - bbox.min.x,
      y: bbox.max.y - bbox.min.y,
      z: 1,
    };
    tower.position = {
      x: newPosition.x,
      y: newPosition.y,
      z: newPosition.z,
    };
    tower.size = newSize;
    if (tower.brims) {
      tower.brims = {
        ...tower.brims,
        size: { ...newSize },
      };
    }
    return tower;
    /* eslint-enable no-param-reassign */
  },
  getTowerPadding: (style) => {
    // towers must sit outside the model's brim(s) if present
    const brimWidth = style.brimLoops * style.extrusionWidth + style.brimGap;
    const brimPadding = style.useBrim ? brimWidth : 0;
    // towers have their own raft, so leave room for 2x raft bases
    const raftPadding = style.useRaft ? style.raftXYInflation * 2 + 2 : 0;
    // support inflation may increase the bounding box of the model(s)
    const supportPadding = style.useSupport ? style.supportXYInflation : 0;
    // actual gap requirements for the tower
    return Math.max(raftPadding, supportPadding) + brimPadding;
  },
};

export default Utils;

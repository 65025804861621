import React, { Component } from 'react';
import _ from 'lodash';

import {
  MaterialContent,
  InfoContainer,
  MaterialSelection,
  DropdownContainer,
  Content,
  InfoHighlightWrapper,
  SwapIconWrapper,
} from './spliceSettings.styles';

import Tooltips from './tooltips.jsx';
import Fields from './fields.metadata';
import Utils from './spliceSettings.utils';
import { fieldTypes } from '../../../../constants';
import { Icons, Links } from '../../../../themes';

import { InterfaceUtils, ProjectUtils } from '../../../../utils';

import {
  Body1,
  Button,
  Caption,
  Checkbox,
  ConfirmationModal,
  Dropdown,
  Form,
  Icon,
  Input,
  ToolTipWrapper,
  Link,
} from '../../../../shared';

class SpliceSettings extends Component {
  static defaultProps = {
    saveSpliceSettings: false,
    toggleOnSave: () => {},
    onSpliceSettingsInputChange: () => {},
    onSpliceSettingsInputError: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      paletteType: '',
      paletteModel: '',
      ingoingMaterial: '',
      outgoingMaterial: '',
      spliceSettings: {},
      focusField: null,
      errors: {},
      showCancelModal: false,
      invalidFields: new Set(), // used to enable 'save' button when all the inputs are valid
    };
  }

  componentDidMount() {
    const { project } = this.props;
    this.props.getSpliceSettings();
    if (project) {
      const supported = ProjectUtils.configSupportsSpliceSettings(
        project.deviceConfig
      );
      if (supported) {
        this.setState({
          paletteType: project.deviceConfig.type,
          paletteModel: project.deviceConfig.model,
          ingoingMaterial: this.props.materialId,
          outgoingMaterial: this.props.materialId,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { spliceSettings } = this.props;
    if (prevProps.spliceSettings !== spliceSettings) {
      this.setState({
        spliceSettings,
        errors: _.mapValues(spliceSettings, (byIdPair) =>
          _.mapValues(byIdPair, (bySpliceCore) =>
            _.mapValues(bySpliceCore, () => false)
          )
        ),
      });
    }

    // enable disable save button
    this.onSpliceSettingsInputChange();
    this.onSpliceSettingsInputError();
    if (
      prevProps.saveSpliceSettings !== this.props.saveSpliceSettings &&
      this.props.saveSpliceSettings
    ) {
      this.handleSave();
      this.props.toggleOnSave();
    }
  }

  updateDropdown(fieldName, value) {
    if (value === '-1') return;
    if (
      (fieldName === 'ingoingMaterial' && !this.state.outgoingMaterial) ||
      (fieldName === 'outgoingMaterial' && !this.state.ingoingMaterial)
    ) {
      this.setState({ ingoingMaterial: value, outgoingMaterial: value });
      return;
    }
    this.setState({ [fieldName]: value });
  }

  focusField(fieldName) {
    this.setState({ focusField: fieldName }, () => {
      this.setState({ focusField: null });
    });
  }

  updateField(fieldName, value, error = false) {
    const {
      paletteType,
      paletteModel,
      ingoingMaterial,
      outgoingMaterial,
      spliceSettings,
      errors,
    } = this.state;
    const spliceCore = Utils.getSpliceCore(paletteType, paletteModel);
    const key = `${ingoingMaterial}-${outgoingMaterial}`;
    this.setState({
      spliceSettings: {
        ...spliceSettings,
        [key]: {
          ...spliceSettings[key],
          [spliceCore]: {
            ...spliceSettings[key][spliceCore],
            [fieldName]: value,
          },
        },
      },
      errors: {
        ...errors,
        [key]: {
          ...errors[key],
          [spliceCore]: {
            ...errors[key][spliceCore],
            [fieldName]: error,
          },
        },
      },
    });
  }

  updateFieldSuccess(fieldName, value) {
    const { invalidFields } = this.state;
    this.updateField(fieldName, value, false);
    if (invalidFields.has(fieldName)) {
      invalidFields.delete(fieldName);
      this.setState({ invalidFields: new Set(invalidFields) });
    }
  }

  updateFieldFailure(fieldName, value) {
    const { invalidFields } = this.state;
    this.updateField(fieldName, value, true);
    if (!invalidFields.has(fieldName)) {
      invalidFields.add(fieldName);
      this.setState({ invalidFields: new Set(invalidFields) });
    }
  }

  isDisabled(field) {
    let isDisabled = !!field.disabled;
    if (typeof field.disabled === 'function') {
      isDisabled = field.disabled(this.state);
    }
    return isDisabled;
  }

  getValue(field) {
    let value = this.state[field.name];
    if (typeof field.value === 'function') {
      value = field.value(this.state);
    }
    return value;
  }

  getOptions(field) {
    const { materials, project } = this.props;
    if (typeof field.options === 'function') {
      const filtered = _.filter(materials, (material) => material.id !== '0');
      if (project) {
        return field.options(filtered, project.materialIds);
      }
      return field.options(filtered);
    }
    return field.options;
  }

  selectPalette(typeAndModel) {
    const [type, model] = typeAndModel.split('/');
    this.setState({
      paletteType: type,
      paletteModel: model,
      ingoingMaterial: '',
      outgoingMaterial: '',
    });
  }

  getNextSaveError() {
    // start with errors in the current set of fields
    const {
      paletteType,
      paletteModel,
      ingoingMaterial,
      outgoingMaterial,
      spliceSettings,
      errors,
    } = this.state;
    if (paletteType && paletteModel && ingoingMaterial && outgoingMaterial) {
      const spliceCore = Utils.getSpliceCore(paletteType, paletteModel);
      const key = `${ingoingMaterial}-${outgoingMaterial}`;
      if (errors[key]) {
        const fieldName = _.findKey(errors[key][spliceCore]);
        if (fieldName) {
          return {
            paletteType,
            paletteModel,
            ingoingMaterial,
            outgoingMaterial,
            fieldName,
          };
        }
      }
    }
    // now look for errors in any set of fields
    const usedKeys = _.keys(spliceSettings);
    for (let i = 0; i < usedKeys.length; i++) {
      const key = usedKeys[i];
      const [ingoingId, outgoingId] = _.split(key, '-');
      const usedSpliceCores = _.keys(spliceSettings[key]);
      for (let j = 0; j < usedSpliceCores.length; j++) {
        const spliceCore = usedSpliceCores[j];
        if (errors[key]) {
          const fieldName = _.findKey(errors[key][spliceCore]);
          if (fieldName) {
            return {
              paletteType,
              paletteModel,
              ingoingMaterial: ingoingId,
              outgoingMaterial: outgoingId,
              fieldName,
            };
          }
        }
      }
    }
    return null;
  }

  handleSave() {
    const validationError = this.getNextSaveError();
    if (validationError) {
      const {
        paletteType,
        paletteModel,
        ingoingMaterial,
        outgoingMaterial,
        fieldName,
      } = validationError;
      this.setState(
        {
          paletteType,
          paletteModel,
          ingoingMaterial,
          outgoingMaterial,
        },
        () => {
          this.focusField(fieldName);
        }
      );
      return;
    }

    const { spliceSettings: existingSettings } = this.props;
    const { spliceSettings: newSettings } = this.state;
    const allChanges = Utils.getAllChangedSpliceSettings(
      existingSettings,
      newSettings
    );
    this.props.createSpliceSettings(allChanges);
  }

  showCancelSpliceSettingsModal(e) {
    e.stopPropagation();
    const { spliceSettings: existingSettings } = this.props;
    const { spliceSettings: newSettings } = this.state;
    if (Utils.areSpliceSettingsChanged(existingSettings, newSettings)) {
      this.setState({ showCancelModal: true });
    } else {
      this.handleCancel();
    }
  }

  renderOutgoingSelection() {
    if (!this.state.paletteType || !this.state.paletteModel) return null;
    return this.renderField(
      Fields.materialSelection.fields.outgoingMaterial,
      'outgoingMaterial'
    );
  }

  renderIngoingSelection() {
    if (!this.state.paletteType || !this.state.paletteModel) return null;
    return this.renderField(
      Fields.materialSelection.fields.ingoingMaterial,
      'ingoingMaterial'
    );
  }

  onSwapMaterials() {
    this.setState({
      ingoingMaterial: this.state.outgoingMaterial,
      outgoingMaterial: this.state.ingoingMaterial,
    });
  }

  renderSwapButton() {
    if (!this.state.paletteType || !this.state.paletteModel) return null;
    const disabled = this.state.ingoingMaterial === this.state.outgoingMaterial;
    return (
      <SwapIconWrapper>
        <Button
          clean
          icon={Icons.basic.swap}
          title='Swap material order'
          disabled={disabled}
          onClick={() => this.onSwapMaterials()}>
          Swap
        </Button>
      </SwapIconWrapper>
    );
  }

  renderMaterialSelectionLabel() {
    if (!this.state.paletteType || !this.state.paletteModel) return null;
    return {
      title: 'Material combination',
      description: 'Choose which material is joined to which material.',
      content: null,
    };
  }

  renderMaterialSelection() {
    if (!this.state.paletteType || !this.state.paletteModel) return null;
    return (
      <MaterialSelection>
        {this.renderIngoingSelection()}
        {this.renderSwapButton()}
        {this.renderOutgoingSelection()}
      </MaterialSelection>
    );
  }

  renderMaterialContent() {
    if (
      !this.state.ingoingMaterial ||
      !this.state.outgoingMaterial ||
      this.props.getSpliceSettingsPending ||
      _.isEmpty(this.state.spliceSettings)
    ) {
      return null;
    }
    return (
      <MaterialContent>
        <Caption noSpacing grey>
          Splice parameters
        </Caption>
        {this.renderFieldGroup(Fields.spliceSettings)}
      </MaterialContent>
    );
  }

  renderCancelButton() {
    return (
      <Button onClick={(e) => this.showCancelSpliceSettingsModal(e)}>
        Cancel
      </Button>
    );
  }

  onSpliceSettingsInputChange() {
    const { ingoingMaterial, outgoingMaterial } = this.state;
    const { spliceSettings: existingSettings } = this.props;
    const { spliceSettings: newSettings } = this.state;
    const spliceSettingsChanged = Utils.areSpliceSettingsChanged(
      existingSettings,
      newSettings
    );

    this.props.onSpliceSettingsInputChange(
      ingoingMaterial && outgoingMaterial ? spliceSettingsChanged : false
    );
  }

  onSpliceSettingsInputError() {
    const { invalidFields } = this.state;
    this.props.onSpliceSettingsInputError(invalidFields.size !== 0);
  }

  renderCancelModal() {
    const { showCancelModal } = this.state;
    if (!showCancelModal) return null;

    return (
      <ConfirmationModal
        isWarning
        primaryLabel='You have unsaved changes'
        secondaryLabel='Are you sure you would like to exit without saving?'
        cancelLabel='Close'
        confirmLabel='Exit'
        onClickCancel={() => this.setState({ showCancelModal: false })}
        onClickConfirm={() => this.handleCancel()}
      />
    );
  }

  renderFieldGroup(group) {
    const { fields } = group;
    if (_.isEmpty(fields)) return null;
    return (
      <Form
        gap={'0.75rem'}
        items={this.renderFields(fields).filter((item) => item !== null)}
      />
    );
  }

  renderFields(fields) {
    return _.map(fields, (field) => this.renderField(field));
  }

  renderField(field) {
    if (typeof field.display === 'function') {
      if (!field.display(this.state)) return null;
    }
    const { paletteType, paletteModel, ingoingMaterial, outgoingMaterial } =
      this.state;
    const key = `${ingoingMaterial}-${outgoingMaterial}-${paletteType}-${paletteModel}-${field.name}`;
    switch (field.type) {
      case fieldTypes.number:
        return this.renderNumberField(field, key);
      case fieldTypes.checkbox:
        return this.renderCheckbox(field, key);
      case fieldTypes.dropdown:
        return this.renderDropdown(field, key);
      default:
        return null;
    }
  }

  renderNumberField(field, key) {
    const { min, gte, max, lte, step } = field.custom(this.state);
    let { tooltip } = field;
    if (typeof field.tooltip === 'function') {
      tooltip = field.tooltip(this.state);
    }
    const { paletteType, paletteModel, ingoingMaterial, outgoingMaterial } =
      this.state;
    const spliceCore = Utils.getSpliceCore(paletteType, paletteModel);
    const pairKey = `${ingoingMaterial}-${outgoingMaterial}`;
    const isInvalid = this.state.errors[pairKey][spliceCore][field.name];
    return {
      title: field.label,
      description: `${field.infoLabel}
      Range: ${min} to ${max}`,
      content: (
        <Input
          type='number'
          key={key}
          isInvalid={isInvalid}
          forceFocus={this.state.focusField === field.name}
          infoTooltip={tooltip}
          disabled={this.isDisabled(field)}
          value={this.getValue(field)}
          min={min}
          gte={gte}
          max={max}
          lte={lte}
          step={step}
          units={field.units}
          onChangeSuccess={(value) =>
            this.updateFieldSuccess(field.name, value)
          }
          onChangeFailure={(value) =>
            this.updateFieldFailure(field.name, value)
          }
        />
      ),
    };
  }

  renderDropdown(field, key) {
    let { tooltip } = field;
    if (typeof field.tooltip === 'function') {
      tooltip = field.tooltip(this.state);
    }
    return (
      <DropdownContainer key={key}>
        <ToolTipWrapper info tooltip={tooltip}>
          <Dropdown
            allowFilter
            label={field.label}
            value={this.getValue(field)}
            options={this.getOptions(field)}
            disabled={this.isDisabled(field)}
            onChange={(value) => this.updateDropdown(field.name, value)}
          />
        </ToolTipWrapper>
      </DropdownContainer>
    );
  }

  renderCheckbox(field, key) {
    if (field.type !== fieldTypes.checkbox && !field.canDisable) {
      return null;
    }
    let { tooltip } = field;
    if (typeof field.tooltip === 'function') {
      tooltip = field.tooltip(this.state);
    }
    return {
      title: field.label,
      description: '',
      content: (
        <ToolTipWrapper key={key} info tooltip={tooltip}>
          <Checkbox
            value={this.getValue(field)}
            disabled={this.isDisabled(field)}
            onChange={(e) => this.updateField(field.name, e.target.checked)}
          />
        </ToolTipWrapper>
      ),
    };
  }

  renderPaletteSelection() {
    const options = [
      { label: 'Palette', value: 'palette/p' },
      { label: 'Palette+', value: 'palette/p-plus' },
      { label: 'Palette 2', value: 'palette-2/p2' },
      { label: 'Palette 2 Pro', value: 'palette-2/p2-pro' },
      { label: 'Palette 2S', value: 'palette-2/p2s' },
      { label: 'Palette 2S Pro', value: 'palette-2/p2s-pro' },
      { label: 'Palette 3', value: 'palette-3/p3' },
      { label: 'Palette 3 Pro', value: 'palette-3/p3-pro' },
    ];

    return {
      title: 'Device type',
      description: 'Different devices have different splicing capabilities.',
      content: (
        <DropdownContainer>
          <ToolTipWrapper wide tooltip={Tooltips.spliceCore}>
            <Dropdown
              value={`${this.state.paletteType}/${this.state.paletteModel}`}
              options={options}
              onChange={(typeAndModel) => this.selectPalette(typeAndModel)}
            />
          </ToolTipWrapper>
        </DropdownContainer>
      ),
    };
  }

  getSpliceTuningLink() {
    switch (this.state.paletteType) {
      case 'Palette 2':
        return Links.p2SpliceTuning;
      case 'Palette 2 Pro':
        return Links.p2pSpliceTuning;
      default:
        return Links.legacySpliceTuning;
    }
  }

  renderInfoSection() {
    const link = this.getSpliceTuningLink();
    return (
      <InfoContainer>
        <InfoHighlightWrapper>
          <Icon src={Icons.fdm.material} />
          <Body1 noSpacing>
            Splice settings are associated with material profiles. They are
            applied across projects.
          </Body1>
        </InfoHighlightWrapper>
        <Body1>
          Palette uses a combination of heat, compression, and cooling to join
          filaments together. Splices should be strong enough to feed into your
          extruder without breaking, and have a diameter close to 1.75 mm so
          they feed properly.
        </Body1>
        <Link external href={link}>
          Learn more about splice settings
        </Link>
      </InfoContainer>
    );
  }

  renderDropdownSelections() {
    return (
      <div>
        <Form
          gap='0.75rem'
          items={[
            this.renderPaletteSelection(),
            this.renderMaterialSelectionLabel(),
          ].filter((item) => item !== null)}
        />
        {this.renderMaterialSelection()}
      </div>
    );
  }

  renderContent() {
    return (
      <Content>
        {this.renderInfoSection()}
        {this.renderDropdownSelections()}
        {this.renderMaterialContent()}
        {this.renderCancelModal()}
      </Content>
    );
  }

  renderMainPanelContent() {
    return (
      <>
        {InterfaceUtils.getLoadingSpinner(this.props)}
        {this.renderContent()}
      </>
    );
  }

  render() {
    return this.renderMainPanelContent();
  }
}

export default SpliceSettings;

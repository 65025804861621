import React, { Component, Fragment } from 'react';
import _ from 'lodash';

import {
  BadgeWrapper,
  CheckboxBadgesContainer,
  FieldRow,
  FieldGroupLabel,
  FieldOverrideContainer,
  FieldOverrideMiniSwatchContainer,
  FieldGroup,
  GroupBadgesContainer,
  GroupCheckboxWrapper,
  InputBadgesContainer,
} from './forms.styles';

import {
  Badge,
  BoxSelection,
  Caption,
  Checkbox,
  Input,
  MiniSwatch,
  MultiVariantInput,
  ToolTipWrapper,
} from '../../../../shared';

import ToolTipWithChart from '../../toolTipWithChart/toolTipWithChart.jsx';

import { fieldTypes, slicerBadgeLabels } from '../../../../constants';
import { FormatUtils } from '../../../../utils';
import { Icons } from '../../../../themes';

class StyleSettingsFormSection extends Component {
  static defaultProps = {
    filterBy: '',
    materials: {},
    projectMaterials: [],
    projectColors: [],
  };

  static isFilterMatched(values, meta, filterBy) {
    let { name, label, tags } = meta;
    if (name && name.includes(filterBy)) return true;
    if (!label) return false;
    if (typeof label === 'function') {
      label = label(values);
    }
    if (!filterBy) return true;
    if (label.toLowerCase().includes(filterBy)) return true;
    if (!tags) return false;
    for (let i = 0; i < tags.length; i++) {
      if (tags[i].includes(filterBy)) return true;
    }
    return false;
  }

  static isFieldGroupMatched(values, meta, filterBy) {
    if (StyleSettingsFormSection.isFilterMatched(values, meta, filterBy)) {
      return true;
    }
    const areFieldsMatched = _.some(meta.fields, (field) =>
      StyleSettingsFormSection.isFilterMatched(values, field, filterBy)
    );
    if (areFieldsMatched) {
      return true;
    }
    if (meta.subgroups) {
      return _.some(meta.subgroups, (subgroup) =>
        this.isFieldGroupMatched(values, subgroup, filterBy)
      );
    }
    return false;
  }

  static isSectionMatched() {
    return true;
  }

  handleUndoAction(fieldName) {
    const { initialStyle } = this.props;
    this.updateFieldSuccess(fieldName, initialStyle[fieldName]);
  }

  renderSlicerBadge(slicer) {
    const badgeLabel = slicerBadgeLabels[slicer];
    return <Badge key={slicer}>{badgeLabel}</Badge>;
  }

  renderIconBadge(field) {
    const isDisabled = this.isDisabled(field);
    if (_.includes(this.props.fieldChanges, field.name))
      return (
        <Badge
          icon={Icons.basic.undo}
          disabled={isDisabled}
          onClick={() => this.handleUndoAction(field.name)}
        />
      );
    return null;
  }

  renderFieldGroup(group) {
    const { style, filterBy, projectProps } = this.props;
    if (
      group.slicers &&
      projectProps &&
      !group.slicers.includes(projectProps.slicer)
    ) {
      return null;
    }
    if (
      typeof group.display === 'function' &&
      !group.display(style, projectProps)
    ) {
      return null;
    }
    let overrideFilterBy = filterBy;
    if (StyleSettingsFormSection.isFilterMatched(style, group, filterBy)) {
      overrideFilterBy = '';
    }
    const content = this.renderFields(group.fields, overrideFilterBy);
    let isDirectlyEmpty = false;
    if (_.isEmpty(_.filter(content, _.identity))) {
      let subgroupIsVisible = false;
      if (group.subgroups) {
        subgroupIsVisible = _.some(group.subgroups, (subgroup) =>
          StyleSettingsFormSection.isFieldGroupMatched(
            style,
            subgroup,
            filterBy
          )
        );
      }
      if (!group.displayIfEmpty) {
        if (!subgroupIsVisible) return null;
        isDirectlyEmpty = true;
      }
    }
    return (
      <FieldGroup isEmpty={isDirectlyEmpty}>
        {this.renderFieldGroupLabel(group)}
        {content}
      </FieldGroup>
    );
  }

  renderFieldGroupLabel(group) {
    if (group.label) {
      return (
        <ToolTipWrapper wide tooltip={group.tooltip}>
          <FieldGroupLabel>
            <GroupBadgesContainer>
              <BadgeWrapper>
                {group.slicers &&
                  !this.props.projectProps &&
                  _.map(group.slicers, (slicer, idx) => (
                    <div key={idx}>{this.renderSlicerBadge(slicer)}</div>
                  ))}
                {this.props.fieldChanges && this.renderIconBadge(group)}
              </BadgeWrapper>
            </GroupBadgesContainer>
            {typeof group.isChecked === 'function'
              ? this.renderGroupCheckbox(group)
              : group.label}
          </FieldGroupLabel>
        </ToolTipWrapper>
      );
    }
    return null;
  }

  updateFieldSuccess(fieldName, value) {
    this.props.updateFieldSuccess(fieldName, value);
  }

  updateFieldFailure(fieldName, value) {
    this.props.updateFieldFailure(fieldName, value);
  }

  renderTooltipWithChart(field, currentUserValue, activeVariantIndex = -1) {
    if (!field.tooltip) return null;

    // special case; retrieve current value from object
    let currentValue = currentUserValue;
    if (field.name === 'defaultSupportExtruder') {
      currentValue = currentUserValue.value;
    }

    return (
      <ToolTipWithChart
        field={field}
        currentValue={currentValue}
        activeVariantIndex={activeVariantIndex}
        printerTag={this.props.printerTag}
        settingGroup='styles'
      />
    );
  }

  renderGroupCheckbox(group) {
    if (typeof group.isChecked !== 'function') return null;
    const isDisabled = this.isDisabled(group);
    const isChecked = group.isChecked(this.props.style);
    return (
      <GroupCheckboxWrapper>
        <Checkbox
          boldLabel
          label={group.label}
          value={isChecked}
          disabled={isDisabled}
          onChange={() =>
            group.updateFieldsOnToggle(
              !isChecked,
              this.updateFieldSuccess.bind(this)
            )
          }
        />
      </GroupCheckboxWrapper>
    );
  }

  renderFields(fields, filterBy) {
    return _.map(fields, (field, index) =>
      this.renderField(field, index, filterBy)
    );
  }

  isDisabled(field) {
    if (typeof field.disabled === 'function') {
      return field.disabled(
        this.props.style,
        this.props.slicer,
        this.props.projectProps
      );
    }
    return !!field.disabled;
  }

  renderField(field, index, filterBy) {
    if (
      field.slicers &&
      this.props.projectProps &&
      !field.slicers.includes(this.props.projectProps.slicer)
    ) {
      return null;
    }
    if (typeof field.display === 'function') {
      if (!field.display(this.props.style, this.props.projectProps)) {
        return null;
      }
    } else if (typeof field.display !== 'undefined' && !field.display) {
      return null;
    }
    if (
      StyleSettingsFormSection.isFilterMatched(
        this.props.style,
        field,
        filterBy
      )
    ) {
      return (
        <Fragment key={index}>
          <FieldRow>
            {field.label &&
              field.variants[0].type === fieldTypes.boxSelection && (
                <Caption grey>{field.label}</Caption>
              )}
            <GroupBadgesContainer>
              <BadgeWrapper>
                {field.variants[0].type === fieldTypes.boxSelection &&
                  field.slicers &&
                  !this.props.projectProps &&
                  _.map(field.slicers, (slicer, idx) => (
                    <div key={idx}>{this.renderSlicerBadge(slicer)}</div>
                  ))}
                {field.variants[0].type === fieldTypes.boxSelection &&
                  this.props.fieldChanges &&
                  this.renderIconBadge(field)}
              </BadgeWrapper>
            </GroupBadgesContainer>
            {this.renderFieldContent(field)}
          </FieldRow>
          {this.renderFieldOverride(field, index)}
        </Fragment>
      );
    }
    return null;
  }

  renderFieldOverride(field, index) {
    if (!this.props.projectProps) return null;
    const { materials: projectMaterials, materialIds: projectMaterialIds } =
      this.props.projectProps;

    const fieldUsageFlag = `use${field.name[0].toUpperCase()}${field.name.slice(
      1
    )}`;

    const drivesUsingMaterial = {};
    _.forEach(projectMaterialIds, (id, drive) => {
      if (id !== '0') {
        if (!drivesUsingMaterial[id]) {
          drivesUsingMaterial[id] = [];
        }
        drivesUsingMaterial[id].push(drive);
      }
    });

    const overrideList = [];

    _.forEach(drivesUsingMaterial, (drives, materialId) => {
      if (projectMaterials[materialId].style[fieldUsageFlag]) {
        const key = `${index}.${materialId}`;
        const drivesLabels = [];
        let keyIndex = 0;
        _.forEach(drives, (drive) => {
          const color = this.props.projectProps.colors[drive];
          if (keyIndex > 0) {
            if (keyIndex === drives.length - 1) {
              drivesLabels.push(' and');
            } else {
              drivesLabels.push(', ');
            }
          }
          drivesLabels.push(
            <FieldOverrideMiniSwatchContainer key={keyIndex}>
              <MiniSwatch color={color} />
            </FieldOverrideMiniSwatchContainer>
          );
          drivesLabels.push(drive + 1);
          keyIndex++;
        });
        const usesLabel = drives.length === 1 ? 'uses' : 'use';
        let overrideValue = projectMaterials[materialId].style[field.name];
        let overrideUnits = '';
        if (typeof overrideValue === 'object') {
          overrideUnits = overrideValue.units;
          overrideValue = overrideValue.value;
        } else if (field.variants[0].units) {
          overrideUnits = field.variants[0].units;
        }
        if (overrideUnits) {
          overrideUnits = FormatUtils.formatUnits(overrideUnits, true);
        }
        overrideList.push(
          <div key={key}>
            {FormatUtils.pluralize('Input', drives.length)} {drivesLabels}{' '}
            {usesLabel} {overrideValue}
            {overrideUnits}
          </div>
        );
      }
    });

    if (_.isEmpty(overrideList)) return null;

    return (
      <FieldOverrideContainer key={field.name}>
        {overrideList}
      </FieldOverrideContainer>
    );
  }

  renderFieldContent(field) {
    if (!field || !field.variants || field.variants.length < 1) return null;
    if (field.variants.length > 1) {
      return this.renderMultiVariantFieldContent(field);
    }
    const variant = field.variants[0];
    switch (variant.type) {
      case fieldTypes.number:
        return this.renderNumberField(field, variant);
      case fieldTypes.boxSelection:
        return this.renderBoxSelection(field, variant);
      case fieldTypes.checkbox:
        return this.renderCheckbox(field);
      default:
        return null;
    }
  }

  renderMultiVariantFieldContent(field) {
    const activeVariant = _.findIndex(
      field.variants,
      (v) => v.units === this.props.style[field.name].units
    );
    let { disabled } = field;
    if (typeof field.disabled === 'function') {
      disabled = field.disabled(this.props.style, this.props.slicer);
    }
    const multiValue = this.props.style[field.name].value;
    return (
      <>
        <MultiVariantInput
          label={field.label}
          variants={field.variants}
          style={this.props.style}
          activeVariant={activeVariant}
          value={multiValue}
          isInvalid={this.props.errors[field.name]}
          forceFocus={this.props.focusField === field.name}
          disabled={disabled}
          infoTooltip={this.renderTooltipWithChart(
            field,
            multiValue,
            activeVariant
          )}
          wideTooltip
          onChangeSuccess={(value) =>
            this.onNumberFieldChangeSuccess(field, value)
          }
          onChangeFailure={(value) =>
            this.onNumberFieldChangeFailure(field, value)
          }
        />
        <InputBadgesContainer>
          <BadgeWrapper>
            {field.slicers &&
              !this.props.projectProps &&
              _.map(field.slicers, (slicer, idx) => (
                <div key={idx}>{this.renderSlicerBadge(slicer)}</div>
              ))}
            {this.props.fieldChanges && this.renderIconBadge(field)}
          </BadgeWrapper>
        </InputBadgesContainer>
      </>
    );
  }

  renderNumberField(field, variant) {
    const { min, gte, max, lte, step } = variant;
    let { label, value } = field;
    if (typeof label === 'function') {
      label = field.label(this.props.style);
    }
    let fieldValue = this.props.style[field.name];
    if (typeof value === 'function') {
      fieldValue = field.value(this.props.style);
    } else if (typeof value === 'object') {
      fieldValue = fieldValue.value;
    }
    return (
      <>
        <Input
          type='number'
          label={label}
          isInvalid={this.props.errors[field.name]}
          forceFocus={this.props.focusField === field.name}
          disabled={this.isDisabled(field)}
          value={fieldValue}
          min={min}
          gte={gte}
          max={max}
          lte={lte}
          step={step}
          infoTooltip={this.renderTooltipWithChart(field, fieldValue)}
          wideTooltip
          units={variant.units === 'C' ? '°C' : variant.units}
          onChangeSuccess={(newValue) =>
            this.onNumberFieldChangeSuccess(field, newValue)
          }
          onChangeFailure={(newValue) =>
            this.onNumberFieldChangeFailure(field, newValue)
          }
        />
        <InputBadgesContainer>
          <BadgeWrapper>
            {field.slicers &&
              !this.props.projectProps &&
              _.map(field.slicers, (slicer, idx) => (
                <div key={idx}>{this.renderSlicerBadge(slicer)}</div>
              ))}
            {this.props.fieldChanges && this.renderIconBadge(field)}
          </BadgeWrapper>
        </InputBadgesContainer>
      </>
    );
  }

  renderBoxSelection(field, variant) {
    const { options } = variant;

    let selectableOptions = options;
    if (typeof options === 'function') {
      selectableOptions = options(this.props.style, this.props.projectProps);
    }

    return (
      <ToolTipWrapper
        wide
        tooltip={this.renderTooltipWithChart(
          field,
          this.props.style[field.name]
        )}>
        <BoxSelection
          maxColumns={variant.horizontal ? 4 : 1}
          value={this.props.style[field.name]}
          options={selectableOptions}
          disabled={this.isDisabled(field)}
          onClick={(fieldValue) => this.onBoxSelectionClick(field, fieldValue)}
        />
      </ToolTipWrapper>
    );
  }

  renderCheckbox(field) {
    return (
      <ToolTipWrapper
        wide
        tooltip={this.renderTooltipWithChart(
          field,
          this.props.style[field.name]
        )}>
        <CheckboxBadgesContainer>
          <BadgeWrapper>
            {field.slicers &&
              !this.props.projectProps &&
              _.map(field.slicers, (slicer, idx) => (
                <div key={idx}>{this.renderSlicerBadge(slicer)}</div>
              ))}
            {this.props.fieldChanges && this.renderIconBadge(field)}
          </BadgeWrapper>
        </CheckboxBadgesContainer>
        <Checkbox
          label={field.label}
          disabled={this.isDisabled(field)}
          value={this.props.style[field.name]}
          onChange={() => this.onCheckboxClick(field)}
        />
      </ToolTipWrapper>
    );
  }

  onNumberFieldChangeSuccess(field, value) {
    this.updateFieldSuccess(field.name, value);
  }

  onNumberFieldChangeFailure(field, value) {
    this.updateFieldFailure(field.name, value);
  }

  onBoxSelectionClick(field, fieldValue) {
    this.updateFieldSuccess(field.name, fieldValue);
  }

  onCheckboxClick(field) {
    this.updateFieldSuccess(field.name, !this.props.style[field.name]);
  }

  render() {
    return null;
  }
}

export default StyleSettingsFormSection;

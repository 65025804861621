import styled from 'styled-components';

import { Image } from '../../shared';

export const InfoContainer = styled.div`
  flex-grow: 1;
  min-width: 0;

  height: calc(100% - 2.75rem);
  padding: 1.375rem;

  display: flex;
  flex-direction: column;
  text-align: flex-start;
  justify-content: space-between;
`;

export const PrinterImage = styled(Image)`
  padding: 0.75rem;
`;

export const AdvancedButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

import { connect } from 'react-redux';

import Router from './router.jsx';
import { actions as navActions } from '../reducers/nav/nav';
import { actions as threeActions } from '../reducers/three/three';

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.refreshToken,
  theme: state.auth.preferences.theme,
  currentProject: state.slicer.currentProject,
  projects: state.slicer.projects,
  printers: state.printers.printers,
});

/**
 * Note to us:
 * We generally connect to store from each component via it's container.js.
 * However, since `updateNavStack` is used across many components, I figured it made sense to
 * map the dispatch function from here (i.e., the router) and pass it down as prop to each <Route />
 */
const mapDispatchToProps = (dispatch) => ({
  updateNavStack: (navStack) => dispatch(navActions.updateNavStack(navStack)),
  updatePrintBed: (printer) =>
    dispatch(threeActions.updatePrintBedRequest(printer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);

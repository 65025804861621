import { connect } from 'react-redux';

import { actions as authActions } from '../../../../reducers/auth/auth';
import { actions as iotActions } from '../../../../reducers/iot/iot';

import FilesSection from './files.jsx';

const mapStateToProps = (state) => ({
  sortBy: {
    mode: state.auth.preferences.deviceFilesSortMode,
    direction: state.auth.preferences.deviceFilesSortDirection,
  },
  pendingRequests: state.iot.pendingRequests,
  getStorageDrivesPending: state.iot.status.getStorageDrivesPending,
  getStorageDrivesSuccess: state.iot.status.getStorageDrivesSuccess,
  getDrivePathFilesPending: state.iot.status.getDrivePathFilesPending,
  getDrivePathFilesSuccess: state.iot.status.getDrivePathFilesSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  updatePreferences: (sortMode, sortDirection) =>
    dispatch(
      authActions.editAccountRequest({
        preferences: {
          deviceFilesSortMode: sortMode,
          deviceFilesSortDirection: sortDirection,
        },
      })
    ),
  getStorageDrives: (device) =>
    dispatch(iotActions.getStorageDrivesRequest(device)),
  getDrivePathFiles: (device, fullDrivePath) =>
    dispatch(iotActions.getDrivePathFilesRequest(device, fullDrivePath)),
  editFilePath: (device, filePathData) =>
    dispatch(iotActions.editDeviceFilePathRequest(device, filePathData)),
  forgetDeviceFiles: (device) => dispatch(iotActions.forgetDeviceFiles(device)),
  startPrint: (device, filePath) =>
    dispatch(iotActions.startPrintRequest(device, filePath)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilesSection);

import 'regenerator-runtime/runtime';
import { call, put, select, take } from 'redux-saga/effects';
import _ from 'lodash';

import { handleGenericError, getPrinterState } from '../common';
import { actions, types } from '../../reducers/printers/printers';

const composePrinterData = (currentSharedPrinter, newPrinterName) => {
  const { machineSettings } = currentSharedPrinter;

  // change profile name
  let formattedMachineSettings = {
    ...machineSettings,
    name: newPrinterName,
  };
  // omit timestamp
  formattedMachineSettings = _.omit(formattedMachineSettings, ['timestamp']);

  const printerData = {
    machineSettings: formattedMachineSettings,
    modded: currentSharedPrinter.modded || false,
  };

  // also include tag id, if available
  if (currentSharedPrinter.tagId)
    printerData.tagId = currentSharedPrinter.tagId;
  return printerData;
};

export default function* importSharedPrinter(action) {
  try {
    const { currentSharedPrinter } = yield select(getPrinterState);
    const {
      importPrinter,
      stylesToImport,
      printerId: existingPrinterId,
      newPrinterName,
    } = action.payload;

    // 1. If user is importing the printer profile, create a new printer
    let printerId = existingPrinterId;
    if (importPrinter) {
      // create new printer

      const printerData = composePrinterData(
        currentSharedPrinter,
        newPrinterName
      );
      yield put(actions.createPrinterRequest(printerData));

      // wait for createPrinterSuccess
      const createPrinterSuccessAction = yield take(
        types.CREATE_PRINTER_SUCCESS
      );
      printerId = createPrinterSuccessAction.payload.printer.id;
    }

    // 2. Then, create style(s) for the selected printerId
    if (!_.isEmpty(stylesToImport)) {
      yield put(actions.createStyleProfilesRequest(printerId, stylesToImport));
      // wait for createStyleProfilesSuccess
      yield take(types.CREATE_STYLE_PROFILES_SUCCESS);
    }

    // finally, dispatch importSharedPrinterSuccess
    yield put(actions.importSharedPrinterSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import ProfileParser from './parser';

import { SupportStyles } from './enums';
import FormatUtils from '../format/format';

const { roundTo } = FormatUtils;

const parseKsp = (input) => {
  const contents = {};
  const lines = input.split(/\r|\n|\r\n/);
  let lastKey = null;
  for (let i = 0; i < lines.length; i++) {
    let line = lines[i];
    if (line.startsWith('; >')) {
      // decoded G-code sequences
    } else if (line.startsWith('; ^')) {
      // continuation of previous line
      if (lastKey) {
        line = line.slice(3).trim();
        contents[lastKey] += line;
      }
    } else {
      if (line[0] === ';') {
        line = line.slice(1);
      }
      line = line.trim();
      if (line.length > 0) {
        const [key, value] = line.split(' = ');
        if (key) {
          contents[key] = value;
          lastKey = key;
        }
      }
    }
  }
  return contents;
};

const decodeHexString = (input) => {
  let ascii = '';
  for (let i = 0; i < input.length; i += 2) {
    ascii += String.fromCharCode(parseInt(input.slice(i, i + 2), 16));
  }
  return ascii;
};

const interpolateSpeedQuality = (lo, hi, speedQuality) =>
  lo + (hi - lo) * speedQuality;

const floatOrPercentMultiplier = (num, multiplicand) => {
  if (num < 0) {
    return (multiplicand * -num) / 100;
  }
  return num;
};

const getInfillDensity = (denominator) => {
  //   Density   Denominator
  //   ---------------------
  //   100%      0
  //   50%       1
  //   33.3%     2
  //   25%       3
  //   20%       4
  //   16.7%     5
  //   12.5%     6
  //   10%       7
  //   5%        8
  //   2.5%      9
  //   Hollow    10
  //   Vase      11
  if (denominator === 11) return [0, true];
  if (denominator === 10) return [0, false];
  if (denominator === 9) return [3, false];
  if (denominator === 8) return [5, false];
  if (denominator === 7) return [10, false];
  if (denominator === 6) return [13, false];
  if (denominator === 5) return [17, false];
  if (denominator === 4) return [20, false];
  if (denominator === 3) return [25, false];
  if (denominator === 2) return [34, false];
  if (denominator === 1) return [50, false];
  if (denominator === 0) return [100, false];
  return [15, false];
};

export default class KISSlicerParser extends ProfileParser {
  cleanGCode(gcode) {
    return super.cleanGCode(decodeHexString(gcode));
  }

  parse(input) {
    const defaultPrinter = ProfileParser.getDefaultPrinter();
    const { machineSettings } = defaultPrinter;
    const style = defaultPrinter.styles[0];
    const warnings = {};

    const ksp = parseKsp(input);

    // profile name
    machineSettings.name = ksp.printer_name;

    // file extension
    machineSettings.extension = ksp.extension;

    // G-code sequences
    machineSettings.startSequence = this.cleanGCode(ksp.g_code_prefix);
    machineSettings.endSequence = this.cleanGCode(ksp.g_code_postfix);

    // add comments?
    machineSettings.addComments = ksp.add_comments === '1';

    // firmware type
    machineSettings.firmwareType = parseInt(ksp.firmware_type, 10);

    // firmware retraction
    machineSettings.firmwareRetraction = ksp.add_m101_g10 === '0' ? 0 : 2;

    // extruders
    {
      machineSettings.extruderCount = parseInt(ksp.num_extruders, 10);
      const nozzleDiameters = [];
      const filamentDiameters = [];
      for (let i = 0; i < machineSettings.extruderCount; i++) {
        nozzleDiameters.push(
          roundTo(parseFloat(ksp[`nozzle_dia_${i + 1}`]), 2)
        );
        filamentDiameters.push(roundTo(parseFloat(ksp.fiber_dia_mm), 2));
      }
      machineSettings.nozzleDiameter = nozzleDiameters;
      machineSettings.filamentDiameter = filamentDiameters;
      machineSettings.bowdenTubeLength =
        Math.round(parseFloat(ksp.drive_len_1)) || 0;
    }

    // bed shape
    machineSettings.circular = ksp.round_bed !== '0';
    machineSettings.bedSize = {
      x: roundTo(parseFloat(ksp.bed_size_x_mm), 2),
      y: roundTo(parseFloat(ksp.bed_size_y_mm), 2),
      z: roundTo(parseFloat(ksp.bed_size_z_mm), 2),
    };
    machineSettings.originOffset = {
      x: roundTo(
        parseFloat(ksp.bed_offset_x_mm) - machineSettings.bedSize[0] / 2,
        2
      ),
      y: roundTo(
        parseFloat(ksp.bed_offset_y_mm) - machineSettings.bedSize[1] / 2,
        2
      ),
    };

    // general
    const speedQuality = parseInt(ksp.quality_percentage, 10) / 100;
    style.skinThickness = {
      units: 'mm',
      value: roundTo(parseFloat(ksp.skin_thickness_mm), 2),
    };
    style.solidLayerSpeed = roundTo(
      interpolateSpeedQuality(
        parseFloat(ksp.lo_speed_solid_mm_per_s),
        parseFloat(ksp.hi_speed_solid_mm_per_s),
        speedQuality
      ),
      1
    );
    style.perimeterCount = Math.round(parseFloat(ksp.num_loops));
    style.perimeterOrder = ksp.loops_insideout === '0' ? 0 : 2;
    style.perimeterSpeed = {
      units: 'mm/s',
      value: roundTo(
        interpolateSpeedQuality(
          parseFloat(ksp.lo_speed_loops_mm_per_s),
          parseFloat(ksp.hi_speed_loops_mm_per_s),
          speedQuality
        ),
        1
      ),
    };
    style.externalPerimeterSpeed = {
      units: 'mm/s',
      value: roundTo(
        interpolateSpeedQuality(
          parseFloat(ksp.lo_speed_perim_mm_per_s),
          parseFloat(ksp.hi_speed_perim_mm_per_s),
          speedQuality
        ),
        1
      ),
    };
    style.seamAngle = parseInt(ksp.seam_angle_degrees, 10);
    style.seamJitter = parseInt(ksp.seam_jitter_degrees, 10);
    style.seamOnCorners = ksp.use_corners !== '0';
    style.rapidXYSpeed = roundTo(parseFloat(ksp.travel_speed_mm_per_s), 1);
    style.rapidZSpeed = roundTo(parseFloat(ksp.z_speed_mm_per_s), 1);
    style.monotonicSweep = ksp.monotonic_sweep === '1';

    // extrusion
    style.extrusionWidth = roundTo(parseFloat(ksp.extrusion_width_mm), 2);
    style.extrusionMultiplier = Math.floor(parseFloat(ksp.ext_gain_1) * 100);
    style.gapFillMinLength = roundTo(parseFloat(ksp.crowning_threshold_mm), 1);
    style.useGapFill = style.gapFillMinLength > 0;
    style.retractLength = roundTo(parseFloat(ksp.destring_length), 1);
    style.useRetracts = style.retractLength > 0;
    style.retractSpeed = roundTo(parseFloat(ksp.destring_speed_mm_per_s), 1);
    style.retractDisableThreshold = roundTo(parseFloat(ksp.destring_min_mm), 1);
    style.retractForceThreshold = roundTo(
      parseFloat(ksp.destring_trigger_mm),
      1
    );
    style.wipeLength = roundTo(parseFloat(ksp.wipe_mm), 1);
    style.zLift = roundTo(parseFloat(ksp.Z_lift_mm), 1);

    // layer heights
    style.layerHeight = roundTo(
      floatOrPercentMultiplier(
        parseFloat(ksp.layer_thickness_mm),
        style.extrusionWidth
      ),
      2
    );
    style.maxLayerHeight = {
      units: 'mm',
      value: roundTo(
        floatOrPercentMultiplier(
          parseFloat(ksp.max_layer_thickness_mm),
          style.extrusionWidth
        ),
        2
      ),
    };
    if (style.maxLayerHeight.value === 0) {
      style.maxLayerHeight = {
        units: '%',
        value: 100,
      };
    } else {
      style.useVariableLayerHeight = true;
    }
    {
      const unsupportedStepover = roundTo(
        parseFloat(ksp.unsupported_stepover_mm),
        2
      );
      if (unsupportedStepover < 0) {
        style.unsupportedStepover = {
          units: '%',
          value: -unsupportedStepover,
        };
      } else {
        style.unsupportedStepover = {
          units: 'mm',
          value: unsupportedStepover,
        };
      }
      const supportedStepover = roundTo(
        parseFloat(ksp.supported_stepover_mm),
        2
      );
      if (supportedStepover < 0) {
        style.supportedStepover = {
          units: '%',
          value: -supportedStepover,
        };
      } else {
        style.supportedStepover = {
          units: 'mm',
          value: supportedStepover,
        };
      }
    }
    style.firstLayerHeight = {
      units: 'mm',
      value: roundTo(
        floatOrPercentMultiplier(
          parseFloat(ksp.first_layer_thickness_mm),
          style.extrusionWidth
        ),
        2
      ),
    };

    // infill
    {
      const denominator = parseInt(ksp.infill_density_denominator, 10);
      const [infillDensity, vaseMode] = getInfillDensity(denominator);
      style.infillDensity = infillDensity;
      style.spiralVaseMode = vaseMode;
    }
    style.infillStyle = parseInt(ksp.infill_st_oct_rnd, 10);
    style.infillExtrusionWidth = {
      units: 'mm',
      value: roundTo(parseFloat(ksp.infill_extrusion_width), 2),
    };
    style.infillSpeed = {
      units: 'mm/s',
      value: roundTo(
        interpolateSpeedQuality(
          parseFloat(ksp.lo_speed_sparse_mm_per_s),
          parseFloat(ksp.hi_speed_sparse_mm_per_s),
          speedQuality
        ),
        1
      ),
    };
    style.infillPerimeterOverlap = Math.round(
      parseFloat(ksp.solid_loop_overlap_fraction) * 200
    );

    // temperature/cooling
    style.printTemperature = parseInt(ksp.temperature_C, 10);
    style.firstLayerPrintTemperature = {
      units: 'C',
      value: parseInt(ksp.first_layer_C, 10),
    };
    style.bedTemperature = parseInt(ksp.bed_C, 10);
    style.fanSpeed = parseInt(ksp.fan_cool_percent, 10);
    style.perimeterFanSpeed = {
      units: '%',
      value: parseInt(ksp.fan_loops_percent, 10),
    };
    style.perimeterFanSpeed = {
      units: '%',
      value: parseInt(ksp.fan_loops_percent, 10),
    };
    style.useFan = style.fanSpeed > 0;
    style.enableFanAtLayer = Math.floor(
      parseFloat(ksp.fan_Z_mm) / style.layerHeight
    );
    style.minLayerTime = parseInt(ksp.min_layer_time_s, 10);

    // first layer
    style.zOffset = roundTo(parseFloat(ksp.bed_offset_z_mm), 2);
    style.firstLayerSpeed = {
      units: 'mm/s',
      value: roundTo(parseFloat(ksp.first_layer_speed_mm_per_s), 1),
    };
    const brimLoops = Math.ceil(parseFloat(ksp.brim_mm) / style.extrusionWidth);
    const brimLayers = Math.ceil(
      parseFloat(ksp.brim_ht_mm) / style.layerHeight
    );
    style.useBrim = brimLoops > 0 && brimLayers > 0;
    style.brimLoops = Math.max(brimLoops, 1);
    style.brimLayers = Math.max(brimLayers, 1);
    style.brimGap = roundTo(parseFloat(ksp.brim_gap_mm), 1);
    style.useRaft = ksp.raft_mode !== '0';
    style.defaultRaftExtruder = parseInt(ksp.raft_ext, 10);
    style.raftXYInflation = roundTo(parseFloat(ksp.raft_inflate_mm), 2);
    style.upperRaftSpeed = {
      units: 'mm/s',
      value: roundTo(parseFloat(ksp.top_raft_speed_mm_per_s), 1),
    };
    style.lowerRaftSpeed = {
      units: 'mm/s',
      value: roundTo(parseFloat(ksp.bottom_raft_speed_mm_per_s), 1),
    };
    style.raftZGap = roundTo(parseFloat(ksp.first_Z_gap_mm), 2);
    style.upperRaftLayerHeight = {
      units: 'mm',
      value: roundTo(parseFloat(ksp.raft_hi_thick_mm), 2),
    };
    style.lowerRaftLayerHeight = {
      units: 'mm',
      value: roundTo(parseFloat(ksp.raft_lo_thick_mm), 2),
    };
    style.upperRaftExtrusionWidth = {
      units: 'mm',
      value: roundTo(parseFloat(ksp.raft_hi_width_mm), 2),
    };
    style.lowerRaftExtrusionWidth = {
      units: 'mm',
      value: roundTo(parseFloat(ksp.raft_lo_width_mm), 2),
    };
    style.upperRaftDensity = {
      units: '%',
      value: Math.round(
        (style.upperRaftExtrusionWidth.value /
          parseFloat(ksp.raft_hi_stride_mm)) *
          100
      ),
    };
    style.lowerRaftDensity = {
      units: '%',
      value: Math.round(
        (style.lowerRaftExtrusionWidth.value /
          parseFloat(ksp.raft_lo_stride_mm)) *
          100
      ),
    };

    // supports
    style.useSupport = ksp.support_density !== '0';
    style.supportDensity =
      parseInt(ksp.support_density, 10) || SupportStyles.DENSE;
    if (style.supportDensity % 2 !== 0) {
      style.supportDensity += 1;
    }
    style.maxOverhangAngle = parseInt(ksp.support_angle_deg, 10);
    style.defaultSupportExtruder = {
      units: 'index',
      value: parseInt(ksp.support_body_ext, 10),
    };
    style.supportZGap = {
      units: 'mm',
      value: roundTo(parseFloat(ksp.interface_Z_gap_mm), 2),
    };
    style.supportXYGap = roundTo(
      floatOrPercentMultiplier(
        parseFloat(ksp.support_gap_mm),
        style.extrusionWidth
      ),
      2
    );
    style.supportXYInflation = roundTo(parseFloat(ksp.support_inflate_mm), 2);
    style.useSupportInterface =
      ksp.solid_interface !== '0' || ksp.use_lower_interface !== '0';
    style.defaultSupportInterfaceExtruder = parseInt(ksp.support_ext, 10);
    style.supportInterfaceExtrusionWidth = {
      units: 'mm',
      value: roundTo(parseFloat(ksp.interface_width_mm), 2),
    };
    style.supportInterfaceDensity = {
      units: '%',
      value: Math.min(
        100,
        Math.round(
          (style.supportInterfaceExtrusionWidth.value /
            parseFloat(ksp.interface_stride_mm)) *
            100
        )
      ),
    };
    style.supportInterfaceThickness = {
      units: 'mm',
      value: roundTo(parseFloat(ksp.interface_band_mm), 2),
    };
    style.useRaftInterfaces = parseInt(ksp.raft_interface_layers, 10) > 0;

    return {
      machineSettings,
      styles: [style],
      materials: [],
      warnings,
    };
  }
}

import styled from 'styled-components';
import { Breakpoints } from '../../../themes';
import shadows from '../../../themes/shadows';

export const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  ${shadows.d2}
  border-radius: 0.5rem;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.grey4};
  background-color: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
`;

export const EditorAndOutput = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media (min-width: ${Breakpoints.smallWidth}) {
    flex-direction: row;
  }
`;

export const Output = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  border-top: solid 1px ${(props) => props.theme.colors.grey4};
  background-color: ${(props) => props.theme.colors.grey1};
  overflow-y: auto;
  padding-bottom: 8rem;
  @media (min-width: ${Breakpoints.smallWidth}) {
    border-top: none;
    border-left: solid 1px ${(props) => props.theme.colors.grey4};
  }
`;

export const EditorWrapper = styled.div`
  position: relative;
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  border: none;
  border-radius: 0;

  .CodeMirror {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
    height: auto !important;
  }
`;

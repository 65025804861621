import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../canvas-api';
import { handleGenericError } from '../common';
import { actions } from '../../reducers/printers/printers';

export default function* createPrinterTag(action) {
  const { tagName } = action.payload;
  try {
    const response = yield call(API, {
      path: 'printers/tags',
      method: methods.PUT,
      body: {
        tagName,
      },
    });

    if (response === null) return;

    const { tag } = response;

    yield put(actions.createPrinterTagSuccess(tag));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import Breakpoints from './breakpoints';
import { LightColors, DarkColors } from './colors';
import Fonts from './fonts';
import Icons from './icons';
import { LightImages, DarkImages } from './images';
import Links from './links';
import Dates from './dates';
import Shadows from './shadows';

const THEMES = {
  light: 'light',
  dark: 'dark',
};

const getTheme = (themeName) => {
  switch (themeName) {
    case THEMES.light:
      return {
        name: THEMES.light,
        colors: LightColors,
        fonts: Fonts,
        images: LightImages,
      };
    case THEMES.dark:
      return {
        name: THEMES.dark,
        colors: DarkColors,
        fonts: Fonts,
        images: DarkImages,
      };
    default:
      return {
        name: THEMES.light,
        colors: LightColors,
        fonts: Fonts,
        images: LightImages,
      };
  }
};

export {
  Breakpoints,
  LightColors,
  Fonts,
  Icons,
  LightImages,
  Links,
  Dates,
  Shadows,
  getTheme,
};

import React, { Component } from 'react';
import _ from 'lodash';

import { Container, FormsContainer } from './styleSettingsForm.styles';

import FormPanel from './formPanel.jsx';
import { Forms } from './fields.metadata';
import { SearchBar } from '../../../../shared';

class StyleSettingsForm extends Component {
  static defaultProps = {
    style: {},
    errors: {},
    focusField: null,
    updateFieldSuccess: () => {},
    updateFieldFailure: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      filterBy: '',
    };
  }

  onFilterChange(filterBy) {
    this.setState({
      filterBy: filterBy.toLowerCase().trim(),
    });
  }

  renderSearchBar() {
    return (
      <SearchBar
        value={this.state.filterBy}
        placeholder='Filter style settings'
        onChange={(value) => this.onFilterChange(value)}
      />
    );
  }

  renderForms() {
    const { filterBy } = this.state;
    return (
      <FormsContainer>
        {_.map(Forms, (form, index) => this.renderForm(form, index, filterBy))}
      </FormsContainer>
    );
  }

  getFormHighlights(form) {
    if (typeof form.highlights !== 'function') return [];
    return form.highlights(
      this.props.style,
      this.props.errors,
      this.props.slicer
    );
  }

  getSubgroupChanges(subgroups) {
    const { fieldChanges } = this.props;
    const changes = [];
    _.forEach(subgroups, (subgroup) =>
      _.forEach(subgroup.fields, (subfield) => {
        if (_.includes(fieldChanges, subfield.name)) {
          changes.push(subfield.name);
        }
      })
    );
    return changes;
  }

  getFieldChanges(form) {
    const { fieldChanges } = this.props;
    if (typeof form.fieldChanges !== 'function') return null;
    const changes = [];
    _.forEach(form.metadata, (formItem) => {
      if (formItem.name && _.includes(fieldChanges, formItem.name))
        changes.push(formItem.name);
      if (formItem.subgroups) {
        const subgroupChanges = this.getSubgroupChanges(formItem.subgroups);
        if (subgroupChanges.length > 0) changes.push(...subgroupChanges);
      }
      _.forEach(formItem.fields, (field) => {
        if (_.includes(fieldChanges, field.name)) changes.push(field.name);
      });
    });
    return form.fieldChanges(changes);
  }

  renderForm(form, index, filterBy) {
    const FormContent = form.content;
    if (!FormContent.isSectionMatched(this.props.style, filterBy)) return null;
    const triggerOpen =
      this.props.focusField &&
      FormContent.isSectionMatched(this.props.style, this.props.focusField);
    if (typeof form.display === 'function') {
      if (!form.display(this.props.style, this.props.projectProps)) {
        return null;
      }
    } else if (typeof form.display !== 'undefined' && !form.display) {
      return null;
    }
    const highlights = this.getFormHighlights(form);
    const fieldChanges = this.getFieldChanges(form);
    return (
      <FormPanel
        key={index}
        label={form.label}
        icon={form.icon}
        highlights={highlights}
        forceOpen={filterBy.trim() !== ''}
        fieldChangesLength={fieldChanges && fieldChanges.length}
        triggerOpen={triggerOpen}>
        <FormContent
          fieldChanges={fieldChanges}
          slicer={this.props.slicer}
          initialStyle={this.props.initialStyle}
          style={this.props.style}
          errors={this.props.errors}
          focusField={this.props.focusField}
          filterBy={filterBy}
          projectProps={this.props.projectProps}
          printerTag={this.props.printerTag}
          updateFieldSuccess={(fieldName, value) =>
            this.props.updateFieldSuccess(fieldName, value)
          }
          updateFieldFailure={(fieldName, value) =>
            this.props.updateFieldFailure(fieldName, value)
          }
        />
      </FormPanel>
    );
  }

  render() {
    return (
      <Container>
        {this.renderSearchBar()}
        {this.renderForms()}
      </Container>
    );
  }
}
export default StyleSettingsForm;

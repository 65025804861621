import styled from 'styled-components';
import SliderUnstyled from '@mui/base/SliderUnstyled';

export const DefaultContainer = styled.div`
  margin-top: 1rem;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  width: ${(props) => (props.vertical ? '1.5rem' : '100%')};
  height: ${(props) => (props.vertical ? `calc(100% - 1rem)` : '3rem')};

  .MuiSlider-dragging .MuiSlider-track {
    background-color: ${(props) => props.theme.colors.grey5}90;
  }
`;

export const DefaultSlider = styled(SliderUnstyled)`
  min-width: 4px;
  width: 100%;
  width: ${(props) => (props.vertical ? '4px' : '100%')};
  height: ${(props) => (props.vertical ? '100%' : '4px')};
  height: 100%;
  display: inline-block;
  position: relative;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;

  .MuiSlider-rail {
    display: block;
    position: absolute;
    width: ${(props) => (props.vertical ? '4px' : '100%')};
    height: ${(props) => (props.vertical ? '100%' : '4px')};
    border-radius: 2px;
    background-color: ${(props) => props.theme.colors.grey5}30;
  }

  .MuiSlider-track {
    display: block;
    width: ${(props) => (props.vertical ? '4px' : '100%')};
    height: ${(props) => (props.vertical ? '100%' : '4px')};
    position: absolute;
    border-radius: 2px;
    background-color: ${(props) => props.theme.colors.grey5}70;
  }

  .MuiSlider-thumb {
    display: flex;
    flex-direction: ${(props) => (props.vertical ? 'row' : 'column')};
    justify-content: ${(props) => (props.vertical ? 'flex-end' : 'center')};
    align-items: center;
    position: absolute;
    pointer-events: ${(props) => (props.disabled ? 'none' : 'all !important')};
    width: calc(1rem + 4px);
    height: calc(1rem + 4px);
    margin-top: calc(-0.5rem);
    margin-left: calc(-0.5rem);
    box-sizing: border-box;
    border-radius: 50%;
    border: ${(props) => props.theme.colors.grey4} solid 2px;
    background-color: ${(props) => props.theme.colors.grey2};
    :hover, .Mui-focusVisible {
      box-shadow: 0 0 0 0.75rem ${(props) => props.theme.colors.grey5}30;
    }
  }

  .MuiSlider-valueLabel {
    display: flex;
    margin-top: ${(props) => (props.vertical ? '' : `calc(1rem + 2rem)`)};
    margin-right: ${(props) => (props.vertical ? `calc(1rem + 0.5rem)` : '')};
  }

  .MuiSlider-valueLabelLabel {
    display: flex;
    color: ${(props) => props.theme.colors.textSecondary};
  }

  .Mui-active {
    box-shadow: 0 0 0 0.75rem ${(props) => props.theme.colors.grey5}20;
  }
}`;

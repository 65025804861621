import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../canvas-api';
import { handleGenericError } from '../common';
import { actions } from '../../reducers/iot/iot';
import { subscribeToDevice } from './subscriptions';
import { waitForInitConnection } from './initConnection';
import { CLIENT_INIT_FAILURE_MESSAGE } from '../../utils/iot-worker/constants';

export default function* activateDevice(action) {
  const { activationCode } = action.payload;
  try {
    const connectionInitialized = yield call(waitForInitConnection);
    if (!connectionInitialized) {
      throw new Error(CLIENT_INIT_FAILURE_MESSAGE);
    }
    const response = yield call(API, {
      path: 'devices/link',
      method: methods.PUT,
      body: {
        activationCode,
      },
    });
    if (response === null) return;
    const deviceId = response.device.id;
    yield put(actions.activateDeviceSuccess(response.device));

    // already waited for connection initialization above
    yield call(subscribeToDevice, deviceId);
    yield put(actions.getDeviceStateRequest(deviceId));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

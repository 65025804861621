import React from 'react';

import { useTheme } from 'styled-components';

import { StyledBadge } from './badge.styles';
import { Caption } from '../typography/typography';
import Icon from '../icon/icon.jsx';

const Badge = ({
  icon = '', // optional - specify which icon to use
  disabled = false,
  onClick = () => {},
  ...props
}) => {
  const theme = useTheme();

  // When both icon and text badges are used,
  // icon badges should precede text badges.

  return (
    <StyledBadge icon={icon} disabled={disabled} onClick={onClick}>
      {icon === '' ? (
        <Caption grey noSpacing>
          {props.children}
        </Caption>
      ) : (
        <Icon
          src={icon}
          size={Icon.sizes.MINI}
          color={theme.colors.whiteDefault}
        />
      )}
    </StyledBadge>
  );
};

export default Badge;

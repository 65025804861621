import ProfileParser from './parser';
import {
  Firmware,
  InfillStyles,
  PerimeterOrder,
  SolidFillStyles,
  SupportStyles,
} from './enums';
import { parseXml, mmPerMin2mmPerS } from './utils';
import {
  getTagContentsString,
  getTagContentsInt,
  getTagContentsFloat,
  getTagContentsBool,
} from './xmlUtils';
import { paletteConstants } from '../../constants';
import FormatUtils from '../format/format';

const { roundTo } = FormatUtils;

const getExtruderInfo = (tag) => {
  let toolheadNumber = 0;
  let diameter = 0;
  let autoWidth = true;
  let width = 0;
  let extrusionMultiplier = 1;
  let useRetract = false;
  let retractionDistance = 0;
  let retractionZLift = 0;
  let retractionSpeed = 0;
  let useWipe = false;
  let wipeDistance = 0;
  let useCoasting = false;
  let coastingDistance = 0;
  for (let i = 0; i < tag.childNodes.length; i++) {
    const child = tag.childNodes[i];
    switch (child.nodeName) {
      case 'toolheadNumber':
        toolheadNumber = parseInt(child.textContent, 10);
        break;
      case 'diameter':
        diameter = parseFloat(child.textContent);
        break;
      case 'autoWidth':
        autoWidth = child.textContent === '1';
        break;
      case 'width':
        width = parseFloat(child.textContent);
        break;
      case 'extrusionMultiplier':
        extrusionMultiplier = parseFloat(child.textContent);
        break;
      case 'useRetract':
        useRetract = child.textContent === '1';
        break;
      case 'retractionDistance':
        retractionDistance = parseFloat(child.textContent);
        break;
      case 'retractionZLift':
        retractionZLift = parseFloat(child.textContent);
        break;
      case 'retractionSpeed':
        retractionSpeed = mmPerMin2mmPerS(parseFloat(child.textContent));
        break;
      case 'useWipe':
        useWipe = child.textContent === '1';
        break;
      case 'wipeDistance':
        wipeDistance = parseFloat(child.textContent);
        break;
      case 'useCoasting':
        useCoasting = child.textContent === '1';
        break;
      case 'coastingDistance':
        coastingDistance = parseFloat(child.textContent);
        break;
      default:
        break;
    }
  }
  if (autoWidth) {
    width = diameter * 1.2;
  }
  if (!useWipe) {
    wipeDistance = 0;
  }
  if (!useCoasting) {
    coastingDistance = 0;
  }
  return {
    toolheadNumber,
    diameter,
    width,
    extrusionMultiplier,
    useRetract,
    retractionDistance,
    retractionZLift,
    retractionSpeed,
    wipeDistance,
    coastingDistance,
  };
};

const getTemperatureController = (tag) => {
  const controller = {
    temperatureNumber: 0,
    isHeatedBed: false,
    setpoint: {
      layer: 0,
      temperature: 0,
    },
  };
  for (let i = 0; i < tag.childNodes.length; i++) {
    const child = tag.childNodes[i];
    switch (child.nodeName) {
      case 'temperatureNumber':
        controller.temperatureNumber = parseInt(child.textContent, 10);
        break;
      case 'isHeatedBed':
        controller.isHeatedBed = child.textContent === '1';
        break;
      case 'setpoint':
        for (let j = 0; j < child.attributes.length; j++) {
          if (child.attributes[j].name === 'layer') {
            controller.setpoint.layer = parseInt(child.attributes[j].value, 10);
          }
          if (child.attributes[j].name === 'temperature') {
            controller.setpoint.temperature = parseInt(
              child.attributes[j].value,
              10
            );
          }
        }
        break;
      default:
        break;
    }
  }
  return controller;
};

const parseFanSpeed = (domNode) => {
  const xFanSpeed = domNode.getElementsByTagName('fanSpeed')[0];
  let maxSpeed = 0;
  let enableFanAtLayer = 0;
  let set = false;
  for (let i = 0; i < xFanSpeed.childNodes.length; i++) {
    const setpoint = xFanSpeed.childNodes[i];
    const layer = parseInt(setpoint.layer, 10);
    const speed = parseInt(setpoint.speed, 10);
    if (speed > maxSpeed) {
      maxSpeed = speed;
      enableFanAtLayer = layer - 1;
      set = true;
      if (speed === 100) {
        break;
      }
    }
  }
  return {
    set,
    useFan: maxSpeed > 0,
    enableFanAtLayer,
    speed: maxSpeed,
  };
};

const parseAutoConfigureQuality = (domNode, defaultStyle) => {
  const newStyle = { ...defaultStyle };
  newStyle.name = domNode.getAttribute('name');
  newStyle.layerHeight =
    getTagContentsFloat(domNode, 'layerHeight') || defaultStyle.layerHeight;
  const topSolidLayers = getTagContentsInt(domNode, 'topSolidLayers');
  const bottomSolidLayers = getTagContentsInt(domNode, 'bottomSolidLayers');
  newStyle.skinThickness = {
    units: 'layers',
    value: Math.max(bottomSolidLayers, 1),
  };
  newStyle.topSkinThickness = {
    units: 'layers',
    value: Math.max(topSolidLayers, 1),
  };
  newStyle.brimLayers =
    getTagContentsInt(domNode, 'skirtLayers') || defaultStyle.brimLayers;
  newStyle.infillDensity =
    getTagContentsInt(domNode, 'infillPercentage') ||
    defaultStyle.infillDensity;
  return newStyle;
};

const parseAutoConfigureMaterial = (domNode) => {
  const material = ProfileParser.getDefaultMaterial();
  material.name = domNode.getAttribute('name');
  const extruderTemperature = getTagContentsInt(
    domNode,
    'globalExtruderTemperature'
  );
  if (extruderTemperature) {
    material.printTemperature = extruderTemperature;
    material.firstLayerPrintTemperature = {
      value: 'auto',
    };
    material.usePrintTemperature = true;
    material.useFirstLayerPrintTemperature = true;
  }
  const bedTemperature = getTagContentsInt(domNode, 'globalBedTemperature');
  if (bedTemperature) {
    material.bedTemperature = bedTemperature;
    material.useBedTemperature = true;
  }
  const extrusionMultiplier = Math.round(
    getTagContentsFloat(domNode, 'globalExtrusionMultiplier') * 100
  );
  if (extrusionMultiplier) {
    material.extrusionMultiplier = extrusionMultiplier;
    material.useExtrusionMultiplier = true;
  }
  const {
    set: fanSet,
    useFan,
    enableFanAtLayer,
    speed: fanSpeed,
  } = parseFanSpeed(domNode);
  if (fanSet) {
    material.useFan = useFan;
    material.useUseFan = true;
    material.enableFanAtLayer = enableFanAtLayer;
    material.useEnableFanAtLayer = true;
    material.fanSpeed = fanSpeed;
    material.useFanSpeed = true;
    material.perimeterFanSpeed = {
      value: 'auto',
    };
    material.usePerimeterFanSpeed = true;
  }
  return material;
};

export default class Simplify3DParser extends ProfileParser {
  cleanGCode(gcode) {
    return super
      .cleanGCode(gcode)
      .replace(/,/g, '\n')

      .replace(/\[old_tool]/g, '<EXT+0>')
      .replace(/\[new_tool]/g, '<EXT+0>')

      .replace(/\[build_size_z]/g, '<MAXZ>')

      .replace(/\[current_X_position]/g, '<X>')
      .replace(/\[current_Y_position]/g, '<Y>')
      .replace(/\[current_Z_position]/g, '<Z>')
      .replace(/\[current_position_x]/g, '<X>')
      .replace(/\[current_position_y]/g, '<Y>')
      .replace(/\[current_position_z]/g, '<Z>')

      .replace(/\[next_X_position]/g, '<NEXTX>')
      .replace(/\[next_Y_position]/g, '<NEXTY>')
      .replace(/\[next_position_x]/g, '<NEXTX>')
      .replace(/\[next_position_y]/g, '<NEXTY>')

      .replace(/\[progress]/g, '<%>')

      .replace(/\[retract_distance]/g, '<DESTRING>')
      .replace(/\[toolchange_retract_distance]/g, '<DESTRING>')
      .replace(/\[prime_distance]/g, '<DESTRING>')
      .replace(/\[toolchange_prime_distance]/g, '<DESTRING>')

      .replace(/\[primary_extruder_temperature]/g, '<TEMP>')
      .replace(/\[extruder0_temperature]/g, '<TEMP>')
      .replace(/\[extruder1_temperature]/g, '<TEMP>')
      .replace(/\[extruder2_temperature]/g, '<TEMP>')
      .replace(/\[extruder3_temperature]/g, '<TEMP>')
      .replace(/\[extruder4_temperature]/g, '<TEMP>')
      .replace(/\[extruder5_temperature]/g, '<TEMP>')

      .replace(/\[bed_temperature]/g, '<BED>')
      .replace(/\[bed0_temperature]/g, '<BED>')
      .replace(/\[bed1_temperature]/g, '<BED>')
      .replace(/\[bed2_temperature]/g, '<BED>')
      .replace(/\[bed3_temperature]/g, '<BED>')
      .replace(/\[bed4_temperature]/g, '<BED>')
      .replace(/\[bed5_temperature]/g, '<BED>')

      .replace(/\[current_layer]/g, '<LAYER>')

      .replace(/\[total_print_time_sec]/g, '<TTOTAL>');
  }

  parse(input) {
    const dom = parseXml(input);
    const defaultPrinter = ProfileParser.getDefaultPrinter();
    const { machineSettings } = defaultPrinter;
    const mainStyle = defaultPrinter.styles[0];
    const warnings = {};

    const s3dVersion = {
      major: 0,
      minor: 0,
      patch: 0,
    };

    // profile name
    // Simplify3D version
    const xProfile = dom.getElementsByTagName('profile')[0];
    {
      let profileName = 'profile';
      for (let i = 0; i < xProfile.attributes.length; i++) {
        if (xProfile.attributes[i].name === 'name') {
          profileName = xProfile.attributes[i].value;
        } else if (xProfile.attributes[i].name === 'app') {
          const semver = xProfile.attributes[i].value.split(' ')[1];
          [s3dVersion.major, s3dVersion.minor, s3dVersion.patch] = semver
            .split('.')
            .map((v) => parseInt(v, 10));
        }
      }
      machineSettings.name = profileName.trim();
      mainStyle.name = profileName.trim();
    }

    // file extension
    {
      const extension = getTagContentsString(dom, 'exportFileFormat');
      if (extension !== null) {
        machineSettings.extension = extension;
      }
    }

    // G-code sequences
    machineSettings.startSequence = this.cleanGCode(
      getTagContentsString(dom, 'startingGcode')
    );
    machineSettings.endSequence = this.cleanGCode(
      getTagContentsString(dom, 'endingGcode')
    );
    ['startSequence', 'endSequence'].forEach((sequence) => {
      const gcode = machineSettings[sequence];
      if (
        gcode.includes('[') ||
        gcode.includes(']') ||
        gcode.includes('{') ||
        gcode.includes('}')
      ) {
        const lines = gcode.split('\n');
        const sanitizedLines = [];
        lines.forEach((line) => {
          if (
            line.includes('[') ||
            line.includes(']') ||
            line.includes('{') ||
            line.includes('}')
          ) {
            if (!warnings[sequence]) {
              warnings[sequence] = [];
            }
            warnings[sequence].push(line);
            sanitizedLines.push(`; ${line}`);
          } else {
            sanitizedLines.push(line);
          }
        });
        machineSettings[sequence] = sanitizedLines.join('\n');
      }
    });

    // extruder info
    {
      const xExtruders = Array.from(xProfile.children).filter(
        (child) => child.tagName.toLowerCase() === 'extruder'
      );
      const primaryExtruder =
        xExtruders[getTagContentsInt(dom, 'primaryExtruder')];
      // extruder count
      machineSettings.extruderCount = Math.max(
        1,
        Math.min(xExtruders.length, paletteConstants.DEFAULT_INPUT_COUNT)
      );
      const bestExtInfo = new Array(paletteConstants.DEFAULT_INPUT_COUNT).fill(
        null
      );
      const preventOverwrite = new Array(
        paletteConstants.DEFAULT_INPUT_COUNT
      ).fill(false);
      let primaryExtruderToolhead = 0;
      for (let i = 0; i < xExtruders.length; i++) {
        const extruder = xExtruders[i];
        const extruderInfo = getExtruderInfo(extruder);
        if (
          extruderInfo.toolheadNumber < paletteConstants.DEFAULT_INPUT_COUNT
        ) {
          if (extruder === primaryExtruder) {
            bestExtInfo[extruderInfo.toolheadNumber] = extruderInfo;
            preventOverwrite[extruderInfo.toolheadNumber] = true;
            primaryExtruderToolhead = extruderInfo.toolheadNumber;
          } else if (!preventOverwrite[extruderInfo.toolheadNumber]) {
            bestExtInfo[extruderInfo.toolheadNumber] = extruderInfo;
          }
        }
      }
      let filamentDiameter = 1.75;
      {
        const diameter = getTagContentsFloat(dom, 'filamentDiameter');
        if (diameter !== null && diameter > 2) {
          filamentDiameter = 2.85;
        }
      }
      const filamentDiameters = [];
      const nozzleDiameters = [];
      for (let i = 0; i < machineSettings.extruderCount; i++) {
        filamentDiameters[i] = filamentDiameter;
        if (bestExtInfo[i] === null) {
          nozzleDiameters.push(bestExtInfo[primaryExtruderToolhead].diameter);
        } else {
          nozzleDiameters.push(bestExtInfo[i].diameter);
        }
      }
      machineSettings.nozzleDiameter = nozzleDiameters;
      machineSettings.filamentDiameter = filamentDiameters;
      mainStyle.extrusionWidth = roundTo(
        bestExtInfo[primaryExtruderToolhead].width,
        2
      );
      mainStyle.infillExtrusionWidth = mainStyle.extrusionWidth;
      mainStyle.extrusionMultiplier = Math.round(
        bestExtInfo[primaryExtruderToolhead].extrusionMultiplier * 100
      );
      mainStyle.useRetracts = bestExtInfo[primaryExtruderToolhead].useRetract;
      mainStyle.retractLength = roundTo(
        bestExtInfo[primaryExtruderToolhead].retractionDistance,
        1
      );
      mainStyle.retractSpeed = roundTo(
        bestExtInfo[primaryExtruderToolhead].retractionSpeed,
        1
      );
      mainStyle.zLift = roundTo(
        bestExtInfo[primaryExtruderToolhead].retractionZLift,
        2
      );
      mainStyle.wipeLength = roundTo(
        bestExtInfo[primaryExtruderToolhead].wipeDistance,
        1
      );
      mainStyle.coastDistance = roundTo(
        bestExtInfo[primaryExtruderToolhead].coastingDistance,
        1
      );
    }

    // firmware type
    {
      const relativeEDistances = getTagContentsBool(dom, 'relativeEdistances');
      if (relativeEDistances) {
        machineSettings.firmwareType = Firmware.FIRMWARE_5D_REL;
      } else {
        machineSettings.firmwareType = Firmware.FIRMWARE_5D_ABS;
      }
      const firmware = getTagContentsString(
        dom,
        'firmwareTypeOverride'
      ).toLowerCase();
      if (firmware !== null) {
        if (firmware.startsWith('makerbot')) {
          machineSettings.gpxProfile =
            getTagContentsString(dom, 'GPXconfigOverride') || 'r2';
        } else if (firmware.includes('flashforge dreamer')) {
          machineSettings.firmwareType = Firmware.FIRMWARE_FLASHFORGE;
        }
      }
    }
    if (getTagContentsBool(dom, 'createX3G') === true) {
      machineSettings.extension = 'x3g';
    }
    if (getTagContentsBool(dom, 'createMB5G') === true) {
      machineSettings.extension = 'makerbot';
    }

    // add M101/G10
    machineSettings.firmwareRetraction = getTagContentsBool(
      dom,
      'includeM10123'
    )
      ? 2
      : 0;

    // Z speed
    mainStyle.rapidZSpeed = roundTo(
      mmPerMin2mmPerS(getTagContentsFloat(dom, 'rapidZspeed')),
      1
    );

    // bed size X/Y/Z
    machineSettings.bedSize = {
      x: roundTo(getTagContentsFloat(dom, 'strokeXoverride'), 2),
      y: roundTo(getTagContentsFloat(dom, 'strokeYoverride'), 2),
      z: roundTo(getTagContentsFloat(dom, 'strokeZoverride'), 2),
    };

    // bed offset X/Y/Z
    machineSettings.originOffset = {
      x: roundTo(getTagContentsFloat(dom, 'originOffsetXoverride'), 2),
      y: roundTo(getTagContentsFloat(dom, 'originOffsetYoverride'), 2),
    };
    mainStyle.zOffset = roundTo(
      getTagContentsFloat(dom, 'originOffsetZoverride'),
      2
    );

    // round bed
    machineSettings.circular = getTagContentsBool(dom, 'machineTypeOverride');

    // travel speed
    mainStyle.rapidXYSpeed = roundTo(
      mmPerMin2mmPerS(getTagContentsFloat(dom, 'rapidXYspeed')),
      1
    );

    // infill speed
    mainStyle.infillSpeed = {
      units: 'mm/s',
      value: roundTo(
        mmPerMin2mmPerS(getTagContentsFloat(dom, 'defaultSpeed')),
        1
      ),
    };

    // perimeter speed
    mainStyle.perimeterSpeed = {
      units: 'mm/s',
      value: roundTo(
        mainStyle.infillSpeed.value *
          getTagContentsFloat(dom, 'outlineUnderspeed'),
        1
      ),
    };

    // solid speed
    mainStyle.solidLayerSpeed = roundTo(
      mainStyle.infillSpeed.value *
        getTagContentsFloat(dom, 'solidInfillUnderspeed'),
      1
    );

    // first layer speed
    mainStyle.firstLayerSpeed = {
      units: '%',
      value: Math.round(getTagContentsFloat(dom, 'firstLayerUnderspeed') * 100),
    };

    // raft airgap
    mainStyle.raftZGap = roundTo(
      getTagContentsFloat(dom, 'raftSeparationDistance'),
      2
    );

    // default extruders
    mainStyle.defaultSupportExtruder = {
      units: 'index',
      value: getTagContentsInt(dom, 'supportExtruder'),
    };
    mainStyle.defaultSupportInterfaceExtruder =
      mainStyle.defaultSupportExtruder.value;
    mainStyle.defaultRaftExtruder = getTagContentsInt(dom, 'raftExtruder');

    // solid loop overlap
    mainStyle.infillPerimeterOverlap = Math.max(
      0,
      Math.min(100, getTagContentsInt(dom, 'outlineOverlapPercentage'))
    );

    // layer thickness
    mainStyle.layerHeight = roundTo(getTagContentsFloat(dom, 'layerHeight'), 2);
    mainStyle.firstLayerHeight = {
      units: '%',
      value: Math.round(getTagContentsFloat(dom, 'firstLayerHeightPercentage')),
    };

    // fan Z
    {
      const { useFan, enableFanAtLayer, speed } = parseFanSpeed(dom);
      mainStyle.useFan = useFan;
      mainStyle.enableFanAtLayer = enableFanAtLayer;
      mainStyle.fanSpeed = speed;
      mainStyle.perimeterFanSpeed = {
        value: 'auto',
      };
    }

    // print temperature
    // keep-warm temperature
    // first layer temperature
    // bed temperature
    mainStyle.bedTemperature = 0;
    {
      const xTemperatureControllers = dom.getElementsByTagName(
        'temperatureController'
      );
      let maxTempSeen = 0;
      for (let i = 0; i < xTemperatureControllers.length; i++) {
        const controller = getTemperatureController(xTemperatureControllers[i]);
        if (controller.isHeatedBed) {
          mainStyle.bedTemperature = Math.round(
            controller.setpoint.temperature
          );
        } else {
          if (controller.setpoint.layer === 1) {
            mainStyle.firstLayerPrintTemperature = {
              units: 'C',
              value: Math.round(controller.setpoint.temperature),
            };
          }
          if (controller.setpoint.temperature > maxTempSeen) {
            maxTempSeen = controller.setpoint.temperature;
          }
        }
      }
      mainStyle.printTemperature = Math.round(maxTempSeen);
    }
    if (mainStyle.firstLayerPrintTemperature.value === 0) {
      mainStyle.firstLayerPrintTemperature = {
        value: 'auto',
      };
    }

    // destring min mm
    {
      const useRetractionMinTravel = getTagContentsBool(
        dom,
        'useRetractionMinTravel'
      );
      if (useRetractionMinTravel) {
        mainStyle.retractDisableThreshold = roundTo(
          getTagContentsFloat(dom, 'retractionMinTravel'),
          1
        );
        mainStyle.retractForceThreshold = mainStyle.retractDisableThreshold;
      }
    }

    // min layer time
    {
      const adjustSpeedForCooling = getTagContentsBool(
        dom,
        'adjustSpeedForCooling'
      );
      if (adjustSpeedForCooling) {
        mainStyle.minLayerTime = Math.round(
          getTagContentsFloat(dom, 'minSpeedLayerTime')
        );
      }
    }

    // loop count
    mainStyle.perimeterCount = getTagContentsInt(dom, 'perimeterOutlines');

    // loops inside out
    mainStyle.perimeterOrder = getTagContentsBool(
      dom,
      'printPerimetersInsideOut'
    )
      ? PerimeterOrder.PERIMETER_LAST
      : PerimeterOrder.PERIMETER_FIRST;

    // skin thickness
    {
      const topSolidLayers = getTagContentsInt(dom, 'topSolidLayers');
      const bottomSolidLayers = getTagContentsInt(dom, 'bottomSolidLayers');
      mainStyle.skinThickness = {
        units: 'layers',
        value: bottomSolidLayers,
      };
      mainStyle.topSkinThickness = {
        units: 'layers',
        value: topSolidLayers,
      };
    }

    // solid fill style
    {
      const solidFillPattern = getTagContentsString(
        dom,
        'externalInfillPattern'
      );
      if (solidFillPattern !== null) {
        switch (solidFillPattern.toLowerCase()) {
          case 'concentric':
            mainStyle.solidLayerStyle = SolidFillStyles.CONCENTRIC;
            break;
          case 'rectilinear':
          default:
            mainStyle.solidLayerStyle = SolidFillStyles.STRAIGHT;
            break;
        }
      }
    }

    // gap fill
    {
      const useGapFill = getTagContentsBool(dom, 'allowThinWallGapFill');
      if (useGapFill !== null) {
        mainStyle.useGapFill = useGapFill;
      }
    }

    // infill extrusion width
    mainStyle.infillExtrusionWidth = {
      units: '%',
      value: getTagContentsInt(dom, 'infillExtrusionWidthPercentage'),
    };

    // infill density
    mainStyle.infillDensity = getTagContentsInt(dom, 'infillPercentage');

    // infill style
    {
      const infillPattern = getTagContentsString(dom, 'internalInfillPattern');
      if (infillPattern === null) {
        // tag not found -- use default
        mainStyle.infillStyle = InfillStyles.STRAIGHT;
      } else {
        switch (infillPattern.toLowerCase()) {
          case 'rectilinear':
            mainStyle.infillStyle = InfillStyles.STRAIGHT;
            break;
          case 'grid':
          case 'triangular':
          case 'fast honeycomb':
            mainStyle.infillStyle = InfillStyles.OCTAGONAL;
            break;
          case 'wiggle':
            mainStyle.infillStyle = InfillStyles.ROUNDED;
            break;
          case 'full honeycomb':
            mainStyle.infillStyle = InfillStyles.CELLULAR;
            break;
          default:
            break;
        }
      }
    }

    // spiral vase mode
    mainStyle.spiralVaseMode = getTagContentsBool(dom, 'spiralVaseMode');

    // support density
    mainStyle.useSupport = getTagContentsBool(dom, 'generateSupport');
    {
      const supportDensity = getTagContentsInt(dom, 'supportInfillPercentage');
      if (supportDensity <= 33) {
        mainStyle.supportDensity = SupportStyles.ROUGH;
      } else if (supportDensity <= 66) {
        mainStyle.supportDensity = SupportStyles.DENSE;
      } else {
        mainStyle.supportDensity = SupportStyles.ULTRA;
      }
    }

    // interface density
    mainStyle.supportInterfaceDensity = {
      units: '%',
      value: getTagContentsInt(dom, 'denseSupportInfillPercentage'),
    };

    // use interfaces
    mainStyle.useSupportInterface =
      getTagContentsInt(dom, 'denseSupportLayers') > 0;

    // dense support layers
    const denseSupportLayers = getTagContentsInt(dom, 'denseSupportLayers');
    if (denseSupportLayers) {
      mainStyle.supportInterfaceThickness = {
        units: 'layers',
        value: Math.max(2, denseSupportLayers),
      };
    }

    // support XY inflation
    mainStyle.supportXYInflation = roundTo(
      getTagContentsFloat(dom, 'supportExtraInflation'),
      2
    );

    // support gap
    mainStyle.supportXYGap = roundTo(
      getTagContentsFloat(dom, 'supportHorizontalPartOffset'),
      2
    );

    // support angle
    mainStyle.maxOverhangAngle = getTagContentsInt(dom, 'maxOverhangAngle');

    // support speed
    mainStyle.supportSpeed = {
      units: 'mm/s',
      value: roundTo(
        mainStyle.infillSpeed.value *
          getTagContentsFloat(dom, 'supportUnderspeed'),
        1
      ),
    };

    // interface Z gap
    {
      const topSeparationLayers = getTagContentsInt(
        dom,
        'supportUpperSeparationLayers'
      );
      mainStyle.supportZGap = {
        units: 'layers',
        value: topSeparationLayers,
      };
    }

    // raft mode
    mainStyle.useRaft = getTagContentsBool(dom, 'useRaft');

    // raft XY inflation
    mainStyle.raftXYInflation = roundTo(
      getTagContentsFloat(dom, 'raftOffset'),
      2
    );

    // brim
    mainStyle.useBrim = getTagContentsBool(dom, 'useSkirt');
    mainStyle.brimLoops = Math.max(1, getTagContentsInt(dom, 'skirtOutlines'));
    mainStyle.brimLayers = Math.max(1, getTagContentsInt(dom, 'skirtLayers'));
    mainStyle.brimGap = roundTo(getTagContentsFloat(dom, 'skirtOffset'), 2);

    // avoid crossing perimeters
    {
      const avoidCrossingOutline = getTagContentsBool(
        dom,
        'avoidCrossingOutline'
      );
      if (avoidCrossingOutline !== null) {
        mainStyle.avoidCrossingPerimeters = avoidCrossingOutline;
        const detourFactor = getTagContentsFloat(
          dom,
          'maxMovementDetourFactor'
        );
        mainStyle.avoidCrossingPerimetersMaxDetour = {
          units: '%',
          value: Math.round((detourFactor - 1) * 100),
        };
      }
    }

    // auto-configure for print quality
    const xAutoConfigureQualities = dom.getElementsByTagName(
      'autoConfigureQuality'
    );
    const styles = Array.from(xAutoConfigureQualities).map(
      (xAutoConfigureQuality) =>
        parseAutoConfigureQuality(xAutoConfigureQuality, mainStyle)
    );
    if (styles.length === 0) {
      styles.push(mainStyle);
    }

    // auto-configure for material
    const xAutoConfigureMaterials = dom.getElementsByTagName(
      'autoConfigureMaterial'
    );
    const materials = Array.from(xAutoConfigureMaterials).map(
      (xAutoConfigureMaterial) =>
        parseAutoConfigureMaterial(xAutoConfigureMaterial)
    );

    return {
      machineSettings,
      styles,
      materials,
      warnings,
    };
  }
}

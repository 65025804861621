import styled from 'styled-components';

export const StyledContainer = styled.div`
  background: transparent;
`;

export const StyledIconContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 0.2rem;
  display: flex;
  &:hover {
    color: ${(props) => props.theme.colors.textPrimary};
  }
`;

export const StyledSelect = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  color: ${(props) => {
    if (props.disabled) return props.theme.colors.textTertiary;
    if (props.grey) return props.theme.colors.textSecondary;
    return props.theme.colors.textPrimary;
  }};
  padding: 0 0.5rem 0 0.5rem;
  };
`;

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import { getThreeState, handleGenericError } from '../common';
import actions from '../../reducers/three/actions';
import { setRenderFlag } from './animationFrame';

export default function* attachGizmo(action) {
  try {
    const { gizmo } = yield select(getThreeState);
    const { selectedMeshes, gizmoMode, onMouseUp } = action.payload;

    gizmo.attach(selectedMeshes);
    gizmo.setMode(gizmoMode);
    gizmo.handleTransforms = onMouseUp;
    const { x, y, z } = gizmo.getCenter();

    yield put(actions.attachGizmoSuccess({ x, y, z }));
    setRenderFlag();
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import _ from 'lodash';

const Helpers = {
  updatePrinterInMap: (existingPrinters, printer) => ({
    ...existingPrinters,
    [printer.id]: printer,
  }),
  deletePrintersFromMap: (existingPrinters, printerIds) => {
    const updatedPrinters = _.omit(existingPrinters, printerIds);
    return updatedPrinters;
  },
  deletePrinterStyles: (printer, styleIds) => {
    const updatedStyles = _.filter(
      printer.styles,
      (setting) => !styleIds.includes(setting.id)
    );
    return {
      ...printer,
      styles: updatedStyles,
    };
  },
  composePrintersMap: (printersArray) => {
    const printers = {};
    _.forEach(printersArray, (printer) => {
      printers[printer.id] = printer;
    });
    return printers;
  },
};

export default Helpers;

import styled from 'styled-components';
import {
  DefaultUnitLabelWrapper,
  StyledContainerBase,
  StyledInputBase,
} from '../../../../../../../shared/abstractInput/abstractInput.styles';

export const TargetTemperatureContainer = styled(StyledContainerBase)`
  flex-grow: 1;
  height: auto;
  min-height: 0;
  margin: 0;
  padding: 0;
  font-size: 1rem;
`;

export const TargetTemperatureInput = styled(StyledInputBase)`
  line-height: 1.1rem;
  text-align: center;
  position: static;
  padding: 0.2rem 0.4rem;
  font-weight: ${(props) => props.theme.fonts.weightBold};
  font-size: 1.1rem;
`;

export const TargetTemperatureUnits = styled(DefaultUnitLabelWrapper)`
  bottom: 0.2rem;
`;

import styled, { css } from 'styled-components';
import {
  StyledContainerBase,
  StyledInputBase,
} from '../../../../shared/abstractInput/abstractInput.styles';

export const ButtonsContainer = styled.div`
  align-items: center;
  display: none;
  ${({ tower }) => tower && `padding-right: 0.5rem;`}
`;

const structureLineGuideStyles = css`
  /* css for tree structure line guide */
  content: '';
  position: absolute;
  bottom: 50%;
  left: 1em;
  width: 0.5em;
  height: 2.5em;
  border-left: 1px solid ${(props) => props.theme.colors.grey4};
  border-bottom: 1px solid ${(props) => props.theme.colors.grey4};
  pointer-events: none;
`;

export const Container = styled.div`
  font-size: 0.9em;
  margin: 0;
  padding: 0 0.2em;
  box-sizing: border-box;
  display: flex;
  justify-content: left;
  align-items: center;
  user-select: none;
  position: relative;
  cursor: pointer;
  border-left: 4px solid transparent;

  ${(props) =>
    props.hovered &&
    css`
      background-color: ${props.theme.colors.elevatedBackgroundSecondary};
    `}

  :hover {
    background-color: ${(props) =>
      props.theme.colors.elevatedBackgroundSecondary};
    & ${ButtonsContainer} {
      display: flex;
    }
  }

  ${(props) =>
    props.dropdownOpen &&
    css`
      background-color: ${props.theme.colors.elevatedBackgroundSecondary};
      & ${ButtonsContainer} {
        display: flex;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      color: ${props.theme.colors.textPrimary};
    `}

  ${(props) =>
    props.active &&
    css`
      border-left-color: ${props.theme.colors.greenDefault};
      background-color: ${props.theme.colors.elevatedBackgroundSecondary};
      :hover {
        background-color: ${props.theme.colors.elevatedBackgroundSecondary};
      }
      & ${ButtonsContainer} {
        display: flex;
      }
    `}

  ${(props) =>
    props.editing &&
    css`
      background-color: transparent;
      :hover {
        background-color: transparent;
        & ${ButtonsContainer} {
          display: none;
        }
      }
      & ${ButtonsContainer} {
        display: none;
      }
    `}

  ${(props) =>
    props.editing === false &&
    props.visible === false &&
    css`
      input {
        -webkit-text-fill-color: ${props.theme.colors.textTertiary};
        color: ${props.theme.colors.textTertiary};
      }
    `}

  ${(props) =>
    props.depth > 0 &&
    css`
      padding-left: 2em;

      ::after {
        ${structureLineGuideStyles};
      }

      :first-of-type::after {
        /* avoid drawing the guide line too far up */
        height: 1em;
      }
    `}
`;

export const StyledInputContainer = styled(StyledContainerBase)`
  flex-grow: 1;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
  min-height: 2rem;
  border-color: transparent;
  pointer-events: none;
`;

export const StyledInput = styled(StyledInputBase)`
  -webkit-text-fill-color: ${(props) => props.theme.colors.textPrimary};
  color: ${(props) => props.theme.colors.textPrimary};
  opacity: 1;
  font-size: 0.875rem;
  line-height: 1.125rem;
  padding-left: 0.3rem;
  text-overflow: ellipsis;
`;
export const EditingValidInputContainer = styled(StyledInputContainer)`
  pointer-events: auto;
  border-color: ${(props) => props.theme.colors.greenDefault};
`;
export const EditingErrorInputContainer = styled(StyledInputContainer)`
  pointer-events: auto;
`;

export const IconWrapper = styled.div`
  width: auto;
  height: auto;
`;

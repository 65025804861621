import _ from 'lodash';
import * as THREE from 'three';

// modified from http://cwestblog.com/2013/04/10/javascript-comparing-and-sorting-strings-with-numbers
const reParts = /\d+|\D+/g; // separate digits from non-digit strings
const reDigit = /\d/; // test if the string has a digit
const compareStringsWithNumbers = (a, b) => {
  // get rid of case issues
  const aUpper = a.toUpperCase();
  const bUpper = b.toUpperCase();
  // separate the strings into substrings that either have only digits or no digits
  const aParts = aUpper.match(reParts);
  reParts.lastIndex = 0;
  const bParts = bUpper.match(reParts);
  reParts.lastIndex = 0;
  if (aParts && bParts) {
    let isDigitPart;
    const aIsDigitPart = reDigit.test(aParts[0]);
    const bIsDigitPart = reDigit.test(bParts[0]);
    if (aIsDigitPart === bIsDigitPart) {
      isDigitPart = aIsDigitPart;
      const length = Math.min(aParts.length, bParts.length);
      for (let i = 0; i < length; i++) {
        if (isDigitPart) {
          // comparing two numbers
          const aPart = parseInt(aParts[i], 10);
          const bPart = parseInt(bParts[i], 10);
          if (aPart !== bPart) {
            return aPart - bPart;
          }
        } else {
          // comparing two strings
          const aPart = aParts[i];
          const bPart = bParts[i];
          if (aPart !== bPart) {
            return aPart.localeCompare(bPart);
          }
        }
        // if this was a digit part, the next part will not be
        isDigitPart = !isDigitPart;
      }
    }
  }
  // use normal comparison
  return aUpper.localeCompare(bUpper);
};

const Utils = {
  formatModelTree: (models) => {
    const modelTreeMap = {};
    _.forEach(models, (model) => {
      const pathParts = model.path.split('|');
      pathParts.shift(); // paths always start with a leading "|"
      const modelName = pathParts.pop();
      let parent = modelTreeMap;
      while (!_.isEmpty(pathParts)) {
        const part = pathParts.shift();
        if (!parent[part]) {
          parent[part] = {
            name: part,
            type: 'group',
            children: {},
          };
        }
        parent = parent[part].children;
      }
      const { path, ...dataForStore } = model;
      parent[modelName] = {
        ...dataForStore,
        name: modelName,
        transforms: {
          rotate: Utils.vector3ArrToObj(model.transforms.rotate),
          translate: Utils.vector3ArrToObj(model.transforms.translate),
          scale: Utils.vector3ArrToObj(model.transforms.scale),
        },
        mesh: null,
        material: null,
        rle: null,
        colored: false,
        supportsRle: null,
        hasCustomSupports: false,
        active: false,
      };
    });
    const mapToTree = (map) =>
      _.map(map, (val) => {
        if (val.children) {
          return {
            ...val,
            children: mapToTree(val.children),
          };
        }
        return val;
      });
    return mapToTree(modelTreeMap);
  },
  addMeshesToModelTree: (models, meshes) => {
    return Utils.map(models, (model) => {
      const fileName = model.name;
      const files = _.find(meshes, (m) => fileName === m.name);
      const { mesh, rle, supportsRle } = files;
      mesh.name = model.id;
      const { material } = mesh;
      return {
        ...model,
        mesh,
        material,
        rle,
        colored: !!rle,
        supportsRle,
        hasCustomSupports: !!supportsRle,
      };
    });
  },
  vector3ArrToObj: (arr) => ({ x: arr[0], y: arr[1], z: arr[2] }),
  vector3ObjToAr: (obj) => [obj.x, obj.y, obj.z],
  searchById: (id, models) =>
    _.reduce(
      models,
      (foundNode, node) => {
        if (foundNode) return foundNode;
        if (node.id === id) return node;
        if (node.type === 'group') return Utils.searchById(id, node.children);
        return foundNode;
      },
      null
    ),
  map: (models, callback) =>
    _.reduce(
      models,
      (mappedTree, node, index) => {
        if (node.type === 'group') {
          mappedTree.push({
            ...node,
            children: Utils.map(node.children, callback),
          });
        } else {
          mappedTree.push(callback(node, index));
        }
        return mappedTree;
      },
      []
    ),
  reduce: (models, callback, accumulator) =>
    _.reduce(models, callback, accumulator),
  removeById: (id, models) =>
    _.reduce(
      models,
      (newModelTree, node) => {
        if (node.type === 'group') {
          const updatedChildren = Utils.removeById(id, node.children);
          if (!_.isEmpty(updatedChildren)) {
            newModelTree.push({
              ...node,
              children: updatedChildren,
            });
          }
        } else if (node.id !== id) {
          newModelTree.push(node);
        }
        return newModelTree;
      },
      []
    ),
  getExtrudersUsed: (models, style, inputCount) => {
    const extrudersSeen = new Array(inputCount).fill(false);
    if (style) {
      if (style.useRaft) {
        extrudersSeen[style.defaultRaftExtruder || 0] = true;
      }
      if (style.useSupport) {
        if (style.defaultSupportExtruder.value !== 'auto') {
          extrudersSeen[style.defaultSupportExtruder.value || 0] = true;
        }
        if (style.useSupportInterface) {
          extrudersSeen[style.defaultSupportInterfaceExtruder || 0] = true;
        }
      }
    }
    Utils.map(models, (node) => {
      // look at triangle colors first
      if (node.colored) {
        // if colored, use color RLE data
        for (let i = 1; i < node.rle.length; i += 2) {
          extrudersSeen[node.rle[i]] = true;
        }
      } else {
        // if not colored, use model extruder
        extrudersSeen[node.extruder] = true;
      }
      // if textured, additionally consider stamp colors
      if (node.textured) {
        const stampsArray = _.values(node.stamps);
        let currentStamp;
        for (let j = 0; j < stampsArray.length; j++) {
          currentStamp = stampsArray[j];
          for (let k = 0; k < currentStamp.extrudersUsed.length; k++) {
            if (currentStamp.extrudersUsed[k]) {
              extrudersSeen[k] = true;
            }
          }
        }
      }
    });
    return extrudersSeen;
  },
  getUniqueExtruderCount: (models, style, inputCount) => {
    const extrudersSeen = Utils.getExtrudersUsed(models, style, inputCount);
    return extrudersSeen.reduce(
      (accumulator, ext) => (ext ? accumulator + 1 : accumulator),
      0
    );
  },
  getParent: (models, model) => {
    const findChild = (ms, m) =>
      _.reduce(
        ms,
        (parent, node) => {
          if (parent) return parent;
          if (node.type === 'group') {
            const foundChild = findChild(node.children, m);
            if (foundChild) return node;
          }
          if (node.id === m.id) return true;
          return parent;
        },
        null
      );
    const parent = findChild(models, model);
    if (parent === true) {
      // model is at root of model tree
      return null;
    }
    return parent;
  },
  flattenDeep: (models) =>
    _.reduce(
      models,
      (flattenedTree, model) => {
        if (model.type === 'group') {
          return flattenedTree.concat(Utils.flattenDeep(model.children));
        }
        flattenedTree.push(model);
        return flattenedTree;
      },
      []
    ),
  sort: (models) =>
    models.sort((a, b) => {
      // NOTE: the case where there is more than one group with the same name and with the
      // same parent node will result in an unspecified order of those groups.
      if (a.type === 'group') {
        // eslint-disable-next-line no-param-reassign
        a.children = Utils.sort(a.children);
      }
      if (b.type === 'group') {
        // eslint-disable-next-line no-param-reassign
        b.children = Utils.sort(b.children);
      }
      if (a.type === 'group' && b.type !== 'group') {
        return -1;
      }
      if (a.type !== 'group' && b.type === 'group') {
        return 1;
      }
      if (
        a.name.toLowerCase() === b.name.toLowerCase() &&
        a.type !== 'group' &&
        b.type !== 'group'
      ) {
        return a.id.localeCompare(b.id);
      }
      return compareStringsWithNumbers(a.name, b.name);
    }),
  updateModelColors: (models, newColors) => {
    const newExtruderColors = newColors.map((color) => ({
      color: new THREE.Vector3(color[0] / 255, color[1] / 255, color[2] / 255),
    }));

    return Utils.map(models, (item) => {
      const model = item;
      // update extruderColors uniform on parent mesh
      model.mesh.material.uniforms.extruderColors.value = newExtruderColors;

      if (model.mesh.children.length) {
        // update extruderColors uniform on stamp meshes as well
        let currentStampGroup;
        for (let i = 0; i < model.mesh.children.length; i++) {
          currentStampGroup = model.mesh.children[i];
          const [currentStamp] = currentStampGroup.children;
          const stampUniforms = currentStamp.material.uniforms;

          stampUniforms.extruderColors.value = newExtruderColors;
        }
      }
      return model;
    });
  },
};

export default Utils;

import styled from 'styled-components';

import { Breakpoints } from '../../../../../themes';

export const SliderContainer = styled.div`
  width: 100%;
  height: auto;
  pointer-events: ${(props) => (props.brushSelected ? 'auto' : 'none')};

  @media (max-height: ${Breakpoints.extraSmallHeight}) {
    margin: 1rem 0.5rem 0;
  }
`;

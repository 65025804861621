import types from './types';

const actions = {
  resetOnboardingStore: () => ({
    type: types.RESET_ONBOARDING_STORE,
    payload: {},
  }),
  getOnboardingStateRequest: () => ({
    type: types.GET_ONBOARDING_STATE_REQUEST,
    payload: {},
  }),
  getOnboardingStateSuccess: (modals, tasks) => ({
    type: types.GET_ONBOARDING_STATE_SUCCESS,
    payload: { modals, tasks },
  }),
  getOnboardingStateFailure: (error) => ({
    type: types.GET_ONBOARDING_STATE_FAILURE,
    payload: { error },
  }),
  startModalRequest: (identifier) => ({
    type: types.START_MODAL_REQUEST,
    payload: { identifier },
  }),
  startModalSuccess: () => ({
    type: types.START_MODAL_SUCCESS,
    payload: {},
  }),
  startModalFailure: (error) => ({
    type: types.START_MODAL_FAILURE,
    payload: { error },
  }),
  completeModalRequest: (identifier) => ({
    type: types.COMPLETE_MODAL_REQUEST,
    payload: { identifier },
  }),
  completeModalSuccess: () => ({
    type: types.COMPLETE_MODAL_SUCCESS,
    payload: {},
  }),
  completeModalFailure: (error) => ({
    type: types.COMPLETE_MODAL_FAILURE,
    payload: { error },
  }),
  startTaskRequest: (identifier) => ({
    type: types.START_TASK_REQUEST,
    payload: { identifier },
  }),
  startTaskSuccess: () => ({
    type: types.START_TASK_SUCCESS,
    payload: {},
  }),
  startTaskFailure: (error) => ({
    type: types.START_TASK_FAILURE,
    payload: { error },
  }),
  setTaskStepStatusRequest: (task, status) => ({
    type: types.SET_TASK_STEP_STATUS_REQUEST,
    payload: { task, status },
  }),
  setTaskStepStatusSuccess: () => ({
    type: types.SET_TASK_STEP_STATUS_SUCCESS,
    payload: {},
  }),
  setTaskStepStatusFailure: (error) => ({
    type: types.SET_TASK_STEP_STATUS_FAILURE,
    payload: { error },
  }),
};

export default actions;

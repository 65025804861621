import 'regenerator-runtime/runtime';
import { call, select } from 'redux-saga/effects';

import { getThreeState, handleGenericError } from '../common';
import { setRenderFlag } from './animationFrame';

export default function* hidePrintBed(action) {
  try {
    const state = yield select(getThreeState);
    const { scene, originAxes, printBed, printBedBoundingBox, printBedGrid } =
      state;

    if (originAxes) {
      scene.remove(originAxes);
    }
    if (printBed) {
      scene.remove(printBed);
    }
    if (printBedBoundingBox) {
      scene.remove(printBedBoundingBox);
    }
    if (printBedGrid) {
      scene.remove(printBedGrid);
    }
    setRenderFlag();
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

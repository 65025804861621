import styled from 'styled-components';
import { Breakpoints } from '../../themes';

export const CanvasElement = styled.canvas`
  width: 100%;
  height: 100%;

  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  @media (max-width: ${Breakpoints.smallWidth}) {
    max-width: 100%;
  }
`;

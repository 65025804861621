import React, { Component } from 'react';
import _ from 'lodash';

import { withTheme } from 'styled-components';

import {
  AccountManagerInputWrapper,
  ButtonWrapper,
  ColorPalette,
  EditPasswordWrapper,
  FooterButtonWrapper,
  Integration,
  IntegrationButtonWrapper,
  IntegrationImage,
  IntegrationLink,
  IntegrationTextContainer,
  IntegrationsWrapper,
  LoginRecordWrapper,
  LogoutButtonWrapper,
  PasswordButtonWrapper,
  PasswordRequirements,
  ProjectColorsContainer,
  StyledCaption,
} from './accountManager.styles';

import ConfigureColor from '../slicer/placeView/tools/material/configureColor/configureColor.jsx';

import { formFields, sidebarTabs, inputFields } from './fields.metadata';
import Routes from '../../router/routes';

import { FormatUtils, InterfaceUtils } from '../../utils';

import { defaultColors as CanvasColors } from '../../reducers/slicer/initialState';

import {
  Body1,
  Button,
  Caption,
  Checkbox,
  ColorSwatch,
  ConfirmationModal,
  Dropdown,
  Form,
  Input,
  Modal,
  ModalHeader,
  ModalFooter,
  Page,
  DeviceDropdown,
  SideBar,
  Subtitle1,
  Subtitle2,
} from '../../shared';

import LoginRecord from './loginRecord';
import { fieldTypes } from '../../constants';

const getColorState = (defaultColors) => {
  if (defaultColors === 'auto') {
    return {
      colors: CanvasColors,
      auto: true,
    };
  }
  return {
    colors: defaultColors,
    auto: false,
  };
};

class AccountManager extends Component {
  constructor(props) {
    super(props);
    const colorState = getColorState(props.newProjectOptions.defaultColors);
    this.state = {
      showDeleteModal: false,
      showConfigureColorModal: false,
      extruderIndex: null, // index of color currently being editing
      focusField: null,
      activeTabIndex: 0, // used for switching between SideBar tabs
      showChangeEmailModal: false,
      showChangePasswordModal: false,
      autoColors: colorState.auto,
      values: {
        email: '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        defaultSlicer: props.newProjectOptions.defaultSlicer,
        defaultStyle: props.newProjectOptions.defaultStyle,
        defaultMaterial: props.newProjectOptions.defaultMaterial,
        defaultPrinter: props.newProjectOptions.defaultPrinter,
        defaultDeviceConfig: props.newProjectOptions.defaultDeviceConfig,
        defaultColors: colorState.colors,
        showNewProjectDialog: props.newProjectOptions.showDialog,
        includeThumbnailInZip: props.preferences.includeThumbnailInZip,
        preferUnzipped: props.preferences.preferUnzipped,
        theme: props.preferences.theme,
      },
      errors: {
        email: true,
        currentPassword: true,
        newPassword: true,
        confirmPassword: true,
      },
    };
  }

  componentDidMount() {
    // emit error toast if user was redirected here with an error in the URL
    const parsedParams = FormatUtils.parseQueryParams(
      this.props.location.search
    );
    if (parsedParams.error) {
      // special handling of access_denied error because it could be caused by the user
      if (parsedParams.error === 'access_denied') {
        InterfaceUtils.emitToast(
          'info',
          'The user or authorization server denied the request'
        );
      } else {
        InterfaceUtils.emitToast('error', parsedParams.error);
      }
    }

    this.props.updateNavStack([
      { text: 'My Account', route: Routes.toManageAccounts() },
    ]);

    if (this.props.userEmail) {
      this.setState({
        values: {
          ...this.state.values,
          email: this.props.userEmail,
        },
        errors: {
          ...this.state.errors,
          email: false,
        },
      });
    }
    if (
      !this.props.getIntegrationsSuccess &&
      !this.props.getIntegrationsPending
    ) {
      // retrieve list of integrations
      this.props.getIntegrations();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.deleteAccountSuccess && this.props.deleteAccountSuccess) {
      // redirect on delete account success
      this.props.history.push(Routes.toHome());
      this.props.logout();
    }
    // update component state with retrieved account info
    let doUpdate = false;
    const newState = {
      values: {
        ...this.state.values,
      },
      errors: {
        ...this.state.errors,
      },
    };
    if (this.props.userEmail !== prevProps.userEmail) {
      doUpdate = true;
      newState.values.email = this.props.userEmail;
      newState.errors.email = false;
    }
    if (prevProps.newProjectOptions !== this.props.newProjectOptions) {
      doUpdate = true;
      newState.values.defaultSlicer =
        this.props.newProjectOptions.defaultSlicer;
      newState.values.defaultPrinter =
        this.props.newProjectOptions.defaultPrinter;
      newState.values.defaultDeviceConfig =
        this.props.newProjectOptions.defaultDeviceConfig;
      newState.values.defaultStyle = this.props.newProjectOptions.defaultStyle;
      newState.values.defaultMaterial =
        this.props.newProjectOptions.defaultMaterial;
      const colorState = getColorState(
        this.props.newProjectOptions.defaultColors
      );
      newState.autoColors = colorState.auto;
      newState.values.defaultColors = colorState.colors;
      newState.values.showNewProjectDialog =
        this.props.newProjectOptions.showDialog;
    }
    if (doUpdate) {
      this.setState(newState);
    }
  }

  focusField(fieldName) {
    this.setState({ focusField: fieldName }, () => {
      this.setState({ focusField: null });
    });
  }

  onChangeSuccess(fieldName, value) {
    this.setState({
      values: {
        ...this.state.values,
        [fieldName]: value,
      },
      errors: {
        ...this.state.errors,
        [fieldName]: false,
      },
    });
  }

  onChangeFailure(fieldName) {
    this.setState({
      errors: {
        ...this.state.errors,
        [fieldName]: true,
      },
    });
  }

  handleUpdateEmail() {
    if (this.state.errors.email) {
      this.focusField('email');
      return;
    }
    this.props.editAccountRequest({ email: this.state.values.email });
    this.setState({ showChangeEmailModal: false });
  }

  handleUpdatePassword() {
    if (this.state.errors.newPassword) {
      this.focusField('newPassword');
      return;
    }
    if (this.state.errors.confirmPassword) {
      this.focusField('confirmPassword');
      return;
    }
    if (this.state.errors.currentPassword) {
      this.focusField('currentPassword');
      return;
    }
    this.props.editAccountRequest({
      currentPassword: this.state.values.currentPassword,
      newPassword: this.state.values.newPassword,
      confirmPassword: this.state.values.confirmPassword,
    });
    this.setState({ showChangePasswordModal: false });
  }

  handleUpdateNewProjectOptions() {
    const newProjectOptions = {
      defaultPrinter: this.state.values.defaultPrinter,
      defaultSlicer: this.state.values.defaultSlicer,
      defaultStyle: this.state.values.defaultStyle,
      defaultMaterial: this.state.values.defaultMaterial,
      defaultColors: this.state.autoColors
        ? 'auto'
        : this.state.values.defaultColors,
      showDialog: this.state.values.showNewProjectDialog,
      defaultDeviceConfig: this.state.values.defaultDeviceConfig,
    };
    this.props.editAccountRequest({ newProjectOptions });
  }

  toggleConfigureColorModal(extruderIndex = null) {
    this.setState({
      showConfigureColorModal: !this.state.showConfigureColorModal,
      extruderIndex,
    });
  }

  updateDefaultColor(newColor) {
    const { extruderIndex } = this.state;
    const { defaultColors } = this.state.values;
    const updatedColors = [...defaultColors];
    updatedColors[extruderIndex] = newColor;

    this.setState({
      showConfigureColorModal: false,
      extruderIndex: null,
      autoColors: false,
      values: {
        ...this.state.values,
        defaultColors: updatedColors,
      },
    });
  }

  renderProjectColors() {
    const colors = this.state.values.defaultColors;
    const selection = _.map(colors, (color, index) =>
      this.renderColorSwatch(color, index)
    );
    return (
      <ProjectColorsContainer>
        <ColorPalette>{selection}</ColorPalette>
      </ProjectColorsContainer>
    );
  }

  renderColorSwatch(color, driveIndex) {
    return (
      <ColorSwatch
        static
        forceShowLabel
        color={color}
        key={driveIndex}
        label={driveIndex + 1}
        onSelect={() => this.toggleConfigureColorModal(driveIndex)}
      />
    );
  }

  renderConfigureColorModal() {
    const { showConfigureColorModal, extruderIndex } = this.state;
    if (!showConfigureColorModal || extruderIndex === null) return null;

    const [r, g, b, a] = this.state.values.defaultColors[extruderIndex];
    const colorObj = {
      r,
      g,
      b,
      a,
    };

    return (
      <Modal width='25rem'>
        <ConfigureColor
          currentColor={colorObj}
          onClickClose={() => this.toggleConfigureColorModal()}
          onUpdateProjectColor={(newColor) => this.updateDefaultColor(newColor)}
        />
      </Modal>
    );
  }

  renderIntegration(integrationData) {
    const { userIntegrations } = this.props;
    const {
      name,
      description,
      id: integrationId,
      image,
      link,
    } = integrationData;
    const integrationIsLinked =
      userIntegrations &&
      userIntegrations[integrationId] &&
      userIntegrations[integrationId].linked;
    return (
      <Integration key={integrationId}>
        <IntegrationLink href={link} target='_blank' rel='noopener noreferrer'>
          {image ? <IntegrationImage src={image} /> : null}
        </IntegrationLink>
        <IntegrationTextContainer>
          <Subtitle2>{name}</Subtitle2>
          <Caption grey noSpacing>
            {description}
          </Caption>
        </IntegrationTextContainer>
        <IntegrationButtonWrapper>
          <Button
            minimal
            buttonTextColor={this.props.theme.colors.greenDefault}
            onClick={() =>
              integrationIsLinked
                ? this.props.unlinkIntegration(integrationId)
                : this.props.linkIntegration(integrationId)
            }>
            {integrationIsLinked ? 'Unlink' : 'Link'}
          </Button>
        </IntegrationButtonWrapper>
      </Integration>
    );
  }

  renderIntegrations() {
    const { allIntegrations } = this.props;
    return (
      <IntegrationsWrapper>
        {allIntegrations ? (
          <>
            {_.map(allIntegrations, (integration) =>
              this.renderIntegration(integration)
            )}
          </>
        ) : null}
      </IntegrationsWrapper>
    );
  }

  renderLoginHistory() {
    return (
      <LoginRecordWrapper>
        {_.map(this.props.loginHistory, (login, idx) => (
          <LoginRecord key={idx} details={login} />
        ))}
      </LoginRecordWrapper>
    );
  }

  renderDeleteModal() {
    if (!this.state.showDeleteModal) return null;

    return (
      <ConfirmationModal
        isWarning
        primaryLabel='Delete Account'
        secondaryLabel='Are you sure you would like to delete your account
        and all associated content and settings?'
        tertiaryLabel='This action cannot be undone!'
        onClickCancel={() => this.setState({ showDeleteModal: false })}
        onClickConfirm={() => this.props.deleteAccountRequest()}
      />
    );
  }

  updateField(fieldName, value) {
    const updatedValues = { ...this.state.values };
    updatedValues[fieldName] = value;

    switch (fieldName) {
      case 'defaultPrinter':
        return this.handlePrinterChange();
      default:
        return this.setState({
          values: updatedValues,
        });
    }
  }

  handlePrinterChange(value) {
    const updatedValues = {
      ...this.state.values,
      defaultPrinter: value,
      defaultStyle: 'auto',
    };
    return this.setState({
      values: updatedValues,
    });
  }

  handleThemeChange(newTheme) {
    const updatedInfo = {
      preferences: { theme: newTheme },
    };
    this.props.editAccountRequest(updatedInfo);
  }

  handleIncludeThumbnailToggle(value) {
    const updatedPreference = {
      preferences: {
        includeThumbnailInZip: value,
      },
    };
    this.props.editAccountRequest(updatedPreference);
  }

  handlePreferUnzippedToggle(value) {
    const updatedPreference = {
      preferences: {
        preferUnzipped: value,
      },
    };
    this.props.editAccountRequest(updatedPreference);
  }

  onFieldChange(fieldName, value) {
    switch (fieldName) {
      case 'theme':
        return (
          this.handleThemeChange(value), this.updateField(fieldName, value)
        );
      case 'defaultPrinter':
        return this.handlePrinterChange(value);
      case 'includeThumbnailInZip':
        return (
          this.handleIncludeThumbnailToggle(value),
          this.updateField(fieldName, value)
        );
      case 'preferUnzipped':
        return (
          this.handlePreferUnzippedToggle(value),
          this.updateField(fieldName, value)
        );
      default:
        return this.updateField(fieldName, value);
    }
  }

  clearValuesState() {
    const colorState = getColorState(
      this.props.newProjectOptions.defaultColors
    );
    const defaultValues = {
      email: '',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      defaultSlicer: this.props.newProjectOptions.defaultSlicer,
      defaultStyle: this.props.newProjectOptions.defaultStyle,
      defaultMaterial: this.props.newProjectOptions.defaultMaterial,
      defaultPrinter: this.props.newProjectOptions.defaultPrinter,
      defaultDeviceConfig: this.props.newProjectOptions.defaultDeviceConfig,
      defaultColors: colorState.colors,
      showNewProjectDialog: this.props.newProjectOptions.showDialog,
      includeThumbnailInZip: this.props.preferences.includeThumbnailInZip,
      preferUnzipped: this.props.preferences.preferUnzipped,
      theme: this.props.preferences.theme,
    };
    this.setState({
      autoColors: colorState.auto,
      values: defaultValues,
    });
  }

  getValue(field) {
    let value = this.state.values[field.name];
    if (typeof field.value === 'function') {
      value = field.value(this.state.values);
    }
    return value;
  }

  isDisabled(field) {
    let isDisabled = !!field.disabled;
    if (typeof field.disabled === 'function') {
      isDisabled = field.disabled(this.state.values);
    }
    return isDisabled;
  }

  renderDropdown(field) {
    let { options, allowFilter } = field;
    if (typeof options === 'function') {
      options = options(
        this.state.values,
        this.props.printers,
        this.props.materials
      );
    }
    const isDisabled = this.isDisabled(field);
    return (
      <Dropdown
        allowFilter={allowFilter}
        rightAlign
        value={this.getValue(field)}
        options={options}
        disabled={isDisabled}
        onChange={(value) => {
          const newValue = field.numeric ? parseFloat(value) : value;
          this.onFieldChange(field.name, newValue);
        }}
      />
    );
  }

  renderDeviceDropdown() {
    const { printers } = this.props;
    const { defaultPrinter } = this.state.values;
    const isElementSelected =
      printers[defaultPrinter] &&
      printers[defaultPrinter].machineSettings.extension === 'mcfx';
    return (
      <DeviceDropdown
        hideLabel
        deviceConfig={this.state.values.defaultDeviceConfig}
        onChange={(deviceConfig) => {
          this.onFieldChange('defaultDeviceConfig', deviceConfig);
        }}
        disabled={isElementSelected}
      />
    );
  }

  renderCheckbox(field) {
    if (field.type !== fieldTypes.checkbox) return null;
    return (
      <Checkbox
        label={field.label}
        value={this.getValue(field)}
        onChange={(e) => this.onFieldChange(field.name, e.target.checked)}
      />
    );
  }

  renderVisibleFields(group) {
    const filterGroup = group.fields ? group.fields : group;
    return _.filter(filterGroup, (field) => {
      if (typeof field.display === 'function') {
        return field.display(this.state.values);
      }
      return true;
    });
  }

  renderDeleteAccountButton() {
    return (
      <Button
        primary
        warning
        onClick={() => this.setState({ showDeleteModal: true })}>
        Delete account
      </Button>
    );
  }

  renderSaveButton() {
    return (
      <ButtonWrapper>
        <Button primary onClick={() => this.handleUpdateNewProjectOptions()}>
          Save
        </Button>
      </ButtonWrapper>
    );
  }

  renderLogoutButton() {
    return (
      <LogoutButtonWrapper>
        <Button minimal warning onClick={() => this.props.logoutAllSessions()}>
          Logout from all devices
        </Button>
      </LogoutButtonWrapper>
    );
  }

  renderPasswordButton() {
    return (
      <PasswordButtonWrapper>
        <Button
          minimal
          buttonTextColor={this.props.theme.colors.greenDefault}
          onClick={() => this.setState({ showChangePasswordModal: true })}>
          Change password
        </Button>
      </PasswordButtonWrapper>
    );
  }

  renderChangeEmail() {
    const { values } = this.state;
    const { userEmail, theme } = this.props;
    return (
      <EditPasswordWrapper>
        <Body1 noSpacing>{userEmail}</Body1>
        <Button
          minimal
          buttonTextColor={theme.colors.greenDefault}
          onClick={() =>
            this.setState({
              showChangeEmailModal: true,
              values: { ...values, email: userEmail },
            })
          }>
          Edit
        </Button>
      </EditPasswordWrapper>
    );
  }

  onResetClick() {
    return this.setState({
      values: {
        ...this.state.values,
        defaultColors: CanvasColors,
      },
      autoColors: true,
    });
  }

  onClose() {
    return this.setState({
      values: {
        ...this.state.values,
        newPassword: '',
        currentPassword: '',
        confirmPassword: '',
      },
      showChangePasswordModal: false,
      showChangeEmailModal: false,
    });
  }

  renderChangePasswordModal() {
    if (!this.state.showChangePasswordModal) return null;
    return (
      <Modal width='21rem'>
        <ModalHeader>
          <Subtitle1>Update your email address</Subtitle1>
        </ModalHeader>
        <AccountManagerInputWrapper>
          <Body1>
            Passwords may not contain your username, must be at least 8
            characters, and must contain at least two of the following:
          </Body1>
          <PasswordRequirements>
            <li>
              <Body1 noSpacing>uppercase letters</Body1>
            </li>
            <li>
              <Body1 noSpacing>lowercase letters</Body1>
            </li>
            <li>
              <Body1 noSpacing>numbers</Body1>
            </li>
            <li>
              <Body1 noSpacing>symbols</Body1>
            </li>
          </PasswordRequirements>
          <Input
            type='password'
            autoFill='current-password'
            label={inputFields.currentPassword.label}
            value={this.state.values.currentPassword}
            validator={(value) =>
              inputFields.currentPassword.validator(value, this.state)
            }
            onChangeSuccess={(value) =>
              this.onChangeSuccess('currentPassword', value)
            }
            onChangeFailure={() => this.onChangeFailure('currentPassword')}
          />
        </AccountManagerInputWrapper>
        <AccountManagerInputWrapper>
          <Input
            type='password'
            autoFill='new-password'
            label={inputFields.newPassword.label}
            value={this.state.values.newPassword}
            validator={(value) =>
              inputFields.newPassword.validator(value, this.state)
            }
            onChangeSuccess={(value) =>
              this.onChangeSuccess('newPassword', value)
            }
            onChangeFailure={() => this.onChangeFailure('newPassword')}
          />
        </AccountManagerInputWrapper>
        <AccountManagerInputWrapper>
          <Input
            type='password'
            autoFill='new-password'
            label={inputFields.confirmPassword.label}
            value={this.state.values.confirmPassword}
            validator={(value) =>
              inputFields.confirmPassword.validator(value, this.state)
            }
            onChangeSuccess={(value) =>
              this.onChangeSuccess('confirmPassword', value)
            }
            onChangeFailure={() => this.onChangeFailure('confirmPassword')}
          />
        </AccountManagerInputWrapper>
        <ModalFooter>
          <FooterButtonWrapper>
            <Button onClick={() => this.onClose()}>Cancel</Button>
            <Button primary onClick={() => this.handleUpdatePassword()}>
              Save
            </Button>
          </FooterButtonWrapper>
        </ModalFooter>
      </Modal>
    );
  }

  renderChangeEmailModal() {
    if (!this.state.showChangeEmailModal) return null;
    return (
      <Modal width='21rem'>
        <ModalHeader>
          <Subtitle1>Update your email address</Subtitle1>
        </ModalHeader>
        <Body1 noSpacing>
          After updating your email address, you will be sent a verification
          email to confirm the change.
        </Body1>
        <AccountManagerInputWrapper>
          <Input
            type='email'
            label={inputFields.email.label}
            value={this.state.values.email}
            onChangeSuccess={(value) => this.onChangeSuccess('email', value)}
            onChangeFailure={() => this.onChangeFailure('email')}
          />
        </AccountManagerInputWrapper>
        <ModalFooter>
          <FooterButtonWrapper>
            <Button onClick={() => this.onClose()}>Cancel</Button>
            <Button
              disabled={this.props.userEmail === this.state.values.email}
              primary
              onClick={() => this.handleUpdateEmail()}>
              Save
            </Button>
          </FooterButtonWrapper>
        </ModalFooter>
      </Modal>
    );
  }

  renderSwatchDescription() {
    return (
      <>
        <Caption grey noSpacing>
          Customize the colors used by new projects by default.
        </Caption>
        {!this.state.autoColors && (
          <StyledCaption green noSpacing onClick={() => this.onResetClick()}>
            Reset
          </StyledCaption>
        )}
      </>
    );
  }

  renderField(field) {
    switch (field.type) {
      case fieldTypes.text:
        return this.renderIconField(field);
      case fieldTypes.number:
        return this.renderNumberField(field);
      case fieldTypes.checkbox:
        return this.renderCheckbox(field);
      case fieldTypes.dropdown:
        return this.renderDropdown(field);
      case 'deviceDropdown':
        return this.renderDeviceDropdown();
      case 'colorSwatch':
        return this.renderProjectColors();
      case 'loginHistory':
        return this.renderLogoutButton();
      case 'integrations':
        return this.renderIntegrations();
      case 'deleteAccountButton':
        return this.renderDeleteAccountButton();
      case 'password':
        return this.renderPasswordButton();
      case 'emailAddress':
        return this.renderChangeEmail();
      default:
        return null;
    }
  }

  renderFieldGroup(group, index) {
    const visibleFields = this.renderVisibleFields(group);
    if (_.isEmpty(visibleFields)) return null;
    if (group.subformItem) {
      const subformData = {
        children: this.renderSubformItems(visibleFields),
      };
      return <Form key={index} items={[subformData]} />;
    }
    const formData = [];
    _.map(visibleFields, (field) => {
      if (field.type === 'colorSwatch') {
        const formField = {
          ...field,
          description: this.renderSwatchDescription(),
          content: this.renderField(field),
        };
        return formData.push(formField);
      }

      const formField = {
        ...field,
        content: this.renderField(field),
      };
      return formData.push(formField);
    });
    return <Form key={index} items={formData} />;
  }

  renderLoginSecurityTab() {
    return (
      <>
        {this.renderFieldGroup(formFields.loginAndSecurity)}
        {this.renderLoginHistory()}
      </>
    );
  }

  renderNewProjectsTab() {
    return (
      <>
        {this.renderFieldGroup(formFields.newProject)}
        {this.renderSaveButton()}
      </>
    );
  }

  renderSideBarContent(index) {
    const currentTab = sidebarTabs[index].text;
    switch (currentTab) {
      case 'General':
        return this.renderFieldGroup(formFields.general);
      case 'New Projects':
        return this.renderNewProjectsTab();
      case 'Sliced Files':
        return this.renderFieldGroup(formFields.slicedFiles);
      case 'Login and Security':
        return this.renderLoginSecurityTab();
      case 'Integrations':
        return this.renderIntegrations();
      case 'Danger Zone':
        return this.renderFieldGroup(formFields.dangerZone);
      default:
        return null;
    }
  }

  setActiveTabIndex(index) {
    this.clearValuesState();
    return this.setState({ activeTabIndex: index });
  }

  renderSideBar() {
    const { activeTabIndex } = this.state;
    return (
      <SideBar
        items={sidebarTabs}
        activeTab={this.state.activeTabIndex}
        setActiveTab={(index) => this.setActiveTabIndex(index)}
        fullScreenView>
        {this.renderSideBarContent(activeTabIndex)}
      </SideBar>
    );
  }

  renderMainPanelContent() {
    return (
      <>
        {this.renderDeleteModal()}
        {this.renderConfigureColorModal()}
        {this.renderChangePasswordModal()}
        {this.renderChangeEmailModal()}
        {this.renderSideBar()}
        {InterfaceUtils.getLoadingSpinner(this.props)}
      </>
    );
  }

  render() {
    return <Page hideDivider>{this.renderMainPanelContent()}</Page>;
  }
}

export default withTheme(AccountManager);

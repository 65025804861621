import React from 'react';
import Metadata from './temperature.metadata';
import StyleSettingsFormSection from '../styleSettingsFormSection.jsx';

import { Container, Col } from '../forms.styles';

class Content extends StyleSettingsFormSection {
  static isSectionMatched(props, filterBy) {
    return (
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.temperature,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.minLayerTime,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.cooling,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.bridging,
        filterBy
      )
    );
  }

  render() {
    return (
      <Container>
        <Col>
          {this.renderFieldGroup(Metadata.temperature)}
          {this.renderFieldGroup(Metadata.minLayerTime)}
        </Col>
        <Col>
          {this.renderFieldGroup(Metadata.cooling)}
          {this.renderFieldGroup(Metadata.bridging)}
        </Col>
      </Container>
    );
  }
}

export default Content;

import { connect } from 'react-redux';

import Playback from './playback.jsx';

import { actions } from '../../../../../reducers/iot/iot';

const mapStateToProps = (state) => ({
  webRtcConnectionOffer: state.iot.webRtcConnectionOffer,
  initWebRtcConnectionPending: state.iot.status.initWebRtcConnectionPending,
  initWebRtcConnectionSuccess: state.iot.status.initWebRtcConnectionSuccess,
  establishWebRtcConnectionPending:
    state.iot.status.establishWebRtcConnectionPending,
  establishWebRtcConnectionSuccess:
    state.iot.status.establishWebRtcConnectionSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  initConnection: (deviceId) =>
    dispatch(actions.initWebRtcConnectionRequest(deviceId)),
  establishConnection: (deviceId, connectionAnswer) =>
    dispatch(
      actions.establishWebRtcConnectionRequest(deviceId, connectionAnswer)
    ),
  keepAlive: (deviceId, sessionId) =>
    dispatch(actions.webRtcKeepAliveRequest(deviceId, sessionId)),
  removeConnection: () => dispatch(actions.removeWebRtcConnectionOffer()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Playback);

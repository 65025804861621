import 'regenerator-runtime/runtime';
import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';

import { groupModelsCall } from './groupModels';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

export default function* alignAndGroupModels(action) {
  try {
    const { currentProject: projectId } = yield select(getSlicerState);

    // update thumbnail
    yield put(actions.updateProjectThumbnailRequest(projectId));

    const { models, groupName, newCenter } = action.payload;
    const groupResponses = yield call(groupModelsCall, models, groupName);
    if (_.some(groupResponses, (groupResponse) => groupResponse === null))
      return;

    const transformsData = _.map(models, (model) => ({
      id: model.id,
      transforms: {
        ...model.transforms,
        translate: newCenter,
      },
    }));
    yield put(actions.updateModelTransformsRequest(transformsData));
    yield put(actions.alignAndGroupModelsSuccess());
    yield put(actions.invalidateSlice(projectId));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

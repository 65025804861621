// brand
import mosaicLogoIconColorful from './images/brand/mosaic-logo-icon-colorful.svg';
import mosaicLogoIconBlack from './images/brand/mosaic-logo-icon-black.svg';
import mosaicLogoIconWhite from './images/brand/mosaic-logo-icon-white.svg';
import mosaicLogoTextBlack from './images/brand/mosaic-logo-text-black.svg';
import mosaicLogoTextWhite from './images/brand/mosaic-logo-text-white.svg';
import mosaicLogoFullBlack from './images/brand/mosaic-logo-full-black.svg';
import mosaicLogoFullWhite from './images/brand/mosaic-logo-full-white.svg';
import canvasLogoFullBlack from './images/brand/canvas-logo-full-black.svg';
import canvasLogoFullWhite from './images/brand/canvas-logo-full-white.svg';

// cursors
import fillCursor from './images/cursors/fill.svg';
import fillCursor2x from './images/cursors/fill-2x.svg';
import sphereCursor from './images/cursors/sphere.svg';
import sphereCursor2x from './images/cursors/sphere-2x.svg';
import triangleCursor from './images/cursors/triangle.svg';
import triangleCursor2x from './images/cursors/triangle-2x.svg';

// devices
import paletteImage from './images/devices/p.png';
import palettePlusImage from './images/devices/p-plus.png';
import palette2Image from './images/devices/p2.png';
import palette2ProImage from './images/devices/p2p.png';
import palette3Image from './images/devices/p3.png';
import palette3ProImage from './images/devices/p3p.png';
import hubImage from './images/devices/canvas-hub.png';
import hubSImage from './images/devices/canvas-hub-s.png';
import customHubImage from './images/devices/diy-hub.png';
import elementImage from './images/devices/element.png';
import elementHTImage from './images/devices/element-ht.png';

// interface
import backgroundLight from './images/interface/canvas-background-light.svg';
import backgroundDark from './images/interface/canvas-background-dark.svg';
import calibrationPrint from './images/interface/calibration-print.svg';
import homepagePattern from './images/interface/homepage-pattern.svg';
import folder from './images/interface/folder.svg';
import noContent from './images/interface/no-content.svg';
import extruder from './images/interface/extruder.svg';

// onboarding
import welcomeToCanvas from './images/onboarding/welcome-to-canvas.svg';
import firmwareUpdate from './images/onboarding/firmware-update.svg';
import printerOnboarding from './images/onboarding/printer.svg';
import addPrinter from './images/onboarding/add-printer.svg';
import addDevice from './images/onboarding/add-device.svg';
import fourInputBenchy from './images/onboarding/four-input-benchy.svg';
import hereToHelp from './images/onboarding/here-to-help.svg';
import adminPanel from './images/onboarding/admin-panel.svg';
import connectionSettings from './images/onboarding/connection-settings.svg';
import controlHub from './images/onboarding/control-hub.svg';
import controlPalette from './images/onboarding/control-palette.svg';
import sendToP3 from './images/onboarding/send-to-p3.svg';
import slic3r from './images/onboarding/slic3r.svg';
import newLayout from './images/onboarding/new-layout.png';
import setupsSimplification from './images/onboarding/setups-simplification.png';
import newMaterialsPanel from './images/onboarding/new-materials-panel.png';
import pingsAndWebcam from './images/onboarding/pings-and-webcam.png';

// printers
import printerIcon1 from './images/printers/1.svg';
import printerIcon2 from './images/printers/2.svg';
import printerIcon3 from './images/printers/3.svg';
import printerIcon4 from './images/printers/4.svg';
import printerIcon5 from './images/printers/5.svg';
import printerIcon6 from './images/printers/6.svg';
import printerIcon7 from './images/printers/7.svg';
import printerIcon8 from './images/printers/8.svg';
import printerIcon9 from './images/printers/9.svg';
import printerIcon10 from './images/printers/10.svg';
import printerIcon11 from './images/printers/11.svg';
import printerIcon12 from './images/printers/12.svg';
import printerIcon13 from './images/printers/13.svg';
import printerIcon14 from './images/printers/14.svg';
import printerIcon15 from './images/printers/15.svg';
import printerIcon16 from './images/printers/16.svg';
import printerIcon17 from './images/printers/17.svg';
import printerIcon18 from './images/printers/18.svg';
import printerIcon19 from './images/printers/19.svg';
import elementIcon from './images/printers/element.svg';
import elementHTIcon from './images/printers/element-ht.svg';
import elementArrayIcon from './images/printers/element-array.svg';

// styles
import styleIcon1 from './images/styles/1.svg';
import styleIcon2 from './images/styles/2.svg';
import styleIcon3 from './images/styles/3.svg';
import styleIcon4 from './images/styles/4.svg';
import styleIcon5 from './images/styles/5.svg';
import styleIcon6 from './images/styles/6.svg';
import styleIcon7 from './images/styles/7.svg';
import styleIcon8 from './images/styles/8.svg';
import styleIcon9 from './images/styles/9.svg';

const Images = {
  canvasLogoFullBlack,
  canvasLogoFullWhite,
  mosaicLogoIconColorful,
  mosaicLogoIconWhite,
  mosaicLogoIconBlack,
  mosaicLogoTextWhite,
  mosaicLogoTextBlack,
  mosaicLogoFullWhite,
  mosaicLogoFullBlack,
  homepagePattern,
  folder,
  noContent,
  calibrationPrint,
  printerIcons: {
    'default/printers/1': printerIcon1,
    'default/printers/2': printerIcon2,
    'default/printers/3': printerIcon3,
    'default/printers/4': printerIcon4,
    'default/printers/5': printerIcon5,
    'default/printers/6': printerIcon6,
    'default/printers/7': printerIcon7,
    'default/printers/8': printerIcon8,
    'default/printers/9': printerIcon9,
    'default/printers/10': printerIcon10,
    'default/printers/11': printerIcon11,
    'default/printers/12': printerIcon12,
    'default/printers/13': printerIcon13,
    'default/printers/14': printerIcon14,
    'default/printers/15': printerIcon15,
    'default/printers/16': printerIcon16,
    'default/printers/17': printerIcon17,
    'default/printers/18': printerIcon18,
    'default/printers/19': printerIcon19,
    'default/printers/Element': elementIcon,
    'default/printers/ElementHT': elementHTIcon,
    'default/printers/ElementArray': elementArrayIcon,
  },
  styleIcons: {
    'default/styles/1': styleIcon1,
    'default/styles/2': styleIcon2,
    'default/styles/3': styleIcon3,
    'default/styles/4': styleIcon4,
    'default/styles/5': styleIcon5,
    'default/styles/6': styleIcon6,
    'default/styles/7': styleIcon7,
    'default/styles/8': styleIcon8,
    'default/styles/9': styleIcon9,
  },
  paletteIcons: {
    palette: paletteImage,
    palettePlus: palettePlusImage,
    palette2: palette2Image,
    palette2Pro: palette2ProImage,
    palette3: palette3Image,
    palette3Pro: palette3ProImage,
  },
  hubIcons: {
    hub: hubImage,
    hubS: hubSImage,
    customHub: customHubImage,
  },
  elementIcons: {
    element: elementImage,
    elementHT: elementHTImage,
  },
  onboarding: {
    welcomeToCanvas,
    firmwareUpdate,
    printerOnboarding,
    addPrinter,
    addDevice,
    fourInputBenchy,
    hereToHelp,
    adminPanel,
    connectionSettings,
    controlHub,
    controlPalette,
    sendToP3,
    slic3r,
    newLayout,
    setupsSimplification,
    newMaterialsPanel,
    pingsAndWebcam,
  },
  cursors: {
    fill: fillCursor,
    fill2x: fillCursor2x,
    sphere: sphereCursor,
    sphere2x: sphereCursor2x,
    triangle: triangleCursor,
    triangle2x: triangleCursor2x,
  },
  setupIcons: {
    extruder,
  },
};

const LightImages = {
  ...Images,
  background: backgroundLight,
  mosaicLogoIcon: mosaicLogoIconBlack,
  mosaicLogoText: mosaicLogoTextBlack,
  mosaicLogoFull: mosaicLogoFullBlack,
  canvasLogoFull: canvasLogoFullBlack,
};

const DarkImages = {
  ...Images,
  background: backgroundDark,
  mosaicLogoIcon: mosaicLogoIconWhite,
  mosaicLogoText: mosaicLogoTextWhite,
  mosaicLogoFull: mosaicLogoFullWhite,
  canvasLogoFull: canvasLogoFullWhite,
};

export { LightImages, DarkImages };

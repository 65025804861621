import styled from 'styled-components';

export const LineChartContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;

  .legendCells .cell .label {
    font-size: 14px;
    fill: ${(props) => props.theme.colors.textPrimary};
  }
`;

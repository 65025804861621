import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import _ from 'lodash';
import Icons from '../../../themes/icons';

import {
  Container,
  DeviceSection,
  DeviceLabel,
  CharacterInputs,
  LabelsContainer,
  DeviceIcons,
  AccessorySection,
} from './deviceActivation.styles';

import Routes from '../../../router/routes';
import CharacterInput from './characterInput/characterInput.jsx';
import { Caption, H6, Icon, Link, Page, Subtitle1 } from '../../../shared';
import { InterfaceUtils } from '../../../utils';
import { Links } from '../../../themes';

const ACTIVATION_CODE_LENGTH = 4;

class DeviceActivation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activationCode: [],
    };
  }

  componentDidMount() {
    this.props.updateNavStack([
      { text: 'My Devices', route: Routes.toManageDevices() },
      { text: 'Connect', route: Routes.toDeviceActivation() },
    ]);
    if (this.props.location.search) {
      const queryParams = new URLSearchParams(this.props.location.search);
      const code = queryParams.get('code');
      if (code && code.length === ACTIVATION_CODE_LENGTH) {
        const activationCode = _.map(code, (char) => char.toUpperCase());
        this.setState({ activationCode }, () => {
          this.props.activateDeviceRequest(activationCode.join(''));
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.activateDevicePending &&
      !this.props.activateDevicePending &&
      this.props.activateDeviceSuccess
    ) {
      this.props.history.push({
        pathname: Routes.toManageDevices(),
      });
    }
  }

  onKeyDown(event) {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      if (this.state.activationCode.length > 0) {
        this.setState({
          activationCode: this.state.activationCode.slice(0, -1),
        });
      }
    }
  }

  onPaste(event) {
    event.preventDefault();
    const pastedValue =
      event.clipboardData && event.clipboardData.getData('Text');

    if (pastedValue && pastedValue.length === ACTIVATION_CODE_LENGTH) {
      const activationCode = _.map(pastedValue, (char) => char.toUpperCase());
      this.setState({ activationCode }, () => {
        this.props.activateDeviceRequest(activationCode.join(''));
      });
    }
  }

  onInputChange(index, value) {
    if (!value) return;
    const activationCode = [...this.state.activationCode];
    activationCode[index] = value[0].toUpperCase();

    this.setState({ activationCode }, () => {
      if (activationCode.length === ACTIVATION_CODE_LENGTH) {
        this.props.activateDeviceRequest(activationCode.join(''));
      }
    });
  }

  renderCharacterInput(index) {
    return (
      <CharacterInput
        key={index}
        active={!!this.state.activationCode[index]}
        allowEmpty
        disabled={this.props.activateDevicePending}
        value={this.state.activationCode[index] || ''}
        autoComplete='off'
        autoCapitalize={false}
        forceFocus={index === this.state.activationCode.length}
        onChangeSuccess={(value) => this.onInputChange(index, value)}
        onKeyDown={(e) => this.onKeyDown(e)}
        onPaste={(e) => this.onPaste(e)}
      />
    );
  }

  renderDeviceLabels() {
    return (
      <LabelsContainer>
        <DeviceIcons>
          <Icon src={Icons.mosaic.hub} color={this.props.theme.colors.grey5} />
          <Icon src={Icons.mosaic.p3} color={this.props.theme.colors.grey5} />
          <Icon
            src={Icons.mosaic.element}
            color={this.props.theme.colors.grey5}
          />
        </DeviceIcons>
        <DeviceLabel>
          <Subtitle1>Online devices</Subtitle1>
        </DeviceLabel>
        <DeviceLabel>
          <Caption grey>
            Connect to Canvas with the activation code provided on your device.
          </Caption>
        </DeviceLabel>
        <DeviceLabel>
          <Caption>
            <Link external href={Links.connectDevice}>
              Find my code
            </Link>
          </Caption>
        </DeviceLabel>
      </LabelsContainer>
    );
  }

  renderAccessoryLabels() {
    return (
      <LabelsContainer>
        <DeviceIcons>
          <Icon src={Icons.mosaic.p} color={this.props.theme.colors.grey5} />
          <Icon src={Icons.mosaic.p2} color={this.props.theme.colors.grey5} />
        </DeviceIcons>
        <DeviceLabel>
          <Subtitle1>Palette+ and Palette 2</Subtitle1>
        </DeviceLabel>
        <DeviceLabel>
          <Caption grey>Add Palette+ or any Palette 2 models.</Caption>
        </DeviceLabel>
      </LabelsContainer>
    );
  }

  renderCharacterInputs() {
    const inputs = [];
    for (let i = 0; i < ACTIVATION_CODE_LENGTH; i++) {
      inputs.push(this.renderCharacterInput(i));
    }
    return <CharacterInputs>{inputs}</CharacterInputs>;
  }

  renderHeader() {
    return <H6>Connect your device</H6>;
  }

  renderDeviceSection() {
    return (
      <DeviceSection>
        {this.renderDeviceLabels()}
        {this.renderCharacterInputs()}
      </DeviceSection>
    );
  }

  renderAccessorySection() {
    return <AccessorySection>{this.renderAccessoryLabels()}</AccessorySection>;
  }

  render() {
    return (
      <Page>
        <Container>
          {InterfaceUtils.getLoadingSpinner(this.props)}
          {this.renderHeader()}
          {this.renderDeviceSection()}
          {this.renderAccessorySection()}
        </Container>
      </Page>
    );
  }
}

export default withTheme(DeviceActivation);

import 'regenerator-runtime/runtime';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import _ from 'lodash';

import { MonitoringUtils } from '../../utils';

import { getOnboardingState } from '../common';
import {
  actions,
  types as onboardingTypes,
} from '../../reducers/onboarding/onboarding';
import { types as iotTypes } from '../../reducers/iot/iot';

const getTasksToTrigger = (modalIdentifier) => {
  switch (modalIdentifier) {
    case 'welcomeToCanvas':
      return ['canvas'];
    case 'usingSetups':
      return ['setups'];
    case 'palette2':
      return ['palette2'];
    default:
      return [];
  }
};

// check if completing a modal should trigger any tasks
function* watchCompleteModal(action) {
  try {
    const { identifier } = action.payload;
    const tasksToTrigger = getTasksToTrigger(identifier);
    yield all(
      _.map(tasksToTrigger, (task) => put(actions.startTaskRequest(task)))
    );
  } catch (err) {
    MonitoringUtils.sentryManualReport(err, true);
  }
}

// check if linking a device to user's account should trigger any tasks
function* watchActivateDevice(action) {
  try {
    const { tasks } = yield select(getOnboardingState);
    const { device } = action.payload;

    let identifier;
    switch (device.type) {
      case 'palette-3':
        identifier = 'palette3';
        break;
      case 'canvas-hub':
        identifier = 'canvasHub';
        break;
      default:
        // not a device we have tasks for
        return;
    }
    // only start the task if the user hasn't seen it yet!
    if (!tasks[identifier].started) {
      yield put(actions.startTaskRequest(identifier));
    }
  } catch (err) {
    MonitoringUtils.sentryManualReport(err, true);
  }
}

export default function* taskTriggers() {
  yield all([
    takeEvery(onboardingTypes.COMPLETE_MODAL_REQUEST, watchCompleteModal),
    takeEvery(iotTypes.ACTIVATE_DEVICE_SUCCESS, watchActivateDevice),
  ]);
}

import { useState } from 'react';

const useInput = (initialValue) => {
  const [inputValue, setInputValue] = useState(initialValue);
  const [inputError, setInputError] = useState(false);

  const handleInputChange = (value, error = false) => {
    setInputValue(value);
    setInputError(error);
  };

  return {
    inputValue,
    inputError,
    handleInputChange,
  };
};

export default useInput;

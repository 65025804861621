import React from 'react';
import { withTheme } from 'styled-components';

import Metadata from './transition.metadata';
import StyleSettingsFormSection from '../styleSettingsFormSection.jsx';

import {
  Container,
  Col,
  VariableTransitionLengthButtons,
} from '../forms.styles';

import { Button } from '../../../../../shared';

class Content extends StyleSettingsFormSection {
  static isSectionMatched(props, filterBy) {
    return (
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.transitionMethod,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.transitionSettings,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.transitionTarget,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.towerSettings,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.sideTransitionSettings,
        filterBy
      )
    );
  }

  renderVariableTransitionLengthButton() {
    if (!this.props.projectProps) return null;
    const { onToggleTransitionLengthModal } = this.props.projectProps;
    return (
      <VariableTransitionLengthButtons>
        <Button expand onClick={() => onToggleTransitionLengthModal()}>
          Variable transition lengths...
        </Button>
      </VariableTransitionLengthButtons>
    );
  }

  render() {
    return (
      <Container>
        <Col>
          {this.renderFieldGroup(Metadata.transitionMethod)}
          {this.renderFieldGroup(Metadata.transitionSettings)}
          {this.renderVariableTransitionLengthButton()}
          {this.renderFieldGroup(Metadata.transitionTarget)}
          {this.renderFieldGroup(Metadata.sideTransitionSettings)}
        </Col>
        <Col>{this.renderFieldGroup(Metadata.towerSettings)}</Col>
      </Container>
    );
  }
}

export default withTheme(Content);

import _ from 'lodash';

import {
  PrinterUtils,
  MaterialUtils,
  SortUtils,
  ValidateUtils,
} from '../../utils';
import { fieldTypes, slicers, slicerExperimentalStatus } from '../../constants';
import { Icons } from '../../themes';

export const inputFields = {
  email: {
    label: 'Email',
    required: true,
    type: fieldTypes.email,
  },

  /*
   * PASSWORD POLICY:
   * - must be at least 8 characters long
   * - must not contain backslashes
   * - must not contain username
   * - must not be 'password'
   * - must contain at least two of:
   *   - uppercase letters
   *   - lowercase letters
   *   - digits
   *   - special characters/symbols
   */

  newPassword: {
    label: 'New Password',
    validator: ValidateUtils.isPasswordWithReason,
    required: true,
    type: fieldTypes.password,
  },
  confirmPassword: {
    label: 'Confirm Password',
    validator: (value, state) =>
      ValidateUtils.confirmPasswordWithReason(value, state.values.newPassword),
    required: true,
    type: fieldTypes.password,
  },
  currentPassword: {
    label: 'Current Password',
    validator: (value) => value.length > 0,
    required: true,
    type: fieldTypes.password,
  },
};

export const formFields = {
  general: {
    fields: [
      {
        title: 'Theme',
        description:
          'Adjust the appearance of Canvas to find the most comfortable colors for your eyes.',
        required: false,
        type: fieldTypes.dropdown,
        name: 'theme',
        options: [
          { label: 'Light', value: 'light' },
          { label: 'Dark', value: 'dark' },
          { label: 'Auto (OS)', value: 'os' },
        ],
      },
    ],
  },

  newProject: {
    saveButton: true,
    fields: [
      {
        title: 'Default slicer',
        description:
          'New projects will be created with this slicer by default.',
        type: fieldTypes.dropdown,
        name: 'defaultSlicer',
        value: (values) => values.defaultSlicer,
        options: Object.entries(slicers).map(([label, value]) => ({
          label,
          subLabel: slicerExperimentalStatus[value]
            ? '(experimental)'
            : undefined,
          value,
        })),
      },
      {
        title: 'Default printer profile',
        description:
          'New projects will be created with this printer profile by default.',
        type: fieldTypes.dropdown,
        name: 'defaultPrinter',
        allowFilter: true,
        value: (values) => values.defaultPrinter,
        options: (values, printers) => {
          const printerOptions = [
            { label: 'Most recent', value: 'auto' },
            ..._.map(PrinterUtils.sortPrinters(printers), (printer) => ({
              label: printer.machineSettings.name,
              value: printer.id,
            })),
          ];
          return printerOptions;
        },
      },
      {
        title: 'Default style',
        description: 'New projects will be created with this style by default.',
        type: fieldTypes.dropdown,
        name: 'defaultStyle',
        value: (values) => values.defaultStyle,
        disabled: (values) => values.defaultPrinter === 'auto',
        options: (values, printers) => {
          const { defaultPrinter } = values;
          const styleOptions = [{ label: 'Most recent', value: 'auto' }];
          if (defaultPrinter !== 'auto' && printers[defaultPrinter]) {
            const printerStyles = printers[defaultPrinter].styles;
            styleOptions.push(
              ..._.map(SortUtils.sortByName(printerStyles), (style) => ({
                label: style.name,
                value: style.id,
              }))
            );
          }
          return styleOptions;
        },
      },
      {
        title: 'Default device',
        description:
          'New projects will be created with this device by default.',
        type: 'deviceDropdown',
        name: 'defaultDevice',
      },
      {
        title: 'Default material',
        description:
          'New projects will be created with this material by default.',
        type: fieldTypes.dropdown,
        name: 'defaultMaterial',
        value: (values) => values.defaultMaterial,
        options: (values, printers, materials) => {
          const materialOptions = _.map(
            SortUtils.sortMaterialProfilesByType(materials),
            (material) => ({
              label: MaterialUtils.getListLabel(material),
              value: material.id,
            })
          );
          return materialOptions;
        },
      },
      {
        title: 'Prompt to confirm settings',
        description:
          'When creating a new project you will be asked to confirm project settings.',
        type: fieldTypes.checkbox,
        name: 'showNewProjectDialog',
        label: 'Enabled',
      },
      {
        title: 'Default colors',
        type: 'colorSwatch',
      },
    ],
  },

  slicedFiles: {
    fields: [
      {
        title: 'Include project thumbnails with sliced file downloads',
        description:
          'Changing this setting will not affect projects that have already been sliced.',
        type: fieldTypes.checkbox,
        name: 'includeThumbnailInZip',
        label: 'Enabled',
      },
      {
        title: 'Prefer unzipped file downloads when possible',
        description:
          'When enabled, projects with only one output file (excluding thumbnails) will be downloaded directly. Projects with more than one file will still be zipped.',
        type: fieldTypes.checkbox,
        name: 'preferUnzipped',
        label: 'Enabled',
      },
    ],
  },

  loginAndSecurity: {
    fields: [
      {
        title: 'Email address',
        description: 'You may use either username or email to log in.',
        type: 'emailAddress',
      },
      {
        title: 'Password',
        description: 'Update the password to your Canvas account.',
        type: 'password',
      },
      {
        title: 'Login history',
        description:
          'These devices have been used to log in to your account in the past 30 days. All timestamps are relative to your current timezone.',
        type: 'loginHistory',
      },
    ],
  },

  dangerZone: {
    fields: [
      {
        title: 'Delete your account',
        description:
          'Deleting your account will delete all data. This cannot be undone.',
        type: 'deleteAccountButton',
      },
    ],
  },
};

export const sidebarTabs = [
  {
    text: 'General',
    icon: Icons.basic.settings,
  },
  {
    text: 'New Projects',
    icon: Icons.project.newProject,
  },
  {
    text: 'Sliced Files',
    icon: Icons.project.sliced,
  },
  {
    text: 'Login and Security',
    icon: Icons.basic.key,
  },
  {
    text: 'Integrations',
    icon: Icons.basic.integration,
  },
  {
    text: 'Danger Zone',
    icon: Icons.basic.danger,
  },
];

import React from 'react';
import { useTheme } from 'styled-components';
import _ from 'lodash';

import Button from '../button/button.jsx';
import Progress from '../progress/progress.jsx';

import {
  Container,
  ProgressOuter,
  BarAndButtonContainer,
  CancelButtonWrapper,
  LabelWrapper,
} from './progressOverlay.styles';

import { Subtitle2 } from '../typography/typography';

const ProgressOverlay = ({
  progress = 0,
  label = '',
  onCancel = null,
  isCancelDisabled = false,
  coverHeaderBar = true,
}) => {
  const renderLabel = () => {
    if (!label) return null;

    let text = label;
    if (progress !== null) {
      text += ` – ${_.round(progress, 2)}%`;
    }
    return (
      <LabelWrapper>
        <Subtitle2 white>{text}</Subtitle2>
      </LabelWrapper>
    );
  };

  const renderCancelButton = () => {
    if (!onCancel) return null;
    return (
      <CancelButtonWrapper>
        <Button clean disabled={isCancelDisabled} onClick={onCancel}>
          Cancel
        </Button>
      </CancelButtonWrapper>
    );
  };

  const theme = useTheme();

  return (
    <Container coverHeaderBar={coverHeaderBar}>
      <BarAndButtonContainer>
        <ProgressOuter>
          <Progress
            determinate
            stroke={40}
            primaryColor={theme.colors.greenDefault}
            secondaryColor={theme.colors.grey1}
            progress={progress}
          />
        </ProgressOuter>
        {renderCancelButton()}
      </BarAndButtonContainer>
      {renderLabel()}
    </Container>
  );
};

export default ProgressOverlay;

import React, { Component } from 'react';
import _ from 'lodash';

import {
  PrintStatsContainer,
  Heading,
  HeadingTitle,
  HeadingValue,
  Table,
  ColorPatch,
  PlaceholderLabelWrapper,
  TableData,
  TableRow,
  TableBody,
} from './printStats.styles';
import { SlicerUtils } from '../../../../utils';
import { Body1 } from '../../../../shared/typography/typography';

class PrintStats extends Component {
  static formatTime(time) {
    if (time < 60) {
      return `${time} s`;
    }
    if (time < 60 * 60) {
      const mins = Math.floor(time / 60);
      return `${mins} min`;
    }
    if (time < 60 * 60 * 24) {
      const hours = Math.floor(time / (60 * 60));
      const mins = Math.floor(time / 60) % 60;
      return `${hours} hr ${mins} min`;
    }
    const days = Math.floor(time / (60 * 60 * 24));
    const hours = Math.floor(time / (60 * 60)) % 24;
    const daysLabel = days === 1 ? `${days} day` : `${days} days`;
    return `${daysLabel} ${hours} hr`;
  }

  static formatDimensions(dimensions) {
    return `${_.join(dimensions, ' x ')} mm`;
  }

  static formatLength(length) {
    return `${_.round(length / 1000, 2)} m`;
  }

  static renderHeading(heading, value = null, indent = 0) {
    if (!value) {
      return (
        <Heading key={heading} indent={indent}>
          <HeadingTitle>{heading}</HeadingTitle>
        </Heading>
      );
    }
    return (
      <Heading key={heading} indent={indent}>
        <HeadingTitle>{heading}: </HeadingTitle>
        <HeadingValue>{value}</HeadingValue>
      </Heading>
    );
  }

  static renderTable(rows, indent = 0, key = '') {
    return (
      <Table indent={indent} key={key}>
        <TableBody>{rows}</TableBody>
      </Table>
    );
  }

  static renderRow(heading, value) {
    return (
      <TableRow key={heading}>
        <TableData>{heading}: </TableData>
        <TableData>{value}</TableData>
      </TableRow>
    );
  }

  static renderDriveLength(color, extruder, length) {
    const colorPatch = <ColorPatch hex={color} />;
    return (
      <TableRow key={extruder}>
        <TableData>{colorPatch}</TableData>
        <TableData>Drive {extruder}:</TableData>
        <TableData>{PrintStats.formatLength(length)}</TableData>
      </TableRow>
    );
  }

  renderBoundingBoxSection() {
    const { stats } = this.props;
    if (stats.boundingBox) {
      const dimensions = _.map([0, 1, 2], (index) => {
        const delta =
          stats.boundingBox.max[index] - stats.boundingBox.min[index];
        return Math.ceil(delta * 10) / 10;
      });
      return PrintStats.renderHeading(
        'Size',
        PrintStats.formatDimensions(dimensions)
      );
    }
    return null;
  }

  renderTimeSection() {
    const { stats } = this.props;
    if (stats.time) {
      return PrintStats.renderHeading(
        'Time',
        PrintStats.formatTime(stats.time)
      );
    }
    return null;
  }

  renderLengthSection() {
    const { colors, stats } = this.props;
    const children = [];
    if (stats.totalLength) {
      children.push(
        PrintStats.renderHeading(
          'Filament Length',
          PrintStats.formatLength(stats.totalLength)
        )
      );
      if (stats.length) {
        const drivesUsed = stats.length.map((length) => length > 0);
        const drivesUsedCount = drivesUsed.reduce(
          (acc, isUsed) => (isUsed ? acc + 1 : acc),
          0
        );
        if (drivesUsedCount > 1) {
          const driveRows = [];
          stats.length.forEach((length, index) => {
            if (length > 0) {
              const color = SlicerUtils.rgbaArrayToHexString(colors[index]);
              driveRows.push(
                PrintStats.renderDriveLength(color, index + 1, length)
              );
            }
          });
          children.push(PrintStats.renderTable(driveRows, 1, 'driveLengths'));
        }
      }
      return children;
    }
    return null;
  }

  renderPaletteSection() {
    const { stats } = this.props;
    const children = [];
    if (stats.splices) {
      children.push(PrintStats.renderRow('Total Splices', stats.splices));
    }
    if (stats.pings) {
      children.push(PrintStats.renderRow('Total Pings', stats.pings));
    }
    if (!_.isEmpty(children)) {
      return [
        PrintStats.renderHeading('Palette'),
        PrintStats.renderTable(children, 1),
      ];
    }
    return null;
  }

  render() {
    if (!this.props.stats || _.isEmpty(this.props.stats)) {
      return (
        <PlaceholderLabelWrapper>
          <Body1 grey>Finish slicing to generate print summary</Body1>
        </PlaceholderLabelWrapper>
      );
    }
    return (
      <PrintStatsContainer>
        {this.renderBoundingBoxSection()}
        {this.renderTimeSection()}
        {this.renderLengthSection()}
        {this.renderPaletteSection()}
      </PrintStatsContainer>
    );
  }
}

export default PrintStats;

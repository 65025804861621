import React, { Component } from 'react';

import {
  Content,
  InteractivePreviewContainer,
  LoadingContainer,
} from './previewCard.styles';

import { ButtonContainer, ThumbnailWrapper } from '../importProject.styles';

import {
  Progress,
  Button,
  Image,
  Modal,
  MiniVisualizer,
} from '../../../../shared';

import { Body1 } from '../../../../shared/typography/typography';

import ImportHeading from '../importHeading/importHeading.jsx';

class PreviewCard extends Component {
  renderButtons() {
    const {
      isImportDataLoading,
      isAuthenticated,
      toggleImportSharedProjectModal,
      onNextClick,
      onLoginClick,
    } = this.props;
    return (
      <ButtonContainer>
        {isAuthenticated ? (
          <Button onClick={toggleImportSharedProjectModal}>Cancel</Button>
        ) : (
          <Button onClick={onLoginClick}>Login</Button>
        )}
        <Button
          primary
          disabled={isImportDataLoading || !isAuthenticated}
          onClick={onNextClick}>
          {isImportDataLoading ? 'Loading' : 'Next'}
        </Button>
      </ButtonContainer>
    );
  }

  renderInteractivePreview() {
    const { loadedModels, projectDataLoaded, currentSharedProject } =
      this.props;
    const { name, thumbnail, thumbnailDataUrl, models } = currentSharedProject;
    if (!projectDataLoaded) return null;

    if (models) {
      if (loadedModels) {
        // models are ready to display in interactive preview
        return <MiniVisualizer models={loadedModels} />;
      }
      if (thumbnail && thumbnailDataUrl) {
        // models not yet loaded
        return (
          <>
            <LoadingContainer>
              <ThumbnailWrapper>
                <Image
                  alt={`${name} thumbnail preview`}
                  src={thumbnailDataUrl}
                />
              </ThumbnailWrapper>
            </LoadingContainer>
            <Progress circular />
          </>
        );
      }
      return <Body1 grey>Loading interactive preview</Body1>;
    }
    return <Body1 grey>No preview available</Body1>;
  }

  renderHeading() {
    if (!this.props.projectDataLoaded) return null;
    return <ImportHeading sharedProject={this.props.currentSharedProject} />;
  }

  render() {
    return (
      <Modal width='40rem'>
        <Content>
          <InteractivePreviewContainer>
            {this.renderInteractivePreview()}
          </InteractivePreviewContainer>
          {this.renderHeading()}
          {this.renderButtons()}
        </Content>
      </Modal>
    );
  }
}

export default PreviewCard;

/**
 * adapted from https://github.com/mrdoob/three.js/blob/master/examples/js/controls/TransformControls.js
 * @author ma-ee-ku https://github.com/leemun1
 */

/* eslint-disable no-underscore-dangle, no-param-reassign */

import * as THREE from 'three';
import _ from 'lodash';

import THREEtypes from '../reducers/three/types';
import { SlicerUtils } from '../utils';
import { setRenderFlag } from '../sagas/three/animationFrame';

/**
 * When taking a dot product of two vectors, the result is a euclidean magnitude
 * of the two and the consine of the angle between them.
 *
 * When the value is 1, the vectors are parallel, in the same direction.
 * When the value is -1, the vectors are parallel, in the opposite direction.
 *
 * The threshold constants defined below are the cosine of the angles
 * which determines whether or not we show/hide handles
 * depending on their current alignment w.r.t. the camera (i.e., the "eye")
 */

const AXIS_HIDE_THRESHOLD = 0.99;
const PLANE_HIDE_THRESHOLD = 0.2;
const AXIS_FLIP_THRESHOLD = -0.4;

// this determines how much angle to "snap" the roation
const ROTATION_SNAP = THREE.Math.degToRad(45);
const ZERO_VECTOR = new THREE.Vector3(0, 0, 0);
const IDENTITY_QUATERNION = new THREE.Quaternion();

class GizmoHandles extends THREE.Object3D {
  constructor(parent) {
    super();
    this.type = 'GizmoHandles';
    this._parent = parent;

    /**
     * Reusable utility variables:
     * These are used for calculating the gizmo handles' transforms.
     *
     * In order to have the gizmo be positioned and aligned to axes,
     * we need to dynamically calculate its transforms dynamically whenever the view updates.
     *
     * Instead of creating new entities (i.e., vectors, matrices, quaternions, etc.) for
     * each calculation, we declare them here and reuse them as necessary by changing their values.
     */
    this.tempVector = new THREE.Vector3(0, 0, 0);
    this.tempEuler = new THREE.Euler();
    this.alignVector = new THREE.Vector3(0, 1, 0);
    this.lookAtMatrix = new THREE.Matrix4();
    this.tempQuaternion = new THREE.Quaternion();
    this.tempQuaternion2 = new THREE.Quaternion();

    // the vectors defining each axis
    this.unitX = new THREE.Vector3(1, 0, 0);
    this.unitY = new THREE.Vector3(0, 1, 0);
    this.unitZ = new THREE.Vector3(0, 0, 1);

    // Gizmo components per axis
    this.gizmo = {};
    this.picker = {};
    this.helper = {};

    // Gizmo creation
    this.createComponents();
  }

  // shared materials
  static getGizmoMaterial(color, opacity = 1) {
    return new THREE.MeshBasicMaterial({
      depthTest: false,
      transparent: true,
      side: THREE.DoubleSide,
      color,
      opacity,
    });
  }

  static getGizmoLineMaterial(color, opacity = 1) {
    return new THREE.LineBasicMaterial({
      depthTest: false,
      transparent: true,
      color,
      opacity,
    });
  }

  // reusable geometry
  static getArrowGeometry() {
    return new THREE.CylinderBufferGeometry(0, 0.05, 0.2, 12, 1, false);
  }

  static getScaleHandleGeometry() {
    return new THREE.BoxBufferGeometry(0.125, 0.125, 0.125);
  }

  static getLineGeometry() {
    const lineGeometry = new THREE.BufferGeometry();
    lineGeometry.addAttribute(
      'position',
      new THREE.Float32BufferAttribute([0, 0, 0, 1, 0, 0], 3)
    );
    return lineGeometry;
  }

  static getCircleGeometry(radius, arc) {
    const geometry = new THREE.BufferGeometry();
    const vertices = [];

    for (let i = 0; i <= 64 * arc; ++i) {
      vertices.push(
        0,
        Math.cos((i / 32) * Math.PI) * radius,
        Math.sin((i / 32) * Math.PI) * radius
      );
    }

    geometry.addAttribute(
      'position',
      new THREE.Float32BufferAttribute(vertices, 3)
    );
    return geometry;
  }

  static getTranslateHelperGeometry() {
    // Special geometry for transform helper.
    // If scaled with position vector it spans from [0,0,0] to position
    const geometry = new THREE.BufferGeometry();
    geometry.addAttribute(
      'position',
      new THREE.Float32BufferAttribute([0, 0, 0, 1, 1, 1], 3)
    );
    return geometry;
  }

  defineTranslateComponents() {
    // Gizmo definitions - custom hierarchy definitions for setupComponents method
    const gizmoTranslate = {
      X: [
        [
          new THREE.Mesh(
            GizmoHandles.getArrowGeometry(),
            GizmoHandles.getGizmoMaterial(0xff0000)
          ),
          [1, 0, 0],
          [0, 0, -Math.PI / 2],
          null,
          'fwd',
        ],
        [
          new THREE.Mesh(
            GizmoHandles.getArrowGeometry(),
            GizmoHandles.getGizmoMaterial(0xff0000)
          ),
          [1, 0, 0],
          [0, 0, Math.PI / 2],
          null,
          'bwd',
        ],
        [
          new THREE.Line(
            GizmoHandles.getLineGeometry(),
            GizmoHandles.getGizmoLineMaterial(0xff0000)
          ),
        ],
      ],
      Y: [
        [
          new THREE.Mesh(
            GizmoHandles.getArrowGeometry(),
            GizmoHandles.getGizmoMaterial(0x2ecc71)
          ),
          [0, 1, 0],
          null,
          null,
          'fwd',
        ],
        [
          new THREE.Mesh(
            GizmoHandles.getArrowGeometry(),
            GizmoHandles.getGizmoMaterial(0x2ecc71)
          ),
          [0, 1, 0],
          [Math.PI, 0, 0],
          null,
          'bwd',
        ],
        [
          new THREE.Line(
            GizmoHandles.getLineGeometry(),
            GizmoHandles.getGizmoLineMaterial(0x2ecc71)
          ),
          null,
          [0, 0, Math.PI / 2],
        ],
      ],
      XY: [
        [
          new THREE.Mesh(
            new THREE.PlaneBufferGeometry(0.295, 0.295),
            GizmoHandles.getGizmoMaterial(0xffff00, 0.25)
          ),
          [0.15, 0.15, 0],
        ],
        [
          new THREE.Line(
            GizmoHandles.getLineGeometry(),
            GizmoHandles.getGizmoLineMaterial(0xffff00)
          ),
          [0.18, 0.3, 0],
          null,
          [0.125, 1, 1],
        ],
        [
          new THREE.Line(
            GizmoHandles.getLineGeometry(),
            GizmoHandles.getGizmoLineMaterial(0xffff00)
          ),
          [0.3, 0.18, 0],
          [0, 0, Math.PI / 2],
          [0.125, 1, 1],
        ],
      ],
    };

    const pickerTranslate = {
      X: [
        [
          new THREE.Mesh(
            new THREE.CylinderBufferGeometry(0.2, 0, 1, 4, 1, false),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          [0.6, 0, 0],
          [0, 0, -Math.PI / 2],
        ],
      ],
      Y: [
        [
          new THREE.Mesh(
            new THREE.CylinderBufferGeometry(0.2, 0, 1, 4, 1, false),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          [0, 0.6, 0],
        ],
      ],
      XY: [
        [
          new THREE.Mesh(
            new THREE.PlaneBufferGeometry(0.4, 0.4),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          [0.2, 0.2, 0],
        ],
      ],
    };

    const helperTranslate = {
      START: [
        [
          new THREE.Mesh(
            new THREE.OctahedronBufferGeometry(0.01, 2),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          null,
          null,
          null,
          'helper',
        ],
      ],
      END: [
        [
          new THREE.Mesh(
            new THREE.OctahedronBufferGeometry(0.01, 2),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          null,
          null,
          null,
          'helper',
        ],
      ],
      DELTA: [
        [
          new THREE.Line(
            GizmoHandles.getTranslateHelperGeometry(),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          null,
          null,
          null,
          'helper',
        ],
      ],
      X: [
        [
          new THREE.Line(
            GizmoHandles.getLineGeometry(),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          [-1e3, 0, 0],
          null,
          [1e6, 1, 1],
          'helper',
        ],
      ],
      Y: [
        [
          new THREE.Line(
            GizmoHandles.getLineGeometry(),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          [0, -1e3, 0],
          [0, 0, Math.PI / 2],
          [1e6, 1, 1],
          'helper',
        ],
      ],
      Z: [
        [
          new THREE.Line(
            GizmoHandles.getLineGeometry(),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          [0, 0, -1e3],
          [0, -Math.PI / 2, 0],
          [1e6, 1, 1],
          'helper',
        ],
      ],
    };

    return {
      gizmo: gizmoTranslate,
      picker: pickerTranslate,
      helper: helperTranslate,
    };
  }

  defineRotateComponents() {
    const gizmoRotate = {
      X: [
        [
          new THREE.Line(
            GizmoHandles.getCircleGeometry(1, 0.5),
            GizmoHandles.getGizmoLineMaterial(0xff0000)
          ),
        ],
        [
          new THREE.Mesh(
            new THREE.OctahedronBufferGeometry(0.05, 0),
            GizmoHandles.getGizmoMaterial(0xff0000)
          ),
          [0, 0, 0.99],
          null,
          [1, 3, 1],
        ],
      ],
      Y: [
        [
          new THREE.Line(
            GizmoHandles.getCircleGeometry(1, 0.5),
            GizmoHandles.getGizmoLineMaterial(0x2ecc71)
          ),
          null,
          [0, 0, -Math.PI / 2],
        ],
        [
          new THREE.Mesh(
            new THREE.OctahedronBufferGeometry(0.05, 0),
            GizmoHandles.getGizmoMaterial(0x2ecc71)
          ),
          [0, 0, 0.99],
          null,
          [3, 1, 1],
        ],
      ],
      Z: [
        [
          new THREE.Line(
            GizmoHandles.getCircleGeometry(1, 0.5),
            GizmoHandles.getGizmoLineMaterial(0x0000ff)
          ),
          null,
          [0, Math.PI / 2, 0],
        ],
        [
          new THREE.Mesh(
            new THREE.OctahedronBufferGeometry(0.05, 0),
            GizmoHandles.getGizmoMaterial(0x0000ff)
          ),
          [0.99, 0, 0],
          null,
          [1, 3, 1],
        ],
      ],
    };

    const pickerRotate = {
      X: [
        [
          new THREE.Mesh(
            new THREE.TorusBufferGeometry(1, 0.1, 4, 24),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          [0, 0, 0],
          [0, -Math.PI / 2, -Math.PI / 2],
        ],
      ],
      Y: [
        [
          new THREE.Mesh(
            new THREE.TorusBufferGeometry(1, 0.1, 4, 24),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          [0, 0, 0],
          [Math.PI / 2, 0, 0],
        ],
      ],
      Z: [
        [
          new THREE.Mesh(
            new THREE.TorusBufferGeometry(1, 0.1, 4, 24),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          [0, 0, 0],
          [0, 0, -Math.PI / 2],
        ],
      ],
    };

    const helperRotate = {
      AXIS: [
        [
          new THREE.Line(
            GizmoHandles.getLineGeometry(),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          [-1e3, 0, 0],
          null,
          [1e6, 1, 1],
          'helper',
        ],
      ],
    };

    return {
      gizmo: gizmoRotate,
      picker: pickerRotate,
      helper: helperRotate,
    };
  }

  defineScaleComponents() {
    const gizmoScale = {
      X: [
        [
          new THREE.Mesh(
            GizmoHandles.getScaleHandleGeometry(),
            GizmoHandles.getGizmoMaterial(0xff0000)
          ),
          [0.8, 0, 0],
          [0, 0, -Math.PI / 2],
        ],
        [
          new THREE.Line(
            GizmoHandles.getLineGeometry(),
            GizmoHandles.getGizmoLineMaterial(0xff0000)
          ),
          null,
          null,
          [0.8, 1, 1],
        ],
      ],
      Y: [
        [
          new THREE.Mesh(
            GizmoHandles.getScaleHandleGeometry(),
            GizmoHandles.getGizmoMaterial(0x2ecc71)
          ),
          [0, 0.8, 0],
        ],
        [
          new THREE.Line(
            GizmoHandles.getLineGeometry(),
            GizmoHandles.getGizmoLineMaterial(0x2ecc71)
          ),
          null,
          [0, 0, Math.PI / 2],
          [0.8, 1, 1],
        ],
      ],
      Z: [
        [
          new THREE.Mesh(
            GizmoHandles.getScaleHandleGeometry(),
            GizmoHandles.getGizmoMaterial(0x0000ff)
          ),
          [0, 0, 0.8],
          [Math.PI / 2, 0, 0],
        ],
        [
          new THREE.Line(
            GizmoHandles.getLineGeometry(),
            GizmoHandles.getGizmoLineMaterial(0x0000ff)
          ),
          null,
          [0, -Math.PI / 2, 0],
          [0.8, 1, 1],
        ],
      ],
      XYZX: [
        [
          new THREE.Mesh(
            GizmoHandles.getScaleHandleGeometry(),
            GizmoHandles.getGizmoMaterial(0xb2babb, 0.6)
          ),
          [1.1, 0, 0],
        ],
      ],
      XYZY: [
        [
          new THREE.Mesh(
            GizmoHandles.getScaleHandleGeometry(),
            GizmoHandles.getGizmoMaterial(0xb2babb, 0.6)
          ),
          [0, 1.1, 0],
        ],
      ],
      XYZZ: [
        [
          new THREE.Mesh(
            GizmoHandles.getScaleHandleGeometry(),
            GizmoHandles.getGizmoMaterial(0xb2babb, 0.6)
          ),
          [0, 0, 1.1],
        ],
      ],
    };

    const pickerScale = {
      X: [
        [
          new THREE.Mesh(
            new THREE.CylinderBufferGeometry(0.2, 0, 0.8, 4, 1, false),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          [0.5, 0, 0],
          [0, 0, -Math.PI / 2],
        ],
      ],
      Y: [
        [
          new THREE.Mesh(
            new THREE.CylinderBufferGeometry(0.2, 0, 0.8, 4, 1, false),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          [0, 0.5, 0],
        ],
      ],
      Z: [
        [
          new THREE.Mesh(
            new THREE.CylinderBufferGeometry(0.2, 0, 0.8, 4, 1, false),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          [0, 0, 0.5],
          [Math.PI / 2, 0, 0],
        ],
      ],
      XYZX: [
        [
          new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.2, 0.2, 0.2),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          [1.1, 0, 0],
        ],
      ],
      XYZY: [
        [
          new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.2, 0.2, 0.2),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          [0, 1.1, 0],
        ],
      ],
      XYZZ: [
        [
          new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.2, 0.2, 0.2),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          [0, 0, 1.1],
        ],
      ],
    };

    const helperScale = {
      X: [
        [
          new THREE.Line(
            GizmoHandles.getLineGeometry(),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          [-1e3, 0, 0],
          null,
          [1e6, 1, 1],
          'helper',
        ],
      ],
      Y: [
        [
          new THREE.Line(
            GizmoHandles.getLineGeometry(),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          [0, -1e3, 0],
          [0, 0, Math.PI / 2],
          [1e6, 1, 1],
          'helper',
        ],
      ],
      Z: [
        [
          new THREE.Line(
            GizmoHandles.getLineGeometry(),
            GizmoHandles.getGizmoMaterial(0xffffff, 0.33)
          ),
          [0, 0, -1e3],
          [0, -Math.PI / 2, 0],
          [1e6, 1, 1],
          'helper',
        ],
      ],
    };

    return {
      gizmo: gizmoScale,
      picker: pickerScale,
      helper: helperScale,
    };
  }

  setupComponents(gizmoMap) {
    // Creates an Object3D with components described in custom hierarchy definition.
    const componentGroup = new THREE.Object3D();

    const gizmoProperties = _.keys(gizmoMap);
    for (let i = 0; i < gizmoProperties.length; i++) {
      const name = gizmoProperties[i];
      for (let j = gizmoMap[name].length; j--; ) {
        const object = gizmoMap[name][j][0].clone();
        const position = gizmoMap[name][j][1];
        const rotation = gizmoMap[name][j][2];
        const scale = gizmoMap[name][j][3];
        const tag = gizmoMap[name][j][4];

        // name and tag properties are essential for picking and updating logic.
        object.name = name;
        object.tag = tag;

        if (position) {
          object.position.set(position[0], position[1], position[2]);
        }
        if (rotation) {
          object.rotation.set(rotation[0], rotation[1], rotation[2]);
        }
        if (scale) {
          object.scale.set(scale[0], scale[1], scale[2]);
        }

        object.updateMatrix();

        const tempGeometry = object.geometry.clone();
        tempGeometry.applyMatrix(object.matrix);
        object.geometry = tempGeometry;

        object.position.set(0, 0, 0);
        object.rotation.set(0, 0, 0);
        object.scale.set(1, 1, 1);

        componentGroup.add(object);
      }
    }

    return componentGroup;
  }

  createComponents() {
    let { gizmo, picker, helper } = this.defineTranslateComponents();
    this.gizmo.translate = this.setupComponents(gizmo);
    this.add(this.gizmo.translate);
    this.picker.translate = this.setupComponents(picker);
    this.add(this.picker.translate);
    this.helper.translate = this.setupComponents(helper);
    this.add(this.helper.translate);

    ({ gizmo, picker, helper } = this.defineRotateComponents());
    this.gizmo.rotate = this.setupComponents(gizmo);
    this.add(this.gizmo.rotate);
    this.picker.rotate = this.setupComponents(picker);
    this.add(this.picker.rotate);
    this.helper.rotate = this.setupComponents(helper);
    this.add(this.helper.rotate);

    ({ gizmo, picker, helper } = this.defineScaleComponents());
    this.gizmo.scale = this.setupComponents(gizmo);
    this.add(this.gizmo.scale);
    this.picker.scale = this.setupComponents(picker);
    this.add(this.picker.scale);
    this.helper.scale = this.setupComponents(helper);
    this.add(this.helper.scale);

    // Pickers should be hidden always
    this.picker.translate.visible = false;
    this.picker.rotate.visible = false;
    this.picker.scale.visible = false;
  }

  updateMatrixWorld() {
    const parent = this._parent;
    let currentSpace = parent.space;

    // orient scale to local rotation only if single item
    if (parent.mode === 'scale' && parent.selected.length === 1)
      currentSpace = 'local';

    const currentQuaternion =
      currentSpace === 'local' ? parent.worldQuaternion : IDENTITY_QUATERNION;

    // Show only gizmos for current transform mode

    this.gizmo.translate.visible = parent.mode === 'translate';
    this.gizmo.rotate.visible = parent.mode === 'rotate';
    this.gizmo.scale.visible = parent.mode === 'scale';

    this.helper.translate.visible = parent.mode === 'translate';
    this.helper.rotate.visible = parent.mode === 'rotate';
    this.helper.scale.visible = parent.mode === 'scale';

    const handles = [
      ...this.picker[parent.mode].children,
      ...this.gizmo[parent.mode].children,
      ...this.helper[parent.mode].children,
    ];

    for (let i = 0; i < handles.length; i++) {
      const handle = handles[i];
      if (parent.selected.length) {
        // hide aligned to camera
        handle.visible = true;
        handle.rotation.set(0, 0, 0);
        handle.position.copy(parent.worldPosition);

        const eyeDistance = parent.worldPosition.distanceTo(
          parent.cameraPosition
        );
        handle.scale
          .set(1, 1, 1)
          .multiplyScalar((eyeDistance * parent.size) / 7);

        // TODO: simplify helpers and consider decoupling from gizmo
        if (handle.tag === 'helper') {
          handle.visible = false;

          if (handle.name === 'AXIS') {
            handle.position.copy(parent.worldPositionStart);
            handle.visible = !!parent.axis;

            if (parent.axis === 'X') {
              this.tempQuaternion.setFromEuler(this.tempEuler.set(0, 0, 0));
              handle.quaternion
                .copy(currentQuaternion)
                .multiply(this.tempQuaternion);

              if (
                Math.abs(
                  this.alignVector
                    .copy(this.unitX)
                    .applyQuaternion(currentQuaternion)
                    .dot(parent.eye)
                ) > AXIS_HIDE_THRESHOLD
              ) {
                handle.visible = false;
              }
            }

            if (parent.axis === 'Y') {
              this.tempQuaternion.setFromEuler(
                this.tempEuler.set(0, 0, Math.PI / 2)
              );
              handle.quaternion
                .copy(currentQuaternion)
                .multiply(this.tempQuaternion);

              if (
                Math.abs(
                  this.alignVector
                    .copy(this.unitY)
                    .applyQuaternion(currentQuaternion)
                    .dot(parent.eye)
                ) > AXIS_HIDE_THRESHOLD
              ) {
                handle.visible = false;
              }
            }

            if (parent.axis === 'Z') {
              this.tempQuaternion.setFromEuler(
                this.tempEuler.set(0, Math.PI / 2, 0)
              );
              handle.quaternion
                .copy(currentQuaternion)
                .multiply(this.tempQuaternion);

              if (
                Math.abs(
                  this.alignVector
                    .copy(this.unitZ)
                    .applyQuaternion(currentQuaternion)
                    .dot(parent.eye)
                ) > AXIS_HIDE_THRESHOLD
              ) {
                handle.visible = false;
              }
            }

            if (parent.axis === 'E') {
              handle.visible = false;
            }
          } else if (handle.name === 'START') {
            handle.position.copy(parent.worldPositionStart);
            handle.visible = parent.dragging;
          } else if (handle.name === 'END') {
            handle.position.copy(parent.worldPosition);
            handle.visible = parent.dragging;
          } else if (handle.name === 'DELTA') {
            handle.position.copy(parent.worldPositionStart);
            handle.quaternion.copy(parent.worldQuaternionStart);
            this.tempVector
              .set(1e-10, 1e-10, 1e-10)
              .add(parent.worldPositionStart)
              .sub(parent.worldPosition)
              .multiplyScalar(-1);
            this.tempVector.applyQuaternion(
              parent.worldQuaternionStart.clone().inverse()
            );
            handle.scale.copy(this.tempVector);
            handle.visible = parent.dragging;
          } else {
            handle.quaternion.copy(currentQuaternion);

            if (parent.dragging) {
              handle.position.copy(parent.worldPositionStart);
            } else {
              handle.position.copy(parent.worldPosition);
            }

            if (parent.axis) {
              handle.visible = parent.axis.includes(handle.name);
            }
          }
        }

        // Align handles to current local or world rotation
        handle.quaternion.copy(currentQuaternion);

        if (parent.mode === 'translate' || parent.mode === 'scale') {
          // Hide translate and scale axis facing the camera
          if (handle.name === 'X' || handle.name === 'XYZX') {
            if (
              Math.abs(
                this.alignVector
                  .copy(this.unitX)
                  .applyQuaternion(currentQuaternion)
                  .dot(parent.eye)
              ) > AXIS_HIDE_THRESHOLD
            ) {
              handle.scale.set(1e-10, 1e-10, 1e-10);
              handle.visible = false;
            }
          }
          if (handle.name === 'Y' || handle.name === 'XYZY') {
            if (
              Math.abs(
                this.alignVector
                  .copy(this.unitY)
                  .applyQuaternion(currentQuaternion)
                  .dot(parent.eye)
              ) > AXIS_HIDE_THRESHOLD
            ) {
              handle.scale.set(1e-10, 1e-10, 1e-10);
              handle.visible = false;
            }
          }
          if (handle.name === 'Z' || handle.name === 'XYZZ') {
            if (
              Math.abs(
                this.alignVector
                  .copy(this.unitZ)
                  .applyQuaternion(currentQuaternion)
                  .dot(parent.eye)
              ) > AXIS_HIDE_THRESHOLD
            ) {
              handle.scale.set(1e-10, 1e-10, 1e-10);
              handle.visible = false;
            }
          }
          if (handle.name === 'XY') {
            if (
              Math.abs(
                this.alignVector
                  .copy(this.unitZ)
                  .applyQuaternion(currentQuaternion)
                  .dot(parent.eye)
              ) < PLANE_HIDE_THRESHOLD
            ) {
              handle.scale.set(1e-10, 1e-10, 1e-10);
              handle.visible = false;
            }
          }
          if (handle.name === 'YZ') {
            if (
              Math.abs(
                this.alignVector
                  .copy(this.unitX)
                  .applyQuaternion(currentQuaternion)
                  .dot(parent.eye)
              ) < PLANE_HIDE_THRESHOLD
            ) {
              handle.scale.set(1e-10, 1e-10, 1e-10);
              handle.visible = false;
            }
          }
          if (handle.name === 'XZ') {
            if (
              Math.abs(
                this.alignVector
                  .copy(this.unitY)
                  .applyQuaternion(currentQuaternion)
                  .dot(parent.eye)
              ) < PLANE_HIDE_THRESHOLD
            ) {
              handle.scale.set(1e-10, 1e-10, 1e-10);
              handle.visible = false;
            }
          }

          // Flip translate and scale axis ocluded behind another axis

          if (handle.name.includes('X')) {
            if (
              this.alignVector
                .copy(this.unitX)
                .applyQuaternion(currentQuaternion)
                .dot(parent.eye) < AXIS_FLIP_THRESHOLD
            ) {
              if (handle.tag === 'fwd') {
                handle.visible = false;
              } else {
                handle.scale.x *= -1;
              }
            } else if (handle.tag === 'bwd') {
              handle.visible = false;
            }
          }

          if (handle.name.includes('Y')) {
            if (
              this.alignVector
                .copy(this.unitY)
                .applyQuaternion(currentQuaternion)
                .dot(parent.eye) < AXIS_FLIP_THRESHOLD
            ) {
              if (handle.tag === 'fwd') {
                handle.visible = false;
              } else {
                handle.scale.y *= -1;
              }
            } else if (handle.tag === 'bwd') {
              handle.visible = false;
            }
          }

          if (handle.name.includes('Z')) {
            if (
              this.alignVector
                .copy(this.unitZ)
                .applyQuaternion(currentQuaternion)
                .dot(parent.eye) < AXIS_FLIP_THRESHOLD
            ) {
              if (handle.tag === 'fwd') {
                handle.visible = false;
              } else {
                handle.scale.z *= -1;
              }
            } else if (handle.tag === 'bwd') {
              handle.visible = false;
            }
          }
        } else if (parent.mode === 'rotate') {
          // Align handles to current local or world rotation
          this.tempQuaternion2.copy(currentQuaternion);
          this.alignVector
            .copy(parent.eye)
            .applyQuaternion(
              this.tempQuaternion.copy(currentQuaternion).inverse()
            );

          if (handle.name.includes('E')) {
            handle.quaternion.setFromRotationMatrix(
              this.lookAtMatrix.lookAt(parent.eye, ZERO_VECTOR, this.unitY)
            );
          }

          if (handle.name === 'X') {
            this.tempQuaternion.setFromAxisAngle(
              this.unitX,
              Math.atan2(-this.alignVector.y, this.alignVector.z)
            );
            this.tempQuaternion.multiplyQuaternions(
              this.tempQuaternion2,
              this.tempQuaternion
            );
            handle.quaternion.copy(this.tempQuaternion);
          }

          if (handle.name === 'Y') {
            this.tempQuaternion.setFromAxisAngle(
              this.unitY,
              Math.atan2(this.alignVector.x, this.alignVector.z)
            );
            this.tempQuaternion.multiplyQuaternions(
              this.tempQuaternion2,
              this.tempQuaternion
            );
            handle.quaternion.copy(this.tempQuaternion);
          }

          if (handle.name === 'Z') {
            this.tempQuaternion.setFromAxisAngle(
              this.unitZ,
              Math.atan2(this.alignVector.y, this.alignVector.x)
            );
            this.tempQuaternion.multiplyQuaternions(
              this.tempQuaternion2,
              this.tempQuaternion
            );
            handle.quaternion.copy(this.tempQuaternion);
          }
        }

        // Hide disabled axes
        handle.visible =
          handle.visible && (!handle.name.includes('X') || parent.showX);
        handle.visible =
          handle.visible && (!handle.name.includes('Y') || parent.showY);
        handle.visible =
          handle.visible && (!handle.name.includes('Z') || parent.showZ);
        handle.visible =
          handle.visible &&
          (!handle.name.includes('E') ||
            (parent.showX && parent.showY && parent.showZ));

        // highlight selected axis
        handle.material._opacity =
          handle.material._opacity || handle.material.opacity;
        handle.material._color =
          handle.material._color || handle.material.color.clone();

        handle.material.color.copy(handle.material._color);
        handle.material.opacity = handle.material._opacity;

        if (!parent.enabled) {
          handle.material.opacity *= 0.5;
          handle.material.color.lerp(new THREE.Color(1, 1, 1), 0.5);
        } else if (parent.axis) {
          if (handle.name === parent.axis) {
            handle.material.opacity = 1.0;
          } else if (parent.axis.split('').some((a) => handle.name === a)) {
            handle.material.opacity = 1.0;
          } else {
            handle.material.opacity *= 0.25;
          }
        }
      } else {
        handle.visible = false;
      }
    }

    super.updateMatrixWorld();
  }
}

class GizmoPlane extends THREE.Mesh {
  constructor(geometry, material, parent) {
    super(geometry, material);

    this.type = 'GizmoPlane';
    this._parent = parent;

    this.unitX = new THREE.Vector3(1, 0, 0);
    this.unitY = new THREE.Vector3(0, 1, 0);
    this.unitZ = new THREE.Vector3(0, 0, 1);

    /**
     * Reusable utility variables:
     * These are used for calculating the gizmo plane's transforms.
     * They have the same purpose as the utility variables for the GizmoHandles class
     */
    this.tempVector = new THREE.Vector3();
    this.dirVector = new THREE.Vector3();
    this.alignVector = new THREE.Vector3();
    this.tempMatrix = new THREE.Matrix4();
  }

  updateMatrixWorld() {
    const parent = this._parent;
    let currentSpace = parent.space;

    this.position.copy(parent.worldPosition);

    if (parent.mode === 'scale') currentSpace = 'local'; // scale always oriented to local rotation

    const currentQuaternion =
      currentSpace === 'local' ? parent.worldQuaternion : IDENTITY_QUATERNION;

    this.unitX.set(1, 0, 0).applyQuaternion(currentQuaternion);
    this.unitY.set(0, 1, 0).applyQuaternion(currentQuaternion);
    this.unitZ.set(0, 0, 1).applyQuaternion(currentQuaternion);

    // Align the plane for current transform mode, axis and space.

    this.alignVector.copy(this.unitY);

    switch (parent.mode) {
      case 'translate':
      case 'scale':
        switch (parent.axis) {
          case 'X':
            this.alignVector.copy(parent.eye).cross(this.unitX);
            this.dirVector.copy(this.unitX).cross(this.alignVector);
            break;
          case 'Y':
            this.alignVector.copy(parent.eye).cross(this.unitY);
            this.dirVector.copy(this.unitY).cross(this.alignVector);
            break;
          case 'Z':
            this.alignVector.copy(parent.eye).cross(this.unitZ);
            this.dirVector.copy(this.unitZ).cross(this.alignVector);
            break;
          case 'XY':
            this.dirVector.copy(this.unitZ);
            break;
          case 'YZ':
            this.dirVector.copy(this.unitX);
            break;
          case 'XZ':
            this.alignVector.copy(this.unitZ);
            this.dirVector.copy(this.unitY);
            break;
          case 'XYZ':
          case 'E':
            this.dirVector.set(0, 0, 0);
            break;
          default:
            break;
        }
        break;
      case 'rotate':
      default:
        // special case for rotate
        this.dirVector.set(0, 0, 0);
    }

    if (this.dirVector.length() === 0) {
      // If in rotate mode, make the plane parallel to camera
      this.quaternion.copy(parent.cameraQuaternion);
    } else {
      this.tempMatrix.lookAt(
        this.tempVector.set(0, 0, 0),
        this.dirVector,
        this.alignVector
      );
      this.quaternion.setFromRotationMatrix(this.tempMatrix);
    }

    super.updateMatrixWorld();
  }
}

export default class GizmoController extends THREE.Object3D {
  constructor(camera, domElement, viewControls) {
    super();

    this.domElement = domElement || document;
    this.camera = camera;
    this.viewControls = viewControls;

    this.object = null;
    this.selected = [];

    this.axis = null;
    this.mode = 'translate';
    this.space = 'world';
    this.size = 1;

    this.enabled = true;
    this.dragging = false;
    this.showX = true;
    this.showY = true;
    this.showZ = true;

    this.ray = new THREE.Raycaster();

    /**
     * Reusable utility variables:
     * These are used for calculating the attached objects' transforms.
     *
     * Most calculations are broken down into multiple steps, and these variables
     * allow us to avoid creating new entities (i.e., vectors, quaternions, etc.) for
     * each intermediate step.
     */

    this.tempVector = new THREE.Vector3();
    this.tempVector2 = new THREE.Vector3();

    this.pointStart = new THREE.Vector3();
    this.pointEnd = new THREE.Vector3();
    this.positionDelta = new THREE.Vector3();

    this.newPosition = new THREE.Vector3();
    this.newQuaternion = new THREE.Quaternion();
    this.newScale = new THREE.Vector3();

    this.startCenter = new THREE.Vector3();
    this.currentCenter = new THREE.Vector3();

    this.rotationAxis = new THREE.Vector3();
    this.rotationAngle = 0;

    this.cameraPosition = new THREE.Vector3();
    this.cameraQuaternion = new THREE.Quaternion();

    this.worldPositionStart = new THREE.Vector3();
    this.worldQuaternionStart = new THREE.Quaternion();

    this.worldPosition = new THREE.Vector3();
    this.worldQuaternion = new THREE.Quaternion();
    this.worldScale = new THREE.Vector3();

    this.eye = new THREE.Vector3();

    this.unit = {
      X: new THREE.Vector3(1, 0, 0),
      Y: new THREE.Vector3(0, 1, 0),
      Z: new THREE.Vector3(0, 0, 1),
    };

    this.createHandles();
    this.createPlane();

    this.onPointerHover = this.onPointerHover.bind(this);
    this.onPointerMove = this.onPointerMove.bind(this);
    this.onPointerDown = this.onPointerDown.bind(this);
    this.onPointerUp = this.onPointerUp.bind(this);

    this.domElement.addEventListener('mousedown', this.onPointerDown, false);
    this.domElement.addEventListener('touchstart', this.onPointerDown, false);
    this.domElement.addEventListener('mousemove', this.onPointerHover, false);
    this.domElement.addEventListener('touchmove', this.onPointerHover, false);
    this.domElement.addEventListener('mouseup', this.onPointerUp, false);
    this.domElement.addEventListener('touchend', this.onPointerUp, false);
    this.domElement.addEventListener('touchcancel', this.onPointerUp, false);
    this.domElement.addEventListener('touchleave', this.onPointerUp, false);
  }

  dispose() {
    this.detach();

    this.domElement.removeEventListener('mousedown', this.onPointerDown);
    this.domElement.removeEventListener('touchstart', this.onPointerDown);
    this.domElement.removeEventListener('mousemove', this.onPointerHover);
    this.domElement.removeEventListener('touchmove', this.onPointerHover);
    this.domElement.removeEventListener('mouseup', this.onPointerUp);
    this.domElement.removeEventListener('touchend', this.onPointerUp);
    this.domElement.removeEventListener('touchcancel', this.onPointerUp);
    this.domElement.removeEventListener('touchleave', this.onPointerUp);
  }

  attach(selectedMeshes) {
    this.selected = selectedMeshes;
    this.visible = true;

    // TODO: investigate if this.object is necessary
    // eslint-disable-next-line prefer-destructuring
    this.object = selectedMeshes[0];

    this.includesTower = !!this.selected.find(
      (item) => item.name === THREEtypes.TOWER_MESH_NAME
    );
    this.calculateCenter();
  }

  detach() {
    this.object = null;
    this.visible = false;
    this.axis = null;
    this.selected = [];
  }

  createHandles() {
    this.handles = new GizmoHandles(this);
    this.add(this.handles);
  }

  createPlane() {
    const geometry = new THREE.PlaneBufferGeometry(100000, 100000, 2, 2);
    const material = new THREE.MeshBasicMaterial({
      visible: false,
      wireframe: true,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.1,
    });
    this.plane = new GizmoPlane(geometry, material, this);
    this.add(this.plane);
  }

  setMode(mode) {
    this.mode = mode;

    this.showX = true;
    this.showY = true;
    this.showZ = true;

    // if tower is selected, hide z axis
    if (this.includesTower) {
      // if rotate gizmo, hide x and y axis as well
      if (this.mode === 'rotate') {
        this.showX = false;
        this.showY = false;
      }
      this.showZ = false;
    }
  }

  getPointer(event) {
    const pointer = event.changedTouches ? event.changedTouches[0] : event;
    const rect = this.domElement.getBoundingClientRect();

    return {
      x: ((pointer.clientX - rect.left) / rect.width) * 2 - 1,
      y: (-(pointer.clientY - rect.top) / rect.height) * 2 + 1,
      button: event.button,
    };
  }

  onPointerHover(event) {
    if (!this.enabled) return;

    this.handlePointerHover(this.getPointer(event));
  }

  onPointerDown(event) {
    if (!this.enabled) return;

    event.preventDefault();
    this.domElement.addEventListener('mousemove', this.onPointerMove, false);
    this.domElement.addEventListener('touchmove', this.onPointerMove, false);
    this.handlePointerHover(this.getPointer(event));
    this.handlePointerDown(this.getPointer(event));
  }

  onPointerMove(event) {
    if (!this.enabled) return;

    event.preventDefault();
    this.handlePointerMove(this.getPointer(event), event);
  }

  onPointerUp(event) {
    if (!this.enabled) return;

    event.preventDefault(); // Prevent MouseEvent on mobile
    this.domElement.removeEventListener('mousemove', this.onPointerMove, false);
    this.domElement.removeEventListener('touchmove', this.onPointerMove, false);
    this.handlePointerUp(this.getPointer(event));
  }

  handlePointerHover(pointer) {
    if (
      this.object === null ||
      this.dragging === true ||
      (pointer.button !== undefined && pointer.button !== 0)
    )
      return;

    this.ray.setFromCamera(pointer, this.camera);

    const intersect =
      this.ray.intersectObjects(
        this.handles.picker[this.mode].children,
        true
      )[0] || false;

    if (intersect) {
      this.axis = intersect.object.name;
    } else {
      this.axis = null;
    }
  }

  handlePointerDown(pointer) {
    if (
      this.object === null ||
      this.dragging === true ||
      (pointer.button !== undefined && pointer.button !== 0)
    )
      return;

    if (
      (pointer.button === 0 || pointer.button === undefined) &&
      this.axis !== null
    ) {
      this.ray.setFromCamera(pointer, this.camera);

      const planeIntersect =
        this.ray.intersectObjects([this.plane], true)[0] || false;

      if (planeIntersect) {
        let currentSpace = this.space;

        if (this.mode === 'scale') {
          currentSpace = 'local';
        } else {
          currentSpace = 'world';
        }

        this.object.updateMatrixWorld();
        this.object.parent.updateMatrixWorld();

        this.worldPositionStart.copy(this.startCenter);
        this.worldQuaternionStart.setFromRotationMatrix(
          this.object.matrixWorld
        );

        this.pointStart.copy(planeIntersect.point).sub(this.worldPositionStart);

        if (currentSpace === 'local') {
          this.pointStart.applyQuaternion(
            this.worldQuaternionStart.clone().inverse()
          );
        }
      }

      this.dragging = true;
      this.viewControls.enabled = false;
    }
  }

  handlePointerMove(pointer, event) {
    if (
      this.object === undefined ||
      this.axis === null ||
      this.dragging === false ||
      (pointer.button !== undefined && pointer.button !== 0)
    )
      return;

    let currentSpace = this.space;

    if (this.mode === 'scale') {
      currentSpace = 'local';
    } else {
      currentSpace = 'world';
    }

    this.ray.setFromCamera(pointer, this.camera);

    const planeIntersect =
      this.ray.intersectObjects([this.plane], true)[0] || false;

    if (planeIntersect === false) return;

    this.pointEnd.copy(planeIntersect.point).sub(this.worldPositionStart);

    if (currentSpace === 'local') {
      this.pointEnd.applyQuaternion(
        this.worldQuaternionStart.clone().inverse()
      );
    }

    if (this.mode === 'translate') {
      if (!this.axis.includes('X')) {
        this.pointEnd.x = this.pointStart.x;
      }
      if (!this.axis.includes('Y')) {
        this.pointEnd.y = this.pointStart.y;
      }
      if (!this.axis.includes('Z')) {
        this.pointEnd.z = this.pointStart.z;
      }

      // compute change in position
      this.positionDelta.subVectors(this.pointEnd, this.pointStart);

      // Apply translate
      if (this.selected.length > 0) {
        // group translate
        this.selected.forEach((mesh) => {
          // set new mesh position
          this.newPosition.addVectors(
            mesh.userData.startPosition,
            this.positionDelta
          );
          mesh.position.copy(this.newPosition);

          // set new center
          this.newPosition.addVectors(this.startCenter, this.positionDelta);
          this.currentCenter.copy(this.newPosition);

          // update stamps orientation
          this.updateModelStamps(mesh);

          // invalidate current bounding box
          mesh.userData.boundingBoxWorld = null;
        });
      }
    } else if (this.mode === 'scale') {
      if (this.axis.includes('XYZ')) {
        // uniform scale
        const currentAxis = this.axis.slice(-1);
        const d = this.pointEnd.clone().divide(this.pointStart);

        let newScale = 1;
        if (currentAxis === 'X') {
          newScale = d.x;
        } else if (currentAxis === 'Y') {
          newScale = d.y;
        } else if (currentAxis === 'Z') {
          newScale = d.z;
        }

        if (this.pointEnd.dot(this.pointStart) < 0) newScale *= -1;
        this.tempVector.set(newScale, newScale, newScale);
      } else {
        // axis scale
        // use 'tempVector' for regular model and 'tempVector2' for tower
        this.tempVector.copy(this.pointEnd).divide(this.pointStart);
        const d = this.pointEnd.length() / this.pointStart.length();

        if (!this.axis.includes('X')) {
          this.tempVector.x = 1;
          this.tempVector2.set(1 / d, d, 1);
        }
        if (!this.axis.includes('Y')) {
          this.tempVector.y = 1;
          this.tempVector2.set(d, 1 / d, 1);
        }
        if (!this.axis.includes('Z')) {
          this.tempVector.z = 1;
        }
      }

      // Apply scale
      if (this.selected.length > 0) {
        // group scale
        this.selected.forEach((mesh) => {
          if (mesh.name !== THREEtypes.TOWER_MESH_NAME) {
            // set new mesh scale
            this.newScale
              .copy(mesh.userData.startScale)
              .multiply(this.tempVector);
            mesh.scale.copy(this.newScale);

            // adjust mesh position w.r.t. bbox center
            this.newPosition
              .subVectors(mesh.userData.startPosition, this.startCenter)
              .multiply(this.tempVector)
              .add(this.startCenter);

            mesh.position.copy(this.newPosition);
          } else {
            // tower
            this.newScale
              .copy(mesh.userData.startScale)
              .multiply(this.tempVector2);
            mesh.scale.copy(this.newScale);
          }

          // update stamps orientation
          this.updateModelStamps(mesh);

          // invalidate current bounding box
          mesh.userData.boundingBoxWorld = null;
        });
      }
    } else if (this.mode === 'rotate') {
      const ROTATION_SPEED =
        5 /
        this.worldPosition.distanceTo(
          this.tempVector.setFromMatrixPosition(this.camera.matrixWorld)
        );

      const unit = this.unit[this.axis];

      if (this.axis === 'X' || this.axis === 'Y' || this.axis === 'Z') {
        this.rotationAxis.copy(unit);

        this.tempVector = unit.clone();
        this.tempVector2 = this.pointEnd.clone().sub(this.pointStart);

        this.rotationAngle =
          this.tempVector2.dot(this.tempVector.cross(this.eye).normalize()) *
          ROTATION_SPEED;
      }

      // Apply rotation snap
      if (event.ctrlKey || event.metaKey) {
        this.rotationAngle =
          Math.round(this.rotationAngle / ROTATION_SNAP) * ROTATION_SNAP;
      }

      // Apply rotate
      if (this.selected.length > 0) {
        // group rotation
        this.selected.forEach((mesh) => {
          // set new rotation
          mesh.setRotationFromAxisAngle(this.rotationAxis, this.rotationAngle);
          mesh.quaternion.multiply(mesh.userData.startQuaternion);

          // pivot position around combined bbox center
          this.newPosition
            .subVectors(mesh.userData.startPosition, this.startCenter)
            .applyAxisAngle(this.rotationAxis, this.rotationAngle)
            .add(this.startCenter);

          mesh.position.copy(this.newPosition);

          // update stamps orientation
          this.updateModelStamps(mesh);

          // invalidate current bounding box
          mesh.userData.boundingBoxWorld = null;
        });
      }
    }
    setRenderFlag();
  }

  handlePointerUp(pointer) {
    if (pointer.button !== undefined && pointer.button !== 0) return;

    if (this.dragging && this.axis !== null) {
      this.viewControls.enabled = true;
      this.handleTransforms();
    }

    this.dragging = false;
    if (pointer.button === undefined) this.axis = null;
  }

  calculateCenter() {
    // calculate center of bbox that includes all selected models
    const combinedBbox = SlicerUtils.getBoundingBox(this.selected);
    combinedBbox.getCenter(this.startCenter);
    this.currentCenter.copy(this.startCenter);
  }

  getCenter() {
    return this.currentCenter.clone();
  }

  updateModelStamps(mesh) {
    let stampMesh;
    let stampCamera;
    mesh.children.forEach((stampGroup) => {
      [stampMesh, stampCamera] = stampGroup.children;
      // update view matrix
      stampMesh.material.uniforms.cameraViewMatrix.value =
        stampCamera.matrixWorldInverse;
      // update direction
      stampCamera.getWorldDirection(
        stampMesh.material.uniforms.cameraDirection.value
      );
    });
  }

  updateMatrixWorld() {
    if (this.object !== null) {
      this.object.updateMatrixWorld();
      this.object.matrixWorld.decompose(
        this.worldPosition,
        this.worldQuaternion,
        this.worldScale
      );
    }

    // override gizmo position to center of selected models
    this.worldPosition.copy(this.currentCenter);

    this.camera.updateMatrixWorld();
    this.cameraPosition.setFromMatrixPosition(this.camera.matrixWorld);
    this.cameraQuaternion.setFromRotationMatrix(this.camera.matrixWorld);

    if (this.camera instanceof THREE.PerspectiveCamera) {
      this.eye.copy(this.cameraPosition).sub(this.worldPosition).normalize();
    } else if (this.camera instanceof THREE.OrthographicCamera) {
      this.eye.copy(this.cameraPosition).normalize();
    }

    super.updateMatrixWorld();
  }
}

import styled from 'styled-components';
import { Shadows, Breakpoints } from '../../../themes';

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  ${Shadows.d1};
  width: 15rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 4px;
  position: absolute;
  right: 0rem;
  top: 0rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.75rem;
  gap: 0.5rem;
`;

export const Header = styled.div`
  display: none;
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    box-sizing: border-box;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey3};
    height: 2.8125rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem 0.75rem 0.75rem;
  }
`;
export const DeviceSelectionContainer = styled.div`
  display: flex;
  gap: 0.25rem;
`;

export const DeviceDropdownWrapper = styled.div`
  width: 100%;
  min-width: 0;
`;

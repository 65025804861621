import React from 'react';

import { Links } from '../../../../themes';
import { Body1 } from '../../../../shared/typography/typography';
import { Image, Link } from '../../../../shared';

const metadata = {
  title: 'Setting up a new Palette 2',
  proceedButtonLabel: 'Done',
  content: (theme) => (
    <>
      <Body1 noSpacing>
        If this is your first time setting up a Palette 2, please update your
        firmware in order to enjoy the best Palette experience.
      </Body1>
      <Image
        alt='Firmware update'
        src={theme.images.onboarding.firmwareUpdate}
      />
      <Link external href={Links.firmware}>
        Follow this link to a guide with detailed instructions
      </Link>
    </>
  ),
};

export default metadata;

import styled, { css } from 'styled-components';

import { Breakpoints } from '../../../themes';

import { Image } from '../../../shared';
import shadows from '../../../themes/shadows';

export const Container = styled.div``;

export const Sidebar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 18rem;
  pointer-events: none;
  z-index: 1;

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: none;
  }
`;

export const SentToConfirmation = styled.a`
  position: absolute;
  bottom: 4.5rem;
  right: 1rem;
  font-size: 0.8rem;
  font-weight: ${(props) => props.theme.fonts.weightBold};
  cursor: pointer;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.textSecondary};

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    grid-row: 1 / 2;
    position: relative;
    right: 0;
    top: 0;
  }
`;

export const SummaryPanelWrapper = styled.div`
  z-index: 1;
  position: relative;
  margin-top: 4.5rem;
  margin-left: 1rem;
  width: 15rem;
  background-color: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  pointer-events: all;
`;

export const LegendAndOptionsContainer = styled.div`
  position: relative;
  margin-top: 0.5rem;
  margin-left: 1rem;
  width: 15rem;
  pointer-events: none;
  user-select: none;
  z-index: 1;
`;

export const ModalContent = styled.div`
  position: relative;
`;

export const ModalTitle = styled.div`
  font-size: 1.1rem;
  font-weight: ${(props) => props.theme.fonts.weightBold};
  margin-bottom: 1rem;
`;

export const ModalList = styled.ul`
  list-style: none;
  padding: 0;
  max-height: 25rem;
  overflow-x: hidden;
  overflow-y: scroll;

  @media (max-height: ${Breakpoints.smallHeight}) {
    max-height: 15rem;
  }
`;

const modalListItemStyles = css`
  border: 1px solid ${(props) => props.theme.colors.grey2};
  border-radius: 0.1rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  margin: 0.3rem 0;

  img {
    margin-right: 1em;
  }

  &:hover {
    background-color: ${(props) =>
      props.theme.colors.elevatedBackgroundSecondary};
  }
`;

export const ModalListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  ${modalListItemStyles}
`;

export const ListItemName = styled.span`
  max-width: 12rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: hidden;
  flex-grow: 1;
  text-align: left;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    max-width: 8rem;
  }
`;

export const DismissButtonWrapper = styled.div`
  position: absolute;
  top: -0.5rem;
  right: -3rem;
`;

export const BottomButtonsContainer = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    flex-direction: column;
  }
`;

export const SendToButtonWrapper = styled.div`
  padding: 0.5rem;
  padding-left: 0;
`;

export const SendImage = styled(Image)`
  width: 2rem;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    width: 1.5rem;
  }
`;

export const SendToPrintBar = styled.div`
  display: flex;
  order: 2;

  background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  box-sizing: border-box;
  ${shadows.d1}

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    order: 1;
  }
`;

export const SendToDropdownWrapper = styled.div`
  padding: 0.5rem;
`;

export const SendToIconWrapper = styled.div`
  margin-right: 0.5rem;
`;

export const SliceControlButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  order: 1;

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    order: 2;
    width: 100%;
    justify-content: flex-end;
  }
`;

export const SideBarMobile = styled.div`
  display: none;
  position: fixed;
  top: 4.5rem;
  left: 1rem;

  width: 15rem;
  z-index: 1;

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const StyledDescriptionIconContainer = styled.div`
  margin-right: 0.5rem;
`;

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: ${(props) => (props.forceOpen ? 'default' : 'pointer')};
  padding: 0 0.8125rem 0 0.5625rem;
  height: 3rem;
  min-height: 3rem;
  box-sizing: border-box;
  background: ${(props) =>
    props.isCollapsed
      ? props.theme.colors.elevatedBackgroundPrimary
      : props.theme.colors.elevatedBackgroundSecondary};
  border-bottom: 0.1px solid ${(props) => props.theme.colors.grey3};

  &:hover {
    background: ${(props) => props.theme.colors.elevatedBackgroundSecondary};
  }
`;

export const Content = styled.div`
  flex-direction: column;
  position: relative;
  display: flex;
  box-sizing: border-box;
  max-height: ${(props) => (props.isCollapsed ? 0 : props.uncollapsedHeight)}px;
  height: ${(props) => (props.isCollapsed ? 0 : props.uncollapsedHeight)}px;
  overflow-y: hidden;

  ${(props) =>
    props.scroll &&
    css`
      overflow-y: ${props.isCollapsed ? 'hidden' : 'auto'};
    `};
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  height: ${(props) => (props.isCollapsed ? 0 : 3)}rem;
  overflow: ${(props) => (props.isCollapsed ? 'hidden' : 'visible')};
`;

import React from 'react';

import { hereToHelp } from './common.metadata';
import Routes from '../../../../router/routes';
import {
  ImageContainer,
  ConnectionSettingsImage,
  ControlHubImage,
  AdminPanelImage,
} from '../taskManager.styles';

import { Body1 } from '../../../../shared/typography/typography';

const metadata = {
  title: 'Canvas Hub',
  stepOrder: [
    'addHubToSetup',
    'connectionSettings',
    'accessAdminPanel',
    'hereToHelp',
  ],
  steps: {
    addHubToSetup: {
      title: 'Find Canvas Hub under Devices',
      content: (
        <>
          <Body1>
            Canvas Hub has been added to your Devices. You can control Palette 2
            and your printer directly from Canvas.
          </Body1>
          <ImageContainer>
            <ControlHubImage />
          </ImageContainer>
        </>
      ),
      proceedLabel: 'Go to Devices',
      onProceed: (props) => props.history.push(Routes.toManageDevices()),
    },
    connectionSettings: {
      title: 'Connection settings',
      content: (
        <>
          <Body1>
            To view detailed connection settings, double click a device to enter
            device controls and choose Connection Settings.
          </Body1>
          <ImageContainer>
            <ConnectionSettingsImage />
          </ImageContainer>
        </>
      ),
    },
    accessAdminPanel: {
      title: 'Access admin panel',
      content: (
        <>
          <Body1>
            You can still access OctoPrint through your Hub&rsquo;s local IP
            address or serial number for advanced options.
          </Body1>
          <ImageContainer>
            <AdminPanelImage />
          </ImageContainer>
        </>
      ),
    },
    hereToHelp,
  },
};

export default metadata;

import { connect } from 'react-redux';

import { actions } from '../../reducers/slicer/slicer';
import ShareProjectModal from './shareProjectModal.jsx';

const mapStateToProps = (state) => ({
  materials: state.slicer.materials,
  models: state.slicer.models,
  createSharedProjectPending: state.slicer.status.createSharedProjectPending,
  createSharedProjectSuccess: state.slicer.status.createSharedProjectSuccess,
  deleteSharedProjectPending: state.slicer.status.deleteSharedProjectPending,
  deleteSharedProjectSuccess: state.slicer.status.deleteSharedProjectSuccess,
  updateSharedProjectPending: state.slicer.status.updateSharedProjectPending,
  updateSharedProjectSuccess: state.slicer.status.updateSharedProjectSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  createSharedProject: (
    projectId,
    shareModels,
    sharePrinter,
    shareStyle,
    shareMaterials
  ) =>
    dispatch(
      actions.createSharedProjectRequest(
        projectId,
        shareModels,
        sharePrinter,
        shareStyle,
        shareMaterials
      )
    ),
  deleteSharedProject: (projectId) =>
    dispatch(actions.deleteSharedProjectRequest(projectId)),
  updateSharedProject: (
    projectId,
    shareModels,
    sharePrinter,
    shareStyle,
    shareMaterials
  ) =>
    dispatch(
      actions.updateSharedProjectRequest(
        projectId,
        shareModels,
        sharePrinter,
        shareStyle,
        shareMaterials
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareProjectModal);

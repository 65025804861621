import styled, { css, keyframes } from 'styled-components';

import { SlicerUtils } from '../../utils';

const shimmerAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const shimmerStyles = css`
  position: relative;
  overflow: hidden;

  ::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    animation: ${shimmerAnimation} 1s infinite;
    background: ${(props) => {
      const grey =
        props.theme.name === 'dark'
          ? props.theme.colors.grey1
          : props.theme.colors.grey2;
      const mainColor = SlicerUtils.hexStringToRGBA(grey, 0).join(', ');
      const offColor = SlicerUtils.hexStringToRGBA(grey, 0.5).join(', ');

      return css`
      linear-gradient(
        90deg,
        rgba(${mainColor}) 0%,
        rgba(${offColor}) 50%,
        rgba(${mainColor}) 100%
      );
    `;
    }};
  }
`;

const fauxStyles = css`
  border-radius: 0.2rem;
  background: ${(props) =>
    props.theme.name === 'dark'
      ? props.theme.colors.grey2
      : props.theme.colors.grey3};
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const FauxImageWrapper = styled.div`
  position: relative;
  margin-bottom: 0.5rem;
  padding-bottom: 50%;

  ${(props) => props.shimmer && shimmerStyles};
`;

export const FauxImage = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  ${fauxStyles};
`;

export const FauxTextWrapper = styled.div`
  position: relative;
  margin-bottom: 0.5rem;

  ${(props) =>
    props.short &&
    css`
      margin-right: 5rem;
    `}

  ${(props) => props.shimmer && shimmerStyles};
`;

export const FauxText = styled.div`
  height: 1.2rem;
  ${fauxStyles};
`;

import React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { CardGridContainer, CardListContainer } from './cardList.styles';

const CardList = (props) => {
  const Container = props.listView ? CardListContainer : CardGridContainer;
  return (
    <DndProvider backend={HTML5Backend}>
      <Container ref={props.cardListRef} {...props}>
        {props.children}
      </Container>
    </DndProvider>
  );
};

export default CardList;

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import { getIotState, getSlicerState, handleGenericError } from '../../common';
import API from '../../canvas-api';
import { makeRequest } from '../../iot/horizon';
import { actions } from '../../../reducers/slicer/slicer';

export default function* dispatchPrintToDevice(action) {
  try {
    const { projects } = yield select(getSlicerState);
    const { projectId, deviceId } = action.payload;
    const project = projects[projectId];

    // make API request to get signed URL for S3
    const apiResponse = yield call(API, {
      path: `projects/${projectId}/download`,
    });
    if (apiResponse === null) return;

    const { url } = apiResponse;

    // make horizon request to instruct device to download the file
    const { devices } = yield select(getIotState);
    const deviceType = devices[deviceId].type;

    yield call(
      makeRequest,
      deviceId,
      'storage',
      {
        method: deviceType === 'canvas-hub' ? 'put' : 'post',
        query: {
          s3path: url,
          name: project.name,
        },
      },
      'dispatchPrintToDevice'
    );

    yield put(actions.dispatchPrintToDeviceSuccess());

    // TODO: get the file path from upload request, then start a print with that
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

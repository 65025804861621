import { connect } from 'react-redux';

import Slicer from './slicer.jsx';

import { actions as slicerActions } from '../../reducers/slicer/slicer';
import { actions as THREEactions } from '../../reducers/three/three';

const mapStateToProps = (state) => ({
  getAccountPending: state.auth.status.getAccountPending,
  getAccountSuccess: state.auth.status.getAccountSuccess,
  loadProjectPending: state.slicer.status.loadProjectPending,
  loadProjectSuccess: state.slicer.status.loadProjectSuccess,
  getPrintersPending: state.printers.status.getPrintersPending,
  getPrintersSuccess: state.printers.status.getPrintersSuccess,
  createProjectPending: state.slicer.status.createProjectPending,
  createProjectSuccess: state.slicer.status.createProjectSuccess,
  repackPending: state.slicer.status.repackPending,
  uploadProgress: state.slicer.status.uploadProgress,
  uploadPending: state.slicer.status.uploadStlPending,
  deleteModelsPending: state.slicer.status.deleteModelsPending,
  downloadPending: state.slicer.status.downloadPending,
  downloadSuccess: state.slicer.status.downloadSuccess,
  duplicateModelPending: state.slicer.status.duplicateModelPending,
  alignGroupPending: state.slicer.status.alignAndGroupModelsPending,
  models: state.slicer.models,
  tower: state.slicer.transitionTower,
  projects: state.slicer.projects,
  printers: state.printers.printers,
  currentProject: state.slicer.currentProject,
  materials: state.slicer.materials,
  devices: state.iot.devices,
  getDevicesPending: state.iot.status.getDevicesPending,
  getDevicesSuccess: state.iot.status.getDevicesSuccess,
  getMaterialsPending: state.slicer.status.getMaterialsPending,
  getMaterialsSuccess: state.slicer.status.getMaterialsSuccess,
  changeMaterialPending: state.slicer.status.changeMaterialPending,
  changeMaterialSuccess: state.slicer.status.changeMaterialSuccess,
  updateProjectNamePending: state.slicer.status.updateProjectNamePending,
  updateProjectNameSuccess: state.slicer.status.updateProjectNameSuccess,
  updateProjectColorsPending: state.slicer.status.updateProjectColorsPending,
  updateProjectColorsSuccess: state.slicer.status.updateProjectColorsSuccess,
  initScenePending: state.three.status.initScenePending,
  updatePrintBedPending: state.three.status.updatePrintBedPending,
  transitionTower: state.slicer.transitionTower,
  updateProjectSettingsPending:
    state.slicer.status.updateProjectSettingsPending,
  updateProjectSettingsSuccess:
    state.slicer.status.updateProjectSettingsSuccess,
  composeColorsRLEPending: state.slicer.status.composeColorsRLEPending,
  autoSegmentPending: state.slicer.status.autoSegmentPending,
  buildFacesPending: state.slicer.status.buildFacesPending,
  generateAtlasPending: state.slicer.status.generateAtlasPending,
  resetRegionsPending: state.slicer.status.resetRegionsPending,
  saveModelPaintDataPending: state.slicer.status.saveModelPaintDataPending,
  saveModelPaintDataSuccess: state.slicer.status.saveModelPaintDataSuccess,
  saveModelCustomSupportsPending:
    state.slicer.status.saveModelCustomSupportsPending,
  saveModelCustomSupportsSuccess:
    state.slicer.status.saveModelCustomSupportsSuccess,
  dispatchPrintToPolarPending: state.slicer.status.dispatchPrintToPolarPending,
  remapperContext: state.slicer.remapperContext,
  remapProjectInputsPending: state.slicer.status.remapProjectInputsPending,
  remapProjectInputsSuccess: state.slicer.status.remapProjectInputsSuccess,
  sliceJobs: state.slicer.sliceJobs,
  dispatchSliceJobPending: state.slicer.status.dispatchSliceJobPending,
  cancelSliceJobPending: state.slicer.status.cancelSliceJobPending,
  createSpliceSettingsPending: state.slicer.status.createSpliceSettingsPending,
  updateVariableTransitionLengthsPending:
    state.slicer.status.updateVariableTransitionLengthsPending,
});

const mapDispatchToProps = (dispatch) => ({
  // THREE-related actions
  initScene: (canvas) => dispatch(THREEactions.initSceneRequest(canvas)),
  destroyScene: () => dispatch(THREEactions.destroySceneRequest()),
  resetCamera: (allowTween = true, paintMode = false, modelToColor = null) =>
    dispatch(THREEactions.resetCamera(allowTween, paintMode, modelToColor)),
  updatePrintBed: (printer) =>
    dispatch(THREEactions.updatePrintBedRequest(printer)),
  addModelsToScene: (resetCamera = false, centerModels = false) =>
    dispatch(THREEactions.addModelsToScene(resetCamera, centerModels)),
  removeModelsFromScene: (models = null) =>
    dispatch(THREEactions.removeModelsFromScene(models)),
  addModelToScene: (model) => dispatch(THREEactions.addModelToScene(model)),
  removeModelFromScene: (model) =>
    dispatch(THREEactions.removeModelFromScene(model)),
  addTowerToScene: (tower) => dispatch(THREEactions.addTowerToScene(tower)),
  removeTowerFromScene: () => dispatch(THREEactions.removeTowerFromScene()),

  // OTHER
  loadProject: (projectId) =>
    dispatch(slicerActions.loadProjectRequest(projectId)),
  unloadCurrentProject: () => dispatch(slicerActions.unloadCurrentProject()),
  unloadToolpath: () => dispatch(slicerActions.unloadToolpath()),
  cancelSlice: (projectId) =>
    dispatch(slicerActions.cancelSliceJobRequest(projectId)),
  updateProjectName: (projectId, projectName) =>
    dispatch(slicerActions.updateProjectNameRequest(projectId, projectName)),
  updateVariableTransitionLengths: (projectId, values) =>
    dispatch(
      slicerActions.updateVariableTransitionLengthsRequest(projectId, values)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Slicer);

import { connect } from 'react-redux';

import { actions } from '../../../../reducers/three/three';
import ObjectItem from './objectItem.jsx';

const mapStateToProps = (state) => ({
  models: state.slicer.models,
  updateModelNamePending: state.slicer.status.updateModelNamePending,
  updateModelNameSuccess: state.slicer.status.updateModelNameSuccess,
  updateGroupNamePending: state.slicer.status.updateGroupNamePending,
  updateGroupNameSuccess: state.slicer.status.updateGroupNameSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  hoverModels: (hoveredModel) => dispatch(actions.hoverModels([hoveredModel])),
  unhoverModels: () => dispatch(actions.hoverModels([])),
});

export default connect(mapStateToProps, mapDispatchToProps)(ObjectItem);

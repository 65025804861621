import { connect } from 'react-redux';

import SliceView from './sliceView.jsx';

import actions from '../../../reducers/slicer/actions';
import THREEactions from '../../../reducers/three/actions';

import { IntegrationUtils, ProjectUtils } from '../../../utils';

const mapStateToProps = (state) => {
  const project = state.slicer.projects[state.slicer.currentProject];
  const printer = ProjectUtils.getProjectPrinter(
    project,
    state.printers.printers
  );

  const compatibleDeviceIds = ProjectUtils.getCompatibleDeviceIds(
    project,
    state.printers.printers[project.printerId],
    state.iot.devices
  );

  const deviceIds = ProjectUtils.getDeviceIds(state.iot.devices);

  const canSendToPolar = IntegrationUtils.canSendToPolar(
    state.auth.userIntegrations
  );
  return {
    toolpath: state.slicer.toolpath,
    project,
    printer,
    models: state.slicer.models,
    devices: state.iot.devices,
    deviceStates: state.iot.deviceStates,
    deviceIds,
    compatibleDeviceIds,
    transitionTower: state.slicer.transitionTower,
    loadToolpathPtpPending: state.slicer.status.loadToolpathPtpPending,
    loadToolpathPtpProgress: state.slicer.status.loadToolpathPtpProgress,
    createToolpathMeshesPending:
      state.slicer.status.createToolpathMeshesPending,
    createToolpathMeshesSuccess:
      state.slicer.status.createToolpathMeshesSuccess,
    createToolpathMeshesProgress:
      state.slicer.status.createToolpathMeshesProgress,
    getPolarPrintersSuccess: state.slicer.status.getPolarPrintersSuccess,
    canSendToPolar,
    polarPrinters: canSendToPolar
      ? state.slicer.integrations.polarCloud.printers
      : [],
    dispatchPrintToPolarPending:
      state.slicer.status.dispatchPrintToPolarPending,
    dispatchPrintToPolarSuccess:
      state.slicer.status.dispatchPrintToPolarSuccess,
    dispatchPrintToDevicePending:
      state.slicer.status.dispatchPrintToDevicePending,
    dispatchPrintToDeviceSuccess:
      state.slicer.status.dispatchPrintToDeviceSuccess,
    sliceJobs: state.slicer.sliceJobs,
    dispatchSliceJobPending: state.slicer.status.dispatchSliceJobPending,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addModelsToScene: () => dispatch(THREEactions.addModelsToScene()),
  removeModelsFromScene: () => dispatch(THREEactions.removeModelsFromScene()),
  addTowerToScene: (tower) => dispatch(THREEactions.addTowerToScene(tower)),
  removeTowerFromScene: () => dispatch(THREEactions.removeTowerFromScene()),
  addToolpath: (toolpath) => dispatch(THREEactions.addToolpath(toolpath)),
  updateToolpath: (toolpath, prevToolpath) =>
    dispatch(THREEactions.updateToolpath(toolpath, prevToolpath)),
  removeToolpath: (toolpath) => dispatch(THREEactions.removeToolpath(toolpath)),
  dispatchSliceJob: (projectId) =>
    dispatch(actions.dispatchSliceJobRequest(projectId)),
  download: (projectId) => dispatch(actions.downloadRequest(projectId)),
  loadToolpath: (viewType = null) =>
    dispatch(actions.loadToolpathPtpRequest(viewType)),
  cancelLoadToolpath: () => dispatch(actions.loadToolpathPtpCancel()),
  unloadToolpath: () => dispatch(actions.unloadToolpath()),
  cancelCreateToolpathMeshes: () =>
    dispatch(actions.createToolpathMeshesCancel()),
  setToolpathViewType: (viewType) =>
    dispatch(actions.setToolpathViewType(viewType)),
  setToolpathShowTravel: (showTravel) =>
    dispatch(actions.setToolpathShowTravel(showTravel)),
  setToolpathShowRetracts: (showRetracts) =>
    dispatch(actions.setToolpathShowRetracts(showRetracts)),
  setToolpathShowRestarts: (showRestarts) =>
    dispatch(actions.setToolpathShowRestarts(showRestarts)),
  setToolpathLayerRange: (minLayer, maxLayer) =>
    dispatch(actions.setToolpathLayerRange(minLayer, maxLayer)),
  getPolarPrinters: () => dispatch(actions.getPolarPrintersRequest()),
  dispatchPrintToPolar: (projectId, polarPrinterSerialNumber) =>
    dispatch(
      actions.dispatchPrintToPolarRequest(projectId, polarPrinterSerialNumber)
    ),
  dispatchPrintToDevice: (projectId, deviceId) =>
    dispatch(actions.dispatchPrintToDeviceRequest(projectId, deviceId)),
  getSlicePreviewData: () => dispatch(actions.getSlicePreviewData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SliceView);

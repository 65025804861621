import { connect } from 'react-redux';

import { actions as slicerActions } from '../../../../reducers/slicer/slicer';
import TransitionLength from './transitionLength.jsx';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  updateVariableTransitionLengths: (projectId, values) =>
    dispatch(
      slicerActions.updateVariableTransitionLengthsRequest(projectId, values)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransitionLength);

import React from 'react';

import supportTooltips from '../../printerManager/configureStyle/forms/supports/tooltips.jsx';
import { fieldTypes } from '../../../constants';

const fromPrintBedOnly = (
  <div>
    Specify where you would like to generate support. Supports can be generated
    underneath any overhanging sections of the model, or between the print bed
    and the model only.
  </div>
);

export default [
  {
    name: 'maxOverhangAngle',
    label: 'Maximum overhang',
    tags: [],
    tooltip: supportTooltips.maxOverhangAngle,
    variants: [
      {
        units: '°',
        type: fieldTypes.number,
        min: 0,
        gte: false,
        max: 90,
        step: 1,
      },
    ],
  },
  {
    name: 'fromPrintBedOnly',
    label: 'From print bed only',
    tags: [],
    tooltip: fromPrintBedOnly,
    variants: [
      {
        type: fieldTypes.checkbox,
      },
    ],
  },
];

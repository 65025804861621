import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ element = 'div', testId = '', children }) => {
  const [container] = useState(() => {
    // This will be executed only on the initial render
    // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
    const el = document.createElement(element);
    if (testId) {
      el.dataset.testId = testId;
    }
    return el;
  });

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  return createPortal(children, container);
};

export default Portal;

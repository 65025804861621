import 'regenerator-runtime/runtime';
import { put, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import { Vector3, Box3 } from 'three';

import {
  getSlicerState,
  getPrinterState,
  handleGenericError,
} from '../../common';
import { updateTowerPositionAndSize } from '../tower/updateTowerPositionAndSize';
import { actions } from '../../../reducers/slicer/slicer';

import {
  PackingUtils,
  PrinterUtils,
  ProjectUtils,
  SceneUtils,
  SlicerUtils,
  TowerUtils,
} from '../../../utils';

const PADDING_DEFAULT = 2.5; // 5 mm gap between models

const DIRECTIONS = {
  X: 'x',
  Y: 'y',
};

const getLength = (box, axis) => box.max[axis] - box.min[axis];

const getBounding2DArea = (box) => {
  const { min, max } = box;
  const xLength = max.x - min.x;
  const yLength = max.y - min.y;
  return xLength * yLength;
};

const getMaxByAxis = (nodes, axis) =>
  _.reduce(
    nodes,
    (maxValue, node) => Math.max(getLength(node.boundingBox, axis), maxValue),
    0
  );

const getInitialPositionData = (model) => {
  let boundingBox;
  if (model.type === 'group') {
    boundingBox = SlicerUtils.getCombinedBoundingBox(model.children);
  } else {
    boundingBox = SlicerUtils.getBoundingBox(model.mesh);
  }
  boundingBox.min.x -= PADDING_DEFAULT;
  boundingBox.min.y -= PADDING_DEFAULT;
  boundingBox.max.x += PADDING_DEFAULT;
  boundingBox.max.y += PADDING_DEFAULT;
  const center = SlicerUtils.getBoundingBoxCenter(boundingBox);
  const area = getBounding2DArea(boundingBox);
  return {
    model,
    boundingBox,
    area,
    center,
  };
};

const prepareModels = (models) =>
  _.map(models, (node) => getInitialPositionData(node));

const prepareTower = (tower, padding) => {
  if (!tower) return null;
  const center = new Vector3(0, 0, 0);
  const xHalfLength = tower.brims.size.x / 2;
  const yHalfLength = tower.brims.size.y / 2;
  const boundingBox = new Box3(
    new Vector3(-xHalfLength - padding, -yHalfLength - padding, 0),
    new Vector3(xHalfLength + padding, yHalfLength + padding, 0)
  );
  const area = getBounding2DArea(boundingBox);
  return {
    tower,
    boundingBox,
    area,
    center,
  };
};

const offsetModelTransforms = (model, translateOffset) => ({
  ...model,
  transforms: {
    translate: {
      x: model.transforms.translate.x + translateOffset.x,
      y: model.transforms.translate.y + translateOffset.y,
      z: model.transforms.translate.z + translateOffset.z,
    },
  },
});

const setTowerTransforms = (tower, position) => ({
  ...tower,
  position: {
    ...tower.position,
    x: position.x,
    y: position.y,
  },
});

const updateModelPositions = (nodes, repackBounds) => {
  const packedModels = [];
  let packedTower = null;
  nodes.forEach((node) => {
    const { boundingBox } = node;
    if (node.tower) {
      const towerCenter = {
        x:
          repackBounds.xMin +
          node.x +
          (boundingBox.max.x - boundingBox.min.x) / 2,
        y:
          repackBounds.yMin +
          node.y +
          (boundingBox.max.y - boundingBox.min.y) / 2,
      };
      packedTower = setTowerTransforms(node.tower, towerCenter);
    } else {
      const offsets = {
        x: repackBounds.xMin + node.x - boundingBox.min.x,
        y: repackBounds.yMin + node.y - boundingBox.min.y,
        z: -boundingBox.min.z,
      };
      if (node.model.type === 'group') {
        _.forEach(node.model.children, (child) =>
          packedModels.push(offsetModelTransforms(child, offsets))
        );
      } else {
        packedModels.push(offsetModelTransforms(node.model, offsets));
      }
    }
  });
  return {
    packedModels,
    packedTower,
  };
};

const packModels = (bedSize, paddedModels, majorAxis) => {
  const bedAspectRatio = bedSize.x / bedSize.y;
  let minorAxis = DIRECTIONS.X;
  if (majorAxis === DIRECTIONS.X) {
    minorAxis = DIRECTIONS.Y;
  }
  const maxLength = getMaxByAxis(paddedModels, minorAxis);
  const result = PackingUtils.potpack(paddedModels, maxLength, bedAspectRatio);
  const boundingContainer = { xLength: result.w, yLength: result.h };
  return {
    packedModels: paddedModels,
    boundingContainer,
  };
};

const centerOnBed = (boundingContainer, bedSize, originOffset) => {
  const bedCenter = SceneUtils.getBedMiddle(bedSize, originOffset);
  return {
    xMin: bedCenter.x - boundingContainer.xLength / 2,
    yMin: bedCenter.y - boundingContainer.yLength / 2,
  };
};

const localRepack = (machineSettings, models, tower, towerPadding) => {
  const { bedSize, originOffset } = machineSettings;
  const packDirection = bedSize.x >= bedSize.y ? DIRECTIONS.X : DIRECTIONS.Y;
  const initialModelPositions = prepareModels(models);
  const initialTowerPosition = prepareTower(tower, towerPadding);

  if (initialTowerPosition) {
    initialModelPositions.push(initialTowerPosition);
  }

  const { packedModels, boundingContainer } = packModels(
    bedSize,
    initialModelPositions,
    packDirection
  );
  const repackBounds = centerOnBed(boundingContainer, bedSize, originOffset);
  return updateModelPositions(packedModels, repackBounds);
};

const createModelTransforms = (packedModels) =>
  _.map(packedModels, (model) => ({
    id: model.id,
    transforms: _.pick(model.transforms, ['translate']),
  }));

export default function* repack(action) {
  try {
    const { newTower } = action.payload; // optionally dispatch repack with new tower
    const {
      models,
      transitionTower,
      currentProject: projectId,
      projects,
    } = yield select(getSlicerState);
    const { printers } = yield select(getPrinterState);
    const project = projects[projectId];
    const printer = ProjectUtils.getProjectPrinter(project, printers);
    const machineSettings = PrinterUtils.formatMachineSettings(
      printer.machineSettings
    );

    const towerPadding = TowerUtils.getTowerPadding(project.style);
    const { packedModels, packedTower } = localRepack(
      machineSettings,
      models,
      newTower || transitionTower,
      towerPadding
    );
    const modelTransforms = createModelTransforms(packedModels);
    yield put(actions.updateModelTransformsRequest(modelTransforms));
    if (packedTower) {
      yield call(updateTowerPositionAndSize, packedTower, projectId);
    }
    yield put(actions.repackSuccess(packedModels));
    yield put(actions.invalidateSlice(projectId));

    // update thumbnail
    yield put(actions.updateProjectThumbnailRequest(projectId));
  } catch (err) {
    yield call(handleGenericError, action, err);
    yield put(actions.repackFailure(err));
  }
}

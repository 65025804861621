import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import { handleGenericError } from '../common';
import { makeRequest } from './horizon';
import { actions } from '../../reducers/iot/iot';

export default function* pausePrint(action) {
  const { device } = action.payload;

  try {
    const deviceType = device.type;

    yield call(
      makeRequest,
      device.id,
      deviceType === 'canvas-hub' ? 'printer/pause' : 'printer/print/pause',
      {
        method: 'post',
      },
      'pausePrint'
    );
    yield put(actions.pausePrintSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

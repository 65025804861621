import React from 'react';
import _ from 'lodash';
import { DragLayer } from 'react-dnd';
import { DefaultContainer } from './draggableCard.styles';
import {
  LayerContainer,
  PreviewContainer,
  DragPreviewFirstCard,
  DragPreviewSecondCard,
  DragPreviewThirdCard,
} from './customDragLayer.styles';

const renderCustomDragPreview = (props) => {
  const { selectedProjectIds, StyledContainer = DefaultContainer } = props;
  if (_.isEmpty(selectedProjectIds)) return null;
  const { width, height } = props;

  const renderSecondCard = () => {
    if (selectedProjectIds.length > 1) {
      return (
        <DragPreviewSecondCard width={width} height={height}>
          <StyledContainer selected={true} />
        </DragPreviewSecondCard>
      );
    }
    return null;
  };
  const renderThirdCard = () => {
    if (selectedProjectIds.length > 2) {
      return (
        <DragPreviewThirdCard width={width} height={height}>
          <StyledContainer selected={true} />
        </DragPreviewThirdCard>
      );
    }
    return null;
  };

  return (
    <DragPreviewFirstCard width={width}>
      <StyledContainer {...props}>
        {props.children}
        {renderSecondCard()}
        {renderThirdCard()}
      </StyledContainer>
    </DragPreviewFirstCard>
  );
};

const CustomDragLayer = (props) => {
  const { isDragging, selected, currentOffset } = props;
  if (!isDragging || !selected) {
    return null;
  }

  return (
    <LayerContainer>
      <PreviewContainer currentOffset={currentOffset}>
        {renderCustomDragPreview(props)}
      </PreviewContainer>
    </LayerContainer>
  );
};

function collect(monitor) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  };
}

export default DragLayer(collect)(CustomDragLayer);

import React from 'react';
import Metadata from './general.metadata';
import StyleSettingsFormSection from '../styleSettingsFormSection.jsx';

import { Container, Col } from '../forms.styles';

class Content extends StyleSettingsFormSection {
  static isSectionMatched(props, filterBy) {
    return (
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.solidLayers,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.finish,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.ironing,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.travel,
        filterBy
      )
    );
  }

  render() {
    return (
      <Container>
        <Col>
          {this.renderFieldGroup(Metadata.solidLayers)}
          {this.renderFieldGroup(Metadata.travel)}
        </Col>
        <Col>
          {this.renderFieldGroup(Metadata.finish)}
          {this.renderFieldGroup(Metadata.ironing)}
        </Col>
      </Container>
    );
  }
}

export default Content;

import React, { Component } from 'react';

import {
  ButtonContainer,
  HeaderStylesWrapper,
  SideBarContentWrapper,
} from './spliceTransitionModal.styles';

import {
  Button,
  ConfirmationModal,
  H6OneLine,
  SettingsModal,
  SideBar,
} from '../../../shared';

import { InterfaceUtils } from '../../../utils';
import TransitionLength from './transitionLength/transitionLength.container';
import SpliceSettings from './spliceSettings/spliceSettings.container';

const tabOptions = [
  {
    text: 'Splice Settings',
  },
  {
    text: 'Transition Lengths',
  },
];

class SpliceTransitionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusField: null,
      baseSettings: {},
      overrideSettings: {},
      errors: {},
      activeTabIndex: 0, // state is used to switch between SideBar tabs
      spliceSettingsChanged: false,
      spliceSettingsInputError: false,
      saveSpliceSettings: false,
      transitionLengthChanged: false,
      transitionLengthInputError: false,
      saveTransitionLength: false,
      showCancelModal: false,
      spliceSettingsOnly: false,
    };
  }

  componentDidMount() {
    const { defaultActiveTab } = this.props;
    if (defaultActiveTab) {
      this.setState({ activeTabIndex: 1 });
    }
  }

  handleSave() {
    const { transitionLengthChanged, spliceSettingsChanged } = this.state;
    this.setState(
      {
        saveTransitionLength: transitionLengthChanged,
        saveSpliceSettings: spliceSettingsChanged,
      },
      () => this.props.onClickClose()
    );
  }

  renderCancelModal() {
    const { showCancelModal } = this.state;
    if (!showCancelModal) return null;

    return (
      <ConfirmationModal
        isWarning
        primaryLabel='You have unsaved changes'
        secondaryLabel='Are you sure you would like to exit without saving?'
        cancelLabel='Close'
        confirmLabel='Exit'
        onClickCancel={() => this.setState({ showCancelModal: false })}
        onClickConfirm={() => this.props.onClickClose()}
      />
    );
  }

  showCancelModal() {
    const { spliceSettingsChanged, transitionLengthChanged } = this.state;
    if (spliceSettingsChanged || transitionLengthChanged) {
      this.setState({ showCancelModal: true });
    } else {
      this.props.onClickClose();
    }
  }

  renderButtons() {
    const {
      spliceSettingsChanged,
      transitionLengthChanged,
      transitionLengthInputError,
      spliceSettingsInputError,
    } = this.state;
    let enableSave =
      (spliceSettingsChanged || transitionLengthChanged) &&
      !spliceSettingsInputError;
    enableSave =
      enableSave &&
      (transitionLengthChanged ? !transitionLengthInputError : true);
    return (
      <ButtonContainer>
        <Button expand onClick={() => this.showCancelModal()}>
          Cancel
        </Button>
        <Button
          disabled={!enableSave}
          expand
          primary
          onClick={() => this.handleSave()}>
          Save
        </Button>
      </ButtonContainer>
    );
  }

  renderHeader() {
    const label = this.props.spliceSettingsOnly
      ? tabOptions[0].text
      : 'Splice and Transition';
    return (
      <HeaderStylesWrapper>
        <H6OneLine>{label}</H6OneLine>
      </HeaderStylesWrapper>
    );
  }

  renderSpliceSettingsOnlySideBarContent() {
    return (
      <SideBarContentWrapper show>
        <SpliceSettings
          onClickClose={() => null}
          saveSpliceSettings={this.state.saveSpliceSettings}
          toggleOnSave={() =>
            this.setState({
              saveSpliceSettings: !this.state.saveSpliceSettings,
            })
          }
          onSpliceSettingsInputChange={(value) => {
            if (value !== this.state.spliceSettingsChanged) {
              this.setState({ spliceSettingsChanged: value });
            }
          }}
          onSpliceSettingsInputError={(value) => {
            if (value !== this.state.spliceSettingsInputError) {
              this.setState({ spliceSettingsInputError: value });
            }
          }}
        />
      </SideBarContentWrapper>
    );
  }

  renderSidebarContent(index) {
    const {
      project,
      onSaveTransitionLength,
      driveColors,
      variableTransitionLengths,
      currentExtruderIndex,
    } = this.props;

    if (this.props.spliceSettingsOnly)
      return this.renderSpliceSettingsOnlySideBarContent();

    // if the material is generic let materialId be undefined
    let materialId;
    if (project.materialIds[currentExtruderIndex] !== '0') {
      materialId = project.materialIds[currentExtruderIndex];
    }

    return (
      <>
        <SideBarContentWrapper show={index === 0}>
          <SpliceSettings
            project={project}
            materialId={materialId}
            onClickClose={() => null}
            saveSpliceSettings={this.state.saveSpliceSettings}
            toggleOnSave={() =>
              this.setState({
                saveSpliceSettings: !this.state.saveSpliceSettings,
              })
            }
            onSpliceSettingsInputChange={(value) => {
              if (value !== this.state.spliceSettingsChanged) {
                this.setState({ spliceSettingsChanged: value });
              }
            }}
            onSpliceSettingsInputError={(value) => {
              if (value !== this.state.spliceSettingsInputError) {
                this.setState({ spliceSettingsInputError: value });
              }
            }}
          />
        </SideBarContentWrapper>
        <SideBarContentWrapper show={index === 1}>
          <TransitionLength
            onSave={onSaveTransitionLength}
            toggleOnSave={() =>
              this.setState({
                saveTransitionLength: !this.state.saveTransitionLength,
              })
            }
            driveColors={driveColors}
            project={project}
            saveTransitionLength={this.state.saveTransitionLength}
            onTransitionLengthInputChanged={(value) => {
              if (value !== this.state.transitionLengthChanged) {
                this.setState({ transitionLengthChanged: value });
              }
            }}
            onTransitionLengthInputError={(value) => {
              if (value !== this.state.transitionLengthInputError) {
                this.setState({ transitionLengthInputError: value });
              }
            }}
            {...variableTransitionLengths}
          />
        </SideBarContentWrapper>
      </>
    );
  }

  setActiveTabIndex(index) {
    return this.setState({ activeTabIndex: index });
  }

  renderSidebar() {
    return (
      <SideBar
        items={this.props.spliceSettingsOnly ? [tabOptions[0]] : tabOptions}
        activeTab={this.state.activeTabIndex}
        setActiveTab={(index) => this.setActiveTabIndex(index)}>
        {this.renderSidebarContent(this.state.activeTabIndex)}
      </SideBar>
    );
  }

  renderConfigureMaterialModal() {
    return (
      <>
        {InterfaceUtils.getLoadingSpinner(this.props)}
        <SettingsModal
          headerTitle={this.renderHeader()}
          headerButtons={this.renderButtons()}
          sideBarContent={this.renderSidebar()}
        />
        {this.renderCancelModal()}
      </>
    );
  }

  render() {
    return this.renderConfigureMaterialModal();
  }
}

export default SpliceTransitionModal;

import _ from 'lodash';
import * as THREE from 'three';

import types from '../../reducers/slicer/types';

/* eslint-disable no-param-reassign, no-loop-func, no-continue */
const Utils = {
  resetRegions: (faces) => {
    for (let i = 0; i < faces.length; i++) {
      faces[i].region = 0;
    }
    return faces;
  },
  resetSegments: (faces) => {
    for (let i = 0; i < faces.length; i++) {
      faces[i].segment = 0;
    }
    return faces;
  },
  someSegmentsAreTooSmall: (segments, sizeThreshold) =>
    segments.reduce((flag, segment) => {
      if (segment.length <= sizeThreshold) flag = true;
      return flag;
    }, false),
  composeSegmentMap: (faces, angleThreshold) => {
    // Construct segment data
    const segments = [];

    let face;
    for (let i = 0; i < faces.length; i++) {
      face = faces[i];

      const neighbourIndices = [];
      if (face.edge.twin) neighbourIndices.push(face.edge.twin.faceIndex);
      if (face.edge.next.twin)
        neighbourIndices.push(face.edge.next.twin.faceIndex);
      if (face.edge.prev.twin)
        neighbourIndices.push(face.edge.prev.twin.faceIndex);

      let neighbour;
      for (
        let neighborIndex = 0;
        neighborIndex < neighbourIndices.length;
        neighborIndex++
      ) {
        neighbour = faces[neighbourIndices[neighborIndex]];
        if (face.segment === 0 && neighbour.segment > 0) {
          // not currently in a segment
          if (
            face.area === 0 ||
            face.normal.angleTo(neighbour.normal) <= angleThreshold
          ) {
            // join neighbour's segment if:
            // - face has area of 0
            // - face has similar normal to neighbour
            face.segment = neighbour.segment;
            segments[face.segment].push(face);
          }
        } else if (
          face.segment > 0 &&
          neighbour.segment > 0 &&
          neighbour.segment !== face.segment
        ) {
          if (
            face.area === 0 ||
            face.normal.angleTo(neighbour.normal) <= angleThreshold
          ) {
            // two adjacent segments need merging if:
            // - face has an area of 0, or
            // - face has similar normal to neighbour
            // - if so, merge the smaller segment into the larger segment
            const newIsLarger =
              segments[face.segment].length >
              segments[neighbour.segment].length;
            const keepSegment = newIsLarger ? face.segment : neighbour.segment;
            const mergeSegment = newIsLarger ? neighbour.segment : face.segment;

            let mergeTriangle;
            for (let k = 0; k < segments[mergeSegment].length; k++) {
              mergeTriangle = segments[mergeSegment][k];
              mergeTriangle.segment = keepSegment;
              segments[keepSegment].push(mergeTriangle);
            }
            // TODO: should the arrays be concatenated here, instead of doing pushes in the loop?
            delete segments[mergeSegment];
          }
        }
      }
      if (face.segment === 0) {
        // create a new segment starting with only this face
        face.segment = segments.length + 1;
        segments[face.segment] = [face];
      }
    }

    // compact the segment numbers so the segments array is dense
    const denseSegments = Utils.compactSparseArray(segments, 'segment');
    return denseSegments;
  },
  mergeSimilarSegments: (faces, segments, angleThreshold, sizeThreshold) => {
    const segmentsWithMeta = new Map();
    segments.forEach((segment, segmentIndex) => {
      const boundaryMap = new Map();
      segment.forEach((face) => {
        // find indices of adjacent segment
        const neighbourIndices = [];
        if (face.edge.twin) neighbourIndices.push(face.edge.twin.faceIndex);
        if (face.edge.next.twin)
          neighbourIndices.push(face.edge.next.twin.faceIndex);
        if (face.edge.prev.twin)
          neighbourIndices.push(face.edge.prev.twin.faceIndex);

        let neighbour;
        for (
          let neighborIndex = 0;
          neighborIndex < neighbourIndices.length;
          neighborIndex++
        ) {
          neighbour = faces[neighbourIndices[neighborIndex]];
          // neighbour face belongs in different segment
          if (face.segment !== neighbour.segment) {
            if (boundaryMap.has(neighbour.segment)) {
              // neighbour face's segment is already in map; append to it
              const existingBoundary = boundaryMap.get(neighbour.segment);
              boundaryMap.set(neighbour.segment, [...existingBoundary, face]);
            } else {
              // neighbour face's segment is not in map yet; add to it
              boundaryMap.set(neighbour.segment, [face]);
            }
          }
        }
      });

      // store segment meta data
      segmentsWithMeta.set(segmentIndex, {
        faces: segment,
        boundaryMap,
      });
    });

    const mergedSegmentIndices = new Set();
    const mergedSegments = [];
    segmentsWithMeta.forEach((currentSegmentWithMeta, curSegmentIndex) => {
      const { faces: curFaces, boundaryMap: curBoundaryMap } =
        currentSegmentWithMeta;

      // already merged; skip current segment
      if (mergedSegmentIndices.has(curSegmentIndex)) return;
      let facesToMerge = curFaces.slice();
      mergedSegmentIndices.add(curSegmentIndex);

      curBoundaryMap.forEach((boundaryFromCurSide, adjSegmentIndex) => {
        // already merged; skip adjacent segment
        if (mergedSegmentIndices.has(adjSegmentIndex)) return;

        const adjSegmentWithMeta = segmentsWithMeta.get(adjSegmentIndex);
        const { faces: adjFaces, boundaryMap: adjBoundaryMap } =
          adjSegmentWithMeta;

        const boundaryFromAdjSide = adjBoundaryMap.get(curSegmentIndex);

        // cannot find matching boundary from adj side; skip this boundary
        if (!boundaryFromAdjSide) return;

        const curSideBoundaryNormal = new THREE.Vector3();
        for (let j = 0; j < boundaryFromCurSide.length; j++) {
          curSideBoundaryNormal.add(boundaryFromCurSide[j].normal);
        }
        curSideBoundaryNormal.normalize();

        const adjSideBoundaryNormal = new THREE.Vector3();
        for (let k = 0; k < boundaryFromAdjSide.length; k++) {
          adjSideBoundaryNormal.add(boundaryFromAdjSide[k].normal);
        }
        adjSideBoundaryNormal.normalize();

        if (
          curSideBoundaryNormal.angleTo(adjSideBoundaryNormal) <=
            angleThreshold ||
          curFaces.length <= sizeThreshold ||
          adjFaces.length <= sizeThreshold
        ) {
          // meet criteria; merge segments
          facesToMerge = facesToMerge.concat(adjFaces);
          mergedSegmentIndices.add(adjSegmentIndex);
        }
      });
      mergedSegments.push(facesToMerge);
    });
    return mergedSegments.map((mergedSegment, segmentIndex) =>
      mergedSegment.map((mergedSegmentFace) => {
        mergedSegmentFace.segment = segmentIndex;
        return mergedSegmentFace;
      })
    );
  },
  trimSegmentBoundaries: (faces, segments, sizeThreshold) => {
    const visitedFaces = [];
    for (let segmentIndex = 0; segmentIndex < segments.length; segmentIndex++) {
      const segment = segments[segmentIndex];
      // define region boundaries
      let currentFace;
      const segmentBoundaryEdges = [];
      for (let i = 0; i < segment.length; i++) {
        currentFace = segment[i];

        const edges = [
          currentFace.edge,
          currentFace.edge.next,
          currentFace.edge.prev,
        ];

        let currentEdge;
        for (let j = 0; j < edges.length; j++) {
          currentEdge = edges[j];
          if (currentEdge.twin) {
            const twinFace = faces[currentEdge.twin.faceIndex];
            if (twinFace.segment !== currentFace.segment) {
              segmentBoundaryEdges.push(currentEdge);
            }
          }
        }
      }

      const oldBoundary = segmentBoundaryEdges.slice();
      let currentSegment;
      while (oldBoundary.length > 0) {
        currentSegment = oldBoundary.shift();
        let nextSegmentIndex = oldBoundary.findIndex(
          (item) => item.tail.toString() === currentSegment.head.toString()
        );
        let nextSegment = oldBoundary[nextSegmentIndex];

        // try searching in opposite direction
        if (!nextSegment) {
          nextSegmentIndex = oldBoundary.findIndex(
            (item) => item.head.toString() === currentSegment.tail.toString()
          );
          nextSegment = oldBoundary[nextSegmentIndex];
        }
        // skip current segment if:
        // 1. not connected
        // 2. current segment has no twin
        // 3. next segment has no twin
        if (!nextSegment || !currentSegment.twin || !nextSegment.twin) continue;

        // boundary is pushed in
        if (currentSegment.twin.faceIndex === nextSegment.twin.faceIndex) {
          if (visitedFaces.includes(currentSegment.twin.faceIndex)) continue;
          const commonTwinFace = faces[currentSegment.twin.faceIndex];
          const oldSegmentIndex = commonTwinFace.segment;

          // find new segment
          const newSegment = [
            commonTwinFace.edge,
            commonTwinFace.edge.next,
            commonTwinFace.edge.prev,
          ].find((item) => {
            if (!item.twin) return false;
            return (
              faces[item.twin.faceIndex].segment === commonTwinFace.segment
            );
          });

          // skip this segment if new segment cannot be found
          if (!newSegment) continue;

          // skip this segment if already at min size
          if (segments[oldSegmentIndex].length <= sizeThreshold) continue;

          // mark this face as visited
          visitedFaces.push(currentSegment.twin.faceIndex);

          // reassign segment number of this face
          faces[currentSegment.twin.faceIndex].segment = segmentIndex;

          // add this face to current segment
          segments[segmentIndex].push(commonTwinFace);

          // remove this face from old segment
          segments[oldSegmentIndex] = segments[oldSegmentIndex].filter(
            (face) => face.edge.faceIndex !== currentSegment.twin.faceIndex
          );

          _.pullAt(oldBoundary, [nextSegmentIndex]);
          oldBoundary.push(newSegment);
        } else if (currentSegment.faceIndex === nextSegment.faceIndex) {
          // boundary is pushed out
          if (visitedFaces.includes(currentSegment.faceIndex)) continue;
          const commonFace = faces[currentSegment.faceIndex];
          const twinFaceOne = faces[currentSegment.twin.faceIndex];
          const twinFaceTwo = faces[nextSegment.twin.faceIndex];

          // find new segment
          const newSegment = [
            commonFace.edge,
            commonFace.edge.next,
            commonFace.edge.prev,
          ].find((item) => {
            if (!item.twin) return false;
            return faces[item.twin.faceIndex].segment === commonFace.segment;
          });

          // skip this segment if new segment cannot be found
          if (!newSegment) continue;

          if (twinFaceOne.segment !== twinFaceTwo.segment) continue;
          const adjacentSegmentIndex = twinFaceOne.segment;

          // skip this segment if already at min size
          if (segments[segmentIndex].length <= sizeThreshold) continue;

          // mark this face as visited
          visitedFaces.push(currentSegment.faceIndex);

          // reassign segment number of this face
          faces[currentSegment.faceIndex].segment = adjacentSegmentIndex;

          // add this face to adjacent segment
          segments[adjacentSegmentIndex].push(commonFace);

          // remove this face from current segment
          segments[segmentIndex] = segments[segmentIndex].filter(
            (face) => face.edge.faceIndex !== currentSegment.faceIndex
          );

          _.pullAt(oldBoundary, [nextSegmentIndex]);
          oldBoundary.push(newSegment);
        }
      }
    }
    return segments;
  },
  flattenSegmentToVertices: (faces, segments) => {
    const rawSegmentVertices = [];
    const currentVert = new THREE.Vector3();
    let currentSegment;
    for (let i = 0; i < segments.length; i++) {
      currentSegment = segments[i];
      let currentFace;
      for (let j = 0; j < currentSegment.length; j++) {
        currentFace = currentSegment[j];
        const edges = [
          currentFace.edge, // a
          currentFace.edge.next, // b
          currentFace.edge.next.next, // c
        ];
        let currentEdge;
        for (let k = 0; k < edges.length; k++) {
          currentEdge = edges[k];
          if (currentEdge.twin) {
            const twinFace = faces[currentEdge.twin.faceIndex];
            if (twinFace.segment !== currentFace.segment) {
              if (currentEdge.vertOrder === 0) {
                // edge a
                rawSegmentVertices.push(
                  ...currentVert.copy(currentFace.a).toArray(),
                  ...currentVert.copy(currentFace.b).toArray()
                );
              } else if (currentEdge.vertOrder === 1) {
                // edge b
                rawSegmentVertices.push(
                  ...currentVert.copy(currentFace.b).toArray(),
                  ...currentVert.copy(currentFace.c).toArray()
                );
              } else {
                // edge c
                rawSegmentVertices.push(
                  ...currentVert.copy(currentFace.c).toArray(),
                  ...currentVert.copy(currentFace.a).toArray()
                );
              }
            }
          }
        }
      }
    }
    return rawSegmentVertices;
  },
  createSegmentBoundaries: (verticesBuffer) => {
    const segmentGeom = new THREE.BufferGeometry();
    segmentGeom.addAttribute(
      'position',
      new THREE.BufferAttribute(new Float32Array(verticesBuffer), 3)
    );
    const segmentMaterial = new THREE.LineBasicMaterial({ color: 0x810bf8 });
    const segmentLines = new THREE.LineSegments(segmentGeom, segmentMaterial);
    segmentLines.name = types.SEGMENT_BOUNDARIES_NAME;
    return segmentLines;
  },
  compactSparseArray: (array, property) => {
    // _.keys does not work for sparse arrays; use Object.keys() instead
    // [empty, something, empty] will need to give[0, 1, 2]
    const sparseItems = Object.keys(array).map((item) => parseInt(item, 10));
    const denseItems = [];

    let sparseItem;
    for (let denseItem = 0; denseItem < sparseItems.length; denseItem++) {
      sparseItem = sparseItems[denseItem];
      denseItems[denseItem] = array[sparseItem];

      let faceInDenseRegion;
      for (let m = 0; m < denseItems[denseItem].length; m++) {
        faceInDenseRegion = denseItems[denseItem][m];
        faceInDenseRegion[property] = denseItem;
      }
    }
    return denseItems;
  },
  composeRegionMap: (faces) => {
    // Construct region data
    const regions = [];
    // TODO: naive loop over all triangles may be replaced with BFS/DFS
    // - may reduce the number of merges required at expense of queueing
    // - test/instrument both approaches to compare efficiency
    // TODO: keep regions in a map instead of an array,
    //    and keep a counter for the number of regions created so far?

    let face;
    for (let i = 0; i < faces.length; i++) {
      face = faces[i];

      const neighbourIndices = [];
      if (face.edge.twin) neighbourIndices.push(face.edge.twin.faceIndex);
      if (face.edge.next.twin)
        neighbourIndices.push(face.edge.next.twin.faceIndex);
      if (face.edge.prev.twin)
        neighbourIndices.push(face.edge.prev.twin.faceIndex);

      let neighbour;
      for (
        let neighborIndex = 0;
        neighborIndex < neighbourIndices.length;
        neighborIndex++
      ) {
        neighbour = faces[neighbourIndices[neighborIndex]];
        if (
          face.region === 0 &&
          neighbour.region > 0 &&
          neighbour.color === face.color
        ) {
          // not currently in a region -- join neighbour’s region
          face.region = neighbour.region;
          regions[face.region].push(face);
        } else if (
          face.region > 0 &&
          neighbour.region > 0 &&
          neighbour.region !== face.region &&
          neighbour.color === face.color
        ) {
          // two adjacent regions need merging -- merge the smaller region into the larger region
          const newIsLarger =
            regions[face.region].length > regions[neighbour.region].length;
          const keepRegion = newIsLarger ? face.region : neighbour.region;
          const mergeRegion = newIsLarger ? neighbour.region : face.region;

          let mergeTriangle;
          for (let k = 0; k < regions[mergeRegion].length; k++) {
            mergeTriangle = regions[mergeRegion][k];
            mergeTriangle.region = keepRegion;
            regions[keepRegion].push(mergeTriangle);
          }
          // TODO: should the arrays be concatenated here, instead of doing pushes in the loop?
          delete regions[mergeRegion];
        }
      }
      if (face.region === 0) {
        // create a new region starting with only this face
        face.region = regions.length + 1;
        regions[face.region] = [face];
      }
    }

    const denseRegions = Utils.compactSparseArray(regions, 'region');
    return denseRegions;
  },
};

/* eslint-enable no-param-reassign, no-loop-func, no-continue */
export default Utils;

import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';
import _ from 'lodash';

import { getPaginatedItems } from '../canvas-api';

import { handleGenericError } from '../common';
import { sanitizePrinterData } from './common';
import { actions } from '../../reducers/printers/printers';

export default function* getPrinterPresets(action) {
  try {
    const printerPresets = yield call(
      getPaginatedItems,
      'printer-presets',
      'presets'
    );
    if (printerPresets === null) return;
    const sanitizedPresets = {};
    _.forEach(printerPresets, (preset) => {
      sanitizedPresets[preset.id] = sanitizePrinterData(preset);
    });
    yield put(actions.getPrinterPresetsSuccess(sanitizedPresets));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

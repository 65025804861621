import styled from 'styled-components';

const orderedListIndentation = '2.5rem';

export const LegalPageWrapper = styled.div`
  max-width: 60rem;
  margin: 0 auto;
  color: ${(props) => props.theme.colors.textPrimary};
`;

export const Article = styled.article`
  padding-top: 3em;
  padding-bottom: 5em;

  header {
    margin-bottom: 4em;
  }

  h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }

  h2 {
    font-size: 1.2em;
    margin-top: 2em;
    font-weight: ${(props) => props.theme.fonts.weightBold};
  }

  h3 {
    font-weight: ${(props) => props.theme.fonts.weightBold};
  }

  p {
    line-height: 1.4;
  }

  strong {
    font-weight: ${(props) => props.theme.fonts.weightBold};
  }

  ol {
    margin-bottom: 2em;
    margin-left: 0;
    padding-left: 0;
    counter-reset: section;
    list-style-type: none;
  }

  ul {
    margin-bottom: 2em;
    margin-left: 0;
    padding-left: 1.25em;
  }

  li {
    margin-bottom: 1em;
    line-height: 1.4;
  }

  li h4 {
    display: inline-block;
    margin: 0;
    font-size: 1em;
    font-weight: ${(props) => props.theme.fonts.weightBold};
    text-transform: uppercase;
  }

  ol > li {
    padding-left: ${orderedListIndentation};
  }

  ol > li::before {
    display: inline-block;
    width: ${orderedListIndentation};
    margin-left: -${orderedListIndentation};
    counter-increment: section;
    content: counters(section, '.');
    font-size: 1.2em;
  }

  ol ol > li::before {
    font-size: 1em;
  }

  li:first-child {
    margin-top: 1em;
  }

  ol > li.legal-list-item-skip {
    padding-left: 0;
    &::before {
      counter-increment: none;
      display: none;
    }
  }

  ol.legal-list-alpha {
    counter-reset: legalListAlpha;
  }

  ol.legal-list-alpha > li::before {
    counter-increment: legalListAlpha;
    content: counter(legalListAlpha, lower-alpha) ')';
  }
`;

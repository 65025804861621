import React, { Component } from 'react';

import {
  Container,
  WarningTextWrapper,
  AdditionalWarningTextWrapper,
} from './notFound.styles';

import { Body1, Button, H1, Page } from '../../shared';

import Routes from '../../router/routes';

class NotFound extends Component {
  renderMainPanelContent() {
    const { extraDescriptionText, history } = this.props;
    let extraDescription = null;
    if (extraDescriptionText) {
      extraDescription = (
        <AdditionalWarningTextWrapper>
          <Body1 grey>{this.props.extraDescriptionText}</Body1>
        </AdditionalWarningTextWrapper>
      );
    }
    return (
      <Container>
        <H1 grey>404</H1>
        <WarningTextWrapper>
          <Body1 grey>Page not found</Body1>
        </WarningTextWrapper>
        {extraDescription}
        <Button primary onClick={() => history.push(Routes.toHome())}>
          Go to my projects
        </Button>
      </Container>
    );
  }

  render() {
    return <Page>{this.renderMainPanelContent()}</Page>;
  }
}

export default NotFound;

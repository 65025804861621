import styled from 'styled-components';

export const LayerContainer = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
`;

export const PreviewContainer = styled.div.attrs((props) => ({
  style: {
    display: props.currentOffset ? 'block' : 'none',
    transform: props.currentOffset
      ? `translate(${props.currentOffset.x}px, ${props.currentOffset.y}px)`
      : 'none',
  },
}))``;
export const DragPreviewFirstCard = styled.div`
  width: calc(${(props) => props.width}px);
`;

export const DragPreviewSecondCard = styled.div`
  border-width: 0.16em;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  padding: 0;
  position: absolute;
  top: -1em;
  left: -1em;
  z-index: -1;
`;

export const DragPreviewThirdCard = styled.div`
  border-width: 0.16em;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  padding: 0;
  position: absolute;
  top: -2em;
  left: -2em;
  z-index: -2;
`;

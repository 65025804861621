import THREEtypes from '../three/types';

export const defaultColors = [
  [60, 113, 193, 1], // blue
  [239, 68, 59, 1], // red
  [156, 193, 118, 1], // green
  [252, 202, 70, 1], // yellow
  [155, 89, 182, 1], // purple
  [26, 82, 118, 1], // navy
  [250, 219, 216, 1], // pink
  [245, 176, 65, 1], // orange
];

export const defaultMaterials = {
  0: {
    id: '0',
    timestamp: '2018-09-25T10:39:00Z',
    name: 'Default Material',
    type: 'PLA',
    diameter: 1.75,
    materialChangeSequence: '',
    style: {
      useExtrusionMultiplier: false,
      extrusionMultiplier: 100,
      useRetractLength: false,
      retractLength: 1,
      useRetractSpeed: false,
      retractSpeed: 30,
      useWipeLength: false,
      wipeLength: 2,
      useMaxPrintSpeed: false,
      maxPrintSpeed: {
        units: 'mm/s',
        value: 60,
      },
      usePrintTemperature: false,
      printTemperature: 210,
      useFirstLayerPrintTemperature: false,
      firstLayerPrintTemperature: {
        value: 'auto',
      },
      useBedTemperature: false,
      bedTemperature: 60,
      useUseFan: false,
      useFan: true,
      useEnableFanAtLayer: false,
      enableFanAtLayer: 1,
      useFanSpeed: false,
      fanSpeed: 100,
      usePerimeterFanSpeed: false,
      perimeterFanSpeed: {
        value: 'auto',
      },
      useTowerSpeed: false,
      towerSpeed: 40,
      useMaxBridgingSpeed: false,
      maxBridgingSpeed: 20,
    },
  },
};

export const defaultToolpath = {
  version: null,
  ptp: null, // raw or decoded (depending on version) geometry/color data
  legend: [],
  layerHeights: [],
  extrusions: null,
  travels: null,
  retracts: null,
  restarts: null,
  viewType: THREEtypes.TOOLPATH_BY_TOOL,
  showTravel: false,
  showRetracts: false,
  showRestarts: false,
  layerCount: 0,
  minLayer: 0, // index of bottom clip plane Z in layerHeights
  maxLayer: 0, // index of top clip plane Z in layerHeights
};

const initialState = {
  intersectMode: 'object',
  transitionTower: null,
  materials: defaultMaterials,
  brush: {
    color: '',
    type: 'facet',
    size: 2,
  },
  status: {
    // Projects
    loadProjectsPending: false,
    loadProjectsSuccess: false,
    loadProjectPending: false,
    loadProjectSuccess: false,
    updateProjectSettingsPending: false,
    updateProjectSettingsSuccess: false,
    createProjectPending: false,
    createProjectSuccess: false,
    createCalibrationProjectPending: false,
    createCalibrationProjectSuccess: false,
    deleteProjectsPending: false,
    deleteProjectsSuccess: false,
    updateProjectNamePending: false,
    updateProjectNameSuccess: false,
    updateProjectStylePending: false,
    updateProjectStyleSuccess: false,
    updateProjectColorsPending: false,
    updateProjectColorsSuccess: false,
    updateVariableTransitionLengthsPending: false,
    updateVariableTransitionLengthsSuccess: false,
    centerProjectPending: false,
    centerProjectSuccess: false,
    repackPending: false,
    repackSuccess: false,
    downloadPending: false,
    downloadSuccess: false,
    loadToolpathPtpPending: false,
    loadToolpathPtpProgress: 0,
    loadToolpathPtpSuccess: false,
    createToolpathMeshesPending: false,
    createToolpathMeshesProgress: 0,
    createToolpathMeshesSuccess: false,
    updateProjectThumbnailPending: false,
    updateProjectThubmnailSuccess: false,
    getProjectThumbnailPending: false,
    getProjectThubmnailSuccess: false,
    deleteProjectThumbnailPending: false,
    deleteProjectThubmnailSuccess: false,
    duplicateProjectPending: false,
    duplicateProjectSuccess: false,
    createFolderPending: false,
    createFolderSuccess: false,
    updateProjectsParentPending: false,
    updateProjectsParentSuccess: false,
    createSharedProjectPending: false,
    createSharedProjectSuccess: false,
    deleteSharedProjectPending: false,
    deleteSharedProjectSuccess: false,
    updateSharedProjectPending: false,
    updateSharedProjectSuccess: false,
    getSharedProjectPending: false,
    getSharedProjectSuccess: false,
    importSharedProjectPending: false,
    importSharedProjectSuccess: false,
    getSharedProjectThumbnailPending: false,
    getSharedProjectThumbnailSuccess: false,
    getSharedProjectModelsPending: false,
    getSharedProjectModelsSuccess: false,
    dispatchPrintToDevicePending: false,
    dispatchPrintToDeviceSuccess: false,
    remapProjectInputsPending: false,
    remapProjectInputsSuccess: false,
    message: '',
    // Models
    uploadStlPending: false,
    uploadStlSuccess: false,
    uploadProgress: 0,
    duplicateModelPending: false,
    duplicateModelSuccess: false,
    deleteModelsPending: false,
    deleteModelsSuccess: false,
    updateModelNamePending: false,
    updateModelNameSuccess: false,
    updateModelTransformsPending: false,
    updateModelTransformsSuccess: false,
    updateGroupNamePending: false,
    updateGroupNameSuccess: false,
    groupModelsPending: false,
    groupModelsSuccess: false,
    ungroupModelsPending: false,
    ungroupModelsSuccess: false,
    alignAndGroupModelsPending: false,
    alignAndGroupModelsSuccess: false,
    // Materials
    getMaterialsPending: false,
    getMaterialsSuccess: false,
    createMaterialPending: false,
    createMaterialSuccess: false,
    applyMaterialPending: false,
    applyMaterialSuccess: false,
    editMaterialPending: false,
    editMaterialSuccess: false,
    deleteMaterialsPending: false,
    deleteMaterialsSuccess: false,
    getSpliceSettingsPending: false,
    getSpliceSettingsSuccess: false,
    createSpliceSettingsPending: false,
    createSpliceSettingsSuccess: false,
    changeMaterialPending: false,
    changeMaterialSuccess: false,
    // FacePainter
    composeColorsRLEPending: false,
    autoSegmentPending: false,
    resetRegionsPending: false,
    buildFacesPending: false,
    generateAtlasPending: false,
    saveModelPaintDataPending: false,
    saveModelPaintDataSuccess: false,
    deleteModelPaintDataPending: false,
    deleteModelPaintDataSuccess: false,
    updateStampTransformsPending: false,
    updateStampTransformsSuccess: false,
    isPainting: false,
    modelToColorId: null,
    showSegmentBoundaries: false,
    // Integrations
    getPolarPrintersPending: false,
    getPolarPrintersSuccess: false,
    dispatchPrintToPolarPending: false,
    dispatchPrintToPolarSuccess: false,
  },
  integrations: {
    polarCloud: {
      printers: null,
    },
  },
  projects: {},
  currentProject: '',
  currentSharedProject: '',
  allModelsHidden: false,
  models: [],
  spliceSettings: {},
  toolpath: defaultToolpath,
  thumbnails: {},
  remapperContext: null,
  sliceJobs: {},
};

export default initialState;

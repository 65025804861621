import styled, { css } from 'styled-components';
import { Body1 } from '../typography/typography';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  text-align: left;
`;

const inputReset = css`
  &:active,
  &:focus {
    outline: none;
  }
  padding: 0;
`;

export const Box = styled.div`
  background: ${(props) =>
    props.checked
      ? `radial-gradient(circle, ${props.theme.colors.whiteDefault} 0%, ${props.theme.colors.whiteDefault} 50%, transparent 50%, transparent 100%)`
      : 'transparent'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InvisibleInput = styled.input`
  ${inputReset}
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
  opacity: 0;
  background-color: transparent;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;

export const CheckboxLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  max-width: 100%;
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.textTertiary
      : props.theme.colors.textPrimary};
`;

export const StyledLabel = styled(Body1)`
  ${(props) =>
    props.boldLabel &&
    css`
      font-weight: bold;
    `}
`;

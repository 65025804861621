import 'regenerator-runtime/runtime';
import { call, put, race, take } from 'redux-saga/effects';
import _ from 'lodash';

import { handleGenericError } from '../../common';
import { actions, types } from '../../../reducers/slicer/slicer';
import { Links } from '../../../themes';

const getCalibrationModels = async () => {
  const filenames = _.keys(Links.calibrationModels);
  return Promise.all(
    _.map(filenames, async (filename) => {
      const s3Url = Links.calibrationModels[filename];
      const response = await fetch(s3Url);
      if (!response.ok) {
        throw new Error('Failed to load calibration model');
      }
      const blob = await response.blob();
      return new File([blob], filename);
    })
  );
};

export default function* createCalibrationProject(action) {
  try {
    // step 1. create a new project
    const { projectOptions } = action.payload;
    yield put(actions.createProjectRequest(projectOptions));
    const createProjectResult = yield race({
      success: take(types.CREATE_PROJECT_SUCCESS),
      failure: take(types.CREATE_PROJECT_FAILURE),
    });
    if (!createProjectResult.success) {
      // error display will be handled by createProject saga
      yield put(
        actions.createCalibrationProjectFailure(
          createProjectResult.failure.payload.error
        )
      );
      return;
    }
    // note: state.slicer.currentProject is now set to the new project's ID,
    //   which makes the following sagas "just work"

    // step 2. get calibration models from S3
    const filesList = yield call(getCalibrationModels);

    // step 3. add calibration models to project
    yield put(actions.uploadStlRequest(filesList, true));
    const uploadModelsResult = yield race({
      success: take(types.UPLOAD_STL_SUCCESS),
      failure: take(types.UPLOAD_STL_FAILURE),
    });
    if (!uploadModelsResult.success) {
      // error display will be handled by uploadStl saga
      yield put(
        actions.createCalibrationProjectFailure(
          uploadModelsResult.failure.payload.error
        )
      );
      return;
    }
    const { models } = uploadModelsResult.success.payload;

    // step 4. set one of the models to use T1
    //  (all uploaded models default to T0, so only need to change one)
    const modelToUpdate = models[0].children[1];
    const modelPaintData = {
      id: modelToUpdate.id,
      deleteRLE: false,
      deleteTexture: false,
    };
    const newExtruder = 1;
    yield put(actions.applyMaterialRequest([modelPaintData], newExtruder));
    const applyMaterialResult = yield race({
      success: take(types.APPLY_MATERIAL_SUCCESS),
      failure: take(types.APPLY_MATERIAL_FAILURE),
    });
    if (!applyMaterialResult.success) {
      yield put(
        actions.createCalibrationProjectFailure(
          applyMaterialResult.failure.payload.error
        )
      );
      // error display will be handled by applyMaterial saga
      return;
    }

    // if we got success, we'll get this next
    const updatedTowerAction = yield take(types.UPDATE_TOWER_FROM_SERVER);
    const newTower = updatedTowerAction.tower;

    // step 5. repack
    yield put(actions.repackRequest(newTower));
    const repackResult = yield race({
      success: take(types.REPACK_SUCCESS),
      failure: take(types.REPACK_FAILURE),
    });
    if (!repackResult.success) {
      yield put(
        actions.createCalibrationProjectFailure(
          repackResult.failure.payload.error
        )
      );
      // error display will be handled by repack saga
      return;
    }

    yield put(actions.createCalibrationProjectSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

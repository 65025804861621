import antlr4 from 'antlr4';

import SyntaxError from './syntaxError';

const BaseErrorListener = antlr4.error.ErrorListener;

export default class ErrorListener extends BaseErrorListener {
  constructor() {
    super();
    this.caughtErrors = [];
  }

  syntaxError(recognizer, offendingSymbol, line, column, msg) {
    this.caughtErrors.push(new SyntaxError(msg, line, column));
  }

  checkErrors() {
    if (this.caughtErrors.length > 0) {
      throw this.caughtErrors[0];
    }
  }
}

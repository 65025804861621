import React from 'react';

import { fieldTypes, fieldSteps, slicers } from '../../../../../constants';
import tooltips from './tooltips.jsx';
import { MiniSwatch } from '../../../../../shared';

const {
  EXTRUSION_WIDTH_STEP,
  LAYER_HEIGHT_MIN,
  LAYER_HEIGHT_STEP,
  SPEED_STEP,
} = fieldSteps;
const { KISSlicer, Slic3r } = slicers;

const Metadata = {
  zOffset: {
    label: 'Print bed offset',
    tags: ['z-offset'],
    fields: [
      {
        name: 'zOffset',
        label: 'Z offset',
        tooltip: tooltips.zOffset,
        tags: ['z-offset'],
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            step: LAYER_HEIGHT_STEP,
          },
        ],
      },
    ],
  },
  speed: {
    label: 'First layer speed',
    tags: [],
    fields: [
      {
        name: 'firstLayerSpeed',
        label: 'First layer speed',
        tooltip: tooltips.firstLayerSpeed,
        tags: ['print speed'],
        variants: [
          {
            units: 'mm/s',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: SPEED_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            units: '%',
            optionLabel: '% of solid layer speed',
            tags: [],
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: 1,
            toBaseUnits: (value, style) =>
              Math.round((value * style.solidLayerSpeed) / 10) / 10,
            fromBaseUnits: (value, style) =>
              Math.round((value / style.solidLayerSpeed) * 100),
          },
        ],
      },
    ],
  },
  sizeCompensation: {
    label: 'Size compensation',
    tags: [],
    fields: [
      {
        name: 'firstLayerSizeCompensation',
        label: 'First layer size compensation',
        tags: ['elephant foot'],
        tooltip: tooltips.firstLayerSizeCompensation,
        slicers: [Slic3r],
        disabled: (style, slicer) => slicer && slicer !== Slic3r,
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            gte: true,
            step: fieldSteps.EXTRUSION_WIDTH_STEP,
          },
        ],
      },
    ],
  },
  skirt: {
    name: 'useBrim',
    label: 'Skirt/brim',
    tags: [],
    tooltip: tooltips.useBrim,
    isChecked: (style) => style.useBrim,
    updateFieldsOnToggle: (checked, updateField) => {
      updateField('useBrim', checked);
    },
    fields: [
      {
        name: 'brimLoops',
        label: 'Number of outlines',
        tags: [],
        tooltip: tooltips.brimLoops,
        disabled: (style) => !style.useBrim,
        variants: [
          {
            type: fieldTypes.number,
            min: 1,
            step: 1,
          },
        ],
      },
      {
        name: 'brimLayers',
        label: 'Number of layers',
        tags: [],
        tooltip: tooltips.brimLayers,
        disabled: (style) => !style.useBrim,
        variants: [
          {
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: 1,
          },
        ],
      },
      {
        name: 'brimGap',
        label: 'Horizontal gap from part',
        tags: ['brim gap'],
        tooltip: tooltips.brimGap,
        disabled: (style) => !style.useBrim,
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            step: EXTRUSION_WIDTH_STEP,
          },
        ],
      },
    ],
  },
  raft: {
    name: 'useRaft',
    label: 'Raft',
    tags: [],
    tooltip: tooltips.useRaft,
    isChecked: (style) => style.useRaft,
    updateFieldsOnToggle: (checked, updateField) => {
      updateField('useRaft', checked);
    },
    fields: [
      {
        name: 'defaultRaftExtruder',
        label: 'Raft extruder',
        tags: [],
        slicers: [KISSlicer],
        display: (style, projectProps) =>
          !projectProps || projectProps.colors.length > 1,
        disabled: (style, slicer) =>
          !style.useRaft || (slicer && slicer !== KISSlicer),
        variants: [
          {
            type: fieldTypes.boxSelection,
            horizontal: true,
            options: (style, projectProps) => {
              const numInputs = projectProps ? projectProps.colors.length : 8;
              return new Array(numInputs).fill(0).map((val, index) => {
                const color = projectProps ? projectProps.colors[index] : null;
                return {
                  label: (
                    <MiniSwatch
                      color={color}
                      label={index + 1}
                      selected={style.defaultRaftExtruder === index}
                    />
                  ),
                  value: index,
                };
              });
            },
          },
        ],
      },
    ],
    subgroups: {
      raft1: {
        fields: [
          {
            name: 'raftZGap',
            label: 'Vertical gap from part',
            tags: ['airgap', 'air gap', 'z gap', 'z-gap', 'zgap'],
            tooltip: tooltips.raftZGap,
            slicers: [KISSlicer],
            disabled: (style, slicer) =>
              !style.useRaft || (slicer && slicer !== KISSlicer),
            variants: [
              {
                units: 'mm',
                type: fieldTypes.number,
                min: 0,
                gte: false,
                step: LAYER_HEIGHT_STEP,
              },
            ],
          },
          {
            name: 'upperRaftSpeed',
            label: 'Top layer speed',
            tags: [],
            tooltip: tooltips.upperRaftSpeed,
            slicers: [KISSlicer],
            disabled: (style, slicer) =>
              !style.useRaft || (slicer && slicer !== KISSlicer),
            variants: [
              {
                units: 'mm/s',
                type: fieldTypes.number,
                min: 0,
                gte: false,
                step: SPEED_STEP,
                toBaseUnits: (value) => value,
                fromBaseUnits: (value) => value,
              },
              {
                units: '%',
                optionLabel: '% of solid layer speed',
                tags: [],
                type: fieldTypes.number,
                min: 0,
                gte: false,
                step: 1,
                toBaseUnits: (value, style) =>
                  Math.round((value * style.solidLayerSpeed) / 10) / 10,
                fromBaseUnits: (value, style) =>
                  Math.round((value / style.solidLayerSpeed) * 100),
              },
            ],
          },
          {
            name: 'upperRaftLayerHeight',
            label: 'Top layer height',
            tags: [],
            tooltip: tooltips.upperRaftLayerHeight,
            slicers: [KISSlicer],
            disabled: (style, slicer) =>
              !style.useRaft || (slicer && slicer !== KISSlicer),
            variants: [
              {
                units: 'mm',
                type: fieldTypes.number,
                min: LAYER_HEIGHT_MIN,
                gte: true,
                step: LAYER_HEIGHT_STEP,
                toBaseUnits: (value) => value,
                fromBaseUnits: (value) => value,
              },
              {
                units: '%',
                optionLabel: '% of layer height',
                type: fieldTypes.number,
                min: 0,
                gte: false,
                step: 1,
                toBaseUnits: (value, style) =>
                  Math.round(value * style.layerHeight) / 100,
                fromBaseUnits: (value, style) =>
                  Math.round((value / style.layerHeight) * 100),
              },
              {
                type: fieldTypes.auto,
                toBaseUnits: (value, style) =>
                  Math.round(1.25 * style.layerHeight * 100) / 100,
                fromBaseUnits: () => 'auto',
              },
            ],
          },
          {
            name: 'upperRaftExtrusionWidth',
            label: 'Top layer extrusion width',
            tags: [],
            tooltip: tooltips.upperRaftExtrusionWidth,
            slicers: [KISSlicer],
            disabled: (style, slicer) =>
              !style.useRaft || (slicer && slicer !== KISSlicer),
            variants: [
              {
                units: 'mm',
                type: fieldTypes.number,
                min: 0,
                gte: false,
                step: EXTRUSION_WIDTH_STEP,
                toBaseUnits: (value) => value,
                fromBaseUnits: (value) => value,
              },
              {
                units: '%',
                optionLabel: '% of extrusion width',
                tags: [],
                type: fieldTypes.number,
                min: 0,
                gte: false,
                step: 1,
                toBaseUnits: (value, style) =>
                  Math.round(value * style.extrusionWidth) / 100,
                fromBaseUnits: (value, style) =>
                  Math.round((value / style.extrusionWidth) * 100),
              },
              {
                type: fieldTypes.auto,
                toBaseUnits: (value, style) =>
                  Math.round(0.9 * style.extrusionWidth * 100) / 100,
                fromBaseUnits: () => 'auto',
              },
            ],
          },
          {
            name: 'upperRaftDensity',
            label: 'Top layer density',
            tags: [],
            tooltip: tooltips.upperRaftDensity,
            slicers: [KISSlicer],
            disabled: (style, slicer) =>
              !style.useRaft || (slicer && slicer !== KISSlicer),
            variants: [
              {
                units: '%',
                type: fieldTypes.number,
                min: 0,
                gte: false,
                max: 100,
                step: 1,
                toBaseUnits: (value) => value,
                fromBaseUnits: (value) => value,
              },
              {
                type: fieldTypes.auto,
                toBaseUnits: () => 66,
                fromBaseUnits: () => 'auto',
              },
            ],
          },
        ],
      },
      raft2: {
        fields: [
          {
            name: 'raftXYInflation',
            label: 'Horizontal inflation',
            tags: [],
            tooltip: tooltips.raftXYInflation,
            disabled: (style) => !style.useRaft,
            variants: [
              {
                units: 'mm',
                type: fieldTypes.number,
                min: 0,
                step: EXTRUSION_WIDTH_STEP,
              },
            ],
          },
          {
            name: 'lowerRaftSpeed',
            label: 'Bottom layer speed',
            tags: [],
            tooltip: tooltips.lowerRaftSpeed,
            slicers: [KISSlicer],
            disabled: (style, slicer) =>
              !style.useRaft || (slicer && slicer !== KISSlicer),
            variants: [
              {
                units: 'mm/s',
                type: fieldTypes.number,
                min: 0,
                gte: false,
                step: SPEED_STEP,
                toBaseUnits: (value) => value,
                fromBaseUnits: (value) => value,
              },
              {
                units: '%',
                optionLabel: '% of solid layer speed',
                tags: [],
                type: fieldTypes.number,
                min: 0,
                gte: false,
                step: 1,
                toBaseUnits: (value, style) =>
                  Math.round((value * style.solidLayerSpeed) / 10) / 10,
                fromBaseUnits: (value, style) =>
                  Math.round((value / style.solidLayerSpeed) * 100),
              },
            ],
          },
          {
            name: 'lowerRaftLayerHeight',
            label: 'Bottom layer height',
            tags: [],
            tooltip: tooltips.lowerRaftLayerHeight,
            slicers: [KISSlicer],
            disabled: (style, slicer) =>
              !style.useRaft || (slicer && slicer !== KISSlicer),
            variants: [
              {
                units: 'mm',
                type: fieldTypes.number,
                min: LAYER_HEIGHT_MIN,
                gte: true,
                step: LAYER_HEIGHT_STEP,
                toBaseUnits: (value) => value,
                fromBaseUnits: (value) => value,
              },
              {
                units: '%',
                optionLabel: '% of layer height',
                tags: [],
                type: fieldTypes.number,
                min: 0,
                gte: false,
                step: 1,
                toBaseUnits: (value, style) =>
                  Math.round(value * style.layerHeight) / 100,
                fromBaseUnits: (value, style) =>
                  Math.round((value / style.layerHeight) * 100),
              },
              {
                type: fieldTypes.auto,
                toBaseUnits: (value, style) =>
                  Math.round(1.5 * style.layerHeight * 100) / 100,
                fromBaseUnits: () => 'auto',
              },
            ],
          },
          {
            name: 'lowerRaftExtrusionWidth',
            label: 'Bottom layer extrusion width',
            tags: [],
            tooltip: tooltips.lowerRaftExtrusionWidth,
            slicers: [KISSlicer],
            disabled: (style, slicer) =>
              !style.useRaft || (slicer && slicer !== KISSlicer),
            variants: [
              {
                units: 'mm',
                type: fieldTypes.number,
                min: 0,
                gte: false,
                step: EXTRUSION_WIDTH_STEP,
                toBaseUnits: (value) => value,
                fromBaseUnits: (value) => value,
              },
              {
                units: '%',
                optionLabel: '% of extrusion width',
                tags: [],
                type: fieldTypes.number,
                min: 0,
                gte: false,
                step: 1,
                toBaseUnits: (value, style) =>
                  Math.round(value * style.extrusionWidth) / 100,
                fromBaseUnits: (value, style) =>
                  Math.round((value / style.extrusionWidth) * 100),
              },
              {
                type: fieldTypes.auto,
                toBaseUnits: (value, style) =>
                  Math.round(1.5 * style.extrusionWidth * 100) / 100,
                fromBaseUnits: () => 'auto',
              },
            ],
          },
          {
            name: 'lowerRaftDensity',
            label: 'Bottom layer density',
            tags: [],
            tooltip: tooltips.lowerRaftDensity,
            disabled: (style) => !style.useRaft,
            variants: [
              {
                units: '%',
                type: fieldTypes.number,
                min: 0,
                gte: false,
                max: 100,
                step: 1,
                toBaseUnits: (value) => value,
                fromBaseUnits: (value) => value,
              },
              {
                type: fieldTypes.auto,
                toBaseUnits: () => 33,
                fromBaseUnits: () => 'auto',
              },
            ],
          },
        ],
      },
      raft3: {
        fields: [
          {
            name: 'useRaftInterfaces',
            label: 'Generate support interfaces above rafts',
            tags: [],
            tooltip: tooltips.useRaftInterfaces,
            slicers: [KISSlicer],
            disabled: (style, slicer) =>
              !(style.useSupport && style.useSupportInterface) ||
              !style.useRaft ||
              (slicer && slicer !== KISSlicer),
            variants: [
              {
                type: fieldTypes.checkbox,
              },
            ],
          },
        ],
      },
    },
  },
};

export default Metadata;

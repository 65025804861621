import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API from '../../canvas-api';
import { handleGenericError } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

export default function* getSharedProject(action) {
  try {
    const { shareId } = action.payload;

    const response = yield call(API, {
      path: `projects/shared/${shareId}`,
      token: null,
    });
    const { sharedProject } = response;

    yield put(actions.getSharedProjectSuccess(sharedProject));

    if (sharedProject.models) {
      const { models, colors } = sharedProject;
      yield put(actions.getSharedProjectModelsRequest(shareId, models, colors));
    }
    if (sharedProject.thumbnail) {
      yield put(actions.getSharedProjectThumbnailRequest(shareId));
    }
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

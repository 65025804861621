import { FormatUtils, SlicerUtils } from '../../../../../utils';

const DISABLED_AXIS = '';

const AXES = ['x', 'y', 'z'];

export const SCALE_STEP_MODEL_MM = 0.001;
export const SCALE_STEP_MODEL_PERCENT = 0.01;

const MIN_TOWER_DIMENSION = 15;

export const OFFSET_MM = 10; // 10 mm
export const OFFSET_PERCENT = 0.1; // 10%

export const getBlankAxisValues = () => ({
  x: DISABLED_AXIS,
  y: DISABLED_AXIS,
  z: DISABLED_AXIS,
});

const areScalesEqual = (selectedModels) => {
  const firstModelScale = { ...selectedModels[0].transforms.scale };
  for (let i = 1; i < selectedModels.length; i++) {
    const thisModelScale = { ...selectedModels[i].transforms.scale };
    const isEqual = AXES.every(
      (axis) => thisModelScale[axis] === firstModelScale[axis]
    );
    if (!isEqual) return false;
  }
  return true;
};

export const computeAxisValues = (selectedModels, selectedTower) => {
  let scales = getBlankAxisValues(); // 100% === 1
  let dimensions = getBlankAxisValues(); // 1 mm === 1

  if (
    (selectedTower && selectedModels.length > 0) ||
    (!selectedTower && selectedModels.length === 0)
  ) {
    // tower and at least one model are both selected, OR nothing is selected
    // - disable all three fields and display no value
    return { scales, dimensions };
  }

  /*
   * determine the value to display in text fields
   * - if all selected models have the same transform value display it in the corresponding field
   * - if not, display a field with no value
   */

  if (selectedTower) {
    // only tower is selected
    dimensions = {
      x: FormatUtils.roundTo(selectedTower.size.x, 2),
      y: FormatUtils.roundTo(selectedTower.size.y, 2),
      z: DISABLED_AXIS, // disable Z axis for tower
    };
  } else {
    // only models are selected
    // - if all selected models have the same scale values, display them in the corresponding fields
    // - otherwise, display fields with no values
    if (areScalesEqual(selectedModels)) {
      scales = selectedModels[0].transforms.scale;
    }
    const combinedBbox = SlicerUtils.getCombinedBoundingBox(selectedModels);
    AXES.forEach((axis) => {
      const dimension = combinedBbox.max[axis] - combinedBbox.min[axis];
      dimensions[axis] = FormatUtils.roundTo(dimension, 3);
    });
  }

  return { scales, dimensions };
};

export const isOffsetDisabled = (
  axis,
  selectedModels,
  selectedTower,
  percentScale,
  currentScales, // %
  currentDimensions // mm
) => {
  const disabled = {
    minus: false,
    plus: false,
  };

  if (selectedTower) {
    // tower selected
    if (axis === 'z' || !percentScale) {
      // always disable Z scale buttons for tower
      // always disable all scale buttons in mm mode
      disabled.minus = true;
      disabled.plus = true;
    } else {
      // disable X/Y scale buttons if they would result in invalid tower dimensions
      const minAxisSize = MIN_TOWER_DIMENSION / (1 - OFFSET_PERCENT);

      if (selectedTower.size[axis] <= minAxisSize) {
        disabled.minus = true;
      }
      const otherTowerAxis = axis === 'x' ? 'y' : 'x';
      if (selectedTower.size[otherTowerAxis] <= minAxisSize) {
        disabled.plus = true;
      }
    }
  } else if (selectedModels.length > 0) {
    // model(s) selected
    // disable X/Y/Z scale buttons if they would scale the model(s) down to, or past, 0
    if (percentScale) {
      const currentScale = Math.abs(currentScales[axis]);
      if (currentScale < OFFSET_PERCENT + SCALE_STEP_MODEL_PERCENT) {
        disabled.minus = true;
      }
    } else {
      const currentDimension = Math.abs(currentDimensions[axis]);
      if (currentDimension < OFFSET_MM + SCALE_STEP_MODEL_MM) {
        disabled.minus = true;
      }
    }
  } else {
    // nothing selected -- disable all buttons
    disabled.minus = true;
    disabled.plus = true;
  }
  return disabled;
};

export const clampGizmoTowerScale = (transitionTower, scale) => {
  let updatedX = transitionTower.size.x * scale.x;
  let updatedY = transitionTower.size.y * scale.y;

  // ensure the user does not use the gizmo to make the tower too small in any dimension
  if (updatedX < MIN_TOWER_DIMENSION || updatedY < MIN_TOWER_DIMENSION) {
    const currentArea = transitionTower.size.x * transitionTower.size.y;
    if (updatedX < MIN_TOWER_DIMENSION) {
      updatedX = MIN_TOWER_DIMENSION;
      updatedY = currentArea / MIN_TOWER_DIMENSION;
    } else {
      updatedY = MIN_TOWER_DIMENSION;
      updatedX = currentArea / MIN_TOWER_DIMENSION;
    }
  }

  return {
    x: updatedX,
    y: updatedY,
    z: transitionTower.size.z,
  };
};

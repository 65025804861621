import React, { Component } from 'react';
import _ from 'lodash';

import {
  AddOverrideWrapper,
  ContainerCheckbox,
  CheckboxLabelStylesWrapper,
  CheckboxWrapper,
  FieldRemoveButtonWrapper,
  FieldRow,
  FieldWarningWrapper,
  OverrideDropdownWrapper,
  OverrideFields,
  OverridePlaceholderStylesWrapper,
  OverrideSettings,
  TextWrapper,
} from './configureMaterial.styles';

import {
  ActionButton,
  Body1,
  Button,
  Caption,
  Checkbox,
  Dropdown,
  DropdownButton,
  Form,
  Input,
  Link,
  MultiVariantInput,
  SequenceEditor,
  SettingsModal,
  SideBar,
} from '../../../shared';

import { Icons } from '../../../themes';

import Fields, { materialStyleFieldNames } from './fields.metadata';
import { defaultMaterials } from '../../../reducers/slicer/initialState';
import Routes from '../../../router/routes';
import { fieldTypes } from '../../../constants';
import { FormatUtils, InterfaceUtils, ValidateUtils } from '../../../utils';
import { parse as parseScript } from '../../../utils/printerscript';

class ConfigureMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusField: null,
      baseSettings: {},
      overrideSettings: {},
      errors: {},
      saveButtonDisabled: true,
      activeTabIndex: 0, // state is used to switch between SideBar tabs
      isMobile: false, // dropdown options dropUp on mobile
    };
    this.setMobileView = this.setMobileView.bind(this);
  }

  componentDidMount() {
    const { materials, materialId } = this.props;
    let material = defaultMaterials['0'];
    if (materialId) {
      material = materials[materialId];
      material.style = {
        ...defaultMaterials['0'].style,
        ...material.style,
      };
    } else {
      this.setState({ saveButtonDisabled: false });
    }
    this.setMobileView();
    window.addEventListener('resize', this.setMobileView);

    this.setState({
      baseSettings: {
        id: material.id,
        name: material.name,
        diameter: material.diameter,
        type: material.type,
        materialChangeSequence: material.materialChangeSequence,
      },
      errors: {
        name: false,
        diameter: false,
        type: false,
      },
      overrideSettings: material.style,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (_.isEmpty(prevState.baseSettings || prevState.overrideSettings)) return;

    if (
      (prevState.baseSettings !== this.state.baseSettings ||
        prevState.overrideSettings !== this.state.overrideSettings) &&
      this.state.saveButtonDisabled
    ) {
      this.setState({ saveButtonDisabled: false });
    }
    const createSuccess =
      !prevProps.createMaterialSuccess && this.props.createMaterialSuccess;
    const editSuccess =
      !prevProps.editMaterialSuccess && this.props.editMaterialSuccess;
    if (createSuccess || editSuccess) {
      this.props.history.push(Routes.toManageMaterials());
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setMobileView);
  }

  setMobileView() {
    this.setState({ isMobile: window.innerWidth < 640 });
  }

  focusField(fieldName) {
    this.setState({ focusField: fieldName }, () => {
      this.setState({ focusField: null });
    });
  }

  onChangeSuccess(fieldName, value) {
    const newState = {
      errors: {
        ...this.state.errors,
        [fieldName]: false,
      },
    };
    if (_.has(this.state.baseSettings, fieldName)) {
      newState.baseSettings = {
        ...this.state.baseSettings,
        [fieldName]: value,
      };
    } else {
      newState.overrideSettings = {
        ...this.state.overrideSettings,
        [fieldName]: value,
      };
    }
    this.setState(newState);
  }

  onChangeFailure(fieldName) {
    this.setState({
      errors: {
        ...this.state.errors,
        [fieldName]: true,
      },
    });
  }

  removeField(field) {
    const usageFlag = this.composeUsageFlag(field.name);
    this.setState({
      overrideSettings: {
        ...this.state.overrideSettings,
        [usageFlag]: false,
      },
    });
  }

  getValue(field) {
    const { baseSettings, overrideSettings } = this.state;
    return field.value({ ...baseSettings, ...overrideSettings });
  }

  isDisabled(field) {
    if (typeof field.disabled !== 'function') return false;
    const { baseSettings, overrideSettings } = this.state;
    return field.disabled({ ...baseSettings, ...overrideSettings });
  }

  addOverride(name) {
    const usageFlag = this.composeUsageFlag(name);
    this.setState({
      overrideSettings: {
        ...this.state.overrideSettings,
        [usageFlag]: true,
      },
      showOverrideOptions: false,
    });
  }

  composeUsageFlag(fieldName) {
    return `use${fieldName.charAt(0).toUpperCase()}${fieldName.slice(1)}`;
  }

  detectUsage(fieldName) {
    const usageFlag = this.composeUsageFlag(fieldName);
    return this.state.overrideSettings[usageFlag] === true;
  }

  formatMaterial() {
    const { baseSettings, overrideSettings } = this.state;
    let style = {};
    _.forEach(materialStyleFieldNames, (field) => {
      const [usageFlag, fieldName] = field;
      style[usageFlag] = overrideSettings[usageFlag];
      style[fieldName] = overrideSettings[fieldName];
    });
    if (overrideSettings.enableFanAtLayer === -1) {
      style = _.omitBy(style, (val, key) =>
        key.toLowerCase().includes('fanspeed')
      );
    }
    return { ...baseSettings, style };
  }

  getNextScriptError() {
    // too expensive to parse PrinterScript on change,
    // so we only check for syntax errors on save
    // (CodeMirror syntax highlighting is not to be trusted!)
    const scriptFieldNames = _.map(
      Fields.sequenceFields.fields,
      (field) => field.name
    );
    return _.reduce(
      scriptFieldNames,
      (scriptError, fieldName) => {
        if (scriptError) return scriptError;
        const value = this.state.baseSettings[fieldName];
        if (value && value.startsWith('@printerscript 1.0')) {
          let script = `${value}\n`;
          script = script.slice(script.indexOf('\n'));
          try {
            parseScript(script);
          } catch (error) {
            return {
              error: typeof error === 'string' ? new Error(error) : error,
              fieldName,
            };
          }
        }
        return null;
      },
      null
    );
  }

  handleSave() {
    const allFields = [...Fields.baseFields, ...Fields.overrideFields];
    const focusField = ValidateUtils.getNextFormErrorName(
      allFields,
      this.state.errors
    );
    if (focusField) {
      this.focusField(focusField);
      return;
    }

    const scriptError = this.getNextScriptError();
    if (scriptError) {
      const { error, fieldName } = scriptError;
      const field = _.find(
        Fields.sequenceFields.fields,
        (meta) => meta.name === fieldName
      );
      const label = field.label.toLowerCase();
      const syntaxError = error.message
        .slice(error.message.indexOf(':') + 1)
        .trim();
      const message = `Syntax error in ${label} sequence: ${syntaxError}`;
      InterfaceUtils.emitToast('error', message);
      return;
    }

    const { match, materialId } = this.props;
    const material = this.formatMaterial();

    if (materialId || (match && match.params.materialId)) {
      this.props.editMaterial(material);
    } else {
      this.props.createMaterial(material);
    }
    this.props.onClickClose();
  }

  renderOverrideSettings() {
    const description =
      'Style overrides allows a specified print setting to be used when a material is selected. ';
    const url =
      'https://support.mosaicmfg.com/Guide/How+to+Add+Style+Overrides+to+Material+Profiles/142';
    const fields = _.filter(Fields.overrideFields, (field) =>
      this.detectUsage(field.name)
    );
    return (
      <OverrideSettings>
        <TextWrapper>
          <Body1 noSpacing>
            {description}
            <Link external href={url} greenDefault>
              Learn more
            </Link>
          </Body1>
        </TextWrapper>
        <OverrideDropdownWrapper>
          {this.renderAddOverrideDropdown()}
        </OverrideDropdownWrapper>
        <OverrideFields>
          {this.renderFields(fields, true)}
          {this.renderOverridePlaceholder(fields.length)}
        </OverrideFields>
      </OverrideSettings>
    );
  }

  renderFields(fields, overrides = false) {
    return _.map(fields, (field, index) =>
      this.renderField(field, index, overrides)
    );
  }

  renderField(field, index, overrides) {
    // show warnings for fanSpeed and perimeterFanSpeed
    let warning = '';
    if (this.isDisabled(field)) {
      warning = "Set 'Start fan at layer' ≥ 0 to enable field";
    }
    return (
      <FieldRow key={index} overrides={overrides}>
        {this.renderFieldContent(field)}
        {overrides && this.renderFieldRemoveButton(field)}
        {warning && this.renderFieldWarning(warning)}
      </FieldRow>
    );
  }

  renderFieldContent(field) {
    if (!field || !field.variants || field.variants.length < 1) return null;
    if (field.variants.length > 1) {
      return this.renderMultiVariantFieldContent(field);
    }
    const variant = field.variants[0];
    switch (variant.type) {
      case fieldTypes.text:
        return this.renderTextField(field);
      case fieldTypes.number:
        return this.renderNumberField(field, variant);
      case fieldTypes.dropdown:
        return this.renderDropdown(field);
      case fieldTypes.checkbox:
        return this.renderCheckbox(field);
      default:
        return null;
    }
  }

  renderFieldRemoveButton(field) {
    return (
      <FieldRemoveButtonWrapper>
        <ActionButton
          clean
          width='1.5rem'
          icon={Icons.basic.x}
          onClick={() => this.removeField(field)}
        />
      </FieldRemoveButtonWrapper>
    );
  }

  renderOverridePlaceholder(length) {
    if (length > 0) return null;
    return (
      <OverridePlaceholderStylesWrapper>
        <Body1 grey>You do not have any style overrides yet.</Body1>
      </OverridePlaceholderStylesWrapper>
    );
  }

  renderFieldWarning(warning) {
    return (
      <FieldWarningWrapper>
        <Caption>{warning}</Caption>
      </FieldWarningWrapper>
    );
  }

  renderMultiVariantFieldContent(field) {
    const fieldValue = this.getValue(field);
    const activeVariant = _.findIndex(
      field.variants,
      (v) => v.units === fieldValue.units
    );
    let { disabled } = field;
    if (typeof field.disabled === 'function') {
      disabled = field.disabled(this.props);
    }
    return (
      <MultiVariantInput
        label={field.label}
        variants={field.variants}
        style={this.props}
        activeVariant={activeVariant}
        value={fieldValue.value}
        disabled={disabled}
        forceFocus={this.state.focusField === field.name}
        onChangeSuccess={(value) => this.onChangeSuccess(field.name, value)}
        onChangeFailure={() =>
          this.onChangeFailure(field.name)
        }></MultiVariantInput>
    );
  }

  renderTextField(field) {
    return (
      <Input
        type='text'
        label={field.label}
        value={this.getValue(field)}
        forceFocus={this.state.focusField === field.name}
        onChangeSuccess={(value) => this.onChangeSuccess(field.name, value)}
        onChangeFailure={() => this.onChangeFailure(field.name)}
      />
    );
  }

  renderNumberField(field, variant) {
    const { min, gte, max, lte, step } = variant;
    let { units } = variant;
    if (units) {
      units = FormatUtils.formatUnits(units);
    }
    return (
      <Input
        type='number'
        label={field.label}
        min={min}
        gte={gte}
        max={max}
        lte={lte}
        step={step}
        units={units}
        value={this.getValue(field)}
        disabled={this.isDisabled(field)}
        forceFocus={this.state.focusField === field.name}
        onChangeSuccess={(value) => this.onChangeSuccess(field.name, value)}
        onChangeFailure={() => this.onChangeFailure(field.name)}
      />
    );
  }

  renderDropdown(field) {
    return (
      <Dropdown
        label={field.label}
        options={field.variants[0].options}
        value={this.getValue(field)}
        onChange={(value) => this.onChangeSuccess(field.name, value)}
        dropUp={this.state.isMobile}
      />
    );
  }

  renderCheckbox(field) {
    return (
      <ContainerCheckbox>
        {this.renderCheckboxLabel(field)}
        <CheckboxWrapper>
          <Checkbox
            label={field.label}
            value={this.getValue(field)}
            disabled={this.isDisabled(field)}
            onChange={() =>
              this.onChangeSuccess(field.name, !this.getValue(field))
            }
          />
        </CheckboxWrapper>
      </ContainerCheckbox>
    );
  }

  renderCheckboxLabel(field) {
    if (!field.label) return null;
    return (
      <CheckboxLabelStylesWrapper>
        <Caption grey>Cooling fan</Caption>
      </CheckboxLabelStylesWrapper>
    );
  }

  renderAddOverrideDropdown() {
    const remaining = _.filter(
      Fields.overrideFields,
      (field) => !this.detectUsage(field.name)
    );
    const disabled = _.isEmpty(remaining);
    const options = _.map(remaining, (field) => ({
      label: field.label,
      value: field.name,
    }));
    return (
      <AddOverrideWrapper>
        <DropdownButton
          options={options}
          disabled={disabled}
          customLabel='Add new override'
          descriptionIcon={Icons.basic.plus}
          onChange={(fieldName) => this.addOverride(fieldName)}
        />
      </AddOverrideWrapper>
    );
  }

  renderButtons() {
    return (
      <>
        <Button
          expand
          onClick={(e) => {
            e.stopPropagation();
            this.props.onClickClose();
          }}>
          Cancel
        </Button>
        <Button
          expand
          primary
          disabled={this.state.saveButtonDisabled}
          onClick={() => this.handleSave()}>
          Save material
        </Button>
      </>
    );
  }

  renderHeader() {
    const { match, materialId } = this.props;
    let label = 'New Material';
    if (materialId || (match && match.params.materialId)) {
      label = `Edit ${this.state.baseSettings.name}`;
    }
    return label;
  }

  renderScripts() {
    if (_.isEmpty(this.state.baseSettings)) return null;
    return (
      <SequenceEditor
        {...this.state.baseSettings}
        sequences={Fields.sequenceFields}
        updateField={(name, value) => this.onChangeSuccess(name, value)}
      />
    );
  }

  renderFormContent(fields, index) {
    if (!fields) return null;
    const { content } = fields[index];
    const { baseFields } = Fields;
    switch (content) {
      case 'material-name-input':
        return this.renderTextField(baseFields[0]);
      case 'diameter-input':
        return this.renderNumberField(baseFields[1], baseFields[1].variants[0]);
      case 'filament-type-dropdown':
        return this.renderDropdown(baseFields[2]);
      default:
        return null;
    }
  }

  renderFormItems(fields) {
    const formField = _.map(fields, (item, index) => {
      return {
        ...item,
        content: this.renderFormContent(fields, index),
      };
    });
    return formField;
  }

  renderGeneralSettings() {
    return <Form items={this.renderFormItems(Fields.formGeneralFields)} />;
  }

  renderSidebarContent(fields, index) {
    const { text } = fields[index];
    switch (text) {
      case 'General':
        return this.renderGeneralSettings(fields, index);
      case 'Style overrides':
        return this.renderOverrideSettings();
      case 'Sequences':
        return this.renderScripts();
      default:
        return null;
    }
  }

  setActiveTabIndex(index) {
    return this.setState({ activeTabIndex: index });
  }

  renderSidebar() {
    return (
      <SideBar
        items={Fields.sidebarFields}
        activeTab={this.state.activeTabIndex}
        setActiveTab={(index) => this.setActiveTabIndex(index)}>
        {this.renderSidebarContent(
          Fields.sidebarFields,
          this.state.activeTabIndex
        )}
      </SideBar>
    );
  }

  renderConfigureMaterialModal() {
    return (
      <>
        {InterfaceUtils.getLoadingSpinner(this.props)}
        <SettingsModal
          headerTitle={this.renderHeader()}
          headerButtons={this.renderButtons()}
          sideBarContent={this.renderSidebar()}
        />
      </>
    );
  }

  render() {
    return this.renderConfigureMaterialModal();
  }
}

export default ConfigureMaterial;

import Routes from '../../router/routes';

export const MenuMeta = [
  {
    title: 'Reference Manual',
    description: 'Documentation of the features and syntax of PrinterScript',
    route: Routes.toPrinterScriptDocs(),
  },
  {
    title: 'Canvas Sequences and Variables',
    description: 'Explanation of Canvas sequences and predefined variables',
    route: Routes.toPrinterScriptVariables(),
  },
  {
    title: 'Playground',
    description: 'An interactive editor for experimenting and testing scripts',
    route: Routes.toPrinterScriptPlayground(),
  },
  {
    title: 'Legacy Scripts',
    description: 'Documentation of non-PrinterScript printer sequences',
    route: Routes.toPrinterScriptLegacy(),
  },
];

import _ from 'lodash';
import { all, call } from 'redux-saga/effects';

import API, { methods } from '../../../canvas-api';
import { uploadAllPartsToS3 } from '../../../s3';

function* updateTexture(projectId, modelId, uvBuffer, imageBuffer) {
  // initialize the upload
  const initResponse = yield call(API, {
    method: methods.PUT,
    path: `projects/${projectId}/models/${modelId}/texture`,
    body: {
      uv: {
        contentLength: uvBuffer.byteLength,
      },
      image: {
        contentLength: imageBuffer.byteLength,
      },
    },
  });
  if (initResponse === null) return null;

  // upload image and uv files in parallel
  const [uvETags, imageETags] = yield all([
    call(uploadAllPartsToS3, initResponse.uv.urls, uvBuffer),
    call(uploadAllPartsToS3, initResponse.image.urls, imageBuffer),
  ]);

  // finalize upload
  return yield call(API, {
    method: methods.PUT,
    path: `projects/${projectId}/models/${modelId}/texture/complete`,
    body: {
      uv: {
        uploadId: initResponse.uv.uploadId,
        parts: _.map(uvETags, (eTag, idx) => ({
          partNumber: initResponse.uv.urls[idx].partNumber,
          eTag,
        })),
      },
      image: {
        uploadId: initResponse.image.uploadId,
        parts: _.map(imageETags, (eTag, idx) => ({
          partNumber: initResponse.image.urls[idx].partNumber,
          eTag,
        })),
      },
    },
  });
}

export function* deleteTexture(projectId, modelId) {
  return yield call(API, {
    method: methods.DELETE,
    path: `projects/${projectId}/models/${modelId}/texture`,
  });
}

export function* updateModelTexture(projectId, model, uvBuffer, imageBuffer) {
  const modelId = model.id;

  if (uvBuffer && imageBuffer) {
    // texture data is desired -- PUT
    return yield call(updateTexture, projectId, modelId, uvBuffer, imageBuffer);
  }
  if (model.textured) {
    // texture data is not desired -- DELETE
    return yield call(deleteTexture, projectId, modelId);
  }
  return {};
}

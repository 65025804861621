import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { ReactSVG } from 'react-svg';
import { Container } from './logo.styles';

const size = {
  WIDTH: 170,
  HEIGHT: 32,
};

class Logo extends Component {
  static size = size;

  static defaultProps = {
    width: size.WIDTH,
    height: size.HEIGHT,
    onClick: () => {},
  };

  render() {
    return (
      <Container
        widthPx={this.props.width}
        heightPx={this.props.height}
        onClick={this.props.onClick}>
        <ReactSVG
          src={this.props.theme.images.canvasLogoFull}
          wrapper='svg'></ReactSVG>
      </Container>
    );
  }
}

export default withTheme(Logo);

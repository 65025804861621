const initialState = {
  notifications: {},
  status: {
    getNotificationsPending: false,
    getNotificationsSuccess: false,
    updateNotificationPending: false,
    updateNotificationSuccess: false,
    deleteNotificationPending: false,
    deleteNotificationSuccess: false,
  },
};

export default initialState;

const Breakpoints = {
  extraSmallWidth: '30em',
  smallWidth: '40em',
  mediumWidth: '54em',
  largeWidth: '72em',
  extraLargeWidth: '96em',

  extraSmallHeight: '30em',
  smallHeight: '40em',
  mediumHeight: '54em',
  largeHeight: '72em',
};

export default Breakpoints;

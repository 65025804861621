import 'regenerator-runtime/runtime';
import { call, put, all } from 'redux-saga/effects';
import _ from 'lodash';

import API, {
  methods,
  handleConflictResponses,
  handleBatchCallErrorResponses,
} from '../canvas-api';
import { handleGenericError } from '../common';
import { actions } from '../../reducers/printers/printers';

function* deletePrinterCall(printerId) {
  try {
    const response = yield call(API, {
      method: methods.DELETE,
      path: `printers/${printerId}`,
    });
    if (response === null) return null;
    return { status: 200, deletedId: printerId };
  } catch (error) {
    return error;
  }
}

export default function* deletePrinters(action) {
  try {
    const { printerIds } = action.payload;
    const responses = yield all(
      _.map(printerIds, (printerId) => call(deletePrinterCall, printerId))
    );

    if (_.some(responses, (response) => response === null)) return;

    const deleteResponses = _.filter(
      responses,
      (response) => response.status === 200
    );
    const deletedIds = _.map(deleteResponses, (response) => response.deletedId);

    yield put(actions.deletePrintersSuccess(deletedIds));

    handleConflictResponses(responses);

    handleBatchCallErrorResponses(responses);
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import FormatUtils from '../format/format';

export const mmPerMin2mmPerS = (value) => FormatUtils.roundTo(value / 60, 1);

export const parseXml = (input) => {
  const parser = new DOMParser();
  return parser.parseFromString(input, 'text/xml');
};

export const parseIni = (input) => {
  const contents = {};
  const lines = input.split(/\r|\n|\r\n/);
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    if (line.trim().length > 0) {
      const [key, value] = line.split(' = ');
      contents[key] = value;
    }
  }
  return contents;
};

import * as THREE from 'three';
import _ from 'lodash';

import { SHADER_CHUNKS } from '../faces/shaders';

/* eslint-disable no-param-reassign, no-loop-func, no-continue, no-bitwise */
const Utils = {
  composeAtlasPatches: (faces, constants) => {
    const {
      padding,
      resolution,
      maxTextureSize,
      // maxTextures,
    } = constants;

    let atlasTotalArea = 0;
    let atlasIndex = 0;
    const patchesByAtlas = [];
    const areaByAtlas = new Map();
    patchesByAtlas[atlasIndex] = [];

    const flatPosition = new THREE.Vector3();
    const targetVector = new THREE.Vector3(0, 0, 1);
    const rotationQuat = new THREE.Quaternion();

    // TODO: disable compact packing until Pizza is ready to accomodate same logic
    // const lengthVector = new THREE.Vector2();
    // const edgeVector = new THREE.Vector2();

    for (let i = 0; i < faces.length; i++) {
      let xMax = Number.NEGATIVE_INFINITY;
      let xMin = Number.POSITIVE_INFINITY;
      let yMax = Number.NEGATIVE_INFINITY;
      let yMin = Number.POSITIVE_INFINITY;
      const currentFace = faces[i];
      const projectedCoords = [];

      rotationQuat.setFromUnitVectors(faces[i].normal, targetVector);

      const verts = [currentFace.a, currentFace.b, currentFace.c];

      let currentVert;
      for (let k = 0; k < verts.length; k++) {
        currentVert = verts[k];
        flatPosition.copy(currentVert).applyQuaternion(rotationQuat);
        projectedCoords.push({
          x: flatPosition.x,
          y: flatPosition.y,
        });
      }

      // scale to correct resolution
      for (let m = 0; m < projectedCoords.length; m++) {
        projectedCoords[m].x *= resolution;
        projectedCoords[m].y *= resolution;
      }

      // TODO: disable compact packing until Pizza is ready to accomodate same logic
      // // find longest edge
      // let longestLength = 0;
      // let currentLength;

      // // AB
      // currentLength = lengthVector
      //   .set(projectedCoords[0].x, projectedCoords[0].y)
      //   .distanceToSquared(projectedCoords[1]);
      // if (currentLength >= longestLength) {
      //   longestLength = currentLength;
      //   edgeVector.set(
      //     projectedCoords[1].x - projectedCoords[0].x,
      //     projectedCoords[1].y - projectedCoords[0].y,
      //   );
      // }

      // // BC
      // currentLength = lengthVector
      //   .set(projectedCoords[1].x, projectedCoords[1].y)
      //   .distanceToSquared(projectedCoords[2]);
      // if (currentLength >= longestLength) {
      //   longestLength = currentLength;
      //   edgeVector.set(
      //     projectedCoords[2].x - projectedCoords[1].x,
      //     projectedCoords[2].y - projectedCoords[1].y,
      //   );
      // }

      // // CA
      // currentLength = lengthVector
      //   .set(projectedCoords[2].x, projectedCoords[2].y)
      //   .distanceToSquared(projectedCoords[0]);
      // if (currentLength >= longestLength) {
      //   longestLength = currentLength;
      //   edgeVector.set(
      //     projectedCoords[0].x - projectedCoords[2].x,
      //     projectedCoords[0].y - projectedCoords[2].y,
      //   );
      // }

      // // how much is that edge offset from positive y-axis?
      // let angleToY = (Math.PI / 2) - Math.atan2(edgeVector.y, edgeVector.x);
      // if (angleToY < 0) angleToY += 2 * Math.PI;

      // // align that edge vertically
      // const pivot = projectedCoords[0];
      // for (let m = 0; m < projectedCoords.length; m++) {
      //   const c = Math.cos(angleToY);
      //   const s = Math.sin(angleToY);

      //   const x = projectedCoords[m].x - pivot.x;
      //   const y = projectedCoords[m].y - pivot.y;

      //   projectedCoords[m].x = x * c - y * s + pivot.x;
      //   projectedCoords[m].y = x * s + y * c + pivot.y;
      // }

      // update side min and max values
      xMin = Math.min(
        xMin,
        projectedCoords[0].x,
        projectedCoords[1].x,
        projectedCoords[2].x
      );
      xMax = Math.max(
        xMax,
        projectedCoords[0].x,
        projectedCoords[1].x,
        projectedCoords[2].x
      );
      yMin = Math.min(
        yMin,
        projectedCoords[0].y,
        projectedCoords[1].y,
        projectedCoords[2].y
      );
      yMax = Math.max(
        yMax,
        projectedCoords[0].y,
        projectedCoords[1].y,
        projectedCoords[2].y
      );

      // add padding on all sides
      const patchWidth = Math.round(xMax) - Math.round(xMin) + 2 * padding;
      const patchHeight = Math.round(yMax) - Math.round(yMin) + 2 * padding;

      for (let n = 0; n < projectedCoords.length; n++) {
        // normalize by subtracting xy mins
        projectedCoords[n].x -= Math.round(xMin);
        projectedCoords[n].y -= Math.round(yMin);

        // offset x and y pixels by padding size
        projectedCoords[n].x += padding;
        projectedCoords[n].y += padding;
      }

      const patchUvs = [
        {
          u: projectedCoords[0].x / patchWidth,
          v: projectedCoords[0].y / patchHeight,
        },
        {
          u: projectedCoords[1].x / patchWidth,
          v: projectedCoords[1].y / patchHeight,
        },
        {
          u: projectedCoords[2].x / patchWidth,
          v: projectedCoords[2].y / patchHeight,
        },
      ];

      const estimatedSideLength = Math.floor(
        Math.sqrt(atlasTotalArea + patchWidth * patchHeight) * 1.2
      );

      if (estimatedSideLength >= maxTextureSize) {
        // collect patches in new atlas
        atlasIndex++;
        patchesByAtlas[atlasIndex] = [];
        atlasTotalArea = 0;
      }

      // add to list of patches
      patchesByAtlas[atlasIndex].push({
        patchWidth,
        patchHeight,
        patchIndex: i,
        patchUvs,
      });

      // record atlasIndex for face
      faces[i].atlas = atlasIndex;

      // add patch area to total atlas area
      atlasTotalArea += patchWidth * patchHeight;
      areaByAtlas.set(atlasIndex, atlasTotalArea);
    }

    return {
      patchesByAtlas,
      areaByAtlas,
    };
  },
  computeBoundsRLE: (imageTempBuf32, imageWidth, bounds, userColors) => {
    // make color map
    const colorUint32Map = new Map();
    // set current model's extruder index for empty color
    colorUint32Map.set(0, -1);
    // set extruder index for each color
    for (let i = 0; i < userColors.length; i++) {
      const [r, g, b] = userColors[i];
      // eslint-disable-next-line no-bitwise
      const uint32 = ((255 << 24) | (b << 16) | (g << 8) | r) >>> 0;
      colorUint32Map.set(uint32, i);
    }

    let currentColorIndex = -1;
    let currentRunLength = 0;
    const extrudersUsed = [];
    const { xMin, xMax, yMin, yMax } = bounds;
    const rle = [];

    const finishRun = () => {
      if (currentRunLength === 0) return;
      rle.push(currentRunLength, currentColorIndex);
      if (
        currentColorIndex >= 0 &&
        !extrudersUsed.includes(currentColorIndex)
      ) {
        // mark this color as used
        extrudersUsed.push(currentColorIndex);
      }
    };

    const calculateRLE = (colorIndex) => {
      if (colorIndex === currentColorIndex) {
        // same color -- increment the current run length
        currentRunLength++;
      } else if (currentRunLength > 0) {
        // new color -- end the current run, then start a new one
        finishRun();
        // begin the next run
        currentColorIndex = colorIndex;
        currentRunLength = 1;
      } else {
        // new color and zero run length
        // (this should only run for the first pixel)
        currentColorIndex = colorIndex;
        currentRunLength++;
      }
    };

    let pixelIndex;
    let extruderIndex;
    for (let y = yMin; y < yMax; y++) {
      for (let x = xMin; x < xMax; x++) {
        pixelIndex = y * imageWidth + x;
        // compute RLE for pixel

        // query existing color map to find extruder index for this pixel
        extruderIndex = colorUint32Map.get(imageTempBuf32[pixelIndex]);

        if (extruderIndex === undefined) {
          // pixel's color is not recognized - maybe due to shader rounding error?
          // try to find nearest color instead

          const unknownColorUint32 = imageTempBuf32[pixelIndex];
          const unknowColorVec = new THREE.Vector3(
            (unknownColorUint32 & 255) / 255, // r
            ((unknownColorUint32 >> 8) & 255) / 255, // g
            ((unknownColorUint32 >> 16) & 255) / 255 // b
          );
          const currentDistanceVec = new THREE.Vector3();

          let closestDistance = Number.POSITIVE_INFINITY;
          let closestColorIndex = 0;

          userColors.forEach((userColor, userColorIndex) => {
            const [r, g, b] = userColor;
            const userColorVec = new THREE.Vector3(r / 255, g / 255, b / 255);
            currentDistanceVec.subVectors(unknowColorVec, userColorVec);
            if (currentDistanceVec.length() < closestDistance) {
              closestColorIndex = userColorIndex;
              closestDistance = currentDistanceVec.length();
            }
          });

          // add this new unrecognized color to the existing color map
          // this color will now be recognized in subsequent encounters
          colorUint32Map.set(unknownColorUint32, closestColorIndex);

          // set the current pixel's extruder index to the closest color index found
          extruderIndex = closestColorIndex;
        }

        calculateRLE(extruderIndex);
      }
    }
    finishRun();

    return { rle, extrudersUsed };
  },
  writeUVsAsBinary: (uvAttr, groups) => {
    const { count, array } = uvAttr;
    const numFaces = count / 3;
    const versionNumber = 0;
    const bufferLength = 1 + 4 + 4 * 7 * numFaces;
    const arrayBuffer = new ArrayBuffer(bufferLength);
    const output = new DataView(arrayBuffer);

    let offset = 0;
    // set version number
    output.setUint8(offset, versionNumber, true);
    offset += 1;

    // set num of tris
    output.setUint32(offset, numFaces, true);
    offset += 4;

    let faceIndex;
    for (let i = 0; i < array.length; i += 6) {
      faceIndex = Math.floor(i / 6);
      // set image index
      output.setUint32(offset, groups[faceIndex].materialIndex, true);
      offset += 4;

      // set vertex 1 uv
      output.setFloat32(offset, array[i + 0], true);
      offset += 4;
      output.setFloat32(offset, 1 - array[i + 1], true);
      offset += 4;

      // set vertex 2 uv
      output.setFloat32(offset, array[i + 2], true);
      offset += 4;
      output.setFloat32(offset, 1 - array[i + 3], true);
      offset += 4;

      // set vertex 3 uv
      output.setFloat32(offset, array[i + 4], true);
      offset += 4;
      output.setFloat32(offset, 1 - array[i + 5], true);
      offset += 4;
    }
    return output.buffer;
  },
  writeAtlasAsBinary: (atlasImages, projectColors) => {
    const rlePerImage = new Map();
    const versionNumber = 0;

    let headerBytesPerImage = 0;
    let dataBytesPerImage = 0;
    let image;
    let imageBuf32;
    let imageBounds;
    let imageRLE;
    let imageExtrudersUsed;

    const totalExtrudersUsed = [];
    for (let i = 0; i < atlasImages.length; i++) {
      image = atlasImages[i];

      // get uint32 view of atlas
      imageBuf32 = new Uint32Array(image.data.buffer);

      // define bounds to compose rle for i.e., the entire atlas image
      imageBounds = {
        xMin: 0,
        xMax: image.width,
        yMin: 0,
        yMax: image.height,
      };

      // compose rle
      ({ rle: imageRLE, extrudersUsed: imageExtrudersUsed } =
        Utils.computeBoundsRLE(
          imageBuf32,
          image.width,
          imageBounds,
          projectColors
        ));

      // update total extruders used
      for (let j = 0; j < imageExtrudersUsed.length; j++) {
        if (!totalExtrudersUsed.includes(imageExtrudersUsed[j])) {
          totalExtrudersUsed.push(imageExtrudersUsed[j]);
        }
      }

      // record how many bytes are required
      // 12 bytes in header for image meta
      headerBytesPerImage += 4 + 4 + 4;
      // 4 bytes in data for run length
      dataBytesPerImage += 4 * (imageRLE.length / 2);
      // 1 bytes in data for extruder index
      dataBytesPerImage += 1 * (imageRLE.length / 2);

      rlePerImage.set(i, imageRLE);
    }

    let offset = 0;
    let bufferLength = 1 + 4 + 1;
    bufferLength += totalExtrudersUsed.length;
    bufferLength += headerBytesPerImage;
    bufferLength += dataBytesPerImage;
    const arrayBuffer = new ArrayBuffer(bufferLength);
    const output = new DataView(arrayBuffer);

    // set header
    // set version number
    output.setUint8(offset, versionNumber, true);
    offset += 1;

    // set num of images
    output.setUint32(offset, atlasImages.length, true);
    offset += 4;

    // set total extruders used
    output.setUint8(offset, totalExtrudersUsed.length, true);
    offset += 1;

    for (let i = 0; i < totalExtrudersUsed.length; i++) {
      // set used extruder index
      output.setInt8(offset, totalExtrudersUsed[i], true);
      offset += 1;
    }

    // set meta per image
    for (let i = 0; i < atlasImages.length; i++) {
      image = atlasImages[i];
      imageRLE = rlePerImage.get(i);
      // set image width
      output.setUint32(offset, image.width, true);
      offset += 4;
      // set image height
      output.setUint32(offset, image.height, true);
      offset += 4;
      // set number of elements
      output.setUint32(offset, imageRLE.length / 2, true);
      offset += 4;
    }

    // set data
    for (let i = 0; i < atlasImages.length; i++) {
      image = atlasImages[i];
      imageRLE = rlePerImage.get(i);
      for (let j = 0; j < imageRLE.length; j += 2) {
        // set run length
        output.setUint32(offset, imageRLE[j], true);
        offset += 4;
        // set extruder index
        output.setInt8(offset, imageRLE[j + 1], true);
        offset += 1;
      }
    }
    return output.buffer;
  },
  getExtrudersUsedFromRLE: (rle, inputCount) => {
    const extrudersSeen = new Array(inputCount).fill(false);
    for (let i = 1; i < rle.length; i += 2) {
      if (rle[i] >= 0) {
        extrudersSeen[rle[i]] = true;
      }
    }
    return extrudersSeen;
  },
  exportTextureAsPNG: (buffer, width, height) => {
    // create a temporary canvas for exporting image
    const exportCanvas = document.createElement('canvas');
    exportCanvas.width = width;
    exportCanvas.height = height;
    const exportCtx = exportCanvas.getContext('2d');
    const exportImageData = exportCtx.getImageData(0, 0, width, height);
    exportImageData.data.set(buffer);
    exportCtx.putImageData(exportImageData, 0, 0);

    // download as png
    const dataURL = exportCanvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = 'texture.png';
    link.click();
  },
  drawAtlasWithStamps: (
    currentModel,
    project,
    camera,
    dimensionsByAtlas,
    atlasUvs
  ) => {
    const atlasImages = [];

    // 1 Create new renderer for atlas
    const renderer = new THREE.WebGLRenderer({
      antialias: false,
      powerPreference: 'low-power',
      preserveDrawingBuffer: true,
      alpha: true,
      depth: true,
    });
    renderer.setPixelRatio(window.devicePixelRatio || 1);
    renderer.autoClear = false;

    /* COMMON SETUP */
    // setup color uniform
    const extruderColorsUniform =
      currentModel.mesh.material.uniforms.extruderColors;
    const shaderDefines = currentModel.mesh.material.defines;

    // setup indices for line segments
    const indices = currentModel.mesh.geometry.index;
    const pairIndices = [];

    let vertAIndex;
    let vertBIndex;
    let vertCIndex;
    for (let i = 0; i < indices.count / 3; i++) {
      vertAIndex = indices.getX(i * 3 + 0);
      vertBIndex = indices.getX(i * 3 + 1);
      vertCIndex = indices.getX(i * 3 + 2);
      pairIndices.push(
        vertAIndex,
        vertBIndex,

        vertBIndex,
        vertCIndex,

        vertCIndex,
        vertAIndex
      );
    }

    const pairIndicesAttr = new THREE.BufferAttribute(
      new Uint32Array(pairIndices),
      1
    );

    /* DRAW ATLAS */
    dimensionsByAtlas.forEach((dimension) => {
      const { width, height } = dimension;

      // initialize buffer to hold pixel data
      const buf8 = new Uint8Array(width * height * 4);

      // 2 Create new Target for atlas (set render target)
      // setup render target for base and stamp mesh triangles
      const quadRenderTarget = new THREE.WebGLRenderTarget(width, height, {
        magFilter: THREE.NearestFilter,
        minFilter: THREE.NearestFilter,
        format: THREE.RGBAFormat,
        depthBuffer: false,
        stencilBuffer: false,
      });

      // setup render target for dilated pixels
      const dilateRenderTarget = new THREE.WebGLRenderTarget(width, height, {
        magFilter: THREE.NearestFilter,
        minFilter: THREE.NearestFilter,
        format: THREE.RGBAFormat,
        depthBuffer: false,
        stencilBuffer: false,
      });

      // 3 Create new Scene and geometries
      const atlasScene = new THREE.Scene();
      const clonedFillGeom = currentModel.mesh.geometry.clone();
      clonedFillGeom.addAttribute('uv', atlasUvs);

      const clonedLineGeom = currentModel.mesh.geometry.clone();
      clonedLineGeom.addAttribute('uv', atlasUvs);
      clonedLineGeom.setIndex(pairIndicesAttr);

      // 5 Create new Material for base Mesh
      // ( gl_position to uv coords, and gl_fragcolor = base Color)
      const baseMaterial = new THREE.ShaderMaterial({
        vertexShader: SHADER_CHUNKS.atlasFaceVertexShader,
        fragmentShader: SHADER_CHUNKS.atlasFaceFragmentShader,
        side: THREE.DoubleSide,
        transparent: true,
        uniforms: {
          extruderColors: extruderColorsUniform,
        },
        defines: shaderDefines,
      });

      // add base fill mesh
      const newBaseFillMesh = new THREE.Mesh(clonedFillGeom, baseMaterial);
      newBaseFillMesh.applyMatrix(currentModel.mesh.matrix);
      atlasScene.add(newBaseFillMesh);

      // add base line mesh
      const newBaseLineMesh = new THREE.LineSegments(
        clonedLineGeom,
        baseMaterial
      );
      newBaseLineMesh.applyMatrix(currentModel.mesh.matrix);
      atlasScene.add(newBaseLineMesh);

      // add each stamp mesh to scene
      _.forEach(currentModel.mesh.children, (stampGroup) => {
        const [stampMesh] = stampGroup.children;
        // 8 Create new Material for stamp
        const stampMaterial = new THREE.ShaderMaterial({
          vertexShader: SHADER_CHUNKS.atlasStampVertexShader,
          fragmentShader: SHADER_CHUNKS.atlasStampFragmentShader,
          side: THREE.DoubleSide,
          transparent: true,
          uniforms: stampMesh.material.uniforms,
          defines: shaderDefines,
        });

        // add stamp fill mesh
        const newStampFillMesh = new THREE.Mesh(clonedFillGeom, stampMaterial);
        newStampFillMesh.applyMatrix(currentModel.mesh.matrix);
        atlasScene.add(newStampFillMesh);

        // add stamp line mesh
        const newStampLineMesh = new THREE.LineSegments(
          clonedLineGeom,
          stampMaterial
        );
        newStampLineMesh.applyMatrix(currentModel.mesh.matrix);
        atlasScene.add(newStampLineMesh);
      });

      // 8 Render
      renderer.setRenderTarget(quadRenderTarget);
      renderer.render(atlasScene, camera);

      // clear renderer
      renderer.autoClear = true;

      // 9 dilate
      const quadScene = new THREE.Scene();
      const quadCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
      const quadPlane = new THREE.PlaneBufferGeometry(2, 2);

      const dilateMaterial = new THREE.ShaderMaterial({
        vertexShader: SHADER_CHUNKS.atlasDilateVertexShader,
        fragmentShader: SHADER_CHUNKS.atlasDilateFragmentShader,
        uniforms: {
          quadTexture: { type: 't', value: quadRenderTarget.texture },
          atlasWidth: { value: width },
          atlasHeight: { value: height },
        },
        side: THREE.DoubleSide,
      });

      const dilateMesh = new THREE.Mesh(quadPlane, dilateMaterial);
      quadScene.add(dilateMesh);

      // run dilation pass
      renderer.setRenderTarget(dilateRenderTarget);
      renderer.render(quadScene, quadCamera);
      renderer.setRenderTarget(null);

      // 10 Output render
      // write out final rendertarget to buffer
      renderer.readRenderTargetPixels(
        dilateRenderTarget,
        0,
        0,
        width,
        height,
        buf8
      );

      quadRenderTarget.dispose();
      dilateRenderTarget.dispose();

      atlasImages.push({
        width,
        height,
        data: {
          buffer: buf8.buffer,
        },
      });
    });

    return atlasImages;
  },
};

export default Utils;

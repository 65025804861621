import React from 'react';
import _ from 'lodash';

import {
  CheckboxListWrapper,
  CheckboxContainer,
  CaptionLabelWrapper,
} from './checkboxList.styles';

import Checkbox from '../checkbox/checkbox.jsx';
import { Caption } from '../typography/typography';

const CheckboxListItem = (props) => (
  <CheckboxContainer>
    <Checkbox
      label={props.label}
      value={props.value}
      disabled={props.disabled}
      onChange={props.onChange}
    />
    {props.captionLabel && (
      <CaptionLabelWrapper>
        <Caption grey noSpacing>
          {props.captionLabel(props.text)}
        </Caption>
      </CaptionLabelWrapper>
    )}
    {props.subCaptionLabel && props.subCaptionLabel(props.text)}
  </CheckboxContainer>
);

function CheckboxList(props) {
  return (
    <CheckboxListWrapper>
      {_.map(props.options, (option) => (
        <CheckboxListItem
          key={option.id}
          label={option.name}
          value={option.value}
          disabled={props.disabled || option.disabled}
          captionLabel={() => props.captionLabel(option.id, option.text)}
          subCaptionLabel={() => props.subCaptionLabel(option.id, option.text)}
          onChange={() => props.onChange(option.id, option.value)}
        />
      ))}
    </CheckboxListWrapper>
  );
}

export default CheckboxList;

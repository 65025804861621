import React, { Component } from 'react';

import {
  Container,
  ButtonsContainer,
  StyledInputContainer,
  StyledInput,
  EditingValidInputContainer,
  EditingErrorInputContainer,
  IconWrapper,
} from './objectItem.styles';

import { InterfaceUtils } from '../../../../utils';

import { DropdownMenu, Icon, AbstractInput } from '../../../../shared';

import types from '../../../../reducers/three/types';
import { Icons } from '../../../../themes';

const OPTION_DUPLICATE_OBJECT = 0;
const OPTION_EDIT_OBJECT = 1;
const OPTION_DELETE_OBJECT = 2;

class ObjectItem extends Component {
  static defaultProps = {
    name: '',
    onClick: () => {},
    onToggleVisible: () => {},
    onDeleteClick: () => {},
    onDuplicateClick: () => {},
    onEdit: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      editing: false,
      hovered: false,
      dropdownOpen: false,
    };
    this.nodeRef = React.createRef();
    this.inputRef = React.createRef();
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
    this.onWindowClick = this.onWindowClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.onWindowClick, true);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick, true);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      name,
      updateModelNamePending,
      updateModelNameSuccess,
      updateGroupNamePending,
      updateGroupNameSuccess,
    } = this.props;
    if (prevProps.name !== name) {
      this.setState({ name });
    }
    if (updateModelNamePending !== prevProps.updateModelNamePending) {
      if (!updateModelNamePending && !updateModelNameSuccess) {
        this.setState({ name });
      }
    }
    if (updateGroupNamePending !== prevProps.updateGroupNamePending) {
      if (!updateGroupNamePending && !updateGroupNameSuccess) {
        this.setState({ name });
      }
    }
    if (!prevState.editing && this.state.editing && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  onWindowClick(e) {
    if (!this.nodeRef.current) return;
    if (this.nodeRef.current.contains(e.target)) return;
    if (this.state.editing) {
      this.onNameSave(e);
    }
  }

  onVisibilityClick(e) {
    e.stopPropagation();
    this.props.onToggleVisible(this.props.model);
  }

  onEditClick() {
    this.setState({ editing: !this.state.editing });
  }

  onDeleteClick() {
    this.props.onDeleteClick(this.props.model);
  }

  onDuplicateClick() {
    this.setState({ hovered: false }, () => {
      this.props.unhoverModels();
      this.props.onDuplicateClick(this.props.model);
    });
  }

  onKeyDown(e) {
    e.stopPropagation();
    if (e.keyCode === 27) {
      // ESC
      this.revertName();
      e.target.blur();
    } else if (e.keyCode === 13) {
      // Enter
      this.onNameSave(e);
      e.target.blur();
    }
  }

  onBlur(e) {
    // handling for input losing focus on mobile
    this.revertName();
    e.target.blur();
  }

  onChangeSuccess(value) {
    this.setState({ name: value, error: false });
  }

  onChangeFailure(value) {
    this.setState({ name: value, error: true });
  }

  setName(name) {
    this.setState(
      {
        editing: false,
        name,
        error: false,
      },
      () => {
        // deselect all text
        // https://stackoverflow.com/questions/3169786/clear-text-selection-with-javascript
        if (window.getSelection) {
          if (window.getSelection().empty) {
            // Chrome
            window.getSelection().empty();
          } else if (window.getSelection().removeAllRanges) {
            // Firefox
            window.getSelection().removeAllRanges();
          }
        } else if (document.selection) {
          // IE?
          document.selection.empty();
        }
      }
    );
  }

  revertName() {
    this.setName(this.props.name);
  }

  onNameSave(e) {
    e.stopPropagation();
    const { models, model } = this.props;
    const { name, error } = this.state;
    // don't save if the name is invalid
    if (error) {
      this.revertName();
      return;
    }
    // don't save if name didn't change
    if (name === this.props.name) {
      this.revertName();
      return;
    }
    // prevent users from using the pipe or backslash character
    if (name.includes('|')) {
      InterfaceUtils.emitToast(
        'warn',
        "Object names cannot include the '|' character!"
      );
      this.revertName();
      return;
    }
    if (name.includes('\\')) {
      InterfaceUtils.emitToast(
        'warn',
        "Object names cannot include the '\\' character!"
      );
      this.revertName();
      return;
    }
    if (model.type === 'group') {
      // check for unique name, since back end allows for arbitrary paths
      const names = models.map((item) => item.name);
      if (names.includes(this.state.name)) {
        InterfaceUtils.emitToast('warn', `Name '${name}' is already taken!`);
        this.revertName();
        return;
      }
    }
    this.setName(name);
    // make update call
    if (model.type === 'group') {
      this.props.onEdit(this.props.name, name);
    } else {
      this.props.onEdit(model, name);
    }
  }

  onDropdownOpen() {
    this.setState({ dropdownOpen: true });
  }

  onDropdownClose() {
    this.setState({ dropdownOpen: false });
  }

  onMouseOver() {
    this.setState({ hovered: true }, () => {
      this.props.hoverModels(this.props.model);
    });
  }

  onMouseOut() {
    this.setState({ hovered: false }, () => {
      this.props.unhoverModels();
    });
  }

  validateObjectName(value) {
    if (value.includes('|')) {
      return {
        isValid: false,
        reason: "Names cannot include the '|' character",
      };
    }
    if (value.includes('\\')) {
      return {
        isValid: false,
        reason: "Names cannot include the '\\' character",
      };
    }
    return value.trim().length > 0;
  }

  getEditingContainer() {
    return this.state.error
      ? EditingErrorInputContainer
      : EditingValidInputContainer;
  }

  renderTextfield() {
    const { editing, name } = this.state;
    return (
      <AbstractInput
        innerRef={this.inputRef}
        type='text'
        title={name}
        value={name}
        isInvalid={this.state.error}
        validator={(value) => this.validateObjectName(value)}
        onChangeSuccess={(value) => this.onChangeSuccess(value)}
        onChangeFailure={(value) => this.onChangeFailure(value)}
        onKeyDown={(e) => this.onKeyDown(e)}
        onBlur={(e) => this.onBlur(e)}
        disabled={!editing}
        disableTooltip={name.trim().length === 0}
        StyledInput={StyledInput}
        StyledContainer={
          editing ? this.getEditingContainer() : StyledInputContainer
        }
      />
    );
  }

  renderVisibilityButton() {
    const icon = this.props.visible
      ? Icons.basic.eyeOpen
      : Icons.basic.eyeClose;
    return (
      <IconWrapper
        onClick={(e) => this.onVisibilityClick(e)}
        onDoubleClick={(e) => e.stopPropagation()}>
        <Icon src={icon} />
      </IconWrapper>
    );
  }

  handleClick(e) {
    if (this.state.editing) return;
    this.props.onClick(e);
  }

  handleDoubleClick(e) {
    e.stopPropagation();
    if (this.state.editing) return;
    this.setState({ editing: true });
  }

  renderButtons() {
    return (
      <ButtonsContainer tower={this.props.model === 'tower'}>
        {this.renderVisibilityButton()}
        {this.renderMenuDropdown()}
      </ButtonsContainer>
    );
  }

  renderMenuDropdown() {
    if (this.props.model === 'tower') return null;
    const options = [
      { label: 'Duplicate', value: OPTION_DUPLICATE_OBJECT },
      { label: 'Rename', value: OPTION_EDIT_OBJECT },
      { label: 'Delete', value: OPTION_DELETE_OBJECT },
    ];
    return (
      <DropdownMenu
        rightAlign
        minHeight='0'
        options={options}
        icon={Icons.basic.more}
        disabled={this.props.disabled}
        onOpen={() => this.onDropdownOpen()}
        onClose={() => this.onDropdownClose()}
        onChange={(value) => this.onOptionSelect(value)}
      />
    );
  }

  onOptionSelect(value) {
    switch (value) {
      case OPTION_DUPLICATE_OBJECT:
        this.onDuplicateClick();
        break;
      case OPTION_EDIT_OBJECT:
        this.onEditClick();
        break;
      case OPTION_DELETE_OBJECT:
        this.onDeleteClick();
        break;
      default:
        break;
    }
  }

  render() {
    const { depth, model, active, visible } = this.props;
    let onClick = () => {};
    let onDoubleClick = () => {};
    if (this.props.onClick) {
      onClick = (e) => this.handleClick(e);
      if (model !== 'tower') {
        onDoubleClick = (e) => this.handleDoubleClick(e);
      }
    }
    return (
      <Container
        ref={this.nodeRef}
        depth={depth}
        active={active}
        visible={visible}
        hovered={this.state.hovered}
        dropdownOpen={this.state.dropdownOpen}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        data-model-id={model === 'tower' ? '' : model.id}
        data-model-type={model === 'tower' ? types.TOWER_MESH_NAME : model.type}
        editing={this.state.editing}>
        {this.renderTextfield()}
        {this.renderButtons()}
      </Container>
    );
  }
}

export default ObjectItem;

import styled from 'styled-components';

export const Container = styled.div`
  user-select: none;
  pointer-events: none;
  width: ${(props) => props.sizePx}px;
  height: ${(props) => props.sizePx}px;
  min-width: ${(props) => props.sizePx}px;
  min-height: ${(props) => props.sizePx}px;
  overflow: hidden;
`;

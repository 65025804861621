import React from 'react';
import { Body1 } from '../../../../../shared';
import { DeviceUtils } from '../../../../../utils';
import { SectionPlaceholder } from '../../viewDevice.styles';
import Placeholder from '../../placeholder.jsx';
import Playback from './playback.container';

const WebcamSection = (props) => {
  const { device, deviceState } = props;

  if (!DeviceUtils.isWebcamAllowed(deviceState)) {
    return (
      <SectionPlaceholder borderless disabled>
        <Body1 grey>Streaming is disabled.</Body1>
        <Body1 grey noSpacing>
          You can enable it via Palette&apos;s camera settings.
        </Body1>
      </SectionPlaceholder>
    );
  }
  if (!DeviceUtils.isWebcamConnected(deviceState)) {
    return <Placeholder borderless>No camera connected</Placeholder>;
  }

  return <Playback deviceId={device.id} />;
};

export default WebcamSection;

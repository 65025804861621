import React, { Component } from 'react';
import _ from 'lodash';

import {
  Container,
  ColorNameLabelWrapper,
  HexFieldInputWrapper,
  CloseButtonWrapper,
  SaveButtonWrapper,
  RGBAInputWrapper,
} from './configureColor.styles';
import ColorPicker from './colorPicker/colorPicker.jsx';

import { MaterialUtils, SlicerUtils } from '../../../../../../utils';

import {
  ActionButton,
  Button,
  Input,
  Subtitle2,
} from '../../../../../../shared';

import { Icons } from '../../../../../../themes';

class ConfigureColor extends Component {
  constructor(props) {
    super(props);
    const { r, g, b, a } = props.currentColor;
    const hex = SlicerUtils.rgbaArrayToHexString([r, g, b, a]);
    this.state = {
      focusField: null,
      // current values as displayed in input fields
      values: {
        r,
        g,
        b,
        a,
        hex,
      },
      // most recent set of valid values, for properly rendering color name
      // even if any current values are invalid
      lastValidValues: {
        r,
        g,
        b,
        a,
        hex,
      },
      errors: {},
    };
  }

  changeHexSuccess(hex) {
    const [r, g, b, a] = SlicerUtils.hexStringToRGBA(hex);
    this.setState({
      values: {
        r,
        g,
        b,
        a,
        hex,
      },
      lastValidValues: {
        r,
        g,
        b,
        a,
        hex,
      },
      errors: {},
    });
  }

  changeHexFailure(hex) {
    this.setState({
      values: {
        ...this.state.values,
        hex,
      },
      errors: {
        ...this.state.errors,
        hex: true,
      },
    });
  }

  areAllChannelsValid(errors) {
    return !(errors.r || errors.g || errors.b || errors.a);
  }

  changeChannelSuccess(channel, value) {
    const errors = { ...this.state.errors };
    errors[channel] = false;
    if (this.areAllChannelsValid(errors)) {
      // recalculate hex field value
      const rgba = _.omit(this.state.values, 'hex');
      rgba[channel] = value;
      const { r, g, b, a } = rgba;
      const newHexValue = SlicerUtils.rgbaArrayToHexString([r, g, b, a]);
      this.setState({
        values: {
          ...this.state.values,
          [channel]: value,
          hex: newHexValue,
        },
        lastValidValues: {
          ...this.state.values,
          [channel]: value,
          hex: newHexValue,
        },
        errors: {},
      });
    } else {
      // simply set our value and clear our error
      this.setState({
        values: {
          ...this.state.values,
          [channel]: value,
        },
        errors: {
          ...this.state.errors,
          [channel]: false,
        },
      });
    }
  }

  changeChannelFailure(channel, value) {
    this.setState({
      values: {
        ...this.state.values,
        [channel]: value,
      },
      errors: {
        ...this.state.errors,
        [channel]: true,
      },
    });
  }

  validateHex(value) {
    const regex = /^#?([0-9a-f]{6})$/i;
    return regex.test(value);
  }

  onColorPickerChange(newColor) {
    const { r, g, b, a } = newColor;
    const hex = SlicerUtils.rgbaArrayToHexString([r, g, b, a]);
    this.setState({
      values: {
        ...newColor,
        hex,
      },
      lastValidValues: {
        ...newColor,
        hex,
      },
      errors: {},
    });
  }

  focusField(fieldName) {
    this.setState({ focusField: fieldName }, () => {
      this.setState({ focusField: null });
    });
  }

  getNextSaveError() {
    return _.findKey(this.state.errors) || null;
  }

  handleSave() {
    const fieldName = this.getNextSaveError();
    if (fieldName) {
      this.focusField(fieldName);
    } else {
      const { r, g, b, a } = this.state.values;
      this.props.onUpdateProjectColor([r, g, b, a]);
      this.props.onClickClose();
    }
  }

  renderColorName() {
    const { r, g, b, a } = this.state.lastValidValues;
    const colorName = MaterialUtils.getClosestColor([r, g, b, a]);
    return (
      <ColorNameLabelWrapper>
        <Subtitle2>{colorName}</Subtitle2>
      </ColorNameLabelWrapper>
    );
  }

  renderCloseButton() {
    return (
      <CloseButtonWrapper>
        <ActionButton
          clean
          icon={Icons.basic.x}
          onClick={() => this.props.onClickClose()}
        />
      </CloseButtonWrapper>
    );
  }

  renderColorPicker() {
    return (
      <ColorPicker
        color={_.omit(this.state.values, 'hex')}
        onChange={(colorInfo) => this.onColorPickerChange(colorInfo.rgb)}
      />
    );
  }

  renderHexField() {
    return (
      <HexFieldInputWrapper>
        <Input
          label='Hex'
          type='text'
          value={this.state.values.hex}
          isInvalid={this.state.errors.hex}
          forceFocus={this.state.focusField === 'hex'}
          validator={this.validateHex}
          spellCheck={false}
          onChangeSuccess={(newHex) => this.changeHexSuccess(newHex)}
          onChangeFailure={(newHex) => this.changeHexFailure(newHex)}
        />
      </HexFieldInputWrapper>
    );
  }

  renderRGBAField(channel) {
    const min = 0;
    const max = channel === 'a' ? 1 : 255;
    const step = channel === 'a' ? 0.01 : 1;
    return (
      <RGBAInputWrapper>
        <Input
          label={channel.toUpperCase()}
          type='number'
          min={min}
          gte
          max={max}
          lte
          step={step}
          value={this.state.values[channel]}
          isInvalid={this.state.errors[channel]}
          forceFocus={this.state.focusField === channel}
          onChangeSuccess={(value) => this.changeChannelSuccess(channel, value)}
          onChangeFailure={(value) => this.changeChannelFailure(channel, value)}
        />
      </RGBAInputWrapper>
    );
  }

  renderRGBAFields() {
    return (
      <>
        {this.renderRGBAField('r')}
        {this.renderRGBAField('g')}
        {this.renderRGBAField('b')}
        {this.renderRGBAField('a')}
      </>
    );
  }

  renderSaveButton() {
    return (
      <SaveButtonWrapper>
        <Button primary onClick={() => this.handleSave()}>
          Save
        </Button>
      </SaveButtonWrapper>
    );
  }

  render() {
    return (
      <Container>
        {this.renderColorName()}
        {this.renderCloseButton()}
        {this.renderColorPicker()}
        {this.renderHexField()}
        {this.renderRGBAFields()}
        {this.renderSaveButton()}
      </Container>
    );
  }
}

export default ConfigureColor;

import styled, { css } from 'styled-components';
import { Breakpoints } from '../../themes';

const transparentPattern = css`
  background-position: 0px 0px, 10px 10px;
  background-size: 20px 20px;
  background-image: linear-gradient(
      45deg,
      #eee 25%,
      transparent 25%,
      transparent 75%,
      #eee 75%,
      #eee 100%
    ),
    linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);
`;

const computeBackgroundStyles = (props) => {
  if (props.value[3] === 0) {
    return transparentPattern;
  }
  return css`
    background: rgba(
      ${props.value[0]},
      ${props.value[1]},
      ${props.value[2]},
      255
    );
  `;
};

export const StyledContainer = styled.div`
  min-width: 2rem;
  width: 2rem;
  min-height: 2rem;
  height: 2rem;
  padding: 0;
  margin-right: 1.3rem;
  border-radius: 0.2rem;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  ${(props) => computeBackgroundStyles(props)};

  @media (max-width: ${Breakpoints.smallWidth}) {
    margin-right: 0.7rem;
  }
`;

export const StyledOption = styled.div`
  font-size: 1rem;
  min-width: 2rem;
  width: 2rem;
  min-height: 2rem;
  height: 2rem;
  padding: 0;
  cursor: pointer;
  border-radius: 0.2rem;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  ${(props) => computeBackgroundStyles(props)};
`;

export const StyledIconContainer = styled.div`
 margin-left: 1.4rem;
 margin-top: -0.25rem;
  ${(props) =>
    props.showOption &&
    css`
      transform: rotate(180deg);
    `};
  }
  @media (max-width: ${Breakpoints.smallWidth}) {
    margin-left: 1.1rem;
      }
`;

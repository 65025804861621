import React, { Component } from 'react';

import {
  ModalButtonWrapper,
  ModalInputWrapper,
} from '../projectManager.styles';

import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalFooter,
} from '../../../shared';

import { Subtitle1 } from '../../../shared/typography/typography';

class CreateFolderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusField: false,
      inputValue: 'New Folder',
      inputError: false,
    };
  }

  handleMarginClick() {
    this.props.onMarginClick();
  }

  focusField() {
    this.setState({ focusField: true }, () => {
      this.setState({ focusField: false });
    });
  }

  onInputChangeSuccess(value) {
    this.setState({
      inputValue: value,
      inputError: false,
    });
  }

  onInputChangeFailure() {
    this.setState({
      inputError: true,
    });
  }

  onCancel() {
    this.props.onCancel();
  }

  onSave() {
    if (this.state.inputError) {
      this.focusField();
    } else {
      this.props.onConfirm(this.state.inputValue);
    }
  }

  render() {
    const { inputValue } = this.state;

    return (
      <Modal onMarginClick={(e) => this.handleMarginClick(e)}>
        <ModalHeader>
          <Subtitle1>Name your folder</Subtitle1>
        </ModalHeader>
        <ModalInputWrapper>
          <Input
            type='text'
            label='Name'
            autoFocus
            forceFocus={this.state.focusField}
            value={inputValue}
            onChangeSuccess={(value) => this.onInputChangeSuccess(value)}
            onChangeFailure={() => this.onInputChangeFailure()}
          />
        </ModalInputWrapper>
        <ModalFooter>
          <ModalButtonWrapper>
            <Button
              title='Cancel'
              onClick={(e) => {
                e.stopPropagation();
                this.onCancel();
              }}>
              Cancel
            </Button>
          </ModalButtonWrapper>
          <ModalButtonWrapper>
            <Button
              primary
              title='Create'
              onClickKeyTrigger='Enter'
              onClick={() => this.onSave()}>
              Create
            </Button>
          </ModalButtonWrapper>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CreateFolderModal;

import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { actions } from '../../../reducers/slicer/slicer';
import { InterfaceUtils } from '../../../utils';

export function* updateTowerPositionAndSize(tower, projectId) {
  try {
    const response = yield call(API, {
      method: methods.POST,
      path: `projects/${projectId}/tower`,
      body: {
        position: [tower.position.x, tower.position.y],
        size: [tower.size.x, tower.size.y],
      },
    });
    if (response === null) return;

    yield put(actions.updateTower(response.transitionTower));
  } catch (e) {
    InterfaceUtils.emitToast('error', e.message);
  }
}

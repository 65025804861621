import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding: 0.625rem;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: 1fr;
  grid-gap: 1.375rem;
  box-sizing: border-box;
  user-select: none;
`;

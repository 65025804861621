import printerTypes from './types';
import printerActions from './actions';
import printerInitialState from './initialState';
import Helpers from './helpers';

export const types = printerTypes;
export const actions = printerActions;
export const initialState = printerInitialState;

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.RESET_PRINTER_STORE: {
      return initialState;
    }
    case types.PARSE_PROFILE_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          parseProfilePending: true,
          parseProfileSuccess: false,
        },
      };
    }
    case types.PARSE_PROFILE_SUCCESS: {
      return {
        ...state,
        parsedProfile: payload.parsedProfile,
        status: {
          ...state.status,
          parseProfilePending: false,
          parseProfileSuccess: true,
        },
      };
    }
    case types.PARSE_PROFILE_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          parseProfilePending: false,
          parseProfileSuccess: false,
        },
      };
    }
    case types.RESET_PARSED_PROFILE: {
      return {
        ...state,
        parsedProfile: null,
        status: {
          ...state.status,
          parseProfilePending: false,
          parseProfileSuccess: false,
        },
      };
    }
    case types.CREATE_PRINTER_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          createPrinterPending: true,
          createPrinterSuccess: false,
        },
      };
    }
    case types.CREATE_PRINTER_SUCCESS: {
      const { printer } = payload;
      return {
        ...state,
        printers: Helpers.updatePrinterInMap(state.printers, printer),
        status: {
          ...state.status,
          createPrinterPending: false,
          createPrinterSuccess: true,
          createStylesSuccess: false,
        },
      };
    }
    case types.CREATE_PRINTER_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          createPrinterPending: false,
          createPrinterSuccess: false,
        },
      };
    }
    case types.DUPLICATE_PRINTER_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          duplicatePrinterPending: true,
          duplicatePrinterSuccess: false,
        },
      };
    }
    case types.DUPLICATE_PRINTER_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          duplicatePrinterPending: false,
          duplicatePrinterSuccess: true,
        },
      };
    }
    case types.DUPLICATE_PRINTER_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          duplicatePrinterPending: false,
          duplicatePrinterSuccess: false,
        },
      };
    }
    case types.UPDATE_PRINTER_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          updatePrinterPending: true,
          updatePrinterSuccess: false,
        },
      };
    }
    case types.UPDATE_PRINTER_SUCCESS: {
      return {
        ...state,
        printers: Helpers.updatePrinterInMap(state.printers, payload.printer),
        status: {
          ...state.status,
          updatePrinterPending: false,
          updatePrinterSuccess: true,
        },
      };
    }
    case types.UPDATE_PRINTER_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updatePrinterPending: false,
          updatePrinterSuccess: false,
        },
      };
    }

    case types.UPDATE_CALIBRATION_SETTINGS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          updateCalibrationSettingsPending: true,
          updateCalibrationSettingsSuccess: false,
        },
      };
    }
    case types.UPDATE_CALIBRATION_SETTINGS_SUCCESS: {
      return {
        ...state,
        printers: Helpers.updatePrinterInMap(state.printers, payload.printer),
        status: {
          ...state.status,
          updateCalibrationSettingsPending: false,
          updateCalibrationSettingsSuccess: true,
        },
      };
    }
    case types.UPDATE_CALIBRATION_SETTINGS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updateCalibrationSettingsPending: false,
          updateCalibrationSettingsSuccess: false,
        },
      };
    }

    case types.DELETE_PRINTERS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          deletePrintersPending: true,
          deletePrintersSuccess: false,
        },
      };
    }
    case types.DELETE_PRINTERS_SUCCESS: {
      return {
        ...state,
        printers: Helpers.deletePrintersFromMap(
          state.printers,
          payload.printerIds
        ),
        status: {
          ...state.status,
          deletePrintersPending: false,
          deletePrintersSuccess: true,
        },
      };
    }
    case types.DELETE_PRINTERS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          deletePrintersPending: false,
          deletePrintersSuccess: false,
        },
      };
    }
    case types.CREATE_STYLE_PROFILES_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          createStylesPending: true,
          createStylesSuccess: false,
          createPrinterSuccess: false,
        },
      };
    }
    case types.CREATE_STYLE_PROFILES_SUCCESS: {
      const { printerId, styleProfiles } = payload;
      const printer = state.printers[printerId];
      const updatedPrinter = { ...printer, styles: styleProfiles };
      return {
        ...state,
        printers: Helpers.updatePrinterInMap(state.printers, updatedPrinter),
        status: {
          ...state.status,
          createStylesPending: false,
          createStylesSuccess: true,
        },
      };
    }
    case types.CREATE_STYLE_PROFILES_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          createStylesPending: false,
          createStylesSuccess: false,
        },
      };
    }
    case types.UPDATE_STYLE_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          updatePrinterPending: true,
          updatePrinterSuccess: false,
        },
      };
    }
    case types.UPDATE_STYLE_SUCCESS: {
      return {
        ...state,
        printers: Helpers.updatePrinterInMap(state.printers, payload.printer),
        status: {
          ...state.status,
          updatePrinterPending: false,
          updatePrinterSuccess: true,
        },
      };
    }
    case types.UPDATE_STYLE_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updatePrinterPending: false,
          updatePrinterSuccess: false,
        },
      };
    }
    case types.DELETE_STYLES_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteStylesPending: true,
          deleteStylesSuccess: false,
        },
      };
    }
    case types.DELETE_STYLES_SUCCESS: {
      const { printerId, styleIds } = payload;
      const printer = state.printers[printerId];
      const updatedPrinter = Helpers.deletePrinterStyles(printer, styleIds);
      return {
        ...state,
        printers: Helpers.updatePrinterInMap(state.printers, updatedPrinter),
        status: {
          ...state.status,
          deleteStylesPending: false,
          deleteStylesSuccess: true,
        },
      };
    }
    case types.DELETE_STYLES_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteStylesPending: false,
          deleteStylesSuccess: false,
        },
      };
    }
    case types.GET_PRINTERS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getPrintersPending: true,
          getPrintersSuccess: false,
        },
      };
    }
    case types.GET_PRINTERS_SUCCESS: {
      return {
        ...state,
        printers: Helpers.composePrintersMap(payload.printers),
        status: {
          ...state.status,
          getPrintersPending: false,
          getPrintersSuccess: true,
        },
      };
    }
    case types.GET_PRINTERS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getPrintersPending: false,
          getPrintersSuccess: false,
        },
      };
    }
    case types.GET_PRINTER_PRESETS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getPrinterPresetsPending: true,
          getPrinterPresetsSuccess: false,
        },
      };
    }
    case types.GET_PRINTER_PRESETS_SUCCESS: {
      return {
        ...state,
        presets: payload.presets,
        status: {
          ...state.status,
          getPrinterPresetsPending: false,
          getPrinterPresetsSuccess: true,
        },
      };
    }
    case types.GET_PRINTER_PRESETS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getPrinterPresetsPending: false,
          getPrinterPresetsSuccess: false,
        },
      };
    }
    // Sharing
    case types.CREATE_SHARED_PRINTER_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          createSharedPrinterPending: true,
          createSharedPrinterSuccess: false,
        },
      };
    }
    case types.CREATE_SHARED_PRINTER_SUCCESS: {
      const { printerId, shareData } = payload;
      return {
        ...state,
        printers: {
          ...state.printers,
          [printerId]: {
            ...state.printers[printerId],
            shareData,
          },
        },
        status: {
          ...state.status,
          createSharedPrinterPending: false,
          createSharedPrinterSuccess: true,
        },
      };
    }
    case types.CREATE_SHARED_PRINTER_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          createSharedPrinterPending: false,
          createSharedPrinterSuccess: false,
        },
      };
    }
    case types.DELETE_SHARED_PRINTER_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteSharedPrinterPending: true,
          deleteSharedPrinterSuccess: false,
        },
      };
    }
    case types.DELETE_SHARED_PRINTER_SUCCESS: {
      const { printerId, timestamp } = payload;
      return {
        ...state,
        printers: {
          ...state.printers,
          [printerId]: {
            ...state.printers[printerId],
            shareData: {
              dateShared: timestamp,
            },
          },
        },
        status: {
          ...state.status,
          deleteSharedPrinterPending: false,
          deleteSharedPrinterSuccess: true,
        },
      };
    }
    case types.DELETE_SHARED_PRINTER_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteSharedPrinterPending: false,
          deleteSharedPrinterSuccess: false,
        },
      };
    }
    case types.UPDATE_SHARED_PRINTER_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          updateSharedPrinterPending: true,
          updateSharedPrinterSuccess: false,
        },
      };
    }
    case types.UPDATE_SHARED_PRINTER_SUCCESS: {
      const { printerId, shareData } = payload;
      return {
        ...state,
        printers: {
          ...state.printers,
          [printerId]: {
            ...state.printers[printerId],
            shareData,
          },
        },
        status: {
          ...state.status,
          updateSharedPrinterPending: false,
          updateSharedPrinterSuccess: true,
        },
      };
    }
    case types.UPDATE_SHARED_PRINTER_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updateSharedPrinterPending: false,
          updateSharedPrinterSuccess: false,
        },
      };
    }
    case types.GET_SHARED_PRINTER_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getSharedPrinterPending: true,
          getSharedPrinterSuccess: false,
        },
      };
    }
    case types.GET_SHARED_PRINTER_SUCCESS: {
      return {
        ...state,
        currentSharedPrinter: payload.sharedPrinter,
        status: {
          ...state.status,
          getSharedPrinterPending: false,
          getSharedPrinterSuccess: true,
        },
      };
    }
    case types.GET_SHARED_PRINTER_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getSharedPrinterPending: false,
          getSharedPrinterSuccess: false,
        },
      };
    }
    case types.IMPORT_SHARED_PRINTER_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          importSharedPrinterPending: true,
          importSharedPrinterSuccess: false,
        },
      };
    }
    case types.IMPORT_SHARED_PRINTER_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          importSharedPrinterPending: false,
          importSharedPrinterSuccess: true,
        },
      };
    }
    case types.IMPORT_SHARED_PRINTER_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          importSharedPrinterPending: false,
          importSharedPrinterSuccess: false,
        },
      };
    }
    case types.QUERY_PRINTER_TAGS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          queryPrinterTagsPending: true,
          queryPrinterTagsSuccess: false,
        },
      };
    }
    case types.QUERY_PRINTER_TAGS_SUCCESS: {
      return {
        ...state,
        tags: payload.tags,
        status: {
          ...state.status,
          queryPrinterTagsPending: false,
          queryPrinterTagsSuccess: true,
        },
      };
    }
    case types.QUERY_PRINTER_TAGS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          queryPrinterTagsPending: false,
          queryPrinterTagsSuccess: false,
        },
      };
    }
    case types.CREATE_PRINTER_TAG_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          createPrinterTagPending: true,
          createPrinterTagSuccess: false,
        },
      };
    }
    case types.CREATE_PRINTER_TAG_SUCCESS: {
      const { aggregatedData, name } = payload.tag;
      return {
        ...state,
        tags: [...state.tags, { id: aggregatedData.id, name }],
        currentPrinterTag: { ...aggregatedData, name },
        status: {
          ...state.status,
          createPrinterTagPending: false,
          createPrinterTagSuccess: true,
        },
      };
    }
    case types.CREATE_PRINTER_TAG_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          createPrinterTagPending: false,
          createPrinterTagSuccess: false,
        },
      };
    }
    case types.GET_PRINTER_TAG_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getPrinterTagPending: true,
          getPrinterTagSuccess: false,
        },
      };
    }
    case types.GET_PRINTER_TAG_SUCCESS: {
      return {
        ...state,
        currentPrinterTag: payload.printerTag,
        status: {
          ...state.status,
          getPrinterTagPending: false,
          getPrinterTagSuccess: true,
        },
      };
    }
    case types.GET_PRINTER_TAG_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getPrinterTagPending: false,
          getPrinterTagSuccess: false,
        },
      };
    }
    case types.CLEAR_PRINTER_TAG: {
      return {
        ...state,
        currentPrinterTag: null,
      };
    }
    default: {
      return state;
    }
  }
};

import 'regenerator-runtime/runtime';
import { takeLatest, takeEvery, all } from 'redux-saga/effects';

import { types } from '../../reducers/notifications/notifications';

// import each saga
import getNotifications from './getNotifications';
import updateNotification from './updateNotification';
import deleteNotification from './deleteNotification';

export default function* notificationsSaga() {
  yield all([
    takeLatest(types.GET_NOTIFICATIONS_REQUEST, getNotifications),
    takeEvery(types.UPDATE_NOTIFICATION_REQUEST, updateNotification),
    takeEvery(types.DELETE_NOTIFICATION_REQUEST, deleteNotification),
  ]);
}

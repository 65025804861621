import _ from 'lodash';

const Utils = {
  browserSupportsNative: () => 'Notification' in window,

  shouldSendNative: () => !document.hasFocus(),

  requestNativePermission: async () => {
    if (!Utils.browserSupportsNative()) {
      // no support for notifications, so treat it the same as
      // if the user denied permission to send notifications
      return 'denied';
    }
    if (Notification.permission === 'default') {
      // user has not been asked for permission to send
      // desktop notifications, so ask them now
      return Notification.requestPermission();
    }
    // user has already been asked for permission, so
    // just return the existing permission value
    return Notification.permission;
  },

  sendNative: async (title, body) => {
    const permission = await Utils.requestNativePermission();
    if (permission === 'granted') {
      return new Notification(title, {
        body,
        icon: 'https://canvas3d.io/favicon.ico',
      });
    }
    return null;
  },

  /**
   * Sorts a notifications map into an array from newest
   * to oldest, with unread notifications first
   *
   * @param {object} notifications Map of ID => Notification
   * @return {object[]} Array of Notification
   */
  sort: (notifications) =>
    _.values(notifications).sort((a, b) => {
      if (a.read && !b.read) {
        return 1; // sort b before a
      }
      if (!a.read && b.read) {
        return -1; // sort a before b
      }
      // both read, or both unread -- sort by sent timestamp
      return b.sent.localeCompare(a.sent);
    }),
};

export default Utils;

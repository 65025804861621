import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import _ from 'lodash';

import {
  ButtonWrapper,
  MetadataContainer,
  MetadataItem,
  PaletteSettingsContainer,
  PrinterSettingsContainer,
  SubtitleWrapper,
  Content,
} from './common.styles';

import {
  Body1,
  Button,
  Dropdown,
  Icon,
  Modal,
  ModalFooter,
  ModalHeader,
  Subtitle1,
  Subtitle2,
} from '../../../../shared';

import { usePrevious } from '../../../../hooks';

import { DeviceUtils } from '../../../../utils';

import { actions as iotActions } from '../../../../reducers/iot/iot';
import { Icons } from '../../../../themes';

const ConnectionSettingsSection = ({
  device,
  currentDeviceState,
  pendingRequests,
  connectedDevice,
  partConnected,
  serialConnection,
  status,
}) => {
  const ports = DeviceUtils.getAvailableComPorts(currentDeviceState);
  const prevPorts = usePrevious(ports);

  const [availableComPorts, setAvailableComPorts] = useState([]);
  const [comPort, setComPort] = useState(null);
  const [baudRate, setBaudRate] = useState('auto');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(iotActions.scanPortsRequest(device));
  }, [dispatch, device]);

  useEffect(() => {
    if (ports && !_.isEqual(ports, prevPorts)) {
      setAvailableComPorts(ports);

      if (partConnected && serialConnection) {
        setComPort(serialConnection.port);
        setBaudRate(serialConnection.baud);
      } else {
        setComPort(ports[0]);
        setBaudRate('auto');
      }
    }
  }, [ports, prevPorts, partConnected, serialConnection]);

  const handleConnect = () => {
    let connectTo;
    const connectionSettings = {
      comPort,
    };

    if (connectedDevice === 'Printer') {
      connectTo = 'printer';
      connectionSettings.baudrate = baudRate;
    } else if (connectedDevice === 'Palette') {
      connectTo = 'palette';
    }

    dispatch(
      iotActions.connectPortRequest(device, connectTo, connectionSettings)
    );
  };

  const handleDisconnect = () => {
    let disconnectFrom;

    if (connectedDevice === 'Printer') {
      disconnectFrom = 'printer';
    } else if (connectedDevice === 'Palette') {
      disconnectFrom = 'palette';
    }

    dispatch(iotActions.disconnectPortRequest(device, disconnectFrom));
  };

  const renderBaudRateSelection = () => {
    const rates = [
      'auto',
      250000,
      235000,
      230400,
      115200,
      57600,
      38400,
      19200,
      9600,
    ];
    const options = _.map(rates, (rate) => {
      let label = _.capitalize(rate.toString());
      if (partConnected && serialConnection && serialConnection.baud === rate) {
        label += ' (current)';
      }
      return {
        label,
        value: rate,
      };
    });

    return (
      <Dropdown
        label='Baud rate'
        value={baudRate}
        options={options}
        disabled={partConnected}
        onChange={(value) => setBaudRate(value)}
      />
    );
  };

  const renderComPortSelection = () => {
    const options = _.map(availableComPorts, (port) => {
      let label = port;
      if (partConnected && serialConnection && serialConnection.port === port) {
        label += ' (current)';
      }
      return {
        label,
        value: port,
      };
    });

    let scanStatus = '';
    const isScanning = status.scanPortsPending;
    if (isScanning) {
      scanStatus = '(scanning)';
    }

    return (
      <Dropdown
        label={`COM port ${scanStatus}`}
        value={comPort}
        options={options}
        disabled={_.isEmpty(options) || isScanning || partConnected}
        onChange={(value) => setComPort(value)}
      />
    );
  };

  const renderConnectButton = () => {
    const connectingToPort = pendingRequests.connectPort;

    let label = partConnected ? 'Disconnect' : 'Connect';
    if (connectingToPort) {
      label = 'Connecting';
    }

    return (
      <Button
        primary={!partConnected}
        clean
        warning={partConnected}
        disabled={!comPort || connectingToPort}
        onClick={partConnected ? handleDisconnect : handleConnect}>
        {label}
      </Button>
    );
  };

  const SettingsContainer =
    connectedDevice === 'Printer'
      ? PrinterSettingsContainer
      : PaletteSettingsContainer;
  const deviceName = DeviceUtils.getDeviceTypeName(device.type);

  return (
    <>
      <MetadataContainer>
        <MetadataItem>
          <SubtitleWrapper>
            <Subtitle2 noSpacing>{deviceName}</Subtitle2>
            <Icon src={Icons.basic.swap} />
            <Subtitle2 noSpacing>{connectedDevice}</Subtitle2>
          </SubtitleWrapper>
        </MetadataItem>
        <MetadataItem>
          <Body1 noSpacing green>
            {partConnected ? 'Connected (USB)' : 'Not connected'}
          </Body1>
        </MetadataItem>
      </MetadataContainer>
      <SettingsContainer>
        {connectedDevice === 'Printer' && renderBaudRateSelection()}
        {renderComPortSelection()}
        {renderConnectButton()}
      </SettingsContainer>
    </>
  );
};

const getConnectionInfo = (connectedDevice, currentDeviceState) => {
  let partConnected = false;
  let serialConnection = null;

  if (connectedDevice === 'Printer') {
    partConnected = DeviceUtils.isConnectedToPrinter(currentDeviceState);
    serialConnection =
      DeviceUtils.getPrinterSerialConnection(currentDeviceState);
  } else if (connectedDevice === 'Palette') {
    // must be a palette
    partConnected = DeviceUtils.isConnectedToAccessory(currentDeviceState);
    serialConnection =
      DeviceUtils.getAccessorySerialConnection(currentDeviceState);
  }

  return {
    connectedDevice,
    partConnected,
    serialConnection,
  };
};

const ConnectionSettingsModal = (props) => {
  const dispatch = useDispatch();
  const { onClose, device, currentDeviceState, pendingRequests } = props;
  const connectedDevices = useMemo(
    () => (device.type === 'canvas-hub' ? ['Printer', 'Palette'] : ['Printer']),
    [device.type]
  );

  const connectionInfo = useMemo(
    () =>
      connectedDevices.map((connectedDevice) =>
        getConnectionInfo(connectedDevice, currentDeviceState)
      ),
    [connectedDevices, currentDeviceState]
  );

  const handleRescan = useCallback(() => {
    dispatch(iotActions.scanPortsRequest(device));
  }, [device, dispatch]);

  const { status } = useSelector((state) => state.iot);
  const isScanning = status.scanPortsPending;

  const partsConnected = connectionInfo.map((x) => x.partConnected);
  const allPartsConnected = partsConnected.reduce(
    (prev, next) => prev && next,
    true
  );
  const isConnectingToPort = pendingRequests.connectPort;

  const rescanDisabled = isScanning || isConnectingToPort || allPartsConnected;

  return (
    <Modal onMarginClick={onClose} width='45rem'>
      <ModalHeader>
        <Subtitle1>Connection Settings</Subtitle1>
      </ModalHeader>
      <Content>
        {connectionInfo.map(
          ({ connectedDevice, partConnected, serialConnection }) => (
            <ConnectionSettingsSection
              key={connectedDevice}
              partConnected={partConnected}
              serialConnection={serialConnection}
              connectedDevice={connectedDevice}
              device={device}
              currentDeviceState={currentDeviceState}
              pendingRequests={pendingRequests}
              status={status}
            />
          )
        )}
      </Content>
      <ModalFooter>
        <ButtonWrapper>
          <Button disabled={rescanDisabled} onClick={handleRescan}>
            Rescan
          </Button>
          <Button primary onClick={onClose}>
            Close
          </Button>
        </ButtonWrapper>
      </ModalFooter>
    </Modal>
  );
};

export default withTheme(ConnectionSettingsModal);

import React from 'react';

import { ModalButtonWrapper } from '../stampPrompt.styles';

import {
  Button,
  FileUploader,
  Modal,
  ModalHeader,
  ModalFooter,
} from '../../../../../../shared';

import { Subtitle1 } from '../../../../../../shared/typography/typography';

const StampUploader = ({ onUploadImage = () => {}, onCancel = () => {} }) => (
  <Modal width='30em'>
    <ModalHeader>
      <Subtitle1>Upload an image to stamp</Subtitle1>
    </ModalHeader>
    <FileUploader
      accept={['.png', '.jpeg', '.jpg']}
      secondaryLabel={'Supported types: png, jpg, jpeg'}
      onChange={onUploadImage}
    />
    <ModalFooter>
      <ModalButtonWrapper>
        <Button onClick={onCancel}>Cancel</Button>
      </ModalButtonWrapper>
    </ModalFooter>
  </Modal>
);

export default StampUploader;

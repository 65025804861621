import styled from 'styled-components';

import { Shadows } from '../../themes';

export const CardContainer = styled.div`
  padding: ${(props) => props.padding};
  height: ${(props) => props.height};
  width: 100%;
  display: flex;
  background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  border: 1px solid transparent;
  border-radius: 0.25rem;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.2s;
  border-color: ${(props) =>
    props.selected ? props.theme.colors.greenDefault : 'transparent'};
  box-sizing: border-box;
  opacity: ${(props) => (props.opacity ? props.opacity : 1)};

  &:hover {
    border-color: ${(props) => props.theme.colors.greenDefault};
  }

  ${(props) => {
    if (props.shadow === 'd0') {
      return Shadows.d0;
    }
    if (props.shadow === 'd1') {
      return Shadows.d1;
    }
    if (props.shadow === 'd3') {
      return Shadows.d3;
    }
    return Shadows.d2;
  }}
`;

export const DefaultStyledLayoutContainer = styled.div``;

import 'regenerator-runtime/runtime';
import { call, select } from 'redux-saga/effects';
import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';

import { getThreeState, getAuthState, handleGenericError } from '../common';
import { startTween, stopTween } from './animationFrame';
import Helpers from '../../reducers/three/helpers';

const TWEEN_DURATION = 300; // ms

const UP_VEC = new THREE.Vector3(0, 0, 1);

// TWEEN.Tween instance
let tween = null;

export function* stopCameraTween() {
  if (!tween) return;
  tween.stop();
  tween = null;
  const { controls } = yield select(getThreeState);
  controls.update();
  controls.enabled = true;
  stopTween();
}

const getEndRotation = (endPosition, endLookAt) => {
  // at the end of the animation, the direction of the camera will be
  // the vector from its end position to the final lookAt value
  // https://threejs.org/docs/index.html#api/en/math/Matrix4.lookAt
  // holy cow, this is a convenient util
  const mat = new THREE.Matrix4().lookAt(endPosition, endLookAt, UP_VEC);
  return new THREE.Quaternion().setFromRotationMatrix(mat);
};

// controls should already be updated and disabled by whatever triggered this saga
export default function* tweenCamera(action) {
  try {
    const { camera, controls } = yield select(getThreeState);
    const { viewOptions } = yield select(getAuthState);
    const { position, zoom, lookAt } = action.payload;

    // stop any currently-running tween first
    if (tween) {
      yield call(stopCameraTween);
    }

    // set required tween values
    const endRotationQuat = getEndRotation(position, lookAt);
    const startValues = {
      posX: camera.position.x,
      posY: camera.position.y,
      posZ: camera.position.z,
      zoom: camera.zoom,
      rotX: camera.quaternion.x,
      rotY: camera.quaternion.y,
      rotZ: camera.quaternion.z,
      rotW: camera.quaternion.w,
    };
    const endValues = {
      posX: position.x,
      posY: position.y,
      posZ: position.z,
      zoom,
      rotX: endRotationQuat.x,
      rotY: endRotationQuat.y,
      rotZ: endRotationQuat.z,
      rotW: endRotationQuat.w,
    };
    tween = new TWEEN.Tween(startValues)
      .to(endValues, TWEEN_DURATION)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate((current) => {
        camera.position.set(current.posX, current.posY, current.posZ);
        camera.zoom = current.zoom;
        camera.quaternion.set(
          current.rotX,
          current.rotY,
          current.rotZ,
          current.rotW
        );
        Helpers.updateProjectionMatrix(camera, viewOptions.orthographic);
      })
      .onComplete(() => {
        stopTween(); // tell animationFrame we're done
        tween = null;
        controls.enabled = true;
        controls.update();
      });

    // start the tween
    startTween(); // tell animationFrame to update us!
    tween.start();
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import React from 'react';

export default function MosaicLogo(props) {
  return (
    <svg
      version='1.1'
      id='mosaic-logo'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width='71px'
      height='110px'
      viewBox='0 0 35.5 55'
      enableBackground='new 0 0 35.5 55'
      xmlSpace='preserve'
      {...props}>
      <g id='purple'>
        <path
          id='purple-2'
          fill='#810BF8'
          d='M27.099,32.898l6.97-3.096C29.412,15.5,24.397,0.095,24.397,0.095l-3.989,12.253 L27.099,32.898z'
        />
        <polygon
          id='purple-1'
          fill='#5E16BA'
          points='20.405,12.359 27.092,32.9 34.068,29.801'
        />
      </g>
      <g id='teal'>
        <polygon
          id='teal-3'
          fill='#17A078'
          points='26.169,34.982 2.708,45.408 0,53.729 32.271,53.729'
        />
        <polygon
          id='teal-2'
          fill='#1DBC94'
          points='32.259,53.729 6.938,53.729 10.816,41.813'
        />
        <polygon
          id='teal-1'
          fill='#23D8B6'
          points='2.706,45.418 0,53.729 6.938,53.729 10.816,41.813'
        />
      </g>
      <g id='yellow'>
        <polygon
          id='yellow-2'
          fill='#FDB300'
          points='11.456,39.851 25.694,33.523 19.604,14.818'
        />
        <polygon
          id='yellow-1'
          fill='#FED941'
          points='11.456,39.85 25.683,33.527 15.771,26.592'
        />
      </g>
    </svg>
  );
}

import * as THREE from 'three';
import { RED, TEAL } from './constants';

const vertShader = `
#if NUM_POINT_LIGHTS > 0
  struct PointLight {
    vec3 position;
  };
  uniform PointLight pointLights[NUM_POINT_LIGHTS];
#endif
  uniform mediump float bottom;
  uniform mediump float top;
  uniform lowp vec3 color;
  attribute lowp vec3 offset;
  varying lowp vec4 c;

  void main() {
    vec3 instancedPosition = position + offset;
    if (offset.z < bottom || offset.z > top) {
      c = vec4(0, 0, 0, -1);
    } else {
      vec3 normalDir = normalize((modelViewMatrix * vec4(normal, 0.0)).xyz);
      vec3 lightDir = normalize(pointLights[0].position - (modelViewMatrix * vec4(instancedPosition, 1.0)).xyz);
      vec3 diffuseReflection = color * max(0.0, dot(normalDir, lightDir));
      c = vec4(diffuseReflection, 1);
    }
    gl_Position = projectionMatrix * modelViewMatrix * vec4(instancedPosition, 1.0);
  }
`;

const fragShader = `
  varying lowp vec4 c;
  uniform lowp float alphaTest;

  void main() {
    if (c.a < alphaTest) discard;
    gl_FragColor = vec4(c.xyz, 1.0);
  }
`;

const makeMaterial = (color) =>
  new THREE.ShaderMaterial({
    uniforms: THREE.UniformsUtils.merge([
      {
        top: { value: 16384 }, // support mediump precision
        bottom: { value: -16384 }, // support mediump precision
        color: { value: color }, // THREE.Vector3/THREE.Color
        alphaTest: { value: 0 },
      },
    ]),
    fragmentShader: fragShader,
    vertexShader: vertShader,
  });

const retractMaterial = makeMaterial(new THREE.Color(TEAL));
const restartMaterial = makeMaterial(new THREE.Color(RED));

const InstancedSphere = (positionBuffer, diameter, isRetract = true) => {
  const offsets = new Float32Array(positionBuffer);
  const instanceCount = Math.floor(offsets.length / 3);
  const sphere = new THREE.SphereBufferGeometry(diameter, 6, 6);
  const geometry = new THREE.InstancedBufferGeometry();
  geometry.maxInstancedCount = instanceCount;
  // global attributes
  geometry.index = sphere.index;
  geometry.attributes.position = sphere.attributes.position;
  geometry.attributes.normal = sphere.attributes.normal;
  // instanced position attribute
  geometry.addAttribute(
    'offset',
    new THREE.InstancedBufferAttribute(offsets, 3)
  );
  // get appropriate material
  const material = isRetract ? retractMaterial : restartMaterial;
  const mesh = new THREE.Mesh(geometry, material);
  mesh.frustumCulled = false;
  return mesh;
};

export default InstancedSphere;

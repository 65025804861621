const Fonts = {
  familyBody: '"Inter", sans-serif',
  familyHeader: '"Inter", sans-serif',
  familyMono: 'monospace',
  weightLight: 300,
  weightRegular: 400,
  weightMedium: 500,
  weightBold: 600,
};

const defaultFonts = { ...Fonts };

export default defaultFonts;

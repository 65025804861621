import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import API, { methods } from '../../canvas-api';
import { getPrinterState, handleGenericError } from '../../common';
import {
  prepareMachineSettingsData,
  sanitizeMachineSettingsData,
  sanitizePrinterData,
} from '../../printers/common';

import { actions } from '../../../reducers/slicer/slicer';
import { actions as printerActions } from '../../../reducers/printers/printers';

import { createStyleCall } from '../../printers/createPrinterStyles';

import {
  ImportStylesOptions,
  ImportPrinterOptions,
} from '../../../views/import/importProject/metadata';

export default function* importSharedProject(action) {
  try {
    const { printers } = yield select(getPrinterState);
    const { sharedProject, importOptions } = action.payload;
    const shareId = sharedProject.id;
    const {
      name,
      importStyles,
      importPrinter,
      newPrinterProfileName,
      newStyleProfileName,
      importMaterials,
      importSpliceSettings,
    } = importOptions;
    let { styleId, printerId } = importOptions;
    const importEssentialStyles =
      importStyles === ImportStylesOptions.USE_ESSENTIALS;
    if (
      sharedProject.printer &&
      sharedProject.style &&
      importPrinter === ImportPrinterOptions.IMPORT_PRINTER_PROFILE &&
      importStyles === ImportStylesOptions.IMPORT_STYLE_PROFILE
    ) {
      const latestMachineSettings =
        sharedProject.printer.machineSettings[
          sharedProject.printer.machineSettings.length - 1
        ];
      const machineSettings = sanitizeMachineSettingsData(
        latestMachineSettings
      );
      const newPrinterProfile = {
        machineSettings: {
          ...machineSettings,
          name:
            newPrinterProfileName ||
            `${machineSettings.name} by ${sharedProject.ownerUsername}`,
        },
        modded: false,
      };
      const preparedMachineSettings = prepareMachineSettingsData(
        newPrinterProfile.machineSettings
      );

      const body = {
        ...preparedMachineSettings,
        tagId: newPrinterProfile.tagId,
        modded: newPrinterProfile.modded,
      };

      const response = yield call(API, {
        method: methods.PUT,
        path: 'printers',
        body,
      });
      if (response.printer === null) return;
      const sanitizedPrinter = sanitizePrinterData(response.printer);
      yield put(printerActions.createPrinterSuccess(sanitizedPrinter));
      printerId = response.printer.id;
      const sharedProjectStyle = {
        ...sharedProject.style,
        name:
          newStyleProfileName ||
          `${sharedProject.style.name} by ${sharedProject.ownerUsername}`,
      };
      const updatedStyles = yield call(
        createStyleCall,
        printerId,
        sharedProjectStyle
      );
      if (updatedStyles === null) return;
      yield put(
        printerActions.createStyleProfilesSuccess(printerId, updatedStyles)
      );
      // now get the ID of the newly-created profile
      styleId = updatedStyles[0].id;
    } else if (
      importPrinter === ImportPrinterOptions.USE_EXISTING_PRINTER_PROFILE
    ) {
      if (
        sharedProject.style &&
        importStyles === ImportStylesOptions.IMPORT_STYLE_PROFILE
      ) {
        // create a new style profile on the printer
        // const printer = printers[printerId];
        const newStyleProfile = {
          ...sharedProject.style,
          name:
            newStyleProfileName ||
            `${sharedProject.style} by ${sharedProject.ownerUsername}`,
        };
        const updatedStyles = yield call(
          createStyleCall,
          printerId,
          newStyleProfile
        );
        if (updatedStyles === null) return;
        yield put(
          printerActions.createStyleProfilesSuccess(printerId, updatedStyles)
        );

        // now get the ID of the newly-created profile
        const printer = printers[printerId];
        const currentStyleIds = _.map(printer.styles, (style) => style.id);
        const newStyleIds = _.map(updatedStyles, (style) => style.id);
        styleId = _.find(newStyleIds, (id) => !_.includes(currentStyleIds, id));
      }
    }

    const body = {
      styleId,
      printerId,
      importEssentialStyles,
      importMaterials,
      importSpliceSettings,
    };

    // optional params
    if (name) {
      body.name = name;
    }

    const response = yield call(API, {
      method: methods.PUT,
      path: `projects/shared/${shareId}`,
      body,
    });
    if (response === null) return;

    if (importMaterials) {
      yield put(actions.getMaterialsRequest());
    }
    yield put(actions.importSharedProjectSuccess(response.project));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

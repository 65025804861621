import 'regenerator-runtime/runtime';
import _ from 'lodash';
import { call, put, select, all } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';
import { TreeUtils } from '../../../utils';
import { loadStampData } from './helpers/stamps';

const getMeshClone = (model) => {
  const cloned = model.mesh.clone();
  cloned.material = cloned.material.clone();
  cloned.geometry = cloned.geometry.clone();
  // get rid of any existing stamps
  cloned.remove(...cloned.children);
  cloned.geometry.computeBoundsTree({
    maxDepth: 50,
  });
  return cloned;
};

function* getModelStamps(modelId, inputCount) {
  const { currentProject: projectId } = yield select(getSlicerState);
  const response = yield call(API, {
    path: `projects/${projectId}/models/${modelId}/stamps`,
  });
  if (response === null) return null;

  // initialize stamps map
  const stamps = {};
  const stampsArray = _.values(response.stamps);

  const stampData = yield all(
    _.map(stampsArray, (currentStamp) =>
      call(loadStampData, currentStamp, inputCount)
    )
  );

  for (let i = 0; i < stampsArray.length; i++) {
    const { id } = stampsArray[i];
    stamps[id] = stampData[i];
  }
  return stamps;
}

function* attachMeshToDuplicatedModel(model, originalModel, inputCount) {
  const newMesh = getMeshClone(originalModel);
  newMesh.name = model.id;

  const formattedModel = {
    ...model,
    active: false,
    name: model.path.replace('|', ''),
    mesh: newMesh,
    material: originalModel.material,
    transforms: {
      rotate: TreeUtils.vector3ArrToObj(model.transforms.rotate),
      translate: TreeUtils.vector3ArrToObj(model.transforms.translate),
      scale: TreeUtils.vector3ArrToObj(model.transforms.scale),
    },
  };
  if (originalModel.rle) {
    // if original model is colored, copy over its RLE
    formattedModel.rle = [...originalModel.rle];
  }
  if (model.textured && model.isNew) {
    // if model is textured, get stamps from server
    const stampResponse = yield call(getModelStamps, model.id, inputCount);
    if (stampResponse === null) return null;
    formattedModel.stamps = stampResponse;
  }
  return formattedModel;
}

function* formatNewTree(newFlatTree, originalIds) {
  const {
    models: originalTree,
    currentProject: projectId,
    projects,
  } = yield select(getSlicerState);
  const project = projects[projectId];

  const originalModels = _.filter(
    TreeUtils.flattenDeep(originalTree),
    (model) => originalIds.includes(model.id)
  );
  const newModels = _.filter(newFlatTree, 'isNew');
  const duplicatesWithMesh = yield all(
    _.map(newModels, (newModel) => {
      const originalModel = TreeUtils.searchById(
        newModel.fromId,
        originalModels
      );
      return call(
        attachMeshToDuplicatedModel,
        newModel,
        originalModel,
        project.colors.length
      );
    })
  );
  if (_.some(duplicatesWithMesh, (item) => item === null)) return null;
  const newModelTree = [];
  _.forEach(duplicatesWithMesh, (item) => {
    const parsedPath = item.path.split('|');
    if (parsedPath.length > 2) {
      const newItem = {
        ...item,
        name: parsedPath[2],
      };
      const groupIndex = _.findIndex(newModelTree, { name: parsedPath[1] });
      if (groupIndex >= 0) {
        newModelTree[groupIndex].children.push(newItem);
      } else {
        newModelTree.push({
          name: parsedPath[1],
          type: 'group',
          children: [newItem],
        });
      }
    } else {
      newModelTree.push(item);
    }
  });

  const formattedOriginalTree = TreeUtils.map(originalTree, (item) => {
    const model = item;
    model.active = false;
    model.isNew = false;
    return model;
  });

  return [...formattedOriginalTree, ...newModelTree];
}

export default function* duplicateModel(action) {
  try {
    const { currentProject: projectId } = yield select(getSlicerState);
    const { modelIds, count } = action.payload;
    const response = yield call(API, {
      method: methods.PUT,
      path: `projects/${projectId}/models/duplicate`,
      body: { ids: modelIds, count },
    });
    if (response === null) return;

    const newModelTree = yield call(formatNewTree, response.models, modelIds);
    if (_.some(newModelTree, (item) => item === null)) return;

    yield put(actions.duplicateModelSuccess(newModelTree));
    yield put(actions.invalidateSlice(projectId));
    yield put(actions.repackRequest());
    // update thumbnail
    yield put(actions.updateProjectThumbnailRequest(projectId));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import React from 'react';
import { LinkContainer, LinkWrapper } from './shareLink.styles';
import { Body1 } from '../typography/typography';

const ShareLink = (props) => (
  <LinkContainer data-test-id='share-link' disabled={props.disabled}>
    <LinkWrapper>
      <Body1 noSpacing>{props.link}</Body1>
    </LinkWrapper>
  </LinkContainer>
);

export default ShareLink;

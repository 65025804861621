import 'regenerator-runtime/runtime';
import { all, call, put } from 'redux-saga/effects';
import _ from 'lodash';

import API, {
  methods,
  handleConflictResponses,
  handleBatchCallErrorResponses,
} from '../canvas-api';
import { handleGenericError } from '../common';
import { actions } from '../../reducers/iot/iot';
import { subscribeToDevice, unsubscribeFromDevice } from './subscriptions';
import { waitForInitConnection } from './initConnection';
import { CLIENT_INIT_FAILURE_MESSAGE } from '../../utils/iot-worker/constants';

function* unlinkDeviceCall(deviceId) {
  try {
    const connectionInitialized = yield call(waitForInitConnection);
    if (!connectionInitialized) {
      throw new Error(CLIENT_INIT_FAILURE_MESSAGE);
    }

    yield call(unsubscribeFromDevice, deviceId);

    const response = yield call(API, {
      method: methods.DELETE,
      path: `devices/${deviceId}/link`,
    });
    if (response === null) return null;
    return { status: 200, unlinkedId: deviceId };
  } catch (e) {
    // failed to unlink, so re-subscribe if not a 5XX error
    if (!e.status || e.status < 500) {
      yield call(subscribeToDevice, deviceId);
    }
    return e;
  }
}

export default function* unlinkDevices(action) {
  try {
    const { deviceIds } = action.payload;
    const responses = yield all(
      _.map(deviceIds, (deviceId) => call(unlinkDeviceCall, deviceId))
    );

    if (_.some(responses, (response) => response === null)) return;

    const unlinkResponses = _.filter(
      responses,
      (response) => response.status === 200
    );
    const unlinkedIds = _.map(
      unlinkResponses,
      (response) => response.unlinkedId
    );

    yield put(actions.unlinkDevicesSuccess(unlinkedIds));

    handleConflictResponses(responses);

    handleBatchCallErrorResponses(responses);
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

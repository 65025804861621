const fieldTypes = {
  auto: 'FIELDTYPES/AUTOVALUE',
  text: 'FIELDTYPES/TEXTFIELD',
  icon: 'FIELDTYPES/ICON',
  textarea: 'FIELDTYPES/TEXTAREA',
  codearea: 'FIELDTYPES/CODEAREA',
  email: 'FIELDTYPES/EMAIL',
  password: 'FIELDTYPES/PASSWORDFIELD',
  number: 'FIELDTYPES/NUMBERFIELD',
  checkbox: 'FIELDTYPES/CHECKBOX',
  radio: 'FIELDTYPES/RADIO',
  boxSelection: 'FIELDTYPES/BOXSELECTION',
  dropdown: 'FIELDTYPES/DROPDOWN',
  mobileToolbar: 'FIELDTYPES/MOBILETOOLBAR',
};

export default fieldTypes;

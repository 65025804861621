import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import API from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

export default function* loadProjectStats(action) {
  try {
    const { projectId } = action.payload;
    const { projects } = yield select(getSlicerState);
    const response = yield call(API, {
      path: `projects/${projectId}/statistics`,
    });
    if (response === null) return;
    const project = {
      ...projects[projectId],
      stats: response.statistics,
    };
    yield put(actions.loadProjectStatsSuccess(project));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import React from 'react';
import _ from 'lodash';

import {
  Container,
  DropdownContainer,
  ClearTagButtonWrapper,
} from './tagSelector.styles';

import { ActionButton, Dropdown } from '../../../../shared';
import { Icons } from '../../../../themes';

class TagSelector extends React.Component {
  handleClear(e) {
    e.stopPropagation();
    this.props.onClear();
  }

  renderClearButton() {
    if (!this.props.tagName) return null;
    return (
      <ClearTagButtonWrapper>
        <ActionButton
          width='2rem'
          icon={Icons.basic.x}
          title='Clear printer model'
          onClick={(e) => this.handleClear(e)}
        />
      </ClearTagButtonWrapper>
    );
  }

  renderDropdown() {
    const { tagId, tagName, tags } = this.props;
    const options = _.map(tags, (tag) => ({
      value: tag.id,
      label: tag.name,
    }));
    return (
      <DropdownContainer>
        <Dropdown
          value={tagId}
          options={options}
          unfoundOptionText={tagName || 'Search printer make'}
          isOptionUnfound={!tagName}
          onChange={(id) => this.props.onSelect(id)}
          allowFilter
          filterBarPlaceholder='e.g. Prusa Mk3'
          onFilter={(name) => this.props.onQuery(name)}
          filterResultsPending={this.props.queryPrinterTagsPending}
          allowFilterCreation={(name) => name !== tagName}
          filterCreationText='Create new entry'
          onCreate={(name) => this.props.onCreate(name)}
          filterCreationPending={this.props.createPrinterTagPending}
        />
      </DropdownContainer>
    );
  }

  render() {
    return (
      <Container>
        {this.renderDropdown()}
        {this.renderClearButton()}
      </Container>
    );
  }
}

export default TagSelector;

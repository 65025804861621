/* eslint-disable */

import RuntimeError from './runtimeError';

import SequenceParserVisitor from './gen/SequenceParserVisitor';

const DEFAULT_OPTIONS = {
  maxLoopIterations: -1, // < 0 for no limit
  maxOutputSize: -1, // bytes (< 0 for no limit)
  eol: '\n',
  locals: {},
};

export default class Visitor extends SequenceParserVisitor {
  constructor(options = {}) {
    const mergedOpts = { ...DEFAULT_OPTIONS, ...options };
    super();
    this._MAX_LOOP_ITERS = mergedOpts.maxLoopIterations;
    this._MAX_OUTPUT_SIZE = mergedOpts.maxOutputSize;
    this._EOL = mergedOpts.eol;
    this._locals = new Map(Object.entries(mergedOpts.locals));
    this._loopIters = 0;
    this.result = '';
  }

  getLocal(key) {
    if (!this._locals.has(key)) {
      this._locals.set(key, 0);
      return 0;
    }
    return this._locals.get(key);
  }

  setLocal(key, value) {
    this._locals.set(key, value);
  }

  writeOutput(ctx, text) {
    const size = this.result.length + text.length;
    if (this._MAX_OUTPUT_SIZE >= 0 && size > this._MAX_OUTPUT_SIZE) {
      const { line, column } = ctx.start;
      throw new RuntimeError('maximum output size exceeded', line, column);
    }
    this.result += text;
  }

  visitIfBlock(ctx) {
    const enterIf = this.visit(ctx.children[2]);
    if (enterIf) {
      return this.visitStatements(ctx.children[5]);
    }
    if (ctx.children[7]) {
      return this.visitOptionalElseBlock(ctx.children[7]);
    }
  }

  visitOptionalElseBlock(ctx) {
    return this.visitChildren(ctx);
  }

  visitWhileBlock(ctx) {
    const conditionCtx = ctx.children[2];
    let enterWhile = this.visit(conditionCtx);
    while (enterWhile) {
      if (this._MAX_LOOP_ITERS >= 0 && this._loopIters >= this._MAX_LOOP_ITERS) {
        const { line, column } = ctx.start;
        throw new RuntimeError('maximum number of loop iterations exceeded', line, column);
      }
      // enter the loop
      if (ctx.children.length >= 7) {
        this.visitStatements(ctx.children[5]);
      }
      // check for loop exit condition
      enterWhile = this.visit(conditionCtx);
      this._loopIters++;
    }
  }

  visitAssignment(ctx) {
    const identifier = ctx.IDENTIFIER().getText();
    const value = this.visit(ctx.expr);
    this.setLocal(identifier, value);
  }

  visitGCode(ctx) {
    this.visitChildren(ctx);
    this.writeOutput(ctx, this._EOL);
  }

  visitGCodeText(ctx) {
    this.writeOutput(ctx, ctx.getText());
  }

  visitGCodeEscapedText(ctx) {
    // remove leading backslash
    const text = ctx.getText();
    if (text.length > 0) {
      this.writeOutput(ctx, text.slice(1));
    }
  }

  visitGCodeSubExpression(ctx) {
    const value = this.visit(ctx.children[1]);
    const rounded = Math.round(value * 10e5) / 10e5;
    this.writeOutput(ctx, `${rounded}`);
  }

  static _getArity(fn) {
    switch (fn) {
      case 'min':
        return 2; // minimum arity
      case 'max':
        return 2; // minimum arity
      case 'pow':
        return 2; // exact arity
      case 'abs':
      case 'floor':
      case 'ceil':
      case 'round':
      case 'trunc':
      case 'sqrt':
      case 'sin':
      case 'cos':
      case 'tan':
      case 'asin':
      case 'acos':
      case 'atan':
        return 1; // exact arity
      default:
        throw new Error(`unknown function: '${fn}'`);
    }
  }

  static _evaluateFunction(fn, args) {
    switch (fn) {
      case 'min': return Math.min(...args);
      case 'max': return Math.max(...args);
      case 'pow': {
        if (args[0] === 0 && args[1] === 0) return 1;
        return args[0] ** args[1];
      }
      case 'abs': return Math.abs(args[0]);
      case 'floor': return Math.floor(args[0]);
      case 'ceil': return Math.ceil(args[0]);
      case 'round': return Math.sign(args[0]) * Math.round(Math.abs(args[0]));
      case 'trunc': return Math.trunc(args[0]);
      case 'sqrt': {
        if (args[0] < 0) throw new Error("expected non-negative argument to 'sqrt'");
        return Math.sqrt(args[0]);
      }
      case 'sin': return Math.sin(args[0]);
      case 'cos': return Math.cos(args[0]);
      case 'tan': {
        if ((Math.abs(args[0] - (Math.PI / 2)) % Math.PI) < 1e-5) throw new Error("undefined result of 'tan'");
        return Math.tan(args[0]);
      }
      case 'asin': return Math.asin(args[0]);
      case 'acos': return Math.acos(args[0]);
      case 'atan': return Math.atan(args[0]);
      default: throw new Error(`unknown function: '${fn}'`);
    }
  }

  visitFunctionCall(ctx) {
    // name of the function
    const fn = ctx.children[0].getText();
    // get just the child nodes that are arguments to the function
    const paramChildren = [];
    for (let i = 2; // skip the function name and opening paren
         i < ctx.children.length - 1; // skip the closing paren
         i += 2 // skip commas between arguments
    ) {
      paramChildren.push(ctx.children[i]);
    }
    // check the number of arguments for an error before evaluating any of them
    const argc = paramChildren.length;
    let requiredArity;
    try {
      requiredArity = Visitor._getArity(fn);
    } catch (err) {
      const { line, column } = ctx.start;
      throw new RuntimeError(err.message, line, column);
    }

    if (fn === 'max' || fn === 'min') {
      if (argc < requiredArity) {
        const { line, column } = ctx.start;
        throw new RuntimeError(`expected at least ${requiredArity} arguments to ${fn} (${argc} given)`, line, column);
      }
    } else if (argc !== requiredArity) {
        const { line, column } = ctx.start;
        throw new RuntimeError(`expected ${requiredArity} arguments to ${fn} (${argc} given)`, line, column);
    }
    // now evaluate arguments
    const argv = paramChildren.map(child => this.visit(child));
    try {
      return Visitor._evaluateFunction(fn, argv);
    } catch (err) {
      const { line, column } = ctx.start;
      throw new RuntimeError(err.message, line, column);
    }
  }

  visitIdentExpr(ctx) {
    return this.getLocal(ctx.getText());
  }

  visitIntExpr(ctx) {
    const value = Number.parseInt(ctx.getText(), 10);
    // convert -0 to 0
    return value === 0 ? 0 : value;
  }

  visitFloatExpr(ctx) {
    // convert -0 to 0
    const value = Number.parseFloat(ctx.getText());
    return value === 0 ? 0 : value;
  }

  visitBoolExpr(ctx) {
    return ctx.getText() === 'true' ? 1 : 0;
  }

  visitParenExpr(ctx) {
    return this.visit(ctx.children[1]);
  }

  static _evaluateUnaryOp(op, operand) {
    switch (op) {
      case '!': return (!operand) ? 1 : 0;
      case '-': return operand === 0 ? operand : -operand;
      default: throw new Error(`unknown unary operator '${op}'`);
    }
  }

  visitUnaryOpExpr(ctx) {
    const op = ctx.children[0].getText();
    const operand = this.visit(ctx.children[1]);
    try {
      return Visitor._evaluateUnaryOp(op, operand);
    } catch (err) {
      const { line, column } = ctx.start;
      throw new RuntimeError(err.message, line, column);
    }
  }

  static _evaluateBinaryOp(lhs, op, rhs) {
    switch (op) {
      case '*': return lhs * rhs;
      case '/': {
        if (rhs === 0) throw new Error('division by zero encountered');
        return lhs / rhs;
      }
      case '%': {
        if (rhs === 0) throw new Error('mod by zero encountered');
        return ((lhs % rhs) + rhs) % rhs;
      }
      case '+': return lhs + rhs;
      case '-': return lhs - rhs;
      case '==': return (lhs === rhs) ? 1 : 0;
      case '!=': return (lhs !== rhs) ? 1 : 0;
      case '<': return (lhs < rhs) ? 1 : 0;
      case '<=': return (lhs <= rhs) ? 1 : 0;
      case '>': return (lhs > rhs) ? 1 : 0;
      case '>=': return (lhs >= rhs) ? 1 : 0;
      case '||': return (lhs || rhs) ? 1 : 0;
      case '&&': return (lhs && rhs) ? 1 : 0;
      default: throw new Error(`unknown binary operator '${op}'`);
    }
  }

  visitBinaryOpExpr(ctx) {
    const op = ctx.children[1].getText();
    const lhs = this.visit(ctx.children[0]);
    const rhs = this.visit(ctx.children[2]);
    try {
      return Visitor._evaluateBinaryOp(lhs, op, rhs);
    } catch (err) {
      const { line, column } = ctx.start;
      throw new RuntimeError(err.message, line, column);
    }
  }
}

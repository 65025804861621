import React from 'react';

const spliceCore = (
  <>
    Splice settings are stored separately for different versions of Palette, due
    to differences in splicing algorithms and hardware.
  </>
);

const outgoing = (
  <>
    When splicing, the outgoing material is the material that is finished being
    used. For example, when printing with PLA and changing to TPU, PLA is the
    outgoing material.
  </>
);

const ingoing = (
  <>
    When splicing, the ingoing material is the material that will be used next.
    For example, when printing with PLA and changing to TPU, TPU is the ingoing
    material.
  </>
);

const heatFactorP1 = (
  <>
    The amount of time spent heating filament. Each increment of 1 adds an
    additional 1 second to the heating time. Note that higher heating times may
    require slower print speeds.
  </>
);

const compressionFactorP1 = (
  <>
    The distance the two filament ends are compressed into one another. Each
    increment of 1 adds an additional compression of 0.6 mm to the splice.
  </>
);

const reverse = (
  <>
    Forward splicing heats the outgoing filament and compresses the ingoing
    filament into it. Reverse splicing instead heats the ingoing filament and
    reverses the outgoing filament back into it. Reverse splicing is useful for
    flexible filaments that are prone to buckling under compression.
  </>
);

const heatFactorP23 = (
  <>
    The amount of time spent heating filament. Each increment of 1 adds an
    additional 0.5 seconds to the heating time. Note that higher heating times
    may require slower print speeds.
  </>
);

const compressionFactorP23 = (
  <>
    The distance the two filament ends are compressed into one another. Each
    increment of 1 adds an additional compression of 1 mm to the splice.
  </>
);

const coolingFactor = (
  <>
    The amount of time spent cooling filament. Each increment of 1 adds an
    additional 1 second to the cooling time. Note that higher cooling times may
    require slower print speeds.
  </>
);

export default {
  spliceCore,
  outgoing,
  ingoing,
  heatFactorP1,
  compressionFactorP1,
  reverse,
  heatFactorP23,
  compressionFactorP23,
  coolingFactor,
};

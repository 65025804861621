import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import Constants from '../../constants';
import API, { methods } from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { InterfaceUtils, NotificationUtils } from '../../../utils';
import { actions } from '../../../reducers/slicer/slicer';

export default function* dispatchSliceJob() {
  const { currentProject: projectId, projects } = yield select(getSlicerState);

  let response;
  try {
    response = yield call(API, {
      method: methods.PUT,
      path: 'slice/job',
      body: {
        projectId,
      },
    });
    if (response === null) return;

    const { stats, ...projectWithNoSlice } = {
      ...projects[projectId],
      sliced: false,
    };

    const { name, timestamp } = response.sliceJob;

    const jobInfo = {
      name,
      timestamp,
      progress: 0,
      status: 'Preparing to slice',
    };

    yield put(actions.dispatchSliceJobSuccess(projectWithNoSlice, jobInfo));
  } catch (err) {
    yield put(actions.dispatchSliceJobFailure(projectId, err.message));
    if (NotificationUtils.shouldSendNative()) {
      yield call(NotificationUtils.sendNative, 'Failed to slice', err.message);
    } else {
      InterfaceUtils.emitToast('error', err.message);
    }
  }
}

export function* handleSliceJobDispatched(payload) {
  const { id, name, timestamp } = payload;

  const jobInfo = {
    name,
    timestamp,
    progress: 0,
    status: 'Preparing to slice',
  };

  yield put(actions.updateSliceJob(id, jobInfo));
}

export function* handleSliceJobUpdate(jobUpdate) {
  const { error, timestamp, projectId, type, payload } = jobUpdate;

  const { currentProject, projects, sliceJobs } = yield select(getSlicerState);

  const sliceJob = sliceJobs[projectId];

  if (!sliceJob || sliceJob.timestamp !== timestamp) {
    // slice job is not found in store, or the stored job's timestamp does not match
    // ignore the job update
    return;
  }

  if (error) {
    yield put(actions.cancelSliceJobSuccess(projectId));
    const message =
      payload && payload.message
        ? payload.message
        : Constants.SLICE_ERROR_MESSAGE;
    if (NotificationUtils.shouldSendNative()) {
      yield call(NotificationUtils.sendNative, 'Failed to slice', message);
    } else {
      InterfaceUtils.emitToast('error', message);
    }
    return;
  }

  if (type === 'PROGRESS') {
    const jobInfo = {
      progress: payload.progress,
    };
    if (payload.status) {
      jobInfo.status = payload.status;
    }

    yield put(actions.updateSliceJob(projectId, jobInfo));
  } else if (type === 'WARNING') {
    InterfaceUtils.emitToast('warn', payload.message);
  } else if (type === 'SUCCESS') {
    // check if project is already loaded in store
    const projectInStore = projects[projectId];

    let slicedProject = null;
    if (projectInStore) {
      // only load toolpath data and update project object, if already loaded
      const response = yield call(API, {
        path: `projects/${projectId}`,
      });
      if (response === null) return;

      const ptpHeader = response.project.ptpHeader || {};
      slicedProject = {
        ...projectInStore,
        sliced: true,
        ptpHeader,
      };
    }

    yield put(actions.completeSliceJob(projectId, slicedProject));
    const message = `${sliceJob.name} has finished slicing!`;
    if (NotificationUtils.shouldSendNative()) {
      // Canvas is not in focus; send native OS notification
      yield call(NotificationUtils.sendNative, 'Slicing complete', message);
    } else if (currentProject !== projectId) {
      // this project is not currently open; notify user in-app
      InterfaceUtils.emitToast('success', message);
    }
  }
}

export function* cancelSliceJob(action) {
  const { projectId } = action.payload;

  try {
    yield call(API, {
      method: methods.DELETE,
      path: `slice/job/${projectId}`,
    });

    yield put(actions.cancelSliceJobSuccess(projectId));
  } catch (e) {
    if (e.status === 404) {
      // job to be cancelled was not found -- treat as success
      yield put(actions.cancelSliceJobSuccess(projectId));
    } else {
      yield call(handleGenericError, action, e);
    }
  }
}

export function* handleSliceJobCanceled(payload) {
  const { id, timestamp } = payload;
  const { sliceJobs } = yield select(getSlicerState);

  if (!sliceJobs[id] || sliceJobs[id].timestamp !== timestamp) {
    // slice job is not found in store, or the stored job's timestamp does not match
    // ignore this update
    return;
  }

  yield put(actions.cancelSliceJobSuccess(id));
}

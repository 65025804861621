import styled, { css } from 'styled-components';
import { Breakpoints } from '../../themes';
import { ButtonText } from '../typography/typography';

const buttonReset = css`
  &:active,
  &:focus {
    outline: none;
  }
`;

export const IconContainer = styled.div`
  margin-right: ${(props) => (props.noText ? '0' : '0.5rem')};
  justify-content: center;
  align-items: center;
`;

export const StyledButton = styled.button`
  ${buttonReset}
  margin: 0;
  min-width: ${(props) => props.minWidth};
  padding: 0 1rem;
  height: ${(props) => props.height};
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  user-select: none;
  pointer-events: auto;

  white-space: nowrap;
  box-sizing: border-box;
  ${(props) =>
    props.upperCase &&
    css`
      text-transform: uppercase;
    `}

  ${(props) =>
    props.compact &&
    css`
      padding: 0 0.5rem;
    `}

  ${(props) =>
    props.expand &&
    css`
      width: 100%;
    `}

  /*                     Default (Secondary)                    */
  border: 1px solid ${(props) => props.theme.colors.greenDefault};
  ${ButtonText} {
    color: ${(props) => props.theme.colors.greenDefault};
  }
  background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};

  &:hover {
    border-color: ${(props) => props.theme.colors.greenDark};
    ${ButtonText} {
      color: ${(props) => props.theme.colors.greenDark};
    }
    background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  }

  /*                    Minimal                    */
  ${(props) =>
    props.minimal &&
    css`
      border: none;
      ${ButtonText} {
        color: ${props.buttonTextColor
          ? props.buttonTextColor
          : props.theme.colors.textPrimary};
      }
      background: transparent;

      &:hover {
        border: none;
        ${ButtonText} {
          color: ${props.buttonTextColor
            ? props.buttonTextColor
            : props.theme.colors.textPrimary};
        }
        background: transparent;
      }
    `}

  /*                    Clean                    */
  ${(props) =>
    props.clean &&
    css`
      border-color: ${props.theme.colors.grey3};
      ${ButtonText} {
        color: ${props.buttonTextColor
          ? props.buttonTextColor
          : props.theme.colors.textPrimary};
      }
      background: ${props.theme.colors.elevatedBackgroundPrimary};

      &:hover {
        border-color: ${props.theme.colors.grey3};
        ${ButtonText} {
          color: ${props.buttonTextColor
            ? props.buttonTextColor
            : props.theme.colors.textPrimary};
        }
        background: ${props.theme.colors.elevatedBackgroundSecondary};
      }
    `}

  /*                    Toggled                    */
  ${(props) =>
    props.toggled &&
    css`
      border-color: 1px solid ${props.theme.colors.greenDefault};
      ${ButtonText} {
        color: ${props.buttonTextColor
          ? props.buttonTextColor
          : props.theme.colors.greenDefault};
      }
      background: ${props.theme.colors
        .greenDefault}30; // RGBA hex syntax (#rrggbbaa)

      &:hover {
        border-color: 1px solid ${props.theme.colors.greenDark};
        ${ButtonText} {
          color: ${props.buttonTextColor
            ? props.buttonTextColor
            : props.theme.colors.greenDark};
        }
        background: ${props.theme.colors.greenDark}30;
      }
    `}

  /*                    Warning                    */
  ${(props) =>
    props.warning &&
    css`
      border-color: ${props.theme.colors.redDefault};
      ${ButtonText} {
        color: ${props.buttonTextColor
          ? props.buttonTextColor
          : props.theme.colors.redDefault};
      }

      &:hover {
        border-color: ${props.theme.colors.redDark};
        ${ButtonText} {
          color: ${props.buttonTextColor
            ? props.buttonTextColor
            : props.theme.colors.redDark};
        }
      }
    `}

  /*                    Primary                    */
  ${(props) =>
    props.primary &&
    css`
      border-color: ${props.theme.colors.greenDefault};
      ${ButtonText} {
        color: ${props.buttonTextColor
          ? props.buttonTextColor
          : props.theme.colors.whiteDefault};
      }
      background: ${props.theme.colors.greenDefault};

      &:hover {
        border-color: ${props.theme.colors.greenDark};
        ${ButtonText} {
          color: ${props.buttonTextColor
            ? props.buttonTextColor
            : props.theme.colors.whiteDefault};
        }
        background: ${props.theme.colors.greenDark};
      }
    `}

  /*                    Primary & Warning                   */
  ${(props) =>
    props.primary &&
    props.warning &&
    css`
      border-color: ${props.theme.colors.redDefault};
      ${ButtonText} {
        color: ${props.buttonTextColor
          ? props.buttonTextColor
          : props.theme.colors.whiteDefault};
      }
      background: ${props.theme.colors.redDefault};

      &:hover {
        border-color: ${props.theme.colors.redDark};
        ${ButtonText} {
          color: ${props.buttonTextColor
            ? props.buttonTextColor
            : props.theme.colors.whiteDefault};
        }
        background: ${props.theme.colors.redDark};
      }
    `}

  /*                    Tab                   */
  ${(props) =>
    props.tab &&
    css`
      padding: 0 0.5rem;
      border-radius: 0;

      border: none;
      ${ButtonText} {
        color: ${props.buttonTextColor
          ? props.buttonTextColor
          : props.theme.colors.textPrimary};
      }
      background: transparent;

      &:hover {
        border: none;
        ${ButtonText} {
          color: ${props.buttonTextColor
            ? props.buttonTextColor
            : props.theme.colors.greenDark};
        }
        background: transparent;
      }

      @media (max-width: ${Breakpoints.mediumWidth}) {
        font-size: 0.8rem;
      }
    `}

  /*                    ActiveTab                   */
  ${(props) =>
    props.activeTab &&
    css`
      padding: 0 0.5rem;
      border-radius: 0;

      border: none;
      ${ButtonText} {
        color: ${props.buttonTextColor
          ? props.buttonTextColor
          : props.theme.colors.greenDefault};
      }
      background: transparent;

      &:hover {
        border: none;
        ${ButtonText} {
          color: ${props.buttonTextColor
            ? props.buttonTextColor
            : props.theme.colors.greenDark};
        }

        background: transparent;
      }

      @media (max-width: ${Breakpoints.mediumWidth}) {
        font-size: 0.8rem;
      }
    `}

  /*                    Link                    */
  ${(props) =>
    props.link &&
    css`
      padding: 0;
      font-size: 0.8rem;
      width: auto;
      height: auto;

      border-color: transparent;
      ${ButtonText} {
        color: ${props.buttonTextColor
          ? props.buttonTextColor
          : props.theme.colors.grey5};
      }
      background: transparent;

      &:hover {
        border-color: transparent;
        ${ButtonText} {
          color: ${props.buttonTextColor
            ? props.buttonTextColor
            : props.theme.colors.purpleDefault};
        }
        background: transparent;
      }
    `}

  /*                    Disabled                    */
  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      pointer-events: none;

      border-color: ${props.theme.colors.grey3};
      background: ${props.theme.colors.elevatedBackgroundTertiary};
      ${ButtonText} {
        color: ${props.buttonTextColor
          ? props.buttonTextColor
          : props.theme.colors.textTertiary};
      }

      ${props.clean &&
      css`
        background: ${props.theme.colors.elevatedBackgroundPrimary};
      `}
      ${props.minimal && 'border: none; background: transparent;'}
    `}
`;

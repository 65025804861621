import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  border-bottom: 3px solid
    ${(props) =>
      props.active && !props.noBorder
        ? props.theme.colors.greenDefault
        : 'transparent'};
  padding: 0 ${(props) => props.sidePadding};
  display: flex;
  align-items: center;
`;

import styled, { css } from 'styled-components';

export const LinkContainer = styled.div`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.grey4};
  border-radius: 0.2rem;
  background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  height: 2.4rem;

  ${(props) =>
    props.disabled &&
    css`
      border-color: ${props.theme.colors.grey3};
      color: ${props.theme.colors.textTertiary};
      background: ${props.theme.colors.elevatedBackgroundTertiary};
    `}
`;

export const LinkWrapper = styled.div`
  width: 100%;
  max-width: none;
  margin: 0.4rem 0.6rem 0rem 0.6rem;
  overflow-y: hidden;
  overflow-x: auto;
  user-select: all;
  text-align: left;
  white-space: nowrap;
`;

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { withTheme } from 'styled-components';

import {
  CheckboxContainer,
  DropdownContainer,
  BedGraphic,
  BedGraphicContainer,
  BedUnitXWrapper,
  BedUnitYWrapper,
  OriginMarker,
  IconsContainer,
  IconOption,
  GCodeLine,
  PrinterIcon,
  SequenceEditorWrapper,
  SequenceWarningsButtonWrapper,
  TextFieldInputWrapper,
  TextWrapper,
} from './configurePrinter.styles';

import {
  GeneralFields,
  ExtruderFields,
  BedFields,
  FirmwareFields,
  TransitionFields,
  SequenceFields,
  originOffsetPresets,
} from './fields.metadata';

import { parse as parseScript } from '../../../utils/printerscript';

import {
  Body1,
  Button,
  Caption,
  Checkbox,
  Dropdown,
  Form,
  Input,
  Link,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  SettingsModal,
  SequenceEditor,
  SideBar,
  Subtitle2,
  ToolTipWrapper,
} from '../../../shared';

import TagSelector from './tagSelector/tagSelector.jsx';
import ToolTipWithChart from '../toolTipWithChart/toolTipWithChart.jsx';

import {
  defaultPrinter,
  defaultStyle,
} from '../../../reducers/printers/initialState';
import { fieldTypes } from '../../../constants';
import { InterfaceUtils } from '../../../utils';
import Routes from '../../../router/routes';

const tabs = [
  {
    text: 'General',
  },
  {
    text: 'Extruder',
  },
  {
    text: 'Bed',
  },
  {
    text: 'Firmware',
  },
  {
    text: 'Transition',
  },
  {
    text: 'Sequences',
  },
];

class ConfigurePrinter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabIndex: 0,
      focusField: null,
      showSequenceWarningsModal: false,
      originOffsetPreset: 1,
      machineSettings: { ...defaultPrinter.machineSettings },
      errors: {},
      warnings: {},
    };
  }

  componentDidMount() {
    const { mode, printer, history, match } = this.props;
    if (mode === 'create') {
      this.props.clearPrinterTag();
    } else if (mode === 'edit') {
      const { printerId } = match.params;
      if (!printer) {
        history.push(Routes.toViewPrinter(printerId));
      } else {
        if (printer.tagId) {
          this.props.getPrinterTag(printer.tagId);
        } else {
          this.props.clearPrinterTag();
        }

        const { machineSettings } = printer;
        this.setState({
          originOffsetPreset: this.getOriginOffsetPreset(),
          machineSettings,
        });
      }
    } else if (mode === 'import') {
      if (!printer) {
        history.push(Routes.toManagePrinters());
      } else {
        const { machineSettings, warnings } = printer;
        this.props.clearPrinterTag();
        this.setState({
          originOffsetPreset: this.getOriginOffsetPreset(),
          showSequenceWarningsModal: !_.isEmpty(warnings),
          machineSettings,
          warnings,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { mode, printers, history } = this.props;
    if (mode === 'create' || mode === 'import') {
      const { createPrinterPending, createPrinterSuccess } = this.props;
      const printerCreated =
        prevProps.createPrinterPending &&
        !createPrinterPending &&
        createPrinterSuccess;
      if (printerCreated) {
        const createdPrinter = _.find(
          printers,
          (printer) => !prevProps.printers[printer.id]
        );
        if (createdPrinter) {
          history.push(Routes.toViewPrinter(createdPrinter.id));
        }
      }
    } else if (mode === 'edit') {
      const { updatePrinterPending, updatePrinterSuccess } = this.props;
      const printerUpdated =
        prevProps.updatePrinterPending &&
        !updatePrinterPending &&
        updatePrinterSuccess;
      if (printerUpdated) {
        this.props.onClickSave();
      }
    }
  }

  componentWillUnmount() {
    if (this.props.mode === 'import') {
      this.props.resetParsedProfile();
    }
  }

  hideSequenceWarningsModal() {
    this.setState({
      showSequenceWarningsModal: false,
    });
  }

  focusField(fieldName) {
    this.setState({ focusField: fieldName }, () => {
      this.setState({ focusField: null });
    });
  }

  getOriginOffsetPreset() {
    const { machineSettings } = this.props.printer;
    const { originOffset, bedSize } = machineSettings;
    if (originOffset.x === 0 && originOffset.y === 0) {
      return originOffsetPresets.bottomleft;
    }
    if (
      Math.abs(originOffset.x - bedSize.x / 2) < 10e-3 &&
      Math.abs(originOffset.y - bedSize.y / 2) < 10e-3
    ) {
      return originOffsetPresets.middle;
    }
    return originOffsetPresets.custom;
  }

  updatedErrors(fieldName, error) {
    if (fieldName === 'bedSize.x') {
      return {
        ...this.state.errors,
        [fieldName]: error,
        'bedSize.diameter': error,
      };
    }
    if (fieldName === 'bedSize.diameter') {
      return {
        ...this.state.errors,
        [fieldName]: error,
        'bedSize.x': error,
        'bedSize.y': error,
      };
    }
    return {
      ...this.state.errors,
      [fieldName]: error,
    };
  }

  updateField(fieldName, value, error = false) {
    const updatedMachineSettings = { ...this.state.machineSettings };
    if (fieldName === 'bedSize.diameter') {
      const [field] = fieldName.split('.');
      updatedMachineSettings[field] = {
        ...this.state.machineSettings[field],
        x: value,
        y: value,
      };
      this.setState({
        machineSettings: updatedMachineSettings,
        errors: this.updatedErrors(fieldName, error),
      });
      return;
    }
    if (fieldName.includes('.')) {
      // nested properties
      const [field, key] = fieldName.split('.');
      updatedMachineSettings[field] = {
        ...this.state.machineSettings[field],
        [key]: value,
      };
      this.setState({
        machineSettings: updatedMachineSettings,
        errors: this.updatedErrors(fieldName, error),
      });
      return;
    }
    if (fieldName.includes('[')) {
      const parts = fieldName.split('[');
      const [field] = parts;
      const key = parts[1].slice(0, parts[1].indexOf(']'));
      const index = parseInt(key, 10);
      updatedMachineSettings[field] = [...this.state.machineSettings[field]];
      updatedMachineSettings[field][index] = value;
      this.setState({
        machineSettings: updatedMachineSettings,
        errors: this.updatedErrors(fieldName, error),
      });
      return;
    }
    updatedMachineSettings[fieldName] = value;
    this.setState({
      machineSettings: updatedMachineSettings,
      errors: this.updatedErrors(fieldName, error),
    });
  }

  onFieldChange(fieldName, value) {
    switch (fieldName) {
      case 'firmwareRetraction': {
        this.updateField('firmwareRetraction', value ? 2 : 0);
        return;
      }
      case 'originOffsetPreset': {
        if (value === originOffsetPresets.bottomleft) {
          this.setState({
            machineSettings: {
              ...this.state.machineSettings,
              originOffset: { x: 0, y: 0 },
            },
            errors: {
              ...this.state.errors,
              [fieldName]: false,
            },
            originOffsetPreset: originOffsetPresets.bottomleft,
          });
        } else if (value === originOffsetPresets.middle) {
          this.setState({
            machineSettings: {
              ...this.state.machineSettings,
              originOffset: {
                x: this.state.machineSettings.bedSize.x / 2,
                y: this.state.machineSettings.bedSize.y / 2,
              },
            },
            errors: {
              ...this.state.errors,
              [fieldName]: false,
            },
            originOffsetPreset: originOffsetPresets.middle,
          });
        } else {
          this.setState({ originOffsetPreset: originOffsetPresets.custom });
        }
        return;
      }
      case 'extruderCount': {
        const nozzleDiameters = [];
        const filamentDiameters = [];
        for (let i = 0; i < value; i++) {
          nozzleDiameters[i] = this.state.machineSettings.nozzleDiameter[i];
          filamentDiameters[i] = this.state.machineSettings.filamentDiameter[i];
          if (nozzleDiameters[i] === undefined) {
            [nozzleDiameters[i]] = nozzleDiameters;
          }
          if (filamentDiameters[i] === undefined) {
            [filamentDiameters[i]] = filamentDiameters;
          }
        }
        this.setState({
          machineSettings: {
            ...this.state.machineSettings,
            nozzleDiameter: nozzleDiameters,
            filamentDiameter: filamentDiameters,
            extruderCount: value,
          },
          errors: {
            ...this.state.errors,
            [fieldName]: false,
          },
        });
        return;
      }
      case 'circular': {
        const newMachineSettings = { ...this.state.machineSettings };
        if (value === true) {
          newMachineSettings.bedSize = {
            ...newMachineSettings.bedSize,
            y: newMachineSettings.bedSize.x,
          };
        }
        newMachineSettings.circular = value;
        this.setState({
          machineSettings: newMachineSettings,
          errors: {
            ...this.state.errors,
            [fieldName]: false,
          },
        });
        return;
      }
      case 'bedSize.x': {
        const newMachineSettings = {
          ...this.state.machineSettings,
          bedSize: {
            ...this.state.machineSettings.bedSize,
            x: value,
          },
          originOffset: { ...this.state.machineSettings.originOffset },
        };
        const newErrors = {
          ...this.state.errors,
          [fieldName]: false,
          'bedSize.diameter': false,
        };
        if (this.state.originOffsetPreset === originOffsetPresets.middle) {
          newMachineSettings.originOffset.x = value / 2;
          newErrors['originOffset.x'] = false;
        }
        this.setState({
          machineSettings: newMachineSettings,
          errors: newErrors,
        });
        return;
      }
      case 'bedSize.y': {
        const newMachineSettings = {
          ...this.state.machineSettings,
          bedSize: {
            ...this.state.machineSettings.bedSize,
            y: value,
          },
          originOffset: { ...this.state.machineSettings.originOffset },
        };
        const newErrors = {
          ...this.state.errors,
          [fieldName]: false,
        };
        if (this.state.originOffsetPreset === originOffsetPresets.middle) {
          newMachineSettings.originOffset.y = value / 2;
          newErrors['originOffset.y'] = false;
        }
        this.setState({
          machineSettings: newMachineSettings,
          errors: newErrors,
        });
        return;
      }
      case 'bedSize.diameter': {
        const newMachineSettings = {
          ...this.state.machineSettings,
          bedSize: {
            ...this.state.machineSettings.bedSize,
            x: value,
            y: value,
          },
        };
        const newErrors = {
          ...this.state.errors,
          [fieldName]: false,
          'bedSize.x': false,
          'bedSize.y': false,
        };
        this.setState({
          machineSettings: newMachineSettings,
          errors: newErrors,
        });
        return;
      }
      default:
        this.updateField(fieldName, value);
    }
  }

  onFieldError(fieldName, value) {
    this.setState(
      {
        errors: {
          ...this.state.errors,
          [fieldName]: true,
        },
      },
      () => {
        this.updateField(fieldName, value, true);
      }
    );
  }

  isDisabled(field) {
    let isDisabled = !!field.disabled;
    if (typeof field.disabled === 'function') {
      isDisabled = field.disabled({
        originOffsetPreset: this.state.originOffsetPreset,
        ...this.state.machineSettings,
      });
    }
    return isDisabled;
  }

  getValue(field) {
    let value = this.state.machineSettings[field.name];
    if (typeof field.value === 'function') {
      value = field.value(this.state.machineSettings);
    }
    if (field.name === 'originOffsetPreset') {
      return this.state[field.name];
    }
    return value;
  }

  composeSequenceWarnings() {
    const { warnings } = this.state;
    if (!_.isEmpty(warnings)) {
      const warnStart =
        warnings.startSequence && !_.isEmpty(warnings.startSequence);
      const warnEnd = warnings.endSequence && !_.isEmpty(warnings.endSequence);
      if (warnStart || warnEnd) {
        const warnBoth = warnStart && warnEnd;
        let label;
        if (warnBoth) {
          label = 'start/end sequence';
        } else if (warnStart) {
          label = 'start sequence';
        } else if (warnEnd) {
          label = 'end sequence';
        }
        const intro = (
          <Body1 noSpacing>
            {`Canvas automatically converted supported variables found in your ${label}. However, the following lines contain unsupported syntax or variables:`}
          </Body1>
        );
        const outro = (
          <Body1>
            These lines have been converted to comments to prevent issues when
            printing.
          </Body1>
        );
        let startWarningHeader = null;
        let startWarningContent = null;
        if (warnStart) {
          if (warnBoth) {
            startWarningHeader = <Body1>Start sequence:</Body1>;
          }
          startWarningContent = (
            <ul>
              {_.map(warnings.startSequence, (line, index) => (
                <GCodeLine key={index}>{line}</GCodeLine>
              ))}
            </ul>
          );
        }
        let endWarningHeader = null;
        let endWarningContent = null;
        if (warnEnd) {
          if (warnBoth) {
            endWarningHeader = <Body1>End sequence:</Body1>;
          }
          endWarningContent = (
            <ul>
              {_.map(warnings.endSequence, (line, index) => (
                <GCodeLine key={index}>{line}</GCodeLine>
              ))}
            </ul>
          );
        }
        return (
          <>
            {intro}
            {startWarningHeader}
            {startWarningContent}
            {endWarningHeader}
            {endWarningContent}
            {outro}
          </>
        );
      }
    }
    return null;
  }

  getNextSaveError() {
    const { errors, activeTabIndex } = this.state;
    // prioritize errors on the current tab
    const tabNames = [
      tabs[activeTabIndex].text,
      ..._.filter(
        tabs,
        (tabName) => tabName.text !== tabs[activeTabIndex].text
      ).map((item) => item.text),
    ];
    // reduce over each tab
    return _.reduce(
      tabNames,
      (tabError, tab) => {
        if (tabError) return tabError;
        // reduce over each field group in the tab
        const tabFields = this.getTabMeta(tab);
        return _.reduce(
          tabFields,
          (groupError, fieldGroup) => {
            if (groupError) return groupError;
            // reduce over each field in the group
            const { fields } = fieldGroup;
            return _.reduce(
              fields,
              (error, field) => {
                if (error) return error;
                if (errors[field.name]) {
                  return {
                    tab,
                    fieldName: field.name,
                  };
                }
                return null;
              },
              null
            );
          },
          null
        );
      },
      null
    );
  }

  getNextScriptError() {
    // too expensive to parse PrinterScript on change,
    // so we only check for syntax errors on save
    // (CodeMirror syntax highlighting is not to be trusted!)
    const scriptFieldNames = _.map(
      SequenceFields.fields,
      (field) => field.name
    );
    return _.reduce(
      scriptFieldNames,
      (tabError, fieldName) => {
        if (tabError) return tabError;
        const value = this.state.machineSettings[fieldName];
        if (
          fieldName === 'sideTransitionSequence' ||
          value.startsWith('@printerscript 1.0')
        ) {
          let script = `${value}\n`;
          if (script.startsWith('@printerscript 1.0')) {
            script = script.slice(script.indexOf('\n'));
          }
          try {
            parseScript(script);
          } catch (error) {
            return {
              error: typeof error === 'string' ? new Error(error) : error,
              fieldName,
            };
          }
        }
        return null;
      },
      null
    );
  }

  composeDefaultStylesFromPrinter(printerData) {
    const style = {
      ...defaultStyle,
      extrusionWidth: printerData.machineSettings.nozzleDiameter[0],
    };
    return [style];
  }

  handleSave() {
    const validationError = this.getNextSaveError();
    if (validationError) {
      const { tab, fieldName } = validationError;
      const errorTabIndex = _.map(tabs, (tabName, index) => {
        return tabName.text === tab ? index : null;
      })
        .filter((item) => item !== null)
        .reduce((current) => current);
      this.setState(
        {
          activeTabIndex: errorTabIndex,
        },
        () => {
          this.focusField(fieldName);
        }
      );
      return;
    }

    const scriptError = this.getNextScriptError();
    if (scriptError) {
      const { error, fieldName } = scriptError;
      const field = _.find(
        SequenceFields.fields,
        (meta) => meta.name === fieldName
      );
      const label = field.label.toLowerCase();
      const syntaxError = error.message
        .slice(error.message.indexOf(':') + 1)
        .trim();
      const message = `Syntax error in ${label} sequence: ${syntaxError}`;
      this.setState(
        {
          activeTabIndex: 5,
        },
        () => {
          InterfaceUtils.emitToast('error', message);
        }
      );
      return;
    }

    const { mode, match, printer, printerTag } = this.props;

    const printerData = {
      machineSettings: this.state.machineSettings,
      modded: printer.modded || false,
    };

    // also include tag id, if available
    if (printerTag) printerData.tagId = printerTag.id;

    if (mode === 'create') {
      // create printer along with default style
      const styles = this.composeDefaultStylesFromPrinter(printerData);

      this.props.createPrinter(printerData, styles);
    } else if (mode === 'import') {
      // create printer with imported styles, or with default style
      const styles = _.isEmpty(printer.styles)
        ? this.composeDefaultStylesFromPrinter(printerData)
        : printer.styles;

      this.props.createPrinter(printerData, styles);
    } else if (mode === 'edit') {
      const { printerId } = match.params;
      this.props.updatePrinter(printerId, printerData);
    }
  }

  renderTooltipWithChart(field) {
    if (!field.tooltip) return null;
    if (!this.props.printerTag) return field.tooltip;
    return (
      <ToolTipWithChart
        field={field}
        currentValue={this.getValue(field)}
        printerTag={this.props.printerTag}
      />
    );
  }

  renderSequenceWarningModal() {
    if (!this.state.showSequenceWarningsModal) return null;
    const sequenceWarnings = this.composeSequenceWarnings();
    return (
      <Modal width='30rem'>
        <ModalHeader>
          <Subtitle2>Warning</Subtitle2>
        </ModalHeader>
        <ModalBody>{sequenceWarnings}</ModalBody>
        <ModalFooter>
          <SequenceWarningsButtonWrapper>
            <Button primary onClick={() => this.hideSequenceWarningsModal()}>
              Got it
            </Button>
          </SequenceWarningsButtonWrapper>
        </ModalFooter>
      </Modal>
    );
  }

  renderCheckbox(field) {
    if (field.type !== fieldTypes.checkbox && !field.canDisable) return null;
    return (
      <CheckboxContainer key={field.name}>
        <ToolTipWrapper wide tooltip={this.renderTooltipWithChart(field)}>
          <Checkbox
            label={field.label}
            value={this.getValue(field)}
            disabled={this.isDisabled(field)}
            onChange={(e) => this.onFieldChange(field.name, e.target.checked)}
          />
        </ToolTipWrapper>
      </CheckboxContainer>
    );
  }

  renderTextField(field) {
    return (
      <TextFieldInputWrapper>
        <Input
          type='text'
          key={field.name}
          infoTooltip={this.renderTooltipWithChart(field)}
          wideTooltip
          isInvalid={this.state.errors[field.name]}
          forceFocus={this.state.focusField === field.name}
          value={this.getValue(field)}
          disabled={this.isDisabled(field)}
          onChangeSuccess={(value) => this.onFieldChange(field.name, value)}
          onChangeFailure={(value) => this.onFieldError(field.name, value)}
        />
      </TextFieldInputWrapper>
    );
  }

  renderIconField(field) {
    return (
      <IconsContainer key={field.name}>
        {_.map(field.options, (option) => this.renderIcon(option))}
      </IconsContainer>
    );
  }

  renderIcon(option) {
    return (
      <IconOption
        key={option}
        onClick={() => this.onFieldChange('icon', option)}
        isCurrent={option === this.state.machineSettings.icon}>
        <PrinterIcon
          alt='printer icon'
          src={this.props.theme.images.printerIcons[option]}
        />
      </IconOption>
    );
  }

  renderNumberField(field) {
    const { min, gte, max, lte, step } = field.custom;
    return (
      <TextFieldInputWrapper>
        <Input
          type='number'
          key={field.name}
          infoTooltip={this.renderTooltipWithChart(field)}
          wideTooltip
          isInvalid={this.state.errors[field.name]}
          forceFocus={this.state.focusField === field.name}
          disabled={this.isDisabled(field)}
          value={this.getValue(field)}
          min={min}
          gte={gte}
          max={max}
          lte={lte}
          step={step}
          units={field.units}
          onChangeSuccess={(value) => this.onFieldChange(field.name, value)}
          onChangeFailure={(value) => this.onFieldError(field.name, value)}
        />
      </TextFieldInputWrapper>
    );
  }

  renderDropdown(field) {
    let { options } = field;
    if (typeof options === 'function') {
      options = options(this.state.machineSettings);
    }
    const isDisabled = this.isDisabled(field);
    return (
      <DropdownContainer key={field.name}>
        <ToolTipWrapper wide tooltip={this.renderTooltipWithChart(field)}>
          <Dropdown
            value={this.getValue(field)}
            options={options}
            disabled={isDisabled}
            onChange={(value) => {
              const newValue = field.numeric ? parseFloat(value) : value;
              this.onFieldChange(field.name, newValue);
            }}
          />
        </ToolTipWrapper>
      </DropdownContainer>
    );
  }

  renderBedGraphic() {
    const { circular, bedSize, originOffset } = this.state.machineSettings;
    const markerX = (originOffset.x / Math.max(1, bedSize.x)) * 100;
    const markerY = (originOffset.y / Math.max(1, bedSize.y)) * 100;
    return (
      <BedGraphicContainer>
        <BedGraphic circular={circular}>
          <BedUnitXWrapper>
            <Caption noSpacing>{`${bedSize.x || '0'} mm`}</Caption>
          </BedUnitXWrapper>
          {!circular && (
            <BedUnitYWrapper>
              <Caption noSpacing>{`${bedSize.y || '0'} mm`}</Caption>
            </BedUnitYWrapper>
          )}
          <OriginMarker x={markerX} y={markerY} />
        </BedGraphic>
      </BedGraphicContainer>
    );
  }

  getTabMeta(tabName) {
    switch (tabName) {
      case 'Extruder':
        return ExtruderFields;
      case 'Bed':
        return BedFields;
      case 'Firmware':
        return FirmwareFields;
      case 'Transition':
        return TransitionFields;
      case 'Sequence':
        return SequenceFields;
      case 'General':
        return GeneralFields;
      default:
        return null;
    }
  }

  renderTagSelector() {
    const {
      tags,
      printerTag,
      queryPrinterTagsPending,
      getPrinterTagPending,
      createPrinterTagPending,
    } = this.props;

    return (
      <TagSelector
        key={-1}
        tags={tags}
        queryPrinterTagsPending={queryPrinterTagsPending}
        getPrinterTagPending={getPrinterTagPending}
        createPrinterTagPending={createPrinterTagPending}
        tagId={printerTag ? printerTag.id : ''}
        tagName={printerTag ? printerTag.name : ''}
        onQuery={(queryString) => this.props.queryPrinterTags(queryString)}
        onSelect={(tagId) => this.props.getPrinterTag(tagId)}
        onCreate={(tagName) => this.props.createPrinterTag(tagName)}
        onClear={() => this.props.clearPrinterTag()}
      />
    );
  }

  renderButtons() {
    return (
      <>
        <Button expand onClick={() => this.props.onClickClose()}>
          Cancel
        </Button>
        <Button primary onClick={() => this.handleSave()}>
          Save printer
        </Button>
      </>
    );
  }

  renderHeaderTitle() {
    const { printer, mode } = this.props;
    let label;
    if (mode === 'create') {
      label = 'New Printer Profile';
    }
    if (mode === 'edit') {
      const printerName = printer.machineSettings.name;
      label = `Edit ${printerName}`;
    }
    if (mode === 'import') {
      label = 'Import Printer Profile';
    }
    return label;
  }

  setActiveTabIndex(index) {
    return this.setState({ activeTabIndex: index });
  }

  combinedArray(a, b) {
    return a.reduce((c, d, i) => c.concat(d, b[i]), []);
  }

  renderVisibleFields(group) {
    const filterGroup = group.fields ? group.fields : group;
    return _.filter(filterGroup, (field) => {
      if (typeof field.display === 'function') {
        return field.display(this.state.machineSettings);
      }
      return true;
    });
  }

  renderCurrentTab() {
    const { activeTabIndex } = this.state;
    const currentTab = tabs[activeTabIndex].text;
    const fields = this.getTabMeta(currentTab);
    // render sequence tab
    if (currentTab === tabs[5].text) {
      return this.renderSequencesTab();
    }
    // render extruder tab
    if (currentTab === tabs[1].text) {
      const { filamentDiameter, nozzleDiameter } = fields;
      const extruderNumberData = this.combinedArray(
        nozzleDiameter.fields,
        filamentDiameter.fields
      );
      return _.map(fields, (fieldGroup, index) => {
        return this.renderExtruderFieldGroup(
          fieldGroup,
          extruderNumberData,
          index
        );
      });
    }
    return (
      <>
        {_.map(fields, (fieldGroup, index) => {
          return this.renderFieldGroup(fieldGroup, index);
        })}
        {currentTab === tabs[2].text && this.renderBedGraphic()}
      </>
    );
  }

  renderFieldGroup(group, index) {
    const visibleFields = this.renderVisibleFields(group);
    if (_.isEmpty(visibleFields)) return null;
    if (group.subformItem) {
      const subformData = {
        noSubformSpacing: true,
        children: this.renderSubformItems(visibleFields),
      };
      return <Form key={index} items={[subformData]} />;
    }

    const formData = [];
    _.map(visibleFields, (field) => {
      const formField = {
        ...field,
        content: this.renderField(field),
      };
      return formData.push(formField);
    });
    return <Form key={index} items={formData} />;
  }

  renderSubformItems(group) {
    const subform = _.map(group, (field) => {
      const subformItems = {
        ...field,
        subformContent: this.renderField(field),
      };
      return subformItems;
    });
    return subform;
  }

  renderExtruderFieldGroup(group, extruderNumberData, index) {
    const visibleFields = this.renderVisibleFields(group);
    if (_.isEmpty(visibleFields)) return null;
    if (group.name === 'nozzleDiameter') return null;
    if (!group.subformItem) {
      const formData = [];
      _.map(visibleFields, (field) => {
        const formField = {
          ...field,
          content: this.renderField(field),
        };
        return formData.push(formField);
      });
      return <Form key={index} items={formData} />;
    }

    const subformData = [
      {
        noSubformSpacing: true,
        children: this.renderExtruderSubformItems(extruderNumberData),
      },
    ];
    return <Form key={index} items={subformData} />;
  }

  renderExtruderSubformItems(combinedArray) {
    const visibleFields = this.renderVisibleFields(combinedArray);
    if (_.isEmpty(visibleFields)) return null;

    const subform = _.map(visibleFields, (field) => {
      const subformItems = {
        ...field,
        subformContent: this.renderField(field),
      };
      return subformItems;
    });

    let extruderCount = 0;
    const subformWithExtruderNumber = _.flatten(subform).map((field, index) => {
      if (index % 2 === 0) {
        return [{ subformDescription: `Extruder ${++extruderCount}` }, field];
      }
      return field;
    });
    return _.flatten(subformWithExtruderNumber);
  }

  renderField(field) {
    switch (field.type) {
      case fieldTypes.text:
        return this.renderTextField(field);
      case fieldTypes.icon:
        return this.renderIconField(field);
      case fieldTypes.number:
        return this.renderNumberField(field);
      case fieldTypes.radio:
        return this.renderRadioList(field);
      case fieldTypes.checkbox:
        return this.renderCheckbox(field);
      case fieldTypes.dropdown:
        return this.renderDropdown(field);
      case 'tags':
        return this.renderTagSelector();
      default:
        return null;
    }
  }

  renderSideBar() {
    return (
      <SideBar
        items={tabs}
        activeTab={this.state.activeTabIndex}
        setActiveTab={(index) => this.setActiveTabIndex(index)}>
        {this.renderCurrentTab()}
      </SideBar>
    );
  }

  renderSequencesTab() {
    const url = Routes.toPrinterScript();
    return (
      <>
        <TextWrapper>
          <Body1>Custom sequences</Body1>
          <Caption grey>
            These scripts can be used to customize the print’s output at
            specific points in the print. This adds significant flexibility to
            Canvas’ slicing process, but is a particularly advanced feature and
            changes should be made with caution.
          </Caption>
          <Caption grey noSpacing>
            Refer to the{' '}
            <Link external href={url} greenDefault>
              documentation
            </Link>{' '}
            for more information.
          </Caption>
        </TextWrapper>
        <SequenceEditorWrapper>
          <SequenceEditor
            {...this.state.machineSettings}
            sequences={SequenceFields}
            updateField={(name, value) => this.updateField(name, value)}
          />
        </SequenceEditorWrapper>
      </>
    );
  }

  renderConfigurePrinterModal() {
    return (
      <>
        {InterfaceUtils.getLoadingSpinner(this.props)}
        {this.renderSequenceWarningModal()}
        <SettingsModal
          headerTitle={this.renderHeaderTitle()}
          headerButtons={this.renderButtons()}
          sideBarContent={this.renderSideBar()}
        />
      </>
    );
  }

  render() {
    return this.renderConfigurePrinterModal();
  }
}

export default withTheme(withRouter(ConfigurePrinter));

import React, { Component } from 'react';
import _ from 'lodash';

import {
  StyledToolTipContainer,
  ChartContainer,
  Legends,
  Legend,
  GreySwatch,
  GreenSwatch,
} from './toolTipWithChart.styles';

import { BarPlot, Caption, TrendLine } from '../../../shared';

import { fieldTypes, paletteConstants } from '../../../constants';

class ToolTipWithChart extends Component {
  static defaultProps = {
    field: null,
    printerTag: null,
    currentValue: null,
    activeVariantIndex: -1,
    printerType: 'unmodded',
    settingGroup: 'machineSettings',
  };

  getFieldOptions(fieldName, variant) {
    if (variant.type === fieldTypes.checkbox) {
      return [
        { label: 'Enabled', value: true },
        { label: 'Disabled', value: false },
      ];
    }

    const extruderFieldNames = [
      'chromaExtruder',
      'defaultSupportExtruder',
      'defaultSupportInterfaceExtruder',
    ];

    // this one needs more discussion;
    // currently, the visualizations are generated from the tagged printers data only,
    // and does not take project settings into account
    // this might cause some UX issues as the input counts between printers/projects diverge
    if (extruderFieldNames.includes(fieldName)) {
      return new Array(paletteConstants.DEFAULT_INPUT_COUNT)
        .fill(0)
        .map((val, index) => ({
          label: (index + 1).toString(),
          value: index,
        }));
    }

    return variant.options;
  }

  formatAggregatedData() {
    const {
      field,
      printerTag,
      currentValue,
      activeVariantIndex,
      printerType,
      settingGroup,
    } = this.props;

    // for machine settings, given data is ready-to-use
    let variant = field;
    // clone the data to avoid mutation
    let aggregatedData = _.cloneDeep(
      printerTag[printerType][settingGroup][field.name]
    );
    if (!aggregatedData) return null;
    // for style settings, we need to retrieve the appropriate variant data
    if (settingGroup === 'styles') {
      if (activeVariantIndex === -1) {
        // active variant index is not provided; assume single-variant field
        [variant] = field.variants;
        [aggregatedData] = aggregatedData.variants;
      } else {
        // active variant index is provided; find relevant data
        variant = field.variants[activeVariantIndex];
        aggregatedData = aggregatedData.variants.find(
          (item) => item.units === variant.units
        );
      }
    }

    // no relevant data found;
    if (!aggregatedData) return null;

    // categorical fields require additional processing to
    //   map displayed labels and aggregated field option data
    if (
      aggregatedData.type === 'boolean' ||
      aggregatedData.type === 'category'
    ) {
      // no usage data available; skip processing
      if (aggregatedData.usage.length === 0) return aggregatedData;
      let currentUserValue = currentValue;

      if (field.type === fieldTypes.checkbox) {
        // if we are rendering a checkbox for this field
        //   make sure to first map the aggregated option values to true/false
        aggregatedData.usage = aggregatedData.usage.map((item) => {
          // already true/false; skip this one
          if (typeof item.category === 'boolean') return item;

          let mappedCategory = item.category === 1;

          if (field.name === 'firmwareRetraction') {
            mappedCategory = item.category === 2;
            currentUserValue = currentValue === 2;
          }

          return {
            ...item,
            category: mappedCategory,
          };
        });
      }

      // include unseen options and replace labels
      const existingOptions = this.getFieldOptions(field.name, variant);
      aggregatedData.usage = existingOptions.map((option) => {
        const optionData = aggregatedData.usage.find(
          (item) => item.category === option.value
        );
        return {
          category: option.label,
          count: optionData ? optionData.count : 0,
          isCurrent: optionData
            ? optionData.category === currentUserValue
            : false,
        };
      });
    }

    return aggregatedData;
  }

  renderChartTitle() {
    return <Caption>Distribution of user values:</Caption>;
  }

  renderLegends(dataType) {
    const isTrendLine = dataType === 'number';
    const currentValueText = isTrendLine
      ? 'Current value'
      : 'Currently selected';

    return (
      <Legends>
        <Legend>
          <GreenSwatch line={isTrendLine} />
          <Caption noSpacing>{currentValueText}</Caption>
        </Legend>
        <Legend>
          <GreySwatch line={isTrendLine} />
          <Caption noSpacing>Values used by other profiles</Caption>
        </Legend>
      </Legends>
    );
  }

  renderChart() {
    const { currentValue, printerTag } = this.props;

    if (!printerTag) return null;

    // we are ignoring the fields/variants with 'auto'
    if (currentValue === 'auto') return null;

    const formattedData = this.formatAggregatedData();
    if (!formattedData) return null;

    let plot = null;
    let insufficientData = false;
    if (formattedData.type === 'boolean' || formattedData.type === 'category') {
      // boolean or category field; visualize bar plot of aggregated data

      if (formattedData.usage.length === 0) {
        // no usage data; don't visualize
        insufficientData = true;
      } else {
        plot = <BarPlot data={formattedData} />;
      }
    }

    if (formattedData.type === 'number') {
      // number field; visualize trend line of aggregated data

      if (formattedData.bins.length === 0) {
        // bins contain no data; don't visualize
        insufficientData = true;
      } else {
        plot = <TrendLine currentValue={currentValue} data={formattedData} />;
      }
    }

    if (insufficientData) {
      return (
        <Caption grey noSpacing>
          Not enough data to display distribution
        </Caption>
      );
    }

    return (
      <>
        {this.renderChartTitle()}
        {plot}
        {this.renderLegends(formattedData.type)}
      </>
    );
  }

  render() {
    const { field } = this.props;

    return (
      <StyledToolTipContainer>
        {field.tooltip}
        <ChartContainer>{this.renderChart()}</ChartContainer>
      </StyledToolTipContainer>
    );
  }
}

export default ToolTipWithChart;

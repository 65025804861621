import styled from 'styled-components';

export const ModalContent = styled.div`
  border-radius: 0.25rem;
  transition: all 0.2s;
`;

export const ButtonWrapper = styled.div`
  margin-left: 0.5rem;
`;

import React, { Component } from 'react';

import ModalManager from './modalManager/modalManager.container';
import TaskManager from './taskManager/taskManager.container';

class Onboarding extends Component {
  componentDidMount() {
    this.props.getOnboardingState();
  }

  componentWillUnmount() {
    this.props.resetOnboardingStore();
  }

  render() {
    const { getOnboardingStatePending, getOnboardingStateSuccess } = this.props;
    if (getOnboardingStatePending || !getOnboardingStateSuccess) return null;
    return (
      <>
        <ModalManager />
        <TaskManager />
      </>
    );
  }
}

export default Onboarding;

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

export default function* updateTowerPositionOrSize(action) {
  try {
    const { currentProject: projectId } = yield select(getSlicerState);
    const { xyz, positionOrSize } = action.payload;
    const { x, y } = xyz;
    const body = { [positionOrSize]: [x, y] };

    const { transitionTower } = yield call(API, {
      method: methods.POST,
      path: `projects/${projectId}/tower`,
      body,
    });

    yield put(actions.updateTowerPositionOrSizeSuccess());
    yield put(actions.invalidateSlice(projectId));
    yield put(actions.updateTower(transitionTower));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

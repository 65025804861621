import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../canvas-api';
import { handleGenericError } from '../common';
import { actions } from '../../reducers/printers/printers';
import { sanitizePrinterData } from './common';

export default function* updatePrinter(action) {
  try {
    const { printerId, loadingOffset, printValue, calibrationLength } =
      action.payload;

    const body = {
      loadingOffset,
      printValue,
      calibrationLength,
    };

    const response = yield call(API, {
      method: methods.POST,
      path: `printers/${printerId}/calibration`,
      body,
    });

    if (response === null) return;

    const sanitizedPrinter = sanitizePrinterData(response.printer);
    yield put(actions.updateCalibrationSettingsSuccess(sanitizedPrinter));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import React, { Component } from 'react';
import { withTheme } from 'styled-components';

import { DefaultContainer, DefaultSlider } from './slider.styles';

class Slider extends Component {
  static defaultProps = {
    value: 0,
    onChange: () => {},
    onChangeCommitted: () => {},
    min: 0,
    max: 100,
    step: 1,
    vertical: false,
    valueLabelStyles: {
      backgroundColor: 'inherit',
      borderRadius: '0.3em',
    },
    disabled: false,
    disableSwap: true,
    name: '',
    valueLabelFormatter: (value) => value,
    StyledSlider: DefaultSlider,
    StyledContainer: DefaultContainer,
  };

  render() {
    const { StyledSlider, StyledContainer } = this.props;
    return (
      <StyledContainer vertical={this.props.vertical}>
        <StyledSlider
          min={this.props.min}
          max={this.props.max}
          step={this.props.step}
          value={this.props.value}
          vertical={this.props.vertical}
          orientation={this.props.vertical ? 'vertical' : 'horizontal'}
          disabled={this.props.disabled}
          disableSwap={this.props.disableSwap}
          name={this.props.name}
          onChange={this.props.onChange}
          onChangeCommitted={this.props.onChangeCommitted}
          valueLabelDisplay='on'
          valueLabelFormat={this.props.valueLabelFormatter}
          valueLabelStyles={this.props.valueLabelStyles}
        />
      </StyledContainer>
    );
  }
}

export default withTheme(Slider);

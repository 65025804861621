import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import API from '../canvas-api';

import { handleGenericError, getAuthState } from '../common';
import { actions } from '../../reducers/onboarding/onboarding';

export default function* getOnboardingState(action) {
  try {
    const { userId } = yield select(getAuthState);
    const response = yield call(API, {
      path: `users/${userId}/onboarding`,
    });
    if (response === null) return;
    const { modals, tasks } = response;
    yield put(actions.getOnboardingStateSuccess(modals, tasks));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import 'regenerator-runtime/runtime';
import _ from 'lodash';
import { all, call, put, select } from 'redux-saga/effects';

import API from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';
import types from '../../../reducers/three/types';
import { TreeUtils, InterfaceUtils } from '../../../utils';
import { fetchS3 } from '../../s3';

let abortController = null;
let aborted = false;

const getDesiredFileNames = (toolpath, viewType, singleBuffer) => {
  const files = [];
  if (singleBuffer) {
    files.push(singleBuffer);
  } else {
    files.push('core', 'legend');
    if (toolpath.showTravel) {
      files.push('travelPosition');
    }
    if (toolpath.showRetracts) {
      files.push('retractPosition');
    }
    if (toolpath.showRestarts) {
      files.push('restartPosition');
    }
    switch (viewType) {
      case types.TOOLPATH_BY_SPEED:
        files.push('feedrateColor');
        break;
      case types.TOOLPATH_BY_PATH_TYPE:
        files.push('pathTypeColor');
        break;
      case types.TOOLPATH_BY_FAN:
        files.push('fanSpeedColor');
        break;
      case types.TOOLPATH_BY_TEMPERATURE:
        files.push('temperatureColor');
        break;
      case types.TOOLPATH_BY_LAYER_HEIGHT:
        files.push('layerHeightColor');
        break;
      default:
      case types.TOOLPATH_BY_TOOL:
        files.push('toolColor');
        break;
    }
  }
  return files;
};

export default function* loadToolpathPtp(action) {
  try {
    const slicerState = yield select(getSlicerState);
    const projectId = slicerState.currentProject;
    const currentProject = slicerState.projects[projectId];
    const { toolpath } = slicerState;
    let { viewType, singleBuffer, isColorBuffer } = action.payload;
    if (!viewType) {
      const { models } = slicerState;
      const uniqueExtruderCounts = TreeUtils.getUniqueExtruderCount(
        models,
        currentProject.style,
        currentProject.colors.length
      );
      const isMultiColor = uniqueExtruderCounts > 1;
      viewType = isMultiColor
        ? types.TOOLPATH_BY_TOOL
        : types.TOOLPATH_BY_PATH_TYPE;
    }

    // get the appropriate presigned URLs
    const files = getDesiredFileNames(toolpath, viewType, singleBuffer);
    abortController = new AbortController();
    aborted = false;
    let urls;
    try {
      urls = yield call(API, {
        signal: abortController.signal,
        path: `projects/${projectId}/toolpath?files=${files.join(',')}`,
      });
      if (urls === null) return;
    } catch (err) {
      if (err.name === 'AbortError') {
        // request aborted
        aborted = true;
        return;
      }
      if (JSON.parse(err).status === 404) {
        yield put(actions.loadToolpathPtpFailure());
        return;
      }
      return;
    }

    const s3GetParams = {
      method: 'GET',
      signal: abortController.signal,
    };
    let legend;
    if (singleBuffer) {
      ({ legend } = toolpath.ptp);
    } else {
      // get the legend first
      try {
        const legendResponse = yield call(fetchS3, urls.legend, s3GetParams);
        if (legendResponse.status >= 200 && legendResponse.status <= 299) {
          legend = yield call([legendResponse, legendResponse.json]);
        } else {
          yield put(actions.loadToolpathPtpFailure());
          InterfaceUtils.emitToast(
            'error',
            'An error occurred loading the print preview.'
          );
          return;
        }
      } catch (err) {
        if (err.name === 'AbortError') {
          // request aborted
          aborted = true;
          return;
        }
        throw err;
      }
    }

    // get everything else in parallel
    let buffers = {};
    if (isColorBuffer) {
      // remove other color buffers in memory
      buffers = {
        toolColor: null,
        pathTypeColor: null,
        feedrateColor: null,
        fanSpeedColor: null,
        temperatureColor: null,
        layerHeightColor: null,
      };
    } else if (!singleBuffer) {
      // remove all buffers in memory
      buffers = {
        position: null,
        normal: null,
        index: null,
        extrusionWidth: null,
        layerHeight: null,
        legend: null,
        travelPosition: null,
        retractPosition: null,
        restartPosition: null,
        toolColor: null,
        pathTypeColor: null,
        feedrateColor: null,
        fanSpeedColor: null,
        temperatureColor: null,
        layerHeightColor: null,
      };
    }
    const bufferUrls = _.omit(urls, 'legend');
    yield all(
      _.map(bufferUrls, async (url, fileName) => {
        if (!aborted) {
          try {
            const response = await fetch(url, s3GetParams);
            if (response.status >= 200 && response.status <= 299) {
              const arrayBuffer = await response.arrayBuffer();
              if (fileName === 'core') {
                const coreBuffers = [
                  'position',
                  'normal',
                  'index',
                  'extrusionWidth',
                  'layerHeight',
                ];
                _.forEach(coreBuffers, (name) => {
                  buffers[name] = arrayBuffer.slice(
                    legend.header[name].offset,
                    legend.header[name].offset + legend.header[name].size
                  );
                });
                return;
              }
              buffers[fileName] = arrayBuffer;
            }
          } catch (err) {
            if (err.name === 'AbortError') {
              // request aborted
              aborted = true;
              return;
            }
            throw err;
          }
        }
      })
    );
    abortController = null;
    if (aborted) return;

    const ptpData = {
      legend,
      buffers,
    };
    yield put(actions.loadToolpathPtpSuccess(legend.header.version, ptpData));
    yield put(
      actions.createToolpathMeshesRequest(
        viewType,
        !singleBuffer,
        isColorBuffer
      )
    );
  } catch (e) {
    yield call(handleGenericError, action, e);
    yield put(actions.loadToolpathPtpFailure());
  }
}

// eslint-disable-next-line require-yield
export function* cancelLoadToolpathPtp() {
  if (abortController) {
    // PTP v3 and higher
    abortController.abort();
    abortController = null;
    aborted = false;
  }
}

import React, { Component } from 'react';

import { Overlay, Container, Header } from './uploadModal.styles';

import UploadContent from './uploadContent.jsx';
import UploadButtons from './uploadButtons.jsx';

import { ActionButton, H6 } from '../../../shared';

import { Icons } from '../../../themes';

class UploadModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: 'left',
      modalWindow: {},
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    const bbox = this.containerRef.current.getBoundingClientRect();
    const modalWindow = {
      x: 0,
      width: 0,
      midpoint: 0,
    };
    modalWindow.x = bbox.x;
    modalWindow.width = bbox.width;
    modalWindow.midpoint = bbox.width / 2 + bbox.x;
    this.setState({
      modalWindow,
    });
  }

  handleMouseMove(e) {
    if (e.clientX < this.state.modalWindow.midpoint) {
      this.setState({ hover: 'left' });
    } else if (e.clientX > this.state.modalWindow.midpoint) {
      this.setState({ hover: 'right' });
    }
  }

  handleUpload(fileUpload) {
    this.props.onHandleModalUpload(fileUpload);
  }

  render() {
    return (
      <Overlay onMouseMove={(e) => this.handleMouseMove(e)}>
        <Container ref={this.containerRef} onClick={(e) => e.stopPropagation()}>
          <Header>
            <H6 noSpacing>Add models</H6>
            <ActionButton
              minimal
              icon={Icons.basic.x}
              onClick={() => this.props.onToggleModal()}
            />
          </Header>
          <UploadContent hover={this.state.hover} />
          <UploadButtons onHandleUpload={(e) => this.handleUpload(e)} />
        </Container>
      </Overlay>
    );
  }
}

export default UploadModal;

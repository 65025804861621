import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API from '../../canvas-api';
import { handleGenericError } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';
import { FileUtils } from '../../../utils';
import { fetchS3 } from '../../s3';

let callInProgress = false;
const queuedProjectIds = [];

function* getThumbnail(action) {
  try {
    const { projectId } = action.payload;

    const response = yield call(API, {
      path: `projects/${projectId}/thumbnail`,
    });
    if (response === null) return;
    const { thumbnail: signedURL } = response;

    const thumbnail = yield call(fetchS3, signedURL);
    const arrayBuffer = yield call([thumbnail, 'arrayBuffer']);
    const base64String = FileUtils.arrayBufferToBase64(arrayBuffer);

    const completeThumbnailString = `data:image/png;base64,${base64String}`;
    yield put(
      actions.getProjectThumbnailSuccess(projectId, completeThumbnailString)
    );
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

export default function* getProjectThumbnail(action) {
  if (callInProgress) {
    const { projectId } = action.payload;
    queuedProjectIds.push(projectId);
  } else {
    callInProgress = true;
    yield call(getThumbnail, action);
    while (queuedProjectIds.length > 0) {
      const projectId = queuedProjectIds.shift();
      const queuedAction = actions.getProjectThumbnailRequest(projectId);
      yield call(getThumbnail, queuedAction);
    }
    callInProgress = false;
  }
}

// eslint-disable-next-line require-yield
export function* clearThumbnailQueue() {
  queuedProjectIds.splice(0, queuedProjectIds.length);
  callInProgress = false;
}

import React from 'react';

import {
  Container,
  FauxTextWrapper,
  FauxText,
  FauxImageWrapper,
  FauxImage,
} from './contentPlaceholder.styles';

const ContentPlaceholder = () => (
  <Container>
    <FauxTextWrapper short shimmer>
      <FauxText />
    </FauxTextWrapper>
    <FauxTextWrapper shimmer>
      <FauxText />
    </FauxTextWrapper>
    <FauxImageWrapper shimmer>
      <FauxImage />
    </FauxImageWrapper>
  </Container>
);

export default ContentPlaceholder;

import { connect } from 'react-redux';
import { actions as printerActions } from '../../../reducers/printers/printers';
import { actions as slicerActions } from '../../../reducers/slicer/slicer';

import ViewPrinter from './viewPrinter.jsx';
import { actions as authActions } from '../../../reducers/auth/auth';

const mapStateToProps = (state) => ({
  sortBy: {
    mode: state.auth.preferences.stylesSortMode,
    direction: state.auth.preferences.stylesSortDirection,
  },
  createdProjectId: state.slicer.currentProject,
  getPrintersPending: state.printers.status.getPrintersPending,
  getPrintersSuccess: state.printers.status.getPrintersSuccess,
  createStylesSuccess: state.printers.status.createStylesSuccess,
  createStylesPending: state.printers.status.createStylesPending,
  deleteStylesSuccess: state.printers.status.deleteStylesSuccess,
  deleteStylesPending: state.printers.status.deleteStylesPending,
  updatePrinterPending: state.printers.status.updatePrinterPending,
  updatePrinterSuccess: state.printers.status.updatePrinterSuccess,
  createProjectSuccess: state.slicer.status.createProjectSuccess,
  deletePrinterSuccess: state.printers.status.deletePrinterSuccess,
  printers: state.printers.printers,
  getMaterialsPending: state.slicer.status.getMaterialsPending,
  getMaterialsSuccess: state.slicer.status.getMaterialsSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  updatePreferences: (sortMode, sortDirection) =>
    dispatch(
      authActions.editAccountRequest({
        preferences: {
          stylesSortMode: sortMode,
          stylesSortDirection: sortDirection,
        },
      })
    ),
  deleteStyles: (printerId, styleIds) =>
    dispatch(printerActions.deleteStylesRequest(printerId, styleIds)),
  createStyleProfiles: (printerId, styles) =>
    dispatch(printerActions.createStyleProfilesRequest(printerId, styles)),
  updatePrinterStyles: (printerId, styleId, styles) =>
    dispatch(
      printerActions.updateStyleProfileRequest(printerId, styleId, styles)
    ),
  resetNewProjectFlags: () => dispatch(slicerActions.resetNewProjectFlags()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPrinter);

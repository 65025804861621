import _ from 'lodash';

export const ProjectSortMode = {
  NAME: 1,
  PRINTER: 2,
  DATE_MODIFIED: 3,
  DATE_CREATED: 4,
};

export const ProjectSortDirection = {
  ASCENDING: 1,
  DESCENDING: -1,
};

export const ProjectSortDefault = {
  mode: ProjectSortMode.DATE_MODIFIED,
  direction: ProjectSortDirection.ASCENDING,
};

export const PrinterSortMode = {
  NAME: 1,
  PALETTE_TYPE: 2, // this option is no longer supported, but the enums need to stay unchanged
  DATE_MODIFIED: 3,
  DATE_CREATED: 4,
};

export const PrinterSortDirection = {
  ASCENDING: 1,
  DESCENDING: -1,
};

export const PrinterSortDefault = {
  mode: PrinterSortMode.NAME,
  direction: PrinterSortDirection.ASCENDING,
};

export const StyleSortMode = {
  NAME: 1,
  DATE_MODIFIED: 2,
  DATE_CREATED: 3,
};

export const StyleSortDirection = {
  ASCENDING: 1,
  DESCENDING: -1,
};

export const StyleSortDefault = {
  mode: StyleSortMode.NAME,
  direction: StyleSortDirection.ASCENDING,
};

export const MaterialSortMode = {
  NAME: 1,
  TYPE: 2,
  DATE_MODIFIED: 3,
  DATE_CREATED: 4,
};

export const MaterialSortDirection = {
  ASCENDING: 1,
  DESCENDING: -1,
};

export const MaterialSortDefault = {
  mode: MaterialSortMode.NAME,
  direction: MaterialSortDirection.ASCENDING,
};

export const DeviceSortMode = {
  NAME: 1,
  DATE_CREATED: 2,
  DATE_MODIFIED: 3,
};

export const DeviceSortDirection = {
  ASCENDING: 1,
  DESCENDING: -1,
};

export const DeviceSortDefault = {
  mode: DeviceSortMode.NAME,
  direction: DeviceSortDirection.ASCENDING,
};

export const FileSortMode = {
  NAME: 1,
  DATE_MODIFIED: 3,
};

export const FileSortDirection = {
  ASCENDING: 1,
  DESCENDING: -1,
};

export const FileSortDefault = {
  mode: FileSortMode.NAME,
  direction: FileSortDirection.ASCENDING,
};

const Utils = {
  sortByPath: (
    itemsToSort,
    secondReference,
    type = 'string',
    reverse = false,
    customCompare = null
  ) => {
    // you can specify property that is nested by specifying its location in the path parameter
    //   e.g., path = 'machineSettings.name'
    // also, the default comparator function could be overriden with a custom one that
    //   compares a to b and returns a value >0, <0, or 0
    let compare = (a, b) => a.localeCompare(b);
    if (customCompare) compare = customCompare;
    const { mainArrayToSort, mainPath } = itemsToSort;
    const reverseSign = reverse ? -1 : 1;
    return mainArrayToSort.sort((rawA, rawB) => {
      let a = rawA;
      let b = rawB;
      let count = 0;
      let levels = mainPath.split('.');
      if (secondReference) {
        ({ a, b, levels } = Utils.useSecondPathReference(
          a,
          b,
          secondReference,
          levels
        ));
      }
      while (count < levels.length) {
        const currentLevel = levels[count];
        a = a[currentLevel];
        b = b[currentLevel];
        count++;
      }
      if (type === 'string') {
        a = a.toLowerCase();
        b = b.toLowerCase();
        return compare(a, b) * reverseSign;
      }
      return compare(a, b) * -reverseSign;
    });
  },
  useSecondPathReference: (
    element1,
    element2,
    secondReference,
    mainPathLevels
  ) => {
    const { secondReferenceObj, secondPath } = secondReference;
    let a = element1;
    let b = element2;
    let count = 0;
    while (count < mainPathLevels.length) {
      const currentLevel = mainPathLevels[count];
      if (count === 0) {
        a = secondReferenceObj[a[currentLevel]];
        b = secondReferenceObj[b[currentLevel]];
      } else {
        a = a[currentLevel];
        b = b[currentLevel];
      }
      count++;
    }
    const secondPathLevels = secondPath.split('.');
    return { a, b, levels: secondPathLevels };
  },
  sortByName: (items, descending = false, path = 'name') =>
    Utils.sortByPath(
      { mainArrayToSort: _.values(items), mainPath: path },
      null,
      'string',
      descending // true = ZtoA, false = AtoZ
    ),
  sortByModified: (items, descending = false, path = 'modified') =>
    Utils.sortByPath(
      { mainArrayToSort: _.values(items), mainPath: path },
      null,
      'date',
      descending // true = oldest first, false = newest first
    ),
  sortByCreated: (items, descending = false, path = 'created') =>
    Utils.sortByPath(
      { mainArrayToSort: _.values(items), mainPath: path },
      null,
      'date',
      descending // true = oldest first, false = newest first
    ),
  sortByType: (items, descending = false, path = 'type') =>
    Utils.sortByPath(
      { mainArrayToSort: _.values(items), mainPath: path },
      null,
      'string',
      descending // true = AtoZ, false = ZtoA
    ),
  sortByPrinterName: (
    items,
    secondReference,
    descending = false,
    primaryPath = 'printerId',
    secondaryPath = 'machineSettings.name'
  ) =>
    Utils.sortByPath(
      { mainArrayToSort: _.values(items), mainPath: primaryPath },
      { secondReferenceObj: secondReference, secondPath: secondaryPath },
      'string',
      descending // true = ZtoA, // false = AToZ
    ),
  /**
   * sort an array of materials by type
   * @param materialsMap object or list of materials
   * @param descending boolean for descending sort direction
   * @returns array of sorted materials
   */
  sortMaterialProfilesByType: (materialsMap, descending = false) => {
    const reverseSign = descending ? -1 : 1;
    const materials = _.values(materialsMap);
    materials.sort((a, b) => {
      if (a.id === '0') return -1;
      if (b.id === '0') return 1;

      const aType = a.type.toLowerCase();
      const bType = b.type.toLowerCase();
      if (aType !== bType) {
        return aType.localeCompare(bType) * reverseSign;
      }
      // fall back 1: compare ID if material types are the same
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();
      if (aName !== bName) {
        return aName.localeCompare(bName) * reverseSign;
      }
      // fall back 2: compare ID if names are the same
      return a.id.localeCompare(b.id) * reverseSign;
    });
    return materials;
  },
};

export default Utils;

import styled from 'styled-components';

export const StyledContainerBase = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: ${(props) => (props.hasLabel ? `3.5rem` : `2.5rem`)};
  padding-left: 0.625rem;
  border: 1px solid
    ${(props) =>
      props.isInvalid
        ? props.theme.colors.redDefault
        : props.theme.colors.grey3};
  border-radius: 0.2em;
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.elevatedBackgroundTertiary
      : props.theme.colors.elevatedBackgroundPrimary};
  transition: all 0.2s;
  transition-property: border-color, background-color, color;
`;

export const DefaultStyledContainer = styled(StyledContainerBase)``;

export const StyledInputBase = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.875rem ${(props) => (props.hasUnits ? '3rem' : '0.4rem')} 0.625rem
    0.625rem;
  ${(props) => !props.hasLabel && 'padding: 0.75rem 0.625rem;'}
  border: none;
  box-sizing: border-box;
  font-family: inherit;
  color: ${(props) => {
    if (props.isAuto) return 'transparent';
    if (props.disabled) return props.theme.colors.textTertiary;
    return props.theme.colors.textPrimary;
  }};
  opacity: 1;
  cursor: ${(props) => (props.disabled ? 'default' : 'text')};
  user-select: ${(props) => (props.disabled ? 'none' : 'text')};
  background-color: transparent;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  font-weight: ${(props) => props.theme.fonts.weightRegular};
  outline: none;

  &::placeholder {
    color: ${(props) => {
      if (props.isAuto) return 'transparent';
      if (props.disabled) return props.theme.colors.textTertiary;
      return props.theme.colors.textSecondary;
    }};
  }

  /* By default, Firefox number inputs come with buttons, this removes them: */
  -moz-appearance: textfield;

  /* Change the chrome autofill color to white */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: ${(props) => props.theme.colors.grey5};
    box-shadow: 0 0 0 2rem white inset;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const DefaultStyledInput = styled(StyledInputBase)``;

export const DefaultInputLabelWrapper = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.625rem;
  pointer-events: none;
  user-select: none;
  z-index: 1;
  text-align: left;
  overflow: hidden;
  max-width: calc(100% - 1rem);
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  min-width: 0;
  color: ${(props) => props.theme.colors.textSecondary};
`;

export const DefaultUnitLabelWrapper = styled.div`
  position: absolute;
  right: 0.625rem;
  bottom: ${(props) => (props.hasLabel ? `0.5rem` : `0.625rem`)};
  padding-top: 1rem;
  pointer-events: none;
  user-select: none;
  background-color: transparent;
  z-index: 1;
  transition: all 0.2s;
  transition-property: border-color, background-color, color;
`;

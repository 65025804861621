import _ from 'lodash';

/* eslint-disable no-bitwise */
const Utils = {
  parseStampRLEBuffer: (buffer) => {
    const view = new DataView(buffer);
    const colors = [];
    let offset = 0;
    while (offset < buffer.byteLength) {
      colors.push(view.getUint32(offset, true));
      colors.push(view.getInt8(offset + 4));
      offset += 5;
    }
    return colors;
  },
  getStampRLEBuffer: (rle) => {
    if (!Array.isArray(rle) || rle.length % 2 !== 0) {
      throw new Error('Expected even-length RLE array');
    }
    const binarySize = (rle.length / 2) * 5; // 5 bytes per run
    const buffer = new ArrayBuffer(binarySize);
    const view = new DataView(buffer);
    let offset = 0;
    for (let i = 0; i < rle.length; i += 2) {
      view.setUint32(offset, rle[i], true);
      view.setInt8(offset + 4, rle[i + 1]);
      offset += 5;
    }
    return buffer;
  },
  buildStampImageFromRLE: (rle, dimensions) => {
    const { width, height } = dimensions;
    const stampImageBuf32 = new Uint32Array(width * height);

    let numPixelsToColor;
    let pixelColorIndex;
    let currentRunIndex = 0;
    numPixelsToColor = rle[currentRunIndex];
    pixelColorIndex = rle[currentRunIndex + 1];

    let pixelIndex;
    let extruderIndex;
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        pixelIndex = y * width + x;

        if (numPixelsToColor <= 0) {
          currentRunIndex++;
          if (currentRunIndex * 2 <= rle.length) {
            numPixelsToColor = rle[currentRunIndex * 2];
            pixelColorIndex = rle[currentRunIndex * 2 + 1];
          }
        }

        if (pixelColorIndex >= 0) {
          // 1-indexed extruder index; reserve 0 for transparent pixels
          extruderIndex = pixelColorIndex + 1;
          // bake it in the pixel
          // eslint-disable-next-line no-bitwise
          stampImageBuf32[pixelIndex] =
            (255 << 24) |
            (extruderIndex << 16) |
            (extruderIndex << 8) |
            extruderIndex;
        }

        numPixelsToColor--;
      }
    }

    return stampImageBuf32.buffer;
  },
  getStampFiles: (currentModel) => {
    const stampFiles = [];
    let stampUniforms;

    // add each stamp mesh to scene
    _.forEach(currentModel.mesh.children, (stampGroup) => {
      const [stampMesh] = stampGroup.children;
      stampUniforms = stampMesh.material.uniforms;
      const {
        cameraOrthographic,
        imageDimensions,
        canvasDimensions,
        stampRLE,
        stampBounds,
        depthBounds,
      } = stampMesh.material.userData;

      const meta = {
        version: 0,
        stampRotation: stampUniforms.stampRotation.value,
        cameraViewMatrix: stampUniforms.cameraViewMatrix.value.toArray(),
        cameraProjectionMatrix:
          stampUniforms.cameraProjectionMatrix.value.toArray(),
        cameraDirection: stampUniforms.cameraDirection.value.toArray(),
        renderOrder: stampUniforms.renderOrder.value,
        cameraOrthographic,
        imageDimensions,
        canvasDimensions,
        stampBounds,
        depthBounds,
      };

      const depth = stampUniforms.depthTexture.value.image.data.buffer;

      stampFiles.push({
        id: stampMesh.name,
        image: stampRLE,
        depth,
        meta,
      });
    });
    return stampFiles;
  },
  getStampRLE: (
    posterizedImage,
    posterizedPaletteUint32Map,
    customColorMap
  ) => {
    const { data, width, height } = posterizedImage;
    const uint32View = new Uint32Array(data.buffer);

    let pixelIndex;
    let colorGroupIndex;

    let currentColorIndex = -1;
    let currentRunLength = 0;
    const rle = [];

    const finishRun = () => {
      if (currentRunLength === 0) return;
      rle.push(currentRunLength, currentColorIndex);
    };

    const calculateRLE = (colorIndex) => {
      if (colorIndex === currentColorIndex) {
        // same color -- increment the current run length
        currentRunLength++;
      } else if (currentRunLength > 0) {
        // new color -- end the current run, then start a new one
        finishRun();
        // begin the next run
        currentColorIndex = colorIndex;
        currentRunLength = 1;
      } else {
        // new color and zero run length
        // (this should only run for the first pixel)
        currentColorIndex = colorIndex;
        currentRunLength++;
      }
    };

    let extruderIndex;
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        pixelIndex = y * width + x;
        // compute RLE for stamp image

        // find color group index for this pixel
        colorGroupIndex = posterizedPaletteUint32Map.get(
          uint32View[pixelIndex]
        );

        if (colorGroupIndex >= 0) {
          // find the mapped extruder index for this color group index
          extruderIndex = customColorMap[colorGroupIndex];
        } else {
          // transparent pixel; just set -1
          extruderIndex = -1;
        }

        calculateRLE(extruderIndex);
      }
    }
    finishRun();

    return rle;
  },
  posterize(imgData) {
    const inData = imgData.data;
    const outData = inData.slice();

    let i;
    const n = imgData.width * imgData.height * 4;
    const numLevels = 2;
    const numAreas = 256 / numLevels;
    const numValues = 256 / (numLevels - 1);

    /* eslint-disable no-bitwise */
    for (i = 0; i < n; i += 4) {
      outData[i] = numValues * ((inData[i] / numAreas) >> 0);
      outData[i + 1] = numValues * ((inData[i + 1] / numAreas) >> 0);
      outData[i + 2] = numValues * ((inData[i + 2] / numAreas) >> 0);
      outData[i + 3] = numValues * ((inData[i + 3] / numAreas) >> 0);
    }
    /* eslint-enable no-bitwise */

    imgData.data.set(outData);
    return imgData;
  },
};

export default Utils;

import 'regenerator-runtime/runtime';
import { call, select, put } from 'redux-saga/effects';

import { getThreeState, handleGenericError } from '../common';
import actions from '../../reducers/three/actions';
import { setRenderFlag } from './animationFrame';

export default function* removeBoundaries(action) {
  try {
    const { scene, boundaries } = yield select(getThreeState);
    if (scene && boundaries) {
      boundaries.geometry.dispose();
      scene.remove(boundaries);
      setRenderFlag();
    }
    yield put(actions.removeBoundariesSuccess(boundaries));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import React, { Component } from 'react';

import ToolTipWrapper from '../toolTipWrapper/toolTipWrapper.jsx';

import {
  DefaultStyledContainer,
  DefaultStyledInput,
  DefaultUnitLabelWrapper,
  DefaultInputLabelWrapper,
} from './abstractInput.styles';

import { FormatUtils, ValidateUtils } from '../../utils';

import { Caption, Body1 } from '../typography/typography';

const KEY_CODE_UP = 38;
const KEY_CODE_DOWN = 40;

const areValuesEqual = (a, b) => {
  const aIsNaN = Number.isNaN(a);
  const bIsNaN = Number.isNaN(b);
  if (aIsNaN || bIsNaN) {
    return aIsNaN === bIsNaN;
  }
  return a === b;
};

class AbstractInput extends Component {
  static defaultProps = {
    // create default ref in the constructor, otherwise all inputs will share this one!
    innerRef: null,
    // input type (internally mapped to HTML input types)
    type: 'text',
    allowEmpty: false,
    // most recent field value as provided by parent
    // - changes to this value will override state!
    value: '',
    // validity state as provided by parent
    // - changes to this value will override state!
    isInvalid: false,
    title: '',
    label: '',
    placeholder: '',
    customInputStyles: '',
    disabled: false,
    autoComplete: 'on',
    autoCapitalize: true,
    autoFocus: false,
    // imperative prop to tell us to focus!
    forceFocus: false,
    // custom validator for field content
    validator: null,
    // revert to the last valid value on blur?
    // - should only be used if parent does not maintain error state
    // - in general, should be true if onChangeFailure is ignored
    revertOnBlur: false,
    // input changed and is valid
    // - for number fields, value will be a number
    // - for string fields, value will be trimmed unless disableAutoTrim=true
    onChangeSuccess: (/* value */) => {},
    // input changed and is not valid
    // - reason contains a string explaining the validation error
    onChangeFailure: (/* value, reason */) => {},
    // convenience handlers
    onFocus: (/* event */) => {},
    onBlur: (/* event */) => {},
    onKeyDown: (/* event */) => {},
    onKeyUp: (/* event */) => {},
    onPaste: (/* event */) => {},
    stopPropagationOnKeyDown: false,
    stopPropagationOnKeyUp: false,
    stopPropagationOnPaste: false,
    // for number fields, disable up/down key increments?
    disableArrowKeyIncrement: false,
    // for string fields, disable trimming values?
    disableAutoTrim: false,
    // override built-in error tooltips
    disableTooltip: false,
    tooltip: null,
    // help tooltip to display when no errors exist
    infoTooltip: null,
    wideTooltip: false,
    // specific to number fields
    units: '',
    min: null,
    gte: true,
    max: null,
    lte: true,
    step: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value.toString(),
      isInvalid: false,
      invalidReason: '',
      focused: false,
    };
    this.state.lastSuccessValue = props.value; // for minimizing external value updates
    this.inputRef = this.props.innerRef || React.createRef();
  }

  componentDidUpdate(prevProps) {
    // keep our ref up to date
    if (this.props.innerRef !== prevProps.innerRef) {
      this.inputRef = this.props.innerRef;
    }
    let setState = false;
    const newState = {
      value: this.state.value,
      isInvalid: this.state.isInvalid,
      invalidReason: this.state.invalidReason,
    };
    // focus the input field if requested
    const doForceFocus =
      this.props.forceFocus && prevProps.forceFocus !== this.props.forceFocus;
    const isNewValue = !areValuesEqual(
      this.props.value,
      this.state.lastSuccessValue
    );
    if (isNewValue) {
      // parent provided us with a new value -- assume it's valid
      // unless props.isInvalid changed too (checked below)
      newState.value = this.props.value.toString();
      newState.lastSuccessValue = this.props.value;
      newState.isInvalid = false;
      setState = true;
    }
    if (doForceFocus) {
      // force focus was triggered, likely as a result of form validation
      // - re-validate our current content
      // - give error styles to empty inputs for a visual cue
      if (!this.props.allowEmpty && newState.value.trim().length === 0) {
        newState.isInvalid = true;
        newState.invalidReason = '';
      } else {
        const { isValid, reason } = this.validate(newState.value);
        newState.isInvalid = !isValid;
        newState.invalidReason = reason;
      }
      setState = true;
    }
    if (this.props.isInvalid && !this.state.isInvalid) {
      // parent is forcing us to be invalid
      // - run this.validate just to try and get a good error message
      const { reason } = this.validate(newState.value);
      newState.isInvalid = true;
      newState.invalidReason = reason;
      setState = true;
    } else if (
      // parent is no longer forcing us to be invalid
      // - we might still contain an invalid value, or not
      (!this.props.isInvalid && this.state.isInvalid) ||
      // numeric bounds changed dynamically
      // - we might no longer be valid, or have just become valid
      this.props.min !== prevProps.min ||
      this.props.max !== prevProps.max
    ) {
      // re-validate our state
      const { isValid, reason } = this.validate(this.state.value);
      const isInvalid = !isValid;
      if (isInvalid !== this.state.isInvalid) {
        newState.isInvalid = isInvalid;
        newState.invalidReason = reason;
        setState = true;
      }
    }
    if (setState) {
      this.setState(newState);
    }
    if (doForceFocus && this.inputRef.current) {
      this.inputRef.current.focus();
      this.selectAll();
      this.inputRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }

  selectAll() {
    if (!this.inputRef.current) return;
    this.inputRef.current.setSelectionRange(0, this.state.value.length);
  }

  getOutOfBoundsText() {
    const { min, gte, max, lte } = this.props;
    if (max === null) {
      // only min is used
      return gte
        ? `Enter a value of at least ${min}`
        : `Enter a value greater than ${min}`;
    }
    if (min === null) {
      // only max is used
      return lte
        ? `Enter a value of at most ${max}`
        : `Enter a value less than ${max}`;
    }
    return `Enter a value between ${min} and ${max}`;
  }

  validateBounds(float) {
    const { min, gte, max, lte } = this.props;
    let isMinValid = true;
    if (min !== null) {
      if (gte) {
        isMinValid = float >= min;
      } else {
        isMinValid = float > min;
      }
    }
    if (!isMinValid) {
      return false;
    }
    let isMaxValid = true;
    if (max !== null) {
      if (lte) {
        isMaxValid = float <= max;
      } else {
        isMaxValid = float < max;
      }
    }
    return isMaxValid;
  }

  getIncrementText() {
    if (this.props.step === 1) return 'Value must be an integer';
    return `Value must be a multiple of ${this.props.step}`;
  }

  validateStep(float) {
    const { step } = this.props;
    let isStepValid = true;
    if (step !== null) {
      const roundingFactor = 10e8;
      const roundedValue =
        Math.round((float / step) * roundingFactor) / roundingFactor;
      isStepValid = Number.isInteger(roundedValue);
    }
    return isStepValid;
  }

  validate(value) {
    const trimmedValue = this.props.disableAutoTrim ? value : value.trim();
    const { type, allowEmpty, validator } = this.props;
    if (validator) {
      const result = validator(trimmedValue);
      if (typeof result === 'object') {
        return result;
      }
      return {
        isValid: result,
      };
    }
    if (type === 'email') {
      if (allowEmpty && trimmedValue.length === 0) return true;
      return {
        isValid: ValidateUtils.isEmail(trimmedValue),
        reason: 'Enter a valid email address',
      };
    }
    if (type === 'text') {
      return {
        isValid: allowEmpty || trimmedValue.length > 0,
        reason: this.props.label
          ? `${this.props.label} is required`
          : 'Required',
      };
    }
    if (type === 'number') {
      const validNumberRegex = /^[+-]?(?:(?:[0-9]*\.[0-9]+)|(?:[0-9]+))$/;
      let float;
      if (typeof trimmedValue === 'number') {
        float = trimmedValue;
      } else if (typeof trimmedValue === 'string') {
        const isValidNumber = validNumberRegex.test(trimmedValue);
        if (!isValidNumber) {
          return {
            isValid: false,
            reason: 'Enter a valid number',
          };
        }
        float = parseFloat(trimmedValue);
        if (Number.isNaN(float)) {
          return {
            isValid: false,
            reason: 'Enter a valid number',
          };
        }
      } else {
        return {
          isValid: false,
          reason: 'Enter a valid number',
        };
      }
      const areBoundsValid = this.validateBounds(float);
      if (!areBoundsValid) {
        return {
          isValid: false,
          reason: this.getOutOfBoundsText(),
        };
      }
      const isStepValid = this.validateStep(float);
      if (!isStepValid) {
        return {
          isValid: false,
          reason: this.getIncrementText(),
        };
      }
      return {
        isValid: true,
      };
    }
    return {
      isValid: true,
    };
  }

  onChange(e) {
    const { value } = e.target;
    const { isValid, reason } = this.validate(value);
    if (isValid) {
      this.onChangeSuccess(value);
    } else {
      this.onChangeFailure(value, reason);
    }
  }

  formatSuccessValue(value) {
    const { type, disableAutoTrim } = this.props;
    if (type === 'email' || type === 'text') {
      return disableAutoTrim ? value : value.trim();
    }
    if (type === 'number') {
      return parseFloat(value.trim());
    }
    return value;
  }

  onChangeSuccess(value) {
    const lastSuccessValue = this.formatSuccessValue(value);
    this.setState({
      value,
      lastSuccessValue,
      isInvalid: false,
      invalidReason: '',
    });
    this.props.onChangeSuccess(this.formatSuccessValue(value));
    this.toolTipContainer.manuallyHide();
  }

  onChangeFailure(value, reason = '') {
    this.setState({
      value,
      isInvalid: true,
      invalidReason: reason,
    });
    this.props.onChangeFailure(value);
    this.toolTipContainer.manuallyShow();
  }

  onFocus(e) {
    if (this.state.focused) return;
    this.setState({ focused: true });
    this.props.onFocus(e);
  }

  onBlur(e) {
    if (document.activeElement === this.inputRef.current) return;
    const newState = {
      value: this.state.value,
      focused: false,
    };
    if (!this.state.isInvalid) {
      // auto-format (display our parent form's true value)
      newState.value = this.props.value.toString();
    } else if (this.props.revertOnBlur) {
      // go back to being valid
      newState.value = this.props.value.toString();
    }
    this.setState(newState);
    this.props.onBlur(e);
  }

  onUpArrowKeyDown(e) {
    e.preventDefault();
    if (this.state.isInvalid) return;
    const { value, step, max, lte } = this.props;
    const increment = step === null ? 1 : step;
    const newValue = FormatUtils.roundTo(value + increment, 7);
    let stillInRange = true;
    if (max !== null) {
      if (lte) {
        stillInRange = newValue <= max;
      } else {
        stillInRange = newValue < max;
      }
    }
    if (stillInRange) {
      this.setState(
        {
          value: newValue,
          isInvalid: false,
        },
        () => {
          this.props.onChangeSuccess(newValue);
        }
      );
    }
  }

  onDownArrowKeyDown(e) {
    e.preventDefault();
    if (this.state.isInvalid) return;
    const { value, step, min, gte } = this.props;
    const increment = step === null ? 1 : step;
    const newValue = FormatUtils.roundTo(value - increment, 7);
    let stillInRange = true;
    if (min !== null) {
      if (gte) {
        stillInRange = newValue >= min;
      } else {
        stillInRange = newValue > min;
      }
    }
    if (stillInRange) {
      this.setState(
        {
          value: newValue,
          isInvalid: false,
        },
        () => {
          this.props.onChangeSuccess(newValue);
        }
      );
    }
  }

  onKeyDown(e) {
    if (this.props.stopPropagationOnKeyDown) e.stopPropagation();
    if (this.props.type === 'number' && !this.props.disableArrowKeyIncrement) {
      if (e.keyCode === KEY_CODE_UP) {
        this.onUpArrowKeyDown(e);
        return;
      }
      if (e.keyCode === KEY_CODE_DOWN) {
        this.onDownArrowKeyDown(e);
        return;
      }
    }
    this.props.onKeyDown(e);
  }

  onKeyUp(e) {
    if (this.props.stopPropagationOnKeyUp) e.stopPropagation();
    this.props.onKeyUp(e);
  }

  onPaste(e) {
    if (this.props.stopPropagationOnPaste) e.stopPropagation();
    this.props.onPaste(e);
  }

  getInputMode() {
    switch (this.props.type) {
      case 'number':
        return 'numeric';
      case 'email':
        return 'email';
      case 'url':
        return 'url';
      case 'tel':
        return 'tel';
      default:
        return 'text';
    }
  }

  renderInput(StyledInput) {
    const { type, isAuto, disabled } = this.props;
    const isDisabled = isAuto || disabled;
    // - only set the input's 'type' attribute for passwords, for obfuscation
    // - for the rest, set 'inputmode' instead, for mobile keyboard hints
    //   but no other semantics, e.g. input validation by the browser
    const inputType = type === 'password' ? 'password' : 'text';
    // always disable auto-capitalize for non-text inputs
    const doAutoCapitalize =
      this.props.autoCapitalize && type === 'text' ? 'sentences' : 'none';
    return (
      <StyledInput
        autoComplete={this.props.autoComplete}
        autoFocus={this.props.autoFocus}
        autoCapitalize={doAutoCapitalize}
        ref={this.inputRef}
        type={inputType}
        inputMode={this.getInputMode()}
        placeholder={this.props.placeholder}
        value={this.state.value}
        spellCheck={this.props.spellCheck}
        onFocus={(e) => this.onFocus(e)}
        onBlur={(e) => this.onBlur(e)}
        onChange={(e) => this.onChange(e)}
        onKeyDown={(e) => this.onKeyDown(e)}
        onKeyUp={(e) => this.onKeyUp(e)}
        onPaste={(e) => this.onPaste(e)}
        disabled={isDisabled}
        isAuto={isAuto}
        hasLabel={this.props.label !== ''}
      />
    );
  }

  renderLabel(InputLabelWrapper) {
    if (!this.props.label) return null;
    return (
      <InputLabelWrapper>
        <Caption grey disabled={this.props.disabled}>
          {this.props.label}
        </Caption>
      </InputLabelWrapper>
    );
  }

  renderUnits(UnitLabelWrapper) {
    if (!this.props.units) return null;
    return (
      <UnitLabelWrapper hasLabel={this.props.label !== ''}>
        <Body1 noSpacing grey disabled={this.props.disabled}>
          {this.props.units}
        </Body1>
      </UnitLabelWrapper>
    );
  }

  getToolTipContent() {
    if (this.props.tooltip) {
      return this.props.tooltip;
    }
    if (
      !this.props.disableTooltip &&
      this.state.isInvalid &&
      !(this.props.infoTooltip && this.state.value.trim() === '')
    ) {
      let tooltipContent;
      if (this.state.invalidReason) {
        // a specific reason is available -- use it!
        tooltipContent = this.state.invalidReason;
      } else if (this.state.value.trim().length === 0) {
        // a required field is empty
        tooltipContent = `${this.props.label || 'Value'} is required`;
      } else {
        // most generic, the field is just not valid
        tooltipContent = `${this.props.label || 'Value'} is invalid`;
      }
      return tooltipContent;
    }
    if (this.props.infoTooltip) {
      return this.props.infoTooltip;
    }
    return null;
  }

  render() {
    const { disabled } = this.props;
    // override if any component was passed to replace the defaults
    let {
      StyledContainer,
      StyledInput,
      StyledUnitLabelWrapper,
      StyledInputLabelWrapper,
    } = this.props;
    StyledContainer = StyledContainer || DefaultStyledContainer;
    StyledInput = StyledInput || DefaultStyledInput;
    StyledUnitLabelWrapper = StyledUnitLabelWrapper || DefaultUnitLabelWrapper;
    StyledInputLabelWrapper =
      StyledInputLabelWrapper || DefaultInputLabelWrapper;
    return (
      <StyledContainer
        isInvalid={this.state.isInvalid}
        disabled={disabled}
        title={this.props.title}
        hasLabel={this.props.label !== ''}>
        <ToolTipWrapper
          tooltip={this.getToolTipContent()}
          error={this.state.isInvalid}
          wide={this.props.wideTooltip}
          ref={(ref) => {
            this.toolTipContainer = ref;
          }}>
          {this.renderLabel(StyledInputLabelWrapper)}
          {this.renderUnits(StyledUnitLabelWrapper)}
          {this.renderInput(StyledInput)}
        </ToolTipWrapper>
      </StyledContainer>
    );
  }
}
export default AbstractInput;

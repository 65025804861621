import { call, put } from 'redux-saga/effects';

import { makeRequest } from './horizon';
import { actions } from '../../reducers/iot/iot';

export default function* initWebRtcConnection(action) {
  try {
    const { deviceId } = action.payload;
    const response = yield call(
      makeRequest,
      deviceId,
      'webcam/init-connection-remote',
      {
        method: 'post',
        timeoutMs: 30 * 1000,
      },
      'initWebRtcConnection'
    );
    const { connectionOffer } = response.body;
    yield put(actions.initWebRtcConnectionSuccess(deviceId, connectionOffer));
  } catch (err) {
    yield put(actions.initWebRtcConnectionFailure(err));
  }
}

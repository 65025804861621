import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import { handleGenericError } from '../common';
import { makeRequest } from './horizon';
import { actions } from '../../reducers/iot/iot';

export default function* sendCommand(action) {
  const { device, command } = action.payload;

  try {
    const deviceType = device.type;
    const response = yield call(
      makeRequest,
      device.id,
      deviceType === 'canvas-hub' ? 'command' : 'printer/command',
      {
        method: 'post',
        query: {
          command,
        },
      },
      'sendCommand'
    );

    // status code will equal 200, iff device responds with serial
    // take the message included in the response
    // otherwise, just acknowledge it with the current timestamp
    let msg;
    if (response.status === 200) {
      msg = response.body.message;
    } else {
      msg = `message acknowledged at ${new Date().toISOString()}`;
    }

    yield put(actions.sendCommandSuccess(msg));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import { useEffect } from 'react';

const useDocumentEvent = (events) => {
  useEffect(() => {
    events.forEach((eventArgs) => {
      document.addEventListener(...eventArgs);
    });
    return () =>
      events.forEach((eventArgs) => {
        document.removeEventListener(...eventArgs);
      });
  }, [events]);
};

export default useDocumentEvent;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
`;

export const Section = styled.div`
  margin: 0.75rem 0;
`;

export const Frame = styled.div`
  border: solid 1px ${(props) => props.theme.colors.grey3};
  box-sizing: border-box;
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  padding: 1rem;
  gap: 0.5rem;
`;

export const FrameItem = styled.div`
  display: flex;
`;

export const FrameItemNumber = styled.div`
  width: 3rem;
`;

export const FrameItemValue = styled.div``;

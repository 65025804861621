import WelcomeToCanvas from './welcomeToCanvas.metadata';
import Palette2 from './palette2.metadata';
import Slic3r from './slic3r.metadata';
import UIRefresh1 from './uiRefresh1.metadata';
import UIRefresh2 from './uiRefresh2.metadata';
import UIRefresh3 from './uiRefresh3.metadata';
import Palette3PingsAndCamera from './palette3PingsAndCamera.metadata';

// array order dictates priority when multiple modals are to be shown
export const modalOrder = [
  'welcomeToCanvas',
  'palette2',
  'slic3r',
  'uiRefresh1',
  'uiRefresh2',
  'uiRefresh3',
  'palette3PingsAndCamera',
];

export const modalMetadata = {
  welcomeToCanvas: WelcomeToCanvas,
  palette2: Palette2,
  slic3r: Slic3r,
  uiRefresh1: UIRefresh1,
  uiRefresh2: UIRefresh2,
  uiRefresh3: UIRefresh3,
  palette3PingsAndCamera: Palette3PingsAndCamera,
};

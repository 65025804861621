import styled from 'styled-components';

export const Container = styled.div`
  z-index: 5001;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.overlay};
`;

import React, { Component } from 'react';
import _ from 'lodash';

import {
  FieldContainer,
  ToggleButtonContainer,
  CreateRegionsPanelWrapper,
  ContainerContent,
  AcceptButtonWrapper,
  AcceptButtonSpan,
} from './autoSegment.styles';

import { ModalButtonWrapper } from '../../paintView.styles';

import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Toggle,
  ToolCollapsiblePanel,
} from '../../../../../shared';

import { Subtitle1, Body1 } from '../../../../../shared/typography/typography';

import fields from './autoSegment.metadata';

class AutoSegment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleChecked: false,
      focusField: null,
      values: {
        angleThresholdTriangle: 15,
        angleThresholdSegment: 25,
        sizeThreshold: 5,
      },
      errors: {},
      forceOpen: false,
      headerCustomButton: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { showSegmentBoundaries } = this.props;
    if (showSegmentBoundaries !== prevProps.showSegmentBoundaries) {
      this.setState({ isToggleChecked: showSegmentBoundaries });
    }
  }

  onFieldChange(fieldName, value, error = false) {
    this.setState({
      values: {
        ...this.state.values,
        [fieldName]: value,
      },
      errors: {
        ...this.state.errors,
        [fieldName]: error,
      },
    });
  }

  onFieldChangeSuccess(fieldName, value) {
    this.onFieldChange(fieldName, value, false);
  }

  onFieldChangeFailure(fieldName, value) {
    this.onFieldChange(fieldName, value, true);
  }

  focusField(fieldName) {
    this.setState({ focusField: fieldName }, () => {
      this.setState({ focusField: null });
    });
  }

  getNextSaveError() {
    return _.findKey(this.state.errors) || null;
  }

  handleAutoSegment() {
    const fieldName = this.getNextSaveError();
    if (fieldName) {
      this.focusField(fieldName);
      return;
    }
    this.props.onAutoSegment(
      (this.state.values.angleThresholdTriangle * Math.PI) / 180,
      (this.state.values.angleThresholdSegment * Math.PI) / 180,
      this.state.values.sizeThreshold
    );
  }

  renderBoundaryToggleButton() {
    return (
      <ToggleButtonContainer>
        <Toggle
          disabled={this.props.disabled}
          label='Show boundaries'
          on={!!this.props.showSegmentBoundaries}
          onChange={(e) => this.props.onToggleBoundaries(e.target.checked)}
        />
      </ToggleButtonContainer>
    );
  }

  renderParamField(field) {
    const { name, label } = field;
    const { min, max, gte, step, units } = field.variants[0];
    return (
      <FieldContainer key={name}>
        <Input
          type='number'
          label={label}
          value={this.state.values[name]}
          isInvalid={this.state.errors[name]}
          forceFocus={this.state.focusField === name}
          min={min}
          gte={gte}
          max={max}
          step={step}
          infoTooltip={field.tooltip}
          units={units}
          stopPropagationOnKeyDown
          onChangeSuccess={(value) => this.onFieldChangeSuccess(name, value)}
          onChangeFailure={(value) => this.onFieldChangeFailure(name, value)}
        />
      </FieldContainer>
    );
  }

  renderParamFields() {
    return _.map(fields, (field) => this.renderParamField(field));
  }

  renderAcceptButton() {
    return (
      <FieldContainer>
        <AcceptButtonWrapper>
          <Button
            minWidth='100%'
            primary
            onClick={() => this.handleAutoSegment()}>
            {'Create'}
            <AcceptButtonSpan>regions</AcceptButtonSpan>
          </Button>
        </AcceptButtonWrapper>
      </FieldContainer>
    );
  }

  renderAutoSegment() {
    return (
      <CreateRegionsPanelWrapper>
        <ToolCollapsiblePanel
          label='Create Regions'
          isCollapsed={this.props.isCollapsed}
          scroll={false}
          forceOpen={this.props.forceOpen}
          headerCustomButton={this.props.headerCustomButton}>
          <ContainerContent>
            {this.renderBoundaryToggleButton()}
            {this.renderParamFields()}
            {this.renderAcceptButton()}
          </ContainerContent>
        </ToolCollapsiblePanel>
      </CreateRegionsPanelWrapper>
    );
  }

  renderWarningModal() {
    if (!this.props.showWarningModal) return null;
    return (
      <Modal width='30em'>
        <ModalHeader>
          <Subtitle1>Creating regions for large model</Subtitle1>
        </ModalHeader>
        <ModalBody>
          <Body1>
            Creating regions for a model this large may take a while and use
            significant memory.
          </Body1>
          <Body1>Would you like to save before continuing?</Body1>
        </ModalBody>
        <ModalFooter>
          <ModalButtonWrapper>
            <Button
              primary
              minWidth='6rem'
              onClick={this.props.onAcceptWarning}>
              Yes
            </Button>
          </ModalButtonWrapper>
          <ModalButtonWrapper>
            <Button minWidth='6rem' onClick={this.props.onIgnoreWarning}>
              Continue without saving
            </Button>
          </ModalButtonWrapper>
          <ModalButtonWrapper>
            <Button minWidth='6rem' onClick={this.props.onCancelWarning}>
              Cancel
            </Button>
          </ModalButtonWrapper>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    return (
      <>
        {this.renderAutoSegment()}
        {this.renderWarningModal()}
      </>
    );
  }
}

export default AutoSegment;

import React from 'react';
import { Caption, UL } from '../../../../../shared';

const skinThickness = (
  <>
    Specify the thickness of the outer shell of your model. This thickness will
    be used for top and bottom layers, as well as adding solid material to the
    outside of your part when set thicker than your number of perimeters. A
    higher value will provide a stronger part and more filled-in top and bottom
    layers. However, more material will be used and print times will increase.
  </>
);

const topSkinThickness = (
  <>
    <>Specify the thickness of the top shell shell of your model.</>
    <>Only available when using Slic3r.</>
  </>
);

const semiSolidLayers = (
  <>
    <>
      When enabled, the final infill layers below top surfaces will be made 50%
      dense. This allows you to use less solid layers for top surfaces, saving
      time and material while still producing a good top surface finish.
    </>
    <>Only available when using KISSlicer.</>
  </>
);

const solidLayerSpeed = (
  <>
    This value specifies the print speed while printing top and bottom layers.
    Reducing speed will create higher quality surfaces, while increasing the
    speed will reduce print times.
  </>
);

const rapidXYSpeed = (
  <>
    Specify the speed your printer should move while not printing material. This
    speed is generally close to the top speed your printer can consistently move
    left or right, back or forward. A speed too fast may lead to inaccurate
    prints, while too slow can greatly increase print times and unwanted
    filament stringing.
  </>
);

const rapidZSpeed = (
  <>
    Specify the speed your printer should move in the vertical direction while
    not printing material. This speed is used most commonly when switching
    between layers or during a Z-lift movement.
  </>
);

const avoidCrossingPerimeters = (
  <>
    <>
      Attempt to stay within the boundary of a printed region when travelling.
      This can reduce the chance of the nozzle contaminating regions of
      different colors or materials.
    </>
    <>Only available when using Slic3r.</>
  </>
);

const avoidCrossingPerimetersMaxDetour = (
  <>
    <>
      Specify the maximum increase in travel distance when attempting to avoid
      crossing perimeters. This can be specified as an absolute maximum extra
      distance in mm (e.g. detours of no more than 50 cm) or a maximum
      multiplier (e.g. detours no more than 50% longer than a direct travel).
    </>
    <>Only available when using Slic3r.</>
  </>
);

const solidLayerStyle = (
  <>
    <>Select the style to use when generating top and bottom solid layers.</>
    <UL>
      <li>
        <Caption noSpacing>
          Straight: Fill the layer with a series of parallel lines.
        </Caption>
      </li>
      <li>
        <Caption noSpacing>
          Straight (no backtracking): Same pattern as ‘Straight’, but without
          backtracking. See ‘Avoid backtracking on solid layers’ below.
        </Caption>
      </li>
      <li>
        <Caption>
          Concentric: Fill the layer with a series of perimeters, producing a
          ring-like effect.
        </Caption>
      </li>
    </UL>
    <>Only available when using Slic3r.</>
  </>
);

const topSolidLayerStyle = (
  <>
    <>
      Select the style to use when generating just top solid layers. Choosing
      ‘Auto’ will use the same setting as ‘Solid layer style’.
    </>
    <>Only available when using Slic3r.</>
  </>
);

const monotonicSweep = (
  <>
    <>
      Turning this setting on will cause all solid surfaces to be printed from
      one side of the part to the other. This may increase print time and
      retractions significantly, but can lead to more uniform and visually
      appealing surfaces. This is especially noticeable for parts with large and
      flat top layers.
    </>
    <>Only available when using KISSlicer.</>
  </>
);

const useIroning = (
  <>
    <>
      Ironing smooths out top layers by travelling along them and re-extruding a
      small amount of filament to fill the troughs between extrusions.
    </>
    <>Only available when using Slic3r.</>
  </>
);

const ironingFlowrate = (
  <>
    <>The amount of filament to extrude during an ironing pass.</>
    <>Only available when using Slic3r.</>
  </>
);

const ironingSpacing = (
  <>
    <>The distance between ironing paths.</>
    <>Only available when using Slic3r.</>
  </>
);

const ironingSpeed = (
  <>
    <>The speed to use when ironing.</>
    <>Only available when using Slic3r.</>
  </>
);

export default {
  skinThickness,
  topSkinThickness,
  semiSolidLayers,
  solidLayerSpeed,
  rapidXYSpeed,
  rapidZSpeed,
  avoidCrossingPerimeters,
  avoidCrossingPerimetersMaxDetour,
  solidLayerStyle,
  topSolidLayerStyle,
  monotonicSweep,
  useIroning,
  ironingFlowrate,
  ironingSpacing,
  ironingSpeed,
};

import styled from 'styled-components';

import {
  defaultModalButtonStyles,
  defaultModalInputStyles,
} from '../modal/modal.styles';

export const DropdownWrapper = styled.div`
  display: grid;
  grid-column: span 2;
  justify-self: stretch;
  width: 100%;
`;

export const ModalButtonWrapper = styled.div`
  ${defaultModalButtonStyles}
  margin-top: 1em;
  margin-left: 0.5em;
`;

export const ModalInputWrapper = styled.div`
  ${defaultModalInputStyles}
`;

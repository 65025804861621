import { put } from 'redux-saga/effects';

import { InterfaceUtils, MonitoringUtils } from '../utils';

export const getAuthState = (state) => state.auth;
export const getPrinterState = (state) => state.printers;
export const getSlicerState = (state) => state.slicer;
export const getThreeState = (state) => state.three;
export const getIotState = (state) => state.iot;
export const getOnboardingState = (state) => state.onboarding;

export function* handleGenericError(action, error) {
  if (error.type === 'unauthorized') {
    // user logged out before API request was sent
    return;
  }
  if (error.name !== 'CanvasApiError' || !error.status || error.status >= 500) {
    // report errors to Sentry
    // - any error that isn't from an API call
    // - any API call error without a status (should never occur in practice)
    // - any 500-level API call error
    MonitoringUtils.sentryManualReport(error);
  }
  const errorType = action.type.replace('_REQUEST', '_FAILURE');
  const errorAction = {
    type: errorType,
    payload: {
      error,
    },
  };
  yield put(errorAction);
  InterfaceUtils.emitToast('error', error.message);
}

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import { getAuthState, getThreeState, handleGenericError } from '../common';
import { actions } from '../../reducers/three/three';

import { SceneUtils, ResponsiveUtils } from '../../utils';
import { getTheme } from '../../themes';
import { setRenderFlag } from './animationFrame';

export default function* updatePrintBed(action) {
  try {
    const { printer, render } = action.payload;
    const { viewOptions, preferences } = yield select(getAuthState);
    const state = yield select(getThreeState);
    const { scene } = state;
    const themePreference =
      preferences.theme === 'os'
        ? ResponsiveUtils.getUserOSThemePreference()
        : preferences.theme;
    const theme = getTheme(themePreference);
    let { originAxes, printBed, printBedBoundingBox, printBedGrid } = state;

    // remove existing, if necessary
    if (originAxes) {
      scene.remove(originAxes);
      _.forEach(originAxes.children, (child) => {
        child.material.dispose();
        child.geometry.dispose();
      });
      originAxes = null;
    }
    if (printBed) {
      scene.remove(printBed);
      _.forEach(printBed.children, (child) => {
        child.material.dispose();
        child.geometry.dispose();
      });
    }
    if (printBedBoundingBox) {
      scene.remove(printBedBoundingBox);
      _.forEach(printBedBoundingBox.children, (child) => {
        child.material.dispose();
        child.geometry.dispose();
      });
      printBedBoundingBox = null;
    }
    if (printBedGrid) {
      scene.remove(printBedGrid);
      _.forEach(printBedGrid.children, (child) => {
        child.material.dispose();
        child.geometry.dispose();
      });
      printBedGrid = null;
    }

    const { circular, bedSize, originOffset } = printer.machineSettings;

    // create origin axis lines
    if (viewOptions.originAxes) {
      originAxes = SceneUtils.createAxes(15);
      scene.add(originAxes);
    }

    // create solid print bed
    printBed = SceneUtils.createPrintBed(
      bedSize.x,
      bedSize.y,
      circular,
      theme.colors.printBed
    );
    printBed.position.setX(bedSize.x / 2 - originOffset.x);
    printBed.position.setY(bedSize.y / 2 - originOffset.y);
    printBed.updateMatrixWorld();
    scene.add(printBed);

    // create bed bounding box if enabled
    if (viewOptions.boundingBox) {
      printBedBoundingBox = SceneUtils.createPrintBedBoundingBox(
        printer.machineSettings.bedSize,
        printer.machineSettings.originOffset,
        printer.machineSettings.circular
      );
      scene.add(printBedBoundingBox);
    }

    // create grid lines if enabled
    if (viewOptions.gridLines) {
      printBedGrid = SceneUtils.createPrintBedGrid(
        printer.machineSettings.bedSize,
        printer.machineSettings.originOffset,
        printer.machineSettings.circular,
        theme.colors.printBedGridMajor,
        theme.colors.printBedGridMinor
      );
      scene.add(printBedGrid);
    }

    // store new mesh references
    const printerHeight = printer.machineSettings.bedSize.z;
    yield put(
      actions.updatePrintBedSuccess(
        printerHeight,
        printBed,
        printBedBoundingBox,
        printBedGrid,
        originAxes
      )
    );
    if (render) {
      setRenderFlag();
    }
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import styled from 'styled-components';

import { Breakpoints } from '../../themes';
import { Caption, Image, Link } from '../../shared';

export const LogoutButtonWrapper = styled.div`
  @media (max-width: ${Breakpoints.mediumWidth}) {
    margin-left: -1rem;
  }
`;

export const PasswordButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${Breakpoints.mediumWidth}) {
    justify-content: flex-start;
    margin-left: -1rem;
  }
`;

export const EditPasswordWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCaption = styled(Caption)`
  cursor: pointer;
`;

export const ProjectColorsContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grey3};
  padding: 0.75rem;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
`;

export const ColorPalette = styled.div`
  display: grid;
  grid-template-columns: repeat(4, min-content);
  grid-row-gap: 0.25rem;
  grid-column-gap: 0.25rem;
`;

export const PasswordRequirements = styled.ul`
  margin: 0.75rem 0 1rem -1rem;
`;

export const IntegrationsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-gap: 1rem;
  width: 100%;
`;

export const Integration = styled.div`
  padding: 1.25rem;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const IntegrationTextContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  flex-direction: column;
`;

export const IntegrationImage = styled(Image)`
  height: 4rem;
  width: 4rem;
`;

export const IntegrationLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`;

export const IntegrationButtonWrapper = styled.div`
  margin-top: 1.25rem;
  margin-left: -1rem;
`;

export const LoginRecordContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  border: solid 1px ${(props) => props.theme.colors.grey3};
  padding: 0.75rem;
  border-radius: 0.25rem;
`;

export const AccountManagerInputWrapper = styled.div`
  margin-top: 0.5rem;
`;

export const LoginRecordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-self: end;
`;

export const FooterButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

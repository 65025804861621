import THREETypes from './types';
import THREEActions from './actions';
import THREEInitialState from './initialState';

export const types = THREETypes;
export const actions = THREEActions;
export const initialState = THREEInitialState;

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.INIT_SCENE_REQUEST: {
      return {
        ...state,
        canvas: payload.canvas,
        status: {
          ...state.status,
          initScenePending: true,
          initSceneSuccess: false,
        },
      };
    }
    case types.INIT_SCENE_SUCCESS: {
      const {
        scene,
        camera,
        renderer,
        controls,
        ambientLight,
        pointLight,
        gizmo,
      } = payload;
      return {
        ...state,
        scene,
        camera,
        renderer,
        controls,
        ambientLight,
        pointLight,
        gizmo,
        status: {
          ...state.status,
          initScenePending: false,
          initSceneSuccess: true,
        },
      };
    }
    case types.INIT_SCENE_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          initScenePending: false,
          initSceneSuccess: false,
        },
      };
    }
    case types.DESTROY_SCENE_REQUEST: {
      return {
        ...state,
        hoveredModels: [],
        status: {
          ...state.status,
          destroyScenePending: true,
          destroySceneSuccess: false,
        },
      };
    }
    case types.DESTROY_SCENE_SUCCESS: {
      return {
        ...state,
        printerHeight: 0,
        scene: null,
        camera: null,
        renderer: null,
        controls: null,
        originAxes: null,
        printBed: null,
        printBedBoundingBox: null,
        printBedGrid: null,
        ambientLight: null,
        pointLight: null,
        status: {
          ...state.status,
          destroyScenePending: false,
          destroySceneSuccess: true,
        },
      };
    }
    case types.DESTROY_SCENE_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          destroyScenePending: false,
          destroySceneSuccess: false,
        },
      };
    }
    case types.ENABLE_CONTROLS: {
      const { controls } = state;
      if (controls) {
        controls.enableZoom = true;
        controls.enableRotate = true;
        controls.enablePan = true;
      }
      return state;
    }
    case types.DISABLE_CONTROLS: {
      const { controls } = state;
      if (controls) {
        controls.enableZoom = false;
        controls.enableRotate = false;
        controls.enablePan = false;
      }
      return state;
    }
    case types.REPLACE_CAMERA_SUCCESS: {
      const { camera } = payload;
      return {
        ...state,
        camera,
      };
    }
    case types.UPDATE_PRINT_BED_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          updatePrintBedPending: true,
        },
      };
    }
    case types.UPDATE_PRINT_BED_SUCCESS: {
      const {
        printerHeight,
        printBed,
        printBedBoundingBox,
        printBedGrid,
        originAxes,
      } = payload;
      return {
        ...state,
        printerHeight,
        printBed,
        printBedBoundingBox,
        printBedGrid,
        originAxes,
        status: {
          ...state.status,
          updatePrintBedPending: false,
        },
      };
    }
    case types.UPDATE_PRINT_BED_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updatePrintBedPending: false,
        },
      };
    }
    case types.ADD_BOUNDARIES_SUCCESS: {
      const { boundaries } = payload;
      return {
        ...state,
        boundaries,
      };
    }
    case types.REMOVE_BOUNDARIES_SUCCESS: {
      return {
        ...state,
        boundaries: null,
      };
    }
    case types.HOVER_MODELS: {
      const { hoveredModels } = payload;
      return {
        ...state,
        hoveredModels,
      };
    }
    case types.TOGGLE_MOVING_SWATCH: {
      return {
        ...state,
        status: {
          ...state.status,
          isMovingSwatch: !state.status.isMovingSwatch,
        },
      };
    }
    case types.ATTACH_GIZMO_SUCCESS: {
      const { gizmoCenter } = payload;
      return {
        ...state,
        gizmoCenter,
        status: {
          ...state.status,
          updateGizmoPending: false,
        },
      };
    }
    case types.UPDATE_GIZMO_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          updateGizmoPending: true,
        },
      };
    }
    case types.UPDATE_GIZMO_SUCCESS: {
      const { gizmoCenter } = payload;
      return {
        ...state,
        gizmoCenter,
        status: {
          ...state.status,
          updateGizmoPending: false,
        },
      };
    }
    case types.DETACH_GIZMO_SUCCESS: {
      return {
        ...state,
        gizmoCenter: null,
        status: {
          ...state.status,
          updateGizmoPending: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};

import styled from 'styled-components';
import { Breakpoints } from '../../themes';

export const div100vhStyles = {
  width: '100%',
};

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 4.5rem;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  user-select: none;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const UploadButtonWrapper = styled.div`
  margin-left: 1rem;
  margin-right: auto;
  z-index: 1;

  i {
    color: ${(props) => props.theme.colors.whiteDefault};
  }

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: none;
  }
`;

export const ProfilesPanelWrapper = styled.div`
  z-index: 1;
  position: absolute;
  top: 4.5rem;
  right: 1rem;
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: ${(props) => (props.showProfilesPanelMobile ? 'block' : 'none')};
  }
`;

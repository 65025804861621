import styled from 'styled-components';

const OnlineIndicator = styled.div`
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  box-sizing: border-box;
  border: solid 2px
    ${(props) => (props.online ? 'transparent' : props.theme.colors.grey4)};
  background-color: ${(props) => {
    // Titanium offline state takes precedence
    if (!props.online) return 'transparent';
    // if Titanium online, indicate device connectivity
    if (props.connected) return props.theme.colors.greenDark;
    return props.theme.colors.grey4;
  }};
`;

export default OnlineIndicator;

import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { Container, InvisibleInput } from './radioButton.styles';

import Icon from '../icon/icon.jsx';
import { Icons } from '../../themes';

class RadioButton extends Component {
  static defaultProps = {
    value: '', // tie to state of your component
    checked: false,
    onChange: () => {},
    groupName: '',
    disabled: false,
  };

  renderInput() {
    return (
      <InvisibleInput
        type='radio'
        name={this.props.groupName}
        checked={this.props.checked}
        value={this.props.value}
        disabled={this.props.disabled}
        onChange={(e) => this.props.onChange(e)}
      />
    );
  }

  renderCircle() {
    if (this.props.disabled) {
      return (
        <Icon
          src={
            this.props.checked ? Icons.basic.radioChecked : Icons.basic.radio
          }
          color={this.props.theme.colors.grey4}
        />
      );
    }
    return (
      <Icon
        src={this.props.checked ? Icons.basic.radioChecked : Icons.basic.radio}
        color={
          this.props.checked
            ? this.props.theme.colors.greenDefault
            : this.props.theme.colors.grey5
        }
      />
    );
  }

  render() {
    return (
      <Container disabled={this.props.disabled}>
        {this.renderInput()}
        {this.renderCircle()}
      </Container>
    );
  }
}

export default withTheme(RadioButton);

import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import {
  StyledContainer,
  PanelContainer,
  NewNotificationsCount,
  ActiveJobCount,
} from './headerBarRight.styles';

import JobMonitor from '../jobMonitor/jobMonitor.jsx';
import NotificationList from '../notificationList/notificationList.container';
import UserPanel from '../userPanel/userPanel.jsx';

import ActionButton from '../../../actionButton/actionButton.jsx';
import ShareProjectModal from '../../../shareProjectModal/shareProjectModal.container';

import { Icons } from '../../../../themes';
import { FormatUtils } from '../../../../utils';
import { usePanel } from '../../../../hooks';
import { Body1OneLine } from '../../../typography/typography';

const HeaderBarRight = ({
  sliceJobs,
  devicesWithPrintJobs,
  pausePrint,
  resumePrint,
  cancelSlice,
  notifications,
  currentProject,
  projects,
  dispatchSliceJobPending,
  cancelSliceJobPending,
}) => {
  const theme = useContext(ThemeContext);
  const history = useHistory();

  const [sharePanelRef, isSharePanelOpen, setIsSharePanelOpen] = usePanel();
  const [jobMonitorRef, isJobMonitorOpen, setIsJobMonitorOpen] = usePanel();
  const [
    notificationPanelRef,
    isNotificationPanelOpen,
    setIsNotificationPanelOpen,
  ] = usePanel();
  const [userPanelRef, isUserPanelOpen, setIsUserPanelOpen] = usePanel();

  const toggleAllPanels = (share, jobMonitor, notification, user) => {
    setIsSharePanelOpen(share);
    setIsJobMonitorOpen(jobMonitor);
    setIsNotificationPanelOpen(notification);
    setIsUserPanelOpen(user);
  };
  const toggleSharePanel = () =>
    toggleAllPanels(!isSharePanelOpen, false, false, false);
  const toggleJobMonitorPanel = () =>
    toggleAllPanels(false, !isJobMonitorOpen, false, false);
  const toggleNotificationPanel = () =>
    toggleAllPanels(false, false, !isNotificationPanelOpen, false);
  const toggleUserPanel = () =>
    toggleAllPanels(false, false, false, !isUserPanelOpen);

  const renderSharePanel = () => {
    const isInSlicer = history.location.pathname.includes('/slicer/');
    const projectDataReady =
      currentProject && _.includes(_.keys(projects), currentProject);
    if (!isInSlicer || !projectDataReady) return null;

    const project = projects[currentProject];
    const { shareData, modified } = project;
    const currentlyShared = shareData && shareData.shareId;
    const updated = currentlyShared ? modified > shareData.dateShared : false;
    let color = theme.colors.grey4;
    if (currentlyShared) {
      color = updated ? theme.colors.yellowDark : theme.colors.greenDefault;
    }

    return (
      <PanelContainer ref={sharePanelRef}>
        <ActionButton
          clean
          icon={Icons.basic.share}
          iconColor={color}
          onClick={toggleSharePanel}
        />
        {isSharePanelOpen && (
          <ShareProjectModal
            onMarginClick={toggleSharePanel}
            selectedProject={projects[currentProject]}
          />
        )}
      </PanelContainer>
    );
  };

  const renderJobMonitor = () => {
    const jobCount = _.size(sliceJobs) + _.size(devicesWithPrintJobs);
    return (
      <PanelContainer ref={jobMonitorRef} onClick={toggleJobMonitorPanel}>
        <ActionButton
          clean
          icon={Icons.fdm.setup}
          iconColor={
            jobCount > 0 ? theme.colors.greenDefault : theme.colors.grey6
          }
        />
        {jobCount > 0 && (
          <ActiveJobCount>
            <Body1OneLine noSpacing green>
              {jobCount} {FormatUtils.pluralize('job', jobCount)}
            </Body1OneLine>
          </ActiveJobCount>
        )}
        {isJobMonitorOpen && (
          <JobMonitor
            sliceJobs={sliceJobs}
            devicesWithPrintJobs={devicesWithPrintJobs}
            onPausePrint={pausePrint}
            onResumePrint={resumePrint}
            onCancelSlice={cancelSlice}
            dispatchSliceJobPending={dispatchSliceJobPending}
            cancelSliceJobPending={cancelSliceJobPending}
          />
        )}
      </PanelContainer>
    );
  };

  const renderNotificationPanel = () => {
    const unreadItemsCount = _.reduce(
      notifications,
      (total, item) => total + (item.read ? 0 : 1),
      0
    );

    return (
      <PanelContainer
        ref={notificationPanelRef}
        onClick={toggleNotificationPanel}>
        <ActionButton clean icon={Icons.basic.bell} />
        {unreadItemsCount > 0 && (
          <NewNotificationsCount>{unreadItemsCount}</NewNotificationsCount>
        )}
        {isNotificationPanelOpen && <NotificationList />}
      </PanelContainer>
    );
  };

  const renderUserPanel = () => (
    <PanelContainer ref={userPanelRef} onClick={toggleUserPanel}>
      <ActionButton clean icon={Icons.user.user} />
      {isUserPanelOpen && <UserPanel onTogglePanel={toggleUserPanel} />}
    </PanelContainer>
  );

  return (
    <StyledContainer>
      {renderSharePanel()}
      {renderJobMonitor()}
      {renderNotificationPanel()}
      {renderUserPanel()}
    </StyledContainer>
  );
};

export default HeaderBarRight;

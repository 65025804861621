import React from 'react';
import { Caption, UL } from '../../../../../shared';

const infillDensity = (
  <>
    Set the relative amount of plastic to be used in the inside of a print. 0%
    would create a hollow part, while 100% would be completely solid. Higher
    infill densities support top surfaces better, add strength and rigidity to
    parts, and increase part weight. Lower densities will use less material and
    print faster.
  </>
);

const spiralVaseMode = (
  <>
    Enabling vase mode prints a hollow part with no top surfaces. Shell
    thickness and number of perimeters will be used to determine the thickness
    of bottom surfaces and walls.
  </>
);

const infillExtrusionWidth = (
  <>
    Set the extrusion width to be used while printing infill. A thicker
    extrusion width can strengthen the printed part and reduce print times.
  </>
);

const infillSpeed = (
  <>This value specifies the print speed while printing infill.</>
);

const infillPerimeterOverlap = (
  <>
    Specify the distance infill print lines overlap with perimeters. This
    setting is also used to specify the overlap between top and bottom surfaces
    and their perimeters. A higher overlap will bond more to the perimeter and
    remove gaps on top and bottom surfaces. 0% means the infill print line will
    end just as it reaches the perimeter, whereas 100% will continue printing
    until the path moves through the entire innermost perimeter. A value too
    high may lead to ridges where either an infill or surface print line touches
    the perimeter.
  </>
);

const infillStyle = (
  <>
    <Caption noSpacing>Select the style to use when generating infill.</Caption>
    <UL>
      <li>
        <Caption noSpacing>
          Straight: A pattern of alternating perpendicular lines, that prints
          quickly and provides moderate strength to the part.
        </Caption>
      </li>
      <li>
        <Caption noSpacing>
          Octagonal: A pattern of octagons for a slightly slower print than
          ‘Straight’ with slightly more strength.
        </Caption>
      </li>
      <li>
        <Caption noSpacing>
          Rounded: ‘Octagonal’ pattern with smoothed out corners, printing
          slightly faster than ‘Octagonal’ but resulting in a larger G-code
          file.
        </Caption>
      </li>
      <li>
        <Caption>
          Cellular: 3D infill pattern which may improve strength in different
          loading directions, however is only recommended for materials with
          strong interlayer adhesion.
        </Caption>
      </li>
    </UL>
  </>
);

export default {
  infillDensity,
  spiralVaseMode,
  infillExtrusionWidth,
  infillSpeed,
  infillPerimeterOverlap,
  infillStyle,
};

const types = {
  RESET_SLICER_STORE: 'SLICER/RESET_SLICER_STORE',

  LOAD_PROJECTS_REQUEST: 'SLICER/LOAD_PROJECTS_REQUEST',
  LOAD_PROJECTS_SUCCESS: 'SLICER/LOAD_PROJECTS_SUCCESS',
  LOAD_PROJECTS_FAILURE: 'SLICER/LOAD_PROJECTS_FAILURE',

  UNLOAD_MODELS: 'SLICER/UNLOAD_MODELS',

  CREATE_PROJECT_REQUEST: 'SLICER/CREATE_PROJECT_REQUEST',
  CREATE_PROJECT_SUCCESS: 'SLICER/CREATE_PROJECT_SUCCESS',
  CREATE_PROJECT_FAILURE: 'SLICER/CREATE_PROJECT_FAILURE',
  RESET_NEW_PROJECT_FLAGS: 'SLICER/RESET_NEW_PROJECT_FLAGS',

  CREATE_CALIBRATION_PROJECT_REQUEST:
    'SLICER/CREATE_CALIBRATION_PROJECT_REQUEST',
  CREATE_CALIBRATION_PROJECT_SUCCESS:
    'SLICER/CREATE_CALIBRATION_PROJECT_SUCCESS',
  CREATE_CALIBRATION_PROJECT_FAILURE:
    'SLICER/CREATE_CALIBRATION_PROJECT_FAILURE',

  LOAD_PROJECT_REQUEST: 'SLICER/LOAD_PROJECT_REQUEST',
  LOAD_PROJECT_SUCCESS: 'SLICER/LOAD_PROJECT_SUCCESS',
  LOAD_PROJECT_FAILURE: 'SLICER/LOAD_PROJECT_FAILURE',

  UNLOAD_CURRENT_PROJECT: 'SLICER/UNLOAD_CURRENT_PROJECT',

  SET_INPUT_REMAPPER_CONTEXT: 'SLICER/SET_INPUT_REMAPPER_CONTEXT',
  CLEAR_INPUT_REMAPPER_CONTEXT: 'SLICER/CLEAR_INPUT_REMAPPER_CONTEXT',

  REMAP_PROJECT_INPUTS_REQUEST: 'SLICER/REMAP_PROJECT_INPUTS_REQUEST',
  REMAP_PROJECT_INPUTS_SUCCESS: 'SLICER/REMAP_PROJECT_INPUTS_SUCCESS',
  REMAP_PROJECT_INPUTS_FAILURE: 'SLICER/REMAP_PROJECT_INPUTS_FAILURE',

  DELETE_PROJECTS_REQUEST: 'SLICER/DELETE_PROJECTS_REQUEST',
  DELETE_PROJECTS_SUCCESS: 'SLICER/DELETE_PROJECTS_SUCCESS',
  DELETE_PROJECTS_FAILURE: 'SLICER/DELETE_PROJECTS_FAILURE',

  SET_INTERSECT_MODE: 'SLICER/SET_INTERSECT_MODE',

  UPLOAD_STL_REQUEST: 'SLICER/UPLOAD_STL_REQUEST',
  UPLOAD_STL_SUCCESS: 'SLICER/UPLOAD_STL_SUCCESS',
  UPLOAD_STL_FAILURE: 'SLICER/UPLOAD_STL_FAILURE',
  UPDATE_UPLOAD_PROGRESS: 'SLICER/UPDATE_UPLOAD_PROGRESS',

  CENTER_PROJECT_REQUEST: 'SLICER/CENTER_PROJECT_REQUEST',
  CENTER_PROJECT_SUCCESS: 'SLICER/CENTER_PROJECT_SUCCESS',
  CENTER_PROJECT_FAILURE: 'SLICER/CENTER_PROJECT_FAILURE',

  REPACK_REQUEST: 'SLICER/REPACK_REQUEST',
  REPACK_SUCCESS: 'SLICER/REPACK_SUCCESS',
  REPACK_FAILURE: 'SLICER/REPACK_FAILURE',

  DELETE_MODELS_REQUEST: 'SLICER/DELETE_MODELS_REQUEST',
  DELETE_MODELS_SUCCESS: 'SLICER/DELETE_MODELS_SUCCESS',
  DELETE_MODELS_FAILURE: 'SLICER/DELETE_MODELS_FAILURE',

  SELECT_MATERIAL: 'SLICER/SELECT_MATERIAL',

  GET_MATERIALS_REQUEST: 'SLICER/GET_MATERIALS_REQUEST',
  GET_MATERIALS_SUCCESS: 'SLICER/GET_MATERIALS_SUCCESS',
  GET_MATERIALS_FAILURE: 'SLICER/GET_MATERIALS_FAILURE',

  CREATE_MATERIAL_REQUEST: 'SLICER/CREATE_MATERIAL_REQUEST',
  CREATE_MATERIAL_SUCCESS: 'SLICER/CREATE_MATERIAL_SUCCESS',
  CREATE_MATERIAL_FAILURE: 'SLICER/CREATE_MATERIAL_FAILURE',

  EDIT_MATERIAL_REQUEST: 'SLICER/EDIT_MATERIAL_REQUEST',
  EDIT_MATERIAL_SUCCESS: 'SLICER/EDIT_MATERIAL_SUCCESS',
  EDIT_MATERIAL_FAILURE: 'SLICER/EDIT_MATERIAL_FAILURE',

  DELETE_MATERIALS_REQUEST: 'SLICER/DELETE_MATERIALS_REQUEST',
  DELETE_MATERIALS_SUCCESS: 'SLICER/DELETE_MATERIALS_SUCCESS',
  DELETE_MATERIALS_FAILURE: 'SLICER/DELETE_MATERIALS_FAILURE',

  UPDATE_PROJECT_COLORS_REQUEST: 'SLICER/UPDATE_PROJECT_COLORS_REQUEST',
  UPDATE_PROJECT_COLORS_SUCCESS: 'SLICER/UPDATE_PROJECT_COLORS_SUCCESS',
  UPDATE_PROJECT_COLORS_FAILURE: 'SLICER/UPDATE_PROJECT_COLORS_FAILURE',

  UPDATE_VARIABLE_TRANSITION_LENGTHS_REQUEST:
    'SLICER/UPDATE_VARIABLE_TRANSITION_LENGTHS_REQUEST',
  UPDATE_VARIABLE_TRANSITION_LENGTHS_SUCCESS:
    'SLICER/UPDATE_VARIABLE_TRANSITION_LENGTHS_SUCCESS',
  UPDATE_VARIABLE_TRANSITION_LENGTHS_FAILURE:
    'SLICER/UPDATE_VARIABLE_TRANSITION_LENGTHS_FAILURE',

  CHANGE_MATERIAL_REQUEST: 'SLICER/CHANGE_MATERIAL_REQUEST',
  CHANGE_MATERIAL_SUCCESS: 'SLICER/CHANGE_MATERIAL_SUCCESS',
  CHANGE_MATERIAL_FAILURE: 'SLICER/CHANGE_MATERIAL_FAILURE',

  GET_SPLICE_SETTINGS_REQUEST: 'SLICER/GET_SPLICE_SETTINGS_REQUEST',
  GET_SPLICE_SETTINGS_SUCCESS: 'SLICER/GET_SPLICE_SETTINGS_SUCCESS',
  GET_SPLICE_SETTINGS_FAILURE: 'SLICER/GET_SPLICE_SETTINGS_FAILURE',

  CREATE_SPLICE_SETTINGS_REQUEST: 'SLICER/CREATE_SPLICE_SETTINGS_REQUEST',
  CREATE_SPLICE_SETTINGS_SUCCESS: 'SLICER/CREATE_SPLICE_SETTINGS_SUCCESS',
  CREATE_SPLICE_SETTINGS_FAILURE: 'SLICER/CREATE_SPLICE_SETTINGS_FAILURE',

  UPDATE_PROJECT_NAME_REQUEST: 'SLICER/UPDATE_PROJECT_NAME_REQUEST',
  UPDATE_PROJECT_NAME_SUCCESS: 'SLICER/UPDATE_PROJECT_NAME_SUCCESS',
  UPDATE_PROJECT_NAME_FAILURE: 'SLICER/UPDATE_PROJECT_NAME_FAILURE',

  UPDATE_PROJECT_SETTINGS_REQUEST: 'SLICER/UPDATE_PROJECT_SETTINGS_REQUEST',
  UPDATE_PROJECT_SETTINGS_SUCCESS: 'SLICER/UPDATE_PROJECT_SETTINGS_SUCCESS',
  UPDATE_PROJECT_SETTINGS_FAILURE: 'SLICER/UPDATE_PROJECT_SETTINGS_FAILURE',

  INVALIDATE_SLICE: 'SLICER/INVALIDATE_SLICE',

  GET_SLICE_JOBS_REQUEST: 'SLICER/GET_SLICER_JOBS_REQUEST',
  GET_SLICE_JOBS_SUCCESS: 'SLICER/GET_SLICER_JOBS_SUCCESS',
  GET_SLICE_JOBS_FAILURE: 'SLICER/GET_SLICER_JOBS_FAILURE',

  DISPATCH_SLICE_JOB_REQUEST: 'SLICER/DISPATCH_SLICE_JOB_REQUEST',
  DISPATCH_SLICE_JOB_SUCCESS: 'SLICER/DISPATCH_SLICE_JOB_SUCCESS',
  DISPATCH_SLICE_JOB_FAILURE: 'SLICER/DISPATCH_SLICE_JOB_FAILURE',

  CANCEL_SLICE_JOB_REQUEST: 'SLICER/CANCEL_SLICE_JOB_REQUEST',
  CANCEL_SLICE_JOB_SUCCESS: 'SLICER/CANCEL_SLICE_JOB_SUCCESS',
  CANCEL_SLICE_JOB_FAILURE: 'SLICER/CANCEL_SLICE_JOB_FAILURE',

  UPDATE_SLICE_JOB: 'SLICER/UPDATE_SLICE_JOB',
  COMPLETE_SLICE_JOB: 'SLICER/COMPLETE_SLICE_JOB',
  GET_SLICE_PREVIEW_DATA: 'SLICER/GET_SLICE_PREVIEW_DATA',

  LOAD_PROJECT_STATS_REQUEST: 'SLICER/LOAD_PROJECT_STATS_REQUEST',
  LOAD_PROJECT_STATS_SUCCESS: 'SLICER/LOAD_PROJECT_STATS_SUCCESS',
  LOAD_PROJECT_STATS_FAILURE: 'SLICER/LOAD_PROJECT_STATS_FAILURE',

  LOAD_TOOLPATH_PTP_REQUEST: 'SLICER/LOAD_TOOLPATH_PTP_REQUEST',
  LOAD_TOOLPATH_PTP_PROGRESS: 'SLICER/LOAD_TOOLPATH_PTP_PROGRESS',
  LOAD_TOOLPATH_PTP_SUCCESS: 'SLICER/LOAD_TOOLPATH_PTP_SUCCESS',
  LOAD_TOOLPATH_PTP_FAILURE: 'SLICER/LOAD_TOOLPATH_PTP_FAILURE',
  LOAD_TOOLPATH_PTP_CANCEL: 'SLICER/LOAD_TOOLPATH_PTP_CANCEL',
  UNLOAD_TOOLPATH_PTP: 'SLICER/UNLOAD_TOOLPATH_PTP',

  CREATE_TOOLPATH_MESHES_REQUEST: 'SLICER/CREATE_TOOLPATH_MESHES_REQUEST',
  CREATE_TOOLPATH_MESHES_PROGRESS: 'SLICER/CREATE_TOOLPATH_MESHES_PROGRESS',
  CREATE_TOOLPATH_MESHES_SUCCESS: 'SLICER/CREATE_TOOLPATH_MESHES_SUCCESS',
  CREATE_TOOLPATH_MESHES_FAILURE: 'SLICER/CREATE_TOOLPATH_MESHES_FAILURE',
  CREATE_TOOLPATH_MESHES_CANCEL: 'SLICER/CREATE_TOOLPATH_MESHES_CANCEL',

  SET_TOOLPATH_VIEW_TYPE: 'SLICER/SET_TOOLPATH_VIEW_TYPE',
  SET_TOOLPATH_SHOW_TRAVEL: 'SLICER/SET_TOOLPATH_SHOW_TRAVEL',
  SET_TOOLPATH_SHOW_RETRACTS: 'SLICER/SET_TOOLPATH_SHOW_RETRACTS',
  SET_TOOLPATH_SHOW_RESTARTS: 'SLICER/SET_TOOLPATH_SHOW_RESTARTS',
  SET_TOOLPATH_LAYER_RANGE: 'SLICER/SET_TOOLPATH_LAYER_RANGE',

  DOWNLOAD_REQUEST: 'SLICER/DOWNLOAD_REQUEST',
  DOWNLOAD_SUCCESS: 'SLICER/DOWNLOAD_SUCCESS',
  DOWNLOAD_FAILURE: 'SLICER/DOWNLOAD_FAILURE',

  UPDATE_TOWER: 'SLICER/UPDATE_TOWER',
  UPDATE_TOWER_FROM_SERVER: 'SLICER/UPDATE_TOWER_FROM_SERVER',
  TOGGLE_TOWER_VISIBILITY: 'SLICER/TOGGLE_TOWER_VISIBILITY',
  REPOSITION_TOWER: 'SLICER/REPOSITION_TOWER',

  UPDATE_MODEL_TRANSFORMS_REQUEST: 'SLICER/UPDATE_MODEL_TRANSFORMS_REQUEST',
  UPDATE_MODEL_TRANSFORMS_SUCCESS: 'SLICER/UPDATE_MODEL_TRANSFORMS_SUCCESS',
  UPDATE_MODEL_TRANSFORMS_FAILURE: 'SLICER/UPDATE_MODEL_TRANSFORMS_FAILURE',

  UPDATE_STAMP_TRANSFORMS_REQUEST: 'SLICER/UPDATE_STAMP_TRANSFORMS_REQUEST',
  UPDATE_STAMP_TRANSFORMS_SUCCESS: 'SLICER/UPDATE_STAMP_TRANSFORMS_SUCCESS',
  UPDATE_STAMP_TRANSFORMS_FAILURE: 'SLICER/UPDATE_STAMP_TRANSFORMS_FAILURE',

  UPDATE_MODEL_NAME_REQUEST: 'SLICER/UPDATE_MODEL_NAME_REQUEST',
  UPDATE_MODEL_NAME_SUCCESS: 'SLICER/UPDATE_MODEL_NAME_SUCCESS',
  UPDATE_MODEL_NAME_FAILURE: 'SLICER/UPDATE_MODEL_NAME_FAILURE',

  UPDATE_MODEL_GROUP_NAME_REQUEST: 'SLICER/UPDATE_MODEL_GROUP_NAME_REQUEST',
  UPDATE_MODEL_GROUP_NAME_SUCCESS: 'SLICER/UPDATE_MODEL_GROUP_NAME_SUCCESS',
  UPDATE_MODEL_GROUP_NAME_FAILURE: 'SLICER/UPDATE_MODEL_GROUP_NAME_FAILURE',

  GROUP_MODELS_REQUEST: 'SLICER/GROUP_MODELS_REQUEST',
  GROUP_MODELS_SUCCESS: 'SLICER/GROUP_MODELS_SUCCESS',
  GROUP_MODELS_FAILURE: 'SLICER/GROUP_MODELS_FAILURE',

  UNGROUP_MODELS_REQUEST: 'SLICER/UNGROUP_MODELS_REQUEST',
  UNGROUP_MODELS_SUCCESS: 'SLICER/UNGROUP_MODELS_SUCCESS',
  UNGROUP_MODELS_FAILURE: 'SLICER/UNGROUP_MODELS_FAILURE',

  ALIGN_AND_GROUP_MODELS_REQUEST: 'SLICER/ALIGN_AND_GROUP_MODELS_REQUEST',
  ALIGN_AND_GROUP_MODELS_SUCCESS: 'SLICER/ALIGN_AND_GROUP_MODELS_SUCCESS',
  ALIGN_AND_GROUP_MODELS_FAILURE: 'SLICER/ALIGN_AND_GROUP_MODELS_FAILURE',

  TOGGLE_MODEL_ACTIVE_STATE: 'SLICER/TOGGLE_MODEL_ACTIVE_STATE',
  ACTIVATE_MODELS: 'SLICER/ACTIVATE_MODELS',
  DEACTIVATE_ALL_MODELS: 'SLICER/DEACTIVATE_ALL_MODELS',

  SET_MODEL_VISIBILITY_REQUEST: 'SLICER/SET_MODEL_VISIBILITY_REQUEST',
  SET_MODEL_VISIBILITY_SUCCESS: 'SLICER/SET_MODEL_VISIBILITY_SUCCESS',
  SET_MODEL_VISIBILITY_FAILURE: 'SLICER/SET_MODEL_VISIBILITY_FAILURE',

  SHOW_ALL_MODELS: 'SLICER/SHOW_ALL_MODELS',
  HIDE_ALL_MODELS: 'SLICER/HIDE_ALL_MODELS',

  DUPLICATE_MODEL_REQUEST: 'SLICER/DUPLICATE_MODEL_REQUEST',
  DUPLICATE_MODEL_SUCCESS: 'SLICER/DUPLICATE_MODEL_SUCCESS',
  DUPLICATE_MODEL_FAILURE: 'SLICER/DUPLICATE_MODEL_FAILURE',

  TOGGLE_TOWER_ACTIVE_STATE: 'SLICER/TOGGLE_TOWER_ACTIVE_STATE',
  ACTIVATE_TOWER: 'SLICER/ACTIVATE_TOWER',
  DEACTIVATE_TOWER: 'SLICER/DEACTIVATE_TOWER',

  UPDATE_TOWER_POSITION_OR_SIZE_REQUEST:
    'SLICER/UPDATE_TOWER_POSITION_OR_SIZE_REQUEST',
  UPDATE_TOWER_POSITION_OR_SIZE_SUCCESS:
    'SLICER/UPDATE_TOWER_POSITION_OR_SIZE_SUCCESS',
  UPDATE_TOWER_POSITION_OR_SIZE_FAILURE:
    'SLICER/UPDATE_TOWER_POSITION_OR_SIZE_FAILURE',

  APPLY_MATERIAL_REQUEST: 'SLICER/APPLY_MATERIAL_REQUEST',
  APPLY_MATERIAL_SUCCESS: 'SLICER/APPLY_MATERIAL_SUCCESS',
  APPLY_MATERIAL_FAILURE: 'SLICER/APPLY_MATERIAL_FAILURE',

  UPDATE_PROJECT_STYLE_REQUEST: 'SLICER/UPDATE_PROJECT_STYLE_REQUEST',
  UPDATE_PROJECT_STYLE_SUCCESS: 'SLICER/UPDATE_PROJECT_STYLE_SUCCESS',
  UPDATE_PROJECT_STYLE_FAILURE: 'SLICER/UPDATE_PROJECT_STYLE_FAILURE',

  UPDATE_PROJECT_THUMBNAIL_REQUEST: 'SLICER/UPDATE_PROJECT_THUMBNAIL_REQUEST',
  UPDATE_PROJECT_THUMBNAIL_SUCCESS: 'SLICER/UPDATE_PROJECT_THUMBNAIL_SUCCESS',
  UPDATE_PROJECT_THUMBNAIL_FAILURE: 'SLICER/UPDATE_PROJECT_THUMBNAIL_FAILURE',

  GET_PROJECT_THUMBNAIL_REQUEST: 'SLICER/GET_PROJECT_THUMBNAIL_REQUEST',
  GET_PROJECT_THUMBNAIL_SUCCESS: 'SLICER/GET_PROJECT_THUMBNAIL_SUCCESS',
  GET_PROJECT_THUMBNAIL_FAILURE: 'SLICER/GET_PROJECT_THUMBNAIL_FAILURE',

  DELETE_PROJECT_THUMBNAIL_REQUEST: 'SLICER/DELETE_PROJECT_THUMBNAIL_REQUEST',
  DELETE_PROJECT_THUMBNAIL_SUCCESS: 'SLICER/DELETE_PROJECT_THUMBNAIL_SUCCESS',
  DELETE_PROJECT_THUMBNAIL_FAILURE: 'SLICER/DELETE_PROJECT_THUMBNAIL_FAILURE',

  DUPLICATE_PROJECT_REQUEST: 'SLICER/DUPLICATE_PROJECT_REQUEST',
  DUPLICATE_PROJECT_SUCCESS: 'SLICER/DUPLICATE_PROJECT_SUCCESS',
  DUPLICATE_PROJECT_FAILURE: 'SLICER/DUPLICATE_PROJECT_FAILURE',

  CREATE_FOLDER_REQUEST: 'SLICER/CREATE_FOLDER_REQUEST',
  CREATE_FOLDER_SUCCESS: 'SLICER/CREATE_FOLDER_SUCCESS',
  CREATE_FOLDER_FAILURE: 'SLICER/CREATE_FOLDER_FAILURE',

  UPDATE_PROJECTS_PARENT_REQUEST: 'SLICER/UPDATE_PROJECTS_PARENT_REQUEST',
  UPDATE_PROJECTS_PARENT_SUCCESS: 'SLICER/UPDATE_PROJECTS_PARENT_SUCCESS',
  UPDATE_PROJECTS_PARENT_FAILURE: 'SLICER/UPDATE_PROJECTS_PARENT_FAILURE',

  DISPATCH_PRINT_TO_DEVICE_REQUEST: 'SLICER/DISPATCH_PRINT_TO_DEVICE_REQUEST',
  DISPATCH_PRINT_TO_DEVICE_SUCCESS: 'SLICER/DISPATCH_PRINT_TO_DEVICE_SUCCESS',
  DISPATCH_PRINT_TO_DEVICE_FAILURE: 'SLICER/DISPATCH_PRINT_TO_DEVICE_FAILURE',

  // Face Painter
  // REQUEST-SUCCESS pattern for event flow between saga and component
  // JOB STARTED-FINISHED pattern for event flow between main and worker thread

  REGISTER_BRUSH: 'PAINTER/REGISTER_BRUSH',
  MARK_MODEL_TO_COLOR: 'PAINTER/MARK_MODEL_TO_COLOR',
  TOGGLE_PAINTING: 'PAINTER/TOGGLE_PAINTING',
  SELECT_BRUSH_COLOR: 'PAINTER/SELECT_BRUSH_COLOR',
  SELECT_BRUSH_TYPE: 'PAINTER/SELECT_BRUSH_TYPE',
  SELECT_BRUSH_SIZE: 'PAINTER/SELECT_BRUSH_SIZE',
  CANCEL_WORKER: 'PAINTER/CANCEL_WORKER',
  TOGGLE_SEGMENT_BOUNDARIES: 'PAINTER/TOGGLE_SEGMENT_BOUNDARIES',
  COLOR_GIVEN_FACES: 'PAINTER/COLOR_GIVEN_FACES',
  MARK_INTERSECTED_SEGMENT: 'PAINTER/MARK_INTERSECTED_SEGMENT',
  UNMARK_INTERSECTED_SEGMENT: 'PAINTER/UNMARK_INTERSECTED_SEGMENT',
  FACES_TO_COLOR_FACET_MODE: 'PAINTER/FACES_TO_COLOR_FACET_MODE',
  FACES_TO_COLOR_SPHERE_MODE: 'PAINTER/FACES_TO_COLOR_SPHERE_MODE',
  FACES_TO_COLOR_FILL_REGION_MODE: 'PAINTER/FACES_TO_COLOR_FILL_REGION_MODE',
  FACES_TO_COLOR_FILL_ALL_MODE: 'PAINTER/FACES_TO_COLOR_FILL_ALL_MODE',

  BUILD_FACES_REQUEST: 'PAINTER/BUILD_FACES_REQUEST',
  BUILD_FACES_SUCCESS: 'PAINTER/BUILD_FACES_SUCCESS',
  BUILD_FACES_JOB_STARTED: 'PAINTER/BUILD_FACES_JOB_STARTED',
  BUILD_FACES_JOB_FINISHED: 'PAINTER/BUILD_FACES_JOB_FINISHED',

  RESET_REGIONS_REQUEST: 'PAINTER/RESET_REGIONS_REQUEST',
  RESET_REGIONS_SUCCESS: 'PAINTER/RESET_REGIONS_SUCCESS',
  RESET_REGIONS_JOB_STARTED: 'PAINTER/RESET_REGIONS_JOB_STARTED',
  RESET_REGIONS_JOB_FINISHED: 'PAINTER/RESET_REGIONS_JOB_FINISHED',

  AUTO_SEGMENT_REQUEST: 'PAINTER/AUTO_SEGMENT_REQUEST',
  AUTO_SEGMENT_SUCCESS: 'PAINTER/AUTO_SEGMENT_SUCCESS',
  AUTO_SEGMENT_JOB_STARTED: 'PAINTER/AUTO_SEGMENT_JOB_STARTED',
  AUTO_SEGMENT_JOB_FINISHED: 'PAINTER/AUTO_SEGMENT_JOB_FINISHED',

  RESTORE_COLORS_WITH_RLE: 'PAINTER/RESTORE_COLORS_WITH_RLE',
  COMPOSE_COLORS_RLE_REQUEST: 'PAINTER/COMPOSE_COLORS_RLE_REQUEST',
  COMPOSE_COLORS_RLE_SUCCESS: 'PAINTER/COMPOSE_COLORS_RLE_SUCCESS',
  COMPOSE_COLORS_RLE_JOB_STARTED: 'PAINTER/COMPOSE_COLORS_RLE_JOB_STARTED',
  COMPOSE_COLORS_RLE_JOB_FINISHED: 'PAINTER/COMPOSE_COLORS_RLE_JOB_FINISHED',

  SAVE_MODEL_PAINT_DATA_REQUEST: 'PAINTER/SAVE_MODEL_PAINT_DATA_REQUEST',
  SAVE_MODEL_PAINT_DATA_SUCCESS: 'PAINTER/SAVE_MODEL_PAINT_DATA_SUCCESS',
  SAVE_MODEL_PAINT_DATA_FAILURE: 'PAINTER/SAVE_MODEL_PAINT_DATA_FAILURE',

  DELETE_MODEL_PAINT_DATA_REQUEST: 'PAINTER/DELETE_MODEL_PAINT_DATA_REQUEST',
  DELETE_MODEL_PAINT_DATA_SUCCESS: 'PAINTER/DELETE_MODEL_PAINT_DATA_SUCCESS',
  DELETE_MODEL_PAINT_DATA_FAILURE: 'PAINTER/DELETE_MODEL_PAINT_DATA_FAILURE',

  SEGMENT_BOUNDARIES_NAME: 'PAINTER/SEGMENT_BOUNDARIES_NAME',

  // Texture painting
  STORE_CURRENT_COLORS: 'PAINTER/STORE_CURRENT_COLORS',
  STORE_CURRENT_STROKE_RADIUS: 'PAINTER/STORE_CURRENT_STROKE_RADIUS',
  STORE_DRAWING_UVS: 'PAINTER/STORE_DRAWING_UVS',
  STORE_DRAWING_CANVAS_DIMENSIONS: 'PAINTER/STORE_DRAWING_CANVAS_DIMENSIONS',
  STORE_CURRENT_ATLAS_BUFFERS: 'PAINTER/STORE_CURRENT_ATLAS_BUFFERS',
  STORE_CAMERA_DIRECTION: 'PAINTER/STORE_CAMERA_DIRECTION',

  GENERATE_ATLAS_REQUEST: 'PAINTER/GENERATE_ATLAS_REQUEST',
  GENERATE_ATLAS_SUCCESS: 'PAINTER/GENERATE_ATLAS_SUCCESS',
  GENERATE_ATLAS_JOB_STARTED: 'PAINTER/GENERATE_ATLAS_JOB_STARTED',
  GENERATE_ATLAS_JOB_FINISHED: 'PAINTER/GENERATE_ATLAS_JOB_FINISHED',

  PARSE_TEXTURE_ATLAS: 'PAINTER/PARSE_TEXTURE_ATLAS',
  PARSE_ATLAS_JOB_STARTED: 'PAINTER/PARSE_ATLAS_JOB_STARTED',
  PARSE_ATLAS_JOB_FINISHED: 'PAINTER/PARSE_ATLAS_JOB_FINISHED',

  // Sharing
  CREATE_SHARED_PROJECT_REQUEST: 'SLICER/CREATE_SHARED_PROJECT_REQUEST',
  CREATE_SHARED_PROJECT_SUCCESS: 'SLICER/CREATE_SHARED_PROJECT_SUCCESS',
  CREATE_SHARED_PROJECT_FAILURE: 'SLICER/CREATE_SHARED_PROJECT_FAILURE',

  DELETE_SHARED_PROJECT_REQUEST: 'SLICER/DELETE_SHARED_PROJECT_REQUEST',
  DELETE_SHARED_PROJECT_SUCCESS: 'SLICER/DELETE_SHARED_PROJECT_SUCCESS',
  DELETE_SHARED_PROJECT_FAILURE: 'SLICER/DELETE_SHARED_PROJECT_FAILURE',

  UPDATE_SHARED_PROJECT_REQUEST: 'SLICER/UPDATE_SHARED_PROJECT_REQUEST',
  UPDATE_SHARED_PROJECT_SUCCESS: 'SLICER/UPDATE_SHARED_PROJECT_SUCCESS',
  UPDATE_SHARED_PROJECT_FAILURE: 'SLICER/UPDATE_SHARED_PROJECT_FAILURE',

  GET_SHARED_PROJECT_REQUEST: 'SLICER/GET_SHARED_PROJECT_REQUEST',
  GET_SHARED_PROJECT_SUCCESS: 'SLICER/GET_SHARED_PROJECT_SUCCESS',
  GET_SHARED_PROJECT_FAILURE: 'SLICER/GET_SHARED_PROJECT_FAILURE',

  IMPORT_SHARED_PROJECT_REQUEST: 'SLICER/IMPORT_SHARED_PROJECT_REQUEST',
  IMPORT_SHARED_PROJECT_SUCCESS: 'SLICER/IMPORT_SHARED_PROJECT_SUCCESS',
  IMPORT_SHARED_PROJECT_FAILURE: 'SLICER/IMPORT_SHARED_PROJECT_FAILURE',

  GET_SHARED_PROJECT_THUMBNAIL_REQUEST:
    'SLICER/GET_SHARED_PROJECT_THUMBNAIL_REQUEST',
  GET_SHARED_PROJECT_THUMBNAIL_SUCCESS:
    'SLICER/GET_SHARED_PROJECT_THUMBNAIL_SUCCESS',
  GET_SHARED_PROJECT_THUMBNAIL_FAILURE:
    'SLICER/GET_SHARED_PROJECT_THUMBNAIL_FAILURE',

  GET_SHARED_PROJECT_MODELS_REQUEST: 'SLICER/GET_SHARED_PROJECT_MODELS_REQUEST',
  GET_SHARED_PROJECT_MODELS_SUCCESS: 'SLICER/GET_SHARED_PROJECT_MODELS_SUCCESS',
  GET_SHARED_PROJECT_MODELS_FAILURE: 'SLICER/GET_SHARED_PROJECT_MODELS_FAILURE',

  // Supports
  SAVE_MODEL_CUSTOM_SUPPORTS_REQUEST:
    'SLICER/SAVE_MODEL_CUSTOM_SUPPORTS_REQEUST',
  SAVE_MODEL_CUSTOM_SUPPORTS_SUCCESS:
    'SLICER/SAVE_MODEL_CUSTOM_SUPPORTS_SUCCESS',
  SAVE_MODEL_CUSTOM_SUPPORTS_FAILURE:
    'SLICER/SAVE_MODEL_CUSTOM_SUPPORTS_FAILURE',

  // Integrations
  GET_POLAR_PRINTERS_REQUEST: 'SLICER/GET_POLAR_PRINTERS_REQUEST',
  GET_POLAR_PRINTERS_SUCCESS: 'SLICER/GET_POLAR_PRINTERS_SUCCESS',
  GET_POLAR_PRINTERS_FAILURE: 'SLICER/GET_POLAR_PRINTERS_FAILURE',

  DISPATCH_PRINT_TO_POLAR_REQUEST: 'SLICER/DISPATCH_PRINT_TO_POLAR_REQUEST',
  DISPATCH_PRINT_TO_POLAR_SUCCESS: 'SLICER/DISPATCH_PRINT_TO_POLAR_SUCCESS',
  DISPATCH_PRINT_TO_POLAR_FAILURE: 'SLICER/DISPATCH_PRINT_TO_POLAR_FAILURE',
};

export default types;

import { setCanvasDimensions } from './raycast';
import { setRenderFlag } from './animationFrame';

let currentRenderer = null;
let currentCamera = null;

const onResize = () => {
  currentRenderer.setSize(window.innerWidth, window.innerHeight);
  const { top, left, width, height } =
    currentRenderer.domElement.getBoundingClientRect();
  setCanvasDimensions(top, left, width, height);
  if (currentCamera.type === 'PerspectiveCamera') {
    // perspective
    currentCamera.aspect = window.innerWidth / window.innerHeight;
  } else {
    // orthographic
    currentCamera.left = -window.innerWidth / 2;
    currentCamera.right = window.innerWidth / 2;
    currentCamera.top = window.innerHeight / 2;
    currentCamera.bottom = -window.innerHeight / 2;
  }
  currentCamera.updateProjectionMatrix();
  setRenderFlag();
};

export const enableResize = (renderer, camera) => {
  currentRenderer = renderer;
  currentCamera = camera;
  window.addEventListener('resize', onResize);
};

export const updateCamera = (camera) => {
  currentCamera = camera;
};

export const disableResize = () => {
  currentRenderer = null;
  currentCamera = null;
  window.removeEventListener('resize', onResize);
};

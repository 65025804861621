import types from './types';

const actions = {
  resetPrinterStore: () => ({
    type: types.RESET_PRINTER_STORE,
    payload: {},
  }),
  parseProfileRequest: (file) => ({
    type: types.PARSE_PROFILE_REQUEST,
    payload: { file },
  }),
  parseProfileSuccess: (parsedProfile) => ({
    type: types.PARSE_PROFILE_SUCCESS,
    payload: { parsedProfile },
  }),
  parseProfileFailure: (error) => ({
    type: types.PARSE_PROFILE_FAILURE,
    payload: { error },
  }),
  resetParsedProfile: () => ({
    type: types.RESET_PARSED_PROFILE,
    payload: {},
  }),
  createPrinterRequest: (printerData, styles = []) => ({
    type: types.CREATE_PRINTER_REQUEST,
    payload: { printerData, styles },
  }),
  createPrinterSuccess: (printer) => ({
    type: types.CREATE_PRINTER_SUCCESS,
    payload: { printer },
  }),
  createPrinterFailure: (error) => ({
    type: types.CREATE_PRINTER_FAILURE,
    payload: { error },
  }),

  duplicatePrinterRequest: (printerId) => ({
    type: types.DUPLICATE_PRINTER_REQUEST,
    payload: { printerId },
  }),
  duplicatePrinterSuccess: (printer) => ({
    type: types.DUPLICATE_PRINTER_SUCCESS,
    payload: { printer },
  }),
  duplicatePrinterFailure: (error) => ({
    type: types.DUPLICATE_PRINTER_FAILURE,
    payload: { error },
  }),

  updatePrinterRequest: (printerId, printerData) => ({
    type: types.UPDATE_PRINTER_REQUEST,
    payload: { printerId, printerData },
  }),
  updatePrinterSuccess: (printer) => ({
    type: types.UPDATE_PRINTER_SUCCESS,
    payload: { printer },
  }),
  updatePrinterFailure: (error) => ({
    type: types.UPDATE_PRINTER_FAILURE,
    payload: { error },
  }),

  deletePrintersRequest: (printerIds) => ({
    type: types.DELETE_PRINTERS_REQUEST,
    payload: { printerIds },
  }),
  deletePrintersSuccess: (printerIds) => ({
    type: types.DELETE_PRINTERS_SUCCESS,
    payload: { printerIds },
  }),
  deletePrintersFailure: (error) => ({
    type: types.DELETE_PRINTERS_FAILURE,
    payload: { error },
  }),

  updateCalibrationSettingsRequest: (
    printerId,
    loadingOffset,
    printValue,
    calibrationLength
  ) => ({
    type: types.UPDATE_CALIBRATION_SETTINGS_REQUEST,
    payload: { printerId, loadingOffset, printValue, calibrationLength },
  }),
  updateCalibrationSettingsSuccess: (printer) => ({
    type: types.UPDATE_CALIBRATION_SETTINGS_SUCCESS,
    payload: { printer },
  }),
  updateCalibrationSettingsFailure: (error) => ({
    type: types.UPDATE_CALIBRATION_SETTINGS_FAILURE,
    payload: { error },
  }),

  createStyleProfilesRequest: (printerId, styleProfiles) => ({
    type: types.CREATE_STYLE_PROFILES_REQUEST,
    payload: { printerId, styleProfiles },
  }),
  createStyleProfilesSuccess: (printerId, styleProfiles) => ({
    type: types.CREATE_STYLE_PROFILES_SUCCESS,
    payload: { printerId, styleProfiles },
  }),
  createStyleProfilesFailure: (error) => ({
    type: types.CREATE_STYLE_PROFILES_FAILURE,
    payload: { error },
  }),

  updateStyleProfileRequest: (printerId, styleId, styles) => ({
    type: types.UPDATE_STYLE_REQUEST,
    payload: { printerId, styleId, styles },
  }),
  updateStyleProfileSuccess: (printer) => ({
    type: types.UPDATE_STYLE_SUCCESS,
    payload: { printer },
  }),
  updateStyleProfileFailure: (error) => ({
    type: types.UPDATE_STYLE_FAILURE,
    payload: { error },
  }),

  deleteStylesRequest: (printerId, styleIds) => ({
    type: types.DELETE_STYLES_REQUEST,
    payload: { printerId, styleIds },
  }),
  deleteStylesSuccess: (printerId, styleIds) => ({
    type: types.DELETE_STYLES_SUCCESS,
    payload: { printerId, styleIds },
  }),
  deleteStylesFailure: (error) => ({
    type: types.DELETE_STYLES_FAILURE,
    payload: { error },
  }),

  getPrintersRequest: () => ({
    type: types.GET_PRINTERS_REQUEST,
    payload: {},
  }),
  getPrintersSuccess: (printers) => ({
    type: types.GET_PRINTERS_SUCCESS,
    payload: { printers },
  }),
  getPrintersFailure: (error) => ({
    type: types.GET_PRINTERS_FAILURE,
    payload: { error },
  }),

  getPrinterPresetsRequest: () => ({
    type: types.GET_PRINTER_PRESETS_REQUEST,
    payload: {},
  }),
  getPrinterPresetsSuccess: (presets) => ({
    type: types.GET_PRINTER_PRESETS_SUCCESS,
    payload: { presets },
  }),
  getPrinterPresetsFailure: (error) => ({
    type: types.GET_PRINTER_PRESETS_FAILURE,
    payload: { error },
  }),
  // Sharing
  createSharedPrinterRequest: (printerId, styleIds) => ({
    type: types.CREATE_SHARED_PRINTER_REQUEST,
    payload: { printerId, styleIds },
  }),
  createSharedPrinterSuccess: (printerId, shareData) => ({
    type: types.CREATE_SHARED_PRINTER_SUCCESS,
    payload: { printerId, shareData },
  }),
  createSharedPrinterFailure: (error) => ({
    type: types.CREATE_SHARED_PRINTER_FAILURE,
    payload: { error },
  }),
  deleteSharedPrinterRequest: (printerId) => ({
    type: types.DELETE_SHARED_PRINTER_REQUEST,
    payload: { printerId },
  }),
  deleteSharedPrinterSuccess: (printerId, timestamp) => ({
    type: types.DELETE_SHARED_PRINTER_SUCCESS,
    payload: { printerId, timestamp },
  }),
  deleteSharedPrinterFailure: (error) => ({
    type: types.DELETE_SHARED_PRINTER_FAILURE,
    payload: { error },
  }),
  updateSharedPrinterRequest: (printerId, styleIds) => ({
    type: types.UPDATE_SHARED_PRINTER_REQUEST,
    payload: { printerId, styleIds },
  }),
  updateSharedPrinterSuccess: (printerId, shareData) => ({
    type: types.UPDATE_SHARED_PRINTER_SUCCESS,
    payload: { printerId, shareData },
  }),
  updateSharedPrinterFailure: (error) => ({
    type: types.UPDATE_SHARED_PRINTER_FAILURE,
    payload: { error },
  }),
  getSharedPrinterRequest: (shareId) => ({
    type: types.GET_SHARED_PRINTER_REQUEST,
    payload: { shareId },
  }),
  getSharedPrinterSuccess: (sharedPrinter) => ({
    type: types.GET_SHARED_PRINTER_SUCCESS,
    payload: { sharedPrinter },
  }),
  getSharedPrinterFailure: (error) => ({
    type: types.GET_SHARED_PRINTER_FAILURE,
    payload: { error },
  }),
  importSharedPrinterRequest: (
    importPrinter,
    stylesToImport,
    printerId = '',
    newPrinterName = ''
  ) => ({
    type: types.IMPORT_SHARED_PRINTER_REQUEST,
    payload: { importPrinter, stylesToImport, printerId, newPrinterName },
  }),
  importSharedPrinterSuccess: (printer) => ({
    type: types.IMPORT_SHARED_PRINTER_SUCCESS,
    payload: { printer },
  }),
  importSharedPrinterFailure: (error) => ({
    type: types.IMPORT_SHARED_PRINTER_FAILURE,
    payload: { error },
  }),
  queryPrinterTagsRequest: (queryString) => ({
    type: types.QUERY_PRINTER_TAGS_REQUEST,
    payload: { queryString },
  }),
  queryPrinterTagsSuccess: (tags) => ({
    type: types.QUERY_PRINTER_TAGS_SUCCESS,
    payload: { tags },
  }),
  queryPrinterTagsFailure: (error) => ({
    type: types.QUERY_PRINTER_TAGS_FAILURE,
    payload: { error },
  }),
  createPrinterTagRequest: (tagName) => ({
    type: types.CREATE_PRINTER_TAG_REQUEST,
    payload: { tagName },
  }),
  createPrinterTagSuccess: (tag) => ({
    type: types.CREATE_PRINTER_TAG_SUCCESS,
    payload: { tag },
  }),
  createPrinterTagFailure: (error) => ({
    type: types.CREATE_PRINTER_TAG_FAILURE,
    payload: { error },
  }),
  getPrinterTagRequest: (tagId) => ({
    type: types.GET_PRINTER_TAG_REQUEST,
    payload: { tagId },
  }),
  getPrinterTagSuccess: (printerTag) => ({
    type: types.GET_PRINTER_TAG_SUCCESS,
    payload: { printerTag },
  }),
  getPrinterTagFailure: (error) => ({
    type: types.GET_PRINTER_TAG_FAILURE,
    payload: { error },
  }),
  clearPrinterTag: () => ({
    type: types.CLEAR_PRINTER_TAG,
    payload: {},
  }),
};

export default actions;

import React, { useCallback } from 'react';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import {
  Breakline,
  StyledContainer,
  JobSection,
  SectionPlaceholder,
  SectionTitleContainer,
  JobList,
  PrintJob,
  SliceJob,
  SliceJobInfo,
  LabelsContainer,
  SliceCancelButtonWrapper,
} from './jobMonitor.styles';

import Button from '../../../button/button.jsx';
import Progress from '../../../progress/progress.jsx';
import DeviceStatus from '../../../deviceStatus/deviceStatus.jsx';

import Routes from '../../../../router/routes';
import {
  Body1,
  Body1OneLine,
  CaptionOneLine,
  Subtitle1,
} from '../../../typography/typography';

const JobMonitor = ({
  sliceJobs = {},
  devicesWithPrintJobs = {},
  onCancelSlice = () => {},
  onPausePrint = () => {},
  onResumePrint = () => {},
  dispatchSliceJobPending = false,
  cancelSliceJobPending = false,
}) => {
  const history = useHistory();

  const theme = useTheme();

  const renderSliceJobs = useCallback(
    () => (
      <JobSection>
        <SectionTitleContainer>
          <Subtitle1>Slicing</Subtitle1>
        </SectionTitleContainer>
        <JobList>
          {_.isEmpty(sliceJobs) ? (
            <SectionPlaceholder>
              <Body1 grey noSpacing>
                No project is being sliced
              </Body1>
            </SectionPlaceholder>
          ) : (
            _.map(sliceJobs, (job, jobId) => (
              <>
                <SliceJob
                  key={jobId}
                  onClick={() => history.push(Routes.toSlicer(jobId))}>
                  <LabelsContainer>
                    <SliceJobInfo>
                      <Body1OneLine noSpacing>{job.name}</Body1OneLine>
                      <Body1 noSpacing>
                        {job.progress === null ? null : `${job.progress}%`}
                      </Body1>
                    </SliceJobInfo>
                    {job.status ? (
                      <CaptionOneLine grey noSpacing>
                        {job.status}
                      </CaptionOneLine>
                    ) : null}
                  </LabelsContainer>
                  <SliceCancelButtonWrapper>
                    <Button
                      minWidth='0'
                      height='2rem'
                      disabled={
                        dispatchSliceJobPending || cancelSliceJobPending
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        onCancelSlice(jobId);
                      }}>
                      Cancel
                    </Button>
                  </SliceCancelButtonWrapper>
                </SliceJob>
                <Progress
                  determinate
                  progress={job.progress}
                  primaryColor={theme.colors.greenDefault}
                />
              </>
            ))
          )}
        </JobList>
      </JobSection>
    ),
    [
      history,
      sliceJobs,
      onCancelSlice,
      dispatchSliceJobPending,
      cancelSliceJobPending,
      theme,
    ]
  );

  const renderPrintJobs = useCallback(
    () => (
      <JobSection>
        <SectionTitleContainer>
          <Subtitle1>Printing</Subtitle1>
        </SectionTitleContainer>
        <JobList>
          {_.isEmpty(devicesWithPrintJobs) ? (
            <SectionPlaceholder>
              <Body1 grey noSpacing>
                No active prints found
              </Body1>
            </SectionPlaceholder>
          ) : (
            _.map(devicesWithPrintJobs, (deviceInfo, deviceId) => (
              <PrintJob key={deviceId}>
                <DeviceStatus
                  allowContainerClick
                  currentDeviceState={deviceInfo.currentDeviceState}
                  device={deviceInfo.device}
                  pendingRequests={deviceInfo.pendingRequests}
                  onPausePrint={() => onPausePrint(deviceInfo.device)}
                  onResumePrint={() => onResumePrint(deviceInfo.device)}
                />
              </PrintJob>
            ))
          )}
        </JobList>
      </JobSection>
    ),
    [devicesWithPrintJobs, onPausePrint, onResumePrint]
  );

  return (
    <StyledContainer onClick={(e) => e.stopPropagation()}>
      {renderSliceJobs()}
      <Breakline />
      {renderPrintJobs()}
    </StyledContainer>
  );
};

export default JobMonitor;

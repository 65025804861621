const types = {
  RESET_NOTIFICATIONS_STORE: 'NOTIFICATIONS/RESET_NOTIFICATIONS_STORE',

  GET_NOTIFICATIONS_REQUEST: 'NOTIFICATIONS/GET_NOTIFICATIONS_REQUEST',
  GET_NOTIFICATIONS_SUCCESS: 'NOTIFICATIONS/GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAILURE: 'NOTIFICATIONS/GET_NOTIFICATIONS_FAILURE',

  UPDATE_NOTIFICATION_REQUEST: 'NOTIFICATIONS/UPDATE_NOTIFICATION_REQUEST',
  UPDATE_NOTIFICATION_SUCCESS: 'NOTIFICATIONS/UPDATE_NOTIFICATION_SUCCESS',
  UPDATE_NOTIFICATION_FAILURE: 'NOTIFICATIONS/UPDATE_NOTIFICATION_FAILURE',

  DELETE_NOTIFICATION_REQUEST: 'NOTIFICATIONS/DELETE_NOTIFICATION_REQUEST',
  DELETE_NOTIFICATION_SUCCESS: 'NOTIFICATIONS/DELETE_NOTIFICATION_SUCCESS',
  DELETE_NOTIFICATION_FAILURE: 'NOTIFICATIONS/DELETE_NOTIFICATION_FAILURE',

  // received over MQTT
  RECEIVE_NOTIFICATION: 'NOTIFICATIONS/RECEIVE_NOTIFICATION',
};

export default types;

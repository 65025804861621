import styled from 'styled-components';
import {
  DefaultOption,
  DefaultSelect,
} from '../abstractDropdown/abstractDropdown.styles';

export const StyledOptionWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const StyledOption = styled(DefaultOption)`
  padding: 0.625rem 0 0.625rem 1rem;
`;

export const StyledSelect = styled(DefaultSelect)`
  justify-content: flex-start;
  div:first-child {
    margin: 0 0.625rem 0 0.125rem;
  }
  div:last-child {
    margin: 0;
    margin-left: auto;
  }
`;

import React from 'react';
import AbstractCard from '../abstractCard/abstractCard.jsx';
import { StyledContainer } from './materialCard.styles';

const MaterialCard = ({ children, ...props }) => {
  return (
    <AbstractCard
      {...props}
      StyledLayoutContainer={StyledContainer}
      padding='1.375rem'
      height='auto'>
      {children}
    </AbstractCard>
  );
};

export default MaterialCard;

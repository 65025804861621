const types = {
  INIT_CLIENT_REQUEST: 'IOT/WORKER/INIT_CLIENT_REQUEST',
  INIT_CLIENT_RESPONSE: 'IOT/WORKER/INIT_CLIENT_RESPONSE',

  UPDATE_CREDENTIALS_REQUEST: 'IOT/WORKER/UPDATE_CREDENTIALS_REQUEST',
  UPDATE_CREDENTIALS_RESPONSE: 'IOT/WORKER/UPDATE_CREDENTIALS_RESPONSE',

  SUBSCRIBE_REQUEST: 'IOT/WORKER/SUBSCRIBE_REQUEST',
  SUBSCRIBE_RESPONSE: 'IOT/WORKER/SUBSCRIBE_RESPONSE',

  UNSUBSCRIBE_REQUEST: 'IOT/WORKER/UNSUBSCRIBE_REQUEST',
  UNSUBSCRIBE_RESPONSE: 'IOT/WORKER/UNSUBSCRIBE_RESPONSE',

  HORIZON_REQUEST: 'IOT/WORKER/HORIZON_REQUEST',
  HORIZON_RESPONSE: 'IOT/WORKER/HORIZON_RESPONSE',

  STATUS: 'IOT/WORKER/STATUS',
  REINITIALIZE: 'IOT/WORKER/REINITIALIZE',
  REFRESH: 'IOT/WORKER/REFRESH',
  MESSAGE: 'IOT/WORKER/MESSAGE',
};

export default types;

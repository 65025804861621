import styled, { css } from 'styled-components';

import { Breakpoints } from '../../../../themes';
import { majorSectionStyles } from '../../deviceManager.styles';

export const Container = styled.div`
  ${majorSectionStyles};
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;
  width: 100%;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  justify-self: start;
`;

export const ControlBar = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 0.75rem;
  gap: 1rem;

  @media (max-width: ${Breakpoints.smallWidth}) {
    flex-direction: column;
  }
`;

export const SortControls = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
`;

export const ActionButtons = styled.div`
  display: flex;
`;

export const ActionButtonWrapper = styled.div`
  margin-left: 0.5rem;
`;

export const FilesList = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  flex-direction: column;
`;

export const CurrentPathContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  border-left: 4px solid transparent;
  transition: all 0.2s;
  padding: 0.5rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey2};
`;

export const PathInfo = styled.div`
  margin-left: auto;
  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.textSecondary};
  display: flex;
  align-items: center;
  height: 2rem;
  padding-left: 0.25rem;
  padding-right: 0.5rem;
  //transition: background-color 0.2s;
  border-radius: 4px;
  //outline: solid 1px red;

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
      :hover {
        background-color: ${props.theme.colors.elevatedBackgroundSecondary};
      }
    `}
`;

export const SubDirIcon = styled.div`
  transform: rotateZ(90deg);
  margin-right: 0.5rem;
`;

export const FileItem = styled.div`
  display: grid;
  grid-template-columns: min-content minmax(0, 1fr) min-content min-content;
  grid-gap: 0.5rem;
  align-items: center;
  box-sizing: border-box;
  padding: 0.5rem;
  border-left: 4px solid transparent;
  transition: all 0.2s;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey2};
  transition-property: border-left-color, background-color;
  cursor: pointer;

  :hover {
    background-color: ${(props) =>
      props.theme.colors.elevatedBackgroundSecondary};
  }

  :last-of-type {
    border-bottom: none;
  }

  ${(props) =>
    props.selected &&
    css`
      border-left-color: ${props.theme.colors.greenDefault};
      background-color: ${props.theme.colors.elevatedBackgroundSecondary};
    `};

  @media (max-width: ${Breakpoints.smallWidth}) {
    grid-template-columns: min-content minmax(0, 1fr) min-content;
  }

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    grid-template-columns: min-content minmax(0, 1fr);
  }
`;

const fileItemLabelStyles = css`
  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.textSecondary};
  margin: 0;
  user-select: none;
  cursor: inherit;
`;

export const FileName = styled.label`
  ${fileItemLabelStyles};
  flex: 1;
  color: ${(props) => props.theme.colors.textPrimary};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
`;

export const FileType = styled.label`
  ${fileItemLabelStyles};
  min-width: 3rem;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    display: none;
  }
`;

export const FileTimestamp = styled.label`
  ${fileItemLabelStyles};
  min-width: 10rem;
  text-align: right;

  @media (max-width: ${Breakpoints.smallWidth}) {
    display: none;
  }
`;

export const StartPrintButtonWrapper = styled.div`
  height: 100%;
  font-size: 0.9rem;
`;

export const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

export const PageNumber = styled.div`
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.textPrimary};
  margin: 0 0.5rem;
`;

export const PaginationButtonContainer = styled.div`
  margin: 0 0.5rem;
`;

export const StyledIconContainer = styled.div`
  margin-left: 3rem;
  display: flex;
  min-width: 0;
  @media (max-width: ${Breakpoints.mediumWidth}) {
    margin-left: 2rem;
  }
  @media (max-width: ${Breakpoints.smallWidth}) {
    margin-left: 0;
  }
`;

export const TextWrapper = styled.div`
  margin-bottom: 1.25rem;
`;

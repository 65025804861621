import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {
  OfflineContainer,
  OnlineContainer,
  TabButtons,
  TabContent,
} from './controls.styles';

import Placeholder from '../placeholder.jsx';
import PrinterControls from './printer/printer.jsx';
import TemperatureControls from './temperature/temperature.jsx';
import DeviceTerminal from './deviceTerminal/deviceTerminal.jsx';
import WebcamSection from './webcam/webcam.jsx';
import Logs from './logs/logs.jsx';

import { TabButton } from '../../../../shared';

import { DeviceUtils } from '../../../../utils';

const tabs = {
  controls: 'controls',
  temperature: 'temperature',
  terminal: 'terminal',
  logs: 'logs',
  webcam: 'webcam',
};

const tabLabels = {
  controls: 'Controls',
  temperature: 'Temperature',
  terminal: 'Terminal',
  logs: 'Logs',
  webcam: 'Camera',
};

class ControlsSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: tabs.controls,
    };

    this.onMoveExtruder = this.onMoveExtruder.bind(this);
    this.onHomeExtruder = this.onHomeExtruder.bind(this);
    this.onFeedFilament = this.onFeedFilament.bind(this);
    this.onControlFan = this.onControlFan.bind(this);
    this.onControlMotor = this.onControlMotor.bind(this);
    this.onSendCommand = this.onSendCommand.bind(this);
    this.onClearCommandHistory = this.onClearCommandHistory.bind(this);
    this.onSetTargetTemperature = this.onSetTargetTemperature.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.deviceId !== prevProps.match.params.deviceId) {
      this.setState({
        currentTab: tabs.controls,
      });
    }
  }

  changeTab(tabName) {
    if (tabName === this.state.currentTab) return;
    this.setState({
      currentTab: tabName,
    });
  }

  onMoveExtruder(movementData) {
    const { device } = this.props;
    this.props.moveExtruder(device, movementData);
  }

  onHomeExtruder(axes) {
    const { device } = this.props;
    this.props.homeExtruder(device, axes);
  }

  onFeedFilament(feedData) {
    const { device } = this.props;
    this.props.feedFilament(device, feedData);
  }

  onControlFan(controlData) {
    const { device } = this.props;
    this.props.controlFan(device, controlData);
  }

  onControlMotor(controlData) {
    const { device } = this.props;
    this.props.controlMotor(device, controlData);
  }

  onSendCommand(command) {
    const { device } = this.props;
    this.props.sendCommand(device, command);
  }

  onClearCommandHistory() {
    this.props.clearCommandHistory();
  }

  onSetTargetTemperature(temperatureData) {
    const { device } = this.props;
    this.props.setTargetTemperature(device, temperatureData);
  }

  renderTabButtons() {
    const { device, currentDeviceState } = this.props;
    const supportsTerminal = DeviceUtils.isTerminalSupported(device);
    const supportsLogs = DeviceUtils.isPingListSupported(
      currentDeviceState,
      device
    );
    const supportsWebcam = DeviceUtils.isWebcamSupported(currentDeviceState);
    return (
      <TabButtons>
        {this.renderTabButton(tabs.controls)}
        {this.renderTabButton(tabs.temperature)}
        {supportsTerminal && this.renderTabButton(tabs.terminal)}
        {supportsLogs && this.renderTabButton(tabs.logs)}
        {supportsWebcam && this.renderTabButton(tabs.webcam)}
      </TabButtons>
    );
  }

  renderTabButton(tabName) {
    return (
      <TabButton
        sidePadding='1rem'
        onClick={() => this.changeTab(tabName)}
        active={this.state.currentTab === tabName}>
        {tabLabels[tabName]}
      </TabButton>
    );
  }

  renderTabContent() {
    const { currentTab } = this.state;
    const { commandHistory, currentDeviceState, device } = this.props;
    const pendingRequests = this.props.pendingRequests[device.id];

    switch (currentTab) {
      case tabs.controls:
        return (
          <PrinterControls
            currentDeviceState={currentDeviceState}
            device={device}
            pendingRequests={pendingRequests}
            onMoveExtruder={this.onMoveExtruder}
            onHomeExtruder={this.onHomeExtruder}
            onFeedFilament={this.onFeedFilament}
            onControlFan={this.onControlFan}
            onControlMotor={this.onControlMotor}
          />
        );
      case tabs.temperature:
        return (
          <TemperatureControls
            currentDeviceState={currentDeviceState}
            device={device}
            pendingRequests={pendingRequests}
            onSetTargetTemperature={this.onSetTargetTemperature}
            includeChamberControls={device.type === 'element'}
          />
        );
      case tabs.terminal:
        return (
          <DeviceTerminal
            currentDeviceState={currentDeviceState}
            commandHistory={commandHistory}
            onSendCommand={this.onSendCommand}
            onClearCommandHistory={this.onClearCommandHistory}
          />
        );
      case tabs.webcam:
        return (
          <WebcamSection device={device} deviceState={currentDeviceState} />
        );
      case tabs.logs:
        return <Logs deviceState={currentDeviceState} />;
      default:
        return null;
    }
  }

  renderActiveControls() {
    return (
      <>
        {this.renderTabButtons()}
        <TabContent>{this.renderTabContent()}</TabContent>
      </>
    );
  }

  render() {
    const { currentDeviceState } = this.props;
    const isOnline = DeviceUtils.isOnline(currentDeviceState);
    const Container = isOnline ? OnlineContainer : OfflineContainer;

    let content;
    let placeholderText;
    if (isOnline) {
      content = this.renderActiveControls();
    } else {
      placeholderText = 'Offline';
    }

    return (
      <Container short={!isOnline}>
        {placeholderText ? (
          <Placeholder>{placeholderText}</Placeholder>
        ) : (
          content
        )}
      </Container>
    );
  }
}

export default withRouter(ControlsSection);

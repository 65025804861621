import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../canvas-api';
import { handleGenericError } from '../common';
import { actions } from '../../reducers/notifications/notifications';

export default function* deleteNotification(action) {
  try {
    const { notificationId } = action.payload;
    const response = yield call(API, {
      method: methods.DELETE,
      path: `notifications/${notificationId}`,
    });
    if (response === null) return;
    yield put(actions.deleteNotificationSuccess(notificationId));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import { useState, useRef } from 'react';

import useWindowEvent from './useWindowEvent';

/**
 * Functions which performs a click outside event listener
 * @param {*} initialState initialState of the panel
 * @param {*} onAfterClose some extra function call to do after closing the panel
 */
const usePanel = (initialState = false, onAfterClose = null) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(initialState);

  const handleClickOutside = (event) => {
    if (ref.current && ref.current.contains(event.target)) {
      return;
    }
    setIsOpen(false);
    if (onAfterClose) onAfterClose();
  };

  const handleHidePanel = (event) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
      if (onAfterClose) onAfterClose();
    }
  };

  useWindowEvent([
    ['click', handleClickOutside],
    ['keydown', handleHidePanel],
  ]);

  return [ref, isOpen, setIsOpen];
};

export default usePanel;

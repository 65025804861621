import React, { Component } from 'react';

import {
  HeaderCustomButtonWrapper,
  HeaderLabelWrapper,
  CollapseIconWrapper,
} from './abstractCollapsiblePanel.styles';

import Icon from '../icon/icon.jsx';
import { Icons } from '../../themes';

import { Subtitle2, Subtitle2OneLine } from '../typography/typography';

class AbstractCollapsiblePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: !!props.isCollapsed,
      uncollapsedHeight: 0,
    };
    this.containerRef = React.createRef();
    this.contentRef = React.createRef();
    this.onResize = this.onResize.bind(this);
  }

  static defaultProps = {
    icon: '',
    label: '',
    scroll: true,
    isCollapsed: true,
    triggerOpen: false,
    triggerCollapse: false,
    onOpen: (/* event */) => {},
    onClose: (/* event */) => {},
    highlights: null,
    footerContent: null,
    headerCustomButton: null,
    fieldChangesLength: 0,
  };

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.children !== this.props.children) {
      this.onResize();
    }
    if (this.props.triggerCollapse && !prevProps.triggerCollapse) {
      this.setCollapsedState(true);
    } else if (this.props.triggerOpen && !prevProps.triggerOpen) {
      this.setCollapsedState(false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  setCollapsedState(isCollapsed) {
    if (this.state.isCollapsed === isCollapsed) return;
    this.setState({ isCollapsed }, () => {
      if (this.containerRef.current) {
        const event = {
          target: this.containerRef.current,
        };
        if (isCollapsed) {
          this.props.onClose(event);
        } else {
          this.props.onOpen(event);
        }
      }
    });
  }

  onHeaderClick() {
    if (this.props.forceOpen) return;
    this.setCollapsedState(!this.state.isCollapsed);
  }

  onResize() {
    const uncollapsedHeight = this.contentRef.current.scrollHeight;
    this.setState({ uncollapsedHeight });
  }

  renderHeader(Header) {
    let { isCollapsed } = this.state;
    const {
      icon,
      label,
      highlights,
      forceOpen,
      headerCustomButton,
      fieldChangesLength,
    } = this.props;
    let chevronIcon = null;
    let headerButton = null;
    if (forceOpen) {
      isCollapsed = false;
      if (React.isValidElement(headerCustomButton)) {
        headerButton = (
          <HeaderCustomButtonWrapper>
            {headerCustomButton}
          </HeaderCustomButtonWrapper>
        );
      }
    } else {
      chevronIcon = (
        <CollapseIconWrapper
          defaultCollapsiblePanel={this.props.defaultCollapsiblePanel}>
          <Icon
            src={isCollapsed ? Icons.basic.chevronDown : Icons.basic.chevronUp}
          />
        </CollapseIconWrapper>
      );
    }

    return (
      <Header
        isCollapsed={isCollapsed}
        forceOpen={forceOpen}
        onClick={() => this.onHeaderClick()}>
        {icon && <Icon src={icon} />}
        <HeaderLabelWrapper
          icon={icon}
          defaultCollapsiblePanel={this.props.defaultCollapsiblePanel}>
          <Subtitle2OneLine>{label}</Subtitle2OneLine>
          {fieldChangesLength > 0 && (
            <Subtitle2 green>{`(${fieldChangesLength})`}</Subtitle2>
          )}
        </HeaderLabelWrapper>
        {highlights}
        {headerButton || chevronIcon}
      </Header>
    );
  }

  renderContent(Content) {
    let { isCollapsed } = this.state;
    const { forceOpen, scroll, children, footerContent } = this.props;
    if (forceOpen) {
      isCollapsed = false;
    }
    return (
      <Content
        isCollapsed={isCollapsed}
        footerContent={footerContent !== null}
        uncollapsedHeight={this.contentRef.current?.scrollHeight ?? 0}
        scroll={scroll}>
        <div ref={this.contentRef}>{children}</div>
      </Content>
    );
  }

  renderFooter(Footer) {
    if (!this.props.footerContent) return null;
    return (
      <Footer isCollapsed={this.state.isCollapsed}>
        {this.props.footerContent}
      </Footer>
    );
  }

  render() {
    const { Container, Header, Content, Footer } = this.props;
    return (
      <Container ref={this.containerRef}>
        {this.renderHeader(Header)}
        {this.renderContent(Content)}
        {this.renderFooter(Footer)}
      </Container>
    );
  }
}

export default AbstractCollapsiblePanel;

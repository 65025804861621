import 'regenerator-runtime/runtime';
import { select, call, put } from 'redux-saga/effects';
import _ from 'lodash';

import { getSlicerState, handleGenericError } from '../../common';
import actions from '../../../reducers/slicer/actions';
import types from '../../../reducers/slicer/types';
import { TreeUtils } from '../../../utils';
import FacesWorker from '../../../utils/faces/faces.worker';

let facesWorker = new FacesWorker();

const resetWorker = () => {
  facesWorker.terminate();
  facesWorker = new FacesWorker();
};

facesWorker.onerror = () => resetWorker();

export const getWorker = () => facesWorker;

export default function* useWorker(action) {
  try {
    const { models } = yield select(getSlicerState);

    switch (action.type) {
      case types.CANCEL_WORKER: {
        resetWorker();
        break;
      }

      case types.GENERATE_ATLAS_REQUEST: {
        const { resolution, maxTextureSize, maxTextures } = action.payload;
        facesWorker.postMessage({
          type: action.type,
          payload: {
            resolution,
            maxTextureSize,
            maxTextures,
          },
        });
        break;
      }

      case types.BUILD_FACES_REQUEST: {
        const flattenedModels = TreeUtils.flattenDeep(models);
        const modelToColor = _.find(
          flattenedModels,
          (item) => item.id === action.payload.modelId
        );
        const { mesh, rle, extruder } = modelToColor;
        facesWorker.postMessage({
          type: action.type,
          payload: {
            posFloat32Array: mesh.geometry.attributes.position.array,
            modelMatrix: mesh.matrix.clone().toArray(),
            modelRle: rle,
            modelExtruder: extruder,
          },
        });

        // mark analyzed model
        yield put(actions.markModelToColor(action.payload.modelId));
        break;
      }

      case types.AUTO_SEGMENT_REQUEST: {
        facesWorker.postMessage({
          type: action.type,
          payload: action.payload,
        });
        break;
      }

      case types.RESET_REGIONS_REQUEST: {
        facesWorker.postMessage({
          type: action.type,
          payload: action.payload,
        });
        break;
      }

      case types.COMPOSE_COLORS_RLE_REQUEST: {
        facesWorker.postMessage({
          type: action.type,
          payload: action.payload,
        });
        break;
      }

      default:
        break;
    }
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import _ from 'lodash';

class FilePartProgress {
  constructor(partSize) {
    this.total = partSize;
    this.loaded = 0;
  }
}

class FileProgress {
  constructor(partSizes) {
    this.parts = _.map(partSizes, (partSize) => new FilePartProgress(partSize));
  }

  getTotalSize() {
    return _.reduce(this.parts, (size, part) => size + part.total, 0);
  }

  getLoadedSize() {
    return _.reduce(this.parts, (size, part) => size + part.loaded, 0);
  }
}

export default class ProgressTracker {
  constructor(files, onProgress) {
    const progresses = {};
    _.forEach(files, (file) => {
      progresses[file.id] = new FileProgress(file.partSizes);
    });
    this.files = progresses;
    this.onProgress = onProgress;
    // internal throttling
    this.lastMessage = null; // performance.now() timestamp
    this.throttle = 500; // ms
  }

  getTotalSize() {
    return _.reduce(
      _.values(this.files),
      (size, file) => size + file.getTotalSize(),
      0
    );
  }

  getLoadedSize() {
    return _.reduce(
      _.values(this.files),
      (size, file) => size + file.getLoadedSize(),
      0
    );
  }

  getProgress() {
    return Math.round((this.getLoadedSize() / this.getTotalSize()) * 100);
  }

  updateProgress(id, partIdx, loaded) {
    this.files[id].parts[partIdx].loaded = loaded;
    // throttle this.onProgress calls
    const now = performance.now();
    if (!this.lastMessage || now >= this.lastMessage + this.throttle) {
      this.onProgress(this.getProgress());
      this.lastMessage = now;
    }
  }
}

import React, { Component } from 'react';
import _ from 'lodash';

import { ImportStylesOptions, ImportPrinterOptions } from '../metadata';

import {
  Header,
  HeadingContainer,
  ThumbnailPreviewContainer,
  DropdownContainer,
  ImportSelectionItem,
  ImportSelectionLabelContainer,
  ImportMaterialsNameWrapper,
  BoxSelectionWrapper,
  NewProjectNameInputWrapper,
  ImportSpliceSettingsWrapper,
} from './importCard.styles';

import { ButtonContainer } from '../importProject.styles';

import {
  BoxSelection,
  Button,
  Checkbox,
  Dropdown,
  Input,
  RadioList,
  Modal,
} from '../../../../shared';

import ImportHeading from '../importHeading/importHeading.jsx';
import StaticThumbnail from '../staticThumbnail/staticThumbnail.jsx';

import { FormatUtils, SortUtils } from '../../../../utils';

import { Body1, Caption } from '../../../../shared/typography/typography';

const DO_NOT_IMPORT_TEXT = 'Do not import';

class ImportCard extends Component {
  renderThumbnailPreview() {
    const { currentSharedProject, thumbnailLoaded } = this.props;
    return (
      <ThumbnailPreviewContainer>
        <StaticThumbnail
          currentSharedProject={currentSharedProject}
          thumbnailLoaded={thumbnailLoaded}
        />
      </ThumbnailPreviewContainer>
    );
  }

  renderNameInput() {
    const { projectName, projectNameError, focusField } = this.props;
    return (
      <NewProjectNameInputWrapper>
        <Input
          label='New project name'
          value={projectName}
          isInvalid={projectNameError}
          forceFocus={focusField === 'projectName'}
          onChangeSuccess={(value) => this.props.onNameChangeSuccess(value)}
          onChangeFailure={(value) => this.props.onNameChangeFailure(value)}
        />
      </NewProjectNameInputWrapper>
    );
  }

  getEssentialsText() {
    const { currentSharedProject } = this.props;
    const { style } = currentSharedProject;
    if (!style) return '';
    const layerHeightText = `${style.layerHeight} mm layers`;
    const infillText = `${style.infillDensity}% infill`;
    const supportsText = `supports ${
      style.useSupport ? 'enabled' : 'disabled'
    }`;
    return `${layerHeightText} • ${infillText} • ${supportsText}`;
  }

  renderPrinterOptionList() {
    const { importPrinter, currentSharedProject } = this.props;
    const { printer } = currentSharedProject;
    if (!printer) return null;
    const options = [
      {
        label: 'Use existing printer profile',
        value: ImportPrinterOptions.USE_EXISTING_PRINTER_PROFILE,
      },
      {
        label: 'Import printer profile',
        value: ImportPrinterOptions.IMPORT_PRINTER_PROFILE,
      },
    ];

    return (
      <RadioList
        options={options}
        value={importPrinter}
        onChange={(value) => this.props.onFieldChange('importPrinter', value)}
      />
    );
  }

  renderNewPrinterNameInput() {
    const { importPrinter, printerName, printerNameError, focusField } =
      this.props;
    if (importPrinter !== ImportPrinterOptions.IMPORT_PRINTER_PROFILE)
      return null;
    return (
      <DropdownContainer>
        <Input
          label='New printer profile name'
          value={printerName}
          isInvalid={printerNameError}
          forceFocus={focusField === 'printerName'}
          onChangeSuccess={(value) =>
            this.props.onPrinterNameChangeSuccess(value)
          }
          onChangeFailure={(value) =>
            this.props.onPrinterNameChangeFailure(value)
          }
        />
      </DropdownContainer>
    );
  }

  renderPrinterDropdown() {
    const { printers, importPrinter, printerId } = this.props;

    if (importPrinter === ImportPrinterOptions.USE_EXISTING_PRINTER_PROFILE) {
      const sortedPrinters = SortUtils.sortByName(
        printers,
        true,
        'machineSettings.name'
      );
      const printerOptions = [];
      _.each(sortedPrinters, (printer) => {
        printerOptions.push({
          label: printer.machineSettings.name,
          value: printer.id,
          disabled: printer.styles.length === 0,
        });
      });

      return (
        <DropdownContainer>
          <Dropdown
            value={printerId}
            label='Printer profile'
            disabled={printerOptions.length === 0}
            options={printerOptions}
            onChange={(value) => this.props.onFieldChange('printerId', value)}
            allowFilter
          />
        </DropdownContainer>
      );
    }
    return null;
  }

  renderPrinterOptions() {
    const { printer } = this.props.currentSharedProject;
    if (!printer) {
      return this.renderPrinterDropdown();
    }
    return (
      <>
        <ImportSelectionItem>
          <ImportSelectionLabelContainer>
            <Body1>Printer settings</Body1>
          </ImportSelectionLabelContainer>
          {this.renderPrinterOptionList()}
        </ImportSelectionItem>
        {this.renderNewPrinterNameInput()}
        {this.renderPrinterDropdown()}
      </>
    );
  }

  renderStylesOptionList() {
    const { currentSharedProject, importStyles, importPrinter } = this.props;
    const { style } = currentSharedProject;
    if (!style) return null;

    const disabled =
      !style || importPrinter === ImportPrinterOptions.IMPORT_PRINTER_PROFILE;
    const essentialsText = this.getEssentialsText();
    const options = [
      {
        label: 'Use existing style profile',
        value: ImportStylesOptions.DO_NOT_IMPORT,
      },
      {
        label: 'Import as new style profile',
        value: ImportStylesOptions.IMPORT_STYLE_PROFILE,
      },
      {
        label: 'Use essential styles only',
        value: ImportStylesOptions.USE_ESSENTIALS,
      },
    ];

    return (
      <>
        <RadioList
          options={options}
          value={importStyles}
          disabled={disabled}
          onChange={(value) => this.props.onFieldChange('importStyles', value)}
        />
        {essentialsText && (
          <Caption grey disabled={disabled}>
            {essentialsText}
          </Caption>
        )}
      </>
    );
  }

  renderNewStyleNameInput() {
    const { importStyles, styleName, styleNameError, focusField } = this.props;
    if (importStyles !== ImportStylesOptions.IMPORT_STYLE_PROFILE) return null;
    return (
      <DropdownContainer>
        <Input
          label='New style profile name'
          value={styleName}
          isInvalid={styleNameError}
          forceFocus={focusField === 'styleName'}
          onChangeSuccess={(value) =>
            this.props.onStyleNameChangeSuccess(value)
          }
          onChangeFailure={(value) =>
            this.props.onStyleNameChangeFailure(value)
          }
        />
      </DropdownContainer>
    );
  }

  renderStylesDropdown() {
    const { printers, importStyles, printerId } = this.props;
    if (importStyles === ImportStylesOptions.IMPORT_STYLE_PROFILE) return null;
    const styles = printers[printerId]?.styles;
    const styleOptions = _.map(SortUtils.sortByName(styles), (style) => ({
      label: style.name,
      value: style.id,
    }));
    return (
      <DropdownContainer>
        <Dropdown
          value={this.props.styleId}
          label='Style profile'
          disabled={styleOptions.length === 0}
          options={styleOptions}
          onChange={(styleId) => this.props.onFieldChange('styleId', styleId)}
        />
      </DropdownContainer>
    );
  }

  renderStylesOptions() {
    const { style } = this.props.currentSharedProject;
    if (!style) {
      return this.renderStylesDropdown();
    }

    return (
      <>
        <ImportSelectionItem>
          <ImportSelectionLabelContainer>
            <Body1 disabled={!style}>Style settings</Body1>
          </ImportSelectionLabelContainer>
          {this.renderStylesOptionList()}
        </ImportSelectionItem>
        {this.renderNewStyleNameInput()}
        {this.renderStylesDropdown()}
      </>
    );
  }

  renderMaterialsOptions() {
    const { currentSharedProject, importMaterials, importSpliceSettings } =
      this.props;
    const { materials, spliceSettings } = currentSharedProject;

    if (!materials) return null;
    const materialsCount = _.keys(materials).length;
    if (materialsCount === 0) return null;

    const importMaterialsOptionText = `Import ${materialsCount} ${FormatUtils.pluralize(
      'material',
      materialsCount
    )}`;
    const materialNames = `(${_.join(
      _.map(materials, (material) => material.name),
      ', '
    )})`;
    const options = [
      { label: importMaterialsOptionText, value: true },
      { label: DO_NOT_IMPORT_TEXT, value: false },
    ];
    const showSpliceSettingsCheckbox = importMaterials && spliceSettings;

    return (
      <ImportSelectionItem>
        <ImportSelectionLabelContainer>
          <Body1>Material profiles</Body1>
          <ImportMaterialsNameWrapper>
            <Body1 grey>{materialNames}</Body1>
          </ImportMaterialsNameWrapper>
        </ImportSelectionLabelContainer>
        <BoxSelectionWrapper>
          <BoxSelection
            maxColumns={Infinity}
            disabled={!materials}
            value={importMaterials}
            options={options}
            onClick={(value) =>
              this.props.onFieldChange('importMaterials', !!value)
            }
          />
        </BoxSelectionWrapper>
        {showSpliceSettingsCheckbox && (
          <ImportSpliceSettingsWrapper>
            <Checkbox
              label='Import splice settings'
              value={importSpliceSettings}
              onChange={() =>
                this.props.onFieldChange(
                  'importSpliceSettings',
                  !importSpliceSettings
                )
              }
            />
          </ImportSpliceSettingsWrapper>
        )}
      </ImportSelectionItem>
    );
  }

  renderButtons() {
    const {
      importStyles,
      styleId,
      projectNameError,
      isImportDataLoaded,
      printerId,
      importPrinter,
    } = this.props;

    let requiredFieldsFilled =
      !projectNameError &&
      (styleId || importStyles === ImportStylesOptions.IMPORT_STYLE_PROFILE);
    requiredFieldsFilled = importPrinter
      ? printerId !== ''
      : requiredFieldsFilled;
    return (
      <ButtonContainer>
        <Button onClick={this.props.onBackClick}>Back</Button>
        <Button
          primary
          disabled={!isImportDataLoaded || !requiredFieldsFilled}
          onClick={this.props.onNextClick}>
          Import
        </Button>
      </ButtonContainer>
    );
  }

  render() {
    return (
      <Modal width='40rem'>
        <Header>
          <HeadingContainer>
            <ImportHeading sharedProject={this.props.currentSharedProject} />
          </HeadingContainer>
          {this.renderThumbnailPreview()}
          {this.renderNameInput()}
        </Header>
        {this.renderPrinterOptions()}
        {this.renderStylesOptions()}
        {this.renderMaterialsOptions()}
        {this.renderButtons()}
      </Modal>
    );
  }
}

export default ImportCard;

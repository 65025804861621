import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import API, { methods } from '../canvas-api';

import { handleGenericError, getAuthState } from '../common';
import { actions } from '../../reducers/auth/auth';
import { InterfaceUtils } from '../../utils';

export default function* editAccount(action) {
  try {
    const { userId } = yield select(getAuthState);
    const { updatedInfo } = action.payload;
    const body = {};
    if (updatedInfo.email) {
      body.email = updatedInfo.email;
    }
    if (updatedInfo.currentPassword) {
      body.password = updatedInfo.currentPassword;
    }
    if (updatedInfo.newPassword) {
      body.newPassword = updatedInfo.newPassword;
    }
    if (updatedInfo.viewOptions) {
      body.viewOptions = updatedInfo.viewOptions;
    }
    if (updatedInfo.preferences) {
      body.preferences = updatedInfo.preferences;
    }
    if (updatedInfo.newProjectOptions) {
      body.newProjectOptions = updatedInfo.newProjectOptions;
    }

    const response = yield call(API, {
      method: methods.POST,
      path: `users/${userId}`,
      body,
    });
    if (response === null) return;

    yield put(actions.editAccountSuccess(response.user));
    if (updatedInfo.email) {
      InterfaceUtils.emitToast(
        'success',
        'Email has been successfully updated'
      );
    } else if (updatedInfo.newPassword) {
      InterfaceUtils.emitToast(
        'success',
        'Password has been successfully updated'
      );
    } else if (updatedInfo.newProjectOptions) {
      InterfaceUtils.emitToast(
        'success',
        'New project settings have been successfully updated'
      );
    } else if (
      updatedInfo.preferences &&
      (updatedInfo.preferences.includeThumbnailInZip !== undefined ||
        updatedInfo.preferences.preferUnzipped !== undefined)
    ) {
      InterfaceUtils.emitToast(
        'success',
        'Sliced file settings have been successfully updated'
      );
    }
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

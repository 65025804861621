import _ from 'lodash';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';

import SceneUtils from '../scene/scene';
import { SHADER_CHUNKS } from '../faces/shaders';
import types from '../../reducers/three/types';

/* eslint-disable no-param-reassign */

const Utils = {
  formatFields: (project) =>
    _.reduce(
      project,
      (acc, fieldValue, fieldName) => {
        if (_.isObject(fieldValue) && !_.isArray(fieldValue)) {
          acc[fieldName] = Utils.formatFields(fieldValue);
        } else if (_.isArray(fieldValue) && fieldValue.length === 3) {
          acc[fieldName] = Utils.vector3ArrToObj(fieldValue);
        } else if (_.isArray(fieldValue) && fieldValue.length === 2) {
          acc[fieldName] = Utils.vector2ArrToObj(fieldValue);
        } else {
          acc[fieldName] = fieldValue;
        }
        return acc;
      },
      {}
    ),
  vector2ArrToObj: (arr) => ({ x: arr[0], y: arr[1] }),
  vector2ObjToAr: (obj) => [obj.x, obj.y],
  vector3ArrToObj: (arr) => ({ x: arr[0], y: arr[1], z: arr[2] }),
  vector3ObjToAr: (obj) => [obj.x, obj.y, obj.z],
  getMeshMaterial: (colors) => {
    const extruderColors = colors.map((color) => ({
      color: new THREE.Vector3(color[0] / 255, color[1] / 255, color[2] / 255),
    }));

    const uniforms = THREE.UniformsUtils.merge([
      THREE.UniformsLib['lights'],
      {
        highlighted: { value: false },
        inSupportView: { value: false },
        extruderColors: {
          value: extruderColors,
        },
      },
    ]);

    const defines = {
      NUM_EXTRUDER_COLORS: extruderColors.length,
    };

    return new THREE.ShaderMaterial({
      vertexShader: SHADER_CHUNKS.defaultMeshVertexShader,
      fragmentShader: SHADER_CHUNKS.defaultMeshFragmentShader,
      lights: true,
      side: THREE.DoubleSide,
      uniforms,
      defines,
    });
  },
  getTowerMaterial: () => {
    const uniforms = THREE.UniformsUtils.merge([
      THREE.UniformsLib['lights'],
      {
        highlighted: { value: false },
      },
    ]);
    return new THREE.ShaderMaterial({
      vertexShader: SHADER_CHUNKS.defaultTowerVertexShader,
      fragmentShader: SHADER_CHUNKS.defaultTowerFragmentShader,
      lights: true,
      uniforms,
    });
  },
  getStampMaterial: (uniforms, defines) =>
    new THREE.ShaderMaterial({
      vertexShader: SHADER_CHUNKS.renderStampVertexShader,
      fragmentShader: SHADER_CHUNKS.renderStampFragmentShader,
      transparent: true,
      lights: true,
      polygonOffset: true, // use polygon offset to "pull forward"
      polygonOffsetFactor: -1 * uniforms.renderOrder.value,
      polygonOffsetUnits: -1,
      uniforms,
      defines,
    }),
  requestMesh: async (url, modelData, colors, colorsRle, supportsRle) => {
    const modelName = modelData.path.split('|').pop();
    const loader = new STLLoader();
    return new Promise((resolve, reject) => {
      const onLoad = (geometry) => {
        if (!geometry) {
          reject(new Error('Could not load models. Please reload the page.'));
          return;
        }
        geometry.computeVertexNormals();
        geometry.computeBoundsTree({
          maxDepth: 50,
        });

        // setup color attributes on faces
        const colorAttr = new THREE.BufferAttribute(
          new Float32Array(geometry.attributes.position.count),
          1
        );
        const colorAttrLength = colorAttr.count / 3;

        if (colorsRle) {
          // colored model; set RLE-parsed extruder index
          let rleIndex = 0;
          let numFacesToColor = colorsRle[rleIndex];
          rleIndex++;
          let extruderIndex = colorsRle[rleIndex];
          rleIndex++;

          for (let i = 0; i < colorAttrLength; i++) {
            if (numFacesToColor <= 0) {
              numFacesToColor = colorsRle[rleIndex];
              rleIndex++;
              extruderIndex = colorsRle[rleIndex];
              rleIndex++;
            }
            colorAttr.setXYZ(
              i * 3,
              extruderIndex,
              extruderIndex,
              extruderIndex
            );
            numFacesToColor--;
          }
        } else {
          const modelExtruder = modelData.extruder;
          // regular model; set model extruder index
          for (let i = 0; i < colorAttrLength; i++) {
            colorAttr.setXYZ(
              i * 3,
              modelExtruder,
              modelExtruder,
              modelExtruder
            );
          }
        }

        colorAttr.needsUpdate = true;
        geometry.addAttribute('color', colorAttr);
        geometry.attributes.color.setDynamic(true);

        // setup support attributes on faces
        const supportAttr = new THREE.BufferAttribute(
          new Float32Array(geometry.attributes.position.count),
          1
        );

        if (supportsRle) {
          // custom supports; parse supports rle
          let rleIndex = 0;
          let numFacesToColor = supportsRle[rleIndex];
          rleIndex++;
          let faceColorIndex = supportsRle[rleIndex];
          rleIndex++;

          for (let i = 0; i < colorAttrLength; i++) {
            if (numFacesToColor <= 0) {
              numFacesToColor = supportsRle[rleIndex];
              rleIndex++;
              faceColorIndex = supportsRle[rleIndex];
              rleIndex++;
            }

            if (faceColorIndex === 1) {
              supportAttr.setXYZ(i * 3, 1.0, 1.0, 1.0);
            }
            numFacesToColor--;
          }
        }
        geometry.addAttribute('support', supportAttr);
        geometry.attributes.support.setDynamic(true);

        const material = Utils.getMeshMaterial(colors);
        const mesh = new THREE.Mesh(geometry, material);

        resolve({
          name: modelName,
          mesh,
          rle: colorsRle,
          colored: !!colorsRle,
          supportsRle,
          hasCustomSupports: !!supportsRle,
        });
      };
      const onProgress = (/* xhr */) => {};
      const onError = (/* err */) => {
        reject(new Error('Could not load models. Please reload the page.'));
      };
      try {
        loader.load(url, onLoad, onProgress, onError);
      } catch (err) {
        reject(err);
      }
    });
  },
  applyStamps: (parentMesh, stampMap) => {
    // clear stamps meshes from model meshes, if any
    Utils.resetStamps(parentMesh, stampMap);

    // add stamp meshes to model meshes, if any
    Utils.addStamps(parentMesh, stampMap);
  },
  addStamps: (parentMesh, stampMap) => {
    // get stamps in array and sort by render order
    const stamps = _.map(stampMap, (stampObject, key) => {
      stampObject.id = key;
      return stampObject;
    });
    const sortedStamps = _.sortBy(stamps, (stamp) => stamp.meta.renderOrder);
    if (_.isEmpty(sortedStamps)) return;

    const extruderColorsUniform = parentMesh.material.uniforms.extruderColors;
    const shaderDefines = parentMesh.material.defines;

    // add stamps to parent mesh
    _.forEach(sortedStamps, (stamp) => {
      const existsAlready = _.find(
        parentMesh.children,
        (stampGroup) => stampGroup.children[0].name === stamp.id
      );
      if (existsAlready) {
        // no-op if this stamp is already added
        return;
      }

      const { stampRLE, stampTextureBuffer, depthTextureBuffer, meta } = stamp;

      const stampTexture = new THREE.DataTexture(
        new Uint8Array(stampTextureBuffer),
        meta.imageDimensions.width,
        meta.imageDimensions.height,
        THREE.RGBAFormat
      );
      stampTexture.flipY = true;
      stampTexture.needsUpdate = true;

      const depthTexture = new THREE.DataTexture(
        new Uint8Array(depthTextureBuffer),
        meta.depthBounds.right - meta.depthBounds.left,
        meta.depthBounds.bottom - meta.depthBounds.top,
        THREE.RGBAFormat
      );
      depthTexture.needsUpdate = true;

      // create stamp camera
      const stampCamera = SceneUtils.createCamera(meta.cameraOrthographic);

      const cameraViewMatrix = new THREE.Matrix4().fromArray(
        meta.cameraViewMatrix
      );

      const cameraProjectionMatrix = new THREE.Matrix4().fromArray(
        meta.cameraProjectionMatrix
      );

      const cameraDirection = new THREE.Vector3().fromArray(
        meta.cameraDirection
      );

      const cameraMatrixWorld = new THREE.Matrix4().getInverse(
        cameraViewMatrix
      );
      stampCamera.applyMatrix(cameraMatrixWorld);
      stampCamera.needsUpdate = true;

      const stampBottomLeft = new THREE.Vector2(
        meta.stampBounds.left / meta.canvasDimensions.width,
        1 - meta.stampBounds.bottom / meta.canvasDimensions.height
      );
      const stampTopRight = new THREE.Vector2(
        meta.stampBounds.right / meta.canvasDimensions.width,
        1 - meta.stampBounds.top / meta.canvasDimensions.height
      );
      const stampWidth = meta.stampBounds.right - meta.stampBounds.left;
      const stampHeight = meta.stampBounds.bottom - meta.stampBounds.top;

      const depthBottomLeft = new THREE.Vector2(
        meta.depthBounds.left / meta.canvasDimensions.width,
        1 - meta.depthBounds.bottom / meta.canvasDimensions.height
      );
      const depthTopRight = new THREE.Vector2(
        meta.depthBounds.right / meta.canvasDimensions.width,
        1 - meta.depthBounds.top / meta.canvasDimensions.height
      );
      const depthWidth = meta.depthBounds.right - meta.depthBounds.left;
      const depthHeight = meta.depthBounds.bottom - meta.depthBounds.top;

      const mergedUniforms = THREE.UniformsUtils.merge([
        THREE.UniformsLib['lights'],
        {
          stampBottomLeft: { value: stampBottomLeft },
          stampTopRight: { value: stampTopRight },
          stampWidth: { value: stampWidth },
          stampHeight: { value: stampHeight },
          stampRotation: { value: meta.stampRotation },
          depthBottomLeft: { value: depthBottomLeft },
          depthTopRight: { value: depthTopRight },
          depthWidth: { value: depthWidth },
          depthHeight: { value: depthHeight },
          cameraViewMatrix: { type: 'm4', value: cameraViewMatrix },
          cameraProjectionMatrix: { type: 'm4', value: cameraProjectionMatrix },
          cameraDirection: { value: cameraDirection },
          renderOrder: { value: meta.renderOrder },
          highlighted: { value: false },
          translucent: { value: false },
          extruderColors: extruderColorsUniform,
        },
      ]);

      // Important to define the textures outside the THREE.UniformsUtils.merge call
      // (as that call clones the texture)
      mergedUniforms.stampTexture = { type: 't', value: stampTexture };
      mergedUniforms.depthTexture = { type: 't', value: depthTexture };

      // create stamp material
      const stampMaterial = Utils.getStampMaterial(
        mergedUniforms,
        shaderDefines
      );

      stampMaterial.userData.stampRLE = stampRLE;
      stampMaterial.userData.cameraOrthographic = meta.cameraOrthographic;
      stampMaterial.userData.imageDimensions = meta.imageDimensions;
      stampMaterial.userData.canvasDimensions = meta.canvasDimensions;
      stampMaterial.userData.stampBounds = meta.stampBounds;
      stampMaterial.userData.depthBounds = meta.depthBounds;

      const stampMesh = new THREE.Mesh(parentMesh.geometry, stampMaterial);
      stampMesh.applyMatrix(parentMesh.matrix);
      stampMesh.name = stamp.id;

      const stampGroup = new THREE.Group();
      stampGroup.matrixAutoUpdate = false;

      stampGroup.attach(stampMesh);
      parentMesh.attach(stampGroup);
      stampGroup.attach(stampCamera);
    });
  },
  resetStamps: (parentMesh, stampMap) => {
    // collect stamps to delete (i.e., the ones that never got saved in server);
    const stampsToDelete = _.reduce(
      parentMesh.children,
      (collected, stampGroup) => {
        const [stampMesh] = stampGroup.children;
        // reset all stamp meshes visibility to true
        // even if they are currently not visible (e.g when leaving paint view)
        stampGroup.visible = true;
        if (!_.has(stampMap, stampMesh.name)) collected.push(stampGroup);
        return collected;
      },
      []
    );

    // delete collected stamps
    _.forEach(stampsToDelete, (stampGroupToDelete) => {
      Utils.removeStampGroup(stampGroupToDelete, parentMesh);
    });
  },
  removeStampGroup: (stampGroup, parentMesh) => {
    const [stampMesh] = stampGroup.children;
    // dispose of stamp textures
    const { stampTexture, depthTexture } = stampMesh.material.uniforms;
    stampTexture.value.dispose();
    depthTexture.value.dispose();

    // dispose of stamp group
    stampMesh.material.dispose();
    stampMesh.geometry.dispose();
    parentMesh.remove(stampGroup);
  },
  updateStampOrder: (parentMesh) => {
    parentMesh.children.forEach((stampGroup, index) => {
      const [stampMesh] = stampGroup.children;
      // update render order of stamp
      stampMesh.material.uniforms.renderOrder.value = index + 1;
    });
  },
  createMeshes: async (files, colors) => {
    const meshes = _.map(files, async (file) => {
      const fileUrl = URL.createObjectURL(file);
      const mesh = await Utils.createMesh(fileUrl, colors);
      URL.revokeObjectURL(fileUrl);
      return mesh;
    });
    return Promise.all(meshes);
  },
  createMesh: async (fileUrl, colors) => {
    const loader = new STLLoader();
    return new Promise((resolve, reject) => {
      loader.load(fileUrl, (geometry) => {
        if (!geometry) {
          reject(new Error('Unable to load geometry'));
          return;
        }
        geometry.computeVertexNormals();
        geometry.computeBoundsTree({
          maxDepth: 50,
        });

        // setup color attributes on faces
        const colorAttr = new THREE.BufferAttribute(
          new Float32Array(geometry.attributes.position.count),
          1
        );
        geometry.addAttribute('color', colorAttr);
        geometry.attributes.color.setDynamic(true);

        // setup support attributes on faces
        const supportAttr = new THREE.BufferAttribute(
          new Float32Array(geometry.attributes.position.count),
          1
        );
        geometry.addAttribute('support', supportAttr);
        geometry.attributes.support.setDynamic(true);

        const material = Utils.getMeshMaterial(colors);
        const mesh = new THREE.Mesh(geometry, material);
        resolve(mesh);
      });
    });
  },
  createTowerMesh: (tower) => {
    const hasBrims = tower.brims && tower.brims.count > 0;
    const geometry = new THREE.BoxGeometry(
      hasBrims ? tower.brims.size.x : tower.size.x,
      hasBrims ? tower.brims.size.y : tower.size.y,
      hasBrims ? tower.brims.size.z : tower.size.z
    );
    const material = Utils.getTowerMaterial();
    const mesh = new THREE.Mesh(geometry, material);
    mesh.name = types.TOWER_MESH_NAME;
    mesh.rotateZ(tower.rotation);
    mesh.position.set(tower.position.x, tower.position.y, tower.position.z);
    mesh.visible = tower.visible;
    if (tower.active) {
      mesh.material.uniforms.highlighted.value = true;
    }
    return mesh;
  },
  degreesToRadians: (degrees) => (degrees * Math.PI) / 180,
  radiansToDegrees: (radians) => Math.round((radians * 180) / Math.PI),
  getBoundingBox: (object3d) => {
    if (_.isArray(object3d)) {
      return _.reduce(
        object3d,
        (acc, child) => {
          const bbox = Utils.getBoundingBox(child);
          acc.expandByPoint(bbox.min);
          acc.expandByPoint(bbox.max);
          return acc;
        },
        new THREE.Box3()
      );
    }
    if (object3d.isGroup) {
      return Utils.getBoundingBox(object3d.children);
    }
    if (!object3d.userData.boundingBoxWorld) {
      object3d.userData.boundingBoxWorld = new THREE.Box3().setFromObject(
        object3d
      );
    }
    return object3d.userData.boundingBoxWorld;
  },
  getCombinedBoundingBox: (models) =>
    _.reduce(
      models,
      (acc, node) => {
        let bbox;
        if (node.type === 'group') {
          bbox = Utils.getCombinedBoundingBox(node.children);
        } else {
          bbox = Utils.getBoundingBox(node.mesh);
        }
        acc.expandByPoint(bbox.min);
        acc.expandByPoint(bbox.max);
        return acc;
      },
      new THREE.Box3()
    ),
  getBoundingSphere: (obj) => {
    const bbox = Utils.getBoundingBox(obj);
    const sphere = new THREE.Sphere();
    bbox.getBoundingSphere(sphere);
    return sphere;
  },
  getBoundingSphereFromBoundingBox: (boundingBox) => {
    const sphere = new THREE.Sphere();
    boundingBox.getBoundingSphere(sphere);
    return sphere;
  },
  getMeshOffsetCoords: (mesh, position) => {
    const bbox = Utils.getBoundingBox(mesh);
    return {
      x: position.x,
      y: position.y,
      z: -bbox.min.z,
    };
  },
  getMinZ: (meshes) => Utils.getBoundingBox(meshes).min.z,
  offsetMeshesZ: (meshes) => {
    if (Array.isArray(meshes)) {
      const minZ = Utils.getMinZ(meshes);
      _.forEach(meshes, (mesh) => {
        mesh.translateZ(-minZ);
      });
      return -minZ;
    }
    // single mesh
    const minZ = Utils.getMinZ([meshes]);
    meshes.translateZ(-minZ);
    return -minZ;
  },
  hexStringToRGBA: (hex, alpha = 1) =>
    _.concat(
      _.map(hex.replace(/^#/, '').match(/.{2}/g), (str) => parseInt(str, 16)), // RGB
      [alpha] // A
    ),
  rgbaArrayToHexString: (arr) =>
    `#${_.map(arr, (element, index) => {
      if (index === 3) return '';
      return `00${element.toString(16)}`.substr(-2);
    }).join('')}`,
  removeMeshHighlights: (meshes) => {
    if (_.isEmpty(meshes)) return;
    meshes.forEach((mesh) => {
      const { geometry, userData, name } = mesh;

      mesh.material.uniforms.highlighted.value = false;

      if (name !== types.TOWER_MESH_NAME) {
        // reset previously highlighted face if any

        if (userData.highlightedFaceIndex) {
          const faceIndex = userData.highlightedFaceIndex;
          const colorIndex = userData.highlightedFaceColorIndex;
          const colorAttr = geometry.attributes.color;
          colorAttr.setXYZ(faceIndex * 3, colorIndex, colorIndex, colorIndex);
          colorAttr.needsUpdate = true;

          userData.highlightedFaceIndex = null;
          userData.highlightedFaceColorIndex = null;
        }

        // update stamp highlights as well
        mesh.children.forEach((stampGroup) => {
          const [stampMesh] = stampGroup.children;
          stampMesh.material.uniforms.highlighted.value = false;
          stampMesh.material.uniforms.translucent.value = false;
        });
      }
    });
  },
  highlightMeshes: (meshes) => {
    if (_.isEmpty(meshes)) return;
    meshes.forEach((mesh) => {
      mesh.material.uniforms.highlighted.value = true;

      // update stamp highlights as well
      mesh.children.forEach((stampGroup) => {
        const [stampMesh] = stampGroup.children;
        stampMesh.material.uniforms.highlighted.value = true;
      });
    });
  },
  highlightFace: (intersection) => {
    document.body.style.cursor = 'pointer';
    const { object, faceIndex } = intersection;
    const { geometry, userData, children } = object;

    const vertexIndex = geometry.index.array[faceIndex * 3];
    const adjustedFaceIndex = Math.floor(vertexIndex / 3);

    // make stamps translucent
    children.forEach((stampGroup) => {
      const [stampMesh] = stampGroup.children;
      stampMesh.material.uniforms.translucent.value = true;
    });

    const colorAttr = geometry.attributes.color;
    const originalColorIndex = colorAttr.getX(adjustedFaceIndex * 3);

    userData.highlightedFaceIndex = adjustedFaceIndex;
    userData.highlightedFaceColorIndex = originalColorIndex;

    // set special color index by flipping sign and subtract 1 (because -0 doesn't work)
    //  e.g., 0  ->  -0  ->  -1
    //  e.g., 1  ->  -1  -> -2

    // inside shaders, if the extruder index is negative for a given vertex,
    //  we know that the vertex belongs to our target face
    //  revert the above process to retrieve the original color index

    const specialIndex = originalColorIndex * -1 - 1;
    colorAttr.setXYZ(
      adjustedFaceIndex * 3,
      specialIndex,
      specialIndex,
      specialIndex
    );
    colorAttr.needsUpdate = true;
  },
  getSelectedModels: (models) =>
    models.reduce((acc, model) => {
      if (model.type === 'group') {
        return acc.concat(Utils.getSelectedModels(model.children));
      }
      if (model.active) {
        acc.push(model);
      }
      return acc;
    }, []),
  getBoundingBoxCenter: (bbox) => {
    const center = new THREE.Vector3();
    bbox.getCenter(center);
    return center;
  },
  clampDegreesToCircle: (degrees) => ((degrees % 360) + 360) % 360,
  // fixes JS's weird handling of negative modulos to work like proper math
  // e.g. `-1 % 10` should be 9, but by default in JS it would be -1
  // see this thread for details:
  //   https://stackoverflow.com/questions/4467539/javascript-modulo-gives-a-negative-result-for-negative-numbers
  clampRadiansToCircle: (radians) => {
    const fullCircle = 2 * Math.PI;
    return ((radians % fullCircle) + fullCircle) % fullCircle;
  },
};

/* eslint-enable no-param-reassign */
export default Utils;

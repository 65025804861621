import styled from 'styled-components';
import { Breakpoints, Shadows } from '../../themes';

export const ModalContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  ${Shadows.d1};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0.75rem 0.75rem 1.75rem;
  gap: 1rem;
  z-index: 2;
  ${Shadows.d1};

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0 1rem 1rem;
    gap: 1rem;
  }
`;

export const SidebarWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

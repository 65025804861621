import { call, put } from 'redux-saga/effects';

import { DEVICE_ORIGIN_NAME } from './topics';
import { handleBroadcast } from './horizon';
import { unsubscribeFromDevice } from './subscriptions';

import { actions } from '../../reducers/iot/iot';
import { actions as notificationActions } from '../../reducers/notifications/notifications';

import {
  handleSliceJobDispatched,
  handleSliceJobUpdate,
  handleSliceJobCanceled,
} from '../slicer/project/slice';

import { InterfaceUtils, NotificationUtils } from '../../utils';

function* handleSystemMessage(message) {
  const { type, payload } = message;
  switch (type) {
    case 'NOTIFICATION': {
      yield put(notificationActions.receiveNotification(payload));
      if (NotificationUtils.shouldSendNative()) {
        yield call(
          NotificationUtils.sendNative,
          payload.title,
          payload.content
        );
      }
      break;
    }
    case 'DEVICE_UNLINKED': {
      const { deviceId, name } = payload;
      yield call(unsubscribeFromDevice, deviceId);
      yield put(actions.unlinkDevicesSuccess([deviceId]));
      InterfaceUtils.emitToast(
        'info',
        `Device '${name}' was unlinked from your account`
      );
      break;
    }
    case 'SLICE_JOB_DISPATCHED': {
      yield call(handleSliceJobDispatched, payload);
      break;
    }
    case 'SLICE_JOB_UPDATE': {
      yield call(handleSliceJobUpdate, payload);
      break;
    }
    case 'SLICE_JOB_CANCELED': {
      yield call(handleSliceJobCanceled, payload);
      break;
    }
    default:
      break;
  }
}

export default function* receiveMessage(action) {
  const { topic, message } = action.payload;
  const [, ...topicParts] = topic.split('/'); // first part of path is env namespace
  if (topicParts[0] === 'users') {
    yield call(handleSystemMessage, message);
  } else if (topicParts[0] === 'devices' && topicParts.length >= 4) {
    const deviceId = topicParts[1];
    if (topicParts[2] === DEVICE_ORIGIN_NAME) {
      if (topicParts[3] === 'broadcast') {
        // device message, broadcast-style from device
        const broadcastPath = topicParts.slice(4).join('/');
        yield call(handleBroadcast, deviceId, broadcastPath, message);
      } else {
        // device message, response-style from device (this is handled in
        // ./horizon.js, where the corresponding request was made)
      }
    }
  }
}

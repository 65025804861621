import React from 'react';
import { withTheme } from 'styled-components';
import _ from 'lodash';

import {
  ActionButtonWrapper,
  BoxSelectionWrapper,
  CaptionWrapper,
  Container,
  ControlsWrapper,
  ExtrudeTool,
  ExtruderStepControlsWrapper,
  ExtrusionControls,
  ExtrusionControlsWrapper,
  ExtrusionPreview,
  ExtrusionPreviewControlContainer,
  ExtrusionPreviewImage,
  FanControls,
  FanSpeed,
  FanSpeedContainer,
  FanTool,
  GreenSlider,
  GreenSliderContainer,
  HomeMovement,
  HorizontalControls,
  HorizontalMovements,
  Horizontal3DControls,
  Horizontal3DControlsRotation,
  HorizontalMovement,
  MoveTool,
  StepControls,
  StyledNumberInputContainer,
  ToolsWrapper,
  VerticalControls,
  VerticalMovements,
  VerticalMovement,
} from './printer.styles';

import {
  AbstractInput,
  ActionButton,
  Body1,
  BoxSelection,
  Button,
  Caption,
  Icon,
  Progress,
  Slider,
  Subtitle2,
  ToolTipWrapper,
} from '../../../../../shared';

import { Icons } from '../../../../../themes';
import { DeviceUtils } from '../../../../../utils';
import { SpinnerContainer } from '../temperature/temperatureController/temperatureController.styles';

import Placeholder from '../../placeholder.jsx';

const MOVEMENT_DIRECTIONS = {
  LEFT: 'left',
  RIGHT: 'right',
  FORWARD: 'forward',
  BACKWARD: 'backward',
  UP: 'up',
  DOWN: 'down',
};

const STEP_CONTROL_OPTIONS = [
  { label: '0.1 mm', value: 0.1 },
  { label: '1 mm', value: 1 },
  { label: '10 mm', value: 10 },
  { label: 'Custom', value: -1 },
];

const MOTOR_AND_FAN_OPTIONS = [
  { label: 'On', value: true },
  { label: 'Off', value: false },
];

class PrinterControls extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentFanSpeed: 0,
      currentXYZStepDistance: 1,
      currentExtruderStepDistance: 1,
      useCustomXYZStepDistance: false,
      useCustomExtruderStepDistance: false,
      customXYZStepDistance: 0.1,
      customExtruderStepDistance: 0.1,
      customXYZStepDistanceError: false,
      customExtruderStepDistanceError: false,
      retracting: false,
      extruding: false,
    };

    this.onRetract = this.onRetract.bind(this);
    this.onExtrude = this.onExtrude.bind(this);
    this.onChangeFanSpeed = this.onChangeFanSpeed.bind(this);
    this.updateFanSpeed = this.updateFanSpeed.bind(this);
  }

  componentDidMount() {
    const { currentDeviceState } = this.props;
    const fanSpeed = DeviceUtils.getFanSpeed(currentDeviceState);

    this.setState({
      currentFanSpeed: fanSpeed,
    });
  }

  componentDidUpdate(prevProps) {
    const { currentDeviceState } = this.props;
    const { currentDeviceState: previousDeviceState } = prevProps;

    if (!_.isEqual(currentDeviceState, previousDeviceState)) {
      // device state got updated
      const currentFanSpeed = DeviceUtils.getFanSpeed(currentDeviceState);
      const previousFanSpeed = DeviceUtils.getFanSpeed(previousDeviceState);

      // fan speed got changed
      if (currentFanSpeed !== previousFanSpeed) {
        this.setState({
          currentFanSpeed,
        });
      }
    }
  }

  getMovementAxis(movementDirection) {
    switch (movementDirection) {
      case MOVEMENT_DIRECTIONS.LEFT:
      case MOVEMENT_DIRECTIONS.RIGHT:
        return 'x';
      case MOVEMENT_DIRECTIONS.FORWARD:
      case MOVEMENT_DIRECTIONS.BACKWARD:
        return 'y';
      case MOVEMENT_DIRECTIONS.UP:
      case MOVEMENT_DIRECTIONS.DOWN:
        return 'z';
      default:
        return null;
    }
  }

  getStepDistanceForRequest(
    currentStepDistance,
    customStepDistance,
    useCustomStepDistance
  ) {
    let stepDistance = currentStepDistance;
    if (useCustomStepDistance) {
      stepDistance = customStepDistance;
    }
    return stepDistance;
  }

  onSelectXYZStepDistance(stepDistance) {
    this.setState({
      currentXYZStepDistance: stepDistance,
      useCustomXYZStepDistance: stepDistance === -1,
    });
  }

  onSelectExtruderStepDistance(stepDistance) {
    this.setState({
      currentExtruderStepDistance: stepDistance,
      useCustomExtruderStepDistance: stepDistance === -1,
    });
  }

  onChangeCustomXYZStepDistance(stepDistance, error = false) {
    this.setState({
      customXYZStepDistance: stepDistance,
      customXYZStepDistanceError: error,
    });
  }

  onChangeCustomExtruderStepDistance(stepDistance, error = false) {
    this.setState({
      customExtruderStepDistance: stepDistance,
      customExtruderStepDistanceError: error,
    });
  }

  onMoveExtruder(movementDirection) {
    const {
      currentXYZStepDistance,
      customXYZStepDistance,
      useCustomXYZStepDistance,
    } = this.state;
    const stepDistance = this.getStepDistanceForRequest(
      currentXYZStepDistance,
      customXYZStepDistance,
      useCustomXYZStepDistance
    );

    let directionMultiplier = 1;
    const negativeDirections = [
      MOVEMENT_DIRECTIONS.LEFT,
      MOVEMENT_DIRECTIONS.BACKWARD,
      MOVEMENT_DIRECTIONS.DOWN,
    ];
    if (negativeDirections.includes(movementDirection)) {
      directionMultiplier = -1;
    }

    const axis = this.getMovementAxis(movementDirection);
    if (!axis) return;

    this.props.onMoveExtruder({ [axis]: directionMultiplier * stepDistance });
  }

  onHomeExtruder(axes) {
    this.props.onHomeExtruder(axes);
  }

  onRetract() {
    const {
      currentExtruderStepDistance,
      customExtruderStepDistance,
      useCustomExtruderStepDistance,
    } = this.state;

    const stepDistance = this.getStepDistanceForRequest(
      currentExtruderStepDistance,
      customExtruderStepDistance,
      useCustomExtruderStepDistance
    );

    this.props.onFeedFilament({
      e: -1 * stepDistance, // feed amount; +ve for extrude, -ve for retract
    });

    this.setState(
      {
        retracting: true,
        extruding: false,
      },
      () =>
        setTimeout(
          () =>
            this.setState({
              retracting: false,
            }),
          3000
        )
    );
  }

  onExtrude() {
    const {
      currentExtruderStepDistance,
      customExtruderStepDistance,
      useCustomExtruderStepDistance,
    } = this.state;

    const stepDistance = this.getStepDistanceForRequest(
      currentExtruderStepDistance,
      customExtruderStepDistance,
      useCustomExtruderStepDistance
    );

    this.props.onFeedFilament({
      e: stepDistance, // feed amount; +ve for extrude, -ve for retract
    });

    this.setState(
      {
        retracting: false,
        extruding: true,
      },
      () =>
        setTimeout(
          () =>
            this.setState({
              extruding: false,
            }),
          3000
        )
    );
  }

  onToggleMotor(motorOn) {
    this.props.onControlMotor({ on: motorOn });
  }

  onToggleFan(fanOn) {
    const targetFanSpeed = this.state.currentFanSpeed || 100;

    this.props.onControlFan({
      speed: fanOn ? targetFanSpeed : 0,
    });
  }

  updateFanSpeed() {
    this.props.onControlFan({
      speed: this.state.currentFanSpeed,
    });
  }

  onChangeFanSpeed(e, value) {
    this.setState({
      currentFanSpeed: value,
    });
  }

  renderHorizontalMovementControls() {
    const { currentDeviceState, device } = this.props;

    const movements = [
      {
        direction: MOVEMENT_DIRECTIONS.FORWARD,
        icon: Icons.fdm.extruderUp,
        title: 'Move extruder forward',
      },
      {
        direction: MOVEMENT_DIRECTIONS.BACKWARD,
        icon: Icons.fdm.extruderDown,
        title: 'Move extruder backward',
      },
      {
        direction: MOVEMENT_DIRECTIONS.LEFT,
        icon: Icons.fdm.extruderLeft,
        title: 'Move extruder left',
      },
      {
        direction: MOVEMENT_DIRECTIONS.RIGHT,
        icon: Icons.fdm.extruderRight,
        title: 'Move extruder right',
      },
    ];

    const isPrinting = DeviceUtils.isActivelyPrinting(
      currentDeviceState,
      device
    );
    const isPrintPaused = DeviceUtils.isPrintPaused(currentDeviceState, device);

    return (
      <HorizontalControls>
        <HorizontalMovements>
          <Horizontal3DControls>
            <Horizontal3DControlsRotation>
              {movements.map((movement) => (
                <HorizontalMovement
                  key={movement.direction}
                  direction={movement.direction}
                  disabled={isPrinting && !isPrintPaused}>
                  <ActionButton
                    clean
                    icon={movement.icon}
                    title={movement.title}
                    onClick={() => this.onMoveExtruder(movement.direction)}
                  />
                </HorizontalMovement>
              ))}
            </Horizontal3DControlsRotation>
          </Horizontal3DControls>
        </HorizontalMovements>
        <HomeMovement disabled={isPrinting && !isPrintPaused}>
          <ActionButtonWrapper>
            <ActionButton
              clean
              icon={Icons.fdm.home}
              title='Home extruder horizontally'
              tooltipProps={{ rightAlign: true }}
              onClick={() => this.onHomeExtruder(['x', 'y'])}
            />
          </ActionButtonWrapper>
          <CaptionWrapper>
            <Caption grey noSpacing>
              XY
            </Caption>
          </CaptionWrapper>
        </HomeMovement>
      </HorizontalControls>
    );
  }

  renderVerticalMovementControls() {
    const { currentDeviceState, device } = this.props;

    const movements = [
      {
        direction: MOVEMENT_DIRECTIONS.UP,
        icon: Icons.fdm.extruderUp,
        title: 'Move extruder up',
      },
      {
        direction: MOVEMENT_DIRECTIONS.DOWN,
        icon: Icons.fdm.extruderDown,
        title: 'Move extruder down',
      },
    ];

    const isActivelyPrinting = DeviceUtils.isActivelyPrinting(
      currentDeviceState,
      device
    );
    const isPrintPaused = DeviceUtils.isPrintPaused(currentDeviceState, device);
    return (
      <VerticalControls>
        <VerticalMovements>
          {movements.map((movement) => (
            <VerticalMovement
              key={movement.direction}
              disabled={isActivelyPrinting && !isPrintPaused}>
              <ActionButton
                clean
                icon={movement.icon}
                title={movement.title}
                onClick={() => this.onMoveExtruder(movement.direction)}
              />
            </VerticalMovement>
          ))}
        </VerticalMovements>
        <HomeMovement disabled={isActivelyPrinting && !isPrintPaused}>
          <ActionButtonWrapper>
            <ActionButton
              clean
              icon={Icons.fdm.home}
              title='Home extruder vertically'
              tooltipProps={{ rightAlign: true }}
              onClick={() => this.onHomeExtruder(['z'])}
            />
          </ActionButtonWrapper>
          <CaptionWrapper>
            <Caption grey noSpacing>
              Z
            </Caption>
          </CaptionWrapper>
        </HomeMovement>
      </VerticalControls>
    );
  }

  renderExtrusionPreview() {
    const { retracting, extruding } = this.state;
    return (
      <ExtrusionPreview retracting={retracting} extruding={extruding}>
        <ExtrusionPreviewImage
          src={this.props.theme.images.setupIcons.extruder}
        />
        {retracting && <Icon src={Icons.basic.arrowUp} />}
        {extruding && <Icon src={Icons.basic.arrowDown} />}
      </ExtrusionPreview>
    );
  }

  renderExtrusionControls() {
    const { currentDeviceState } = this.props;
    const isReadyToExtrude = DeviceUtils.isReadyToExtrude(currentDeviceState);

    return (
      <ExtrusionControlsWrapper>
        <ToolTipWrapper
          tooltip={isReadyToExtrude ? null : 'Cold extrusion is not supported'}>
          <ExtrusionControls>
            <Button
              expand
              clean
              disabled={!isReadyToExtrude}
              onClick={this.onRetract}
              icon={Icons.fdm.extruderUp}>
              Retract
            </Button>
            <Button
              expand
              clean
              disabled={!isReadyToExtrude}
              onClick={this.onExtrude}
              icon={Icons.fdm.extruderDown}>
              Extrude
            </Button>
          </ExtrusionControls>
        </ToolTipWrapper>
      </ExtrusionControlsWrapper>
    );
  }

  renderCustomXYZStepDistanceField() {
    return (
      <AbstractInput
        type='number'
        label='Step'
        min={0.1}
        gte={true}
        step={0.1}
        units='mm'
        StyledContainer={StyledNumberInputContainer}
        value={this.state.customXYZStepDistance}
        isInvalid={this.state.customXYZStepDistanceError}
        disabled={!this.state.useCustomXYZStepDistance}
        onChangeSuccess={(value) => this.onChangeCustomXYZStepDistance(value)}
        onChangeFailure={(value) =>
          this.onChangeCustomXYZStepDistance(value, true)
        }
      />
    );
  }

  renderCustomExtruderStepDistanceField() {
    return (
      <AbstractInput
        type='number'
        label='Step'
        min={0.1}
        gte={true}
        step={0.1}
        units='mm'
        StyledContainer={StyledNumberInputContainer}
        value={this.state.customExtruderStepDistance}
        isInvalid={this.state.customExtruderStepDistanceError}
        disabled={!this.state.useCustomExtruderStepDistance}
        onChangeSuccess={(value) =>
          this.onChangeCustomExtruderStepDistance(value)
        }
        onChangeFailure={(value) =>
          this.onChangeCustomExtruderStepDistance(value, true)
        }
      />
    );
  }

  renderXYZStepControls() {
    return (
      <StepControls>
        {this.renderCustomXYZStepDistanceField()}
        <BoxSelectionWrapper>
          <BoxSelection
            value={this.state.currentXYZStepDistance}
            options={STEP_CONTROL_OPTIONS}
            maxColumns={2}
            noStacking
            onClick={(value) => this.onSelectXYZStepDistance(value)}
          />
        </BoxSelectionWrapper>
      </StepControls>
    );
  }

  renderExtruderStepControls() {
    return (
      <StepControls>
        {this.renderCustomExtruderStepDistanceField()}
        <BoxSelectionWrapper>
          <BoxSelection
            value={this.state.currentExtruderStepDistance}
            options={STEP_CONTROL_OPTIONS}
            maxColumns={2}
            noStacking
            onClick={(value) => this.onSelectExtruderStepDistance(value)}
          />
        </BoxSelectionWrapper>
      </StepControls>
    );
  }

  renderMotorControls() {
    const { currentDeviceState, pendingRequests = {}, device } = this.props;
    const isMotorOn = DeviceUtils.isMotorOn(currentDeviceState);

    const isActivelyPrinting = DeviceUtils.isActivelyPrinting(
      currentDeviceState,
      device
    );
    const isPrintPaused = DeviceUtils.isPrintPaused(currentDeviceState, device);
    const isPending = pendingRequests.controlMotor;

    return (
      <FanControls>
        {isPending && (
          <SpinnerContainer>
            <Progress circular />
          </SpinnerContainer>
        )}
        <Subtitle2>Motor</Subtitle2>
        <BoxSelectionWrapper>
          <BoxSelection
            maxColumns={2}
            value={isMotorOn}
            noStacking
            options={MOTOR_AND_FAN_OPTIONS}
            disabled={(isActivelyPrinting && !isPrintPaused) || isPending}
            onClick={(value) => this.onToggleMotor(value)}
          />
        </BoxSelectionWrapper>
      </FanControls>
    );
  }

  renderFanControls() {
    const { pendingRequests = {} } = this.props;
    const isPending = pendingRequests.controlFan;
    return (
      <FanControls>
        {isPending && (
          <SpinnerContainer>
            <Progress circular />
          </SpinnerContainer>
        )}
        <Subtitle2>Fan</Subtitle2>
        <FanSpeed>
          <FanSpeedContainer>
            <Body1 noSpacing>Speed</Body1>
            <Slider
              disabled={isPending}
              value={this.state.currentFanSpeed}
              step={10}
              min={0}
              max={100}
              onChange={this.onChangeFanSpeed}
              onChangeCommitted={this.updateFanSpeed}
              StyledSlider={GreenSlider}
              StyledContainer={GreenSliderContainer}
            />
            <Body1 noSpacing grey>{`${this.state.currentFanSpeed}%`}</Body1>
          </FanSpeedContainer>
        </FanSpeed>
      </FanControls>
    );
  }

  renderTools() {
    return (
      <ToolsWrapper>
        <MoveTool>
          <ControlsWrapper>
            {this.renderHorizontalMovementControls()}
            {this.renderVerticalMovementControls()}
          </ControlsWrapper>
          {this.renderXYZStepControls()}
        </MoveTool>

        <ExtrudeTool>
          <ExtrusionPreviewControlContainer>
            {this.renderExtrusionPreview()}
            {this.renderExtrusionControls()}
          </ExtrusionPreviewControlContainer>
          <ExtruderStepControlsWrapper>
            {this.renderExtruderStepControls()}
          </ExtruderStepControlsWrapper>
        </ExtrudeTool>

        <FanTool>
          {this.renderMotorControls()}
          {this.renderFanControls()}
        </FanTool>
      </ToolsWrapper>
    );
  }

  render() {
    const { currentDeviceState } = this.props;
    const isConnected = DeviceUtils.isConnectedToPrinter(currentDeviceState);
    if (!isConnected) {
      return <Placeholder borderless>No printer connected</Placeholder>;
    }

    return <Container>{this.renderTools()}</Container>;
  }
}

export default withTheme(PrinterControls);

import React, { Component } from 'react';
import { withTheme } from 'styled-components';

import Icon from '../icon/icon.jsx';
import { Icons } from '../../themes';
import {
  Container,
  Box,
  InvisibleInput,
  CheckboxLabelWrapper,
  StyledLabel,
} from './checkbox.styles';

class Checkbox extends Component {
  static defaultProps = {
    label: '',
    boldLabel: false, // makes label bold. Useful in forms where checkbox needs to have a group label, instead of a regular label
    value: false,
    onChange: () => {},
    disabled: false,
  };

  renderBox() {
    const { value, disabled } = this.props;
    const checked = !!value;
    const iconSrc = checked
      ? Icons.basic.checkboxChecked
      : Icons.basic.checkboxEmpty;
    let iconColor = this.props.theme.colors.grey4;
    if (!disabled) {
      iconColor = checked
        ? this.props.theme.colors.greenDefault
        : this.props.theme.colors.grey5;
    }

    return (
      <Box checked={checked}>
        <Icon src={iconSrc} color={iconColor} />
        <InvisibleInput
          type='checkbox'
          checked={checked}
          disabled={disabled}
          onChange={(e) => this.props.onChange(e)}
        />
      </Box>
    );
  }

  renderLabel() {
    if (!this.props.label) return null;
    return (
      <CheckboxLabelWrapper>
        <StyledLabel
          boldLabel={this.props.boldLabel}
          disabled={this.props.disabled}
          noSpacing>
          {this.props.label}
        </StyledLabel>
      </CheckboxLabelWrapper>
    );
  }

  render() {
    return (
      <Container disabled={this.props.disabled}>
        {this.renderBox()}
        {this.renderLabel()}
      </Container>
    );
  }
}

export default withTheme(Checkbox);

/* eslint-disable quotes */
const Routes = {
  // Account
  toHome: () => `/`,
  toManageAccounts: () => `/account-settings`,
  toTermsOfService: () => `/terms-of-service`,
  toPrivacyPolicy: () => `/privacy-policy`,

  // PrinterScript
  toPrinterScript: () => `/printerscript`,
  toPrinterScriptDocs: () => `/printerscript/reference`,
  toPrinterScriptPlayground: () => `/printerscript/playground`,
  toPrinterScriptVariables: () => `/printerscript/variables`,
  toPrinterScriptLegacy: () => `/printerscript/legacy`,

  // Authorization
  toUserAuthorization: () => `/authorization`,

  // Projects
  toManageProjects: () => `/projects`,
  toFolder: (folderId = ':projectId') => `/projects/${folderId}`,
  toNewProject: () => `/slicer/new`,
  toSlicer: (projectId = ':projectId') => `/slicer/${projectId}`,
  toImportSharedProject: (shareId = ':shareId') =>
    `/projects/shared/${shareId}`,

  // Printers
  toManagePrinters: () => `/printers`,
  toViewPrinter: (printerId = ':printerId') => `/printers/${printerId}`,
  toImportSharedPrinter: (shareId = ':shareId') =>
    `/printers/shared/${shareId}`,

  // Materials
  toManageMaterials: () => `/materials`,
  toSpliceSettings: () => `/materials/splice-settings`,

  // Devices
  toDeviceActivation: () => `/connect`,
  toManageDevices: () => `/devices`,
  toViewDevice: (deviceId = ':deviceId') => `/devices/${deviceId}`,
};

export default Routes;

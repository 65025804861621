import React, { useCallback, useState } from 'react';
import _ from 'lodash';

import {
  RemapUIContainer,
  InputCountsContainer,
  InputCountContainer,
  ReduceOptionContainer,
  ButtonWrapper,
} from './inputRemapperModal.styles';

import {
  Body1,
  Button,
  Modal,
  ModalFooter,
  RadioList,
  Subtitle1,
} from '../../../shared';

import Routes from '../../../router/routes';
import DeviceConfigOptions from './DeviceConfigOptions/DeviceConfigOptions.jsx';
import InputOptions from './InputOptions/InputOptions.jsx';
import ModalHeader from '../../../shared/modal/modalHeader.jsx';
import ModalBody from '../../../shared/modal/modalBody.jsx';
import { FormatUtils, ProjectUtils } from '../../../utils';

const initialState = {
  deviceConfig: undefined,
  targetInputs: [],
};

const InputRemapperModal = (props) => {
  const [state, setState] = useState(initialState);

  const onCancel = () => {
    const { projectInitialized, history, clearInputRemapperContext } = props;

    clearInputRemapperContext();
    if (!projectInitialized) {
      history.push(Routes.toManageProjects());
    }
  };

  const onSaveInput = () => {
    const { remapperContext, projectInitialized } = props;
    const { targetInputs } = state;
    const reloadRequired = !projectInitialized;

    const formattedInputMap = _.map(targetInputs, (mappedIndex) => {
      if (mappedIndex < 0) return 0;
      return mappedIndex;
    });

    props.remapProjectInputs(
      remapperContext.projectId,
      formattedInputMap,
      reloadRequired
    );
  };

  const onSaveDevice = () => {
    const { remapperContext, projectInitialized } = props;
    const reloadRequired = !projectInitialized;

    const { deviceConfig } = state;

    const updatedProjectSettings = {
      deviceConfig,
    };

    props.updateProjectSettings(
      remapperContext.projectId,
      updatedProjectSettings,
      reloadRequired
    );
  };

  const setDeviceConfig = useCallback((config) => {
    setState((oldState) => ({
      ...oldState,
      deviceConfig: config,
    }));
  }, []);

  const setTargetInputs = useCallback((newTargetInputs) => {
    setState((oldState) => ({
      ...oldState,
      targetInputs: newTargetInputs,
    }));
  }, []);

  const renderHeader = () => {
    const { projectInitialized } = props;
    return (
      <ModalHeader>
        <Subtitle1>
          {projectInitialized
            ? 'Remap project inputs'
            : 'Reduce project inputs'}
        </Subtitle1>
      </ModalHeader>
    );
  };

  const [checkedValue, setCheckedValue] = useState('input');

  const RenderRemapUI = () => {
    const { projectInitialized, remapperContext, printer } = props;
    const { deviceConfig, targetInputs } = state;

    const remapTriggeredManually = !!remapperContext.triggeredManually;
    const options = [
      {
        value: 'input',
        label: 'Reduce number of inputs used',
      },
      {
        value: 'device',
        label: 'Select a different device',
      },
    ];

    const renderRemapDescriptionText = () => {
      return (
        <Body1>
          You can use this interface to remap existing inputs to another input.
          All color data associated with a given existing input will be updated
          to use the color of the target input of your choice.
        </Body1>
      );
    };

    const renderReduceDescriptionText = () => {
      const { existingInputsSeen, availableInputCount } = remapperContext;
      const usedInputCount = existingInputsSeen.reduce(
        (accumulator, ext) => (ext ? accumulator + 1 : accumulator),
        0
      );
      return (
        <>
          <InputCountsContainer>
            <InputCountContainer>
              <Body1 noSpacing>
                Available {FormatUtils.pluralize('input', availableInputCount)}
              </Body1>
              <Subtitle1>{availableInputCount}</Subtitle1>
            </InputCountContainer>
            <InputCountContainer>
              <Body1 noSpacing red>
                Used {FormatUtils.pluralize('input', usedInputCount)}
              </Body1>
              <Subtitle1 red>{usedInputCount}</Subtitle1>
            </InputCountContainer>
          </InputCountsContainer>
          {!remapTriggeredManually && (
            <Body1 noSpacing>
              This project uses more inputs than are available. Please choose
              one of the options below to continue.
            </Body1>
          )}
        </>
      );
    };

    const selectionList = (
      <RadioList
        options={options}
        onChange={setCheckedValue}
        value={checkedValue}
      />
    );

    const usedInputCount = remapperContext.existingInputsSeen.reduce(
      (accumulator, ext) => (ext ? accumulator + 1 : accumulator),
      0
    );

    return (
      <ModalBody>
        <RemapUIContainer>
          {projectInitialized
            ? renderRemapDescriptionText()
            : renderReduceDescriptionText()}
          {!remapTriggeredManually && selectionList}
          <ReduceOptionContainer>
            <DeviceConfigOptions
              remapTriggeredManually={remapTriggeredManually}
              deviceConfig={deviceConfig}
              setDeviceConfig={setDeviceConfig}
              usedInputCount={usedInputCount}
              printer={printer}
              currentlySelected={checkedValue === 'device'}
            />
            <InputOptions
              remapTriggeredManually={remapTriggeredManually}
              remapperContext={remapperContext}
              targetInputs={targetInputs}
              setTargetInputs={setTargetInputs}
              currentlySelected={checkedValue === 'input'}
            />
          </ReduceOptionContainer>
        </RemapUIContainer>
      </ModalBody>
    );
  };

  const renderButtons = () => {
    const { remapperContext } = props;
    const { existingInputsSeen } = remapperContext;

    const remapTriggeredManually = !!remapperContext.triggeredManually;

    let disabled;
    let onClick;

    if (remapTriggeredManually || checkedValue === 'input') {
      const { targetInputs } = state;
      disabled = targetInputs
        .filter((mappedIndex, index) => existingInputsSeen[index])
        .some((mappedIndex) => mappedIndex < 0);
      onClick = onSaveInput;
    } else if (checkedValue === 'device') {
      const { printer } = props;
      const { deviceConfig } = state;

      const currentInputCount = ProjectUtils.getInputCount(
        deviceConfig,
        printer
      );

      const usedInputCount = remapperContext.colors.length;

      disabled = usedInputCount > 8 || currentInputCount < usedInputCount;
      onClick = onSaveDevice;
    }

    return (
      <ModalFooter>
        <ButtonWrapper>
          <Button onClick={onCancel}>Cancel</Button>
          <Button primary disabled={disabled} onClick={onClick}>
            Save
          </Button>
        </ButtonWrapper>
      </ModalFooter>
    );
  };

  return (
    <Modal width='28em'>
      {renderHeader()}
      {RenderRemapUI()}
      {renderButtons()}
    </Modal>
  );
};

export default InputRemapperModal;

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import API from '../canvas-api';

import { handleGenericError, getAuthState } from '../common';
import { actions } from '../../reducers/auth/auth';

export default function* linkIntegration(action) {
  try {
    const { userId } = yield select(getAuthState);
    const { integrationId } = action.payload;

    const response = yield call(API, {
      path: `users/${userId}/integrations/${integrationId}/authorize`,
    });
    if (response === null) return;

    yield put(actions.linkIntegrationSuccess());
    window.location.href = response.url;
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

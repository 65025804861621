import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../canvas-api';

import { handleGenericError } from '../common';
import { actions } from '../../reducers/auth/auth';

export default function* authorizeIntegration(action) {
  try {
    const { clientId, redirectUri, responseType, state, userAuthorization } =
      action.payload;
    const body = {
      client_id: clientId,
      redirect_uri: redirectUri,
      response_type: responseType,
      userAuthorization,
    };
    if (state) body.state = state;

    const response = yield call(API, {
      method: methods.POST,
      path: 'users/oauth/token/authorize',
      body,
    });
    if (response === null) return;

    yield put(actions.authorizeIntegrationSuccess());
    window.location.href = response.url;
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

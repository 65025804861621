import _ from 'lodash';

import tooltips from './tooltips.jsx';
import Routes from '../../../router/routes';
import { Firmware } from '../../../utils/profile-import/enums';
import { fieldSteps, fieldTypes, paletteConstants } from '../../../constants';

const { EXTRUSION_WIDTH_STEP } = fieldSteps;

export const originOffsetPresets = {
  custom: 0,
  bottomleft: 1,
  middle: 2,
};

export const GeneralFields = {
  general: {
    fields: [
      {
        title: 'Printer profile name',
        description: 'Give the printer a name.',
        name: 'name',
        required: true,
        type: fieldTypes.text,
      },
      {
        title: 'Printer model',
        description: 'Choose the printer model (optional).',
        name: 'tags',
        type: 'tags',
      },

      {
        title: 'Print file type',
        description:
          'Select the file extension that the printer uses for print files.',
        name: 'extension',
        tooltip: tooltips.extension,
        type: fieldTypes.dropdown,
        options: _.filter(
          [
            { label: 'gcode', value: 'gcode' },
            { label: 'gco', value: 'gco' },
            { label: 'g3drem (Dremel)', value: 'g3drem' },
            { label: 'g', value: 'g' },
            { label: 'gx', value: 'gx' },
            { label: 'hvs (Hyvision)', value: 'hvs' },
            { label: 'makerbot (5th Gen)', value: 'makerbot' },
            process.env.REACT_APP_GEN_3_HW === 'true'
              ? { label: 'mcfx (Mosaic)', value: 'mcfx' }
              : null,
            { label: 'x3g', value: 'x3g' },
          ],
          _.identity
        ),
      },
      {
        title: 'Include comments in print files',
        description:
          'Comments provide useful diagnostic information and markers for postprocessing scripts, but increase file size and can slow down parsing on some printer hardware/firmware. Note that disabling this setting will not remove comments from your custom sequences. If turned off, comments from your custom sequences will not be affected.',
        label: 'Enabled',
        name: 'addComments',
        tooltip: tooltips.addComments,
        type: fieldTypes.checkbox,
        display: (props) =>
          props.extension !== 'makerbot' &&
          props.extension !== 'mcfx' &&
          props.extension !== 'x3g',
      },
      {
        title: 'Printer icon',
        description: 'Choose the best icon for your favourite printer.',
        stackedFormItem: true,
        name: 'icon',
        required: true,
        type: fieldTypes.icon,
        options: _.filter(
          [
            'default/printers/1',
            'default/printers/2',
            'default/printers/3',
            'default/printers/4',
            'default/printers/5',
            'default/printers/6',
            'default/printers/7',
            'default/printers/8',
            'default/printers/9',
            'default/printers/10',
            'default/printers/11',
            'default/printers/12',
            'default/printers/13',
            'default/printers/14',
            'default/printers/15',
            'default/printers/16',
            'default/printers/17',
            'default/printers/18',
            'default/printers/19',
            process.env.REACT_APP_GEN_3_HW === 'true'
              ? 'default/printers/Element'
              : null,
            process.env.REACT_APP_GEN_3_HW === 'true'
              ? 'default/printers/ElementHT'
              : null,
            process.env.REACT_APP_GEN_3_HW === 'true'
              ? 'default/printers/ElementArray'
              : null,
          ],
          _.identity
        ),
      },
    ],
  },
};

export const ExtruderFields = {
  extruderCount: {
    fields: [
      {
        title: 'Extruder count',
        description: 'Total number of extruders on the printer.',
        name: 'extruderCount',
        type: fieldTypes.dropdown,
        options: new Array(paletteConstants.DEFAULT_INPUT_COUNT)
          .fill(0)
          .map((val, index) => ({
            label: (index + 1).toString(),
            value: index + 1,
          })),
      },
    ],
  },
  nozzleDiameter: {
    subformItem: true,
    name: 'nozzleDiameter',
    fields: new Array(paletteConstants.DEFAULT_INPUT_COUNT)
      .fill(0)
      .map((val, index) => ({
        name: `nozzleDiameter[${index}]`,
        subformTitle: `Nozzle diameter`,
        subformDescription: 'Enter nozzle diameter',
        value: (props) => props.nozzleDiameter[index],
        required: false,
        display: (props) => props.nozzleDiameter.length > index,
        type: fieldTypes.number,
        units: 'mm',
        custom: {
          min: 0,
          gte: false,
          step: EXTRUSION_WIDTH_STEP,
        },
      })),
  },
  filamentDiameter: {
    subformItem: true,
    name: 'filamentDiameter',
    fields: new Array(paletteConstants.DEFAULT_INPUT_COUNT)
      .fill(0)
      .map((val, index) => ({
        name: `filamentDiameter[${index}]`,
        subformTitle: `Filament diameter`,
        subformDescription: 'Enter filament diameter',
        value: (props) => props.filamentDiameter[index],
        required: false,
        display: (props) => props.filamentDiameter.length > index,
        type: fieldTypes.dropdown,
        options: [
          { label: '1.75 mm', value: 1.75 },
          { label: '2.85 mm', value: 2.85 },
        ],
      })),
  },
  chromaSettings: {
    fields: [
      {
        title: 'Extruder for Palette printing',
        description:
          'Select the extruder that Palette is connected to. Currently, the other extruder(s) cannot be used together while a Palette is being used.',
        name: 'chromaExtruder',
        tooltip: tooltips.chromaExtruder,
        type: fieldTypes.dropdown,
        display: (props) => props.extruderCount > 1,
        options: (props) =>
          Array(props.extruderCount)
            .fill(0)
            .map((unusedValue, index) => ({
              label: (index + 1).toString(),
              value: index,
            })),
      },
    ],
  },
  bowdenTube: {
    fields: [
      {
        title: 'Bowden tube length',
        description:
          'The last piece of filament that Palette creates will be extended by this amount. This ensures that the filament is still gripped by the printer’s drive gear at the end of the print. For printers with Bowden tubes or long extruders, this should be the distance from your printer’s drive gear to the nozzle tip. If you are unsure of the exact measurement, overestimating is better.',
        name: 'bowdenTubeLength',
        tooltip: tooltips.bowdenTubeLength,
        type: fieldTypes.number,
        units: 'mm',
        custom: {
          min: 0,
        },
      },
    ],
  },
};

export const BedFields = {
  bedShape: {
    fields: [
      {
        title: 'Bed shape',
        description: 'Choose the shape of the printer bed.',
        name: 'circular',
        type: fieldTypes.dropdown,
        options: [
          { label: 'Rectangular', value: false },
          { label: 'Circular', value: true },
        ],
      },
    ],
  },
  bedSize: {
    subformItem: true,
    fields: [
      {
        subformTitle: 'Bed size - X',
        subformDescription: 'Enter the bed size.',
        name: 'bedSize.x',
        type: fieldTypes.number,
        units: 'mm',
        value: (props) => props.bedSize.x,
        display: (props) => !props.circular,
        custom: {
          min: 0,
          gte: false,
        },
      },
      {
        subformTitle: 'Bed size - Y',
        subformDescription: 'Enter the bed size.',
        name: 'bedSize.y',
        type: fieldTypes.number,
        units: 'mm',
        value: (props) => props.bedSize.y,
        display: (props) => !props.circular,
        custom: {
          min: 0,
          gte: false,
        },
      },
      {
        subformTitle: 'Bed diameter',
        subformDescription: 'Enter bed diameter.',
        name: 'bedSize.diameter',
        type: fieldTypes.number,
        units: 'mm',
        value: (props) => props.bedSize.x,
        display: (props) => props.circular,
        custom: { min: 0, gte: false },
      },
      {
        subformTitle: 'Bed size - Z',
        subformDescription: 'Enter the bed size.',
        name: 'bedSize.z',
        type: fieldTypes.number,
        units: 'mm',
        value: (props) => props.bedSize.z,
        custom: {
          min: 0,
          gte: false,
        },
      },
    ],
  },
  originOffsetPresets: {
    fields: [
      {
        title: 'Origin offset',
        description:
          'These offsets determine where the origin of the bed plate is located. You can specify the location of your printer’s origin (0,0). Measured in mm from the bottom-left corner.',
        name: 'originOffsetPreset',
        tooltip: tooltips.originOffset,
        type: fieldTypes.dropdown,
        options: [
          {
            label: 'Bottom-Left',
            value: originOffsetPresets.bottomleft,
          },
          {
            label: 'Middle',
            value: originOffsetPresets.middle,
          },
          {
            label: 'Custom',
            value: originOffsetPresets.custom,
          },
        ],
      },
    ],
  },
  originOffset: {
    subformItem: true,
    fields: [
      {
        subformTitle: 'Offset - X',
        subformDescription: 'Enter the offset.',
        name: 'originOffset.x',
        type: fieldTypes.number,
        disabled: (state) =>
          state.originOffsetPreset !== originOffsetPresets.custom,
        value: (props) => props.originOffset.x,
        units: 'mm',
        custom: {},
      },
      {
        subformTitle: 'Offset - Y',
        subformDescription: 'Enter the offset.',
        name: 'originOffset.y',
        type: fieldTypes.number,
        disabled: (state) =>
          state.originOffsetPreset !== originOffsetPresets.custom,
        value: (props) => props.originOffset.y,
        units: 'mm',
        custom: {},
      },
    ],
  },
};

export const FirmwareFields = {
  firmware: {
    fields: [
      {
        title: 'Firmware type',
        description: 'Choose the type of firmware the printer is using.',
        name: 'firmwareType',
        type: fieldTypes.dropdown,
        numeric: true,
        display: (props) =>
          props.extension !== 'g3drem' &&
          props.extension !== 'g' &&
          props.extension !== 'gx' &&
          props.extension !== 'makerbot' &&
          props.extension !== 'mcfx' &&
          props.extension !== 'x3g',
        options: [
          { label: '5D Absolute', value: Firmware.FIRMWARE_5D_ABS },
          { label: '5D Relative', value: Firmware.FIRMWARE_5D_REL },
          { label: 'FlashForge', value: Firmware.FIRMWARE_FLASHFORGE },
          { label: 'Ultimaker Griffin', value: Firmware.FIRMWARE_GRIFFIN },
        ],
      },
      {
        title: 'Filament purge',
        description:
          'Some printers have start sequences that are performed independent of G-code supplied. If these sequences include any extrusion, total that amount and input it here. This setting will account for this filament usage in Palette’s color calibration.',
        name: 'firmwarePurge',
        tooltip: tooltips.firmwarePurge,
        type: fieldTypes.number,
        display: (props) => props.extension !== 'mcfx',
        units: 'mm',
        custom: {
          min: 0,
        },
      },
      {
        title: 'Use firmware retraction',
        description:
          'Some printers allow retraction to be configured via the firmware and initiated by a special G-code command. If you would like to use G10/G11 commands for retraction instead of G1 commands, enable this setting.',
        label: 'Enabled',
        name: 'firmwareRetraction',
        tooltip: tooltips.firmwareRetraction,
        type: fieldTypes.checkbox,
        display: (props) =>
          props.extension !== 'makerbot' &&
          props.extension !== 'mcfx' &&
          props.extension !== 'x3g',
      },
      {
        title: 'Use movement pauses for pinging',
        description:
          'In accessory made, Palette calibrates with the printer by pausing occasionally on the transition tower. However, some printers are unable to pause for the required length of time. Enabling movement pauses will move the extruder back and forth instead of pausing.',
        label: 'Enabled',
        name: 'jogPauses',
        tooltip: tooltips.jogPauses,
        type: fieldTypes.checkbox,
        display: (props) =>
          props.extension !== 'makerbot' && props.extension !== 'mcfx',
      },
      {
        title: 'Clear buffer command',
        description:
          'During connected mode pings, Palette uses this G-code command to accurately synchronize itself with the printer without pausing the print. Most firmware supports G4 P0, but certain firmwares require M400 to be used instead in order to avoid a pause.',
        name: 'clearBufferCommand',
        tooltip: tooltips.clearBufferCommand,
        type: fieldTypes.dropdown,
        options: [
          { value: 0, label: 'G4 P0' },
          { value: 1, label: 'M400' },
        ],
      },
    ],
  },
  gpx: {
    fields: [
      {
        title: 'GPX profile',
        description: 'Choose the profile that will be used for X3G generation',
        name: 'gpxProfile',
        tooltip: tooltips.gpxProfile,
        display: (props) => props.extension === 'x3g',
        type: fieldTypes.dropdown,
        options: [
          { label: 'Replicator 1 Single (r1)', value: 'r1' },
          { label: 'Replicator 1 Single with heated bed (cr1)', value: 'cr1' },
          { label: 'Replicator 1 Dual (r1d)', value: 'r1d' },
          { label: 'Replicator 1 Dual with heated bed (cr1d)', value: 'cr1d' },
          { label: 'Replicator 2 (r2)', value: 'r2' },
          { label: 'Replicator 2 with heated bed (r2h)', value: 'r2h' },
          { label: 'Replicator 2X (r2x)', value: 'r2x' },
          { label: 'FlashForge Creator Pro (fcp)', value: 'fcp' },
          { label: 'ZYYX Single (z)', value: 'z' },
          { label: 'ZYYX Dual (zd)', value: 'zd' },
        ],
      },
      {
        title: 'A-steps',
        description:
          ' Input the steps/mm for extruder A. This is used during X3G generation. Setting this to 0 will use the default specified by the GPX profile.',
        name: 'aStepsPerMM',
        tooltip: tooltips.aStepsPerMM,
        display: (props) => props.extension === 'x3g',
        type: fieldTypes.number,
        units: 'steps/mm',
        custom: {
          min: 0,
          gte: true,
        },
      },
      {
        title: 'B-steps',
        description:
          ' Input the steps/mm for extruder B. This is used during X3G generation. Setting this to 0 will use the default specified by the GPX profile.',
        name: 'bStepsPerMM',
        tooltip: tooltips.bStepsPerMM,
        display: (props) =>
          props.extension === 'x3g' && props.extruderCount > 1,
        type: fieldTypes.number,
        units: 'steps/mm',
        custom: {
          min: 0,
          gte: true,
        },
      },
    ],
  },
};

export const TransitionFields = {
  sideTransitionSettings: {
    label: 'Side transition type',
    fields: [
      {
        title: 'Side transition type',
        description:
          'When using side transitions, your printer can either be set to extrude along an edge of the bed or in one specific place. For printers that extrude too quickly while not moving, select ‘Along Edge’.',
        name: 'sideTransitionPurgeInPlace',
        tooltip: tooltips.sideTransitionPurgeInPlace,
        type: fieldTypes.dropdown,
        options: [
          {
            label: 'Along edge',
            value: false,
          },
          {
            label: 'In place',
            value: true,
          },
        ],
      },
    ],
  },
  sideTransitionCoordinates: {
    subformItem: true,
    fields: [
      {
        subformTitle: 'Side transition X',
        subformDescription: 'Enter X coordinates',
        name: 'sideTransitionCoordinates.x',
        value: (props) => props.sideTransitionCoordinates.x,
        display: (props) => props.sideTransitionPurgeInPlace,
        type: fieldTypes.number,
        units: 'mm',
        custom: {},
      },
      {
        subformTitle: 'Side transition Y',
        subformDescription: 'Enter Y coordinates',
        name: 'sideTransitionCoordinates.y',
        value: (props) => props.sideTransitionCoordinates.y,
        display: (props) => props.sideTransitionPurgeInPlace,
        type: fieldTypes.number,
        units: 'mm',
        custom: {},
      },
    ],
  },
  sideTransitionEdge: {
    subformItem: true,
    fields: [
      {
        subformTitle: 'Edge side',
        subformDescription: 'Enter the edge side.',
        name: 'sideTransitionEdge',
        tooltip: tooltips.sideTransitionEdge,
        display: (props) => !props.sideTransitionPurgeInPlace,
        type: fieldTypes.dropdown,
        numeric: true,
        options: [
          { label: 'North', value: 0 },
          { label: 'East', value: 3 },
          { label: 'South', value: 1 },
          { label: 'West', value: 2 },
        ],
      },
      {
        subformTitle: 'Edge offset',
        subformDescription: 'Enter the edge offset.',
        name: 'sideTransitionEdgeOffset',
        tooltip: tooltips.sideTransitionEdgeOffset,
        display: (props) => !props.sideTransitionPurgeInPlace,
        type: fieldTypes.number,
        units: 'mm',
        custom: {},
      },
    ],
  },
  pauseDuringPings: {
    fields: [
      {
        title: 'Pause off of the transition tower during pings',
        description:
          'When enabled, the printer will move away from the tower during ping pauses. This setting only affects behavior in accessory mode. Consider enabling this setting if your printer oozes significantly during ping pauses.',
        label: 'Enabled',
        name: 'pingOffTower',
        tooltip: tooltips.pingOffTower,
        type: fieldTypes.checkbox,
      },
    ],
  },
};

const modes = {
  gcode: 'gcode',
  printerscript: 'printerscript',
};

export const SequenceFields = {
  fields: [
    {
      label: 'Start of print',
      name: 'startSequence',
      type: fieldTypes.codearea,
      modes: [modes.gcode, modes.printerscript],
      helpLink: (mode) =>
        `${
          mode === modes.gcode
            ? Routes.toPrinterScriptLegacy()
            : Routes.toPrinterScriptVariables()
        }#start-of-print`,
    },
    {
      label: 'End of print',
      name: 'endSequence',
      type: fieldTypes.codearea,
      modes: [modes.gcode, modes.printerscript],
      helpLink: (mode) =>
        `${
          mode === modes.gcode
            ? Routes.toPrinterScriptLegacy()
            : Routes.toPrinterScriptVariables()
        }#end-of-print`,
    },
    {
      label: 'Layer change',
      name: 'layerChangeSequence',
      type: fieldTypes.codearea,
      modes: [modes.gcode, modes.printerscript],
      helpLink: (mode) =>
        `${
          mode === modes.gcode
            ? Routes.toPrinterScriptLegacy()
            : Routes.toPrinterScriptVariables()
        }#layer-change`,
    },
    {
      label: 'Before side transitioning',
      name: 'preSideTransitionSequence',
      type: fieldTypes.codearea,
      modes: [modes.gcode, modes.printerscript],
      helpLink: (mode) =>
        `${
          mode === modes.gcode
            ? Routes.toPrinterScriptLegacy()
            : Routes.toPrinterScriptVariables()
        }#before-side-transitioning`,
    },
    {
      label: 'Side transition',
      name: 'sideTransitionSequence',
      type: fieldTypes.codearea,
      modes: [modes.printerscript],
      helpLink: () => `${Routes.toPrinterScriptVariables()}#side-transition`,
    },
    {
      label: 'After side transitioning',
      name: 'postSideTransitionSequence',
      type: fieldTypes.codearea,
      modes: [modes.gcode, modes.printerscript],
      helpLink: (mode) =>
        `${
          mode === modes.gcode
            ? Routes.toPrinterScriptLegacy()
            : Routes.toPrinterScriptVariables()
        }#after-side-transitioning`,
    },
  ],
};

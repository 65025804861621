import 'regenerator-runtime/runtime';
import { call, select } from 'redux-saga/effects';

import { getThreeState, handleGenericError } from '../common';
import { setRenderFlag } from './animationFrame';

export default function* removeModelFromScene(action) {
  try {
    const { scene } = yield select(getThreeState);
    if (!scene) return;
    const { model } = action.payload;
    if (model.mesh) {
      scene.remove(model.mesh);
      setRenderFlag();
    }
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import 'regenerator-runtime/runtime';
import { put, select } from 'redux-saga/effects';

import { getSlicerState } from '../../common';
import { types, actions } from '../../../reducers/slicer/slicer';
import { types as THREEtypes } from '../../../reducers/three/three';

export default function* setToolpathOptions(action) {
  const { toolpath } = yield select(getSlicerState);

  switch (action.type) {
    case types.SET_TOOLPATH_VIEW_TYPE: {
      const { viewType } = action.payload;
      // load new toolpath color
      let bufferName;
      switch (viewType) {
        case THREEtypes.TOOLPATH_BY_TOOL:
          bufferName = 'toolColor';
          break;
        case THREEtypes.TOOLPATH_BY_PATH_TYPE:
          bufferName = 'pathTypeColor';
          break;
        case THREEtypes.TOOLPATH_BY_SPEED:
          bufferName = 'feedrateColor';
          break;
        case THREEtypes.TOOLPATH_BY_FAN:
          bufferName = 'fanSpeedColor';
          break;
        case THREEtypes.TOOLPATH_BY_TEMPERATURE:
          bufferName = 'temperatureColor';
          break;
        case THREEtypes.TOOLPATH_BY_LAYER_HEIGHT:
          bufferName = 'layerHeightColor';
          break;
        default:
          return;
      }
      yield put(actions.loadToolpathPtpRequest(viewType, bufferName, true));
      break;
    }

    case types.SET_TOOLPATH_SHOW_TRAVEL: {
      const { showTravel } = action.payload;
      const { viewType } = toolpath;
      if (showTravel && !toolpath.ptp.buffers.travelPosition) {
        yield put(actions.loadToolpathPtpRequest(viewType, 'travelPosition'));
      }
      break;
    }

    case types.SET_TOOLPATH_SHOW_RETRACTS: {
      const { showRetracts } = action.payload;
      const { viewType } = toolpath;
      if (showRetracts && !toolpath.ptp.buffers.retractPosition) {
        yield put(actions.loadToolpathPtpRequest(viewType, 'retractPosition'));
      }
      break;
    }

    case types.SET_TOOLPATH_SHOW_RESTARTS: {
      const { showRestarts } = action.payload;
      const { viewType } = toolpath;
      if (showRestarts && !toolpath.ptp.buffers.restartPosition) {
        yield put(actions.loadToolpathPtpRequest(viewType, 'restartPosition'));
      }
      break;
    }

    default:
      break;
  }
}

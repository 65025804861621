import { eventChannel } from 'redux-saga';

import IotWorker from '../../utils/iot-worker/iot.worker';
import workerTypes from '../../utils/iot-worker/types';
import workerActions from '../../utils/iot-worker/actions';
import logger from './logger';
import { MonitoringUtils } from '../../utils';

const NO_ACTIVE_WORKER = 'No active worker';

let iotWorker = null;

export const createWorker = () => {
  logger.dev('createWorker');
  iotWorker = new IotWorker();
  iotWorker.onerror = (e) => {
    logger.error(e.message);
    MonitoringUtils.sentryManualReport(e.message);
  };
};

export const terminateWorker = () => {
  if (iotWorker) {
    logger.dev('terminateWorker');
    iotWorker.terminate();
    iotWorker = null;
  }
};

const waitForResponse = async (type) =>
  new Promise((fulfill) => {
    const handler = (message) => {
      if (message.data.type === type) {
        if (iotWorker) {
          iotWorker.removeEventListener('message', handler);
        }
        fulfill(message.data.payload);
      }
    };
    iotWorker.addEventListener('message', handler);
  });

const waitForHorizonResponse = async (requestId) =>
  new Promise((fulfill) => {
    const handler = (message) => {
      if (
        message.data.type === workerTypes.HORIZON_RESPONSE &&
        message.data.payload.requestId === requestId
      ) {
        if (iotWorker) {
          iotWorker.removeEventListener('message', handler);
        }
        fulfill(message.data.payload);
      }
    };
    iotWorker.addEventListener('message', handler);
  });

export const initClient = async (
  endpoint,
  clientId,
  region,
  identityId,
  token
) => {
  if (!iotWorker) {
    throw new Error(NO_ACTIVE_WORKER);
  }
  const promise = waitForResponse(workerTypes.INIT_CLIENT_RESPONSE); // NO AWAIT
  iotWorker.postMessage(
    workerActions.initClientRequest(
      endpoint,
      clientId,
      region,
      identityId,
      token
    )
  );
  const response = await promise;
  if (response === null) return;
  if (response.error) {
    throw new Error(response.error);
  }
};

export const updateCredentials = async (region, identityId, token) => {
  if (!iotWorker) {
    throw new Error(NO_ACTIVE_WORKER);
  }
  const promise = waitForResponse(workerTypes.UPDATE_CREDENTIALS_RESPONSE); // NO AWAIT
  iotWorker.postMessage(
    workerActions.updateCredentialsRequest(region, identityId, token)
  );
  const response = await promise;
  if (response === null) return;
  if (response.error) {
    throw new Error(response.error);
  }
};

export const subscribe = async (topicFilters) => {
  if (!iotWorker) {
    throw new Error(NO_ACTIVE_WORKER);
  }
  const promise = waitForResponse(workerTypes.SUBSCRIBE_RESPONSE); // NO AWAIT
  iotWorker.postMessage(workerActions.subscribeRequest(topicFilters));
  const response = await promise;
  if (response === null) return;
  if (response.error) {
    throw new Error(response.error);
  }
};

export const unsubscribe = async (topicFilters) => {
  if (!iotWorker) {
    throw new Error(NO_ACTIVE_WORKER);
  }
  const promise = waitForResponse(workerTypes.UNSUBSCRIBE_RESPONSE); // NO AWAIT
  iotWorker.postMessage(workerActions.unsubscribeRequest(topicFilters));
  const response = await promise;
  if (response === null) return;
  if (response.error) {
    throw new Error(response.error);
  }
};

// counter used internally to distinguish response messages from worker
let horizonRequestId = 0;

export const horizonRequest = async (deviceTopicRoot, path, payload) => {
  if (!iotWorker) {
    throw new Error(NO_ACTIVE_WORKER);
  }
  const requestId = horizonRequestId;
  horizonRequestId++;
  const promise = waitForHorizonResponse(requestId); // NO AWAIT
  iotWorker.postMessage(
    workerActions.horizonRequest(requestId, deviceTopicRoot, path, payload)
  );
  const horizonResponse = await promise;
  if (horizonResponse === null) return null;
  if (horizonResponse.error) {
    throw new Error(horizonResponse.error);
  } else {
    return horizonResponse.response;
  }
};

export const getChannel = () =>
  eventChannel((emit) => {
    if (!iotWorker) {
      throw new Error(NO_ACTIVE_WORKER);
    }
    const messageListener = (message) => {
      const { type } = message.data;
      switch (type) {
        case workerTypes.STATUS:
        case workerTypes.REINITIALIZE:
        case workerTypes.REFRESH:
        case workerTypes.MESSAGE:
          emit(message.data);
          break;
        default:
          break;
      }
    };
    iotWorker.addEventListener('message', messageListener);
    return () => {
      if (iotWorker) {
        iotWorker.removeEventListener('message', messageListener);
      }
    };
  });

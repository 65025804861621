import { ValidateUtils } from '../../utils';
import { fieldTypes } from '../../constants';

/*
 * USERNAME POLICY:
 * - must be at least 5 characters long
 * - must be unique (not checked here)
 * - may only contain letters, numbers, and . - _
 */

/*
 * PASSWORD POLICY:
 * - must be at least 8 characters long
 * - must not contain backslashes
 * - must not contain username
 * - must not be 'password'
 * - must contain at least two of:
 *   - uppercase letters
 *   - lowercase letters
 *   - digits
 *   - special characters/symbols
 */

const Fields = {
  login: {
    email: {
      label: 'Username or email',
      required: true,
      type: fieldTypes.text,
      autoCapitalize: false,
      validator: () => true,
    },
    password: {
      label: 'Password',
      required: true,
      type: fieldTypes.password,
      validator: () => true,
    },
  },
  register: {
    email: {
      label: 'Email',
      required: true,
      type: fieldTypes.email,
    },
    username: {
      label: 'Username',
      required: true,
      type: fieldTypes.text,
      autoCapitalize: false,
      validator: (username) => ValidateUtils.isUsernameWithReason(username),
    },
    password: {
      label: 'Password',
      required: true,
      type: fieldTypes.password,
      validator: (password) => ValidateUtils.isPasswordWithReason(password),
    },
    confirmPassword: {
      label: 'Confirm Password',
      required: true,
      type: fieldTypes.password,
      validator: (password, state) =>
        ValidateUtils.confirmPasswordWithReason(
          password,
          state.values.password
        ),
    },
  },
  forgotPassword: {
    email: {
      label: 'Username or email',
      required: true,
      type: fieldTypes.text,
      autoCapitalize: false,
      validator: () => true,
    },
  },
  resetPassword: {
    email: {
      label: 'Username or email',
      required: true,
      type: fieldTypes.text,
      autoCapitalize: false,
      validator: () => true,
    },
    resetCode: {
      label: 'Reset Code',
      required: true,
      type: fieldTypes.text,
      validator: () => true,
    },
    password: {
      label: 'New Password',
      required: true,
      type: fieldTypes.password,
      validator: (password) => ValidateUtils.isPasswordWithReason(password),
    },
    confirmPassword: {
      label: 'Confirm Password',
      required: true,
      type: fieldTypes.password,
      validator: (password, state) =>
        ValidateUtils.confirmPasswordWithReason(
          password,
          state.values.password
        ),
    },
  },
};

if (process.env.REACT_APP_SIGNUP_KEY_ENABLED === 'TRUE') {
  Fields.register.betaToken = {
    label: 'Signup Key',
    validator: (token) => {
      if (typeof token !== 'string') return false;
      if (token.trim() !== token) return false;
      if (token.length < 5) return false;
      return !/[^A-Za-z0-9-._]/.test(token);
    },
    required: true,
    type: fieldTypes.text,
    autoCapitalize: false,
  };
}

export default Fields;

import styled from 'styled-components';

import { Breakpoints } from '../../../../themes';

export const Content = styled.div`
  display: grid;
  grid-gap: 1rem;
  @media (max-width: ${Breakpoints.smallWidth}) {
    margin-top: 0;
  }
  overflow-y: auto;
`;

export const MaterialSelection = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.75rem;
  @media (max-width: ${Breakpoints.mediumWidth}) {
    margin-top: 0;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    justify-content: center;
  }
`;

export const SwapIconWrapper = styled.div`
  margin: 0 0.75rem;
  align-items: center;
  justify-content: center;
  @media (max-width: ${Breakpoints.mediumWidth}) {
    margin: 0 auto;
  }
`;

export const DropdownContainer = styled.div`
  grid-column: span 1;

  @media (max-width: ${Breakpoints.mediumWidth}) {
    span {
      max-width: calc(100vw - 12rem);
    }
  }
`;

export const MaterialContent = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grey3};
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${(props) =>
    props.theme.colors.elevatedBackgroundSecondary};
  padding: 1rem;
  display: grid;
  grid-gap: 0.75rem;
  @media (max-width: ${Breakpoints.mediumWidth}) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, min-content);
  }
`;

export const InfoHighlightWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${(props) =>
    props.theme.colors.elevatedBackgroundSecondary};
`;

export const InfoContainer = styled.div`
  display: grid;
`;

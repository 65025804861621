import 'regenerator-runtime/runtime';
import { put } from 'redux-saga/effects';

import { actions as iotActions } from '../../reducers/iot/iot';

export default function* forgetDeviceFiles(action) {
  const { device } = action.payload;

  if (device) {
    yield put(iotActions.clearDeviceStorageDrives(device.id));
    yield put(iotActions.clearDeviceFilesContext(device.id));
  }
}

import { connect } from 'react-redux';

import { actions as slicerActions } from '../../../reducers/slicer/slicer';

import ImportProject from './importProject.jsx';

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.refreshToken,
  loginPending: state.auth.status.loginPending,
  loginSuccess: state.auth.status.loginSuccess,
  models: state.slicer.models,
  currentSharedProject: state.slicer.currentSharedProject,
  printers: state.printers.printers,
  devices: state.iot.devices,
  getDevicesPending: state.iot.status.getDevicesPending,
  getDevicesSuccess: state.iot.status.getDevicesSuccess,
  getPrintersPending: state.printers.status.getPrintersPending,
  getPrintersSuccess: state.printers.status.getPrintersSuccess,
  getSharedProjectPending: state.slicer.status.getSharedProjectPending,
  getSharedProjectSuccess: state.slicer.status.getSharedProjectSuccess,
  importSharedProjectPending: state.slicer.status.importSharedProjectPending,
  importSharedProjectSuccess: state.slicer.status.importSharedProjectSuccess,
  getSharedProjectThumbnailSuccess:
    state.slicer.status.getSharedProjectThumbnailSuccess,
  getSharedProjectModelsSuccess:
    state.slicer.status.getSharedProjectModelsSuccess,
  currentProject: state.slicer.currentProject,
  getAccountSuccess: state.auth.status.getAccountSuccess,
  newProjectOptions: state.auth.newProjectOptions,
});

const mapDispatchToProps = (dispatch) => ({
  getSharedProject: (shareId) =>
    dispatch(slicerActions.getSharedProjectRequest(shareId)),
  importSharedProject: (sharedProject, importOptions) =>
    dispatch(
      slicerActions.importSharedProjectRequest(sharedProject, importOptions)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportProject);

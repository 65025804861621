import React from 'react';

import ConfirmationModal from '../confirmationModal/confirmationModal.jsx';

const RedirectToPrinterModal = ({
  onCancel = () => {},
  onConfirm = () => {},
}) => (
  <ConfirmationModal
    primaryLabel='Cannot create new project'
    secondaryLabel='Please add a printer profile and style first.'
    tertiaryLabel='You will not be able to create a new project until
      you have set up a printer profile and printer style.'
    confirmLabel='Add Printer'
    onClickCancel={() => onCancel()}
    onClickConfirm={() => onConfirm()}
  />
);

export default RedirectToPrinterModal;

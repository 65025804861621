import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../canvas-api';
import { handleGenericError } from '../common';
import { actions } from '../../reducers/auth/auth';
import { ValidateUtils } from '../../utils';

export default function* resetPassword(action) {
  try {
    const { email, resetCode, password } = action.payload;
    const emailFieldName = ValidateUtils.isEmail(email) ? 'email' : 'username';
    const body = {
      [emailFieldName]: email,
      resetCode,
      password,
    };
    yield call(API, {
      method: methods.POST,
      path: 'users/reset-password',
      token: null,
      body,
    });

    yield put(actions.loginRequest(email, password));
    yield put(actions.resetPasswordSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import React, { useMemo, useState } from 'react';

import {
  Container,
  ContentWrapper,
  PlaceholderTextWrapper,
  PlaceholderIconWrapper,
  PrintSummary,
  PrintControlButtons,
  PrintName,
  PrintProgress,
  TimeElapsedBar,
  TimeTotalBar,
  SummaryItem,
} from './print.styles';

import {
  Button,
  Icon,
  Subtitle2,
  Caption,
  Body1,
  Badge,
  Modal,
  ModalHeader,
  ModalFooter,
  Subtitle1,
  Link,
} from '../../../../shared';

import { DeviceUtils, FormatUtils } from '../../../../utils';

import { SectionPlaceholder } from '../viewDevice.styles';
import { Links } from '../../../../themes';

const PrintSection = ({
  device,
  currentDeviceState,
  pendingRequests,
  connected,
  onPausePrint,
  onResumePrint,
  onCancelPrint,
}) => {
  const [showErrorDetailsModal, setShowErrorDetailsModal] = useState(false);

  const onErrorDetailsClick = () => {
    setShowErrorDetailsModal(true);
  };

  const isTitaniumOnline = connected;
  const isDeviceOnline = DeviceUtils.isOnline(currentDeviceState);
  const isConnectedToPrinter =
    DeviceUtils.isConnectedToPrinter(currentDeviceState);

  const printingStates = useMemo(() => {
    const isPrinting = DeviceUtils.isPrinting(currentDeviceState, device);
    const isPrintPausing = DeviceUtils.isPrintPausing(
      currentDeviceState,
      device
    );
    const isPrintPaused = DeviceUtils.isPrintPaused(currentDeviceState, device);
    const isPrintCancelling = DeviceUtils.isPrintCancelling(
      currentDeviceState,
      device
    );
    const isPrintCancelled = DeviceUtils.isPrintCancelled(
      currentDeviceState,
      device
    );
    const isPrintCompleted = DeviceUtils.isPrintCompleted(
      currentDeviceState,
      device
    );
    const isPrintFailed = DeviceUtils.isPrintFailed(currentDeviceState, device);
    return {
      isPrinting,
      isPrintPausing,
      isPrintPaused,
      isPrintCancelling,
      isPrintCancelled,
      isPrintCompleted,
      isPrintFailed,
    };
  }, [currentDeviceState, device]);

  const getCompletedButtonLabel = () => {
    if (printingStates.isPrintCompleted) return 'Completed';
    if (printingStates.isPrintCancelled) return 'Cancelled';
    if (printingStates.isPrintFailed) return 'Error details';
    return '';
  };

  const getPauseButtonLabel = () => {
    if (printingStates.isPrintPausing) return 'Pausing…';
    if (printingStates.isPrintPaused) return 'Resume';
    return 'Pause';
  };

  const getCancelButtonLabel = () => {
    if (printingStates.isPrintCancelling) return 'Cancelling…';
    return 'Cancel';
  };

  const renderErrorDetailsModal = () => {
    if (!showErrorDetailsModal) return null;
    if (!printingStates.isPrintFailed) {
      setShowErrorDetailsModal(false);
      return null;
    }
    const { code, info } = DeviceUtils.getPrintFailure(
      currentDeviceState,
      device
    );
    const onClose = () => setShowErrorDetailsModal(false);
    return (
      <Modal onMarginClick={onClose}>
        <ModalHeader>
          <Subtitle1>Error details</Subtitle1>
        </ModalHeader>
        <Body1>{info}</Body1>
        <Body1>
          Visit{' '}
          <Link external href={Links.support}>
            mm3d.co/support
          </Link>{' '}
          to learn more about error {code}
        </Body1>
        <ModalFooter>
          <Button primary onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const renderPrintControlButtons = () => {
    const {
      isPrintPausing,
      isPrintPaused,
      isPrintCancelling,
      isPrintCancelled,
      isPrintCompleted,
      isPrintFailed,
    } = printingStates;

    if (isPrintCompleted || isPrintCancelled || isPrintFailed) {
      return (
        <PrintControlButtons single>
          <Button
            clean
            minWidth='0'
            disabled={!isPrintFailed}
            onClick={onErrorDetailsClick}>
            {getCompletedButtonLabel()}
          </Button>
        </PrintControlButtons>
      );
    }

    const isPending =
      pendingRequests.pausePrint ||
      pendingRequests.resumePrint ||
      pendingRequests.cancelPrint ||
      isPrintPausing ||
      isPrintCancelling;

    return (
      <PrintControlButtons>
        <Button
          primary={isPrintPaused}
          minWidth='0'
          disabled={isPending}
          onClick={isPrintPaused ? onResumePrint : onPausePrint}>
          {getPauseButtonLabel()}
        </Button>
        <Button
          minimal={isPending}
          clean
          minWidth='0'
          disabled={isPending}
          onClick={onCancelPrint}>
          {getCancelButtonLabel()}
        </Button>
      </PrintControlButtons>
    );
  };

  const renderSummary = () => {
    const printName = DeviceUtils.getPrintName(currentDeviceState, device);
    const printProgress = DeviceUtils.getPrintProgress(currentDeviceState);

    const { isPrintPaused, isPrintCompleted, isPrintCancelled, isPrintFailed } =
      printingStates;

    const isLastJob = isPrintCompleted || isPrintCancelled || isPrintFailed;

    const filamentUsageMM = DeviceUtils.getFilamentUsage(
      currentDeviceState,
      device
    );
    const filamentUsageM = FormatUtils.roundTo(filamentUsageMM / 1000, 2);
    const displayFilament = filamentUsageM <= 0 ? '—' : `${filamentUsageM} m`;

    // time values are both in seconds
    const timeValueOfInterest = isLastJob
      ? DeviceUtils.getPrintTimeElapsed(currentDeviceState)
      : DeviceUtils.getPrintTimeRemaining(currentDeviceState);
    const displayETA =
      timeValueOfInterest <= 0
        ? '—'
        : FormatUtils.formatDuration(timeValueOfInterest);
    const timeLabel = `Time ${isLastJob ? 'Taken' : 'Remaining'}`;

    return (
      <PrintSummary>
        <ContentWrapper>
          <PrintName>
            {isLastJob && <Badge>Last job</Badge>}
            <Subtitle2>{printName}</Subtitle2>
          </PrintName>

          <PrintProgress>
            <TimeTotalBar />
            <TimeElapsedBar
              w={printProgress}
              isPaused={isPrintPaused}
              isCancelled={isPrintCancelled}
              isFailed={isPrintFailed}
            />
          </PrintProgress>

          <SummaryItem>
            <Subtitle2
              grey={isPrintPaused || isPrintCancelled}
              red={isPrintFailed}
              green
              bold>
              {displayFilament}
            </Subtitle2>
            <Caption grey noSpacing>
              Filament Used
            </Caption>
          </SummaryItem>
          <SummaryItem>
            <Subtitle2
              grey={isPrintPaused || isPrintCancelled}
              red={isPrintFailed}
              green>
              {FormatUtils.roundTo(printProgress, 2)}%
            </Subtitle2>
            <Caption grey noSpacing>
              Complete
            </Caption>
          </SummaryItem>
          <SummaryItem>
            <Subtitle2
              grey={isPrintPaused || isPrintCancelled}
              red={isPrintFailed}
              green
              bold>
              {displayETA}
            </Subtitle2>
            <Caption grey noSpacing>
              {timeLabel}
            </Caption>
          </SummaryItem>
          {renderPrintControlButtons()}
        </ContentWrapper>
      </PrintSummary>
    );
  };

  const renderPlaceholder = (title, description) => {
    return (
      <SectionPlaceholder>
        <PlaceholderIconWrapper>
          <Icon src={DeviceUtils.getDeviceIcon(device)} />
        </PlaceholderIconWrapper>
        <PlaceholderTextWrapper>
          <Subtitle2>{title}</Subtitle2>
          <Body1 grey>{description}</Body1>
        </PlaceholderTextWrapper>
      </SectionPlaceholder>
    );
  };

  const renderActiveSummary = () => {
    if (printingStates.isPrinting) {
      return renderSummary();
    }
    // idle
    const title = 'Ready';
    const description = 'Select a file to start printing';
    return renderPlaceholder(title, description);
  };

  const renderTitaniumOfflinePlaceholder = () => {
    const title = 'Canvas is offline';
    const description =
      'Canvas could not connect to the device communication service';
    return renderPlaceholder(title, description);
  };

  const renderDeviceOfflinePlaceholder = () => {
    const title = 'Device is offline';
    const description = 'Connect it to the Internet to control it from Canvas';
    return renderPlaceholder(title, description);
  };

  const renderNoPrinterPlaceholder = () => {
    const title = 'No printer connected';
    const description = 'Connect a printer to control it from Canvas';
    return renderPlaceholder(title, description);
  };

  let content;
  if (!isTitaniumOnline) {
    content = renderTitaniumOfflinePlaceholder();
  } else if (!isDeviceOnline) {
    content = renderDeviceOfflinePlaceholder();
  } else if (!isConnectedToPrinter) {
    content = renderNoPrinterPlaceholder();
  } else {
    content = renderActiveSummary();
  }

  return (
    <Container>
      {renderErrorDetailsModal()}
      {content}
    </Container>
  );
};

export default PrintSection;

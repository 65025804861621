import React, { Component } from 'react';
import _ from 'lodash';

import {
  ModalContent,
  SharePrinterNameWrapper,
  UpdateSharedCopyButtonWrapper,
  ShareSelectionsWrapper,
  DateSharedContainer,
  Notification,
  ShareTypeDropdownWrapper,
  LinkContainerStyles,
  CopyLinkButtonStyles,
  NotificationTextWrapper,
  FooterButtonWrapper,
  CheckboxListWrapper,
} from './sharePrinterModal.styles';

import Modal from '../modal/modal.jsx';
import CheckboxList from '../checkboxList/checkboxList.jsx';
import Dropdown from '../dropdown/dropdown.jsx';
import ShareLink from '../shareLink/shareLink.jsx';
import Button from '../button/button.jsx';

import { FormatUtils, InterfaceUtils } from '../../utils';

import { Subtitle1OneLine, Body1, Caption } from '../typography/typography';

class SharePrinterModal extends Component {
  constructor(props) {
    super(props);
    const { shareData, styles } = props.selectedPrinter;
    const printerStyleIds = _.map(styles, (style) => style.id);
    const styleIds =
      shareData && shareData.sharedStyleIds
        ? _.filter(shareData.sharedStyleIds, (sharedStyleId) =>
            printerStyleIds.includes(sharedStyleId)
          )
        : [];
    this.state = {
      printer: props.selectedPrinter,
      printerId: props.selectedPrinter.id,
      styleIds,
      shareId: shareData ? shareData.shareId : '',
      shareType: shareData && shareData.shareId ? 'link' : 'off',
      linkCopied: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      printers,
      createSharedPrinterSuccess,
      deleteSharedPrinterSuccess,
      updateSharedPrinterSuccess,
    } = this.props;
    const updatedPrinter = printers[this.state.printer.id];

    const sharedPrinterCreated =
      !prevProps.createSharedPrinterSuccess && createSharedPrinterSuccess;
    const sharedPrinterDeleted =
      !prevProps.deleteSharedPrinterSuccess && deleteSharedPrinterSuccess;
    const sharedPrinterUpdated =
      !prevProps.updateSharedPrinterSuccess && updateSharedPrinterSuccess;

    if (sharedPrinterCreated) {
      const { id, shareData } = updatedPrinter;
      const { shareId, sharedStyleIds } = shareData;
      this.setState({
        printer: updatedPrinter,
        shareId,
        printerId: id,
        styleIds: sharedStyleIds,
      });
    }

    if (sharedPrinterDeleted) {
      this.setState({
        printer: updatedPrinter,
        shareId: '',
        styleIds: [],
      });
    }

    if (sharedPrinterUpdated) {
      const { id, shareData } = updatedPrinter;
      const { sharedStyleIds } = shareData;
      this.setState({
        printer: updatedPrinter,
        printerId: id,
        styleIds: sharedStyleIds,
      });
    }
  }

  handleUpdateSharedCopy() {
    const { shareId, shareType, printerId, styleIds, printer } = this.state;
    const existingStyleIds = _.map(printer.styles, (style) => style.id);
    const styleIdsToShare = _.filter(styleIds, (styleId) =>
      existingStyleIds.includes(styleId)
    );

    if (shareId && shareType === 'link') {
      this.props.updateSharedPrinter(printerId, styleIdsToShare);
      this.setState({ linkCopied: false });
    }
  }

  copyToClipboard(link) {
    InterfaceUtils.copyToClipboard(link);

    // show notification that link was copied for 10s
    this.setState({ linkCopied: true });
    setTimeout(() => {
      this.setState({ linkCopied: false });
    }, 10000);
  }

  handleShareTypeChange(value) {
    const { shareId, printerId, styleIds } = this.state;
    const selectionsNotEmpty = printerId && !_.isEmpty(styleIds);

    if (shareId && value === 'off') {
      this.props.deleteSharedPrinter(printerId);
      this.setState({
        shareType: value,
        linkCopied: false,
      });
    }

    if (!shareId && value === 'link' && selectionsNotEmpty) {
      this.props.createSharedPrinter(printerId, styleIds);
      this.setState({
        shareType: value,
        linkCopied: false,
      });
    }
  }

  toggleStyleId(styleId) {
    const { styleIds } = this.state;
    const styleAlreadySelected = _.includes(styleIds, styleId);
    const styleRemovedFromSelected = _.difference(styleIds, [styleId]);
    const styleAddedToSelected = [...styleIds, styleId];

    const updatedStyleIds = styleAlreadySelected
      ? styleRemovedFromSelected
      : styleAddedToSelected;
    this.setState({ styleIds: updatedStyleIds });
  }

  renderModalNotification() {
    const {
      createSharedPrinterPending,
      deleteSharedPrinterPending,
      updateSharedPrinterPending,
    } = this.props;
    const { linkCopied } = this.state;
    const actionPending =
      createSharedPrinterPending ||
      deleteSharedPrinterPending ||
      updateSharedPrinterPending ||
      linkCopied;

    let labelText = '';
    if (createSharedPrinterPending) labelText = 'Creating share link';
    if (deleteSharedPrinterPending) labelText = 'Turning off share link';
    if (updateSharedPrinterPending) labelText = 'Updating shared copy';
    if (linkCopied) labelText = 'Link copied';

    return (
      <Notification reveal={actionPending}>
        <NotificationTextWrapper>
          <Caption noSpacing>{labelText}</Caption>
        </NotificationTextWrapper>
      </Notification>
    );
  }

  renderFooter() {
    const {
      createSharedPrinterPending,
      deleteSharedPrinterPending,
      updateSharedPrinterPending,
    } = this.props;
    const actionPending =
      createSharedPrinterPending ||
      deleteSharedPrinterPending ||
      updateSharedPrinterPending;

    return (
      <>
        <FooterButtonWrapper>
          <Button
            primary
            disabled={actionPending}
            title='Close'
            onClick={(e) => {
              e.stopPropagation();
              this.props.onMarginClick();
            }}>
            {actionPending ? 'Saving...' : 'Close'}
          </Button>
        </FooterButtonWrapper>
      </>
    );
  }

  renderUpdateSharedCopy() {
    const { shareData, machineSettings, styles } = this.state.printer;
    let date = null;
    let shareStatusText = 'The printer is currently not shared.';
    let canUpdate = false;
    if (shareData) {
      const { dateShared, shareId, sharedStyleIds } = shareData;
      date = FormatUtils.isoToDateString(dateShared);
      shareStatusText = shareId
        ? `Shared copy last updated on ${date}.`
        : shareStatusText;

      let selectionChanged = false;
      let stylesUpdated = false;
      let stylesDeleted = false;
      if (sharedStyleIds) {
        selectionChanged =
          _.xor(this.state.styleIds, sharedStyleIds).length !== 0;

        const sharedStyles = _.filter(styles, (style) =>
          sharedStyleIds.includes(style.id)
        );
        stylesUpdated = _.some(
          sharedStyles,
          (style) => style.timestamp > dateShared
        );

        const styleIds = _.map(styles, (style) => style.id);
        stylesDeleted = _.some(
          sharedStyleIds,
          (sharedStyleId) => !styleIds.includes(sharedStyleId)
        );
      }

      const printerUpdated = machineSettings.timestamp > dateShared;

      canUpdate =
        printerUpdated || selectionChanged || stylesUpdated || stylesDeleted;
    }
    const selectionsNotEmpty =
      this.state.printerId && !_.isEmpty(this.state.styleIds);
    return (
      <>
        <DateSharedContainer>
          <Body1 grey noSpacing>
            {shareStatusText}
          </Body1>
        </DateSharedContainer>
        <UpdateSharedCopyButtonWrapper>
          <Button
            title='Update shared copy'
            disabled={!this.state.shareId || !canUpdate || !selectionsNotEmpty}
            onClick={() => this.handleUpdateSharedCopy()}>
            Update shared copy
          </Button>
        </UpdateSharedCopyButtonWrapper>
      </>
    );
  }

  renderLink() {
    const link = this.state.shareId
      ? `${process.env.REACT_APP_SHARE_HOST}/printers/${this.state.shareId}`
      : '';
    return (
      <>
        <LinkContainerStyles>
          <ShareLink link={link} disabled={!link} />
        </LinkContainerStyles>
        <CopyLinkButtonStyles>
          <Button
            title='Copy link'
            disabled={!link}
            onClick={() => this.copyToClipboard(link)}>
            Copy link
          </Button>
        </CopyLinkButtonStyles>
      </>
    );
  }

  renderShareTypeDropdown() {
    const options = [
      { label: 'Off', value: 'off' },
      { label: 'Link', value: 'link' },
    ];
    const selectionsNotEmpty = !_.isEmpty(this.state.styleIds);

    return (
      <ShareTypeDropdownWrapper>
        <Dropdown
          options={options}
          label='Sharing type'
          disabled={!selectionsNotEmpty}
          value={this.state.shareType}
          onChange={(value) => this.handleShareTypeChange(value)}
        />
      </ShareTypeDropdownWrapper>
    );
  }

  renderSelections() {
    const { printer } = this.state;
    const styles = _.map(printer.styles, (style) => ({
      id: style.id,
      name: style.name,
      value: _.includes(this.state.styleIds, style.id),
    }));

    const noStylesAvailable = _.isEmpty(styles);
    return (
      <>
        <ShareSelectionsWrapper>
          <Body1 noSpacing>
            {!noStylesAvailable
              ? 'Select style profiles to include'
              : 'No style profiles available.'}
          </Body1>
        </ShareSelectionsWrapper>
        <CheckboxListWrapper>
          <CheckboxList
            options={styles}
            captionLabel={() => null}
            subCaptionLabel={() => null}
            onChange={(id) => this.toggleStyleId(id)}
          />
        </CheckboxListWrapper>
      </>
    );
  }

  renderHeading() {
    const printerName = this.state.printer.machineSettings.name;
    return (
      <SharePrinterNameWrapper>
        <Subtitle1OneLine>{`Sharing: ${printerName}`}</Subtitle1OneLine>
      </SharePrinterNameWrapper>
    );
  }

  render() {
    return (
      <Modal onMarginClick={this.props.onMarginClick} width={'27.5rem'}>
        <ModalContent>
          {this.renderHeading()}
          {this.renderSelections()}
          {this.renderShareTypeDropdown()}
          {this.renderLink()}
          {this.renderUpdateSharedCopy()}
          {this.renderFooter()}
          {this.renderModalNotification()}
        </ModalContent>
      </Modal>
    );
  }
}

export default SharePrinterModal;

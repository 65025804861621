const BYTES_PER_MB = 1024 ** 2;

const MAX_UPLOAD_SIZE_MB = 100;
const MAX_UPLOAD_SIZE_BYTES = MAX_UPLOAD_SIZE_MB * BYTES_PER_MB;

const Constants = {
  THROTTLE_DEFAULT: 1000,
  THROTTLE_SHORT: 500,
  MAX_UPLOAD_SIZE_BYTES,
  TIMEOUT_ERROR_MESSAGE: 'The server timed out. Please try again.',
  REPACK_ERROR_MESSAGE:
    'An error occurred while auto-arranging. Please reload the page.',
  UPLOAD_ERROR_MESSAGE:
    'An error occurred while uploading. Please reload the page and try again.',
  SLICE_ERROR_MESSAGE: 'An error occurred while slicing.',
  SINGLE_UPLOAD_SIZE_ERROR_MESSAGE: `Maximum upload size of ${MAX_UPLOAD_SIZE_MB} MB exceeded.`,
  MULTI_UPLOAD_SIZE_ERROR_MESSAGE: `Maximum upload size of ${MAX_UPLOAD_SIZE_MB} MB exceeded. Try uploading each model individually.`,
};

export default Constants;

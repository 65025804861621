import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

export default function* changeMaterial(action) {
  try {
    const { currentProject: projectId } = yield select(getSlicerState);
    const { materialIds } = action.payload;

    const response = yield call(API, {
      method: methods.POST,
      path: `projects/${projectId}`,
      body: { materialIds },
    });
    if (response === null) return;
    yield put(actions.changeMaterialSuccess(response.project));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

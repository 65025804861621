import _ from 'lodash';

import onboardingTypes from './types';
import onboardingActions from './actions';
import onboardingInitialState from './initialState';

export const types = onboardingTypes;
export const actions = onboardingActions;
export const initialState = onboardingInitialState;

const mergeModalStates = (storeValues, retrievedValues) => {
  const merged = {
    ...storeValues,
    ...retrievedValues,
  };
  _.forEach(storeValues, (storeStatus, identifier) => {
    merged[identifier] = {
      ...storeStatus,
      ...retrievedValues[identifier],
    };
  });
  return merged;
};

const mergeTaskStates = (storeValues, retrievedValues) => {
  const merged = {
    ...storeValues,
    ...retrievedValues,
  };
  _.forEach(storeValues, (storeStatus, identifier) => {
    if (!retrievedValues[identifier]) {
      merged[identifier] = storeStatus;
      return;
    }
    merged[identifier] = {
      ...storeStatus,
      ...retrievedValues[identifier],
    };
    if (retrievedValues[identifier].steps) {
      merged[identifier].steps = {
        ...storeStatus.steps,
        ...retrievedValues[identifier].steps,
      };
    }
  });
  return merged;
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.RESET_ONBOARDING_STORE: {
      return initialState;
    }

    case types.GET_ONBOARDING_STATE_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getOnboardingStatePending: true,
          getOnboardingStateSuccess: false,
        },
      };
    }

    case types.GET_ONBOARDING_STATE_SUCCESS: {
      const { modals, tasks } = payload;
      const mergedModals = mergeModalStates(state.modals, modals);
      const mergedTasks = mergeTaskStates(state.tasks, tasks);
      return {
        ...state,
        modals: mergedModals,
        tasks: mergedTasks,
        status: {
          ...state.status,
          getOnboardingStatePending: false,
          getOnboardingStateSuccess: true,
        },
      };
    }

    case types.GET_ONBOARDING_STATE_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getOnboardingStatePending: false,
          getOnboardingStateSuccess: false,
        },
      };
    }

    case types.START_MODAL_REQUEST: {
      const { identifier } = payload;
      return {
        ...state,
        modals: {
          ...state.modals,
          [identifier]: {
            ...state.modals[identifier],
            started: true,
          },
        },
        status: {
          ...state.status,
          setOnboardingStatePending: true,
          setOnboardingStateSuccess: false,
        },
      };
    }

    case types.START_MODAL_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          setOnboardingStatePending: false,
          setOnboardingStateSuccess: true,
        },
      };
    }

    case types.START_MODAL_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          setOnboardingStatePending: false,
          setOnboardingStateSuccess: false,
        },
      };
    }

    case types.COMPLETE_MODAL_REQUEST: {
      const { identifier } = payload;
      return {
        ...state,
        modals: {
          ...state.modals,
          [identifier]: {
            ...state.modals[identifier],
            completed: true,
          },
        },
        status: {
          ...state.status,
          setOnboardingStatePending: true,
          setOnboardingStateSuccess: false,
        },
      };
    }

    case types.COMPLETE_MODAL_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          setOnboardingStatePending: false,
          setOnboardingStateSuccess: true,
        },
      };
    }

    case types.COMPLETE_MODAL_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          setOnboardingStatePending: false,
          setOnboardingStateSuccess: false,
        },
      };
    }

    case types.START_TASK_REQUEST: {
      const { identifier } = payload;
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [identifier]: {
            ...state.tasks[identifier],
            started: true,
          },
        },
        status: {
          ...state.status,
          setOnboardingStatePending: true,
          setOnboardingStateSuccess: false,
        },
      };
    }

    case types.START_TASK_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          setOnboardingStatePending: false,
          setOnboardingStateSuccess: true,
        },
      };
    }

    case types.START_TASK_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          setOnboardingStatePending: false,
          setOnboardingStateSuccess: false,
        },
      };
    }

    case types.SET_TASK_STEP_STATUS_REQUEST: {
      const { task, status } = payload;
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [task]: {
            ...state.tasks[task],
            steps: {
              ...state.tasks[task].steps,
              ...status,
            },
          },
        },
        status: {
          ...state.status,
          setOnboardingStatePending: true,
          setOnboardingStateSuccess: false,
        },
      };
    }

    case types.SET_TASK_STEP_STATUS_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          setOnboardingStatePending: false,
          setOnboardingStateSuccess: true,
        },
      };
    }

    case types.SET_TASK_STEP_STATUS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          setOnboardingStatePending: false,
          setOnboardingStateSuccess: false,
        },
      };
    }

    default: {
      return state;
    }
  }
};

import { fieldTypes, fieldSteps, slicers } from '../../../../../constants';
import tooltips from './tooltips.jsx';
import { SolidFillStyles } from '../../../../../utils/profile-import/enums';

const { EXTRUSION_WIDTH_STEP, LAYER_HEIGHT_STEP, SPEED_STEP } = fieldSteps;
const { KISSlicer, Slic3r } = slicers;

const solidFillStyles = [
  { label: 'Straight', value: SolidFillStyles.STRAIGHT },
  { label: 'Straight (no backtracking)', value: SolidFillStyles.MONOTONIC },
  { label: 'Concentric', value: SolidFillStyles.CONCENTRIC },
];

const topSolidFillStyles = [...solidFillStyles, { label: 'Auto', value: -1 }];

const Metadata = {
  solidLayers: {
    label: 'Solid layers',
    tags: ['surfaces', 'top', 'bottom'],
    fields: [
      {
        name: 'skinThickness',
        label: 'Shell thickness',
        tags: ['skin'],
        tooltip: tooltips.skinThickness,
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            step: LAYER_HEIGHT_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            units: 'layers',
            type: fieldTypes.number,
            min: 0,
            step: 1,
            toBaseUnits: (value, style) =>
              Math.round(value * style.layerHeight * 100) / 100,
            fromBaseUnits: (value, style) =>
              Math.round(value / style.layerHeight),
          },
        ],
      },
      {
        name: 'topSkinThickness',
        label: 'Top shell thickness',
        tags: ['skin'],
        tooltip: tooltips.topSkinThickness,
        slicers: [Slic3r],
        disabled: (style, slicer) => slicer && slicer !== Slic3r,
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            step: LAYER_HEIGHT_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            units: 'layers',
            type: fieldTypes.number,
            min: 0,
            step: 1,
            toBaseUnits: (value, style) =>
              Math.round(value * style.layerHeight * 100) / 100,
            fromBaseUnits: (value, style) =>
              Math.round(value / style.layerHeight),
          },
          {
            type: fieldTypes.auto,
            toBaseUnits: (value, style) => style.layerHeight * 3,
            fromBaseUnits: () => 'auto',
          },
        ],
      },
      {
        name: 'solidLayerSpeed',
        label: 'Solid layer speed',
        tags: ['top speed', 'bottom speed'],
        tooltip: tooltips.solidLayerSpeed,
        variants: [
          {
            units: 'mm/s',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: SPEED_STEP,
          },
        ],
      },
      {
        name: 'semiSolidLayers',
        label: 'Semi-solid layers below top surfaces',
        tags: [],
        tooltip: tooltips.semiSolidLayers,
        slicers: [KISSlicer],
        disabled: (style, slicer) => slicer && slicer !== KISSlicer,
        variants: [
          {
            type: fieldTypes.checkbox,
          },
        ],
      },
    ],
  },
  travel: {
    label: 'Travel movement',
    tags: [],
    fields: [
      {
        name: 'rapidXYSpeed',
        label: 'X/Y travel speed',
        tags: [],
        tooltip: tooltips.rapidXYSpeed,
        variants: [
          {
            units: 'mm/s',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: SPEED_STEP,
          },
        ],
      },
      {
        name: 'rapidZSpeed',
        label: 'Z travel speed',
        tags: [],
        tooltip: tooltips.rapidZSpeed,
        variants: [
          {
            units: 'mm/s',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: SPEED_STEP,
          },
        ],
      },
      {
        name: 'avoidCrossingPerimeters',
        label: 'Avoid crossing perimeters',
        tags: [],
        tooltip: tooltips.avoidCrossingPerimeters,
        slicers: [Slic3r],
        disabled: (style, slicer) => slicer && slicer !== Slic3r,
        variants: [
          {
            type: fieldTypes.checkbox,
          },
        ],
      },
      {
        name: 'avoidCrossingPerimetersMaxDetour',
        label: 'Maximum detour length',
        tags: [],
        tooltip: tooltips.avoidCrossingPerimetersMaxDetour,
        slicers: [Slic3r],
        disabled: (style, slicer) =>
          !style.avoidCrossingPerimeters || (slicer && slicer !== Slic3r),
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            gte: true,
            step: EXTRUSION_WIDTH_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            units: '%',
            optionLabel: '% of direct travel',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: 1,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
        ],
      },
    ],
  },
  finish: {
    label: 'Surface Finish',
    tags: [],
    fields: [
      {
        name: 'solidLayerStyle',
        label: 'Solid layer style',
        tags: ['solid fill type'],
        tooltip: tooltips.solidLayerStyle,
        slicers: [Slic3r],
        disabled: (style, slicer) => slicer && slicer !== Slic3r,
        variants: [
          {
            type: fieldTypes.boxSelection,
            options: solidFillStyles,
          },
        ],
      },
      {
        name: 'topSolidLayerStyle',
        label: 'Top solid layer style',
        tags: ['solid fill type'],
        tooltip: tooltips.topSolidLayerStyle,
        slicers: [Slic3r],
        disabled: (style, slicer) => slicer && slicer !== Slic3r,
        variants: [
          {
            type: fieldTypes.boxSelection,
            options: topSolidFillStyles,
          },
        ],
      },
      {
        name: 'monotonicSweep',
        label: 'Avoid backtracking on solid layers',
        tags: [],
        tooltip: tooltips.monotonicSweep,
        slicers: [KISSlicer],
        disabled: (style, slicer) => slicer && slicer !== KISSlicer,
        variants: [
          {
            type: fieldTypes.checkbox,
          },
        ],
      },
    ],
  },
  ironing: {
    name: 'useIroning',
    label: 'Ironing',
    tags: [],
    tooltip: tooltips.useIroning,
    slicers: [Slic3r],
    disabled: (style, slicer) => slicer && slicer !== Slic3r,
    isChecked: (style) => style.useIroning,
    updateFieldsOnToggle: (checked, updateField) => {
      updateField('useIroning', checked);
    },
    fields: [
      {
        name: 'ironingFlowrate',
        label: 'Flow',
        tags: ['ironing', 'flowrate'],
        tooltip: tooltips.ironingFlowrate,
        disabled: (style, slicer) =>
          !style.useIroning || (slicer && slicer !== Slic3r),
        variants: [
          {
            units: '%',
            optionLabel: '% of solid layer flowrate',
            type: fieldTypes.number,
            min: 0,
            max: 100,
            step: 1,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            type: fieldTypes.auto,
            toBaseUnits: () => 15,
            fromBaseUnits: () => 'auto',
          },
        ],
      },
      {
        name: 'ironingSpacing',
        label: 'Spacing',
        tags: ['ironing'],
        tooltip: tooltips.ironingSpacing,
        disabled: (style, slicer) =>
          !style.useIroning || (slicer && slicer !== Slic3r),
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: EXTRUSION_WIDTH_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            type: fieldTypes.auto,
            toBaseUnits: () => 0.1,
            fromBaseUnits: () => 'auto',
          },
        ],
      },
      {
        name: 'ironingSpeed',
        label: 'Speed',
        tags: ['ironing', 'speed', 'feedrate'],
        tooltip: tooltips.ironingSpeed,
        disabled: (style, slicer) =>
          !style.useIroning || (slicer && slicer !== Slic3r),
        variants: [
          {
            units: 'mm/s',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: SPEED_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            units: '%',
            optionLabel: '% of solid layer speed',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: 1,
            toBaseUnits: (value, style) =>
              Math.round((value * style.solidLayerSpeed) / 100),
            fromBaseUnits: (value, style) =>
              Math.round((value / style.solidLayerSpeed) * 100),
          },
          {
            type: fieldTypes.auto,
            toBaseUnits: () => 15,
            fromBaseUnits: () => 'auto',
          },
        ],
      },
    ],
  },
};

export default Metadata;

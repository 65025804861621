import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../canvas-api';
import { handleGenericError } from '../common';
import { actions } from '../../reducers/iot/iot';

export default function* updateDevice(action) {
  const { deviceId, deviceData } = action.payload;

  try {
    const response = yield call(API, {
      path: `devices/${deviceId}`,
      method: methods.POST,
      body: deviceData,
    });
    if (response === null) return;

    yield put(actions.updateDeviceSuccess(response.device));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import React, { Component } from 'react';
import _ from 'lodash';

import { Link, Page } from '../../../shared';
import Routes from '../../../router/routes';

import sections from './content.jsx';
import {
  PrintScriptWrapper,
  Article,
  Header,
  Main,
  SectionNumber,
  SectionLink,
  Nav,
  SubsectionNumber,
} from '../styles';

class PrinterScriptVariables extends Component {
  componentDidMount() {
    document.title = 'Sequences and Variables • Canvas';
    this.props.updateNavStack([
      { text: 'PrinterScript', route: Routes.toPrinterScript() },
      {
        text: 'Sequences and Variables',
        route: Routes.toPrinterScriptVariables(),
      },
    ]);
  }

  renderHeader() {
    return (
      <Header>
        <h1>Canvas Sequences and Variables</h1>
      </Header>
    );
  }

  getSectionId(sIdx, section) {
    if (section.id) return section.id;
    return `s${sIdx + 1}`;
  }

  getSubsectionId(sIdx, ssIdx, subsection) {
    if (subsection.id) return subsection.id;
    return `s${sIdx + 1}-${ssIdx + 1}`;
  }

  renderTocSubsection(sIdx, ssIdx, subsection) {
    const href = `#${this.getSubsectionId(sIdx, ssIdx, subsection)}`;
    return (
      <li key={ssIdx}>
        <Link href={href}>{subsection.title}</Link>
      </li>
    );
  }

  renderTocSection(sIdx, section) {
    const href = `#${this.getSectionId(sIdx, section)}`;
    const subsections = section.subsections ? (
      <ol>
        {_.map(section.subsections, (subsection, ssIdx) =>
          this.renderTocSubsection(sIdx, ssIdx, subsection)
        )}
      </ol>
    ) : null;
    return (
      <li key={sIdx}>
        <Link href={href}>{section.title}</Link>
        {subsections}
      </li>
    );
  }

  renderToc() {
    return (
      <Nav>
        <ol>
          {_.map(sections, (section, sIdx) =>
            this.renderTocSection(sIdx, section)
          )}
        </ol>
      </Nav>
    );
  }

  renderSubsection(sIdx, ssIdx, subsection) {
    const id = this.getSubsectionId(sIdx, ssIdx, subsection);
    return (
      <section key={ssIdx} id={id}>
        <h3>
          <SubsectionNumber>
            {sIdx + 1}.{ssIdx + 1}
          </SubsectionNumber>
          {subsection.title}
          <SectionLink title='Back to top' href='#top'>
            &uarr;
          </SectionLink>
          <SectionLink title='This section’s link' href={`#${id}`}>
            §
          </SectionLink>
        </h3>
        {subsection.content}
      </section>
    );
  }

  renderSection(sIdx, section) {
    const content =
      section.content ||
      _.map(section.subsections, (subsection, ssIdx) =>
        this.renderSubsection(sIdx, ssIdx, subsection)
      );
    const id = this.getSectionId(sIdx, section);
    return (
      <section key={sIdx} id={id}>
        <h2>
          <SectionNumber>{sIdx + 1}</SectionNumber>
          {section.title}
          <SectionLink title='Back to top' href='#top'>
            &uarr;
          </SectionLink>
          <SectionLink title='This section’s link' href={`#${id}`}>
            §
          </SectionLink>
        </h2>
        {content}
      </section>
    );
  }

  renderContent() {
    return (
      <Main>
        {_.map(sections, (section, sIdx) => this.renderSection(sIdx, section))}
      </Main>
    );
  }

  render() {
    return (
      <PrintScriptWrapper>
        <Page>
          <Article id='top'>
            {this.renderHeader()}
            {this.renderToc()}
            {this.renderContent()}
          </Article>
        </Page>
      </PrintScriptWrapper>
    );
  }
}

export default PrinterScriptVariables;

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 100;
  height: 100%;
  width: auto;
  min-height: ${(props) => (props.minHeight ? props.minHeight : '20em')};
  border: 1px solid ${(props) => props.theme.colors.grey3};
`;

export const InlineContainer = styled(Container)`
  border-radius: 0.25rem;
  background-color: ${(props) =>
    props.theme.colors.elevatedBackgroundSecondary};
  &:hover {
    background-color: ${(props) =>
      props.theme.colors.elevatedBackgroundTertiary};
  }
`;

export const FloatingContainer = styled(Container)`
  background-color: ${(props) =>
    props.hovered
      ? props.theme.colors.elevatedBackgroundSecondary
      : props.theme.colors.elevatedBackgroundPrimary};
`;

export const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
  user-select: auto;
`;

export const DisplayContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const TextContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;

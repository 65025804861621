import 'regenerator-runtime/runtime';
import { call, select } from 'redux-saga/effects';

import { getThreeState, handleGenericError } from '../common';
import { setRenderFlag } from './animationFrame';

export default function* removeToolpath(action) {
  try {
    const { scene } = yield select(getThreeState);
    const { toolpath } = action.payload;
    if (!scene || !toolpath.version || !toolpath.extrusions) {
      return;
    }
    if (toolpath.extrusions) {
      scene.remove(toolpath.extrusions);
      toolpath.extrusions.geometry.dispose();
    }
    if (toolpath.travels) {
      scene.remove(toolpath.travels);
      toolpath.travels.geometry.dispose();
    }
    if (toolpath.retracts) {
      scene.remove(toolpath.retracts);
      toolpath.retracts.geometry.dispose();
    }
    if (toolpath.restarts) {
      scene.remove(toolpath.restarts);
      toolpath.restarts.geometry.dispose();
    }
    setRenderFlag();
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

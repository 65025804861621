import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API from '../canvas-api';

import { handleGenericError } from '../common';
import { actions } from '../../reducers/auth/auth';
import { ValidateUtils } from '../../utils';

export default function* requestPasswordReset(action) {
  const { email } = action.payload;
  const emailFieldName = ValidateUtils.isEmail(email) ? 'email' : 'username';
  try {
    yield call(API, {
      path: `users/reset-password/?${emailFieldName}=${encodeURIComponent(
        email
      )}`,
      token: null,
    });

    yield put(actions.requestPasswordResetSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

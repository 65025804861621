import { fieldTypes, fieldSteps } from '../../../../../constants';
import tooltips from './tooltips.jsx';

const { EXTRUSION_WIDTH_STEP, SPEED_STEP } = fieldSteps;

export const transitionMethods = [
  { label: 'Transition tower', value: 1 },
  { label: 'Side transitions', value: 2 },
];

const Metadata = {
  transitionMethod: {
    label: 'Transition method',
    tags: [],
    fields: [
      {
        name: 'transitionMethod',
        label: 'Transition method',
        tags: [],
        tooltip: tooltips.transitionMethod,
        variants: [
          {
            type: fieldTypes.boxSelection,
            options: transitionMethods,
          },
        ],
      },
    ],
  },
  transitionSettings: {
    label: 'Transition length',
    tags: [],
    fields: [
      {
        name: 'transitionLength',
        label: 'Transition length',
        tooltip: tooltips.transitionLength,
        tags: ['purge amount', 'purge distance', 'transition distance'],
        disabled: (style, slicer, projectProps = {}) => {
          return (
            style.transitionMethod === 0 ||
            projectProps.variableTransitionLengths
          );
        },
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 30,
            max: 330,
            step: 1,
          },
        ],
      },
    ],
  },
  transitionTarget: {
    label: 'Transition target',
    tags: ['transition offset', 'transition position'],
    fields: [
      {
        name: 'transitionTarget',
        label: 'Target position',
        tags: [],
        tooltip: tooltips.transitionTarget,
        disabled: (style) => style.transitionMethod === 0,
        variants: [
          {
            units: '%',
            type: fieldTypes.number,
            min: 10,
            max: 60,
            step: 1,
          },
        ],
      },
      {
        name: 'purgeToInfill',
        label: 'Use infill for transitioning',
        tags: ['purge', 'fill'],
        tooltip: tooltips.purgeToInfill,
        variants: [
          {
            type: fieldTypes.checkbox,
          },
        ],
      },
    ],
  },
  towerSettings: {
    label: 'Transition tower',
    tags: [],
    fields: [
      {
        name: 'towerMinDensity',
        label: 'Minimum density',
        tags: [],
        tooltip: tooltips.towerMinDensity,
        disabled: (style) => style.transitionMethod !== 1,
        variants: [
          {
            units: '%',
            type: fieldTypes.number,
            min: 0,
            max: 100,
            step: 1,
          },
        ],
      },
      {
        name: 'towerMaxDensity',
        label: 'Maximum density',
        tags: [],
        tooltip: tooltips.towerMaxDensity,
        disabled: (style) => style.transitionMethod !== 1,
        variants: [
          {
            units: '%',
            type: fieldTypes.number,
            min: 80,
            max: 100,
            step: 1,
          },
        ],
      },
      {
        name: 'towerMinBottomDensity',
        label: 'Minimum bottom density',
        tags: [],
        tooltip: tooltips.towerMinBottomDensity,
        disabled: (style) => style.transitionMethod !== 1,
        variants: [
          {
            units: '%',
            type: fieldTypes.number,
            min: 0,
            max: 100,
            step: 1,
          },
        ],
      },
      {
        name: 'towerMinBrims',
        label: 'Minimum brim count',
        tags: [],
        tooltip: tooltips.towerMinBrims,
        disabled: (style) => style.transitionMethod !== 1,
        variants: [
          {
            type: fieldTypes.number,
            min: 0,
            step: 1,
          },
        ],
      },
      {
        name: 'towerSpeed',
        label: 'Tower print speed',
        tags: [],
        tooltip: tooltips.towerSpeed,
        disabled: (style) => style.transitionMethod !== 1,
        variants: [
          {
            units: 'mm/s',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: SPEED_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            type: fieldTypes.auto,
            toBaseUnits: () => 60,
            fromBaseUnits: () => 'auto',
          },
        ],
      },
      {
        name: 'towerExtrusionWidth',
        label: 'Tower extrusion width',
        tags: ['extrusion thickness'],
        tooltip: tooltips.towerExtrusionWidth,
        disabled: (style) => style.transitionMethod !== 1,
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: EXTRUSION_WIDTH_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            type: fieldTypes.auto,
            toBaseUnits: (value, style) => {
              if (style.infillExtrusionWidth.units === 'mm') {
                return style.infillExtrusionWidth.value;
              }
              if (style.infillExtrusionWidth.units === '%') {
                return (
                  Math.round(
                    style.infillExtrusionWidth.value * style.extrusionWidth
                  ) / 100
                );
              }
              return Math.round(style.extrusionWidth * 120) / 100;
            },
            fromBaseUnits: () => 'auto',
          },
        ],
      },
    ],
  },
  sideTransitionSettings: {
    label: 'Side transition speed',
    tags: [],
    fields: [
      {
        name: 'sideTransitionPurgeSpeed',
        label: 'Speed',
        tags: [],
        tooltip: tooltips.sideTransitionPurgeSpeed,
        disabled: (style) => style.transitionMethod !== 2,
        variants: [
          {
            units: 'mm/s',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: SPEED_STEP,
          },
        ],
      },
    ],
  },
};

export default Metadata;

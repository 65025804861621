import React from 'react';
import { withTheme } from 'styled-components';

import {
  AdditionalInfoWrapper,
  DetailsSection,
  DeviceImage,
  DeviceInfoWrapper,
  InfoItem,
  MetaSection,
  NameAndType,
  SubtitleWrapper,
  StyledImage,
} from './deviceMetadataModal.styles';

import {
  Body1,
  Button,
  Caption,
  Link,
  Modal,
  ModalFooter,
  ModalHeader,
  OnlineIndicator,
  Subtitle1,
  Subtitle1OneLine,
  ToolTipWrapper,
} from '../../../../../shared';

import { Links } from '../../../../../themes';
import { DeviceUtils, FormatUtils } from '../../../../../utils';

const DeviceMetadataModal = (props) => {
  const { device, theme, deviceOnline, titaniumOnline, onClose } = props;
  const deviceName = DeviceUtils.getDeviceName(device.type, device.model);
  const supportLink =
    device.type === 'palette-3' ? Links.p3Support : Links.hubSupport;

  let tooltip;
  if (titaniumOnline) {
    tooltip = `Device ${deviceOnline ? 'online' : 'offline'}`;
  } else {
    tooltip = 'Canvas offline';
  }
  return (
    <Modal onMarginClick={onClose} width='47rem'>
      <ModalHeader>
        <Subtitle1>Device Info</Subtitle1>
      </ModalHeader>
      <MetaSection>
        <DeviceImage>
          <StyledImage
            src={DeviceUtils.getDeviceImage(device.type, device.model, theme)}
          />
        </DeviceImage>
        <DetailsSection>
          <NameAndType>
            <ToolTipWrapper tooltip={tooltip}>
              <DeviceInfoWrapper>
                <SubtitleWrapper>
                  <div style={{ minWidth: '0' }}>
                    <Subtitle1OneLine>{device.name}</Subtitle1OneLine>
                  </div>
                </SubtitleWrapper>
                <OnlineIndicator
                  online={titaniumOnline}
                  connected={deviceOnline}
                />
              </DeviceInfoWrapper>
              <Caption grey noSpacing>
                {deviceName}
              </Caption>
            </ToolTipWrapper>
          </NameAndType>
          <AdditionalInfoWrapper>
            <InfoItem>
              <Caption grey noSpacing>
                Added
              </Caption>
              <Body1 noSpacing>
                {FormatUtils.isoToDateString(device.created)}
              </Body1>
            </InfoItem>
            <InfoItem>
              <Caption grey noSpacing>
                Last modified
              </Caption>
              <Body1 noSpacing>
                {FormatUtils.isoToDateString(device.modified)}
              </Body1>
            </InfoItem>
            <InfoItem>
              <Caption grey noSpacing>
                Support
              </Caption>
              <Body1 noSpacing>
                <Link external href={supportLink}>
                  Support Articles
                </Link>
              </Body1>
              <Body1 noSpacing>
                <Link external href={Links.zendesk}>
                  Customer Support
                </Link>
              </Body1>
            </InfoItem>
          </AdditionalInfoWrapper>
        </DetailsSection>
      </MetaSection>
      <ModalFooter>
        <Button primary onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default withTheme(DeviceMetadataModal);

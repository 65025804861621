import React from 'react';
import {
  StyledCharacterInput,
  StyledContainer,
  StyledInput,
} from './characterInput.styles';
import { AbstractInput } from '../../../../shared';

function CharacterInput(props) {
  return (
    <StyledCharacterInput key={props.key} active={props.active}>
      <AbstractInput
        {...props}
        StyledContainer={StyledContainer}
        StyledInput={StyledInput}
      />
    </StyledCharacterInput>
  );
}

export default CharacterInput;

import { connect } from 'react-redux';
import Home from './home.jsx';
import actions from '../../reducers/auth/actions';

const mapStateToProps = (state) => ({
  autoLogout: state.auth.autoLogout,
  usernameOrEmail: state.auth.usernameOrEmail,
  resetPasswordPending: state.auth.status.resetPasswordPending,
  username: state.auth.username,
  userId: state.auth.userId,
  isAuthenticated: !!state.auth.refreshToken,
  // only used by InterfaceUtils.getLoadingSpinner
  loginPending: state.auth.status.loginPending,
  loginSuccess: state.auth.status.loginSuccess,
  signupPending: state.auth.status.signupPending,
  signupSuccess: state.auth.status.signupSuccess,
  requestPasswordResetSuccess: state.auth.status.requestPasswordResetSuccess,
  requestPasswordResetPending: state.auth.status.requestPasswordResetPending,
});

const mapDispatchToProps = (dispatch) => ({
  setAutoLogout: (enabled) => dispatch(actions.setAutoLogout(enabled)),
  loginRequest: (email, password) =>
    dispatch(actions.loginRequest(email, password)),
  signUpRequest: (username, email, password, betaToken, newsletter) =>
    dispatch(
      actions.signupRequest(username, email, password, betaToken, newsletter)
    ),
  requestPasswordReset: (email) =>
    dispatch(actions.requestPasswordResetRequest(email)),
  resetPasswordRequest: (email, resetCode, password) =>
    dispatch(actions.resetPasswordRequest(email, resetCode, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);

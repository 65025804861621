import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { useInput } from '../../../../../../hooks';

import {
  StyledContainer,
  ToggleSection,
  TitleTextWrapper,
  ControlToggle,
  ToggleIcon,
  OptionsBar,
  TemperaturePresets,
  PresetItem,
  CurrentTemperatureOnToggle,
  PresetButtonText,
  TargetTemperatureButton,
  TargetTemperatureInputContainer,
  SpinnerContainer,
  OffsetButtonWrapper,
} from './temperatureController.styles';

import {
  ActionButton,
  Button,
  Icon,
  Progress,
  ToolTipWrapper,
} from '../../../../../../shared';

import { DeviceUtils, FormatUtils } from '../../../../../../utils';
import { Icons } from '../../../../../../themes';

import { Subtitle2 } from '../../../../../../shared/typography/typography';

import OnClickActivationInput from './onClickActivationInput/onClickActivationInput.jsx';

const OFFSET_STEP = 5; // -/+ buttons offset the target by this amount

const getCurrentTemperature = (part, currentDeviceState) => {
  switch (part.type) {
    case 'nozzle':
      return DeviceUtils.getNozzleTemperature(currentDeviceState);
    case 'bed':
      return DeviceUtils.getBedTemperature(currentDeviceState);
    case 'chamber':
      return DeviceUtils.getChamberTemperature(currentDeviceState);
    default:
      return 0;
  }
};

const getTargetTemperature = (part, currentDeviceState) => {
  switch (part.type) {
    case 'nozzle':
      return DeviceUtils.getTargetNozzleTemperature(currentDeviceState);
    case 'bed':
      return DeviceUtils.getTargetBedTemperature(currentDeviceState);
    case 'chamber':
      return DeviceUtils.getTargetChamberTemperature(currentDeviceState);
    default:
      return 0;
  }
};

const getTemperaturePresets = (part) => {
  switch (part.type) {
    case 'nozzle':
      return DeviceUtils.getNozzleTemperatureOptions();
    case 'bed':
      return DeviceUtils.getBedTemperatureOptions();
    case 'chamber':
      return DeviceUtils.getChamberTemperatureOptions();
    default:
      return [];
  }
};

const TemperatureController = ({
  part,
  theme,
  pendingRequests = {},
  currentDeviceState,
  onSetTargetTemperature,
  device,
}) => {
  // TODO: get temperature presets from device in the future
  const temperaturePresets = getTemperaturePresets(part);
  const currentTemperature = getCurrentTemperature(part, currentDeviceState);
  const targetTemperature = getTargetTemperature(part, currentDeviceState);

  const [inEditMode, setInEditMode] = useState(false);
  const {
    inputValue: targetInputValue,
    inputError: targetInputError,
    handleInputChange: handleTargetInputChange,
  } = useInput(targetTemperature);

  const formatTemperatureData = (target) => ({
    [part.type]: part.type === 'nozzle' ? [target] : target,
  });

  const onToggle = (switchOn) => {
    const isActivelyPrinting = DeviceUtils.isActivelyPrinting(
      currentDeviceState,
      device
    );
    const isPrintPaused = DeviceUtils.isPrintPaused(currentDeviceState, device);
    if (isActivelyPrinting && !isPrintPaused && part.type !== 'bed') return;
    const newTarget = switchOn ? temperaturePresets[0].targetTemperature : 0;
    handleTargetInputChange(newTarget);
    const formattedData = formatTemperatureData(newTarget);
    onSetTargetTemperature(formattedData);
  };

  const onSelectPreset = (option) => {
    const newTarget = option.targetTemperature;
    const formattedData = formatTemperatureData(newTarget);
    handleTargetInputChange(newTarget);
    setInEditMode(false);
    onSetTargetTemperature(formattedData);
  };

  const onSendInputValue = () => {
    const formattedData = formatTemperatureData(targetInputValue);
    onSetTargetTemperature(formattedData);
    setInEditMode(false);
  };

  const onOffsetTarget = (offsetAmount) => {
    const newTarget = targetTemperature + offsetAmount;
    if (newTarget <= 0) return;

    // make a request with the new option
    handleTargetInputChange(newTarget);
    const formattedData = formatTemperatureData(newTarget);
    onSetTargetTemperature(formattedData);
  };

  const renderPresets = () => {
    const isActivelyPrinting = DeviceUtils.isActivelyPrinting(
      currentDeviceState,
      device
    );
    const presetsDisabled =
      isActivelyPrinting || pendingRequests.setTargetTemperature;

    return (
      <TemperaturePresets>
        {temperaturePresets.map((option, index) => (
          <PresetItem
            key={index}
            isDisabled={presetsDisabled}
            onClick={() => {
              if (presetsDisabled) return;
              onSelectPreset(option, index);
            }}>
            <Subtitle2 grey={presetsDisabled}>
              {option.targetTemperature} °C
            </Subtitle2>
            <Subtitle2 bold grey>
              {option.materialType}
            </Subtitle2>
          </PresetItem>
        ))}
      </TemperaturePresets>
    );
  };

  const renderToggleSection = () => {
    if (inEditMode) {
      return <ToggleSection>{renderPresets()}</ToggleSection>;
    }

    const isActive = targetTemperature > 0;
    const isActivelyPrinting = DeviceUtils.isActivelyPrinting(
      currentDeviceState,
      device
    );
    const isPrintPaused = DeviceUtils.isPrintPaused(currentDeviceState, device);

    return (
      <ToggleSection>
        <TitleTextWrapper>
          <Subtitle2>{part.name}</Subtitle2>
        </TitleTextWrapper>
        <ControlToggle
          active={isActive}
          isDisabled={
            isActivelyPrinting && !isPrintPaused && part.type !== 'bed'
          }
          onClick={() => onToggle(!isActive)}>
          <CurrentTemperatureOnToggle active={isActive}>
            {`${FormatUtils.roundTo(currentTemperature, 1)} °C`}
          </CurrentTemperatureOnToggle>
          <ToggleIcon
            active={isActive}
            isDisabled={
              isActivelyPrinting && !isPrintPaused && part.type !== 'bed'
            }>
            <Icon
              color={
                isActive ? theme.colors.whiteDefault : theme.colors.blackDefault
              }
              src={Icons.fdm.heating}
            />
          </ToggleIcon>
        </ControlToggle>
      </ToggleSection>
    );
  };

  const renderOptionsBar = () => {
    const isActivelyPrinting = DeviceUtils.isActivelyPrinting(
      currentDeviceState,
      device
    );
    const isActive = targetTemperature > 0;
    const incrementDisabled = !isActive || pendingRequests.setTargetTemperature;
    const decrementDisabled =
      incrementDisabled || targetTemperature - OFFSET_STEP <= 0;
    const targetOptionText = `${targetTemperature} °C`;

    if (inEditMode) {
      return (
        <OptionsBar>
          <OffsetButtonWrapper>
            <ActionButton
              clean
              title='Back'
              icon={Icons.basic.arrowLeft}
              onClick={() => {
                // revert input when 'cancelling' out of edit mode
                handleTargetInputChange(targetTemperature);
                setInEditMode(false);
              }}
            />
          </OffsetButtonWrapper>
          <TargetTemperatureInputContainer>
            <OnClickActivationInput
              autoFocus
              type='number'
              min={0}
              gte={true}
              step={1}
              units='°C'
              value={targetInputValue}
              isInvalid={targetInputError}
              onChangeSuccess={(value) => handleTargetInputChange(value)}
              onChangeFailure={(value) => handleTargetInputChange(value, true)}
            />
          </TargetTemperatureInputContainer>
          <OffsetButtonWrapper>
            <ActionButton
              clean
              title='Set target'
              icon={Icons.basic.check}
              disabled={targetInputError}
              onClick={() => onSendInputValue()}
              tooltipProps={{ rightAlign: true }}
            />
          </OffsetButtonWrapper>
        </OptionsBar>
      );
    }

    return (
      <OptionsBar>
        <OffsetButtonWrapper>
          <ActionButton
            clean
            title={`Decrease by ${OFFSET_STEP} °C`}
            icon={Icons.basic.minus}
            disabled={decrementDisabled}
            onClick={() => onOffsetTarget(-OFFSET_STEP)}
          />
        </OffsetButtonWrapper>
        <TargetTemperatureButton>
          <ToolTipWrapper
            tooltip={
              isActivelyPrinting
                ? 'Target temperature'
                : 'Set target temperature'
            }>
            <Button minimal onClick={() => setInEditMode(true)}>
              <PresetButtonText active={isActive && !isActivelyPrinting}>
                {targetOptionText}
              </PresetButtonText>
            </Button>
          </ToolTipWrapper>
        </TargetTemperatureButton>
        <OffsetButtonWrapper>
          <ActionButton
            clean
            title={`Increase by ${OFFSET_STEP} °C`}
            icon={Icons.basic.plus}
            disabled={incrementDisabled}
            onClick={() => onOffsetTarget(OFFSET_STEP)}
            tooltipProps={{ rightAlign: true }}
          />
        </OffsetButtonWrapper>
      </OptionsBar>
    );
  };

  return (
    <StyledContainer>
      {pendingRequests.setTargetTemperature && (
        <SpinnerContainer>
          <Progress circular />
        </SpinnerContainer>
      )}
      {renderToggleSection()}
      {renderOptionsBar()}
    </StyledContainer>
  );
};

export default withTheme(TemperatureController);

import React from 'react';
import { withTheme } from 'styled-components';

import { Content, Margin, ShadowContainer } from './sideBar.styles';

const SideBar = ({ children, onMarginClick = () => {} }) => {
  const handelMarginClick = (e) => {
    e.stopPropagation();
    onMarginClick(e);
  };

  return (
    <ShadowContainer>
      <Margin onClick={handelMarginClick} />
      <Content>{children}</Content>
    </ShadowContainer>
  );
};

export default withTheme(SideBar);

import React, { Component } from 'react';
import _ from 'lodash';

import {
  Content,
  StyleRows,
  StyleRow,
  StyleRowLabel,
  StyleRowLabelTitle,
  StyleRowLabelHighlights,
  HeaderLabelWrapper,
  ActionButtonWrapper,
} from './configureStyle.styles';
import { InterfaceUtils } from '../../../utils';

import { Button, Checkbox, H6 } from '../../../shared';

class SelectStyleImports extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { parsedProfile } = this.props;
    this.setState({
      styles: parsedProfile.styles,
      selected: new Array(parsedProfile.styles.length).fill(true),
    });
  }

  componentDidUpdate(prevProps) {
    const needsRedirect =
      prevProps.requestSuccess !== this.props.requestSuccess;
    if (needsRedirect) {
      this.handleRedirect();
    }
  }

  componentWillUnmount() {
    if (this.props.mode === 'import') {
      this.props.resetParsedProfile();
    }
  }

  renderHeader() {
    return (
      <HeaderLabelWrapper>
        <H6>{this.props.title}</H6>
      </HeaderLabelWrapper>
    );
  }

  renderStyleOptions() {
    return (
      <StyleRows>
        {_.map(this.state.styles, (style, index) =>
          this.renderStyleOption(style, index)
        )}
      </StyleRows>
    );
  }

  toggleStyleSelection(index) {
    const newSelections = [...this.state.selected];
    newSelections[index] = !newSelections[index];
    this.setState({
      selected: newSelections,
    });
  }

  renderStyleOption(style, index) {
    const label = (
      <StyleRowLabel>
        <StyleRowLabelTitle>{style.name}</StyleRowLabelTitle>
        <StyleRowLabelHighlights>
          {style.layerHeight} mm layers, {style.infillDensity}% infill, supports{' '}
          {style.useSupport ? 'on' : 'off'}
        </StyleRowLabelHighlights>
      </StyleRowLabel>
    );
    return (
      <StyleRow key={index}>
        <Checkbox
          label={label}
          value={this.state.selected[index]}
          onChange={() => this.toggleStyleSelection(index)}
        />
      </StyleRow>
    );
  }

  handleSave() {
    const { printer } = this.props;
    const { styles, selected } = this.state;
    const selectedStyles = _.filter(styles, (style, index) => selected[index]);
    this.props.createStyleProfiles(printer.id, selectedStyles);
  }

  renderImportButton() {
    return (
      <ActionButtonWrapper>
        <Button primary onClick={() => this.handleSave()}>
          Import selected
        </Button>
      </ActionButtonWrapper>
    );
  }

  handleRedirect() {
    const { requestSuccess, hideAllStyleModals } = this.props;
    if (requestSuccess) {
      hideAllStyleModals();
    }
  }

  renderMainPanelContent() {
    return (
      <Content>
        {InterfaceUtils.getLoadingSpinner(this.props)}
        {this.renderHeader()}
        {this.renderStyleOptions()}
        {this.renderImportButton()}
      </Content>
    );
  }

  render() {
    return this.renderMainPanelContent();
  }
}

export default SelectStyleImports;

/* eslint-disable max-len */
import React from 'react';

import Routes from '../../router/routes';
import LegalPage from './legalPage.jsx';
import { Article } from './legal.styles';
import { Link } from '../../shared';

class PrivacyPolicy extends LegalPage {
  componentDidMount() {
    const title = 'Privacy Policy';
    document.title = `${title} • Canvas`;
    this.props.updateNavStack([
      { text: title, route: Routes.toPrivacyPolicy() },
    ]);
  }

  renderMainPanelContent() {
    return (
      <Article>
        <header>
          <h1>Canvas Privacy Policy</h1>
          <h3>Last updated: September 8, 2021</h3>
        </header>

        <ol>
          <li>
            <h4>Introduction</h4>
            <ol>
              <li>
                At Mosaic Manufacturing Ltd., including our divisions,
                affiliates and subsidiaries (collectively “
                <strong>Mosaic</strong>”, “<strong>We</strong>” or “
                <strong>Us</strong>” and terms of similar meaning), we are
                committed to your privacy. This means we do not transfer,
                distribute, disclose, rent or sell any of your Personal
                Information to third parties except as provided for in this
                policy (the “<strong>Privacy Policy</strong>”) or as
                specifically consented by you.
              </li>
              <li>
                Mosaic has developed policies and procedures consistent with the{' '}
                <em>
                  Personal Information Protection and Electronic Documents Act
                </em>{' '}
                (“<strong>PIPEDA</strong>”), and other relevant privacy laws. By
                accepting this Privacy Policy in registration or by visiting and
                using the Canvas Platform or website (the “<strong>Site</strong>
                ”) and/or utilizing the services through the Site or our
                products and services (the “<strong>Services</strong>”), you
                expressly consent to our collection, use and disclosure of your
                Personal Information in accordance with this Privacy Policy.
                This Privacy Policy is incorporated into and subject to our
                Terms of Service.
              </li>
              <li>
                This Privacy Policy will be assessed against new technologies,
                business practices and our users’ needs. As we update, expand
                and diversify our products and services, this Privacy Policy may
                evolve and we reserve the right to modify and amend this Privacy
                Policy from time to time in our sole discretion without prior
                notice. Any such amendment(s) will be posted on our Site and
                will be effective as of the date of posting.
              </li>
              <li>
                Please check this Privacy Policy from time to time for updates
                by checking the date of the “Last Update” at the top of this
                document. If we make any material changes to this Privacy
                Policy, we will notify you by email or through your Canvas
                account.
              </li>
            </ol>
          </li>

          <li>
            <h4>Scope</h4>
            <ol>
              <li>
                This Privacy Policy applies to all personal information (“
                <strong>Personal Information</strong>”) collected, used or
                disclosed by Mosaic from our customers (including their
                employees or agents), visitors to the Site and any other persons
                who may provide us with Personal Information (collectively “
                <strong>Users</strong>”). This Privacy Policy also applies to
                information that is about you, but individually does not
                identify you, as described below.
              </li>
            </ol>
          </li>

          <li>
            <h4>Accountability</h4>
            <ol>
              <li>
                Mosaic is responsible for the Personal Information under our
                possession and control. We have designated a Privacy Officer to
                be responsible for our compliance with this Privacy Policy and
                privacy legislation.
              </li>
              <li>
                Mosaic will make known, upon request, the title of the person or
                persons designated to oversee Mosaic’s compliance with this
                Privacy Policy.
              </li>
              <li>
                Mosaic is responsible for Personal Information in its possession
                or custody, including information that has been transferred to a
                third party for processing. Mosaic shall use contractual or
                other means to provide a comparable level of protection while
                the information is being processed by a third party.
              </li>
              <li>
                Mosaic shall implement policies and practices to give effect to
                these principles, including:
                <ul>
                  <li>
                    Implementing procedures to protect Personal Information;
                  </li>
                  <li>
                    Establishing procedures to receive and respond to complaints
                    and inquiries;
                  </li>
                  <li>
                    Training staff and communicating to staff information about
                    the organization’s policies and practices; and
                  </li>
                  <li>
                    Developing information to explain the organization’s
                    policies and procedures.
                  </li>
                </ul>
              </li>
            </ol>
          </li>

          <li>
            <h4>Information We Collect</h4>
            <ol>
              <li className='legal-list-item-skip'>
                <strong>
                  <em>a. Personal Information</em>
                </strong>
              </li>
              <li>
                Personal Information is any information recorded in any form
                that identifies or can identify an individual and includes any
                information provided to us by you or your Users in using our
                Site and Services. Personal Information includes, but is not
                limited to, the following:
                <ul>
                  <li>
                    registration and verification information and user profile
                    information that you provide to us in creating your account
                    to use our Services (e.g. name, address, email address,
                    country of residence);
                  </li>
                  <li>
                    financial and billing information (such as credit card
                    number and expiration date);
                  </li>
                  <li>
                    demographic information (such as age, education, occupation)
                    and information about individual preferences;
                  </li>
                  <li>
                    feedback information about your 3-D prints and rating
                    information;
                  </li>
                  <li>
                    information collected from third party services that you
                    connect to our Service;
                  </li>
                  <li>
                    any communications and Personal Information that you provide
                    through our comment and feedback forms;
                  </li>
                  <li>
                    information that we may collect from your use of our
                    products and services (e.g. material levels); and
                  </li>
                  <li>
                    any information that Mosaic collects from you that is
                    intended to improve and personalize your service experience.
                  </li>
                </ul>
              </li>
              <li className='legal-list-item-skip'>
                <strong>
                  <em>b. Non-Personally Identifiable Information</em>
                </strong>
              </li>
              <li>
                Personal Information does not include information that is
                aggregated or anonymized in such a manner that it cannot be
                connected to you or any User. This may include print success
                percentage aggregated among many Users. Such information by
                itself or together cannot be tracked to a specific individual’s
                personally identifiable information.
              </li>
            </ol>
          </li>

          <li>
            <h4>How do we use Personal Information?</h4>
            <ol>
              <li>
                Mosaic collects Personal Information for the following purposes:
                <ul>
                  <li>to provide Services to you and your Users;</li>
                  <li>
                    to establish and maintain responsible commercial relations
                    and to communicate with you in order to provide service.
                    This includes, but is not limited to, communications
                    relating to billing, payments, advertising, promotion and
                    account verification;
                  </li>
                  <li>
                    to respond to any correspondence you may direct to us;
                  </li>
                  <li>
                    to understand, research, and improve our Services, including
                    understanding User feedback;
                  </li>
                  <li>
                    to provide you with information about new products,
                    services, newsletters, informative e-mails, and research on
                    future product ideas or improvements, where you have agreed
                    to receive such materials;
                  </li>
                  <li>
                    market our products and our services and provide you with
                    special offers that may be of interest to you and to improve
                    your experience on the Site or with our Services;
                  </li>
                  <li>
                    assist us in creating better products and services to meet
                    your needs;
                  </li>
                  <li>provide you with technical support;</li>
                  <li>
                    help you quickly find software, services or product
                    information important to you; send transaction-related
                    communications such as welcome letters and product/service
                    order confirmations. Mosaic may also send you surveys or
                    marketing communications to inform you of new products or
                    services or other information that may be of interest;
                  </li>
                  <li>
                    restrict the availability of some of the products, services
                    and content to certain parts of the world, where required
                    for legal reasons, by using your address, IP address and
                    other information in order to enforce those restrictions;
                  </li>
                  <li>to enforce our Terms of Service and legal agreements;</li>
                  <li>
                    to provide you with system or administrative messages;
                  </li>
                  <li>
                    to carry out other purposes that are disclosed to you and to
                    which you consent; and
                  </li>
                  <li>
                    to carry out any other purpose permitted or required by law.
                  </li>
                </ul>
              </li>
              <li>
                While using the Site and Services, you may have opportunities to
                post reviews or other Personal Information in public forums at
                your own discretion. Please understand that when you post such
                information, it is public and we are not able to protect it from
                other persons who may view the public areas of our website. You
                should exercise caution when deciding to disclose any Personal
                Information in a publicly available forum such as the Web site
                and agree to release, indemnify, and hold harmless Mosaic for
                any damages or claims resulting from any of your postings.
                Mosaic is not obligated to monitor postings and at its
                discretion may remove any content submitted.
              </li>
            </ol>
          </li>

          <li>
            <h4>How do we use Non-Personally Identifiable Information?</h4>
            <ol>
              <li>
                In an ongoing effort to better understand and serve the users of
                our Services, we conduct research on our customer and User
                demographics and behavior based on Personal Information and
                other information that we have collected. This research can be
                used to provide our customers with statistics and metrics that
                may be of interest to them, such as improved default printer
                profiles. This research will be compiled and analyzed on an
                aggregate basis and this aggregate does not identify any
                individual and therefore is considered and treated as
                Non-Personally Identifiable Information under this Privacy
                Policy.
              </li>
            </ol>
          </li>

          <li>
            <h4>Consent</h4>
            <ol>
              <li>
                We respect your privacy and, unless otherwise required by law,
                we will not collect, use, transfer or disclose your Personal
                Information without your prior consent. Your consent may be
                expressed or implied. You may expressly give your consent in
                writing, verbally or through any electronic means. In certain
                circumstances, your consent may be implied by your actions.
              </li>
              <li>
                Where appropriate, Mosaic will generally seek consent for the
                use or disclosure of the information at the time of collection.
                In certain circumstances, consent with respect to use or
                disclosure may be sought after the information has been
                collected but before use (for example, when Mosaic wants to use
                information for a purpose other than those identified above). In
                obtaining consent, Mosaic will use reasonable efforts to ensure
                that a customer is advised of the identified purposes for which
                Personal Information collected will be used or disclosed.
              </li>
              <li>
                The form of consent sought by Mosaic may vary, depending upon
                the circumstances and type of information disclosed. In
                determining the appropriate form of consent, Mosaic shall take
                into account the sensitivity of the Personal Information and the
                reasonable expectations of our customers and Users. Mosaic will
                seek express consent when the information is likely to be
                considered sensitive. Implied consent will generally be
                appropriate where the information is less sensitive.
              </li>
              <li>
                Where you have provided your consent to the collection, use,
                transfer and disclosure of your Personal Information for a
                specific purpose, you have the right to withdraw consent for
                that specific purpose at any time. To withdraw your consent,
                please send Us an email at{' '}
                <Link href='mailto:privacy@mosaicmanufacturing.com'>
                  privacy@mosaicmanufacturing.com
                </Link>
                . Once We have received notification that you have withdrawn
                your consent, we will no longer process your Personal
                Information for the purpose or purposes you originally agreed
                to, unless We have another legitimate legal basis for doing so.
                However, withdrawing consent may result in our inability to
                provide you with certain Services.
              </li>
            </ol>
          </li>

          <li>
            <h4>Limiting Collection</h4>
            <ol>
              <li>
                Mosaic will limit the collection of Personal Information to that
                which is necessary for the purposes identified by Mosaic. Mosaic
                will collect information by fair and lawful means. Mosaic
                collects Personal Information from our Clients for the purposes
                described under section 5.
              </li>
            </ol>
          </li>

          <li>
            <h4>Limiting Use, Disclosure and Retention</h4>
            <ol>
              <li>
                Unless required by law, or in connection with a business
                transaction, Mosaic shall not use or disclose or transfer
                Personal Information for any purpose other than those described
                above without first identifying and documenting the new purpose
                and obtaining your consent, where such consent may not
                reasonably be implied.
              </li>
              <li>
                As noted above, Mosaic does not disclose your Personal
                Information to third parties. Notwithstanding, your Personal
                Information may be transferred to third party suppliers,
                contractors and agents (“<strong>Affiliates</strong>”) who are
                contracted by Mosaic to assist it in providing and developing
                products and services, for example customer service and user
                support, and payment processing. Such Affiliates will only use
                your Personal Information for the purposes identified in this
                Privacy Policy. Such Affiliates may be located outside of Canada
                and the United States, and your Personal Information may be
                collected, used, disclosed, stored and processed in such
                jurisdictions for the purposes described in this Privacy Policy.
                Mosaic takes reasonable contractual or other measures to protect
                your Personal Information while processed or handled by such
                Affiliates. If your Personal Information is located outside
                Canada or the United States it will be subject to legal
                requirements in those foreign countries applicable to our
                service providers, for example, lawful requirements to disclose
                Personal Information to government authorities in those
                countries.
              </li>
              <li>
                In the event your Personal Information is disclosed to a third
                party pursuant to a business transaction (including a potential
                sale or financing), Mosaic will ensure that it has entered into
                an agreement under which the collection, use and disclosure of
                the information is related to those purposes that relate to the
                transaction.
              </li>
              <li>
                Mosaic may also use, or disclose your Personal Information to
                third parties, if we have reason to believe that using or
                disclosing such information is necessary to: (i) conduct
                investigations of possible breaches of law; (ii) identify,
                contact, or bring legal action against someone who may be
                violating an agreement they have with us; (iii) investigate
                security breaches or cooperate with government authorities
                pursuant to a legal matter or (iv) to protect our rights, safety
                or property.
              </li>
              <li>
                Subject to the foregoing, only Mosaic’s and our Affiliates’
                employees with a business need to know, or whose duties
                reasonably so require, are granted access to Personal
                Information about our Users. All such employees will be required
                as a condition of employment to contractually respect the
                confidentiality of your Personal Information.
              </li>
              <li>
                Mosaic will retain Personal Information for only as long as
                required to fulfill the identified purposes or as required by
                law. Personal Information that is no longer required to fulfill
                the identified purposes will be destroyed, erased or made
                anonymous according to the guidelines and procedures established
                by Mosaic.
              </li>
            </ol>
          </li>

          <li>
            <h4>Using the Site &amp; Cookies</h4>
            <ol>
              <li>
                In order to improve the Site, we may use small files commonly
                known as “cookies”. A cookie is a small amount of data, which
                often includes a unique identifier that is sent to your computer
                or mobile phone (your “<strong>device</strong>”) from the Site
                and is stored on your device’s browser or hard drive. Cookies
                are used in analyzing trends, administering the Site, tracking
                users’ movements around the Site and to gather demographic
                information about our user base as a whole. We may generate
                reports based on the use of these technologies an aggregated
                basis
              </li>
              <li>
                The cookies we use on the Site won’t collect personally
                identifiable information about you and we won’t disclose
                information stored in cookies that we place on your device to
                third parties. We use cookies to remember users’ settings, store
                login addresses, authenticate users, run website experiments,
                and store analytics data, such as browser, device type and
                operating system.
              </li>
              <li>
                Users can control the use of cookies at the individual browser
                level. If you reject cookies, you may still use our site, but
                your ability to use some features or areas of our site may be
                limited.
              </li>
              <li>
                You can find more information about how to do manage cookies for
                all the commonly used internet browsers by visiting{' '}
                <Link external href='https://allaboutcookies.org'>
                  www.allaboutcookies.org
                </Link>
                . This website will also explain how you can delete cookies
                which are already stored on your device.
              </li>
              <li className='legal-list-item-skip'>
                <u>Cookies from third parties</u>
              </li>
              <li>
                We use Google Analytics, a web analytics service provided by
                Google Inc. (“<strong>Google</strong>”) on the Site. Google
                Analytics uses “cookies”, which are text files placed on your
                computer, to help the Site analyse how users use the Site. The
                information generated by the cookie about your use of the Site
                (including your IP address) will be transmitted to and stored by
                Google on servers in the United States. Google will use this
                information for the purpose of evaluating your use of the Site,
                compiling reports on website activity for website operators and
                providing other services relating to website activity and
                Internet usage. Google may also transfer this information to
                third parties where required to do so by law, or where such
                third parties process the information on Google’s behalf. Google
                will not associate your IP address with any other data held by
                Google. You may refuse the use of cookies by selecting the
                appropriate settings on your browser, however please note that
                if you do this you may not be able to use the full functionality
                of the Sites. By using the Site, you consent to the processing
                of data about you by Google in the manner and for the purposes
                set out above.
              </li>
              <li>
                We may use additional third party and such parties will have
                their own privacy policies describing how they use information
                that they collect.
              </li>
            </ol>
          </li>

          <li>
            <h4>How can I Access my Personal Information?</h4>
            <ol>
              <li>
                If you have any questions about this Privacy Policy or have any
                concerns about how we manage your Personal Information, please
                contact our Privacy Officer as follows:
                <p>
                  Attention: Privacy Officer
                  <br />
                  480 University Avenue, Suite 802
                  <br />
                  Toronto, Ontario
                  <br />
                  M5G1V2
                  <br />
                  Phone: <Link href='tel:+16135837132'>+1 613 583 7132</Link>
                  <br />
                  Email:{' '}
                  <Link href='mailto:privacy@mosaicmanufacturing.com'>
                    privacy@mosaicmanufacturing.com
                  </Link>
                </p>
              </li>
              <li>
                Mosaic will endeavor to answer your questions and advise you of
                any steps taken to address the issues raised by you. If you are
                dissatisfied with our response, you may be entitled to make a
                written submission to the Federal Privacy Commissioner (Canada)
                or other relevant privacy or data protection authorities. Mosaic
                will maintain procedures for addressing and responding to all
                inquiries or complaints from users about Mosaic’s handling of
                Personal Information. Mosaic shall investigate all complaints.
              </li>
              <li>
                Upon written request, Mosaic will provide you with information
                about what Personal Information we may have about you. To
                review, delete and update your Personal Information to ensure it
                is accurate, please contact our Privacy Officer using the
                information in section 11.1.
              </li>
              <li>
                We will respond to your request within a reasonable time and at
                minimal or no cost to the individual.
                <br />
                Mosaic may ask you for further information from you to help Us
                respond to your request.
              </li>
              <li>
                You may challenge the accuracy and completeness of the
                information and have it amended as appropriate. You may also
                request that we erase your Personal Information where there is
                no good reason for Mosaic to continue to process or use it.
                Note, however, that Mosaic may not always be able to comply with
                your request of erasure for specific legal reasons, which will
                be provided to you, if applicable, at the time of your request.
              </li>
              <li>
                <strong>NOTE</strong>: In certain circumstances, Mosaic may not
                be able to provide access to all the Personal Information it
                holds about a User. Exceptions may include information that is
                prohibitively costly to provide, information that contains
                references to other individuals, information that cannot be
                disclosed for legal, security or commercial proprietary reasons,
                or information that is subject to solicitor-client or litigation
                privilege. Mosaic will provide the reasons for denying access
                upon request.
              </li>
            </ol>
          </li>

          <li>
            <h4>Safeguards</h4>
            <ol>
              <li>
                We take administrative, technical and physical measures to
                safeguard your Personal Information against unauthorized access,
                unauthorized disclosure, theft and misuse. Mosaic stores and
                processes your Personal Information on computers principally in
                Canada as well as internationally. Mosaic employs reasonable
                physical, organizational, managerial and technical measures to
                help ensure that your Personal Information is secure. In
                addition, our dedicated team of information technology
                professionals works to maintain data accuracy and help prevent
                unauthorized access to sensitive information. Personal
                Information may only be accessed by persons within our
                organization, or our third-party service providers, who require
                such access to carry out the purposes indicated above.
              </li>
              <li>
                Unfortunately, no security system can be guaranteed to be 100%
                secure. Accordingly, we cannot guarantee the security of your
                Personal Information and cannot assume liability for improper
                access to it. The Internet is not a secure medium and you
                acknowledge and agree that the privacy of your e-mail
                communication and Personal Information can never be guaranteed
                as any e-mail communication may be lost, intercepted or altered.
                By using our Site, or providing Personal Information to us
                through any means, you agree that we may communicate with you
                electronically regarding security, privacy, and administrative
                issues relating to your use of our Site and our Services.
              </li>
            </ol>
          </li>

          <li>
            <h4>Interpretation of this Privacy Policy</h4>
            <ol>
              <li>
                This Privacy Policy does not create or confer upon any
                individual any rights, or impose upon Mosaic any obligations, in
                addition to any rights or obligations imposed by Canada’s
                applicable federal and provincial privacy laws. Should there be
                any inconsistency between this Privacy Policy and Canada’s
                applicable federal and provincial privacy laws, this Privacy
                Policy will be interpreted in a manner that complies with the
                applicable privacy laws.
              </li>
              <li>
                The Site and services provided by Mosaic are controlled and
                operated by us from Canada, and we do not intend that our
                services subject us to the laws or jurisdiction of any state,
                country or territory other than that of Canada. We do not
                represent or warrant that the Site and services are appropriate
                or available for use in any particular jurisdiction. Those who
                choose to access the Site and services do so on their own
                initiative and at their own risk, and are responsible for
                complying with all local laws, rules and regulations. We may
                limit the Site and services availability, in whole or in part,
                to any person, geographic area or jurisdiction we choose, at any
                time and in our sole discretion.
              </li>
            </ol>
          </li>

          <li>
            <h4>Children</h4>
            <ol>
              <li>
                Our Site and the Services offered on our Site are not directed
                to children under the age of 13 years. We will not knowingly
                collect Personal Information about a child under the age of 13.
                No one under age 13 is allowed to register for the products or
                services, provide any personally identifiable information or use
                our social, community and public discussion areas, photo and
                video galleries, bulletin boards, forums, chats, blogs,
                personal/job search and other classified ads, and elsewhere.
                Minors between the ages of 13 and 17 or 18, inclusive, must get
                the permission of their parent(s) or legal guardian(s) before
                making purchases, including subscriptions.
              </li>
            </ol>
          </li>

          <li>
            <h4>Hyperlinks</h4>
            <ol>
              <li>
                Our Web sites may be connected by “hyperlinks” to other
                websites. You acknowledge and agree that Mosaic makes no
                representation, warranty, covenant, or claim regarding, and
                Mosaic expressly disclaims and denies any responsibility or
                liability for, directly or indirectly, the privacy practices on
                any third-party website or resource accessible by hyperlink from
                our Web sites. You agree that the inclusion of any such
                hyperlink does not suggest, represent, warrant, covenant or
                imply that Mosaic monitors, endorses or exerts any control over
                such website or linked resource whatsoever. You agree that
                Mosaic provides these hyperlinks to you only as a convenience,
                and the inclusion of any hyperlink does not suggest, represent,
                warrant, covenant or imply affiliation, endorsement or adoption
                by Mosaic of any such website or linked resource whatsoever.
              </li>
              <li>
                When you leave our Site, you acknowledge and agree that our
                terms and policies do not govern your access to, and use of,
                other websites. You acknowledge our recommendation to review the
                applicable privacy and data gathering practices, of any website
                or linked resource to which you navigate from our Site.
              </li>
            </ol>
          </li>

          <li>
            <h4>General</h4>
            <ol>
              <li>
                Mosaic reserves the right to modify and amend this privacy
                policy from time to time in its sole discretion without prior
                notice. Any such amendment(s) will be posted on our Site and
                will be effective as of the date of posting.
              </li>
            </ol>
          </li>
        </ol>
      </Article>
    );
  }
}

export default PrivacyPolicy;

import React, { Component } from 'react';
import _ from 'lodash';
import { withTheme } from 'styled-components';

import { Button, Icon } from '../../../../shared';

import {
  Container,
  TaskListHeader,
  TaskListTitle,
  TaskStatuses,
  StatusBubble,
  TaskContainer,
  TaskContent,
  TaskTitleWrapper,
  TaskButtons,
  IconWrapper,
  SkipButtonWrapper,
} from './taskList.styles';
import Icons from '../../../../themes/icons';

import { TaskStatus } from '../../../../reducers/onboarding/constants';

import { Subtitle1, Subtitle2 } from '../../../../shared/typography/typography';

class TaskList extends Component {
  static defaultProps = {
    identifier: '',
    title: 'Tasks',
    steps: {},
    stepOrder: [],
    taskStatus: {},
    collapsed: false,
    onHeaderClick: () => {},
    setTaskStepStatus: () => {},
  };

  constructor(props) {
    super(props);
    this.onCurrentStepSkip = this.onCurrentStepSkip.bind(this);
    this.onCurrentStepProceed = this.onCurrentStepProceed.bind(this);
  }

  isLastStep(step) {
    if (this.props.stepOrder.length === 0) return false;
    return step === this.props.stepOrder[this.props.stepOrder.length - 1];
  }

  getCurrentStepName() {
    for (let i = 0; i < this.props.stepOrder.length; i++) {
      const stepName = this.props.stepOrder[i];
      if (this.props.taskStatus.steps[stepName] === TaskStatus.INCOMPLETE) {
        return stepName;
      }
    }
    return null;
  }

  onStatusBubbleClick(e, step) {
    // prevent this click from collapsing the TaskList
    e.stopPropagation();
    // do nothing if the clicked step is on an incomplete task
    if (this.props.taskStatus.steps[step] === TaskStatus.INCOMPLETE) return;
    // mark all steps from the current one to the clicked one as incomplete
    const currentStep = this.getCurrentStepName();
    const stepIndex = this.props.stepOrder.indexOf(step);
    const currentStepIndex = this.props.stepOrder.indexOf(currentStep);
    const updatedStatuses = {};
    for (let i = currentStepIndex; i >= stepIndex; i--) {
      const ithStep = this.props.stepOrder[i];
      updatedStatuses[ithStep] = TaskStatus.INCOMPLETE;
    }
    this.props.setTaskStepStatus(this.props.identifier, updatedStatuses);
  }

  renderTaskStatusIcon(step, status) {
    if (status === TaskStatus.INCOMPLETE) return null;
    return (
      <IconWrapper>
        <Icon
          size={Icon.sizes.SMALL}
          src={Icons.basic.check}
          color={this.props.theme.colors.elevatedBackgroundPrimary}
        />
      </IconWrapper>
    );
  }

  renderTaskStatusBubble(step, currentStep) {
    const status = this.props.taskStatus.steps[step];
    return (
      <StatusBubble
        key={step}
        status={status}
        active={step === currentStep}
        onClick={(e) => this.onStatusBubbleClick(e, step)}>
        {this.renderTaskStatusIcon(step, status)}
      </StatusBubble>
    );
  }

  renderHeader() {
    if (this.props.stepOrder.length === 0) return null;
    const currentStep = this.getCurrentStepName();
    return (
      <TaskListHeader onClick={this.props.onHeaderClick}>
        <TaskStatuses>
          {_.map(this.props.stepOrder, (step) =>
            this.renderTaskStatusBubble(step, currentStep)
          )}
        </TaskStatuses>
        <TaskListTitle>
          <Subtitle2 green>{this.props.title}</Subtitle2>
        </TaskListTitle>
      </TaskListHeader>
    );
  }

  onCurrentStepProceed() {
    const currentStep = this.getCurrentStepName();
    // mark current step as complete
    this.props.setTaskStepStatus(this.props.identifier, {
      [currentStep]: TaskStatus.COMPLETE,
    });
    // call step's custom handler, if present
    if (this.props.steps[currentStep].onProceed) {
      this.props.steps[currentStep].onProceed(this.props);
    }
  }

  onCurrentStepSkip() {
    const currentStep = this.getCurrentStepName();
    // mark current step as skipped
    this.props.setTaskStepStatus(this.props.identifier, {
      [currentStep]: TaskStatus.SKIPPED,
    });
  }

  renderCurrentStepProceedButton() {
    const currentStep = this.getCurrentStepName();
    const isLastStep = this.isLastStep(currentStep);
    const step = this.props.steps[currentStep];
    const label = step.proceedLabel || (isLastStep ? 'Done' : 'Next');
    return (
      <Button
        primary
        expand
        height='2.5rem'
        onClick={this.onCurrentStepProceed}>
        {label}
      </Button>
    );
  }

  renderCurrentStepSkipButton() {
    const currentStep = this.getCurrentStepName();
    const isLastStep = this.isLastStep(currentStep);
    if (isLastStep) return null;
    const step = this.props.steps[currentStep];
    const label = step.skipLabel || 'Skip';
    return (
      <SkipButtonWrapper>
        <Button
          minimal
          minWidth='4rem'
          height='2.5rem'
          onClick={this.onCurrentStepSkip}>
          {label}
        </Button>
      </SkipButtonWrapper>
    );
  }

  renderCurrentStep() {
    if (this.props.collapsed) return null;
    const currentStep = this.getCurrentStepName();
    const step = this.props.steps[currentStep];
    return (
      <TaskContainer>
        <TaskContent>
          <TaskTitleWrapper>
            <Subtitle1>{step.title}</Subtitle1>
          </TaskTitleWrapper>
          {step.content}
        </TaskContent>
        <TaskButtons>
          {this.renderCurrentStepProceedButton()}
          {this.renderCurrentStepSkipButton()}
        </TaskButtons>
      </TaskContainer>
    );
  }

  render() {
    return (
      <Container>
        {this.renderHeader()}
        {this.renderCurrentStep()}
      </Container>
    );
  }
}

export default withTheme(TaskList);

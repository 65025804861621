import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import { getPaginatedItems } from '../canvas-api';
import { handleGenericError, getAuthState } from '../common';
import { actions } from '../../reducers/printers/printers';
import { sanitizePrinterData } from './common';

export default function* getPrinters(action) {
  try {
    const { userId } = yield select(getAuthState);
    const printers = yield call(
      getPaginatedItems,
      `printers/user/${userId}`,
      'printers'
    );
    if (printers === null) return;
    const sanitizedPrinters = _.map(printers, (printer) =>
      sanitizePrinterData(printer)
    );
    yield put(actions.getPrintersSuccess(sanitizedPrinters));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import React, { Component } from 'react';
import _ from 'lodash';
import { withTheme } from 'styled-components';

import {
  Content,
  IconField,
  IconOption,
  LabelContainer,
  NameField,
  IconsContainer,
  InputWrapper,
} from './configureStyle.styles';
import { Fields } from './forms/fields.metadata';
import Routes from '../../../router/routes';
import { fieldTypes } from '../../../constants';
import { InterfaceUtils } from '../../../utils';
import { defaultPrinter } from '../../../reducers/printers/initialState';

import {
  Body1,
  Button,
  Caption,
  Image,
  Input,
  SettingsModal,
} from '../../../shared';

import StyleSettingsForm from './forms/styleSettingsForm.jsx';

class ConfigureStyle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusField: null,
      values: {
        ...defaultPrinter.styles[0],
      },
      errors: {},
    };
  }

  componentDidMount() {
    const {
      mode,
      printers,
      printerId,
      history,
      parsedProfile,
      styleId,
      showSelectImportStylesModal,
      hideAllStyleModals,
    } = this.props;
    const printer = printers[printerId];
    if (!printer) {
      history.push(Routes.toViewPrinter(printerId));
      return;
    }

    if (printer.tagId) {
      this.props.getPrinterTag(printer.tagId);
    }

    if (mode === 'edit') {
      const styleToEdit = _.find(
        printer.styles,
        (style) => style.id === styleId
      );
      this.setState({
        values: {
          ...styleToEdit,
        },
        errors: {},
      });
    } else if (mode === 'import') {
      if (parsedProfile.styles.length > 1) {
        hideAllStyleModals();
        showSelectImportStylesModal();
      } else {
        this.setState({
          values: {
            ...parsedProfile.styles[0],
          },
          errors: {},
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const needsRedirect =
      prevProps.requestSuccess !== this.props.requestSuccess;
    if (needsRedirect) {
      this.props.hideAllStyleModals();
    }
  }

  componentWillUnmount() {
    if (this.props.mode === 'import') {
      this.props.resetParsedProfile();
    }
  }

  focusField(fieldName) {
    this.setState({ focusField: fieldName }, () => {
      this.setState({ focusField: null });
    });
  }

  updateField(fieldName, value, error = false) {
    this.setState(
      {
        values: {
          ...this.state.values,
          [fieldName]: value,
        },
        errors: {
          ...this.state.errors,
          [fieldName]: error,
        },
      },
      () => {}
    );
  }

  updateFieldSuccess(fieldName, value) {
    this.updateField(fieldName, value, false);
  }

  updateFieldFailure(fieldName, value) {
    this.updateField(fieldName, value, true);
  }

  getNextSaveError() {
    return _.findKey(this.state.errors) || null;
  }

  handleSave() {
    const fieldName = this.getNextSaveError();
    if (fieldName) {
      this.focusField(fieldName);
      return;
    }

    const { mode, printerId, styleId } = this.props;
    if (mode === 'create' || mode === 'import') {
      this.props.createStyleProfiles(printerId, [this.state.values]);
    } else if (mode === 'edit') {
      this.props.updateStyleProfile(printerId, styleId, this.state.values);
    }

    this.props.hideAllStyleModals();
  }

  renderFieldLabel(field) {
    return (
      <LabelContainer key={field.label}>
        <Body1 noSpacing>{field.label}</Body1>
        <Caption grey noSpacing>
          {field.description}
        </Caption>
      </LabelContainer>
    );
  }

  renderRenameInput(field) {
    return (
      <InputWrapper>
        <Input
          type='text'
          key={field.label}
          value={this.state.values[field.name]}
          isInvalid={this.state.errors[field.name]}
          forceFocus={this.state.focusField === field.name}
          onChangeSuccess={(value) =>
            this.updateFieldSuccess(field.name, value)
          }
          onChangeFailure={(value) =>
            this.updateFieldFailure(field.name, value)
          }
        />
      </InputWrapper>
    );
  }

  renderPrinterIcon(option) {
    return (
      <IconOption
        key={option}
        option={option}
        onClick={() => this.updateFieldSuccess('icon', option)}
        isCurrent={option === this.state.values.icon}>
        <Image
          alt='Style icon'
          src={this.props.theme.images.styleIcons[option]}
        />
      </IconOption>
    );
  }

  renderPrinterIcons(field) {
    return (
      <IconsContainer>
        {_.map(field.options, (option) => this.renderPrinterIcon(option))}
      </IconsContainer>
    );
  }

  renderNameField(field) {
    return (
      <NameField key={field.name}>
        {this.renderFieldLabel(field)}
        {this.renderRenameInput(field)}
      </NameField>
    );
  }

  renderIconField(field) {
    return (
      <IconField key={field.name}>
        {this.renderFieldLabel(field)}
        {this.renderPrinterIcons(field)}
      </IconField>
    );
  }

  renderField(field) {
    switch (field.type) {
      case fieldTypes.text:
        return this.renderNameField(field);
      case fieldTypes.icon:
        return this.renderIconField(field);
      default:
        return null;
    }
  }

  renderProfileFields() {
    const fields = _.map(Fields, (field) => this.renderField(field));
    return fields;
  }

  renderStyleSettingsForm() {
    return (
      <StyleSettingsForm
        style={this.state.values}
        errors={this.state.errors}
        focusField={this.state.focusField}
        printerTag={this.props.printerTag}
        updateFieldSuccess={(fieldName, value) =>
          this.updateFieldSuccess(fieldName, value)
        }
        updateFieldFailure={(fieldName, value) =>
          this.updateFieldFailure(fieldName, value)
        }
      />
    );
  }

  renderHeaderButtons() {
    return (
      <>
        <Button onClick={() => this.props.hideAllStyleModals()}>Cancel</Button>
        <Button primary onClick={() => this.handleSave()}>
          Save style profile
        </Button>
      </>
    );
  }

  renderModalContent() {
    return (
      <Content>
        {InterfaceUtils.getLoadingSpinner(this.props)}

        {this.renderProfileFields()}
        {this.renderStyleSettingsForm()}
      </Content>
    );
  }

  render() {
    return (
      <SettingsModal
        width='47.25rem'
        height='48rem'
        headerTitle={this.props.title}
        headerButtons={this.renderHeaderButtons()}
        sideBarContent={this.renderModalContent()}
      />
    );
  }
}

export default withTheme(ConfigureStyle);

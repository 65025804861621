import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: absolute;
  bottom: ${(props) => props.bottomOffset};
  right: 1rem;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

const commonImageStyles = css`
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ImageContainer = styled.div`
  margin-top: 0.75rem;
  flex: 1;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.elevatedBackgroundSecondary};
  border-radius: 0.25rem;
  padding: 0.5rem;
`;

export const PrinterImage = styled.div`
  ${commonImageStyles};
  background-image: url(${(props) =>
    props.theme.images.onboarding.printerOnboarding});
`;

export const HubImage = styled.div`
  ${commonImageStyles};
  background-image: url(${(props) => props.theme.images.onboarding.controlHub});
`;

export const AddPrinterImage = styled.div`
  ${commonImageStyles};
  background-image: url(${(props) => props.theme.images.onboarding.addPrinter});
`;

export const AddDeviceImage = styled.div`
  ${commonImageStyles};
  background-image: url(${(props) => props.theme.images.onboarding.addDevice});
`;

export const FourInputBenchyImage = styled.div`
  ${commonImageStyles};
  background-image: url(${(props) =>
    props.theme.images.onboarding.fourInputBenchy});
`;

export const HereToHelpImage = styled.div`
  ${commonImageStyles};
  background-image: url(${(props) => props.theme.images.onboarding.hereToHelp});
`;

export const SendToP3Image = styled.div`
  ${commonImageStyles};
  background-image: url(${(props) => props.theme.images.onboarding.sendToP3});
`;

export const ControlHubImage = styled.div`
  ${commonImageStyles};
  background-image: url(${(props) => props.theme.images.onboarding.controlHub});
`;

export const ControlPaletteImage = styled.div`
  ${commonImageStyles};
  background-image: url(${(props) =>
    props.theme.images.onboarding.controlPalette});
`;

export const ConnectionSettingsImage = styled.div`
  ${commonImageStyles};
  background-image: url(${(props) =>
    props.theme.images.onboarding.connectionSettings});
`;

export const AdminPanelImage = styled.div`
  ${commonImageStyles};
  background-image: url(${(props) => props.theme.images.onboarding.adminPanel});
`;

import React from 'react';
import _ from 'lodash';

import { InlineCode as C } from '../docs/code.jsx';
import { FunctionTable, FunctionTH, FunctionTD } from '../docs/styles';

const machineSettingsLocals = [
  [
    'comments',
    'boolean',
    <>
      <C>1</C> if output should contain comments
    </>,
  ],
  ['nozzleDiameter', 'mm', 'Nozzle diameter of the printing extruder'],
  ['filamentDiameter', 'mm', 'Filament diameter of the printing extruder'],
  ['bowdenTubeLength', 'mm', 'Distance from drive gear to nozzle'],
  ['bedSizeX', 'mm', 'X size of the print bed'],
  ['bedSizeY', 'mm', 'Y size of the print bed'],
  ['bedSizeZ', 'mm', 'Z size of the print bed'],
];

const styleSettingsLocals = [
  // general
  ['shellThickness', 'mm', ''],
  ['topShellThickness', 'mm', 'Slic3r only'],
  ['solidLayerSpeed', 'mm/s', ''],
  ['semiSolidLayers', 'boolean', 'KISSlicer only'],
  [
    'solidLayerStyle',
    null,
    <>
      Slic3r only
      <br />
      0 = rectilinear
      <br />
      1 = monotonic
      <br />2 = concentric
    </>,
  ],
  [
    'topSolidLayerStyle',
    null,
    <>
      Slic3r only
      <br />
      See <C>solidLayerStyle</C>
    </>,
  ],
  ['avoidBacktrack', 'boolean', 'KISSlicer only'],
  ['useIroning', 'boolean', 'Slic3r only'],
  ['ironingFlowrate', '%', 'Slic3r only'],
  ['ironingSpacing', 'mm', 'Slic3r only'],
  ['ironingSpeed', 'mm/s', 'Slic3r only'],
  ['xyTravelSpeed', 'mm/s', ''],
  ['zTravelSpeed', 'mm/s', ''],
  ['avoidCrossingPerimeters', 'boolean', 'Slic3r only'],
  // layer height
  ['layerHeight', 'mm', ''],
  ['firstLayerHeight', 'mm', ''],
  ['maxLayerHeight', 'mm', 'KISSlicer only'],
  ['supportedStepWidth', 'mm', 'KISSlicer only'],
  ['unsupportedStepWidth', 'mm', 'KISSlicer only'],
  // extrusion
  ['extrusionWidth', 'mm', ''],
  ['extrusionMultiplier', '%', ''],
  ['useGapFill', 'boolean', ''],
  ['gapFillThreshold', 'mm', 'KISSlicer only'],
  ['retractDistance', 'mm', ''],
  ['retractSpeed', 'mm/s', ''],
  ['retractDisableThreshold', 'mm', ''],
  ['retractForceThreshold', 'mm', 'KISSlicer only'],
  ['wipeDistance', 'mm', ''],
  ['zLiftDistance', 'mm', ''],
  // perimeters
  ['perimeterCount', null, ''],
  ['perimetersOutsideIn', 'boolean', ''],
  ['perimeterSpeed', 'mm/s', ''],
  ['perimeterExtrusionWidth', 'mm', 'Slic3r only'],
  ['externalPerimeterSpeed', 'mm/s', ''],
  ['externalPerimeterExtrusionWidth', 'mm', 'Slic3r only'],
  ['coastDistance', 'mm', 'KISSlicer only'],
  ['cornerSeams', 'boolean', ''],
  ['seamAngle', '°', ''],
  ['seamJitter', '°', 'KISSlicer only'],
  // infill
  ['infillDensity', '%', ''],
  [
    'infillStyle',
    null,
    <>
      0 = straight
      <br />
      1 = octagonal
      <br />
      2 = rounded
      <br />3 = cellular
    </>,
  ],
  ['vaseMode', 'boolean', ''],
  ['infillExtrusionWidth', 'mm', ''],
  ['infillSpeed', 'mm/s', ''],
  ['infillPerimeterOverlap', '%', ''],
  // temperature
  ['printTemperature', '°C', ''],
  ['firstLayerPrintTemperature', '°C', ''],
  ['bedTemperature', '°C', ''],
  ['minLayerTime', 's', ''],
  ['useFan', 'boolean', ''],
  ['enableFanAtLayer', null, '0-indexed'],
  ['fanSpeed', '%', ''],
  ['perimeterFanSpeed', '%', 'KISSlicer only'],
  ['maxBridgingSpeed', 'mm/s', ''],
  // first layer
  ['zOffset', 'mm', ''],
  ['firstLayerSpeed', 'mm/s', ''],
  ['firstLayerSizeCompensation', 'mm', 'Slic3r only'],
  ['brimCount', null, ''],
  ['brimLayers', null, ''],
  ['brimGap', 'mm', ''],
  ['useRaft', 'boolean', ''],
  ['raftZGap', 'mm', 'KISSlicer only'],
  ['raftXYInflation', 'mm', ''],
  ['raftTopSpeed', 'mm/s', 'KISSlicer only'],
  ['raftTopLayerHeight', 'mm', 'KISSlicer only'],
  ['raftTopExtrusionWidth', 'mm', 'KISSlicer only'],
  ['raftTopDensity', '%', 'KISSlicer only'],
  ['raftBottomSpeed', 'mm/s', 'KISSlicer only'],
  ['raftBottomLayerHeight', 'mm', 'KISSlicer only'],
  ['raftBottomExtrusionWidth', 'mm', 'KISSlicer only'],
  ['raftBottomDensity', '%', ''],
  ['useRaftInterface', 'boolean', 'KISSlicer only'],
  // supports
  ['useSupport', 'boolean', ''],
  ['supportSpeed', 'mm/s', 'Slic3r only'],
  [
    'supportDensity',
    null,
    <>
      1 = low
      <br />
      2 = medium
      <br />3 = high
    </>,
  ],
  ['useCustomSupport', 'boolean', ''],
  ['maxOverhang', '°', ''],
  ['supportXYGap', 'mm', ''],
  ['supportZGap', 'mm', ''],
  ['supportXYInflation', 'mm', 'KISSlicer only'],
  ['useSupportInterface', 'boolean', ''],
  ['interfaceDensity', '%', ''],
  ['interfaceSpeed', 'mm/s', 'Slic3r only'],
  ['interfaceExtrusionWidth', 'mm', ''],
  ['interfaceThickness', 'mm', ''],
  ['supportReinforcementInterval', null, 'KISSlicer only'],
  // transitions
  [
    'transitionMethod',
    null,
    <>
      1 = transition tower
      <br />2 = side transitions
    </>,
  ],
  ['transitionLength', 'mm', ''],
  ['transitionUsingInfill', 'boolean', ''],
  ['transitionTarget', '%', ''],
  ['sideTransitionSpeed', 'mm/s', ''],
  ['minTowerDensity', '%', ''],
  ['minTowerBottomDensity', '%', ''],
  ['maxTowerDensity', '%', ''],
  ['minTowerBrimCount', null, ''],
  ['towerSpeed', 'mm/s', ''],
  ['towerExtrusionWidth', 'mm', ''],
];

const miscLocals = [
  ['totalLayers', '', 'Total number of layers in the print'],
  ['totalTime', 's', 'Total estimated print time'],
  ['timeElapsed', 's', 'Estimated print time elapsed'],
  ['currentPrintTemperature', '°C', 'Current extruder temperature'],
  ['currentBedTemperature', '°C', 'Current print bed temperature'],
];

const startLocals = [
  ['layer', null, '0'],
  ['nextX', 'mm', 'Upcoming X position'],
  ['nextY', 'mm', 'Upcoming Y position'],
  ['nextZ', 'mm', 'Upcoming Z position'],
  ['currentPrintTemperature', '°C', '0'],
];

const endLocals = [
  [
    'layer',
    null,
    <>
      Equal to <C>totalLayers</C>
    </>,
  ],
  ['currentX', 'mm', 'Current X position'],
  ['currentY', 'mm', 'Current Y position'],
  ['currentZ', 'mm', 'Current Z position'],
];

const preSideTransitionLocals = [
  ['layer', null, 'Most recent layer number (0-indexed)'],
  ['currentX', 'mm', 'Current X position (on the print)'],
  ['currentY', 'mm', 'Current Y position (on the print)'],
  ['currentZ', 'mm', 'Current Z position (on the print)'],
  ['nextX', 'mm', 'Side transition X coordinate'],
  ['nextY', 'mm', 'Side transition Y coordinate'],
  [
    'nextZ',
    'mm',
    <>
      Equal to <C>currentZ</C>
    </>,
  ],
  ['transitionLength', 'mm', 'Length of the upcoming transition'],
];

const sideTransitionLocals = [
  ['layer', null, 'Most recent layer number (0-indexed)'],
  ['currentX', 'mm', 'Current X position'],
  ['currentY', 'mm', 'Current Y position'],
  ['currentZ', 'mm', 'Current Z position'],
  ['transitionLength', 'mm', 'Length of the current transition'],
];

const postSideTransitionLocals = [
  ['layer', null, 'Upcoming layer number (0-indexed)'],
  ['currentX', 'mm', 'Current X position'],
  ['currentY', 'mm', 'Current Y position'],
  ['currentZ', 'mm', 'Current Z position'],
  ['nextX', 'mm', 'Next X position (on the print)'],
  ['nextY', 'mm', 'Next Y position (on the print)'],
  ['nextZ', 'mm', 'Next Z position (on the print)'],
  ['transitionLength', 'mm', 'Length of the previous transition'],
];

const layerChangeLocals = [
  ['layer', null, 'New layer number (0-indexed)'],
  ['currentX', 'mm', 'Current X position'],
  ['currentY', 'mm', 'Current Y position'],
  ['currentZ', 'mm', 'Current Z position'],
  ['nextX', 'mm', 'Next X position'],
  ['nextY', 'mm', 'Next Y position'],
  ['nextZ', 'mm', 'Next Z position'],
];

const materialChangeLocals = [
  ['layer', null, 'Current layer number (0-indexed)'],
  ['currentX', 'mm', 'Current X position'],
  ['currentY', 'mm', 'Current Y position'],
  ['currentZ', 'mm', 'Current Z position'],
  ['nextX', 'mm', 'Next X position'],
  ['nextY', 'mm', 'Next Y position'],
  ['nextZ', 'mm', 'Next Z position'],
];

const sections = [
  {
    title: 'Explanation',
    content: (
      <>
        <p>
          Canvas makes a number of predefined variables available for use in
          PrinterScript scripts. Most of these variables have a constant value,
          such as those representing the printer and style settings being used
          by the print. These variables are listed in Section 1.
        </p>
        <p>
          Depending on the type of sequence the script is used for (at the start
          of the print, after a side transition, etc.), certain additional
          variables may be available to use, and some variables may be given a
          different value. These variables are listed in Section 2 and always
          take priority over those in Section 1.
        </p>
        <p>
          Note that the variables and values listed below can be overwritten.
          While this means you don&rsquo;t need to worry about collisions with
          variables that exist but you aren&rsquo;t using, keep in mind that
          they may be initialized with non-zero values. You should either try
          and keep your variable names unique, or make a habit of explicitly
          initializing all variables you create.
        </p>
      </>
    ),
  },
  {
    title: 'Order of execution',
    content: (
      <>
        <p>
          At some points in the print, more than one script may be executed
          back-to-back. If more than one script is relevant at a given time, the
          following order is always used:
        </p>
        <ul>
          <li>Start of print</li>
          <li>Before side transitioning</li>
          <li>Material change</li>
          <li>Side transition</li>
          <li>After side transitioning</li>
          <li>Layer change</li>
          <li>End of print</li>
        </ul>
      </>
    ),
  },
  {
    title: 'Constants in all scripts',
    subsections: [
      {
        title: 'Printer settings',
        content: (
          <FunctionTable>
            <thead>
              <tr>
                <FunctionTH>Identifier</FunctionTH>
                <FunctionTH>Units</FunctionTH>
                <FunctionTH>Notes</FunctionTH>
              </tr>
            </thead>
            <tbody>
              {_.map(machineSettingsLocals, (local) => (
                <tr key={local[0]}>
                  <FunctionTD>
                    <C>{local[0]}</C>
                  </FunctionTD>
                  <FunctionTD>{local[1]}</FunctionTD>
                  <FunctionTD>{local[2]}</FunctionTD>
                </tr>
              ))}
            </tbody>
          </FunctionTable>
        ),
      },
      {
        title: 'Style settings',
        content: (
          <FunctionTable>
            <thead>
              <tr>
                <FunctionTH>Identifier</FunctionTH>
                <FunctionTH>Units</FunctionTH>
                <FunctionTH>Notes</FunctionTH>
              </tr>
            </thead>
            <tbody>
              {_.map(styleSettingsLocals, (local) => (
                <tr key={local[0]}>
                  <FunctionTD>
                    <C>{local[0]}</C>
                  </FunctionTD>
                  <FunctionTD>{local[1]}</FunctionTD>
                  <FunctionTD>{local[2]}</FunctionTD>
                </tr>
              ))}
            </tbody>
          </FunctionTable>
        ),
      },
      {
        title: 'Additional variables',
        content: (
          <FunctionTable>
            <thead>
              <tr>
                <FunctionTH>Identifier</FunctionTH>
                <FunctionTH>Units</FunctionTH>
                <FunctionTH>Notes</FunctionTH>
              </tr>
            </thead>
            <tbody>
              {_.map(miscLocals, (local) => (
                <tr key={local[0]}>
                  <FunctionTD>
                    <C>{local[0]}</C>
                  </FunctionTD>
                  <FunctionTD>{local[1]}</FunctionTD>
                  <FunctionTD>{local[2]}</FunctionTD>
                </tr>
              ))}
            </tbody>
          </FunctionTable>
        ),
      },
    ],
  },

  {
    title: 'Sequences',
    subsections: [
      {
        title: 'Start of print',
        id: 'start-of-print',
        content: (
          <>
            <p>
              This script is stored with the printer profile and runs at the
              very start of the print. It can be used to home the print head,
              preheat the extruder and print bed, prime the nozzle, and more.
            </p>
            <p>
              In addition to the variables in Section 1 and below, any material
              overrides from the first material used in the print will be
              applied.
            </p>
            <p>
              If this script does not preheat the extruder, Canvas will
              automatically heat the extruder before this script runs. The same
              will be done for the print bed if the print bed temperature
              setting is not zero but this script does not preheat it.
            </p>
            <p>
              When Palette printing, any filament extruded by this script will
              contribute to the length of the first splice.
            </p>
            <FunctionTable>
              <thead>
                <tr>
                  <FunctionTH>Identifier</FunctionTH>
                  <FunctionTH>Units</FunctionTH>
                  <FunctionTH>Notes</FunctionTH>
                </tr>
              </thead>
              <tbody>
                {_.map(startLocals, (local) => (
                  <tr key={local[0]}>
                    <FunctionTD>
                      <C>{local[0]}</C>
                    </FunctionTD>
                    <FunctionTD>{local[1]}</FunctionTD>
                    <FunctionTD>{local[2]}</FunctionTD>
                  </tr>
                ))}
              </tbody>
            </FunctionTable>
          </>
        ),
      },
      {
        title: 'End of print',
        id: 'end-of-print',
        content: (
          <>
            <p>
              This script is stored with the printer profile and runs at the
              very end of the print. It can be used to move the print head out
              of the way, cool down the extruder and print bed, disable motors,
              and more.
            </p>
            <p>
              In addition to the variables in Section 1 and below, any material
              overrides from the last material used in the print will be
              applied.
            </p>
            <p>
              When Palette printing, any filament extruded by this script will
              contribute to the length of the last splice.
            </p>
            <FunctionTable>
              <thead>
                <tr>
                  <FunctionTH>Identifier</FunctionTH>
                  <FunctionTH>Units</FunctionTH>
                  <FunctionTH>Notes</FunctionTH>
                </tr>
              </thead>
              <tbody>
                {_.map(endLocals, (local) => (
                  <tr key={local[0]}>
                    <FunctionTD>
                      <C>{local[0]}</C>
                    </FunctionTD>
                    <FunctionTD>{local[1]}</FunctionTD>
                    <FunctionTD>{local[2]}</FunctionTD>
                  </tr>
                ))}
              </tbody>
            </FunctionTable>
          </>
        ),
      },
      {
        title: 'Layer change',
        id: 'layer-change',
        content: (
          <>
            <p>
              This script is stored with the printer profile and runs at the
              start of every layer (including the first).
            </p>
            <p>
              In addition to the variables in Section 1 and below, any material
              overrides from the current material used in the print will be
              applied.
            </p>
            <FunctionTable>
              <thead>
                <tr>
                  <FunctionTH>Identifier</FunctionTH>
                  <FunctionTH>Units</FunctionTH>
                  <FunctionTH>Notes</FunctionTH>
                </tr>
              </thead>
              <tbody>
                {_.map(layerChangeLocals, (local) => (
                  <tr key={local[0]}>
                    <FunctionTD>
                      <C>{local[0]}</C>
                    </FunctionTD>
                    <FunctionTD>{local[1]}</FunctionTD>
                    <FunctionTD>{local[2]}</FunctionTD>
                  </tr>
                ))}
              </tbody>
            </FunctionTable>
          </>
        ),
      },
      {
        title: 'Material change',
        id: 'material-change',
        content: (
          <>
            <p>
              This script is stored with a material profile and runs whenever
              switching to that material (including at the start of the print).
            </p>
            <p>
              In addition to the variables in Section 1 and below, any material
              overrides from the material that owns the script will be applied.
            </p>
            <p>
              Canvas will automatically adjust the printer&rsquo;s temperature
              if necessary before this script runs. This script is mainly useful
              for material-specific setup other than temperature changes, such
              as applying a linear advance factor.
            </p>
            <FunctionTable>
              <thead>
                <tr>
                  <FunctionTH>Identifier</FunctionTH>
                  <FunctionTH>Units</FunctionTH>
                  <FunctionTH>Notes</FunctionTH>
                </tr>
              </thead>
              <tbody>
                {_.map(materialChangeLocals, (local) => (
                  <tr key={local[0]}>
                    <FunctionTD>
                      <C>{local[0]}</C>
                    </FunctionTD>
                    <FunctionTD>{local[1]}</FunctionTD>
                    <FunctionTD>{local[2]}</FunctionTD>
                  </tr>
                ))}
              </tbody>
            </FunctionTable>
          </>
        ),
      },
      {
        title: 'Before side transitioning',
        id: 'before-side-transitioning',
        content: (
          <>
            <p>
              This script is stored with the printer profile and runs at the
              start of a side transition when Palette printing with side
              transitions enabled.
            </p>
            <p>
              In addition to the variables in Section 1 and below, any material
              overrides from the last material used in the print will be
              applied.
            </p>
            <p>
              If this script is unused, Canvas automatically handles movement
              from the print to the side transition position. If implemented,
              this script is responsible for moving the print head to the
              correct position for a side transition.
            </p>
            <p>
              Any filament extruded by this script will contribute to the length
              of the current splice being used by the printer (that is, the
              material used before the transition) and does not contribute to
              the calculation of transition length.
            </p>
            <FunctionTable>
              <thead>
                <tr>
                  <FunctionTH>Identifier</FunctionTH>
                  <FunctionTH>Units</FunctionTH>
                  <FunctionTH>Notes</FunctionTH>
                </tr>
              </thead>
              <tbody>
                {_.map(preSideTransitionLocals, (local) => (
                  <tr key={local[0]}>
                    <FunctionTD>
                      <C>{local[0]}</C>
                    </FunctionTD>
                    <FunctionTD>{local[1]}</FunctionTD>
                    <FunctionTD>{local[2]}</FunctionTD>
                  </tr>
                ))}
              </tbody>
            </FunctionTable>
          </>
        ),
      },
      {
        title: 'Side transition',
        id: 'side-transition',
        content: (
          <>
            <p>
              This script is stored with the printer profile and controls the
              side transition itself when Palette printing in connected mode
              with side transitions enabled. This script is not used when
              printing in accessory mode.
            </p>
            <p>
              In addition to the variables in Section 1 and below, any material
              overrides from the next material used in the print will be
              applied.
            </p>
            <p>
              If this script is unused, Canvas automatically generates a
              sequence of extrusion and movement depending on the settings used
              for the print. If implemented, this script is responsible for
              extruding the entirety of the transition.
            </p>
            <p>
              The <C>transitionLength</C> variable reflects the amount of
              extrusion for the current transition, accounting for variable
              transition lengths and infill transitioning.
            </p>
            <p>
              While Canvas will account for discrepancies, this is the minimum
              amount of extrusion that should be generated. If any splice
              created is shorter than Palette&rsquo;s physical minimum, an error
              will occur and slicing will terminate entirely. To avoid this,
              ensure this script always extrudes at least{' '}
              <C>transitionLength</C> mm of filament.
            </p>
            <FunctionTable>
              <thead>
                <tr>
                  <FunctionTH>Identifier</FunctionTH>
                  <FunctionTH>Units</FunctionTH>
                  <FunctionTH>Notes</FunctionTH>
                </tr>
              </thead>
              <tbody>
                {_.map(sideTransitionLocals, (local) => (
                  <tr key={local[0]}>
                    <FunctionTD>
                      <C>{local[0]}</C>
                    </FunctionTD>
                    <FunctionTD>{local[1]}</FunctionTD>
                    <FunctionTD>{local[2]}</FunctionTD>
                  </tr>
                ))}
              </tbody>
            </FunctionTable>
          </>
        ),
      },
      {
        title: 'After side transitioning',
        id: 'after-side-transitioning',
        content: (
          <>
            <p>
              This script is stored with the printer profile and runs at the end
              of a side transition when Palette printing with side transitions
              enabled.
            </p>
            <p>
              In addition to the variables in Section 1 and below, any material
              overrides from the next material used in the print will be
              applied.
            </p>
            <p>
              This script need not move from the side transition position back
              to the print, as Canvas will handle this automatically. It may be
              useful, however, to do some manual movement beforehand, such as to
              move inside the build volume before re-enabling software endstops.
            </p>
            <p>
              Any filament extruded by this script will contribute to the length
              of the current splice being used by the printer (that is, the
              material used after the transition) and does not contribute to the
              calculation of transition length.
            </p>
            <FunctionTable>
              <thead>
                <tr>
                  <FunctionTH>Identifier</FunctionTH>
                  <FunctionTH>Units</FunctionTH>
                  <FunctionTH>Notes</FunctionTH>
                </tr>
              </thead>
              <tbody>
                {_.map(postSideTransitionLocals, (local) => (
                  <tr key={local[0]}>
                    <FunctionTD>
                      <C>{local[0]}</C>
                    </FunctionTD>
                    <FunctionTD>{local[1]}</FunctionTD>
                    <FunctionTD>{local[2]}</FunctionTD>
                  </tr>
                ))}
              </tbody>
            </FunctionTable>
          </>
        ),
      },
    ],
  },
];

export default sections;

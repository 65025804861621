import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';
import { InterfaceUtils } from '../../../utils';

export default function* duplicateProject(action) {
  try {
    const { projectId } = action.payload;

    const response = yield call(API, {
      method: methods.PUT,
      path: `projects/${projectId}/duplicate`,
    });
    if (response === null) return;

    yield put(actions.duplicateProjectSuccess(response.project));
    InterfaceUtils.emitToast(
      'success',
      'Project has been successfully duplicated'
    );
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import React, { Component } from 'react';
import _ from 'lodash';

import {
  ChangesRowContainer,
  ContentWrapper,
  Container,
  ProfileControlContainer,
  StyleSettingsFormContainer,
  ProjectOptionsRow,
  NameContainer,
  SlicerDropdownContainer,
  StyledCustomLabel,
} from './projectSettings.styles';

import {
  Button,
  ConfirmationModal,
  Dropdown,
  H6,
  Input,
  ModalHeader,
  SettingsModal,
  Subtitle1OneLine,
} from '../../../shared';

import StyleSettingsForm from '../../printerManager/configureStyle/forms/styleSettingsForm.jsx';

import { slicerExperimentalStatus, slicers } from '../../../constants';
import { FormatUtils, PrinterUtils } from '../../../utils';

class ProjectSettings extends Component {
  static defaultProps = {
    variableTransitionLengths: null,
    onClickClose: () => {},
    onToggleTransitionLengthModal: () => {},
  };

  constructor(props) {
    super(props);
    const { name, style, slicer } = props.project;
    this.state = {
      slicer,
      projectStyles: style,
      styleErrors: {},
      currentStyleIds: [],
      renameValue: name,
      renameError: false,
      focusField: null,
      showCancelModal: false,
      showEnterSupportViewModal: false,
    };
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);
    const { project, printers } = this.props;

    const projectPrinter = printers[project.printerId];

    if (projectPrinter && projectPrinter.tagId) {
      this.props.getPrinterTag(projectPrinter.tagId);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  componentDidUpdate(prevProps) {
    const { createStylesPending, createStylesSuccess, printers, project } =
      this.props;
    const { currentStyleIds } = this.state;

    if (
      prevProps.createStylesPending &&
      !createStylesPending &&
      createStylesSuccess
    ) {
      // new style profile has been created -- find it, use it, and save everything
      const { printerId } = project;
      const printerProfile = printers[printerId];

      const newStyleProfile = _.find(
        printerProfile.styles,
        (style) => !_.includes(currentStyleIds, style.id)
      );
      this.setState(
        {
          projectStyles: PrinterUtils.omitNonStyleFields(newStyleProfile),
          styleErrors: {},
        },
        () => {
          const updatedSettings = {
            styles: newStyleProfile,
          };
          this.props.updateProjectSettings(project.id, updatedSettings);
        }
      );
    }
  }

  focusField(fieldName) {
    this.setState({ focusField: fieldName }, () => {
      this.setState({ focusField: null });
    });
  }

  getNextSaveError() {
    if (this.state.renameError) {
      return 'rename';
    }
    return _.findKey(this.state.styleErrors) || null;
  }

  getStylesFromSelectedPrinter() {
    const { printers, project } = this.props;
    const { printerId } = project;
    return printers[printerId].styles;
  }

  getSelectedStyleProfile() {
    // selected style profile must belong to the selected printer
    const stylesFromSelectedPrinter = this.getStylesFromSelectedPrinter();

    // find selected style profile from the selected printer's styles, and return it
    return _.find(
      stylesFromSelectedPrinter,
      (style) => style.id === this.props.project.styleId
    );
  }

  handleSave() {
    const { project } = this.props;
    const { projectStyles, renameValue, slicer } = this.state;

    const fieldName = this.getNextSaveError();
    if (fieldName) {
      this.focusField(fieldName);
      return;
    }

    const slicerChanged = slicer !== project.slicer;
    const styleValuesChanged = !PrinterUtils.areStylesEqual(
      projectStyles, // current values
      project.style // previous values
    );
    const nameChanged = renameValue !== project.name;

    if (slicerChanged || styleValuesChanged) {
      const styleProfile = this.getSelectedStyleProfile();
      const updatedProjectStyles = {
        ...styleProfile,
        ...projectStyles,
      };
      const updatedSettings = {
        slicer,
        styles: updatedProjectStyles,
      };
      if (nameChanged) {
        updatedSettings.name = renameValue;
      }
      this.props.updateProjectSettings(project.id, updatedSettings);
    } else if (nameChanged) {
      // only make this API call if name changed but nothing else did
      this.props.updateProjectName(project.id, renameValue);
    }

    this.props.onClickClose();
  }

  onKeyDown(e) {
    if (e.keyCode === 13) {
      // Enter
      this.handleSave();
    }
  }

  showCancelProjectSettingsModal(e) {
    e.stopPropagation();
    const { project, updatePrinterPending, createStylesPending } = this.props;
    const { projectStyles, slicer, renameValue } = this.state;

    const originalProjectStyles = project.style;
    const areStylesEqual = PrinterUtils.areStylesEqual(
      projectStyles,
      originalProjectStyles
    );
    const areNamesEqual = project.name === renameValue;
    const areSlicersEqual = project.slicer === slicer;
    const areAllSettingsEqual =
      areNamesEqual && areStylesEqual && areSlicersEqual;

    if (areAllSettingsEqual || updatePrinterPending || createStylesPending) {
      this.props.onClickClose();
    } else {
      this.setState({ showCancelModal: true });
    }
  }

  handleEnterSupportView() {
    const { project, updatePrinterPending, createStylesPending } = this.props;
    const { projectStyles } = this.state;

    const originalProjectStyles = project.style;
    const areStylesEqual = PrinterUtils.areStylesEqual(
      projectStyles,
      originalProjectStyles
    );

    if (areStylesEqual || updatePrinterPending || createStylesPending) {
      this.props.onEnterSupportView();
    } else {
      // see what changed
      const changedFields = PrinterUtils.diffStyles(
        projectStyles,
        originalProjectStyles
      );
      const fieldsRequiringConfirm = _.filter(
        changedFields,
        (fieldName) =>
          fieldName !== 'useSupport' && fieldName !== 'useCustomSupports'
      );
      if (_.isEmpty(fieldsRequiringConfirm)) {
        // if the only things that changed were enabling support
        // and/or enabling custom supports, save changes automatically
        // and proceed to support view immediately
        this.handleSave();
        this.props.onEnterSupportView();
      } else {
        // otherwise, prompt the user to save changes first
        this.setState({ showEnterSupportViewModal: true });
      }
    }
  }

  updateField(fieldName, value, error = false) {
    this.setState({
      projectStyles: {
        ...this.state.projectStyles,
        [fieldName]: value,
      },
      styleErrors: {
        ...this.state.styleErrors,
        [fieldName]: error,
      },
    });
  }

  updatePrinterStyle() {
    const fieldName = this.getNextSaveError();
    if (fieldName) {
      this.focusField(fieldName);
      return;
    }
    const { project } = this.props;
    const { printerId, styleId } = project;
    const { projectStyles } = this.state;

    const projectStylesOfInterest =
      PrinterUtils.omitNonStyleFields(projectStyles);
    this.props.updatePrinterStyle(printerId, styleId, projectStylesOfInterest);
  }

  handleSaveAsNewStyle() {
    const fieldName = this.getNextSaveError();
    if (fieldName) {
      this.focusField(fieldName);
      return;
    }

    const { project, printers } = this.props;
    const { projectStyles } = this.state;
    const { printerId, styleId } = project;

    const printerProfile = printers[printerId];
    const styleProfile = _.find(
      printerProfile.styles,
      (profile) => profile.id === styleId
    );
    const newStyle = {
      ..._.omit(styleProfile, ['id']),
      ...projectStyles,
    };
    this.setState(
      {
        currentStyleIds: _.map(printerProfile.styles, (profile) => profile.id),
      },
      () => {
        this.props.createStyleProfiles(printerId, [newStyle]);
      }
    );
  }

  revertProjectStyle() {
    const styleProfile = this.getSelectedStyleProfile();
    this.setState({
      projectStyles: PrinterUtils.omitNonStyleFields(styleProfile),
      styleErrors: {},
    });
  }

  onRename(value, error = false) {
    this.setState({
      renameValue: value,
      renameError: error,
    });
  }

  renderRenameField() {
    return (
      <NameContainer>
        <Input
          type='text'
          label='Project name'
          forceFocus={this.state.focusField === 'rename'}
          value={this.state.renameValue}
          isInvalid={this.state.renameError}
          onChangeSuccess={(value) => this.onRename(value, false)}
          onChangeFailure={(value) => this.onRename(value, true)}
        />
      </NameContainer>
    );
  }

  renderSlicerField() {
    const options = Object.entries(slicers).map(([label, value]) => ({
      label,
      subLabel: slicerExperimentalStatus[value] ? '(experimental)' : undefined,
      value,
    }));
    return (
      <SlicerDropdownContainer>
        <Dropdown
          rightAlign
          value={this.state.slicer}
          label='Slicer'
          options={options}
          onChange={(value) => this.setState({ slicer: value })}
        />
      </SlicerDropdownContainer>
    );
  }

  getChangedFields() {
    const { projectStyles } = this.state;
    const currentStyle = this.getSelectedStyleProfile();
    const filteredCurrentStyle = PrinterUtils.omitNonStyleFields(currentStyle);
    const filteredProjectStyles =
      PrinterUtils.omitNonStyleFields(projectStyles);
    return PrinterUtils.diffStyles(filteredProjectStyles, filteredCurrentStyle);
  }

  renderStyleSettingsForm() {
    const { materials, printers, printerTag, project } = this.props;
    const { focusField, slicer, projectStyles, styleErrors } = this.state;

    const projectProps = {
      // props for disabling/hiding certain settings
      slicer,

      // props for material overrides
      colors: project.colors,
      materialIds: project.materialIds,
      materials: _.pickBy(materials, (material, id) =>
        _.includes(project.materialIds, id)
      ),

      // props for variable transitions
      variableTransitionLengths: project.variableTransitionLengths,
      onToggleTransitionLengthModal: this.props.onToggleTransitionLengthModal,

      // props for custom supports
      onClickConfigureCustomSupports: this.handleEnterSupportView.bind(this),
    };

    // only pass in printer tag if currently selected printer is tagged
    const printer = printers[project.printerId];

    return (
      <StyleSettingsFormContainer>
        <StyleSettingsForm
          initialStyle={this.getSelectedStyleProfile()}
          style={projectStyles}
          slicer={slicer}
          errors={styleErrors}
          focusField={focusField}
          fieldChanges={this.getChangedFields()}
          printerTag={printer.tagId ? printerTag : null}
          updateFieldSuccess={(fieldName, value) =>
            this.updateField(fieldName, value, false)
          }
          updateFieldFailure={(fieldName, value) =>
            this.updateField(fieldName, value, true)
          }
          projectProps={projectProps}
        />
      </StyleSettingsFormContainer>
    );
  }

  renderHeader() {
    return (
      <ModalHeader>
        <H6>Project Settings</H6>
      </ModalHeader>
    );
  }

  renderProfileControl() {
    return (
      <ProfileControlContainer>
        {this.renderProfileActions()}
      </ProfileControlContainer>
    );
  }

  handleProfileAction(value) {
    switch (value) {
      case 'save':
        return this.updatePrinterStyle();
      case 'create':
        return this.handleSaveAsNewStyle();
      case 'reset':
        return this.revertProjectStyle();
      default:
        return null;
    }
  }

  renderChangesDropdown() {
    const { updatePrinterPending, createStylesPending } = this.props;
    const { projectStyles } = this.state;
    const styleProfile = this.getSelectedStyleProfile();
    const currentStyle = this.getSelectedStyleProfile();

    const areStylesEqual = PrinterUtils.areStylesEqual(
      styleProfile,
      projectStyles
    );
    const isDisabled =
      areStylesEqual || updatePrinterPending || createStylesPending;
    const options = [
      {
        label: `Push the changes to ${currentStyle.name}`,
        value: 'save',
        disabled: isDisabled,
      },
      {
        label: 'Save as a new style profile',
        value: 'create',
        disabled: isDisabled,
      },
      { label: 'Reset all changes', value: 'reset', disabled: isDisabled },
    ];

    const changedFields = this.getChangedFields();
    const formattedText = FormatUtils.pluralize('change', changedFields.length);
    return (
      <Dropdown
        options={options}
        onChange={(value) => this.handleProfileAction(value)}
        green
        customLabel={`${changedFields.length} ${formattedText}`}
        StyledSelect={StyledCustomLabel}
        borderlessContainer
        borderlessSelect
        rightAlign
      />
    );
  }

  renderStyleChangesRow() {
    const currentStyle = this.getSelectedStyleProfile();
    return (
      <ChangesRowContainer>
        <Subtitle1OneLine>{currentStyle.name}</Subtitle1OneLine>
        {this.renderChangesDropdown()}
      </ChangesRowContainer>
    );
  }

  renderContent() {
    return (
      <ContentWrapper>
        <ProjectOptionsRow>
          {this.renderRenameField()}
          {this.renderSlicerField()}
        </ProjectOptionsRow>
        {this.renderStyleChangesRow()}
        {this.renderStyleSettingsForm()}
      </ContentWrapper>
    );
  }

  renderHeaderButtons() {
    const { updatePrinterPending, createStylesPending } = this.state;
    const pendingRequests = updatePrinterPending || createStylesPending;

    return (
      <>
        <Button
          onClick={(e) => this.showCancelProjectSettingsModal(e)}
          disabled={pendingRequests}>
          Cancel
        </Button>
        <Button
          primary
          onClick={() => this.handleSave()}
          disabled={pendingRequests}>
          Save
        </Button>
      </>
    );
  }

  renderCancelModal() {
    if (!this.state.showCancelModal) return null;
    return (
      <ConfirmationModal
        isWarning
        primaryLabel='You have unsaved changes'
        secondaryLabel='Are you sure you would like to exit without saving?'
        cancelLabel='Close'
        confirmLabel='Exit'
        onClickCancel={() => this.setState({ showCancelModal: false })}
        onClickConfirm={() => this.props.onClickClose()}
      />
    );
  }

  renderEnterSupportViewModal() {
    if (!this.state.showEnterSupportViewModal) return null;
    return (
      <ConfirmationModal
        primaryLabel='You have unsaved changes'
        secondaryLabel='Would you like to save them and proceed to custom support configuration?'
        confirmLabel='Continue'
        onClickCancel={() =>
          this.setState({ showEnterSupportViewModal: false })
        }
        onClickConfirm={() => {
          this.handleSave(false);
          this.props.onEnterSupportView();
        }}
      />
    );
  }

  renderModalContent() {
    return (
      <Container>
        {this.renderContent()}
        {this.renderCancelModal()}
        {this.renderEnterSupportViewModal()}
      </Container>
    );
  }

  render() {
    return (
      <SettingsModal
        width='47.25rem'
        height='48rem'
        headerTitle='Project Settings'
        headerButtons={this.renderHeaderButtons()}
        sideBarContent={this.renderModalContent()}
      />
    );
  }
}

export default ProjectSettings;

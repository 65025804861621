import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { getAuthState, handleGenericError } from '../common';
import { enableResize } from './resize';
import { setRenderFlag, startAnimation } from './animationFrame';
import { initializeRaycaster } from './raycast';

import { actions } from '../../reducers/three/three';

import { SceneUtils } from '../../utils';
import Gizmo from '../../classes/Gizmo';

export default function* initScene(action) {
  try {
    const { canvas } = action.payload;
    const { viewOptions } = yield select(getAuthState);

    // create scene, camera, renderer
    const scene = SceneUtils.createScene();
    const camera = SceneUtils.createCamera(viewOptions.orthographic);
    const renderer = SceneUtils.createRenderer(canvas);

    // set initial render size
    const { innerWidth, innerHeight } = window;
    renderer.setSize(innerWidth, innerHeight);

    // create controls and raycaster stuff
    const controls = SceneUtils.createOrbitControls(
      camera,
      renderer.domElement
    );
    controls.addEventListener('change', () => {
      setRenderFlag();
    });

    // add lighting
    const ambientLight = SceneUtils.createAmbientLight();
    scene.add(ambientLight);
    const pointLight = SceneUtils.createPointLight();
    camera.add(pointLight);

    // add camera to scene
    scene.add(camera);

    // initialize raycaster
    const { top, left, width, height } =
      renderer.domElement.getBoundingClientRect();
    initializeRaycaster(scene, camera, top, left, width, height);

    // resize handler
    enableResize(renderer, camera);

    // kick off animation loop
    startAnimation(renderer, scene, camera);

    // create gizmo
    const gizmo = new Gizmo(camera, renderer.domElement, controls);
    scene.add(gizmo);

    // store created objects
    yield delay(0); // since saga is synchronous, leave time for pending flag to be updated
    yield put(
      actions.initSceneSuccess(
        scene,
        camera,
        renderer,
        controls,
        ambientLight,
        pointLight,
        gizmo
      )
    );
  } catch (e) {
    yield call(handleGenericError, action, e);
    yield put(actions.initSceneFailure());
  }
}

import React, { Component } from 'react';
import { withTheme } from 'styled-components';

import Progress from '../progress/progress.jsx';

import { Container } from './loadingOverlay.styles';
import Portal from '../portal/portal.jsx';

class LoadingOverlay extends Component {
  static defaultProps = {
    label: '',
  };

  render() {
    return (
      <Portal>
        <Container>
          <Progress
            circular
            primaryColor={this.props.theme.colors.whiteDefault}
            secondaryColor={`${this.props.theme.colors.whiteDefault}30`}
            labelColor={this.props.theme.colors.whiteDefault}
            radius={32}
            label={this.props.label}
          />
        </Container>
      </Portal>
    );
  }
}

export default withTheme(LoadingOverlay);

import styled from 'styled-components';

export const MaterialList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
  gap: 0.5rem;
  border-radius: 0.2rem;
`;

export const PlaceholderTextWrapper = styled.div`
  margin: 2rem 0;
`;

export const MaterialInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const getTagContentsString = (dom, tagName) => {
  const elements = dom.getElementsByTagName(tagName);
  if (elements.length === 0) return null;
  return elements[0].textContent;
};

export const getTagContentsInt = (dom, tagName) => {
  const content = getTagContentsString(dom, tagName);
  if (content === null) return null;
  return Number.parseInt(content, 10);
};

export const getTagContentsFloat = (dom, tagName) => {
  const content = getTagContentsString(dom, tagName);
  if (content === null) return null;
  return Number.parseFloat(content);
};

export const getTagContentsBool = (dom, tagName) => {
  const content = getTagContentsString(dom, tagName);
  if (content === null) return null;
  return content === '1';
};

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import { handleGenericError, getIotState } from '../common';
import { makeRequest } from './horizon';
import { actions as iotActions } from '../../reducers/iot/iot';

export default function* editDeviceFilePath(action) {
  const { device, filePathData } = action.payload;

  const { path: previousPath } = filePathData;

  try {
    const deviceType = device.type;

    // response contains updated file object
    const response = yield call(
      makeRequest,
      device.id,
      deviceType === 'canvas-hub' ? 'storage' : 'storage/rename-file',
      {
        method: 'post',
        query: filePathData,
      },
      'editFilePath'
    );

    // update files in device state
    const { deviceStates } = yield select(getIotState);
    const { filesContext } = deviceStates[device.id];

    const nameStartIndex = previousPath.lastIndexOf('/');
    const previousName = previousPath.slice(nameStartIndex + 1);

    const updatedFiles = _.map(filesContext.currentFiles, (file) =>
      file.name === previousName ? response.body : file
    );

    const updatedFilesContext = {
      ...filesContext,
      currentFiles: updatedFiles,
    };

    yield put(iotActions.setDeviceFilesContext(device.id, updatedFilesContext));
    yield put(iotActions.editDeviceFilePathSuccess());
    const newPathLegs = filePathData.newPath.split('/');
    newPathLegs.pop();
    yield put(
      iotActions.getDrivePathFilesRequest(device, newPathLegs.join('/'))
    );
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

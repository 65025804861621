import * as Sentry from '@sentry/browser';

const SENTRY_ENABLED =
  process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_DSN;

const Utils = {
  sentryInit: () => {
    // Sentry error logging
    if (SENTRY_ENABLED) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        ignoreErrors: ['AbortError'],
      });
    }
  },
  sentryManualReport: (
    err,
    logToConsole = false,
    captureContext = undefined
  ) => {
    // manual reporting, e.g. for errors that are handled by
    // application logic but we still want reported in Sentry
    if (SENTRY_ENABLED) {
      Sentry.captureException(err, captureContext);
    }
    if (logToConsole) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  },
};

export default Utils;

import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import _ from 'lodash';

import {
  DeviceLink,
  DeviceLabel,
  DeviceImage,
  DeviceName,
  DeviceIndicator,
  InfoContainer,
} from './deviceManager.styles';

import UnlinkDevicesModal from './modals/unlinkDevices.jsx';

import {
  Body1OneLine,
  Button,
  CardList,
  DeviceCard,
  ManagerView,
  OnlineIndicator,
  RenameModal,
  Subtitle1OneLine,
  ToolTipWrapper,
} from '../../shared';

import {
  ButtonsContainer,
  ButtonWrapper,
} from '../../shared/managerView/managerView.styles';

import { Icons } from '../../themes';

import { DeviceUtils, FormatUtils, InterfaceUtils } from '../../utils';

import { DeviceSortMode } from '../../utils/sort/sort';

import Routes from '../../router/routes';

const sortOptions = [
  { label: 'Name', value: DeviceSortMode.NAME },
  { label: 'Date modified', value: DeviceSortMode.DATE_MODIFIED },
  { label: 'Date created', value: DeviceSortMode.DATE_CREATED },
];

class DeviceManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterBy: '',
      showRenameModal: false,
      showUnlinkModal: false,
      displayedDevices: [],
      selectedDeviceIds: [],
    };
    this.onWindowClick = this.onWindowClick.bind(this);
    this.onWindowKeydown = this.onWindowKeydown.bind(this);
  }

  componentDidMount() {
    this.props.updateNavStack([
      { text: 'My Devices', route: Routes.toManageDevices() },
    ]);
    window.addEventListener('click', this.onWindowClick);
    window.addEventListener('keydown', this.onWindowKeydown);
    if (!this.props.getDevicesPending) {
      this.updateDisplayedDevices();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
    window.removeEventListener('keydown', this.onWindowKeydown);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.devices !== prevProps.devices ||
      !_.isEqual(this.props.sortBy, prevProps.sortBy)
    ) {
      this.updateDisplayedDevices();
    }
  }

  updateDisplayedDevices() {
    const sortedAndFilteredDevices = this.filterDevices(
      this.props.devices,
      this.state.filterBy
    );
    const newState = {
      displayedDevices: sortedAndFilteredDevices,
      selectedDeviceIds: [],
    };
    _.forEach(this.state.selectedDeviceIds, (id) => {
      if (_.find(sortedAndFilteredDevices, (device) => device.id === id)) {
        newState.selectedDeviceIds.push(id);
      }
    });
    this.setState(newState);
  }

  onWindowKeydown(event) {
    if (event.key === 'Escape') {
      this.deselect();
    } else if (
      event.key === 'Delete' &&
      this.state.selectedDeviceIds.length >= 1 &&
      !this.state.showRenameModal
    ) {
      // Hit DELETE while selecting device(s)
      this.showUnlinkModal(event);
    }
  }

  onWindowClick() {
    const { showUnlinkModal, showRenameModal } = this.state;
    if (showUnlinkModal || showRenameModal) {
      return;
    }
    this.deselect();
  }

  deselect() {
    const { showUnlinkModal, showRenameModal } = this.state;
    if (showUnlinkModal || showRenameModal) {
      this.setState({
        showUnlinkModal: false,
        showRenameModal: false,
      });
    } else {
      this.setState({ selectedDeviceIds: [] });
    }
  }

  filterDevices(devices, filterBy) {
    const filter = filterBy.toLowerCase();
    const filtered = _.filter(devices, (device) => {
      if (filter !== '') {
        return (
          device.name.toLowerCase().includes(filter) || // name match
          device.type.toLowerCase().includes(filter) // type match
        );
      }
      return true;
    });
    return DeviceUtils.sortDevices(filtered, this.props.sortBy);
  }

  selectDevice(e, device) {
    e.stopPropagation();
    const { selectedDeviceIds, displayedDevices } = this.state;

    const updatedSelectedItems = InterfaceUtils.handleSelectableItemClick(
      e,
      displayedDevices,
      selectedDeviceIds,
      device.id
    );
    this.setState({ selectedDeviceIds: updatedSelectedItems });
  }

  areActionsDisabled() {
    const { getDevicesSuccess, devices } = this.props;
    return !getDevicesSuccess || _.isEmpty(devices);
  }

  onFilterChange(filterBy) {
    this.setState({ filterBy }, () => {
      this.updateDisplayedDevices();
    });
  }

  showRenameModal(e) {
    e.stopPropagation();
    this.setState({
      showRenameModal: true,
    });
  }

  hideRenameModal() {
    this.setState({
      showRenameModal: false,
    });
  }

  showUnlinkModal(e) {
    e.stopPropagation();
    this.setState({
      showUnlinkModal: true,
    });
  }

  hideUnlinkModal() {
    this.setState({
      showUnlinkModal: false,
    });
  }

  onSortChange(sortMode, sortDirection) {
    this.props.updatePreferences(sortMode, sortDirection);
  }

  getActionButtonsRightMeta() {
    const { selectedDeviceIds } = this.state;
    return [
      {
        icon: Icons.basic.pencil,
        title: 'Rename device',
        disabled: selectedDeviceIds.length !== 1,
        onClick: (e) => this.showRenameModal(e),
      },
      {
        icon: Icons.basic.trash,
        title: FormatUtils.pluralize(
          'Unlink device',
          selectedDeviceIds.length,
          true
        ),
        disabled: _.isEmpty(selectedDeviceIds),
        onClick: (e) => this.showUnlinkModal(e),
      },
    ];
  }

  handleUnlinkDevices() {
    const { selectedDeviceIds } = this.state;
    this.props.unlinkDevices(selectedDeviceIds);
    this.hideUnlinkModal();
  }

  handleRenameDevice(newName) {
    const [deviceId] = this.state.selectedDeviceIds;
    const device = this.props.devices[deviceId];
    if (newName.trim() !== device.name) {
      this.props.updateDevice(deviceId, { name: newName });
    }
    this.hideRenameModal();
  }

  getLoadingOverlayText() {
    if (this.props.unlinkDevicesPending) {
      const { selectedDeviceIds } = this.state;
      return FormatUtils.pluralize(
        'Unlinking device',
        selectedDeviceIds.length
      );
    }
    if (this.props.updateDevicePending) {
      return 'Updating device';
    }
    return null;
  }

  renderRightPanelContent() {
    return (
      <ButtonsContainer>
        <ButtonWrapper>
          <Button
            primary
            expand
            icon={Icons.basic.plus}
            onClick={() =>
              this.props.history.push(Routes.toDeviceActivation())
            }>
            Connect device
          </Button>
        </ButtonWrapper>
      </ButtonsContainer>
    );
  }

  renderUnlinkButton(deviceId) {
    return (
      <Button warning onClick={() => this.props.unlinkDevice(deviceId)}>
        Unlink
      </Button>
    );
  }

  renderDeviceCard(device) {
    const { deviceStates, history, theme } = this.props;
    if (!deviceStates[device.id]) return null;

    const titaniumOnline = this.props.connected;
    const deviceOnline = deviceStates[device.id].connected;
    const deviceSelected = _.includes(this.state.selectedDeviceIds, device.id);

    let tooltip;
    if (titaniumOnline) {
      tooltip = `Device ${deviceOnline ? 'online' : 'offline'}`;
    } else {
      tooltip = 'Canvas offline';
    }

    const renderDeviceName = () => (
      <DeviceName>
        <ToolTipWrapper tooltip={tooltip}>
          <Subtitle1OneLine noSpacing>{device.name}</Subtitle1OneLine>
        </ToolTipWrapper>
      </DeviceName>
    );

    const renderDeviceIndicator = () => (
      <DeviceIndicator>
        <OnlineIndicator online={titaniumOnline} connected={deviceOnline} />
      </DeviceIndicator>
    );

    const renderDeviceLabel = () => (
      <DeviceLabel>
        {renderDeviceName()}
        {renderDeviceIndicator()}
      </DeviceLabel>
    );

    const hostAddress = `http://${device.hostname}`;
    const renderDeviceHostname = () => (
      <Body1OneLine grey noSpacing>
        <DeviceLink href={hostAddress} target='_blank'>
          {device.hostname}
        </DeviceLink>
      </Body1OneLine>
    );

    const renderDeviceSerialNumber = () => (
      <Body1OneLine grey noSpacing>
        {device.serialNumber}
      </Body1OneLine>
    );

    const renderDeviceCardImage = () => (
      <DeviceImage
        src={DeviceUtils.getDeviceImage(device.type, device.model, theme)}
      />
    );

    const renderDeviceCardInfo = () => (
      <InfoContainer>
        {renderDeviceLabel()}
        {device.serialNumber && renderDeviceSerialNumber()}
        {device.hostname && renderDeviceHostname()}
      </InfoContainer>
    );

    return (
      <DeviceCard
        key={device.id}
        id={device.id}
        selected={deviceSelected}
        onClick={(e) => this.selectDevice(e, device)}
        onDoubleClick={() => history.push(Routes.toViewDevice(device.id))}>
        {renderDeviceCardInfo()}
        {renderDeviceCardImage()}
      </DeviceCard>
    );
  }

  renderDevices() {
    return (
      <CardList>
        {_.map(this.state.displayedDevices, (device) =>
          this.renderDeviceCard(device)
        )}
      </CardList>
    );
  }

  renderUnlinkModal() {
    const { displayedDevices, selectedDeviceIds, showUnlinkModal } = this.state;
    if (!showUnlinkModal) return null;

    const selectedDevices = _.filter(displayedDevices, (device) =>
      selectedDeviceIds.includes(device.id)
    );
    const onCancel = () => this.setState({ showUnlinkModal: false });
    const onConfirm = () => this.handleUnlinkDevices();
    return (
      <UnlinkDevicesModal
        selectedDevices={selectedDevices}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    );
  }

  renderRenameModal() {
    const { displayedDevices, selectedDeviceIds, showRenameModal } = this.state;
    if (!showRenameModal) return null;

    const selectedDevices = _.filter(displayedDevices, (device) =>
      selectedDeviceIds.includes(device.id)
    );
    const targetDevice = selectedDevices[0];

    if (!targetDevice) return null;

    return (
      <RenameModal
        initialValue={targetDevice.name}
        title={`Rename ${targetDevice.name}`}
        onCancel={() => this.setState({ showRenameModal: false })}
        onSave={(newName) => this.handleRenameDevice(newName)}
        onMarginClick={() => this.hideRenameModal()}
      />
    );
  }

  render() {
    const { devices, getDevicesPending, getDevicesSuccess } = this.props;

    return (
      <ManagerView
        name='Devices'
        menuButtons={this.renderRightPanelContent()}
        loadingOverlayText={this.getLoadingOverlayText()}
        loadingContent={getDevicesPending}
        loadingError={!getDevicesPending && !getDevicesSuccess}
        noContent={_.isEmpty(devices)}
        filterBarValue={this.state.filterBy}
        onFilterChange={(value) => this.onFilterChange(value)}
        sortOptions={sortOptions}
        sortMode={this.props.sortBy.mode}
        sortDirection={this.props.sortBy.direction}
        onSortChange={(mode, direction) => this.onSortChange(mode, direction)}
        actionsDisabled={this.areActionsDisabled()}
        actionButtons={this.getActionButtonsRightMeta()}>
        {this.renderDevices()}
        {this.renderRenameModal()}
        {this.renderUnlinkModal()}
      </ManagerView>
    );
  }
}

export default withTheme(DeviceManager);

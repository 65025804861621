import 'regenerator-runtime/runtime';
import { call, select } from 'redux-saga/effects';

import { getThreeState, handleGenericError } from '../common';
import { setRenderFlag } from './animationFrame';

const isLayerBoundSettingChanged = (toolpath, prevToolpath) =>
  toolpath.minLayer !== prevToolpath.minLayer ||
  toolpath.maxLayer !== prevToolpath.maxLayer;

export const updateTopBottomUniforms = (mesh, top, bottom) => {
  // eslint-disable-next-line no-param-reassign
  mesh.material.uniforms.top.value = top + 0.00001;
  // eslint-disable-next-line no-param-reassign
  mesh.material.uniforms.bottom.value = bottom - 0.00001;
};

export default function* updateToolpath(action) {
  try {
    const { scene } = yield select(getThreeState);
    const { toolpath, prevToolpath } = action.payload;

    const isLayerBoundChanged = isLayerBoundSettingChanged(
      toolpath,
      prevToolpath
    );
    const minLayer = toolpath.layerHeights[toolpath.minLayer];
    const maxLayer = toolpath.layerHeights[toolpath.maxLayer - 1];

    if (prevToolpath.extrusions !== toolpath.extrusions) {
      if (prevToolpath.extrusions) {
        scene.remove(prevToolpath.extrusions);
        prevToolpath.extrusions.geometry.dispose();
      }
      // toolpath meshes are now ready
      updateTopBottomUniforms(toolpath.extrusions, maxLayer, minLayer);
      scene.add(toolpath.extrusions);
    } else if (isLayerBoundChanged) {
      updateTopBottomUniforms(toolpath.extrusions, maxLayer, minLayer);
    }

    if (
      prevToolpath.showTravel !== toolpath.showTravel || // option changed, or
      prevToolpath.travels !== toolpath.travels // new data was loaded async
    ) {
      if (toolpath.travels && toolpath.showTravel) {
        updateTopBottomUniforms(toolpath.travels, maxLayer, minLayer);
        scene.add(toolpath.travels);
      } else if (prevToolpath.travels) {
        scene.remove(prevToolpath.travels);
        prevToolpath.travels.geometry.dispose();
      }
    } else if (toolpath.travels && isLayerBoundChanged) {
      updateTopBottomUniforms(toolpath.travels, maxLayer, minLayer);
    }

    if (
      prevToolpath.showRetracts !== toolpath.showRetracts || // option changed, or
      prevToolpath.retracts !== toolpath.retracts // new data was loaded async
    ) {
      if (toolpath.retracts && toolpath.showRetracts) {
        updateTopBottomUniforms(toolpath.retracts, maxLayer, minLayer);
        scene.add(toolpath.retracts);
      } else if (prevToolpath.retracts) {
        scene.remove(prevToolpath.retracts);
        prevToolpath.retracts.geometry.dispose();
      }
    } else if (toolpath.retracts && isLayerBoundChanged) {
      updateTopBottomUniforms(toolpath.retracts, maxLayer, minLayer);
    }

    if (
      prevToolpath.showRestarts !== toolpath.showRestarts || // option changed, or
      prevToolpath.restarts !== toolpath.restarts // new data was loaded async
    ) {
      if (toolpath.restarts && toolpath.showRestarts) {
        updateTopBottomUniforms(toolpath.restarts, maxLayer, minLayer);
        scene.add(toolpath.restarts);
      } else if (prevToolpath.restarts) {
        scene.remove(prevToolpath.restarts);
        prevToolpath.restarts.geometry.dispose();
      }
    } else if (toolpath.restarts && isLayerBoundChanged) {
      updateTopBottomUniforms(toolpath.restarts, maxLayer, minLayer);
    }
    setRenderFlag();
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import navTypes from './types';
import navActions from './actions';
import navInitialState from './initialState';

const types = navTypes;
export const actions = navActions;
export const initialState = navInitialState;

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.UPDATE_NAV_STACK: {
      const { navStack } = payload;
      return {
        ...state,
        navStack,
      };
    }
    default: {
      return state;
    }
  }
};

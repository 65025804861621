import 'regenerator-runtime/runtime';
import { call, select } from 'redux-saga/effects';

import { getThreeState, handleGenericError } from '../common';
import { setRenderFlag } from './animationFrame';

export default function* showPrintBed(action) {
  try {
    const state = yield select(getThreeState);
    const { scene, originAxes, printBed, printBedBoundingBox, printBedGrid } =
      state;
    if (!scene) return;
    if (originAxes) {
      scene.add(originAxes);
    }
    if (printBed) {
      scene.add(printBed);
    }
    if (printBedBoundingBox) {
      scene.add(printBedBoundingBox);
    }
    if (printBedGrid) {
      scene.add(printBedGrid);
    }
    setRenderFlag();
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

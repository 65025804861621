import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import { handleGenericError } from '../common';
import { makeRequest } from './horizon';
import { actions as iotActions } from '../../reducers/iot/iot';

export default function* getStorageDrives(action) {
  const { device } = action.payload;

  try {
    const deviceType = device.type;
    const response = yield call(
      makeRequest,
      device.id,
      deviceType === 'canvas-hub' ? 'storage' : 'storage/drives',
      {
        method: 'get',
      },
      'getStorageDrives'
    );

    const storageDrives = response.body;

    yield put(iotActions.setDeviceStorageDrives(device.id, storageDrives));
    yield put(iotActions.getStorageDrivesSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

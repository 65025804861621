import React from 'react';
import _ from 'lodash';

import { InlineCode as C } from '../docs/code.jsx';
import { FunctionTable, FunctionTH, FunctionTD } from '../docs/styles';
import { vars, varDefinitions } from '../../../shared/codearea/gcode.mode';

const sections = [
  {
    title: 'Explanation',
    content: (
      <>
        <p>
          Non-PrinterScript sequences are evaluated as literal output text with
          simple variable substitution tokens, listed below.
        </p>
        <p>
          Only the side transition sequence, if set, must be valid
          PrinterScript. For all other scripts, either mode is allowed.
        </p>
      </>
    ),
  },
  {
    title: 'Variables',
    content: (
      <>
        <FunctionTable>
          <thead>
            <tr>
              <FunctionTH>Token</FunctionTH>
              <FunctionTH>Value</FunctionTH>
            </tr>
          </thead>
          <tbody>
            {_.map(vars, (token, index) => (
              <tr key={token}>
                <FunctionTD>
                  <C>{token}</C>
                </FunctionTD>
                <FunctionTD>{varDefinitions[index]}</FunctionTD>
              </tr>
            ))}
          </tbody>
        </FunctionTable>
      </>
    ),
  },
  {
    title: 'Sequences',
    subsections: [
      {
        title: 'Start of print',
        id: 'start-of-print',
        content: (
          <>
            <p>
              This script is stored with the printer profile and runs at the
              very start of the print. It can be used to home the print head,
              preheat the extruder and print bed, prime the nozzle, and more.
            </p>
            <p>
              If this script does not contain the <C>&lt;TEMP&gt;</C> variable,
              Canvas will heat the extruder before this sequence runs.
            </p>
            <p>
              When Palette printing, any filament extruded by this script will
              contribute to the length of the first splice.
            </p>
          </>
        ),
      },
      {
        title: 'End of print',
        id: 'end-of-print',
        content: (
          <>
            <p>
              This script is stored with the printer profile and runs at the
              very end of the print. It can be used to move the print head out
              of the way, cool down the extruder and print bed, disable motors,
              and more.
            </p>
            <p>
              When Palette printing, any filament extruded by this script will
              contribute to the length of the last splice.
            </p>
          </>
        ),
      },
      {
        title: 'Layer change',
        id: 'layer-change',
        content: (
          <>
            <p>
              This script is stored with the printer profile and runs at the
              start of each layer, including the first. It can be used to
              re-home the X/Y axes, display print progress, and more.
            </p>
          </>
        ),
      },
      {
        title: 'Material change',
        id: 'material-change',
        content: (
          <>
            <p>
              This script is stored with a material profile and runs whenever
              switching to that material, including at the start of the print.
              It can be used to set speed overrides, linear advance factors, and
              more.
            </p>
          </>
        ),
      },
      {
        title: 'Before side transitioning',
        id: 'before-side-transitioning',
        content: (
          <>
            <p>
              This script is stored with the printer profile and runs before a
              side transition sequence.
            </p>
            <p>
              If empty, Canvas will automatically handle movement from the print
              to the side transition position. Otherwise, you must include a
              move command to this position. Filament will already be retracted
              when this sequence runs, and you should not extrude any filament
              in this sequence.
            </p>
            <p>
              <C>&lt;NEXTX&gt;</C> and <C>&lt;NEXTY&gt;</C> refer to the purge
              coordinates (or first position if purging along an edge).{' '}
              <C>&lt;X&gt;</C>, <C>&lt;Y&gt;</C>, and <C>&lt;Z&gt;</C> refer to
              the current position in the print.
            </p>
          </>
        ),
      },
      {
        title: 'After side transitioning',
        id: 'after-side-transitioning',
        content: (
          <>
            <p>
              This script is stored with the printer profile and runs after a
              side transition sequence. Filament will already be retracted when
              this sequence runs, and you should not extrude any filament in
              this sequence.
            </p>
            <p>
              A travel command is not strictly necessary but may be useful, for
              example if you need to move inside the build volume before
              re-enabling software endstops. <C>&lt;NEXTX&gt;</C> and{' '}
              <C>&lt;NEXTY&gt;</C> refer to the upcoming position in the print.
            </p>
          </>
        ),
      },
    ],
  },
];

export default sections;

import React, { Component } from 'react';

import {
  Ring,
  RingWrapper,
  StaticSwatch,
  MovingSwatch,
} from './colorSwatch.styles';
import types from '../../reducers/three/types';
import { FormatUtils, MaterialUtils } from '../../utils';

class ColorSwatch extends Component {
  static defaultProps = {
    color: 'rgba(225, 225, 225, 0)',
    static: false,
    selected: false,
    disabled: false,
    label: '',
    forceShowLabel: false,
    small: false,
    element: false,
    onClick: () => {},
    onSelect: null,
    onRelease: () => {},
    onDoubleClick: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isMoving: false,
      coord: { x: null, y: null },
    };
    // use for defining target for model drag and drop
    this.touchTarget = null;
    this.touchStartElement = null;
    this.touchCount = 0;
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
  }

  componentDidMount() {
    if (
      !this.props.static &&
      // adding this line for Jest. Jest test in isolation doesn't have access to root
      document.getElementById('root') !== null
    ) {
      // assign touch target to canvas element defined in slicer for model drag and drop
      this.touchTarget = document
        .getElementById('root')
        .childNodes[0].querySelector('div');
    }
  }

  componentWillUnmount() {
    this.touchCount = 0;
    this.touchTarget = null;
    this.touchStartElement = null;
    if (!this.props.static) {
      window.removeEventListener('mousemove', this.onMouseMove);
      window.removeEventListener('mouseup', this.onMouseUp);
      window.removeEventListener('touchmove', this.onTouchMove);
      window.removeEventListener('touchend', this.onTouchEnd);
    }
  }

  componentDidUpdate(prevProps) {
    const { selected } = this.props;
    if (prevProps.selected && !selected) this.touchCount = 0;
  }

  onPointerDown(pageX, pageY) {
    if (this.props.static) {
      if (typeof this.props.onSelect === 'function') {
        this.props.onSelect();
      }
    } else {
      this.setState({ isMoving: true }, () => {
        this.setCoord(pageX, pageY);
        if (typeof this.props.onSelect === 'function') {
          this.props.onSelect();
        }
      });
    }
  }

  onTouchStart(e) {
    if (this.props.disabled) return;
    window.addEventListener('touchmove', this.onTouchMove);
    window.addEventListener('touchend', this.onTouchEnd);
    this.onPointerDown(e.touches[0].pageX, e.touches[0].pageY);
    this.touchStartElement = document.elementFromPoint(
      e.changedTouches[0].pageX,
      e.changedTouches[0].pageY
    ).parentNode;
  }

  onMouseDown(e) {
    if (this.props.disabled) return;
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
    this.onPointerDown(e.pageX, e.pageY);
  }

  onPointerUp(pageX, pageY) {
    // hacky, but allows access to DOM element outside of this component's scope
    const modelIds = [];
    const listItem = document.elementFromPoint(pageX, pageY);
    const itemType = listItem ? listItem.getAttribute('data-model-type') : null;
    if (itemType === 'group') {
      const models = listItem.parentNode.childNodes[1].childNodes;
      models.forEach((model) => {
        const id = model.getAttribute('data-model-id');
        if (id) modelIds.push(id);
      });
    } else if (itemType !== types.TOWER_MESH_NAME && listItem) {
      const id = listItem.getAttribute('data-model-id');
      if (id) modelIds.push(id);
    }
    this.setState({ isMoving: false }, () => {
      document.body.style.cursor = 'auto';
      this.props.onRelease(modelIds);
    });
  }

  onTouchEnd(e) {
    e.stopPropagation();
    window.removeEventListener('touchmove', this.onTouchMove);
    window.removeEventListener('touchend', this.onTouchEnd);
    const touchEndElement = document.elementFromPoint(
      e.changedTouches[0].pageX,
      e.changedTouches[0].pageY
    );
    const touchEndNode = touchEndElement ? touchEndElement.parentNode : null;
    this.onPointerUp(e.changedTouches[0].pageX, e.changedTouches[0].pageY);
    if (touchEndNode === this.touchStartElement) {
      if (this.touchCount === 0) {
        this.touchCount = 1;
      } else if (this.touchCount === 1) {
        this.props.onDoubleClick();
        this.touchCount = 0;
      }
    }
    this.touchStartElement = null;
  }

  onMouseUp(e) {
    e.stopPropagation();
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
    this.onPointerUp(e.pageX, e.pageY);
  }

  onPointerMove(pageX, pageY, targetNodeName) {
    if (!this.state.isMoving) return;
    this.props.handleModelHighlight(targetNodeName);
    this.setCoord(pageX, pageY);
  }

  onTouchMove(e) {
    const intersectElement = document.elementFromPoint(
      e.changedTouches[0].pageX,
      e.changedTouches[0].pageY
    );
    const intersectNode = intersectElement ? intersectElement.parentNode : null;
    let targetNodeName = null;
    if (intersectNode)
      targetNodeName =
        intersectNode === this.touchTarget ? 'CANVAS' : intersectNode.nodeName;
    this.onPointerMove(
      e.changedTouches[0].pageX,
      e.changedTouches[0].pageY,
      targetNodeName
    );
  }

  onMouseMove(e) {
    this.onPointerMove(e.pageX, e.pageY, e.target.nodeName);
  }

  setCoord(x, y) {
    this.setState({ coord: { x, y } });
  }

  render() {
    const color = FormatUtils.getCssColor(this.props.color);
    return (
      <StaticSwatch
        interactive={
          !this.props.static || typeof this.props.onSelect === 'function'
        }
        small={this.props.small}
        swatchColor={color}
        element={this.props.element}
        isMoving={this.state.isMoving}
        disabled={this.props.disabled}
        selected={this.props.selected}
        bright={MaterialUtils.isColorBright(color)}
        forceShowLabel={this.props.forceShowLabel}
        onClick={(e) => this.props.onClick(e)}
        onMouseDown={(e) => this.onMouseDown(e)}
        onTouchStart={(e) => this.onTouchStart(e)}
        onDoubleClick={() => this.props.onDoubleClick()}>
        <span>{this.props.label}</span>
        {this.state.isMoving && (
          <MovingSwatch
            small={this.props.small}
            swatchColor={color}
            isMoving={this.state.isMoving}
            coord={this.state.coord}
            bright={MaterialUtils.isColorBright(color)}
          />
        )}
        <RingWrapper
          disableHover={
            this.props.static || typeof this.props.onSelect !== 'function'
          }
          small={this.props.small}>
          <Ring
            selected={this.props.selected}
            small={this.props.small}
            disabled={this.props.disabled}
            bright={MaterialUtils.isColorBright(color)}>
            <circle stroke='none' fill='none' />
          </Ring>
        </RingWrapper>
      </StaticSwatch>
    );
  }
}

export default ColorSwatch;

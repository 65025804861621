import styled, { css } from 'styled-components';
import { Shadows } from '../../themes';

import { Image } from '../../shared';

export const DeviceLabel = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
`;

export const DeviceName = styled.div`
  min-width: 0;
`;

export const DeviceImage = styled(Image)`
  padding: 0.75rem;
  height: calc(100% - 1.5rem);
`;

export const DeviceIndicator = styled.div``;

export const InfoContainer = styled.div`
  flex-grow: 1;
  min-width: 0;

  height: calc(100% - 2.75rem);
  padding: 1.375rem;

  display: flex;
  flex-direction: column;
  text-align: flex-start;
  justify-content: space-between;
`;

export const DeviceLink = styled.a`
  color: inherit;
`;

export const majorSectionStyles = css`
  background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  ${Shadows.d1};
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

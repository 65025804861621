import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import { handleGenericError } from '../common';
import { makeRequest } from './horizon';
import { actions } from '../../reducers/iot/iot';

export default function* controlMotor(action) {
  const { device, controlData } = action.payload;

  try {
    yield call(
      makeRequest,
      device.id,
      'printer/motor',
      {
        method: 'post',
        query: controlData,
      },
      'controlMotor'
    );

    yield put(actions.controlMotorSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import React from 'react';

const angleThresholdTriangle = (
  <>
    First, faces are grouped into regions where the angle between any two
    adjacent faces is no greater than this threshold.
  </>
);

const angleThresholdSegment = (
  <>
    Next, adjacent regions are merged together if the angle between them is no
    greater than this threshold. The angle of the region is considered as an
    average of its faces. This threshold is generally larger than the face angle
    threshold, to improve handling of curves and imperfections in model
    surfaces.
  </>
);

const sizeThreshold = (
  <>
    Finally, if a region has fewer than this number of faces, it will be merged
    into the adjacent region with the closest angle to it. This is especially
    useful for high-resolution models, where it can be easy to miss very small
    regions when painting.
  </>
);

export default {
  angleThresholdTriangle,
  angleThresholdSegment,
  sizeThreshold,
};

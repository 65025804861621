import 'regenerator-runtime/runtime';
import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

const updateMaterials = (updatedMaterial, existingMaterials) => {
  const materialToUpdate = existingMaterials[updatedMaterial.id];
  return {
    ...existingMaterials,
    [updatedMaterial.id]: {
      ...materialToUpdate,
      ...updatedMaterial,
    },
  };
};

export default function* editMaterial(action) {
  try {
    const { materials: existingMaterials, currentProject } = yield select(
      getSlicerState
    );
    const { material } = action.payload;
    const body = _.omit(material, 'id');

    const response = yield call(API, {
      method: methods.POST,
      path: `materials/${material.id}`,
      body,
    });
    if (response === null) return;

    const updatedMaterials = updateMaterials(
      response.material,
      existingMaterials
    );
    yield put(actions.editMaterialSuccess(updatedMaterials));
    if (currentProject) yield put(actions.invalidateSlice(currentProject));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import styled from 'styled-components';
import { Breakpoints } from '../../../../themes';

export const PrintStatsContainer = styled.div`
  user-select: text;
  padding: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.textPrimary};
`;

export const Heading = styled.h2`
  font-size: 0.9rem;
  font-weight: ${(props) => props.theme.fonts.weightRegular};
  margin: 0.3rem 0.5rem;
  padding-left: ${(props) => props.indent * 1}rem;
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    margin: 0.3rem 0;
  }
`;

export const HeadingTitle = styled.span`
  font-weight: ${(props) => props.theme.fonts.weightBold};
  margin-right: 0.2rem;
`;

export const HeadingValue = styled.span``;

export const Table = styled.table`
  margin: 0.4rem 0;
  border-collapse: collapse;
  text-align: left;
  font-weight: ${(props) => props.theme.fonts.weightRegular};
  font-size: 0.9rem;
  margin-left: ${(props) => props.indent * 1}rem;
`;

export const TableRow = styled.tr`
  vertical-align: top;
`;

export const TableBody = styled.tbody``;

export const TableData = styled.td`
  &:not(:first-of-type) {
    padding-left: 0.5rem;
  }
`;

export const ColorPatch = styled.span`
  width: 1rem;
  height: 1rem;
  display: inline-block;
  border-radius: 2px;
  background-color: ${(props) => props.hex};
  border: 1px solid ${(props) => props.theme.colors.grey4};
  box-sizing: border-box;
`;

export const PlaceholderLabelWrapper = styled.div`
  text-align: center;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { actions as printerActions } from '../../../reducers/printers/printers';

import ImportPrinter from './importPrinter.jsx';

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.refreshToken,
  loginPending: state.auth.status.loginPending,
  loginSuccess: state.auth.status.loginSuccess,
  currentSharedPrinter: state.printers.currentSharedPrinter,
  printers: state.printers.printers,
  getPrintersSuccess: state.printers.status.getPrintersSuccess,
  createPrinterPending: state.printers.status.createPrinterPending,
  createPrinterSuccess: state.printers.status.createPrinterSuccess,
  getSharedPrinterPending: state.printers.status.getSharedPrinterPending,
  getSharedPrinterSuccess: state.printers.status.getSharedPrinterSuccess,
  importSharedPrinterPending: state.printers.status.importSharedPrinterPending,
  importSharedPrinterSuccess: state.printers.status.importSharedPrinterSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getSharedPrinter: (shareId) =>
    dispatch(printerActions.getSharedPrinterRequest(shareId)),
  importSharedPrinter: (
    importPrinter,
    stylesToImport,
    printerId = '',
    newPrinterName = ''
  ) =>
    dispatch(
      printerActions.importSharedPrinterRequest(
        importPrinter,
        stylesToImport,
        printerId,
        newPrinterName
      )
    ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ImportPrinter)
);

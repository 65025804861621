import React, { Component } from 'react';
import _ from 'lodash';

import { Page, Modal, Button, Link } from '../../shared';

import {
  Container,
  ButtonsContainer,
  ModalTextWrapper,
  PermissionsList,
  PermissionListItem,
  ModalButtonWrapper,
} from './userAuthorization.styles';

import { FormatUtils } from '../../utils';

import {
  Subtitle1,
  Subtitle2,
  Body1,
  Caption,
} from '../../shared/typography/typography';

class UserAuthorization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: {},
    };
  }

  componentDidMount() {
    const { pathname, search: queryParams } = this.props.location;
    const route = `${pathname}${queryParams}`;
    this.props.updateNavStack([
      { text: 'User Authorization', route, id: null },
    ]);
    const parsedParams = FormatUtils.parseQueryParams(queryParams);
    this.setState({ queryParams: parsedParams });
    this.props.getIntegrations();
  }

  handleUserAuthorizationClick(userAuthorized) {
    const {
      client_id: clientId,
      redirect_uri: redirectUri,
      response_type: responseType,
      state,
    } = this.state.queryParams;
    this.props.authorizeIntegration(
      clientId,
      redirectUri,
      responseType,
      state,
      userAuthorized
    );
  }

  renderSinglePermission(text, index) {
    return (
      <PermissionListItem key={index}>
        <ModalTextWrapper>
          <Body1>{text}</Body1>
        </ModalTextWrapper>
      </PermissionListItem>
    );
  }

  renderPermissions() {
    const { permissions } = _.find(
      this.props.allIntegrations,
      (integration) => integration.id === this.state.queryParams.client_id
    );
    return (
      <>
        <ModalTextWrapper>
          <Subtitle2>Permissions:</Subtitle2>
        </ModalTextWrapper>
        <PermissionsList>
          {_.map(permissions, (permission, index) =>
            this.renderSinglePermission(permission, index)
          )}
        </PermissionsList>
      </>
    );
  }

  renderAuthorizationPanel() {
    const { getIntegrationsSuccess, allIntegrations } = this.props;
    if (!getIntegrationsSuccess) return null;
    const { name: integrationPartnerName } = _.find(
      allIntegrations,
      (integration) => integration.id === this.state.queryParams.client_id
    );
    return (
      <Modal width='30em'>
        <Container>
          <ModalTextWrapper>
            <Subtitle1>Authorization Required</Subtitle1>
          </ModalTextWrapper>
          <ModalTextWrapper>
            <Body1>
              {integrationPartnerName} would like to connect your{' '}
              {integrationPartnerName} account to your Canvas account.
            </Body1>
          </ModalTextWrapper>
          {this.renderPermissions()}
          <ModalTextWrapper>
            <Caption grey>
              By linking these accounts, you allow {integrationPartnerName} and
              Canvas to use your information in accordance with their respective
              terms of service. You may unlink your accounts at any time in your{' '}
              <Link external href='https://canvas3d.io/account-settings'>
                Canvas account settings
              </Link>
              .
            </Caption>
          </ModalTextWrapper>
          <ButtonsContainer>
            <ModalButtonWrapper>
              <Button onClick={() => this.handleUserAuthorizationClick(false)}>
                Cancel
              </Button>
            </ModalButtonWrapper>
            <ModalButtonWrapper>
              <Button
                primary
                onClick={() => this.handleUserAuthorizationClick(true)}>
                Allow
              </Button>
            </ModalButtonWrapper>
          </ButtonsContainer>
        </Container>
      </Modal>
    );
  }

  render() {
    return <Page>{this.renderAuthorizationPanel()}</Page>;
  }
}

export default UserAuthorization;

import styled, { css, keyframes } from 'styled-components';

import { Body1 } from '../typography/typography';

export const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.circular ? 'column' : 'row')};
  justify-content: center;
  align-items: center;
`;

export const TextWrapper = styled.div`
  padding: 1rem;
  cursor: default;
  pointer-events: none;
  user-select: none;
  ${Body1} {
    color: ${(props) => props.color};
  }
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const StyledProgressRing = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.radius * 2}px;
  height: ${(props) => props.radius * 2}px;
`;

export const Rings = styled.svg`
  width: ${(props) => props.radius * 2}px;
  height: ${(props) => props.radius * 2}px;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transform: rotate(-90deg);
  ${(props) =>
    props.determinate
      ? null
      : css`
          animation: ${rotate360} 1s linear infinite;
        `};
`;

export const EmptyRing = styled.circle`
  stroke: ${(props) => props.color};
  fill: transparent;
`;

export const ColorRing = styled.circle`
  stroke: ${(props) => props.color};
  fill: transparent;
  transition: stroke-dashoffset 0.3s ease-out;
`;

export const StyledProgressLine = styled.div`
  position: relative;
  overflow-x: hidden;
  width: 100%;
  height: ${(props) => props.stroke}px;
  background-color: ${(props) => props.color};
`;

// unknown issue: ${(props) => props.progress} will not work on 'left:'
const left100 = keyframes`
  from { left: -100%; width: ${(props) => props.progress}%; }
  to { left: 100%; width: ${(props) => props.progress}%;}
`;

export const ColorLine = styled.div`
  position: absolute;
  background-color: ${(props) => props.color};
  width: ${(props) => props.progress}%;
  height: ${(props) => props.stroke}px;
  transition: width 0.5s ease-out;
  ${(props) =>
    props.determinate
      ? null
      : css`
          animation: ${left100} 1s linear infinite;
        `};
`;

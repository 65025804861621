import styled from 'styled-components';
import { Shadows } from '../../../themes';

export const Panel = styled.div`
  width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 0.25rem;
  background: ${(props) => props.theme.colors.whiteDefault};
  ${Shadows.d1};
`;

export const HeaderContainer = styled.div`
  padding: 0 0.75rem;
  width: 100%;
  height: 3rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey3};
`;

export const Header = styled.div`
  color: ${(props) => props.theme.colors.textPrimary};
`;

export const Content = styled.div`
  padding: 0 0.25rem;
  width: 100%;
  box-sizing: border-box;
`;

export const ActionButtonContainer = styled.div`
  display: flex;
`;

import React, { useEffect, useRef } from 'react';

import HeaderBar from './headerBar/headerBar.jsx';
import Drawer from './drawer/drawer.jsx';
import PageTitle from './headerBar/pageTitle/pageTitle.jsx';

import {
  StyledPage,
  NavPanel,
  MainPanel,
  PageTitleContainer,
  ActionBarTop,
  ActionButtonsContainer,
  MenuButtonsContainer,
  Content,
} from './page.styles';

const Page = ({
  children,
  // general
  isAuthenticated = false,
  hideDivider = false,

  navPanel = <Drawer />, // leftPanel
  menuButtons = null, // buttons JSX
  actionButtons = null, // actionButtons JSX
  filterBar = null, // filter bar JSX
}) => {
  const pageRef = useRef(null);

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.focus();
    }
  }, [pageRef]);

  const renderNavPanel = () => {
    return <NavPanel>{navPanel}</NavPanel>;
  };

  const renderPageTitle = () => {
    return (
      <PageTitleContainer>
        <PageTitle />
      </PageTitleContainer>
    );
  };

  const renderActionBarButtons = () => {
    if (actionButtons || menuButtons) {
      return (
        <>
          <ActionButtonsContainer>{actionButtons}</ActionButtonsContainer>
          <MenuButtonsContainer>{menuButtons}</MenuButtonsContainer>
        </>
      );
    }
    return null;
  };

  const renderActionBar = () => {
    return (
      <>
        <ActionBarTop hideDivider={hideDivider}>
          {isAuthenticated && renderPageTitle()}
          {renderActionBarButtons()}
        </ActionBarTop>
        {filterBar}
      </>
    );
  };

  const renderMainPanel = () => {
    return (
      <MainPanel ref={pageRef} tabIndex={0} id='main-panel'>
        {renderActionBar()}
        <Content>{children}</Content>
      </MainPanel>
    );
  };

  return (
    <>
      {isAuthenticated && <HeaderBar pageComponent />}
      <StyledPage isAuthenticated={isAuthenticated}>
        {isAuthenticated && renderNavPanel()}
        {renderMainPanel()}
      </StyledPage>
    </>
  );
};

export default Page;

import React, { Component } from 'react';

import AbstractDropdown from '../abstractDropdown/abstractDropdown.jsx';

class Dropdown extends Component {
  render() {
    return <AbstractDropdown {...this.props} />;
  }
}
export default Dropdown;

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import API, { methods } from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

function* update(projectId, action) {
  try {
    const { style } = action.payload;

    const response = yield call(API, {
      method: methods.POST,
      path: `projects/${projectId}`,
      body: { style },
    });
    if (response === null) return;
    const { project } = response;

    const updatedSettings = {};
    _.forEach(style, (value, key) => {
      updatedSettings[key] = project.style[key];
    });

    yield put(actions.updateProjectStyleSuccess(updatedSettings));
    yield put(
      actions.updateTowerFromServer(projectId, project.transitionTower)
    );
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

let callInProgress = false;
let queuedCall = null;

/**
 * Saga to handle silent update of project "style" only
 * - set of values for style settings local to project
 * This saga is used for cases where the settings need to be updated automatically,
 *   without prompting the user to manually update them via <ProjectSettings />
 * e.g., attempting to entering SupportView while relevant settings are not yet configured
 * Note: not to be confused with `updateProjectSettings` saga,
 *   which handles updating of other project settings as well
 */
export default function* updateProjectStyle(action) {
  const { currentProject: projectId } = yield select(getSlicerState);
  if (callInProgress) {
    queuedCall = {
      projectId,
      action,
    };
  } else {
    callInProgress = true;
    yield call(update, projectId, action);
    while (queuedCall) {
      const { projectId: queuedProjectId, action: queuedAction } = queuedCall;
      queuedCall = null;
      yield call(update, queuedProjectId, queuedAction);
    }
    callInProgress = false;
  }
}

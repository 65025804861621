const types = {
  RESET_ONBOARDING_STORE: 'ONBOARDING/RESET_ONBOARDING_STORE',

  GET_ONBOARDING_STATE_REQUEST: 'ONBOARDING/GET_ONBOARDING_STATE_REQUEST',
  GET_ONBOARDING_STATE_SUCCESS: 'ONBOARDING/GET_ONBOARDING_STATE_SUCCESS',
  GET_ONBOARDING_STATE_FAILURE: 'ONBOARDING/GET_ONBOARDING_STATE_FAILURE',

  START_MODAL_REQUEST: 'ONBOARDING/START_MODAL_REQUEST',
  START_MODAL_SUCCESS: 'ONBOARDING/START_MODAL_SUCCESS',
  START_MODAL_FAILURE: 'ONBOARDING/START_MODAL_FAILURE',

  COMPLETE_MODAL_REQUEST: 'ONBOARDING/COMPLETE_MODAL_REQUEST',
  COMPLETE_MODAL_SUCCESS: 'ONBOARDING/COMPLETE_MODAL_SUCCESS',
  COMPLETE_MODAL_FAILURE: 'ONBOARDING/COMPLETE_MODAL_FAILURE',

  START_TASK_REQUEST: 'ONBOARDING/START_TASK_REQUEST',
  START_TASK_SUCCESS: 'ONBOARDING/START_TASK_SUCCESS',
  START_TASK_FAILURE: 'ONBOARDING/START_TASK_FAILURE',

  SET_TASK_STEP_STATUS_REQUEST: 'ONBOARDING/SET_TASK_STEP_STATUS_REQUEST',
  SET_TASK_STEP_STATUS_SUCCESS: 'ONBOARDING/SET_TASK_STEP_STATUS_SUCCESS',
  SET_TASK_STEP_STATUS_FAILURE: 'ONBOARDING/SET_TASK_STEP_STATUS_FAILURE',
};

export default types;

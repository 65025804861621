import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import { handleGenericError } from '../common';
import { makeRequest } from './horizon';
import { actions } from '../../reducers/iot/iot';

export default function* feedFilament(action) {
  const { device, feedData } = action.payload;

  try {
    yield call(
      makeRequest,
      device.id,
      'printer/move',
      {
        method: 'post',
        query: {
          ...feedData,
          f: device.type === 'canvas-hub' ? 1 : 100, // 100 for p3 as stopgap
          nozzle: 1, // set different values here for multi-extruders?
        },
      },
      'feedFilament'
    );
    yield put(actions.feedFilamentSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

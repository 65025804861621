import styled from 'styled-components';
import Shadows from '../../../../themes/shadows';

export const Container = styled.div``;

export const SearchResultsContainer = styled.div`
  position: absolute;
  top: 3.25rem;

  width: 21rem;
  padding: 0.5rem 0;
  max-height: 90vh;
  overflow-y: auto;

  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 0.25rem;
  box-sizing: border-box;

  background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  ${(props) => !props.showResults && 'display: none;'};
  ${Shadows.d2}
`;

export const ResultsGroup = styled.div`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.colors.grey3};
  }
`;

export const NoResult = styled.div`
  height: 3.125rem;
  padding: 0 0.5rem;
  width: calc(100% - 1rem);

  display: flex;
  text-align: center;
  align-items: center;
`;

export const MaterialLabel = styled.div`
  display: flex;
  gap: 0.5rem;
`;

import _ from 'lodash';

import { TaskStatus } from './constants';

import {
  taskListOrder,
  taskMetadata,
} from '../../views/onboarding/taskManager/metadata';
import { modalOrder } from '../../views/onboarding/modalManager/metadata';

const getInitialModalState = () => {
  const modalState = {};
  _.forEach(modalOrder, (identifier) => {
    modalState[identifier] = {
      started: false,
      completed: false,
    };
  });
  return modalState;
};

const getInitialTaskState = () => {
  const taskState = {};
  _.forEach(taskListOrder, (identifier) => {
    const steps = {};
    _.forEach(taskMetadata[identifier].stepOrder, (step) => {
      steps[step] = TaskStatus.INCOMPLETE;
    });
    taskState[identifier] = {
      started: false,
      steps,
    };
  });
  return taskState;
};

const initialState = {
  modals: getInitialModalState(),
  tasks: getInitialTaskState(),
  status: {
    getOnboardingStatePending: false,
    getOnboardingStateSuccess: false,
    setOnboardingStatePending: false,
    setOnboardingStateSuccess: false,
  },
};

export default initialState;

import styled from 'styled-components';

import { Breakpoints } from '../../../../../themes';

export const FieldContainer = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
  margin-top: 0.5rem;
  min-width: 12rem;
`;

export const AcceptButtonSpan = styled.span`
  margin-left: 0.3em;

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: none;
  }

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    display: none;
  }
`;

export const AcceptButtonWrapper = styled.div`
  width: 100%;
  margin: 0.5em 0;
`;

export const ToggleButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem 0 0.625rem;
  justify-content: space-between;
  span {
    white-space: normal;
  }

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    flex-direction: column;
    span {
      padding-bottom: 0.5em;
    }
  }
`;

export const CreateRegionsPanelWrapper = styled.div`
  h6 {
    overflow: hidden;
  }
`;

export const ContainerContent = styled.div`
  max-height: calc(100vh - 10rem);
  overflow-y: auto;
`;

import styled from 'styled-components';
import { Breakpoints } from '../../themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 5em;

  @media (min-height: ${Breakpoints.largeHeight}) {
    margin-top: 15em;
  }
`;

export const WarningTextWrapper = styled.div`
  margin-bottom: 1em;
`;

import styled from 'styled-components';

import {
  defaultModalContentStyles,
  defaultModalButtonStyles,
} from '../modal/modal.styles';

import { Breakpoints, Shadows } from '../../themes';

export const ModalContent = styled.div`
  ${defaultModalContentStyles}
  display: grid;
  grid-template-columns: 1fr 1fr max-content;
  grid-template-rows: repeat(7, min-content);
  grid-row-gap: 1rem;
  transition: background-color 0.2s;
  position: relative;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    grid-template-rows: repeat(8, min-content);
  }
`;

export const ShareProjectNameWrapper = styled.div`
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-column: 1 / span 5;
  width: 100%;
  text-align: left;
  max-width: none;
`;

export const ShareSelectionsWrapper = styled.div`
  grid-row: 2;
  grid-column: 1 / span 5;
`;

export const CheckboxListWrapper = styled.div`
  grid-column: span 5;
  list-style: none;
  padding-left: 0;
  margin: 0;
`;

export const ShareTypeDropdownWrapper = styled.div`
  display: grid;
  grid-area: 4 / span 4;
  width: 100%;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    grid-column: span 5;
  }
`;

export const LinkContainerStyles = styled.div`
  display: grid;
  grid-row: 5;
  grid-column: span 4;
  width: 100%;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
`;

export const CopyLinkButtonStyles = styled.div`
  ${defaultModalButtonStyles}
  display: grid;
  width: 100%;
  grid-row: 5;
  grid-column: span 1;
  margin-top: -0.5rem;

  @media (max-width: ${Breakpoints.smallWidth}) {
    min-width: 0;
  }
`;

export const DateSharedContainer = styled.div`
  display: grid;
  grid-row: 6;
  grid-column: 1 / span 3;
  max-width: fit-content;
  padding-right: 1rem;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    grid-column: span 5;
  }
`;

export const UpdateSharedCopyButtonWrapper = styled.div`
  ${defaultModalButtonStyles}
  display: grid;
  grid-row: 6;
  grid-column: 4 / span 2;
  align-content: flex-start;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    grid-row: 7;
  }
`;

export const FooterButtonWrapper = styled.div`
  ${defaultModalButtonStyles};
  display: grid;
  grid-row: 7;
  grid-column: 5;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    grid-row: 8;
  }
`;

export const Notification = styled.div`
  display: flex;
  width: min-content;
  height: 2rem;
  padding: 0.05rem 2.5rem;
  position: absolute;
  top: 1%;
  left: 50%;
  transform: translateX(-50%);
  visibility: ${(props) => (props.reveal ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.reveal ? '1' : '0')};
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.yellowLight};
  transition: 0.2s;
  ${Shadows.d1};
`;

export const NotificationTextWrapper = styled.div`
  align-items: center;
  user-select: none;
  margin: 0.1rem;
  display: flex;
  text-align: center;
  white-space: nowrap;
  max-width: 20rem;
`;

export const SpliceSettingsTextWrapper = styled.div`
  margin-left: 2rem;
  text-align: left;
  font-style: italic;
  white-space: normal;
`;

import React from 'react';

import {
  FilterBarContainer,
  LeftBar,
  MiddleBar,
  RightBar,
  StyledHeaderBar,
} from './headerBar.styles';

import GlobalSearchBar from './globalSearchBar/globalSearchBar.jsx';
import HeaderBarLeft from './headerBarLeft/headerBarLeft.container';
import HeaderBarRight from './headerBarRight/headerBarRight.container';
import IotConnectionBar from './iotConnectionBar/iotConnectionBar.container';
import PageTitle from './pageTitle/pageTitle.jsx';

const HeaderBar = ({ pageComponent = false }) => {
  const renderSearchBar = () => (
    <FilterBarContainer pageComponent={pageComponent}>
      <GlobalSearchBar />
    </FilterBarContainer>
  );

  const renderLeftBar = () => (
    <LeftBar pageComponent={pageComponent}>
      <HeaderBarLeft pageComponent={pageComponent} />
      {renderSearchBar()}
    </LeftBar>
  );

  const renderRightBar = () => (
    <RightBar pageComponent={pageComponent}>
      <HeaderBarRight />
    </RightBar>
  );

  const renderMiddleBar = () => (
    <MiddleBar>
      <PageTitle />
    </MiddleBar>
  );

  return (
    <StyledHeaderBar>
      {renderLeftBar()}
      {!pageComponent && renderMiddleBar()}
      {renderRightBar()}
      <IotConnectionBar />
    </StyledHeaderBar>
  );
};

export default HeaderBar;

import styled from 'styled-components';
import { Breakpoints } from '../../../../themes';

export const Header = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, min-content);
  margin-bottom: 1rem;
`;

export const HeadingContainer = styled.div`
  grid-row: 1;
  grid-column: 1 / span 3;
  @media (max-width: ${Breakpoints.smallWidth}) {
    grid-row: 2;
    grid-column: 1 / span 5;
  }
`;

export const ThumbnailPreviewContainer = styled.div`
  grid-row: 1 / span 2;
  grid-column: 4 / span 2;
  background: ${(props) => props.theme.colors.grey2};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 11rem;
  position: relative;
  width: 100%;

  @media (max-width: ${Breakpoints.smallWidth}) {
    grid-row: 1;
    grid-column: 1 / span 5;
  }
`;

export const NewProjectNameInputWrapper = styled.div`
  margin-top: auto;
  display: grid;
  grid-row: 2;
  grid-column: 1 / span 3;

  @media (max-width: ${Breakpoints.smallWidth}) {
    display: grid;
    grid-column: 1 / span 5;
    grid-row: 3;
  }
`;

export const DropdownContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  margin-bottom: 1rem;
  height: 3.625rem;

  @media (max-width: ${Breakpoints.smallWidth}) {
    grid-template-columns: none;
    grid-template-rows: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 1rem;
  }
`;

export const ImportSelectionItem = styled.div`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const ImportSelectionLabelContainer = styled.div`
  display: flex;
  align-items: center;
  line-height: 2rem;

  @media (max-width: ${Breakpoints.smallWidth}) {
    max-width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0.5rem;
  }
`;

export const ImportMaterialsNameWrapper = styled.div`
  justify-content: flex-start;
  margin-left: 1.5rem;

  @media (max-width: ${Breakpoints.smallWidth}) {
    margin-left: 0;
  }
`;

export const BoxSelectionWrapper = styled.div`
  width: 66%;

  @media (max-width: ${Breakpoints.smallWidth}) {
    width: 100%;
  }
`;

export const ImportSpliceSettingsWrapper = styled.div`
  margin-top: 0.8rem;
`;

import _ from 'lodash';

import ProfileParser from './parser';
import {
  Firmware,
  PerimeterOrder,
  SolidFillStyles,
  SupportStyles,
  InfillStyles,
} from './enums';
import { parseIni } from './utils';
import FormatUtils from '../format/format';

const { roundTo } = FormatUtils;

const parseCoordinateList = (input) =>
  _.map(input.split(','), (coord) =>
    _.map(coord.split('x'), (dimension) => parseFloat(dimension))
  );

const parseFloatList = (input) =>
  _.map(input.split(','), (float) => parseFloat(float));

const parseFloatOrPercentMultiplier = (num, multiplicand) => {
  if (num.endsWith('%')) {
    return (multiplicand * parseInt(num.slice(0, -1), 10)) / 100;
  }
  return parseFloat(num);
};

const convertSolidLayerStyle = (fillPattern) => {
  switch (fillPattern) {
    case 'rectilinear':
      return [SolidFillStyles.STRAIGHT, false];
    case 'monotonic':
      return [SolidFillStyles.MONOTONIC, true];
    case 'concentric':
      return [SolidFillStyles.CONCENTRIC, false];
    default:
      return [SolidFillStyles.STRAIGHT, false];
  }
};

export default class Slic3rParser extends ProfileParser {
  cleanGCode(gcode) {
    return super
      .cleanGCode(gcode)
      .replace(/\\n/g, '\n')

      .replace(/\[previous_extruder]/g, '<EXT+0>')
      .replace(/\[current_extruder]/g, '<EXT+0>')
      .replace(/\[next_extruder]/g, '<EXT+0>')

      .replace(/\[layer_z]/g, '<Z>')

      .replace(/\[retract_length]/g, '<DESTRING>')
      .replace(/\[retract_length_toolchange]/g, '<DESTRING>')
      .replace(/\[retract_restart_extra]/g, '<DESTRING>')
      .replace(/\[retract_restart_extra_toolchange]/g, '<DESTRING>')

      .replace(/\[temperature]/g, '<TEMP>')
      .replace(/\[temperature_\d+]/g, '<TEMP>')
      .replace(/\[temperature\[previous_extruder]]/g, '<TEMP>')
      .replace(/\[temperature\[current_extruder]]/g, '<TEMP>')
      .replace(/\[temperature\[next_extruder]]/g, '<TEMP>')
      .replace(/\[first_layer_temperature]/g, '<TEMP>')
      .replace(/\[first_layer_temperature_\d+]/g, '<TEMP>')
      .replace(/\[first_layer_temperature\[previous_extruder]]/g, '<TEMP>')
      .replace(/\[first_layer_temperature\[current_extruder]]/g, '<TEMP>')
      .replace(/\[first_layer_temperature\[next_extruder]]/g, '<TEMP>')

      .replace(/\[bed_temperature]/g, '<BED>')
      .replace(/\[first_layer_bed_temperature]/g, '<BED>')

      .replace(/\[layer_num]/g, '<LAYER>')

      .replace(/\[default_acceleration]/g, '<ACCEL>');
  }

  parse(input) {
    const defaultPrinter = ProfileParser.getDefaultPrinter();
    const { machineSettings } = defaultPrinter;
    const style = defaultPrinter.styles[0];
    const warnings = {};

    const ini = parseIni(input);

    // G-code sequences
    machineSettings.startSequence = this.cleanGCode(ini.start_gcode || '');
    machineSettings.endSequence = this.cleanGCode(ini.end_gcode || '');
    ['startSequence', 'endSequence'].forEach((sequence) => {
      const gcode = machineSettings[sequence];
      if (
        gcode.includes('[') ||
        gcode.includes(']') ||
        gcode.includes('{') ||
        gcode.includes('}')
      ) {
        const lines = gcode.split('\n');
        const sanitizedLines = [];
        lines.forEach((line) => {
          if (
            line.includes('[') ||
            line.includes(']') ||
            line.includes('{') ||
            line.includes('}')
          ) {
            if (!warnings[sequence]) {
              warnings[sequence] = [];
            }
            warnings[sequence].push(line);
            sanitizedLines.push(`; ${line}`);
          } else {
            sanitizedLines.push(line);
          }
        });
        machineSettings[sequence] = sanitizedLines.join('\n');
      }
    });

    // output filename format
    if (ini.output_filename_format) {
      machineSettings.extension = ini.output_filename_format.split('.').pop();
    }

    // bed shape
    {
      const bedCoords = parseCoordinateList(ini.bed_shape);
      const maxPrintHeight = roundTo(parseFloat(ini.max_print_height), 1);
      if (bedCoords.length > 4) {
        // circular bed
        machineSettings.circular = true;
        const diameter = bedCoords.pop()[0] * 2;
        const radius = roundTo(diameter / 2, 1);
        machineSettings.bedSize = {
          x: diameter,
          y: diameter,
          z: maxPrintHeight || diameter,
        };
        machineSettings.originOffset = {
          x: radius,
          y: radius,
        };
      } else {
        // rectangular bed
        const [xMin, yMin] = bedCoords[0];
        const [xMax, yMax] = bedCoords[2];
        const bedSizeX = roundTo(xMax - xMin, 1);
        const bedSizeY = roundTo(yMax - yMin, 1);
        const bedOffsetX = xMin;
        const bedOffsetY = yMin;
        machineSettings.bedSize = {
          x: bedSizeX,
          y: bedSizeY,
          z: maxPrintHeight || Math.max(bedSizeX, bedSizeY),
        };
        machineSettings.originOffset = {
          x: bedOffsetX,
          y: bedOffsetY,
        };
      }
    }

    // firmware
    if (ini.use_relative_e_distances === '1') {
      machineSettings.firmwareType = Firmware.FIRMWARE_5D_REL;
    } else {
      machineSettings.firmwareType = Firmware.FIRMWARE_5D_ABS;
    }

    // Z offset
    style.zOffset = roundTo(parseFloat(ini.z_offset), 2);

    // layer height
    style.layerHeight = roundTo(parseFloat(ini.layer_height), 2);
    style.firstLayerHeight = {
      units: 'mm',
      value: roundTo(
        parseFloatOrPercentMultiplier(
          ini.first_layer_height,
          style.layerHeight
        ),
        2
      ),
    };

    // skin thickness
    {
      const bottomLayers = parseInt(ini.bottom_solid_layers, 10);
      const topLayers = parseInt(ini.top_solid_layers, 10);
      style.skinThickness = {
        units: 'layers',
        value: Math.max(bottomLayers, 1),
      };
      style.topSkinThickness = {
        units: 'layers',
        value: Math.max(topLayers, 1),
      };
    }

    // solid layer style
    if (ini.external_fill_pattern) {
      // Slic3r
      const [fillStyle, monotonic] = convertSolidLayerStyle(
        ini.external_fill_pattern
      );
      style.solidLayerStyle = fillStyle;
      style.monotonicSweep = monotonic;
    } else {
      // PrusaSlicer
      if (ini.bottom_fill_pattern) {
        const [fillStyle, monotonic] = convertSolidLayerStyle(
          ini.bottom_fill_pattern
        );
        style.solidLayerStyle = fillStyle;
        style.monotonicSweep = monotonic;
      }
      if (ini.top_fill_pattern) {
        const [fillStyle, monotonic] = convertSolidLayerStyle(
          ini.top_fill_pattern
        );
        style.topSolidLayerStyle = fillStyle;
        // don't set monotonic back to false if bottom style already set it to true
        style.monotonicSweep = style.monotonicSweep || monotonic;
      }
    }

    // ironing
    if (ini.ironing) {
      style.useIroning = ini.ironing !== '0';
      if (ini.ironing_flowrate) {
        style.ironingFlowrate = {
          units: '%',
          value: parseInt(ini.ironing_flowrate, 10),
        };
      }
      if (ini.ironing_spacing) {
        style.ironingSpacing = {
          units: 'mm',
          value: roundTo(parseFloat(ini.ironing_spacing, 10), 2),
        };
      }
      if (ini.ironing_speed) {
        style.ironingSpeed = {
          units: 'mm/s',
          value: roundTo(parseFloat(ini.ironing_speed, 10), 1),
        };
      }
    }

    // disable fan for the first N layers
    if (ini.fan_always_on === '1') {
      style.enableFanAtLayer = 0;
    } else {
      style.enableFanAtLayer = parseInt(ini.disable_fan_first_layers, 10);
    }
    style.useFan = true;
    style.fanSpeed = parseInt(ini.max_fan_speed, 10) || 100;
    style.minLayerTime = parseInt(ini.slowdown_below_layer_time, 10);

    // nozzle diameters
    {
      const nozzleDiameters = parseFloatList(ini.nozzle_diameter);
      const filamentDiameters = parseFloatList(ini.filament_diameter);
      machineSettings.extruderCount = nozzleDiameters.length;
      machineSettings.nozzleDiameter = _.map(nozzleDiameters, (diam) =>
        roundTo(diam, 2)
      );
      machineSettings.filamentDiameter = _.map(filamentDiameters, (diam) =>
        diam > 2 ? 2.85 : 1.75
      );
    }

    // bowden tube length
    if (
      typeof ini.printer_notes === 'string' &&
      ini.printer_notes.includes('PRINTER_HAS_BOWDEN')
    ) {
      machineSettings.bowdenTubeLength = 200;
    } else if (
      typeof ini.notes === 'string' &&
      ini.notes.includes('PRINTER_HAS_BOWDEN')
    ) {
      machineSettings.bowdenTubeLength = 200;
    }

    // extrusion multiplier
    {
      const multipliers = parseFloatList(ini.extrusion_multiplier);
      style.extrusionMultiplier = Math.round(multipliers[0] * 100) || 100;
    }

    // temperatures
    style.bedTemperature = Math.round(parseFloat(ini.bed_temperature));
    style.printTemperature = Math.round(parseFloatList(ini.temperature)[0]);
    style.firstLayerPrintTemperature = {
      units: 'C',
      value: Math.round(parseFloatList(ini.first_layer_temperature)[0]),
    };
    if (style.firstLayerPrintTemperature === 0) {
      style.firstLayerPrintTemperature = {
        value: 'auto',
      };
    }

    // minimum layer time
    style.minLayerTime = Math.round(parseFloat(ini.slowdown_below_layer_time));

    // infill density
    style.infillDensity = parseInt(ini.fill_density.slice(0, -1), 10);

    // extrusion width
    style.extrusionWidth =
      roundTo(parseFloat(ini.extrusion_width), 2) ||
      machineSettings.nozzleDiameter[0];
    style.infillExtrusionWidth = {
      units: 'mm',
      value:
        roundTo(parseFloat(ini.infill_extrusion_width), 2) ||
        style.extrusionWidth,
    };

    // number of loops
    style.perimeterCount = parseInt(ini.perimeters, 10);

    // external perimeters first
    style.perimeterOrder =
      ini.external_perimeters_first === '0'
        ? PerimeterOrder.PERIMETER_LAST
        : PerimeterOrder.PERIMETER_FIRST;

    // perimeter extrusion width
    if (
      ini.perimeter_extrusion_width &&
      ini.perimeter_extrusion_width !== '0'
    ) {
      style.perimeterExtrusionWidth = {
        units: 'mm',
        value: parseFloat(ini.perimeter_extrusion_width),
      };
    }
    if (
      ini.external_perimeter_extrusion_width &&
      ini.external_perimeter_extrusion_width !== '0'
    ) {
      style.externalPerimeterExtrusionWidth = {
        units: 'mm',
        value: parseFloat(ini.external_perimeter_extrusion_width),
      };
    }

    // avoid crossing perimeters
    style.avoidCrossingPerimeters = ini.avoid_crossing_perimeters === '1';
    if (ini.avoid_crossing_perimeters_max_detour) {
      if (ini.avoid_crossing_perimeters_max_detour.endsWith('%')) {
        style.avoidCrossingPerimetersMaxDetour = {
          units: '%',
          value: parseInt(ini.avoid_crossing_perimeters_max_detour, 10),
        };
      } else {
        style.avoidCrossingPerimetersMaxDetour = {
          units: 'mm',
          value: roundTo(
            parseFloat(ini.avoid_crossing_perimeters_max_detour),
            1
          ),
        };
      }
    }

    // first layer size compensation
    if (ini.elefant_foot_compensation) {
      style.firstLayerSizeCompensation = roundTo(
        parseFloat(ini.elefant_foot_compensation),
        2
      );
    }

    // infill pattern
    {
      const fillPattern = ini.fill_pattern;
      switch (fillPattern) {
        case 'concentric':
        case 'honeycomb':
        case 'triangles':
          style.infillStyle = InfillStyles.OCTAGONAL;
          break;
        case '3d honeycomb':
        case 'hilbert curve':
        case 'gyroid':
          style.infillStyle = InfillStyles.CELLULAR;
          break;
        default:
          style.infillStyle = InfillStyles.STRAIGHT;
          break;
      }
    }

    // spiral vase mode
    style.spiralVaseMode = ini.spiral_vase !== '0';

    // infill overlap
    {
      const overlap = ini.infill_overlap;
      if (overlap.endsWith('%')) {
        style.infillPerimeterOverlap = parseInt(overlap.slice(0, -1), 10);
      } else {
        style.infillPerimeterOverlap = roundTo(
          (parseFloat(overlap) * 100) / style.extrusionWidth,
          0
        );
      }
    }

    // default extruders
    style.defaultSupportInterfaceExtruder = 0;
    style.defaultSupportExtruder = {
      value: 'auto',
    };
    style.defaultObjectExtruder = 0;
    style.defaultRaftExtruder = 0;

    // retraction
    style.retractLength = roundTo(parseFloatList(ini.retract_length)[0], 1);
    style.useRetract = style.retractLength > 0;
    style.retractSpeed = roundTo(parseFloatList(ini.retract_speed)[0], 1);
    style.zLift = roundTo(parseFloatList(ini.retract_lift)[0], 1);
    style.retractDisableThreshold = roundTo(
      parseFloatList(ini.retract_before_travel)[0],
      1
    );
    style.retractForceThreshold = style.retractDisableThreshold;
    if (ini.use_firmware_retraction === '1') {
      machineSettings.firmwareRetraction = 2;
    }

    // speeds
    style.rapidXYSpeed = roundTo(parseFloat(ini.travel_speed), 1);
    style.rapidZSpeed = style.rapidXYSpeed;
    style.infillSpeed = {
      units: 'mm/s',
      value: roundTo(parseFloat(ini.infill_speed), 1),
    };
    style.perimeterSpeed = {
      units: 'mm/s',
      value: roundTo(parseFloat(ini.perimeter_speed), 1),
    };
    style.externalPerimeterSpeed = {
      units: 'mm/s',
      value: roundTo(
        parseFloatOrPercentMultiplier(
          ini.external_perimeter_speed,
          style.perimeterSpeed.value
        ),
        1
      ),
    };
    style.solidLayerSpeed = roundTo(
      parseFloatOrPercentMultiplier(ini.solid_infill_speed, style.infillSpeed),
      1
    );
    style.firstLayerSpeed = {
      units: 'mm/s',
      value: roundTo(
        parseFloatOrPercentMultiplier(
          ini.first_layer_speed,
          style.solidLayerSpeed
        ),
        1
      ),
    };
    style.supportSpeed = {
      units: 'mm/s',
      value: roundTo(parseFloat(ini.support_material_speed), 1),
    };
    style.supportInterfaceSpeed = {
      units: 'mm/s',
      value: roundTo(
        parseFloatOrPercentMultiplier(
          ini.support_material_interface_speed,
          style.supportSpeed.value
        ),
        1
      ),
    };

    // seam
    if (ini.seam_position === 'random') {
      style.seamJitter = 360;
      style.seamOnCorners = false;
    }
    if (ini.seam_position === 'nearest') {
      style.seamJitter = 360;
    }
    if (ini.seam_position === 'aligned') {
      style.seamJitter = 0;
    }
    if (ini.seam_position === 'rear') {
      style.seamAngle = 45;
    }

    // skirt/brim
    const brimLoops = parseInt(ini.skirts, 10);
    style.brimGap = roundTo(parseFloat(ini.skirt_distance), 2);
    style.brimLayers = Math.max(parseInt(ini.skirt_height, 10), 1);
    style.useBrim = brimLoops > 0;
    style.brimLoops = Math.max(brimLoops, 1);

    // wipe
    {
      const [wipe] = parseFloatList(ini.wipe);
      if (wipe) {
        style.wipeLength = 1;
      }
    }

    // raft and support settings
    {
      style.useRaft = parseInt(ini.raft_layers, 10) > 0;
      style.useSupport = ini.support_material === '1';
      style.supportXYInflation = 0;
      style.supportZGap = {
        units: 'mm',
        value: roundTo(parseFloat(ini.support_material_contact_distance), 2),
      };
      style.raftZGap = style.supportZGap.value;
      style.supportDensity = SupportStyles.FINE;
      if (ini.support_material_xy_spacing) {
        style.supportXYGap = roundTo(
          parseFloatOrPercentMultiplier(
            ini.support_material_xy_spacing,
            style.extrusionWidth
          ),
          2
        );
      } else if (ini.support_material_spacing) {
        style.supportXYGap = roundTo(
          parseFloatOrPercentMultiplier(
            ini.support_material_spacing,
            style.extrusionWidth
          ),
          2
        );
      }
      const supportThresholdAngle =
        90 - parseInt(ini.support_material_threshold, 10);
      if (supportThresholdAngle === 0 || supportThresholdAngle === 90) {
        style.maxOverhangAngle = 45;
      } else {
        style.maxOverhangAngle = supportThresholdAngle;
      }
      style.useSupportInterface = ini.support_material_interface_layers !== '0';
      const supportSpacing = parseFloat(ini.support_material_spacing);
      const supportDensity =
        style.extrusionWidth / (supportSpacing + style.extrusionWidth);
      if (supportDensity <= 0.33) {
        style.supportDensity = SupportStyles.ROUGH;
      } else if (supportDensity <= 0.66) {
        style.supportDensity = SupportStyles.DENSE;
      } else {
        style.supportDensity = SupportStyles.ULTRA;
      }
      const interfaceSpacing = parseFloat(
        ini.support_material_interface_spacing
      );
      style.supportInterfaceDensity = {
        units: '%',
        value: Math.round(
          (style.extrusionWidth / (interfaceSpacing + style.extrusionWidth)) *
            100
        ),
      };
      style.upperRaftSpeed = {
        units: 'mm/s',
        value: roundTo(parseFloat(ini.support_material_speed), 1),
      };
      style.lowerRaftSpeed = {
        units: 'mm/s',
        value: style.upperRaftSpeed.value,
      };
      if (ini.support_material_interface_layers) {
        const interfaceLayers = parseInt(
          ini.support_material_interface_layers,
          10
        );
        if (interfaceLayers) {
          style.supportInterfaceThickness = {
            units: 'layers',
            value: Math.max(2, interfaceLayers),
          };
        }
      }
    }

    return {
      machineSettings,
      styles: [style],
      materials: [],
      warnings,
    };
  }
}

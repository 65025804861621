import 'regenerator-runtime/runtime';
import { call, select } from 'redux-saga/effects';

import { getThreeState, handleGenericError } from '../common';
import { setRenderFlag } from './animationFrame';

export default function* removeBrush(action) {
  try {
    const { scene } = yield select(getThreeState);
    if (!scene) return;
    const { brush, dot } = action.payload;
    scene.remove(brush);
    scene.remove(dot);
    setRenderFlag();
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

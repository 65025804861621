import React, { Component } from 'react';
import _ from 'lodash';
import { withTheme } from 'styled-components';

import {
  DraggableCardGrid,
  DraggableCardList,
  ListViewFolderIcon,
  ListViewContent,
  FolderDescriptionContainer,
  ListViewContainer,
  GridViewContainer,
  GridViewContent,
  GridViewInfoContainer,
  GridViewImageContainer,
} from './folderCard.styles';

import { FolderImage } from '../projectManager.styles';

import {
  Body1OneLine,
  CaptionOneLine,
  DraggableCard,
  Subtitle1OneLine,
} from '../../../shared';

import { FormatUtils, ProjectUtils } from '../../../utils';

class FolderCard extends Component {
  shouldComponentUpdate(nextProps) {
    if (
      !_.isEqual(nextProps.folder, this.props.folder) ||
      !_.isEqual(nextProps.listView, this.props.listView) ||
      !_.isEqual(nextProps.allFolders, this.props.allFolders) ||
      !_.isEqual(nextProps.projectCount, this.props.projectCount) ||
      !_.isEqual(nextProps.selectedProjectIds, this.props.selectedProjectIds)
    )
      return true;
    return false;
  }

  renderGridViewName(folder, projectCount) {
    return (
      <GridViewInfoContainer>
        <Subtitle1OneLine noSpacing>{folder.name}</Subtitle1OneLine>
        <Body1OneLine grey noSpacing>
          {`Contains ${projectCount} ${FormatUtils.pluralize(
            'project',
            projectCount
          )}`}
        </Body1OneLine>
      </GridViewInfoContainer>
    );
  }

  renderGridViewFolderIcon() {
    return (
      <GridViewImageContainer>
        <FolderImage alt='Folder icon' src={this.props.theme.images.folder} />
      </GridViewImageContainer>
    );
  }

  renderGridViewContent(folder, projectCount) {
    return (
      <GridViewContent>
        {this.renderGridViewName(folder, projectCount)}
        {this.renderGridViewFolderIcon()}
      </GridViewContent>
    );
  }

  renderDraggableCardGrid(props) {
    const StyledContainer = DraggableCardGrid;
    return <DraggableCard {...props} StyledContainer={StyledContainer} />;
  }

  renderListViewContent(folder, projectCount) {
    return (
      <ListViewContent>
        <ListViewFolderIcon
          alt='Folder icon'
          src={this.props.theme.images.folder}
        />
        <FolderDescriptionContainer>
          <Body1OneLine noSpacing>{folder.name}</Body1OneLine>
          <CaptionOneLine grey noSpacing>
            {`${projectCount} ${FormatUtils.pluralize(
              'project',
              projectCount
            )}`}
          </CaptionOneLine>
        </FolderDescriptionContainer>
      </ListViewContent>
    );
  }

  renderDraggableCardList(props) {
    const StyledContainer = DraggableCardList;
    return <DraggableCard {...props} StyledContainer={StyledContainer} />;
  }

  renderContent(folder) {
    const { projectCount } = this.props;
    return (
      <>
        {this.props.listView &&
          this.renderListViewContent(folder, projectCount)}
        {!this.props.listView &&
          this.renderGridViewContent(folder, projectCount)}
      </>
    );
  }

  render() {
    const { listView, allFolders, folder, selectedProjectIds } = this.props;
    const folderSelected = _.includes(selectedProjectIds, folder.id);
    const parentIds = ProjectUtils.getAllParents(folder.id, allFolders);
    const canReceive = !_.some(selectedProjectIds, (id) =>
      _.includes(parentIds, id)
    );
    const ProperContainer = listView ? ListViewContainer : GridViewContainer;

    const RenderDraggableCard = listView
      ? this.renderDraggableCardList
      : this.renderDraggableCardGrid;

    const onDoubleClick = () => this.props.onDoubleClick(folder.id);

    return (
      <RenderDraggableCard
        nature='folder'
        id={folder.id}
        canReceive={canReceive}
        title={folder.name}
        listView={listView}
        selected={folderSelected}
        selectedProjectIds={selectedProjectIds}
        onClick={(e) => e.stopPropagation()}
        onDoubleClick={onDoubleClick}
        onMouseDown={(e) => this.props.onMouseDown(e, folder)}
        onMouseUp={(e) => this.props.onMouseUp(e, folder)}
        handleDrop={(draggedIds, receivingId) =>
          this.props.onDrop(draggedIds, receivingId)
        }>
        <ProperContainer>{this.renderContent(folder)}</ProperContainer>
      </RenderDraggableCard>
    );
  }
}

export default withTheme(FolderCard);

import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 2rem;
`;

export const ThumbnailWrapper = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

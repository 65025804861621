import React from 'react';
import AbstractCard from '../abstractCard/abstractCard.jsx';
import { StyledContainer } from './deviceCard.styles';

const DeviceCard = (props) => {
  return (
    <AbstractCard
      {...props}
      StyledLayoutContainer={StyledContainer}
      padding='0'>
      {props.children}
    </AbstractCard>
  );
};

export default DeviceCard;

import styled from 'styled-components';

import { Breakpoints, Shadows } from '../../themes';

export const Container = styled.div`
  z-index: ${(props) => (props.coverHeaderBar ? 999 : 994)};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.colors.overlay};
`;

export const BarAndButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  @media (max-width: ${Breakpoints.smallWidth}) {
    gap: 1rem;
    width: 90vw;
    flex-direction: column;
    align-content: center;
  }
`;

export const CancelButtonWrapper = styled.div`
  @media (max-width: ${Breakpoints.smallWidth}) {
    align-self: center;
  }
`;

export const ProgressOuter = styled.div`
  width: 30rem;
  border-radius: 0.25rem;
  overflow: hidden;
  ${Shadows.d1}

  @media (max-width: ${Breakpoints.smallWidth}) {
    width: 100%;
  }
`;

export const LabelWrapper = styled.div`
  padding-top: 1rem;
`;

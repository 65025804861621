import React, { Component } from 'react';
import { StyledImage } from './image.styles';

class Image extends Component {
  static defaultProps = {
    alt: '',
    src: '',
    invert: false,
    onClick: () => {},
  };

  render() {
    return (
      <StyledImage
        alt={this.props.alt}
        src={this.props.src}
        invert={this.props.invert}
        onClick={(e) => this.props.onClick(e)}
        {...this.props}
      />
    );
  }
}

export default Image;

import React, { Component } from 'react';

import { Container } from './tabButton.styles';
import Button from '../button/button.jsx';

class TabButton extends Component {
  static defaultProps = {
    onClick: () => {},
    height: '2.5rem',
    active: false,
    noBorder: false,
    upperCase: false,
    sidePadding: '0rem', // adds extra padding to the left/right sides
  };

  renderButton() {
    return (
      <Button
        tab
        activeTab={this.props.active}
        height={this.props.height}
        minWidth='0'
        upperCase={this.props.upperCase}
        onClick={(e) => this.props.onClick(e)}>
        {this.props.children}
      </Button>
    );
  }

  render() {
    return (
      <Container
        active={this.props.active}
        noBorder={this.props.noBorder}
        sidePadding={this.props.sidePadding}>
        {this.renderButton()}
      </Container>
    );
  }
}

export default TabButton;

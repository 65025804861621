import React from 'react';

import { hereToHelp } from './common.metadata';
import Routes from '../../../../router/routes';
import { Body1 } from '../../../../shared/typography/typography';

import {
  ImageContainer,
  ControlPaletteImage,
  SendToP3Image,
} from '../taskManager.styles';

const metadata = {
  title: 'Palette 3',
  stepOrder: ['addP3ToSetup', 'sendToSetup', 'hereToHelp'],
  steps: {
    addP3ToSetup: {
      title: 'Find Palette under Devices',
      content: (
        <>
          <Body1>
            Palette has been added to your Devices. You can control it directly
            from Canvas when it is online.
          </Body1>
          <ImageContainer>
            <ControlPaletteImage />
          </ImageContainer>
        </>
      ),
      proceedBody1: 'Go to Devices',
      onProceed: (props) => props.history.push(Routes.toManageDevices()),
    },
    sendToSetup: {
      title: 'Send print files to Palette',
      content: (
        <>
          <Body1>
            After slicing a project, you are able to send the print file
            directly to a Palette 3 that is connected to the Internet and
            Canvas.
          </Body1>
          <ImageContainer>
            <SendToP3Image />
          </ImageContainer>
        </>
      ),
    },
    hereToHelp,
  },
};

export default metadata;

export const CommonColors = [
  {
    name: 'Red',
    hex: '#DC143C',
  },
  {
    name: 'Orange',
    hex: '#FF8C00',
  },
  {
    name: 'Yellow',
    hex: '#FFD700',
  },
  {
    name: 'Green',
    hex: '#006400',
  },
  {
    name: 'Blue',
    hex: '#1565C0',
  },
  {
    name: 'Purple',
    hex: '#4B0082',
  },
  {
    name: 'Pink',
    hex: '#FF69B4',
  },
  {
    name: 'Brown',
    hex: '#8B4513',
  },
  {
    name: 'Grey',
    hex: '#808080',
  },
  {
    name: 'Black',
    hex: '#000000',
  },
  {
    name: 'White',
    hex: '#FFFFFF',
  },
];

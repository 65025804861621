import styled from 'styled-components';
import { Breakpoints } from '../../../../themes';

export const Content = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, min-content);
`;

export const InteractivePreviewContainer = styled.div`
  height: 25rem;
  background: ${(props) => props.theme.colors.grey2};
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-height: ${Breakpoints.mediumHeight}) {
    height: 22.5rem;
  }

  @media (max-height: ${Breakpoints.smallHeight}) {
    height: 15rem;
  }

  @media (max-height: ${Breakpoints.extraSmallHeight}) {
    height: 10rem;
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.3;
  position: absolute;
`;

import React, { Component } from 'react';
import _ from 'lodash';
import { withTheme } from 'styled-components';

import {
  PrinterImage,
  AdvancedButtonsContainer,
  InfoContainer,
} from './printerManager.styles';

import CreatePrinterModal from './configurePrinter/wrappers/createPrinter.container';
import ImportPrinterModal from './configurePrinter/wrappers/importPrinter.container';
import UploadProfile from './uploadProfile/wrappers/uploadPrinter.container';
import ImportSharedPrinterModal from '../import/importPrinter/importPrinter.container';
import NotFound from '../notFound/notFound.jsx';
import { sharedPrinterNotfoundDescription } from '../import/importPrinter/metadata';
import SelectPresetModal from './selectPreset/selectPreset.container';

import { FormatUtils, InterfaceUtils, PrinterUtils } from '../../utils';
import { PrinterSortMode } from '../../utils/sort/sort';

import {
  AdvancedButton,
  Button,
  Body1OneLine,
  CardList,
  ConfirmationModal,
  ManagerView,
  Modal,
  ShareMark,
  SharePrinterModal,
  Subtitle1,
  Subtitle1OneLine,
  ModalHeader,
  ModalBody,
  ModalFooter,
  PrinterCard,
  RenameModal,
} from '../../shared';

import {
  ButtonsContainer,
  ButtonWrapper,
} from '../../shared/managerView/managerView.styles';

import Routes from '../../router/routes';
import { Icons } from '../../themes';

const sortOptions = [
  { label: 'Name', value: PrinterSortMode.NAME },
  { label: 'Date modified', value: PrinterSortMode.DATE_MODIFIED },
  { label: 'Date created', value: PrinterSortMode.DATE_CREATED },
];

class PrinterManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterBy: '',
      printersList: [],
      selectedPrinterIds: [],
      showAddPrinterModal: false,
      showDeletePrinterModal: false,
      showRenamePrinterModal: false,
      showShareModal: false,
      showNewPrinterModal: false,
      showUploadPrinterModal: false,
      showImportPrinterModal: false,
      showImportSharedPrinterModal: false,
      sharedPrinterNotFound: false,
      showSelectPresetModal: false,
    };
    this.onWindowClick = this.onWindowClick.bind(this);
    this.onWindowKeydown = this.onWindowKeydown.bind(this);
  }

  componentDidMount() {
    const { location, history } = this.props;
    this.props.updateNavStack([
      { text: 'My Printers', route: Routes.toManagePrinters() },
    ]);
    window.addEventListener('click', this.onWindowClick);
    window.addEventListener('keydown', this.onWindowKeydown);
    window.addEventListener('mousedown', this.handleMouseDown);
    if (location.state && location.state.hasNoPrinter) {
      this.setState({ showAddPrinterModal: true }, () => {
        history.replace(location.pathname);
      });
    }
    if (this.props.getPrintersSuccess) {
      this.updatePrintersList();
    }
  }

  componentDidUpdate(prevProps) {
    // importing shared printer
    const { history, location } = this.props;
    const path = history.location.pathname;
    const shareId =
      path.includes('/printers/shared/') &&
      path.replace('/printers/shared/', '');
    if (shareId && !this.state.showImportSharedPrinterModal) {
      this.setState({
        showImportSharedPrinterModal: true,
        showShareModal: false,
      });
    } else if (!shareId && this.state.showImportSharedPrinterModal) {
      this.setState({ showImportSharedPrinterModal: false });
    }
    if (!shareId && this.state.sharedPrinterNotFound) {
      // when navigating notfound page is only shown on shared printer link
      this.setState({ sharedPrinterNotFound: false });
    }

    if (
      this.props.printers !== prevProps.printers ||
      !_.isEqual(this.props.sortBy, prevProps.sortBy)
    ) {
      const currentPrinterCount = _.size(this.props.printers);
      const prevPrinterCount = _.size(prevProps.printers);
      // this condition is true once a printer is duplicated
      const selectNew =
        currentPrinterCount > prevPrinterCount && prevPrinterCount > 1;
      this.updatePrintersList(selectNew);
    }

    if (location.state && location.state.hasNoPrinter) {
      this.setState({ showAddPrinterModal: true }, () => {
        history.replace(location.pathname);
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
    window.removeEventListener('keydown', this.onWindowKeydown);
  }

  updatePrintersList(selectNew = false) {
    const sortedAndFilteredPrinters = this.filterPrinters(
      this.props.printers,
      this.state.filterBy
    );
    const newState = {
      printersList: sortedAndFilteredPrinters,
      selectedPrinterIds: [],
    };
    if (selectNew) {
      const oldPrinterIds = _.map(
        this.state.printersList,
        (printer) => printer.id
      );
      _.forEach(sortedAndFilteredPrinters, (printer) => {
        if (!_.includes(oldPrinterIds, printer.id)) {
          newState.selectedPrinterIds.push(printer.id);
        }
      });
    } else {
      _.forEach(this.state.selectedPrinterIds, (id) => {
        if (_.find(sortedAndFilteredPrinters, (printer) => printer.id === id)) {
          newState.selectedPrinterIds.push(id);
        }
      });
    }
    this.setState(newState);
  }

  onWindowKeydown(event) {
    if (event.key === 'Escape') {
      this.deselect();
    } else if (
      event.key === 'Delete' &&
      this.state.selectedPrinterIds.length >= 1
    ) {
      // Hit DELETE while selecting printer(s)
      this.showDeletePrinterModal(event);
    }
  }

  onWindowClick() {
    const {
      showAddPrinterModal,
      showDeletePrinterModal,
      showShareModal,
      showRenamePrinterModal,
    } = this.state;
    if (
      showAddPrinterModal ||
      showDeletePrinterModal ||
      showShareModal ||
      showRenamePrinterModal
    ) {
      return;
    }
    this.deselect();
  }

  deselect() {
    const {
      showAddPrinterModal,
      showDeletePrinterModal,
      showShareModal,
      showRenamePrinterModal,
    } = this.state;
    if (
      showAddPrinterModal ||
      showDeletePrinterModal ||
      showShareModal ||
      showRenamePrinterModal
    ) {
      this.setState({
        showAddPrinterModal: false,
        showDeletePrinterModal: false,
        showShareModal: false,
        showRenamePrinterModal: false,
      });
    } else {
      this.setState({
        selectedPrinterIds: [],
      });
    }
  }

  filterPrinters(printers, filterBy) {
    const filter = filterBy.toLowerCase();
    const filtered = _.filter(printers, (printer) => {
      if (filter !== '') {
        return printer.machineSettings.name.toLowerCase().includes(filter);
      }
      return true;
    });
    return PrinterUtils.sortPrinters(filtered, this.props.sortBy);
  }

  onFilterChange(filterBy) {
    this.setState({ filterBy }, () => {
      this.updatePrintersList();
    });
  }

  onSortChange(sortMode, sortDirection) {
    this.props.updatePreferences(sortMode, sortDirection);
  }

  showShareModal() {
    this.setState({ showShareModal: true });
  }

  hideShareModal() {
    this.setState({ showShareModal: false });
  }

  showAddPrinterModal(e) {
    e.stopPropagation();
    this.setState({
      showAddPrinterModal: true,
    });
  }

  hideAddPrinterModal(e) {
    e.stopPropagation();
    this.setState({
      showAddPrinterModal: false,
    });
  }

  showDeletePrinterModal(e) {
    e.stopPropagation();
    this.setState({
      showDeletePrinterModal: true,
    });
  }

  hideDeletePrinterModal() {
    this.setState({
      showDeletePrinterModal: false,
    });
  }

  selectPrinter(e, printer) {
    e.stopPropagation();
    const { selectedPrinterIds, printersList } = this.state;

    const updatedSelectedItems = InterfaceUtils.handleSelectableItemClick(
      e,
      printersList,
      selectedPrinterIds,
      printer.id
    );
    this.setState({ selectedPrinterIds: updatedSelectedItems });
  }

  handleDuplicatePrinter() {
    const { selectedPrinterIds } = this.state;
    this.props.duplicatePrinter(selectedPrinterIds[0]);
  }

  handleDeletePrinters() {
    const { selectedPrinterIds } = this.state;
    this.props.deletePrinters(selectedPrinterIds);
    this.hideDeletePrinterModal();
  }

  renderShareModal() {
    const { showShareModal } = this.state;
    if (!showShareModal) return null;
    return (
      <SharePrinterModal
        selectedPrinter={this.props.printers[this.state.selectedPrinterIds[0]]}
        onMarginClick={() => this.hideShareModal()}
      />
    );
  }

  showImportPrinterModal() {
    this.setState({
      showImportPrinterModal: true,
      showUploadPrinterModal: false,
    });
  }

  hideImportPrinterModal() {
    this.setState({
      showImportPrinterModal: false,
    });
  }

  renderImportPrinterModal() {
    const { showImportPrinterModal } = this.state;
    if (!showImportPrinterModal) return null;
    return (
      <ImportPrinterModal
        onClickClose={() => this.hideImportPrinterModal()}
        onClickSave={() => this.hideImportPrinterModal()}
      />
    );
  }

  renderSharedPrinterNotFound() {
    return (
      <NotFound
        history={this.props.history}
        extraDescriptionText={sharedPrinterNotfoundDescription}
      />
    );
  }

  renderImportSharedPrinterModal() {
    const { showImportSharedPrinterModal } = this.state;
    if (!showImportSharedPrinterModal) return null;
    return (
      <ImportSharedPrinterModal
        updateNavStack={this.props.updateNavStack}
        toggleImportSharedPrinterModal={() => {
          this.setState({ showImportSharedPrinterModal: false });
          this.props.history.push(Routes.toManagePrinters());
        }}
        setSharedPrinterNotFound={() => {
          this.setState({
            sharedPrinterNotFound: true,
          });
        }}
      />
    );
  }

  showUploadPrinterModal() {
    this.setState({
      showUploadPrinterModal: true,
      showAddPrinterModal: false,
    });
  }

  hideUploadPrinterModal() {
    this.setState({
      showUploadPrinterModal: false,
    });
  }

  renderUploadPrinterModal() {
    const { showUploadPrinterModal } = this.state;
    if (!showUploadPrinterModal) return null;
    return (
      <Modal width='30rem'>
        <UploadProfile
          onUpload={() => this.showImportPrinterModal()}
          onClickClose={() => this.hideUploadPrinterModal()}
        />
      </Modal>
    );
  }

  renderNewPrinterModal() {
    const { showNewPrinterModal } = this.state;
    if (!showNewPrinterModal) return null;
    return (
      <CreatePrinterModal
        onClickClose={() => this.hideNewPrinterModal()}
        onClickSave={() => this.hideNewPrinterModal()}
      />
    );
  }

  showNewPrinterModal() {
    this.setState({
      showNewPrinterModal: true,
      showAddPrinterModal: false,
    });
  }

  hideNewPrinterModal() {
    this.setState({
      showNewPrinterModal: false,
    });
  }

  renderAddPrinterHeader() {
    return (
      <ModalHeader>
        <Subtitle1>Create new printer profile</Subtitle1>
      </ModalHeader>
    );
  }

  renderAddPrinterOptions() {
    return (
      <ModalBody>
        <AdvancedButtonsContainer>
          <AdvancedButton
            icon={Icons.fdm.extruding}
            label='Preset'
            description='Tested printer profile presets'
            onClick={() =>
              this.setState({
                showSelectPresetModal: true,
                showAddPrinterModal: false,
              })
            }
          />
          <AdvancedButton
            icon={Icons.basic.importIcon}
            label='Import'
            description='From Simplify3D, Slic3r/PrusaSlicer or KISSlicer'
            onClick={(e) => this.showUploadPrinterModal(e)}
          />
          <AdvancedButton
            icon={Icons.basic.plus}
            label='Blank'
            description='Start from a blank profile'
            onClick={(e) => this.showNewPrinterModal(e)}
          />
        </AdvancedButtonsContainer>
      </ModalBody>
    );
  }

  renderCloseButton() {
    return (
      <ModalFooter>
        <Button minWidth='4.625em' onClick={(e) => this.hideAddPrinterModal(e)}>
          Close
        </Button>
      </ModalFooter>
    );
  }

  renderShowSelectPresetModal() {
    const { showSelectPresetModal } = this.state;
    if (!showSelectPresetModal) return null;
    return (
      <SelectPresetModal
        onCloseSelectPresetModal={() =>
          this.setState({ showSelectPresetModal: false })
        }
      />
    );
  }

  renderAddPrinterModal() {
    const { showAddPrinterModal } = this.state;
    if (!showAddPrinterModal) return null;
    return (
      <Modal onMarginClick={(e) => this.hideAddPrinterModal(e)}>
        {this.renderAddPrinterHeader()}
        {this.renderAddPrinterOptions()}
        {this.renderCloseButton()}
      </Modal>
    );
  }

  renderDeletePrinterModal() {
    const { showDeletePrinterModal, selectedPrinterIds } = this.state;
    if (!showDeletePrinterModal) return null;
    const firstSelectedPrinter = this.props.printers[selectedPrinterIds[0]];

    let primaryLabel = `Delete ${firstSelectedPrinter.machineSettings.name}`;
    let secondaryLabel =
      'Are you sure you would like to remove this printer profile from your account?';

    let warningLabel =
      firstSelectedPrinter.shareData && firstSelectedPrinter.shareData.shareId
        ? 'This printer is currently being shared. Deleting this printer will also delete the shared copy'
        : '';

    if (selectedPrinterIds.length > 1) {
      primaryLabel = `Delete ${selectedPrinterIds.length} printer profiles`;
      secondaryLabel =
        'Are you sure you would like to remove these printer profiles from your account?';

      const sharedPrintersCount = _.reduce(
        selectedPrinterIds,
        (count, printerId) => {
          const printer = this.props.printers[printerId];
          if (printer.shareData && printer.shareData.shareId) return count + 1;
          return count;
        },
        0
      );
      if (sharedPrintersCount > 0) {
        const wording =
          sharedPrintersCount > 1
            ? ['are', 'these printers', 'copies']
            : ['is', 'this printer', 'copy'];
        warningLabel = `${sharedPrintersCount} of these printers ${wording[0]} currently being shared.
          Deleting ${wording[1]} will also delete the shared ${wording[2]}`;
      }
    }

    return (
      <ConfirmationModal
        isWarning
        primaryLabel={primaryLabel}
        secondaryLabel={secondaryLabel}
        tertiaryLabel={warningLabel}
        onClickCancel={() => this.hideDeletePrinterModal()}
        onClickConfirm={() => this.handleDeletePrinters()}
      />
    );
  }

  showRenameModal() {
    this.setState({ showRenamePrinterModal: true });
  }

  hideRenameModal() {
    this.setState({ showRenamePrinterModal: false });
  }

  handleRenamePrinter(renameValue) {
    const [printerId] = this.state.selectedPrinterIds;
    const printer = this.props.printers[printerId];
    const updatedPrinterData = {
      ...printer,
      machineSettings: {
        ...printer.machineSettings,
        name: renameValue,
      },
    };
    if (renameValue.trim() !== printer.name) {
      this.props.updatePrinter(printerId, updatedPrinterData);
    }
    this.hideRenameModal();
  }

  renderRenameModal() {
    const { showRenamePrinterModal, selectedPrinterIds, printersList } =
      this.state;
    if (!showRenamePrinterModal) return null;
    const [printerId] = selectedPrinterIds;
    const selectedPrinter = _.find(
      printersList,
      (printer) => printer.id === printerId
    );
    if (!selectedPrinter) return null;

    const printerName = selectedPrinter.machineSettings.name;
    return (
      <RenameModal
        initialValue={printerName}
        title={'Rename Printer'}
        onMarginClick={() => this.hideRenameModal()}
        onCancel={() => this.hideRenameModal()}
        onSave={(renameValue) => this.handleRenamePrinter(renameValue)}
      />
    );
  }

  getActionButtonsRightMeta() {
    const { selectedPrinterIds } = this.state;
    return [
      {
        icon: Icons.basic.share,
        title: FormatUtils.pluralize(
          'Share printer profile',
          selectedPrinterIds.length,
          true
        ),
        onClick: () => this.showShareModal(),
        disabled: selectedPrinterIds.length !== 1,
      },
      {
        icon: Icons.basic.pencil,
        title: FormatUtils.pluralize(
          'Rename printer profile',
          selectedPrinterIds.length,
          true
        ),
        onClick: () => this.showRenameModal(),
        disabled: _.isEmpty(selectedPrinterIds),
      },
      {
        icon: Icons.basic.copy,
        title: FormatUtils.pluralize(
          'Duplicate printer profile',
          selectedPrinterIds.length,
          true
        ),
        onClick: () => this.handleDuplicatePrinter(),
        disabled: selectedPrinterIds.length !== 1,
      },
      {
        icon: Icons.basic.trash,
        title: FormatUtils.pluralize(
          'Delete printer profile',
          selectedPrinterIds.length,
          true
        ),
        onClick: (e) => this.showDeletePrinterModal(e),
        disabled: _.isEmpty(selectedPrinterIds),
      },
    ];
  }

  renderPrinterCardShareMark(currentlyShared, printerSelected, printer) {
    if (!currentlyShared) return null;
    const { shareData, machineSettings, styles } = printer;
    const { sharedStyleIds, dateShared } = shareData;

    const printerUpdated = machineSettings.timestamp > dateShared;

    const sharedStyles = _.filter(styles, (style) =>
      sharedStyleIds.includes(style.id)
    );
    const stylesUpdated = _.some(
      sharedStyles,
      (style) => style.timestamp > dateShared
    );

    const styleIds = _.map(styles, (style) => style.id);
    const stylesDeleted = _.some(
      sharedStyleIds,
      (sharedStyleId) => !styleIds.includes(sharedStyleId)
    );

    const updated = printerUpdated || stylesUpdated || stylesDeleted;

    return <ShareMark updated={updated} selected={printerSelected} />;
  }

  renderPrinterName(name) {
    return <Subtitle1OneLine noSpacing>{name}</Subtitle1OneLine>;
  }

  renderPrinterStyles(styles) {
    const stylesMeta = `${styles.length} style ${FormatUtils.pluralize(
      'profile',
      styles.length
    )} `;
    return (
      <Body1OneLine grey noSpacing>
        {stylesMeta}
      </Body1OneLine>
    );
  }

  renderPrinterCardInfo(machineSettings, styles) {
    return (
      <InfoContainer>
        {this.renderPrinterName(machineSettings.name)}
        {this.renderPrinterStyles(styles)}
      </InfoContainer>
    );
  }

  renderPrinterCardImage(icon) {
    return (
      <PrinterImage
        alt='Printer icon'
        src={this.props.theme.images.printerIcons[icon]}
      />
    );
  }

  renderPrinterCard(printer) {
    const { machineSettings, styles, shareData } = printer;
    const { selectedPrinterIds } = this.state;
    const printerSelected = _.includes(selectedPrinterIds, printer.id);
    const currentlyShared = shareData && shareData.shareId;
    const onDoubleClick = () =>
      this.props.history.push(Routes.toViewPrinter(printer.id));
    return (
      <PrinterCard
        key={printer.id}
        title={machineSettings.name}
        selected={!!printerSelected}
        onClick={(e) => this.selectPrinter(e, printer)}
        onDoubleClick={onDoubleClick}>
        {this.renderPrinterCardShareMark(
          currentlyShared,
          printerSelected,
          printer
        )}
        {this.renderPrinterCardInfo(machineSettings, styles)}
        {this.renderPrinterCardImage(machineSettings.icon)}
      </PrinterCard>
    );
  }

  renderPrinters() {
    const printers = _.map(this.state.printersList, (printer) =>
      this.renderPrinterCard(printer)
    );
    return <CardList>{printers}</CardList>;
  }

  getLoadingOverlayText() {
    if (this.props.duplicatePrinterPending) return 'Duplicating printer';
    if (this.props.deletePrintersPending) {
      const { selectedPrinterIds } = this.state;
      return FormatUtils.pluralize(
        'Deleting printer',
        selectedPrinterIds.length
      );
    }
    return null;
  }

  areActionsDisabled() {
    const { getPrintersSuccess, printers } = this.props;
    return !getPrintersSuccess || _.isEmpty(printers);
  }

  renderRightPanelContent() {
    return (
      <ButtonsContainer>
        <ButtonWrapper>
          <Button
            primary
            expand
            icon={Icons.basic.plus}
            onClick={(e) => this.showAddPrinterModal(e)}>
            New printer
          </Button>
        </ButtonWrapper>
      </ButtonsContainer>
    );
  }

  render() {
    const { printers, getPrintersPending, getPrintersSuccess, sortBy } =
      this.props;

    // if user was already using PALETTE_TYPE as the sort mode,
    //   silently change it back to the default
    const currentSortMode =
      sortBy.mode === PrinterSortMode.PALETTE_TYPE
        ? PrinterSortMode.NAME
        : sortBy.mode;

    if (this.state.sharedPrinterNotFound) {
      return this.renderSharedPrinterNotFound();
    }

    return (
      <>
        <ManagerView
          name='Printers'
          actionsDisabled={this.areActionsDisabled()}
          menuButtons={this.renderRightPanelContent()}
          loadingOverlayText={this.getLoadingOverlayText()}
          loadingContent={getPrintersPending}
          loadingError={!getPrintersPending && !getPrintersSuccess}
          noContent={_.isEmpty(printers)}
          filterBarValue={this.state.filterBy}
          onFilterChange={(value) => this.onFilterChange(value)}
          sortOptions={sortOptions}
          sortMode={currentSortMode}
          sortDirection={sortBy.direction}
          onSortChange={(mode, direction) => this.onSortChange(mode, direction)}
          actionButtons={this.getActionButtonsRightMeta()}>
          {this.renderPrinters()}
        </ManagerView>
        {this.renderImportSharedPrinterModal()}
        {this.renderAddPrinterModal()}
        {this.renderDeletePrinterModal()}
        {this.renderShareModal()}
        {this.renderNewPrinterModal()}
        {this.renderUploadPrinterModal()}
        {this.renderImportPrinterModal()}
        {this.renderRenameModal()}
        {this.renderShowSelectPresetModal()}
      </>
    );
  }
}

export default withTheme(PrinterManager);

import styled, { css } from 'styled-components';

export const StyledCharacterInput = styled.div`
  border-bottom: 4px solid ${(props) => props.theme.colors.grey3};
  margin: 0 0.5rem;
  transition: border-color 0.2s;

  ${(props) =>
    props.active &&
    css`
      border-color: ${props.theme.colors.greenDefault};
    `}
`;

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 3rem;
  min-height: 3rem;
  margin: 0;
  padding: 0.5rem 0;
  border: none;
  border-radius: 0.2rem;
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.elevatedBackgroundTertiary
      : props.theme.colors.elevatedBackgroundPrimary};
  ${(props) => props.disabled && 'border-color: transparent;'}
  transition: all 0.2s;
  transition-property: border-color, background-color, color;
`;

export const StyledInput = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  border: none;
  box-sizing: border-box;
  color: ${(props) => {
    if (props.isAuto) return 'transparent';
    if (props.disabled) return props.theme.colors.textTertiary;
    return props.theme.colors.textPrimary;
  }};
  cursor: ${(props) => (props.disabled ? 'default' : 'text')};
  user-select: ${(props) => (props.disabled ? 'none' : 'text')};
  background-color: transparent;
  font-size: 2.5rem;
  font-family: ${(props) => props.theme.fonts.familyMono};
  font-weight: ${(props) => props.theme.fonts.weightBold};
  text-align: center;
  line-height: 5rem;
  outline: none;

  /* By default, Firefox number inputs come with buttons, this removes them: */
  -moz-appearance: textfield;

  /* Change the chrome autofill color to white */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: ${(props) => props.theme.colors.grey5};
    box-shadow: 0 0 0 2rem white inset;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

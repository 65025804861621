import React from 'react';

import { ConfirmationModal } from '../../../shared';

const DeleteDeviceModal = ({ selectedDevices, onCancel, onConfirm }) => {
  let primaryLabel = '';
  let secondaryLabel = '';
  let tertiaryLabel = '';

  if (selectedDevices.length === 1) {
    primaryLabel = `Unlink ${selectedDevices[0].name}?`;
    secondaryLabel =
      'Are you sure you would like to unlink this device from your account?';
    tertiaryLabel =
      'You will need to get a new activation code from your device to add it back.';
  } else {
    primaryLabel = `Unlink ${selectedDevices.length} devices?`;
    secondaryLabel =
      'Are you sure you would like to unlink these devices from your account?';
    tertiaryLabel =
      'You will need to get a new activation code from your devices to add them back.';
  }

  return (
    <ConfirmationModal
      isWarning
      confirmLabel='Unlink'
      primaryLabel={primaryLabel}
      secondaryLabel={secondaryLabel}
      tertiaryLabel={tertiaryLabel}
      onClickCancel={onCancel}
      onClickConfirm={onConfirm}
    />
  );
};

export default DeleteDeviceModal;

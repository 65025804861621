import React, { Component } from 'react';
import * as THREE from 'three';
import _ from 'lodash';
import PaletteGenerator from 'image-palette';
import PixelsGenerator from 'image-pixels';

import { StampingContext } from './stamping.context';

import { Container } from './stampPrompt.styles';

import StampPlacer from './placer/placer.jsx';
import StampUploader from './uploader/uploader.jsx';
import StampColorPicker from './colorPicker/colorPicker.jsx';

import { ConfirmationModal } from '../../../../../shared';

import {
  InterfaceUtils,
  SceneUtils,
  SlicerUtils,
  StampUtils,
} from '../../../../../utils';

import { SHADER_CHUNKS } from '../../../../../utils/faces/shaders';
import { setRenderFlag } from '../../../../../sagas/three/animationFrame';

class StampPrompt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 'upload', // upload, color, place
      isOrientingModel: false,
      quantized: false,
      posterizedImage: null,
      posterizedPalette: [],
      customColorMap: {},
      stampRotationDeg: 0,
    };

    this.stampImageRef = React.createRef();

    this.toggleModelOrientationMode =
      this.toggleModelOrientationMode.bind(this);
    this.setStampRotation = this.setStampRotation.bind(this);
    this.onUploadImage = this.onUploadImage.bind(this);
    this.onPlaceStamp = this.onPlaceStamp.bind(this);
    this.onConfirmColors = this.onConfirmColors.bind(this);
    this.onSelectColorMap = this.onSelectColorMap.bind(this);
    this.onClickProjectColor = this.onClickProjectColor.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    this.setupStampingScene();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentStep !== this.state.currentStep) {
      this.props.updatePaintView(this.state.currentStep);
    }
  }

  toggleModelOrientationMode(isOrientingModel) {
    this.setState({ isOrientingModel });
  }

  setupStampingScene() {
    const { currentModel } = this.props;

    // setup depth scene
    this.depthScene = new THREE.Scene();

    // setup depth mesh
    const depthMaterial = new THREE.ShaderMaterial({
      vertexShader: SHADER_CHUNKS.depthVertexShader,
      fragmentShader: SHADER_CHUNKS.depthFragmentShader,
      side: THREE.DoubleSide,
    });

    // add depth mesh to depth scene
    const depthMesh = new THREE.Mesh(currentModel.mesh.geometry, depthMaterial);

    // apply current transformations
    depthMesh.applyMatrix(currentModel.mesh.matrix);
    this.depthScene.add(depthMesh);

    // setup canvases for previewing images
    this.posterizedCanvas = document.createElement('canvas');
    this.quantizedCanvas = document.createElement('canvas');
  }

  setStampRotation(degree) {
    this.setState({ stampRotationDeg: degree });
  }

  getImageBounds() {
    const { canvas } = this.props;
    const boundingRect = this.stampImageRef.current.getBoundingClientRect();

    return {
      top: Math.max(0, Math.floor(boundingRect.top)) * window.devicePixelRatio,
      bottom:
        Math.min(canvas.height, Math.ceil(boundingRect.bottom)) *
        window.devicePixelRatio,
      left:
        Math.max(0, Math.floor(boundingRect.left)) * window.devicePixelRatio,
      right:
        Math.min(canvas.width, Math.ceil(boundingRect.right)) *
        window.devicePixelRatio,
    };
  }

  getPosterizedPaletteAsUint32Map() {
    const { posterizedPalette } = this.state;
    const colorPaletteUint32Map = new Map();

    posterizedPalette.forEach((posterizedColor) => {
      const [r, g, b] = posterizedColor;
      // eslint-disable-next-line no-bitwise
      const uint32 = ((255 << 24) | (b << 16) | (g << 8) | r) >>> 0;
      colorPaletteUint32Map.set(uint32, colorPaletteUint32Map.size);
    });

    // set -1 for empty color
    colorPaletteUint32Map.set(0, -1);

    return colorPaletteUint32Map;
  }

  getStampTextureAndRLE() {
    const { posterizedImage, customColorMap } = this.state;

    const imageDimensions = {
      width: this.quantizedCanvas.width,
      height: this.quantizedCanvas.height,
    };

    const posterizedPaletteUint32Map = this.getPosterizedPaletteAsUint32Map();

    const stampRLE = StampUtils.getStampRLE(
      posterizedImage,
      posterizedPaletteUint32Map,
      customColorMap
    );
    const stampTextureBuffer = StampUtils.buildStampImageFromRLE(
      stampRLE,
      imageDimensions
    );

    const stampTexture = new THREE.DataTexture(
      new Uint8Array(stampTextureBuffer),
      imageDimensions.width,
      imageDimensions.height,
      THREE.RGBAFormat
    );
    stampTexture.flipY = true;
    stampTexture.needsUpdate = true;

    return { stampTexture, stampRLE };
  }

  onPlaceStamp() {
    const { camera, currentModel, canvas, renderer, viewOptions } = this.props;

    // clip depth texture to fit stamp image and keep it within screen bounds
    // it is important that the depth bounds are computed before un-rotating the image
    const depthBounds = this.getImageBounds();
    const depthWidth = depthBounds.right - depthBounds.left;
    const depthHeight = depthBounds.bottom - depthBounds.top;

    // un-rotate to get correct bounding box
    // TODO: figure out how to do this with math
    this.stampImageRef.current.style.transform = 'none';

    const stampBounds = this.getImageBounds();
    const stampWidth = stampBounds.right - stampBounds.left;
    const stampHeight = stampBounds.bottom - stampBounds.top;

    // setup render target for occlusion
    const depthRenderTarget = new THREE.WebGLRenderTarget(
      canvas.width,
      canvas.height,
      {
        magFilter: THREE.NearestFilter,
        minFilter: THREE.NearestFilter,
        format: THREE.RGBAFormat,
        depthBuffer: true,
      }
    );

    // get model bounding box
    const bbox = SlicerUtils.getBoundingBox(currentModel.mesh);

    // create stamp camera
    const stampCamera = SceneUtils.createCamera(viewOptions.orthographic);
    stampCamera.copy(camera, false);

    // Change the camera near and far for better accuracy
    const previousNear = stampCamera.near;
    const previousFar = stampCamera.far;

    const cameraPosition = new THREE.Vector3();
    stampCamera.getWorldPosition(cameraPosition);

    // Need some margin on the near and far clipping plane
    stampCamera.near = bbox.distanceToPoint(cameraPosition) * 0.95;
    const bBoxDepthVec = bbox.max.clone();
    bBoxDepthVec.sub(bbox.min);
    stampCamera.far = stampCamera.near + bBoxDepthVec.length() * 1.05;

    const cameraViewMatrix = stampCamera.matrixWorldInverse.clone();

    stampCamera.needsUpdate = true;
    stampCamera.updateProjectionMatrix();

    const cameraProjectionMatrix = stampCamera.projectionMatrix.clone();

    const cameraDirection = new THREE.Vector3();
    stampCamera.getWorldDirection(cameraDirection);

    // render to depth texture
    renderer.autoClear = false;
    renderer.setRenderTarget(depthRenderTarget);
    renderer.render(this.depthScene, stampCamera);
    renderer.setRenderTarget(null);
    renderer.render(this.depthScene, stampCamera);
    renderer.autoClear = true;

    // Set the camera back to the way it was
    stampCamera.near = previousNear;
    stampCamera.far = previousFar;
    stampCamera.needsUpdate = true;
    stampCamera.updateProjectionMatrix();

    // get clipped depth texture as buffer
    // reference: https://developer.mozilla.org/en-US/docs/Web/API/WebGLRenderingContext/readPixels
    const depthBuffer = new Uint8Array(depthWidth * depthHeight * 4);
    renderer.readRenderTargetPixels(
      depthRenderTarget,
      depthBounds.left,
      canvas.height - depthBounds.bottom,
      depthWidth,
      depthHeight,
      depthBuffer
    );

    const depthTexture = new THREE.DataTexture(
      depthBuffer,
      depthWidth,
      depthHeight,
      THREE.RGBAFormat
    );
    depthTexture.needsUpdate = true;

    // scale and position the stamp texture
    // provide screen coords of stamp in uv space
    const stampBottomLeft = new THREE.Vector2(
      stampBounds.left / canvas.width,
      1 - stampBounds.bottom / canvas.height
    );
    const stampTopRight = new THREE.Vector2(
      stampBounds.right / canvas.width,
      1 - stampBounds.top / canvas.height
    );

    const depthBottomLeft = new THREE.Vector2(
      depthBounds.left / canvas.width,
      1 - depthBounds.bottom / canvas.height
    );
    const depthTopRight = new THREE.Vector2(
      depthBounds.right / canvas.width,
      1 - depthBounds.top / canvas.height
    );

    const extruderColorsUniform =
      currentModel.mesh.material.uniforms.extruderColors;
    const shaderDefines = currentModel.mesh.material.defines;

    const boundedRotation = SlicerUtils.clampDegreesToCircle(
      this.state.stampRotationDeg
    );
    const stampRotation = SlicerUtils.degreesToRadians(boundedRotation);

    const mergedUniforms = THREE.UniformsUtils.merge([
      THREE.UniformsLib['lights'],
      {
        stampBottomLeft: { value: stampBottomLeft },
        stampTopRight: { value: stampTopRight },
        stampWidth: { value: stampWidth },
        stampHeight: { value: stampHeight },
        stampRotation: { value: stampRotation },
        depthBottomLeft: { value: depthBottomLeft },
        depthTopRight: { value: depthTopRight },
        depthWidth: { value: depthWidth },
        depthHeight: { value: depthHeight },
        cameraViewMatrix: { type: 'm4', value: cameraViewMatrix },
        cameraProjectionMatrix: { type: 'm4', value: cameraProjectionMatrix },
        cameraDirection: { value: cameraDirection },
        renderOrder: { value: currentModel.mesh.children.length + 1 },
        highlighted: { value: false },
        translucent: { value: false },
        extruderColors: extruderColorsUniform,
      },
    ]);

    const { stampTexture, stampRLE } = this.getStampTextureAndRLE();

    // Important to define the textures outside the THREE.UniformsUtils.merge call
    // (as that call clones the texture)
    mergedUniforms.stampTexture = { type: 't', value: stampTexture };
    mergedUniforms.depthTexture = { type: 't', value: depthTexture };

    // create stamp material
    const stampMaterial = SlicerUtils.getStampMaterial(
      mergedUniforms,
      shaderDefines
    );

    // store other meta data
    stampMaterial.userData.stampRLE = stampRLE;
    stampMaterial.userData.cameraOrthographic = viewOptions.orthographic;
    stampMaterial.userData.stampBounds = stampBounds;
    stampMaterial.userData.depthBounds = depthBounds;
    stampMaterial.userData.imageDimensions = {
      width: this.quantizedCanvas.width,
      height: this.quantizedCanvas.height,
    };
    stampMaterial.userData.canvasDimensions = {
      width: canvas.width,
      height: canvas.height,
    };

    const stampMesh = new THREE.Mesh(currentModel.mesh.geometry, stampMaterial);
    stampMesh.applyMatrix(currentModel.mesh.matrix);

    const stampGroup = new THREE.Group();
    stampGroup.matrixAutoUpdate = false;

    stampGroup.attach(stampMesh);
    stampGroup.attach(stampCamera);
    currentModel.mesh.attach(stampGroup);

    depthRenderTarget.dispose();
    this.quantizeRenderTarget.dispose();

    this.handleReset();
  }

  handleReset() {
    this.setState({
      currentStep: 'upload',
      posterizedImage: null,
      posterizedPalette: [],
      customColorMap: {},
      stampRotationDeg: 0,
    });
    setRenderFlag();
    this.props.onReset();
  }

  async onUploadImage(e) {
    const { project, renderer } = this.props;

    // maximum dimension of image allowed to be stored on GPU
    // this number varies with the user's current device
    // e.g., 1024 x 1024 (maxTextureSize x maxTextureSize)
    const { maxTextureSize } = renderer.capabilities;

    // generate image data
    let imgData;
    try {
      imgData = await PixelsGenerator(e.target.files[0]);
    } catch (err) {
      const msg =
        'Canvas could not process the image. Please try uploading a different image.';
      InterfaceUtils.emitToast('error', msg);
      return;
    }

    if (imgData.width > maxTextureSize || imgData.height > maxTextureSize) {
      const msg =
        'This image is too big for Canvas to process. Please try uploading a smaller image.';
      InterfaceUtils.emitToast('error', msg);
      return;
    }

    if (imgData.width > 2048 || imgData.height > 2048) {
      const msg =
        'This image may lead to heavy GPU memory usage. Please consider uploading a smaller image.';
      InterfaceUtils.emitToast('warn', msg);
    }

    // posterize image
    const posterized = StampUtils.posterize(imgData);

    // set dimensions for canvas to hold posterized image
    this.posterizedCanvas.width = posterized.width;
    this.posterizedCanvas.height = posterized.height;

    // draw posterized image on this canvas
    const posterizedCtx = this.posterizedCanvas.getContext('2d');
    const posterizedImageData = posterizedCtx.getImageData(
      0,
      0,
      this.posterizedCanvas.width,
      this.posterizedCanvas.height
    );
    posterizedImageData.data.set(posterized.data);
    posterizedCtx.putImageData(posterizedImageData, 0, 0);

    // set dimensions for canvas to hold quantized image
    this.quantizedCanvas.width = posterized.width;
    this.quantizedCanvas.height = posterized.height;

    // generate color palette from posterized image
    const { colors: colorPalette } = await PaletteGenerator(posterized, 10);

    // create color map by finding best matching extruder color
    const customColorMap = {};
    let r;
    let g;
    let b;
    let closestColorIndex;
    const currentColorVec = new THREE.Vector3();
    const projectColorVec = new THREE.Vector3();
    const currentDistanceVec = new THREE.Vector3();

    colorPalette.forEach((posterizedColor, posterizedColorIndex) => {
      [r, g, b] = posterizedColor;

      currentColorVec.set(r / 255, g / 255, b / 255);
      let closestDistance = Number.POSITIVE_INFINITY;

      project.colors.forEach((projectColor, projectColorIndex) => {
        [r, g, b] = projectColor;
        projectColorVec.set(r / 255, g / 255, b / 255);
        currentDistanceVec.subVectors(currentColorVec, projectColorVec);
        if (currentDistanceVec.length() < closestDistance) {
          closestColorIndex = projectColorIndex;
          closestDistance = currentDistanceVec.length();
        }
      });

      customColorMap[posterizedColorIndex] = closestColorIndex;
    });

    this.setState(
      {
        currentStep: 'color',
        posterizedImage: posterized,
        posterizedPalette: colorPalette,
        customColorMap,
      },
      () => {
        this.setupForQuantize();
      }
    );
  }

  onConfirmColors() {
    this.setState({ currentStep: 'place' });
  }

  onSelectColorMap(color, index) {
    const { project } = this.props;
    const extruderIndex = _.findIndex(
      project.colors,
      (extruderColor) => extruderColor === color
    );

    this.setState(
      {
        customColorMap: {
          ...this.state.customColorMap,
          [index]: extruderIndex,
        },
      },
      () => this.quantize()
    );
  }

  onClickProjectColor(index) {
    const { customColorMap } = this.state;

    const newColorMap = {};
    _.forEach(customColorMap, (val, key) => {
      newColorMap[key] = index;
    });

    this.setState(
      {
        customColorMap: newColorMap,
      },
      () => this.quantize()
    );
  }

  getPosterizedColorsUniform() {
    return _.map(this.state.posterizedPalette, (posterizedColor) => {
      const [r, g, b] = posterizedColor;
      return { color: new THREE.Vector3(r / 255, g / 255, b / 255) };
    });
  }

  getPosterizedTexture() {
    const { posterizedImage } = this.state;

    const posterizedTexture = new THREE.DataTexture(
      new Uint8Array(posterizedImage.data.buffer),
      posterizedImage.width,
      posterizedImage.height,
      THREE.RGBAFormat
    );
    posterizedTexture.needsUpdate = true;

    return posterizedTexture;
  }

  setupForQuantize() {
    const { currentModel } = this.props;
    const { customColorMap } = this.state;

    const extruderColorsUniform =
      currentModel.mesh.material.uniforms.extruderColors;
    const shaderDefines = currentModel.mesh.material.defines;

    const posterizedColorsUniform = this.getPosterizedColorsUniform();
    const posterizedTexture = this.getPosterizedTexture();

    // setup render target for quantizing
    this.quantizeRenderTarget = new THREE.WebGLRenderTarget(
      this.quantizedCanvas.width,
      this.quantizedCanvas.height,
      {
        magFilter: THREE.NearestFilter,
        minFilter: THREE.NearestFilter,
        format: THREE.RGBAFormat,
      }
    );

    // uint8 array to read out quantized pixels to
    this.quantizedImageUint8Array = new Uint8Array(
      this.quantizedCanvas.width * this.quantizedCanvas.height * 4
    );

    // setup scene and geometry for quantizing
    this.quadScene = new THREE.Scene();
    this.quadCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
    const quadPlane = new THREE.PlaneBufferGeometry(2, 2);

    const uniforms = {
      colorMap: { type: 'iv1', value: _.values(customColorMap) },
      posterizedImg: { type: 't', value: posterizedTexture },
      extruderColors: extruderColorsUniform,
      posterizedColors: { value: posterizedColorsUniform },
    };

    const defines = {
      ...shaderDefines,
      NUM_POSTERIZED_COLORS: posterizedColorsUniform.length,
    };

    const quantizeMaterial = new THREE.ShaderMaterial({
      vertexShader: SHADER_CHUNKS.quantizeVertexShader,
      fragmentShader: SHADER_CHUNKS.quantizeFragmentShader,
      side: THREE.DoubleSide,
      uniforms,
      defines,
    });

    this.quantizeMesh = new THREE.Mesh(quadPlane, quantizeMaterial);
    this.quadScene.add(this.quantizeMesh);

    // finished setup; run first quantize pass
    this.setState(
      {
        quantized: false,
      },
      () => this.quantize()
    );
  }

  quantize() {
    const { renderer } = this.props;
    const { customColorMap } = this.state;

    // update color map uniform
    this.quantizeMesh.material.uniforms.colorMap.value =
      _.values(customColorMap);

    // run quantize pass
    renderer.autoClear = false;
    renderer.setRenderTarget(this.quantizeRenderTarget);
    renderer.render(this.quadScene, this.quadCamera);
    renderer.setRenderTarget(null);
    renderer.render(this.quadScene, this.quadCamera);
    renderer.autoClear = true;

    // draw output to quantize canvas
    const quantizedCtx = this.quantizedCanvas.getContext('2d');
    const quantizedImageData = quantizedCtx.getImageData(
      0,
      0,
      this.quantizedCanvas.width,
      this.quantizedCanvas.height
    );

    renderer.readRenderTargetPixels(
      this.quantizeRenderTarget,
      0,
      0,
      this.quantizedCanvas.width,
      this.quantizedCanvas.height,
      this.quantizedImageUint8Array
    );

    // update content of quantized canvas
    quantizedImageData.data.set(this.quantizedImageUint8Array);
    quantizedCtx.putImageData(quantizedImageData, 0, 0);

    this.setState(
      {
        quantized: true,
      },
      () => setRenderFlag()
    );
  }

  renderStampUploader() {
    if (!this.props.showStampPrompt) return null;
    if (this.state.currentStep !== 'upload') return null;

    return (
      <StampUploader
        onUploadImage={this.onUploadImage}
        onCancel={this.handleReset}
      />
    );
  }

  renderStampColorPicker() {
    if (!this.props.showStampPrompt) return null;
    if (this.state.currentStep !== 'color') return null;

    return (
      <StampColorPicker
        onConfirmColors={this.onConfirmColors}
        onSelectColorMap={this.onSelectColorMap}
        onClickProjectColor={this.onClickProjectColor}
        onCancel={this.handleReset}
      />
    );
  }

  renderStampPlacer() {
    if (!this.props.showStampPrompt) return null;
    if (this.state.currentStep !== 'place') return null;

    return (
      <StampPlacer
        onPlaceStamp={this.onPlaceStamp}
        toggleModelOrientationMode={this.toggleModelOrientationMode}
        setStampRotation={this.setStampRotation}
        onCancel={this.handleReset}
      />
    );
  }

  renderDeleteModal() {
    if (!this.props.showDeleteModal) return null;

    return (
      <ConfirmationModal
        isWarning
        primaryLabel='Delete stamp'
        secondaryLabel='Are you sure you would like to delete this stamp?'
        onClickCancel={() => this.props.onDeleteCancel()}
        onClickConfirm={() => this.props.onDeleteConfirm()}
      />
    );
  }

  render() {
    return (
      <Container
        isFront={this.props.showStampPrompt || this.props.showDeleteModal}
        isOrientingModel={this.state.isOrientingModel}>
        <StampingContext.Provider
          value={{
            canvas: this.props.canvas,
            project: this.props.project,
            stampImageRef: this.stampImageRef,
            quantizedCanvas: this.quantizedCanvas,
            posterizedCanvas: this.posterizedCanvas,
            posterizedPalette: this.state.posterizedPalette,
            customColorMap: this.state.customColorMap,
            isOrientingModel: this.state.isOrientingModel,
          }}>
          {this.renderStampUploader()}
          {this.renderStampColorPicker()}
          {this.renderStampPlacer()}
          {this.renderDeleteModal()}
        </StampingContext.Provider>
      </Container>
    );
  }
}

export default StampPrompt;

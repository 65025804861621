import styled from 'styled-components';
import { StyledContainerBase } from '../../../../shared/abstractInput/abstractInput.styles';
import { Breakpoints } from '../../../../themes';

export const TransitionLengthConfigWrapper = styled.div`
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  display: grid;
  grid-row-gap: 0.5rem;
  padding: 1rem;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${(props) =>
    props.theme.colors.elevatedBackgroundSecondary};
  overflow-y: auto;
`;

export const CaptionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

export const MinMaxTransitionLength = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;

  @media (max-width: ${Breakpoints.smallWidth}) {
    flex-direction: column;
  }
`;

export const MinTransitionLengthInputWrapper = styled.div`
  margin-top: 0.5rem;
  flex-grow: 1;
  margin-right: 1rem;

  @media (max-width: ${Breakpoints.smallWidth}) {
    margin-right: 0;
  }
`;

export const MaxTransitionLengthInputWrapper = styled.div`
  margin-top: 0.5rem;
  flex-grow: 1;
`;

export const ColorStrengthTable = styled.table`
  margin: 1rem auto 2rem;
  width: 100%;
`;

export const ColorStrengthHeader = styled.tr``;

export const ColorStrengthHeaderCell = styled.th`
  font-family: inherit;
  font-size: 0.8rem;
  font-weight: inherit;
  text-align: center;
  color: ${(props) => props.theme.colors.textSecondary};
`;

export const ColorStrengthRow = styled.tr``;

export const ColorStrengthCell = styled.td`
  text-align: center;
  min-width: 2.5rem;
`;

export const ColorSwatchWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0.1rem 0;
`;

export const RadioButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const VerticalTextOuter = styled.div`
  width: 1rem;
  overflow: visible;
  text-align: center;
  transform: rotate(-90deg);
`;

export const VerticalTextInner = styled.div`
  margin-left: -10rem;
  margin-right: -10rem;
  text-align: center;
`;

export const PairTable = styled.table`
  margin: 0 auto;
  margin-bottom: 2rem;
  font-size: 0.8rem;
`;

export const PairHeaderCell = styled.th`
  font-family: inherit;
  font-weight: inherit;
  text-align: center;
  color: ${(props) => props.theme.colors.textSecondary};
  padding: 0.3rem;
  cursor: default;
  user-select: none;
`;

export const PairRow = styled.tr``;

export const PairCell = styled.td`
  text-align: center;
  padding: 0.3rem;
`;

export const PairFieldInputContainer = styled(StyledContainerBase)`
  margin: 0;
  padding: 0.5rem;
  min-height: 1.5rem;
  min-width: 3rem;
  line-height: 1rem;
`;

export const CheckboxWrapper = styled.div`
  width: calc(100% - 0.4rem);
`;

export const InfoHighlightWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
  padding: 1rem;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${(props) =>
    props.theme.colors.elevatedBackgroundSecondary};
`;

export const InfoContainer = styled.div`
  display: grid;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: end;
  gap: 0.5rem;
  margin-top: 1rem;
`;

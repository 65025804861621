import styled from 'styled-components';

import { Breakpoints } from '../../../../themes';

export const OptionsContainer = styled.div`
  position: relative;
  pointer-events: all;
  width: 15rem;
  pointer-events: auto;
  cursor: default;

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  padding: 0.5rem;
`;

export const ToolpathOptionCheckboxWrapper = styled.div`
  margin-top: 0.3rem;
`;

export const ToolpathPlaceholderWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
`;

export const PlaceholderContainer = styled.div`
  height: 8rem;
  padding: 0 0.5rem;
  width: calc(100% - 1rem);
  display: flex;
  align-items: center;
  text-align: center;
`;

export const BabySpinnerWrapper = styled.div`
  position: relative;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DisplayWrapper = styled.div`
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: none;
  }
`;

export const DisplayWrapperMobile = styled.div`
  display: none;

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: block;
  }
`;

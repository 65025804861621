import React, { Component } from 'react';
import _ from 'lodash';

import {
  Container,
  VariantToggleWrapper,
  DropDownIconContainer,
} from './multiVariantInput.styles';
import { fieldTypes } from '../../constants';

import Input from '../input/input.jsx';
import AbstractDropdown from '../abstractDropdown/abstractDropdown.jsx';
import { FormatUtils } from '../../utils';

class MultiVariantInput extends Component {
  static defaultProps = {
    type: 'number',
    label: '',
    variants: [],
    style: {},
    infoTooltip: null,
    wideTooltip: false,
    onFocus: () => {},
    onKeyDown: () => {},
    onChangeSuccess: () => {},
    onChangeFailure: () => {},
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      value: props.value,
      activeVariant: props.activeVariant || 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value,
      });
    }
    if (this.props.activeVariant !== prevProps.activeVariant) {
      this.setState({
        activeVariant: this.props.activeVariant,
      });
    }
  }

  onChangeSuccess(value) {
    this.setState({ value }, () => {
      this.props.onChangeSuccess({
        units: this.props.variants[this.state.activeVariant].units,
        value,
      });
    });
  }

  onChangeFailure(value) {
    this.setState({ value }, () => {
      this.props.onChangeFailure({
        units: this.props.variants[this.state.activeVariant].units,
        value,
      });
    });
  }

  renderVariant(variant) {
    let displayValue = this.state.value;
    const { disabled } = this.props;
    let isAuto = false;
    if (
      this.props.variants[this.state.activeVariant].type === fieldTypes.auto
    ) {
      displayValue = 'Auto';
      isAuto = true;
    }
    return (
      <Input
        {...variant}
        {...this.props}
        ref={this.inputRef}
        type='number'
        units=''
        label={this.props.label}
        value={displayValue}
        isInvalid={this.props.isInvalid}
        forceFocus={this.props.forceFocus}
        infoTooltip={this.props.infoTooltip}
        wideTooltip={this.props.wideTooltip}
        onKeyDown={(e) => this.props.onKeyDown(e)}
        onChangeSuccess={(value) => this.onChangeSuccess(value)}
        onChangeFailure={(value) => this.onChangeFailure(value)}
        onFocus={(e) => this.props.onFocus(e)}
        disabled={disabled}
        isAuto={isAuto}
      />
    );
  }

  renderActiveVariant() {
    return this.renderVariant(this.props.variants[this.state.activeVariant]);
  }

  onVariantChange(variantIndex) {
    const currentVariant = this.props.variants[this.state.activeVariant];
    const nextVariant = this.props.variants[variantIndex];

    let nextValue = 0;
    let error = false;
    try {
      const currentValueBaseUnits = currentVariant.toBaseUnits(
        this.state.value,
        this.props.style
      );
      nextValue = nextVariant.fromBaseUnits(
        currentValueBaseUnits,
        this.props.style
      );
    } catch (err) {
      error = true;
    }
    if (!this.state.value.toString().trim() || Number.isNaN(nextValue)) {
      error = true;
    }

    this.setState(
      {
        activeVariant: variantIndex,
        value: error ? '' : nextValue,
      },
      () => {
        if (error) {
          this.props.onChangeFailure({
            units: nextVariant.units,
            value: '',
          });
        } else {
          this.props.onChangeSuccess({
            units: nextVariant.units,
            value: nextValue,
          });
        }
      }
    );
  }

  static formatVariantToggleLabel(variant, isOptionLabel = false) {
    if (isOptionLabel && variant.optionLabel) {
      return variant.optionLabel;
    }
    if (variant.type === fieldTypes.auto) {
      return 'Auto';
    }
    return FormatUtils.formatUnits(variant.units);
  }

  renderVariantToggle() {
    const options = _.map(this.props.variants, (variant, index) => ({
      label: MultiVariantInput.formatVariantToggleLabel(variant),
      optionLabel: MultiVariantInput.formatVariantToggleLabel(variant, true),
      value: index,
    }));
    return (
      <VariantToggleWrapper>
        <AbstractDropdown
          borderlessContainer
          minHeight='0'
          grey
          noPadding
          rightAlign
          options={options}
          value={this.state.activeVariant}
          disabled={this.props.disabled}
          forceFocus={this.props.forceFocus}
          onChange={(variantIndex) => this.onVariantChange(variantIndex)}
          StyledIconContainer={DropDownIconContainer}
        />
      </VariantToggleWrapper>
    );
  }

  render() {
    return (
      <Container>
        {this.renderVariantToggle()}
        {this.renderActiveVariant()}
      </Container>
    );
  }
}

export default MultiVariantInput;

import 'regenerator-runtime/runtime';
import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import _ from 'lodash';

import { MonitoringUtils } from '../../utils';

import { getOnboardingState } from '../common';
import { actions, types } from '../../reducers/onboarding/onboarding';
import { types as authTypes } from '../../reducers/auth/auth';

// trigger a set of modals if they haven't already been triggered
function* handleAlwaysTriggeredModals(modals) {
  // trigger "Slic3r" if it hasn't been triggered yet
  const identifiers = ['slic3r'];
  const notYetTriggered = _.filter(
    identifiers,
    (identifier) => !modals[identifier].started
  );
  yield all(
    _.map(notYetTriggered, (identifier) =>
      put(actions.startModalRequest(identifier))
    )
  );
}

// trigger a set of modals only if a previous modal has been triggered
function* handleUpgradeTriggeredModals(modals, tasks) {
  const identifiers = [
    // ['identifier to trigger', ['if any of these identifiers were previously triggered']],
    ['uiRefresh1', ['introducingSetups', 'usingSetups']],
    ['uiRefresh2', ['introducingSetups', 'usingSetups']],
    ['uiRefresh3', ['introducingSetups', 'usingSetups']],
    ['palette3PingsAndCamera', ['tasks.palette3']],
  ];
  const shouldBeTriggered = _.filter(
    identifiers,
    ([identifier, pastTriggers]) => {
      // if the modal has already been triggered, ignore it
      if (modals[identifier]?.started) return false;
      // if any of the 'past triggers' have started, trigger it
      return _.some(pastTriggers, (pastIdentifier) => {
        if (pastIdentifier.startsWith('tasks.')) {
          return tasks[pastIdentifier.slice(6)]?.started;
        }
        return modals[pastIdentifier]?.started;
      });
    }
  );
  yield all(
    _.map(shouldBeTriggered, ([identifier]) =>
      put(actions.startModalRequest(identifier))
    )
  );
}

// check if getting the user's data should trigger any modals
function* watchGetAccount() {
  try {
    const { status } = yield select(getOnboardingState);
    if (status.getOnboardingStatePending) {
      const { success } = yield race({
        success: take(types.GET_ONBOARDING_STATE_SUCCESS),
        failure: take(types.GET_ONBOARDING_STATE_FAILURE),
      });
      if (!success) return;
    }

    const { modals, tasks } = yield select(getOnboardingState);
    yield call(handleAlwaysTriggeredModals, modals);
    yield call(handleUpgradeTriggeredModals, modals, tasks);
  } catch (err) {
    MonitoringUtils.sentryManualReport(err, true);
  }
}

// check if logging in should trigger any modals
function* watchLogin() {
  try {
    // after logging in, the root onboarding component mounts and
    // gets their state from the API -- wait for this request to finish!
    const { success } = yield race({
      success: take(types.GET_ONBOARDING_STATE_SUCCESS),
      failure: take(types.GET_ONBOARDING_STATE_FAILURE),
    });
    if (!success) return;

    const { modals, tasks } = yield select(getOnboardingState);
    yield call(handleAlwaysTriggeredModals, modals);
    yield call(handleUpgradeTriggeredModals, modals, tasks);
  } catch (err) {
    MonitoringUtils.sentryManualReport(err, true);
  }
}

// check if signing up should trigger any modals
function* watchSignup() {
  try {
    // trigger "welcome to Canvas" (we know it hasn't been triggered yet)
    const identifier = 'welcomeToCanvas';
    yield put(actions.startModalRequest(identifier));
  } catch (err) {
    MonitoringUtils.sentryManualReport(err, true);
  }
}

export default function* modalTriggers() {
  yield all([
    takeEvery(authTypes.GET_ACCOUNT_SUCCESS, watchGetAccount),
    takeEvery(authTypes.LOGIN_SUCCESS, watchLogin),
    takeEvery(authTypes.SIGNUP_SUCCESS, watchSignup),
  ]);
}

import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';
import { withTheme } from 'styled-components';
import { Container } from './icon.styles';

const sizes = {
  MINI: 16,
  SMALL: 20,
  MEDIUM: 24,
  LARGE: 40,
};

class Icon extends Component {
  static sizes = sizes;

  static defaultProps = {
    // src: the path to the svg icon, the icons are under theme/icons
    src: '',
    // choose an overriding color, default: grey6
    color: '',
    recolor: true,
    // choose an overriding size, default: 24px * 24px
    size: sizes.MEDIUM,
  };

  render() {
    return (
      <Container sizePx={this.props.size}>
        <ReactSVG
          // beforeInjection: apply any modifications needed to the svg
          beforeInjection={(svg) => {
            if (this.props.recolor) {
              const color =
                this.props.color === ''
                  ? this.props.theme.colors.grey6
                  : this.props.color;
              svg.querySelectorAll('path').forEach((p) => {
                p.setAttribute('fill', color);
              });
            }
            if (!svg.getAttribute('viewBox')) {
              svg.setAttribute(
                'viewBox',
                `0 0 ${String(Icon.sizes.MEDIUM)} ${String(Icon.sizes.MEDIUM)}`
              );
            }
            svg.setAttribute('preserveAspectRatio', 'xMidYMid meet');
            svg.setAttribute('width', String(this.props.size));
            svg.setAttribute('height', String(this.props.size));
          }}
          // fallback: insert a svg to maintain the layout
          fallback={() => (
            <svg
              width={this.props.size}
              height={this.props.size}
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'></svg>
          )}
          // loading: have a placeholder svg while loading // TODO try this out
          loading={() => (
            <svg
              width={this.props.size}
              height={this.props.size}
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'></svg>
          )}
          src={this.props.src}
          wrapper='svg'></ReactSVG>
      </Container>
    );
  }
}

export default withTheme(Icon);

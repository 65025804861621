import { connect } from 'react-redux';

import Page from './page.jsx';

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.refreshToken,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Page);

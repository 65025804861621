import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

export default function* updateModelName(action) {
  try {
    const { currentProject: projectId } = yield select(getSlicerState);
    const { modelId, newModelName } = action.payload;

    const response = yield call(API, {
      method: methods.POST,
      path: `projects/${projectId}/models/${modelId}`,
      body: {
        name: newModelName,
      },
    });
    if (response === null) return;

    yield put(actions.updateModelNameSuccess(modelId, newModelName));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

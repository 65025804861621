import _ from 'lodash';

const Utils = {
  getSpliceCore: (paletteType, paletteModel) => {
    switch (paletteType) {
      case 'palette':
        return 'P';
      case 'palette-2':
        switch (paletteModel) {
          case 'p2':
            return 'SC';
          case 'p2-pro':
            return 'SCP';
          case 'p2s':
            return 'SCS';
          case 'p2s-pro':
            return 'SCSP';
          default:
            throw new Error(
              `Unknown Palette model '${paletteModel}' encountered for type '${paletteType}'`
            );
        }
      case 'palette-3':
        switch (paletteModel) {
          case 'p3':
            return 'P3-SC';
          case 'p3-pro':
            return 'P3-SCP';
          default:
            throw new Error(
              `Unknown Palette model '${paletteModel}' encountered for type '${paletteType}'`
            );
        }
      default:
        throw new Error(`Unknown Palette type '${paletteType}' encountered`);
    }
  },
  formatSettingsRequest: (settings, spliceCore, idPair) => {
    // properly format a single changed idPair settings for the createSpliceSetting saga
    const idPairValues = idPair.split('-');
    const { heatFactor, compressionFactor, coolingFactor, reverse } = settings;
    const formattedSettings = {
      spliceCore,
      heatFactor,
      compressionFactor,
      coolingFactor: 0,
      reverse: false,
    };
    if (spliceCore === 'P') {
      formattedSettings.reverse = reverse;
    } else {
      formattedSettings.coolingFactor = coolingFactor;
    }
    return {
      ingoingId: idPairValues[0],
      outgoingId: idPairValues[1],
      settings: formattedSettings,
    };
  },
  // compare original and current splice settings to see if any changes were made
  areSpliceSettingsChanged: (existingSettings, newSettings) =>
    !_.isEqual(existingSettings, newSettings),
  // compare original and current splice settings and get all changes made
  // - returns a formatted array of all changed splice settings
  getAllChangedSpliceSettings: (existingSettings, newSettings) =>
    _.reduce(
      _.keys(newSettings),
      (changes, idPair) => {
        const allSpliceCoreSettings = newSettings[idPair];
        _.forIn(allSpliceCoreSettings, (settings, spliceCore) => {
          if (settings !== existingSettings[idPair][spliceCore]) {
            const change = Utils.formatSettingsRequest(
              settings,
              spliceCore,
              idPair
            );
            changes.push(change);
          }
        });
        return changes;
      },
      []
    ),
};

export default Utils;

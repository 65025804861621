import React, { Component } from 'react';
import _ from 'lodash';

import {
  MaterialInfo,
  MaterialList,
  PlaceholderTextWrapper,
} from './materialManager.styles';
import { materialStyleFieldNames } from './configureMaterial/fields.metadata';
import ConfigureMaterial from './configureMaterial/configureMaterial.container';
import SpliceTransitionModal from '../slicer/spliceTransitionModal/spliceTransitionModal.jsx';

import { Icons } from '../../themes';
import Routes from '../../router/routes';

import { FormatUtils, InterfaceUtils, MaterialUtils } from '../../utils';
import { MaterialSortMode } from '../../utils/sort/sort';

import {
  Body1,
  Body1OneLine,
  Button,
  Badge,
  ConfirmationModal,
  ManagerView,
  MaterialCard,
  RenameModal,
  Subtitle1OneLine,
} from '../../shared';

import {
  ButtonsContainer,
  ButtonWrapper,
} from '../../shared/managerView/managerView.styles';

const sortOptions = [
  { label: 'Name', value: MaterialSortMode.NAME },
  { label: 'Type', value: MaterialSortMode.TYPE },
  { label: 'Date modified', value: MaterialSortMode.DATE_MODIFIED },
  { label: 'Date created', value: MaterialSortMode.DATE_CREATED },
];

class MaterialManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterBy: '',
      showDeleteMaterialModal: false,
      materialsList: [],
      selectedMaterialIds: [],
      editMaterialId: '',
      showAddMaterialModal: false,
      showEditMaterialModal: false,
      showRenameMaterialModal: false,
      showSpliceSettingsModal: false,
    };
    this.onWindowClick = this.onWindowClick.bind(this);
    this.onWindowKeydown = this.onWindowKeydown.bind(this);
  }

  componentDidMount() {
    this.props.updateNavStack([
      { text: 'My Materials', route: Routes.toManageMaterials() },
    ]);
    window.addEventListener('click', this.onWindowClick);
    window.addEventListener('keydown', this.onWindowKeydown);
    if (this.props.getMaterialsSuccess) {
      this.updateMaterialsList();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
    window.removeEventListener('keydown', this.onWindowKeydown);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.materials !== prevProps.materials ||
      !_.isEqual(this.props.sortBy, prevProps.sortBy) ||
      this.props.location.state?.selectedId
    ) {
      this.updateMaterialsList();
    }
  }

  updateMaterialsList() {
    const { materials } = this.props;
    const { filterBy, selectedMaterialIds } = this.state;
    const sortedAndFilteredMaterials = this.filterMaterials(
      materials,
      filterBy
    );
    const newState = {
      materialsList: sortedAndFilteredMaterials,
      selectedMaterialIds: [],
    };

    if (this.props.location.state?.selectedId) {
      newState.selectedMaterialIds.push(this.props.location.state.selectedId);
    } else {
      _.forEach(selectedMaterialIds, (id) => {
        if (
          _.find(sortedAndFilteredMaterials, (material) => material.id === id)
        ) {
          newState.selectedMaterialIds.push(id);
        }
      });
    }

    this.setState(newState, () => {
      if (this.props.location.state?.selectedId) {
        this.props.history.replace(this.props.location.pathname);
      }
    });
  }

  onWindowKeydown(event) {
    if (event.key === 'Escape') {
      this.deselect();
    } else if (
      event.key === 'Delete' &&
      this.state.selectedMaterialIds.length >= 1
    ) {
      // Hit DELETE while selecting materials(s)
      this.showDeleteModal(event);
    }
  }

  onWindowClick() {
    const { showDeleteMaterialModal, showRenameMaterialModal } = this.state;
    if (showDeleteMaterialModal || showRenameMaterialModal) {
      return;
    }
    this.deselect();
  }

  deselect() {
    const { showDeleteMaterialModal, showRenameMaterialModal } = this.state;
    if (showDeleteMaterialModal || showRenameMaterialModal) {
      this.setState({
        showDeleteMaterialModal: false,
        showRenameMaterialModal: false,
      });
    } else {
      this.setState({ selectedMaterialIds: [] });
    }
  }

  showDeleteModal(e) {
    e.stopPropagation();
    if (_.isEmpty(this.props.materials)) return;
    this.setState({
      showDeleteMaterialModal: true,
    });
  }

  hideDeleteModal() {
    this.setState({
      showDeleteMaterialModal: false,
    });
  }

  handleDeleteMaterial() {
    const { selectedMaterialIds } = this.state;
    this.props.deleteMaterials(selectedMaterialIds);
    this.hideDeleteModal();
  }

  showRenameModal() {
    this.setState({ showRenameMaterialModal: true });
  }

  hideRenameModal() {
    this.setState({ showRenameMaterialModal: false });
  }

  handleRenameMaterial(renameValue) {
    const [materialId] = this.state.selectedMaterialIds;
    const material = this.props.materials[materialId];
    const updatedMaterialName = {
      id: materialId,
      name: renameValue,
    };
    if (renameValue.trim() !== material.name) {
      this.props.editMaterial(updatedMaterialName);
    }
    this.hideRenameModal();
  }

  renderRenameModal() {
    const { showRenameMaterialModal, selectedMaterialIds, materialsList } =
      this.state;
    if (!showRenameMaterialModal) return null;
    const [materialId] = selectedMaterialIds;
    const currentMaterial = _.filter(
      materialsList,
      (material) => material.id === materialId
    );
    if (!currentMaterial) return null;

    const materialName = currentMaterial[0].name;
    return (
      <RenameModal
        initialValue={materialName}
        title={'Rename Material'}
        onMarginClick={() => this.hideRenameModal()}
        onCancel={() => this.hideRenameModal()}
        onSave={(renameValue) => this.handleRenameMaterial(renameValue)}
      />
    );
  }

  selectMaterial(e, material) {
    e.stopPropagation();
    const { selectedMaterialIds, materialsList } = this.state;

    const updatedSelectedItems = InterfaceUtils.handleSelectableItemClick(
      e,
      materialsList,
      selectedMaterialIds,
      material.id
    );
    this.setState({ selectedMaterialIds: updatedSelectedItems });
  }

  filterMaterials(materials, filterBy) {
    const filter = filterBy.toLowerCase();
    const filtered = _.filter(materials, (material) => {
      if (filter !== '') {
        return (
          material.name.toLowerCase().includes(filter) || // name match
          material.type.toLowerCase().includes(filter) // type match
        );
      }
      return true;
    });
    return MaterialUtils.sortMaterials(filtered, this.props.sortBy);
  }

  onFilterChange(filterBy) {
    this.setState({ filterBy }, () => {
      this.updateMaterialsList();
    });
  }

  onSortChange(sortMode, sortDirection) {
    this.props.updatePreferences(sortMode, sortDirection);
  }

  handleEditClick() {
    if (_.isEmpty(this.props.materials)) return;
    const { selectedMaterialIds } = this.state;
    this.props.history.push(Routes.toEditMaterial(selectedMaterialIds[0]));
  }

  renderAddMaterialModal() {
    const { showAddMaterialModal } = this.state;
    if (!showAddMaterialModal) return null;

    return (
      <ConfigureMaterial onClickClose={() => this.hideAddMaterialModal()} />
    );
  }

  renderEditMaterialModal() {
    const { showEditMaterialModal, editMaterialId } = this.state;
    if (!showEditMaterialModal) return null;
    return (
      <ConfigureMaterial
        materialId={editMaterialId}
        onClickClose={() => this.hideEditMaterialModal()}
      />
    );
  }

  showEditMaterialModal() {
    const { selectedMaterialIds } = this.state;
    this.setState({
      showEditMaterialModal: true,
      editMaterialId: selectedMaterialIds[0],
    });
  }

  hideEditMaterialModal() {
    this.setState({
      showEditMaterialModal: false,
      editMaterialId: '',
    });
  }

  showAddMaterialModal(e) {
    e.stopPropagation();
    this.setState({
      showAddMaterialModal: true,
    });
  }

  hideAddMaterialModal() {
    this.setState({
      showAddMaterialModal: false,
    });
  }

  renderDeleteModal() {
    const { showDeleteMaterialModal, selectedMaterialIds } = this.state;
    if (!showDeleteMaterialModal) return null;

    const firstMaterialName = this.props.materials[selectedMaterialIds[0]].name;
    const mainLabel =
      selectedMaterialIds.length > 1
        ? `${selectedMaterialIds.length} materials`
        : `${firstMaterialName}`;
    const secondaryLabel =
      selectedMaterialIds.length > 1 ? 'these materials' : 'this material';

    return (
      <ConfirmationModal
        isWarning
        primaryLabel={`Delete ${mainLabel}?`}
        secondaryLabel={`Are you sure you would like to remove ${secondaryLabel}?`}
        tertiaryLabel={`Parts of the projects that use ${secondaryLabel} will be reset to the default material.`}
        onClickCancel={() => this.hideDeleteModal()}
        onClickConfirm={() => this.handleDeleteMaterial()}
      />
    );
  }

  getActionButtonsRightMeta() {
    const { selectedMaterialIds } = this.state;
    return [
      {
        icon: Icons.basic.pencil,
        title: FormatUtils.pluralize(
          'Rename material profile',
          selectedMaterialIds.length,
          true
        ),
        disabled: selectedMaterialIds.length !== 1,
        onClick: () => this.showRenameModal(),
      },
      {
        icon: Icons.basic.trash,
        title: FormatUtils.pluralize(
          'Delete material profile',
          selectedMaterialIds.length,
          true
        ),
        disabled: _.isEmpty(selectedMaterialIds),
        onClick: (e) => this.showDeleteModal(e),
      },
    ];
  }

  renderMaterialItem(material) {
    const { selectedMaterialIds } = this.state;
    const overridesCount = _.reduce(
      materialStyleFieldNames,
      (acc, field) => (material.style[field[0]] ? acc + 1 : acc),
      0
    );
    const label =
      overridesCount === 0
        ? 'No style overrides'
        : `${overridesCount} style ${FormatUtils.pluralize(
            'override',
            overridesCount
          )}`;
    const materialSelected = _.includes(selectedMaterialIds, material.id);

    return (
      <MaterialCard
        key={material.id}
        selected={materialSelected}
        onClick={(e) => this.selectMaterial(e, material)}
        onDoubleClick={() => this.showEditMaterialModal()}>
        <MaterialInfo>
          <Badge>{material.type}</Badge>
          <Subtitle1OneLine>{material.name}</Subtitle1OneLine>
        </MaterialInfo>
        <Body1OneLine grey noSpacing>
          {label}
        </Body1OneLine>
      </MaterialCard>
    );
  }

  renderMaterialsPlaceholder() {
    if (!_.isEmpty(this.props.materials)) return null;
    return (
      <PlaceholderTextWrapper>
        <Body1 grey> You do not have any custom materials yet. </Body1>
      </PlaceholderTextWrapper>
    );
  }

  renderMaterials() {
    const { materialsList } = this.state;
    return (
      <MaterialList>
        {this.renderMaterialsPlaceholder()}
        {_.map(materialsList, (material) => this.renderMaterialItem(material))}
      </MaterialList>
    );
  }

  getLoadingOverlayText() {
    if (this.props.deleteMaterialsPending) {
      const { selectedMaterialIds } = this.state;
      return FormatUtils.pluralize(
        'Deleting material',
        selectedMaterialIds.length
      );
    }
    return null;
  }

  areActionsDisabled() {
    const { getMaterialsSuccess, materials } = this.props;
    return !getMaterialsSuccess || _.isEmpty(materials);
  }

  toggleSpliceSettingsModal() {
    const { showSpliceSettingsModal } = this.state;
    this.setState({ showSpliceSettingsModal: !showSpliceSettingsModal });
  }

  renderSpliceSettingsModal() {
    const { showSpliceSettingsModal } = this.state;
    if (!showSpliceSettingsModal && this.props.createSpliceSettingsPending) {
      return InterfaceUtils.getLoadingSpinner(this.props);
    }
    if (!showSpliceSettingsModal) {
      return null;
    }
    return (
      <SpliceTransitionModal
        spliceSettingsOnly
        onClickClose={() => this.toggleSpliceSettingsModal()}
      />
    );
  }

  renderRightPanelContent() {
    return (
      <ButtonsContainer>
        <ButtonWrapper>
          <Button
            primary
            expand
            icon={Icons.basic.plus}
            onClick={(e) => this.showAddMaterialModal(e)}>
            New material
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            expand
            disabled={_.isEmpty(this.props.materials)}
            onClick={(e) => {
              e.stopPropagation();
              this.toggleSpliceSettingsModal();
            }}>
            Splice settings
          </Button>
        </ButtonWrapper>
      </ButtonsContainer>
    );
  }

  render() {
    const { materials, getMaterialsPending, getMaterialsSuccess } = this.props;

    return (
      <>
        <ManagerView
          name='Materials'
          actionsDisabled={this.areActionsDisabled()}
          menuButtons={this.renderRightPanelContent()}
          loadingOverlayText={this.getLoadingOverlayText()}
          loadingContent={getMaterialsPending}
          loadingError={!getMaterialsPending && !getMaterialsSuccess}
          noContent={_.isEmpty(materials)}
          filterBarValue={this.state.filterBy}
          onFilterChange={(value) => this.onFilterChange(value)}
          sortOptions={sortOptions}
          sortMode={this.props.sortBy.mode}
          sortDirection={this.props.sortBy.direction}
          onSortChange={(mode, direction) => this.onSortChange(mode, direction)}
          actionButtons={this.getActionButtonsRightMeta()}>
          {this.renderMaterials()}
        </ManagerView>
        {this.renderDeleteModal()}
        {this.renderEditMaterialModal()}
        {this.renderAddMaterialModal()}
        {this.renderRenameModal()}
        {this.renderSpliceSettingsModal()}
      </>
    );
  }
}

export default MaterialManager;

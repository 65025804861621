import _ from 'lodash';

import { fieldTypes, slicers } from '../../../../constants';
import { Icons } from '../../../../themes';

import General from './general/general.jsx';
import LayerHeight from './layerHeight/layerHeight.jsx';
import Extrusion from './extrusion/extrusion.jsx';
import Perimeters from './perimeters/perimeters.jsx';
import Infill from './infill/infill.jsx';
import Temperature from './temperature/temperature.jsx';
import FirstLayer from './firstLayer/firstLayer.jsx';
import Supports from './supports/supports.jsx';
import Transition from './transition/transition.jsx';

import GeneralMetadata from './general/general.metadata';
import LayerHeightMetadata from './layerHeight/layerHeight.metadata';
import ExtrusionMetadata from './extrusion/extrusion.metadata';
import PerimetersMetadata from './perimeters/perimeters.metadata';
import InfillMetadata, { infillStyles } from './infill/infill.metadata';
import TemperatureMetadata from './temperature/temperature.metadata';
import FirstLayerMetadata from './firstLayer/firstLayer.metadata';
import SupportsMetadata, {
  supportDensities,
} from './supports/supports.metadata';
import TransitionMetadata, {
  transitionMethods,
} from './transition/transition.metadata';

const { Slic3r } = slicers;

// display this instead of a value in highlights
const ERR_CHAR = '—';

const getSkinThicknessHighlight = (style) => {
  if (style.skinThickness.units === 'mm') {
    return `${style.skinThickness.value} mm`;
  }
  return `${style.skinThickness.value} layers`;
};

const getLayerHeightHighlight = (style, errors) => {
  if (errors.layerHeight) return ERR_CHAR;
  if (style.useVariableLayerHeight) {
    if (errors.maxLayerHeight) return ERR_CHAR;
    const maxLayerHeight =
      style.maxLayerHeight.units === '%'
        ? Math.round(style.layerHeight * style.maxLayerHeight.value) / 100
        : style.maxLayerHeight.value;
    return `${style.layerHeight} to ${maxLayerHeight} mm`;
  }
  return `${style.layerHeight} mm`;
};

const getFirstLayerHeightHighlight = (style, errors) => {
  if (errors.firstLayerHeight) return ERR_CHAR;
  if (style.firstLayerHeight.units === 'mm') {
    return `${style.firstLayerHeight.value} mm`;
  }
  if (errors.layerHeight) return ERR_CHAR;
  return `${
    Math.round(style.layerHeight * style.firstLayerHeight.value) / 100
  } mm`;
};

const getRetractLengthHighlight = (style, errors) => {
  if (!style.useRetracts) return 'off';
  if (errors.retractLength) return ERR_CHAR;
  return `${style.retractLength} mm`;
};

const getBedTemperatureHighlight = (style, errors) => {
  if (errors.bedTemperature) return ERR_CHAR;
  return style.bedTemperature === 0 ? 'off' : `${style.bedTemperature} °C`;
};

const getSupportDensityHighlight = (style) => {
  if (!style.useSupport) return 'off';
  if (_.has(supportDensities, style.supportDensity)) {
    return supportDensities[style.supportDensity].label.toLowerCase();
  }
  // fallback case so legacy density values don't crash the page
  return 'on';
};

const getTransitionMethodHighlight = (style) => {
  if (style.transitionMethod === 0) return 'off';
  const meta = _.find(
    transitionMethods,
    (method) => method.value === style.transitionMethod
  );
  if (!meta) return ERR_CHAR;
  return meta.label.toLowerCase();
};

export const Forms = [
  {
    fieldName: 'general',
    label: 'General',
    tags: [],
    icon: Icons.basic.settings,
    fieldChanges: (changes) => {
      return changes;
    },
    content: General,
    metadata: GeneralMetadata,
    highlights: (style, errors, slicer) => {
      const highlights = [
        {
          label: 'Shell',
          tags: [],
          value: errors.skinThickness
            ? ERR_CHAR
            : getSkinThicknessHighlight(style),
        },
      ];
      if (!slicer || slicer === Slic3r) {
        highlights.push({
          label: 'Ironing',
          tags: [],
          value: style.useIroning ? 'on' : 'off',
        });
      }
      return highlights;
    },
  },
  {
    name: 'layerHeight',
    label: 'Layer Height',
    tags: [],
    icon: Icons.fdm.layers,
    fieldChanges: (changes) => {
      return changes;
    },
    content: LayerHeight,
    metadata: LayerHeightMetadata,
    highlights: (style, errors) => [
      {
        label: 'Layer height',
        tags: [],
        value: getLayerHeightHighlight(style, errors),
      },
      {
        label: 'First layer',
        tags: [],
        value: getFirstLayerHeightHighlight(style, errors),
      },
    ],
  },
  {
    name: 'extrusion',
    label: 'Extrusion',
    tags: [],
    icon: Icons.fdm.extruder,
    fieldChanges: (changes) => {
      return changes;
    },
    content: Extrusion,
    metadata: ExtrusionMetadata,
    highlights: (style, errors) => [
      {
        label: 'Width',
        tags: [],
        value: errors.extrusionWidth ? ERR_CHAR : `${style.extrusionWidth} mm`,
      },
      {
        label: 'Multiplier',
        tags: [],
        value: errors.extrusionMultiplier
          ? ERR_CHAR
          : `${style.extrusionMultiplier}%`,
      },
      {
        label: 'Retraction',
        tags: [],
        value: getRetractLengthHighlight(style, errors),
      },
    ],
  },
  {
    fieldName: 'perimeters',
    label: 'Perimeters',
    tags: [],
    icon: Icons.fdm.perimeter,
    fieldChanges: (changes) => {
      return changes;
    },
    content: Perimeters,
    metadata: PerimetersMetadata,
    highlights: (style, errors) => [
      {
        label: 'Perimeters',
        tags: [],
        value: errors.perimeterCount ? ERR_CHAR : style.perimeterCount,
      },
    ],
  },
  {
    name: 'infill',
    label: 'Infill',
    tags: [],
    icon: Icons.fdm.infill,
    fieldChanges: (changes) => {
      return changes;
    },
    content: Infill,
    metadata: InfillMetadata,
    highlights: (style, errors) => {
      const highlights = [];
      if (style.spiralVaseMode) {
        highlights.push({
          label: 'Style',
          tags: [],
          value: 'vase mode',
        });
      } else {
        highlights.push({
          label: 'Density',
          tags: [],
          value: errors.infillDensity ? ERR_CHAR : `${style.infillDensity}%`,
        });
        highlights.push({
          label: 'Style',
          tags: [],
          value: infillStyles[style.infillStyle].label.toLowerCase(),
        });
      }
      return highlights;
    },
  },
  {
    name: 'temperature',
    label: 'Temperature and Cooling',
    tags: [],
    icon: Icons.fdm.temperature,
    fieldChanges: (changes) => {
      return changes;
    },
    content: Temperature,
    metadata: TemperatureMetadata,
    highlights: (style, errors) => {
      const highlights = [
        {
          label: 'Extruder',
          tags: [],
          value: errors.printTemperature
            ? ERR_CHAR
            : `${style.printTemperature} °C`,
        },
      ];
      if (
        style.firstLayerPrintTemperature.value !== 'auto' &&
        style.firstLayerPrintTemperature.value !== style.printTemperature
      ) {
        highlights.push({
          label: 'First layer',
          tags: [],
          value: errors.firstLayerPrintTemperature
            ? ERR_CHAR
            : `${style.firstLayerPrintTemperature.value} °C`,
        });
      }
      highlights.push({
        label: 'Bed',
        tags: [],
        value: getBedTemperatureHighlight(style, errors),
      });
      return highlights;
    },
  },
  {
    name: 'firstLayer',
    label: 'First Layer',
    tags: [],
    icon: Icons.fdm.firstLayer,
    fieldChanges: (changes) => {
      return changes;
    },
    content: FirstLayer,
    metadata: FirstLayerMetadata,
    highlights: (style, errors) => [
      {
        label: style.brimGap > 0 || errors.brimGap ? 'Skirt' : 'Brim',
        tags: [],
        value: style.useBrim ? 'on' : 'off',
      },
      {
        label: 'Raft',
        tags: [],
        value: style.useRaft ? 'on' : 'off',
      },
    ],
  },
  {
    name: 'supports',
    label: 'Supports',
    tags: [],
    icon: Icons.fdm.support,
    fieldChanges: (changes) => {
      return changes;
    },
    content: Supports,
    metadata: SupportsMetadata,
    highlights: (style) => {
      const highlights = [
        {
          label: style.useSupport ? 'Density' : 'Support material',
          tags: [],
          value: getSupportDensityHighlight(style),
        },
      ];
      if (style.useSupport) {
        highlights.push({
          label: 'Interfaces',
          tags: [],
          value: style.useSupportInterface ? 'on' : 'off',
        });
      }
      return highlights;
    },
  },
  {
    name: 'transition',
    label: 'Transition',
    tags: [],
    icon: Icons.fdm.transition,
    fieldChanges: (changes) => {
      return changes;
    },
    content: Transition,
    metadata: TransitionMetadata,
    display: (style, projectProps) =>
      !projectProps || projectProps.colors.length > 1,
    highlights: (style, errors) => {
      const highlights = [
        {
          label: 'Method',
          tags: [],
          value: getTransitionMethodHighlight(style),
        },
      ];
      if (style.transitionMethod > 0) {
        highlights.push({
          label: 'Length',
          tags: [],
          value: errors.transitionLength
            ? ERR_CHAR
            : `${style.transitionLength} mm`,
        });
      }
      return highlights;
    },
  },
];

export const Fields = [
  {
    name: 'name',
    label: 'Name',
    description: 'Give the style profile a name',
    tags: [],
    required: true,
    type: fieldTypes.text,
  },
  {
    name: 'icon',
    label: 'Icon',
    description: 'A custom icon helps differentiating style profiles',
    tags: [],
    required: true,
    type: fieldTypes.icon,
    options: [
      'default/styles/1',
      'default/styles/2',
      'default/styles/3',
      'default/styles/4',
      'default/styles/5',
      'default/styles/6',
      'default/styles/7',
      'default/styles/8',
      'default/styles/9',
    ],
  },
];

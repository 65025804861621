import React, { useContext } from 'react';

import { SlicerContext } from '../slicer.context';

import { CanvasElement } from './visualizer.styles';

import { useDocumentEvent } from '../../../hooks';
import { updateMouse } from '../../../sagas/three/raycast';

const Visualizer = (props, ref) => {
  const { currentView, currentTool, projectInitialized } =
    useContext(SlicerContext);

  const handleMouseUpdate = (clientX, clientY) => {
    if (!projectInitialized) return;
    updateMouse(clientX, clientY);
  };

  const onMouseMove = (e) => {
    handleMouseUpdate(e.clientX, e.clientY);
  };

  const onTouchMove = (e) => {
    handleMouseUpdate(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
  };

  const onTouchStart = (e) => {
    handleMouseUpdate(e.touches[0].clientX, e.touches[0].clientY);
  };

  const onTouchEnd = (e) => {
    handleMouseUpdate(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
  };

  useDocumentEvent([['touchmove', onTouchMove]]);

  return (
    <CanvasElement
      ref={ref}
      currentView={currentView}
      currentTool={currentTool}
      onMouseMove={onMouseMove}
      onTouchMove={onTouchMove}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    />
  );
};

export default React.forwardRef(Visualizer);

import 'regenerator-runtime/runtime';
import { call, select, put } from 'redux-saga/effects';
import _ from 'lodash';

import { getThreeState, handleGenericError } from '../common';
import actions from '../../reducers/three/actions';

import { updateCamera as updateCameraForRaycast } from './raycast';
import { updateCamera as updateCameraForResize } from './resize';
import { setCamera, setRenderFlag } from './animationFrame';

import { SceneUtils } from '../../utils';

export default function* replaceCamera(action) {
  try {
    const { scene, camera, controls, gizmo } = yield select(getThreeState);
    const { orthographic, paintMode, modelToColor } = action.payload;

    const newCamera = SceneUtils.createCamera(orthographic);
    // transfer lights and whatnot to the new camera
    _.forEach(camera.children, (child) => {
      camera.remove(child);
      newCamera.add(child);
    });

    // update references to the camera
    controls.object = newCamera;
    gizmo.camera = newCamera;

    // swap them out
    scene.remove(camera);
    scene.add(newCamera);
    setCamera(newCamera);
    updateCameraForRaycast(newCamera);
    updateCameraForResize(newCamera);

    yield put(actions.replaceCameraSuccess(newCamera));
    // adjust camera position differently depending on current view
    yield put(actions.resetCamera(false, paintMode, modelToColor));
    setRenderFlag();
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import React, { Component } from 'react';
import _ from 'lodash';

import {
  ColorSelectionWrapper,
  ColorSwatch,
  Container,
  CustomColorSwatch,
  Header,
  MaterialSelectionWrapper,
  NewMaterialOption,
  StyleOverrideContainer,
  StyledMaterialOption,
  StyledMaterialSelect,
  StyledMaterialOptionWrapper,
} from './material.styles';

import ConfigureColor from './configureColor/configureColor.jsx';
import ConfigureMaterial from '../../../../materialManager/configureMaterial/configureMaterial.container';
import MaterialConfigFields, {
  materialStyleFieldNames,
} from '../../../../materialManager/configureMaterial/fields.metadata';

import { CommonColors } from './colors.metadata';
import { Icons } from '../../../../../themes';

import {
  FormatUtils,
  MaterialUtils,
  SlicerUtils,
  SortUtils,
} from '../../../../../utils';

import {
  ActionButton,
  Badge,
  Body1,
  Caption,
  ColorSelectionDropdown,
  Modal,
} from '../../../../../shared';
import AbstractDropdown from '../../../../../shared/abstractDropdown/abstractDropdown.jsx';

class MaterialTool extends Component {
  static defaultProps = {
    currentView: 'place',
    dropdownOpen: () => {},
    dropdownClose: () => {},
    closeMaterialTool: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      showConfigureColorModal: false,
      showAddMaterialModal: false,
      showEditMaterialModal: false,
      editMaterialId: '',
    };
  }

  handleChangeMaterial(materialId) {
    const { project, currentExtruderIndex } = this.props;
    const { materialIds: existingIds } = project;
    const updatedMaterialIds = existingIds;
    updatedMaterialIds[currentExtruderIndex] = materialId;
    this.props.changeMaterial(updatedMaterialIds);
  }

  handleUpdateProjectColor(color) {
    const { project, currentExtruderIndex, currentView } = this.props;
    let alpha = 1;
    let newColor = color;
    if (_.isArray(color)) {
      // convert RGBA to HEX
      alpha = color[color.length - 1];
      newColor = SlicerUtils.rgbaArrayToHexString(color);
    }

    if (currentView === 'place' || currentView === 'paint') {
      // if in paint view, update RLE then update project colors after
      const updateRLE = currentView === 'paint';
      this.props.updateProjectColors(
        project,
        currentExtruderIndex,
        newColor,
        alpha,
        updateRLE
      );
      if (currentView === 'paint') {
        // update brush color as well if in paint view
        this.props.selectBrushColor(newColor);
      }
    }
  }

  toggleConfigureColorModal() {
    this.setState({
      showConfigureColorModal: !this.state.showConfigureColorModal,
    });
  }

  toggleAddMaterialModal() {
    this.setState({
      showAddMaterialModal: !this.state.showAddMaterialModal,
    });
  }

  showEditMaterialModal(e, material) {
    e.stopPropagation();
    this.setState({
      showEditMaterialModal: true,
      editMaterialId: material.id,
    });
  }

  hideEditMaterialModal() {
    this.setState({
      showEditMaterialModal: false,
      editMaterialId: '',
    });
  }

  renderColorSelectionDropdown() {
    const CREATE_CUSTOM_COLOR_LABEL = 'Custom';
    const { project, currentExtruderIndex } = this.props;
    const dropdownOptions = [];

    // the currentExtruderIndex's color is custom
    const currColorHex = SlicerUtils.rgbaArrayToHexString(
      project.colors[currentExtruderIndex]
    ).toUpperCase();
    const currColorIsCustom = CommonColors.every(
      (commonColor) => commonColor.hex !== currColorHex
    );
    if (currColorIsCustom) {
      dropdownOptions.push({
        label: MaterialUtils.getClosestColor(currColorHex),
        value: currColorHex,
        customOptionLabel: this.renderColorSwatch(currColorHex, 0),
      });
    }

    // add CommonColors to the options
    CommonColors.forEach((color, index) => {
      dropdownOptions.push({
        label: `${MaterialUtils.getClosestColor(color.hex)}`,
        value: color.hex,
        customOptionLabel: this.renderColorSwatch(color.hex, index + 1),
      });
    });

    // add choose custom color to options
    const customColorOption = {
      label: CREATE_CUSTOM_COLOR_LABEL,
      value: CREATE_CUSTOM_COLOR_LABEL,
      customOptionLabel: (
        <CustomColorSwatch onClick={() => this.toggleConfigureColorModal()} />
      ),
    };
    dropdownOptions.push(customColorOption);

    return (
      <ColorSelectionWrapper>
        <ColorSelectionDropdown
          value={currColorHex}
          options={dropdownOptions}
          onChange={(value) =>
            value === CREATE_CUSTOM_COLOR_LABEL
              ? this.toggleConfigureColorModal()
              : this.handleUpdateProjectColor(value)
          }
          onOpen={() => this.props.dropdownOpen()}
          onClose={() => this.props.dropdownClose()}
        />
      </ColorSelectionWrapper>
    );
  }

  renderColorSwatch(color, index) {
    return (
      <ColorSwatch
        key={index}
        color={color}
        isBright={MaterialUtils.isColorBright(color)}
      />
    );
  }

  renderMaterialSelectionDropdown() {
    const NEW_MATERIAL_LABEL = 'New Material';
    const { materials, createMaterialPending, deleteMaterialPending } =
      this.props;
    const isLoading = createMaterialPending || deleteMaterialPending;
    const sortedMaterials = SortUtils.sortMaterialProfilesByType(materials);
    const currentMaterialId =
      this.props.project.materialIds[this.props.currentExtruderIndex];

    // preexisting material options
    const materialsList = _.map(sortedMaterials, (material) => {
      const isDefault = material.id === '0';
      return {
        label: isDefault ? 'Generic' : material.name,
        subLabel: this.getMaterialMeta(material),
        value: material.id,
        customOptionLabel: <Badge>{material.type}</Badge>,
      };
    });

    // add material option
    materialsList.push({
      label: '',
      value: NEW_MATERIAL_LABEL,
      customOptionLabel: (
        <NewMaterialOption>{NEW_MATERIAL_LABEL}</NewMaterialOption>
      ),
    });

    return (
      <MaterialSelectionWrapper isLoading={isLoading}>
        <AbstractDropdown
          descriptionIcon={Icons.fdm.material}
          options={materialsList}
          value={currentMaterialId}
          onChange={(value) =>
            value === NEW_MATERIAL_LABEL
              ? this.toggleAddMaterialModal()
              : this.handleChangeMaterial(value)
          }
          StyledOption={StyledMaterialOption}
          StyledSelect={StyledMaterialSelect}
          StyledOptionWrapper={StyledMaterialOptionWrapper}
          onOpen={() => this.props.dropdownOpen()}
          onClose={() => this.props.dropdownClose()}
        />

        {this.renderStyleOverrides(materials[currentMaterialId])}
      </MaterialSelectionWrapper>
    );
  }

  getMaterialMeta(material) {
    const overridesCount = _.reduce(
      materialStyleFieldNames,
      (sum, field) => {
        const [usageFlag] = field;
        if (material.style[usageFlag]) {
          return sum + 1;
        }
        return sum;
      },
      0
    );
    let subLabel = `${overridesCount} style ${FormatUtils.pluralize(
      'override',
      overridesCount
    )}`;
    if (overridesCount === 0) subLabel = null;
    return subLabel;
  }

  renderConfigureColorModal() {
    const { showConfigureColorModal } = this.state;
    if (!showConfigureColorModal) return null;

    const { project, currentExtruderIndex } = this.props;
    const [r, g, b, a] = project.colors[currentExtruderIndex];
    const currentColor = {
      r,
      g,
      b,
      a,
    };
    return (
      <Modal width='25rem'>
        <ConfigureColor
          extruder={currentExtruderIndex}
          currentColor={currentColor}
          onClickClose={() => this.toggleConfigureColorModal()}
          onUpdateProjectColor={(newColor) =>
            this.handleUpdateProjectColor(newColor)
          }
        />
      </Modal>
    );
  }

  renderAddMaterialModal() {
    const { showAddMaterialModal } = this.state;
    if (!showAddMaterialModal) return null;

    return (
      <ConfigureMaterial onClickClose={() => this.toggleAddMaterialModal()} />
    );
  }

  renderEditMaterialModal() {
    const { showEditMaterialModal, editMaterialId } = this.state;
    if (!showEditMaterialModal) return null;
    return (
      <ConfigureMaterial
        materialId={editMaterialId}
        onClickClose={() => this.hideEditMaterialModal()}
      />
    );
  }

  renderHeader() {
    const { currentExtruderIndex } = this.props;
    return (
      <Header>
        <Body1 noSpacing>Input {currentExtruderIndex + 1}</Body1>
        <ActionButton
          minimal
          icon={Icons.basic.x}
          onClick={this.props.closeMaterialTool}
        />
      </Header>
    );
  }

  renderStyleOverrides(material) {
    const overrideNames = [];
    const overrideItems = [];
    // get names of all overrides
    materialStyleFieldNames.forEach((style) => {
      if (material.style[style[0]] === true) overrideNames.push(style[1]);
    });
    if (overrideNames.length === 0) return null;
    // render the overrides
    MaterialConfigFields.overrideFields.forEach((field, index) => {
      if (overrideNames.includes(field.name)) {
        const overrideValue = material.style[field.name];
        const customFormattedUnits = overrideValue.units
          ? FormatUtils.formatUnits(overrideValue.units, true)
          : '';
        const defaultFormattedUnits = field.variants[0].units
          ? FormatUtils.formatUnits(field.variants[0].units, true)
          : '';
        overrideItems.push(
          <div key={index}>
            <Caption grey noSpacing>
              {field.label}
            </Caption>
            <Body1 noSpacing>
              {typeof overrideValue === 'object' && overrideValue
                ? `${overrideValue.value}${customFormattedUnits}`
                : `${overrideValue}${defaultFormattedUnits}`}
            </Body1>
          </div>
        );
      }
    });
    return <StyleOverrideContainer>{overrideItems}</StyleOverrideContainer>;
  }

  render() {
    const { currentExtruderIndex, currentView } = this.props;
    if (currentExtruderIndex < 0) return null;
    return (
      <Container currentView={currentView}>
        {this.renderHeader()}
        {this.renderConfigureColorModal()}
        {this.renderAddMaterialModal()}
        {this.renderEditMaterialModal()}
        {this.renderColorSelectionDropdown()}
        {this.renderMaterialSelectionDropdown()}
      </Container>
    );
  }
}

export default MaterialTool;

import React, { useRef } from 'react';
import {
  CardContainer,
  DefaultStyledLayoutContainer,
} from './abstractCard.styles';

const AbstractCard = ({
  onClick = () => {},
  onDoubleClick = () => {},
  selected = false,
  image = null,
  padding = '0.75rem',
  height = '8.25rem', // adjust card height
  shadow = 'd1', // adjust shadow - d0, d1, d2, d3
  StyledLayoutContainer = DefaultStyledLayoutContainer, // pass custom layout
  children,
}) => {
  const cardRef = useRef();
  return (
    <CardContainer
      selected={selected}
      image={image}
      padding={padding}
      height={height}
      shadow={shadow}
      ref={cardRef}
      onDoubleClick={onDoubleClick}
      onClick={onClick}>
      <StyledLayoutContainer>{children}</StyledLayoutContainer>
    </CardContainer>
  );
};

export default AbstractCard;

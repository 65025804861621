import types from './types';

const actions = {
  receiveLocalStorageUpdate: (storedAuthState) => ({
    type: types.RECEIVE_LOCAL_STORAGE_UPDATE,
    payload: storedAuthState,
  }),
  setAutoLogout: (enabled) => ({
    type: types.SET_AUTO_LOGOUT,
    payload: { enabled },
  }),
  signupRequest: (username, email, password, betaToken, newsletter) => ({
    type: types.SIGNUP_REQUEST,
    payload: {
      username,
      email,
      password,
      betaToken,
      newsletter,
    },
  }),
  signupSuccess: (token, refreshToken, user) => ({
    type: types.SIGNUP_SUCCESS,
    payload: { token, refreshToken, user },
  }),
  signupFailure: (error) => ({
    type: types.SIGNUP_FAILURE,
    payload: { error },
  }),
  loginRequest: (email, password) => ({
    type: types.LOGIN_REQUEST,
    payload: { email, password },
  }),
  loginSuccess: (user) => ({
    type: types.LOGIN_SUCCESS,
    payload: { user },
  }),
  loginFailure: (error) => ({
    type: types.LOGIN_FAILURE,
    payload: { error },
  }),
  logout: () => ({
    type: types.LOGOUT,
    payload: {},
  }),
  logoutAllSessionsRequest: () => ({
    type: types.LOGOUT_ALL_SESSIONS_REQUEST,
    payload: {},
  }),
  logoutAllSessionsSuccess: () => ({
    type: types.LOGOUT_ALL_SESSIONS_SUCCESS,
    payload: {},
  }),
  logoutAllSessionsFailure: (error) => ({
    type: types.LOGOUT_ALL_SESSIONS_FAILURE,
    payload: { error },
  }),
  refreshUserTokenRequest: (token) => ({
    type: types.REFRESH_USER_TOKEN_REQUEST,
    payload: { token },
  }),
  refreshUserTokenSuccess: (accessToken, refreshToken) => ({
    type: types.REFRESH_USER_TOKEN_SUCCESS,
    payload: { accessToken, refreshToken },
  }),
  refreshUserTokenFailure: (error) => ({
    type: types.REFRESH_USER_TOKEN_FAILURE,
    payload: { error },
  }),
  deleteAccountRequest: () => ({
    type: types.DELETE_ACCOUNT_REQUEST,
    payload: {},
  }),
  deleteAccountSuccess: () => ({
    type: types.DELETE_ACCOUNT_SUCCESS,
    payload: {},
  }),
  deleteAccountFailure: (error) => ({
    type: types.DELETE_ACCOUNT_FAILURE,
    payload: { error },
  }),
  editAccountRequest: (updatedInfo) => ({
    type: types.EDIT_ACCOUNT_REQUEST,
    payload: { updatedInfo },
  }),
  editAccountSuccess: (user) => ({
    type: types.EDIT_ACCOUNT_SUCCESS,
    payload: { user },
  }),
  editAccountFailure: (error) => ({
    type: types.EDIT_ACCOUNT_FAILURE,
    payload: { error },
  }),
  getAccountRequest: () => ({
    type: types.GET_ACCOUNT_REQUEST,
    payload: {},
  }),
  getAccountSuccess: (user) => ({
    type: types.GET_ACCOUNT_SUCCESS,
    payload: { user },
  }),
  getAccountFailure: (error) => ({
    type: types.GET_ACCOUNT_FAILURE,
    payload: { error },
  }),
  requestPasswordResetRequest: (email) => ({
    type: types.REQUEST_PASSWORD_RESET_REQUEST,
    payload: { email },
  }),
  requestPasswordResetSuccess: () => ({
    type: types.REQUEST_PASSWORD_RESET_SUCCESS,
    payload: {},
  }),
  requestPasswordResetFailure: (error) => ({
    type: types.REQUEST_PASSWORD_RESET_FAILURE,
    payload: { error },
  }),
  resetPasswordRequest: (email, resetCode, password) => ({
    type: types.RESET_PASSWORD_REQUEST,
    payload: { email, resetCode, password },
  }),
  resetPasswordSuccess: () => ({
    type: types.RESET_PASSWORD_SUCCESS,
    payload: {},
  }),
  resetPasswordFailure: (error) => ({
    type: types.RESET_PASSWORD_FAILURE,
    payload: { error },
  }),
  authorizeIntegrationRequest: (
    clientId,
    redirectUri,
    responseType,
    state,
    userAuthorization
  ) => ({
    type: types.AUTHORIZE_INTEGRATION_REQUEST,
    payload: {
      clientId,
      redirectUri,
      responseType,
      state,
      userAuthorization,
    },
  }),
  authorizeIntegrationSuccess: () => ({
    type: types.AUTHORIZE_INTEGRATION_SUCCESS,
    payload: {},
  }),
  authorizeIntegrationFailure: (error) => ({
    type: types.AUTHORIZE_INTEGRATION_FAILURE,
    payload: { error },
  }),
  linkIntegrationRequest: (integrationId) => ({
    type: types.LINK_INTEGRATION_REQUEST,
    payload: { integrationId },
  }),
  linkIntegrationSuccess: () => ({
    type: types.LINK_INTEGRATION_SUCCESS,
    payload: {},
  }),
  linkIntegrationFailure: (error) => ({
    type: types.LINK_INTEGRATION_FAILURE,
    payload: { error },
  }),
  unlinkIntegrationRequest: (integrationId) => ({
    type: types.UNLINK_INTEGRATION_REQUEST,
    payload: { integrationId },
  }),
  unlinkIntegrationSuccess: (integrationId) => ({
    type: types.UNLINK_INTEGRATION_SUCCESS,
    payload: { integrationId },
  }),
  unlinkIntegrationFailure: (error) => ({
    type: types.UNLINK_INTEGRATION_FAILURE,
    payload: { error },
  }),
  getIntegrationsRequest: () => ({
    type: types.GET_INTEGRATIONS_REQUEST,
    payload: {},
  }),
  getIntegrationsSuccess: (integrations) => ({
    type: types.GET_INTEGRATIONS_SUCCESS,
    payload: { integrations },
  }),
  getIntegrationsFailure: (error) => ({
    type: types.GET_INTEGRATIONS_FAILURE,
    payload: { error },
  }),
};

export default actions;

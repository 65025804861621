import React, { Component } from 'react';

import { AdvancedButton, Page } from '../../shared';
import Routes from '../../router/routes';

import { MenuContainer } from './styles';
import { MenuMeta } from './printerScript.metadata';
import { Icons } from '../../themes';

class PrinterScriptHome extends Component {
  componentDidMount() {
    document.title = 'PrinterScript • Canvas';
    this.props.updateNavStack([
      { text: 'PrinterScript', route: Routes.toPrinterScript() },
    ]);
  }

  renderContent() {
    return (
      <MenuContainer>
        {MenuMeta.map((meta, idx) => (
          <React.Fragment key={idx}>
            <AdvancedButton
              expand
              icon={Icons.basic.arrowRight}
              label={meta.title}
              description={meta.description}
              onClick={() => this.props.history.push(meta.route)}
            />
          </React.Fragment>
        ))}
      </MenuContainer>
    );
  }

  render() {
    return <Page>{this.renderContent()}</Page>;
  }
}

export default PrinterScriptHome;

import 'regenerator-runtime/runtime';
import { takeEvery, takeLatest, all, fork } from 'redux-saga/effects';

import { types } from '../../reducers/onboarding/onboarding';

// import sagas
import getOnboardingState from './getOnboardingState';
import startModal from './startModal';
import completeModal from './completeModal';
import startTask from './startTask';
import setTaskStepStatus from './setTaskStepStatus';
import modalTriggers from './modalTriggers';
import taskTriggers from './taskTriggers';

export default function* onboardingSaga() {
  // set up "trigger listener" sagas to run in the background
  // they will yield take() actions directly as needed
  yield fork(modalTriggers);
  yield fork(taskTriggers);
  yield all([
    takeLatest(types.GET_ONBOARDING_STATE_REQUEST, getOnboardingState),
    takeEvery(types.START_MODAL_REQUEST, startModal),
    takeEvery(types.COMPLETE_MODAL_REQUEST, completeModal),
    takeEvery(types.START_TASK_REQUEST, startTask),
    takeEvery(types.SET_TASK_STEP_STATUS_REQUEST, setTaskStepStatus),
  ]);
}

import styled from 'styled-components';
import { Breakpoints } from '../../../../../../../themes';

export const Container = styled.div`
  grid-column: 1 / -1;
  grid-row: 2 / 3;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(6, min-content);
  grid-gap: 1em;
  align-items: center;

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    padding: 0 0.75em;
    width: auto;
  }
`;

export const SaturationSection = styled.div`
  grid-column: 1 / -1;
  grid-row: 2 / 3;
  height: 10em;
  position: relative;
`;

export const SwatchSection = styled.div`
  grid-column: 1 / 3;
  grid-row: 3 / 5;
  height: 2.5em;
  width: 2.5em;
  justify-self: center;
  position: relative;
`;

export const Swatch = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.2em;
`;

export const HueSection = styled.div`
  grid-column: 3 / -1;
  grid-row: 3 / 4;
  height: 0.7em;
  position: relative;
`;

export const AlphaSection = styled.div`
  grid-column: 3 / -1;
  grid-row: 4 / 5;
  height: 0.7em;
  position: relative;
`;

import styled, { css } from 'styled-components';

import { Breakpoints, Shadows } from '../../../themes';

export const ToolboxContainer = styled.div`
  position: absolute;
  left: 1rem;
  top: 4.5rem;
  display: flex;
  flex-direction: column;
  z-index: 2;

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: none;
  }
`;

export const ToolboxContent = styled.div`
  justify-self: flex-end;
  width: 100%;
  margin-bottom: 0.5rem;
  background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  ${Shadows.d1};
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  min-width: 10rem;
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    padding: 0.5rem 0;
    margin-top: 0;
  }
`;

export const StampControlPanelWrapper = styled.div`
  ${Shadows.d1};
  width: 13rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
  position: absolute;
  z-index: 2;
  left: 1rem;
  top: 11.75rem;
  max-height: calc(100vh - 16.5rem);

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    top: 4.5rem;
  }

  @media (max-height: ${Breakpoints.extraSmallHeight}) {
    max-height: 45vh;
  }
`;

export const StampControlContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const StampList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
`;

export const StampItem = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 0 0.5rem;
  width: calc(100% - 1em);

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.grey1};
  }
`;

export const StampLabelWrapper = styled.div`
  padding-top: 0.5rem;
  margin-left: 0.25rem;
  margin-right: auto;
`;

export const PlaceholderWrapper = styled.div`
  text-align: center;
  padding: 1rem 1rem 0rem 1rem;
  p {
    white-space: normal;
  }
`;

export const StampControlMainButtonWrapper = styled.div`
  margin: 0.5rem auto;
  width: calc(100% - 1.5rem);
`;

const buttonsContainer = css`
  display: grid;
  justify-self: center;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  padding: 0.75rem;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    height: min-content;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: 1fr;
    grid-auto-flow: column;
  }

  @media (max-height: ${Breakpoints.extraSmallHeight}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    padding: 0.2r5em;
  }
`;

export const ControlButtonsContainer = styled.div`
  ${buttonsContainer}
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: flex;
    justify-content: flex-start;
  }
`;
export const AutoSegmentButtonWrapper = styled.div`
  ${buttonsContainer}
  padding-top: 0;
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: flex;
    justify-content: flex-start;
  }
`;

export const ControlButtonsMobileContainer = styled.div`
  ${buttonsContainer}
  display: none;

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: grid;
  }
`;

export const ExtruderButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.5rem;
  padding: 0 0.75rem 0.75rem 0.75rem;
  align-items: center;
`;

export const ViewButtonsContainer = styled.div`
  position: fixed;
  bottom: 1em;
  right: 1em;
  display: flex;
  z-index: 1;
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: none;
  }
`;

export const ViewButtonsMobileContainer = styled.div`
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    display: flex;
    z-index: 1;
  }
`;

export const ViewButtonWrapper = styled.div`
  margin-left: 0.5rem;
`;

const viewButtonMobileStyles = css`
  display: none;
  transition: all 0.2s;
  i {
    color: ${(props) => props.theme.colors.whiteDefault};
  }
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 0.5rem;
    color: ${(props) => props.theme.colors.whiteDefault};
  }
`;

export const CancelSaveButtonMobileWrapper = styled.div`
  ${viewButtonMobileStyles}
  margin-left: 0.5rem;
`;

export const BrushSizeWrapper = styled.div`
  margin: 0.5rem 1rem 0;
  grid-column: span 4;
  background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    grid-column: span 2;
    grid-row: 2 / 3 span 1;
  }
  @media (max-height: ${Breakpoints.extraSmallHeight}) {
    margin-top: -0.25rem;
    grid-column: span 1;
    grid-row: 3 / 4;
    padding-bottom: 1rem;
  }
`;

export const ModalButtonWrapper = styled.div`
  display: inline-block;
  margin-left: 0.5em;
`;

export const UndoRedoButtonsContainer = styled.div`
  position: absolute;
  top: 4.5rem;
  right: 1rem;
  display: flex;
  z-index: 1;
`;

export const UndoRedoButtonWrapper = styled.div`
  margin-left: 0.5rem;
`;

export const MobileToolbarLeftWrapper = styled.div`
  position: relative;
  margin-bottom: 0.5rem;
`;

export const ToolboxMobileContainer = styled.div`
  display: none;
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    min-height: 1rem; // to recognize we are on a small screen on resize
    position: absolute;
    top: 4.5rem;
    display: flex;
    width: min-content;
    left: 0;
    z-index: 2;
    flex-direction: column;
    margin-left: 1rem;
    align-items: flex-start;
  }
`;

export const SupportToolboxMobileContainer = styled(ToolboxMobileContainer)`
  display: none;
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    position: absolute;
    display: flex;
  }
`;

export const AutoSegmentMenuWrapper = styled.div`
  margin-top: ${(props) => (props.isSmallScreen ? '0' : '0.5rem')};
`;

export const IconWrapper = styled.div``;

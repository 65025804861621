import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import { handleGenericError } from '../common';
import { makeRequest } from './horizon';
import { actions } from '../../reducers/iot/iot';
import { InterfaceUtils } from '../../utils';

export default function* startPrint(action) {
  const { device, filePath } = action.payload;

  try {
    const deviceType = device.type;
    yield call(
      makeRequest,
      device.id,
      deviceType === 'canvas-hub' ? 'printer/start' : 'print/connected',
      {
        method: deviceType === 'canvas-hub' ? 'post' : 'job',
        query: {
          path: filePath,
        },
      },
      'startPrint'
    );
    yield put(actions.startPrintSuccess());

    // TODO: perhaps a better identifier thant device.id
    let message = `A print job has been started for ${filePath} in ${device.id}`;
    if (!filePath.startsWith('device/')) {
      // file is in external drive;
      // to start the print, device had to copy it onto its local storage
      message +=
        '. The file was first copied to the device’s local storage to start the print.';
    }

    InterfaceUtils.emitToast('info', message);
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

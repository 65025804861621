import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import { handleGenericError } from '../common';
import { makeRequest } from './horizon';
import { actions } from '../../reducers/iot/iot';

export default function* scanPorts(action) {
  const { device } = action.payload;

  try {
    const deviceType = device.type;

    const response = yield call(
      makeRequest,
      device.id,
      deviceType === 'canvas-hub' ? 'scan-com-ports' : 'com-ports',
      {
        method: 'get',
      },
      'scanPorts'
    );

    const availablePorts = response.body.ports;

    yield put(actions.scanPortsSuccess());
    yield put(actions.setDeviceAvailablePorts(device.id, availablePorts));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import API, { methods } from '../canvas-api';

import { handleGenericError, getAuthState } from '../common';
import { actions } from '../../reducers/onboarding/onboarding';

export default function* startModal(action) {
  try {
    const { identifier } = action.payload;
    const { userId } = yield select(getAuthState);
    const response = yield call(API, {
      method: methods.POST,
      path: `users/${userId}/onboarding/modal/${identifier}`,
      body: {
        started: true,
      },
    });
    if (response === null) return;
    yield put(actions.startModalSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { actions as authActions } from '../../../../reducers/auth/auth';
import { actions as iotActions } from '../../../../reducers/iot/iot';
import { actions as slicerActions } from '../../../../reducers/slicer/slicer';
import { DeviceUtils } from '../../../../utils';
import HeaderBarRight from './headerBarRight.jsx';

const mapStateToProps = (state) => {
  const devicesWithPrintJobs = DeviceUtils.getDevicesWithPrintJobs(
    state.iot.deviceStates,
    state.iot.pendingRequests,
    state.iot.devices
  );
  return {
    username: state.auth.username,
    currentProject: state.slicer.currentProject,
    projects: state.slicer.projects,
    notifications: state.notifications.notifications,
    sliceJobs: state.slicer.sliceJobs,
    devicesWithPrintJobs,
    dispatchSliceJobPending: state.slicer.status.dispatchSliceJobPending,
    cancelSliceJobPending: state.slicer.status.cancelSliceJobPending,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(authActions.logout()),
  pausePrint: (device) => dispatch(iotActions.pausePrintRequest(device)),
  resumePrint: (device) => dispatch(iotActions.resumePrintRequest(device)),
  cancelSlice: (projectId) =>
    dispatch(slicerActions.cancelSliceJobRequest(projectId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderBarRight)
);

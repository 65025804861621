import { connect } from 'react-redux';

import MaterialTool from './material.jsx';
import { actions } from '../../../../../reducers/slicer/slicer';

const mapStateToProps = (state) => ({
  materials: state.slicer.materials,
  printers: state.printers.printers,
  devices: state.iot.devices,
  createMaterialPending: state.slicer.status.createMaterialPending,
  deleteMaterialPending: state.slicer.status.deleteMaterialPending,
});

const mapDispatchToProps = (dispatch) => ({
  changeMaterial: (materialIds) =>
    dispatch(actions.changeMaterialRequest(materialIds)),
  updateProjectColors: (project, index, color, alpha, updateRLE = false) =>
    dispatch(
      actions.updateProjectColorsRequest(
        project,
        index,
        color,
        alpha,
        updateRLE
      )
    ),
  selectBrushColor: (color) => dispatch(actions.selectBrushColor(color)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaterialTool);

import styled, { css } from 'styled-components';

export const FileUploaderOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  z-index: 5000;
  width: 100vw;
  height: 100vh;
  display: none;
  background: transparent;
  ${(props) =>
    props.visible &&
    css`
      display: flex;
    `}
`;

export const UploaderContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
`;

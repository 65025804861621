import 'regenerator-runtime/runtime';
import { call, select, put, take } from 'redux-saga/effects';

import { getSlicerState, getThreeState, handleGenericError } from '../common';
import actions from '../../reducers/three/actions';
import {
  actions as SlicerActions,
  types as SlicerTypes,
} from '../../reducers/slicer/slicer';

import { SlicerUtils, TreeUtils, MatrixUtils } from '../../utils';
import { setRenderFlag } from './animationFrame';

export default function* addModelsToScene(action) {
  try {
    const { scene } = yield select(getThreeState);
    if (!scene) return;
    const { allModelsHidden } = yield select(getSlicerState);
    let { models } = action.payload;
    if (!models) {
      ({ models } = yield select(getSlicerState));
    }
    const { resetCamera, centerModels } = action.payload;
    TreeUtils.map(models, (model) => {
      if (model.mesh) {
        // eslint-disable-next-line no-param-reassign
        model.mesh.visible = model.visible;
        MatrixUtils.updateMeshTransforms(model.mesh, model.transforms);
        if (!allModelsHidden) {
          scene.add(model.mesh);
          SlicerUtils.applyStamps(model.mesh, model.stamps);
        }
      }
    });

    if (centerModels) {
      // center models (and tower) if required
      yield put(SlicerActions.centerProjectRequest());
      yield take(SlicerTypes.CENTER_PROJECT_SUCCESS);
    }

    if (resetCamera) {
      yield put(actions.resetCamera(false));
    }
    setRenderFlag();
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import styled from 'styled-components';

import { Breakpoints } from '../../../themes';

export const StyleCardsWrapper = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  justify-content: center;

  @media (max-width: ${Breakpoints.mediumWidth}) {
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  }
`;

export const ImageWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.grey2};
  border-radius: 0.25rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  display: grid;
  grid-column: 2;
  justify-content: end;
`;
export const TextContainer = styled.div`
  display: grid;
  grid-column: 1 / 2;
  grid-gap: 0.5rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const PlaceholderLabelWrapper = styled.div`
  display: grid;
  grid-column: span 4;
  height: 8rem;
  justify-content: center;
  align-items: center;
  max-width: 100%;
`;

export const AddStyleModalButtonWrapper = styled.div`
  margin-top: 0.5rem;
  grid-column: 2 / 3;
  justify-self: end;
`;

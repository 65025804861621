import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import { handleGenericError, getThreeState } from '../common';
import { disableResize } from './resize';
import { stopAnimation } from './animationFrame';
import { stopCameraTween } from './tweenCamera';
import { destroyRaycaster } from './raycast';
import actions from '../../reducers/three/actions';

export default function* destroyScene(action) {
  try {
    const state = yield select(getThreeState);
    const { controls, gizmo } = state;

    // stop any current tweening animation
    yield call(stopCameraTween);

    // remove our listeners
    disableResize();

    // cancel animation frame
    stopAnimation();

    // clean up the raycaster
    destroyRaycaster();

    // clean up event listeners for controls
    controls.dispose();

    // dispose gizmo
    gizmo.dispose();

    yield put(actions.destroySceneSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import _ from 'lodash';

import notificationsTypes from './types';
import notificationsActions from './actions';
import notificationsInitialState from './initialState';

export const types = notificationsTypes;
export const actions = notificationsActions;
export const initialState = notificationsInitialState;

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.RESET_NOTIFICATIONS_STORE: {
      return initialState;
    }

    case types.GET_NOTIFICATIONS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getNotificationsPending: true,
          getNotificationsSuccess: false,
        },
      };
    }
    case types.GET_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        notifications: payload.notifications,
        status: {
          ...state.status,
          getNotificationsPending: false,
          getNotificationsSuccess: true,
        },
      };
    }
    case types.GET_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getNotificationsPending: false,
          getNotificationsSuccess: false,
        },
      };
    }

    case types.UPDATE_NOTIFICATION_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          updateNotificationPending: true,
          updateNotificationSuccess: false,
        },
      };
    }
    case types.UPDATE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [payload.notification.id]: payload.notification,
        },
        status: {
          ...state.status,
          updateNotificationPending: false,
          updateNotificationSuccess: true,
        },
      };
    }
    case types.UPDATE_NOTIFICATION_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updateNotificationPending: false,
          updateNotificationSuccess: false,
        },
      };
    }

    case types.DELETE_NOTIFICATION_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteNotificationPending: true,
          deleteNotificationSuccess: false,
        },
      };
    }
    case types.DELETE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        notifications: _.omit(state.notifications, payload.notificationId),
        status: {
          ...state.status,
          deleteNotificationPending: false,
          deleteNotificationSuccess: true,
        },
      };
    }
    case types.DELETE_NOTIFICATION_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteNotificationPending: false,
          deleteNotificationSuccess: false,
        },
      };
    }

    case types.RECEIVE_NOTIFICATION: {
      const { notification } = payload;
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [notification.id]: notification,
        },
      };
    }

    default: {
      return state;
    }
  }
};

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import API, { methods } from '../canvas-api';
import { actions } from '../../reducers/auth/auth';
import { getAuthState, handleGenericError } from '../common';

export default function* logoutAllSessions(action) {
  try {
    const { userId } = yield select(getAuthState);
    const response = yield call(API, {
      method: methods.DELETE,
      path: `users/${userId}/sessions`,
    });
    if (response === null) return;

    yield put(actions.logoutAllSessionsSuccess());
    yield put(actions.logout());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import { connect } from 'react-redux';

import PaintView from './paintView.jsx';
import actions from '../../../reducers/slicer/actions';
import THREEactions from '../../../reducers/three/actions';

const mapStateToProps = (state) => {
  const project = state.slicer.projects[state.slicer.currentProject];
  return {
    project,
    brush: state.slicer.brush,
    models: state.slicer.models,
    transitionTower: state.slicer.transitionTower,
    materials: state.slicer.materials,
    isPainting: state.slicer.status.isPainting,
    camera: state.three.camera,
    canvas: state.three.canvas,
    renderer: state.three.renderer,
    viewOptions: state.auth.viewOptions,
    showSegmentBoundaries: state.slicer.status.showSegmentBoundaries,
    autoSegmentPending: state.slicer.status.autoSegmentPending,
    buildFacesPending: state.slicer.status.buildFacesPending,
    resetRegionsPending: state.slicer.status.resetRegionsPending,
    saveModelPaintDataPending: state.slicer.status.saveModelPaintDataPending,
    saveModelPaintDataSuccess: state.slicer.status.saveModelPaintDataSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showPrintBed: () => dispatch(THREEactions.showPrintBed()),
  hidePrintBed: () => dispatch(THREEactions.hidePrintBed()),
  addModelsToScene: () => dispatch(THREEactions.addModelsToScene()),
  removeModelsFromScene: () => dispatch(THREEactions.removeModelsFromScene()),
  addModelToScene: (model) => dispatch(THREEactions.addModelToScene(model)),
  removeModelFromScene: (model) =>
    dispatch(THREEactions.removeModelFromScene(model)),
  addTowerToScene: (tower) => dispatch(THREEactions.addTowerToScene(tower)),
  removeTowerFromScene: () => dispatch(THREEactions.removeTowerFromScene()),
  addBrush: (brush, dot) => dispatch(THREEactions.addBrush(brush, dot)),
  removeBrush: (brush, dot) => dispatch(THREEactions.removeBrush(brush, dot)),
  addBoundaries: (boundaries) => {
    dispatch(THREEactions.addBoundariesRequest(boundaries));
    dispatch(actions.toggleSegmentBoundaries(true));
  },
  removeBoundaries: () => {
    dispatch(THREEactions.removeBoundariesRequest());
    dispatch(actions.toggleSegmentBoundaries(false));
  },
  enableControls: () => dispatch(THREEactions.enableControls()),
  disableControls: () => dispatch(THREEactions.disableControls()),
  cancelPaintOperation: () => dispatch(actions.cancelPaintOperation()),
  registerBrush: (brush) => dispatch(actions.registerBrush(brush)),
  togglePainting: (onOrOff) => dispatch(actions.togglePainting(onOrOff)),
  resetRegions: () => dispatch(actions.resetRegionsRequest()),
  resetRegionsSuccess: () => dispatch(actions.resetRegionsSuccess()),
  buildFaces: (modelId) => dispatch(actions.buildFacesRequest(modelId)),
  buildFacesSuccess: () => dispatch(actions.buildFacesSuccess()),
  generateAtlas: (resolution, maxTextureSize, maxTextures) =>
    dispatch(
      actions.generateAtlasRequest(resolution, maxTextureSize, maxTextures)
    ),
  generateAtlasSuccess: () => dispatch(actions.generateAtlasSuccess()),
  composeColorsRLEAndSave: (inPlace = false) =>
    dispatch(actions.composeColorsRLERequest(inPlace)),
  composeColorsRLESuccess: (modelId, rle, inPlace) =>
    dispatch(actions.composeColorsRLESuccess(modelId, rle, inPlace)),
  restoreColorsWithRLE: (modelId, originalRle) =>
    dispatch(actions.restoreColorsWithRLE(modelId, originalRle)),
  savePaintData: (modelId, paintData) =>
    dispatch(actions.saveModelPaintDataRequest(modelId, paintData)),
  toggleModal: (openOrClosed, modalFor) =>
    dispatch(actions.toggleModal(openOrClosed, modalFor)),
  addNewColor: (newColor) => dispatch(actions.addNewColor(newColor)),
  selectBrushColor: (color) => dispatch(actions.selectBrushColor(color)),
  selectBrushType: (type) => dispatch(actions.selectBrushType(type)),
  selectBrushSize: (size) => dispatch(actions.selectBrushSize(size)),
  autoSegment: (angleTri, angleSegment, minSize) =>
    dispatch(actions.autoSegmentRequest(angleTri, angleSegment, minSize)),
  autoSegmentSuccess: () => dispatch(actions.autoSegmentSuccess()),
  toggleSegmentBoundaries: (onOrOff) =>
    dispatch(actions.toggleSegmentBoundaries(onOrOff)),
  selectMaterial: (material) => dispatch(actions.selectMaterial(material)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaintView);

import { connect } from 'react-redux';

import { actions as authActions } from '../../reducers/auth/auth';
import { actions as iotActions } from '../../reducers/iot/iot';

import DeviceManager from './deviceManager.jsx';

const mapStateToProps = (state) => ({
  sortBy: {
    mode: state.auth.preferences.devicesSortMode,
    direction: state.auth.preferences.devicesSortDirection,
  },
  devices: state.iot.devices,
  connected: state.iot.connected,
  deviceStates: state.iot.deviceStates,
  getDevicesPending: state.iot.status.getDevicesPending,
  getDevicesSuccess: state.iot.status.getDevicesSuccess,
  unlinkDevicesPending: state.iot.status.unlinkDevicesPending,
  unlinkDevicesSuccess: state.iot.status.unlinkDevicesSuccess,
  updateDevicePending: state.iot.status.updateDevicePending,
  updateDeviceSuccess: state.iot.status.updateDeviceSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  updatePreferences: (sortMode, sortDirection) =>
    dispatch(
      authActions.editAccountRequest({
        preferences: {
          devicesSortMode: sortMode,
          devicesSortDirection: sortDirection,
        },
      })
    ),
  unlinkDevices: (deviceIds) =>
    dispatch(iotActions.unlinkDevicesRequest(deviceIds)),
  updateDevice: (deviceId, deviceData) =>
    dispatch(iotActions.updateDeviceRequest(deviceId, deviceData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceManager);

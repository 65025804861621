import 'regenerator-runtime/runtime';
import { call, put, select, take } from 'redux-saga/effects';
import _ from 'lodash';

import {
  getPrinterState,
  getSlicerState,
  handleGenericError,
} from '../../common';
import { actions, types } from '../../../reducers/slicer/slicer';

import {
  MatrixUtils,
  TreeUtils,
  SlicerUtils,
  ProjectUtils,
} from '../../../utils';

import { getCenteredModelPositions, getCenteredTowerPosition } from './common';
import { setRenderFlag } from '../../three/animationFrame';

function* centerTower(centeredModels, oldBboxCenter, transitionTower) {
  const newPosition = getCenteredTowerPosition(
    centeredModels,
    oldBboxCenter,
    transitionTower
  );
  yield put(actions.updateTowerPositionOrSizeRequest(newPosition, 'position'));
  yield take(types.UPDATE_TOWER_POSITION_OR_SIZE_SUCCESS);
}

function* centerModels(models, printer, projectId) {
  const updatedTranslations = getCenteredModelPositions(models, printer);
  yield put(
    actions.updateModelTransformsRequest(updatedTranslations, projectId)
  );
  yield take(types.UPDATE_MODEL_TRANSFORMS_SUCCESS);
  const currentSlicerState = yield select(getSlicerState);
  return currentSlicerState.models;
}

export default function* centerProject(action) {
  const { currentProject, transitionTower, models, projects } = yield select(
    getSlicerState
  );
  const { printers } = yield select(getPrinterState);

  // if payload params are provided, use them (e.g changing project printer and style settings)
  // if not, use data in store (e.g centering a project on shared project import)
  const projectId = action.payload.projectId || currentProject;

  let printer;
  if (action.payload.printerId) {
    // find printer using provided id
    printer = printers[action.payload.printerId];
  } else {
    // find printer using project data
    printer = ProjectUtils.getProjectPrinter(projects[projectId], printers);
  }

  try {
    if (!_.isEmpty(models)) {
      // prepare models and their current bounding box for later calculations
      const flattenedModels = TreeUtils.flattenDeep(models);
      // update mesh transforms to get the proper bounding box
      _.forEach(flattenedModels, (model) => {
        if (model.mesh) {
          MatrixUtils.updateMeshTransforms(model.mesh, model.transforms);
        }
      });
      const oldBbox = SlicerUtils.getCombinedBoundingBox(flattenedModels);
      const oldBboxCenter = SlicerUtils.getBoundingBoxCenter(oldBbox);

      // center models
      const centeredModels = yield call(
        centerModels,
        flattenedModels,
        printer,
        projectId
      );

      if (transitionTower) {
        // center transition tower
        yield call(centerTower, centeredModels, oldBboxCenter, transitionTower);
      } else {
        setRenderFlag();
      }
    }
    yield put(actions.centerProjectSuccess());
    yield put(actions.updateProjectThumbnailRequest(projectId));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

export default function* updateStampTransforms(action) {
  try {
    const { modelsWithStamps } = action.payload;
    const { currentProject: projectId } = yield select(getSlicerState);
    const transformData = [];

    // update stamp position and direction
    modelsWithStamps.forEach((model) => {
      const modelData = {
        id: model.mesh.name,
        stamps: {},
      };

      let stampMesh;
      let stampCamera;
      model.mesh.children.forEach((stampGroup) => {
        [stampMesh, stampCamera] = stampGroup.children;
        // update view matrix
        stampMesh.material.uniforms.cameraViewMatrix.value =
          stampCamera.matrixWorldInverse;
        // update direction
        stampCamera.getWorldDirection(
          stampMesh.material.uniforms.cameraDirection.value
        );

        modelData.stamps[stampMesh.name] = {
          id: stampMesh.name,
          meta: {
            cameraViewMatrix:
              stampMesh.material.uniforms.cameraViewMatrix.value.toArray(),
            cameraDirection:
              stampMesh.material.uniforms.cameraDirection.value.toArray(),
          },
        };
      });
      transformData.push(modelData);
    });

    const response = yield call(API, {
      method: methods.POST,
      path: `projects/${projectId}/models/stamps`,
      body: {
        models: transformData,
      },
    });
    if (response === null) return;

    yield put(actions.updateStampTransformsSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

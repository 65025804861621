import 'regenerator-runtime/runtime';
import { put } from 'redux-saga/effects';

import { actions as slicerActions } from '../../reducers/slicer/slicer';
import { actions as printerActions } from '../../reducers/printers/printers';
import { actions as onboardingActions } from '../../reducers/onboarding/onboarding';
import { actions as notificationsActions } from '../../reducers/notifications/notifications';
import { actions as iotActions } from '../../reducers/iot/iot';

export default function* logout() {
  yield put(printerActions.resetPrinterStore());
  yield put(slicerActions.resetSlicerStore());
  yield put(onboardingActions.resetOnboardingStore());
  yield put(notificationsActions.resetNotificationsStore());
  yield put(iotActions.resetIoTStore());
  yield put(iotActions.closeConnection());
}

import React from 'react';

const printTemperature = (
  <>
    Specify the temperature of nozzle while printing. Most materials will
    provide a recommended temperature.
  </>
);

const firstLayerPrintTemperature = (
  <>
    Specify the temperature of nozzle while printing the first layer. A higher
    temperature for the first layer can help some materials stick better to the
    print bed.
  </>
);

const bedTemperature = (
  <>
    Specify the temperature of the print bed. Most materials will provide a
    recommended temperature. A heated print bed can help some materials stick
    better and prevent warping or peeling later in the print.
  </>
);

const useFan = (
  <>
    Enable or disable the part cooling fan. The part cooling fan helps to cool
    plastic after it has been extruded from the nozzle.
  </>
);

const enableFanAtLayer = (
  <>
    Specify which layer to enable the part cooling fan. Turning the part cooling
    fan off for the first or first couple layers can help plastic stick better
    to the print bed.
  </>
);

const minLayerTime = (
  <>
    Specify the minimum amount of time that a layer should take to print. Print
    speeds will be lowered for layers that otherwise would take less time to
    print. Minimum layer time ensures that the layer has cooled enough before
    printing on top of it.
  </>
);

const fanSpeed = (
  <>
    If your printer supports it, set the fan speed while printing everything
    except perimeters and gap fill.
  </>
);

const perimeterFanSpeed = (
  <>
    <>
      If your printer supports it, set the fan speed while printing perimeters
      and gap fill.
    </>
    <>Only available when using KISSlicer.</>
  </>
);

const maxBridgingSpeed = (
  <>
    The maximum speed to use when printing bridges or unsupported areas. If a
    material also specifies this setting, the lower of the two values will be
    used for that material.
  </>
);

export default {
  printTemperature,
  firstLayerPrintTemperature,
  bedTemperature,
  useFan,
  enableFanAtLayer,
  minLayerTime,
  fanSpeed,
  perimeterFanSpeed,
  maxBridgingSpeed,
};

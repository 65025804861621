import { fieldTypes, fieldSteps, slicers } from '../../../../../constants';
import tooltips from './tooltips.jsx';

const { SPEED_STEP } = fieldSteps;
const { KISSlicer } = slicers;

const Metadata = {
  temperature: {
    label: 'Temperature',
    tags: [],
    fields: [
      {
        name: 'printTemperature',
        label: 'Temperature',
        tags: [
          'hot end temperature',
          'nozzle temperature',
          'extruder temperature',
        ],
        tooltip: tooltips.printTemperature,
        variants: [
          {
            units: 'C',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: 1,
          },
        ],
      },
      {
        name: 'firstLayerPrintTemperature',
        label: 'First layer temperature',
        tags: [],
        tooltip: tooltips.firstLayerPrintTemperature,
        variants: [
          {
            units: 'C',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: 1,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            type: fieldTypes.auto,
            toBaseUnits: (value, style) => style.printTemperature,
            fromBaseUnits: () => 'auto',
          },
        ],
      },
      {
        name: 'bedTemperature',
        label: 'Bed temperature',
        tags: [
          'print bed temperature',
          'heated bed temperature',
          'build plate temperature',
          'heated build plate temperature',
        ],
        tooltip: tooltips.bedTemperature,
        variants: [
          {
            units: 'C',
            type: fieldTypes.number,
            min: 0,
            step: 1,
          },
        ],
      },
    ],
  },
  minLayerTime: {
    label: 'Minimum layer time',
    tags: [],
    fields: [
      {
        name: 'minLayerTime',
        label: 'Minimum layer time',
        tooltip: tooltips.minLayerTime,
        tags: [],
        variants: [
          {
            units: 's',
            type: fieldTypes.number,
            min: 0,
            step: 1,
          },
        ],
      },
    ],
  },
  cooling: {
    name: 'useFan',
    label: 'Cooling fan',
    tags: [],
    tooltip: tooltips.useFan,
    isChecked: (style) => style.useFan,
    updateFieldsOnToggle: (checked, updateField) => {
      updateField('useFan', checked);
    },
    fields: [
      {
        name: 'enableFanAtLayer',
        label: 'Start fan at layer',
        tags: [],
        tooltip: tooltips.enableFanAtLayer,
        disabled: (style) => !style.useFan,
        variants: [
          {
            type: fieldTypes.number,
            min: 0,
            step: 1,
          },
        ],
      },
      {
        name: 'fanSpeed',
        label: 'General fan speed',
        tags: ['pwm'],
        tooltip: tooltips.fanSpeed,
        disabled: (style) => !style.useFan,
        variants: [
          {
            units: '%',
            type: fieldTypes.number,
            min: 0,
            max: 100,
            step: 1,
          },
        ],
      },
      {
        name: 'perimeterFanSpeed',
        label: 'Perimeter fan speed',
        tags: ['pwm'],
        tooltip: tooltips.perimeterFanSpeed,
        slicers: [KISSlicer],
        disabled: (style, slicer) =>
          !style.useFan || (slicer && slicer !== KISSlicer),
        variants: [
          {
            units: '%',
            type: fieldTypes.number,
            min: 0,
            max: 100,
            step: 1,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            type: fieldTypes.auto,
            toBaseUnits: (value, style) => style.fanSpeed,
            fromBaseUnits: () => 'auto',
          },
        ],
      },
    ],
  },
  bridging: {
    label: 'Bridging',
    tags: ['bridge'],
    fields: [
      {
        name: 'maxBridgingSpeed',
        label: 'Maximum bridging speed',
        tooltip: tooltips.maxBridgingSpeed,
        tags: [],
        variants: [
          {
            units: 'mm/s',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: SPEED_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            units: '%',
            optionLabel: '% of solid layer speed',
            tags: [],
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: 1,
            toBaseUnits: (value, style) =>
              Math.round((value * style.solidLayerSpeed) / 10) / 10,
            fromBaseUnits: (value, style) =>
              Math.round((value / style.solidLayerSpeed) * 100),
          },
          {
            type: fieldTypes.auto,
            toBaseUnits: (value, style) =>
              Math.round((style.solidLayerSpeed / 2) * 10) / 10,
            fromBaseUnits: () => 'auto',
          },
        ],
      },
    ],
  },
};

export default Metadata;

import styled from 'styled-components';

import { Breakpoints, Shadows } from '../../../themes';

export const GridViewContainer = styled.div`
  height: 100%;
`;

export const GridViewContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const GridViewInfoContainer = styled.div`
  flex-grow: 1;
  min-width: 0;

  height: calc(100% - 2.5rem);
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
`;

export const GridViewThumbnailContainer = styled.div`
  flex-shrink: 0;
  width: 7.875rem;
  height: calc(100% - 1.5rem);
  padding: 0.75rem;
`;

export const GridViewNamesContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  text-align: flex-start;
  justify-content: center;
  gap: 0.375rem;
`;

export const ListViewContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media (max-width: ${Breakpoints.smallWidth}) {
    max-width: calc(100vw - 4rem);
  }
`;
export const ProjectCardNamesList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.125rem;
  padding-left: 1rem;

  @media (max-width: ${Breakpoints.smallWidth}) {
    width: calc(100% - 1rem - 5rem);
  }
`;

export const ProjectCardDatesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  ${(props) => props.isShared && 'margin-right: 2.5rem;'};
  @media (max-width: ${Breakpoints.largeWidth}) {
    flex-grow: 1;
  }
  @media (max-width: ${Breakpoints.smallWidth}) {
    display: none;
  }
`;

export const DraggableCardGrid = styled.div`
  position: relative;
  height: 9.375rem;
  background: ${(props) => {
    if (props.hovered && !props.selected) {
      if (props.canDrop) {
        return props.theme.colors.greenDefault;
      }
      return props.theme.colors.redDefault;
    }
    return props.theme.colors.elevatedBackgroundPrimary;
  }};
  ${(props) => (props.selected ? Shadows.d0 : Shadows.d1)};
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 0.25rem;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.075s;
  border-color: ${(props) =>
    props.selected ? props.theme.colors.greenDefault : 'transparent'};
  opacity: ${(props) => (props.opacity ? props.opacity : 1)};
  &:hover {
    border-color: ${(props) =>
      props.selected
        ? props.theme.colors.greenDefault
        : props.theme.colors.greenLight};
  }
`;

export const DraggableCardList = styled.div`
  text-align: left;
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
  min-height: 4rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => {
    if (props.hovered && !props.selected) {
      if (props.canDrop) {
        return props.theme.colors.greenDefault;
      }
      return props.theme.colors.redDefault;
    }
    return props.theme.colors.elevatedBackgroundPrimary;
  }};
  ${(props) => (props.selected ? Shadows.d0 : Shadows.d1)};
  border: 1px solid transparent;
  border-radius: 0.25rem;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.075s;
  border-color: ${(props) =>
    props.selected ? props.theme.colors.greenDefault : 'transparent'};
  box-sizing: border-box;
  opacity: ${(props) => (props.opacity ? props.opacity : 1)};

  &:hover {
    border-color: ${(props) =>
      props.selected
        ? props.theme.colors.greenDefault
        : props.theme.colors.greenLight};
  }
`;

export const ThumbnailContainerGrid = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.grey2};
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

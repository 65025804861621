import React, { Component } from 'react';
import _ from 'lodash';

import { FormatUtils, InterfaceUtils, ProjectUtils } from '../../utils';
import { ProjectSortMode } from '../../utils/sort/sort';
import ImportProject from '../import/importProject/importProject.container';
import { ProjectNotFoundDescription } from '../import/importProject/metadata';
import NotFound from '../notFound/notFound.jsx';

import {
  Button,
  CardList,
  ShareProjectModal,
  ManagerView,
  NewProjectButton,
  RenameModal,
} from '../../shared';

import {
  ButtonsContainer,
  ButtonWrapper,
} from '../../shared/managerView/managerView.styles';

import Routes from '../../router/routes';
import { Icons } from '../../themes';

import DeleteModal from './modals/delete.jsx';
import CreateFolderModal from './modals/createFolder.jsx';
import MoveToFolderModal from './modals/moveToFolder.jsx';
import ProjectCard from './projectCard/projectCard.jsx';
import FolderCard from './folderCard/folderCard.jsx';

const sortOptions = [
  { label: 'Name', value: ProjectSortMode.NAME },
  { label: 'Printer', value: ProjectSortMode.PRINTER },
  { label: 'Date created', value: ProjectSortMode.DATE_CREATED },
  { label: 'Date modified', value: ProjectSortMode.DATE_MODIFIED },
];

class ProjectManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterBy: '',
      // split out when props.projectsAndFolders updates
      allProjects: {},
      allFolders: {},
      // currently-displayed projects and folders only
      displayFolders: [], // TODO replace with just IDs
      displayProjects: [], // TODO replace with just IDs
      selectedProjectIds: [],
      doingMultipleDeletion: false,
      currentFolderId: null,
      showMoveToFolderModal: false,
      showDeleteProjectModal: false,
      showRenameProjectModal: false,
      showFolderCreateModal: false,
      showShareModal: false,
      showImportSharedProjectModal: false,
      shareProjectNotFound: false,
    };
    this.onWindowClick = this.onWindowClick.bind(this);
    this.onWindowKeydown = this.onWindowKeydown.bind(this);
    this.handleOpenProject = this.handleOpenProject.bind(this);
    this.handleOpenFolder = this.handleOpenFolder.bind(this);
    this.handleSelectCardMouseDown = this.handleSelectCardMouseDown.bind(this);
    this.handleSelectCardMouseUp = this.handleSelectCardMouseUp.bind(this);
    this.changeParentId = this.changeParentId.bind(this);
  }

  componentDidMount() {
    this.props.updateNavStack([
      { text: 'My Projects', route: Routes.toManageProjects(), id: null },
    ]);
    window.addEventListener('click', this.onWindowClick);
    window.addEventListener('keydown', this.onWindowKeydown);
    if (this.props.loadProjectsSuccess) {
      this.changeFolder();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.createProjectSuccess && this.props.createProjectSuccess) {
      // project created -- don't redirect (NewProjectButton handles this)
      // but return here to prevent ProjectManager state updates from
      // folder navigation detection, as we are about to be unmounted
      return;
    }

    // importing shared project
    const sharedProjectId = ProjectUtils.getSharedProjectId(
      this.props.history.location.pathname
    );
    if (!sharedProjectId && this.state.shareProjectNotFound) {
      // when navigating notfound page is only shown on shared project links
      this.setState({ shareProjectNotFound: false });
    }
    if (sharedProjectId && !this.state.showImportSharedProjectModal) {
      this.setState({ showImportSharedProjectModal: true });
    } else if (!sharedProjectId && this.state.showImportSharedProjectModal) {
      this.setState({ showImportSharedProjectModal: false });
    }

    if (
      prevProps.loadProjectsPending &&
      !this.props.loadProjectsPending &&
      this.props.loadProjectsSuccess &&
      this.state.currentFolderId !== null &&
      sharedProjectId === null
    ) {
      // first page load from within a folder
      this.changeFolder();
      return;
    }

    const folderId = ProjectUtils.getCurrentFolderPathId(
      this.props.history.location.pathname
    );

    if (
      !_.isEmpty(this.props.projectsAndFolders) &&
      this.state.currentFolderId !== folderId &&
      sharedProjectId === null
    ) {
      // user has navigated to another folder
      this.changeFolder();
      return;
    }

    if (
      this.props.projectsAndFolders !== prevProps.projectsAndFolders ||
      this.props.printers !== prevProps.printers ||
      !_.isEqual(this.props.sortBy, prevProps.sortBy)
    ) {
      const currentProjectCount = _.size(this.props.projectsAndFolders);
      const prevProjectCount = _.size(prevProps.projectsAndFolders);
      const selectNew =
        currentProjectCount > prevProjectCount && prevProjectCount > 1;
      this.updateProjectsList(selectNew);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
    window.removeEventListener('keydown', this.onWindowKeydown);
  }

  onWindowKeydown(event) {
    if (event.key === 'Escape') {
      this.deselect();
    } else if (
      event.key === 'Delete' &&
      this.state.selectedProjectIds.length >= 1 &&
      !this.state.showRenameProjectModal
    ) {
      // Hit DELETE while selecting card(s)
      this.showDeleteModal(event);
    }
  }

  onWindowClick() {
    if (
      this.state.showDeleteProjectModal ||
      this.state.showRenameProjectModal ||
      this.state.showMoveToFolderModal ||
      this.state.showFolderCreateModal ||
      this.state.showShareModal
    ) {
      return;
    }
    this.deselect();
  }

  changeFolder() {
    // user has navigated to another folder - update the nav stack
    const folderId = ProjectUtils.getCurrentFolderPathId(
      this.props.history.location.pathname
    );
    const folders = _.pickBy(
      this.props.projectsAndFolders,
      (project) => project.directory
    );
    const navStack = ProjectUtils.buildNavStack(folders, folderId);
    this.setState(
      {
        currentFolderId: folderId,
        filterBy: '',
      },
      () => {
        this.props.updateNavStack(navStack);
        this.updateProjectsList();
      }
    );
  }

  deselect() {
    const {
      showDeleteProjectModal,
      showRenameProjectModal,
      showMoveToFolderModal,
      showFolderCreateModal,
      showShareModal,
    } = this.state;
    if (
      showDeleteProjectModal ||
      showRenameProjectModal ||
      showFolderCreateModal ||
      showMoveToFolderModal ||
      showShareModal
    ) {
      this.setState({
        showDeleteProjectModal: false,
        showRenameProjectModal: false,
        showFolderCreateModal: false,
        showMoveToFolderModal: false,
        showShareModal: false,
      });
    } else {
      this.setState({ selectedProjectIds: [] });
    }
  }

  updateProjectsList(selectNew = false) {
    const { projectsAndFolders, printers, sortBy } = this.props;

    const allProjects = ProjectUtils.getProjects(projectsAndFolders);
    const allFolders = ProjectUtils.getFolders(projectsAndFolders);

    const currentNestedFolderIds = ProjectUtils.getNestedFolders(
      this.state.currentFolderId,
      allFolders,
      projectsAndFolders
    );

    const { filteredProjects, filteredFolders } = this.filterProjects(
      allProjects,
      allFolders,
      currentNestedFolderIds
    );
    const sortedProjects = ProjectUtils.sortProjects(
      filteredProjects,
      printers,
      sortBy
    );
    const sortedFolders = ProjectUtils.sortFolders(filteredFolders);

    const allDisplayContent = [...sortedProjects, ...sortedFolders];

    const newState = {
      allProjects,
      allFolders,
      displayProjects: sortedProjects,
      displayFolders: sortedFolders,
      selectedProjectIds: [],
      doingMultipleDeletion: false,
    };

    if (selectNew) {
      // select only projects that did not previously exist
      const oldProjects = {
        ...this.state.allProjects,
        ...this.state.allFolders,
      };
      const oldProjectIds = _.map(oldProjects, (project) => project.id);
      _.forEach(allDisplayContent, (project) => {
        if (!_.includes(oldProjectIds, project.id)) {
          newState.selectedProjectIds.push(project.id);
        }
      });
    } else {
      // select all projects that were previously selected (and still exist)
      _.forEach(this.state.selectedProjectIds, (id) => {
        if (_.has(this.props.projectsAndFolders, id)) {
          newState.selectedProjectIds.push(id);
        }
      });
    }

    // refresh projects
    this.setState(newState);
  }

  changeParentId(selectedIds, receivingFolderId, doNavigate = false) {
    const { projectsAndFolders } = this.props;

    const validIds = _.filter(selectedIds, (id) => {
      if (receivingFolderId) {
        const receivingFolderIsAlreadyParent =
          projectsAndFolders[id].parentId === receivingFolderId;
        return !receivingFolderIsAlreadyParent;
      }
      return projectsAndFolders[id].parentId;
    });
    if (!_.isEmpty(validIds)) {
      const receivingId = receivingFolderId || null;
      this.props.updateProjectsParent(validIds, receivingId);
      if (doNavigate) {
        this.handleOpenFolder(receivingId);
      }
    }
  }

  handleOpenProject(project) {
    this.props.history.push(Routes.toSlicer(project.id));
  }

  toggleListView(e) {
    e.stopPropagation();
    this.props.updatePreferences(!this.props.listView, this.props.sortBy);
  }

  filterProjects(allProjects, allFolders, nestedFolderIds) {
    const { printers } = this.props;
    const { currentFolderId, filterBy } = this.state;

    // search mode takes priority
    // - if a text filter is applied, include all matching descendants
    // - otherwise, include direct children only

    const filter = filterBy.toLowerCase();
    const filteredProjects = _.filter(allProjects, (project) => {
      if (filterBy !== '') {
        // searching for text -- display all matches in current folder or deeper
        const bothTopLevel = !currentFolderId && !project.parentId;
        const isDescendant = _.includes(nestedFolderIds, project.parentId);
        if (!(bothTopLevel || isDescendant)) {
          // project is not within the current folder or deeper
          return false;
        }
        const projectName = project.name.toLowerCase();
        const projectPrinter = printers[project.printerId];
        const printerName = projectPrinter.machineSettings.name.toLowerCase();
        const dateCreatedText = FormatUtils.isoToDateString(
          project.created
        ).toLowerCase();

        if (projectName.includes(filter)) return true;
        if (printerName.includes(filter)) return true;
        if (dateCreatedText.includes(filter)) return true;
      } else {
        // display all projects in the current folder
        return (
          project.parentId === currentFolderId ||
          (!currentFolderId && !project.parentId)
        );
      }
      return false;
    });

    const filteredFolders = _.filter(allFolders, (folder) => {
      if (filterBy !== '') {
        // searching for text -- display all matches in current folder or deeper
        const bothTopLevel = !currentFolderId && !folder.parentId;
        const isDescendant = _.includes(nestedFolderIds, folder.parentId);
        if (!(bothTopLevel || isDescendant)) {
          // folder is not within the current folder or deeper
          return false;
        }
        const folderName = folder.name.toLowerCase();
        if (folderName.includes(filter)) return true;
      } else {
        // display all folders in the current folder
        return (
          folder.parentId === currentFolderId ||
          (!currentFolderId && !folder.parentId)
        );
      }
      return false;
    });
    return { filteredProjects, filteredFolders };
  }

  onFilterChange(filterBy) {
    this.setState(
      {
        filterBy,
      },
      () => {
        this.updateProjectsList();
      }
    );
  }

  onSortChange(mode, direction) {
    const sortBy = { mode, direction };
    this.props.updatePreferences(this.props.listView, sortBy);
  }

  handleSelectCardMouseDown(e, card) {
    e.stopPropagation();
    const { displayProjects, displayFolders, selectedProjectIds } = this.state;

    // concatenate the two display lists to allow selection across them
    const allItems = [...displayFolders, ...displayProjects];

    const updatedSelectedItems = InterfaceUtils.handleSelectableItemClick(
      e,
      allItems,
      selectedProjectIds,
      card.id
    );
    this.setState({ selectedProjectIds: updatedSelectedItems });
  }

  handleSelectCardMouseUp(e, card) {
    const { selectedProjectIds } = this.state;
    if (
      !e.metaKey &&
      !e.ctrlKey &&
      !e.shiftKey &&
      _.includes(selectedProjectIds, card.id)
    ) {
      this.setState({ selectedProjectIds: [card.id] });
    }
  }

  isDeletingMultipleProjects(projectIds) {
    const { projectsAndFolders } = this.props;
    const deletingMultipleProjects = projectIds.length > 1;
    const folderHasChildren =
      _.some(projectIds, (id) => projectsAndFolders[id].directory) &&
      _.some(projectsAndFolders, (project) =>
        _.includes(projectIds, project.parentId)
      );
    if (deletingMultipleProjects || folderHasChildren) {
      return 'Deleting Projects';
    }
    return 'Deleting Project';
  }

  handleDeleteProject() {
    const { selectedProjectIds } = this.state;
    const selectedProjects = _.map(
      selectedProjectIds,
      (id) => this.props.projectsAndFolders[id]
    );
    this.setState(
      {
        doingMultipleDeletion:
          this.isDeletingMultipleProjects(selectedProjectIds),
        selectedProjectIds: [],
      },
      () => {
        this.props.deleteProjects(selectedProjects);
        this.hideDeleteModal();
      }
    );
  }

  handleRenameProject(renameValue) {
    const [projectId] = this.state.selectedProjectIds;
    const project = this.props.projectsAndFolders[projectId];
    if (renameValue.trim() !== project.name) {
      this.props.updateProjectName(projectId, renameValue);
    }
    this.hideRenameModal();
  }

  handleDuplicateProject() {
    const [projectId] = this.state.selectedProjectIds;
    this.props.duplicateProject(projectId);
  }

  handleOpenFolder(folderId = null) {
    this.setState(
      {
        selectedProjectIds: [],
      },
      () => {
        const route = folderId
          ? Routes.toFolder(folderId)
          : Routes.toManageProjects();
        this.props.history.push(route);
      }
    );
  }

  handleCreateNewFolder(folderName) {
    const { currentFolderId } = this.state;
    this.props.createFolder(folderName.trim(), currentFolderId);
    this.hideFolderCreateModal();
  }

  handleMoveFolder(receivingFolderId) {
    const { allFolders, selectedProjectIds } = this.state;
    const parentIdsTree = ProjectUtils.getAllParents(
      receivingFolderId,
      allFolders
    );
    const parentsAreNotSelected = !_.some(selectedProjectIds, (id) =>
      parentIdsTree.includes(id)
    );
    const receivingFolderIsNotSelected = !_.includes(
      selectedProjectIds,
      receivingFolderId
    );
    const folderCanReceive =
      parentsAreNotSelected && receivingFolderIsNotSelected;

    if (folderCanReceive) {
      this.changeParentId(selectedProjectIds, receivingFolderId, true);
    }
    this.hideMoveToFolderModal();
  }

  showRenameModal() {
    this.setState({ showRenameProjectModal: true });
  }

  hideRenameModal() {
    this.setState({ showRenameProjectModal: false });
  }

  showDeleteModal() {
    this.setState({ showDeleteProjectModal: true });
  }

  hideDeleteModal() {
    this.setState({ showDeleteProjectModal: false });
  }

  showFolderCreateModal(e) {
    e.stopPropagation();
    this.setState({ showFolderCreateModal: true });
  }

  hideFolderCreateModal() {
    this.setState({ showFolderCreateModal: false });
  }

  showMoveToFolderModal() {
    this.setState({ showMoveToFolderModal: true });
  }

  hideMoveToFolderModal() {
    this.setState({ showMoveToFolderModal: false });
  }

  showShareModal() {
    this.setState({ showShareModal: true });
  }

  hideShareModal(e) {
    e.stopPropagation();
    this.setState({ showShareModal: false });
  }

  renderShareModal() {
    const { showShareModal, selectedProjectIds } = this.state;
    const [projectId] = selectedProjectIds;
    if (!showShareModal) return null;
    return (
      <ShareProjectModal
        onMarginClick={(e) => this.hideShareModal(e)}
        selectedProject={this.props.projectsAndFolders[projectId]}
      />
    );
  }

  renderMoveToFolderModal() {
    const { projectsAndFolders, history } = this.props;
    const { allFolders, showMoveToFolderModal, selectedProjectIds } =
      this.state;
    if (!showMoveToFolderModal) return null;
    return (
      <MoveToFolderModal
        folders={allFolders}
        history={history}
        onCancel={() => this.hideMoveToFolderModal()}
        onConfirm={(receivingFolderId) =>
          this.handleMoveFolder(receivingFolderId)
        }
        projectsAndFolders={projectsAndFolders}
        selectedProjectIds={selectedProjectIds}
      />
    );
  }

  renderCreateFolderModal() {
    const { showFolderCreateModal } = this.state;
    if (!showFolderCreateModal) return null;
    return (
      <CreateFolderModal
        onMarginClick={() => this.hideFolderCreateModal()}
        onCancel={() => this.hideFolderCreateModal()}
        onConfirm={(folderName) => this.handleCreateNewFolder(folderName)}
      />
    );
  }

  renderRenameModal() {
    const { showRenameProjectModal, selectedProjectIds } = this.state;
    if (!showRenameProjectModal) return null;
    const [projectId] = selectedProjectIds;
    const selectedProject = this.props.projectsAndFolders[projectId];
    return (
      <RenameModal
        initialValue={selectedProject.name}
        title={'Rename Project'}
        onMarginClick={() => this.hideRenameModal()}
        onCancel={() => this.hideRenameModal()}
        onSave={(renameValue) => this.handleRenameProject(renameValue)}
      />
    );
  }

  renderDeleteModal() {
    const { selectedProjectIds, showDeleteProjectModal } = this.state;
    if (!showDeleteProjectModal) return null;
    const selectedProjects = _.map(
      selectedProjectIds,
      (id) => this.props.projectsAndFolders[id]
    );
    return (
      <DeleteModal
        onCancel={() => this.hideDeleteModal()}
        onConfirm={() => this.handleDeleteProject()}
        selectedProjects={selectedProjects}
      />
    );
  }

  getActionButtonsLeftMeta() {
    const { listView } = this.props;
    return [
      {
        icon: listView ? Icons.basic.grid : Icons.basic.list,
        title: `Switch to ${listView ? 'grid' : 'list'} view`,
        disabled: this.areActionsDisabled(),
        onClick: (e) => this.toggleListView(e),
      },
    ];
  }

  getActionButtonsRightMeta() {
    const { projectsAndFolders } = this.props;
    const { allFolders, selectedProjectIds } = this.state;
    const selectedProjects = _.map(
      selectedProjectIds,
      (id) => projectsAndFolders[id]
    );
    const isFolder = _.some(selectedProjects, (project) => project.directory);
    return [
      {
        icon: Icons.basic.share,
        title: FormatUtils.pluralize(
          isFolder ? 'Share folder' : 'Share project',
          selectedProjectIds.length,
          true
        ),
        disabled: selectedProjectIds.length !== 1 || isFolder,
        onClick: () => this.showShareModal(),
      },
      {
        icon: Icons.basic.pencil,
        title: FormatUtils.pluralize(
          isFolder ? 'Rename folder' : 'Rename project',
          selectedProjectIds.length,
          true
        ),
        disabled: selectedProjectIds.length !== 1,
        onClick: () => this.showRenameModal(),
      },
      {
        icon: Icons.basic.copy,
        title: FormatUtils.pluralize(
          isFolder ? 'Duplicate folder' : 'Duplicate project',
          selectedProjectIds.length,
          true
        ),
        disabled: selectedProjectIds.length !== 1 || isFolder,
        onClick: () => this.handleDuplicateProject(),
      },
      {
        icon: Icons.basic.folderMove,
        title: FormatUtils.pluralize(
          isFolder ? 'Move folder' : 'Move project',
          selectedProjectIds.length,
          true
        ),
        disabled: _.isEmpty(selectedProjectIds) || _.isEmpty(allFolders),
        onClick: () => this.showMoveToFolderModal(),
      },
      {
        icon: Icons.basic.trash,
        title: FormatUtils.pluralize(
          isFolder ? 'Delete folder' : 'Delete project',
          selectedProjectIds.length,
          true
        ),
        disabled: _.isEmpty(selectedProjectIds),
        onClick: () => this.showDeleteModal(),
      },
    ];
  }

  renderProjects() {
    const { getMaterialsSuccess, getPrintersSuccess } = this.props;

    if (!getMaterialsSuccess || !getPrintersSuccess) return null;
    return (
      <>
        {this.renderFoldersList()}
        {this.renderProjectsList()}
      </>
    );
  }

  renderProjectsList() {
    const { displayProjects } = this.state;
    if (_.isEmpty(displayProjects)) return null;
    return (
      <CardList listView={this.props.listView}>
        {_.map(displayProjects, (project) => this.renderProjectCard(project))}
      </CardList>
    );
  }

  renderFoldersList() {
    const { displayFolders } = this.state;
    if (_.isEmpty(displayFolders)) return null;
    return (
      <CardList listView={this.props.listView}>
        {_.map(displayFolders, (folder) => this.renderFolderCard(folder))}
      </CardList>
    );
  }

  renderFolderCard(folder) {
    const { projectsAndFolders, listView } = this.props;

    const projectCount = ProjectUtils.countProjectsInNestedFolders(
      projectsAndFolders,
      folder.id
    );

    return (
      <FolderCard
        key={folder.id}
        folder={folder}
        listView={listView}
        projectCount={projectCount}
        allFolders={this.state.allFolders}
        selectedProjectIds={this.state.selectedProjectIds}
        onDoubleClick={this.handleOpenFolder}
        onMouseDown={this.handleSelectCardMouseDown}
        onMouseUp={this.handleSelectCardMouseUp}
        onDrop={this.changeParentId}
      />
    );
  }

  renderProjectCard(project) {
    const { thumbnails, listView, printers } = this.props;
    const projectThumnailImage = thumbnails[project.id];

    return (
      <ProjectCard
        key={project.id}
        project={project}
        printers={printers}
        listView={listView}
        selectedProjectIds={this.state.selectedProjectIds}
        thumbnailImage={projectThumnailImage}
        loadThumbnail={this.props.loadThumbnail}
        onDoubleClick={this.handleOpenProject}
        onMouseDown={this.handleSelectCardMouseDown}
        onMouseUp={this.handleSelectCardMouseUp}
        onDrop={this.changeParentId}
      />
    );
  }

  getLoadingOverlayText() {
    if (this.props.createFolderPending) return 'Creating Folder';
    if (this.props.duplicateProjectPending) return 'Duplicating Project';
    if (this.props.deleteProjectsPending) {
      return this.state.doingMultipleDeletion
        ? 'Deleting Projects'
        : 'Deleting Project';
    }
    return null;
  }

  areActionsDisabled() {
    const { loadProjectsSuccess, getPrintersSuccess } = this.props;
    return !(loadProjectsSuccess && getPrintersSuccess);
  }

  renderRightPanelContent() {
    const disabled = this.areActionsDisabled();

    return (
      <ButtonsContainer>
        <ButtonWrapper>
          <NewProjectButton
            expand
            withIcon
            parentId={this.state.currentFolderId}
            disabled={disabled}>
            New project
          </NewProjectButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            expand
            disabled={disabled}
            onClick={(e) => this.showFolderCreateModal(e)}>
            New folder
          </Button>
        </ButtonWrapper>
      </ButtonsContainer>
    );
  }

  renderSharedProjectNotFound() {
    return (
      <NotFound
        history={this.props.history}
        extraDescriptionText={ProjectNotFoundDescription}
      />
    );
  }

  renderImportSharedProjectModal() {
    const { showImportSharedProjectModal } = this.state;
    if (showImportSharedProjectModal === false) {
      return null;
    }
    return (
      <ImportProject
        history={this.props.history}
        match={this.props.match}
        toggleImportSharedProjectModal={() => {
          this.setState({ showImportSharedProjectModal: false });
          this.props.history.push(Routes.toManageProjects());
        }}
        setSharedProjectNotFound={() => {
          this.setState({ shareProjectNotFound: true });
        }}
      />
    );
  }

  render() {
    const {
      projectsAndFolders,
      loadProjectsPending,
      loadProjectsSuccess,
      getMaterialsPending,
      getMaterialsSuccess,
      getPrintersPending,
      getPrintersSuccess,
    } = this.props;

    const loadingError =
      (!loadProjectsPending && !loadProjectsSuccess) ||
      (!getMaterialsPending && !getMaterialsSuccess) ||
      (!getPrintersPending && !getPrintersSuccess);

    if (this.state.shareProjectNotFound) {
      return this.renderSharedProjectNotFound();
    }

    return (
      <>
        <ManagerView
          name='Projects'
          actionsDisabled={this.areActionsDisabled()}
          menuButtons={this.renderRightPanelContent()}
          loadingOverlayText={this.getLoadingOverlayText()}
          loadingContent={
            loadProjectsPending || getMaterialsPending || getPrintersPending
          }
          loadingError={loadingError}
          noContent={_.isEmpty(projectsAndFolders)}
          filterBarValue={this.state.filterBy}
          onFilterChange={(value) => this.onFilterChange(value)}
          sortOptions={sortOptions}
          sortMode={this.props.sortBy.mode}
          sortDirection={this.props.sortBy.direction}
          onSortChange={(mode, direction) => this.onSortChange(mode, direction)}
          actionButtonsFilterBar={this.getActionButtonsLeftMeta()}
          actionButtons={this.getActionButtonsRightMeta()}>
          {this.renderProjects()}
        </ManagerView>
        {this.renderRenameModal()}
        {this.renderDeleteModal()}
        {this.renderCreateFolderModal()}
        {this.renderMoveToFolderModal()}
        {this.renderShareModal()}
        {this.renderImportSharedProjectModal()}
      </>
    );
  }
}

export default ProjectManager;

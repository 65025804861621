import styled from 'styled-components';
import { Breakpoints } from '../../../../../themes';

export const Container = styled.div`
  width: 100%;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const TemperatureGraph = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 0.25rem;
  margin-bottom: 1rem;
`;

export const TemperatureControllers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.75rem;

  @media (max-width: ${Breakpoints.smallWidth}) {
    flex-direction: column;
  }
`;

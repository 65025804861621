import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/*
  You can find more info on 'react-toastify' from here:
  https://github.com/fkhadra/react-toastify
*/

import { Shadows } from '../../themes';

const StyledContainer = styled(ToastContainer).attrs({
  closeOnClick: true,
  draggable: true,
  hideProgressBar: true,
  newestOnTop: false,
  pauseOnVisibilityChange: true,
  position: 'bottom-right',
  rtl: false,
})`
  width: auto;
  max-width: 27.5rem;
  min-height: 2.5rem;

  .Toastify__toast {
    max-width: max-content;
    margin-left: auto;
  }

  .toastStyles {
    padding: 0;
    border-radius: 0.25rem;
    margin-top: 1rem;
    margin-bottom: 0rem;
    background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
    ${Shadows.d3};
  }

  .toastBodyStyles {
    margin: 0;
    display: flex;
    align-items: stretch;
  }

  .Toastify__close-button {
    color: transparent;
    width: 0;
  }
`;

class ToastManager extends Component {
  render() {
    return <StyledContainer />;
  }
}

export default withTheme(ToastManager);

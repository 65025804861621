import React from 'react';

const transitionMethod = (
  <>
    Choose whether to use a transition tower or side transitions if your printer
    supports it. Side transition parameters are specified in the printer
    profile. Transition towers will work on any printer; however, with careful
    planning, side transitions can help to reduce waste and print times. Unlike
    towers, side transitions will only print on layers without transitions if a
    ping is required.
  </>
);

const transitionLength = (
  <>
    Specify the length of filament to be used when transitioning from one color
    or material to another. A higher value will allow the nozzle to clean out
    more of the blended region between filaments but will create more waste.
  </>
);

const variableTransitionLengths = (
  <>
    Customize the length of filament used when transitioning between specific
    inputs.
  </>
);

const transitionTarget = (
  <>
    Specify what percent into a transition length the Palette should aim for the
    new color to reach the nozzle. Lower target position values will use more of
    the transition length for cleaning out the blended regions. However,
    decreasing this value requires a well calibrated extruder and Palette, since
    colors may arrive too early and risk appearing on the part.
  </>
);

const purgeToInfill = (
  <>
    Attempt to use the most recent section of infill as part of the transition
    sequence. This can reduce waste and time by repurposing existing extrusion
    for transitioning and works well with higher infill densities. However, the
    gradient produced in the infill may be visible through lightly-colored
    surfaces. This setting should not be used when printing with soluble
    supports.
  </>
);

const towerMinDensity = (
  <>
    Set the minimum density that will be used when no transition is required on
    a layer. Lower values will decrease waste, however, the value should be high
    enough to support future transition layers. 5% is a good default for most
    printers.
  </>
);

const towerMinBottomDensity = (
  <>
    Set the minimum density for the first layer of the tower. A higher value
    will help the tower stick to the print bed.
  </>
);

const towerMaxDensity = (
  <>
    Transition towers will not be more dense than this value. Due to slight
    inaccuracies in filament diameter or flow rate, towers may appear to be
    over-extruded when printing fully dense layers. This can lead to peeling or
    warping of the tower. Decreasing this value may improve printability but
    will make the tower’s footprint physically larger. The same amount of
    filament waste will be created.
  </>
);

const towerMinBrims = (
  <>
    Transition towers will always have at least this number of brims around the
    outside of the first layer. These brims help the tower to stick to the print
    bed and resist warping or peeling throughout the print.
  </>
);

const towerSpeed = (
  <>Set the print speed to be used when printing the transition tower.</>
);

const towerExtrusionWidth = (
  <>Set the extrusion width to be used when printing the transition tower.</>
);

const sideTransitionPurgeSpeed = (
  <>
    Set the print speed to be used while side transitioning. A value too high
    may lead to the extruder grinding on the filament. Since in most cases this
    speed is defined differently, a speed much slower than other print speeds
    should be selected. Some printers are unable to extrude in a controlled
    manner without moving. If you notice the printer extrudes too quickly and
    lowering this speed does not help, switch the Side transition type to ‘Along
    edge’ in printer settings.
  </>
);

export default {
  transitionMethod,
  transitionLength,
  variableTransitionLengths,
  transitionTarget,
  purgeToInfill,
  towerMinDensity,
  towerMinBottomDensity,
  towerMaxDensity,
  towerMinBrims,
  towerSpeed,
  towerExtrusionWidth,
  sideTransitionPurgeSpeed,
};

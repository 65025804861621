import { call, put } from 'redux-saga/effects';

import { singleDeviceTopic } from './topics';

import { actions } from '../../reducers/iot/iot';

import { horizonRequest } from './iotWorker';
import { CLIENT_INIT_FAILURE_MESSAGE } from '../../utils/iot-worker/constants';
import { waitForInitConnection } from './initConnection';

export function* handleBroadcast(deviceId, path, message) {
  if (path === 'health') {
    const { alive } = message.payload.body;
    yield put(actions.setDeviceConnectedStatus(deviceId, alive));
    return;
  }

  // whatever the message is, we know this device is online!
  yield put(actions.setDeviceConnectedStatus(deviceId, true));

  switch (path) {
    case 'state': {
      const { state } = message.payload.body;
      const stateId = message.payload.body.id || null;
      yield put(actions.getDeviceStateSuccess(deviceId, stateId, state));
      break;
    }
    case 'state/delta': {
      const { delta } = message.payload.body;
      const stateId = message.payload.body.id || null;
      yield put(actions.updateDeviceStateRequest(deviceId, stateId, delta));
      break;
    }
    default:
    // no-op
  }
}

export function* makeRequest(deviceId, path, payload, requestFlag = '') {
  const connectionInitialized = yield call(waitForInitConnection);
  if (!connectionInitialized) {
    throw new Error(CLIENT_INIT_FAILURE_MESSAGE);
  }
  const deviceTopicRoot = singleDeviceTopic(deviceId);
  try {
    if (requestFlag) {
      yield put(actions.markPendingDeviceRequest(deviceId, requestFlag));
    }
    const retval = yield call(horizonRequest, deviceTopicRoot, path, payload);
    if (requestFlag) {
      yield put(actions.clearPendingDeviceRequest(deviceId, requestFlag));
    }
    yield put(actions.setDeviceConnectedStatus(deviceId, true));
    return retval;
  } catch (err) {
    if (requestFlag) {
      yield put(actions.clearPendingDeviceRequest(deviceId, requestFlag));
    }
    throw err;
  }
}

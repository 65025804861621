import React from 'react';

import { hereToHelp } from './common.metadata';
import Routes from '../../../../router/routes';
import { Body1 } from '../../../../shared/typography/typography';

import {
  ImageContainer,
  PrinterImage,
  AddPrinterImage,
  AddDeviceImage,
  FourInputBenchyImage,
} from '../taskManager.styles';

const metadata = {
  title: 'Canvas',
  stepOrder: [
    'sliceForPrinter',
    'addPrinterProfile',
    'createSetup',
    'goToProjects',
    'hereToHelp',
  ],
  steps: {
    sliceForPrinter: {
      title: 'Slice for your printer',
      content: (
        <>
          <Body1>
            A printer profile holds the configurations of your printer that are
            used for best slicing results.
          </Body1>
          <ImageContainer>
            <PrinterImage />
          </ImageContainer>
        </>
      ),
    },
    addPrinterProfile: {
      title: 'Add a printer profile',
      content: (
        <>
          <Body1>
            Click &ldquo;New printer&rdquo; and tell Canvas about your printer.
            Canvas may have a preset with some optimal settings.
          </Body1>
          <ImageContainer>
            <AddPrinterImage />
          </ImageContainer>
        </>
      ),
      proceedLabel: 'Go to Printers',
      onProceed: (props) => props.history.push(Routes.toManagePrinters()),
    },
    createSetup: {
      title: 'Add a device',
      content: (
        <>
          <Body1>
            You can link a connected device to Canvas under Devices. Then you
            can control it directly from Canvas when it is online.
          </Body1>
          <ImageContainer>
            <AddDeviceImage />
          </ImageContainer>
        </>
      ),
    },
    goToProjects: {
      title: 'Run a test print',
      content: (
        <>
          <Body1>
            Once you have added a printer profile, you can create a project and
            slice it for your printer.
          </Body1>
          <ImageContainer>
            <FourInputBenchyImage />
          </ImageContainer>
        </>
      ),
      proceedLabel: 'Go to Projects',
      onProceed: (props) => props.history.push(Routes.toManageProjects()),
    },
    hereToHelp,
  },
};

export default metadata;

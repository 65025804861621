import Canvas from './canvas.metadata';
import CanvasHub from './canvasHub.metadata';
import Palette2 from './palette2.metadata';
import Palette3 from './palette3.metadata';

// array order dictates priority when multiple task lists are to be shown
export const taskListOrder = ['canvas', 'canvasHub', 'palette2', 'palette3'];

export const taskMetadata = {
  canvas: Canvas,
  canvasHub: CanvasHub,
  palette2: Palette2,
  palette3: Palette3,
};

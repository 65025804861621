import React, { useEffect, useState } from 'react';
import {
  useFloating,
  size,
  shift,
  flip,
  offset,
  autoUpdate,
} from '@floating-ui/react-dom';
import { withTheme } from 'styled-components';
import _ from 'lodash';

import {
  DefaultContainer,
  DefaultOptions,
  DefaultOptionWrapper,
  DefaultSelect,
  DefaultFieldLabelWrapper,
  DefaultDescriptionIconContainer,
  DefaultIconContainer,
  DefaultSelectedOptionText,
  DefaultOptionsList,
  OptionLabel,
  OptionSubLabel,
  FilterBarContainer,
  FilterResetIconContainer,
  DefaultOption,
} from './abstractDropdown.styles';

import Icon from '../icon/icon.jsx';
import DropdownInput from './dropdownInput/dropdownInput.jsx';
import { usePrevious } from '../../hooks';
import Portal from '../portal/portal.jsx';

import { Icons } from '../../themes';

import { Body1, Caption } from '../typography/typography';
import {
  flattenOptions,
  highlightMatchPrefix,
  highlightMatchSubstring,
} from './utils';

const filterHighlights = {
  NONE: 0,
  PREFIX: 1,
  SUBSTRING: 2,
};

const AbstractDropdown = ({
  value = '', // tie to value of input field
  options = [], // { label: '', value: '' }
  unfoundOptionText = '', // display this value if no option label was found
  isOptionUnfound = false,
  onOpen = () => {},
  onClose = () => {},
  onChange = (/* value */) => {},
  rightAlign = false,
  dropUp = false,
  disabled = false,
  label = '',
  customLabel = '',
  icon = Icons.basic.triangleDown,
  descriptionIcon,
  descriptionIconSize,
  minHeight, // todo: default?
  listHeight = '20rem',
  noPadding = false,
  borderlessContainer = false,
  borderlessSelect = false,
  groupUnderline = false,
  grey = false,
  green = false,
  StyledContainer = DefaultContainer,
  StyledSelect = DefaultSelect,
  StyledSelectedOptionText = DefaultSelectedOptionText,
  StyledFieldLabelWrapper = DefaultFieldLabelWrapper,
  StyledOptions = DefaultOptions,
  StyledOption = DefaultOption,
  StyledOptionWrapper = DefaultOptionWrapper,
  StyledDescriptionIconContainer = DefaultDescriptionIconContainer,
  StyledIconContainer = DefaultIconContainer,
  StyledOptionsList = DefaultOptionsList,
  // filtering
  allowFilter = false,
  filterBarPlaceholder = 'Start typing to search…',
  filterValidator = (/* value */) => true, // override for custom filter text validation
  // override props.onFilter with `(value) => {}` to handle filtering externally,
  // (parent is in charge of providing filtered/updated props.options)
  onFilter = null,
  filterResultsPending = false, // for handling of async filtering
  filterHighlightStyle = AbstractDropdown.filterHighlights.SUBSTRING,
  allowFilterCreation = false, // allow the filter text to be submitted as a new value
  filterCreationText = 'Create new entry', // prefix before the quoted text value
  onCreate = (/* value */) => {},
  filterCreationPending = false, // for handling of async creation

  // implicit props
  theme,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [filter, setFilter] = useState('');
  const [filterInvalid, setFilterInvalid] = useState(false);
  const [maxOptionsHeight, setMaxOptionsHeight] = useState(0);
  const [maxOptionsWidth, setMaxOptionsWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  // positioning dropdown options
  const { x, y, reference, floating, strategy, update, refs } = useFloating({
    placement: `${dropUp ? 'top' : 'bottom'}-${rightAlign ? 'end' : 'start'}`,
    middleware: [
      shift(),
      offset(2), // gap between container and options
      size({
        apply({ width, height, ...args }) {
          // .5 gap rems between options and edge of viewport
          setMaxOptionsHeight(height - 8);
          setMaxOptionsWidth(width - 8);
          setContainerWidth(args.reference.width);
        },
      }),
      flip(),
    ],
    strategy: 'fixed',
  });

  useEffect(() => {
    if (!refs.reference.current || !refs.floating.current) {
      return undefined;
    }
    // Only call this when the floating element is rendered
    return autoUpdate(refs.reference.current, refs.floating.current, update);
  }, [refs.reference, refs.floating, update, showOptions]);

  const onClick = (e) => {
    e.stopPropagation();
  };

  const toggleOptions = (e) => {
    if (disabled) return;
    const newShowOptions = !showOptions;
    setShowOptions(newShowOptions);
    if (newShowOptions) {
      onOpen(e);
    } else {
      onClose();
    }
  };

  const selectOption = (newValue) => {
    setShowOptions(false);
    if (newValue !== value) {
      onChange(newValue);
    }
    onClose();
  };

  const onFilterChangeSuccess = (newValue) => {
    setFilter(newValue);
    setFilterInvalid(false);
    if (onFilter) {
      onFilter(newValue);
    }
  };

  const onFilterChangeFailure = (newValue) => {
    setFilter(newValue);
    setFilterInvalid(true);
  };

  const onFilterKeyDown = (e) => {
    if (e.key === 'Escape') {
      if (filter) {
        // filter bar has text -- clear it
        onFilterChangeSuccess('');
      } else {
        // filter bar is empty -- close dropdown
        toggleOptions(e);
      }
    }
  };

  // window event listeners
  useEffect(() => {
    const onWindowMouseDown = (e) => {
      if (refs.reference.current && refs.reference.current.contains(e.target)) {
        return;
      }
      if (refs.floating.current && refs.floating.current.contains(e.target)) {
        return;
      }
      e.stopPropagation();
      if (showOptions) {
        setShowOptions(false);
        onClose();
      }
    };
    window.addEventListener('mousedown', onWindowMouseDown, false);
    return () => {
      window.removeEventListener('mousedown', onWindowMouseDown, false);
    };
  }, [showOptions, onClose, refs.reference, refs.floating]);

  // state updates triggered by prop updates
  const prevFilterCreationPending = usePrevious(filterCreationPending);
  useEffect(() => {
    if (
      filterCreationPending &&
      filterCreationPending !== prevFilterCreationPending &&
      showOptions
    ) {
      const newFilterValue = '';
      setShowOptions(false);
      setFilter(newFilterValue);
      setFilterInvalid(filterValidator(newFilterValue));
      onClose();
    }
  }, [
    filterCreationPending,
    prevFilterCreationPending,
    showOptions,
    filterValidator,
    onClose,
  ]);

  const renderFilterBarClearButton = () => {
    if (!filter) return null;
    return (
      <FilterResetIconContainer onClick={() => onFilterChangeSuccess('')}>
        <Icon src={Icons.basic.x} color={theme.colors.grey5} />
      </FilterResetIconContainer>
    );
  };

  const renderFilterBar = () => {
    return (
      <FilterBarContainer>
        {renderFilterBarClearButton()}
        <DropdownInput
          autoFocus
          placeholder={filterBarPlaceholder}
          validator={filterValidator}
          type='text'
          value={filter}
          onKeyDown={(e) => onFilterKeyDown(e)}
          onChangeSuccess={onFilterChangeSuccess}
          onChangeFailure={onFilterChangeFailure}
        />
      </FilterBarContainer>
    );
  };

  const renderOptionLabel = (optionLabel) => {
    // if no filter, use plain text
    const normalizedFilter = filter.trim().toLowerCase();
    if (!normalizedFilter) return optionLabel;

    switch (filterHighlightStyle) {
      case filterHighlights.PREFIX:
        return highlightMatchPrefix(optionLabel, normalizedFilter);
      case filterHighlights.SUBSTRING:
        return highlightMatchSubstring(optionLabel, normalizedFilter);
      default:
        return optionLabel;
    }
  };

  const renderOption = (option, isCurrent, idx) => {
    const trimmedLabel = (option.optionLabel || option.label).trim();
    const key = option.key || option.value || idx;
    const CustomOptionLabel = option.customOptionLabel;
    return (
      <StyledOption
        nowrap
        key={key}
        isCurrent={isCurrent}
        disabled={option.disabled}
        isGroupLabel={option.isGroupLabel}
        highlightLabel={option.highlightLabel}
        hideLabel={option.hideLabel}
        groupUnderline={groupUnderline}
        onClick={(e) => {
          if (option.disabled || option.isGroupLabel) return;
          e.stopPropagation();
          selectOption(option.value);
        }}
        value={option.value}>
        {option.hideLabel ? null : (
          <StyledOptionWrapper>
            {CustomOptionLabel !== undefined && CustomOptionLabel}
            {trimmedLabel && (
              <OptionLabel noSpacing>
                {renderOptionLabel(trimmedLabel)}
              </OptionLabel>
            )}
            {option.subLabel && (
              <OptionSubLabel noSpacing>{option.subLabel}</OptionSubLabel>
            )}
          </StyledOptionWrapper>
        )}
      </StyledOption>
    );
  };

  const renderCreateOption = (filteredOptions) => {
    const normalizedFilter = filter.trim();
    const filterLowercase = normalizedFilter.toLowerCase();
    const exactMatches = _.filter(filteredOptions, (option) => {
      const normalizedLabel = (option.optionLabel || option.label)
        .trim()
        .toLowerCase();
      return normalizedLabel === filterLowercase;
    });
    if (
      !allowFilterCreation ||
      filterResultsPending ||
      exactMatches.length > 0 ||
      normalizedFilter.length === 0 ||
      filterInvalid
    ) {
      return null;
    }
    if (
      typeof allowFilterCreation === 'function' &&
      !allowFilterCreation(normalizedFilter)
    ) {
      return null;
    }

    return (
      <StyledOption
        nowrap
        key='NEW_OPTION'
        onClick={() => onCreate(normalizedFilter)}
        value={null}>
        <Body1 noSpacing>
          {filterCreationText} &ldquo;{normalizedFilter}&rdquo;
        </Body1>
      </StyledOption>
    );
  };

  const renderLabel = () => {
    if (!label) return null;
    return (
      <StyledFieldLabelWrapper>
        <Caption grey>{label}</Caption>
      </StyledFieldLabelWrapper>
    );
  };

  const getIconColor = () => {
    if (disabled) return theme.colors.textTertiary;
    if (grey) return theme.colors.textSecondary;
    if (green) return theme.colors.greenDefault;
    return theme.colors.textPrimary;
  };

  const renderDescriptionIcon = () => {
    // adds icon to the left of dropdown label
    if (!descriptionIcon) return null;

    return (
      <StyledDescriptionIconContainer hide={descriptionIconSize === 0}>
        <Icon
          src={descriptionIcon}
          size={descriptionIconSize || Icon.sizes.SMALL}
          color={getIconColor()}
        />
      </StyledDescriptionIconContainer>
    );
  };

  const renderSelectIcon = () => {
    if (!icon) return null;

    return (
      <StyledIconContainer hasLabel={!!label} showOption={showOptions}>
        <Icon src={icon} color={getIconColor()} />
      </StyledIconContainer>
    );
  };

  const renderSelect = () => {
    const flattenedOptions = flattenOptions(options);
    const currentOption = _.find(
      flattenedOptions,
      (option) => option.value === value
    );
    const selectedOptionLabel = currentOption
      ? currentOption.label
      : unfoundOptionText;
    const CustomOptionLabel = currentOption
      ? currentOption.customOptionLabel
      : null;
    return (
      <StyledSelect
        disabled={disabled}
        grey={grey || isOptionUnfound}
        hasLabel={!!label}
        showOption={showOptions}
        borderlessSelect={borderlessSelect}
        noPadding={noPadding}
        onClick={(e) => toggleOptions(e)}>
        {renderDescriptionIcon() || CustomOptionLabel}
        <StyledSelectedOptionText>
          {customLabel || selectedOptionLabel}
        </StyledSelectedOptionText>
        {renderSelectIcon()}
      </StyledSelect>
    );
  };

  const renderOptions = () => {
    if (!showOptions) return null;
    const flattenedOptions = flattenOptions(options);
    const normalizedFilter = filter.trim().toLowerCase();
    const filteredOptions = onFilter
      ? flattenedOptions
      : _.filter(flattenedOptions, (option) => {
          const normalizedLabel = (option.optionLabel || option.label)
            .trim()
            .toLowerCase();
          if (filterHighlightStyle === filterHighlights.PREFIX) {
            return normalizedLabel.startsWith(normalizedFilter);
          }
          return normalizedLabel.includes(normalizedFilter);
        });
    return (
      <StyledOptions
        ref={floating}
        x={x}
        y={y}
        strategy={strategy}
        containerWidth={containerWidth}
        maxOptionsWidth={maxOptionsWidth}>
        <StyledOptionsList
          listHeight={listHeight}
          maxOptionsHeight={maxOptionsHeight}>
          {allowFilter ? renderFilterBar() : null}
          {_.map(filteredOptions, (option, idx) => {
            const isCurrent = option.value === value;
            return renderOption(option, isCurrent, idx);
          })}
          {renderCreateOption(filteredOptions)}
        </StyledOptionsList>
      </StyledOptions>
    );
  };

  return (
    <StyledContainer
      data-test-id='dropdown'
      ref={reference}
      onClick={(e) => onClick(e)}
      onDoubleClick={(e) => e.stopPropagation()}
      disabled={disabled || filterCreationPending}
      showOptions={showOptions}
      minHeight={minHeight}
      borderlessContainer={borderlessContainer}
      value={value}
      options={options}>
      {renderLabel()}
      {renderSelect()}
      <Portal>{renderOptions()}</Portal>
    </StyledContainer>
  );
};

AbstractDropdown.filterHighlights = filterHighlights;

export default withTheme(AbstractDropdown);

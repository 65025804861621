import React, { Component } from 'react';
import _ from 'lodash';

import { FormatUtils, NotificationUtils } from '../../../../utils';
import {
  NotificationCardsContainer,
  NotificationsPlaceholder,
  IconsContainer,
  ReadUnreadIcon,
  NotificationCard,
  NotificationContent,
  NotificationSent,
  NotificationTitle,
  IconWrapper,
} from './notificationList.styles';

import Icon from '../../../icon/icon.jsx';
import { Icons } from '../../../../themes';
import { Body1 } from '../../../typography/typography';

class NotificationList extends Component {
  renderReadUnreadIcon(notification) {
    const read = !!notification.read;
    return (
      <ReadUnreadIcon
        read={read}
        title={`Mark as ${read ? 'unread' : 'read'}`}
        onClick={() => this.props.updateNotification(notification.id, !read)}
      />
    );
  }

  renderDeleteIcon(notification) {
    return (
      <IconWrapper
        onClick={() => this.props.deleteNotification(notification.id)}>
        <Icon src={Icons.basic.x} />
      </IconWrapper>
    );
  }

  renderIcons(notification) {
    return (
      <IconsContainer>
        {this.renderReadUnreadIcon(notification)}
        {this.renderDeleteIcon(notification)}
      </IconsContainer>
    );
  }

  renderNotificationCard(notification) {
    // if notification is marked as read, change text colors to grey
    return (
      <NotificationCard key={notification.id}>
        {this.renderIcons(notification)}
        <NotificationTitle read={notification.read}>
          {notification.title}
        </NotificationTitle>
        <NotificationContent read={notification.read}>
          {notification.content}
        </NotificationContent>
        <NotificationSent read={notification.read}>
          {FormatUtils.isoToRelativeTime(notification.sent)} ago
        </NotificationSent>
      </NotificationCard>
    );
  }

  render() {
    const { notifications } = this.props;
    if (_.isEmpty(notifications)) {
      return (
        <NotificationsPlaceholder onClick={(e) => e.stopPropagation()}>
          <Body1 grey noSpacing>
            No notifications
          </Body1>
        </NotificationsPlaceholder>
      );
    }

    // sort notifications by unread first, then from newest to oldest
    const sortedNotifications = NotificationUtils.sort(notifications);
    return (
      <NotificationCardsContainer onClick={(e) => e.stopPropagation()}>
        {_.map(sortedNotifications, (item) =>
          this.renderNotificationCard(item)
        )}
      </NotificationCardsContainer>
    );
  }
}

export default NotificationList;

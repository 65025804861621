import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import { handleGenericError } from '../common';
import { makeRequest } from './horizon';
import { actions } from '../../reducers/iot/iot';

export default function* moveExtruder(action) {
  const { device, movementData } = action.payload;

  try {
    yield call(
      makeRequest,
      device.id,
      'printer/move',
      {
        method: 'post',
        query: {
          ...movementData,
          nozzle: 1, // set different values here for multi-extruders?
        },
      },
      'moveExtruder'
    );
    yield put(actions.moveExtruderSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

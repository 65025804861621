import _ from 'lodash';
import React, { Component } from 'react';

import {
  ControlButtonsContainer,
  ColorSwatchInfoContainer,
  EditMaterialButtonMobileWrapper,
  ExtruderButtonsContainer,
  GeneralButtonWrapper,
  ListViewMaterialInfo,
  MaterialPanelContent,
  MaterialToolContainer,
  MaterialToolContainerMobile,
  MobileToolbar,
  MobileToolbarLeftWrapper,
  MobileToolbarRightWrapper,
  ObjectBrowserContainer,
  ObjectBrowserContainerMobile,
  SliceButtonWrapper,
  SpliceButtonWrapper,
  ToolboxContainer,
  ToolboxContent,
  ToolboxMobileWrapper,
  ToolboxWrapper,
  ViewButtonsContainer,
} from './placeView.styles';

import HeaderBar from '../../../shared/page/headerBar/headerBar.jsx';
import TranslationTool from './tools/translation/translation.container';
import RotationTool from './tools/rotation/rotation.container';
import ScaleTool from './tools/scale/scale.container';
import ContextTool from './tools/context/context.container';
import MaterialTool from './tools/material/material.container';
import ObjectBrowser from '../objectBrowser/objectBrowser.container';

import {
  ActionButton,
  Button,
  Body1,
  Caption,
  ColorSwatch,
  ConfirmationModal,
  ToolCollapsiblePanel,
  ToolTipWrapper,
} from '../../../shared';

import {
  SlicerUtils,
  FormatUtils,
  TreeUtils,
  TransformUtils,
  ProjectUtils,
  MaterialUtils,
} from '../../../utils';

import { Icons } from '../../../themes';
import types from '../../../reducers/three/types';
import { getIntersection } from '../../../sagas/three/raycast';
import { clampGizmoTowerScale } from './tools/scale/math';

class PlaceView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouse: { x: null, y: null },
      currentMaterial: null,
      showColorOverrideModal: false,
      dropDownOpenInMaterialTool: false,
      modelIdsForColorOverride: [],
      extruderForColorOverride: 0,
      inMobileView: false,
      showObjectBrowserMobile: false,
      showToolboxControlMobile: false,
      showMaterialsMobile: false,
      showEditMaterialTool: false,
      showEditMaterialToolMobile: false,
      toolboxNavigation: [],
      toolboxWrapperHeight: 0,
    };
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.handleGizmoTransforms = this.handleGizmoTransforms.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onToolboxWrapperResize = this.onToolboxWrapperResize.bind(this);
    this.toolboxWrapperRef = React.createRef();
  }

  componentDidMount() {
    this.resizeObserver = new ResizeObserver((entries) => {
      this.onToolboxWrapperResize(entries);
    });
    this.resizeObserver.observe(this.toolboxWrapperRef.current);
    window.addEventListener('mousedown', this.onMouseDown);
    window.addEventListener('mouseup', this.onMouseUp);
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('touchstart', this.onTouchStart);
    window.addEventListener('touchend', this.onTouchEnd);
    window.addEventListener('touchmove', this.onTouchMove);
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.updateGizmo();
    this.props.setCurrentTool(null);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.onMouseDown);
    window.removeEventListener('mouseup', this.onMouseUp);
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('touchstart', this.onTouchStart);
    window.removeEventListener('touchend', this.onTouchEnd);
    window.removeEventListener('touchmove', this.onTouchMove);
    window.removeEventListener('resize', this.onResize);
    this.resizeObserver.disconnect();
    this.props.detachGizmo();
    this.props.deactivateAllModels();
    this.props.setIntersectMode('object');
    this.props.setCurrentTool(null);
  }

  componentDidUpdate(prevProps) {
    const { currentTool, models, gizmo, transitionTower } = this.props;

    let updateGizmo = false;
    let detachGizmo = false;

    if (prevProps.models !== models) {
      updateGizmo = true;
    }

    if (
      prevProps.transformsSuccess !== this.props.transformsSuccess &&
      this.props.transformsSuccess
    ) {
      updateGizmo = true;
    }

    if (prevProps.transitionTower !== transitionTower) {
      updateGizmo = true;
    }

    if (prevProps.currentTool !== currentTool) {
      const toolsWithGizmo = ['translate', 'rotate', 'scale'];
      if (toolsWithGizmo.includes(currentTool)) {
        // gizmo is already "active"; just change the mode
        if (gizmo.object) {
          gizmo.setMode(currentTool);
        } else {
          updateGizmo = true;
        }
      } else {
        detachGizmo = true;
      }
    }

    if (detachGizmo) {
      this.props.detachGizmo();
    } else if (updateGizmo) {
      this.updateGizmo();
    }

    // hide nav buttons on small screens when showProfilesPanelMobile's state changes in Slice view
    if (
      !prevProps.showProfilesPanelMobile &&
      this.props.showProfilesPanelMobile
    ) {
      this.setState({ toolboxNavigation: [() => {}] });
    } else if (
      prevProps.showProfilesPanelMobile &&
      !this.props.showProfilesPanelMobile
    ) {
      this.setState({ toolboxNavigation: [] });
    }
  }

  onToolboxWrapperResize(entries) {
    const toolboxWrapper = entries[0];
    this.setState({ toolboxWrapperHeight: toolboxWrapper.contentRect.height });
  }

  onResize() {
    const { inMobileView, toolboxNavigation } = this.state;
    // going from large screen to small
    const mobileToolbarHeight =
      document.getElementById('mobileToolbar').clientHeight;
    if (mobileToolbarHeight !== 0 && !inMobileView) {
      this.setState({
        inMobileView: true,
      });
      if (toolboxNavigation.length === 0) {
        this.setState({
          showToolboxControlMobile: false,
          showEditMaterialToolMobile: false,
          showMaterialsMobile: false,
        });
      }
      // going from small screen to large
    } else if (mobileToolbarHeight === 0 && inMobileView) {
      this.setState({
        inMobileView: false,
      });
    }
  }

  updateGizmo() {
    const { currentTool, gizmo, models, transitionTower } = this.props;
    const toolsWithGizmo = ['translate', 'rotate', 'scale'];

    if (
      (_.isEmpty(models) && transitionTower === null) || // nothing to attach gizmo to
      !toolsWithGizmo.includes(currentTool)
    ) {
      // not a valid gizmo tool
      this.props.detachGizmo();
      return;
    }

    let targetMeshes;

    const selectedTower =
      transitionTower && transitionTower.active ? transitionTower : null;
    const selectedModels = SlicerUtils.getSelectedModels(models);

    if (selectedTower && !_.isEmpty(selectedModels)) {
      // both tower and models selected; include both iff translate mode
      if (currentTool === 'translate') {
        targetMeshes = selectedModels.map((model) => model.mesh);
        targetMeshes.push(selectedTower.mesh);
      }
    } else if (selectedTower && _.isEmpty(selectedModels)) {
      // just tower selected; include tower iff translate or scale mode
      if (currentTool === 'translate' || currentTool === 'scale') {
        targetMeshes = [selectedTower.mesh];
      }
    } else if (!selectedTower && !_.isEmpty(selectedModels)) {
      // just models selected; include models regardless of mode
      targetMeshes = selectedModels.map((model) => model.mesh);
    }

    const currentGizmoTargets = new Set();
    if (gizmo.object) {
      _.forEach(gizmo.selected, (existingTarget) => {
        currentGizmoTargets.add(existingTarget);
      });
    }

    if (targetMeshes) {
      // store starting transform data for each mesh
      _.forEach(targetMeshes, (mesh) => {
        /* eslint-disable no-param-reassign */
        mesh.userData.startPosition = mesh.position.clone();
        mesh.userData.startQuaternion = mesh.quaternion.clone();
        mesh.userData.startScale = mesh.scale.clone();
        /* eslint-enable no-param-reassign */
      });

      // check if any of the gizmo's targets have changed
      let gizmoTargetsChanged = false;
      if (currentGizmoTargets.size !== targetMeshes.length) {
        // different number of meshes -- definitely changed
        gizmoTargetsChanged = true;
      } else {
        // same number -- ensure they are in fact the same meshes
        _.forEach(targetMeshes, (targetMesh) => {
          if (!currentGizmoTargets.has(targetMesh)) {
            gizmoTargetsChanged = true;
          }
        });
      }

      if (
        gizmoTargetsChanged ||
        (selectedTower && _.includes(targetMeshes, selectedTower.mesh))
      ) {
        this.props.attachGizmo(
          targetMeshes,
          currentTool,
          this.handleGizmoTransforms
        );
      } else {
        this.props.updateGizmo();
      }
    } else {
      // no mesh to attach gizmo to; clear and reset gizmo
      this.props.detachGizmo();
    }
  }

  handleGizmoTransforms() {
    const { models, gizmo, transitionTower } = this.props;

    const modelTransformData = [];

    let newTransforms;
    gizmo.selected.forEach((mesh) => {
      if (mesh.name === types.TOWER_MESH_NAME) {
        // dispatch actions to update tower
        const { position, scale } = mesh;
        if (gizmo.mode === 'translate') {
          this.props.setTowerPosition(position);
        } else if (gizmo.mode === 'scale') {
          const newTowerSize = clampGizmoTowerScale(transitionTower, scale);
          this.props.setTowerSize(newTowerSize);
        }
      } else {
        // collect transform data for each model
        newTransforms = this.composeTransformData(mesh);
        modelTransformData.push({ id: mesh.name, transforms: newTransforms });
      }
    });

    // make batch call to update model transforms
    if (!_.isEmpty(modelTransformData)) {
      if (gizmo.mode === 'rotate' || gizmo.mode === 'scale') {
        // compute updated bounding box for affected models
        const selectedModels = _.filter(
          TreeUtils.flattenDeep(models),
          (model) => model.active
        );
        const combinedBBox = SlicerUtils.getCombinedBoundingBox(selectedModels);
        const zOffset = combinedBBox.min.z;

        if (zOffset !== 0) {
          // drop to bed
          modelTransformData.forEach((data) => {
            // eslint-disable-next-line no-param-reassign
            data.transforms.translate.z -= zOffset;
          });
        }
      }
      this.props.updateModelTransforms(modelTransformData);
    }
  }

  composeTransformData(mesh) {
    const { gizmo } = this.props;
    const transforms = {};
    if (gizmo.mode === 'translate') {
      transforms.translate = mesh.position;
    } else if (gizmo.mode === 'rotate') {
      transforms.translate = mesh.position;
      transforms.rotate = {
        // eslint-disable-next-line no-underscore-dangle
        x: mesh.rotation._x,
        y: mesh.rotation._y,
        z: mesh.rotation._z,
      };
    } else if (gizmo.mode === 'scale') {
      transforms.translate = mesh.position;
      transforms.scale = mesh.scale;
    }
    return transforms;
  }

  canSelectAllObjects() {
    return !this.props.isModalShown && !this.state.showColorOverrideModal;
  }

  renderObjectBrowserPanel() {
    const { project, printers, models } = this.props;
    const allowPainting = ProjectUtils.configSupportsTransitions(
      project.deviceConfig,
      printers[project.printerId]
    );
    const selectedModels = SlicerUtils.getSelectedModels(models);
    const disabled = selectedModels.length !== 1;
    return (
      <ObjectBrowser
        forceOpen
        openPaintView={() => {
          this.handleEnterPaint();
        }}
        headerCustomButton={this.goBackCleanupButton()}
        allowPainting={allowPainting}
        paintDisabled={disabled}
        canSelectAll={this.canSelectAllObjects()}
      />
    );
  }

  renderObjectBrowser() {
    return (
      <ObjectBrowserContainer>
        {this.renderObjectBrowserPanel()}
      </ObjectBrowserContainer>
    );
  }

  renderObjectBrowserMobile() {
    const { showObjectBrowserMobile } = this.state;
    if (!showObjectBrowserMobile) return null;
    return (
      <ObjectBrowserContainerMobile>
        {this.renderObjectBrowserPanel()}
      </ObjectBrowserContainerMobile>
    );
  }

  setMouse(x, y) {
    this.setState({ mouse: { x, y } });
  }

  onPointerDown(posX, posY) {
    // updating state on mousedown event handler stops the event propagation
    // disabling that when dropdown is open
    const { dropDownOpenInMaterialTool } = this.state;
    if (!dropDownOpenInMaterialTool) {
      this.setMouse(posX, posY);
    }
  }

  onMouseDown(e) {
    this.onPointerDown(e.clientX, e.clientY);
  }

  onTouchStart(e) {
    this.onPointerDown(e.touches[0].clientX, e.touches[0].clientY);
  }

  onPointerMove() {
    if (this.state.showColorOverrideModal || this.props.isModalShown) return;
    this.handleModelHighlight();
  }

  onMouseMove(e) {
    if (e.buttons !== 0) return;
    this.onPointerMove();
  }

  onTouchMove() {
    this.onPointerMove();
  }

  onPointerUp(e, posX, posY) {
    const { mouse } = this.state;
    const { models } = this.props;
    if (posX === mouse.x && posY === mouse.y) {
      const [intersection] = getIntersection();
      const onCanvas = e.target.nodeName === 'CANVAS';
      // click on visualizer but not model
      if (!intersection) {
        if (onCanvas) {
          this.props.deactivateAllModels();
          this.props.setIntersectMode('object');
        }
      } else {
        // click on something that is not Canvas
        if (!onCanvas) return;
        const modelId = intersection.object.name;
        if (this.props.intersectMode === 'face') {
          // Face select mode
          if (modelId !== types.TOWER_MESH_NAME) {
            const updatedModels = TransformUtils.layFaceToBed(
              models,
              intersection
            );
            this.props.updateModelTransforms(
              _.map(updatedModels, (model) => ({
                id: model.id,
                transforms: _.pick(model.transforms, ['translate', 'rotate']),
              }))
            );
          }
          this.props.setIntersectMode('object');
        } else {
          // Object select mode
          if (!modelId) return;
          if (modelId === types.TOWER_MESH_NAME) {
            // selected tower
            if (e.shiftKey) {
              this.props.toggleTowerActiveState();
            } else {
              this.props.deactivateAllModels();
              this.props.toggleTowerActiveState();
            }
          } else {
            // selected regular models
            this.selectModels(modelId, e.shiftKey, e.metaKey || e.ctrlKey);
          }
        }
      }
    }
  }

  onMouseUp(e) {
    this.onPointerUp(e, e.clientX, e.clientY);
  }

  onTouchEnd(e) {
    this.onPointerUp(
      e,
      e.changedTouches[0].clientX,
      e.changedTouches[0].clientY
    );
  }

  selectModels(modelId, shiftKey, metaKey) {
    // find the model targeted by pointer
    const currentModel = TreeUtils.searchById(modelId, this.props.models);
    if (!currentModel) return;

    const parent = TreeUtils.getParent(this.props.models, currentModel);

    // deactivate all models, unless holding down SHIFT or META(or CTRL) key
    if (!shiftKey && !metaKey) this.props.deactivateAllModels();

    let modelIds = [];
    if (parent) {
      // model is in a group
      modelIds = _.map(parent.children, (model) => model.id);
    } else {
      // model is not in a group
      modelIds = [modelId];
    }

    if (metaKey) {
      // toggle model active state if holding down META(or CTRL) key
      modelIds.forEach((id) => this.props.toggleModelActiveState(id));
    } else {
      // otherwise just activate models (i.e., click with/without holding SHIFT key)
      this.props.activateModels(modelIds);
    }
  }

  selectTool(tool) {
    // set states for selected tool
    const { setCurrentExtruderIndex, setCurrentTool } = this.props;
    if (tool === 'material') {
      setCurrentTool(tool);
    } else {
      // deselect extrude if not on material tool
      setCurrentExtruderIndex(-1);
      setCurrentTool(tool);
    }
  }

  composeModelsPaintData(modelIds) {
    const flattened = TreeUtils.flattenDeep(this.props.models);
    const affectedModels = _.filter(flattened, (item) =>
      _.includes(modelIds, item.id)
    );
    // compose model paint data
    const modelsPaintData = _.map(affectedModels, (model) => ({
      id: model.id,
      deleteRLE: model.colored,
      deleteTexture: model.textured,
    }));
    return modelsPaintData;
  }

  handleModelHighlight() {
    this.props.removeModelHighlights();
    this.props.highlightModels();
  }

  toggleMaterialToolMobile(index) {
    const { setCurrentExtruderIndex } = this.props;
    const { showMaterialsMobile, showEditMaterialToolMobile } = this.state;
    setCurrentExtruderIndex(index);
    this.setState({
      showEditMaterialToolMobile: !showEditMaterialToolMobile,
      showMaterialsMobile: !showMaterialsMobile,
    });
  }

  handleOpenEditInputToolbox(index) {
    const { toolboxNavigation } = this.state;
    this.toggleMaterialToolMobile(index);
    this.setState({
      toolboxNavigation: [
        ...toolboxNavigation,
        () => {
          this.toggleMaterialToolMobile(-1);
          this.selectTool(null);
        },
      ],
    });
  }

  onSelectMaterial(material, index) {
    const {
      currentTool,
      currentExtruderIndex,
      setCurrentExtruderIndex,
      selectMaterial,
      toggleMovingSwatch,
    } = this.props;
    if (currentTool === 'material' && currentExtruderIndex === index) {
      this.selectTool('null');
      this.selectTool('material');
      setCurrentExtruderIndex(-1);
      this.setState({
        currentMaterial: null,
        showEditMaterialTool: false,
      });
      // material selected
    } else {
      this.selectTool('material');
      selectMaterial(material);
      setCurrentExtruderIndex(index);
      this.setState({
        currentMaterial: material,
        showEditMaterialTool: true,
      });
    }
    toggleMovingSwatch();
  }

  onCloseEditMaterialTool() {
    const { setCurrentExtruderIndex } = this.props;
    const { toolboxNavigation } = this.state;
    this.selectTool('null');
    this.selectTool('material');
    setCurrentExtruderIndex(-1);
    this.setState({
      currentMaterial: null,
      showEditMaterialTool: false,
    });
    // EditMaterialTool was opened in mobile view
    if (toolboxNavigation.length === 2) this.goBackCleanup();
  }

  onReleaseMaterial(modelIds, extruder) {
    const isTargetingObjectBrowser = !_.isEmpty(modelIds);
    const [intersection] = getIntersection();
    this.props.toggleMovingSwatch();
    if (!intersection) {
      // update model color by targeting model in object browser
      if (isTargetingObjectBrowser) {
        const { models } = this.props;
        const selectedModels = SlicerUtils.getSelectedModels(models);
        if (_.isEmpty(selectedModels)) {
          // no models selected, drop to targeted models
          this.onApplyMaterial(modelIds, extruder);
        } else {
          // check if selected models and targeted model intersect
          let modelTargetedAndSelected = false;
          selectedModels.forEach((model) => {
            if (modelTargetedAndSelected) return;
            modelIds.forEach((modelId) => {
              if (modelTargetedAndSelected) return;
              if (model.id === modelId) {
                modelTargetedAndSelected = true;
              }
            });
          });
          if (modelTargetedAndSelected) {
            // targeted model is in selected models, apply to selected materials
            const selectedModelIds = _.map(
              selectedModels,
              (selectedModel) => selectedModel.id
            );
            this.onApplyMaterial(selectedModelIds, extruder);
          } else {
            // deselect all models that were previously selected but
            // not colored and color targeted model
            this.props.deactivateAllModels();
            this.onApplyMaterial(modelIds, extruder);
          }
        }
      }
      return;
    }
    // update model color by targeting model in canvas
    const intersectedModel = intersection.object.name;
    const isNotTower = intersectedModel !== types.TOWER_MESH_NAME;
    if (intersectedModel && isNotTower) {
      this.props.deactivateAllModels();
      this.onApplyMaterial([intersectedModel], extruder);
    }
  }

  onApplyMaterial(modelIds, extruder) {
    const modelsPaintData = this.composeModelsPaintData(modelIds);
    const someModelsHavePaintData = _.some(
      modelsPaintData,
      (model) => model.deleteRLE || model.deleteTexture
    );

    if (someModelsHavePaintData) {
      // prompt color override modal if model painted
      this.setState({
        modelIdsForColorOverride: modelIds,
        extruderForColorOverride: extruder,
        showColorOverrideModal: true,
      });
    } else {
      // proceed to override color
      this.props.activateModels(modelIds);
      this.props.applyMaterial(modelsPaintData, extruder);
      this.props.deactivateAllModels();
    }
  }

  closeColorOverrideModal() {
    this.setState({
      modelIdsForColorOverride: [],
      extruderForColorOverride: 0,
      showColorOverrideModal: false,
    });
  }

  handleColorOverride(modelsPaintData) {
    const { modelIdsForColorOverride, extruderForColorOverride } = this.state;
    this.props.activateModels(modelIdsForColorOverride);
    this.props.applyMaterial(modelsPaintData, extruderForColorOverride);
    this.closeColorOverrideModal();
  }

  renderColorOverrideModal() {
    if (!this.state.showColorOverrideModal) return null;
    const { modelIdsForColorOverride } = this.state;
    const modelsPaintData = this.composeModelsPaintData(
      modelIdsForColorOverride
    );
    const someModelsColored = _.some(
      modelsPaintData,
      (model) => model.deleteRLE
    );
    const someModelsTextured = _.some(
      modelsPaintData,
      (model) => model.deleteTexture
    );

    let colorData = '';
    if (someModelsColored && someModelsTextured)
      colorData = 'painting and stamps';
    else if (someModelsColored) colorData = 'painting';
    else if (someModelsTextured) colorData = 'stamps';

    return (
      <ConfirmationModal
        isWarning
        primaryLabel='Assign new color'
        secondaryLabel={`Are you sure you would like to clear ${colorData} for
        selected ${FormatUtils.pluralize('model', modelsPaintData.length)}?`}
        tertiaryLabel='This will overwrite all color information, and cannot be undone.'
        confirmLabel='Continue'
        onClickCancel={() => this.closeColorOverrideModal()}
        onClickConfirm={() => this.handleColorOverride(modelsPaintData)}
      />
    );
  }

  getModelToColor() {
    const { models } = this.props;
    const selectedModels = SlicerUtils.getSelectedModels(models);
    let modelToColor = null;
    if (selectedModels.length === 1) {
      [modelToColor] = selectedModels;
    }
    return modelToColor;
  }

  renderSliceViewButton() {
    const { project, models } = this.props;

    let allModelsHidden = true;
    TreeUtils.map(models, (model) => {
      if (model.visible) allModelsHidden = false;
    });

    const disabled = _.isEmpty(models) || allModelsHidden;
    const isProjectSliced = project && project.sliced;
    const sliceLabel = isProjectSliced ? 'Preview' : 'Slice';

    return (
      <ToolTipWrapper
        tooltip={disabled ? 'At least one model needs to be visible' : null}>
        <SliceButtonWrapper>
          <Button
            primary
            minWidth='6rem'
            disabled={disabled}
            onClick={() => this.props.changeView('slice')}>
            {sliceLabel}
          </Button>
        </SliceButtonWrapper>
      </ToolTipWrapper>
    );
  }

  renderViewButtons() {
    return (
      <ViewButtonsContainer>
        {this.renderSliceViewButton()}
      </ViewButtonsContainer>
    );
  }

  handleEnterPaint() {
    const modelToColor = this.getModelToColor();
    this.props.onEnterPaint(modelToColor);
  }

  handleClickButtonTool(tool) {
    const { currentTool } = this.props;
    if (tool === currentTool || !tool) {
      this.selectTool(null);
    } else if (tool) {
      this.selectTool(tool);
    }
  }

  renderTransformButtons() {
    const { currentTool } = this.props;
    return (
      <ControlButtonsContainer>
        <ActionButton
          icon={Icons.project.move}
          title='Move'
          primary={currentTool === 'translate'}
          onClick={() => this.handleClickButtonTool('translate')}
        />
        <ActionButton
          icon={Icons.project.rotate}
          title='Rotate'
          primary={currentTool === 'rotate'}
          onClick={() => this.handleClickButtonTool('rotate')}
        />
        <ActionButton
          icon={Icons.project.scale}
          title='Scale'
          primary={currentTool === 'scale'}
          onClick={() => this.handleClickButtonTool('scale')}
        />
        <ActionButton
          icon={Icons.basic.more}
          title='More'
          primary={currentTool === 'context'}
          onClick={() => this.handleClickButtonTool('context')}
        />
      </ControlButtonsContainer>
    );
  }

  renderMaterialInfo(color, material) {
    const colorHex = SlicerUtils.rgbaArrayToHexString(color).toUpperCase();
    return (
      <ListViewMaterialInfo>
        <Body1 noSpacing>{MaterialUtils.getClosestColor(colorHex)}</Body1>
        <Caption noSpacing grey>
          {MaterialUtils.getListLabel(material)}
        </Caption>
      </ListViewMaterialInfo>
    );
  }

  renderExtruderButtons() {
    const { project, materials, materialListView, currentExtruderIndex } =
      this.props;
    return (
      <ExtruderButtonsContainer
        materialListView={materialListView}
        inMobileView={this.state.inMobileView}
        toolboxWrapperHeight={this.state.toolboxWrapperHeight}
        length={project.colors.length}>
        {_.map(project.materialIds, (materialId, index) => {
          const material = materials[materialId];
          const color = project.colors[index];
          return (
            <ColorSwatchInfoContainer
              key={index}
              materialListView={materialListView}>
              <ColorSwatch
                small={materialListView}
                color={color}
                selected={index === currentExtruderIndex}
                onClick={() => this.onSelectMaterial(material, index)}
                onRelease={(modelIds) =>
                  this.onReleaseMaterial(modelIds, index)
                }
                handleModelHighlight={() => this.handleModelHighlight()}
              />
              {materialListView && this.renderMaterialInfo(color, material)}
            </ColorSwatchInfoContainer>
          );
        })}
      </ExtruderButtonsContainer>
    );
  }

  renderEditMaterialTool() {
    const { currentExtruderIndex } = this.props;
    return (
      <MaterialTool
        currentView='place'
        project={this.props.project}
        currentExtruderIndex={currentExtruderIndex}
        dropdownOpen={() =>
          this.setState({
            dropDownOpenInMaterialTool: true,
          })
        }
        dropdownClose={() =>
          this.setState({
            dropDownOpenInMaterialTool: false,
          })
        }
        closeMaterialTool={() => this.onCloseEditMaterialTool()}
      />
    );
  }

  renderEditMaterialPanel() {
    const { showEditMaterialTool, inMobileView } = this.state;
    const { currentExtruderIndex, materialListView } = this.props;
    if (showEditMaterialTool && !inMobileView) {
      return (
        <MaterialToolContainer
          materialListView={materialListView}
          currentExtruderIndex={currentExtruderIndex}>
          {this.renderEditMaterialTool()}
        </MaterialToolContainer>
      );
    }
    return null;
  }

  renderEditMaterialPanelMobile() {
    const { showEditMaterialToolMobile } = this.state;
    if (!showEditMaterialToolMobile) return null;
    return (
      <MaterialToolContainerMobile>
        {this.renderEditMaterialTool()}
      </MaterialToolContainerMobile>
    );
  }

  renderMaterialListOrGridButton() {
    const { materialListView } = this.props;
    return (
      <ActionButton
        minimal
        icon={materialListView ? Icons.basic.list : Icons.basic.bubbles}
        title={`Switch to ${materialListView ? 'grid' : 'list'} view`}
        onClick={() => this.props.updateMaterialListView(!materialListView)}
      />
    );
  }

  renderEditMaterialButton() {
    const { currentExtruderIndex } = this.props;
    if (currentExtruderIndex === -1) return null;
    return (
      <EditMaterialButtonMobileWrapper>
        <Button
          clean
          expand
          onClick={() => this.handleOpenEditInputToolbox(currentExtruderIndex)}>
          Edit
        </Button>
      </EditMaterialButtonMobileWrapper>
    );
  }

  renderMaterialsPanel() {
    const { inMobileView } = this.state;
    const { onToggleSpliceTransitionModal, project } = this.props;
    return (
      <div>
        <ToolCollapsiblePanel
          forceOpen
          label='Materials'
          scroll={false}
          headerCustomButton={
            <>
              {this.renderMaterialListOrGridButton()}
              {this.goBackCleanupButton()}
            </>
          }>
          <MaterialPanelContent>
            {this.renderExtruderButtons()}
            {inMobileView && this.renderEditMaterialButton()}
            {project.materialIds.length > 1 && (
              <SpliceButtonWrapper>
                <Button
                  clean
                  expand
                  onClick={() => onToggleSpliceTransitionModal()}>
                  Splice and transition
                </Button>
              </SpliceButtonWrapper>
            )}
          </MaterialPanelContent>
        </ToolCollapsiblePanel>
        {this.renderEditMaterialPanel()}
      </div>
    );
  }

  renderMaterialsMobile() {
    const { showMaterialsMobile } = this.state;
    if (showMaterialsMobile) {
      return (
        <ToolboxMobileWrapper>
          {this.renderMaterialsPanel()}
        </ToolboxMobileWrapper>
      );
    }
    return null;
  }

  renderToolBoxControlPanel() {
    return (
      <div ref={this.toolboxWrapperRef}>
        <ToolCollapsiblePanel
          forceOpen
          label='Toolbox'
          scroll={false}
          headerCustomButton={this.goBackCleanupButton()}>
          {this.renderTransformButtons()}
          {this.renderToolboxContent()}
        </ToolCollapsiblePanel>
      </div>
    );
  }

  leftToolboxPanels() {
    return (
      <ToolboxWrapper>
        {this.renderToolBoxControlPanel()}
        {this.renderMaterialsPanel()}
      </ToolboxWrapper>
    );
  }

  renderToolboxControlMobile() {
    const { showToolboxControlMobile } = this.state;
    if (showToolboxControlMobile) {
      return (
        <ToolboxMobileWrapper>
          {this.renderToolBoxControlPanel()}
        </ToolboxMobileWrapper>
      );
    }
    return null;
  }

  toggleMaterialsMobile() {
    const { showMaterialsMobile, toolboxNavigation } = this.state;

    if (showMaterialsMobile) {
      this.setState({
        showMaterialsMobile: false,
      });
    } else {
      this.setState({
        toolboxNavigation: [
          ...toolboxNavigation,
          () => {
            this.toggleMaterialsMobile();
          },
        ],
        showMaterialsMobile: true,
        showObjectBrowserMobile: false,
      });
    }
  }

  toggleToolboxControlMobile() {
    const { currentTool, setCurrentExtruderIndex } = this.props;
    const { showToolboxControlMobile, toolboxNavigation } = this.state;

    if (showToolboxControlMobile) {
      if (currentTool) {
        this.handleClickButtonTool(null);
      }
      setCurrentExtruderIndex(-1);
      this.setState({
        showToolboxControlMobile: false,
      });
    } else {
      this.setState({
        toolboxNavigation: [
          ...toolboxNavigation,
          () => {
            this.toggleToolboxControlMobile();
          },
        ],
        showToolboxControlMobile: true,
        showObjectBrowserMobile: false,
      });
    }
  }

  toggleObjectBrowserMobile() {
    const { showObjectBrowserMobile } = this.state;
    this.setState({ showObjectBrowserMobile: !showObjectBrowserMobile });
    if (!showObjectBrowserMobile) {
      this.setState({
        toolboxNavigation: [() => this.toggleObjectBrowserMobile()],
      });
    }
    this.selectTool(null);
  }

  toggleProfilesPanelMobile() {
    const { toolboxNavigation } = this.state;
    this.props.toggleProfilesPanelMobile();
    if (toolboxNavigation.length === 0) {
      this.setState({
        toolboxNavigation: [() => this.props.toggleProfilesPanelMobile()],
      });
    }
  }

  renderMobileToolbarRight() {
    const { toolboxNavigation } = this.state;
    if (toolboxNavigation.length > 0) return null;
    return (
      <MobileToolbarRightWrapper>
        <GeneralButtonWrapper>
          <ActionButton
            icon={Icons.basic.settings}
            title='Project Settings'
            onClick={() => this.toggleProfilesPanelMobile()}
          />
        </GeneralButtonWrapper>
        <ActionButton
          onClick={() => this.toggleObjectBrowserMobile()}
          icon={Icons.basic.list}
          title='Object Browser'
        />
      </MobileToolbarRightWrapper>
    );
  }

  goBackCleanup() {
    const { toolboxNavigation } = this.state;
    // pop the stack and run the clean up function once back button is clicked
    toolboxNavigation.pop()();
    this.setState({
      toolboxNavigation: [...toolboxNavigation],
    });
  }

  goBackCleanupButton() {
    const { inMobileView } = this.state;

    if (!inMobileView) return null;
    return (
      <ActionButton
        onClick={() => this.goBackCleanup()}
        icon={Icons.basic.x}
        title='Close'
        minimal
      />
    );
  }

  renderMobileToolbarLeft() {
    const { toolboxNavigation } = this.state;

    return (
      <MobileToolbarLeftWrapper>
        {toolboxNavigation.length !== 0 ? null : (
          <>
            <GeneralButtonWrapper>
              <ActionButton
                onClick={() => this.props.toggleUploadModal()}
                icon={Icons.project.addModal}
                title='Add Models'
              />
            </GeneralButtonWrapper>
            <GeneralButtonWrapper>
              <ActionButton
                onClick={() => this.toggleToolboxControlMobile()}
                icon={Icons.project.editModal}
                title='Toolbox'
              />
            </GeneralButtonWrapper>
            <ActionButton
              onClick={() => this.toggleMaterialsMobile()}
              icon={Icons.project.materials}
              title='Materials'
            />
          </>
        )}
      </MobileToolbarLeftWrapper>
    );
  }

  getToolContent() {
    switch (this.props.currentTool) {
      case 'translate':
        return <TranslationTool />;
      case 'rotate':
        return <RotationTool />;
      case 'scale':
        return (
          <ScaleTool
            uniformScale={this.props.uniformScale}
            setUniformScale={this.props.setUniformScale}
          />
        );
      case 'context':
        return <ContextTool onEnterSupport={this.props.onEnterSupport} />;
      default:
        return null;
    }
  }

  renderToolboxContent() {
    const content = this.getToolContent();
    if (!content) return null;
    return (
      // passing toolboxNavigation to hide ToolboxContent when switching from large to small screen
      <ToolboxContent>{content}</ToolboxContent>
    );
  }

  renderToolbox() {
    return (
      <ToolboxContainer>
        {this.leftToolboxPanels()}
        <MobileToolbar id='mobileToolbar'>
          {this.renderMobileToolbarLeft()}
          {this.renderMobileToolbarRight()}
          {this.renderMaterialsMobile()}
          {this.renderObjectBrowserMobile()}
          {this.renderToolboxControlMobile()}
          {this.renderEditMaterialPanelMobile()}
        </MobileToolbar>
        <ToolboxContainer />
      </ToolboxContainer>
    );
  }

  render() {
    return (
      <>
        <HeaderBar />
        {this.renderToolbox()}
        {this.renderObjectBrowser()}
        {this.renderColorOverrideModal()}
        {this.renderViewButtons()}
      </>
    );
  }
}

export default PlaceView;

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import IotConnectionBar from './iotConnectionBar.jsx';

const mapStateToProps = (state) => ({
  connected: state.iot.connected,
});

export default withRouter(connect(mapStateToProps, null)(IotConnectionBar));

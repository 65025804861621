import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { actions } from '../../../reducers/iot/iot';
import DeviceActivation from './deviceActivation.jsx';

const mapStateToProps = (state) => ({
  initConnectionPending: state.iot.status.initConnectionPending,
  initConnectionSuccess: state.iot.status.initConnectionSuccess,
  activateDevicePending: state.iot.status.activateDevicePending,
  activateDeviceSuccess: state.iot.status.activateDeviceSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  activateDeviceRequest: (activationCode) =>
    dispatch(actions.activateDeviceRequest(activationCode)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeviceActivation)
);

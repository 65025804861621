import React from 'react';
import _ from 'lodash';

// make the matched prefix of an option label bold
export const highlightMatchPrefix = (label, filter) => [
  <b key={0}>{label.slice(0, filter.length)}</b>,
  <React.Fragment key={1}>{label.slice(filter.length)}</React.Fragment>,
];

// make the matched portions of an option label bold
export const highlightMatchSubstring = (label, filter) => {
  const labelLowercase = label.toLowerCase();
  const children = [];
  let currentIndex = 0;
  do {
    const nextIndex = labelLowercase.indexOf(filter, currentIndex);
    if (nextIndex >= currentIndex) {
      // from currentIndex to nextIndex is unmatched text
      children.push(
        <React.Fragment key={children.length}>
          {label.slice(currentIndex, nextIndex)}
        </React.Fragment>
      );
      // from nextIndex to nextIndex + (filter length) is matched text
      children.push(
        <b key={children.length}>
          {label.slice(nextIndex, nextIndex + filter.length)}
        </b>
      );
      currentIndex = nextIndex + filter.length;
    } else {
      break;
    }
  } while (currentIndex >= 0);
  if (currentIndex < label.length) {
    // handle any trailing unmatched text
    children.push(
      <React.Fragment key={children.length}>
        {label.slice(currentIndex)}
      </React.Fragment>
    );
  }
  return children;
};

export const flattenOptions = (options) =>
  _.reduce(
    options,
    (acc, option) => {
      if (_.has(option, 'items')) {
        acc.push({
          key: option.key,
          label: option.label,
          isGroupLabel: true,
          hideLabel: option.hideLabel || false,
          highlightLabel: option.highlightLabel || false,
        });
        acc.push(...flattenOptions(option.items));
      } else {
        acc.push(option);
      }
      return acc;
    },
    []
  );

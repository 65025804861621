import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ButtonWrapper } from '../projectSettings/projectSettings.styles';
import printerActions from '../../../reducers/printers/actions';
import { usePrevious } from '../../../hooks';

import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalFooter,
  Subtitle1,
  Body1,
} from '../../../shared';
import { fieldTypes } from '../../../constants';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const DeviceConfigurations = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &:empty {
    display: none;
  }
`;

const PaletteFields = [
  {
    label: 'Loading Offset',
    name: 'loadingOffset',
    value: (settings) => settings.configuration.loadingOffset,
    variants: [
      {
        type: fieldTypes.number,
        min: 0,
        gte: false,
        step: 1,
      },
    ],
  },
  {
    label: 'Print Value',
    name: 'printValue',
    value: (settings) => settings.configuration.printValue,
    variants: [
      {
        type: fieldTypes.number,
        min: 0,
        gte: false,
        step: 1,
      },
    ],
  },
  {
    label: 'Calibration Length',
    name: 'calibrationLength',
    value: (settings) => settings.configuration.calibrationLength,
    variants: [
      {
        type: fieldTypes.number,
        min: 0,
        gte: false,
      },
    ],
    units: 'mm',
  },
];

const NumberField = (props) => {
  const { field, setField, getField, onFail, disabled } = props;
  const { min, gte, max, lte, step } = field.variants[0];
  const value = getField(field.name);
  return (
    <Input
      type='number'
      key={field.name}
      label={field.label}
      value={value}
      min={min}
      gte={gte}
      max={max}
      lte={lte}
      step={step}
      units={field.units}
      onChangeSuccess={(val) => {
        setField(field.name, val);
      }}
      onChangeFailure={() => {
        onFail(field.name);
      }}
      disabled={disabled}
    />
  );
};

const PaletteCalibrationSettings = (props) => {
  const { toggleModal, printerId } = props;

  const dispatch = useDispatch();

  const printers = useSelector((state) => state.printers);

  const { updateCalibrationSettingsPending, updateCalibrationSettingsSuccess } =
    printers.status;

  const disabled =
    updateCalibrationSettingsPending && !updateCalibrationSettingsSuccess;

  const printer = printers.printers[printerId];
  const paletteCalibration = printer?.paletteCalibration;

  const [configuration, setConfiguration] = useState(
    paletteCalibration || {
      loadingOffset: 0,
      printValue: 0,
      calibrationLength: 0,
    }
  );

  const [validFields, setValidFields] = useState({
    loadingOffset: configuration.loadingOffset > 0,
    printValue: configuration.printValue > 0,
    calibrationLength: configuration.calibrationLength > 0,
  });

  // close modal after successful save
  const prevUpdateCalibrationSettingsPending = usePrevious(
    updateCalibrationSettingsPending
  );
  useEffect(() => {
    if (
      prevUpdateCalibrationSettingsPending &&
      !updateCalibrationSettingsPending &&
      updateCalibrationSettingsSuccess
    ) {
      toggleModal();
    }
  }, [
    prevUpdateCalibrationSettingsPending,
    toggleModal,
    updateCalibrationSettingsPending,
    updateCalibrationSettingsSuccess,
  ]);

  const setField = (fieldName, value) => {
    setValidFields((state) => ({
      ...state,
      [fieldName]: true,
    }));
    setConfiguration((state) => ({
      ...state,
      [fieldName]: value,
    }));
  };

  const getField = (field) => configuration[field];

  const onFail = (fieldName) => {
    setValidFields((state) => ({
      ...state,
      [fieldName]: false,
    }));
  };

  const onSave = () => {
    const { loadingOffset, printValue, calibrationLength } = configuration;

    dispatch(
      printerActions.updateCalibrationSettingsRequest(
        printerId,
        loadingOffset,
        printValue,
        calibrationLength
      )
    );
  };

  return (
    <Modal>
      <ModalHeader>
        <Subtitle1>Palette Calibration</Subtitle1>
      </ModalHeader>
      <Content>
        <Body1>
          Calibration values for Palette/Palette+ are saved with the printer
          profile to allow for re-use across projects.
        </Body1>
        <DeviceConfigurations>
          {PaletteFields.map((field) => (
            <NumberField
              key={field.name}
              getField={getField}
              setField={setField}
              onFail={onFail}
              field={field}
              config={configuration}
              disabled={disabled}
            />
          ))}
        </DeviceConfigurations>
      </Content>
      <ModalFooter>
        <ButtonWrapper>
          <Button onClick={toggleModal} disabled={disabled}>
            Cancel
          </Button>
        </ButtonWrapper>
        <Button
          primary
          onClick={onSave}
          disabled={
            disabled || Object.values(validFields).some((valid) => !valid)
          }>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PaletteCalibrationSettings;

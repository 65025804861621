import 'regenerator-runtime/runtime';
import { call, select } from 'redux-saga/effects';

import { getSlicerState, getThreeState, handleGenericError } from '../common';

import { TreeUtils } from '../../utils';
import { setRenderFlag } from './animationFrame';

export default function* removeModelsFromScene(action) {
  try {
    let { models } = action.payload;
    if (!models) {
      ({ models } = yield select(getSlicerState));
    }
    const { scene } = yield select(getThreeState);
    if (!scene) return;
    TreeUtils.map(models, (model) => {
      if (model.mesh) {
        scene.remove(model.mesh);
      }
    });
    setRenderFlag();
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

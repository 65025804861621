import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Button,
  FileUploader,
  ModalHeader,
  ModalFooter,
} from '../../../shared';

import { Subtitle1 } from '../../../shared/typography/typography';

class UploadProfile extends Component {
  componentDidUpdate(prevProps) {
    const needsRedirect =
      prevProps.requestSuccess !== this.props.requestSuccess;
    if (needsRedirect) {
      this.handleRedirect();
    }
  }

  onChange(e) {
    this.props.parseProfile(e.target.files[0]);
  }

  renderHeader() {
    return (
      <ModalHeader>
        <Subtitle1>Upload existing profile</Subtitle1>
      </ModalHeader>
    );
  }

  renderFileUploader() {
    return (
      <FileUploader
        minHeight='25em'
        accept={['.fff', '.ini', '.ksp']}
        onChange={(e) => this.onChange(e)}
        secondaryLabel='Accepts profiles from Simplify3D (.fff), Slic3r/PrusaSlicer (.ini), and KISSlicer (.ksp)'
      />
    );
  }

  renderFooter() {
    return (
      <ModalFooter>
        <Button primary onClick={() => this.props.onClickClose()}>
          Close
        </Button>
      </ModalFooter>
    );
  }

  handleRedirect() {
    const { mode, requestSuccess } = this.props;
    if (requestSuccess) {
      if (mode === 'printer') {
        this.props.onUpload();
      } else if (mode === 'style') {
        this.props.onClickClose();
        this.props.showImportStyleModal();
      }
    }
  }

  renderMainPanelContent() {
    return (
      <>
        {this.renderHeader()}
        {this.renderFileUploader()}
        {this.renderFooter()}
      </>
    );
  }

  render() {
    return this.renderMainPanelContent();
  }
}

export default withRouter(UploadProfile);

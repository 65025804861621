import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { usePrevious } from '../../../hooks';
import { actions } from '../../../reducers/slicer/slicer';

import {
  ActionButton,
  Button,
  ConfirmationModal,
  DeviceDropdown,
  Dropdown,
  Subtitle2OneLine,
} from '../../../shared';

import {
  PrinterUtils,
  ProjectUtils,
  SortUtils,
  TreeUtils,
} from '../../../utils';

import {
  Content,
  Container,
  DeviceSelectionContainer,
  DeviceDropdownWrapper,
  Header,
} from './profilesPanel.styles';
import { Icons } from '../../../themes';

const ProfilesPanel = (props) => {
  const [printerId, setPrinterId] = useState(props.printerId);
  const [styleId, setStyleId] = useState(props.styleId);
  const [deviceConfig, setDeviceConfig] = useState(props.deviceConfig);
  const [remappingInputs, setRemappingInputs] = useState(false);
  const [showConfirmChangesModal, setShowConfirmChangesModal] = useState('');

  const dispatch = useDispatch();

  const { models, project, printers, onOpenProjectSettings = () => {} } = props;

  const { remapperContext } = useSelector((state) => state.slicer);
  const {
    remapProjectInputsPending,
    remapProjectInputsSuccess,
    updateProjectSettingsPending,
    updateProjectSettingsSuccess,
  } = useSelector((state) => state.slicer.status);

  const areStyleSettingsChanged = useMemo(() => {
    const styleProfile = _.find(
      printers[props.printerId].styles,
      (style) => style.id === props.styleId
    );
    const projectStyles = project.style;
    return !PrinterUtils.areStylesEqual(styleProfile, projectStyles);
  }, [printers, project.style, props.printerId, props.styleId]);

  const updateProject = useCallback(
    (newPrinterId, newStyleId, newDeviceConfig) => {
      const updatedSettings = {};

      if (newPrinterId !== props.printerId || newStyleId !== props.styleId) {
        const styleProfile = _.find(
          printers[newPrinterId].styles,
          (style) => style.id === newStyleId
        );
        updatedSettings.printerId = newPrinterId;
        updatedSettings.styleId = newStyleId;
        updatedSettings.styles = { ...styleProfile };
      }

      if (!_.isEqual(newDeviceConfig, props.deviceConfig)) {
        updatedSettings.deviceConfig = newDeviceConfig;
      }

      dispatch(
        actions.updateProjectSettingsRequest(project.id, updatedSettings)
      );
    },
    [
      dispatch,
      printers,
      project.id,
      props.deviceConfig,
      props.printerId,
      props.styleId,
    ]
  );

  // if inputs were remapped successfully, save changes in local state now
  const prevRemapProjectInputsPending = usePrevious(remapProjectInputsPending);
  const prevUpdateProjectSettingsPending = usePrevious(
    updateProjectSettingsPending
  );
  useEffect(() => {
    const projectSettingsUpdateSuccess =
      prevUpdateProjectSettingsPending &&
      !updateProjectSettingsPending &&
      updateProjectSettingsSuccess;
    const projectSettingsUpdateFailure =
      prevUpdateProjectSettingsPending &&
      !updateProjectSettingsPending &&
      !updateProjectSettingsSuccess;

    // failed to save changes -- revert selections
    if (projectSettingsUpdateFailure) {
      setPrinterId(props.printerId);
      setStyleId(props.styleId);
      setDeviceConfig(props.deviceConfig);
    }

    if (remappingInputs) {
      const remapped =
        prevRemapProjectInputsPending &&
        !remapProjectInputsPending &&
        remapProjectInputsSuccess;
      if (remapped) {
        // inputs were remapped successfully
        setRemappingInputs(false);
        updateProject(printerId, styleId, deviceConfig);
      } else if (projectSettingsUpdateSuccess) {
        // user selected a different deviceConfig instead of remapping inputs
        setRemappingInputs(false);
        setDeviceConfig(props.deviceConfig);
        updateProject(printerId, styleId, props.deviceConfig);
      } else if (!remapperContext) {
        // user cancelled out of remapping -- revert settings
        setRemappingInputs(false);
        setPrinterId(props.printerId);
        setStyleId(props.styleId);
        setDeviceConfig(props.deviceConfig);
      }
    }
  }, [
    deviceConfig,
    prevRemapProjectInputsPending,
    prevUpdateProjectSettingsPending,
    printerId,
    props.deviceConfig,
    props.printerId,
    props.styleId,
    remapProjectInputsPending,
    remapProjectInputsSuccess,
    remapperContext,
    remappingInputs,
    styleId,
    updateProject,
    updateProjectSettingsPending,
    updateProjectSettingsSuccess,
  ]);

  const { defaultPrinter, defaultStyle } = useSelector(
    (state) => state.auth.newProjectOptions
  );

  const checkInputRemapperRequirement = (newPrinterId, newDeviceConfig) => {
    const printer = printers[newPrinterId];

    const extrudersUsed = TreeUtils.getExtrudersUsed(
      models,
      project.style,
      project.colors.length
    );
    const usedInputCount = extrudersUsed.reduce(
      (accumulator, ext) => (ext ? accumulator + 1 : accumulator),
      0
    );

    const availableInputCount = ProjectUtils.getInputCount(
      newDeviceConfig,
      printer
    );

    return {
      isIncompatibleDevice: usedInputCount > availableInputCount,
      extrudersUsed,
      availableInputCount,
    };
  };

  const handleChange = (newPrinterId, newStyleId, newDeviceConfig) => {
    const { isIncompatibleDevice, extrudersUsed, availableInputCount } =
      checkInputRemapperRequirement(newPrinterId, newDeviceConfig);

    if (isIncompatibleDevice) {
      const newRemapperContext = {
        projectId: project.id,
        colors: project.colors,
        printerId: newPrinterId,
        styleId: newStyleId,
        style: project.style,
        existingInputsSeen: extrudersUsed,
        availableInputCount,
        triggeredManually: false,
      };
      setRemappingInputs(true);
      dispatch(actions.setInputRemapperContext(newRemapperContext));
    } else {
      updateProject(newPrinterId, newStyleId, newDeviceConfig);
    }
  };

  const handlePrinterChange = (newPrinterId) => {
    const printer = printers[newPrinterId];
    let selectedStyleId;
    if (newPrinterId === defaultPrinter && defaultStyle !== 'auto') {
      // select their default style profile
      selectedStyleId = defaultStyle;
    } else {
      // select the most recent style profile
      selectedStyleId = PrinterUtils.getMostRecentStyle(printer).id;
    }
    setPrinterId(newPrinterId);
    setStyleId(selectedStyleId);
    let newDeviceConfig = deviceConfig;
    if (printer.machineSettings.extension === 'mcfx') {
      setDeviceConfig(null);
      newDeviceConfig = null;
    }
    if (areStyleSettingsChanged) {
      setShowConfirmChangesModal('printer');
    } else {
      handleChange(newPrinterId, selectedStyleId, newDeviceConfig);
    }
  };

  const handleStyleChange = (newStyleId) => {
    setStyleId(newStyleId);
    if (areStyleSettingsChanged) {
      setShowConfirmChangesModal('style');
    } else {
      handleChange(printerId, newStyleId, deviceConfig);
    }
  };

  const handleDeviceConfigChange = (newDeviceConfig) => {
    setDeviceConfig(newDeviceConfig);
    handleChange(printerId, styleId, newDeviceConfig);
  };

  const renderPrinterDropdown = () => {
    const printerOptions = _.map(
      PrinterUtils.sortPrinters(printers),
      (printer) => ({
        label: printer.machineSettings.name,
        value: printer.id,
        disabled: printer.styles.length === 0,
      })
    );

    return (
      <Dropdown
        rightAlign
        allowFilter
        descriptionIcon={Icons.fdm.extruding}
        options={printerOptions}
        value={printerId}
        onChange={handlePrinterChange}
      />
    );
  };

  const renderStyleDropdown = () => {
    // available styles are dependent on selected printer
    const printerStyles = printers[printerId].styles;
    const styleOptions = _.map(
      SortUtils.sortByName(printerStyles),
      (style) => ({
        label: style.name,
        value: style.id,
      })
    );

    return (
      <Dropdown
        rightAlign
        descriptionIcon={Icons.fdm.styleProfile}
        value={styleId}
        options={styleOptions}
        onChange={handleStyleChange}
      />
    );
  };

  const renderDeviceDropdown = () => {
    const isElementSelected =
      printers[printerId].machineSettings.extension === 'mcfx';
    return (
      <DeviceDropdownWrapper>
        <DeviceDropdown
          hideLabel
          deviceConfig={deviceConfig}
          disabled={isElementSelected}
          onChange={handleDeviceConfigChange}
        />
      </DeviceDropdownWrapper>
    );
  };

  const renderCalibrationSettingsButton = () => {
    if (deviceConfig?.type !== 'palette') return null;
    return (
      <ActionButton
        title='Update Calibration Settings'
        icon={Icons.basic.tune}
        onClick={props.toggleCalibrationModal}
      />
    );
  };

  const renderHeader = () => {
    return (
      <Header>
        <Subtitle2OneLine>Project</Subtitle2OneLine>
        <ActionButton
          title='Close'
          icon={Icons.basic.x}
          minimal
          onClick={props.onCloseMobile}
        />
      </Header>
    );
  };

  const renderDeviceSelectionContainer = () => {
    return (
      <DeviceSelectionContainer>
        {renderDeviceDropdown()}
        {renderCalibrationSettingsButton()}
      </DeviceSelectionContainer>
    );
  };

  const renderConfirmChangesModal = () => (
    <ConfirmationModal
      primaryLabel={`Change ${showConfirmChangesModal} profile`}
      secondaryLabel={`Are you sure you want to change your ${showConfirmChangesModal} profile?`}
      tertiaryLabel='Current project settings will be overwritten.'
      isWarning
      confirmLabel='Continue'
      onClickConfirm={() => {
        setShowConfirmChangesModal('');
        handleChange(printerId, styleId, deviceConfig);
      }}
      onClickCancel={() => {
        setPrinterId(props.printerId);
        setStyleId(props.styleId);
        setShowConfirmChangesModal('');
      }}
    />
  );

  return (
    <>
      {showConfirmChangesModal && renderConfirmChangesModal()}
      <Container>
        {renderHeader()}
        <Content>
          {renderPrinterDropdown()}
          {renderStyleDropdown()}
          {renderDeviceSelectionContainer()}
          <Button clean expand onClick={onOpenProjectSettings}>
            Project settings
          </Button>
        </Content>
      </Container>
    </>
  );
};

export default ProfilesPanel;

import { Icons, Links } from '../../themes';
import Routes from '../../router/routes';

export const MenuMeta = [
  {
    items: [
      {
        label: 'Projects',
        route: Routes.toHome(),
        iconName: Icons.project.project,
      },
      {
        label: 'Devices',
        route: Routes.toManageDevices(),
        iconName: Icons.mosaic.hub,
      },
    ],
  },
  {
    title: 'Profiles',
    items: [
      {
        label: 'Printers',
        route: Routes.toManagePrinters(),
        iconName: Icons.fdm.extruding,
      },
      {
        label: 'Materials',
        route: Routes.toManageMaterials(),
        iconName: Icons.fdm.material,
      },
    ],
  },
];

export const SupportMeta = [
  {
    label: 'Get help with Canvas',
    link: Links.zendesk,
  },
  {
    label: 'Report a bug',
    link: Links.issueTracker,
  },
  {
    label: 'Open-source licenses',
    link: Links.ossLicenses,
  },
];

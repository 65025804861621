import React from 'react';

import {
  Container,
  TerminalLogs,
  LogItem,
  TerminalControls,
  TerminalButtons,
  TerminalInputContainer,
} from './deviceTerminal.styles';

import { Button, AbstractInput } from '../../../../../shared';

import { useInput } from '../../../../../hooks';
import { DeviceUtils } from '../../../../../utils';
import Placeholder from '../../placeholder.jsx';

const DeviceTerminal = ({
  currentDeviceState,
  commandHistory = [],
  onSendCommand = () => {},
  onClearCommandHistory = () => {},
}) => {
  const { inputValue, inputError, handleInputChange } = useInput('');

  const isConnected = DeviceUtils.isConnectedToPrinter(currentDeviceState);
  if (!isConnected) {
    return <Placeholder borderless>No printer connected</Placeholder>;
  }

  const handleSendCommand = () => {
    onSendCommand(inputValue);
    handleInputChange('');
  };

  const handleClearLogs = () => {
    onClearCommandHistory();
  };

  return (
    <Container>
      <TerminalLogs>
        {commandHistory.map((log, index) => (
          <LogItem key={index}>
            {log.type}: {log.command}
          </LogItem>
        ))}
      </TerminalLogs>
      <TerminalControls>
        <AbstractInput
          type='text'
          placeholder='Enter command'
          autoFocus
          value={inputValue}
          onChangeSuccess={(value) => handleInputChange(value)}
          onChangeFailure={(value) => handleInputChange(value, true)}
          StyledContainer={TerminalInputContainer}
        />
        <TerminalButtons>
          <Button
            onClickKeyTrigger='Enter'
            disabled={!inputValue || inputError}
            onClick={handleSendCommand}>
            Send
          </Button>
          <Button clean onClick={handleClearLogs}>
            Clear terminal
          </Button>
        </TerminalButtons>
      </TerminalControls>
    </Container>
  );
};

export default DeviceTerminal;

import Constants from '../../sagas/constants';

const Utils = {
  formatFilename: (name) =>
    name
      .trim()
      .replace(/[\s:;=]/g, '-')
      .replace(/['"`\\/*[\]()<>|,^%?]/g, '')
      .replace(/-+/g, '-'),
  downloadBlob: (filename, blob) => {
    if (!(blob instanceof Blob)) return;
    const url = URL.createObjectURL(blob);
    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', url);
    linkElement.setAttribute('download', Utils.formatFilename(filename));
    linkElement.setAttribute('tabindex', '-1');
    linkElement.style.display = 'none';
    linkElement.style.pointerEvents = 'none';
    document.body.appendChild(linkElement);
    linkElement.click();
    document.body.removeChild(linkElement);
  },
  arrayBufferToBase64: (arrayBuffer) => {
    /* eslint-disable no-bitwise */
    // Taken from https://gist.github.com/jonleighton/958841#file-base64arraybuffer-js

    // Converts an ArrayBuffer directly to base64, without any intermediate 'convert to string then
    // use window.btoa' step. According to my tests, this appears to be a faster approach:
    // http://jsperf.com/encoding-xhr-image-data/5

    /*
    MIT LICENSE
    Copyright 2011 Jon Leighton
    Permission is hereby granted, free of charge, to any person obtaining a copy of this
      software and associated documentation files (the "Software"), to deal in the
      Software without restriction, including without limitation the rights to use, copy,
      modify, merge, publish, distribute, sublicense, and/or sell copies of the Software,
      and to permit persons to whom the Software is furnished to do so, subject to the
      following conditions:
    The above copyright notice and this permission notice shall be included in all copies
      or substantial portions of the Software.
    THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
      INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
      PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT
      HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION
      OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE
      SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
    */
    let base64 = '';
    const encodings =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

    const bytes = new Uint8Array(arrayBuffer);
    const { byteLength } = bytes;
    const byteRemainder = byteLength % 3;
    const mainLength = byteLength - byteRemainder;

    let a;
    let b;
    let c;
    let d;
    let chunk;

    // Main loop deals with bytes in chunks of 3
    for (let i = 0; i < mainLength; i += 3) {
      // Combine the three bytes into a single integer
      chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

      // Use bitmasks to extract 6-bit segments from the triplet
      a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
      b = (chunk & 258048) >> 12; //   258048   = (2^6 - 1) << 12
      c = (chunk & 4032) >> 6; //      4032     = (2^6 - 1) << 6
      d = chunk & 63; //               63       = 2^6 - 1

      // Convert the raw binary segments to the appropriate ASCII encoding
      base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
    }

    // Deal with the remaining bytes and padding
    if (byteRemainder === 1) {
      chunk = bytes[mainLength];

      a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

      // Set the 4 least significant bits to zero
      b = (chunk & 3) << 4; // 3 = 2^2 - 1

      base64 += `${encodings[a]}${encodings[b]}==`;
    } else if (byteRemainder === 2) {
      chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

      a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
      b = (chunk & 1008) >> 4; //   1008  = (2^6 - 1) << 4

      // Set the 2 least significant bits to zero
      c = (chunk & 15) << 2; //     15    = 2^4 - 1

      base64 += `${encodings[a]}${encodings[b]}${encodings[c]}=`;
    }

    return base64;
    /* eslint-enable no-bitwise */
  },
  asciiStlToBinary: (ascii) => {
    // using param reassignment for earlier garbage collection of original ASCII content
    // eslint-disable-next-line no-param-reassign
    ascii = ascii.split(/\r\n|\r|\n/g).map((line) => line.trim().toLowerCase());
    const lines = ascii;
    const LITTLE_ENDIAN = true;
    const HEADER_SIZE = 84;
    // account for STL files with more than one "solid" group
    const facetLineCount = lines.filter(
      (line) =>
        line && !line.startsWith('solid') && !line.startsWith('endsolid')
    ).length;
    const facetCount = facetLineCount / 7; // 7 lines per facet
    const size = HEADER_SIZE + facetCount * 50; // total bytes in binary STL
    const buffer = new ArrayBuffer(size);
    // populate header (leave the first 80 bytes as zeros)
    const triangleCountView = new DataView(buffer, 80, 4);
    triangleCountView.setUint32(0, facetCount, LITTLE_ENDIAN);
    // add each facet
    let facetIndex = 0;
    let lineParts;
    for (let i = 1; i < lines.length - 7 /* no increment condition */; ) {
      const facetView = new DataView(buffer, HEADER_SIZE + facetIndex * 50, 48);
      while (lines[i].startsWith('endsolid')) {
        // handle STL files with more than one "solid" group
        // "endsolid"
        // "solid ascii"
        i += 2;
      }
      // "facet normal X Y Z"
      lineParts = lines[i].split(/\s+/);
      facetView.setFloat32(0, Number(lineParts[2]), LITTLE_ENDIAN);
      facetView.setFloat32(4, Number(lineParts[3]), LITTLE_ENDIAN);
      facetView.setFloat32(8, Number(lineParts[4]), LITTLE_ENDIAN);
      i++;
      // "outer loop"
      i++;
      // "vertex X Y Z"
      // "vertex X Y Z"
      // "vertex X Y Z"
      for (let j = 0; j < 3; j++) {
        lineParts = lines[i].split(/\s+/);
        facetView.setFloat32((j + 1) * 12, Number(lineParts[1]), LITTLE_ENDIAN);
        facetView.setFloat32(
          (j + 1) * 12 + 4,
          Number(lineParts[2]),
          LITTLE_ENDIAN
        );
        facetView.setFloat32(
          (j + 1) * 12 + 8,
          Number(lineParts[3]),
          LITTLE_ENDIAN
        );
        i++;
      }
      // "endloop"
      // "endfacet"
      i += 2;
      // leave the two attribute bytes as zeros
      facetIndex++;
    }
    return buffer;
  },
  arrayBufferToString: (buf, maxLength) => {
    const bufView = new Int8Array(buf, 0, maxLength || buf.byteLength);
    const decoder = new TextDecoder();
    return decoder.decode(bufView);
  },
  formatFileSize: (sizeInBytes) => {
    if (sizeInBytes <= 1024 * 2) {
      return `${sizeInBytes} bytes`;
    }
    if (sizeInBytes <= 1024 ** 2 * 2) {
      return `${Math.round((sizeInBytes * 10) / 1024) / 10} kB`;
    }
    if (sizeInBytes <= 1024 ** 3) {
      return `${Math.round((sizeInBytes * 10) / 1024 ** 2) / 10} MB`;
    }
    return `${Math.round((sizeInBytes * 10) / 1024 ** 3) / 10} GB`;
  },
  loadStl: async (file) =>
    new Promise((fulfill, reject) => {
      try {
        if (file.size > Constants.MAX_UPLOAD_SIZE_BYTES) {
          reject(
            new Error(
              `Model size (${Utils.formatFileSize(
                file.size
              )}) exceeds maximum size of ${Utils.formatFileSize(
                Constants.MAX_UPLOAD_SIZE_BYTES
              )}`
            )
          );
          return;
        }
        const reader = new FileReader();
        // error handling
        reader.addEventListener('error', (event) =>
          reject(new Error(`Error loading file (${event.target.error.code})`))
        );
        reader.addEventListener('abort', () =>
          reject(new Error('Upload aborted'))
        );
        // successful load
        reader.addEventListener('load', (event) => {
          try {
            const buf = event.target.result;
            if (!reader.result) {
              reject(new Error('No model data was uploaded'));
              return;
            }
            // check for ASCII header to determine if a full
            // file format conversion is needed
            const scanLength = Math.min(300, buf.byteLength);
            let binaryStl = buf;
            const headerAsAscii = Utils.arrayBufferToString(buf, scanLength);
            if (
              headerAsAscii.startsWith('solid') &&
              headerAsAscii.includes('facet') &&
              headerAsAscii.includes('normal')
            ) {
              // these three facts mean this file is almost certainly ASCII -- convert it to binary
              binaryStl = Utils.asciiStlToBinary(
                Utils.arrayBufferToString(buf)
              );
            } else if ((buf.byteLength - 84) % 50) {
              reject(new Error('Incomplete model data'));
              return;
            }
            fulfill(binaryStl);
          } catch (err) {
            reject(new Error(`Failed to load model '${file.name}'`));
          }
        });
        reader.readAsArrayBuffer(file);
      } catch (err) {
        reject(err);
      }
    }),
};

export default Utils;

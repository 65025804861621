import _ from 'lodash';

import Simplify3DParser from '../profile-import/simplify3d';
import Slic3rParser from '../profile-import/slic3r';
import KISSlicerParser from '../profile-import/kisslicer';
import SortUtils, {
  PrinterSortMode,
  PrinterSortDirection,
  PrinterSortDefault,
  StyleSortMode,
  StyleSortDirection,
  StyleSortDefault,
} from '../sort/sort';

const Utils = {
  formatMachineSettings: (machine) => {
    const { bedSize, originOffset, sideTransitionCoordinates } = machine;
    const formatted = { ...machine };
    if (Array.isArray(bedSize)) {
      formatted.bedSize = {
        x: bedSize[0],
        y: bedSize[1],
        z: bedSize[2],
      };
    }
    if (Array.isArray(originOffset)) {
      formatted.originOffset = {
        x: originOffset[0],
        y: originOffset[1],
      };
    }
    if (Array.isArray(sideTransitionCoordinates)) {
      formatted.sideTransitionCoordinates = {
        x: sideTransitionCoordinates[0],
        y: sideTransitionCoordinates[1],
      };
    }
    return formatted;
  },

  parseSlicerProfile: async (file) =>
    new Promise((fulfill, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onloadend = () => {
        const fileContents = reader.result;
        let parser;
        if (file.name.endsWith('.fff')) {
          parser = new Simplify3DParser();
        } else if (file.name.endsWith('.ini')) {
          parser = new Slic3rParser();
        } else if (file.name.endsWith('.ksp')) {
          parser = new KISSlicerParser();
        } else {
          reject(new Error('Invalid file type'));
          return;
        }
        try {
          const parsedProfile = parser.parse(fileContents);
          fulfill(parsedProfile);
        } catch (err) {
          reject(err);
        }
      };
    }),

  /**
   * sort an array of printers in the order specified by the sortBy values
   * @param printersList array of printers
   * @param sortBy object containing a sorting mode and direction, both integers
   * @returns array of sorted printers
   */
  sortPrinters: (printersList, sortBy = PrinterSortDefault) => {
    if (_.isEmpty(printersList)) return [];

    const { mode, direction } = sortBy;
    const descending = direction === PrinterSortDirection.DESCENDING;

    switch (mode) {
      case PrinterSortMode.NAME: {
        return SortUtils.sortByName(
          printersList,
          descending,
          'machineSettings.name'
        );
      }
      case PrinterSortMode.DATE_MODIFIED: {
        return SortUtils.sortByModified(printersList, descending);
      }
      case PrinterSortMode.DATE_CREATED: {
        return SortUtils.sortByCreated(printersList, descending);
      }
      default: {
        return SortUtils.sortByName(
          printersList,
          descending,
          'machineSettings.name'
        );
      }
    }
  },

  /**
   * sort an array of styles in the order specified by the sortBy values
   * @param stylesList array of style profiles
   * @param sortBy object containing a sorting mode and direction, both integers
   * @returns array of sorted styles
   */
  sortStyles: (stylesList, sortBy = StyleSortDefault) => {
    if (_.isEmpty(stylesList)) return [];

    const { mode, direction } = sortBy;
    const descending = direction === StyleSortDirection.DESCENDING;

    switch (mode) {
      case StyleSortMode.NAME: {
        return SortUtils.sortByName(stylesList, descending);
      }
      case StyleSortMode.DATE_MODIFIED: {
        return SortUtils.sortByModified(stylesList, descending, 'timestamp');
      }
      case StyleSortMode.DATE_CREATED: {
        return SortUtils.sortByCreated(stylesList, descending);
      }
      default: {
        return SortUtils.sortByName(stylesList, descending);
      }
    }
  },

  /**
   * get the most recent printer from a list of printers
   * @param printers
   * @returns the printer with the most recent timestamp
   */
  getMostRecentPrinter: (printers) =>
    _.reduce(printers || [], (mostRecentSoFar, printer) =>
      printer.machineSettings.timestamp >
      mostRecentSoFar.machineSettings.timestamp
        ? printer
        : mostRecentSoFar
    ),

  /**
   * get the most recent style of a printer
   * @param printer
   * @returns the style with the most recent timestamp
   */
  getMostRecentStyle: (printer) =>
    _.reduce(printer.styles || [], (mostRecentSoFar, style) =>
      style.timestamp > mostRecentSoFar.timestamp ? style : mostRecentSoFar
    ),

  getDefaultPrinterAndStyle: (defPrinterId, defStyleId, printers) => {
    let defaultPrinterId = defPrinterId;
    let defaultStyleId = defStyleId;
    if (defaultPrinterId !== 'auto') {
      if (printers[defaultPrinterId]) {
        // printer exists
        if (printers[defaultPrinterId].styles.length === 0) {
          // no printer styles
          defaultPrinterId = 'auto';
          defaultStyleId = 'auto';
        } else if (
          defaultStyleId !== 'auto' &&
          !_.find(
            printers[defaultPrinterId].styles,
            (style) => style.id === defaultStyleId
          ) // style does not exist
        ) {
          defaultStyleId = 'auto';
        }
      } else {
        defaultPrinterId = 'auto';
        defaultStyleId = 'auto';
      }
    }
    if (
      defaultPrinterId !== 'auto' &&
      defaultStyleId !== 'auto' &&
      !_.find(
        printers[defaultPrinterId].styles,
        (style) => style.id === defaultStyleId
      )
    ) {
      if (printers[defaultPrinterId].styles.length === 0) {
        defaultPrinterId = 'auto';
      }
      defaultStyleId = 'auto';
    }
    let printerId = defaultPrinterId;
    if (defaultPrinterId === 'auto') {
      const validPrinters = [];
      _.each(printers, (printer) => {
        if (printer.styles.length > 0) {
          validPrinters.push(printer);
        }
      });
      printerId = Utils.getMostRecentPrinter(validPrinters).id;
      // styleId must be auto, will be handled below
      // styleId = most recent style in printers[printerId]
    }
    let styleId = defaultStyleId;
    if (defaultStyleId === 'auto') {
      styleId = Utils.getMostRecentStyle(printers[printerId]).id;
    }

    return { printerId, styleId };
  },

  omitNonStyleFields: (style) =>
    _.omit(style, ['name', 'id', 'created', 'timestamp', 'icon']),

  areStylesEqual: (styleSetA, styleSetB) => {
    const aStylesOfInterest = Utils.omitNonStyleFields(styleSetA);
    const bStylesOfInterest = Utils.omitNonStyleFields(styleSetB);
    return _.isEqual(aStylesOfInterest, bStylesOfInterest);
  },

  diffStyles: (styleSetA, styleSetB) =>
    _.reduce(
      styleSetA,
      (changedFields, value, key) => {
        if (
          styleSetB[key] &&
          _.isEqual(value.units, undefined) &&
          _.isEqual(styleSetB[key].value, 'auto')
        )
          return changedFields;
        return _.isEqual(value, styleSetB[key])
          ? changedFields
          : [...changedFields, key];
      },
      []
    ),
};

export default Utils;

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';

import reducer from './reducers';
import THREEtypes from './three/types';
import IoTtypes from './iot/types';
import * as storage from './localStorage';

import { sagas, runSagas } from '../sagas/sagas';

const persistedState = storage.loadState();

// options for redux logger
// read more: https://github.com/evgenyrodionov/redux-logger#options
const loggerOptions = {
  collapsed: true,
  timestamp: false,
};

const actionsToIgnore = [
  THREEtypes.HIGHLIGHT_MODELS,
  THREEtypes.REMOVE_MODEL_HIGHLIGHTS,
  THREEtypes.HOVER_MODELS,
  IoTtypes.RECEIVE_MESSAGE,
  IoTtypes.SET_DEVICE_CONNECTED_STATUS,
  IoTtypes.UPDATE_DEVICE_STATE_REQUEST,
  IoTtypes.UPDATE_DEVICE_STATE_SUCCESS,
];

// to make logger ignore actions specified above,
// add `process.env.REACT_APP_CONCISE_REDUX_LOGGER=true` to .env.development.local
if (process.env.REACT_APP_CONCISE_REDUX_LOGGER === 'true') {
  loggerOptions.predicate = (getState, action) =>
    !actionsToIgnore.includes(action.type);
}

const logger = createLogger(loggerOptions);

// add `process.env.REACT_APP_HAS_REDUX_LOGGER=true` to .env.development.local
let middleware;
if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_HAS_REDUX_LOGGER === 'true'
) {
  // use redux devtools and redux logger for development
  middleware = composeWithDevTools(applyMiddleware(sagas, logger));
} else {
  // don't use redux devtools or redux logger for production
  middleware = applyMiddleware(sagas);
}
const store = createStore(reducer, persistedState, middleware);

storage.observeStore(store);
storage.observeStorage(store);

// initialize redux-saga
runSagas();

export default store;

import React from 'react';

import AbstractDropdown from '../abstractDropdown/abstractDropdown.jsx';
import {
  StyledContainer,
  StyledIconContainer,
  StyledOption,
} from './colorPickerDropdown.styles';

function ColorPickerDropdown(props) {
  return (
    <AbstractDropdown
      {...{
        ...props,
        StyledContainer,
        StyledOption,
        StyledIconContainer,
        rightAlign: true,
      }}
    />
  );
}

export default ColorPickerDropdown;

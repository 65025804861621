import styled, { css } from 'styled-components';

export const StyledBadge = styled.div`
  padding: 0.0625rem 0.25rem;
  border-radius: 0.25rem;
  background-color: ${(props) =>
    props.icon ? props.theme.colors.greenDefault : props.theme.colors.grey3};
  cursor: default;
  user-select: none;
  white-space: nowrap;
  pointer-events: none;

  ${(props) =>
    props.icon &&
    css`
      cursor: pointer;
      user-select: auto;
      pointer-events: auto;
    `};

  ${(props) =>
    props.disabled &&
    props.icon &&
    css`
      user-select: none;
      cursor: default;
      pointer-events: none;
      background-color: ${props.theme.colors.grey3};
    `}
`;

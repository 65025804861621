import React, { Component } from 'react';

import { SliderContainer } from './brushSize.styles';
import { Slider } from '../../../../../shared';

class BrushSize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: props.brush.size,
    };
    this.onChange = this.onChange.bind(this);
    this.onChangeComplete = this.onChangeComplete.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keypress', this.onKeyPress);
    document.addEventListener('keyup', this.onKeyUp);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.brush.size !== this.props.brush.size) {
      this.setState({
        size: this.props.brush.size,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.onKeyPress);
    document.removeEventListener('keyup', this.onKeyUp);
  }

  onKeyPress(e) {
    if (this.props.brush.type !== 'sphere') return;
    const { size } = this.state;
    // change brush size
    let newSize = size;
    if (e.key === '[') {
      newSize -= 0.1;
      if (newSize > 0) this.setState({ size: newSize });
    } else if (e.key === ']') {
      newSize += 0.1;
      if (newSize < 20) this.setState({ size: newSize });
    }
  }

  onKeyUp(e) {
    if (this.props.brush.type !== 'sphere') return;
    if (e.key === '[' || e.key === ']')
      this.props.onSelectSize(this.state.size);
  }

  onChange(e, value) {
    if (this.props.brush.type !== 'sphere') return;
    this.setState({
      size: value,
    });
  }

  onChangeComplete() {
    this.props.onSelectSize(this.state.size);
  }

  render() {
    return (
      <SliderContainer
        title='Adjust size of sphere brush'
        brushSelected={this.props.brush.type === 'sphere'}>
        <Slider
          value={this.state.size}
          min={0.1}
          max={20}
          step={0.1}
          onChange={this.onChange}
          onChangeCommitted={this.onChangeComplete}
          valueLabelFormatter={(value) => Math.round(value * 10) / 10}
        />
      </SliderContainer>
    );
  }
}

export default BrushSize;

import React from 'react';
import * as d3 from 'd3';
import { withTheme } from 'styled-components';

class BarPlot extends React.Component {
  static defaultProps = {
    data: null,
    margin: {
      top: 10,
      right: 30,
      bottom: 30,
      left: 40,
    },
  };

  constructor(props) {
    super(props);
    this.svgRef = React.createRef();
  }

  componentDidMount() {
    this.createChart();
  }

  createChart() {
    if (!this.props.data) return;

    const { margin, data, theme } = this.props;

    const { usage } = data;

    const width = 350 - margin.left - margin.right;
    const height = 150 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    const svg = d3
      .select(this.svgRef.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // X axis
    const x = d3
      .scaleBand()
      .range([0, width])
      .domain(usage.map((d) => d.category))
      .padding(0.6);
    svg
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x))
      .selectAll('text')
      .style('text-anchor', 'middle');

    // Add Y axis
    const y = d3
      .scaleLinear()
      .domain([0, d3.max(usage, (d) => d.count)])
      .range([height, 0]);

    // Bars
    svg
      .selectAll('mybar')
      .data(usage)
      .enter()
      .append('rect')
      .attr('x', (d) => x(d.category))
      .attr('y', (d) => y(d.count))
      .attr('width', x.bandwidth())
      .attr('height', (d) => height - y(d.count))
      .attr('fill', (d) => {
        if (d.isCurrent) return theme.colors.greenLight;
        return theme.colors.grey5;
      });
  }

  render() {
    if (!this.props.data) return null;
    return <svg ref={this.svgRef} />;
  }
}

export default withTheme(BarPlot);

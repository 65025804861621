import React from 'react';

import { Body1 } from '../../../../shared/typography/typography';
import { Image } from '../../../../shared';

const metadata = {
  title: 'Easier and faster navigation across Canvas',
  content: (theme) => (
    <>
      <Body1 noSpacing>
        Do you have a lot of projects? Find your projects and profiles faster
        with the improved page layout and a new site-wide search at the top.
      </Body1>
      <Image
        alt='New layout and site-wide search'
        src={theme.images.onboarding.newLayout}
      />
    </>
  ),
};

export default metadata;

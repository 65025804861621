import React from 'react';
import { Caption, UL } from '../../shared';

const email = <>Enter your email address.</>;

const username = (
  <Caption noSpacing>
    Usernames must be at least 5 characters long and can only contain letters,
    numbers, and <span style={{ whiteSpace: 'nowrap' }}>_ . -</span>
  </Caption>
);

const password = (
  <>
    <Caption noSpacing>
      Passwords may not contain your username, must be at least 8 characters,
      and must contain at least two of:
    </Caption>
    <UL>
      <li>
        <Caption noSpacing>uppercase letters</Caption>
      </li>
      <li>
        <Caption noSpacing>lowercase letters</Caption>
      </li>
      <li>
        <Caption noSpacing>numbers</Caption>
      </li>
      <li>
        <Caption noSpacing>symbols</Caption>
      </li>
    </UL>
  </>
);

export default {
  email,
  username,
  password,
};

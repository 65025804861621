import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

export default function* updateVariableTransitionLengths(action) {
  try {
    const { projectId, values } = action.payload;

    const response = yield call(API, {
      method: methods.POST,
      path: `projects/${projectId}`,
      body: {
        variableTransitionLengths: values,
      },
    });
    if (response === null) return;

    yield put(actions.updateVariableTransitionLengthsSuccess());
    yield put(actions.invalidateSlice(projectId));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

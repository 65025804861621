import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import API, { methods } from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

export default function* deleteModels(action) {
  try {
    const { currentProject: projectId, models } = yield select(getSlicerState);
    const { modelIds } = action.payload;
    const allModelsWereDeleted = _.isEmpty(models);

    // delete thumbnail if no more models remain
    if (allModelsWereDeleted) {
      yield put(actions.deleteProjectThumbnailRequest(projectId));
    } else {
      // update thumbnail otherwise
      yield put(actions.updateProjectThumbnailRequest(projectId));
    }

    const response = yield call(API, {
      method: methods.DELETE,
      path: `projects/${projectId}/models`,
      body: { modelIds },
    });
    if (response === null) return;

    yield put(actions.deleteModelsSuccess());
    yield put(actions.invalidateSlice(projectId));
    yield put(
      actions.updateTowerFromServer(projectId, response.transitionTower)
    );
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

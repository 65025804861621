import _ from 'lodash';

import authActions from './auth/actions';
import initialAuthState from './auth/initialState';

const filterStateForSessionStorage = (state) => ({
  auth: {
    autoLogout: state.auth.autoLogout,
    username: state.auth.username,
    userEmail: state.auth.userEmail,
    userId: state.auth.userId,
    refreshToken: state.auth.refreshToken,
    preferences: state.auth.preferences,
  },
});

const filterStateForLocalStorage = (state) => {
  if (state.auth.autoLogout) {
    return {
      auth: state.auth.autoLogout,
    };
  }
  return filterStateForSessionStorage(state);
};

const load = (storage) => {
  try {
    const serializedState = storage.getItem(process.env.REACT_APP_CANVAS_STATE);
    if (serializedState === null) {
      return null;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return null;
  }
};

export const save = (storage, state) => {
  try {
    const serializedState = JSON.stringify(state);
    storage.setItem(process.env.REACT_APP_CANVAS_STATE, serializedState);
  } catch (err) {
    // ignore write errors
  }
};

export const loadState = () => {
  // attempt to read SessionStorage before LocalStorage
  const storedState = load(window.sessionStorage) ||
    load(window.localStorage) || { auth: {} };
  return {
    auth: {
      ...initialAuthState,
      ...storedState.auth,
    },
  };
};

export const saveState = (state) => {
  // persist any credentials to SessionStorage
  const sessionState = filterStateForSessionStorage(state);
  save(window.sessionStorage, sessionState);

  // only persist credentials to LocalStorage if auto-logout is false
  const localState = filterStateForLocalStorage(state);
  save(window.localStorage, localState);
};

// watch for changes to relevant session data in redux store,
// updating LocalStorage and SessionStorage as appropriate
export const observeStore = (store) => {
  let currentSessionState;
  const onChange = () => {
    const nextState = store.getState();
    const nextSessionState = filterStateForSessionStorage(nextState);
    // avoid unnecessary writes
    if (!_.isEqual(nextSessionState, currentSessionState)) {
      currentSessionState = nextSessionState;
      saveState(nextState);
    }
  };
  const unsubscribe = store.subscribe(onChange);
  onChange(); // call once to populate currentSessionState
  return unsubscribe;
};

// watch for changes to relevant LocalStorage data in other
// tabs, updating the redux store as appropriate
export const observeStorage = (store) => {
  const onChange = () => {
    const storedState = load(window.localStorage);
    if (storedState && storedState.auth) {
      store.dispatch(authActions.receiveLocalStorageUpdate(storedState.auth));
    }
  };
  window.addEventListener('storage', onChange);

  // return an 'unsubscribe' function
  return () => {
    window.removeEventListener('storage', onChange);
  };
};

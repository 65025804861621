import styled from 'styled-components';

export const CanvasElement = styled.canvas`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.systemBackgroundPrimary};
  display: block;

  ${(props) => {
    if (props.currentView === 'place') return '';
    let cursor;
    let cursor2x;
    switch (props.currentTool) {
      case 'facet':
        cursor = props.theme.images.cursors.triangle;
        cursor2x = props.theme.images.cursors.triangle2x;
        break;
      case 'fill':
        cursor = props.theme.images.cursors.fill;
        cursor2x = props.theme.images.cursors.fill2x;
        break;
      case 'sphere':
        cursor = props.theme.images.cursors.sphere;
        cursor2x = props.theme.images.cursors.sphere2x;
        break;
      default:
        return '';
    }
    return `
      cursor: url(${cursor}) 1 1, default;
      cursor: image-set(
        url(${cursor}) 1x,
        url(${cursor2x}) 2x
      ) 1 1, default;
    `;
  }}
`;

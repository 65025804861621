import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import _ from 'lodash';

import Codearea from '../codearea/codearea.jsx';
import Link from '../link/link.jsx';

import { Subtitle2 } from '../typography/typography';

import {
  Sequences,
  Sequence,
  CodeareaWrapper,
  ModesList,
  ModeLabel,
  ModeHelp,
  HeaderWrapper,
  HeadingWrapper,
} from './sequenceEditor.styles';
import ActionButton from '../actionButton/actionButton.jsx';
import { Icons } from '../../themes';

class SequenceEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modes: this.getInitialModes(props),
      forceUpdate: null,
      persistCursor: null,
    };
  }

  componentDidUpdate() {
    if (this.state.forceUpdate) {
      this.setState({ forceUpdate: null });
      return;
    }
    if (this.state.persistCursor) {
      this.setState({ persistCursor: null });
    }
  }

  getMode(script) {
    if (script && script.startsWith('@printerscript 1.0')) {
      return 'printerscript';
    }
    return 'gcode';
  }

  getInitialModes(props) {
    return _.reduce(
      props.sequences.fields,
      (modes, field) => {
        let mode;
        if (field.modes.length > 1) {
          mode = this.getMode(props[field.name]);
        } else {
          [mode] = field.modes;
        }
        return {
          ...modes,
          [field.name]: mode,
        };
      },
      {}
    );
  }

  onModeIconClick(field, mode) {
    const directive = '@printerscript 1.0';
    if (this.state.modes[field.name] === mode) return;
    let value = this.props[field.name] || '';
    if (mode === 'printerscript' && !value.startsWith(directive)) {
      value = `@printerscript 1.0\n${value}`;
      this.onChange(field, value);
      return;
    }
    if (mode === 'gcode' && value.startsWith(directive)) {
      value = value.slice(directive.length + 1);
      if (value.length > 0 && value[0] === '\n') {
        value = value.slice(1);
      }
      this.onChange(field, value);
    }
  }

  renderModeIcons(field) {
    const modes = {
      gcode: 'G-code',
      printerscript: 'PS',
    };
    const fieldModes = _.pickBy(modes, (label, mode) =>
      _.includes(field.modes, mode)
    );
    const currentMode = this.state.modes[field.name];
    return (
      <ModesList>
        {_.map(fieldModes, (label, mode) => (
          <ModeLabel
            key={mode}
            disabled={field.modes.length < 2}
            active={mode === currentMode}
            onClick={() => this.onModeIconClick(field, mode)}>
            {label}
          </ModeLabel>
        ))}
        <ModeHelp>
          <Link external href={field.helpLink(currentMode)}>
            <ActionButton minimal icon={Icons.basic.help} />
          </Link>
        </ModeHelp>
      </ModesList>
    );
  }

  onChange(field, value, cursor) {
    this.props.updateField(field.name, value);
    if (field.modes.length < 2) return;
    const mode = this.getMode(value);
    if (this.state.modes[field.name] === mode) return;
    this.setState({
      modes: {
        ...this.state.modes,
        [field.name]: mode,
      },
      forceUpdate: field.name,
      persistCursor: {
        fieldName: field.name,
        cursor,
      },
    });
  }

  renderSequenceContent(field) {
    if (this.state.forceUpdate === field.name) return null;
    let cursor;
    if (
      this.state.persistCursor &&
      this.state.persistCursor.fieldName === field.name
    ) {
      ({ cursor } = this.state.persistCursor);
    }
    return (
      <CodeareaWrapper>
        <Codearea
          mode={this.state.modes[field.name]}
          label={field.label}
          value={this.props[field.name] === ' ' ? '' : this.props[field.name]}
          autoResize
          cursor={cursor}
          onChange={(newValue, newCursor) =>
            this.onChange(field, newValue, newCursor)
          }
        />
      </CodeareaWrapper>
    );
  }

  renderSequences() {
    return _.map(this.props.sequences.fields, (field, index) => (
      <Sequence key={index}>
        <HeaderWrapper>
          <HeadingWrapper>
            <Subtitle2>{field.label}</Subtitle2>
          </HeadingWrapper>
          {this.renderModeIcons(field)}
        </HeaderWrapper>
        {this.renderSequenceContent(field)}
      </Sequence>
    ));
  }

  render() {
    return <Sequences>{this.renderSequences()}</Sequences>;
  }
}

export default withTheme(SequenceEditor);

import React, { useEffect, useRef } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import _ from 'lodash';
import { DefaultContainer } from './draggableCard.styles';

import CustomDragLayer from './customDragLayer.jsx';

const cardSource = {
  beginDrag(props) {
    return { id: props.id };
  },
  endDrag(props, monitor) {
    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();
    if (!monitor.didDrop() || dropResult.id === item.id) {
      return null;
    }
    return props.handleDrop(props.selectedProjectIds, dropResult.id);
  },
  canDrag(props) {
    return props.selected;
  },
  isDragging(props, monitor) {
    return props.id === monitor.getItem().id;
  },
};

const cardTarget = {
  drop(props) {
    return { id: props.id };
  },
  canDrop(props) {
    return !props.selected && props.canReceive;
  },
};

function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
    sourceItem: monitor.getItem(),
  };
}

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
    item: monitor.getItem(),
    canDrop: monitor.canDrop(),
  };
}

const DraggableCard = (props) => {
  const { connectDragPreview } = props;

  useEffect(() => {
    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true,
      });
    }
  }, [connectDragPreview]);

  const cardRef = useRef();
  function renderCustomDragLayer() {
    const { selectedProjectIds, selected, isDragging } = props;
    if (!selected || !isDragging) return null;
    return (
      <CustomDragLayer
        {...props}
        selected={selected}
        selectedProjectIds={selectedProjectIds}
        width={cardRef.current.offsetWidth}
        height={cardRef.current.offsetHeight}
      />
    );
  }

  function renderSourceCard() {
    const {
      connectDragSource,
      sourceItem,
      selected,
      StyledContainer = DefaultContainer,
    } = props;
    const opacity = selected && sourceItem ? 0.2 : 1;

    // card can only be dropped
    return connectDragSource(
      <div>
        <StyledContainer
          ref={cardRef}
          opacity={opacity}
          {...props}
          onDoubleClick={(e) => props.onDoubleClick(e)}
          onMouseDown={(e) => props.onMouseDown(e)}
          onClick={(e) => props.onClick(e)}>
          {props.children}
        </StyledContainer>
        {renderCustomDragLayer()}
      </div>
    );
  }

  function renderSourceAndTargetCard() {
    const {
      connectDragSource,
      connectDropTarget,
      selected,
      item,
      StyledContainer = DefaultContainer,
    } = props;
    const opacity = selected && item ? 0.2 : 1;

    // card can both be dropped AND receive other dropped cards
    return connectDropTarget(
      connectDragSource(
        <div>
          <StyledContainer
            ref={cardRef}
            opacity={opacity}
            {...props}
            onDoubleClick={(e) => props.onDoubleClick(e)}
            onMouseDown={(e) => props.onMouseDown(e)}
            onClick={(e) => props.onClick(e)}>
            {props.children}
          </StyledContainer>
          {renderCustomDragLayer()}
        </div>
      )
    );
  }

  const { nature } = props;
  if (nature === 'folder') return renderSourceAndTargetCard();
  return renderSourceCard();
};

export default _.flow([
  DropTarget('card', cardTarget, collectTarget),
  DragSource('card', cardSource, collectSource),
])(DraggableCard);

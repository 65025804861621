import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import _ from 'lodash';

import ActionButton from '../actionButton/actionButton.jsx';
import Dropdown from '../dropdown/dropdown.jsx';
import Image from '../image/image.jsx';
import LoadingOverlay from '../loadingOverlay/loadingOverlay.jsx';
import Page from '../page/page.container';
import ToolTipWrapper from '../toolTipWrapper/toolTipWrapper.jsx';
import ContentPlaceholder from '../contentPlaceholder/contentPlaceholder.jsx';

import { ProjectSortDirection } from '../../utils/sort/sort';
import { Icons } from '../../themes';
import {
  SortDropdownWrapper,
  Placeholders,
  PlaceholderMessages,
  FilterBar,
  FilterBarContainer,
  ChildrenWrapper,
} from './managerView.styles';

import { Body1 } from '../typography/typography';

/*
  action button metadata format:
  {
    icon: string
    title: string
    disabled: boolean
    onClick: (event) => {},
  }
   */

const ManagerView = ({
  children,

  // general
  name, // to use in generated labels -- mandatory!
  actionsDisabled = false, // disables filter bar/action buttons

  // content/fallbacks
  loadingOverlayText = null, // set to string to render fullscreen overlay
  loadingContent = false, // dictates when to render content placeholder
  loadingError = false, // dictates when to render error message
  noContent = false, // render no-content graphic

  // sidebars
  menuButtons = null, // JSX content (e.g. buttons)

  // filter bar left
  sortOptions = [], // { label: string, value: number }
  sortMode = null, // value of selected option
  sortDirection = null,
  onSortChange = (/* mode, direction */) => {},

  // action button meta (see above for schema)
  actionButtonsFilterBar = [], // after built-in sort tools
  actionButtons = [], // action buttons in action bar top
}) => {
  const theme = useContext(ThemeContext);

  const areActionDisabled = actionsDisabled || loadingContent;

  const renderSortDropdown = () => {
    if (_.isEmpty(sortOptions)) return null;

    return (
      <SortDropdownWrapper>
        <ToolTipWrapper tooltip='Sort by'>
          <Dropdown
            borderlessContainer
            borderlessSelect
            grey
            minHeight='0'
            value={sortMode}
            options={sortOptions}
            disabled={areActionDisabled}
            onChange={(value) => onSortChange(value, sortDirection)}
          />
        </ToolTipWrapper>
      </SortDropdownWrapper>
    );
  };

  const renderSortDirectionToggle = () => {
    if (sortDirection === null) return null;

    const arrowDirectionIcon =
      sortDirection === ProjectSortDirection.ASCENDING
        ? Icons.basic.arrowDown
        : Icons.basic.arrowUp;

    return (
      <ActionButton
        icon={arrowDirectionIcon}
        title='Toggle sort direction'
        disabled={areActionDisabled}
        onClick={(e) => {
          e.stopPropagation();
          onSortChange(sortMode, -sortDirection);
        }}
      />
    );
  };

  const renderCustomActionButton = (meta, index) => (
    <ActionButton
      key={index}
      icon={meta.icon}
      title={meta.title}
      disabled={areActionDisabled || meta.disabled}
      show={!meta.disabled}
      onClick={(e) => {
        e.stopPropagation();
        meta.onClick(e);
      }}
    />
  );

  const renderFilterBar = () => (
    <FilterBarContainer>
      <FilterBar>
        {renderSortDropdown()}
        {renderSortDirectionToggle()}
        {_.map(
          actionButtonsFilterBar,
          // conceptually, the sort direction button has index 0
          (meta, index) => renderCustomActionButton(meta, index + 1)
        )}
      </FilterBar>
    </FilterBarContainer>
  );

  const renderActionButtons = () =>
    _.map(actionButtons, (meta, index) =>
      renderCustomActionButton(meta, index)
    );

  const renderLoadingOverlay = () => {
    if (!loadingOverlayText) return null;
    return <LoadingOverlay label={loadingOverlayText} />;
  };

  const renderError = () => (
    <PlaceholderMessages>
      <Body1 grey> An error occurred while loading {name.toLowerCase()}.</Body1>
      <Body1 grey>Please try refreshing the page.</Body1>
    </PlaceholderMessages>
  );

  const renderLoadingContentPlaceholder = () => (
    <Placeholders>
      {Array(10)
        .fill()
        .map((val, index) => (
          <ContentPlaceholder key={index} />
        ))}
    </Placeholders>
  );

  const renderNoContentPlaceholder = () => {
    const label = `You do not have any ${name.toLowerCase()} yet.`;
    return (
      <PlaceholderMessages>
        <Image alt={label} src={theme.images.noContent} />
        <Body1 grey>{label}</Body1>
      </PlaceholderMessages>
    );
  };

  const renderContent = () => {
    if (loadingContent) {
      return renderLoadingContentPlaceholder();
    }
    if (loadingError) {
      return renderError();
    }
    if (noContent) {
      return renderNoContentPlaceholder();
    }
    return <ChildrenWrapper>{children}</ChildrenWrapper>;
  };

  return (
    <Page
      menuButtons={menuButtons}
      filterBar={renderFilterBar()}
      actionButtons={renderActionButtons()}>
      {renderLoadingOverlay()}
      {renderContent()}
    </Page>
  );
};

export default ManagerView;

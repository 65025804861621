import React from 'react';
import _ from 'lodash';
import { toast, cssTransition } from 'react-toastify';
import '../../shared/toastManager/animation.css';
import LoadingOverlay from '../../shared/loadingOverlay/loadingOverlay.jsx';
import ToastMessage from '../../shared/toastManager/toastMessage.jsx';

const Utils = {
  getSpinnerMessage: (flags) => {
    // Slicer & Project
    if (flags.loadProjectPending) return 'Loading project';
    if (flags.updateProjectPending) return 'Updating project';
    if (flags.repackPending) return 'Auto-arranging';
    if (flags.downloadPending) return 'Download starting';
    if (flags.deleteModelsPending) return 'Deleting models';
    if (flags.deleteGroupPending) return 'Deleting models';
    if (flags.duplicateModelPending) return 'Duplicating models';
    if (flags.alignGroupPending) return 'Aligning and grouping models';
    if (flags.remapProjectInputsPending) return 'Updating project';
    if (flags.updateProjectSettingsPending) return 'Updating project';
    if (flags.getSpliceSettingsPending) return 'Loading splice settings';
    if (
      flags.updateVariableTransitionLengthsPending &&
      flags.createSpliceSettingsPending
    )
      return 'Saving splice and transition settings';
    if (flags.createSpliceSettingsPending) return 'Saving splice settings';
    if (flags.updateVariableTransitionLengthsPending)
      return 'Saving variable transition lengths';

    // Printer & Palette & Style Profile
    if (flags.getPrintersPending) return 'Loading printers';
    if (flags.createPrinterPending) return 'Creating printer';
    if (flags.deletePrintersPending) return 'Deleting printer';
    if (flags.duplicatePrinterPending) return 'Duplicating printer';
    if (flags.updatePrinterPending) return 'Updating printer';
    if (flags.createStylesPending) return 'Creating style profile';
    if (flags.updateStylePending) return 'Updating style profile';
    if (flags.deleteStylesPending) return 'Deleting style profile';
    if (flags.getPrinterPresetsPending) return 'Loading printer presets';
    if (flags.createPrinterTagPending) return 'Registering printer model';

    // Materials
    if (flags.getMaterialsPending) return 'Loading materials';
    if (flags.createMaterialPending) return 'Creating material';
    if (flags.editMaterialPending) return 'Updating material';
    if (flags.deleteMaterialsPending) return 'Deleting material';
    if (flags.updateProjectColorsPending) return 'Updating colors';
    if (flags.changeMaterialPending) return 'Changing material';

    // Auth
    if (flags.loginPending) return 'Signing in';
    if (flags.logoutAllSessionsPending) return 'Ending all sessions';
    if (flags.signupPending) return 'Creating an account';
    if (flags.getAccountPending) return 'Loading account';
    if (flags.editAccountPending) return 'Updating account';
    if (flags.deleteAccountPending) return 'Deleting account';
    if (flags.requestPasswordResetPending) return 'Sending password reset code';
    if (flags.resetPasswordPending) return 'Resetting password';

    // Face Painter
    if (flags.buildFacesPending) return 'Analyzing model';
    if (flags.generateAtlasPending) return 'Analyzing model';
    if (flags.autoSegmentPending) return 'Creating regions';
    if (flags.composeColorsRLEPending) return 'Saving changes';
    if (flags.saveModelPaintDataPending) return 'Saving changes';

    // Supports
    if (flags.saveModelCustomSupportsPending) return 'Saving changes';

    // Integrations
    if (flags.linkIntegrationPending) return '';
    if (flags.unlinkIntegrationPending) return 'Unlinking integration';
    if (flags.getIntegrationsPending) return 'Loading integrations';
    if (flags.getPolarPrintersPending) return 'Loading printers';
    if (flags.dispatchPrintToPolarPending) return 'Dispatching sliced file';

    // Devices
    if (flags.getDevicesPending) return 'Loading devices';
    if (flags.activateDevicePending) return 'Activating device';
    if (flags.updateDevicePending) return 'Updating device';

    // default
    return '';
  },
  getLoadingSpinner: (props, viewName = '') => {
    const flags = _.pickBy(props, (value, key) => key.includes('Pending'));
    if (flags.queryPrinterTagsPending) return null;
    if (_.every(flags, (value) => !value)) return null;
    const message = Utils.getSpinnerMessage(flags, viewName);
    return <LoadingOverlay label={message} />;
  },
  // set close timer to false to persist the toast until clicked
  emitToast: (type, label, buttonName, buttonOnClick, closeTimer = 5000) => {
    const fade = cssTransition({
      collapse: false,
      enter: 'fadeIn',
      exit: 'fadeOut',
      duration: [300, 200], // time for transition in and transition out
    });
    const toastFadeTime =
      buttonName && closeTimer !== false ? 10000 : closeTimer;
    const message = (
      <ToastMessage
        type={type}
        label={label}
        buttonName={buttonName}
        buttonOnClick={buttonOnClick}
      />
    );
    if (type === 'success') {
      toast.success(message, {
        transition: fade,
        className: 'toastStyles',
        bodyClassName: 'toastBodyStyles',
        autoClose: toastFadeTime,
      });
      return;
    }
    if (type === 'warn') {
      toast.warn(message, {
        transition: fade,
        autoClose: toastFadeTime,
        className: 'toastStyles',
        bodyClassName: 'toastBodyStyles',
      });
      return;
    }
    if (type === 'error') {
      toast.error(message, {
        transition: fade,
        autoClose: toastFadeTime,
        className: 'toastStyles',
        bodyClassName: 'toastBodyStyles',
      });
      return;
    }
    // default to info toast if type is unrecognized
    toast.info(message, {
      transition: fade,
      className: 'toastStyles',
      bodyClassName: 'toastBodyStyles',
      autoClose: toastFadeTime,
    });
  },
  selectAdditionalItem: (
    allItems,
    selectedItemKeys,
    itemKey,
    keyName = 'id'
  ) => {
    const allItemKeys = _.map(allItems, (item) => item[keyName]);
    const itemAlreadySelected = _.includes(selectedItemKeys, itemKey);

    if (itemAlreadySelected) {
      // item is already selected
      // - we will be deselecting it, but potentially designating a new
      //   "reference item" which is used as state for range selection

      if (selectedItemKeys.length === 1) return [];

      // find the removed item key's index in a sorted array of selected item keys
      const selectedItemIndices = _.map(selectedItemKeys, (key) =>
        _.indexOf(allItemKeys, key)
      );
      const sortedIndices = _.sortBy(selectedItemIndices);
      const itemIndexInList = _.indexOf(allItemKeys, itemKey);
      const itemIndexInSortedIndices = _.indexOf(
        sortedIndices,
        itemIndexInList
      );

      // determine the new reference item's key
      const newReferenceItemIndex =
        itemIndexInSortedIndices === sortedIndices.length - 1
          ? sortedIndices[itemIndexInSortedIndices - 1]
          : sortedIndices[itemIndexInSortedIndices + 1];
      const newReferenceKey = allItemKeys[newReferenceItemIndex];

      // remove the item, and assign new reference item by moving its key to the end
      return [
        ..._.filter(
          selectedItemKeys,
          (key) => key !== itemKey && key !== newReferenceKey
        ),
        newReferenceKey,
      ];
    }
    // item is not selected
    // - select it and designate as the new reference card by appending it
    return [...selectedItemKeys, itemKey];
  },
  selectItemRange: (allItems, selectedItemKeys, itemKey, keyName = 'id') => {
    // if nothing is selected, "create" a range with just the selected item
    if (_.isEmpty(selectedItemKeys)) return [itemKey];

    const allItemKeys = _.map(allItems, (item) => item[keyName]);

    const itemIndexInList = _.indexOf(allItemKeys, itemKey);
    const referenceItemKey = _.last(selectedItemKeys);
    const referenceItemIndex = _.indexOf(allItemKeys, referenceItemKey);

    // identify current contiguous subgroups within selected cards
    const selectedItemIndices = _.map(selectedItemKeys, (key) =>
      _.indexOf(allItemKeys, key)
    );
    const sortedIndices = _.sortBy(selectedItemIndices);
    const subgroups = Utils.getContiguousSubgroups(sortedIndices);

    // build up the selection
    const untouchedSubgroupKeys = [];

    // keep selected items that are not part of the subgroup containing the reference item
    if (!_.includes(selectedItemKeys, itemKey)) {
      _.forEach(subgroups, (subgroup) => {
        if (!_.includes(subgroup, referenceItemIndex)) {
          _.forEach(subgroup, (index) => {
            untouchedSubgroupKeys.push(allItemKeys[index]);
          });
        }
      });
    }

    // add the card range between reference card and new card
    // - don't include the reference card, as it will be forced to the end
    const rangeStartIndex = Math.min(referenceItemIndex, itemIndexInList);
    const rangeEndIndex = Math.max(referenceItemIndex, itemIndexInList) + 1;
    const referenceItemSubgroup = _.filter(
      _.slice(allItemKeys, rangeStartIndex, rangeEndIndex),
      (key) => key !== referenceItemKey
    );

    // keep previous reference card at last index
    return [
      ..._.uniq([...untouchedSubgroupKeys, ...referenceItemSubgroup]),
      referenceItemKey,
    ];
  },
  getContiguousSubgroups: (values) => {
    /**
     * Takes in an array of sorted values (e.g: [1, 2, 4, 6, 7, 8])
     * Returns an array of contiguous subgroups (e.g: [ [1, 2], [4], [6, 7, 8] ])
     */
    const subgroups = [];
    let currentSubgroup = [];
    _.forEach(values, (value, key) => {
      currentSubgroup.push(value);
      if (value + 1 !== values[key + 1]) {
        subgroups.push(currentSubgroup);
        currentSubgroup = [];
      }
    });

    return subgroups;
  },
  handleSelectableItemClick: (
    e,
    allItems,
    selectedItemKeys,
    clickedItemKey,
    keyName = 'id'
  ) => {
    let updatedSelectedItemKeys = selectedItemKeys;
    if (e.metaKey || e.ctrlKey) {
      // Mac command key + Windows Ctrl key: select multiple individual items
      updatedSelectedItemKeys = Utils.selectAdditionalItem(
        allItems,
        selectedItemKeys,
        clickedItemKey,
        keyName
      );
    } else if (e.shiftKey) {
      // Shift key: select range of items
      updatedSelectedItemKeys = Utils.selectItemRange(
        allItems,
        selectedItemKeys,
        clickedItemKey,
        keyName
      );
    } else if (!_.includes(selectedItemKeys, clickedItemKey)) {
      // select single item
      updatedSelectedItemKeys = [clickedItemKey];
    }
    return updatedSelectedItemKeys;
  },
  /**
   * copy-paste util: copies the provided text to the clipboard so users may paste it
   * @param text string to copy
   */
  copyToClipboard: (text) => {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  },
};

export default Utils;

import React from 'react';

import AbstractDropdown from '../abstractDropdown/abstractDropdown.jsx';
import {
  StyledContainer,
  StyledIconContainer,
  StyledSelect,
} from './dropdownMenu.styles';

function DropdownMenu(props) {
  return (
    <AbstractDropdown
      {...props}
      StyledContainer={StyledContainer}
      StyledIconContainer={StyledIconContainer}
      StyledSelect={StyledSelect}
    />
  );
}

export default DropdownMenu;

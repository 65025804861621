import styled from 'styled-components';

import { Breakpoints } from '../../../../themes';
import { floatingPanelStyles } from '../headerBarRight/headerBarRight.styles';

export const Container = styled.div`
  ${floatingPanelStyles};
  display: flex;
  flex-direction: column;
  width: 18rem;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    min-width: calc(100% - 3rem);
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.25rem;
`;

export const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${(props) => props.theme.colors.grey3};
  border-bottom: 1px solid ${(props) => props.theme.colors.grey3};
  padding: 0.5rem;
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-radius: 0.25rem;
  padding: 0.75rem;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.elevatedBackgroundSecondary};
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem;
`;

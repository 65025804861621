import { CanvasApiError, methods } from './canvas-api';

export const fetchS3 = async (url, init) => {
  const response = await fetch(url, init);
  if (response.ok) {
    return response;
  }
  throw new CanvasApiError({
    status: response.status,
    message: response.statusText,
  });
};

/**
 * Upload a single S3 part for a multipart upload.
 *
 * Returns the ETag of the uploaded part
 *
 * @param url {string} S3 URL to use for the UploadPart request
 * @param slice {DataView} Data to upload
 */
const uploadPartToS3 = async (url, slice) => {
  const uploadPartResponse = await fetchS3(url, {
    method: methods.PUT,
    body: slice,
  });
  return uploadPartResponse.headers.get('ETag').slice(1, -1); // remove leading and trailing quotes
};

/**
 * @typedef {Object} UploadPartParams
 * @property {number} partNumber
 * @property {number} contentLength
 * @property {string} url
 */

/**
 * Upload a file to S3 via a multipart upload.
 *
 * Returns an array of ETags corresponding to each uploaded part
 *
 * @param urls {UploadPartParams[]}
 * @param buffer {ArrayBuffer}
 */
export const uploadAllPartsToS3 = async (urls, buffer) => {
  // get a DataView for each part to upload
  const slices = [];
  let startIdx = 0;
  for (let i = 0; i < urls.length; i++) {
    const { contentLength } = urls[i];
    slices.push(new DataView(buffer, startIdx, contentLength));
    startIdx += contentLength;
  }

  // upload all parts in parallel
  return Promise.all(
    slices.map((slice, idx) => {
      const { url } = urls[idx];
      return uploadPartToS3(url, slice);
    })
  );
};

import styled, { css, keyframes } from 'styled-components';
import { StyledContainerBase } from '../../../../../shared/abstractInput/abstractInput.styles';
import {
  DefaultSlider,
  DefaultContainer,
} from '../../../../../shared/slider/slider.styles';
import { Container as ActionButtonContainer } from '../../../../../shared/actionButton/actionButton.styles';
import { StyledButton } from '../../../../../shared/button/button.styles';
import { Container as StyledIcon } from '../../../../../shared/icon/icon.styles';
import { Breakpoints } from '../../../../../themes';
import { Image } from '../../../../../shared';

const buttonShadows = css`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  min-width: 0;
`;

export const MoveTool = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 12rem;
  grid-gap: 0.75rem;

  @media (max-width: ${Breakpoints.smallWidth}) {
    grid-template-columns: 1fr;
  }
`;

export const ExtrudeTool = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: auto 12.3rem;
  grid-gap: 0.75rem;

  @media (max-width: ${Breakpoints.smallWidth}) {
    grid-template-columns: 1fr;
  }
`;

export const FanTool = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.75rem;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    display: flex;
    flex-direction: column;
  }
`;

export const HorizontalControls = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 0.25rem;
  display: flex;
  position: relative;
  flex-grow: 1;
`;

export const VerticalControls = styled.div`
  margin: 0;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 0.25rem;
  display: flex;
  position: relative;
`;

export const HorizontalMovements = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Horizontal3DControls = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 11rem;
  max-width: 18rem;
  min-height: 9rem;
  margin-top: -1rem;
  transform: perspective(30rem);
`;

export const Horizontal3DControlsRotation = styled.div`
  // inner element is used to fix visibility glitch on iOS -- same perspective
  // as above is repeated on inner element along with the desired rotation
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 11rem;
  max-width: 18rem;
  min-height: 9rem;
  transform: perspective(30rem) rotateX(40deg);
`;

const movementShadows = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  transition: all 0.2s;
  transition-property: border-color, opacity;

  ${ActionButtonContainer} {
    width: 100%;
    height: 100%;

    ${StyledButton} {
      ${buttonShadows};
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      opacity: 0.5;
      pointer-events: none;

      :hover {
        border-color: transparent;
      }

      ${StyledButton} {
        pointer-events: none;
      }
    `}
`;

export const HorizontalMovement = styled.div`
  ${movementShadows};
  position: absolute;
  width: 3.6rem;
  height: 3.6rem;

  ${(props) =>
    props.direction === 'forward' &&
    css`
      top: calc(15% - 1.8rem);
      left: calc(50% - 1.8rem);
    `}

  ${(props) =>
    props.direction === 'backward' &&
    css`
      top: calc(85% - 1.8rem);
      left: calc(50% - 1.8rem);
    `}

  ${(props) =>
    props.direction === 'left' &&
    css`
      top: calc(50% - 1.8rem);
      left: calc(20% - 1.8rem);
    `}

  ${(props) =>
    props.direction === 'right' &&
    css`
      top: calc(50% - 1.8rem);
      left: calc(80% - 1.8rem);
    `}
`;

export const ActionButtonWrapper = styled.div`
  margin-top: 2.5rem;
`;

export const CaptionWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 0.5rem;
`;

export const VerticalMovements = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const VerticalMovement = styled.div`
  flex: 1;
  ${movementShadows};
`;

export const HomeMovement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid transparent;
  border-left-color: ${(props) => props.theme.colors.grey3};
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  transition: all 0.2s;
  transition-property: border-color, opacity;
  z-index: 1;
  text-align: center;

  ${ActionButtonContainer} {
    margin-bottom: 0.2rem;
    flex: 1;

    ${StyledButton} {
      ${buttonShadows};
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      opacity: 0.5;
      pointer-events: none;

      ${StyledButton} {
        pointer-events: none;
      }
    `}
`;

const moveUp = keyframes`
  from {
    top: 70%;
  }
  to {
    top: 60%;
  }
`;

const moveDown = keyframes`
  from {
    top: 60%;
  }
  to {
    top: 70%;
  }
`;

export const ExtrusionPreviewControlContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  border-radius: 0.25rem;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  margin-right: -0.3rem;
  @media (max-width: ${Breakpoints.smallWidth}) {
    margin-right: 0;
  }
`;

export const ExtrusionPreview = styled.div`
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  min-height: 8rem;
  margin-right: 4rem;

  &::after {
    content: '';
    display: block;
    flex-basis: 0;
    flex-grow: 6;
    width: 7px;
    margin-left: 5px;
    box-sizing: border-box;
    background: ${(props) => {
      if (props.retracting) return props.theme.colors.redDefault;
      if (props.extruding) return props.theme.colors.greenDefault;
      return props.theme.colors.grey4;
    }};
    transition: background 0.2s;
  }

  ${StyledIcon} {
    position: absolute;
    top: 70%;
    left: 40%;
    color: ${(props) => props.theme.colors.grey4};

    ${(props) =>
      props.retracting &&
      css`
        animation: ${moveUp} 1s linear infinite;
      `}

    ${(props) =>
      props.extruding &&
      css`
        animation: ${moveDown} 1s linear infinite;
      `}
  }
`;

export const ExtrusionPreviewImage = styled(Image)`
  flex-basis: 0;
  flex-grow: 4;
`;

export const ExtrusionControlsWrapper = styled.div`
  display: flex;
  justify-self: flex-end;
`;

export const ExtrusionControls = styled.div`
  position: relative;
  height: 100%;
  box-sizing: border-box;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  margin-right: 0.75rem;
`;

export const FanControls = styled.div`
  display: grid;
  grid-template-rows: repeat(3, min-content);
  grid-gap: 0.5rem;
  position: relative;
`;

export const FanSpeed = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

export const FanSpeedContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
  margin-top: 0.85rem;
  user-select: ${(props) => (props.disabled ? 'none' : 'auto')};

  @media (max-width: ${Breakpoints.smallWidth}) {
    margin-top: 0.45rem;
  }

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    margin-top: 0;
  }
`;

export const StepControls = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;

  @media (max-width: ${Breakpoints.smallWidth}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }
  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    grid-template-columns: 1fr 10.1rem;
  }
`;

export const ExtruderStepControlsWrapper = styled.div`
  margin-left: 0.3rem;

  @media (max-width: ${Breakpoints.smallWidth}) {
    margin-left: 0;
  }
`;

export const StyledNumberInputContainer = styled(StyledContainerBase)`
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.elevatedBackgroundSecondary
      : props.theme.colors.elevatedBackgroundPrimary};
`;

export const BoxSelectionWrapper = styled.div`
  margin-top: 0.5rem;

  @media (max-width: ${Breakpoints.smallWidth}) {
    margin-top: 0;
  }
`;

export const ToolsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1.25rem;
  width: 100%;
  height: min-content;
  min-width: 0;
`;

export const ControlsWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 7.5rem;
  gap: 0.75rem;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
`;

export const GreenSlider = styled(DefaultSlider)`
  .MuiSlider-valueLabel {
    margin: 0;
    display: none;
  }

  .MuiSlider-rail {
    background-color: ${(props) => props.theme.colors.greenDefault}40;
    height: 2px;
    pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
    ${(props) =>
      props.disabled &&
      css`
        background-color: ${props.theme.colors.elevatedBackgroundTertiary};
      `}
  }

  .MuiSlider-track {
    background-color: ${(props) =>
      props.disabled
        ? props.theme.colors.elevatedBackgroundTertiary
        : props.theme.colors.greenDefault};
    height: 2px;
  }

  .MuiSlider-thumb {
    width: 12px;
    height: 12px;
    margin-top: -0.3rem;
    border: ${(props) => props.theme.colors.greenDefault} solid 2px;
    background-color: ${(props) => props.theme.colors.greenDefault};

    :hover,
    .Mui-focusVisible {
      box-shadow: 0 0 0 0.75rem ${(props) => props.theme.colors.grey5}10;
    }

    ${(props) =>
      props.disabled &&
      css`
        background-color: ${props.theme.colors.elevatedBackgroundTertiary};
        border: ${props.theme.colors.grey3};
      `}
  }
`;

export const GreenSliderContainer = styled(DefaultContainer)`
  display: flex;
  flex-direction: row;
  height: auto;
  margin-top: -0.1rem;

  .MuiSlider-dragging .MuiSlider-track {
    background-color: ${(props) => props.theme.colors.greenDefault};
  }
`;

import 'regenerator-runtime/runtime';
import { put, select } from 'redux-saga/effects';

import { getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

export default function* getSlicePreviewData() {
  const { currentProject: projectId, projects } = yield select(getSlicerState);
  const project = projects[projectId];

  yield put(actions.loadProjectStatsRequest(projectId));
  if (project.ptpHeader.version && project.ptpHeader.version >= 5) {
    yield put(actions.loadToolpathPtpRequest());
  }
}

import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;

  & > :not(:last-child) {
    margin-bottom: 0.25rem;
  }
`;

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

import React from 'react';

import AbstractDropdown from '../abstractDropdown/abstractDropdown.jsx';
import {
  StyledOptionWrapper,
  StyledOption,
  StyledSelect,
} from './colorSelectionDropdown.styles';

function ColorSelectionDropdown(props) {
  return (
    <AbstractDropdown
      {...props}
      StyledOptionWrapper={StyledOptionWrapper}
      StyledOption={StyledOption}
      StyledSelect={StyledSelect}
    />
  );
}
export default ColorSelectionDropdown;

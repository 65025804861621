import _ from 'lodash';

import authTypes from './types';
import authActions from './actions';
import authInitialState from './initialState';

export const types = authTypes;
export const actions = authActions;
export const initialState = authInitialState;

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.RECEIVE_LOCAL_STORAGE_UPDATE: {
      return {
        ...state,
        ...payload,
      };
    }
    case types.SET_AUTO_LOGOUT: {
      const { enabled } = payload;
      return {
        ...state,
        autoLogout: enabled,
      };
    }
    case types.SIGNUP_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          signupPending: true,
          signupSuccess: false,
        },
      };
    }
    case types.SIGNUP_SUCCESS: {
      const { token, refreshToken, user } = payload;
      return {
        ...state,
        token,
        refreshToken,
        username: user.username,
        userId: user.id,
        status: {
          ...state.status,
          signupPending: false,
          signupSuccess: true,
        },
      };
    }
    case types.SIGNUP_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          signupPending: false,
          signupSuccess: false,
        },
      };
    }
    case types.LOGIN_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          loginPending: true,
          loginSuccess: false,
          requestPasswordResetSuccess: false,
          resetPasswordSuccess: false,
        },
      };
    }
    case types.LOGIN_SUCCESS: {
      const { user } = payload;
      return {
        ...state,
        token: user.token,
        refreshToken: user.refreshToken,
        username: user.username,
        userId: user.userId,
        userEmail: user.userEmail,
        status: {
          ...state.status,
          loginPending: false,
          loginSuccess: true,
        },
      };
    }
    case types.LOGIN_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          loginPending: false,
          loginSuccess: false,
        },
      };
    }
    case types.LOGOUT: {
      return { ...initialState };
    }
    case types.LOGOUT_ALL_SESSIONS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          logoutAllSessionsPending: true,
          logoutAllSessionsSuccess: false,
        },
      };
    }
    case types.LOGOUT_ALL_SESSIONS_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          logoutAllSessionsPending: false,
          logoutAllSessionsSuccess: true,
        },
      };
    }
    case types.LOGOUT_ALL_SESSIONS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          logoutAllSessionsPending: false,
          logoutAllSessionsSuccess: false,
        },
      };
    }
    case types.REFRESH_USER_TOKEN_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          refreshUserTokenPending: true,
          refreshUserTokenSuccess: false,
        },
      };
    }
    case types.REFRESH_USER_TOKEN_SUCCESS: {
      const { accessToken, refreshToken } = payload;
      return {
        ...state,
        token: accessToken,
        refreshToken,
        status: {
          ...state.status,
          refreshUserTokenPending: false,
          refreshUserTokenSuccess: true,
        },
      };
    }
    case types.REFRESH_USER_TOKEN_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          refreshUserTokenPending: false,
          refreshUserTokenSuccess: false,
        },
      };
    }
    case types.DELETE_ACCOUNT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteAccountPending: true,
          deleteAccountSuccess: false,
        },
      };
    }
    case types.DELETE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteAccountPending: false,
          deleteAccountSuccess: true,
        },
      };
    }
    case types.DELETE_ACCOUNT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteAccountPending: false,
          deleteAccountSuccess: false,
        },
      };
    }
    case types.EDIT_ACCOUNT_REQUEST: {
      const { updatedInfo } = payload;
      let { viewOptions } = state;
      if (updatedInfo.viewOptions) {
        viewOptions = {
          ...state.viewOptions,
          ...updatedInfo.viewOptions,
        };
      }
      let { newProjectOptions } = state;
      if (updatedInfo.newProjectOptions) {
        newProjectOptions = {
          ...state.newProjectOptions,
          ...updatedInfo.newProjectOptions,
        };
      }
      let { preferences } = state;
      if (updatedInfo.preferences) {
        preferences = {
          ...state.preferences,
          ...updatedInfo.preferences,
        };
      }
      return {
        ...state,
        viewOptions,
        newProjectOptions,
        preferences,
        status: {
          ...state.status,
          editAccountPending: true,
          editAccountSuccess: false,
        },
      };
    }
    case types.EDIT_ACCOUNT_SUCCESS: {
      const { email } = payload.user;
      return {
        ...state,
        userEmail: email,
        status: {
          ...state.status,
          editAccountPending: false,
          editAccountSuccess: true,
        },
      };
    }
    case types.EDIT_ACCOUNT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          editAccountPending: false,
          editAccountSuccess: false,
        },
      };
    }
    case types.GET_ACCOUNT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getAccountPending: true,
          getAccountSuccess: false,
        },
      };
    }
    case types.GET_ACCOUNT_SUCCESS: {
      const {
        username,
        email,
        loginHistory,
        integrations,
        viewOptions,
        newProjectOptions,
        preferences,
      } = payload.user;
      return {
        ...state,
        username,
        userEmail: email,
        loginHistory,
        viewOptions: {
          ...state.viewOptions,
          ...viewOptions,
        },
        newProjectOptions: {
          ...state.newProjectOptions,
          ...newProjectOptions,
        },
        preferences: {
          ...state.preferences,
          ...preferences,
        },
        userIntegrations: integrations,
        status: {
          ...state.status,
          getAccountPending: false,
          getAccountSuccess: true,
        },
      };
    }
    case types.GET_ACCOUNT_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getAccountPending: false,
          getAccountSuccess: false,
        },
      };
    }
    case types.REQUEST_PASSWORD_RESET_REQUEST: {
      const { email } = payload;
      return {
        ...state,
        usernameOrEmail: email,
        status: {
          ...state.status,
          requestPasswordResetPending: true,
          requestPasswordResetSuccess: false,
        },
      };
    }
    case types.REQUEST_PASSWORD_RESET_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          requestPasswordResetPending: false,
          requestPasswordResetSuccess: true,
        },
      };
    }
    case types.REQUEST_PASSWORD_RESET_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          requestPasswordResetPending: false,
          requestPasswordResetSuccess: false,
        },
      };
    }
    case types.RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          resetPasswordPending: true,
          resetPasswordSuccess: false,
        },
      };
    }
    case types.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          resetPasswordPending: false,
          resetPasswordSuccess: true,
        },
      };
    }
    case types.RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          resetPasswordPending: false,
          resetPasswordSuccess: false,
        },
      };
    }
    case types.AUTHORIZE_INTEGRATION_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          authorizeIntegrationPending: true,
          authorizeIntegrationSuccess: false,
        },
      };
    }
    case types.AUTHORIZE_INTEGRATION_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          authorizeIntegrationPending: false,
          authorizeIntegrationSuccess: true,
        },
      };
    }
    case types.AUTHORIZE_INTEGRATION_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          authorizeIntegrationPending: false,
          authorizeIntegrationSuccess: false,
        },
      };
    }
    case types.LINK_INTEGRATION_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          linkIntegrationPending: true,
          linkIntegrationSuccess: false,
        },
      };
    }
    case types.LINK_INTEGRATION_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          linkIntegrationPending: false,
          linkIntegrationSuccess: true,
        },
      };
    }
    case types.LINK_INTEGRATION_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          linkIntegrationPending: false,
          linkIntegrationSuccess: false,
        },
      };
    }
    case types.UNLINK_INTEGRATION_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          unlinkIntegrationPending: true,
          unlinkIntegrationSuccess: false,
        },
      };
    }
    case types.UNLINK_INTEGRATION_SUCCESS: {
      const { integrationId } = payload;
      let updatedIntegrations = _.omit(state.userIntegrations, integrationId);
      if (_.isEmpty(updatedIntegrations)) updatedIntegrations = null;
      return {
        ...state,
        userIntegrations: updatedIntegrations,
        status: {
          ...state.status,
          unlinkIntegrationPending: false,
          unlinkIntegrationSuccess: true,
        },
      };
    }
    case types.UNLINK_INTEGRATION_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          unlinkIntegrationPending: false,
          unlinkIntegrationSuccess: false,
        },
      };
    }
    case types.GET_INTEGRATIONS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getIntegrationsPending: true,
          getIntegrationsSuccess: false,
        },
      };
    }
    case types.GET_INTEGRATIONS_SUCCESS: {
      const { integrations } = payload;
      return {
        ...state,
        allIntegrations: integrations,
        status: {
          ...state.status,
          getIntegrationsPending: false,
          getIntegrationsSuccess: true,
        },
      };
    }
    case types.GET_INTEGRATIONS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getIntegrationsPending: false,
          getIntegrationsSuccess: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};

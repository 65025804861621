import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import API from '../canvas-api';

import { handleGenericError, getAuthState } from '../common';
import { actions } from '../../reducers/auth/auth';

export default function* getAccount(action) {
  try {
    const { userId } = yield select(getAuthState);

    const response = yield call(API, {
      path: `users/?id=${userId}`,
    });
    if (response === null) return;

    const { user } = response;
    yield put(actions.getAccountSuccess(user));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

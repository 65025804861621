import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../canvas-api';

import { handleGenericError } from '../common';
import { actions } from '../../reducers/auth/auth';
import { ValidateUtils } from '../../utils';

export default function* login(action) {
  try {
    const { email, password } = action.payload;
    const emailFieldName = ValidateUtils.isEmail(email) ? 'email' : 'username';
    const formData = {
      [emailFieldName]: email,
      password,
    };

    const response = yield call(API, {
      method: methods.POST,
      path: 'users/login',
      token: null,
      body: formData,
    });

    const user = {
      token: response.token,
      refreshToken: response.refreshToken,
      username: response.username,
      userId: response.id,
      userEmail: response.email,
    };
    yield put(actions.loginSuccess(user));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

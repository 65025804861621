import React, { Component } from 'react';

import { LegalPageWrapper } from './legal.styles';
import { Page } from '../../shared';

class LegalPage extends Component {
  renderMainPanelContent() {
    return null;
  }

  render() {
    return (
      <Page>
        <LegalPageWrapper>{this.renderMainPanelContent()}</LegalPageWrapper>
      </Page>
    );
  }
}

export default LegalPage;

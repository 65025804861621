import { useEffect } from 'react';

const useWindowEvent = (events) => {
  useEffect(() => {
    events.forEach((eventArgs) => {
      window.addEventListener(...eventArgs);
    });
    return () =>
      events.forEach((eventArgs) => {
        window.removeEventListener(...eventArgs);
      });
  }, [events]);
};

export default useWindowEvent;

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {
  computeBoundsTree,
  disposeBoundsTree,
  acceleratedRaycast,
} from 'three-mesh-bvh';

import types from '../../reducers/three/types';

// replace THREE's default bounds tree calculation functions
THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

const CIRCLE_SEGMENTS = 64;

const Utils = {
  createVec2: () => new THREE.Vector2(),
  createVec3: () => new THREE.Vector3(),
  createColor: () => new THREE.Color(),
  createQuat: () => new THREE.Quaternion(),
  createCamera: (orthographic = false) => {
    const { innerWidth: width, innerHeight: height } = window;
    const near = 0.2;
    const far = 10000;
    let camera;
    if (orthographic) {
      const left = -width / 2;
      const right = width / 2;
      const top = height / 2;
      const bottom = -height / 2;
      camera = new THREE.OrthographicCamera(
        left,
        right,
        top,
        bottom,
        near,
        far
      );
    } else {
      const fov = 75;
      const aspectRatio = width / height;
      camera = new THREE.PerspectiveCamera(fov, aspectRatio, near, far);
    }
    camera.up.set(0, 0, 1);
    return camera;
  },
  createSphereMarker: () => new THREE.Sphere(),
  createRaycaster: () => new THREE.Raycaster(),
  createMouse: () => new THREE.Vector2(),
  createScene: () => new THREE.Scene(),
  createRenderer: (domElement) => {
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      powerPreference: 'low-power',
      alpha: true,
      depth: true,
      canvas: domElement,
    });
    renderer.setPixelRatio(window.devicePixelRatio || 1);
    return renderer;
  },
  createRendererForSnapshot: (domElement) => {
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      canvas: domElement,
      preserveDrawingBuffer: true,
    });
    renderer.setPixelRatio(window.devicePixelRatio || 1);
    return renderer;
  },
  createAmbientLight: () => new THREE.AmbientLight(0x736f6e),
  createPointLight: () => new THREE.PointLight(0xffffff, 1),
  createGridHelper: (size, divisions) => new THREE.GridHelper(size, divisions),
  createPrintBed: (xLength, yLength, isCircular, bedColor) => {
    let geometry;
    if (isCircular) {
      const radius = xLength / 2;
      geometry = new THREE.CircleGeometry(radius, CIRCLE_SEGMENTS);
    } else {
      geometry = new THREE.PlaneGeometry(xLength, yLength);
    }
    const material = new THREE.MeshBasicMaterial({
      color: bedColor,
      transparent: true,
      opacity: 0.8,
      side: THREE.FrontSide,
    });
    const bedMesh = new THREE.Mesh(geometry, material);
    bedMesh.position.setZ(-0.11);
    const edges = new THREE.EdgesGeometry(geometry);
    const edgeMaterial = new THREE.LineBasicMaterial({ color: 0x7f8c8d });
    const edgeMesh = new THREE.LineSegments(edges, edgeMaterial);
    edgeMesh.position.setZ(-0.11);
    const group = new THREE.Group();
    group.add(bedMesh);
    group.add(edgeMesh);
    group.name = types.BED_MESH_NAME;
    return group;
  },
  createPrintBedBoundingBox: (bedSize, originOffset, isCircular) => {
    const bedMiddle = Utils.getBedMiddle(bedSize, originOffset);
    if (isCircular) {
      const radius = bedSize.x / 2;
      const material = new THREE.LineBasicMaterial({ color: 0x7f8c8d });
      const circle = new THREE.CircleGeometry(radius, CIRCLE_SEGMENTS);
      const circleEdges = new THREE.EdgesGeometry(circle);
      const meshTop = new THREE.LineSegments(circleEdges, material);
      meshTop.position.set(0, 0, bedSize.z);
      const lines = new THREE.BufferGeometry();
      const vertices = [];
      for (let i = 0; i < 4; i++) {
        const theta = (i * Math.PI) / 2 + Math.PI / 4;
        const x = radius * Math.cos(theta);
        const y = radius * Math.sin(theta);
        vertices.push(x, y, 0, x, y, bedSize.z);
      }
      lines.addAttribute(
        'position',
        new THREE.BufferAttribute(new Float32Array(vertices), 3)
      );
      const lineMesh = new THREE.LineSegments(lines, material);
      const group = new THREE.Group();
      group.add(meshTop);
      group.add(lineMesh);
      group.position.set(bedMiddle.x, bedMiddle.y, 0);
      group.name = types.BED_BOX_NAME;
      return group;
    }
    const box = new THREE.Box3();
    box.setFromCenterAndSize(
      new THREE.Vector3(bedMiddle.x, bedMiddle.y, bedSize.z / 2),
      new THREE.Vector3(bedSize.x, bedSize.y, bedSize.z)
    );
    const mesh = new THREE.Box3Helper(box, 0x7f8c8d);
    const group = new THREE.Group();
    group.add(mesh);
    group.name = types.BED_BOX_NAME;
    return group;
  },
  createPrintBedGrid: (
    bedSize,
    originOffset,
    isCircular,
    majorGridColor,
    minorGridColor
  ) => {
    const tickMajor = 50;
    const tickMinor = 5;
    const colorMajor = new THREE.Color(majorGridColor).toArray();
    const colorMinor = new THREE.Color(minorGridColor).toArray();
    const bedMiddle = Utils.getBedMiddle(bedSize, originOffset);
    const radius = bedSize.x / 2;
    const xMin = bedMiddle.x - bedSize.x / 2;
    const xMax = xMin + bedSize.x;
    const yMin = bedMiddle.y - bedSize.y / 2;
    const yMax = yMin + bedSize.y;

    const lines = new THREE.BufferGeometry();
    const vertices = [];
    const colors = [];
    // north/south grid lines (traverse bed in +X direction)
    let currentX = Math.ceil(xMin / tickMinor) * tickMinor;
    while (currentX <= xMax) {
      let yStart = yMin;
      let yEnd = yMax;
      if (isCircular) {
        const dx = Math.abs(bedMiddle.x - currentX);
        const dy = radius * Math.sin(Math.acos(dx / radius));
        yStart = bedMiddle.y - dy;
        yEnd = bedMiddle.y + dy;
      }
      vertices.push(currentX, yStart, 0);
      vertices.push(currentX, yEnd, 0);
      if (currentX % tickMajor === 0) {
        // major grid line
        colors.push(...colorMajor, ...colorMajor);
      } else {
        // minor grid line
        colors.push(...colorMinor, ...colorMinor);
      }
      currentX += tickMinor;
    }

    // east/west grid lines (traverse bed in +Y direction)
    let currentY = Math.ceil(yMin / tickMinor) * tickMinor;
    while (currentY <= yMax) {
      let xStart = xMin;
      let xEnd = xMax;
      if (isCircular) {
        const dy = Math.abs(bedMiddle.y - currentY);
        const dx = radius * Math.sin(Math.acos(dy / radius));
        xStart = bedMiddle.x - dx;
        xEnd = bedMiddle.x + dx;
      }
      vertices.push(xStart, currentY, 0);
      vertices.push(xEnd, currentY, 0);
      if (currentY % tickMajor === 0) {
        // major grid line
        colors.push(...colorMajor, ...colorMajor);
      } else {
        // minor grid line
        colors.push(...colorMinor, ...colorMinor);
      }
      currentY += tickMinor;
    }

    lines.addAttribute(
      'position',
      new THREE.BufferAttribute(new Float32Array(vertices), 3)
    );
    lines.addAttribute(
      'color',
      new THREE.BufferAttribute(new Float32Array(colors), 3)
    );

    const material = new THREE.LineBasicMaterial({
      color: 0xffffff,
      vertexColors: THREE.VertexColors,
    });
    const lineMesh = new THREE.LineSegments(lines, material);
    lineMesh.position.setZ(-0.1);
    const group = new THREE.Group();
    group.add(lineMesh);
    group.name = types.BED_GRID_NAME;
    return group;
  },
  createBrush: () => {
    const geometry = new THREE.SphereBufferGeometry(1, 32, 32);
    geometry.attributes.position.dynamic = true;
    const material = new THREE.MeshLambertMaterial({
      color: 'grey',
      opacity: 0.2,
      depthTest: true,
      transparent: true,
    });
    const brush = new THREE.Mesh(geometry, material);
    brush.visible = false;
    return brush;
  },
  createDot: () => {
    const geometry = new THREE.BufferGeometry();
    const vertices = new Float32Array([0, 0, 0]);
    geometry.addAttribute('position', new THREE.BufferAttribute(vertices, 3));
    const material = new THREE.PointsMaterial({
      color: 'red',
      size: 5,
      sizeAttenuation: false,
      depthTest: false,
      transparent: true,
    });
    const dot = new THREE.Points(geometry, material);
    dot.visible = false;
    dot.renderOrder = 1;
    return dot;
  },
  createOrbitControls: (camera, domElement) => {
    const controls = new OrbitControls(camera, domElement);
    controls.screenSpacePanning = true;
    controls.enableKeys = false;
    return controls;
  },
  getBedMiddle: (bedDimensions, originOffset) => {
    let bed = bedDimensions;
    if (Array.isArray(bedDimensions)) {
      bed = {
        x: bedDimensions[0],
        y: bedDimensions[1],
        z: bedDimensions[2],
      };
    }
    let origin = originOffset;
    if (Array.isArray(originOffset)) {
      origin = {
        x: originOffset[0],
        y: originOffset[1],
        z: originOffset[2],
      };
    }
    return {
      x: bed.x / 2 - origin.x,
      y: bed.y / 2 - origin.y,
      z: 0,
    };
  },
  getCameraResetPosition: (
    bedSize,
    originOffset,
    boundingSphere,
    orthographic = false
  ) => {
    const bedMiddle = Utils.getBedMiddle(bedSize, originOffset);
    const pos = { x: 0, y: 0, z: 0 };
    if (orthographic) {
      pos.x = bedMiddle.x + bedSize.x / 8;
      pos.y = bedMiddle.y - bedSize.y;
      pos.z = bedSize.z - bedSize.z / 4;
    } else {
      pos.x = bedMiddle.x;
      pos.y = bedMiddle.y - boundingSphere.radius * 1.25;
      pos.z = (bedMiddle.y - pos.y) * Math.tan((45 * Math.PI) / 180);
    }
    return pos;
  },
  getCameraSnapshotPosition: (boundingSphere) => {
    const pos = { x: 0, y: 0, z: 0 };
    const { radius, center } = boundingSphere;

    // the lower the multiplier, the closer the camera is to the bounding sphere
    const distYMultiplier = 1.25;

    // calculates the y-axis distance using the radius of the bounding sphere
    const distY = radius * distYMultiplier;

    // calculates the z-axis distance for a 40 degrees view of the bounding sphere
    const distZ = distY * Math.tan((40 * Math.PI) / 180);

    pos.x = center.x;
    pos.y = center.y - distY;
    pos.z = center.z + distZ;
    return pos;
  },
  buildAxis: (src, dst, colorHex, dashed) => {
    const geometry = new THREE.BufferGeometry();
    let material;
    if (dashed) {
      material = new THREE.LineDashedMaterial({
        linewidth: 1,
        color: colorHex,
        dashSize: 3,
        gapSize: 3,
      });
    } else {
      material = new THREE.LineBasicMaterial({
        linewidth: 1,
        color: colorHex,
      });
    }
    const vertices = new Float32Array([
      src.x,
      src.y,
      src.z,
      dst.x,
      dst.y,
      dst.z,
    ]);
    geometry.addAttribute('position', new THREE.BufferAttribute(vertices, 3));
    const line = new THREE.LineSegments(geometry, material);
    line.computeLineDistances();
    return line;
  },
  createAxes: (length) => {
    const axes = new THREE.Group();
    const origin = new THREE.Vector3(0, 0, 0);
    axes.add(
      Utils.buildAxis(origin, new THREE.Vector3(length, 0, 0), 0xff0000, false)
    ); // +X
    axes.add(
      Utils.buildAxis(origin, new THREE.Vector3(-length, 0, 0), 0xff0000, true)
    ); // -X
    axes.add(
      Utils.buildAxis(origin, new THREE.Vector3(0, length, 0), 0x00ff00, false)
    ); // +Y
    axes.add(
      Utils.buildAxis(origin, new THREE.Vector3(0, -length, 0), 0x00ff00, true)
    ); // -Y
    axes.add(
      Utils.buildAxis(origin, new THREE.Vector3(0, 0, length), 0x0000ff, false)
    ); // +Z
    axes.add(
      Utils.buildAxis(origin, new THREE.Vector3(0, 0, -length), 0x0000ff, true)
    ); // -Z
    axes.name = types.AXES_OBJ_NAME;
    return axes;
  },
  convertHexToThreeColor: (hex) => new THREE.Color().set(hex),
};

export default Utils;

import 'regenerator-runtime/runtime';
import { call, put, select, take } from 'redux-saga/effects';
import _ from 'lodash';

import { handleGenericError, getPrinterState } from '../common';
import { actions, types } from '../../reducers/printers/printers';

export default function* duplicatePrinter(action) {
  try {
    const { printers } = yield select(getPrinterState);
    const { printerId } = action.payload;
    const originalPrinter = printers[printerId];

    // first, compose new printer data
    const machineSettings = _.omit(originalPrinter.machineSettings, [
      'timestamp',
      'shareData',
    ]);

    const printerData = {
      machineSettings,
      modded: originalPrinter.modded || false,
    };

    // also include tag id, if available
    if (originalPrinter.tagId) printerData.tagId = originalPrinter.tagId;

    yield put(actions.createPrinterRequest(printerData));

    // wait for createPrinterSuccess
    const createPrinterSuccessAction = yield take(types.CREATE_PRINTER_SUCCESS);
    const createdPrinterId = createPrinterSuccessAction.payload.printer.id;

    // next, create styles if there are any
    if (!_.isEmpty(originalPrinter.styles)) {
      yield put(
        actions.createStyleProfilesRequest(
          createdPrinterId,
          originalPrinter.styles
        )
      );
      // wait for createStyleProfilesSuccess
      yield take(types.CREATE_STYLE_PROFILES_SUCCESS);
    }

    // finally, dispatch duplicatePrinterSuccess
    yield put(actions.duplicatePrinterSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

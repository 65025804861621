import styled from 'styled-components';
import { Breakpoints } from '../../../themes';

export const ToolboxContainer = styled.div`
  position: absolute;
  left: 1rem;
  top: 8rem;
  display: flex;
  flex-direction: column;
  z-index: 2;

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    left: 0;
    top: 4.5rem;
    align-items: flex-start;
  }
`;

export const ToolboxContent = styled.div`
  padding: 0 0.75rem 0.75rem 0.75rem;
`;

export const ToolboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: none;
  }
`;

export const ToolboxMobileWrapper = styled.div`
  position: relative;
  left: 1rem;
  display: none;
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: block;
  }
`;

export const ControlButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.5rem;
  padding: 0.75rem;
  align-items: center;
`;

export const ExtruderButtonsContainer = styled(ControlButtonsContainer)`
  padding: 0.75rem 0.875rem;
  grid-gap: 0.5rem 0.75rem;
  ${(props) =>
    props.materialListView &&
    `
  min-width:11.25rem;
  grid-template-columns: none;
  grid-template-row: ${props.length}fr;
  justify-content: start;
  max-height: calc(100vh - ${props.toolboxWrapperHeight + 311}px);
  overflow-y: auto;
  overflow-x: hidden; 
  `}
  ${(props) => props.inMobileView && `max-height: calc(100vh - 15.5rem);`}
  min-height: 1.5rem;
  }
`;
export const ColorSwatchInfoContainer = styled.div`
  ${(props) =>
    props.materialListView &&
    `
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem ;
  align-items: center;`}
`;

export const ViewButtonsContainer = styled.div`
  position: fixed;
  bottom: 1em;
  right: 1em;
  display: flex;
  z-index: 1;
`;

export const SliceButtonWrapper = styled.div`
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    position: fixed;
    bottom: 1em;
    right: 1em;
  }

  @media (max-height: ${Breakpoints.smallHeight}) {
    font-size: 0.9em;
  }
`;

export const MobileToolbarRightWrapper = styled.div`
  display: none;
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: block;
    z-index: 2;
    position: absolute;
    left: calc(100vw - 3.5rem);
    top: 0rem;
  }
`;

export const MobileToolbarLeftWrapper = styled.div`
  position: relative;
  left: 1rem;
  display: none;
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: block;
  }
`;

export const ObjectBrowserContainer = styled.div`
  display: block;
  position: relative;
  left: calc(100vw - 16rem);
  top: 11rem;
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: none;
  }
`;

export const ObjectBrowserContainerMobile = styled(ObjectBrowserContainer)`
  display: none;
  top: 0rem;
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: block;
    bottom: 0rem;
  }
`;

export const GeneralButtonWrapper = styled.div`
  margin-bottom: 0.5rem;
`;
export const MaterialPanelContent = styled.div`
  width: 13rem;
`;

export const MobileToolbar = styled.div`
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    //  min-height used to verify screen size change on resize
    min-height: 1rem;
  }
`;

export const SpliceButtonWrapper = styled.div`
  margin: 0 0.75rem 0.75rem 0.75rem;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: auto 1fr;
`;

export const MaterialToolContainer = styled.div`
  position: absolute;
  top: 10.5rem;
  left: 13.25rem;
  ${(props) =>
    !props.materialListView &&
    ` top:${
      10.5 + (Math.ceil((props.currentExtruderIndex + 1) / 4) - 1) * 2.75
    }rem;`}
`;

export const MaterialToolContainerMobile = styled.div`
  margin-left: 1rem;
  display: none;
  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    display: block;
  }
`;

export const ListViewMaterialInfo = styled.div`
  p {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: 9.5rem;
  }
`;

export const EditMaterialButtonMobileWrapper = styled.div`
  margin: 0 0.75rem 0.5rem 0.75rem;
`;

import _ from 'lodash';
import CodeMirror from 'codemirror';

const KEYWORDS = ['if', 'else', 'while', 'true', 'false'];

const OP_FIRST_CHARS = [
  '(',
  ')',
  ',',
  '-',
  '+',
  '*',
  '/',
  '%',
  '>',
  '<',
  '=',
  '!',
  '&',
  '|',
];

const TOKEN_TYPES = {
  ERROR: 'error',
  COMMENT: 'comment',
  DIRECTIVE: 'directive',
  KEYWORD: 'keyword',
  IDENT: 'identifier',
  LITERAL: 'literal',
  OUTPUT: 'output',
  TEMPLATE: 'template',
  BRACE: 'brace',
  OP: 'op',
};

const LEXER_MODES = {
  EXPRESSION: 'expression',
  BLOCK_COMMENT: 'block_comment',
  OUTPUT_SQ: 'output_sq',
  OUTPUT_DQ: 'output_dq',
};

/* eslint-disable no-param-reassign */
const printerScriptMode = (/* config */) => {
  const mode = {};

  mode.lineComment = '//';
  mode.blockCommentStart = '/*';
  mode.blockCommentEnd = '*/';

  // stateful modes must provide an initial state
  mode.startState = () => ({
    mode: [null], // null for "default" lexer mode
    identifiers: new Set(),
  });

  mode.copyState = (state) => ({
    ...state,
    mode: [...state.mode],
    identifiers: new Set(state.identifiers),
  });

  mode.compareStates = (/* a, b */) => false;

  mode.parseBlockComment = (stream, state) => {
    while (!stream.eol()) {
      if (stream.eat('*') && stream.eat('/')) {
        // comment ends here
        state.mode.pop();
        return TOKEN_TYPES.COMMENT;
      }
      // escaped close comment
      if (!stream.eol() && stream.eat('\\')) {
        if (!stream.eol() && stream.eat('*')) {
          if (!stream.eol()) {
            stream.eat('/');
          }
        }
      }
      // all other text
      if (!stream.eol()) {
        stream.next();
      }
    }
    // no state.mode.pop, comment will continue next line
    return TOKEN_TYPES.COMMENT;
  };

  mode.parseOutput = (stream, state, quoteType = '"') => {
    let iters = 0;
    while (!stream.eol()) {
      const next = stream.next();
      // escapes
      if (next === '\\') {
        if (!stream.eol()) {
          if (stream.eat(/['"]/)) {
            // eat escaped quote (either type)
          } else if (stream.eat('{') && stream.eat('{')) {
            // eat escaped template
          }
        }
      } else if (next === '{') {
        if (!stream.eol() && stream.eat('{')) {
          if (iters > 0) {
            // non-template text has already been matched,
            // give these matches back to divide the token
            // here (more efficient than per-character!) and
            // match next time, when iters === 0
            stream.backUp(2);
            return TOKEN_TYPES.OUTPUT;
          }
          state.mode.push(null);
          return TOKEN_TYPES.TEMPLATE;
        }
      } else if (next === quoteType) {
        // closing quote (matching type only)
        state.mode.pop();
        return TOKEN_TYPES.OUTPUT;
      }
      iters++;
      // else continue calling next()
    }
    // end-of-line reached without closing string
    state.mode.pop(); // try to resume non-string parsing on the next line
    return TOKEN_TYPES.ERROR;
  };

  // read one token from the stream,
  // optionally mutate our state,
  // and return a style string (or `null` for tokens that do not have to be styled)
  mode.token = (stream, state) => {
    // sub-mode continuations pre-empt normal decision-making
    if (state.mode[state.mode.length - 1] === LEXER_MODES.BLOCK_COMMENT) {
      // continuation of a multi-line block comment
      return mode.parseBlockComment(stream, state);
    }
    if (state.mode[state.mode.length - 1] === LEXER_MODES.OUTPUT_DQ) {
      // continuation of a double-quoted string
      return mode.parseOutput(stream, state, '"');
    }
    if (state.mode[state.mode.length - 1] === LEXER_MODES.OUTPUT_SQ) {
      // continuation of a single-quoted string
      return mode.parseOutput(stream, state, "'");
    }

    if (stream.eatSpace()) {
      return null;
    }

    // look at next character to decide
    const next = stream.next();

    if (next === '@') {
      stream.skipToEnd();
      return TOKEN_TYPES.DIRECTIVE;
    }

    // line/block comment, or division?
    if (next === '/') {
      if (stream.eat('/')) {
        // line comment
        stream.skipToEnd();
        return TOKEN_TYPES.COMMENT;
      }
      if (stream.eat('*')) {
        // block comment
        state.mode.push(LEXER_MODES.BLOCK_COMMENT);
        return TOKEN_TYPES.COMMENT;
      }
      // division
      return TOKEN_TYPES.OP;
    }

    // identifier, or keyword?
    if (/[a-zA-Z_]/.test(next)) {
      stream.eatWhile(/[a-zA-Z0-9_]/);
      const text = stream.current();
      if (_.includes(KEYWORDS, text)) {
        // case-sensitive!
        return TOKEN_TYPES.KEYWORD;
      }
      state.identifiers.add(text);
      return TOKEN_TYPES.IDENT;
    }

    // closing brace/close template?
    if (next === '}') {
      if (stream.eat('}')) {
        state.mode.pop();
        if (state.mode.length === 0) {
          state.mode.push(null);
          return TOKEN_TYPES.ERROR;
        }
        return TOKEN_TYPES.TEMPLATE;
      }
      return TOKEN_TYPES.BRACE;
    }

    // opening brace?
    if (next === '{') {
      return TOKEN_TYPES.BRACE;
    }

    // operator?
    if (_.includes(OP_FIRST_CHARS, next)) {
      if (next === '&' && !stream.eat('&')) {
        return TOKEN_TYPES.ERROR;
      }
      if (next === '|' && !stream.eat('|')) {
        return TOKEN_TYPES.ERROR;
      }
      if (next === '>' || next === '<' || next === '!' || next === '=') {
        stream.eat('='); // valid operator either way, just consume it
      }
      return TOKEN_TYPES.OP;
    }

    // output statement?
    if (next === '"' || next === "'") {
      state.mode.push(
        next === '"' ? LEXER_MODES.OUTPUT_DQ : LEXER_MODES.OUTPUT_SQ
      );
      return TOKEN_TYPES.OUTPUT;
    }

    // int/float literal?
    if (/[0-9]/.test(next)) {
      stream.eatWhile(/[0-9]/);
      if (stream.eat('.')) {
        stream.eatWhile(/[0-9]/);
      }
      return TOKEN_TYPES.LITERAL;
    }

    // float literal (omitting leading 0 before decimal)?
    if (next === '.') {
      stream.eatWhile(/[0-9]/);
      return TOKEN_TYPES.LITERAL;
    }

    // any other token is news to us!
    return TOKEN_TYPES.ERROR;
  };

  return mode;
};
/* eslint-enable no-param-reassign */

const PrinterScriptMode = {
  name: 'printerscript',
  fn: printerScriptMode,
};

export default PrinterScriptMode;

export const showHint = (editor /* , event */) => {
  CodeMirror.showHint(
    editor,
    () => {
      const cursor = editor.getCursor();
      const token = editor.getTokenAt(cursor);
      const finalState = editor.getStateAfter();
      const { start } = token;
      const end = cursor.ch;
      const { line } = cursor;
      const currentWord = token.string;
      const candidates = [...KEYWORDS, ...Array.from(finalState.identifiers)];
      let matches = [];
      if (currentWord.length > 0) {
        matches = _.filter(
          candidates,
          (ident) => ident !== currentWord && ident.startsWith(currentWord)
        );
      }
      return {
        list: matches,
        from: CodeMirror.Pos(line, start),
        to: CodeMirror.Pos(line, end),
      };
    },
    {
      completeSingle: false,
    }
  );
};

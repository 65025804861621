import React from 'react';
import { CustomPicker } from 'react-color';
import {
  Saturation,
  Hue,
  Alpha,
  Checkboard,
} from 'react-color/lib/components/common';
import ChromePointer from 'react-color/lib/components/chrome/ChromePointer';
import ChromePointerCircle from 'react-color/lib/components/chrome/ChromePointerCircle';

import {
  Container,
  SaturationSection,
  SwatchSection,
  HueSection,
  AlphaSection,
  Swatch,
} from './colorPicker.styles';

const MyPicker = (props) => {
  const rgbaString = `rgba(${props.rgb.r},${props.rgb.g},${props.rgb.b},${props.rgb.a})`;
  return (
    <Container>
      <SaturationSection>
        <Saturation {...props} pointer={ChromePointerCircle} />
      </SaturationSection>
      <SwatchSection>
        <Checkboard size={10} />
        <Swatch style={{ backgroundColor: rgbaString }} />
      </SwatchSection>
      <HueSection>
        <Hue {...props} pointer={ChromePointer} />
      </HueSection>
      <AlphaSection>
        <Alpha {...props} pointer={ChromePointer} />
      </AlphaSection>
    </Container>
  );
};

export default CustomPicker(MyPicker);

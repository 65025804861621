import styled from 'styled-components';
import { Breakpoints } from '../../../../../themes';
import { Image } from '../../../../../shared';

export const MetaSection = styled.div`
  display: flex;
  gap: 0.25rem;
  height: min-content;
`;

export const DeviceImage = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grey3};
  padding: 0.75rem;
  border-radius: 0.25rem;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledImage = styled(Image)`
  width: 80%;
  height: 80%;
  align-self: center;
`;

export const DetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
  padding: 0.75rem;
  overflow-x: hidden;

  @media (min-width: ${Breakpoints.mediumWidth}) {
    margin-right: 5rem;
  }
`;

export const DeviceInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  width: 90%;
`;

export const SubtitleWrapper = styled.div`
  min-width: 0;
`;

export const AdditionalInfoWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  justify-content: space-between;

  @media (max-width: ${Breakpoints.smallWidth}) {
    flex-direction: column;
  }
`;
export const NameAndType = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

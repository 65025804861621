import React, { Component } from 'react';
import { withTheme } from 'styled-components';

import { isAnyChrome, isAnySafari, isFirefox } from './constants';
import { Container, ContainerInner } from './browserWarning.styles';
import ActionButton from '../actionButton/actionButton.jsx';
import { Icons } from '../../themes';
import { Body1 } from '../typography/typography';

class BrowserWarning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWarning: !isAnyChrome && !isAnySafari && !isFirefox,
    };
  }

  render() {
    if (!this.state.showWarning) return null;
    return (
      <Container>
        <ContainerInner>
          <Body1 noSpacing>
            You are using an unsupported browser. If you experience any issues
            with Canvas, please try using Chrome, Safari, or Firefox.
          </Body1>
          <ActionButton
            minimal
            icon={Icons.basic.x}
            iconColor={this.props.theme.colors.blackDefault}
            onClick={() => this.setState({ showWarning: false })}
          />
        </ContainerInner>
      </Container>
    );
  }
}

export default withTheme(BrowserWarning);

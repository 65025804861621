import styled from 'styled-components';
import { Body1 } from '../typography/typography';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  z-index: 5000;
  pointer-events: none;
`;

export const ContainerInner = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  position: relative;
  box-sizing: border-box;
  max-width: 36rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  pointer-events: all;
  border-radius: 0.25rem;
  background-color: ${(props) => props.theme.colors.yellowLight};
  ${Body1} {
    color: ${(props) => props.theme.colors.blackDefault};
  }
`;

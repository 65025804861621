import styled from 'styled-components';

export const CardGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
  grid-gap: 0.5rem;
  justify-content: center;
  margin-bottom: 0.75rem;
`;

export const CardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 0.75rem;
`;

const initialState = {
  canvas: null, // DOM element used for rendering
  scene: null, // THREE.Scene
  camera: null, // THREE.Camera, either perspective or orthographic
  renderer: null, // THREE.Renderer
  controls: null, // THREE.OrbitControls
  ambientLight: null, // attached directly to scene
  pointLight: null, // attached directly to camera
  printerHeight: 0, // Z height of printer, used for camera reset math
  originAxes: null, // origin axis lines, user-toggled
  printBed: null, // solid print bed, (generally) always visible
  printBedBoundingBox: null, // 3D bounding box, user-toggled
  printBedGrid: null, // grid lines, user-toggled
  gizmo: null,
  gizmoCenter: null,
  boundaries: null,
  hoveredModels: [],
  resetCamera: () => {},
  status: {
    initScenePending: false,
    initSceneSuccess: false,
    destroyScenePending: false,
    destroySceneSuccess: false,
    updatePrintBedPending: false,
    isMovingSwatch: false,
    updateGizmoPending: false,
  },
};

export default initialState;

import { connect } from 'react-redux';

import ConfigureStyle from '../configureStyle.jsx';
import { actions } from '../../../../reducers/printers/printers';

const mapStateToProps = (state) => ({
  mode: 'create',
  title: 'Create Custom Style Profile',
  printerTag: state.printers.currentPrinterTag,
  printers: state.printers.printers,
  createStylesPending: state.printers.status.createStylesPending,
  requestSuccess: state.printers.status.createStylesSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  createStyleProfiles: (printerId, styles) =>
    dispatch(actions.createStyleProfilesRequest(printerId, styles)),
  getPrinterTag: (tagId) => dispatch(actions.getPrinterTagRequest(tagId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureStyle);

import React, { useRef, useEffect, useCallback, useContext } from 'react';
import * as d3 from 'd3';
import { legendColor } from 'd3-svg-legend';
import { ThemeContext } from 'styled-components';
import { useResizeDetector } from 'react-resize-detector';
import { LineChartContainer } from './lineChart.styles';

function getRange(data, key, [minX, minY]) {
  return [
    minX !== null
      ? minX
      : d3.min(
          data.map((d) => d.data),
          (arr) => d3.min(arr, (d) => d[key])
        ),
    minY !== null
      ? minY
      : d3.max(
          data.map((d) => d.data),
          (arr) => d3.max(arr, (d) => d[key])
        ),
  ];
}

const LineChart = ({
  data,
  xRange,
  yRange,
  margin,
  yTickCount,
  yTickFormat,
  patternStrokeWidth,
}) => {
  const theme = useContext(ThemeContext);
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  let { width, height } = useResizeDetector({
    targetRef: containerRef,
    refreshMode: 'debounce',
    refreshRate: 0,
    handleHeight: false,
  });
  width = width || 0;
  height = height || 0;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const [minX, maxX] = getRange(data, 'x', xRange);
  const [minY, maxY] = getRange(data, 'y', yRange);

  const legendData = data.map(({ color, label }) => ({
    color,
    label,
  }));

  const xScale = d3.scaleLinear().domain([minX, maxX]).range([0, innerWidth]);

  const yScale = d3.scaleLinear().domain([minY, maxY]).range([innerHeight, 0]);

  const renderYAxis = useCallback(
    (graph) => {
      const ticks = d3.axisRight(yScale).ticks(yTickCount);
      if (yTickFormat !== null) {
        ticks.tickFormat(yTickFormat);
      }

      const yAxis = graph
        .append('g')
        .attr('transform', `translate(${innerWidth}, 0)`)
        .call(ticks);

      yAxis.selectAll('.domain').remove();

      const axisTicks = yAxis.selectAll('g.tick').style('font-size', '14px');

      axisTicks
        .select('text')
        .style('fill', theme.colors.textTertiary)
        .attr('font-family', theme.fonts.familyBody);

      axisTicks.select('line').remove();
    },
    [
      yScale,
      yTickCount,
      yTickFormat,
      innerWidth,
      theme.colors.textTertiary,
      theme.fonts.familyBody,
    ]
  );

  const renderSeriesData = useCallback(
    (graph) => {
      data.forEach((seriesData) => {
        // Line generator
        const line = d3
          .line()
          .x((d) => xScale(d.x))
          .y((d) => yScale(d.y));

        // Append the path to the svg element
        graph
          .append('path')
          .datum(seriesData.data)
          .attr('d', line)
          .style('stroke', seriesData.color)
          .style('stroke-width', 2)
          .style('fill', 'none');
      });
    },
    [data, xScale, yScale]
  );

  const renderLegend = useCallback(
    (graph) => {
      const legendMargin = 10;

      const legendContainer = graph
        .append('g')
        .attr('transform', `translate(${legendMargin * 2}, ${legendMargin})`);

      const legendSize = 16;

      const rect = legendContainer
        .append('rect')
        .attr('x', -1 * legendMargin)
        .attr('y', -1 * legendMargin)
        .attr(
          'height',
          legendData.length * (legendSize + 3) + legendMargin * 2 - 3
        )
        .attr('stroke', theme.colors.textTertiary)
        .attr('rx', '4')
        .attr('ry', '4')
        .style('fill', theme.colors.elevatedBackgroundPrimary);

      const ordinal = d3
        .scaleOrdinal()
        .domain(legendData.map((d) => d.label))
        .range(legendData.map((d) => d.color));

      const legendOrdinal = legendColor()
        .shape('rect')
        .shapeWidth(legendSize)
        .shapeHeight(legendSize)
        .shapePadding(0)
        .scale(ordinal);

      legendContainer.call(legendOrdinal);

      legendContainer.selectAll('.swatch').attr('rx', '2');

      const maxWidth = d3.max(
        legendContainer
          .selectAll('.legendCells .cell')
          .nodes()
          .map((node) => node.getBoundingClientRect().width)
      );
      rect.attr('width', maxWidth + legendMargin * 2);
    },
    [
      legendData,
      theme.colors.elevatedBackgroundPrimary,
      theme.colors.textTertiary,
    ]
  );

  const renderDefs = useCallback(() => {
    const stripeWidth = patternStrokeWidth * 2;
    return (
      <defs>
        <pattern
          id='chartStripePattern'
          patternUnits='userSpaceOnUse'
          width={stripeWidth}
          height={stripeWidth}
          patternTransform={`rotate(90) translate(${patternStrokeWidth}, 0)`}>
          <line
            x1='0'
            y='0'
            x2='0'
            y2={stripeWidth}
            stroke={theme.colors.elevatedBackgroundTertiary}
            strokeWidth={stripeWidth}
          />
        </pattern>
      </defs>
    );
  }, [patternStrokeWidth, theme.colors.elevatedBackgroundTertiary]);

  useEffect(() => {
    function renderChart() {
      const svgRoot = d3.select(svgRef.current);
      svgRoot.selectAll('g').remove();

      const graph = svgRoot
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      renderYAxis(graph);

      renderSeriesData(graph);

      renderLegend(graph);
    }

    renderChart(svgRef);
  }, [
    width,
    height,
    data,
    xRange,
    yRange,
    margin.left,
    margin.top,
    renderYAxis,
    renderSeriesData,
    renderLegend,
  ]);

  return (
    <>
      {data.length > 0 && (
        <LineChartContainer ref={containerRef}>
          <svg width='100%' height='100%' ref={svgRef}>
            {renderDefs()}
            <rect
              width={Math.max(width - margin.right, 0)}
              height='100%'
              fill='url(#chartStripePattern)'
            />
          </svg>
        </LineChartContainer>
      )}
    </>
  );
};

LineChart.defaultProps = {
  data: [],
  xRange: [null, null],
  yRange: [null, null],
  yTickCount: 10,
  yTickFormat: null,
  patternStrokeWidth: 25,
  margin: {
    top: 10,
    right: 65,
    bottom: 10,
    left: 0,
  },
};

export default LineChart;

import { connect } from 'react-redux';

import RotationContents from './rotation.jsx';
import { actions } from '../../../../../reducers/slicer/slicer';
import { SlicerUtils } from '../../../../../utils';

const mapStateToProps = (state) => {
  const { transitionTower } = state.slicer;
  const selectedTower =
    transitionTower && transitionTower.active ? transitionTower : null;
  const selectedModels = SlicerUtils.getSelectedModels(state.slicer.models);

  return {
    models: state.slicer.models,
    selectedModels,
    selectedTower,
    pivot: state.three.gizmoCenter,
    updateGizmoPending: state.three.status.updateGizmoPending,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateModelTransforms: (transformData) => {
    dispatch(actions.updateModelTransformsRequest(transformData));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RotationContents);

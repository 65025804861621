import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import Routes from '../../../router/routes';
import Example from './codeExample.jsx';
import { InlineCode as C, BlockCode as CB } from './code.jsx';
import { FunctionTable, FunctionTH, FunctionTD } from './styles';
import { Heightless } from '../styles';
import { Links } from '../../../themes';
import { Link } from '../../../shared';

const sections = [
  {
    title: 'Introduction',
    content: (
      <>
        <p>
          PrinterScript is a scripting language designed specifically for use
          with print sequences. It allows the complex use of arithmetic and
          logic, variables, conditional execution and iteration, and formatted
          output, rather than just simple variable assignment.
        </p>
        <p>
          These constructs allow for flexible scripts that reduce the need for
          manual changes by making information about the settings used for a
          project, as well as the current state of the print, directly available
          to the script.
        </p>
        <p>
          This document serves as a language reference for PrinterScript, as
          well as an introduction to its syntax and feature set. If you discover
          any errors or inconsistencies within this document, or within
          PrinterScript itself, please{' '}
          <Link external href={Links.issueTracker}>
            file an issue
          </Link>
          .
        </p>
      </>
    ),
  },
  {
    title: 'Enabling PrinterScript',
    content: (
      <>
        <p>
          To maintain backwards compatibility with non-PrinterScript sequences,
          you must explicitly opt in to the use of a specific PrinterScript
          version. This is done by placing the directive
        </p>
        <CB>@printerscript 1.0</CB>
        <p>
          as the first line of your script. This directive is not technically
          part of the PrinterScript language itself, but is needed by Canvas in
          order to know whether or not it should treat your script as
          PrinterScript code.
        </p>
        <p>
          Omitting this directive will cause Canvas to treat your script as
          literal output text (including support for the limited{' '}
          <C>&lt;VAR&gt;</C>-style variable replacement candidates) rather than
          PrinterScript.
        </p>
      </>
    ),
  },
  {
    title: 'Syntax Errors and Runtime Errors',
    subsections: [
      {
        title: 'Syntax Errors',
        content: (
          <>
            <p>A syntax error occurs when a script contains invalid content.</p>
            <p>
              Syntax errors can include invalid characters, invalid expressions
              and statements, and sequences of characters that are not allowed
              by PrinterScript. They will always cause a script to fail and can
              be detected before actually evaluating a script.
            </p>
            <p>
              If a script contains a syntax error, Canvas will terminate slicing
              and report an error. Thus, it&rsquo;s important to ensure scripts
              are syntactically valid. However, Canvas will always issue a
              warning when attempting to save a script containing any syntax
              errors.
            </p>
          </>
        ),
      },
      {
        title: 'Runtime Errors',
        content: (
          <>
            <p>A runtime error occurs when the evaluation of a script fails.</p>
            <p>
              Unlike syntax errors, runtime errors result from the invalid
              evaluation of a well-formed script. For example, division by zero
              causes a runtime error. The expression <C>... / 0</C> will clearly
              produce this error, but for the expression <C>... / x</C> an error
              will only occur if <C>x == 0</C>.
            </p>
            <p>
              If a script produces a runtime error at any point during its
              execution, Canvas will terminate slicing and report an error.
              Thus, even more than with syntax errors, it&rsquo;s very important
              to protect against runtime errors—for example, by guarding against
              their possibility using functions or conditionals.
            </p>
            <Example
              in={['a = 0', '...', 'b = 2 / a']}
              out='Runtime error: division by zero'
              error
            />
            <Example
              in={[
                'a = 0',
                '...',
                '// use max(a, 1) to ensure',
                '// we never divide by 0',
                'b = 2 / max(a, 1)',
              ]}
              locals={{ a: 0, b: 2 }}
            />
          </>
        ),
      },
    ],
  },
  {
    title: 'Comments',
    content: (
      <>
        <p>PrinterScript supports two types of code comment:</p>
        <ul>
          <li>
            Line comments begin with <C>{'//'}</C> and continue to the end of
            the line.
          </li>
          <li>
            Block comments begin with <C>{'/*'}</C> and continue until the next{' '}
            <C>{'*/'}</C>.
          </li>
        </ul>
        <p>Comments within an output statement are not removed.</p>
        <Example
          in={[
            '// this will not be output',
            '',
            '/* neither will this! */',
            '',
            '"; AAA // BBB" // CCC',
            '',
            '/* this comment',
            'spans multiple lines */',
          ]}
          out='; AAA // BBB'
        />
        <p>
          Unclosed block comments are allowed in PrinterScript, implicitly
          closing at the end of the script. This can be useful to quickly
          disable an entire script, but should be used carefully.
        </p>
        <Example in={['/*', '', '"G1 ..."', '"G1 ..."', '"G1 ..."']} out='' />
      </>
    ),
  },
  {
    title: 'Size Limitations',
    content: (
      <>
        <p>
          Although not directly limited by PrinterScript, Canvas limits the size
          of a single input script to 1 MiB. Additionally, a single script may
          generate a maximum of 50 MiB of output. Exceeding this maximum results
          in a runtime error.
        </p>
      </>
    ),
  },
  {
    title: 'Literals, Variables, and Expressions',
    subsections: [
      {
        title: 'Literals',
        content: (
          <>
            <p>
              PrinterScript supports integer, floating-point, and boolean
              literals. All these types of values are stored internally as
              floating-point numbers.
            </p>
            <p>
              Integer and floating-point literals can be preceded by a sign.
              Minus signs are required for negative literal values, but a
              literal with a plus sign, <C>+x</C>, can also be used for
              readability and will simply evaluate to <C>x</C>.
            </p>
            <p>
              The boolean literals <C>true</C> and <C>false</C> are
              case-sensitive and simply act as keywords for the values <C>1</C>{' '}
              and <C>0</C>, respectively.
            </p>
          </>
        ),
      },
      {
        title: 'Expressions',
        content: (
          <>
            <p>
              Expressions allow the evaluation of complex arithmetic, logical,
              and comparison operations, as well as function calls. They can
              contain literal values, references to variables, operators,
              function calls, and sub-expressions.
            </p>
            <Example in='(10 + 2) * 3 / 4' out='9' />
            <p>Empty expressions are invalid in PrinterScript.</p>
            <p>
              At any point in the evaluation of an expression, the result is
              always a floating-point value. Logical and comparison operations
              always return a result of 1 or 0. Modulo operations <C>a % b</C>{' '}
              always return a result <C>r</C> where 0 ≤ <C>r</C> &lt; <C>b</C>.
            </p>
            <p>
              The order of operations, from highest precedence to lowest, is:
            </p>
            <ul>
              <li>
                Function calls <C>f(x)</C>
              </li>
              <li>
                Expressions within parentheses <C>(x)</C>
              </li>
              <li>
                Logical NOT <C>!x</C>
              </li>
              <li>
                Negation <C>-x</C>
              </li>
              <li>
                Multiplication <C>x * y</C>, division <C>x / y</C>, and modulo{' '}
                <C>x % y</C>
              </li>
              <li>
                Addition <C>x + y</C> and subtraction <C>x - y</C>
              </li>
              <li>
                Relational operators <C>x == y</C>, <C>x != y</C>,{' '}
                <C>x &lt; y</C>, <C>x &lt;= y</C>, <C>x &gt; y</C>, and{' '}
                <C>x &gt;= y</C>
              </li>
              <li>
                Logical OR <C>x || y</C> and logical AND <C>x && y</C>
              </li>
            </ul>
            <p>
              Binary operators with the same precedence are left-associative.
            </p>
            <Example
              in={['a = 36 / 6 / 2', 'b = (36 / 6) / 2', 'c = 36 / (6 / 2)']}
              locals={{ a: 3, b: 3, c: 12 }}
            />
            <p>
              If a division or modulo by zero occurs at any point in the
              execution of a script, it will result in a runtime error.
            </p>
            <Example
              in='a = 1 / 0'
              out='Runtime error: division by zero'
              error
            />
          </>
        ),
      },
      {
        title: 'Variables and Assignment Statements',
        content: (
          <>
            <p>Values can be stored and retrieved from variables.</p>
            <p>
              Variable names are case-sensitive. They can contain letters,
              digits, and underscores, but must not begin with a digit. The only
              other restriction is that the keywords <C>true</C>, <C>false</C>,{' '}
              <C>if</C>, <C>else</C>, and <C>while</C> cannot be used as
              variable names.
            </p>
            <p>
              Variables can be used within expressions, and the result of an
              expression can be assigned to a variable.
            </p>
            <Example
              in={['a = 10', 'b = 2 * a + 1']}
              locals={{ a: 10, b: 21 }}
            />
            <p>
              Variables that have never been assigned a value will be
              initialized with a value of <C>0</C> the first time they are used.
            </p>
            <Example in={['b = a + 1']} locals={{ a: 0, b: 1 }} />
          </>
        ),
      },
      {
        title: 'Built-in Variables',
        content: (
          <>
            <p>
              Canvas initializes a handful of useful variables when executing
              scripts. Some values are the same for all sequence types, such as
              style settings, while others depend on the current state of the
              print or the type of sequence being generated.
            </p>
            <p>
              Any of these pre-defined variables can be reassigned; while this
              has no effect outside of the current script, it means you
              don&rsquo;t need to worry about collisions with existing variable
              names.
            </p>
            <p>
              See{' '}
              <ReactRouterLink to={Routes.toPrinterScriptVariables()}>
                Sequences and Variables
              </ReactRouterLink>{' '}
              for a listing of the variables available and the values they
              represent for each type of sequence.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Output and Interpolation',
    subsections: [
      {
        title: 'Output Statements',
        content: (
          <>
            <p>
              Output is created by enclosing content in either single or double
              quotes.
            </p>
            <Example
              in='"G1 X0 Y0 ; move to (0, 0)"'
              out='G1 X0 Y0 ; move to (0, 0)'
            />
            <p>
              To output a literal quote instead of ending the statement, use the
              escape sequence <C>{"\\'"}</C> (within single quotes) or{' '}
              <C>{'\\"'}</C> (within double quotes). For better readability,
              using the opposite quote type should be preferred over escape
              sequences.
            </p>
            <p>
              A newline is always inserted after an output statement. Whitespace
              is retained in the output.
            </p>
            <p>
              Output statements cannot span multiple lines. Instead, use
              multiple output statements.
            </p>
            <Example
              in={[
                "'G1 X0 Y0 ; move to (0, 0)",
                "G1 X10 Y20 ; move to (10, 20)'",
              ]}
              out='Syntax error: newline in output statement'
              error
            />
            <Example
              in={[
                "'G1 X0 Y0 ; move to (0, 0)'",
                "'G1 X10 Y20 ; move to (10, 20)'",
              ]}
              out={[
                'G1 X0 Y0 ; move to (0, 0)',
                'G1 X10 Y20 ; move to (10, 20)',
              ]}
            />
          </>
        ),
      },
      {
        title: 'Expression Interpolation',
        content: (
          <>
            <p>
              Output statements can use interpolation to dynamically populate
              values within the output. An interpolation begins with{' '}
              <C>{'{{'}</C>, contains any valid PrinterScript expression, and
              ends with <C>{'}}'}</C>.
            </p>
            <Example
              in={["'G1 X{{x}} F3600'", "'G1 X{{x + 10}} F3600'"]}
              out={['G1 X0 F3600', 'G1 X10 F3600']}
              locals={{ x: 0 }}
            />
            <p>
              To output a literal <C>{'{{'}</C>, use the escape sequence{' '}
              <C>{'\\{{'}</C>. Escape sequences are not needed for <C>{'}}'}</C>
              , <C>{'{'}</C>, or <C>{'}'}</C>.
            </p>
            <p>
              Again, expression values are always treated as floating-point
              numbers. They are output with the minimum number of decimal places
              required, rounding to a maximum of 5 decimal places if necessary.
            </p>
            <Example
              in={[
                'a = 0.66666666667',
                'b = 0.000001',
                'c = 1.50',
                'd = 3',
                "'; a = {{a}}, b = {{b}}'",
                "'; c = {{c}}, d = {{d}}'",
              ]}
              out={['; a = 0.66667, b = 0', '; c = 1.5, d = 3']}
              locals={{
                a: 0.66666666667,
                b: 0.000001,
                c: 1.5,
                d: 3,
              }}
            />
          </>
        ),
      },
    ],
  },
  {
    title: 'Functions',
    content: (
      <>
        <p>
          A number of built-in functions are available to use within
          expressions.
        </p>
        <FunctionTable>
          <thead>
            <tr>
              <FunctionTH>Function</FunctionTH>
              <FunctionTH center>Arity</FunctionTH>
              <FunctionTH>Description</FunctionTH>
            </tr>
          </thead>
          <tbody>
            <tr>
              <FunctionTD>
                <C>min(...vs)</C>
              </FunctionTD>
              <FunctionTD center>≥ 2</FunctionTD>
              <FunctionTD>The argument with the minimum value</FunctionTD>
            </tr>
            <tr>
              <FunctionTD>
                <C>max(...vs)</C>
              </FunctionTD>
              <FunctionTD center>≥ 2</FunctionTD>
              <FunctionTD>The argument with the maximum value</FunctionTD>
            </tr>
            <tr>
              <FunctionTD>
                <C>pow(b, e)</C>
              </FunctionTD>
              <FunctionTD center>2</FunctionTD>
              <FunctionTD>
                <C>b</C> to the power of <C>e</C>
              </FunctionTD>
            </tr>
            <tr>
              <FunctionTD>
                <C>abs(v)</C>
              </FunctionTD>
              <FunctionTD center>2</FunctionTD>
              <FunctionTD>
                The absolute value of <C>v</C>
              </FunctionTD>
            </tr>
            <tr>
              <FunctionTD>
                <C>round(v)</C>
              </FunctionTD>
              <FunctionTD center>1</FunctionTD>
              <FunctionTD>
                The closest integer value to <C>v</C>
              </FunctionTD>
            </tr>
            <tr>
              <FunctionTD>
                <C>floor(v)</C>
              </FunctionTD>
              <FunctionTD center>1</FunctionTD>
              <FunctionTD>
                The closest integer value to <C>v</C>, rounding toward -∞
              </FunctionTD>
            </tr>
            <tr>
              <FunctionTD>
                <C>ceil(v)</C>
              </FunctionTD>
              <FunctionTD center>1</FunctionTD>
              <FunctionTD>
                The closest integer value to <C>v</C>, rounding toward +∞
              </FunctionTD>
            </tr>
            <tr>
              <FunctionTD>
                <C>trunc(v)</C>
              </FunctionTD>
              <FunctionTD center>1</FunctionTD>
              <FunctionTD>
                The closest integer value to <C>v</C>, rounding toward 0
              </FunctionTD>
            </tr>
            <tr>
              <FunctionTD>
                <C>sqrt(v)</C>
              </FunctionTD>
              <FunctionTD center>1</FunctionTD>
              <FunctionTD>
                The square root of <C>v</C>
              </FunctionTD>
            </tr>
            <tr>
              <FunctionTD>
                <C>sin(v)</C>
              </FunctionTD>
              <FunctionTD center>1</FunctionTD>
              <FunctionTD>
                The sine of angle <C>v</C>, in radians
              </FunctionTD>
            </tr>
            <tr>
              <FunctionTD>
                <C>cos(v)</C>
              </FunctionTD>
              <FunctionTD center>1</FunctionTD>
              <FunctionTD>
                The cosine of angle <C>v</C>, in radians
              </FunctionTD>
            </tr>
            <tr>
              <FunctionTD>
                <C>tan(v)</C>
              </FunctionTD>
              <FunctionTD center>1</FunctionTD>
              <FunctionTD>
                The tangent of angle <C>v</C>, in radians
              </FunctionTD>
            </tr>
            <tr>
              <FunctionTD>
                <C>asin(v)</C>
              </FunctionTD>
              <FunctionTD center>1</FunctionTD>
              <FunctionTD>
                The inverse sine of value <C>v</C>, in radians
              </FunctionTD>
            </tr>
            <tr>
              <FunctionTD>
                <C>acos(v)</C>
              </FunctionTD>
              <FunctionTD center>1</FunctionTD>
              <FunctionTD>
                The inverse cosine of value <C>v</C>, in radians
              </FunctionTD>
            </tr>
            <tr>
              <FunctionTD>
                <C>atan(v)</C>
              </FunctionTD>
              <FunctionTD center>1</FunctionTD>
              <FunctionTD>
                The inverse tangent of value <C>v</C>, in radians
              </FunctionTD>
            </tr>
          </tbody>
        </FunctionTable>
        <Example
          in={[
            'a = abs(-1)',
            'b = round(1.6)',
            'c = min(a, b)',
            'd = max(a, b, sqrt(100))',
            'e = pow(2, 5)',
          ]}
          locals={{
            a: 1,
            b: 2,
            c: 1,
            d: 10,
            e: 32,
          }}
        />
        <p>
          Calling a function that doesn&rsquo;t exist, such as <C>foo()</C>,
          will result in a runtime error. The following function calls will also
          result in a runtime error:
        </p>
        <ul>
          <li>
            <C>pow(0, 0)</C>
          </li>
          <li>
            <C>sqrt(v)</C> where <C>v</C> &lt; 0
          </li>
          <li>
            <C>tan(v)</C> where the tangent function is undefined at <C>v</C>,
            i.e. (<C>v</C> –{' '}
            <Heightless>
              <sup>π</sup>&frasl;<sub>2</sub>
            </Heightless>
            ) mod π = 0
          </li>
        </ul>
        <p>
          Scripts cannot define custom functions. Because of this, variable
          names do not collide with function names, as PrinterScript always
          knows the difference in context.
        </p>
        <Example
          in={['two = 2', 'sqrt = sqrt(two)', '; {{sqrt}}, {{sqrt(3)}}']}
          out='; 1.41421, 1.73205'
          locals={{ two: 2, sqrt: Math.sqrt(2) }}
        />
      </>
    ),
  },
  {
    title: 'Conditionals',
    content: (
      <>
        <p>
          The <C>if</C> keyword is used to indicate a block of PrinterScript
          that will only be executed if a condition is met. <C>if</C> statements
          take the form
        </p>
        <CB>
          if ( &lt;condition&gt; ) {'{'} [statements] {'}'}
        </CB>
        <p>
          where <C>&lt;condition&gt;</C> is any valid PrinterScript expression.
        </p>
        <Example
          in={['if (true) { a = 1 }', '', 'if (false) {', '  b = 2', '}']}
          locals={{ a: 1 }}
        />
        <p>
          An <C>if</C> statement can be optionally followed by any number of{' '}
          <C>else if</C> statements, and finally an optional <C>else</C>{' '}
          statement. The first statement whose condition evaluates to{' '}
          <C>true</C> has its block executed, and all subsequent <C>else if</C>/
          <C>else</C> statements are skipped.
        </p>
        <Example
          in={[
            'a = 3',
            'if (a == 1) {',
            '  b = 10',
            '} else if (a == 2) {',
            '  b = 7',
            '} else if (a == 3) {',
            '  b = 9',
            '} else {',
            '  b = 4',
            '}',
          ]}
          locals={{ a: 3, b: 9 }}
        />
        <p>
          Conditional blocks may be empty, or contain one or more statements.
          This includes loops and nested conditionals.
        </p>
        <Example
          in={[
            'a = true',
            'b = false',
            'if (a) {',
            '  if (b) { "; 1" } else { "; 2" }',
            '} else {',
            '  if (b) { "; 3" } else { "; 4" }',
            '}',
          ]}
          out={'; 2'}
          locals={{ a: 1, b: 0 }}
        />
      </>
    ),
  },
  {
    title: 'Loops',
    content: (
      <>
        <p>
          The <C>while</C> keyword is used to indicate a block of PrinterScript
          that will be executed repeatedly as long as a condition is met.{' '}
          <C>while</C> statements take the form
        </p>
        <CB>
          while ( &lt;condition&gt; ) {'{'} [statements] {'}'}
        </CB>
        <p>
          where <C>&lt;condition&gt;</C> is any valid PrinterScript expression.
        </p>
        <Example
          in={[
            'i = 0',
            'while (i < 3) {',
            '  "; i = {{i}}"',
            '  "G1 X{{i * 50}} Y0 F3000"',
            '  i = i + 1',
            '}',
          ]}
          out={[
            '; i = 0',
            'G1 X0 Y0 F3000',
            '; i = 1',
            'G1 X50 Y0 F3000',
            '; i = 2',
            'G1 X100 Y0 F3000',
          ]}
          locals={{ i: 3 }}
        />
        <p>
          Canvas places a large but finite limit on the number of loop
          iterations a single script can execute. This limit is currently
          1,000,000 iterations, and ensures that scripts do not infinitely loop
          and fail to terminate. A script attempting to exceed this limit will
          result in a runtime error.
        </p>
        <Example
          in='while (true) { }'
          out='Runtime error: maximum iterations exceeded'
          error
        />
        <p>
          To avoid infinite loops, ensure your loop condition uses at least one
          variable and that you update this variable within the loop.
        </p>
        <p>
          Loop blocks may be empty, or contain one or more statements. This
          includes conditionals and nested loops.
        </p>
        <Example
          in={[
            'i = 0',
            'while (i < 3) {',
            '  if (i == 0 ) {',
            '    "; i is zero"',
            '  } else {',
            '    "; i is positive"',
            '  i = i + 1',
            '}',
          ]}
          out={['; i is zero', '; i is positive', '; i is positive']}
          locals={{ i: 3 }}
        />
        <p>
          Note that with nested loops, both the outer and inner loop contribute
          to the total number of iterations. For example, a script with a loop
          that runs 10 times, containing a loop that also runs 10 times, will
          execute a total of 110 iterations: 10 for the outer loop, and 10
          &times; 10 = 100 for the inner loop.
        </p>
        <Example
          in={[
            'total = 0',
            'i = 0',
            'while (i < 10) {',
            '  total = total + 1',
            '  j = 0',
            '  while (j < 10) {',
            '    total = total + 1',
            '    j = j + 1',
            '  }',
            '  i = i + 1',
            '}',
          ]}
          locals={{ i: 10, j: 10, total: 110 }}
        />
      </>
    ),
  },
];

export default sections;

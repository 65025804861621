const Helpers = {
  updateProjectionMatrix(camera, orthographic) {
    const { innerWidth, innerHeight } = window;
    /* eslint-disable no-param-reassign */
    if (orthographic) {
      camera.left = -innerWidth / 2;
      camera.right = innerWidth / 2;
      camera.top = innerHeight / 2;
      camera.bottom = -innerHeight / 2;
    } else {
      camera.aspect = innerWidth / innerHeight;
    }
    /* eslint-enable no-param-reassign */
    camera.updateProjectionMatrix();
  },
};

export default Helpers;

import styled from 'styled-components';
import {
  DefaultOptionsList,
  DefaultOption,
  DefaultOptionWrapper,
  DefaultSelect,
  DefaultSelectedOptionText,
} from '../abstractDropdown/abstractDropdown.styles';

import { Caption } from '../typography/typography';

export const GridOptions = styled(DefaultOptionsList)`
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 0.75rem;
`;

export const StyledOption = styled(DefaultOption)`
  display: flex;
  border-radius: 0.25rem;
  :nth-of-type(even) {
    margin-right: 0.75rem;
  }
  :nth-of-type(odd) {
    margin-left: 0.75rem;
  }
  &:first-of-type {
    grid-column-start: span 2;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey3};
    padding: 0.75rem 1rem;
    margin-left: 0rem;
    border-radius: 0rem;
  }
  :nth-of-type(2) {
    grid-column-start: span 2;
    margin-left: 0.75rem;
  }
  border-bottom: none;
  padding: 0.5rem 0.5rem;
`;

export const StyledOptionWrapper = styled(DefaultOptionWrapper)`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
`;

export const StyledSelect = styled(DefaultSelect)`
  justify-content: flex-start;
  align-items: center;
`;

export const StyledSelectedOptionText = styled(DefaultSelectedOptionText)`
  margin-right: auto;
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.375rem;
`;

export const PaletteOptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

export const DisabledDevice = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  ${Caption} {
    color: ${(props) => props.theme.colors.textTertiary};
  }
`;

export const SwitchAccessoryModeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;

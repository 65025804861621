import 'regenerator-runtime/runtime';
import { call, put, all } from 'redux-saga/effects';
import _ from 'lodash';

import API, { methods } from '../../canvas-api';
import { handleGenericError } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';
import { InterfaceUtils, FormatUtils } from '../../../utils';

function* deleteProjectCall(projectId) {
  try {
    const response = yield call(API, {
      method: methods.DELETE,
      path: `projects/${projectId}`,
    });
    if (response === null) return null;
    return { status: 200, deletedId: projectId };
  } catch (error) {
    return error;
  }
}

function* deleteFolderCall(projectId) {
  try {
    const response = yield call(API, {
      method: methods.DELETE,
      path: `projects/directory/${projectId}`,
    });
    if (response === null) return null;
    return { status: 200, deletedId: projectId };
  } catch (error) {
    return error;
  }
}

export default function* deleteProjects(action) {
  const { projects } = action.payload;
  try {
    const responses = yield all(
      _.map(projects, (project) => {
        if (project.directory) return call(deleteFolderCall, project.id);
        return call(deleteProjectCall, project.id);
      })
    );
    if (_.some(responses, (response) => response === null)) return;

    const deleteResponses = _.filter(
      responses,
      (response) => response.status === 200
    );
    const deletedIds = _.map(deleteResponses, (response) => response.deletedId);
    yield put(actions.deleteProjectsSuccess(deletedIds));

    // toast message for deleted
    let toastMessage = '';
    if (deleteResponses.length > 0) {
      toastMessage = `${deleteResponses.length} ${FormatUtils.pluralize(
        'project',
        deleteResponses.length
      )} ${deleteResponses.length === 1 ? 'was' : 'were'} deleted`;
      InterfaceUtils.emitToast('success', toastMessage);
    }

    // toast message for the not deleted
    toastMessage = '';
    if (deletedIds.length < projects.length) {
      toastMessage = `${
        projects.length - deleteResponses.length
      } ${FormatUtils.pluralize(
        'project',
        projects.length - deleteResponses.length
      )} couldn't be deleted`;
      projects.forEach((project) => {
        if (!deletedIds.includes(project.id)) {
          toastMessage += '\n';
          toastMessage += '\t';
          toastMessage += project.name;
        }
      });
      InterfaceUtils.emitToast('warn', toastMessage);
    }
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import styled from 'styled-components';
import {
  defaultModalContentStyles,
  defaultModalButtonStyles,
  defaultModalLabelStyles,
} from '../../shared/modal/modal.styles';

export const Container = styled.div`
  ${defaultModalContentStyles}
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const ModalButtonWrapper = styled.div`
  ${defaultModalButtonStyles}
  margin-left: 0.5em;
`;

export const ModalTextWrapper = styled.div`
  ${defaultModalLabelStyles}
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5em;
`;

export const PermissionsList = styled.ul`
  margin: 0;
  padding-left: 1.5em;
  list-style-type: circle;
`;

export const PermissionListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 0.5em;
  }
`;

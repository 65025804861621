import _ from 'lodash';
import { withTheme } from 'styled-components';
import * as THREE from 'three';
import React, { Component } from 'react';
import { sphereIntersectTriangle } from 'three-mesh-bvh/src/Utils/MathUtilities';

import {
  ToolboxContainer,
  ViewButtonsContainer,
  ViewButtonsMobileContainer,
  ControlButtonsContainer,
  ViewButtonWrapper,
  UndoRedoButtonsContainer,
  SupportToolboxMobileContainer,
  ControlButtonsMobileContainer,
  ExtruderButtonsContainer,
  UndoRedoButtonWrapper,
  CancelSaveButtonMobileWrapper,
  BrushSizeWrapper,
} from '../paintView/paintView.styles';

import {
  AutoSupportsFields,
  AutoSupportsFieldsFooter,
  FooterLabels,
  FieldsContentWrapper,
  ContextToolsContainer,
} from './supportView.styles';

import { FieldContainer } from '../paintView/tools/autoSegment/autoSegment.styles';

import HeaderBar from '../../../shared/page/headerBar/headerBar.jsx';
import fields from './autoSupports.metadata';
import BrushSize from '../paintView/tools/brush/brushSize.jsx';

import {
  ColorSwatch,
  Checkbox,
  ToolCollapsiblePanel,
  Button,
  Input,
  Modal,
  ModalHeader,
  ConfirmationModal,
  ToolTipWrapper,
  Subtitle1,
  Caption,
  ActionButton,
} from '../../../shared';

import { Icons } from '../../../themes';
import { fieldTypes } from '../../../constants';

import { SceneUtils, TreeUtils, SlicerUtils } from '../../../utils';

import { SUPPORT_FACE_COLORS } from '../../../utils/faces/shaders';
import {
  getIntersectionForSupport,
  updateMouse,
} from '../../../sagas/three/raycast';
import UndoManager from '../../../classes/UndoManager';
import { setRenderFlag } from '../../../sagas/three/animationFrame';

class SupportView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeExtruder: true,
      currentExtruderIndex: 0,
      prevExtruderIndex: -1,
      savingForExit: false,
      showBasicControlMobile: false,
      showCancelModal: false,
      showAutoGenerateModal: false,
      focusMaxOverhangAngle: false,
      maxOverhangAngle: 45,
      maxOverhangAngleError: false,
      fromPrintBedOnly: false,
      supportsTrackerMap: null,
      showStructurePreview: false,
      facesMapByMesh: null,
      hasUndo: false,
      hasRedo: false,
    };
    this.intersection = null;
    this.undoManager = new UndoManager();
    this.brush = SceneUtils.createBrush();
    this.dot = SceneUtils.createDot();
    this.sphereMarker = SceneUtils.createSphereMarker();
    this.canvas = null;
    this.onUnload = this.onUnload.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  isUndoRedoDisabled() {
    return this.state.savingForExit;
  }

  isUndoDisabled() {
    return this.isUndoRedoDisabled() || !this.state.hasUndo;
  }

  isRedoDisabled() {
    return this.isUndoRedoDisabled() || !this.state.hasRedo;
  }

  isDotVisible() {
    const { type } = this.props.brush;
    return type === 'facet' || type === 'sphere';
  }

  isBrushVisible() {
    const { type } = this.props.brush;
    return type === 'sphere';
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload);
    window.addEventListener('mousedown', this.onMouseDown);
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
    window.addEventListener('touchstart', this.onTouchStart);
    window.addEventListener('touchend', this.onTouchEnd);
    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('keyup', this.onKeyUp);

    this.props.addBrush(this.brush, this.dot);
    this.props.registerBrush(this.brush);
    const rgba = SUPPORT_FACE_COLORS[this.state.currentExtruderIndex];
    const hexString = SlicerUtils.rgbaArrayToHexString(rgba);
    this.props.selectBrushColor(hexString);
    this.props.setCurrentTool('facet');

    const meshes = TreeUtils.flattenDeep(this.props.models).map(
      (model) => model.mesh
    );
    this.setState({ meshes }, () => {
      this.configureUndoManager();
      this.buildFacesMapByMesh();
      this.buildSupportsTracker();
    });
  }

  componentWillUnmount() {
    this.undoManager = null;
    window.removeEventListener('beforeunload', this.onUnload);
    window.removeEventListener('mousedown', this.onMouseDown);
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
    window.removeEventListener('touchstart', this.onTouchStart);
    if (this.canvas)
      this.canvas.removeEventListener('touchmove', this.onTouchMove);
    window.removeEventListener('touchend', this.onTouchEnd);
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('keyup', this.onKeyUp);
    this.props.removeBrush(this.brush, this.dot);
    if (this.props.transitionTower) {
      this.props.addTowerToScene(this.props.transitionTower);
    }
    this.props.setCurrentTool(null);

    this.disposePreviewStructure();
    this.renderColorAttribute();
  }

  componentDidUpdate(prevProps) {
    if (this.isUndoRedoDisabled()) {
      document.body.style.cursor = 'progress';
    } else {
      document.body.style.cursor = 'initial';
    }

    if (prevProps.brush !== this.props.brush) {
      const { size } = this.props.brush;
      this.brush.scale.set(size, size, size);
    }
    if (this.props.brush.type === '') {
      this.brush.visible = false;
      this.dot.visible = false;
    } else if (prevProps.brush.type !== this.props.brush.type) {
      this.brush.visible = this.isBrushVisible();
      this.dot.visible = this.isDotVisible();
    }

    if (
      prevProps.saveModelCustomSupportsPending &&
      !this.props.saveModelCustomSupportsPending
    ) {
      if (
        this.props.saveModelCustomSupportsSuccess &&
        this.state.savingForExit
      ) {
        this.props.onExitView();
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.isPainting) {
      return false;
    }
    return true;
  }

  onUnload(e) {
    if (this.state.hasUndo || this.state.hasRedo) {
      e.returnValue = 'Leave site? Changes you made may not be saved.';
    }
  }

  onKeyUp(e) {
    e.stopPropagation();
    e.preventDefault();
    if (
      (e.key === 'z' && (e.ctrlKey || e.metaKey) && e.shiftKey) ||
      (e.key === 'y' && (e.ctrlKey || e.metaKey))
    ) {
      // Redo -- Ctrl+Shift+Z or Ctrl+Y
      if (!this.isRedoDisabled()) {
        this.undoManager.redo();
      }
    } else if (e.key === 'z' && (e.ctrlKey || e.metaKey)) {
      // Undo -- Ctrl+Z
      if (!this.isUndoDisabled()) {
        this.undoManager.undo();
      }
    }
  }

  onPointerDown(e) {
    const { brush } = this.props;

    // if does not meet conditions for painting, don't do anything;
    const onCanvas = e.target.nodeName === 'CANVAS';
    if (!onCanvas) return;
    const modifierKeyPressed = e.altKey || e.ctrlKey || e.metaKey;
    if (modifierKeyPressed || !this.intersection || !this.isDotVisible()) {
      return;
    }

    // otherwise, proceed to relevant paint mode
    this.props.togglePainting(true);
    this.props.disableControls();
    if (brush.type === 'facet') {
      this.supportFacetMode();
    } else if (brush.type === 'sphere') {
      this.supportSphereMode();
    }
  }

  onMouseDown(e) {
    if (e.button > 0) return;
    this.onPointerDown(e);
  }

  onTouchStart(e) {
    e.stopPropagation();
    if (e.touches.length > 1) return;
    const onCanvas = e.target.nodeName === 'CANVAS';
    if (!onCanvas) return;
    this.canvas = e.target;
    this.intersection = getIntersectionForSupport(this.state.meshes);
    this.onPointerDown(e);
    this.canvas.addEventListener('touchmove', this.onTouchMove);
  }

  onPointerMove() {
    const intersection = getIntersectionForSupport(this.state.meshes);
    this.intersection = intersection;

    if (intersection) {
      this.brush.position.copy(intersection.point);
      this.brush.visible = this.isBrushVisible();
      this.dot.position.copy(intersection.point);
      this.dot.visible = this.isDotVisible();
    } else {
      this.brush.visible = false;
      this.dot.visible = false;
    }
    setRenderFlag();

    const { isPainting, brush } = this.props;
    if (!isPainting || !intersection) return;
    if (brush.type === 'facet') {
      this.supportFacetMode();
    } else if (brush.type === 'sphere') {
      this.supportSphereMode();
    }
  }

  onMouseMove() {
    this.onPointerMove();
  }

  onTouchMove(e) {
    updateMouse(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
    this.onPointerMove();
  }

  onPointerUp() {
    const { isPainting } = this.props;
    if (!isPainting) return;
    this.registerUndoRedo();
    this.props.togglePainting(false);
    this.props.enableControls();
  }

  onMouseUp() {
    this.onPointerUp();
  }

  onTouchEnd() {
    this.onPointerUp();
    this.brush.visible = false;
    this.dot.visible = false;
    if (this.canvas)
      this.canvas.removeEventListener('touchmove', this.onTouchMove);
  }

  onKeyDown(e) {
    const extruderNumbers = ['1', '2'];
    if (extruderNumbers.includes(e.key)) {
      const index = Number(e.key) - 1;
      const hex = SlicerUtils.rgbaArrayToHexString(SUPPORT_FACE_COLORS[index]);
      this.selectExtruderButton(hex, index);
    }
  }

  configureUndoManager() {
    this.undoManager.setCallback(() => {
      this.setState({
        hasUndo: this.undoManager.hasUndo(),
        hasRedo: this.undoManager.hasRedo(),
      });
    });
  }

  renderColorAttribute() {
    this.state.meshes.forEach((mesh) => {
      // render colors using color attribute
      // eslint-disable-next-line no-param-reassign
      mesh.material.uniforms.inSupportView.value = false;

      mesh.children.forEach((stampGroup) => {
        // show stamps
        // eslint-disable-next-line no-param-reassign
        stampGroup.visible = true;
      });
    });
  }

  restoreSupportAttribute() {
    TreeUtils.flattenDeep(this.props.models).forEach((model) => {
      const { mesh, supportsRle } = model;
      const supportAttr = mesh.geometry.attributes.support;
      const supportAttrLength = supportAttr.count / 3;

      if (supportsRle) {
        // restore attribute from original supports RLE
        let rleIndex = 0;
        let numFacesToColor = supportsRle[rleIndex];
        rleIndex++;
        let faceColorIndex = supportsRle[rleIndex];
        rleIndex++;

        for (let i = 0; i < supportAttrLength; i++) {
          if (numFacesToColor <= 0) {
            numFacesToColor = supportsRle[rleIndex];
            rleIndex++;
            faceColorIndex = supportsRle[rleIndex];
            rleIndex++;
          }

          if (faceColorIndex === 1) {
            supportAttr.setXYZ(i * 3, 1.0, 1.0, 1.0);
          } else {
            supportAttr.setXYZ(i * 3, 0.0, 0.0, 0.0);
          }
          numFacesToColor--;
        }
      } else {
        for (let i = 0; i < supportAttrLength; i++) {
          // restore to default supports RLE
          supportAttr.setXYZ(i * 3, 0, 0, 0);
        }
      }
      supportAttr.needsUpdate = true;
    });
    setRenderFlag();
  }

  buildSupportsTracker() {
    const supportsTrackerMap = {};
    this.state.meshes.forEach((mesh) => {
      // initialize an empty array to store marked faces per model
      supportsTrackerMap[mesh.name] = {
        prev: [],
        next: [],
      };
    });

    this.setState({ supportsTrackerMap });
  }

  buildFacesMapByMesh() {
    const { meshes } = this.state;

    const facesMapByMesh = {};
    const vA = new THREE.Vector3();
    const vB = new THREE.Vector3();
    const vC = new THREE.Vector3();

    meshes.forEach((mesh) => {
      // render colors using support attribute
      // eslint-disable-next-line no-param-reassign
      mesh.material.uniforms.inSupportView.value = true;

      mesh.children.forEach((stampGroup) => {
        // hide stamps
        // eslint-disable-next-line no-param-reassign
        stampGroup.visible = false;
      });

      // initialize map to hold triangles
      const facesMap = new Map();
      const positionAttr = mesh.geometry.attributes.position;
      const { array } = positionAttr;

      for (let i = 0; i < array.length; i += 9) {
        const currentFace = new THREE.Triangle();

        vA.set(array[i + 0], array[i + 1], array[i + 2]).applyMatrix4(
          mesh.matrix
        );
        vB.set(array[i + 3], array[i + 4], array[i + 5]).applyMatrix4(
          mesh.matrix
        );
        vC.set(array[i + 6], array[i + 7], array[i + 8]).applyMatrix4(
          mesh.matrix
        );

        currentFace.set(vA, vB, vC);

        facesMap.set(Math.floor(i / 9), currentFace);
      }

      facesMapByMesh[mesh.name] = facesMap;
    });
    this.setState(
      {
        facesMapByMesh,
      },
      () => {
        this.createPreviewStructure();
        this.updatePreviewStructure();
      }
    );
  }

  supportFacetMode() {
    const { faceIndex, object } = this.intersection;
    const { currentExtruderIndex } = this.state;

    const supportAttr = object.geometry.attributes.support;
    const prevStatus = supportAttr.getX(faceIndex * 3);
    const newStatus = currentExtruderIndex === 0 ? 1.0 : 0.0;

    // only mark faces when status changes
    if (prevStatus !== newStatus) {
      const facesWithPrevStatus = [];
      const facesWithNextStatus = [];

      // record undo/redo
      facesWithPrevStatus.push({ index: faceIndex, status: prevStatus });
      facesWithNextStatus.push({ index: faceIndex, status: newStatus });
      this.recordBeforeAfterFaceStatus(
        object.name,
        facesWithPrevStatus,
        facesWithNextStatus
      );

      // update support attribute
      this.supportFace(supportAttr, faceIndex, newStatus);
      supportAttr.needsUpdate = true;
      setRenderFlag();
    }
  }

  supportSphereMode() {
    const { brush } = this.props;
    const { point } = this.intersection;
    const { currentExtruderIndex, meshes } = this.state;
    this.sphereMarker.set(point, brush.size);

    // support faces in sphere - work on each mesh separately
    let faceIndex;
    let prevStatus;
    const newStatus = currentExtruderIndex === 0 ? 1.0 : 0.0;
    meshes.forEach((mesh) => {
      const supportAttr = mesh.geometry.attributes.support;

      // record undo/redo
      const facesWithPrevStatus = [];
      const facesWithNextStatus = [];

      mesh.geometry.boundsTree.shapecast(
        mesh,
        (box) => {
          box.applyMatrix4(mesh.matrix);
          return this.sphereMarker.intersectsBox(box);
        },
        (tri, firstVertexIndex) => {
          tri.a.applyMatrix4(mesh.matrix);
          tri.b.applyMatrix4(mesh.matrix);
          tri.c.applyMatrix4(mesh.matrix);
          if (sphereIntersectTriangle(this.sphereMarker, tri)) {
            faceIndex = Math.floor(
              mesh.geometry.index.array[firstVertexIndex] / 3
            );
            prevStatus = supportAttr.getX(faceIndex * 3);

            // only mark faces when status changes
            if (prevStatus !== newStatus) {
              // record undo/redo
              facesWithPrevStatus.push({
                index: faceIndex,
                status: prevStatus,
              });
              facesWithNextStatus.push({ index: faceIndex, status: newStatus });

              // update support attribute
              this.supportFace(supportAttr, faceIndex, newStatus);
            }
          }
          return false;
        }
      );
      supportAttr.needsUpdate = true;
      this.recordBeforeAfterFaceStatus(
        mesh.name,
        facesWithPrevStatus,
        facesWithNextStatus
      );
    });
    setRenderFlag();
  }

  supportGivenFaces(facesMap) {
    _.forEach(facesMap, (dataPerModel, modelName) => {
      const targetMesh = _.find(
        this.state.meshes,
        (mesh) => mesh.name === modelName
      );
      const supportAttr = targetMesh.geometry.attributes.support;
      _.forEach(dataPerModel, (data) =>
        this.supportFace(supportAttr, data.index, data.status)
      );
      supportAttr.needsUpdate = true;
    });
    this.updatePreviewStructure();
  }

  supportFace(supportAttr, faceIndex, newStatus) {
    supportAttr.setXYZ(faceIndex * 3, newStatus, newStatus, newStatus);
  }

  recordBeforeAfterFaceStatus(
    modelName,
    facesWithPrevStatus,
    facesWithNextStatus
  ) {
    const { supportsTrackerMap } = this.state;

    const updatedTrackerMap = {
      ...supportsTrackerMap,
      [modelName]: {
        prev: [...supportsTrackerMap[modelName].prev, ...facesWithPrevStatus],
        next: [...supportsTrackerMap[modelName].next, ...facesWithNextStatus],
      },
    };

    this.setState({ supportsTrackerMap: updatedTrackerMap });
  }

  selectNullTool(index) {
    this.setState(
      {
        activeExtruder: false,
        currentExtruderIndex: -1,
        prevExtruderIndex: index,
      },
      () => {
        this.props.selectBrushType('');
        this.props.setCurrentTool(null);
      }
    );
  }

  selectTool(tool) {
    const { currentTool } = this.props;
    // toggle off tool or tool set to null.
    if (tool === currentTool || !tool) {
      this.selectNullTool(this.state.currentExtruderIndex);
    } else {
      this.props.setCurrentTool(tool); // update current tool
      // tool is a paint tool
      this.props.selectBrushType(tool); // select brush type
      this.setState({ activeExtruder: true }); // show extruder
      if (this.state.currentExtruderIndex === -1) {
        this.setState({ currentExtruderIndex: this.state.prevExtruderIndex });
      }
    }
  }

  registerUndoRedo() {
    const { supportsTrackerMap } = this.state;
    const undoMap = {};
    const redoMap = {};

    _.forEach(supportsTrackerMap, (dataPerModel, modelName) => {
      undoMap[modelName] = dataPerModel.prev;
      redoMap[modelName] = dataPerModel.next;
    });

    this.buildSupportsTracker();
    // record BEFORE/AFTER face status
    this.undoManager.add({
      undo: {
        data: undoMap,
        fn: this.supportGivenFaces.bind(this),
      },
      redo: {
        data: redoMap,
        fn: this.supportGivenFaces.bind(this),
      },
    });
    this.updatePreviewStructure();
  }

  undo(e) {
    e.stopPropagation();
    this.undoManager.undo();
  }

  redo(e) {
    e.stopPropagation();
    this.undoManager.redo();
  }

  composeSupportRLE() {
    const customSupportsData = [];
    const { meshes } = this.state;

    meshes.forEach((mesh) => {
      let currentNeedSupport = -1;
      let currentRunLength = 0;
      const rle = [];

      const finishRun = () => {
        if (currentRunLength === 0) return;
        rle.push(currentRunLength, currentNeedSupport);
      };

      const calculateRLE = (needSupport) => {
        if (needSupport === currentNeedSupport) {
          // same status -- increment the current run length
          currentRunLength++;
        } else if (currentRunLength > 0) {
          // new status -- end the current run, then start a new one
          finishRun();
          // begin the next run
          currentNeedSupport = needSupport;
          currentRunLength = 1;
        } else {
          // new status and zero run length
          // (this should only run for the first triangle)
          currentNeedSupport = needSupport;
          currentRunLength++;
        }
      };

      const positionAttr = mesh.geometry.attributes.position;
      const supportAttr = mesh.geometry.attributes.support;
      const { array } = positionAttr;

      let faceIndex;
      let needSupport;
      for (let i = 0; i < array.length; i += 9) {
        faceIndex = Math.floor(i / 9);
        needSupport = supportAttr.getX(faceIndex * 3); // just get value from first vertex
        calculateRLE(needSupport);
      }
      finishRun();

      customSupportsData.push({
        modelId: mesh.name,
        supportsRle: rle,
      });
    });

    return customSupportsData;
  }

  handleSave(exitAfter = true) {
    const modelsSupportsData = this.composeSupportRLE();
    this.props.saveModelCustomSupports(modelsSupportsData);
    this.setState({ savingForExit: exitAfter });
  }

  handleCancel() {
    if (this.state.hasUndo || this.state.hasRedo) {
      this.setState({ showCancelModal: true });
    } else {
      this.props.onExitView();
    }
  }

  cancelSupportView() {
    this.restoreSupportAttribute();
    this.setState({ showCancelModal: false });
    this.props.onExitView();
  }

  selectExtruderButton(hex, index) {
    if (this.props.brush.type !== '') {
      this.props.selectBrushColor(hex);
      this.setState({
        activeExtruder: true,
        currentExtruderIndex: index,
      });
    } else {
      this.setState({
        activeExtruder: false,
        currentExtruderIndex: -1,
      });
    }
  }

  focusAngleField() {
    this.setState({ focusMaxOverhangAngle: true }, () => {
      this.setState({ focusMaxOverhangAngle: false });
    });
  }

  handleAutoGenerateSupports() {
    if (this.state.maxOverhangAngleError) {
      this.focusAngleField();
      return;
    }

    const { meshes, facesMapByMesh } = this.state;

    const zUpVector = new THREE.Vector3(0, 0, 1);
    const zDownVector = new THREE.Vector3(0, 0, -1);
    const thresholdRad = SlicerUtils.degreesToRadians(
      this.state.maxOverhangAngle
    );
    const raycaster = SceneUtils.createRaycaster();

    const currentNormal = new THREE.Vector3();
    const currentMidpoint = new THREE.Vector3();

    meshes.forEach((mesh) => {
      const facesWithPrevStatus = [];
      const facesWithNextStatus = [];

      const supportAttr = mesh.geometry.attributes.support;
      const facesMap = facesMapByMesh[mesh.name];

      let prevStatus;
      facesMap.forEach((currentFace, faceIndex) => {
        prevStatus = supportAttr.getX(faceIndex * 3);
        currentFace.getNormal(currentNormal);
        currentFace.getMidpoint(currentMidpoint);

        let newStatus = 1.0;
        if (zUpVector.angleTo(currentNormal) >= Math.PI / 2 + thresholdRad) {
          if (this.state.fromPrintBedOnly) {
            // only mark faces visible from print bed
            raycaster.set(currentMidpoint, zDownVector);
            const intersections = raycaster.intersectObjects(meshes);

            // I can actually compare the face index (the "correct" way),
            // but this could be expensive
            // eslint-disable-next-line no-loop-func
            // const notSelf = intersections.filter((intersection) => {
            //   const { index } = intersection.object.geometry;
            //   const vertexIndex = index.array[intersection.faceIndex * 3];
            //   const intersectedFaceIndex = Math.floor(vertexIndex / 3);
            //   return intersectedFaceIndex !== faceIndex;
            // });

            // or, I can only take intersections that have non-trivial distance,
            // but this may not be reliable
            const notSelf = intersections.filter(
              (intersection) => intersection.distance > 0.0001
            );

            if (notSelf.length > 0) newStatus = 0.0;
          }
        } else {
          newStatus = 0.0;
        }

        // record undo/redo
        facesWithPrevStatus.push({ index: faceIndex, status: prevStatus });
        facesWithNextStatus.push({ index: faceIndex, status: newStatus });

        // update support attribute
        this.supportFace(supportAttr, faceIndex, newStatus);
      });
      supportAttr.needsUpdate = true;
      this.recordBeforeAfterFaceStatus(
        mesh.name,
        facesWithPrevStatus,
        facesWithNextStatus
      );
    });
    this.setState(
      {
        showAutoGenerateModal: false,
      },
      () => {
        this.registerUndoRedo();
      }
    );
  }

  handleClearSupports() {
    const { meshes } = this.state;
    meshes.forEach((mesh) => {
      const facesWithPrevStatus = [];
      const facesWithNextStatus = [];

      let prevStatus;
      const supportAttr = mesh.geometry.attributes.support;
      for (let i = 0; i < supportAttr.array.length; i++) {
        // get previous status
        prevStatus = supportAttr.getX(i * 3);

        // record undo/redo
        facesWithPrevStatus.push({ index: i, status: prevStatus });
        facesWithNextStatus.push({ index: i, status: 0 });

        // update support attribute
        supportAttr.array[i] = 0;
      }
      supportAttr.needsUpdate = true;
      this.recordBeforeAfterFaceStatus(
        mesh.name,
        facesWithPrevStatus,
        facesWithNextStatus
      );
    });

    this.registerUndoRedo();
  }

  handleTogglePreview() {
    this.setState(
      {
        showStructurePreview: !this.state.showStructurePreview,
      },
      () => {
        this.updatePreviewStructure();
      }
    );
  }

  createPreviewStructure() {
    const material = new THREE.LineBasicMaterial({
      color: 0xe67e22,
      transparent: true,
      opacity: 0.3,
    });

    const geometry = new THREE.BufferGeometry();
    geometry.addAttribute('position', new THREE.Float32BufferAttribute([], 3));
    this.previewStructure = new THREE.LineSegments(geometry, material);

    this.props.scene.add(this.previewStructure);
  }

  updatePreviewStructure() {
    const { meshes, facesMapByMesh, showStructurePreview } = this.state;

    const oldGeometry = this.previewStructure.geometry;

    const zDownVector = new THREE.Vector3(0, 0, -1);
    const raycaster = SceneUtils.createRaycaster();

    const currentNormal = new THREE.Vector3();
    const currentMidpoint = new THREE.Vector3();

    const vertices = [];

    if (showStructurePreview) {
      meshes.forEach((mesh) => {
        const supportAttr = mesh.geometry.attributes.support;
        const facesMap = facesMapByMesh[mesh.name];

        let prevStatus;
        facesMap.forEach((currentFace, faceIndex) => {
          prevStatus = supportAttr.getX(faceIndex * 3);

          if (prevStatus === 1.0) {
            currentFace.getNormal(currentNormal);
            currentFace.getMidpoint(currentMidpoint);

            raycaster.set(currentMidpoint, zDownVector);

            // head: self
            vertices.push(
              currentMidpoint.x,
              currentMidpoint.y,
              currentMidpoint.z
            );

            // tail:
            const intersections = raycaster
              .intersectObjects(meshes)
              .filter((intersection) => intersection.distance > 0.0001);
            if (intersections.length > 0) {
              // first face seen along the way
              vertices.push(
                intersections[0].point.x,
                intersections[0].point.y,
                intersections[0].point.z
              );
            } else {
              // or the point on bed
              vertices.push(currentMidpoint.x, currentMidpoint.y, 0);
            }
          }
        });
      });
    }

    const newGeometry = new THREE.BufferGeometry();
    newGeometry.addAttribute(
      'position',
      new THREE.Float32BufferAttribute(vertices, 3)
    );

    this.previewStructure.geometry = newGeometry;
    oldGeometry.dispose();
    setRenderFlag();
  }

  disposePreviewStructure() {
    this.previewStructure.geometry.dispose();
    this.previewStructure.material.dispose();
    this.props.scene.remove(this.previewStructure);
    setRenderFlag();
  }

  renderCancelModal() {
    if (!this.state.showCancelModal) return null;

    return (
      <ConfirmationModal
        isWarning
        primaryLabel='You have unsaved changes'
        secondaryLabel='Are you sure you would like to exit without saving your custom supports?'
        cancelLabel='Close'
        confirmLabel='Exit'
        onClickCancel={() => this.setState({ showCancelModal: false })}
        onClickConfirm={() => this.cancelSupportView()}
      />
    );
  }

  renderFieldsHeader() {
    return (
      <ModalHeader>
        <Subtitle1>Auto-generate supports</Subtitle1>
        <ActionButton
          clean
          icon={Icons.basic.x}
          onClick={() =>
            this.setState({
              showAutoGenerateModal: false,
              maxOverhangAngle: 45,
              maxOverhangAngleError: false,
              fromPrintBedOnly: false,
            })
          }
        />
      </ModalHeader>
    );
  }

  renderFieldsFooter() {
    return (
      <AutoSupportsFieldsFooter>
        <FooterLabels>
          <Caption grey>Any existing custom supports will be cleared.</Caption>
          <Caption grey>This action can be undone.</Caption>
        </FooterLabels>
        <Button
          minWidth='6rem'
          primary
          onClick={() => this.handleAutoGenerateSupports()}>
          Generate
        </Button>
      </AutoSupportsFieldsFooter>
    );
  }

  renderAutoGenerateModal() {
    if (!this.state.showAutoGenerateModal) return null;
    return (
      <Modal width='20em'>
        <AutoSupportsFields>
          {this.renderFieldsHeader()}
          <FieldsContentWrapper>
            {this.renderParamFields()}
          </FieldsContentWrapper>
          {this.renderFieldsFooter()}
        </AutoSupportsFields>
      </Modal>
    );
  }

  renderParamFields() {
    return _.map(fields, (field) => {
      const variant = field.variants[0];
      switch (variant.type) {
        case fieldTypes.number:
          return this.renderNumberField(field);
        case fieldTypes.checkbox:
          return this.renderCheckbox(field);
        default:
          return null;
      }
    });
  }

  onAngleChange(value, error = false) {
    this.setState({
      maxOverhangAngle: value,
      maxOverhangAngleError: error,
    });
  }

  onAngleChangeSuccess(value) {
    this.onAngleChange(value, false);
  }

  onAngleChangeFailure(value) {
    this.onAngleChange(value, true);
  }

  renderNumberField(field) {
    const { name, label, tooltip } = field;
    const { min, max, gte, step, units } = field.variants[0];
    return (
      <FieldContainer key={name}>
        <Input
          type='number'
          label={label}
          value={this.state[name]}
          isInvalid={this.state.maxOverhangAngleError}
          forceFocus={this.state.focusMaxOverhangAngle}
          min={min}
          gte={gte}
          max={max}
          step={step}
          infoTooltip={tooltip}
          units={units}
          stopPropagationOnKeyDown
          onChangeSuccess={(value) => this.onAngleChangeSuccess(value)}
          onChangeFailure={(value) => this.onAngleChangeFailure(value)}
        />
      </FieldContainer>
    );
  }

  renderCheckbox(field) {
    const { name, label, tooltip } = field;
    return (
      <FieldContainer key={name}>
        <ToolTipWrapper tooltip={tooltip}>
          <Checkbox
            label={label}
            value={this.state[name]}
            onChange={() =>
              this.setState({
                fromPrintBedOnly: !this.state.fromPrintBedOnly,
              })
            }
          />
        </ToolTipWrapper>
      </FieldContainer>
    );
  }

  renderPaintUndoRedoButtons() {
    const tooltipProps = {
      rightAlign: true,
      bottomDirection: true,
    };

    return (
      <UndoRedoButtonsContainer>
        <UndoRedoButtonWrapper>
          <ActionButton
            icon={Icons.basic.undo}
            title='Undo'
            onClick={(e) => this.undo(e)}
            disabled={this.isUndoDisabled()}
            tooltipProps={tooltipProps}
          />
        </UndoRedoButtonWrapper>
        <UndoRedoButtonWrapper>
          <ActionButton
            icon={Icons.basic.redo}
            title='Redo'
            onClick={(e) => this.redo(e)}
            disabled={this.isRedoDisabled()}
            tooltipProps={tooltipProps}
          />
        </UndoRedoButtonWrapper>
      </UndoRedoButtonsContainer>
    );
  }

  renderViewButtons() {
    return (
      <ViewButtonsContainer>
        <Button onClick={() => this.handleCancel()}>Cancel</Button>
        <ViewButtonWrapper>
          <Button primary onClick={() => this.handleSave()}>
            Save and return
          </Button>
        </ViewButtonWrapper>
      </ViewButtonsContainer>
    );
  }

  renderViewButtonsMobile() {
    return (
      <ViewButtonsMobileContainer>
        <CancelSaveButtonMobileWrapper>
          <ActionButton
            warning
            icon={Icons.basic.x}
            onClick={() => this.handleCancel()}
          />
        </CancelSaveButtonMobileWrapper>
        <CancelSaveButtonMobileWrapper>
          <ActionButton
            primary
            icon={Icons.basic.check}
            onClick={() => this.handleSave()}
          />
        </CancelSaveButtonMobileWrapper>
      </ViewButtonsMobileContainer>
    );
  }

  toggleBasicControlMobile() {
    this.setState({
      showBasicControlMobile: !this.state.showBasicControlMobile,
    });
  }

  closeBasicControlMobileButton() {
    return (
      <ActionButton
        onClick={() => this.toggleBasicControlMobile()}
        icon={Icons.basic.x}
        title='Close'
        minimal
      />
    );
  }

  renderMobileToolbarLeft() {
    const { showBasicControlMobile } = this.state;
    if (showBasicControlMobile) return null;
    return (
      <ActionButton
        icon={Icons.project.brush}
        title='Supports'
        onClick={() => this.toggleBasicControlMobile()}
      />
    );
  }

  renderFacetButton(currentTool) {
    return (
      <ActionButton
        icon={Icons.project.triangle}
        title='Facet'
        primary={currentTool === 'facet'}
        onClick={() => this.selectTool('facet')}
      />
    );
  }

  renderSphereButton(currentTool) {
    return (
      <ActionButton
        icon={Icons.project.circle}
        title='Sphere'
        primary={currentTool === 'sphere'}
        onClick={() => this.selectTool('sphere')}
      />
    );
  }

  renderMoreButton(currentTool) {
    return (
      <ActionButton
        icon={Icons.basic.more}
        title='More'
        primary={currentTool === 'context'}
        onClick={() => this.selectTool('context')}
      />
    );
  }

  renderPaintControls() {
    const { currentTool } = this.props;
    return (
      <ControlButtonsContainer>
        {this.renderFacetButton(currentTool)}
        {this.renderSphereButton(currentTool)}
        {this.renderMoreButton(currentTool)}
      </ControlButtonsContainer>
    );
  }

  renderPaintControlsMobile() {
    const { currentTool } = this.props;
    return (
      <ControlButtonsMobileContainer>
        {this.renderFacetButton(currentTool)}
        {this.renderSphereButton(currentTool)}
        {this.renderMoreButton(currentTool)}
      </ControlButtonsMobileContainer>
    );
  }

  renderExtruderButtons() {
    const { activeExtruder, currentExtruderIndex } = this.state;

    return (
      <ExtruderButtonsContainer>
        {_.map(SUPPORT_FACE_COLORS, (color, index) => {
          const hex = SlicerUtils.rgbaArrayToHexString(color);
          return (
            <ColorSwatch
              key={index}
              label={index > 0 ? 'Off' : 'On'}
              color={color}
              static={true}
              onSelect={() => this.selectExtruderButton(hex, index)}
              selected={activeExtruder && index === currentExtruderIndex}
            />
          );
        })}
      </ExtruderButtonsContainer>
    );
  }

  renderContextTools() {
    return (
      <ContextToolsContainer>
        <Button
          clean
          expand
          onClick={() => this.setState({ showAutoGenerateModal: true })}>
          Auto-generate
        </Button>
        <Button clean expand onClick={() => this.handleClearSupports()}>
          Clear all
        </Button>
        <Button clean expand onClick={() => this.handleTogglePreview()}>
          Toggle preview
        </Button>
      </ContextToolsContainer>
    );
  }

  renderBrushSize() {
    const { currentTool, brush } = this.props;
    if (currentTool === 'sphere') {
      return (
        <BrushSizeWrapper>
          <BrushSize
            brush={brush}
            onSelectSize={(size) => this.props.selectBrushSize(size)}
          />
        </BrushSizeWrapper>
      );
    }
    return null;
  }

  renderBasicControl(smallScreen) {
    return (
      <ToolCollapsiblePanel
        label='Supports'
        isCollapsed={false}
        scroll={false}
        forceOpen={smallScreen}
        headerCustomButton={smallScreen && this.closeBasicControlMobileButton()}
        onOpen={() => this.setState({ showToolContent: true })}
        onClose={() => this.setState({ showToolContent: false })}>
        {this.renderPaintControls()}
        {this.props.currentTool === 'context' ? (
          this.renderContextTools()
        ) : (
          <>
            {this.renderExtruderButtons()}
            {this.renderBrushSize()}
          </>
        )}
      </ToolCollapsiblePanel>
    );
  }

  renderBasicControlMobile() {
    const { showBasicControlMobile } = this.state;
    let basicControlMobile = null;
    if (showBasicControlMobile) {
      basicControlMobile = this.renderBasicControl(true);
    }
    return basicControlMobile;
  }

  renderMobileToolbox() {
    return (
      <SupportToolboxMobileContainer>
        {this.renderMobileToolbarLeft()}
        {this.renderBasicControlMobile()}
      </SupportToolboxMobileContainer>
    );
  }

  renderToolbox() {
    return <ToolboxContainer>{this.renderBasicControl()}</ToolboxContainer>;
  }

  render() {
    return (
      <>
        <HeaderBar />
        {this.renderToolbox()}
        {this.renderMobileToolbox()}
        {this.renderPaintUndoRedoButtons()}
        {this.renderViewButtons()}
        {this.renderViewButtonsMobile()}
        {this.renderCancelModal()}
        {this.renderAutoGenerateModal()}
      </>
    );
  }
}

export default withTheme(SupportView);

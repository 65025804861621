import React from 'react';
import { Body1 } from '../../../shared';
import { SectionPlaceholder } from './viewDevice.styles';

const Placeholder = ({ borderless = false, children }) => (
  <SectionPlaceholder disabled borderless={borderless}>
    <Body1 grey noSpacing>
      {children}
    </Body1>
  </SectionPlaceholder>
);

export default Placeholder;

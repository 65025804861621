import { connect } from 'react-redux';

import { actions as authActions } from '../../reducers/auth/auth';
import { actions as printerActions } from '../../reducers/printers/printers';
import PrinterManager from './printerManager.jsx';

const mapStateToProps = (state) => ({
  printers: state.printers.printers,
  sortBy: {
    mode: state.auth.preferences.printersSortMode,
    direction: state.auth.preferences.printersSortDirection,
  },
  projects: state.slicer.projects,
  getPrintersPending: state.printers.status.getPrintersPending,
  getPrintersSuccess: state.printers.status.getPrintersSuccess,
  duplicatePrinterPending: state.printers.status.duplicatePrinterPending,
  duplicatePrinterSuccess: state.printers.status.duplicatePrinterSuccess,
  deletePrintersPending: state.printers.status.deletePrintersPending,
  deletePrintersSuccess: state.printers.status.deletePrintersSuccess,
  createSharedPrinterPending: state.printers.status.createSharedPrinterPending,
  createSharedPrinterSuccess: state.printers.status.createSharedPrinterSuccess,
  deleteSharedPrinterPending: state.printers.status.deleteSharedPrinterPending,
  deleteSharedPrinterSuccess: state.printers.status.deleteSharedPrinterSuccess,
  updateSharedPrinterPending: state.printers.status.updateSharedPrinterPending,
  updateSharedPrinterSuccess: state.printers.status.updateSharedPrinterSuccess,
  getMaterialsPending: state.slicer.status.getMaterialsPending,
  getMaterialsSuccess: state.slicer.status.getMaterialsSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  updatePreferences: (sortMode, sortDirection) =>
    dispatch(
      authActions.editAccountRequest({
        preferences: {
          printersSortMode: sortMode,
          printersSortDirection: sortDirection,
        },
      })
    ),
  duplicatePrinter: (printerId) =>
    dispatch(printerActions.duplicatePrinterRequest(printerId)),
  deletePrinters: (printerIds) =>
    dispatch(printerActions.deletePrintersRequest(printerIds)),
  updatePrinter: (printerId, printerData) =>
    dispatch(printerActions.updatePrinterRequest(printerId, printerData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrinterManager);

import React, { Component } from 'react';

import { CanvasElement } from './miniVisualizer.styles';

import { SceneUtils, TreeUtils, SlicerUtils, MatrixUtils } from '../../utils';

class MiniVisualizer extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.frameId = null;
    this.scene = null;
    this.camera = null;
    this.renderer = null;
    this.controls = null;
    this.needsRender = false;
    this.animate = this.animate.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  componentDidMount() {
    this.initScene();
    this.addLighting();
    this.addModelsToScene();
    this.setCameraAndOrbitControlsPosition();
    this.onResize();
    window.addEventListener('resize', this.onResize);
    this.needsRender = true;
    this.animate();
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.frameId);
    window.removeEventListener('resize', this.onResize);
  }

  initScene() {
    this.scene = SceneUtils.createScene();
    this.camera = SceneUtils.createCamera();
    this.renderer = SceneUtils.createRenderer(this.canvasRef.current);
    this.controls = SceneUtils.createOrbitControls(
      this.camera,
      this.renderer.domElement
    );
    this.controls.addEventListener('change', () => {
      this.needsRender = true;
    });
  }

  addLighting() {
    const ambientLight = SceneUtils.createAmbientLight();
    const pointLight = SceneUtils.createPointLight();
    this.scene.add(ambientLight);
    this.camera.add(pointLight);
    this.scene.add(this.camera);
  }

  addModelsToScene() {
    TreeUtils.map(this.props.models, (model) => {
      if (model.mesh) {
        MatrixUtils.updateMeshTransforms(model.mesh, model.transforms);
        this.scene.add(model.mesh);
        SlicerUtils.applyStamps(model.mesh, model.stamps);
      }
    });
  }

  setCameraAndOrbitControlsPosition() {
    // calculate bounding sphere to get optimal center position to look at
    const boundingBox = SlicerUtils.getCombinedBoundingBox(this.props.models);
    const boundingSphere =
      SlicerUtils.getBoundingSphereFromBoundingBox(boundingBox);
    const cameraCoords = SceneUtils.getCameraSnapshotPosition(
      boundingSphere,
      45
    );

    // set camera position
    this.camera.position.set(cameraCoords.x, cameraCoords.y, cameraCoords.z);
    this.camera.lookAt(boundingSphere.center);
    this.camera.updateProjectionMatrix();

    // set orbit controls position
    this.controls.target.set(
      boundingSphere.center.x,
      boundingSphere.center.y,
      boundingSphere.center.z
    );
    this.controls.update();
  }

  onResize() {
    const { offsetWidth, offsetHeight } = this.canvasRef.current;
    this.renderer.setSize(offsetWidth, offsetHeight);
    this.updateProjectionMatrix();
    this.needsRender = true;
  }

  updateProjectionMatrix() {
    const { offsetWidth, offsetHeight } = this.canvasRef.current;
    this.camera.aspect = offsetWidth / offsetHeight;
    this.camera.updateProjectionMatrix();
  }

  animate() {
    if (this.needsRender) {
      this.renderer.render(this.scene, this.camera);
      this.needsRender = false;
    }
    this.frameId = requestAnimationFrame(this.animate);
  }

  render() {
    return <CanvasElement ref={this.canvasRef} />;
  }
}

export default MiniVisualizer;

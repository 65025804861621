import React, { Component } from 'react';
import { withTheme } from 'styled-components';

import Icon from '../icon/icon.jsx';

import { StyledButton, IconContainer } from './button.styles';
import { ButtonText } from '../typography/typography';

class Button extends Component {
  static defaultProps = {
    minimal: false,
    clean: false,
    toggled: false,
    primary: false,
    warning: false,
    tab: false,
    activeTab: false,
    link: false,
    compact: false,
    expand: false,
    disabled: false,
    height: '2.5rem',
    minWidth: '',
    title: '',
    upperCase: false,
    onClickKeyTrigger: '',
    onClick: () => {},
    onMouseOver: () => {},
    onMouseOut: () => {},
    icon: '',
    iconColor: '',
    iconSize: '',
    image: null,
    buttonTextColor: null,
  };

  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown(e) {
    if (
      this.props.onClickKeyTrigger &&
      e.key === this.props.onClickKeyTrigger
    ) {
      e.preventDefault();
      this.props.onClick(e);
    }
  }

  handleClick(e) {
    if (this.props.disabled) return;
    this.props.onClick(e);
  }

  iconColor() {
    // By Prop
    if (this.props.iconColor) return this.props.iconColor;

    // Disabled
    if (this.props.disabled) return this.props.theme.colors.grey4;

    // Primary
    if (this.props.primary) return this.props.theme.colors.whiteDefault;

    // Clean
    if (this.props.clean) return this.props.theme.colors.grey6;

    // Secondary
    return this.props.theme.colors.greenDefault;
  }

  iconSize() {
    if (this.props.iconSize) return this.props.iconSize;

    return undefined;
  }

  renderIcon() {
    return (
      <IconContainer noText={!this.props.children}>
        <Icon
          src={this.props.icon}
          color={this.iconColor()}
          size={this.iconSize()}
        />
      </IconContainer>
    );
  }

  render() {
    return (
      <StyledButton
        minimal={this.props.minimal}
        clean={this.props.clean}
        toggled={this.props.toggled}
        primary={this.props.primary}
        warning={this.props.warning}
        tab={this.props.tab}
        activeTab={this.props.activeTab}
        link={this.props.link}
        compact={this.props.compact}
        expand={this.props.expand}
        disabled={this.props.disabled}
        height={this.props.height}
        minWidth={this.props.minWidth}
        title={this.props.title || undefined}
        upperCase={this.props.upperCase}
        onClick={(e) => this.handleClick(e)}
        onMouseOver={(e) => this.props.onMouseOver(e)}
        onMouseOut={(e) => this.props.onMouseOut(e)}
        buttonTextColor={this.props.buttonTextColor}>
        {this.props.image}
        {this.props.icon && this.renderIcon()}
        <ButtonText>{this.props.children}</ButtonText>
      </StyledButton>
    );
  }
}

export default withTheme(Button);

import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API from '../canvas-api';
import { handleGenericError } from '../common';
import { actions } from '../../reducers/printers/printers';
import { sanitizePrinterData } from './common';

export default function* getSharedPrinter(action) {
  try {
    const { shareId } = action.payload;

    const response = yield call(API, {
      path: `printers/shared/${shareId}`,
      token: null,
    });

    const sanitizedPrinter = sanitizePrinterData(response.sharedPrinter);
    yield put(actions.getSharedPrinterSuccess(sanitizedPrinter));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

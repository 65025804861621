import React from 'react';

import { withTheme } from 'styled-components';

import Icon from '../icon/icon.jsx';
import { Body1, Subtitle1 } from '../typography/typography';
import Dropdown from '../dropdown/dropdown.jsx';

import {
  ContentContainer,
  ContentWrapper,
  DropDownWrapper,
  SideBarContainer,
  SideBarItem,
  StyledSideBar,
  TextWrapper,
} from './sideBar.styles';

const SideBar = ({
  items,
  activeTab,
  setActiveTab,
  fullScreenView,
  children,
}) => {
  const renderSideBarItems = () =>
    items.map((item, idx) => (
      <SideBarItem
        key={idx}
        active={activeTab === idx}
        onClick={() => setActiveTab(idx)}>
        {item.icon && <Icon src={item.icon} size={Icon.sizes.SMALL} />}
        <TextWrapper>
          <Body1 noSpacing>{item.text}</Body1>
        </TextWrapper>
      </SideBarItem>
    ));

  const dropdownOptions = items.map((item, idx) => ({
    label: item.text,
    value: idx,
  }));

  const renderItemsDropdown = () => (
    <DropDownWrapper>
      <Dropdown
        value={activeTab}
        options={dropdownOptions}
        onChange={(value) => setActiveTab(value)}
      />
    </DropDownWrapper>
  );

  const renderActiveTabTitle = () => {
    const activeTabTitle = items[activeTab].text;
    return activeTabTitle && <Subtitle1>{activeTabTitle}</Subtitle1>;
  };

  return (
    <SideBarContainer>
      <StyledSideBar>
        {renderSideBarItems()}
        {renderItemsDropdown()}
      </StyledSideBar>
      <ContentContainer>
        <ContentWrapper fullScreenView={fullScreenView}>
          {renderActiveTabTitle()}
          {children}
        </ContentWrapper>
      </ContentContainer>
    </SideBarContainer>
  );
};

export default withTheme(SideBar);

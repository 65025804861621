import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import { getPaginatedItems } from '../canvas-api';

import { handleGenericError, getAuthState } from '../common';
import { actions } from '../../reducers/notifications/notifications';

export default function* getNotifications(action) {
  try {
    const { userId } = yield select(getAuthState);
    const notifications = yield call(
      getPaginatedItems,
      `notifications/user/${userId}`,
      'notifications'
    );
    if (notifications === null) return;
    // convert to map
    const notificationsMap = {};
    _.forEach(notifications, (notification) => {
      notificationsMap[notification.id] = notification;
    });

    yield put(actions.getNotificationsSuccess(notificationsMap));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

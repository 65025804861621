import 'regenerator-runtime/runtime';
import { call, put, all } from 'redux-saga/effects';
import _ from 'lodash';

import { getModelFile } from './loadProject';

import API, { methods } from '../../canvas-api';
import { handleGenericError } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';
import { actions as THREEactions } from '../../../reducers/three/three';

import { TreeUtils } from '../../../utils';

export default function* remapProjectInputs(action) {
  try {
    const { projectId, inputMap, reloadRequired } = action.payload;

    const response = yield call(API, {
      method: methods.POST,
      path: `projects/${projectId}/inputs`,
      body: {
        inputMap,
      },
    });
    if (response === null) return;

    const { project } = response;

    if (reloadRequired) {
      // this saga was triggered before project was initialized.
      // finish the action, and exit this saga early
      // Slicer will take care of reloading the project
      yield put(actions.remapProjectInputsSuccess(project, []));
      return;
    }

    const meshes = yield all(
      _.map(project.models, (model) =>
        call(getModelFile, projectId, model, project.colors)
      )
    );
    const modelTree = TreeUtils.addMeshesToModelTree(
      TreeUtils.formatModelTree(project.models),
      meshes
    );

    // "flash" models
    yield put(THREEactions.removeModelsFromScene());
    yield put(actions.remapProjectInputsSuccess(project, modelTree));
    yield put(THREEactions.addModelsToScene());
    yield put(actions.updateProjectThumbnailRequest(projectId));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import { connect } from 'react-redux';

import ConfigureStyle from '../configureStyle.jsx';
import { actions } from '../../../../reducers/printers/printers';

const mapStateToProps = (state) => ({
  mode: 'edit',
  title: 'Edit Style Profile',
  printerTag: state.printers.currentPrinterTag,
  printers: state.printers.printers,
  updateStylePending: state.printers.status.updatePrinterPending,
  requestSuccess: state.printers.status.updatePrinterSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  updateStyleProfile: (printerId, styleId, styles) =>
    dispatch(actions.updateStyleProfileRequest(printerId, styleId, styles)),
  getPrinterTag: (tagId) => dispatch(actions.getPrinterTagRequest(tagId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureStyle);

import { connect } from 'react-redux';

import { actions } from '../../reducers/printers/printers';
import SharePrinterModal from './sharePrinterModal.jsx';

const mapStateToProps = (state) => ({
  printers: state.printers.printers,
  createSharedPrinterPending: state.printers.status.createSharedPrinterPending,
  createSharedPrinterSuccess: state.printers.status.createSharedPrinterSuccess,
  deleteSharedPrinterPending: state.printers.status.deleteSharedPrinterPending,
  deleteSharedPrinterSuccess: state.printers.status.deleteSharedPrinterSuccess,
  updateSharedPrinterPending: state.printers.status.updateSharedPrinterPending,
  updateSharedPrinterSuccess: state.printers.status.updateSharedPrinterSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  createSharedPrinter: (printerId, styleIds) =>
    dispatch(actions.createSharedPrinterRequest(printerId, styleIds)),
  deleteSharedPrinter: (printerId) =>
    dispatch(actions.deleteSharedPrinterRequest(printerId)),
  updateSharedPrinter: (printerId, styleIds) =>
    dispatch(actions.updateSharedPrinterRequest(printerId, styleIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SharePrinterModal);

import React from 'react';
import _ from 'lodash';

import { fieldTypes, fieldSteps, slicers } from '../../../../../constants';
import tooltips from './tooltips.jsx';
import { MiniSwatch } from '../../../../../shared';
import { FormatUtils } from '../../../../../utils';

const { roundTo } = FormatUtils;

const { KISSlicer, Slic3r } = slicers;
const { EXTRUSION_WIDTH_STEP, LAYER_HEIGHT_STEP, SPEED_STEP } = fieldSteps;

export const supportDensities = {
  2: { label: 'Low', tags: [], value: 2 },
  4: { label: 'Medium', tags: [], value: 4 },
  6: { label: 'High', tags: [], value: 6 },
};

const supportMethods = {
  0: { label: 'Standard', tags: [], value: false },
  1: { label: 'Custom', tags: [], value: true },
};

const Metadata = {
  general: {
    name: 'useSupport',
    label: 'Supports',
    tags: [],
    tooltip: tooltips.useSupport,
    isChecked: (style) => style.useSupport,
    updateFieldsOnToggle: (checked, updateField) => {
      updateField('useSupport', checked);
    },
    fields: [
      {
        name: 'defaultSupportExtruder',
        label: 'Support extruder',
        tags: [],
        tooltip: tooltips.defaultSupportExtruder,
        display: (style, projectProps) =>
          !projectProps || projectProps.colors.length > 1,
        disabled: (style) => !style.useSupport,
        variants: [
          {
            type: fieldTypes.boxSelection,
            horizontal: true,
            options: (style, projectProps) => {
              const numInputs = projectProps ? projectProps.colors.length : 8;
              const extruderOptions = new Array(numInputs)
                .fill(0)
                .map((val, index) => {
                  const color = projectProps
                    ? projectProps.colors[index]
                    : null;
                  return {
                    label: (
                      <MiniSwatch
                        color={color}
                        label={index + 1}
                        selected={style.defaultSupportExtruder.value === index}
                      />
                    ),
                    value: { units: 'index', value: index },
                  };
                });
              return [
                ...extruderOptions,
                { label: 'Auto', value: { value: 'auto' } },
              ];
            },
          },
        ],
      },
      {
        name: 'supportDensity',
        label: 'Support density',
        tags: [],
        tooltip: tooltips.supportDensity,
        disabled: (style) => !style.useSupport,
        variants: [
          {
            type: fieldTypes.boxSelection,
            horizontal: true,
            options: _.keys(supportDensities).map(
              (density) => supportDensities[density]
            ),
          },
        ],
      },
      {
        name: 'supportSpeed',
        label: 'Support speed',
        tags: ['feedrate'],
        tooltip: tooltips.supportSpeed,
        slicers: [Slic3r],
        disabled: (style, slicer) =>
          !style.useSupport || (slicer && slicer !== Slic3r),
        variants: [
          {
            units: 'mm/s',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: SPEED_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            units: '%',
            optionLabel: '% of solid layer speed',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: 1,
            toBaseUnits: (value, style) =>
              Math.round((value * style.solidLayerSpeed) / 100),
            fromBaseUnits: (value, style) =>
              Math.round((value / style.solidLayerSpeed) * 100),
          },
          {
            type: fieldTypes.auto,
            toBaseUnits: (value, style) => style.solidLayerSpeed,
            fromBaseUnits: () => 'auto',
          },
        ],
      },
      {
        name: 'useCustomSupports',
        label: 'Support method',
        tags: [],
        tooltip: tooltips.useCustomSupports,
        disabled: (style) => !style.useSupport,
        variants: [
          {
            type: fieldTypes.boxSelection,
            horizontal: true,
            options: _.keys(supportMethods).map(
              (method) => supportMethods[method]
            ),
          },
        ],
      },
      {
        name: 'maxOverhangAngle',
        label: 'Maximum overhang',
        tags: [],
        tooltip: tooltips.maxOverhangAngle,
        disabled: (style) => !style.useSupport || style.useCustomSupports,
        variants: [
          {
            units: '°',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            max: 90,
            step: 1,
          },
        ],
      },
    ],
  },
  placement: {
    label: 'Support placement',
    tags: [],
    fields: [
      {
        name: 'supportZGap',
        label: 'Vertical gap from part',
        tags: [],
        tooltip: tooltips.supportZGap,
        disabled: (style) => !style.useSupport,
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            step: LAYER_HEIGHT_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            units: 'layers',
            type: fieldTypes.number,
            min: 0,
            step: 1,
            toBaseUnits: (value, style) =>
              roundTo(value * style.layerHeight, 2),
            fromBaseUnits: (value, style) =>
              Math.max(0, Math.round(value / style.layerHeight)),
          },
        ],
      },
      {
        name: 'supportXYGap',
        label: 'Horizontal gap from part',
        tags: [],
        tooltip: tooltips.supportXYGap,
        disabled: (style) => !style.useSupport,
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            step: EXTRUSION_WIDTH_STEP,
          },
        ],
      },
      {
        name: 'supportXYInflation',
        label: 'Horizontal inflation',
        tags: [],
        tooltip: tooltips.supportXYInflation,
        slicers: [KISSlicer],
        disabled: (style, slicer) =>
          !style.useSupport || (slicer && slicer !== KISSlicer),
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            step: EXTRUSION_WIDTH_STEP,
          },
        ],
      },
    ],
  },
  reinforcements: {
    name: 'useSupportStitchInterval',
    label: 'Reinforcement layers',
    tags: [],
    tooltip: tooltips.useSupportStitchInterval,
    slicers: [KISSlicer],
    disabled: (style, slicer) => slicer && slicer !== KISSlicer,
    isChecked: (style) => style.useSupportStitchInterval,
    updateFieldsOnToggle: (checked, updateField) => {
      updateField('useSupportStitchInterval', checked);
    },
    fields: [
      {
        name: 'supportStitchInterval',
        label: 'Reinforcement interval',
        tags: [],
        tooltip: tooltips.supportStitchInterval,
        disabled: (style, slicer) =>
          !style.useSupport ||
          !style.useSupportStitchInterval ||
          (slicer && slicer !== KISSlicer),
        variants: [
          {
            units: 'layers',
            type: fieldTypes.number,
            min: 2,
            step: 1,
          },
        ],
      },
    ],
  },
  interfaces: {
    name: 'useSupportInterface',
    label: 'Dense support interfaces',
    tags: [],
    tooltip: tooltips.useSupportInterface,
    disabled: (style) => !style.useSupport,
    isChecked: (style) => style.useSupportInterface,
    updateFieldsOnToggle: (checked, updateField) => {
      updateField('useSupportInterface', checked);
    },
    fields: [
      {
        name: 'defaultSupportInterfaceExtruder',
        label: 'Support interface extruder',
        tags: [],
        tooltip: tooltips.defaultSupportInterfaceExtruder,
        display: (style, projectProps) =>
          !projectProps || projectProps.colors.length > 1,
        disabled: (style) => !style.useSupport || !style.useSupportInterface,
        variants: [
          {
            type: fieldTypes.boxSelection,
            horizontal: true,
            options: (style, projectProps) => {
              const numInputs = projectProps ? projectProps.colors.length : 8;
              return new Array(numInputs).fill(0).map((val, index) => {
                const color = projectProps ? projectProps.colors[index] : null;
                return {
                  label: (
                    <MiniSwatch
                      color={color}
                      label={index + 1}
                      selected={style.defaultSupportInterfaceExtruder === index}
                    />
                  ),
                  value: index,
                };
              });
            },
          },
        ],
      },
      {
        name: 'supportInterfaceSpeed',
        label: 'Interface speed',
        tags: ['feedrate'],
        tooltip: tooltips.supportInterfaceSpeed,
        slicers: [Slic3r],
        disabled: (style, slicer) =>
          !style.useSupport ||
          !style.useSupportInterface ||
          (slicer && slicer !== Slic3r),
        variants: [
          {
            units: 'mm/s',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: SPEED_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            units: '%',
            optionLabel: '% of solid layer speed',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: 1,
            toBaseUnits: (value, style) =>
              Math.round((value * style.solidLayerSpeed) / 100),
            fromBaseUnits: (value, style) =>
              Math.round((value / style.solidLayerSpeed) * 100),
          },
          {
            type: fieldTypes.auto,
            toBaseUnits: (value, style) => style.solidLayerSpeed,
            fromBaseUnits: () => 'auto',
          },
        ],
      },
      {
        name: 'supportInterfaceDensity',
        label: 'Interface density',
        tags: [],
        tooltip: tooltips.supportInterfaceDensity,
        disabled: (style) => !style.useSupport || !style.useSupportInterface,
        variants: [
          {
            units: '%',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            max: 100,
            step: 1,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            type: fieldTypes.auto,
            toBaseUnits: () => 100,
            fromBaseUnits: () => 'auto',
          },
        ],
      },
      {
        name: 'supportInterfaceExtrusionWidth',
        label: 'Interface extrusion width',
        tags: [],
        tooltip: tooltips.supportInterfaceExtrusionWidth,
        disabled: (style) => !style.useSupport || !style.useSupportInterface,
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            step: EXTRUSION_WIDTH_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            type: fieldTypes.auto,
            toBaseUnits: (value, style) => style.extrusionWidth,
            fromBaseUnits: () => 'auto',
          },
        ],
      },
      {
        name: 'supportInterfaceThickness',
        label: 'Interface thickness',
        tags: [],
        tooltip: tooltips.supportInterfaceThickness,
        disabled: (style) => !style.useSupport || !style.useSupportInterface,
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: LAYER_HEIGHT_STEP,
            toBaseUnits: (value) => value,
            fromBaseUnits: (value) => value,
          },
          {
            units: 'layers',
            type: fieldTypes.number,
            min: 2,
            step: 1,
            toBaseUnits: (value, style) =>
              roundTo(value * style.layerHeight, 2),
            fromBaseUnits: (value, style) =>
              Math.max(2, roundTo(value / style.layerHeight, 0)),
          },
        ],
      },
    ],
  },
};

export default Metadata;

import styled from 'styled-components';
import { DefaultSelect } from '../../../shared/abstractDropdown/abstractDropdown.styles';

import { Breakpoints } from '../../../themes';

export const Container = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
`;

export const ButtonWrapper = styled.div`
  margin-right: 0.5rem;
`;

export const ProjectOptionsRow = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  padding-bottom: 1.25rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey3};

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    flex-wrap: wrap;
  }
`;

export const ChangesRowContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

export const NameContainer = styled.div`
  flex-basis: 75%;
  flex-grow: 1;
`;

export const SlicerDropdownContainer = styled.div`
  flex: auto;
`;

export const ProfileControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  max-width: 100%;
  margin-top: 0.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey3};

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const StyleSettingsFormContainer = styled.div`
  min-height: 0;
  flex-basis: 0;
  flex-grow: 1;
`;

export const StyledCustomLabel = styled(DefaultSelect)`
  color: ${(props) => props.theme.colors.greenDefault};
`;

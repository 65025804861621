import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import {
  Container,
  Sidebar,
  LegendAndOptionsContainer,
  SentToConfirmation,
  ModalContent,
  ModalTitle,
  ModalList,
  ModalListItem,
  ListItemName,
  BottomButtonsContainer,
  SendToButtonWrapper,
  SendImage,
  SummaryPanelWrapper,
  DismissButtonWrapper,
  SendToPrintBar,
  SliceControlButtons,
  SideBarMobile,
  SendToDropdownWrapper,
  SendToIconWrapper,
  StyledDescriptionIconContainer,
} from './sliceView.styles';

import {
  Icon,
  Modal,
  Button,
  ToolCollapsiblePanel,
  ConfirmationModal,
  ActionButton,
} from '../../../shared';

import { DeviceUtils, ProjectUtils } from '../../../utils';

import HeaderBar from '../../../shared/page/headerBar/headerBar.jsx';
import PrintStats from './printStats/printStats.jsx';
import ToolpathSlider from './toolpathSlider/toolpathSlider.jsx';
import ToolpathOptions from './toolpathOptions/toolpathOptions.jsx';
import ToolpathLegend from './toolpathLegend/toolpathLegend.jsx';
import ConnectedWarningModal from './connectedWarningModal/connectedWarningModal.jsx';
import StampWarningModal from './stampWarningModal/stampWarningModal.jsx';

import { Icons, LightColors } from '../../../themes';
import { slicers } from '../../../constants';
import { Firmware } from '../../../utils/profile-import/enums';
import Routes from '../../../router/routes';
import MobilePanel from '../mobilePanel/mobilePanel.jsx';
import {
  StyledOption,
  StyledOptionWrapper,
} from '../../../shared/colorSelectionDropdown/colorSelectionDropdown.styles';
import AbstractDropdown from '../../../shared/abstractDropdown/abstractDropdown.jsx';

const connectedModeExtensions = ['gcode', 'gco', 'g', 'hvs', 'g3drem', 'mcfx'];

class SliceView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connectedModeWarning: null,
      stampWarning: false,
      sentToDevice: false,
      sentToPolar: false,
      showPolarModal: false,
      polarPrinterSerialNumber: '',
      selectedDevice: null,
      showSummaryContentMobile: true,
      showPrintPreviewPanelMobile: true,
    };
    this.handleSlice = this.handleSlice.bind(this);
  }

  componentDidMount() {
    const { project, sliceJobs, toolpath, dispatchSliceJobPending } =
      this.props;
    this.props.removeModelsFromScene();
    this.props.removeTowerFromScene();
    if (project.sliced) {
      if (this.props.canSendToPolar) {
        this.props.getPolarPrinters();
      }
      if (toolpath.version && toolpath.extrusions) {
        this.props.addToolpath(toolpath);
      } else {
        const isToolpathVersionValid = this.isToolpathVersionValid();
        const isToolpathLoading = this.isToolpathLoading();
        if (isToolpathVersionValid && !isToolpathLoading) {
          this.props.loadToolpath();
        }
      }
    } else if (process.env.REACT_APP_PARALLEL_SLICING === 'true') {
      // parallel slicing enabled
      const isProjectSlicing =
        dispatchSliceJobPending ||
        ProjectUtils.isProjectSlicing(sliceJobs, project.id);
      if (!isProjectSlicing) {
        // only proceed directly to slice, if current project is not already slicing
        this.handleSlice();
      }
    } else {
      // parallel slicing disabled
      const isAnyProjectSlicing =
        dispatchSliceJobPending || !_.isEmpty(sliceJobs);
      if (!isAnyProjectSlicing) {
        // only proceed directly to slice, if no project is slicing atm
        this.handleSlice();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      project,
      toolpath,
      dispatchPrintToPolarPending,
      dispatchPrintToPolarSuccess,
      dispatchPrintToDevicePending,
      dispatchPrintToDeviceSuccess,
    } = this.props;
    if (prevProps.toolpath !== toolpath) {
      if (prevProps.toolpath.extrusions && !toolpath.extrusions) {
        // toolpath was unloaded by slicing -- remove previous
        this.props.removeToolpath(prevProps.toolpath);
      } else {
        // just update logic
        this.props.updateToolpath(toolpath, prevProps.toolpath);
      }
    }

    if (
      dispatchPrintToPolarSuccess &&
      !dispatchPrintToPolarPending &&
      prevProps.dispatchPrintToPolarPending
    ) {
      this.setState({ sentToPolar: true });
    }

    if (
      dispatchPrintToDeviceSuccess &&
      !dispatchPrintToDevicePending &&
      prevProps.dispatchPrintToDevicePending
    ) {
      this.setState({ sentToDevice: true });
    }

    if (project.sliced && !prevProps.project.sliced) {
      this.props.getSlicePreviewData();
    }
  }

  componentWillUnmount() {
    this.props.cancelLoadToolpath();
    this.props.cancelCreateToolpathMeshes();
    this.props.removeToolpath(this.props.toolpath);
    this.props.addModelsToScene();
    if (this.props.transitionTower) {
      this.props.addTowerToScene(this.props.transitionTower);
    }
  }

  isToolpathVersionValid() {
    const { toolpath, project } = this.props;
    return (
      toolpath.version >= 5 ||
      (project.ptpHeader && project.ptpHeader.version >= 5)
    );
  }

  isToolpathLoading() {
    const { loadToolpathPtpPending, createToolpathMeshesPending } = this.props;
    return loadToolpathPtpPending || createToolpathMeshesPending;
  }

  handleSlice() {
    const { project, printer, models } = this.props;

    const connectedMode = !project.deviceConfig?.accessoryMode;

    const { extension, firmwareType } = printer.machineSettings;
    const extensionIncompatible = !_.includes(
      connectedModeExtensions,
      extension
    );
    const firmwareIncompatible = firmwareType === Firmware.FIRMWARE_GRIFFIN;
    if (connectedMode && (extensionIncompatible || firmwareIncompatible)) {
      this.setState({
        connectedModeWarning: {
          extensionIncompatible,
          firmwareIncompatible,
        },
      });
    } else {
      // check if project has stamps and uses an invalid slicer
      const hasStamps = _.some(models, (model) => model.textured);
      const slicerSupportsStamps =
        !project.slicer || project.slicer === slicers.KISSlicer;
      const showStampWarning = hasStamps && !slicerSupportsStamps;
      if (showStampWarning && !this.state.stampWarning) {
        this.setState({
          stampWarning: true,
        });
      } else {
        this.setState(
          {
            stampWarning: false,
          },
          () => {
            this.props.cancelLoadToolpath();
            this.props.cancelCreateToolpathMeshes();
            this.props.unloadToolpath();
            this.props.dispatchSliceJob(project.id);
          }
        );
      }
    }
  }

  sendToDevice(device) {
    const { project } = this.props;
    this.props.dispatchPrintToDevice(project.id, device);
  }

  onSendToButtonClick() {
    const [destination, device] = this.state.selectedDevice.split('-');
    if (this.props.canSendToPolar && destination === 'polar') {
      this.handleSendToPolar(device);
    } else if (
      this.props.compatibleDeviceIds.length > 0 &&
      destination === 'canvas'
    ) {
      this.sendToDevice(device);
    }
  }

  handleSendToPolar(polarPrinterSerialNumber) {
    this.props.dispatchPrintToPolar(
      this.props.project.id,
      polarPrinterSerialNumber
    );
    this.setState({ polarPrinterSerialNumber });
    this.renderSentToPolarConfirmation();
  }

  renderPrintStats() {
    const { project } = this.props;
    return <PrintStats stats={project.stats} colors={project.colors} />;
  }

  renderSliders() {
    const { toolpath } = this.props;
    if (
      !toolpath.version ||
      toolpath.layerCount === 0 ||
      !toolpath.extrusions
    ) {
      return null;
    }

    return (
      <ToolpathSlider
        toolpath={toolpath}
        setToolpathLayerRange={this.props.setToolpathLayerRange}
      />
    );
  }

  renderSliceButton() {
    const { project, sliceJobs, dispatchSliceJobPending } = this.props;

    let disabled = false;
    if (process.env.REACT_APP_PARALLEL_SLICING === 'true') {
      // parallel slicing enabled
      // disable slice button if current project is slicing
      const isProjectSlicing =
        dispatchSliceJobPending ||
        ProjectUtils.isProjectSlicing(sliceJobs, project.id);
      disabled = isProjectSlicing;
    } else {
      // parallel slicing disabled
      // disable slice button if currently slicing any project
      const isAnyProjectSlicing =
        dispatchSliceJobPending || !_.isEmpty(sliceJobs);
      disabled = isAnyProjectSlicing;
    }

    return (
      <Button height='2.5rem' disabled={disabled} onClick={this.handleSlice}>
        {this.props.project.sliced ? 'Re-slice' : 'Slice'}
      </Button>
    );
  }

  renderDownloadButton() {
    const { project } = this.props;
    const disabled = !project.sliced;
    const onClick = () => this.props.download(project.id);
    return (
      <Button primary disabled={disabled} onClick={onClick}>
        Download
      </Button>
    );
  }

  generateSendToButtonText(deviceId) {
    const { devices } = this.props;
    const currDevice = devices[deviceId];
    let label = 'Send to ';
    if (currDevice.type === 'palette-3') {
      label += 'Palette';
    } else if (currDevice.type === 'canvas-hub') {
      label += 'Hub';
    } else if (currDevice.type === 'element') {
      label += 'Element';
    } else if (currDevice.type === 'array') {
      label += 'Array';
    } else {
      // if device is unknown
      label += 'Device';
    }
    return label;
  }

  renderSendToButton() {
    const {
      canSendToPolar,
      dispatchPrintToDevicePending,
      dispatchPrintToPolarPending,
      deviceStates,
      project,
      printers,
    } = this.props;
    const polarPrinters = printers?.integrations?.polarCloud?.printers;
    if (
      (!canSendToPolar || polarPrinters?.length === 0) &&
      this.props.compatibleDeviceIds.length === 0
    ) {
      return null;
    }

    const sendingProject =
      dispatchPrintToDevicePending || dispatchPrintToPolarPending;

    let label = 'Send to Device';
    if (sendingProject) label = 'Sending...';
    let sendToButtonDisabled = true;

    if (this.state.selectedDevice) {
      const [destination, device] = this.state.selectedDevice.split('-');

      const connected = device ? deviceStates[device].connected : false;
      if (destination === 'canvas' && connected) {
        sendToButtonDisabled = false;
      }
      if (destination === 'polar') sendToButtonDisabled = false;
      if (!project.sliced) sendToButtonDisabled = true;

      // update 'Send to' button text once deviceStates are loaded
      if (deviceStates[device] !== undefined) {
        label = this.generateSendToButtonText(device);
      }
    }
    // if send to button is disabled default to 'Send to Device'
    if (sendToButtonDisabled) {
      label = 'Send to Device';
    }

    return (
      <SendToButtonWrapper>
        <Button
          primary
          disabled={sendToButtonDisabled || sendingProject}
          onClick={() => this.onSendToButtonClick()}>
          {label}
        </Button>
      </SendToButtonWrapper>
    );
  }

  renderSendToDevice() {
    const {
      deviceIds,
      compatibleDeviceIds,
      devices,
      printers,
      deviceStates,
      project,
      theme,
    } = this.props;
    let deviceOptions = _.map(deviceIds, (deviceId) => {
      const device = devices[deviceId];
      const isOnline = deviceStates[device.id].connected;
      const isDisabled = !isOnline || !compatibleDeviceIds.includes(deviceId);
      return {
        label: device.name,
        value: `canvas-${device.id}`,
        disabled: isDisabled,
        customOptionLabel: (
          <SendToIconWrapper>
            <Icon
              src={DeviceUtils.getDeviceIcon(device)}
              color={isOnline ? LightColors.whiteDefault : theme.colors.grey4}
            />
          </SendToIconWrapper>
        ),
      };
    });

    deviceOptions = _.sortBy(deviceOptions, (device) => device?.disabled);
    if (this.state.selectedDevice === null && deviceOptions.length > 0) {
      const deviceOption = _.find(deviceOptions, (option) => !option.disabled);
      if (deviceOption) {
        this.setState({ selectedDevice: deviceOption.value });
      }
    }

    const polarPrinters = printers?.integrations?.polarCloud?.printers;
    _.each(polarPrinters, (polarPrinter) => {
      deviceOptions.push({
        label: `${polarPrinter.name}`,
        value: `polar-${polarPrinter.serialNumber}`,
      });
    });

    if (
      (!this.state.canSendToPolar || polarPrinters?.length === 0) &&
      this.props.compatibleDeviceIds.length === 0
    ) {
      return null;
    }

    let descriptionIcon = null;
    if (this.state.selectedDevice) {
      const [, device] = this.state.selectedDevice.split('-');
      descriptionIcon = DeviceUtils.getDeviceIcon(devices[device]);
    }

    return (
      <SendToDropdownWrapper>
        <AbstractDropdown
          StyledOptionWrapper={StyledOptionWrapper}
          StyledDescriptionIconContainer={StyledDescriptionIconContainer}
          descriptionIcon={descriptionIcon}
          descriptionIconSize={Icon.sizes.MEDIUM}
          StyledOption={StyledOption}
          options={deviceOptions}
          disabled={
            (polarPrinters?.length === 0 &&
              this.props.compatibleDeviceIds.length === 0) ||
            !project.sliced
          }
          rightAlign
          customLabel={
            _.every(deviceOptions, (option) => option.disabled)
              ? 'No devices available'
              : null
          }
          value={this.state.selectedDevice}
          dropUp
          onChange={(val) => this.setState({ selectedDevice: val })}
        />
      </SendToDropdownWrapper>
    );
  }

  renderSentToDeviceConfirmation() {
    const { history } = this.props;
    const { sentToDevice, selectedDevice } = this.state;
    if (!sentToDevice) return null;
    const [, deviceId] = selectedDevice.split('-');

    return (
      <ConfirmationModal
        primaryLabel='Print sent to device'
        secondaryLabel={`Project has been successfully sent to the device. You can now start a print from the device.`}
        cancelLabel='Close'
        confirmLabel='Go to device'
        onClickCancel={() => this.setState({ sentToDevice: false })}
        onClickConfirm={() => {
          this.setState({ sentToDevice: false }, () => {
            history.push(Routes.toViewDevice(deviceId));
          });
        }}
      />
    );
  }

  renderSentToPolarConfirmation() {
    const { sentToPolar, polarPrinterSerialNumber } = this.state;
    if (!sentToPolar || this.props.dispatchPrintToPolarPending) return null;
    return (
      <SentToConfirmation
        target='_blank'
        rel='noopener noreferrer'
        href={`${process.env.REACT_APP_POLAR_HOST}/printers/${polarPrinterSerialNumber}`}>
        Go to Polar Cloud
      </SentToConfirmation>
    );
  }

  renderModalWithList(title, listItems) {
    return (
      <Modal>
        <ModalContent>
          <DismissButtonWrapper>
            <ActionButton
              clean
              icon={Icons.basic.x}
              onClick={() => this.closeModals()}
            />
          </DismissButtonWrapper>
          <ModalTitle>{title}</ModalTitle>
          <ModalList>{listItems}</ModalList>
        </ModalContent>
      </Modal>
    );
  }

  renderSingleListItem(item) {
    const { id, name, image, imageAlt, onClick } = item;
    return (
      <ModalListItem key={id} onClick={onClick}>
        <SendImage alt={imageAlt} src={image} />
        <ListItemName>{name}</ListItemName>
        <Icon src={Icons.basic.chevronRight} />
      </ModalListItem>
    );
  }

  renderConnectedWarningModal() {
    if (!this.state.connectedModeWarning || !this.props.printer) return null;

    return (
      <ConnectedWarningModal
        connectedModeWarning={this.state.connectedModeWarning}
        printer={this.props.printer}
        onConfirm={() => this.setState({ connectedModeWarning: null })}
      />
    );
  }

  renderStampWarningModal() {
    if (!this.state.stampWarning) return null;

    return (
      <StampWarningModal
        onCancel={() => this.setState({ stampWarning: false })}
        onConfirm={() => this.handleSlice()}
      />
    );
  }

  renderToolpathLegend() {
    const isToolpathVersionValid = this.isToolpathVersionValid();
    const isToolpathLoading = this.isToolpathLoading();

    if (!isToolpathVersionValid || isToolpathLoading) return null;

    return <ToolpathLegend toolpath={this.props.toolpath} />;
  }

  renderToolpathOptions() {
    const isToolpathVersionValid = this.isToolpathVersionValid();
    const isToolpathLoading = this.isToolpathLoading();

    return (
      <ToolpathOptions
        toolpath={this.props.toolpath}
        loading={isToolpathLoading}
        isVersionValid={isToolpathVersionValid}
        setToolpathViewType={this.props.setToolpathViewType}
        setToolpathShowTravel={this.props.setToolpathShowTravel}
        setToolpathShowRetracts={this.props.setToolpathShowRetracts}
        setToolpathShowRestarts={this.props.setToolpathShowRestarts}
      />
    );
  }

  renderChangeViewButton() {
    return <Button onClick={() => this.props.changeView('place')}>Back</Button>;
  }

  renderSendToPrintBar() {
    return (
      <SendToPrintBar>
        {this.renderSendToDevice()}
        {this.renderSendToButton()}
      </SendToPrintBar>
    );
  }

  renderSliceControlButtons() {
    return (
      <SliceControlButtons>
        {this.renderChangeViewButton()}
        {this.renderSliceButton()}
        {this.renderDownloadButton()}
      </SliceControlButtons>
    );
  }

  renderBottomButtons() {
    return (
      <BottomButtonsContainer>
        {this.renderSentToDeviceConfirmation()}
        {this.renderSentToPolarConfirmation()}
        {this.renderSliceControlButtons()}
        {this.renderSendToPrintBar()}
      </BottomButtonsContainer>
    );
  }

  toggleSummaryPanel() {
    this.setState({
      showSummaryContentMobile: !this.state.showSummaryContentMobile,
    });
  }

  togglePrintPreview() {
    this.setState({
      showPrintPreviewPanelMobile: !this.state.showPrintPreviewPanelMobile,
    });
  }

  renderSummaryPanel() {
    return (
      <SummaryPanelWrapper>
        <ToolCollapsiblePanel isCollapsed={false} label='Print Summary'>
          {this.renderPrintStats()}
        </ToolCollapsiblePanel>
      </SummaryPanelWrapper>
    );
  }

  renderSummaryPanelMobile() {
    if (!this.state.showSummaryContentMobile) return null;
    return (
      <MobilePanel
        header='Print Summary'
        icon={Icons.basic.chart}
        onClose={() => this.togglePrintPreview()}
        onOpen={() => this.togglePrintPreview()}>
        {this.renderPrintStats()}
      </MobilePanel>
    );
  }

  renderToolpathLegendAndOptions() {
    return (
      <LegendAndOptionsContainer>
        {this.renderToolpathOptions()}
        {this.renderToolpathLegend()}
      </LegendAndOptionsContainer>
    );
  }

  renderPrintPreviewMobile() {
    if (!this.state.showPrintPreviewPanelMobile) return null;
    return (
      <>
        <MobilePanel
          header='Print Preview'
          icon={Icons.basic.settings}
          onClose={() => this.toggleSummaryPanel()}
          onOpen={() => this.toggleSummaryPanel()}>
          {this.renderToolpathOptions()}
        </MobilePanel>
        {this.renderToolpathLegend()}
      </>
    );
  }

  renderSidebar() {
    return (
      <Sidebar>
        {this.renderSummaryPanel()}
        {this.renderToolpathLegendAndOptions()}
      </Sidebar>
    );
  }

  renderSideBarMobile() {
    return (
      <SideBarMobile>
        {this.renderSummaryPanelMobile()}
        {this.renderPrintPreviewMobile()}
      </SideBarMobile>
    );
  }

  render() {
    return (
      <>
        <HeaderBar />
        <Container>
          {this.renderConnectedWarningModal()}
          {this.renderStampWarningModal()}

          {this.renderSidebar()}
          {this.renderSideBarMobile()}
          {this.renderSliders()}

          {this.renderBottomButtons()}
        </Container>
      </>
    );
  }
}

export default withRouter(withTheme(SliceView));

import 'regenerator-runtime/runtime';
import { put, select } from 'redux-saga/effects';

import { getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';
import Helpers from '../../../reducers/slicer/helpers';
import { InterfaceUtils } from '../../../utils';

export default function* updateTowerFromServer(action) {
  try {
    const { projectId, tower: serverResponseTower } = action.payload;
    const tower = Helpers.formatTowerResponse(serverResponseTower);
    const slicerState = yield select(getSlicerState);
    const { transitionTower: currentTower, projects } = slicerState;
    const project = projects[projectId];
    if (!currentTower) {
      if (tower) {
        // tower was created that did not exist previously -- auto-position it
        yield put(actions.repositionTower(projectId, tower, project.style));
      }
      // else -- no tower before and no tower now -- do nothing
    } else if (tower) {
      // tower before and tower now -- only auto-position if size increased
      let currentSize = currentTower.size.x * currentTower.size.y;
      let newSize = tower.size.x * tower.size.y;
      if (currentTower.brims) {
        currentSize = currentTower.brims.size.x * currentTower.brims.size.y;
      }
      if (tower.brims) {
        newSize = tower.brims.size.x * tower.brims.size.y;
      }
      if (newSize > currentSize) {
        // new tower is larger -- auto-position it
        yield put(actions.repositionTower(projectId, tower, project.style));
      } else {
        // don't auto-position, but do update the store and scene
        yield put(actions.updateTower(tower));
      }
    } else {
      // tower before and no tower now -- let it be removed
      yield put(actions.updateTower(tower));
    }
  } catch (e) {
    InterfaceUtils.emitToast('error', e.message);
  }
}

import styled, { css } from 'styled-components';
import { Shadows, Breakpoints } from '../../themes';

export const Container = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s;
`;

export const ModalMargin = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.colors.overlay};
`;

export const Content = styled.div`
  position: relative;
  padding: ${(props) => (props.noPadding ? '0' : '1rem')};
  border-radius: 0.3rem;
  max-height: calc(100% - 10rem);
  height: ${(props) => props.cssHeight};
  width: ${(props) => props.cssWidth};
  flex-direction: column;
  text-align: left;
  overflow-y: ${(props) => (props.noScroll ? 'hidden' : 'auto')};
  background-color: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  color: ${(props) => props.theme.colors.textPrimary};
  ${Shadows.d3};

  @media (max-width: ${Breakpoints.mediumWidth}) {
    max-height: 100vh;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Body = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  text-align: left;
  max-width: 100%;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  padding: ${(props) => (props.addPadding ? '1rem' : '0')};
`;

export const defaultModalContentStyles = css`
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: repeat(4, min-content);
  grid-gap: 0.5rem;
  justify-items: start;
`;

export const defaultModalButtonStyles = css`
  grid-row: 5 / 6;
  grid-column: span 1;
  display: inline-block;
  justify-self: end;
`;

export const defaultModalLabelStyles = css`
  width: 100%;
  justify-content: flex-start;
  grid-column: span 2;
  text-align: left;
  white-space: normal;
`;

export const defaultModalInputStyles = css`
  grid-column: span 2;
  justify-self: stretch;
  margin-top: 0.5rem;
`;

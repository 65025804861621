import React, { Component } from 'react';
import _ from 'lodash';

import {
  ModalButtonWrapper,
  ModalInputWrapper,
  DropdownWrapper,
} from './newProjectOptions.styles';

import Button from '../button/button.jsx';
import Dropdown from '../dropdown/dropdown.jsx';
import Input from '../input/input.jsx';
import Modal from '../modal/modal.jsx';
import ModalHeader from '../modal/modalHeader.jsx';
import ModalBody from '../modal/modalBody.jsx';
import ModalFooter from '../modal/modalFooter.jsx';
import DeviceDropdown from '../deviceDropdown/deviceDropdown.jsx';

import {
  InterfaceUtils,
  MaterialUtils,
  PrinterUtils,
  SortUtils,
} from '../../utils';
import { slicers, slicerExperimentalStatus } from '../../constants';

import { Subtitle1 } from '../typography/typography';

class NewProjectModal extends Component {
  static defaultProps = {
    printers: {},
    materials: {},
  };

  constructor(props) {
    super(props);

    // resolve values of 'auto' for printerId and styleId, if necessary
    const { printerId, styleId } = PrinterUtils.getDefaultPrinterAndStyle(
      props.printerId,
      props.styleId,
      props.printers
    );
    // override device config is printer is Element
    let { deviceConfig } = props;
    if (props.printers[printerId].machineSettings.extension === 'mcfx') {
      deviceConfig = null;
    }

    this.state = {
      focusNameField: false,
      name: 'New Project',
      nameError: false,
      slicer: props.slicer,
      printerId,
      styleId,
      deviceConfig,
      materialId: props.materialId,
    };
  }

  focusNameField() {
    this.setState({ focusNameField: true }, () => {
      this.setState({ focusNameField: false });
    });
  }

  onNameChangeSuccess(value) {
    this.setState({
      name: value,
      nameError: false,
    });
  }

  onNameChangeFailure() {
    this.setState({
      nameError: true,
    });
  }

  onSave() {
    const {
      nameError,
      name,
      slicer,
      printerId,
      deviceConfig,
      styleId,
      materialId,
    } = this.state;
    if (nameError) {
      this.focusNameField();
    } else {
      const projectOptions = {
        name,
        slicer,
        printerId,
        deviceConfig,
        styleId,
        materialId,
      };
      this.props.onConfirm(projectOptions);
    }
  }

  onPrinterChange(value) {
    const { printers } = this.props;
    const updatedState = {
      printerId: value,
    };
    const printer = printers[value];
    const mostRecentPrinterStyle = PrinterUtils.getMostRecentStyle(printer);

    if (mostRecentPrinterStyle) {
      updatedState.styleId = mostRecentPrinterStyle.id;
    } else {
      updatedState.styleId = 'auto';
    }

    if (printers[value].machineSettings.extension === 'mcfx') {
      updatedState.deviceConfig = null;
    }

    this.setState(updatedState, () => {
      if (!mostRecentPrinterStyle) {
        const msg = `${printer.machineSettings.name} has no style profiles`;
        InterfaceUtils.emitToast('warn', msg);
      }
    });
  }

  renderPrinterDropdown() {
    const { printers } = this.props;

    const printerOptions = _.map(
      PrinterUtils.sortPrinters(printers),
      (printer) => ({
        label: printer.machineSettings.name,
        value: printer.id,
        disabled: printer.styles.length === 0,
      })
    );

    return (
      <DropdownWrapper>
        <Dropdown
          allowFilter
          label='Printer profile'
          options={printerOptions}
          value={this.state.printerId}
          onChange={(value) => {
            this.onPrinterChange(value);
          }}
        />
      </DropdownWrapper>
    );
  }

  renderDeviceDropdown() {
    const { printers } = this.props;
    const { printerId, deviceConfig } = this.state;
    const isElementSelected =
      printers[printerId].machineSettings.extension === 'mcfx';
    if (isElementSelected) return null;

    return (
      <DropdownWrapper>
        <DeviceDropdown
          deviceConfig={deviceConfig}
          onChange={(selectedDeviceConfig) => {
            this.setState({ deviceConfig: selectedDeviceConfig });
          }}
        />
      </DropdownWrapper>
    );
  }

  renderStyleDropdown() {
    const { printers } = this.props;
    const printerStyles = printers[this.state.printerId]
      ? printers[this.state.printerId].styles
      : null;
    const styleOptions = _.map(
      SortUtils.sortByName(printerStyles),
      (style) => ({
        label: style.name,
        value: style.id,
      })
    );

    return (
      <DropdownWrapper>
        <Dropdown
          disabled={!this.state.styleId || this.state.styleId === 'auto'}
          value={this.state.styleId}
          label='Style profile'
          options={styleOptions}
          onChange={(value) => {
            this.setState({ styleId: value });
          }}
        />
      </DropdownWrapper>
    );
  }

  renderSlicerDropdown() {
    const slicerOptions = Object.entries(slicers).map(([label, value]) => ({
      label,
      subLabel: slicerExperimentalStatus[value] ? '(experimental)' : undefined,
      value,
    }));
    return (
      <DropdownWrapper>
        <Dropdown
          value={this.state.slicer}
          label='Slicer'
          options={slicerOptions}
          onChange={(value) => {
            this.setState({ slicer: value });
          }}
        />
      </DropdownWrapper>
    );
  }

  renderMaterialDropdown() {
    const materialOptions = _.map(
      SortUtils.sortMaterialProfilesByType(this.props.materials),
      (material) => ({
        label: MaterialUtils.getListLabel(material),
        value: material.id,
      })
    );
    return (
      <DropdownWrapper>
        <Dropdown
          allowFilter
          value={this.state.materialId}
          label='Material profile'
          options={materialOptions}
          onChange={(value) => {
            this.setState({ materialId: value });
          }}
        />
      </DropdownWrapper>
    );
  }

  renderCancelButton() {
    return (
      <ModalButtonWrapper>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            this.props.onCancel();
          }}>
          Cancel
        </Button>
      </ModalButtonWrapper>
    );
  }

  renderSaveButton() {
    const { printerId, styleId } = this.state;
    let disabled = false;
    if (!printerId || !styleId || printerId === 'auto' || styleId === 'auto') {
      disabled = true;
    }
    return (
      <ModalButtonWrapper>
        <Button
          primary
          disabled={disabled}
          title='Create'
          onClickKeyTrigger='Enter'
          onClick={() => this.onSave()}>
          Create
        </Button>
      </ModalButtonWrapper>
    );
  }

  render() {
    return (
      <Modal>
        <ModalHeader>
          <Subtitle1>Create new project</Subtitle1>
        </ModalHeader>
        <ModalBody>
          <ModalInputWrapper>
            <Input
              type='text'
              label='Project name'
              autoFocus
              forceFocus={this.state.focusNameField}
              value={this.state.name}
              onChangeSuccess={(value) => this.onNameChangeSuccess(value)}
              onChangeFailure={() => this.onNameChangeFailure()}
            />
          </ModalInputWrapper>
          {this.renderSlicerDropdown()}
          {this.renderPrinterDropdown()}
          {this.renderStyleDropdown()}
          {this.renderDeviceDropdown()}
          {this.renderMaterialDropdown()}
        </ModalBody>
        <ModalFooter>
          {this.renderCancelButton()}
          {this.renderSaveButton()}
        </ModalFooter>
      </Modal>
    );
  }
}

export default NewProjectModal;

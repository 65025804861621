import { connect } from 'react-redux';

import TranslationContents from './translation.jsx';

import { actions } from '../../../../../reducers/slicer/slicer';

import { ProjectUtils, SlicerUtils } from '../../../../../utils';

const mapStateToProps = (state) => {
  const { transitionTower } = state.slicer;
  const selectedTower =
    transitionTower && transitionTower.active ? transitionTower : null;
  const selectedModels = SlicerUtils.getSelectedModels(state.slicer.models);

  const project = state.slicer.projects[state.slicer.currentProject];
  const printer = ProjectUtils.getProjectPrinter(
    project,
    state.printers.printers
  );

  return {
    selectedModels,
    selectedTower,
    bedSize: printer.machineSettings.bedSize,
    originOffset: printer.machineSettings.originOffset,
    pivot: state.three.gizmoCenter,
    updateGizmoPending: state.three.status.updateGizmoPending,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateModelTransforms: (transformData) => {
    dispatch(actions.updateModelTransformsRequest(transformData));
  },
  setTowerPosition: (position) => {
    dispatch(actions.updateTowerPositionOrSizeRequest(position, 'position'));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslationContents);

import React from 'react';
import { AbstractInput } from '../../../../../../../shared';
import {
  TargetTemperatureContainer,
  TargetTemperatureInput,
  TargetTemperatureUnits,
} from './onClickActivationInput.styles';

function OnClickActivationInput(props) {
  return (
    <AbstractInput
      {...props}
      StyledInput={TargetTemperatureInput}
      StyledContainer={TargetTemperatureContainer}
      StyledUnitLabelWrapper={TargetTemperatureUnits}
    />
  );
}
export default OnClickActivationInput;

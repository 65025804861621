import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API from '../canvas-api';
import { handleGenericError } from '../common';
import { actions } from '../../reducers/printers/printers';

export default function* getPrinterTag(action) {
  const { tagId } = action.payload;
  try {
    const response = yield call(API, {
      path: `printers/tags/${tagId}`,
    });

    if (response === null) return;

    const { printerTag } = response;

    yield put(actions.getPrinterTagSuccess(printerTag));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import API from '../../canvas-api';
import { getAuthState, handleGenericError } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

export default function* getSliceJobs(action) {
  const { userId } = yield select(getAuthState);
  try {
    const response = yield call(API, {
      path: `slice/jobs/user/${userId}`,
    });
    if (response === null) return;

    // convert to map
    const sliceJobs = {};
    _.forEach(response.sliceJobs, (job) => {
      sliceJobs[job.id] = {
        name: job.name,
        timestamp: job.timestamp,
        progress: null,
      };
    });

    yield put(actions.getSliceJobsSuccess(sliceJobs));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { actions } from '../../../reducers/slicer/slicer';
import InputRemapperModal from './inputRemapperModal.jsx';

const mapStateToProps = (state) => ({
  remapProjectInputsPending: state.slicer.status.remapProjectInputsPending,
});

const mapDispatchToProps = (dispatch) => ({
  remapProjectInputs: (projectId, inputMap, reloadRequired) =>
    dispatch(
      actions.remapProjectInputsRequest(projectId, inputMap, reloadRequired)
    ),
  updateProjectSettings: (projectId, settings, reloadRequired = false) =>
    dispatch(
      actions.updateProjectSettingsRequest(projectId, settings, reloadRequired)
    ),
  clearInputRemapperContext: () =>
    dispatch(actions.clearInputRemapperContext()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InputRemapperModal)
);

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  cursor: pointer;
`;

export const JobInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  gap: 0.25rem;
  justify-content: center;
`;

export const CaptionWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const ProgressRingContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const TimeContainer = styled.span`
  white-space: nowrap;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  padding: 0.4rem;
  background: transparent;
  border-radius: 50%;
  color: ${(props) => props.theme.colors.greenDefault};
  transition: background 0.2s;

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: auto;
      background: ${props.theme.colors.elevatedBackgroundTertiary};
    `};
`;

import React, { Component } from 'react';

import { withTheme } from 'styled-components';
import { ActionStyledButton, DisplayContainer } from './actionButton.styles';

import Icon from '../icon/icon.jsx';
import ToolTipWrapper from '../toolTipWrapper/toolTipWrapper.jsx';

const sizes = {
  MINIMUM: '1.5rem',
  NORMAL: '2.5rem',
};

class ActionButton extends Component {
  static sizes = sizes;

  static defaultProps = {
    tooltipProps: {},
    icon: '',
    title: '',
    warning: false,
    primary: false,
    disabled: false,
    show: true,
    clean: false,
    minimal: false,
    iconColor: '',
    noRadius: false,
    onClick: () => {},
  };

  handleClick(e) {
    if (this.props.disabled) return;
    this.props.onClick(e);
  }

  iconColor() {
    if (this.props.disabled) return this.props.theme.colors.grey4;
    if (this.props.iconColor) return this.props.iconColor;
    if (this.props.primary) return this.props.theme.colors.whiteDefault;
    if (this.props.warning) return this.props.theme.colors.whiteDefault;
    return '';
  }

  renderButton() {
    return (
      <ActionStyledButton
        warning={this.props.warning}
        primary={this.props.primary}
        disabled={this.props.disabled}
        clean={this.props.clean}
        minimal={this.props.minimal}
        size={this.props.minimal ? sizes.MINIMUM : sizes.NORMAL}
        noRadius={this.props.noRadius}
        onClick={(e) => this.handleClick(e)}>
        <Icon src={this.props.icon} color={this.iconColor()} />
      </ActionStyledButton>
    );
  }

  render() {
    return (
      <DisplayContainer show={this.props.show}>
        <ToolTipWrapper tooltip={this.props.title}>
          {this.renderButton()}
        </ToolTipWrapper>
      </DisplayContainer>
    );
  }
}

export default withTheme(ActionButton);

import 'regenerator-runtime/runtime';
import { call, select, put } from 'redux-saga/effects';

import { getThreeState, handleGenericError } from '../common';
import actions from '../../reducers/three/actions';
import { setRenderFlag } from './animationFrame';

export default function* addBoundaries(action) {
  try {
    const { scene, boundaries: existingBoundaries } = yield select(
      getThreeState
    );
    const { boundaries } = action.payload;
    if (existingBoundaries) {
      scene.remove(existingBoundaries);
    }
    scene.add(boundaries);
    setRenderFlag();
    yield put(actions.addBoundariesSuccess(boundaries));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import React from 'react';

import { Body1 } from '../../../../shared/typography/typography';
import { Image } from '../../../../shared';

const metadata = {
  title: 'Welcome to Canvas',
  content: (theme) => (
    <>
      <Body1 noSpacing>
        Canvas is modern 3D printing software designed for multi-color,
        multi-material printing. You can use Canvas to slice, digitally paint,
        and control your Internet-connected devices.
      </Body1>
      <Image
        alt='Welcome to canvas'
        src={theme.images.onboarding.welcomeToCanvas}
      />
    </>
  ),
};

export default metadata;

import styled, { css } from 'styled-components';

// container circle
const SwatchSize = 2.25;
const smallSwatchSize = 1.75;
// select ring
const ringSize = 1.625;
const smallRingSize = 1.25;
// accent colors
export const lightTextColor = '#FFFFFF';
export const darkTextColor = '#AEAEB2';

function getSwatchSize(small) {
  return css`
    height: ${small ? smallSwatchSize : SwatchSize}rem;
    width: ${small ? smallSwatchSize : SwatchSize}rem;
  `;
}

export const RingWrapper = styled.div`
  position: absolute;
  ${(props) => getSwatchSize(props.small)}
  ${(props) => props.disableHover && 'display: none;'}
`;

export const Ring = styled.svg`
  ${(props) => getSwatchSize(props.small)}

  circle {
    ${(props) => getSwatchSize(props.small)}
    cx: ${(props) => (props.small ? smallSwatchSize : SwatchSize) / 2}rem;
    cy: ${(props) => (props.small ? smallSwatchSize : SwatchSize) / 2}rem;
    r: ${(props) => (props.small ? smallRingSize : ringSize) / 2}rem;
    stroke-width: ${(props) => (props.small ? 2 : 3)}px;
    ${(props) =>
      props.selected &&
      `stroke: rgba( ${
        props.bright ? '174,174,178' : '255, 255, 255'
      }, 100% );`}
  }

  &:hover {
    circle {
      ${(props) =>
        !props.disabled &&
        `stroke: rgba(
        ${props.bright ? '174,174,178,' : '255, 255, 255,'}
          ${props.selected ? 100 : 50}%
      );`}
    }
  }
`;

const elementCircle = css`
  &::after {
    content: '';
    position: absolute;
    left: ${(props) => (props.small ? 1.125 : 1.625)}rem;
    bottom: ${(props) => (props.small ? 1.125 : 1.625)}rem;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    background-color: ${darkTextColor};
    border: 2px solid rgba(255, 255, 255, 100%);
  }
`;

export const StaticSwatch = styled.div`
  position: relative;
  ${(props) =>
    props.bright &&
    props.theme.name !== 'dark' &&
    `border: 1px solid ${darkTextColor};`}
  box-sizing: border-box;
  border-radius: 50%;
  ${(props) => getSwatchSize(props.small)}

  background-color: ${(props) => props.swatchColor};
  cursor: ${(props) =>
    props.interactive && !props.disabled ? 'pointer' : 'default'};
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;

  text-align: center;
  color: ${(props) => (props.bright ? darkTextColor : lightTextColor)};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  transition: visibility 0.2s;

  span {
    visibility: ${(props) =>
      props.selected || props.forceShowLabel ? 'visible' : 'hidden'};
  }

  &:hover {
    span {
      visibility: visible;
    }
  }

  ${(props) => props.element && elementCircle}
`;

export const MovingSwatch = styled.div.attrs((props) => ({
  style: {
    top: `${props.coord.y - 20 / 2}px`,
    left: `${props.coord.x - 20 / 1.25}px`,
  },
}))`
  z-index: 2;
  position: ${(props) => (props.isMoving ? 'fixed' : 'static')};
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
  box-sizing: border-box;
  ${(props) =>
    props.bright &&
    props.theme.name !== 'dark' &&
    `border: 1px solid ${darkTextColor};`}
  background-color: ${(props) => props.swatchColor};
  cursor: pointer;
  pointer-events: none;
  touch-action: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

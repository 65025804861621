import _ from 'lodash';

const TOPIC_PREFIX = process.env.REACT_APP_IOT_TOPIC_NAMESPACE;

const DEVICE_TOPIC_PREFIX = `${TOPIC_PREFIX}/devices`;

export const DEVICE_ORIGIN_NAME = 'simcoe';

const allUsersTopic = `${TOPIC_PREFIX}/users`;

const singleUserTopic = (userId) => `${TOPIC_PREFIX}/users/${userId}`;

export const singleDeviceTopic = (deviceId) =>
  `${DEVICE_TOPIC_PREFIX}/${deviceId}`;

// all broadcasts from a device
export const getDeviceBroadcastTopicFilter = (deviceId) =>
  `${singleDeviceTopic(deviceId)}/${DEVICE_ORIGIN_NAME}/broadcast/#`;

export const getUserTopicFilters = (userId, deviceIds) => [
  allUsersTopic,
  singleUserTopic(userId),
  ..._.map(deviceIds, getDeviceBroadcastTopicFilter),
];

import styled from 'styled-components';

import { Breakpoints, Shadows } from '../../../themes';

export const Container = styled.div`
  margin: auto 0;
  background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  ${Shadows.d1};
  border-radius: 0.2em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 1em;
`;

export const DeviceSection = styled.div`
  margin-top: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 4px;
  padding: 2em 1em;

  @media (max-width: ${Breakpoints.mediumWidth}) {
    grid-template-columns: 1fr;
  }
  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    display: flex;
    flex-direction: column;
  }
`;

export const AccessorySection = styled.div`
  margin-top: 1em;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 4px;
  padding: 1em;
`;

export const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DeviceIcons = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const DeviceLabel = styled.div`
  margin-top: 0.2em;

  label {
    white-space: normal;
  }
`;

export const CharacterInputs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5em;
`;

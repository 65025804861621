import React, { useState } from 'react';

import {
  ActionButtonContainer,
  HeaderContainer,
  Panel,
  Header,
  Content,
} from './mobilePanel.styles';

import { ActionButton, Subtitle2 } from '../../../shared';
import { Icons } from '../../../themes';

const MobilePanel = ({
  header,
  icon,
  children,
  onOpen = () => {},
  onClose = () => {},
}) => {
  const [showPanel, setShowPanel] = useState(false);

  const renderHeader = () => (
    <HeaderContainer>
      <Header>
        <Subtitle2>{header}</Subtitle2>
      </Header>
      <ActionButton
        minimal
        title='Close'
        icon={Icons.basic.x}
        onClick={() => {
          onClose();
          setShowPanel(false);
        }}
      />
    </HeaderContainer>
  );

  const renderActionButton = () => (
    <ActionButtonContainer>
      <ActionButton
        title={header}
        icon={icon}
        onClick={() => {
          onOpen();
          setShowPanel(true);
        }}
      />
    </ActionButtonContainer>
  );

  const renderPanel = () => (
    <Panel>
      {renderHeader()}
      <Content>{children}</Content>
    </Panel>
  );

  if (showPanel) return renderPanel();
  return renderActionButton();
};

export default MobilePanel;

import { combineReducers } from 'redux';

import auth from './auth/auth';
import onboarding from './onboarding/onboarding';
import slicer from './slicer/slicer';
import three from './three/three';
import printers from './printers/printers';
import nav from './nav/nav';
import notifications from './notifications/notifications';
import iot from './iot/iot';

const reducers = combineReducers({
  auth,
  onboarding,
  slicer,
  three,
  printers,
  nav,
  notifications,
  iot,
});

export default reducers;

import { call, put } from 'redux-saga/effects';
import { makeRequest } from './horizon';

import { actions } from '../../reducers/iot/iot';
import { handleGenericError } from '../common';

export default function* webRtcKeepAlive(action) {
  try {
    const { deviceId, sessionId } = action.payload;
    yield call(
      makeRequest,
      deviceId,
      'webcam/keep-alive',
      {
        method: 'post',
        timeoutMs: 10 * 1000,
        query: {
          sessionId,
        },
      },
      'webRtcKeepAlive'
    );
    yield put(actions.webRtcKeepAliveSuccess(deviceId));
  } catch (err) {
    yield call(handleGenericError, action, err);
  }
}

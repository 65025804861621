const slicers = {
  KISSlicer: 'kisslicer',
  Slic3r: 'slic3r',
};

export default slicers;

export const slicerBadgeLabels = {
  [slicers.KISSlicer]: 'KS',
  [slicers.Slic3r]: 'Slic3r',
};

export const slicerExperimentalStatus = {
  [slicers.KISSlicer]: false,
  [slicers.Slic3r]: true,
};

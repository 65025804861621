import React, { Component } from 'react';
import _ from 'lodash';

import {
  SelectionPanels,
  MakersPanel,
  ModelsPanel,
  PanelList,
  PanelListItem,
  ButtonContainer,
  SpinnerContainer,
} from './selectPreset.styles';

import {
  Button,
  H6,
  Icon,
  LoadingOverlay,
  Progress,
  Subtitle2,
  Modal,
} from '../../../shared';

import Icons from '../../../themes/icons';
import Routes from '../../../router/routes';

class SelectPreset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedManufacturerId: '',
      selectedPresetId: '',
      createdPrinterId: '',
    };
  }

  componentDidMount() {
    const {
      getPrinterPresetsPending,
      getPrinterPresetsSuccess,
      getPrinterPresets,
    } = this.props;
    // load printer presets if not already loaded
    if (!getPrinterPresetsPending && !getPrinterPresetsSuccess) {
      getPrinterPresets();
    }
  }

  componentDidUpdate(prevProps) {
    const { printers, createPrinterPending, createPrinterSuccess } = this.props;
    const printerCreated =
      prevProps.createPrinterPending &&
      !createPrinterPending &&
      createPrinterSuccess;
    if (printerCreated) {
      const createdPrinter = _.find(
        printers,
        (printer) => !prevProps.printers[printer.id]
      );
      this.setState({ createdPrinterId: createdPrinter.id }, () => {
        this.createPrinterStyles();
      });
    }

    const printerStylesCreated =
      prevProps.createStylesPending &&
      !this.props.createStylesPending &&
      this.props.createStylesSuccess;
    if (printerStylesCreated) {
      this.handleRedirectToCreatedPrinter();
    }
  }

  handleManufacturerSelection(selectedManufacturerId) {
    if (selectedManufacturerId !== this.state.selectedManufacturerId) {
      this.setState({
        selectedManufacturerId,
        selectedPresetId: '',
      });
    }
  }

  handlePresetSelection(selectedPresetId) {
    this.setState({ selectedPresetId });
  }

  getSelectedPreset() {
    const { presets } = this.props;
    const { selectedPresetId } = this.state;
    return presets[selectedPresetId];
  }

  handleCreateClick() {
    const preset = this.getSelectedPreset();
    const sanitized = _.omit(preset.machineSettings, 'timestamp');

    const printerData = {
      machineSettings: sanitized,
      modded: preset.modded || false,
    };

    // also include tag id, if available
    if (preset.tagId) {
      printerData.tagId = preset.tagId;
    }

    this.props.createPrinter(printerData);
  }

  createPrinterStyles() {
    const { createdPrinterId } = this.state;
    const preset = this.getSelectedPreset();
    this.props.createStyleProfiles(createdPrinterId, preset.styles);
  }

  handleRedirectToCreatedPrinter() {
    const { createdPrinterId } = this.state;
    this.props.history.push(Routes.toViewPrinter(createdPrinterId));
  }

  renderHeader() {
    return <H6>Printer Presets</H6>;
  }

  renderSelectionPanels() {
    return (
      <SelectionPanels>
        {this.renderPrinterManufacturersPanel()}
        <Icon src={Icons.basic.chevronRight} />
        {this.renderPrinterModelsPanel()}
      </SelectionPanels>
    );
  }

  renderPrinterManufacturersPanel() {
    const { selectedManufacturerId } = this.state;
    const { presets, getPrinterPresetsPending, getPrinterPresetsSuccess } =
      this.props;
    let makersList = (
      <SpinnerContainer>
        <Progress circular />
      </SpinnerContainer>
    );

    if (!getPrinterPresetsPending && getPrinterPresetsSuccess) {
      const manufacturers = {};
      _.forEach(presets, (preset) => {
        if (!manufacturers[preset.manufacturerId]) {
          manufacturers[preset.manufacturerId] = preset.manufacturer;
        }
      });
      const sortedManufacturers = _.sortBy(
        _.map(manufacturers, (manufacturerName, manufacturerId) => ({
          id: manufacturerId,
          name: manufacturerName,
        })),
        (entry) => entry.name.toLowerCase()
      );

      makersList = _.map(sortedManufacturers, ({ id, name }) => {
        const isSelected = selectedManufacturerId === id;
        return (
          <PanelListItem
            key={id}
            onClick={() => this.handleManufacturerSelection(id)}
            isSelected={isSelected}>
            {name}
          </PanelListItem>
        );
      });
    }
    return (
      <MakersPanel>
        <Subtitle2>Select printer make</Subtitle2>
        <PanelList>{makersList}</PanelList>
      </MakersPanel>
    );
  }

  renderPrinterModelsPanel() {
    const { presets } = this.props;
    const { selectedManufacturerId, selectedPresetId } = this.state;
    let modelsList = [];
    if (selectedManufacturerId) {
      const manufacturerModels = _.sortBy(
        _.filter(
          presets,
          (preset) => preset.manufacturerId === selectedManufacturerId
        ),
        (preset) => preset.machineSettings.name.toLowerCase()
      );
      modelsList = _.map(manufacturerModels, (preset) => {
        const presetId = preset.id;
        const { name } = preset.machineSettings;
        const isSelected = selectedPresetId === presetId;
        return (
          <PanelListItem
            key={presetId}
            onClick={() => this.handlePresetSelection(presetId)}
            isSelected={isSelected}>
            {name}
          </PanelListItem>
        );
      });
    }
    return (
      <ModelsPanel>
        <Subtitle2>Select printer model</Subtitle2>
        <PanelList isDisabled={!selectedManufacturerId}>{modelsList}</PanelList>
      </ModelsPanel>
    );
  }

  renderCancelButton() {
    return (
      <Button onClick={() => this.props.onCloseSelectPresetModal()}>
        Cancel
      </Button>
    );
  }

  renderNextButton() {
    const { selectedManufacturerId, selectedPresetId } = this.state;
    const isDisabled = !selectedManufacturerId || !selectedPresetId;
    return (
      <Button
        primary
        disabled={isDisabled}
        onClick={() => this.handleCreateClick()}>
        Create
      </Button>
    );
  }

  renderButtons() {
    return (
      <ButtonContainer>
        {this.renderCancelButton()}
        {this.renderNextButton()}
      </ButtonContainer>
    );
  }

  renderMainPanelContent() {
    return (
      <Modal width='40rem'>
        {this.renderHeader()}
        {this.renderSelectionPanels()}
        {this.renderButtons()}
      </Modal>
    );
  }

  renderLoadingOverlay() {
    const { createPrinterPending, createStylesPending } = this.props;
    if (createPrinterPending || createStylesPending) {
      return <LoadingOverlay label='Creating printer' />;
    }
    return null;
  }

  render() {
    return (
      <>
        {this.renderLoadingOverlay()}
        {this.renderMainPanelContent()}
      </>
    );
  }
}

export default SelectPreset;

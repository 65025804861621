import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 1px dashed ${(props) => props.theme.colors.grey4};
`;

export const UploadButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 2rem;
  cursor: pointer;
  gap: 0.5rem;

  input[type='file'] {
    display: none;
    pointer-events: none;
  }

  &:hover {
    background: ${(props) => props.theme.colors.elevatedBackgroundSecondary};
  }

  &:first-of-type {
    border-right: 1px dashed ${(props) => props.theme.colors.grey4};
  }
`;

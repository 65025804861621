import React from 'react';
import _ from 'lodash';
import { Caption } from '../../shared/typography/typography';
import { UL } from '../../shared/lists/lists';

const hasAtLeastNCharacterTypes = (password, n) => {
  const includes = {
    uppercase: false,
    lowercase: false,
    digits: false,
    symbols: false,
  };

  let hasControlChars = false;
  let hasBackslash = false;
  _.forEach(password, (char, index) => {
    const charCode = password.charCodeAt(index);
    if (charCode < 32 || charCode >= 127) {
      hasControlChars = true; // don't allow control characters
    }
    if (charCode === 92) {
      hasBackslash = true; // don't allow backslashes
    }
    if (charCode >= 65 && charCode <= 90) {
      includes.uppercase = true;
    } else if (charCode >= 97 && charCode <= 122) {
      includes.lowercase = true;
    } else if (charCode >= 48 && charCode <= 57) {
      includes.digits = true;
    } else {
      includes.symbols = true;
    }
  });
  if (hasControlChars || hasBackslash) return false;

  let includeCount = 0;
  if (includes.uppercase) includeCount++;
  if (includes.lowercase) includeCount++;
  if (includes.digits) includeCount++;
  if (includes.symbols) includeCount++;
  return includeCount >= n;
};

const validators = {
  // validator related contants
  defaultMaxFieldValue: 10000,

  // validators
  isBool: (input) => _.isBoolean(input),
  isString: (input) => _.isString(input),
  isFloat: (input) => _.isNumber(input),
  isInt: (input) => _.isInteger(input),
  isEmail: (input) => {
    if (typeof input !== 'string') return false;
    if (_.isEmpty(input)) return false;
    if (input.trim() !== input) return false;
    const emailRegex =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return emailRegex.test(input);
  },
  isUsername: (input) => {
    if (typeof input !== 'string') return false;
    if (input.length < 5) return false;
    if (/[^A-Za-z0-9-._]/.test(input)) return false;
    return true;
  },
  isUsernameWithReason: (input) => {
    if (typeof input !== 'string') return false;
    if (input.length < 5) {
      return {
        isValid: false,
        reason: 'Enter a longer username.',
      };
    }
    if (/[^A-Za-z0-9-._]/.test(input)) {
      return {
        isValid: false,
        reason: 'Username contains invalid characters.',
      };
    }
    return true;
  },
  isPassword: (input) => {
    if (typeof input !== 'string') return false;
    if (input.length < 8) return false;
    const pLower = input.toLowerCase();
    if (pLower.includes('password')) return false;
    return hasAtLeastNCharacterTypes(input, 2);
  },
  isPasswordWithReason: (input) => {
    if (typeof input !== 'string') {
      return {
        isValid: false,
        reason: 'Password is not a string.',
      };
    }
    if (input.length < 8) {
      return {
        isValid: false,
        reason: 'Enter a longer password.',
      };
    }
    const pLower = input.toLowerCase();
    if (pLower.includes('password')) {
      return {
        isValid: false,
        reason: 'Passwords cannot contain the word "password".',
      };
    }
    if (!hasAtLeastNCharacterTypes(input, 2)) {
      return {
        isValid: false,
        reason: (
          <>
            <Caption noSpacing>Passwords must contain at least two of:</Caption>
            <UL>
              <li>
                <Caption noSpacing>uppercase letters</Caption>
              </li>
              <li>
                <Caption noSpacing>lowercase letters</Caption>
              </li>
              <li>
                <Caption noSpacing>numbers</Caption>
              </li>
              <li>
                <Caption noSpacing>symbols</Caption>
              </li>
            </UL>
          </>
        ),
      };
    }
    return true;
  },
  confirmPasswordWithReason: (password, passwordToMatch) => {
    if (password !== passwordToMatch) {
      return {
        isValid: false,
        reason: 'Passwords do not match.',
      };
    }
    return true;
  },
  getNextFormErrorName: (fieldsMeta, errorsState) =>
    _.reduce(
      fieldsMeta,
      (err, field, key) => {
        const fieldName = field.name || key;
        if (err) return err;
        if (errorsState[fieldName]) return fieldName;
        return null;
      },
      null
    ),
};

export default validators;

import React, { Component } from 'react';
import _ from 'lodash';

import {
  DraggableCardGrid,
  DraggableCardList,
  ListViewContainer,
  GridViewContainer,
  ProjectCardNamesList,
  ProjectCardDatesList,
  GridViewContent,
  GridViewInfoContainer,
  GridViewThumbnailContainer,
  GridViewNamesContainer,
} from './projectCard.styles';

import ProjectThumbnail from '../projectThumbnail/projectThumbnail.jsx';

import {
  Body1OneLine,
  CaptionOneLine,
  Caption,
  DraggableCard,
  ShareMark,
  Subtitle1OneLine,
} from '../../../shared';

import { FormatUtils } from '../../../utils';

export default class ProjectCard extends Component {
  shouldComponentUpdate(nextProps) {
    if (
      !_.isEqual(nextProps.project, this.props.project) ||
      !_.isEqual(nextProps.listView, this.props.listView) ||
      !_.isEqual(nextProps.thumbnailImage, this.props.thumbnailImage) ||
      !_.isEqual(nextProps.selectedProjectIds, this.props.selectedProjectIds)
    )
      return true;
    return false;
  }

  renderShareMark(currentlyShared, isSelected, listView, project) {
    if (!currentlyShared) return null;
    const { shareData, modified } = project;
    const updated = modified > shareData.dateShared;
    return (
      <ShareMark
        positionTop={listView ? 'auto' : undefined}
        updated={updated}
        selected={isSelected}
      />
    );
  }

  renderThumbnail(project) {
    const { thumbnailImage, listView } = this.props;
    return (
      <ProjectThumbnail
        project={project}
        thumbnailImage={thumbnailImage}
        listView={listView}
        loadThumbnail={(projectId) => this.props.loadThumbnail(projectId)}
      />
    );
  }

  renderGridViewNames(projectName, printerName) {
    return (
      <GridViewNamesContainer>
        <Subtitle1OneLine noSpacing>{projectName}</Subtitle1OneLine>
        <Body1OneLine grey noSpacing>
          {printerName}
        </Body1OneLine>
      </GridViewNamesContainer>
    );
  }

  renderGridViewLastModifiedDate(lastModified) {
    return (
      <CaptionOneLine grey noSpacing>
        {lastModified}
      </CaptionOneLine>
    );
  }

  renderGridViewCreatedDate(dateCreated) {
    return (
      <Body1OneLine grey noSpacing>
        {dateCreated}
      </Body1OneLine>
    );
  }

  renderGridViewContent(project, printerName, lastModified, dateCreated) {
    return (
      <GridViewContent>
        <GridViewInfoContainer>
          {this.renderGridViewLastModifiedDate(lastModified)}
          {this.renderGridViewNames(project.name, printerName)}
          {this.renderGridViewCreatedDate(dateCreated)}
        </GridViewInfoContainer>

        <GridViewThumbnailContainer>
          {this.renderThumbnail(project)}
        </GridViewThumbnailContainer>
      </GridViewContent>
    );
  }

  renderDraggableCardGrid(props) {
    const StyledContainer = DraggableCardGrid;
    return <DraggableCard {...props} StyledContainer={StyledContainer} />;
  }

  renderListViewContent(
    project,
    isShared,
    printerName,
    lastModified,
    dateCreated
  ) {
    return (
      <>
        {this.renderThumbnail(project)}
        <ProjectCardNamesList>
          <Body1OneLine noSpacing>{project.name}</Body1OneLine>
          <Caption noSpacing grey>
            {printerName}
          </Caption>
        </ProjectCardNamesList>
        <ProjectCardDatesList isShared={isShared}>
          <Caption grey noSpacing>
            {lastModified}
          </Caption>
          <Caption grey noSpacing>
            Created {dateCreated}
          </Caption>
        </ProjectCardDatesList>
      </>
    );
  }

  renderDraggableCardList(props) {
    const StyledContainer = DraggableCardList;
    return <DraggableCard {...props} StyledContainer={StyledContainer} />;
  }

  renderContent(isShared, project) {
    const lastModified = `Last edited ${FormatUtils.isoToRelativeTime(
      project.modified
    )} ago`;
    const dateCreated = `${FormatUtils.isoToDateString(project.created)}`;
    const printer = this.props.printers[project.printerId];
    const printerName = printer.machineSettings.name;
    return (
      <>
        {this.props.listView &&
          this.renderListViewContent(
            project,
            isShared,
            printerName,
            lastModified,
            dateCreated
          )}
        {!this.props.listView &&
          this.renderGridViewContent(
            project,
            printerName,
            lastModified,
            dateCreated
          )}
      </>
    );
  }

  render() {
    const { listView, project, selectedProjectIds } = this.props;
    const isSelected = _.includes(selectedProjectIds, project.id);
    const isShared = project.shareData && project.shareData.shareId;
    const ProperContainer = listView ? ListViewContainer : GridViewContainer;
    const RenderDraggableCard = listView
      ? this.renderDraggableCardList
      : this.renderDraggableCardGrid;

    const onDoubleClick = () => this.props.onDoubleClick(project);

    return (
      <RenderDraggableCard
        id={project.id}
        title={project.name}
        listView={listView}
        selected={isSelected}
        selectedProjectIds={selectedProjectIds}
        onClick={(e) => e.stopPropagation()}
        onDoubleClick={onDoubleClick}
        onMouseDown={(e) => this.props.onMouseDown(e, project)}
        onMouseUp={(e) => this.props.onMouseUp(e, project)}
        handleDrop={(draggedIds, receivingId) =>
          this.props.onDrop(draggedIds, receivingId)
        }>
        <ProperContainer>
          {this.renderShareMark(isShared, isSelected, listView, project)}
          {this.renderContent(isShared, project)}
        </ProperContainer>
      </RenderDraggableCard>
    );
  }
}

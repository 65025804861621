import _ from 'lodash';

import Routes from '../../router/routes';
import SortUtils, {
  ProjectSortMode,
  ProjectSortDirection,
  ProjectSortDefault,
} from '../sort/sort';

const Utils = {
  /**
   * get all folders (i.e not actual projects) from the object of all projects + folders
   * @param allProjects object of project/folder data with IDs as keys
   * @returns an object of just folders, with an additional `childrenFolders` property
   */
  getFolders: (allProjects) => {
    // find all folders within all projects
    const folders = _.reduce(
      allProjects,
      (allFolders, project) => {
        if (project.directory) {
          const folder = {
            [project.id]: {
              ...project,
              childrenFolders: [],
            },
          };
          return {
            ...allFolders,
            ...folder,
          };
        }
        return allFolders;
      },
      {}
    );

    // determine immediate folder children in each folder
    _.forIn(folders, (folder) => {
      const parentFolderId = folder.parentId;
      if (_.has(folders, parentFolderId)) {
        folders[parentFolderId].childrenFolders.push(folder.id);
      }
    });
    return folders;
  },

  /**
   *
   * get all the projects (i.e not folders) from the object of all projects + folders
   * @param allProjects object of project/folder data with IDs as keys
   * @returns an object with just projects
   */
  getProjects: (allProjects) =>
    _.pickBy(allProjects, (project) => !project.directory),

  /**
   * recursively find the total count of nested projects (not folders) within a folder
   * @param allProjects object of project/folder data with IDs as keys
   * @param folderId folder ID at which to start search
   * @returns integer of the total nested count
   */
  countProjectsInNestedFolders: (allProjects, folderId = null) => {
    const children = _.filter(
      allProjects,
      (project) => project.parentId === folderId
    );
    return _.reduce(
      children,
      (itemCount, child) => {
        if (child.directory) {
          return (
            itemCount +
            Utils.countProjectsInNestedFolders(allProjects, child.id)
          );
        }
        return itemCount + 1;
      },
      0
    );
  },

  /**
   * sort an object of folders in alphabetical order, ascending (A-Z)
   * @param folders an object of folders
   * @returns an array of the sorted folders
   */
  sortFolders: (folders) => SortUtils.sortByName(folders),

  /**
   * sort an array of projects in the order specified by the sortByValue
   * @param projectsList array of projects
   * @param printers array of printers
   * @param sortBy sorting mode to use
   * @returns array of sorted projects
   */
  sortProjects: (projectsList, printers, sortBy = ProjectSortDefault) => {
    if (_.isEmpty(projectsList)) return [];

    const { mode, direction } = sortBy;
    const descending = direction === ProjectSortDirection.DESCENDING;

    switch (mode) {
      case ProjectSortMode.NAME: {
        return SortUtils.sortByName(projectsList, descending);
      }
      case ProjectSortMode.PRINTER: {
        if (_.isEmpty(printers)) {
          return SortUtils.sortByModified(projectsList, descending);
        }
        return SortUtils.sortByPrinterName(projectsList, printers, descending);
      }
      case ProjectSortMode.DATE_MODIFIED: {
        return SortUtils.sortByModified(projectsList, descending);
      }
      case ProjectSortMode.DATE_CREATED: {
        return SortUtils.sortByCreated(projectsList, descending);
      }
      default: {
        return SortUtils.sortByModified(projectsList, false);
      }
    }
  },

  /**
   * build nav stack backwards to the top-most level starting from the given pathFolderId
   * at which the user refreshed the page
   * @param folders
   * @param pathFolderId
   * @returns array of all navStack objects from the top-most level to the pathFolderId
   */
  buildNavStack: (folders, pathFolderId) => {
    const folderStack = [
      {
        text: 'My Projects',
        route: Routes.toManageProjects(),
        id: null,
      },
    ];
    if (pathFolderId === null || _.isEmpty(folders)) return folderStack;

    // current folder is the last folder in the stack
    const currentFolder = {
      text: folders[pathFolderId].name,
      route: Routes.toFolder(pathFolderId),
      id: pathFolderId,
      parentId: folders[pathFolderId].parentId || null,
    };
    folderStack.push(currentFolder);

    // build the nav stack
    let folderNavStackCompleted = false;
    while (!folderNavStackCompleted) {
      if (folderStack[0].id === folderStack[1].parentId) {
        folderNavStackCompleted = true;
      } else {
        const { parentId } = folderStack[1];
        const parentFolder = {
          text: folders[parentId].name,
          route: Routes.toFolder(parentId),
          id: parentId,
          parentId: folders[parentId].parentId || null,
        };
        folderStack.splice(1, 0, parentFolder);
      }
    }
    return folderStack;
  },

  /**
   * parse a provided URL string pathname to get the current folder ID
   * @param pathname URL path
   * @returns {string|null} current folder ID
   */
  getCurrentFolderPathId: (pathname) => {
    if (pathname === '/projects' || pathname === '/projects/') return null;
    if (!pathname.startsWith('/projects/')) return null;
    return pathname.replace('/projects/', '');
  },

  /**
   * parse a provided URL string pathname to get the shared project ID
   * @param pathname URL path
   * @returns {string|null} current project ID
   */
  getSharedProjectId: (pathname) => {
    if (!pathname.startsWith('/projects/shared/')) return null;
    return pathname.replace('/projects/shared/', '');
  },

  /**
   * find all the nested folders within the current folder the user is in
   * @param currentFolderId
   * @param folders
   * @param allProjects
   * @returns array of ids of all the nested folders within the current folder,
   * including the current folder id
   */
  getNestedFolders: (currentFolderId, folders, allProjects) => {
    // if user is at the top-most level (not within a folder)
    if (!currentFolderId || _.isEmpty(folders)) {
      return _.reduce(
        allProjects,
        (folderIds, project) => {
          if (project.directory) {
            return [...folderIds, project.id];
          }
          return folderIds;
        },
        []
      );
    }

    const allNestedFolderIds = [currentFolderId];
    let nestedSearchCompleted = false;
    let index = 0;

    while (!nestedSearchCompleted) {
      const currentChildrenFolders =
        folders[allNestedFolderIds[index]].childrenFolders;
      const isLastIndex = index === allNestedFolderIds.length - 1;
      const noChildrenFolders = _.isEmpty(currentChildrenFolders);
      if (isLastIndex && noChildrenFolders) {
        nestedSearchCompleted = true;
      } else {
        allNestedFolderIds.push(...currentChildrenFolders);
        index++;
      }
    }

    return allNestedFolderIds;
  },

  /**
   * determine the direct parent tree from a specific folder ID
   * @param folderId
   * @param folders
   * @returns array of IDs of all the parent folders for the current folder
   */
  getAllParents: (folderId, folders) => {
    let currentFolder = folders[folderId];
    if (!currentFolder || !currentFolder.parentId) return [];
    let currentParentId = currentFolder.parentId;

    const parentIdsTree = [currentParentId];
    while (currentParentId) {
      currentFolder = folders[currentParentId];
      currentParentId = currentFolder.parentId;
      if (currentParentId) {
        parentIdsTree.push(currentParentId);
      }
    }
    return parentIdsTree;
  },

  /**
   * filter through the provided content list to determine whether an element
   * should be displayed on the page or not, depending on the provided parameters
   * @param list
   * @param currentFolderId
   * @param currentNestedFolderIds
   * @param filterByValue
   * @returns array of elements to display
   */
  filterListContent: (
    list,
    currentFolderId,
    currentNestedFolderIds,
    filterByValue
  ) =>
    _.filter(list, (element) => {
      const { parentId } = element;
      const showCardDuringSearch =
        filterByValue && _.includes(currentNestedFolderIds, parentId);
      const showCardOnTopMostLevel = !currentFolderId && !parentId;
      const showCardInsideFolder = parentId === currentFolderId;

      return (
        showCardDuringSearch || showCardOnTopMostLevel || showCardInsideFolder
      );
    }),
  getProjectPrinter: (project, printers) => {
    return printers[project.printerId];
  },
  getCompatibleDeviceIds: (project, printer, devices) => {
    if (printer.machineSettings.extension === 'mcfx') {
      // projects using Element as printer do not have a deviceConfig
      // TODO: differentiate Element and Element HT in the future
      return _.filter(devices, (device) => device.type === 'element').map(
        (device) => device.id
      );
    }

    const { deviceConfig } = project;
    // if deviceConfig is present and Palette is using accessory mode,
    // (except palette 3 & palette 3pro)
    // this project cannot be sent to any devices
    if (deviceConfig?.accessoryMode && deviceConfig.type !== 'palette-3') {
      return [];
    }

    const compatibleIds = [];
    _.each(devices, (device) => {
      if (device.type === 'canvas-hub') {
        // can receive files if no Palette is being used,
        // or if any Palette 2 is being used in connected mode
        if (!deviceConfig || deviceConfig.type === 'palette-2') {
          compatibleIds.push(device.id);
        }
      } else if (deviceConfig) {
        // can receive files if IoT Palette with matching type and model,
        // if the project is using connected mode
        if (
          deviceConfig.type === device.type &&
          deviceConfig.model === device.model
        ) {
          compatibleIds.push(device.id);
        }
      }
    });
    return compatibleIds;
  },
  getDeviceIds: (devices) => _.map(devices, (device) => device.id) || [],
  isProjectSlicing: (sliceJobs, projectId) => {
    if (_.isEmpty(sliceJobs)) return false;

    const job = sliceJobs[projectId];
    if (job) return true;

    return false;
  },
  getSliceJobProgress: (sliceJobs, projectId) => {
    if (_.isEmpty(sliceJobs)) return 0;

    const job = sliceJobs[projectId];
    if (job) return job.progress;

    return 0;
  },
  getSliceJobStatus: (sliceJobs, projectId) => {
    if (_.isEmpty(sliceJobs)) return '';

    const job = sliceJobs[projectId];
    if (job) return job.status;

    return '';
  },

  getInputCount: (deviceConfig, printer) => {
    if (!deviceConfig) {
      // Element
      if (printer.machineSettings.extension === 'mcfx') {
        return 8;
      }
      // TODO: support dual-extruders here in the future?
      return 1;
    }

    const { type, model } = deviceConfig;

    if (type === 'palette' || type === 'palette-2') return 4;

    if (type === 'palette-3') {
      if (model === 'p3') return 4;
      if (model === 'p3-pro') return 8;
    }

    throw new Error('Unexpected device type and model');
  },

  configSupportsSpliceSettings: (deviceConfig) => !!deviceConfig,

  configSupportsTransitions: (deviceConfig, printer) => {
    return !!deviceConfig || printer.machineSettings.extension === 'mcfx';
  },
};

export default Utils;

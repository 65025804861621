import styled, { css } from 'styled-components';

import { SlicerUtils } from '../../../../../../utils';
import { Breakpoints } from '../../../../../../themes';

const composeRGBA = (hex, alpha) => {
  const rgba = SlicerUtils.hexStringToRGBA(hex, alpha);
  return `rgba(${rgba.toString()})`;
};

export const StyledContainer = styled.div`
  position: relative;
  flex: 1;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media (max-width: ${Breakpoints.smallWidth}) {
    font-size: 0.8rem;
    &:not(:last-of-type) {
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  }
`;

const temperatureLabelStyles = css`
  color: ${(props) =>
    props.active
      ? props.theme.colors.greenDefault
      : props.theme.colors.textSecondary};
  font-weight: ${(props) => props.theme.fonts.weightBold};
  font-size: 1.1rem;
  transition: color 0.2s;
  user-select: none;
`;

export const ToggleSection = styled.div`
  min-height: 7.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TitleTextWrapper = styled.div`
  margin-top: 0.5rem;
`;

export const ControlToggle = styled.div`
  background-color: ${(props) =>
    props.active
      ? composeRGBA(props.theme.colors.greenDefault, 0.1)
      : props.theme.colors.elevatedBackgroundTertiary};
  border-radius: 5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  width: 10rem;
  height: 3.5rem;
  transition: background-color 0.2s;
`;

export const CurrentTemperatureOnToggle = styled.div`
  transition: margin-left 0.2s;
  margin-left: ${(props) => (props.active ? '-2rem' : '2rem')};
  ${temperatureLabelStyles};
`;

export const ToggleIcon = styled.div`
  position: absolute;
  right: ${(props) => (props.active ? 0 : 'calc(100% - 3.5rem)')};
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    composeRGBA(
      props.active ? props.theme.colors.greenDefault : props.theme.colors.grey3,
      props.isDisabled ? 0.5 : 1
    )};
  border-radius: 50%;
  transition: all 0.2s;
  transition-property: right, background-color;

  img {
    width: 1.4rem;
    height: 1.4rem;
    filter: ${(props) => (props.active ? 'invert(0%)' : 'invert(30%)')};
  }
`;

export const OptionsBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: none;
  border-top: 1px solid ${(props) => props.theme.colors.grey3};
  background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
`;

export const OffsetButtonWrapper = styled.div`
  padding: 0.5rem;
  font-size: 1rem;
  margin: 0;
`;

export const PresetButtonText = styled.label`
  ${temperatureLabelStyles};
  cursor: inherit;
`;

export const TargetTemperatureButton = styled.div`
  margin: 0 auto;
`;

export const TargetTemperatureInputContainer = styled.div`
  margin: 0 1rem;
  flex-grow: 1;
  align-self: stretch;
  display: flex;
  align-items: center;
`;

export const TemperaturePresets = styled.div`
  width: 100%;
  flex-grow: 1;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(2, min-content);
  grid-gap: 0.2rem;
  padding: 0.2rem;
  box-sizing: border-box;
`;

export const PresetItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 0.25rem;
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  transition: background 0.2s;

  ${(props) =>
    props.isDisabled
      ? css`
          background: ${props.theme.colors.grey2};
          color: ${props.theme.colors.textTertiary};
        `
      : css`
          &:hover {
            background: ${props.theme.colors.elevatedBackgroundSecondary};
          }
        `}
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
`;

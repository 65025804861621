import { connect } from 'react-redux';

import ControlsSection from './controls.jsx';

import { actions as iotActions } from '../../../../reducers/iot/iot';

const mapStateToProps = (state) => ({
  commandHistory: state.iot.commandHistory,
  pendingRequests: state.iot.pendingRequests,
});

const mapDispatchToProps = (dispatch) => ({
  moveExtruder: (device, movementData) =>
    dispatch(iotActions.moveExtruderRequest(device, movementData)),
  homeExtruder: (device, axes) =>
    dispatch(iotActions.homeExtruderRequest(device, axes)),
  feedFilament: (device, feedData) =>
    dispatch(iotActions.feedFilamentRequest(device, feedData)),
  controlFan: (device, controlData) =>
    dispatch(iotActions.controlFanRequest(device, controlData)),
  controlMotor: (device, controlData) =>
    dispatch(iotActions.controlMotorRequest(device, controlData)),
  sendCommand: (device, command) =>
    dispatch(iotActions.sendCommandRequest(device, command)),
  clearCommandHistory: () => dispatch(iotActions.clearCommandHistory()),
  setTargetTemperature: (device, temperatureData) =>
    dispatch(iotActions.setTargetTemperatureRequest(device, temperatureData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ControlsSection);

import React, { Component } from 'react';

import { Container, WarningTextWrapper } from './errorPage.styles';
import { Body1, Button, H1, Page } from '../../shared';

import Routes from '../../router/routes';

class ErrorPage extends Component {
  onHomeClick() {
    this.props.onErrorAcknowledge();
    this.props.history.push(Routes.toHome());
  }

  renderMainPanelContent() {
    return (
      <Container>
        <H1 grey>Oops..!</H1>
        <WarningTextWrapper>
          <Body1 grey>Something went wrong...</Body1>
        </WarningTextWrapper>
        <Button primary onClick={() => this.onHomeClick()}>
          Go to my projects
        </Button>
      </Container>
    );
  }

  render() {
    return <Page>{this.renderMainPanelContent()}</Page>;
  }
}

export default ErrorPage;

import React from 'react';

import { Body1 } from '../../../../shared/typography/typography';
import { Links } from '../../../../themes';
import { Image, Link } from '../../../../shared';

const metadata = {
  title: 'Slic3r: A new engine option for Canvas',
  proceedButtonLabel: 'Continue to Canvas',
  content: (theme) => (
    <>
      <Body1 noSpacing>
        Projects can now be sliced with your choice of two engines, KISSlicer or
        Slic3r.{' '}
        <Link external href={Links.slic3rBlogPost}>
          Learn more
        </Link>
      </Body1>
      <Image alt='Slic3r' src={theme.images.onboarding.slic3r} />
    </>
  ),
};

export default metadata;

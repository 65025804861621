import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import { getThreeState, handleGenericError } from '../common';
import actions from '../../reducers/three/actions';
import { setRenderFlag } from './animationFrame';

export default function* updateGizmo(action) {
  try {
    const { gizmo } = yield select(getThreeState);

    gizmo.calculateCenter();
    const { x, y, z } = gizmo.getCenter();

    yield put(actions.updateGizmoSuccess({ x, y, z }));
    setRenderFlag();
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import { handleGenericError } from '../common';
import { makeRequest } from './horizon';
import { actions as iotActions } from '../../reducers/iot/iot';

export default function* getDrivePathFiles(action) {
  const { device, fullDrivePath } = action.payload;

  try {
    const deviceType = device.type;

    const response = yield call(
      makeRequest,
      device.id,
      'storage',
      {
        method: deviceType === 'canvas-hub' ? 'post' : 'get',
        query: {
          path: fullDrivePath,
        },
      },
      'getDrivePathFiles'
    );

    const driveFiles = response.body;

    const filesContext = {
      currentFullDrivePath: fullDrivePath,
      currentFiles: driveFiles,
    };

    yield put(iotActions.setDeviceFilesContext(device.id, filesContext));
    yield put(iotActions.getDrivePathFilesSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

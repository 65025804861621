import { SceneUtils, SlicerUtils, TransformUtils } from '../../../utils';

export const getCenteredModelPositions = (models, printer) => {
  // get center of new printer bed
  const { bedSize, originOffset } = printer.machineSettings;
  const bedMiddle = SceneUtils.getBedMiddle(bedSize, originOffset);

  // get the newly centered models
  const delta = TransformUtils.getOffsetToBedCenter(models, bedMiddle);
  return models.map((model) => ({
    id: model.id,
    transforms: {
      translate: {
        x: model.transforms.translate.x + delta.x,
        y: model.transforms.translate.y + delta.y,
        z: model.transforms.translate.z + delta.z,
      },
    },
  }));
};

export const getCenteredTowerPosition = (
  centeredModels,
  oldBboxCenter,
  transitionTower
) => {
  // get coordinates of the newly centered models
  const newBbox = SlicerUtils.getCombinedBoundingBox(centeredModels);
  const newBboxCenter = SlicerUtils.getBoundingBoxCenter(newBbox);

  // calculate the delta between the uncentered models and the now centered models
  const bboxDelta = {
    x: newBboxCenter.x - oldBboxCenter.x,
    y: newBboxCenter.y - oldBboxCenter.y,
  };

  // calculate new tower position
  return {
    x: transitionTower.position.x + bboxDelta.x,
    y: transitionTower.position.y + bboxDelta.y,
    z: transitionTower.position.z,
  };
};

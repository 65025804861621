import React, { Component } from 'react';
import _ from 'lodash';

import {
  GroupLabel,
  ModelsList,
  ObjectBrowserWrapper,
  ActionButtonContainer,
  ObjectsPlaceholderLabelWrapper,
  ContentWrapper,
} from './objectBrowser.styles';

import THREEtypes from '../../../reducers/three/types';
import ObjectUtils from './objectBrowser.utils';
import ObjectItem from './objectItem/objectItem.container';

import {
  ActionButton,
  ToolCollapsiblePanel,
  ConfirmationModal,
} from '../../../shared';

import { TreeUtils, SlicerUtils, MatrixUtils } from '../../../utils';

import { Icons } from '../../../themes';

import DuplicateModal from './modals/duplicate.jsx';

import { Body1 } from '../../../shared/typography/typography';

class ObjectBrowser extends Component {
  static defaultProps = {
    canSelectAll: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false,
      showDuplicateItemModal: false,
    };
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);
  }

  componentDidUpdate(prevProps) {
    const { isRepackPending, isRepackSuccess, models } = this.props;
    if (prevProps.isRepackPending !== isRepackPending) {
      if (isRepackSuccess) {
        TreeUtils.flattenDeep(models)
          .filter((model) => model.isNew === true)
          .forEach((model) => this.props.toggleModelActiveState(model.id));
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  renderContent() {
    const { models } = this.props;
    if (models.length < 1) {
      return (
        <ObjectsPlaceholderLabelWrapper>
          <Body1 grey>
            Add models to the project by selecting &lsquo;Add Models&rsquo;
          </Body1>
        </ObjectsPlaceholderLabelWrapper>
      );
    }
    return this.renderModelsList(models);
  }

  renderModelsList(models, depth = 0) {
    return (
      <ModelsList key={2}>
        {this.renderTransitionTowerItem(depth)}
        {_.map(models, (model, index) => {
          if (model.type === 'group') {
            return this.renderGroupItem(model, index, depth);
          }
          return this.renderSingleItem(model, index, depth);
        })}
      </ModelsList>
    );
  }

  composeFlatTree() {
    const flatTree = TreeUtils.flattenDeep(this.props.models);
    if (this.props.hasTower) {
      // insert a placeholder for tower as the first node in flat tree
      flatTree.unshift({
        id: THREEtypes.TOWER_MESH_NAME,
        active: this.props.isTowerActive,
      });
    }
    return flatTree;
  }

  getClickParameters(e) {
    // compose flat tree of objects (including tower if one exists)
    const flatTree = this.composeFlatTree();

    // look for lowest and highest indices of currently active nodes
    const activeIndices = [];
    flatTree.forEach((node, index) => {
      if (node.active === true) activeIndices.push(index);
    });
    const firstActiveIndex = activeIndices[0];
    const lastActiveIndex = activeIndices[activeIndices.length - 1];

    const isSelectingRange = e.shiftKey && !_.isEmpty(activeIndices);
    const isSelectingInclusive = e.metaKey || e.ctrlKey;

    return {
      flatTree,
      firstActiveIndex,
      lastActiveIndex,
      isSelectingRange,
      isSelectingInclusive,
    };
  }

  handleGroupClick(e, item) {
    // clicked on group
    const {
      flatTree,
      firstActiveIndex,
      lastActiveIndex,
      isSelectingRange,
      isSelectingInclusive,
    } = this.getClickParameters(e);
    const childModels = TreeUtils.flattenDeep(item.children);
    if (isSelectingRange) {
      const firstChildIndex = _.findIndex(
        flatTree,
        (node) => node.id === childModels[0].id
      );
      const lastChildIndex = _.findIndex(
        flatTree,
        (node) => node.id === childModels[childModels.length - 1].id
      );
      const sparseRange = [
        firstActiveIndex,
        lastActiveIndex,
        firstChildIndex,
        lastChildIndex,
      ];
      this.rangeClick(flatTree, sparseRange);
    } else if (isSelectingInclusive) {
      // toggle group with rest unchanged
      _.forEach(childModels, (model) => {
        this.props.toggleModelActiveState(model.id);
      });
    } else {
      // toggle group with rest deselected
      this.props.deactivateAllModels();
      _.forEach(childModels, (model) => {
        this.props.toggleModelActiveState(model.id);
      });
    }
  }

  handleSingleModelClick(e, item) {
    // clicked on single model
    const {
      flatTree,
      firstActiveIndex,
      lastActiveIndex,
      isSelectingRange,
      isSelectingInclusive,
    } = this.getClickParameters(e);
    if (isSelectingRange) {
      const currentIndex = _.findIndex(flatTree, (node) => node.id === item.id);
      const sparseRange = [firstActiveIndex, lastActiveIndex, currentIndex];
      this.rangeClick(flatTree, sparseRange);
    } else if (isSelectingInclusive) {
      // toggle single model with rest unchanged
      this.props.toggleModelActiveState(item.id);
    } else {
      // toggle single model with rest deselected
      this.props.deactivateAllModels();
      this.props.toggleModelActiveState(item.id);
    }
  }

  handleTowerClick(e) {
    // clicked on tower
    const {
      flatTree,
      firstActiveIndex,
      lastActiveIndex,
      isSelectingRange,
      isSelectingInclusive,
    } = this.getClickParameters(e);
    if (isSelectingRange) {
      const currentIndex = 0;
      const sparseRange = [firstActiveIndex, lastActiveIndex, currentIndex];
      this.rangeClick(flatTree, sparseRange);
    } else if (isSelectingInclusive) {
      // toggle tower with rest unchanged
      this.props.toggleTowerActiveState();
    } else {
      // toggle tower with rest deselected
      this.props.deactivateAllModels();
      this.props.toggleTowerActiveState();
    }
  }

  onListItemClick(e, item) {
    e.stopPropagation();
    if (item.type === 'group') {
      this.handleGroupClick(e, item);
    } else if (item.type === 'stl') {
      this.handleSingleModelClick(e, item);
    } else if (item.id === THREEtypes.TOWER_MESH_NAME) {
      this.handleTowerClick(e);
    }
  }

  rangeClick(flatTree, sparseRange) {
    // given a flat tree of objects and a sparse range of indices,
    // find the lowest and highest indices in the range and select anything in-between
    const lowerBound = Math.min(...sparseRange);
    const upperBound = Math.max(...sparseRange);
    this.props.deactivateAllModels();
    flatTree.forEach((node, index) => {
      if (index <= upperBound && index >= lowerBound) {
        if (node.id === THREEtypes.TOWER_MESH_NAME) {
          this.props.toggleTowerActiveState();
        } else {
          this.props.toggleModelActiveState(node.id);
        }
      }
    });
  }

  onKeyDown(e) {
    const { models, canSelectAll } = this.props;
    const selectedModels = SlicerUtils.getSelectedModels(models);

    if (canSelectAll) {
      // select/deselect all
      if (e.key.toLowerCase() === 'a' && (e.metaKey || e.ctrlKey)) {
        if (e.shiftKey) {
          // SHIFT-CTRL-A to deselect all models
          this.props.deactivateAllModels();
        } else {
          // CTRL-A to select all models
          const allModelIds = _.map(
            TreeUtils.flattenDeep(models),
            (model) => model.id
          );
          this.props.activateModels(allModelIds);
          if (this.props.hasTower) {
            this.props.activateTower();
          }
        }
        e.stopPropagation();
        e.preventDefault();
        return;
      }
    }

    if (_.isEmpty(selectedModels)) return;

    if (
      e.key === 'Delete' &&
      selectedModels.length >= 1 &&
      !this.state.showDuplicateItemModal
    ) {
      // Hit DELETE while selecting model(s)
      this.setState({ showDeleteModal: true });
      return;
    }
    if (e.key === 'Enter' && this.state.showDeleteModal) {
      // Hit ENTER while modal is up
      const ids = _.map(selectedModels, (model) => model.id);
      this.handleDeleteConfirmation(ids);
      return;
    }
    if (e.key === 'Escape' && this.state.showDeleteModal) {
      // Hit ESC while modal is up
      this.setState({ showDeleteModal: false });
    }
  }

  handleDeleteConfirmation(ids) {
    // check if any groups will have only one remaining child - if so ungroup
    const { models } = this.props;
    const noSiblings = [];
    const groupNames = [];
    models.forEach((model) => {
      if (model.type === 'group') {
        let remainingChildrenCount = model.children.length;
        model.children.forEach((child) => {
          if (ids.includes(child.id)) remainingChildrenCount--;
        });

        if (remainingChildrenCount === 1) {
          noSiblings.push(model.children[0]);
          groupNames.push(model.name);
        }
      }
    });

    this.setState({ showDeleteModal: false }, () => {
      this.props.deleteModels(ids);
      if (!_.isEmpty(noSiblings))
        this.props.ungroupModels(noSiblings, groupNames);
    });
  }

  handleDuplicateConfirmation(modelIds, count) {
    this.setState({ showDuplicateItemModal: false }, () => {
      this.props.duplicateModel(modelIds, count);
    });
  }

  showDuplicateModal() {
    this.setState({ showDuplicateItemModal: true });
  }

  hideDuplicateModal() {
    this.setState({ showDuplicateItemModal: false });
  }

  renderTransitionTowerItem(depth) {
    if (depth > 0 || !this.props.hasTower) return null;
    const towerPlaceholder = {
      id: THREEtypes.TOWER_MESH_NAME,
      active: this.props.isTowerActive,
    };
    return (
      <ObjectItem
        key={-2}
        model='tower'
        name='Transition Tower'
        depth={0}
        active={this.props.isTowerActive}
        visible={this.props.isTowerVisible}
        onClick={(e) => this.onListItemClick(e, towerPlaceholder)}
        onToggleVisible={() => this.props.toggleTowerVisibility()}
      />
    );
  }

  renderSingleItem(node, index, depth = 0) {
    return (
      <ObjectItem
        key={node.id}
        model={node}
        name={node.name}
        depth={depth}
        active={node.active}
        visible={node.visible}
        onClick={(e) => this.onListItemClick(e, node)}
        onDuplicateClick={(model) => this.handleDuplicateClick(model)}
        onToggleVisible={(model) => this.handleVisibilityToggle(model)}
        onEdit={(model, name) => this.handleEditModelName(model, name)}
        onDeleteClick={(model) => this.handleDeleteClick(model)}
      />
    );
  }

  renderGroupItem(node, index, depth = 0) {
    const childModels = TreeUtils.flattenDeep(node.children);
    const allChildrenActive =
      !_.isEmpty(childModels) &&
      _.isEmpty(_.filter(childModels, (model) => !model.active));
    const allChildrenVisible =
      !_.isEmpty(childModels) &&
      _.isEmpty(_.filter(childModels, (model) => !model.visible));
    return (
      <GroupLabel key={index}>
        <ObjectItem
          key={-1}
          model={node}
          name={node.name}
          depth={depth}
          active={allChildrenActive}
          visible={allChildrenVisible}
          onClick={(e) => this.onListItemClick(e, node)}
          onDuplicateClick={(model) => this.handleDuplicateClick(model)}
          onToggleVisible={(model) => this.handleVisibilityToggle(model)}
          onEdit={(oldName, newName) =>
            this.handleEditGroupName(oldName, newName, childModels)
          }
          onDeleteClick={(model) => this.handleDeleteClick(model)}
        />
        {this.renderModelsList(node.children, depth + 1)}
      </GroupLabel>
    );
  }

  handleVisibilityToggle(node) {
    // toggle visibility for whole group
    if (node.type === 'group') {
      let groupVisible = true;
      TreeUtils.map(node.children, (model) => {
        if (!model.visible) {
          groupVisible = false;
        }
      });
      TreeUtils.map(node.children, (model) => {
        this.props.setModelVisibility(model.id, !groupVisible);
      });
    } else {
      // toggle visibility for individual model
      this.props.setModelVisibility(node.id, !node.visible);
    }
  }

  handleEditModelName(model, name) {
    this.props.updateModelName(model.id, name);
  }

  handleEditGroupName(oldName, newName, childModels) {
    this.props.updateModelGroupName(oldName, newName, childModels);
  }

  handleToggleGroup(selected, isGrouping, groupNames) {
    if (isGrouping) {
      const groupName = groupNames[0];
      this.props.groupModels(selected, groupName);
    } else {
      this.props.ungroupModels(selected, groupNames);
    }
  }

  handleAlignAndGroup(selected, groupNames) {
    const existingBBox = SlicerUtils.getCombinedBoundingBox(selected);
    const existingCenter = SlicerUtils.getBoundingBoxCenter(existingBBox);
    const zeroed = _.map(selected, (item) => {
      const model = item;
      model.transforms = {
        ...model.transforms,
        translate: { x: 0, y: 0, z: 0 },
      };
      MatrixUtils.updateMeshTransforms(model.mesh, model.transforms);
      return model;
    });
    const zeroedBBox = SlicerUtils.getCombinedBoundingBox(zeroed);
    const zeroedCenter = SlicerUtils.getBoundingBoxCenter(zeroedBBox);
    const newCenter = {
      x: existingCenter.x - zeroedCenter.x,
      y: existingCenter.y - zeroedCenter.y,
      z: existingCenter.z - zeroedCenter.z,
    };
    const groupName = groupNames[0];
    this.props.alignAndGroupModels(selected, groupName, newCenter);
  }

  handleDeleteClick(model) {
    const { id, type, children } = model;
    let clickedIds = [id];
    if (type === 'group') {
      const clickedChildren = TreeUtils.flattenDeep(children);
      clickedIds = _.map(clickedChildren, (child) => child.id);
    }
    const { models } = this.props;
    const selectedModels = SlicerUtils.getSelectedModels(models);
    const selectedIds = _.map(
      selectedModels,
      (selectedModel) => selectedModel.id
    );

    // clicked item is already included, so stop here and show modal
    const modelAlreadyIncluded = _.isEmpty(
      _.difference(clickedIds, selectedIds)
    );
    if (modelAlreadyIncluded) {
      this.setState({ showDeleteModal: true });
      return;
    }

    // clicked item not included yet, so deactivate previously selected models
    // and activate the newly clicked one(s) then show modal
    this.props.deactivateAllModels();
    if (type === 'group') {
      const childModels = TreeUtils.flattenDeep(children);
      _.forEach(childModels, (child) => {
        this.props.toggleModelActiveState(child.id);
      });
    } else {
      this.props.toggleModelActiveState(id);
    }
    this.setState({ showDeleteModal: true });
  }

  handleDuplicateClick(clickedModel) {
    const { id, type, children } = clickedModel;
    let clickedIds = [id];
    if (type === 'group') {
      const clickedChildren = TreeUtils.flattenDeep(children);
      clickedIds = _.map(clickedChildren, (child) => child.id);
    }
    const { models } = this.props;
    const selectedModels = SlicerUtils.getSelectedModels(models);
    const selectedIds = _.map(
      selectedModels,
      (selectedModel) => selectedModel.id
    );

    const modelAlreadyIncluded = _.isEmpty(
      _.difference(clickedIds, selectedIds)
    );
    if (modelAlreadyIncluded) {
      this.setState({ showDuplicateItemModal: true });
      return;
    }

    this.props.deactivateAllModels();
    if (type === 'group') {
      const childModels = TreeUtils.flattenDeep(children);
      _.forEach(childModels, (child) => {
        this.props.toggleModelActiveState(child.id);
      });
    } else {
      this.props.toggleModelActiveState(id);
    }
    this.setState({ showDuplicateItemModal: true });
  }

  renderActionButtons() {
    if (_.isEmpty(this.props.models)) return null;
    return (
      <ActionButtonContainer>
        {this.props.allowPainting && this.renderEnterPaintView()}
        {this.renderToggleGroupButton()}
        {this.renderAlignAndGroupButton()}
      </ActionButtonContainer>
    );
  }

  renderEnterPaintView() {
    const { paintDisabled, openPaintView } = this.props;
    return (
      <ActionButton
        icon={Icons.project.brush}
        title='Paint'
        onClick={() => openPaintView()}
        disabled={paintDisabled}
      />
    );
  }

  renderToggleGroupButton() {
    const { models } = this.props;
    const { selectedModels, canToggle, isGrouping, groupNames } =
      ObjectUtils.canToggleGroup(models);
    let icon = Icons.basic.link;
    let title = 'Group models';
    if (selectedModels.length > 1 && !isGrouping) {
      icon = Icons.basic.unlink;
      title = 'Ungroup models';
    }

    return (
      <ActionButton
        icon={icon}
        title={title}
        onClick={() =>
          this.handleToggleGroup(selectedModels, isGrouping, groupNames)
        }
        disabled={!canToggle}
      />
    );
  }

  renderAlignAndGroupButton() {
    const { models } = this.props;
    const { selectedModels, isGrouping, groupNames } =
      ObjectUtils.canToggleGroup(models);

    return (
      <ActionButton
        icon={Icons.project.align}
        title='Align and group'
        onClick={() => this.handleAlignAndGroup(selectedModels, groupNames)}
        disabled={!isGrouping}
      />
    );
  }

  renderDeleteModal() {
    if (!this.state.showDeleteModal) return null;
    const { models } = this.props;
    const selectedModels = SlicerUtils.getSelectedModels(models);
    if (_.isEmpty(selectedModels)) return null;

    const mainLabel =
      selectedModels.length > 1
        ? `${selectedModels.length} models`
        : `${selectedModels[0].name}`;
    const secondaryLabel =
      selectedModels.length > 1 ? 'these models' : 'this model';
    const ids = _.map(selectedModels, (model) => model.id);

    return (
      <ConfirmationModal
        isWarning
        primaryLabel={`Delete ${mainLabel}`}
        secondaryLabel={`Are you sure you would like to delete ${secondaryLabel}?`}
        onClickCancel={() => this.setState({ showDeleteModal: false })}
        onClickConfirm={() => this.handleDeleteConfirmation(ids)}
      />
    );
  }

  renderDuplicateModal() {
    const { showDuplicateItemModal } = this.state;
    if (!showDuplicateItemModal) return null;
    const { models } = this.props;
    const selectedModels = SlicerUtils.getSelectedModels(models);
    const ids = _.map(selectedModels, (model) => model.id);
    return (
      <DuplicateModal
        selectedItem={selectedModels}
        onMarginClick={() => this.hideDuplicateModal()}
        onCancel={() => this.hideDuplicateModal()}
        onConfirm={(modelIds, count) => {
          this.handleDuplicateConfirmation(ids, count);
        }}
      />
    );
  }

  render() {
    return (
      <>
        {this.renderDeleteModal()}
        {this.renderDuplicateModal()}
        <ObjectBrowserWrapper>
          <ToolCollapsiblePanel
            label='Objects'
            isCollapsed={false}
            scroll={false}
            forceOpen={this.props.forceOpen}
            footerContent={this.renderActionButtons()}
            headerCustomButton={this.props.headerCustomButton}>
            <ContentWrapper>{this.renderContent()}</ContentWrapper>
          </ToolCollapsiblePanel>
        </ObjectBrowserWrapper>
      </>
    );
  }
}

export default ObjectBrowser;

import React from 'react';
import _ from 'lodash';

import {
  LegendContainer,
  LegendBody,
  LegendLabel,
  LegendColor,
  LegendEntry,
} from './toolpathLegend.styles';

import THREEtypes from '../../../../reducers/three/types';

const ToolpathLegend = ({ toolpath }) => {
  const { legend, viewType } = toolpath;
  if (_.isEmpty(legend)) return null;
  if (viewType === THREEtypes.TOOLPATH_BY_TOOL) return null;
  const legendEntries = _.map(legend, (entry, index) => {
    const { label, color } = entry;
    return (
      <LegendEntry key={index}>
        <LegendColor color={color} />
        <LegendLabel>{label}</LegendLabel>
      </LegendEntry>
    );
  });
  return (
    <LegendContainer>
      <LegendBody>{legendEntries}</LegendBody>
    </LegendContainer>
  );
};

export default ToolpathLegend;

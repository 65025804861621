import React, { Component } from 'react';
import _ from 'lodash';

import {
  HighlightsContainer,
  Highlight,
  HighlightTextWrapper,
} from './forms.styles';

import { Caption, CollapsiblePanel } from '../../../../shared';

class FormPanel extends Component {
  static defaultProps = {
    icon: '',
    label: '',
    highlights: [],
    forceOpen: false,
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.scrollIntoView = this.scrollIntoView.bind(this);
  }

  renderHighlights() {
    const highlights = _.map(this.props.highlights, (highlight) =>
      this.renderHighlight(highlight)
    );
    return <HighlightsContainer>{highlights}</HighlightsContainer>;
  }

  renderHighlight(highlight) {
    return (
      <Highlight key={highlight.label}>
        <HighlightTextWrapper>
          <Caption grey noSpacing>
            {highlight.label}
          </Caption>
        </HighlightTextWrapper>
        <Caption grey noSpacing>
          {highlight.value}
        </Caption>
      </Highlight>
    );
  }

  scrollIntoView() {
    if (!this.ref.current) return;
    if ('scrollBehavior' in document.documentElement.style) {
      this.ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    } else {
      this.ref.current.scrollIntoView(true);
    }
  }

  render() {
    return (
      <div ref={this.ref}>
        <CollapsiblePanel
          label={this.props.label}
          icon={this.props.icon}
          fieldChangesLength={this.props.fieldChangesLength}
          highlights={this.renderHighlights()}
          isCollapsed={true}
          forceOpen={this.props.forceOpen}
          triggerOpen={this.props.triggerOpen}
          onOpen={this.scrollIntoView}
          scroll={false}>
          {this.props.children}
        </CollapsiblePanel>
      </div>
    );
  }
}

export default FormPanel;

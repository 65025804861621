import antlr4 from 'antlr4';

import Visitor from './visitor';
import ErrorListener from './errorListener';

import Lexer from './gen/SequenceLexer';
import Parser from './gen/SequenceParser';

const DEFAULT_OPTIONS = {
  maxLoopIterations: 1e6,
  maxOutputSize: 50 * 1024 * 1024, // 50 MiB
  eol: '\n',
  trailingNewline: true,
};

export const parse = (input) => {
  // lexer
  const chars = new antlr4.InputStream(`${input.replace(/\r\n|\r/g, '\n')}\n`);
  const lexer = new Lexer(chars);
  // change from the default (print errors to console) to throwing errors
  // - we can't throw errors directly from the listener method, since they
  //   won't be caught locally, but we can set a flag on the listener
  const lexerListener = new ErrorListener();
  lexer.removeErrorListeners();
  lexer.addErrorListener(lexerListener);
  // do the lexing
  const tokens = new antlr4.CommonTokenStream(lexer);
  // now check for errors (this might throw)
  try {
    lexerListener.checkErrors();
  } catch (err) {
    throw new Error(err.message);
  }

  // parser
  const parser = new Parser(tokens);
  const parserListener = new ErrorListener();
  parser.removeErrorListeners();
  parser.addErrorListener(parserListener);
  parser.buildParseTrees = true;
  const tree = parser.sequence();
  try {
    parserListener.checkErrors();
  } catch (err) {
    throw new Error(err.message);
  }
  return tree;
};

const interpret = (parseTree, locals = {}, options = {}) => {
  const mergedOpts = { ...DEFAULT_OPTIONS, ...options, locals };
  // visitor
  try {
    const visitor = new Visitor(mergedOpts);
    parseTree.accept(visitor);
    if (!mergedOpts.trailingNewline) {
      visitor.result = visitor.result.slice(0, -1);
    }
    return visitor;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const evaluate = (input, locals = {}, options = {}) => {
  const tree = parse(input);
  return interpret(tree, locals, options);
};

import { connect } from 'react-redux';

import ViewDevice from './viewDevice.jsx';

import { actions as iotActions } from '../../../reducers/iot/iot';

const mapStateToProps = (state) => ({
  connected: state.iot.connected,
  devices: state.iot.devices,
  deviceStates: state.iot.deviceStates,
  pendingRequests: state.iot.pendingRequests,
});

const mapDispatchToProps = (dispatch) => ({
  pausePrint: (device) => dispatch(iotActions.pausePrintRequest(device)),
  resumePrint: (device) => dispatch(iotActions.resumePrintRequest(device)),
  cancelPrint: (device) => dispatch(iotActions.cancelPrintRequest(device)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewDevice);

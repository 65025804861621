import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import {
  StyledPageTitle,
  NavigationStack,
  PageName,
  StyledStackedPageTitle,
} from './pageTitle.styles';

import Icon from '../../../icon/icon.jsx';
import { Icons } from '../../../../themes';

const PageTitle = () => {
  const theme = useTheme();
  const history = useHistory();
  const { navStack } = useSelector((state) => state.nav);

  useEffect(() => {
    return () => {
      document.title = 'Canvas';
    };
  }, []);

  const renderStackLabel = (item, key) => {
    const onClickHandler = item.onClick
      ? item.onClick
      : () => history.push(item.route);

    return (
      <StyledStackedPageTitle
        key={key}
        onClick={onClickHandler}
        disabled={item.disabled}>
        {item.text}
      </StyledStackedPageTitle>
    );
  };

  const renderStackIcon = (key) => (
    <Icon key={key} src={Icons.basic.chevronRight} color={theme.colors.grey5} />
  );

  const getRoutes = () => {
    const routes = [];
    let key = 0;
    _.forEach(navStack, (item, index) => {
      routes.push(renderStackLabel(item, key));
      key++;
      if (index < navStack.length - 1) {
        routes.push(renderStackIcon(key));
        key++;
      }
    });
    return routes;
  };

  const renderNavigationStack = () => {
    if (!_.isEmpty(navStack)) {
      document.title = `${navStack[navStack.length - 1].text} • Canvas`;
    }
    return <NavigationStack>{getRoutes(navStack)}</NavigationStack>;
  };

  const renderSmallWidthName = () => {
    const routes = getRoutes();
    if (!_.isEmpty(routes)) {
      return <PageName>{routes[routes.length - 1]}</PageName>;
    }
    return null;
  };

  return (
    <StyledPageTitle>
      {renderNavigationStack()}
      {renderSmallWidthName()}
    </StyledPageTitle>
  );
};

export default PageTitle;

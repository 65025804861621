import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import API, { methods } from '../canvas-api';

import { handleGenericError, getAuthState } from '../common';
import { actions } from '../../reducers/onboarding/onboarding';

export default function* setTaskStepStatus(action) {
  try {
    const { task, status } = action.payload;

    // persist task status(es) in back end
    const { userId } = yield select(getAuthState);
    const response = yield call(API, {
      method: methods.POST,
      path: `users/${userId}/onboarding/task/${task}/steps`,
      body: status,
    });
    if (response === null) return;
    yield put(actions.setTaskStepStatusSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError } from '../../common';

import { actions } from '../../../reducers/slicer/slicer';

export default function* dispatchPrintToPolar(action) {
  try {
    const { projectId, polarPrinterSerialNumber } = action.payload;

    const response = yield call(API, {
      method: methods.POST,
      path: `projects/${projectId}/dispatch/print/polar`,
      body: { polarPrinterSerialNumber },
    });
    if (response === null) return;
    yield put(actions.dispatchPrintToPolarSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import { getPaginatedItems } from '../../canvas-api';
import { handleGenericError, getAuthState, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';
import { MaterialUtils } from '../../../utils';
import { waitForGetMaterials } from './getMaterials';

export default function* getSpliceSettings(action) {
  try {
    const { userId } = yield select(getAuthState);

    const spliceSettings = yield call(
      getPaginatedItems,
      `splice-settings/user/${userId}`,
      'spliceSettings'
    );
    if (spliceSettings === null) return;

    yield call(waitForGetMaterials);
    const { materials } = yield select(getSlicerState);
    const materialIds = Object.keys(_.omit(materials, '0'));

    // only includes entries that exist in the DB, ie. that were saved previously
    const sparseMap = MaterialUtils.mapSpliceSettings(spliceSettings);

    // fill in missing entries to ensure full matrix exists
    const spliceSettingsMap = MaterialUtils.populateMissingSpliceSettings(
      sparseMap,
      materialIds
    );

    yield put(actions.getSpliceSettingsSuccess(spliceSettingsMap));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import styled, { css } from 'styled-components';

import { Breakpoints } from '../../themes';

export const StyledPage = styled.div`
  width: 100%;
  margin-top: 3.5rem;
  display: flex;

  background-image: url(${(props) => props.theme.images.background});
  background-size: cover;
  outline: none;

  ${(props) =>
    !props.isAuthenticated &&
    css`
      margin-top: -5rem;
    `}
`;

export const NavPanel = styled.div`
  flex-grow: 0;

  width: 16.5rem;
  min-width: 16.5rem;
  padding: 0;

  display: flex;
  flex-direction: column;

  @media (max-width: ${Breakpoints.largeWidth}) {
    display: none;
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  margin-bottom: 1rem;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    padding: 0 0.5rem;
  }
`;

export const MainPanel = styled.div`
  flex-grow: 1;
  padding: 0 0.5rem;
  overflow-x: hidden;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  outline: none;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    padding: 0;
  }
`;

export const PageTitleContainer = styled.div`
  flex-grow: 1;
  min-width: 0;

  @media (max-width: ${Breakpoints.largeWidth}) {
    order: 1;
  }
`;

export const ActionBarTop = styled.div`
  position: sticky;
  top: 0;
  padding: 1rem 0;
  margin-bottom: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) =>
    props.theme.name === 'dark'
      ? props.theme.colors.systemBackgroundTertiary
      : props.theme.colors.elevatedBackgroundSecondary};

  box-shadow: ${(props) => {
    if (!props.hideDivider) {
      if (props.theme.name === 'dark')
        return `-8px 0px 0px 0px ${props.theme.colors.systemBackgroundTertiary}, 8px 0px 0px 0px ${props.theme.colors.systemBackgroundTertiary}, 0px 1px 0px ${props.theme.colors.grey3};`;
      return `-8px 0px 0px 0px ${props.theme.colors.elevatedBackgroundSecondary}, 8px 0px 0px 0px ${props.theme.colors.elevatedBackgroundSecondary}, 0px 1px 0px ${props.theme.colors.grey3};`;
    }

    if (props.theme.name === 'dark')
      return `-8px 0px 0px 0px ${props.theme.colors.systemBackgroundTertiary}, 8px 0px 0px 0px ${props.theme.colors.systemBackgroundTertiary}`;
    return `-8px 0px 0px 0px ${props.theme.colors.elevatedBackgroundSecondary}, 8px 0px 0px 0px ${props.theme.colors.elevatedBackgroundSecondary}`;
  }}}

  z-index: 2;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    padding: 1rem 0.5rem;
  }

  @media (max-width: ${Breakpoints.largeWidth}) {
    flex-direction: column;
    align-items: start;
    gap: 1.25rem;
  }
`;

export const ActionButtonsContainer = styled.div`
  flex-grow: 0;
  margin-right: 0.75rem;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.25rem;

  @media (max-width: ${Breakpoints.largeWidth}) {
    order: 3;
  }
`;

export const MenuButtonsContainer = styled.div`
  flex-grow: 0;

  @media (max-width: ${Breakpoints.largeWidth}) {
    order: 2;
  }
`;

import React from 'react';

const useSupport = (
  <>
    Enable or disable support generation. Support is used to create a scaffold
    under the model, ensuring that overhanging or floating sections have
    something to print on.
  </>
);

const useCustomSupports = (
  <>
    Enable or disable custom supports. Custom supports can be configured to
    manually specify areas under which support will be generated. Standard
    supports will use the maximum overhang angle setting to automatically
    generate support.
  </>
);

const supportDensity = (
  <>
    Choose the support density to use where support is generated. High density
    support will support smaller details better, while low density support will
    be easier to remove and use less material.
  </>
);

const supportSpeed = (
  <>
    <>Specify the speed to use when printing support structures.</>
    <>Only available when using Slic3r.</>
  </>
);

const maxOverhangAngle = (
  <>
    Specify the maximum overhang angle that can be printed without support. 90°
    represents fully horizontal overhangs. 45° is recommended for most printers.
  </>
);

const defaultSupportExtruder = (
  <>
    Select which extruder to use for printing support structures. Selecting
    &lsquo;Auto&rsquo; allows supports to be printed with any extruder, reducing
    the number of transitions in the print.
  </>
);

const supportZGap = (
  <>
    Specify the vertical gap between the top of the support and the bottom of
    the part. A smaller value will provide better support, while a larger value
    will make the support easier to remove.
  </>
);

const supportXYGap = (
  <>
    Specify the gap between the support and the model in the horizontal
    direction. This ensures that the support does not fuse to vertical walls of
    the model and can be more easily removed.
  </>
);

const supportXYInflation = (
  <>
    <>
      Specify the amount that supports should extend horizontally past the
      outside of the models. A larger inflation will generally provide a better
      surface to print on top of.
    </>
    <>Only available when using KISSlicer.</>
  </>
);

const useSupportStitchInterval = (
  <>
    <>
      If enabled, support paths will alternate their orientation on one layer
      per interval. This can improve the stability of tall support structures,
      but may also make break-away supports harder to remove.
    </>
    <>Only available when using KISSlicer.</>
  </>
);

const supportStitchInterval = (
  <>
    <>Specify the interval between reinforcement layers.</>
    <>Only available when using KISSlicer.</>
  </>
);

const useSupportInterface = (
  <>
    Dense support interfaces will make the layers between the supports and the
    part solid. They should only be used when printing with soluble support
    material, as they will make break-away supports harder to remove.
  </>
);

const defaultSupportInterfaceExtruder = (
  <>
    When printing with soluble supports, using material for only the interfaces
    can reduce the amount of soluble filament used. When printing with
    break-away supports, this should generally match the support extruder.
  </>
);

const supportInterfaceSpeed = (
  <>
    <>Specify the speed to use when printing support interfaces.</>
    <>Only available when using Slic3r.</>
  </>
);

const supportInterfaceDensity = <>Specify the density of support interfaces.</>;

const supportInterfaceExtrusionWidth = (
  <>Specify the extrusion width of support interfaces.</>
);

const supportInterfaceThickness = (
  <>Specify the thickness of support interface layers.</>
);

export default {
  useSupport,
  useCustomSupports,
  supportDensity,
  supportSpeed,
  maxOverhangAngle,
  defaultSupportExtruder,
  supportZGap,
  supportXYGap,
  supportXYInflation,
  useSupportStitchInterval,
  supportStitchInterval,
  useSupportInterface,
  defaultSupportInterfaceExtruder,
  supportInterfaceSpeed,
  supportInterfaceDensity,
  supportInterfaceExtrusionWidth,
  supportInterfaceThickness,
};

const CONSTANTS = {
  depthTolerance: 1.1 / 256.0, // 1/256 is the rounding error for packing a depth
  normalTolerance: 0.01,
  intensityMultiplier: 1.25, // multiplies reflection brightness by (100 + x)%
  highlightMultiplier: 1.25, // increases brightness by (100 + x)% for highlighting
  diffuseModifier: 0.3, // bigger value increases light diffusion on surface
  darknessLowerBound: 0.2, // prevents RGB components [0.0-1.9] from falling below bound x
  lambertThrehsold: 0.2, // sets minimum reflection brightness x
};

export const SUPPORT_FACE_COLORS = [
  [230, 126, 34, 1], // support on
  [100, 100, 100, 1], // support off
];

const composeVec3FromRGB = (rgb) =>
  `vec3(${rgb[0] / 255}, ${rgb[1] / 255}, ${rgb[2] / 255})`;

/**
 * Includes point light(s) if any
 */
const includePointLight = `
  #if NUM_POINT_LIGHTS > 0
    struct PointLight {
      vec3 position;
    };

    uniform PointLight pointLights[ NUM_POINT_LIGHTS ];
  #endif
`;

/**
 * Includes definition for the 'Color' struct.
 * This is used for passing in an array of vectors (RGB) as uniforms into the shader.
 * The values for the uniform must match the format of the struct defined here.
 *   e.g.,
 *   uniforms: {
 *     myColors: {
 *       value: [
 *         { color: new THREE.Vector3(r, g, b) }, // one color - matches the 'Color' struct format
 *         { color: new THREE.Vector3(r, g, b) }, // another color
 *       ]
 *     }
 *   }
 */
const defineColorStruct = `
  struct Color {
    vec3 color;
  };
`;

/**
 * Includes declaration for extruder colors uniform (array of 'Color's)
 */
const includeExtruderColors = `
  uniform Color extruderColors[ NUM_EXTRUDER_COLORS ];
`;

/**
 * Includes declaration for posterized colors uniform (array of 'Color's)
 */
const includePosterizedColors = `
  uniform Color posterizedColors[ NUM_POSTERIZED_COLORS ];
`;

/**
 * Snippet to calculate the position (i.e., the UV) to sample from the stamp texture.
 */
const getSamplingPosition = `
  vec2 delta = stampTopRight - stampBottomLeft;
  vec2 samplePosition = (cameraUv - stampBottomLeft) / delta;

  // angle needs to be flipped
  float flippedRotation = stampRotation * -1.0;

  vec2 depthDelta = depthTopRight - depthBottomLeft;
  vec2 depthSamplePosition = (cameraUv - depthBottomLeft) / depthDelta;

  float stepW = 1.0 / (depthWidth + 2.0);
  float stepH = 1.0 / (depthHeight + 2.0);

  float stampAspectRatio = stampWidth / stampHeight;

  // apply rotation and scale transforms manually
  float aSin = sin(flippedRotation);
  float aCos = cos(flippedRotation);

  mat2 rotationMatrix = mat2(aCos, -aSin, aSin, aCos);
  mat2 scaleMatrix = mat2(stampAspectRatio, 0.0, 0.0, 1.0);
  mat2 scaleMatrixInverse = mat2(1.0 / stampAspectRatio, 0.0, 0.0, 1.0);

  samplePosition -= 0.5;
  samplePosition = scaleMatrixInverse * rotationMatrix * scaleMatrix * samplePosition;
  samplePosition += 0.5;

  bool outOfBounds = (samplePosition.x < 0.0
    || samplePosition.x > 1.0
    || samplePosition.y < 0.0
    || samplePosition.y > 1.0
  );
`;

/**
 * Snippet to find frag color for stamp meshes.
 * First, extruder index is sampled from the stamp texture,
 * Next, the index is used for a color lookup.
 * Finally, frag alpha is determined by the occlusion map.
 */
const getStampColor = `
  float depthWithOcclusion = -3.402823466e+38;

  for (float y = -1.0; y <= 1.0; y++) {
    for (float x = -1.0; x <= 1.0; x++) {
      depthWithOcclusion = max(
        depthWithOcclusion,
        unpack(texture2D(depthTexture, depthSamplePosition + vec2(x * stepW, y * stepH)))
      );
    }
  }

  // initialize vector to hold stamp pixel color
  vec4 textureColor;

  // extruder indices are first scaled to 0-255 range, then baked into a texture
  // texture sampling gives back values in 0-1 range
  vec4 sampledIndex = texture2D(stampTexture, samplePosition);

  // sampled index of 0 means transparent pixel
  // only query for color if sampled index is greater than 0
  if (sampledIndex.x > 0.0) {
    // need to compute the actual index by multiplying sampled value by 255
    int extruderIndex = int(sampledIndex.x * 255.0 - 1.0);
    GET_EXTRUDER_COLOR(extruderIndex, textureColor.xyz);
    textureColor.a = 1.0;
  }

  textureColor.a *= float(depthWithoutOcclusion <= depthWithOcclusion + ${CONSTANTS.depthTolerance});
  textureColor.a *= float(!outOfBounds);
`;

/**
 * Includes function to compute lambert reflection brightness
 *  reference: https://en.wikipedia.org/wiki/Lambertian_reflectance
 * @param {vec3} norm - surface normal
 * @param {vec3} lightDir - normalized light direction vector
 * @return {float} reflection brightness
 */
const includeGetLambert = `
  float getLambert(vec3 norm, vec3 lightDir, bool highlighted) {
    vec3 normalizedNormal = normalize(norm);
    vec3 normalizedLightDirection = normalize(lightDir);

    float intensity = ${CONSTANTS.intensityMultiplier};
    if (highlighted) intensity *= ${CONSTANTS.highlightMultiplier};

    float result = dot(normalizedNormal, normalizedLightDirection) * intensity;
    return max(result, ${CONSTANTS.lambertThrehsold});
  }
`;

/**
 * Includes function to apply lambert shading to a given surface color and light color
 * @param {vec3} triColor - color on surface (i.e., triangle color)
 * @param {vec3} lightColor - color of light (i.e., ambient light)
 * @return {vec3} a new RGB color
 */
const includeApplyLambert = `
  vec3 applyLambert(vec3 triColor, vec3 lightColor, float lambert) {
    return (triColor * lambert)
      + (lightColor * (1.0-lambert) * ${CONSTANTS.diffuseModifier});
  }
`;

/**
 * Includes function to clamp a given RGB color to a given boundary
 * @param {vec3} color - given RGB color
 * @return {vec3} a new RGB color
 */
const includeClampColor = `
  vec3 clampColor(vec3 color) {
    vec3 clampedColor;
    clampedColor.x = clamp(color.x, ${CONSTANTS.darknessLowerBound}, 1.0);
    clampedColor.y = clamp(color.y, ${CONSTANTS.darknessLowerBound}, 1.0);
    clampedColor.z = clamp(color.z, ${CONSTANTS.darknessLowerBound}, 1.0);
    return clampedColor;
  }
`;

/**
 * Includes function to return a RGB color for marking custom supports
 * @param {bool} needSupport - whether to mark custom support
 * @return {vec3} a new RGB color
 */
const includeGetSupportColor = `
  vec3 getSupportColor(bool needSupport) {
    vec3 supportColor;
    if (needSupport) {
      supportColor = ${composeVec3FromRGB(SUPPORT_FACE_COLORS[0])};
    } else {
      supportColor = ${composeVec3FromRGB(SUPPORT_FACE_COLORS[1])};
    }
    return supportColor;
  }
`;

/**
 * Includes macro to find the color for a given extruder index
 * @param {number} extruderIndex - extruder index of triangle
 * @param {vec3} extruderColor - vector to store found extruder color
 */
const includeGetExtruderColor = `
  #define GET_EXTRUDER_COLOR(extruderIndex, extruderColor)\
    for ( int i = 0; i < NUM_EXTRUDER_COLORS ; i ++ ) {\
      if (i == extruderIndex) {\
        extruderColor = extruderColors[ i ].color;\
        break;\
      }\
    }\
`;

const defaultMeshVertexShader = `
  ${defineColorStruct}
  ${includePointLight}
  ${includeGetSupportColor}
  ${includeExtruderColors}
  ${includeGetExtruderColor}

  uniform bool inSupportView;

  attribute float color;
  attribute float support;

  varying vec3 vColor;
  varying vec3 vNormal;
  varying vec3 vlightDirection;
  varying float forceHighlight;

  void main() {
    int extruderIndex = int(color);

    if (extruderIndex < 0) {
      // special case; add 1 and flip sign to retrieve original extruder index
      extruderIndex = (extruderIndex + 1) * -1;
      // set flag to force highlight on targeted face
      forceHighlight = 1.0;
    }

    GET_EXTRUDER_COLOR(extruderIndex, vColor);

    if (inSupportView) {
      vColor = getSupportColor(support > 0.0);
    }

    vNormal = normalMatrix * normal;
    vlightDirection = normalize(pointLights[0].position - (modelViewMatrix * vec4(position, 1.0)).xyz);

    gl_Position =  projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

const defaultMeshFragmentShader = `
  ${includeGetLambert}
  ${includeApplyLambert}
  ${includeClampColor}

  uniform vec3 ambientLightColor;
  uniform bool highlighted;

  varying vec3 vColor;
  varying vec3 vNormal;
  varying vec3 vlightDirection;
  varying float forceHighlight;

  void main() {
    bool needsHighlight = highlighted;
    if (forceHighlight == 1.0) {
      needsHighlight = true;
    }

    #ifdef DOUBLE_SIDED
      vec3 surfaceNormal = vNormal * ( float( gl_FrontFacing ) * 2.0 - 1.0 );
      float lambert = getLambert(surfaceNormal, vlightDirection, needsHighlight);
    #else
      float lambert = getLambert(vNormal, vlightDirection, needsHighlight);
    #endif

    vec3 clampedColor = clampColor(vColor);
    vec3 result = applyLambert(clampedColor, ambientLightColor, lambert);

    gl_FragColor = vec4(result, 1.0);
  }
`;

const defaultTowerVertexShader = `
  ${includePointLight}

  varying vec3 vNormal;
  varying vec3 vlightDirection;

  void main() {
    vNormal = normalMatrix * normal;
    vlightDirection = normalize(pointLights[0].position - (modelViewMatrix * vec4(position, 1.0)).xyz);

    gl_Position =  projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

const defaultTowerFragmentShader = `
  ${includeGetLambert}
  ${includeApplyLambert}
  ${includeClampColor}

  uniform vec3 ambientLightColor;
  uniform bool highlighted;

  varying vec3 vNormal;
  varying vec3 vlightDirection;

  void main() {
    // default color is too dark for the highlighting to be noticeable
    // manually set a brighter color on tower as well
    vec3 towerColor = vec3(0.5);
    if (highlighted) towerColor = vec3(0.6);

    float lambert = getLambert(vNormal, vlightDirection, highlighted);
    vec3 clampedColor = clampColor(towerColor);
    vec3 result = applyLambert(clampedColor, ambientLightColor, lambert);

    gl_FragColor = vec4(result, 1.0);
  }
`;

const depthVertexShader = `
  varying float occlusionDepth;
  void main() {
    vec4 pos = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    occlusionDepth = ((pos.z/pos.w) + 1.0) * 0.49;
    gl_Position = pos;
  }
`;

const depthFragmentShader = `
  const vec4 bitSh = vec4(256. * 256. * 256., 256. * 256., 256., 1.);
  const vec4 bitMsk = vec4(0.,vec3(1./256.0));
  const vec4 bitShifts = vec4(1.) / bitSh;

  varying float occlusionDepth;

  vec4 pack (float value) {
      vec4 comp = fract(value * bitSh);
      comp -= comp.xxyz * bitMsk;
      return comp;
  }

  void main() {
    gl_FragColor = pack( occlusionDepth );
  }
`;

const renderStampVertexShader = `
  ${includePointLight}

  uniform mat4 cameraViewMatrix;
  uniform mat4 cameraProjectionMatrix;
  uniform vec3 cameraDirection;

  varying vec2 cameraUv;
  varying float depthWithoutOcclusion;
  varying float cameraDotNorm;

  varying vec3 vNormal;
  varying vec3 vlightDirection;

  void main() {
    vec4 cameraPos = cameraProjectionMatrix
      * cameraViewMatrix
      * modelMatrix
      * vec4(position, 1.0);

    cameraPos /= cameraPos.w;
    depthWithoutOcclusion = (cameraPos.z + 1.0) * 0.49;
    cameraUv = ((cameraPos.xy + 1.0) * 0.5);

    // We cannot use the normal matrix here because it contains the view matrix as well.
    // And our orientation comparison should be to the stamp camera
    vec4 orientedNormal = modelMatrix * vec4(normal, 0.0);
    cameraDotNorm = dot(orientedNormal.xyz, -cameraDirection);

    gl_Position = (projectionMatrix * modelViewMatrix * vec4(position, 1.0));

    vlightDirection = normalize(  pointLights[0].position - (modelViewMatrix * vec4(position, 1.0)).xyz );
    vNormal = normalMatrix * normal;
  }
`;

const renderStampFragmentShader = `
  ${defineColorStruct}
  ${includeGetLambert}
  ${includeApplyLambert}
  ${includeClampColor}
  ${includeExtruderColors}
  ${includeGetExtruderColor}

  uniform sampler2D stampTexture;
  uniform sampler2D depthTexture;

  uniform vec2 stampTopRight;
  uniform vec2 stampBottomLeft;
  uniform float stampWidth;
  uniform float stampHeight;
  uniform float stampRotation;

  uniform vec2 depthTopRight;
  uniform vec2 depthBottomLeft;
  uniform float depthWidth;
  uniform float depthHeight;

  uniform vec3 ambientLightColor;
  uniform bool highlighted;
  uniform bool translucent;

  varying vec2 cameraUv;
  varying float depthWithoutOcclusion;
  varying float cameraDotNorm;

  varying vec3 vNormal;
  varying vec3 vlightDirection;

  const vec4 bitSh = vec4(256.0 * 256.0 * 256.0, 256.0 * 256.0, 256.0, 1.0);
  const vec4 bitInv = 1.0 / bitSh;

  float unpack (vec4 color) {
    return dot(color, bitInv);
  }

  void main() {
    ${getSamplingPosition}

    if (cameraDotNorm > ${CONSTANTS.normalTolerance}) {
      ${getStampColor}

      float lambert = getLambert(vNormal, vlightDirection, highlighted);
      vec3 clampedColor = clampColor(textureColor.xyz);
      vec3 result = applyLambert(clampedColor, ambientLightColor, lambert);

      if (translucent) textureColor.a *= 0.5;

      gl_FragColor = vec4(result, textureColor.a);
    } else {
      gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
    }
  }
`;

const atlasFaceVertexShader = `
  ${defineColorStruct}
  ${includeExtruderColors}
  ${includeGetExtruderColor}

  attribute float color;

  varying vec3 vColor;

  void main() {
    int extruderIndex = int(color);

    GET_EXTRUDER_COLOR(extruderIndex, vColor);

    gl_Position = vec4((uv.x * 2.0 - 1.0), (uv.y * 2.0 - 1.0), 0.0, 1.0);
  }
`;

const atlasFaceFragmentShader = `
  varying vec3 vColor;

  void main() {
    gl_FragColor = vec4(vColor, 1.0);
  }
`;

const atlasStampVertexShader = `
  uniform mat4 cameraViewMatrix;
  uniform mat4 cameraProjectionMatrix;
  uniform vec3 cameraDirection;

  varying vec2 cameraUv;
  varying float depthWithoutOcclusion;
  varying float cameraDotNorm;

  void main() {
    vec4 cameraPos = cameraProjectionMatrix
      * cameraViewMatrix
      * modelMatrix
      * vec4(position, 1.0);

    cameraPos /= cameraPos.w;
    depthWithoutOcclusion = (cameraPos.z + 1.0) * 0.49;
    cameraUv = ((cameraPos.xy + 1.0) * 0.5);

    // We cannot use the normal matrix here because it contains the view matrix as well.
    // And our orientation comparison should be to the stamp camera
    vec4 orientedNormal = modelMatrix * vec4(normal, 0.0);
    cameraDotNorm = dot(orientedNormal.xyz, -cameraDirection);

    gl_Position = vec4((uv.x * 2.0 - 1.0), (uv.y * 2.0 - 1.0), 0.0, 1.0);
  }
`;

const atlasStampFragmentShader = `
  ${defineColorStruct}
  ${includeExtruderColors}
  ${includeGetExtruderColor}

  uniform sampler2D stampTexture;
  uniform sampler2D depthTexture;

  uniform vec2 stampTopRight;
  uniform vec2 stampBottomLeft;
  uniform float stampWidth;
  uniform float stampHeight;
  uniform float stampRotation;

  uniform vec2 depthTopRight;
  uniform vec2 depthBottomLeft;
  uniform float depthWidth;
  uniform float depthHeight;

  varying vec2 cameraUv;
  varying float depthWithoutOcclusion;
  varying float cameraDotNorm;

  const vec4 bitSh = vec4(256.0 * 256.0 * 256.0, 256.0 * 256.0, 256.0, 1.0);
  const vec4 bitInv = 1.0 / bitSh;

  float unpack (vec4 color) {
    return dot(color, bitInv);
  }

  void main() {
    ${getSamplingPosition}

    if (cameraDotNorm > ${CONSTANTS.normalTolerance}) {
      ${getStampColor}

      // actual stamp color
      gl_FragColor = textureColor;

    } else {
      discard;
    }
  }
`;

const atlasDilateVertexShader = `
  varying vec2 vUv;

  void main() {
    vUv = uv;
    gl_Position = vec4((uv.x * 2.0 - 1.0), (uv.y * 2.0 - 1.0), 0.0, 1.0);
  }
`;

const atlasDilateFragmentShader = `
  varying vec2 vUv;

  uniform float atlasWidth;
  uniform float atlasHeight;
  uniform sampler2D quadTexture;

  bool isPixelColored(vec4 pixel) {
    return pixel.a > 0.0;
  }

  void main() {
    // steps for neighbor pixels
    float stepU = 1.0 / atlasWidth;
    float stepV = 1.0 / atlasHeight;

    vec4 currentPixel;
    vec2 offset;

    for (float x = -2.0; x <= 2.0; x += 1.0) {
      for (float y = -2.0; y <= 2.0; y += 1.0) {
        offset = vec2(stepU * x, stepV * y);
        currentPixel = texture2D(quadTexture, vUv + offset);

        if (isPixelColored(currentPixel)) {
          gl_FragColor = currentPixel;
          break;
        }
      }
    }
  }
`;

const quantizeVertexShader = `
  varying vec2 vUv;

  void main() {
    vUv = uv;
    gl_Position = vec4((uv.x * 2.0 - 1.0), (uv.y * 2.0 - 1.0), 0.0, 1.0);
  }
`;

const quantizeFragmentShader = `
  ${defineColorStruct}
  ${includeExtruderColors}
  ${includePosterizedColors}

  uniform int colorMap[ NUM_POSTERIZED_COLORS ];
  uniform sampler2D posterizedImg;

  varying vec2 vUv;

  void main() {
    // sample a pixel from the posterized image
    vec4 currentPixel = texture2D(posterizedImg, vUv);

    // initialize current color index
    int currentColorIdx;

    // initialize current distance
    float currentDistance;

    // initialize mapped color
    vec3 mappedColor;

    // find index of current color
    for (int mapIdx = 0; mapIdx < NUM_POSTERIZED_COLORS; mapIdx += 1) {
      currentDistance = distance(currentPixel.xyz, posterizedColors[mapIdx].color);
      if (currentDistance == 0.0) {
        // found it
        currentColorIdx = colorMap[ mapIdx ];

        // transparent color
        if (currentColorIdx == -1) break;

        // get the right extruder color
        // cannot do something like extruderColors[currentColorIdx] because index has to be a loop index
        // https://stackoverflow.com/questions/19529690/index-expression-must-be-constant-webgl-glsl-error

        for (int colorIdx = 0; colorIdx < NUM_EXTRUDER_COLORS ; colorIdx += 1) {
          if (colorIdx != currentColorIdx) continue;
          mappedColor = extruderColors[ colorIdx ].color;
        }

        break;
      }
    }

    if (currentPixel.a > 0.0) {
      if (currentColorIdx == -1) {
        // set transparent color
        gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
      } else {
        // set mapped color
        gl_FragColor = vec4(mappedColor, 1.0);
      }
    } else {
      // set transparent color
      gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
    }
  }
`;

export const SHADER_CHUNKS = {
  defaultMeshVertexShader,
  defaultMeshFragmentShader,
  defaultTowerVertexShader,
  defaultTowerFragmentShader,
  depthVertexShader,
  depthFragmentShader,
  quantizeVertexShader,
  quantizeFragmentShader,
  renderStampVertexShader,
  renderStampFragmentShader,
  atlasFaceVertexShader,
  atlasFaceFragmentShader,
  atlasStampVertexShader,
  atlasStampFragmentShader,
  atlasDilateVertexShader,
  atlasDilateFragmentShader,
};

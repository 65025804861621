import styled, { css } from 'styled-components';

import { Shadows } from '../../../../themes';
import { SlicerUtils } from '../../../../utils';

export const floatingPanelStyles = css`
  position: absolute;
  z-index: 998;
  right: 0.75rem;
  top: 3.25rem;
  background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  border-radius: 0.25rem;
  ${Shadows.d2};
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0.75rem;
  gap: 0.25rem;
`;

export const PanelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
`;

export const NewNotificationsCount = styled.div`
  background: ${(props) => props.theme.colors.grey3};
  color: ${(props) => props.theme.colors.grey5};
  border-radius: 0.5rem;
  width: 1.8rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: ${(props) => props.theme.fonts.weightBold};
  user-select: none;
`;

export const ActiveJobCount = styled.div`
  background-color: rgba(
    ${(props) =>
      SlicerUtils.hexStringToRGBA(props.theme.colors.greenDefault, 0.3).join(
        ', '
      )}
  );
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
`;

import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { Link } from 'react-router-dom';

import Routes from '../../../../router/routes';
import { Icons } from '../../../../themes';

import {
  SideBarToggleIconWrapper,
  TopLogoWrapper,
  BackButtonWrapper,
  OpenModalWrapper,
  OpenModalContent,
  ClosedBar,
  MenuButtonWrapper,
} from './headerBarLeft.styles';

import Icon from '../../../icon/icon.jsx';
import Logo from '../../../logo/logo.jsx';
import SideBar from './sideBar/sideBar.jsx';
import Drawer from '../../drawer/drawer.jsx';

class HeaderBarLeft extends Component {
  static defaultProps = {
    pageComponent: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSideBarOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.createProjectPending &&
      !this.props.createProjectPending &&
      this.props.createProjectSuccess
    ) {
      this.closeSideBar();
    }
  }

  toggleSideBar() {
    this.setState({
      isSideBarOpen: !this.state.isSideBarOpen,
    });
  }

  closeSideBar() {
    this.setState({
      isSideBarOpen: false,
    });
  }

  renderOpen() {
    if (!this.state.isSideBarOpen) return null;
    return (
      <OpenModalWrapper pageComponent={this.props.pageComponent}>
        <SideBar onMarginClick={() => this.closeSideBar()}>
          <OpenModalContent>
            <Drawer />
          </OpenModalContent>
        </SideBar>
      </OpenModalWrapper>
    );
  }

  renderClosed() {
    return (
      <ClosedBar pageComponent={this.props.pageComponent}>
        <MenuButtonWrapper
          pageComponent={this.props.pageComponent}
          isSideBarOpen={this.state.isSideBarOpen}>
          {this.renderIcon()}
        </MenuButtonWrapper>
        {this.renderLogo()}
        {this.renderBackButton()}
      </ClosedBar>
    );
  }

  renderIcon() {
    const { navStack } = this.props;
    return (
      <SideBarToggleIconWrapper
        active={navStack.length <= 1}
        onClick={() => this.toggleSideBar()}>
        <Icon
          src={this.state.isSideBarOpen ? Icons.basic.x : Icons.basic.menu}
          color={this.props.theme.grey6}
        />
      </SideBarToggleIconWrapper>
    );
  }

  renderLogo() {
    return (
      <TopLogoWrapper isSideBarOpen={this.state.isSideBarOpen}>
        <Link to={Routes.toHome()}>
          <Logo width='105' onClick={() => this.closeSideBar()} />
        </Link>
      </TopLogoWrapper>
    );
  }

  renderBackButton() {
    const { navStack } = this.props;
    const { isSideBarOpen } = this.props;
    if (navStack.length < 2 || isSideBarOpen) return null;
    return (
      <BackButtonWrapper
        onClick={() =>
          this.props.history.push(navStack[navStack.length - 2].route)
        }>
        <Icon src={Icons.basic.arrowLeft} />
      </BackButtonWrapper>
    );
  }

  render() {
    return (
      <>
        {this.renderOpen()}
        {this.renderClosed()}
      </>
    );
  }
}

export default withTheme(HeaderBarLeft);

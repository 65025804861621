import React from 'react';
import _ from 'lodash';

import { ConfirmationModal } from '../../../shared';

const DeleteModal = ({ selectedProjects, onCancel, onConfirm }) => {
  let primaryLabel = '';
  let secondaryLabel = '';
  let tertiaryLabel = '';
  let warningLabel = '';

  if (selectedProjects.length === 1) {
    const isFolder = selectedProjects[0].directory;
    const wording = isFolder
      ? ['folder', 'folder and the files within it']
      : ['project', 'project'];

    primaryLabel = `Delete ${selectedProjects[0].name}?`;
    secondaryLabel = `Are you sure you would like to remove this ${wording[0]}?`;
    tertiaryLabel = `You will not be able to recover this ${wording[1]} after it is deleted.`;

    if (
      selectedProjects[0].shareData &&
      selectedProjects[0].shareData.shareId
    ) {
      warningLabel =
        'This project is currently being shared. Deleting this project will also delete the shared copy.';
    }
  } else {
    const includesFolder = selectedProjects.some(
      (project) => project.directory
    );
    const wording = includesFolder
      ? ['files', 'files', 'folders and projects']
      : ['projects', 'projects', 'projects'];

    // TODO: calculate total number of projects to delete?
    primaryLabel = `Delete ${selectedProjects.length} ${wording[0]}?`;
    secondaryLabel = `Are you sure you would like to remove these ${wording[1]}?`;
    tertiaryLabel = `You will not be able to recover these ${wording[2]} after they are deleted.`;

    const sharedProjectsCount = _.reduce(
      selectedProjects,
      (count, project) => {
        if (project.shareData && project.shareData.shareId) return count + 1;
        return count;
      },
      0
    );
    if (sharedProjectsCount > 0) {
      const warningWording =
        sharedProjectsCount > 1
          ? ['are', 'these projects', 'copies']
          : ['is', 'this project', 'copy'];
      warningLabel = `${sharedProjectsCount} of these projects ${warningWording[0]} currently being shared. Deleting ${warningWording[1]} will also delete the shared ${warningWording[2]}.`;
    }
  }

  return (
    <ConfirmationModal
      isWarning
      primaryLabel={primaryLabel}
      secondaryLabel={secondaryLabel}
      tertiaryLabel={`${warningLabel} ${tertiaryLabel}`}
      onClickCancel={() => onCancel()}
      onClickConfirm={() => onConfirm()}
    />
  );
};

export default DeleteModal;

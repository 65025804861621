import React from 'react';

import { Progress, Image } from '../../../../shared';
import { ThumbnailWrapper } from '../importProject.styles';

import { Body1 } from '../../../../shared/typography/typography';

const StaticThumbnail = (props) => {
  const { currentSharedProject, thumbnailLoaded } = props;
  const { name, thumbnail, thumbnailDataUrl } = currentSharedProject;
  if (thumbnail) {
    if (thumbnailLoaded) {
      return (
        <ThumbnailWrapper>
          <Image alt={`${name} thumbnail preview`} src={thumbnailDataUrl} />
        </ThumbnailWrapper>
      );
    }
    // project thumbnail is still loading
    return <Progress circular />;
  }
  // project has no thumbnail, or thumbnail failed to load
  return (
    <ThumbnailWrapper>
      <Body1 grey>No preview available</Body1>
    </ThumbnailWrapper>
  );
};

export default StaticThumbnail;

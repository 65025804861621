import 'regenerator-runtime/runtime';
import { call, select } from 'redux-saga/effects';

import { getThreeState, handleGenericError } from '../common';
import { updateTopBottomUniforms } from './updateToolpath';
import { setRenderFlag } from './animationFrame';

export default function* addToolpath(action) {
  try {
    const { scene } = yield select(getThreeState);
    const { toolpath } = action.payload;

    if (!toolpath.version || !toolpath.extrusions) {
      return;
    }

    const minLayer = toolpath.layerHeights[toolpath.minLayer];
    const maxLayer = toolpath.layerHeights[toolpath.maxLayer - 1];

    const {
      extrusions,
      showTravel,
      travels,
      showRetracts,
      retracts,
      showRestarts,
      restarts,
    } = toolpath;

    if (extrusions) {
      updateTopBottomUniforms(extrusions, maxLayer, minLayer);
      scene.add(extrusions);
    }
    if (showTravel) {
      updateTopBottomUniforms(travels, maxLayer, minLayer);
      scene.add(travels);
    }
    if (showRetracts) {
      updateTopBottomUniforms(retracts, maxLayer, minLayer);
      scene.add(retracts);
    }
    if (showRestarts) {
      updateTopBottomUniforms(restarts, maxLayer, minLayer);
      scene.add(restarts);
    }
    setRenderFlag();
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

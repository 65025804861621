import { connect } from 'react-redux';

import UploadProfile from '../uploadProfile.jsx';
import { actions } from '../../../../reducers/printers/printers';

const mapStateToProps = (state) => ({
  mode: 'printer',
  requestSuccess: state.printers.status.parseProfileSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  parseProfile: (file) => dispatch(actions.parseProfileRequest(file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadProfile);

import React from 'react';

import { Body1 } from '../../../../shared/typography/typography';
import { Image } from '../../../../shared';

const metadata = {
  title: 'New material panel for power users',
  proceedButtonLabel: 'Done',
  content: (theme) => (
    <>
      <Body1 noSpacing>
        A list view option is added to the materials panel to display material
        color, profiles, and overrides.
      </Body1>
      <Image
        alt='New material panel'
        src={theme.images.onboarding.newMaterialsPanel}
      />
    </>
  ),
};

export default metadata;

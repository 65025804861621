import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Body1, Icon, ColorSwatch } from '../../../../shared';
import { Icons } from '../../../../themes';
import { MaterialUtils } from '../../../../utils';

import {
  Swatches,
  SourceInputs,
  TargetInputContainer,
  TargetInputSelection,
  TargetInputIcon,
  TargetInputOption,
  TargetInputs,
} from './InputOptions.styles';

const initialState = { showSelectionForInput: -1 };

const InputOptions = (props) => {
  const [didMount, setDidMount] = useState(false);
  const { remapperContext: context, setTargetInputs: setInputs } = props;

  useEffect(() => {
    if (didMount) {
      return;
    }

    const { existingInputsSeen, availableInputCount } = context;

    // if required input count is 1, automatically map all extra inputs to 0;
    //   otherwise, map the indices to -1, indicating that inputs still need to be mapped
    const defaultMappedIndex = availableInputCount === 1 ? 0 : -1;
    const initialMap = new Array(existingInputsSeen.length).fill(
      defaultMappedIndex
    );

    setDidMount(true);
    setInputs(initialMap);
  }, [context, setInputs, didMount]);

  const [state, setState] = useState(initialState);
  const renderInfo = () => {
    const { remapperContext } = props;
    const { availableInputCount } = remapperContext;

    return (
      <Body1 noSpacing>
        {availableInputCount === 1
          ? 'Let Canvas automatically update your project to use a single input. Note that all existing color data will be removed.'
          : 'Use this option if you wish to continue using the existing configuration. All color data associated with a given existing input will be updated to use the color of the target input of your choice.'}
      </Body1>
    );
  };

  const onSelectSourceInput = (driveIndex) => {
    const { targetInputs } = props;
    const { existingInputsSeen } = context;

    const updatedTargetInputs = _.map(targetInputs, (mappedIndex, curIndex) => {
      if (existingInputsSeen[curIndex]) return driveIndex;
      return mappedIndex;
    });
    props.setTargetInputs(updatedTargetInputs);
  };

  const renderSourceInputs = () => {
    const { remapperContext } = props;
    const { availableInputCount, colors, existingInputsSeen } = remapperContext;

    const colorSwatches = (
      <Swatches count={colors.length}>
        {_.map(colors, (color, driveIndex) => (
          <ColorSwatch
            static
            forceShowLabel
            disabled={!existingInputsSeen[driveIndex]}
            color={color}
            key={driveIndex}
            label={driveIndex + 1}
            onSelect={
              driveIndex <= availableInputCount - 1
                ? () => onSelectSourceInput(driveIndex)
                : null
            }
          />
        ))}
      </Swatches>
    );

    return (
      <SourceInputs>
        <Body1>Existing inputs</Body1>
        {colorSwatches}
      </SourceInputs>
    );
  };

  const renderTargetInputIcon = () => {
    return (
      <TargetInputIcon>
        <Icon src={Icons.basic.chevronDown} />
      </TargetInputIcon>
    );
  };

  const onMapTargetInput = (targetInputIndex, mappedInputIndex) => {
    const updatedTargetInputs = [...props.targetInputs];
    updatedTargetInputs[targetInputIndex] = mappedInputIndex;

    setState((oldState) => ({
      ...oldState,
      showSelectionForInput: -1,
    }));

    props.setTargetInputs(updatedTargetInputs);
  };

  const renderTargetInputSelection = (targetInputIndex) => {
    const totalCount = props.targetInputs.length;
    const { colors, availableInputCount } = props.remapperContext;
    const alignDirection = targetInputIndex < totalCount / 2 ? 'left' : 'right';

    return (
      <TargetInputSelection align={alignDirection}>
        {_.map(colors.slice(0, availableInputCount), (color, sourceIndex) => (
          <TargetInputOption
            key={sourceIndex}
            color={color}
            bright={MaterialUtils.isColorBright(color)}
            onClick={() => onMapTargetInput(targetInputIndex, sourceIndex)}>
            {sourceIndex + 1}
          </TargetInputOption>
        ))}
      </TargetInputSelection>
    );
  };

  const onToggleTargetInput = (driveIndex) => {
    const { showSelectionForInput } = state;
    if (showSelectionForInput !== -1) {
      setState({
        showSelectionForInput:
          showSelectionForInput === driveIndex ? -1 : driveIndex,
      });
    } else {
      setState({
        showSelectionForInput: driveIndex,
      });
    }
  };

  const renderTargetInputs = () => {
    const { remapperContext, targetInputs } = props;
    const { colors, existingInputsSeen } = remapperContext;
    const { showSelectionForInput } = state;

    const colorSwatches = (
      <Swatches count={targetInputs.length}>
        {_.map(targetInputs, (mappedInputIndex, targetInputIndex) => (
          <TargetInputContainer key={targetInputIndex}>
            <ColorSwatch
              static
              forceShowLabel
              disabled={!existingInputsSeen[targetInputIndex]}
              key={targetInputIndex}
              color={
                mappedInputIndex < 0 ? [0, 0, 0, 0] : colors[mappedInputIndex]
              }
              label={mappedInputIndex < 0 ? '' : mappedInputIndex + 1}
              onSelect={() => onToggleTargetInput(targetInputIndex)}
            />
            {showSelectionForInput === targetInputIndex &&
              renderTargetInputSelection(targetInputIndex)}
            {mappedInputIndex < 0 &&
              existingInputsSeen[targetInputIndex] &&
              renderTargetInputIcon()}
          </TargetInputContainer>
        ))}
      </Swatches>
    );

    return (
      <TargetInputs>
        <Body1>Target inputs</Body1>
        {colorSwatches}
      </TargetInputs>
    );
  };

  const { availableInputCount } = props.remapperContext;
  const { remapTriggeredManually, currentlySelected } = props;

  const shouldShow = currentlySelected || remapTriggeredManually;

  return (
    shouldShow && (
      <>
        {!remapTriggeredManually && renderInfo()}
        {availableInputCount > 1 && renderSourceInputs()}
        {availableInputCount > 1 && renderTargetInputs()}
      </>
    )
  );
};

export default InputOptions;

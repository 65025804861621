import React from 'react';

import AbstractDropdown from '../abstractDropdown/abstractDropdown.jsx';
import {
  StyledContainer,
  StyledSelect,
  StyledSelectedOptionText,
} from './dropdownButton.styles';

function DropdownButton(props) {
  return (
    <AbstractDropdown
      {...props}
      StyledContainer={StyledContainer}
      StyledSelect={StyledSelect}
      StyledSelectedOptionText={StyledSelectedOptionText}
      icon={null}
    />
  );
}

export default DropdownButton;

import styled from 'styled-components';
import { StyledContainerBase } from '../../abstractInput/abstractInput.styles';

export const FilterBarInputContainer = styled(StyledContainerBase)`
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  min-height: 0;
  height: 2rem;
  margin: 0;
  padding: 0;
  padding-bottom: 0.575rem;
  display: flex;
  align-self: center;
  overflow-y: hidden;
  input {
    padding-left: 0.75rem;
    padding-top: 0.65rem;
  }
`;

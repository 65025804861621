import React from 'react';
import _ from 'lodash';
import { Body1 } from '../../../../shared';
import DeviceDropdown from '../../../../shared/deviceDropdown/deviceDropdown.jsx';
import { ProjectUtils } from '../../../../utils';

const deviceMap = {
  none: null,
  'p-plus-accessory': {
    type: 'palette',
    model: 'p-plus',
    accessoryMode: true,
  },
  p2: {
    type: 'palette-2',
    model: 'p2',
    accessoryMode: false,
  },
  'p2-accessory': {
    type: 'palette-2',
    model: 'p2',
    accessoryMode: true,
  },
  'p2-pro': {
    type: 'palette-2',
    model: 'p2-pro',
    accessoryMode: false,
  },
  'p2-pro-accessory': {
    type: 'palette-2',
    model: 'p2-pro',
    accessoryMode: true,
  },
  p2s: {
    type: 'palette-2',
    model: 'p2s',
    accessoryMode: false,
  },
  'p2s-accessory': {
    type: 'palette-2',
    model: 'p2s',
    accessoryMode: true,
  },
  'p2s-pro': {
    type: 'palette-2',
    model: 'p2s-pro',
    accessoryMode: false,
  },
  'p2s-pro-accessory': {
    type: 'palette-2',
    model: 'p2s-pro',
    accessoryMode: true,
  },
  p3: {
    type: 'palette-3',
    model: 'p3',
    accessoryMode: false,
  },
  'p3-accessory': {
    type: 'palette-3',
    model: 'p3',
    accessoryMode: true,
  },
  'p3-pro': {
    type: 'palette-3',
    model: 'p3-pro',
    accessoryMode: false,
  },
  'p3-pro-accessory': {
    type: 'palette-3',
    model: 'p3-pro',
    accessoryMode: true,
  },
};

const DeviceConfigOptions = (props) => {
  const {
    remapTriggeredManually,
    deviceConfig,
    setDeviceConfig,
    usedInputCount,
    printer,
    currentlySelected,
  } = props;

  if (remapTriggeredManually) return null;

  const incompatibleDeviceConfigs = {};

  _.forEach(deviceMap, (config, dropdownName) => {
    const currentInputCount = ProjectUtils.getInputCount(config, printer);
    if (usedInputCount > currentInputCount) {
      incompatibleDeviceConfigs[dropdownName] = config;
    }
  });

  return (
    currentlySelected && (
      <>
        <Body1>
          Use this option if you wish to continue using the same number of
          inputs already in use.
        </Body1>

        {_.size(incompatibleDeviceConfigs) === _.size(deviceMap) ? (
          <Body1 red>No compatible device found</Body1>
        ) : (
          <DeviceDropdown
            deviceConfig={deviceConfig}
            onChange={setDeviceConfig}
            disabledDeviceConfigs={incompatibleDeviceConfigs}
          />
        )}
      </>
    )
  );
};

export default DeviceConfigOptions;

import styled, { css } from 'styled-components';
import { Breakpoints } from '../../../../themes';
import { H6 } from '../../../typography/typography';

export const StyledPageTitle = styled.div`
  margin: 0 auto;
  min-width: 0;
`;

export const NavigationStack = styled.div`
  display: flex;
  padding: 0 0.1rem 0 0.2rem;
  align-items: center;
  overflow: hidden;
  flex-grow: 1;

  @media (max-width: ${Breakpoints.smallWidth}) {
    display: none;
  }
`;

export const PageName = styled.div`
  display: none;
  align-items: center;

  @media (max-width: ${Breakpoints.smallWidth}) {
    display: inline-block;
    max-width: calc(100vw - 10rem);
  }
`;

export const StyledStackedPageTitle = styled(H6)`
  text-align: center;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  color: ${(props) => props.theme.colors.textSecondary};
  transition: color 0.2s;

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      color: ${props.theme.colors.textSecondary};
    `};

  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.textPrimary};
  }

  :last-of-type {
    ${(props) =>
      !props.disabled &&
      css`
        color: ${props.theme.colors.textPrimary};
      `}
  }

  @media (max-width: ${Breakpoints.smallWidth}) {
    width: 100%;
  }

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    max-width: 100%;
  }
`;

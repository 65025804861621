import _ from 'lodash';

/**
 * determine whether a user can dispatch a sliced project to Polar Cloud
 * @param userIntegrationsData object of integrations for the user
 * @param printer printer object for the project
 * @returns a boolean
 */
const canSendToPolar = (userIntegrationsData) => {
  // Check if user is linked to Polar Cloud
  if (!userIntegrationsData) return false;
  const polarCloudIntegrationData = _.find(
    userIntegrationsData,
    (integration) => integration.name === 'Polar Cloud'
  );
  if (!polarCloudIntegrationData) return false;
  if (!polarCloudIntegrationData.linked) return false;
  return true;
};

export default {
  canSendToPolar,
};

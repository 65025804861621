import React from 'react';
import AbstractCollapsiblePanel from '../abstractCollapsiblePanel/abstractCollapsiblePanel.jsx';

import { Container, Header, Content, Footer } from './collapsiblePanel.styles';

function CollapsiblePanel(props) {
  return (
    <AbstractCollapsiblePanel
      {...props}
      Container={Container}
      Header={Header}
      Content={Content}
      Footer={Footer}
      defaultCollapsiblePanel
    />
  );
}

export default CollapsiblePanel;

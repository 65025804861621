import _ from 'lodash';

import { fieldTypes } from '../../../../constants';
import Utils from './spliceSettings.utils';

import { MaterialUtils, SortUtils, FormatUtils } from '../../../../utils';

import Tooltips from './tooltips.jsx';

const getFieldsByPaletteType = (paletteType) => {
  switch (paletteType) {
    case 'palette':
      return ['heat', 'compression', 'reverse'];
    case 'palette-2':
    case 'palette-3':
      return ['heat', 'compression', 'cooling'];
    default:
      throw new Error(`Unknown Palette type '${paletteType}' encountered`);
  }
};

const shouldDisplayField = (paletteType, fieldName) => {
  if (!paletteType) return false;
  const fieldNames = getFieldsByPaletteType(paletteType);
  return fieldNames.includes(fieldName);
};

const getMinHeat = (paletteType, paletteModel) => {
  switch (paletteType) {
    case 'palette':
      return { min: 0, gte: false };
    case 'palette-2':
      switch (paletteModel) {
        case 'p2':
          return { min: -8, gte: true };
        case 'p2-pro':
          return { min: -6, gte: true };
        case 'p2s':
          return { min: -7, gte: true };
        case 'p2s-pro':
          return { min: -5, gte: true };
        default:
          throw new Error(
            `Unknown Palette model '${paletteModel}' encountered for type '${paletteType}'`
          );
      }
    case 'palette-3':
      switch (paletteModel) {
        case 'p3':
        case 'p3-pro':
          return { min: -5, gte: true };
        default:
          throw new Error(
            `Unknown Palette model '${paletteModel}' encountered for type '${paletteType}'`
          );
      }
    default:
      throw new Error(`Unknown Palette type '${paletteType}' encountered`);
  }
};

const getMaxHeat = (paletteType, paletteModel) => {
  switch (paletteType) {
    case 'palette':
      return { max: 15, lte: true };
    case 'palette-2':
      switch (paletteModel) {
        case 'p2':
          return { max: 8, lte: true };
        case 'p2-pro':
          return { max: 6, lte: true };
        case 'p2s':
          return { max: 7, lte: true };
        case 'p2s-pro':
          return { max: 5, lte: true };
        default:
          throw new Error(
            `Unknown Palette model '${paletteModel}' encountered for type '${paletteType}'`
          );
      }
    case 'palette-3':
      switch (paletteModel) {
        case 'p3':
        case 'p3-pro':
          return { max: 8, lte: true };
        default:
          throw new Error(
            `Unknown Palette model '${paletteModel}' encountered for type '${paletteType}'`
          );
      }
    default:
      throw new Error(`Unknown Palette type '${paletteType}' encountered`);
  }
};

const getMinCompression = (paletteType) => {
  switch (paletteType) {
    case 'palette':
      return { min: 0, gte: false };
    case 'palette-2':
      return { min: -10, gte: true };
    case 'palette-3':
      return { min: -6, gte: true };
    default:
      throw new Error(`Unknown Palette type '${paletteType}' encountered`);
  }
};

const getMaxCompression = (paletteType) => {
  switch (paletteType) {
    case 'palette':
      return { max: 15, lte: true };
    case 'palette-2':
      return { max: 10, lte: true };
    case 'palette-3':
      return { max: 6, lte: true };
    default:
      throw new Error(`Unknown Palette type '${paletteType}' encountered`);
  }
};

const getMinCooling = (paletteType, paletteModel) => {
  switch (paletteType) {
    case 'palette':
      throw new Error(
        `Palette type '${paletteType}' does not support cooling factor`
      );
    case 'palette-2':
      switch (paletteModel) {
        case 'p2':
          return { min: -13, gte: true };
        case 'p2-pro':
        case 'p2s':
        case 'p2s-pro':
          return { min: -10, gte: true };
        default:
          throw new Error(
            `Unknown Palette model '${paletteModel}' encountered for type '${paletteType}'`
          );
      }
    case 'palette-3':
      switch (paletteModel) {
        case 'p3':
          return { min: -6, gte: true };
        case 'p3-pro':
          return { min: -3, gte: true };
        default:
          throw new Error(
            `Unknown Palette model '${paletteModel}' encountered for type '${paletteType}'`
          );
      }
    default:
      throw new Error(`Unknown Palette type '${paletteType}' encountered`);
  }
};

const getMaxCooling = (paletteType, paletteModel) => {
  switch (paletteType) {
    case 'palette':
      throw new Error(
        `Palette type '${paletteType}' does not support cooling factor`
      );
    case 'palette-2':
      switch (paletteModel) {
        case 'p2':
          return { max: 13, lte: true };
        case 'p2-pro':
          return { max: 16, lte: true };
        case 'p2s':
          return { max: 15, lte: true };
        case 'p2s-pro':
          return { max: 18, lte: true };
        default:
          throw new Error(
            `Unknown Palette model '${paletteModel}' encountered for type '${paletteType}'`
          );
      }
    case 'palette-3':
      switch (paletteModel) {
        case 'p3':
          return { max: 20, lte: true };
        case 'p3-pro':
          return { max: 23, lte: true };
        default:
          throw new Error(
            `Unknown Palette model '${paletteModel}' encountered for type '${paletteType}'`
          );
      }
    default:
      throw new Error(`Unknown Palette type '${paletteType}' encountered`);
  }
};

function generateMaterialUsageSubLabel(materialId, projectInputMaterials) {
  const usedBy = [];
  projectInputMaterials.forEach(
    (inputMaterialId, index) =>
      inputMaterialId === materialId && usedBy.push(index + 1)
  );
  let subLabel;
  if (usedBy.length > 0) {
    subLabel = `${FormatUtils.pluralize('Input', usedBy.length)}`;
    usedBy.forEach((item, index) => {
      subLabel += index === 0 ? ` ${item}` : `, ${item}`;
    });
  }
  return subLabel;
}

const getFieldStep = (paletteType, paletteModel, fieldName) => {
  switch (paletteType) {
    case 'palette':
      return 0.5;
    case 'palette-2':
      return 1;
    case 'palette-3':
      return fieldName === 'cooling' ? 1 : 0.5;
    default:
      throw new Error(`Unknown Palette type '${paletteType}' encountered`);
  }
};

const getFieldBounds = (paletteType, paletteModel, fieldName) => {
  const step = getFieldStep(paletteType, paletteModel, fieldName);
  if (fieldName === 'heat') {
    return {
      ...getMinHeat(paletteType, paletteModel),
      ...getMaxHeat(paletteType, paletteModel),
      step,
    };
  }
  if (fieldName === 'compression') {
    return {
      ...getMinCompression(paletteType),
      ...getMaxCompression(paletteType),
      step,
    };
  }
  // fieldName === 'cooling'
  return {
    ...getMinCooling(paletteType, paletteModel),
    ...getMaxCooling(paletteType, paletteModel),
    step,
  };
};

const findPropertyValue = (state, property) => {
  const {
    paletteType,
    paletteModel,
    ingoingMaterial,
    outgoingMaterial,
    spliceSettings,
  } = state;
  const spliceCore = Utils.getSpliceCore(paletteType, paletteModel);
  if (!outgoingMaterial || !ingoingMaterial) return '';
  const key = `${ingoingMaterial}-${outgoingMaterial}`;
  return spliceSettings[key][spliceCore][property];
};

const Fields = {
  materialSelection: {
    fields: {
      ingoingMaterial: {
        label: 'Ingoing (to) material',
        name: 'ingoingMaterial',
        type: fieldTypes.dropdown,
        options: (materials, projectInputMaterials = []) =>
          _.map(
            SortUtils.sortMaterialProfilesByType(materials),
            (material) => ({
              label: MaterialUtils.getListLabel(material),
              value: material.id,
              subLabel:
                projectInputMaterials.length > 0
                  ? generateMaterialUsageSubLabel(
                      material.id,
                      projectInputMaterials
                    )
                  : null,
            })
          ),
        tooltip: Tooltips.ingoing,
      },
      outgoingMaterial: {
        label: 'Outgoing (from) material',
        name: 'outgoingMaterial',
        type: fieldTypes.dropdown,
        options: (materials, projectInputMaterials = []) =>
          _.map(
            SortUtils.sortMaterialProfilesByType(materials),
            (material) => ({
              label: MaterialUtils.getListLabel(material),
              value: material.id,
              subLabel:
                projectInputMaterials.length > 0
                  ? generateMaterialUsageSubLabel(
                      material.id,
                      projectInputMaterials
                    )
                  : null,
            })
          ),
        tooltip: Tooltips.outgoing,
      },
    },
  },
  spliceSettings: {
    fields: [
      {
        label: 'Heat factor',
        name: 'heatFactor',
        infoLabel:
          'Each increment of 1 adds an additional 0.5 seconds to the heating time.',
        type: fieldTypes.number,
        disabled: (state) => !state.ingoingMaterial,
        custom: (state) =>
          getFieldBounds(state.paletteType, state.paletteModel, 'heat'),
        value: (state) => findPropertyValue(state, 'heatFactor'),
        tooltip: (state) =>
          state.paletteType === 'palette'
            ? Tooltips.heatFactorP1
            : Tooltips.heatFactorP23,
      },
      {
        label: 'Compression factor',
        name: 'compressionFactor',
        infoLabel:
          'Each increment of 1 adds additional compression of 0.5 mm to the splice.',
        type: fieldTypes.number,
        disabled: (state) => !state.ingoingMaterial,
        custom: (state) =>
          getFieldBounds(state.paletteType, state.paletteModel, 'compression'),
        value: (state) => findPropertyValue(state, 'compressionFactor'),
        tooltip: (state) =>
          state.paletteType === 'palette'
            ? Tooltips.compressionFactorP1
            : Tooltips.compressionFactorP23,
      },
      {
        label: 'Cooling factor',
        name: 'coolingFactor',
        infoLabel:
          'Each increment of 1 adds an additional 1 second to the cooling time.',
        type: fieldTypes.number,
        disabled: (state) => {
          const isPopulated = !!state.ingoingMaterial;
          const isPalette1 = state.paletteType === 'palette';
          return !isPopulated || isPalette1;
        },
        display: (state) => shouldDisplayField(state.paletteType, 'cooling'),
        custom: (state) =>
          getFieldBounds(state.paletteType, state.paletteModel, 'cooling'),
        value: (state) => findPropertyValue(state, 'coolingFactor'),
        tooltip: Tooltips.coolingFactor,
      },
      {
        label: 'Reverse splicing',
        name: 'reverse',
        type: fieldTypes.checkbox,
        disabled: (state) => {
          const isPopulated = !!state.ingoingMaterial;
          const notPalette1 = state.paletteType !== 'palette';
          return !isPopulated || notPalette1;
        },
        display: (state) => shouldDisplayField(state.paletteType, 'reverse'),
        value: (state) => findPropertyValue(state, 'reverse'),
        tooltip: Tooltips.reverse,
      },
    ],
  },
};

export default Fields;

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import API, { methods } from '../canvas-api';
import { handleGenericError, getAuthState } from '../common';
import { actions } from '../../reducers/auth/auth';
import { InterfaceUtils } from '../../utils';

export default function* linkIntegration(action) {
  try {
    const { userId } = yield select(getAuthState);
    const { integrationId } = action.payload;

    const response = yield call(API, {
      method: methods.DELETE,
      path: `users/${userId}/integrations/${integrationId}`,
    });
    if (response === null) return;

    yield put(actions.unlinkIntegrationSuccess(integrationId));
    InterfaceUtils.emitToast('success', 'Integration unlinked');
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

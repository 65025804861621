import React, { Component } from 'react';
import _ from 'lodash';

import {
  ImportStylesOptions,
  ImportPrinterOptions,
  ProjectNotFoundDescription,
} from './metadata';

import Home from '../../home/home.container';
import PreviewCard from './previewCard/previewCard.jsx';
import ImportCard from './importCard/importCard.jsx';

import { Page, LoadingOverlay } from '../../../shared';
import NotFound from '../../notFound/notFound.jsx';

import Routes from '../../../router/routes';
import { PrinterUtils } from '../../../utils';

const cards = {
  PREVIEW: 0,
  IMPORT: 1,
  REMAP: 2,
};

class ImportProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: false,
      currentCard: cards.PREVIEW,
      shareId: props.match.params.shareId,
      targetInputs: null,
      inputCount: 0,
      styleId: '',
      printerId: '',
      projectName: '',
      printerName: '',
      styleName: '',
      importStyles: ImportStylesOptions.DO_NOT_IMPORT,
      importPrinter: ImportPrinterOptions.USE_EXISTING_PRINTER_PROFILE,
      importMaterials: false,
      importSpliceSettings: false,
      projectNameError: false,
      printerNameError: false,
      styleNameError: false,
      projectNotFound: false,
      projectDataLoaded: false,
      modelsLoaded: false,
      thumbnailLoaded: false,
      focusField: null,
      defaultPrinterSelected: false,
    };
  }

  componentDidMount() {
    const { shareId } = this.state;
    this.props.getSharedProject(shareId);
    if (!this.props.isAuthenticated) {
      this.props.updateNavStack([
        {
          text: 'Shared Files',
          route: Routes.toImportSharedProject(shareId),
          id: shareId,
        },
      ]);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      currentSharedProject,
      getSharedProjectSuccess,
      getSharedProjectPending,
      getSharedProjectThumbnailSuccess,
      getSharedProjectModelsSuccess,
      importSharedProjectSuccess,
      currentProject,
      getPrintersSuccess,
      getAccountSuccess,
      printers,
    } = this.props;

    const sharedProjectLoaded =
      !prevProps.getSharedProjectSuccess && getSharedProjectSuccess;
    const sharedProjectLoadFailed =
      prevProps.getSharedProjectPending &&
      !prevProps.getSharedProjectSuccess &&
      !getSharedProjectPending &&
      !getSharedProjectSuccess;
    const sharedProjectModelsLoaded =
      !prevProps.getSharedProjectModelsSuccess && getSharedProjectModelsSuccess;
    const sharedProjectThumbnailLoaded =
      !prevProps.getSharedProjectThumbnailSuccess &&
      getSharedProjectThumbnailSuccess;
    const sharedProjectImported =
      !prevProps.importSharedProjectSuccess && importSharedProjectSuccess;

    if (sharedProjectLoaded) {
      const defaultName = `${currentSharedProject.name} by ${currentSharedProject.ownerUsername}`;
      let defaultPrinterName = '';
      if (currentSharedProject.printer) {
        defaultPrinterName = `${currentSharedProject.printer.name} by ${currentSharedProject.ownerUsername}`;
      }
      let defaultStyleName = '';
      if (currentSharedProject.style) {
        defaultStyleName = `${currentSharedProject.style.name} by ${currentSharedProject.ownerUsername}`;
      }
      this.setState({
        projectDataLoaded: true,
        projectName: defaultName,
        printerName: defaultPrinterName,
        styleName: defaultStyleName,
        projectNameError: false,
        styleNameError: false,
        printerNameError: false,
      });
    }

    if (sharedProjectLoadFailed) {
      this.setState({ projectNotFound: true });
      if (this.props.isAuthenticated) this.props.setSharedProjectNotFound();
    }

    if (sharedProjectModelsLoaded) {
      this.setState({ modelsLoaded: true });
    }

    if (sharedProjectThumbnailLoaded) {
      this.setState({ thumbnailLoaded: true });
    }

    if (sharedProjectImported) {
      const { importStyles, styleId } = this.state;
      const routeParams = { pathname: Routes.toSlicer(currentProject) };
      if (!importStyles && styleId) {
        routeParams.state = { needToCenter: true };
      }
      this.props.history.push(routeParams);
    }

    if (
      prevProps.loginPending &&
      !this.props.loginPending &&
      this.props.loginSuccess
    ) {
      this.setState({ showLogin: false });
    }
    if (
      getPrintersSuccess &&
      getAccountSuccess &&
      this.state.defaultPrinterSelected === false
    ) {
      let printerId = '';
      const { defaultPrinter } = this.props.newProjectOptions;
      const updateState = { defaultPrinterSelected: true };
      if (defaultPrinter) {
        printerId = this.props.newProjectOptions.defaultPrinter;
      } else if (this.props.newProjectOptions.defaultPrinter === 'auto') {
        const printersWithStyles = [];
        _.each(this.props.printers, (printer) => {
          if (printer.styles.length > 0) {
            printersWithStyles.push(printer);
          }
        });
        if (printersWithStyles.length > 0) {
          printerId = PrinterUtils.getMostRecentPrinter(printersWithStyles).id;
        }
      }
      if (printers[printerId] && printers[printerId]?.styles) {
        updateState.printerId = printerId;
        updateState.styleId = PrinterUtils.getMostRecentStyle(
          printers[printerId]
        ).id;
      }
      this.setState(updateState);
    }
  }

  handleProjectNameChange(value, error = false) {
    this.setState({
      projectName: value,
      projectNameError: error,
    });
  }

  handlePrinterNameChange(value, error = false) {
    this.setState({
      printerName: value,
      printerNameError: error,
    });
  }

  handleStyleNameChange(value, error = false) {
    this.setState({
      styleName: value,
      styleNameError: error,
    });
  }

  handleLoginClick() {
    this.setState({ showLogin: true });
  }

  focusField(fieldName) {
    this.setState({ focusField: fieldName }, () => {
      this.setState({ focusField: null });
    });
  }

  importProject() {
    if (this.state.projectNameError) {
      this.focusField('projectName');
      return;
    }
    if (
      this.state.importPrinter ===
        ImportPrinterOptions.IMPORT_PRINTER_PROFILE &&
      this.state.printerNameError
    ) {
      this.focusField('printerName');
      return;
    }
    if (
      this.state.importStyles === ImportStylesOptions.IMPORT_STYLE_PROFILE &&
      this.state.styleNameError
    ) {
      this.focusField('styleName');
      return;
    }
    const importOptions = {
      name: this.state.projectName,
      styleId: this.state.styleId,
      printerId: this.state.printerId,
      importStyles: this.state.importStyles,
      importPrinter: this.state.importPrinter,
      newStyleProfileName: this.state.styleName,
      newPrinterProfileName: this.state.printerName,
      importMaterials: this.state.importMaterials,
      importSpliceSettings: this.state.importSpliceSettings,
    };
    this.props.importSharedProject(
      this.props.currentSharedProject,
      importOptions
    );
  }

  onFieldChange(key, value) {
    const updatedState = { [key]: value };
    if (
      key === 'importPrinter' &&
      value === ImportPrinterOptions.IMPORT_PRINTER_PROFILE
    ) {
      updatedState.importStyles = ImportStylesOptions.IMPORT_STYLE_PROFILE;
    }
    if (key === 'printerId') {
      const printer = this.props.printers[value];

      // automatically select the most recent style profile from this printer
      const mostRecentPrinterStyle = PrinterUtils.getMostRecentStyle(printer);
      updatedState.styleId = mostRecentPrinterStyle.id;
    }
    this.setState(updatedState);
  }

  handlePreviewCardNextClick() {
    this.setState({ currentCard: cards.IMPORT });
  }

  handleImportCardBackClick() {
    this.setState({ currentCard: cards.PREVIEW });
  }

  handleImportCardNextClick() {
    this.importProject();
  }

  renderPageNotFound() {
    if (!this.props.isAuthenticated) {
      return (
        <NotFound
          history={this.props.history}
          extraDescriptionText={ProjectNotFoundDescription}
        />
      );
    }
    return null;
  }

  renderPreviewCard() {
    const {
      models,
      isAuthenticated,
      currentSharedProject,
      getPrintersPending,
      getDevicesPending,
    } = this.props;
    const { modelsLoaded, projectDataLoaded } = this.state;

    const isImportDataLoading = getPrintersPending || getDevicesPending;

    return (
      <PreviewCard
        isAuthenticated={isAuthenticated}
        currentSharedProject={currentSharedProject}
        loadedModels={modelsLoaded ? models : null}
        projectDataLoaded={projectDataLoaded}
        isImportDataLoading={isImportDataLoading}
        onLoginClick={() => this.handleLoginClick()}
        onNextClick={() => this.handlePreviewCardNextClick()}
        toggleImportSharedProjectModal={
          this.props.toggleImportSharedProjectModal
        }
      />
    );
  }

  renderImportCard() {
    const {
      currentSharedProject,
      devices,
      printers,
      getPrintersSuccess,
      getDevicesSuccess,
    } = this.props;
    const {
      styleId,
      printerId,
      importStyles,
      importPrinter,
      importMaterials,
      thumbnailLoaded,
      projectName,
      projectNameError,
      printerName,
      printerNameError,
      styleName,
      styleNameError,
      focusField,
      importSpliceSettings,
    } = this.state;

    const isImportDataLoaded = getPrintersSuccess && getDevicesSuccess;
    return (
      <ImportCard
        styleId={styleId}
        printerId={printerId}
        importStyles={importStyles}
        importPrinter={importPrinter}
        importMaterials={importMaterials}
        importSpliceSettings={importSpliceSettings}
        projectName={projectName}
        projectNameError={projectNameError}
        printerName={printerName}
        printerNameError={printerNameError}
        styleName={styleName}
        styleNameError={styleNameError}
        focusField={focusField}
        currentSharedProject={currentSharedProject}
        isImportDataLoaded={isImportDataLoaded}
        devices={devices}
        printers={printers}
        thumbnailLoaded={thumbnailLoaded}
        onNameChangeSuccess={(value) => this.handleProjectNameChange(value)}
        onNameChangeFailure={(value) =>
          this.handleProjectNameChange(value, true)
        }
        onPrinterNameChangeSuccess={(value) =>
          this.handlePrinterNameChange(value)
        }
        onPrinterNameChangeFailure={(value) =>
          this.handlePrinterNameChange(value, true)
        }
        onStyleNameChangeSuccess={(value) => this.handleStyleNameChange(value)}
        onStyleNameChangeFailure={(value) =>
          this.handleStyleNameChange(value, true)
        }
        onFieldChange={(key, value) => this.onFieldChange(key, value)}
        onBackClick={() => this.handleImportCardBackClick()}
        onNextClick={() => this.handleImportCardNextClick()}
      />
    );
  }

  renderOverlaySpinner() {
    const { importSharedProjectPending, getSharedProjectPending } = this.props;
    if (importSharedProjectPending)
      return <LoadingOverlay label='Importing Project' />;
    if (getSharedProjectPending)
      return <LoadingOverlay label='Loading Shared Project' />;
    return null;
  }

  renderCurrentCard() {
    switch (this.state.currentCard) {
      case cards.IMPORT:
        return this.renderImportCard();
      default:
        return this.renderPreviewCard();
    }
  }

  renderMainPanelContent() {
    return (
      <>
        {this.renderOverlaySpinner()}
        {this.renderCurrentCard()}
      </>
    );
  }

  render() {
    if (this.state.showLogin) {
      const match = { pathname: this.props.match.url };
      return <Home match={match} />;
    }
    if (this.state.projectNotFound) {
      return this.renderPageNotFound();
    }
    if (!this.props.isAuthenticated) {
      return (
        <Page>
          <>{this.renderMainPanelContent()}</>
        </Page>
      );
    }
    return <>{this.renderMainPanelContent()}</>;
  }
}

export default ImportProject;

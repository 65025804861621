import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../canvas-api';
import { handleGenericError } from '../common';
import { actions } from '../../reducers/printers/printers';

export default function* createSharedPrinter(action) {
  try {
    const { printerId, styleIds } = action.payload;

    const response = yield call(API, {
      method: methods.PUT,
      path: `printers/${printerId}/share`,
      body: {
        printerId,
        styleIds,
      },
    });
    if (response === null) return;

    const { id, dateModified } = response.sharedPrinter;
    const shareData = {
      shareId: id,
      dateShared: dateModified,
      sharedStyleIds: styleIds,
    };
    yield put(actions.createSharedPrinterSuccess(printerId, shareData));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import 'regenerator-runtime/runtime';
import _ from 'lodash';
import { all, call, put } from 'redux-saga/effects';

import API from '../../canvas-api';
import { handleGenericError } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';
import { SlicerUtils, TreeUtils } from '../../../utils';
import { loadStampData } from '../models/helpers/stamps';

function* getAllModelsColor(shareId) {
  const response = yield call(API, {
    path: `projects/shared/${shareId}/models/colors`,
    token: null,
  });
  return response.colors;
}

function* getAllCustomSupportRles(shareId) {
  const response = yield call(API, {
    path: `projects/shared/${shareId}/models/supports`,
    token: null,
  });
  return response.supportsRle;
}

function* getAllModelsStamps(shareId, inputCount) {
  const response = yield call(API, {
    path: `projects/shared/${shareId}/models/stamps`,
    token: null,
  });

  // initialize stamps map; key: modelId, value: stampMap for that model
  const allModelsStamps = {};
  const allModelsStampsArray = _.values(response.stamps);

  for (let i = 0; i < allModelsStampsArray.length; i++) {
    const { id: modelId, stamps: modelStamps } = allModelsStampsArray[i];
    const formattedStamps = {};
    const stampsArray = _.values(modelStamps);

    const stampData = yield all(
      _.map(stampsArray, (currentStamp) =>
        call(loadStampData, currentStamp, inputCount)
      )
    );
    for (let j = 0; j < stampsArray.length; j++) {
      const { id } = stampsArray[j];
      formattedStamps[id] = stampData[j];
    }
    allModelsStamps[modelId] = formattedStamps;
  }

  return allModelsStamps;
}

export default function* getSharedProjectModels(action) {
  try {
    const { shareId, models, colors } = action.payload;
    const response = yield call(API, {
      path: `projects/shared/${shareId}/models`,
      token: null,
    });
    const { urls } = response;

    // check if any colored models exist, if so get their color RLEs
    const coloredModelIds = _.keys(models).filter(
      (modelId) => models[modelId].colored
    );
    let rles = {};
    if (!_.isEmpty(coloredModelIds)) {
      rles = yield call(getAllModelsColor, shareId);
      if (rles === null) return;
    }

    // check if any models has custom supports, if so get custom supports RLEs
    const customSupportsModelIds = _.keys(models).filter(
      (modelId) => models[modelId].hasCustomSupports
    );
    let supportsRles = {};
    if (!_.isEmpty(customSupportsModelIds)) {
      supportsRles = yield call(getAllCustomSupportRles, shareId);
      if (supportsRles === null) return;
    }

    // check if any textured models exist, if so get their stamp data
    const texturedModelIds = _.keys(models).filter(
      (modelId) => models[modelId].textured
    );
    let stamps = {};
    if (!_.isEmpty(texturedModelIds)) {
      stamps = yield call(getAllModelsStamps, shareId, colors.length);
      if (stamps === null) return;
    }

    // get meshes (with colors, if any)
    const meshes = yield Promise.all(
      _.map(urls, async (signedUrl) => {
        const { url, id } = signedUrl;

        const modelIsColored = models[id].colored;
        const colorsRle = modelIsColored ? rles[id] : null;

        const modelHasCustomSupports = models[id].hasCustomSupports;
        const supportsRle = modelHasCustomSupports ? supportsRles[id] : null;

        // attach stamps map to model
        const modelHasStamps = models[id].textured;
        if (modelHasStamps) {
          models[id].stamps = stamps[id];
        }

        return SlicerUtils.requestMesh(
          url,
          models[id],
          colors,
          colorsRle,
          supportsRle
        );
      })
    );
    const modelTree = TreeUtils.addMeshesToModelTree(
      TreeUtils.formatModelTree(models),
      meshes
    );
    yield put(actions.getSharedProjectModelsSuccess(modelTree));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

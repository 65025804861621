import React from 'react';
import { useTheme } from 'styled-components';
import { subMinutes } from 'date-fns';
import * as d3 from 'd3';
import TemperatureController from './temperatureController/temperatureController.jsx';
import LineChart from '../../../../../shared/charts/lineChart.jsx';
import {
  Container,
  TemperatureControllers,
  TemperatureGraph,
} from './temperature.styles';

import { DeviceUtils } from '../../../../../utils';
import Placeholder from '../../placeholder.jsx';

const TemperatureControls = ({
  includeChamberControls,
  currentDeviceState,
  pendingRequests,
  onSetTargetTemperature,
  device,
}) => {
  const partsToControl = [
    {
      name: 'Extruder',
      type: 'nozzle',
    },
    {
      name: 'Print Bed',
      type: 'bed',
    },
  ];

  if (includeChamberControls) {
    partsToControl.push({
      name: 'Chamber',
      type: 'chamber',
    });
  }

  const theme = useTheme();

  const isConnected = DeviceUtils.isConnectedToPrinter(currentDeviceState);
  if (!isConnected) {
    return <Placeholder borderless>No printer connected</Placeholder>;
  }

  const mapToDatum = (items, fieldName) =>
    items.map((item) => ({
      x: item.time,
      y: item[fieldName],
    }));

  const data = [
    {
      label: 'Extruder',
      color: theme.colors.purpleDefault,
      data: mapToDatum(currentDeviceState.temperatureHistory, 'extruder'),
    },
    {
      label: 'Extruder (Target)',
      color: theme.colors.purpleExtraLight,
      data: mapToDatum(currentDeviceState.temperatureHistory, 'extruderTarget'),
    },
    {
      label: 'Print Bed',
      color: theme.colors.yellowDark,
      data: mapToDatum(currentDeviceState.temperatureHistory, 'printBed'),
    },
    {
      label: 'Print Bed (Target)',
      color: theme.colors.yellowExtraLight,
      data: mapToDatum(currentDeviceState.temperatureHistory, 'printBedTarget'),
    },
    ...(includeChamberControls
      ? [
          {
            label: 'Chamber',
            color: theme.colors.greenDefault,
            data: mapToDatum(currentDeviceState.temperatureHistory, 'chamber'),
          },
          {
            label: 'Chamber (Target)',
            color: theme.colors.greenExtraLight,
            data: mapToDatum(
              currentDeviceState.temperatureHistory,
              'chamberTarget'
            ),
          },
        ]
      : []),
  ];

  const maxX = d3.max(
    data.map((d) => d.data),
    (arr) => d3.max(arr, (d) => d.x)
  );
  const minX = subMinutes(maxX, 10);
  return (
    <Container>
      <TemperatureGraph>
        <LineChart
          xRange={[minX, maxX]}
          yTickCount={4}
          yTickFormat={(d) => `${d} °C`}
          yRange={[0, null]}
          data={data}
        />
      </TemperatureGraph>
      <TemperatureControllers>
        {partsToControl.map((part) => (
          <TemperatureController
            key={part.type}
            part={part}
            device={device}
            currentDeviceState={currentDeviceState}
            pendingRequests={pendingRequests}
            onSetTargetTemperature={onSetTargetTemperature}
          />
        ))}
      </TemperatureControllers>
    </Container>
  );
};

export default TemperatureControls;

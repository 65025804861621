import React, { Component } from 'react';
import _ from 'lodash';
import { withTheme } from 'styled-components';

import {
  ButtonWrapper,
  PlaceholderLabelWrapper,
  StyleCardsWrapper,
  ImageContainer,
  ImageWrapper,
  TextContainer,
  AddStyleModalButtonWrapper,
} from './viewPrinter.styles';

import EditPrinterModal from '../configurePrinter/wrappers/editPrinter.container';

import Routes from '../../../router/routes';
import { Icons } from '../../../themes';

import { FormatUtils, InterfaceUtils, PrinterUtils } from '../../../utils';
import { StyleSortMode } from '../../../utils/sort/sort';

import EditStyleModal from '../configureStyle/wrappers/editStyle.container';
import CreateStyleModal from '../configureStyle/wrappers/createStyle.container';
import ImportStyleModal from '../configureStyle/wrappers/importStyle.container';
import UploadStyle from '../uploadProfile/wrappers/uploadStyle.container';
import SelectStyleImports from '../configureStyle/wrappers/selectStyleImports.container';

import {
  AdvancedButton,
  Body1,
  Button,
  Body1OneLine,
  ConfirmationModal,
  Image,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  RenameModal,
  StyleProfileCard,
  Subtitle1,
  Subtitle2OneLine,
  ManagerView,
} from '../../../shared';

const sortOptions = [
  { label: 'Name', value: StyleSortMode.NAME },
  { label: 'Date modified', value: StyleSortMode.DATE_MODIFIED },
  { label: 'Date created', value: StyleSortMode.DATE_CREATED },
];

class ViewPrinter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      printerId: props.match.params.printerId,
      printersLoaded: false,
      deleteId: '',
      stylesList: [],
      selectedStyleIds: [],
      editStyleId: '',
      showAddStyleModal: false,
      showDeleteStyleModal: false,
      showUploadStyleModal: false,
      showEditStyleModal: false,
      showCreateStyleModal: false,
      showImportStyleModal: false,
      showSelectImportStylesModal: false,
      showEditPrinterModal: false,
      showRenameStyleModal: false,
    };
    this.onWindowClick = this.onWindowClick.bind(this);
    this.onWindowKeydown = this.onWindowKeydown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.onWindowClick);
    window.addEventListener('keydown', this.onWindowKeydown);
    if (this.props.getPrintersSuccess) {
      this.handlePrintersLoaded();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      getPrintersSuccess,
      createStylesSuccess,
      deleteStylesSuccess,
      updatePrinterSuccess,
    } = this.props;
    const styleDuplicated =
      !prevProps.createStylesSuccess && createStylesSuccess;
    const stylesDeleted = !prevProps.deleteStylesSuccess && deleteStylesSuccess;
    const printerUpdated =
      !prevProps.updatePrinterSuccess && updatePrinterSuccess;

    if (getPrintersSuccess && !this.state.printersLoaded) {
      this.handlePrintersLoaded();
    }

    if (!_.isEqual(this.props.sortBy, prevProps.sortBy)) {
      this.updateStylesList();
    } else if (styleDuplicated) {
      // style profile was duplicated -- select only it
      this.updateStylesList(true);
    } else if (stylesDeleted || printerUpdated) {
      this.updateStylesList();
      const { printerId } = this.state;
      if (
        prevProps.printers[printerId].machineSettings.name !==
        this.props.printers[printerId].machineSettings.name
      ) {
        this.updateNavStack();
      }
    }

    // if we are using global search redirect to another printer
    if (this.state.printerId !== this.props.match.params.printerId) {
      this.setState({ printerId: this.props.match.params.printerId }, () => {
        this.updateNavStack();
        this.updateStylesList();
      });
    }
  }

  handlePrintersLoaded() {
    this.setState({ printersLoaded: true }, () => {
      this.updateStylesList();
      this.updateNavStack();
    });
  }

  updateNavStack() {
    const { printers, updateNavStack } = this.props;
    const { printerId } = this.state;
    updateNavStack([
      { text: 'My Printers', route: Routes.toManagePrinters() },
      {
        text: printers[printerId].machineSettings.name,
        route: Routes.toViewPrinter(printerId),
      },
    ]);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
    window.removeEventListener('keydown', this.onWindowKeydown);
    this.props.resetNewProjectFlags();
  }

  showAddStyleModal(e) {
    e.stopPropagation();
    this.setState({
      showAddStyleModal: true,
    });
  }

  showDeleteStyleModal() {
    this.setState({
      showDeleteStyleModal: true,
    });
  }

  showEditPrinterModal() {
    this.setState({
      showEditPrinterModal: true,
    });
  }

  showEditStyleModal(styleId) {
    this.setState({
      editStyleId: styleId,
      showEditStyleModal: true,
    });
  }

  showCreateStyleModal() {
    this.setState({
      showCreateStyleModal: true,
    });
  }

  showUploadStyleModal() {
    this.setState({
      showUploadStyleModal: true,
    });
  }

  showImportStyleModal() {
    this.setState({
      showImportStyleModal: true,
    });
  }

  showSelectImportStylesModal() {
    this.setState({
      showSelectImportStylesModal: true,
    });
  }

  showRenameStyleModal() {
    this.setState({
      showRenameStyleModal: true,
    });
  }

  hideAllStyleModals() {
    this.setState({
      showAddStyleModal: false,
      showDeleteStyleModal: false,
      showEditPrinterModal: false,
      showEditStyleModal: false,
      showCreateStyleModal: false,
      showImportStyleModal: false,
      showUploadStyleModal: false,
      showSelectImportStylesModal: false,
      showRenameStyleModal: false,
    });
  }

  updateStylesList(selectNew = false) {
    const sortedStyles = PrinterUtils.sortStyles(
      this.props.printers[this.state.printerId].styles,
      this.props.sortBy
    );
    const newState = {
      stylesList: sortedStyles,
      selectedStyleIds: [],
    };
    if (selectNew) {
      // select only styles that did not previously exist
      const oldStyleIds = _.map(this.state.stylesList, (style) => style.id);
      _.forEach(sortedStyles, (style) => {
        if (!_.includes(oldStyleIds, style.id)) {
          newState.selectedStyleIds.push(style.id);
        }
      });
    } else {
      // select all styles that were previously selected (and still exist)
      _.forEach(this.state.selectedStyleIds, (id) => {
        if (_.find(sortedStyles, (style) => style.id === id)) {
          newState.selectedStyleIds.push(id);
        }
      });
    }
    this.setState(newState);
  }

  onWindowKeydown(event) {
    if (event.key === 'Escape') {
      this.deselect();
    }
  }

  onWindowClick() {
    const { showAddStyleModal, showDeleteStyleModal, showRenameStyleModal } =
      this.state;
    if (showAddStyleModal || showDeleteStyleModal || showRenameStyleModal) {
      return;
    }
    this.deselect();
  }

  deselect() {
    const { showAddStyleModal, showDeleteStyleModal, showRenameStyleModal } =
      this.state;
    if (showAddStyleModal || showDeleteStyleModal || showRenameStyleModal) {
      this.setState({
        showAddStyleModal: false,
        showDeleteStyleModal: false,
        showRenameStyleModal: false,
      });
    } else {
      this.setState({ selectedStyleIds: [] });
    }
  }

  selectStyle(e, style) {
    e.stopPropagation();
    const { selectedStyleIds, stylesList } = this.state;

    const updatedSelectedItems = InterfaceUtils.handleSelectableItemClick(
      e,
      stylesList,
      selectedStyleIds,
      style.id
    );
    this.setState({ selectedStyleIds: updatedSelectedItems });
  }

  handleSelectAll() {
    const { selectedStyleIds, stylesList } = this.state;
    if (selectedStyleIds.length !== stylesList.length) {
      this.setState({
        selectedStyleIds: _.map(stylesList, (style) => style.id),
      });
    } else {
      this.setState({
        selectedStyleIds: [],
      });
    }
  }

  renderEditPrinterModal() {
    const { printerId, showEditPrinterModal } = this.state;
    const { printers } = this.props;
    if (!showEditPrinterModal) return null;
    return (
      <EditPrinterModal
        printer={printers[printerId]}
        onClickClose={() => this.hideAllStyleModals()}
        onClickSave={() => this.hideAllStyleModals()}
      />
    );
  }

  updatedStylesList(stylesList, renameValue, currentStyle) {
    // check stylesList to see if renameValue already exists inside
    const checkForName = _.filter(
      stylesList,
      (style) => style.name.toUpperCase() === renameValue.toUpperCase()
    );
    // if it does exist, then return null
    if (checkForName.length >= 1) {
      return null;
    }

    // if it doesn't exist then update selected style within stylesList with renameValue
    const updatedList = _.map(stylesList, (style) => {
      if (style.id === currentStyle[0].id) {
        return {
          ...style,
          name: renameValue,
        };
      }
      return style;
    });
    return this.setState({ stylesList: updatedList });
  }

  handleRenameStyle(renameValue) {
    const { stylesList, printerId, selectedStyleIds } = this.state;
    const [styleId] = selectedStyleIds;
    const currentStyle = _.filter(stylesList, (style) => style.id === styleId);
    const updatedStyleData = {
      ...currentStyle[0],
      name: renameValue,
    };
    const currentStyleName = currentStyle[0].name;
    if (renameValue.trim() !== currentStyleName) {
      this.props.updatePrinterStyles(printerId, styleId, updatedStyleData);
    }
    this.updatedStylesList(stylesList, renameValue, currentStyle);
    this.hideAllStyleModals();
  }

  renderRenameModal() {
    const { showRenameStyleModal, selectedStyleIds, stylesList } = this.state;
    if (!showRenameStyleModal) return null;
    const [styleId] = selectedStyleIds;
    const currentStyle = _.filter(stylesList, (style) => style.id === styleId);
    if (!currentStyle) return null;

    const styleName = currentStyle[0].name;
    return (
      <RenameModal
        initialValue={styleName}
        title={'Rename Style'}
        onMarginClick={() => this.hideAllStyleModals()}
        onCancel={() => this.hideAllStyleModals()}
        onSave={(renameValue) => this.handleRenameStyle(renameValue)}
      />
    );
  }

  handleDuplicateStyle() {
    const { printerId, stylesList, selectedStyleIds } = this.state;
    const existingStyle = _.find(
      stylesList,
      (style) => style.id === selectedStyleIds[0]
    );
    const newStyle = _.omit(existingStyle, ['id']);
    this.props.createStyleProfiles(printerId, [newStyle]);
  }

  handleDeleteStyle() {
    const { printerId, selectedStyleIds } = this.state;
    this.props.deleteStyles(printerId, selectedStyleIds);
    this.hideAllStyleModals();
  }

  renderConfigureStyleModals() {
    const {
      printerId,
      editStyleId,
      showEditStyleModal,
      showCreateStyleModal,
      showImportStyleModal,
    } = this.state;

    if (showEditStyleModal) {
      return (
        <EditStyleModal
          printerId={printerId}
          styleId={editStyleId}
          hideAllStyleModals={() => this.hideAllStyleModals()}
        />
      );
    }
    if (showCreateStyleModal) {
      return (
        <CreateStyleModal
          printerId={printerId}
          hideAllStyleModals={() => this.hideAllStyleModals()}
        />
      );
    }
    if (showImportStyleModal) {
      return (
        <ImportStyleModal
          printerId={printerId}
          showSelectImportStylesModal={() => this.showSelectImportStylesModal()}
          hideAllStyleModals={() => this.hideAllStyleModals()}
        />
      );
    }
    return null;
  }

  renderUploadStyleFileModal() {
    const { printerId, showUploadStyleModal } = this.state;

    if (!showUploadStyleModal) return null;
    return (
      <Modal
        width='47.25rem'
        noScroll
        onMarginClick={() => this.hideAllStyleModals()}>
        <UploadStyle
          printerId={printerId}
          showImportStyleModal={() => this.showImportStyleModal()}
          onClickClose={() => this.hideAllStyleModals()}
        />
      </Modal>
    );
  }

  renderSelectImportStylesModal() {
    const { printers } = this.props;
    const { printerId, showSelectImportStylesModal } = this.state;

    if (!showSelectImportStylesModal) return null;
    return (
      <Modal
        width='auto'
        noScroll
        noPadding
        onMarginClick={() => this.hideAllStyleModals()}>
        <SelectStyleImports
          printer={printers[printerId]}
          hideAllStyleModals={() => this.hideAllStyleModals()}
        />
      </Modal>
    );
  }

  renderAddStyleModal() {
    if (!this.state.showAddStyleModal) return null;
    return (
      <Modal onMarginClick={() => this.hideAllStyleModals()}>
        <ModalHeader>
          <Subtitle1>Add new style profile</Subtitle1>
        </ModalHeader>
        <ModalBody>
          <AdvancedButton
            icon={Icons.basic.importIcon}
            label='Import from a different slicer'
            description='From Simplify3D, Slic3r/PrusaSlicer or KISSlicer'
            onClick={() => this.showUploadStyleModal()}
          />
          <AdvancedButton
            icon={Icons.basic.plus}
            label='Blank'
            description='Start from a blank profile'
            onClick={() => this.showCreateStyleModal()}
          />
        </ModalBody>
        <ModalFooter>
          <AddStyleModalButtonWrapper>
            <Button onClick={() => this.hideAllStyleModals()}>Cancel</Button>
          </AddStyleModalButtonWrapper>
        </ModalFooter>
      </Modal>
    );
  }

  renderDeleteStyleModal() {
    const { showDeleteStyleModal, stylesList, selectedStyleIds } = this.state;
    if (!showDeleteStyleModal) return null;

    const firstSelectedStyle = _.find(
      stylesList,
      (style) => style.id === selectedStyleIds[0]
    );
    let primaryLabel = `Delete ${firstSelectedStyle.name}`;
    let secondaryLabel =
      'Are you sure you would like to remove this style profile from this printer?';

    if (selectedStyleIds.length > 1) {
      primaryLabel = `Delete ${selectedStyleIds.length} style profiles`;
      secondaryLabel =
        'Are you sure you would like to remove these style profiles from this printer?';
    }

    return (
      <ConfirmationModal
        isWarning
        primaryLabel={primaryLabel}
        secondaryLabel={secondaryLabel}
        onClickCancel={() => this.hideAllStyleModals()}
        onClickConfirm={() => this.handleDeleteStyle()}
      />
    );
  }

  renderStylesPlaceholder() {
    if (!_.isEmpty(this.state.stylesList)) return null;
    return (
      <PlaceholderLabelWrapper>
        <Body1 grey>You do not have any style profiles yet.</Body1>
      </PlaceholderLabelWrapper>
    );
  }

  renderStyleItem(style) {
    const { selectedStyleIds } = this.state;
    const styleSelected = _.includes(selectedStyleIds, style.id);
    return (
      <StyleProfileCard
        key={style.id}
        selected={!!styleSelected}
        onClick={(e) => this.selectStyle(e, style)}
        onDoubleClick={() => this.showEditStyleModal(style.id)}>
        <TextContainer>
          <Subtitle2OneLine>{style.name}</Subtitle2OneLine>
          <Body1OneLine noSpacing grey>
            {`Infill: ${style.infillDensity}% | Layer Height: ${style.layerHeight} mm`}
          </Body1OneLine>
        </TextContainer>
        <ImageContainer>
          <ImageWrapper>
            <Image
              alt='Style icon'
              src={this.props.theme.images.styleIcons[style.icon]}
              invert={this.props.theme.name !== 'dark'}
            />
          </ImageWrapper>
        </ImageContainer>
      </StyleProfileCard>
    );
  }

  renderStylesCards() {
    if (!this.props.printers) return null;
    const { stylesList } = this.state;
    return (
      <StyleCardsWrapper>
        {this.renderAddStyleModal()}
        {this.renderDeleteStyleModal()}
        {this.renderUploadStyleFileModal()}
        {this.renderSelectImportStylesModal()}
        {this.renderConfigureStyleModals()}
        {this.renderStylesPlaceholder()}
        {this.renderEditPrinterModal()}
        {this.renderRenameModal()}
        {_.map(stylesList, (style) => this.renderStyleItem(style))}
      </StyleCardsWrapper>
    );
  }

  renderMenuButtons() {
    return (
      <ButtonWrapper>
        <Button
          primary
          expand
          onClick={(e) => this.showAddStyleModal(e)}
          icon={Icons.basic.plus}>
          New style profile
        </Button>
        <Button expand onClick={() => this.showEditPrinterModal()}>
          Printer Settings
        </Button>
      </ButtonWrapper>
    );
  }

  getActionButtonsMeta() {
    const { selectedStyleIds } = this.state;
    return [
      {
        icon: Icons.basic.pencil,
        title: FormatUtils.pluralize(
          'Rename style profile',
          selectedStyleIds.length,
          true
        ),
        onClick: () => this.showRenameStyleModal(),
        disabled: selectedStyleIds.length !== 1,
      },
      {
        icon: Icons.basic.copy,
        title: FormatUtils.pluralize(
          'Duplicate style profile',
          selectedStyleIds.length,
          true
        ),
        onClick: () => this.handleDuplicateStyle(),
        disabled: selectedStyleIds.length !== 1,
      },
      {
        icon: Icons.basic.trash,
        title: FormatUtils.pluralize(
          'Delete style profile',
          selectedStyleIds.length,
          true
        ),
        onClick: () => this.showDeleteStyleModal(),
        disabled: _.isEmpty(selectedStyleIds),
      },
    ];
  }

  areActionsDisabled() {
    const { getPrintersSuccess, printers } = this.props;
    const printer = printers[this.state.printerId];
    return !getPrintersSuccess || !printer.styles || _.isEmpty(printer.styles);
  }

  onSortChange(sortMode, sortDirection) {
    this.props.updatePreferences(sortMode, sortDirection);
  }

  render() {
    const { sortBy, getPrintersPending, getPrintersSuccess } = this.props;
    return (
      <ManagerView
        name='Styles'
        actionsDisabled={this.areActionsDisabled()}
        menuButtons={this.renderMenuButtons()}
        loadingContent={getPrintersPending}
        loadingError={!getPrintersPending && !getPrintersSuccess}
        sortOptions={sortOptions}
        sortMode={sortBy.mode}
        sortDirection={sortBy.direction}
        onSortChange={(mode, direction) => this.onSortChange(mode, direction)}
        actionButtons={this.getActionButtonsMeta()}>
        {InterfaceUtils.getLoadingSpinner(this.props)}
        {this.renderStylesCards()}
      </ManagerView>
    );
  }
}

export default withTheme(ViewPrinter);

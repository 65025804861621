import React, { Component } from 'react';
import _ from 'lodash';

import { Container, Box } from './boxSelection.styles';

import { Body1 } from '../typography/typography';

class BoxSelection extends Component {
  static defaultProps = {
    value: '', // tie to state of your component
    options: [], // { label: '', value: '' }
    onClick: () => {},
    maxColumns: 1,
    disabled: false,
    noStacking: false, // if true boxes are not stacked on extra small screen
    boxPadding: '0.3rem 0.75rem',
  };

  renderBoxes() {
    return _.map(this.props.options, (option, index) =>
      this.renderBox(option, index)
    );
  }

  renderBox(option, index) {
    const isActive = _.isEqual(this.props.value, option.value);
    return (
      <Box
        key={index}
        value={option.value}
        active={isActive}
        boxPadding={this.props.boxPadding}
        disabled={this.props.disabled || option.disabled}
        onClick={() => {
          if (this.props.disabled || option.disabled) return;
          this.props.onClick(option.value);
        }}>
        <Body1 noSpacing>{option.label}</Body1>
      </Box>
    );
  }

  render() {
    return (
      <Container
        maxColumns={this.props.maxColumns}
        noStacking={this.props.noStacking}
        optionCount={this.props.options.length}
        disabled={this.props.disabled}>
        {this.renderBoxes()}
      </Container>
    );
  }
}

export default BoxSelection;

import styled from 'styled-components';

export const CheckboxListWrapper = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
  width: 100%;
`;

export const CheckboxContainer = styled.li`
  margin-bottom: 0.25rem;
`;

export const CaptionLabelWrapper = styled.div`
  margin-top: 0.1rem;
  margin-left: 2rem;
`;

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import API from '../../canvas-api';
import { getAuthState, handleGenericError } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';
import { FileUtils } from '../../../utils';
import Constants from '../../constants';
import { fetchS3 } from '../../s3';

export default function* download(action) {
  try {
    const { preferences } = yield select(getAuthState);
    const { projectId } = action.payload;

    const queryString = preferences.preferUnzipped ? '/?unzipped' : '';
    const response = yield call(API, {
      path: `projects/${projectId}/download${queryString}`,
    });
    if (response === null) return;

    const { url, name } = response;
    const s3Response = yield call(fetchS3, url);
    const blob = yield call([s3Response, 'blob']);
    FileUtils.downloadBlob(name, blob);

    yield put(actions.downloadSuccess());
  } catch (e) {
    // handle timeout errors
    if (e.status === 504) {
      e.message = Constants.TIMEOUT_ERROR_MESSAGE;
    }
    yield call(handleGenericError, action, e);
  }
}

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { actions } from '../../../reducers/onboarding/onboarding';
import ModalManager from './modalManager.jsx';

const mapStateToProps = (state) => ({
  modals: state.onboarding.modals,
});

const mapDispatchToProps = (dispatch) => ({
  completeModal: (identifier) =>
    dispatch(actions.completeModalRequest(identifier)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalManager)
);

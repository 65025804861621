import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';
import * as THREE from 'three';

import { getAuthState, getThreeState, handleGenericError } from '../common';

import Helpers from '../../reducers/three/helpers';
import { actions } from '../../reducers/three/three';
import { SceneUtils, SlicerUtils } from '../../utils';
import { setRenderFlag } from './animationFrame';

export default function* resetCamera(action) {
  try {
    const { printerHeight, printBed, camera, controls } = yield select(
      getThreeState
    );
    const { viewOptions } = yield select(getAuthState);
    const { allowTween, paintMode, modelToColor } = action.payload;

    const boundingBox = SlicerUtils.getBoundingBox(printBed);
    const boundingSphere = SlicerUtils.getBoundingSphere(printBed);
    const bedSize = {
      x: boundingBox.max.x - boundingBox.min.x,
      y: boundingBox.max.y - boundingBox.min.y,
      z: printerHeight,
    };
    const bedMiddle = {
      x: boundingSphere.center.x,
      y: boundingSphere.center.y,
      z: 0,
    };
    const originOffset = {
      x: bedSize.x / 2 - bedMiddle.x,
      y: bedSize.y / 2 - bedMiddle.y,
      z: 0,
    };

    // camera position
    const position = SceneUtils.getCameraResetPosition(
      bedSize,
      originOffset,
      boundingSphere,
      viewOptions.orthographic
    );

    // camera zoom
    let zoom;

    // lookAt target (determines camera rotation)
    let lookAt;

    if (paintMode) {
      const bbox = SlicerUtils.getBoundingBox(modelToColor.mesh);
      const modelBoundingSphere =
        SlicerUtils.getBoundingSphereFromBoundingBox(bbox);
      const { center } = modelBoundingSphere;
      controls.target.set(center.x, center.y, center.z);
      if (viewOptions.orthographic) {
        zoom = 6;
      } else {
        zoom = 3;
      }
      lookAt = center;
    } else {
      controls.target.set(bedMiddle.x, bedMiddle.y, bedMiddle.z / 4);
      controls.minDistance = boundingSphere.radius * 0.05;
      controls.maxDistance = boundingSphere.radius * 4;
      const { innerWidth } = window;

      const defaultZoom = Math.max(0.1, bedSize.y / -200 + 4);
      controls.maxZoom = 300;
      controls.minZoom = Math.min(
        defaultZoom,
        (innerWidth / boundingSphere.radius) * 0.2
      );

      lookAt = new THREE.Vector3(bedMiddle.x, bedMiddle.y, bedMiddle.z / 4);
      if (viewOptions.orthographic) {
        zoom = defaultZoom;
      } else {
        zoom = 1;
      }
    }

    if (
      !allowTween ||
      window.matchMedia('(prefers-reduced-motion: reduce)').matches
    ) {
      // user prefers reduced motion, or app requests no tween
      // no animation
      camera.position.set(position.x, position.y, position.z);
      camera.zoom = zoom;
      camera.lookAt(lookAt);
      Helpers.updateProjectionMatrix(camera, viewOptions.orthographic);
      controls.update();
      setRenderFlag();
    } else {
      // animate the camera movement
      controls.enabled = false;
      yield put(actions.tweenCamera(position, zoom, lookAt));
    }
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

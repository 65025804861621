import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { Caption } from '../typography/typography';

export const Container = styled.div`
  width: 100%;
`;

export const StyledTooltip = styled(ReactTooltip)`
  max-width: 20rem;
  padding: ${(props) =>
    props.wide && !props.error ? '0.75rem' : '0.25rem 0.5rem'} !important;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.02) !important;
  opacity: 1 !important;
  z-index: 1001 !important;

  &.place-top.show {
    margin-top: -0.3rem !important;
  }
  &.place-bottom.show {
    margin-bottom: 0.3rem !important;
  }
  &.place-right.show {
    margin-right: 0.3rem !important;
  }
  &.place-left.show {
    margin-left: -0.3rem !important;
  }

  ${Caption} {
    color: ${(props) => props.textColor};
  }
`;

import 'regenerator-runtime/runtime';
import { call, put, select } from 'redux-saga/effects';

import { updateModelStampData } from './helpers/stamps';
import { updateModelTexture } from './helpers/texture';
import { saveColorsRLE } from './helpers/colors-rle';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';
import { TreeUtils } from '../../../utils';

export default function* saveModelPaintData(action) {
  const {
    currentProject: projectId,
    models,
    projects,
  } = yield select(getSlicerState);

  const { modelId, paintData } = action.payload;

  const project = projects[projectId];
  const currentModel = TreeUtils.searchById(modelId, models);

  try {
    let transitionTower = null;
    const { stampFiles, uvFile, imgFile, rle } = paintData;

    // update/remove colored data on server first
    const colorsResponse = yield call(saveColorsRLE, projectId, modelId, rle);
    if (colorsResponse === null) return;
    const towerFromRLE = colorsResponse.transitionTower;
    if (towerFromRLE) transitionTower = towerFromRLE;

    // update/remove stamps on server (even if there are no stamps (i.e empty array))
    const updatedModelStamps = yield call(
      updateModelStampData,
      projectId,
      currentModel,
      stampFiles,
      project.colors.length
    );
    if (updatedModelStamps === null) return;

    // update/remove texture on server
    const textureResponse = yield call(
      updateModelTexture,
      projectId,
      currentModel,
      uvFile,
      imgFile
    );
    if (textureResponse === null) return;
    const towerFromTexture = textureResponse.transitionTower;
    if (towerFromTexture) transitionTower = towerFromTexture;

    // update/remove tower
    yield put(actions.updateTowerFromServer(projectId, transitionTower));

    yield put(
      actions.saveModelPaintDataSuccess(modelId, updatedModelStamps, rle)
    );

    yield put(actions.invalidateSlice(projectId));

    // update thumbnail
    yield put(actions.updateProjectThumbnailRequest(projectId));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

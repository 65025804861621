import React, { Component } from 'react';

import { withTheme } from 'styled-components';

import {
  ToastMessageContainer,
  IconWrapper,
  ToastTextWrapper,
  ToastButtonWrapper,
  ToastBody1,
  UL,
} from './toastMessage.styles';

import Icon from '../icon/icon.jsx';
import Icons from '../../themes/icons';

import Button from '../button/button.jsx';

class ToastMessage extends Component {
  static defaultProps = {
    type: '',
    message: '',
    label: '',
    buttonName: '',
    buttonOnClick: () => null,
  };

  render() {
    const { buttonName, buttonOnClick, type, theme, label } = this.props;
    let src = Icons.basic.checkCircle;
    let color = theme.colors.greenDefault;
    if (type === 'info') {
      src = Icons.basic.help;
      color = theme.colors.blueDefault;
    } else if (type === 'warn') {
      src = Icons.basic.alert;
      color = theme.colors.yellowDark;
    } else if (type === 'error') {
      src = Icons.basic.error;
      color = theme.colors.whiteDefault;
    }

    function handleNewLines(string) {
      let rowTitle;
      const content = [];
      let listItems = [];
      const lines = string.split('\n');
      lines.forEach((line, index) => {
        if (!line.startsWith('\t')) {
          if (listItems.length > 0) {
            content.push(<UL>{[...listItems]}</UL>);
            listItems = [];
          }
          rowTitle = (
            <ToastBody1 key={index} type={type}>
              {line}
            </ToastBody1>
          );
          content.push(rowTitle);
        } else {
          listItems.push(
            <li key={index}>
              <ToastBody1>{line}</ToastBody1>
            </li>
          );
        }
      });
      // add the last row of list items
      if (listItems.length > 0) {
        content.push(<UL>{[...listItems]}</UL>);
        listItems = [];
      }
      return <>{content}</>;
    }

    return (
      <ToastMessageContainer type={type}>
        {type !== '' && (
          <IconWrapper>
            <Icon src={src} color={color} />
          </IconWrapper>
        )}
        <ToastTextWrapper buttonName={buttonName}>
          {handleNewLines(label)}
        </ToastTextWrapper>
        {buttonName && (
          <ToastButtonWrapper type={type}>
            <Button
              buttonTextColor={type === 'error' ? color : null}
              onClick={() => buttonOnClick()}
              minimal
              height={'100%'}>
              {buttonName}
            </Button>
          </ToastButtonWrapper>
        )}
      </ToastMessageContainer>
    );
  }
}

export default withTheme(ToastMessage);

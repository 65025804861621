/* eslint-disable max-len */
import React from 'react';

import Routes from '../../router/routes';
import LegalPage from './legalPage.jsx';
import { Article } from './legal.styles';
import { Link } from '../../shared';

class TermsOfService extends LegalPage {
  componentDidMount() {
    document.title = 'Terms of Service • Canvas';
    this.props.updateNavStack([
      { text: 'Terms of Service', route: Routes.toTermsOfService() },
    ]);
  }

  renderMainPanelContent() {
    return (
      <Article>
        <header>
          <h1>Canvas Terms of Service and License Agreement</h1>
          <h3>Last updated: September 8, 2021</h3>
        </header>

        <ol>
          <li>
            <h4>Introduction; Definitions; Acceptance of Terms</h4>
            <ol>
              <li>
                <u>Introduction</u>: These Terms of Service and License
                Agreement (“<strong>Agreement</strong>” or “
                <strong>Terms</strong>”) constitutes the agreement between
                Mosaic Manufacturing Ltd., a Canadian corporation (hereinafter
                referred to as “<strong>Mosaic</strong>”, “<strong>We</strong>”,
                “<strong>Our</strong>”, or “<strong>Us</strong>”), and you the
                party using the Mosaic Software (as defined below) and/or using
                the Services (as defined below) (hereinafter referred to as “
                <strong>You</strong>” or “<strong>Customer</strong>”). By
                clicking on the “I ACCEPT” button and using the Software and/or
                using and accessing the Services, you indicate your acceptance
                of this Agreement. This Agreement takes effect on the date you
                accept these Terms (the “<strong>Effective Date</strong>”). You
                represent that You have reached the age of majority and are
                therefore legally able to enter into contracts.
              </li>
              <li>
                <u>Acceptance of Terms</u>: You may not use the Mosaic Software
                or Services without first agreeing to these Terms. By using the
                Software and/or accessing or using the Platform and Services you
                agree to be legally bound by these Terms, and all terms,
                policies and guidelines incorporated by reference in these
                Terms. If you do not agree with these Terms, you may not use of
                the Mosaic Software and/or access the Platform or use the
                Services. Mosaic, including its affiliates as applicable,
                reserves all of its rights at law and equity, including the
                right to block access to this Platform if you violate this
                Agreement.
              </li>
              <li>
                <u>Update to Terms</u>: The Terms may be changed and updated
                from time to time by Mosaic. Mosaic will either provide you with
                updated terms or post updated terms on its website{' '}
                <Link href='https://canvas3d.io/terms-of-service'>
                  https://canvas3d.io/terms-of-service
                </Link>
                . It is your responsibility to review the Agreement periodically
                on the website and your continued use of the Mosaic Software,
                Canvas Platform or Services constitutes your acceptance of the
                modified or additional terms.
              </li>
              <li>
                <u>Privacy Policy</u>: Mosaic’s Privacy Policy{' '}
                <Link href='https://canvas3d.io/privacy-policy'>
                  https://canvas3d.io/privacy-policy
                </Link>{' '}
                is incorporated herein by reference and forms an integral part
                of these Terms.
              </li>
              <li>
                <u>Definitions</u>:
                <ul>
                  <li>
                    “<strong>Account</strong>” means your Mosaic account,
                    including the login identification and password assigned to
                    You by Mosaic that allows you to access the Services.
                  </li>
                  <li>
                    “<strong>Agreement</strong>” or “<strong>Terms</strong>”
                    means these terms of service together with any legal notices
                    applicable to the Services and/or Software, all price
                    schedules, and other supplemental documents that may
                    supplement these Terms now or in the future.
                  </li>
                  <li>
                    “<strong>Applicable Law</strong>” means all applicable
                    local, national, federal, state, provincial and
                    international laws.
                  </li>
                  <li>
                    “<strong>Canvas Platform</strong>” means the Canvas
                    multi-material slicer, and includes any web, mobile, tablet,
                    device applications and application interfaces, and all
                    associated services.
                  </li>
                  <li>
                    “<strong>Content</strong>” means any data, text, software,
                    music, sound, photographs, graphics, video, e-mail messages,
                    3D models, .gcode, 3D printing content or any other
                    messages.
                  </li>
                  <li>
                    “<strong>Mosaic Content</strong>” means any Content, other
                    than User Content, transmitted, posted, displayed, or used
                    in connection with the Services.
                  </li>
                  <li>
                    “<strong>Mosaic Software</strong>” means the software
                    provided to You by Mosaic included in the Mosaic 3-D
                    Printing Products, including any related documentation,
                    firmware, modifications, enhancements or updates.
                  </li>
                  <li>
                    “<strong>Free Services Plan</strong>” means the basic Mosaic
                    Services Plan, that Mosaic offers to customers for free.
                  </li>
                  <li>
                    “<strong>Paid Services Plan</strong>” means any Services
                    Plan other than the Free Plan and includes the Advanced
                    Services Plan and Professional Services Plan.
                  </li>
                  <li>
                    “<strong>Services</strong>” means the services provided
                    through the Canvas Platform, including the ability to upload
                    slicer profiles, 3D models, and a streamlined flow for
                    multi-material 3D printing.
                  </li>
                  <li>
                    “<strong>Services Plan</strong>” means each service or
                    subscription plan offered by Mosaic in connection with the
                    Services.
                  </li>
                  <li>
                    “<strong>User</strong>” means you, the Customer, and any
                    employees or other persons to whom you grant access to your
                    Account.
                  </li>
                  <li>
                    “<strong>User Content</strong>” means any Content that You
                    send, submit, post, use or display in connection with the
                    Canvas Platform Services, for examples Content in the
                    Customer’s profile, design files, or videos.
                  </li>
                </ul>
              </li>
            </ol>
          </li>

          <li>
            <h4>Use of Service/License Grant</h4>
            <ol>
              <li>
                Upon your acceptance of these Terms and the payment of all
                (applicable) fees due to Mosaic, Mosaic grants to you a limited,
                personal, non-exclusive, non-assignable, non-transferable
                license to use the Software (in object code form only on one
                machine), Canvas Platform and Services (collectively, the “
                <strong>Mosaic Intellectual Property</strong>”) for your own
                personal use and not for resale or further distribution. Your
                right to use the Mosaic Intellectual Property is limited by all
                terms and conditions set forth in these Terms. You agree that
                You may not use the Mosaic Intellectual Property for any purpose
                other than as expressly permitted by this Agreement.
              </li>
              <li>
                Except for this license granted to you, Mosaic and its licensors
                retain all right, title and interest in and to the Mosaic
                Intellectual Property, including all related intellectual
                property rights. The Mosaic Intellectual Property is protected
                by applicable intellectual property laws, including Canadian
                copyright law and international treaties. All rights not
                expressly granted are reserved by Mosaic.
              </li>
              <li>
                You agree that You will use the Services only for lawful
                purposes. You acknowledge that Mosaic may terminate the Services
                immediately, without any notice and without any liability by
                Mosaic to You whatsoever, if, at the sole discretion of Mosaic,
                You or any User misuses or permits others to misuse or abuse any
                Service for purposes that are contrary to Applicable Law or
                contrary to these Terms (including section 3.4 (Acceptable
                Use)); Your breach of the license restrictions with respect to
                the Mosaic Software or Canvas Platform; or if Mosaic has to
                preserve the integrity of its network, or comply with applicable
                laws.
              </li>
              <li>
                You are liable for any and all use of the Service, and You
                remain solely responsible for all use of Service utilized
                through Your account, for determining who is authorized to use
                Your Service, and for promptly notifying Mosaic of any
                unauthorized use. You agree to indemnify and hold harmless
                Mosaic against any and all fees, charges, damages, liability,
                costs and expenses (including reasonable legal counsel fees, as
                applicable) associated with such use.
              </li>
            </ol>
          </li>

          <li>
            <h4>User Content</h4>
            <ol>
              <li>
                <u>User Content</u>. You are solely responsible for your User
                Content. You assume all risks associated with use of your User
                Content, including any reliance on its accuracy, completeness or
                usefulness by others, or any disclosure of your User Content
                that personally identifies you or any third party. You will
                retain copyright and any other rights you already hold in User
                Content. By sending, submitting, posting or displaying any User
                Content when using the Services, You hereby grant to Mosaic the
                limited right to use, reproduce, adapt and publish your User
                Content solely for the purposes of including your User Content
                on the Canvas Platform and for the purpose of enabling Mosaic to
                provide the Services to You.
              </li>
              <li>
                <u>Representations</u>. You hereby represent and warrant that
                you have all the rights, power and authority necessary to grant
                the above license, including obtaining consent to use personal
                information from identifiable individuals, and the right to
                publish and display your User Content and that your User Content
                does not violate our Acceptable Use Policy (defined in Section
                3.4).
              </li>
              <li>
                <u>Mosaic Endorsement</u>. You may not represent or imply to
                others that your User Content is in any way provided, sponsored
                or endorsed by Mosaic. Because you alone are responsible for
                your User Content, you may expose yourself to liability if, for
                example, your User Content violates the Acceptable Use Policy.
                Mosaic is not obligated to backup any User Content, and your
                User Content may be deleted at any time without prior notice.
                You are solely responsible for creating and maintaining your own
                backup copies of your User Content if you desire.
              </li>
              <li>
                <u>Acceptable Use Policy</u>. The following terms constitute Our
                “<strong>Acceptable Use Policy</strong>”:
                <ol className='legal-list-alpha'>
                  <li>
                    You agree not to use the Canvas Platform to collect, upload,
                    transmit, display, or distribute any User Content (i) that
                    violates any third-party right, including any copyright,
                    trademark, patent, trade secret, moral right, privacy right,
                    right of publicity, or any other intellectual property or
                    proprietary right; (ii) that is unlawful, harassing,
                    abusive, tortious, threatening, harmful, invasive of
                    another’s privacy, vulgar, defamatory, false, intentionally
                    misleading, trade libelous, pornographic, obscene, patently
                    offensive, promotes racism, bigotry, hatred, or physical
                    harm of any kind against any group or individual or is
                    otherwise objectionable; (iii) that is harmful to minors in
                    any way; or (iv) that is in violation of any law,
                    regulation, or obligations or restrictions imposed by any
                    third party.
                  </li>
                  <li>
                    In addition, you agree not to: (i) upload, transmit, or
                    distribute to or through the Canvas Platform any computer
                    viruses, worms, or any software intended to damage or alter
                    a computer system or data; (ii) send through the Canvas
                    Platform unsolicited or unauthorized advertising,
                    promotional materials, junk mail, spam, chain letters,
                    pyramid schemes, or any other form of duplicative or
                    unsolicited messages, whether commercial or otherwise; You
                    may not purchase, use, or access the Services for the
                    purpose of building a competitive product or service or for
                    any other competitive purposes; (iii) misuse the Canvas
                    Platform or our Services by interfering with their normal
                    operation, or attempting to access them using a method other
                    than through the interfaces and instructions that we
                    provide; (iv) circumvent or attempt to circumvent any
                    limitations that Mosaic imposes on your Account; (v) not
                    probe, scan, or test the vulnerability of any Mosaic system
                    or network; (vi) use any manual or automated system or
                    software to extract or scrape data from the Canvas Platform
                    or other interfaces through which we make our Services
                    available; (vi) deny others access to, or reverse engineer,
                    the Canvas Platform or Services, or attempt to do so; (vii)
                    transmit any viruses, malware, or other types of malicious
                    software, or links to such software, through the Services;
                    (viii) engage in abusive or excessive usage of the Services,
                    which is usage significantly in excess of average usage
                    patterns that adversely affects the speed, responsiveness,
                    stability, availability, or functionality of the Services
                    for other users; (ix) use the Services to infringe the
                    intellectual property rights of others, or to commit an
                    unlawful activity; (x) use software or automated agents or
                    scripts to produce multiple accounts on the Canvas Platform,
                    or to generate automated searches, requests, or queries to
                    (or to strip, scrape, or mine data from) the Canvas Platform
                    (provided, however, that we conditionally grant to the
                    operators of public search engines revocable permission to
                    use spiders to copy materials from the Canvas Platform for
                    the sole purpose of and solely to the extent necessary for
                    creating publicly available searchable indices of the
                    materials, but not caches or archives of such materials,
                    subject to the parameters set forth in our robots.txt file)
                    or (xi) register accounts by “bots” or other automated
                    methods.
                  </li>
                </ol>
              </li>
              <li>
                <u>Enforcement</u>. Mosaic may monitor the use of Services for
                violations of these Terms. Mosaic may suspend or delete your
                Account if We suspect a violation of these Terms, or if Mosaic
                thinks it necessary in order to protect its Services, or Mosaic,
                its affiliates, directors, officers, agents, and employees from
                harm. Mosaic has the right (but not the obligation) to review
                any User Content, and to investigate and/or take appropriate
                action against you in our sole discretion if you violate the
                Acceptable Use Policy or any other provision of these Terms or
                otherwise create liability for us or any other person. Such
                action may include removing or modifying your User Content,
                terminating your Account in accordance with Section 8, and/or
                reporting you to law enforcement authorities.
              </li>
            </ol>
          </li>

          <li>
            <h4>Your Account</h4>
            <ol>
              <li>
                In order to use the Service, You are required to register with
                an Account with Mosaic. In consideration of your use of the
                Services and Canvas Platform, you agree: (a) that you are of
                legal age and have capacity to agree to these Terms on your own
                behalf or on behalf of a minor who will be receiving our
                Services; and (b) you agree to provide true, accurate,
                up-to-date and complete information in all fields indicated as
                mandatory when registering for the Services, as well as any
                additional information provided or any amendments made by You.
                You agree to maintain and promptly update such information to
                keep it true, accurate, current, and complete. Subject to such
                registration, You will receive access to your Canvas Account.{' '}
                <strong>
                  You are not permitted to use the Canvas Platform or the
                  Services or submit User Content to the Canvas Platform if you
                  are under the age of 13.
                </strong>
              </li>
              <li>
                Mosaic recommends that you choose a password to your Account
                that is hard to guess and You keep it secure. It is your
                responsibility to ensure that You do not respond to any
                unsolicited requests for credit card information, password or
                other data. You are fully responsible for all, including any
                fraudulent, use of the Service, which is by or can be connected
                to your Account. Mosaic has the right to change your password
                and Account name at any time in its sole discretion and without
                notice.
              </li>
              <li>
                Mosaic reserves the right to take any action it deems necessary
                or reasonable to ensure the security of the Service and your
                Account, including without limitation, terminating your account,
                changing your password, or requesting additional information to
                authorize transactions on your account. Notwithstanding the
                above, Mosaic may rely on the authority of anyone accessing your
                account or using your password and under no circumstances will
                Mosaic be held liable to you for any liabilities or damages
                resulting from or arising out of (1) any action or inaction of
                Mosaic; (2) any compromise of the confidentiality of your
                Account or password; and (3) any unauthorized access to your
                Account or use of your password. Maintaining the security of
                your personally identifying information is extremely important
                to us. We will take reasonable steps to ensure your personally
                identifying information remains confidential. However, Mosaic
                can not guarantee that our Service is impenetrable to all
                security breaches, and therefore Mosaic makes no warranty,
                guarantee, or representation that our Service will be completely
                protected from viruses, security threats or other
                vulnerabilities.
              </li>
            </ol>
          </li>

          <li>
            <h4>Payments and Billing</h4>
            <ol>
              <li>
                Paid Service Plans are billed on a subscription basis (“
                <strong>Subscriptions</strong>”). You will be billed in advance
                on a recurring, periodic basis (each period is called a “billing
                cycle”). Billing cycles are typically monthly or annual,
                depending on what subscription plan you select when purchasing a
                Subscription. The price and billing cycle of our Subscriptions
                is listed on our website (
                <Link href='https://www.mosaicmfg.com/products/canvas'>
                  https://www.mosaicmfg.com/products/canvas
                </Link>
                ). You agree to pay Mosaic for the fees in accordance with the
                pricing and payment terms presented to you for that Services
                Plan (the “<strong>Fees</strong>”). Subscription prices are
                liable to change at any time, but changes will not affect
                pre-existing subscriptions.
              </li>
              <li>
                Your Subscription will automatically renew at the end of each
                billing cycle unless you cancel auto-renewal through your online
                account management page, or by contacting us at{' '}
                <Link href='https://www.mosaicmfg.com/pages/contact-us'>
                  https://www.mosaicmfg.com/pages/contact-us
                </Link>
                . You may cancel auto-renewal on your Subscription immediately
                after the Subscription starts if you do not want it to renew.
              </li>
              <li>
                Activation of a Paid Services Plan is conditional on acceptance
                of your initial credit card (Visa or MasterCard) and any
                reasonable Account verification methods that Mosaic may require.
                You acknowledge that Mosaic collects all charges pursuant to
                Mosaic’s preauthorized payment service utilizing Stripe (
                <Link href='https://stripe.com/ca'>https://stripe.com/ca</Link>)
                or Shopify Inc.’s (
                <Link href='https://www.shopify.com'>
                  https://www.shopify.com
                </Link>
                ) online e-commerce platform that allows us to sell our products
                and services to You (each a “<strong>Payment Processor</strong>
                ”). You agree to provide complete and accurate payment
                information and further agree that the processing of payment
                transactions is subject to the terms, conditions, and policies,
                including privacy policies, of the Payment Processor and your
                credit card issuer.
              </li>
              <li>
                Mosaic may change the Fees charged for the Paid Service Plan at
                any time, provided that, for Services billed on a subscription
                basis, the change will become effective only at the end of the
                then-current billing cycle of your Subscription. Mosaic will
                provide you with reasonable prior written notice of any change
                in fees to give you an opportunity to cancel your Subscription
                before the change becomes effective.
              </li>
              <li>
                All information that you provide in connection with a purchase
                or transaction or other monetary transaction interaction with
                the Services must be accurate, complete, and current. You are
                responsible for all taxes applicable to the fees and charges in
                connection with the Fees.
              </li>
              <li>
                In the event Mosaic is unable to debit your credit card, or
                otherwise is not paid in respect of the Fees, in addition to any
                other remedies available to Mosaic, Mosaic reserves the right to
                charge an administrative fee and late charges at a monthly
                compounded rate of 1.5% (19.56% per annum). Mosaic will review
                any disputed charge, provided You continue to pay the undisputed
                portion and subsequent invoices.
              </li>
              <li>
                Mosaic may add new services for additional fees and charges, or
                amend Fees and charges for existing service, at any time in its
                sole discretion, but will provide you with at least thirty (30)
                days’ notice prior to any new fees or charges coming into
                effect.
              </li>
            </ol>
          </li>

          <li>
            <h4>License Restrictions</h4>
            <ol>
              <li>
                Except as otherwise provided in these Terms, You shall not (i)
                make any modifications, or improvements to, or derivative works
                of the Mosaic Intellectual Property; (ii) reverse engineer,
                decompile or otherwise attempt to derive the source code,
                underlying ideas, algorithms, structure of organization of the
                Mosaic Intellectual Property; (iii) resell, sell, export,
                transfer, distribute, market, and/or sublicense the Mosaic
                Intellectual Property; or (iv) otherwise use the Mosaic
                Intellectual Property except as authorized pursuant to these
                Terms.
              </li>
            </ol>
          </li>

          <li>
            <h4>Important Disclaimers and Release</h4>
            <ol>
              <li>
                To the maximum extent permitted by Applicable Law and except as
                otherwise provided herein, the Mosaic Intellectual Property is
                provided “as is” and “as available” without any warranty of any
                kind, either express or implied. except as otherwise provided
                herein. Mosaic and its subsidiaries, affiliates and third-party
                service providers disclaim all warranties, express, implied, or
                statutory, to you as to any matter whatsoever, including as to
                merchantability, fitness for a particular purpose, availability
                or reliability, and non-infringement of third-party rights,
                howsoever arising, in each case to the maximum extent permitted
                by Applicable Law.
              </li>
              <li>
                Some jurisdictions do not allow the exclusion of implied
                warranties, so the above exclusion may not apply to You. Some
                jurisdictions do not allow limitations on how long an implied
                warranty lasts, so the above limitation may not apply to You.
              </li>
              <li>
                You hereby release and forever discharge Mosaic (and its
                officers, employees, agents, successors, and assigns) from, and
                hereby waive and release, each and every past, present and
                future dispute, claim, controversy, demand, right, obligation,
                liability, action and cause of action of every kind and nature
                (including personal injuries, death, and property damage), that
                has arisen or arises directly or indirectly out of, or relates
                directly or indirectly to, your use of the Canvas Platform
                and/or Services.
              </li>
            </ol>
          </li>

          <li>
            <h4>Termination by Mosaic</h4>
            <ol>
              <li>
                Mosaic may suspend or terminate Services, without any liability
                to You whatsoever, if You: fail to pay Your Account that is past
                due; harass, threaten or otherwise act unreasonably towards
                Mosaic, its employees or agents; are in breach of these Terms
                (including if You are in contravention of section 3.4 of these
                Terms (Acceptable Use)); use the Services so as to prevent fair
                and proportionate use by others; use the Service contrary to
                law, public policy or decency standards or reasonable usage
                policies instituted from time to time by Mosaic; charge or allow
                others to charge any other person for the use of the Services
                without Mosaic’s prior written agreement; alter or otherwise
                interfere with Mosaic’s facilities, equipment, network or
                connections or use the Services in a prohibited, unlawful, or
                improper manner, including but not limited to, permitting
                fraudulent use.
              </li>
              <li>
                Prior to suspension or termination, Mosaic will make reasonable
                efforts to provide You with advance notice, stating the reason
                for the proposed suspension or termination, the amount owing (if
                any) and any notice period, determined in Mosaic’s reasonable
                discretion, during which You will have the opportunity to
                rectify the reason for proposed suspension or termination.
              </li>
              <li>
                Notwithstanding anything contained herein to the contrary,
                Mosaic will not provide notice of a proposed suspension or
                termination:
                <ol className='legal-list-alpha'>
                  <li>
                    where immediate action must be taken to protect Mosaic’s
                    facilities, equipment, the Canvas Platform, Mosaic’s network
                    or connections, to protect the safety or security of others
                    (including protection against abusive behaviour), to stop
                    the commission of any offence (including fraud), or to
                    ensure compliance with any law, court order or other legal
                    requirement;
                  </li>
                  <li>
                    your breach of any material terms (including license
                    restrictions) of these Terms; or
                  </li>
                  <li>
                    where Mosaic has made reasonable efforts to provide you with
                    advance notice in accordance with the second paragraph above
                    but was unable to do so.
                  </li>
                </ol>
              </li>
              <li>
                A suspension or termination will not affect your obligation to
                pay any amounts owed to Mosaic either during or after the
                suspension or termination, including the full month’s charges
                for the month where your Service is suspended or terminated.
              </li>
            </ol>
          </li>

          <li>
            <h4>Termination by You</h4>
            <ol>
              <li>
                Mosaic permits You to terminate Your Service at any time by
                contacting Mosaic via e-mail at{' '}
                <Link href='mailto:support@mosaicmfg.com'>
                  support@mosaicmfg.com
                </Link>
                . Termination is effective thirty days from the date you provide
                Mosaic notice and You will be charged all applicable charges up
                to and including the effective date of cancellation. For annual
                Subscriptions, any Fees already paid will not be refunded.
              </li>
            </ol>
          </li>

          <li>
            <h4>Effect of Termination</h4>
            <ol>
              <li>
                Upon termination of this Agreement for whatever reason, all
                licenses and rights granted to You by Mosaic will terminate
                immediately and Mosaic will be entitled to immediate payment for
                all Fees incurred up to the date of termination. You shall not
                thereafter install or use the Mosaic Intellectual Property, and
                You must delete or destroy all copies of the Mosaic Intellectual
                Property in your possession. In the event of a termination of
                this Agreement for any reason by either party, You shall have no
                right to claim a refund for any sums paid to Mosaic. From and
                after the termination of this Agreement for any reason by either
                party, You shall not use the Mosaic Intellectual Property for
                any purpose whatsoever.
              </li>
            </ol>
          </li>

          <li>
            <h4>Feedback</h4>
            <ol>
              <li>
                If you provide Mosaic any feedback or suggestions regarding the
                Platform or Services (“<strong>Feedback</strong>”), you hereby
                assign and agree to assign upon our request to Mosaic all rights
                in the Feedback and agree that Mosaic shall have the right to
                use such Feedback and related information in any manner it deems
                appropriate. Mosaic will treat any Feedback you provide to
                Mosaic as non-confidential and non-proprietary. You agree that
                you will not submit to Mosaic any information or ideas that you
                consider to be confidential or proprietary.
              </li>
            </ol>
          </li>

          <li>
            <h4>Privacy Policy</h4>
            <ol>
              <li>
                Any personal information that you provide Mosaic through the
                Canvas Platform or Services is governed by the Canvas Privacy
                Policy (
                <Link href='https://canvas3d.io/privacy-policy'>
                  https://canvas3d.io/privacy-policy
                </Link>
                ), which is incorporated into these Terms of Services by this
                reference.
              </li>
            </ol>
          </li>

          <li>
            <h4>Indemnification</h4>
            <ol>
              <li>
                You agree to indemnify and hold harmless Mosaic, its parents,
                affiliates, customers, vendors, officers, licensors, agents,
                contractors and employees, from all demands, claims,
                proceedings, awards, action, damages, losses, costs, charges and
                expenses, including legal fees, incurred by or made against
                Mosaic, which result from or relate to your use of the Mosaic
                Intellectual Property and any violation of these Terms,
                including, but not limited to, any violation of any third party
                rights with respect to your User Content. You will give Mosaic
                prompt notice of any such demand, claim or proceeding that you
                are aware of.
              </li>
            </ol>
          </li>

          <li>
            <h4>Links to Third-Party Platforms</h4>
            <ol>
              <li>
                The Canvas Platform may contain a number of links to third-party
                websites. Mosaic does not control these linked sites, which you
                may use at your own risk, and is not responsible for the
                contents of any linked website, any link contained in a linked
                website, or any changes or updates to such websites. Mosaic
                makes no representation, warranty or endorsement whatsoever
                about any other website which you may access through this Canvas
                Platform.
              </li>
              <li>
                Mosaic does not warrant, endorse, guarantee, or assume
                responsibility for any product or service advertised or offered
                by a third party through the Canvas Platform or any hyperlinked
                website or service, and Mosaic will not be a party to or in any
                way monitor any transaction between you and third-party
                providers of products or service.
              </li>
            </ol>
          </li>

          <li>
            <h4>Limitation of Liability</h4>
            <ol>
              <li>
                To the maximum extent permitted by applicable law, in no event
                shall Mosaic, its affiliates, agents, directors, employees,
                suppliers or licensors be liable for any direct, indirect,
                punitive, incidental, special, consequential or exemplary
                damages, including without limitation damages for loss of
                profits, goodwill, use, data or other intangible losses, that
                result from the use of, or inability to use, this service. Under
                no circumstances will Mosaic be responsible for any damage, loss
                or injury resulting from hacking, tampering or other
                unauthorized access or use of the service or your account or the
                information contained therein.
              </li>
              <li>
                To the maximum extent permitted by applicable law, Mosaic
                assumes no liability or responsibility for any (i) errors,
                mistakes, or inaccuracies of the services content; (ii) personal
                injury or property damage, of any nature whatsoever, resulting
                from your access to or use of Our Services; (iii) any
                unauthorized access to or use of our secure servers and/or any
                and all personal information stored therein; (iv) any
                interruption or cessation of transmission to or from the
                Services; (v) any bugs, viruses, trojan horses, or the like that
                may be transmitted to or through our Services by any third
                party; and/or (vi) any errors or omissions in any User Content
                or for any loss or damage incurred as a result of the use of any
                content posted, emailed, transmitted, or otherwise made
                available through the service. In no event shall Mosaic, its
                affiliates, agents, directors, employees, suppliers, or
                licensors be liable to you for any claims, proceedings,
                liabilities, obligations, damages, losses or costs in an amount
                exceeding the amount you paid to Mosaic for use of the Services
                during the two months prior to the date of any claim.
              </li>
              <li>
                This limitation of liability section applies whether the alleged
                liability is based on contract, tort, negligence, strict
                liability, or any other basis, even if Mosaic has been advised
                of the possibility of such damage. The foregoing limitation of
                liability shall apply to the fullest extent permitted by law in
                the applicable jurisdiction.
              </li>
            </ol>
          </li>

          <li>
            <h4>Confidentiality</h4>
            <ol>
              <li>
                You shall safeguard, protect, respect, and maintain as
                confidential the Canvas Platform and Services, the underlying
                computer code to which you may obtain or receive access, the
                functional or technical design, logic, or other internal
                routines or workings of the Canvas Platform.
              </li>
            </ol>
          </li>

          <li>
            <h4>No Duty to Correct Errors</h4>
            <ol>
              <li>
                You acknowledge and agree that Mosaic has no obligation to
                correct any defects or errors in the Mosaic Intellectual
                Property, regardless of whether you inform Mosaic of such
                defects or errors or Mosaic otherwise is, or becomes aware of,
                such defects or errors. To the extent Mosaic provides you with
                any updates or upgrades to the Mosaic Intellectual Property such
                updates and upgrades shall be deemed to constitute part of the
                Mosaic Intellectual Property and shall be subject to all terms
                and provisions set forth in this Agreement, including, without
                limitation, terms and provisions related to licenses, use
                restrictions, ownership and distribution of the Mosaic
                Intellectual Property.
              </li>
            </ol>
          </li>

          <li>
            <h4>Trademarks</h4>
            <ol>
              <li>
                The trademarks and logos displayed on the Mosaic Software or
                Canvas Platform are the property of Mosaic or other third
                parties. You are not permitted to use any of these trademarks
                without the prior written consent of Mosaic or the third party.
              </li>
            </ol>
          </li>

          <li>
            <h4>Cookies</h4>
            <ol>
              <li>
                The Canvas Platform uses cookies to identify users, compile
                Canvas Platform usage statistics, and to provide customized
                services. Cookies are small text files stored on your computer
                to identify your browser. Our use of cookies does not enable
                others to access your personal information.
              </li>
            </ol>
          </li>

          <li>
            <h4>Security</h4>
            <ol>
              <li>
                The Canvas Platform uses administrative, contractual, physical,
                and technical means to protect against the unauthorized access
                to personal information, such as confidentiality agreements with
                employees, firewalls, Secure Sockets Layer protocol, usernames
                and passwords. Although Mosaic takes reasonable steps concerning
                security of the Canvas Platform, no website is completely secure
                from unauthorized access.
              </li>
            </ol>
          </li>

          <li>
            <h4>Jurisdiction</h4>
            <ol>
              <li>
                This Agreement shall be governed by and construed in accordance
                with the laws of the Province of Ontario and the laws of Canada
                applicable therein. All disputes, proceedings or claims arising
                out of or in connection with this Agreement will be subject to
                the exclusive jurisdiction of the Courts of the province of
                Ontario.
              </li>
            </ol>
          </li>

          <li>
            <h4>Assignment</h4>
            <ol>
              <li>
                You will not assign this Agreement or sublicense any of your
                rights under this Agreement without the prior written consent of
                Mosaic. Subject to the foregoing, this Agreement will be binding
                upon, and inure to the benefit of, the parties and their
                respective successors and assignees.
              </li>
            </ol>
          </li>

          <li>
            <h4>General</h4>
            <ol>
              <li>
                No agency, partnership, joint venture, or employment is created
                as a result of these Terms and you do not have any authority to
                bind Mosaic in any respect whatsoever. Any consent by a party
                to, or waiver of breach by the other, whether express or
                implied, will not constitute a consent to or waiver of or excuse
                for any other different or subsequent breach unless such waiver
                or consent is in writing and signed by the party claimed to have
                waived or consented. Except as otherwise provided herein, no
                term or provision will be deemed waived and no breach excused.
                These Terms constitute the entire agreement between the parties
                hereto with respect to the subject matter hereof and cancels and
                supersedes any prior understandings and agreements between the
                parties hereto with respect thereto. In the event that any one
                or more provisions contained in these Terms are held to be
                invalid, illegal or unenforceable, such invalidity, illegality
                or unenforceability will not affect any other provisions of
                these Terms and these Terms will be construed as if such
                invalid, illegal or enforceable provision had never been
                contained herein.
              </li>
            </ol>
          </li>

          <li>
            <h4>Contact</h4>
            <ol>
              <li>
                If you have any questions concerning these Terms, please contact
                Mosaic via email at{' '}
                <Link href='mailto:support@mosaicmfg.com'>
                  support@mosaicmfg.com
                </Link>
                , or 480 University Avenue, Suite 802, Toronto, Ontario, M5G
                1V2, Canada.
              </li>
            </ol>
          </li>
        </ol>
      </Article>
    );
  }
}

export default TermsOfService;

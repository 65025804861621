import 'regenerator-runtime/runtime';
import _ from 'lodash';
import { call, put, select, all } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

export default function* ungroupModels(action) {
  try {
    const { currentProject: projectId } = yield select(getSlicerState);
    const { models } = action.payload;

    yield all(
      _.map(models, (model) =>
        call(API, {
          method: methods.POST,
          path: `projects/${projectId}/models/${model.id}`,
          body: {
            path: `|${model.name}`,
          },
        })
      )
    );

    yield put(actions.ungroupModelsSuccess());
    yield put(actions.invalidateSlice(projectId));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import { paletteConstants } from '../../constants';
import {
  InfillStyles,
  SolidFillStyles,
} from '../../utils/profile-import/enums';

export const defaultStyle = {
  //
  // Meta
  //
  id: '',
  name: 'Standard',
  icon: 'default/styles/1',

  //
  // General
  //
  skinThickness: {
    units: 'mm',
    value: 0.6,
  },
  topSkinThickness: {
    value: 'auto',
  },
  semiSolidLayers: true,
  solidLayerSpeed: 40,
  solidLayerStyle: SolidFillStyles.STRAIGHT,
  topSolidLayerStyle: -1,
  monotonicSweep: false,
  useIroning: false,
  ironingFlowrate: {
    value: 'auto',
  },
  ironingSpacing: {
    value: 'auto',
  },
  ironingSpeed: {
    value: 'auto',
  },
  rapidXYSpeed: 60,
  rapidZSpeed: 30,
  avoidCrossingPerimeters: false,
  avoidCrossingPerimetersMaxDetour: {
    units: '%',
    value: 50,
  },

  //
  // Layer Height
  //
  useVariableLayerHeight: false,
  layerHeight: 0.2,
  maxLayerHeight: {
    units: '%',
    value: 150,
  },
  supportedStepover: {
    value: 'auto',
  },
  unsupportedStepover: {
    value: 'auto',
  },
  firstLayerHeight: {
    units: '%',
    value: 100,
  },
  extrusionWidth: 0.4,
  extrusionMultiplier: 100,
  useGapFill: true,
  gapFillMinLength: 1,
  useRetracts: true,
  retractLength: 1,
  retractSpeed: 30,
  retractDisableThreshold: 1,
  retractForceThreshold: 5,
  wipeLength: 0,
  zLift: 0.5,

  //
  // Perimeters
  //
  perimeterCount: 2,
  perimeterOrder: 2, // inside-out
  perimeterSpeed: {
    value: 'auto',
  },
  perimeterExtrusionWidth: {
    value: 'auto',
  },
  externalPerimeterSpeed: {
    value: 'auto',
  },
  externalPerimeterExtrusionWidth: {
    value: 'auto',
  },
  coastDistance: 0,
  seamAngle: 55,
  seamJitter: 45,
  seamOnCorners: true,

  //
  // Infill
  //
  infillDensity: 15,
  infillStyle: InfillStyles.STRAIGHT,
  spiralVaseMode: false,
  infillExtrusionWidth: {
    value: 'auto',
  },
  infillSpeed: {
    units: '%',
    value: 100,
  },
  infillPerimeterOverlap: 20,

  //
  // Temperature/Cooling
  //
  printTemperature: 210,
  firstLayerPrintTemperature: {
    value: 'auto',
  },
  bedTemperature: 60,
  useFan: true,
  enableFanAtLayer: 1,
  minLayerTime: 5,
  fanSpeed: 100,
  perimeterFanSpeed: {
    value: 'auto',
  },
  maxBridgingSpeed: {
    value: 'auto',
  },

  //
  // First Layer
  //
  zOffset: 0,
  firstLayerSpeed: {
    units: '%',
    value: 50,
  },
  firstLayerSizeCompensation: 0,
  useBrim: false,
  brimLoops: 2,
  brimLayers: 1,
  brimGap: 0,
  useRaft: false,
  defaultRaftExtruder: 0,
  raftXYInflation: 2,
  upperRaftSpeed: {
    units: '%',
    value: 60,
  },
  lowerRaftSpeed: {
    units: '%',
    value: 30,
  },
  raftZGap: 0.05,
  upperRaftLayerHeight: {
    value: 'auto',
  },
  lowerRaftLayerHeight: {
    value: 'auto',
  },
  upperRaftExtrusionWidth: {
    value: 'auto',
  },
  lowerRaftExtrusionWidth: {
    value: 'auto',
  },
  upperRaftDensity: {
    value: 'auto',
  },
  lowerRaftDensity: {
    value: 'auto',
  },

  //
  // Support
  //
  useSupport: false,
  useCustomSupports: false,
  supportSpeed: {
    value: 'auto',
  },
  supportDensity: 4, // "dense"
  maxOverhangAngle: 45,
  defaultSupportExtruder: {
    value: 'auto',
  },
  supportZGap: {
    units: 'mm',
    value: 0,
  },
  supportXYGap: 0,
  supportXYInflation: 2,
  useSupportStitchInterval: false,
  supportStitchInterval: 10,
  useSupportInterface: true,
  useRaftInterfaces: false,
  defaultSupportInterfaceExtruder: 0,
  supportInterfaceThickness: {
    units: 'layers',
    value: 2,
  },
  supportInterfaceSpeed: {
    value: 'auto',
  },
  supportInterfaceDensity: {
    value: 'auto',
  },
  supportInterfaceExtrusionWidth: {
    value: 'auto',
  },

  //
  // Transition
  //
  transitionMethod: 1,
  transitionLength: paletteConstants.DEFAULT_TRANSITION_LENGTH,
  transitionTarget: 40,
  purgeToInfill: false,
  towerMinDensity: 5,
  towerMinBottomDensity: 80,
  towerMaxDensity: 100,
  towerMinBrims: 0,
  towerSpeed: {
    value: 'auto',
  },
  towerExtrusionWidth: {
    value: 'auto',
  },
  sideTransitionPurgeSpeed: 4,
};

export const defaultPrinter = {
  id: '',
  tagId: '',
  modded: false,
  machineSettings: {
    // General
    name: 'Default Printer',
    icon: 'default/printers/1',

    // Extruder
    extruderCount: 1,
    nozzleDiameter: [0.4],
    filamentDiameter: [1.75],
    bowdenTubeLength: 0,

    // Bed
    circular: false,
    bedSize: { x: 250, y: 200, z: 200 },
    originOffset: { x: 0, y: 0 },

    // Firmware
    extension: 'gcode',
    gpxProfile: 'r2',
    aStepsPerMM: 0,
    bStepsPerMM: 0,
    addComments: true,
    firmwareType: 2,
    firmwarePurge: 0,
    firmwareRetraction: 0,
    jogPauses: false,
    clearBufferCommand: 0,

    // Transition
    chromaExtruder: 0,
    pingOffTower: false,
    sideTransitionPurgeInPlace: false,
    sideTransitionCoordinates: { x: 0, y: 0 },
    sideTransitionEdge: 0,
    sideTransitionEdgeOffset: 0,

    // G-code Sequences
    startSequence:
      'G28 ; home all axes\nM104 S<TEMP> ; heat extruder\nM140 S<BED> ; heat bed\nM109 S<TEMP> ; wait for extruder to heat\nM190 S<BED> ; wait for bed to heat\nG92 E0 ; reset extruder',
    endSequence:
      'M104 S0 ; turn off extruder\nM140 S0 ; turn off bed\nG28 X Y ; home X and Y axes',
    layerChangeSequence:
      '@printerscript 1.0\n"; layer {{layer + 1}}, Z = {{nextZ}}"',
    preSideTransitionSequence: '',
    sideTransitionSequence: '@printerscript 1.0\n',
    postSideTransitionSequence: '',
  },
  styles: [defaultStyle],
};

const initialState = {
  printers: {},
  presets: {},
  parsedProfile: null,
  currentSharedPrinter: '',
  tags: [],
  currentPrinterTag: null,
  status: {
    parseProfilePending: false,
    parseProfileSuccess: false,
    getPrintersPending: false,
    getPrintersSuccess: false,
    getPrinterPresetsPending: false,
    getPrinterPresetsSuccess: false,
    createPrinterPending: false,
    createPrinterSuccess: false,
    updatePrinterPending: false,
    updatePrinterSuccess: false,
    deletePrinterPending: false,
    deletePrinterSuccess: false,
    createStylesPending: false,
    createStylesSuccess: false,
    deleteStylesPending: false,
    deleteStylesSuccess: false,
    updateCalibrationSettingsPending: false,
    updateCalibrationSettingsSuccess: false,
    // Sharing
    createSharedPrinterPending: false,
    createSharedPrinterSuccess: false,
    deleteSharedPrinterPending: false,
    deleteSharedPrinterSuccess: false,
    updateSharedPrinterPending: false,
    updateSharedPrinterSuccess: false,
    getSharedPrinterPending: false,
    getSharedPrinterSuccess: false,
    importSharedPrinterPending: false,
    importSharedPrinterSuccess: false,
    queryPrinterTagsPending: false,
    queryPrinterTagsSuccess: false,
    getPrinterTagPending: false,
    getPrinterTagSuccess: false,
    createPrinterTagPending: false,
    createPrinterTagSuccess: false,
  },
};

export default initialState;

import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.isFront ? 3 : 0)};

  ${(props) =>
    props.isOrientingModel &&
    css`
      pointer-events: none;
    `}
`;

export const ModalButtonWrapper = styled.div`
  display: inline-block;
  margin-left: 0.5em;
`;

import React from 'react';

const useVariableLayerHeight = (
  <>
    <>
      This setting allows the slicer to automatically and smoothly change
      between a minimum and maximum layer height. The minimum layer height will
      be used where parts have shallow slopes, such as the top of a sphere.
      Maximum layer height will be used for steep slopes or vertical walls. For
      most parts this helps to maintain smooth surfaces without unnecessarily
      increasing print time. This setting can help to reduce transition material
      in multi-material prints.
    </>
    <>Only available when using KISSlicer.</>
  </>
);

const layerHeight = (
  <>
    Specify the height of a single print layer. A higher layer height will print
    more quickly but have more visible layer lines. A lower layer height will
    have smoother surfaces but print slower while using more transition material
    for multi-material prints.
  </>
);

const maxLayerHeight = (
  <>
    <>
      This is the maximum layer height that the slicer will use while variable
      layer heights is enabled.
    </>
    <>Only available when using KISSlicer.</>
  </>
);

const supportedStepover = (
  <>
    <>
      Specify the minimum amount a perimeter should overlap with the layer
      below. This value is used in variable layer height calculations.
      Generally, half of the extrusion width is a good starting point.
    </>
    <>Only available when using KISSlicer.</>
  </>
);

const unsupportedStepover = (
  <>
    <>
      Specify the minimum amount a perimeter should overlap with the layer
      above. This value is used in variable layer height calculations.
      Generally, half of the extrusion width is a good starting point; a lower
      value may require additional support material.
    </>
    <>Only available when using KISSlicer.</>
  </>
);

const firstLayerHeight = (
  <>
    Set the layer height used to print the first layer. A taller first layer can
    lead to better first layer adhesion especially with relatively low layer
    heights throughout the rest of the print.
  </>
);

export default {
  useVariableLayerHeight,
  layerHeight,
  maxLayerHeight,
  supportedStepover,
  unsupportedStepover,
  firstLayerHeight,
};

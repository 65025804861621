import styled, { css } from 'styled-components';

import { Breakpoints } from '../../../themes';
import { majorSectionStyles } from '../deviceManager.styles';

export const ConfigurationsSection = styled.div`
  ${majorSectionStyles};
  display: flex;
  flex: 1 1 0;
  min-width: 0;
`;

export const FilesSectionWrapper = styled.div`
  display: flex;
  flex: 1 1 0;
  min-width: 0;
`;

export const SectionPlaceholder = styled.div`
  background: ${(props) =>
    props.disabled && props.theme.colors.elevatedBackgroundSecondary};
  ${(props) =>
    !props.borderless &&
    css`
      border: 1px solid ${props.theme.colors.grey3};
      border-radius: 0.25rem;
    `}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 0;
  min-height: 8rem;
  padding: 0;
  box-sizing: border-box;
`;

export const TextWrapper = styled.div`
  margin: 1.25rem;
`;

export const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  height: 100%;
  min-width: 0;
  @media (max-width: ${Breakpoints.extraLargeWidth}) {
    flex-direction: column;
  }
`;

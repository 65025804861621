import 'regenerator-runtime/runtime';
import { call, select } from 'redux-saga/effects';

import { getSlicerState, getThreeState, handleGenericError } from '../common';

import { SlicerUtils, MatrixUtils } from '../../utils';
import { setRenderFlag } from './animationFrame';

export default function* addModelToScene(action) {
  try {
    const { scene } = yield select(getThreeState);
    if (!scene) return;
    const { allModelsHidden } = yield select(getSlicerState);
    const { model } = action.payload;
    if (model.mesh) {
      model.mesh.visible = model.visible;
      MatrixUtils.updateMeshTransforms(model.mesh, model.transforms);
      if (!allModelsHidden) {
        scene.add(model.mesh);
        SlicerUtils.applyStamps(model.mesh, model.stamps);
      }
    }
    setRenderFlag();
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import React from 'react';

import { Body1 } from '../../../../shared/typography/typography';
import { Image } from '../../../../shared';

const metadata = {
  title: 'Simplified project settings and device controls',
  content: (theme) => (
    <>
      <Body1 noSpacing>
        Instead of having to assemble a setup, directly choose which printer and
        device you are printing with. Device controls can now be accessed
        directly from the Devices page.
      </Body1>
      <Image
        alt='Simplified project settings and device controls'
        src={theme.images.onboarding.setupsSimplification}
      />
    </>
  ),
};

export default metadata;

import styled, { css } from 'styled-components';
import { Breakpoints, Shadows } from '../../themes';

export const SideBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  ${Shadows.d2}
  overflow: hidden;
  border-radius: 0.25rem;
  height: 100%;

  @media (max-width: ${Breakpoints.mediumWidth}) {
    flex-direction: column;
  }
`;

export const StyledSideBar = styled.div`
  width: 15.8rem;
  box-sizing: border-box;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  border-right: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 0.25rem 0 0 0.25rem;

  @media (max-width: ${Breakpoints.mediumWidth}) {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey3};
    border-radius: 0.25rem 0.25rem 0 0;
  }
`;

export const SideBarItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  padding: 0.75rem;

  cursor: pointer;
  border-radius: 0.25rem;
  background-color: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid transparent;

  ${(props) =>
    props.active &&
    css`
      background-color: ${props.theme.colors.elevatedBackgroundSecondary};
      border: 1px solid ${props.theme.colors.grey3};
    `}

  &:hover {
    background-color: ${(props) =>
      props.theme.colors.elevatedBackgroundSecondary};
  }

  @media (max-width: ${Breakpoints.mediumWidth}) {
    display: none;
  }
`;

export const DropDownWrapper = styled.div`
  display: none;

  @media (max-width: ${Breakpoints.mediumWidth}) {
    display: block;
    width: 100%;
  }
`;

export const TextWrapper = styled.div`
  overflow-wrap: break-word;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: ${Breakpoints.mediumWidth}) {
    justify-content: flex-start;
    margin-bottom: 0.5rem;
    width: auto;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
  gap: 1rem;
  width: calc(100% - 1.5rem - 1.5rem);

  @media (min-width: ${Breakpoints.largeWidth}) {
    ${(props) =>
      props.fullScreenView &&
      css`
        width: 80%;
      `}
  }
`;

import React, { Component } from 'react';
import _ from 'lodash';

import {
  CopyLinkButtonStyles,
  LinkContainerStyles,
  ModalContent,
  ShareProjectNameWrapper,
  ShareSelectionsWrapper,
  DateSharedContainer,
  Notification,
  SpliceSettingsTextWrapper,
  NotificationTextWrapper,
  FooterButtonWrapper,
  UpdateSharedCopyButtonWrapper,
  ShareTypeDropdownWrapper,
  CheckboxListWrapper,
} from './shareProjectModal.styles';

import Modal from '../modal/modal.jsx';
import CheckboxList from '../checkboxList/checkboxList.jsx';
import Dropdown from '../dropdown/dropdown.jsx';
import ShareLink from '../shareLink/shareLink.jsx';
import Button from '../button/button.jsx';

import { FormatUtils, InterfaceUtils } from '../../utils';

import { Subtitle1OneLine, Body1, Caption } from '../typography/typography';

class ShareProjectModal extends Component {
  constructor(props) {
    super(props);
    const { shareData } = props.selectedProject;
    this.state = {
      shareModels:
        shareData && shareData.shareId ? shareData.shareModels : true,
      shareStyle: shareData && shareData.shareId ? shareData.shareStyle : false,
      sharePrinter:
        shareData && shareData.shareId ? shareData.sharePrinter : false,
      shareMaterials:
        shareData && shareData.shareId ? shareData.shareMaterials : false,
      shareId: shareData && shareData.shareId ? shareData.shareId : '',
      project: props.selectedProject,
      restrictions: {
        resharing: false,
        downloadingModels: false,
        paintingModels: false,
      },
      shareType: shareData && shareData.shareId ? 'link' : 'off',
      linkCopied: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      createSharedProjectSuccess,
      deleteSharedProjectSuccess,
      updateSharedProjectSuccess,
    } = this.props;
    const updatedProject = this.props.selectedProject;

    const sharedProjectCreated =
      !prevProps.createSharedProjectSuccess && createSharedProjectSuccess;
    const sharedProjectDeleted =
      !prevProps.deleteSharedProjectSuccess && deleteSharedProjectSuccess;
    const sharedProjectUpdated =
      !prevProps.updateSharedProjectSuccess && updateSharedProjectSuccess;

    if (sharedProjectCreated) {
      const { shareModels, sharePrinter, shareStyle, shareMaterials, shareId } =
        updatedProject.shareData;
      this.setState({
        project: updatedProject,
        shareId,
        shareModels,
        sharePrinter,
        shareStyle,
        shareMaterials,
      });
    }

    if (sharedProjectDeleted) {
      this.setState({
        project: updatedProject,
        shareId: '',
        shareModels: true,
        sharePrinter: false,
        shareStyle: false,
        shareMaterials: false,
      });
    }

    if (sharedProjectUpdated) {
      const { shareModels, sharePrinter, shareStyle, shareMaterials } =
        updatedProject.shareData;
      this.setState({
        project: updatedProject,
        shareModels,
        sharePrinter,
        shareStyle,
        shareMaterials,
      });
    }
  }

  handleUpdateSharedCopy() {
    const {
      project,
      shareId,
      shareType,
      shareModels,
      sharePrinter,
      shareStyle,
      shareMaterials,
    } = this.state;

    if (shareId && shareType === 'link') {
      this.props.updateSharedProject(
        project.id,
        shareModels,
        sharePrinter,
        shareStyle,
        shareMaterials
      );
      this.setState({ linkCopied: false });
    }
  }

  copyToClipboard(link) {
    InterfaceUtils.copyToClipboard(link);

    // show notification that link was copied for 10s
    this.setState({ linkCopied: true });
    setTimeout(() => {
      this.setState({ linkCopied: false });
    }, 10000);
  }

  handleShareTypeChange(value) {
    const {
      project,
      shareId,
      shareModels,
      sharePrinter,
      shareStyle,
      shareMaterials,
    } = this.state;
    const selectionsNotEmpty =
      shareModels || sharePrinter || shareStyle || shareMaterials;

    if (shareId && value === 'off') {
      this.props.deleteSharedProject(project.id);
      this.setState({
        shareType: value,
        linkCopied: false,
      });
    }

    if (!shareId && value === 'link' && selectionsNotEmpty) {
      this.props.createSharedProject(
        project.id,
        shareModels,
        sharePrinter,
        shareStyle,
        shareMaterials
      );
      this.setState({
        shareType: value,
        linkCopied: false,
      });
    }
  }

  toggleShareRestrictionType(restrictionType) {
    const updatedRestrictions = {
      ...this.state.restrictions,
      [restrictionType]: !this.state.restrictions[restrictionType],
    };
    this.setState({ restrictions: updatedRestrictions });
  }

  toggleShareSelectionType(selectionType) {
    const newValue = !this.state[selectionType];
    const newState = {
      [selectionType]: newValue,
    };
    if (selectionType === 'sharePrinter') {
      newState.shareStyle = newValue;
    }
    this.setState(newState);
  }

  renderModalNotification() {
    const {
      createSharedProjectPending,
      deleteSharedProjectPending,
      updateSharedProjectPending,
    } = this.props;
    const { linkCopied } = this.state;
    const actionPending =
      createSharedProjectPending ||
      deleteSharedProjectPending ||
      updateSharedProjectPending ||
      linkCopied;

    let labelText = '';
    if (createSharedProjectPending) labelText = 'Creating share link';
    if (deleteSharedProjectPending) labelText = 'Turning off share link';
    if (updateSharedProjectPending) labelText = 'Updating shared copy';
    if (linkCopied) labelText = 'Link copied';

    return (
      <Notification reveal={actionPending}>
        <NotificationTextWrapper>
          <Caption noSpacing>{labelText}</Caption>
        </NotificationTextWrapper>
      </Notification>
    );
  }

  renderFooter() {
    const {
      createSharedProjectPending,
      deleteSharedProjectPending,
      updateSharedProjectPending,
    } = this.props;
    const actionPending =
      createSharedProjectPending ||
      deleteSharedProjectPending ||
      updateSharedProjectPending;

    return (
      <>
        <FooterButtonWrapper>
          <Button
            primary
            disabled={actionPending}
            title='Close'
            onClick={this.props.onMarginClick}>
            {actionPending ? 'Saving...' : 'Close'}
          </Button>
        </FooterButtonWrapper>
      </>
    );
  }

  renderUpdateSharedCopy() {
    const { shareData, modified } = this.state.project;
    let date = null;
    let shareStatusText = 'The project is currently not shared.';
    let canUpdate = false;
    if (shareData) {
      const { dateShared, shareId } = shareData;
      date = FormatUtils.isoToDateString(dateShared);
      shareStatusText = shareId
        ? `Shared copy last updated on ${date}.`
        : shareStatusText;

      const selectionChanged =
        this.state.shareModels !== shareData.shareModels ||
        this.state.sharePrinter !== shareData.sharePrinter ||
        this.state.shareStyle !== shareData.shareStyle ||
        this.state.shareMaterials !== shareData.shareMaterials;

      canUpdate = modified > dateShared || selectionChanged;
    }
    const selectionsNotEmpty =
      this.state.shareModels ||
      this.state.sharePrinter ||
      this.state.shareStyle ||
      this.state.shareMaterials;
    return (
      <>
        <DateSharedContainer>
          <Body1 grey noSpacing>
            {shareStatusText}
          </Body1>
        </DateSharedContainer>
        <UpdateSharedCopyButtonWrapper>
          <Button
            title='Update shared copy'
            disabled={!this.state.shareId || !canUpdate || !selectionsNotEmpty}
            onClick={() => this.handleUpdateSharedCopy()}>
            Update shared copy
          </Button>
        </UpdateSharedCopyButtonWrapper>
      </>
    );
  }

  renderLink() {
    const link = this.state.shareId
      ? `${process.env.REACT_APP_SHARE_HOST}/projects/${this.state.shareId}`
      : '';
    return (
      <>
        <LinkContainerStyles>
          <ShareLink link={link} disabled={!link} />
        </LinkContainerStyles>
        <CopyLinkButtonStyles>
          <Button
            title='Copy link'
            disabled={!link}
            onClick={() => this.copyToClipboard(link)}>
            Copy link
          </Button>
        </CopyLinkButtonStyles>
      </>
    );
  }

  renderShareTypeDropdown() {
    const options = [
      { label: 'Off', value: 'off' },
      { label: 'Link', value: 'link' },
      // { label: 'Username', value: 'username' },
    ];

    const selectionsNotEmpty =
      this.state.shareModels ||
      this.state.shareStyle ||
      this.state.shareMaterials;

    return (
      <ShareTypeDropdownWrapper>
        <Dropdown
          options={options}
          label='Sharing type'
          disabled={!selectionsNotEmpty}
          value={this.state.shareType}
          onChange={(value) => this.handleShareTypeChange(value)}
        />
      </ShareTypeDropdownWrapper>
    );
  }

  renderSelections() {
    const { materials, models } = this.props;
    const { shareModels, shareStyle, shareMaterials, sharePrinter, project } =
      this.state;

    const uniqueNonDefaultMaterialIds = _.filter(
      _.uniq(project.materialIds),
      (id) => id !== '0'
    );
    const materialNamesAndTypes = _.map(
      uniqueNonDefaultMaterialIds,
      (id) => `${materials[id].name} ${materials[id].type}`
    );
    const materialsTextToDisplay = !_.isEmpty(uniqueNonDefaultMaterialIds)
      ? `(${_.join(materialNamesAndTypes, ', ')})`
      : '';

    const spliceSettingsLabel = (
      <SpliceSettingsTextWrapper>
        <Caption grey>This includes any existing splice settings</Caption>
      </SpliceSettingsTextWrapper>
    );

    const hasModels =
      (_.has(project, 'modelCount') && project.modelCount > 0) || // when rendered from Project Manager
      !_.isEmpty(models); // when rendered from slicer

    const options = [
      {
        id: 'shareModels',
        name: 'Models',
        value: shareModels,
        disabled: hasModels || shareModels,
      },
      {
        id: 'sharePrinter',
        name: 'Printer profile settings',
        value: sharePrinter,
      },
      {
        id: 'shareStyle',
        name: 'Project style settings',
        value: shareStyle,
        disabled: sharePrinter,
      },
      {
        id: 'shareMaterials',
        name: 'Material profiles',
        value: shareMaterials,
        disabled: _.isEmpty(uniqueNonDefaultMaterialIds),
      },
    ];

    return (
      <>
        <ShareSelectionsWrapper>
          <Body1 noSpacing>Select what to share</Body1>
        </ShareSelectionsWrapper>
        <CheckboxListWrapper>
          <CheckboxList
            options={options}
            onChange={(id) => this.toggleShareSelectionType(id)}
            captionLabel={(id) =>
              id === 'shareMaterials' &&
              shareMaterials &&
              materialsTextToDisplay
            }
            subCaptionLabel={(id) =>
              id === 'shareMaterials' && shareMaterials && spliceSettingsLabel
            }
          />
        </CheckboxListWrapper>
      </>
    );
  }

  renderHeading() {
    return (
      <ShareProjectNameWrapper>
        <Subtitle1OneLine>
          {`Sharing: ${this.state.project.name}`}
        </Subtitle1OneLine>
      </ShareProjectNameWrapper>
    );
  }

  render() {
    return (
      <Modal onMarginClick={this.props.onMarginClick} width='27.5rem'>
        <ModalContent>
          {this.renderHeading()}
          {this.renderSelections()}
          {/* {this.renderRestrictions()} */}
          {this.renderShareTypeDropdown()}
          {this.renderLink()}
          {this.renderUpdateSharedCopy()}
          {this.renderFooter()}
          {this.renderModalNotification()}
        </ModalContent>
      </Modal>
    );
  }
}

export default ShareProjectModal;

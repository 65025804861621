import _ from 'lodash';

import SortUtils, {
  DeviceSortMode,
  DeviceSortDirection,
  FileSortMode,
  FileSortDirection,
  DeviceSortDefault,
  FileSortDefault,
} from '../sort/sort';
import Icons from '../../themes/icons';

const Utils = {
  /**
   * sort an array of devices in the order specified by the sortBy values
   * @param devicesList array of devices
   * @param sortBy object containing a sorting mode and direction, both integers
   * @returns array of sorted devices
   */
  sortDevices: (devicesList, sortBy = DeviceSortDefault) => {
    if (_.isEmpty(devicesList)) return [];

    const { mode, direction } = sortBy;
    const descending = direction === DeviceSortDirection.DESCENDING;

    switch (mode) {
      case DeviceSortMode.NAME: {
        return SortUtils.sortByName(devicesList, descending);
      }
      case DeviceSortMode.DATE_CREATED: {
        return SortUtils.sortByCreated(devicesList, descending);
      }
      case DeviceSortMode.DATE_MODIFIED: {
        return SortUtils.sortByModified(devicesList, descending);
      }
      default: {
        return SortUtils.sortByName(devicesList, descending);
      }
    }
  },
  /**
   * sort an array of file (or folder) objects in the order specified by the sortBy values
   * @param items array of file (or folder) objects
   * @param sortBy object containing a sorting mode and direction, both integers
   * @returns array of sorted file (or folder) objects
   */
  sortFilesOrFolders: (items, sortBy = FileSortDefault) => {
    if (_.isEmpty(items)) return [];
    const { mode, direction } = sortBy;
    const descending = direction === FileSortDirection.DESCENDING;

    switch (mode) {
      case FileSortMode.NAME: {
        return SortUtils.sortByName(items, descending);
      }
      case FileSortMode.DATE_MODIFIED: {
        return SortUtils.sortByModified(items, descending, 'dateModified');
      }
      default: {
        return SortUtils.sortByName(items, descending);
      }
    }
  },
  getDeviceIcon: (device) => {
    let icon;

    if (!device) {
      throw new Error('device not provided');
    }

    if (device.type === 'palette-3') {
      icon = Icons.mosaic.p3;
    } else if (device.type === 'canvas-hub') {
      icon = Icons.mosaic.hub;
    } else if (device.type === 'element') {
      icon = Icons.mosaic.element;
    } else if (device.type === 'array') {
      icon = Icons.mosaic.array;
    } else {
      // what device is this? just show a hub icon
      icon = Icons.mosaic.hub;
    }
    return icon;
  },
  getAccessoryIcon: (accessoryType, isConnectedMode) => {
    let icon;

    if (!accessoryType) {
      throw new Error('accessoryType not provided');
    }

    if (accessoryType === 'palette') {
      icon = Icons.mosaic.p;
    } else if (accessoryType === 'palette-2') {
      icon = Icons.mosaic.p2;
      if (!isConnectedMode) {
        icon = Icons.mosaic.p2Accessory;
      }
    } else if (accessoryType === 'palette-3') {
      icon = Icons.mosaic.p3;
      if (!isConnectedMode) {
        icon = Icons.mosaic.p3Accessory;
      }
    } else {
      // what accessory is this? just show a hub icon
      icon = Icons.mosaic.hub;
    }

    return icon;
  },
  getDeviceImage: (deviceType, deviceModel, theme) => {
    if (deviceType === 'palette-3') {
      if (deviceModel === 'p3') return theme.images.paletteIcons.palette3;
      if (deviceModel === 'p3-pro')
        return theme.images.paletteIcons.palette3Pro;
    } else if (deviceType === 'canvas-hub') {
      if (deviceModel === 'mosaic') return theme.images.hubIcons.hub;
      if (deviceModel === 'mosaic-s') return theme.images.hubIcons.hubS;
      if (deviceModel === 'diy') return theme.images.hubIcons.customHub;
    } else if (deviceType === 'element') {
      if (deviceModel === 'el') return theme.images.elementIcons.element;
      if (deviceModel === 'el-ht') return theme.images.elementIcons.elementHT;
    }
    return theme.images.mosaicLogo;
  },
  getAccessoryImage: (accessoryType, accessoryModel, theme) => {
    if (accessoryType === 'palette') {
      if (accessoryModel === 'p') return theme.images.paletteIcons.paletteImage;
      if (accessoryModel === 'p-plus')
        return theme.images.paletteIcons.palettePlusImage;
    } else if (accessoryType === 'palette-2') {
      if (accessoryModel === 'p2') return theme.images.paletteIcons.palette2;
      if (accessoryModel === 'p2-pro')
        return theme.images.paletteIcons.palette2Pro;
      if (accessoryModel === 'p2s') return theme.images.paletteIcons.palette2;
      if (accessoryModel === 'p2s-pro')
        return theme.images.paletteIcons.palette2Pro;
    } else if (accessoryType === 'palette-3') {
      if (accessoryModel === 'p3') return theme.images.paletteIcons.palette3;
      if (accessoryModel === 'p3-pro')
        return theme.images.paletteIcons.palette3Pro;
    }
    return theme.images.mosaicLogo;
  },
  getDeviceTypeName: (deviceType) => {
    if (deviceType === 'palette-3') return 'Palette';
    if (deviceType === 'canvas-hub') return 'Canvas Hub';
    if (deviceType === 'element') return 'Element';
    return 'Unknown';
  },
  getDeviceName: (deviceType, deviceModel) => {
    if (deviceType === 'palette-3') {
      if (deviceModel === 'p3') return 'Palette 3';
      if (deviceModel === 'p3-pro') return 'Palette 3 Pro';
    } else if (deviceType === 'canvas-hub') {
      if (deviceModel === 'mosaic') return 'Canvas Hub';
      if (deviceModel === 'mosaic-s') return 'Canvas Hub S';
      if (deviceModel === 'diy') return 'Custom Canvas Hub';
    } else if (deviceType === 'element') {
      if (deviceModel === 'el') return 'Element';
      if (deviceModel === 'el-ht') return 'Element HT';
    }
    return 'Unknown';
  },
  getAccessoryName: (accessoryType, accessoryModel) => {
    if (accessoryType === 'palette') {
      if (accessoryModel === 'p') return 'Palette';
      if (accessoryModel === 'p-plus') return 'Palette+';
    } else if (accessoryType === 'palette-2') {
      if (accessoryModel === 'p2') return 'Palette 2';
      if (accessoryModel === 'p2-pro') return 'Palette 2 Pro';
      if (accessoryModel === 'p2s') return 'Palette 2S';
      if (accessoryModel === 'p2s-pro') return 'Palette 2S Pro';
    } else if (accessoryType === 'palette-3') {
      if (accessoryModel === 'p3') return 'Palette 3';
      if (accessoryModel === 'p3-pro') return 'Palette 3 Pro';
    }

    return 'Unknown';
  },
  getDevicesWithPrintJobs: (allDeviceStates, allPendingRequests, devices) => {
    const devicesWithPrintJobs = {};

    _.forEach(devices, (device) => {
      const currentDeviceState = allDeviceStates[device.id] || null;
      const pendingRequests = allPendingRequests[device.id] || {};
      // need to check if a device is actually online,
      // to ensure that our current snapshot of device state is up-to-date
      if (currentDeviceState) {
        const isOnline = Utils.isOnline(currentDeviceState);
        const isPrinting = Utils.isPrinting(currentDeviceState, device);
        const isCancelled = Utils.isPrintCancelled(currentDeviceState, device);
        const isCompleted = Utils.isPrintCompleted(currentDeviceState, device);
        if (isOnline && isPrinting && !(isCancelled || isCompleted)) {
          devicesWithPrintJobs[device.id] = {
            device,
            currentDeviceState,
            pendingRequests,
          };
        }
      }
    });

    return devicesWithPrintJobs;
  },
  isOnline: (currentDeviceState) => currentDeviceState?.connected ?? false,
  isReadyToExtrude: (currentDeviceState, nozzleIndex = 0) => {
    const temperatureData =
      currentDeviceState?.deviceState?.printer?.data?.temperature;
    if (
      temperatureData &&
      Array.isArray(temperatureData.nozzle) &&
      temperatureData.nozzle[nozzleIndex]?.actual &&
      temperatureData.nozzle[nozzleIndex]?.target
    ) {
      return (
        Math.abs(
          temperatureData.nozzle[nozzleIndex].actual -
            temperatureData.nozzle[nozzleIndex].target
        ) < 20
      );
    }
    return false;
  },
  isConnectedToPrinter: (currentDeviceState) =>
    currentDeviceState?.deviceState?.printer?.data?.connected ?? false,
  isConnectedToAccessory: (currentDeviceState) =>
    currentDeviceState?.deviceState?.palette?.data?.connected ?? false,

  isPrinting: (currentDeviceState, device) => {
    if (device.type === 'canvas-hub') {
      return !!currentDeviceState?.deviceState?.printer?.job?.name ?? false;
    }
    return (
      currentDeviceState?.deviceState?.simcoe?.job?.name === 'PRINT_CONNECTED'
    );
  },
  isPrintPaused: (currentDeviceState, device) => {
    if (device.type === 'canvas-hub') {
      return (
        currentDeviceState?.deviceState?.printer?.job?.status?.name === 'paused'
      );
    }
    return (
      Utils.isPrinting(currentDeviceState, device) &&
      !currentDeviceState?.deviceState?.simcoe?.job?.finished &&
      currentDeviceState?.deviceState?.printer?.job?.status?.name === 'PAUSED'
    );
  },
  isPrintPausing: (currentDeviceState, device) =>
    Utils.isPrinting(currentDeviceState, device) &&
    currentDeviceState?.deviceState?.printer?.job?.status?.name === 'pausing',
  isPrintCancelling: (currentDeviceState, device) => {
    if (device.type === 'canvas-hub') {
      return (
        Utils.isPrinting(currentDeviceState, device) &&
        currentDeviceState?.deviceState?.printer?.job?.status?.name ===
          'cancelling'
      );
    }
    return (
      Utils.isPrinting(currentDeviceState, device) &&
      currentDeviceState?.deviceState?.simcoe?.job?.cancelling &&
      !currentDeviceState?.deviceState?.simcoe?.job?.finished
    );
  },
  isPrintCancelled: (currentDeviceState, device) =>
    Utils.isPrinting(currentDeviceState, device) &&
    currentDeviceState?.deviceState?.simcoe?.job?.cancelling &&
    currentDeviceState?.deviceState?.simcoe?.job?.finished &&
    !currentDeviceState?.deviceState?.simcoe?.job?.error &&
    !currentDeviceState?.deviceState?.palette?.job?.error,
  isPrintFailed: (currentDeviceState, device) =>
    Utils.isPrinting(currentDeviceState, device) &&
    currentDeviceState?.deviceState?.simcoe?.job?.finished &&
    (!!currentDeviceState?.deviceState?.simcoe?.job?.error ||
      !!currentDeviceState?.deviceState?.palette?.job?.error),
  isPrintCompleted: (currentDeviceState, device) =>
    Utils.isPrinting(currentDeviceState, device) &&
    currentDeviceState?.deviceState?.simcoe?.job?.finished &&
    !currentDeviceState?.deviceState?.simcoe?.job?.cancelling &&
    !currentDeviceState?.deviceState?.simcoe?.job?.error &&
    !currentDeviceState?.deviceState?.palette?.job?.error,
  getPrintFailure: (currentDeviceState, device) => {
    if (!Utils.isPrintFailed(currentDeviceState, device)) return null;
    return (
      currentDeviceState?.deviceState?.simcoe?.job?.error ||
      currentDeviceState?.deviceState?.palette?.job?.error
    );
  },
  isActivelyPrinting: (currentDeviceState, device) => {
    if (!Utils.isPrinting(currentDeviceState, device)) return false;
    if (Utils.isPrintCancelled(currentDeviceState, device)) return false;
    if (Utils.isPrintFailed(currentDeviceState, device)) return false;
    return !Utils.isPrintCompleted(currentDeviceState, device);
  },

  isMotorOn: (currentDeviceState) =>
    currentDeviceState?.deviceState?.printer?.data?.motor ?? false,
  getFanSpeed: (currentDeviceState) =>
    currentDeviceState?.deviceState?.printer?.data?.fan ?? 0,
  getPrintName: (currentDeviceState, device) => {
    if (device?.type === 'canvas-hub') {
      return currentDeviceState?.deviceState?.printer?.job?.name ?? '';
    }
    if (
      currentDeviceState?.deviceState?.simcoe?.job?.name === 'PRINT_CONNECTED'
    ) {
      if (currentDeviceState?.deviceState?.simcoe?.job?.data?.file?.name) {
        return currentDeviceState?.deviceState?.simcoe?.job?.data.file.name;
      }
    }
    return '';
  },
  getPrintPath: (currentDeviceState) =>
    currentDeviceState?.deviceState?.printer?.job?.data?.file?.path ?? '',
  getPrintTimeRemaining: (currentDeviceState) =>
    currentDeviceState?.deviceState?.printer?.job?.data?.timeRemaining ?? 0,
  getPrintTimeElapsed: (currentDeviceState) => {
    const timeData = currentDeviceState?.deviceState?.simcoe?.job?.time;
    if (!timeData) return 0;
    const { start } = timeData;
    let { finish } = timeData;
    if (!finish) {
      finish = new Date().toISOString();
    }
    const startSeconds = new Date(start).valueOf() / 1000;
    const finishSeconds = new Date(finish).valueOf() / 1000;
    return finishSeconds - startSeconds;
  },
  getPrintProgress: (currentDeviceState) =>
    currentDeviceState?.deviceState?.printer?.job?.progress ?? 0,
  getFilamentUsage: (currentDeviceState, device) => {
    if (device.type === 'canvas-hub') {
      return (
        currentDeviceState?.deviceState?.printer?.job?.data?.filament?.length ??
        0
      );
    }
    return (
      currentDeviceState?.deviceState?.palette?.job?.data?.filamentLengthUsed ??
      0
    );
  },
  getNozzleTemperature: (currentDeviceState, nozzleIndex = 0) => {
    const temperatureData =
      currentDeviceState?.deviceState?.printer?.data?.temperature;
    if (
      temperatureData &&
      Array.isArray(temperatureData.nozzle) &&
      temperatureData.nozzle[nozzleIndex]?.actual
    ) {
      return temperatureData.nozzle[nozzleIndex].actual;
    }
    return 0;
  },
  getTargetNozzleTemperature: (currentDeviceState, nozzleIndex = 0) => {
    const temperatureData =
      currentDeviceState?.deviceState?.printer?.data?.temperature;
    if (
      temperatureData &&
      Array.isArray(temperatureData.nozzle) &&
      temperatureData.nozzle[nozzleIndex]?.target
    ) {
      return temperatureData.nozzle[nozzleIndex].target;
    }
    return 0;
  },
  getBedTemperature: (currentDeviceState) =>
    currentDeviceState?.deviceState?.printer?.data?.temperature?.bed?.actual ??
    0,
  getTargetBedTemperature: (currentDeviceState) =>
    currentDeviceState?.deviceState?.printer?.data?.temperature?.bed?.target ??
    0,
  getChamberTemperature: (currentDeviceState) =>
    currentDeviceState?.deviceState?.printer?.data?.temperature?.chamber
      ?.actual ?? 0,
  getTargetChamberTemperature: (currentDeviceState) =>
    currentDeviceState?.deviceState?.printer?.data?.temperature?.chamber
      ?.target ?? 0,
  getNozzleTemperatureOptions: () => [
    {
      targetTemperature: 210,
      materialType: 'PLA',
    },
    {
      targetTemperature: 230,
      materialType: 'TPU',
    },
    {
      targetTemperature: 240,
      materialType: 'ABS',
    },
    {
      targetTemperature: 235,
      materialType: 'PETG',
    },
  ],
  getBedTemperatureOptions: () => [
    {
      targetTemperature: 60,
      materialType: 'PLA',
    },
    {
      targetTemperature: 50,
      materialType: 'TPU',
    },
    {
      targetTemperature: 110,
      materialType: 'ABS',
    },
    {
      targetTemperature: 70,
      materialType: 'PETG',
    },
  ],
  getChamberTemperatureOptions: () => [
    {
      targetTemperature: 0,
      materialType: 'PLA',
    },
    {
      targetTemperature: 0,
      materialType: 'TPU',
    },
    {
      targetTemperature: 110,
      materialType: 'ABS',
    },
    {
      targetTemperature: 0,
      materialType: 'PETG',
    },
  ],
  getAvailableStorageDrives: (currentDeviceState) =>
    currentDeviceState?.storageDrives ?? [],
  getCurrentDrivePathFiles: (currentDeviceState) =>
    currentDeviceState?.filesContext?.currentFiles ?? [],
  getAvailableComPorts: (currentDeviceState) =>
    currentDeviceState?.availablePorts ?? [],
  getPrinterSerialConnection: (currentDeviceState) =>
    currentDeviceState?.deviceState?.printer?.data?.serial ?? null,
  getAccessorySerialConnection: (currentDeviceState) =>
    currentDeviceState?.deviceState?.palette?.data?.serial ?? null,

  isTerminalSupported: (device) => device.type === 'canvas-hub',

  isPingListSupported: (currentDeviceState, device) =>
    device.type === 'palette-3' && Utils.isPrinting(currentDeviceState, device),
  getPingHistory: (currentDeviceState) =>
    currentDeviceState?.deviceState?.palette?.job?.data?.pings ?? [],
  getPongHistory: (currentDeviceState) =>
    currentDeviceState?.deviceState?.palette?.job?.data?.pongs ?? [],

  // does device/firmware support webcams?
  isWebcamSupported: (currentDeviceState) => {
    const webcamState = currentDeviceState?.deviceState?.simcoe?.data?.webcam;
    return !!webcamState;
  },
  // are remote webcam viewing permissions enabled?
  isWebcamAllowed: (currentDeviceState) => {
    const webcamState = currentDeviceState?.deviceState?.simcoe?.data?.webcam;
    return webcamState?.remoteWebcamAllowed ?? false;
  },
  // is there a webcam connected to the device?
  isWebcamConnected: (currentDeviceState) => {
    const webcamState = currentDeviceState?.deviceState?.simcoe?.data?.webcam;
    return webcamState?.connectedSources?.length > 0 ?? false;
  },
};

export default Utils;

import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import {
  FloatingContainer,
  InlineContainer,
  FileInput,
  DisplayContainer,
  TextContainer,
} from './fileUploader.styles';

import Icon from '../icon/icon.jsx';
import { Icons } from '../../themes';
import { Body2, Subtitle2 } from '../typography/typography';

class FileUploader extends Component {
  static defaultProps = {
    primaryLabel: 'Click or drag here to upload',
    secondaryLabel: '',
    onClick: () => {},
    onChange: () => {},
    accept: [],
    title: '',
    multiple: false,
    floating: false,
    icon: Icons.basic.upload,
  };

  renderInput() {
    return (
      <FileInput
        type='file'
        title={this.props.title}
        accept={this.props.accept.join(',')}
        multiple={this.props.multiple}
        onChange={(e) => this.props.onChange(e)}
        onClick={(e) => this.props.onClick(e)}
      />
    );
  }

  renderDisplay() {
    return (
      <DisplayContainer>
        <Icon
          src={this.props.icon}
          color={this.props.theme.colors.grey5}
          size={Icon.sizes.LARGE}
        />
        <TextContainer>
          <Subtitle2 grey>{this.props.primaryLabel}</Subtitle2>
          {this.props.secondaryLabel && (
            <Body2 grey>{this.props.secondaryLabel}</Body2>
          )}
        </TextContainer>
      </DisplayContainer>
    );
  }

  render() {
    if (this.props.floating) {
      return (
        <FloatingContainer
          minHeight={this.props.minHeight}
          hovered={this.props.hovered}>
          {this.renderDisplay()}
          {this.renderInput()}
        </FloatingContainer>
      );
    }
    return (
      <InlineContainer minHeight={this.props.minHeight}>
        {this.renderDisplay()}
        {this.renderInput()}
      </InlineContainer>
    );
  }
}

export default withTheme(FileUploader);

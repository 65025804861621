import types from './types';

const actions = {
  resetNotificationsStore: () => ({
    type: types.RESET_NOTIFICATIONS_STORE,
    payload: {},
  }),

  getNotificationsRequest: () => ({
    type: types.GET_NOTIFICATIONS_REQUEST,
    payload: {},
  }),
  getNotificationsSuccess: (notifications) => ({
    type: types.GET_NOTIFICATIONS_SUCCESS,
    payload: { notifications },
  }),
  getNotificationsFailure: (error) => ({
    type: types.GET_NOTIFICATIONS_FAILURE,
    payload: { error },
  }),

  updateNotificationRequest: (notificationId, read) => ({
    type: types.UPDATE_NOTIFICATION_REQUEST,
    payload: { notificationId, read },
  }),
  updateNotificationSuccess: (notification) => ({
    type: types.UPDATE_NOTIFICATION_SUCCESS,
    payload: { notification },
  }),
  updateNotificationFailure: (error) => ({
    type: types.UPDATE_NOTIFICATION_FAILURE,
    payload: { error },
  }),

  deleteNotificationRequest: (notificationId) => ({
    type: types.DELETE_NOTIFICATION_REQUEST,
    payload: { notificationId },
  }),
  deleteNotificationSuccess: (notificationId) => ({
    type: types.DELETE_NOTIFICATION_SUCCESS,
    payload: { notificationId },
  }),
  deleteNotificationFailure: (error) => ({
    type: types.DELETE_NOTIFICATION_FAILURE,
    payload: { error },
  }),

  receiveNotification: (notification) => ({
    type: types.RECEIVE_NOTIFICATION,
    payload: { notification },
  }),
};

export default actions;

import 'regenerator-runtime/runtime';
import { call, select } from 'redux-saga/effects';

import { types } from '../../reducers/three/three';

import { getThreeState, handleGenericError } from '../common';
import { setRenderFlag } from './animationFrame';

export default function* removeTowerFromScene(action) {
  try {
    const { scene } = yield select(getThreeState);
    if (!scene) return;
    const tower = scene.getObjectByName(types.TOWER_MESH_NAME);
    if (tower) {
      scene.remove(tower);
      setRenderFlag();
    }
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import { connect } from 'react-redux';

import actions from '../../../reducers/slicer/actions';
import printerActions from '../../../reducers/printers/actions';
import ProjectSettings from './projectSettings.jsx';

const mapStateToProps = (state) => {
  const { projects, materials, models } = state.slicer;
  const { printers } = state.printers;
  const { devices } = state.iot;
  const project = projects[state.slicer.currentProject];
  return {
    project,
    printers,
    devices,
    materials,
    models,
    printerTag: state.printers.currentPrinterTag,
    updatePrinterPending: state.printers.status.updatePrinterPending,
    createStylesPending: state.printers.status.createStylesPending,
    createStylesSuccess: state.printers.status.createStylesSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updatePrinterStyle: (printerId, styleId, styles) =>
    dispatch(
      printerActions.updateStyleProfileRequest(printerId, styleId, styles)
    ),
  updateProjectSettings: (projectId, settings) =>
    dispatch(actions.updateProjectSettingsRequest(projectId, settings, false)),
  createStyleProfiles: (printerId, styles) =>
    dispatch(printerActions.createStyleProfilesRequest(printerId, styles)),
  updateProjectName: (projectId, projectName) =>
    dispatch(actions.updateProjectNameRequest(projectId, projectName)),
  getPrinterTag: (tagId) =>
    dispatch(printerActions.getPrinterTagRequest(tagId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettings);

import styled, { css } from 'styled-components';

export const SideBarContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden auto;
  padding-right: 1rem;
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  height: 2.75rem;
  padding-left: 0.75rem;
  cursor: pointer;
  border-left: 0.5rem solid transparent;
  border-radius: 0 0.25rem 0.25rem 0;

  &:hover {
    background: ${(props) => props.theme.colors.elevatedBackgroundTertiary};
  }

  :last-child {
    margin-bottom: 1rem;
  }

  ${(props) =>
    props.isCurrent &&
    css`
      border-color: ${props.theme.colors.greenDefault};
    `};
`;

export const Menu = styled.div`
  flex: 1;

  :not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.grey3};
  }
`;

export const AppMenu = styled.div`
  flex: 1;
`;

export const TitleWrapper = styled.div`
  padding: 0 1rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
`;

export const AppMenuIconWrapper = styled.div`
  margin-right: 0.75rem;
  img {
    margin-right: 0.5rem;
    filter: ${(props) =>
      props.theme.name === 'dark' && props.allowColorInvert
        ? 'invert(100%)'
        : 'invert(0%)'};
  }
`;

export const SupportMenu = styled.div`
  margin: 2rem 0;
`;

export const SupportMenuItem = styled.div`
  justify-content: center;
  padding: 0.125rem 1.25rem;
`;

export const SideBarLogoWrapper = styled.div`
  margin: 0 0 0.5rem 1.25rem;
`;

export const CopyrightTextWrapper = styled.div`
  margin-bottom: 1rem;
  margin-left: 1.25rem;
  text-align: left;
`;

export const NewProjectButtonWrapper = styled.div`
  align-self: flex-start;
  margin: 1rem;
  flex-shrink: 0;
`;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Container,
  StyledHeader,
  StyledMenu,
  MenuItem,
  StyledFooter,
} from './userPanel.styles';

import Icon from '../../../icon/icon.jsx';
import Link from '../../../link/link.jsx';

import Routes from '../../../../router/routes';
import { actions as authActions } from '../../../../reducers/auth/auth';
import { Icons } from '../../../../themes';

import {
  Subtitle1,
  ButtonText,
  CaptionOneLine,
} from '../../../typography/typography';

const Header = () => {
  const { username, userEmail } = useSelector((state) => state.auth);
  return (
    <StyledHeader>
      <Subtitle1>{`Welcome, ${username}`}</Subtitle1>
      <CaptionOneLine grey noSpacing>
        {userEmail}
      </CaptionOneLine>
    </StyledHeader>
  );
};

const Menu = ({ onTogglePanel }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onClickAccountSettings = () => {
    onTogglePanel();
    history.push(Routes.toManageAccounts());
  };

  const onClickLogout = () => {
    onTogglePanel();
    history.push(Routes.toHome());
    dispatch(authActions.logout());
  };

  return (
    <StyledMenu>
      <MenuItem title='Account settings' onClick={onClickAccountSettings}>
        <Icon src={Icons.user.user} />
        <ButtonText>Account settings</ButtonText>
      </MenuItem>
      <MenuItem title='Logout' onClick={onClickLogout}>
        <Icon src={Icons.basic.logout} />
        <ButtonText>Logout</ButtonText>
      </MenuItem>
    </StyledMenu>
  );
};

const Footer = () => (
  <StyledFooter>
    <Link external href={Routes.toPrivacyPolicy()}>
      Privacy Policy
    </Link>
    <Link external href={Routes.toTermsOfService()}>
      Terms of Service
    </Link>
  </StyledFooter>
);

const UserPanel = ({ onTogglePanel = () => {} }) => (
  <Container onClick={(e) => e.stopPropagation()}>
    <Header />
    <Menu onTogglePanel={onTogglePanel} />
    <Footer onTogglePanel={onTogglePanel} />
  </Container>
);

export default UserPanel;

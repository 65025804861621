import 'regenerator-runtime/runtime';
import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';

import { getPaginatedItems } from '../../canvas-api';
import { handleGenericError, getAuthState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';
import { SlicerUtils } from '../../../utils';

const composeProjectMap = (projects) => {
  const sanitizedProjects = _.map(projects, (project) =>
    _.omit(project, 'token')
  );
  const projectsMap = {};
  _.forEach(sanitizedProjects, (project) => {
    projectsMap[project.id] = SlicerUtils.formatFields(project);
  });

  // ensure no projects have an invalid parentId reference, otherwise
  // they would not be navigable in the project manager views
  return _.mapValues(projectsMap, (project) => {
    const { parentId, ...projectWithoutParentId } = project;
    if (
      parentId &&
      (!projectsMap[parentId] || !projectsMap[parentId].directory)
    ) {
      // parent is missing or not a directory -- treat as a top-level project
      return projectWithoutParentId;
    }
    return project;
  });
};

export default function* loadProjects(action) {
  try {
    const { userId } = yield select(getAuthState);
    const projects = yield call(
      getPaginatedItems,
      `projects/user/${userId}`,
      'projects'
    );
    if (projects === null) return;
    const projectsMap = composeProjectMap(projects);
    yield put(actions.loadProjectsSuccess(projectsMap));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

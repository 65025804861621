import { fieldTypes, fieldSteps, slicers } from '../../../../../constants';
import tooltips from './tooltips.jsx';

const { EXTRUSION_WIDTH_STEP, LAYER_HEIGHT_STEP, RETRACT_STEP, SPEED_STEP } =
  fieldSteps;
const { KISSlicer } = slicers;

const Metadata = {
  general: {
    fields: [
      {
        name: 'extrusionWidth',
        label: 'Extrusion width',
        tags: [],
        tooltip: tooltips.extrusionWidth,
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: EXTRUSION_WIDTH_STEP,
          },
        ],
      },
      {
        name: 'extrusionMultiplier',
        label: 'Extrusion multiplier',
        tags: [],
        tooltip: tooltips.extrusionMultiplier,
        variants: [
          {
            units: '%',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: 1,
          },
        ],
      },
    ],
  },
  gapFill: {
    name: 'useGapFill',
    label: 'Gap fill',
    tags: ['crowning'],
    tooltip: tooltips.useGapFill,
    isChecked: (style) => style.useGapFill,
    displayIfEmpty: true,
    updateFieldsOnToggle: (checked, updateField) => {
      updateField('useGapFill', checked);
    },
    fields: [
      {
        name: 'gapFillMinLength',
        label: 'Gap fill threshold',
        tags: ['crowning'],
        tooltip: tooltips.gapFillMinLength,
        slicers: [KISSlicer],
        disabled: (style, slicer) =>
          !style.useGapFill || (slicer && slicer !== KISSlicer),
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            step: EXTRUSION_WIDTH_STEP,
          },
        ],
      },
    ],
  },
  retraction: {
    name: 'useRetracts',
    label: 'Retraction',
    tags: ['destring', 'de-string', 'de string'],
    tooltip: tooltips.useRetracts,
    isChecked: (style) => style.useRetracts,
    updateFieldsOnToggle: (checked, updateField) => {
      updateField('useRetracts', checked);
    },
    fields: [
      {
        name: 'retractLength',
        label: 'Retraction distance',
        tags: ['destring', 'de-string', 'de string'],
        tooltip: tooltips.retractLength,
        disabled: (style) => !style.useRetracts,
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            lte: false,
            step: RETRACT_STEP,
          },
        ],
      },
      {
        name: 'retractSpeed',
        label: 'Retraction speed',
        tags: ['destring', 'de-string', 'de string'],
        tooltip: tooltips.retractSpeed,
        disabled: (style) => !style.useRetracts,
        variants: [
          {
            units: 'mm/s',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: SPEED_STEP,
          },
        ],
      },
      {
        name: 'retractDisableThreshold',
        label: 'Never retract when travelling less than',
        tags: [],
        tooltip: tooltips.retractDisableThreshold,
        disabled: (style) => !style.useRetracts,
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: RETRACT_STEP,
          },
        ],
      },
      {
        name: 'retractForceThreshold',
        label: 'Always retract when travelling more than',
        tags: [],
        tooltip: tooltips.retractForceThreshold,
        slicers: [KISSlicer],
        disabled: (style, slicer) =>
          !style.useRetracts || (slicer && slicer !== KISSlicer),
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            gte: false,
            step: RETRACT_STEP,
          },
        ],
      },
      {
        name: 'wipeLength',
        label: 'Wipe distance',
        tags: [],
        tooltip: tooltips.wipeLength,
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            step: RETRACT_STEP,
          },
        ],
      },
      {
        name: 'zLift',
        label: 'Z-lift distance',
        tags: ['z-hop', 'z hop', 'z lift', 'vertical lift'],
        tooltip: tooltips.zLift,
        variants: [
          {
            units: 'mm',
            type: fieldTypes.number,
            min: 0,
            step: LAYER_HEIGHT_STEP,
          },
        ],
      },
    ],
  },
};

export default Metadata;

import styled from 'styled-components';

import { Breakpoints } from '../../../../themes';
import { floatingPanelStyles } from '../headerBarRight/headerBarRight.styles';

export const StyledContainer = styled.div`
  ${floatingPanelStyles};
  width: 18.75rem;
  max-width: calc(100vw - 4rem);
  max-height: calc(100vh - 4rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    min-width: calc(100% - 3rem);
  }
`;

export const JobSection = styled.div`
  box-sizing: border-box;
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
`;

export const SectionPlaceholder = styled.div`
  padding: 1rem 0;
`;

export const SectionTitleContainer = styled.div`
  padding-top: 1rem;
`;

export const JobList = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: minmax(0, 1fr);
  align-items: center;
  padding: 1rem 0;
`;

export const SliceJob = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  cursor: pointer;
`;

export const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 0.25rem;
`;

export const SliceJobInfo = styled.div`
  cursor: pointer;
  display: flex;
  gap: 0.25rem;
`;

export const SliceCancelButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const PrintJob = styled.div``;

export const Breakline = styled.div`
  width: 100%;
  margin: 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey3};
`;

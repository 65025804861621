import React from 'react';
import _ from 'lodash';

import {
  ExampleContainer,
  ExampleColumn,
  ExampleHeader,
  StyledCode,
  StyledPre,
  StyledSamp,
  Locals,
  Local,
} from './styles';

const In = (props) => (
  <StyledPre>
    <StyledCode>{props.children}</StyledCode>
  </StyledPre>
);

const Example = (props) => {
  let locals = null;
  if (props.locals) {
    locals = (
      <Locals border={!!props.out}>
        <ExampleHeader>Values</ExampleHeader>
        {_.map(props.locals, (value, name) => (
          <Local key={name}>
            {name}: {value}
          </Local>
        ))}
      </Locals>
    );
  }

  let inContent = props.in || null;
  if (_.isArray(props.in)) inContent = _.join(props.in, '\n');

  let outContent = null;
  if (props.out) {
    outContent = (
      <>
        <ExampleHeader>Output</ExampleHeader>
        {_.isArray(props.out) ? _.join(props.out, '\n') : props.out}
      </>
    );
  }

  return (
    <ExampleContainer>
      <In>
        <ExampleHeader>Input</ExampleHeader>
        {inContent}
      </In>
      <ExampleColumn>
        {props.out ? (
          <StyledSamp error={props.error}>{outContent}</StyledSamp>
        ) : null}
        {locals}
      </ExampleColumn>
    </ExampleContainer>
  );
};

export default Example;

import { call } from 'redux-saga/effects';
import API, { methods } from '../../../canvas-api';

export function* saveColorsRLE(projectId, modelId, rle) {
  return yield call(API, {
    method: methods.POST,
    path: `projects/${projectId}/models/${modelId}/colors`,
    body: {
      colors: rle,
    },
  });
}

export function* deleteColorsRLE(projectId, modelId) {
  return yield call(API, {
    method: methods.DELETE,
    path: `projects/${projectId}/models/${modelId}/colors`,
  });
}

import styled from 'styled-components';

import { Breakpoints } from '../../themes';
import { Image } from '../../shared';
import {
  defaultModalButtonStyles,
  defaultModalInputStyles,
} from '../../shared/modal/modal.styles';

export const ProjectThumbnailImage = styled(Image)`
  width: 100%;
  height: 100%;
  justify-self: center;
  text-align: center;
  object-fit: cover;
  margin: 0;
  & > span {
    font-size: 0.8rem;
    line-height: 1.5rem;
    white-space: normal;
  }
`;

export const ProjectThumbnailWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  margin: 0;
  align-items: center;
  justify-content: center;

  @media (max-width: ${Breakpoints.largeWidth}) {
    object-fit: contain;
    max-width: 100%;
  }
`;

export const ModalButtonWrapper = styled.div`
  ${defaultModalButtonStyles}
  margin-left: 0.5rem;
`;
export const ModalInputWrapper = styled.div`
  ${defaultModalInputStyles}
`;

export const ThumbnailContainerList = styled.div`
  position: relative;
  width: 5rem;
  padding: 0;
  margin: 0;
  background: ${(props) => props.theme.colors.grey2};
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
`;

export const FolderMoveCancelButtonWrapper = styled.div`
  ${defaultModalButtonStyles}
  grid-column: span 2;
`;

export const SmallFolderImageContainer = styled.div`
  height: 100%;
  width: 3rem;
  margin-right: 0.75rem;
  flex: none;
`;

export const MoveToFolderNameWrapper = styled.div`
  color: ${(props) =>
    props.cannotMoveTo
      ? props.theme.colors.textTertiary
      : props.theme.colors.textPrimary};
  overflow: auto;
  display: flex;
  align-items: center;
`;

export const FolderMoveWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  min-height: 8rem;
`;

export const FolderImage = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: contain;
  filter: ${(props) =>
    props.theme.name === 'dark' ? 'brightness(60%)' : 'brightness(93%)'};
`;

export const FolderMoveList = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
`;

export const FolderMoveItem = styled.li`
  display: flex;
  height: 2.5rem;
  margin: 0.05rem;
  padding: 0.1rem 0.2rem;
  cursor: pointer;
  background: ${(props) =>
    props.selected
      ? props.theme.colors.elevatedBackgroundSecondary
      : 'transparent'};
  border-left: 4px solid
    ${(props) =>
      props.selected ? props.theme.colors.greenDefault : 'transparent'};

  &:hover {
    background: ${(props) =>
      props.cannotMoveTo
        ? 'transparent'
        : props.theme.colors.elevatedBackgroundSecondary};
    cursor: ${(props) => (props.cannotMoveTo ? 'not-allowed' : 'pointer')};
  }
`;

export const ThumbnailSpinnerContainer = styled.div`
  font-size: 0.8rem;
`;

export const HeaderWrapper = styled.div`
  > div {
    justify-content: flex-start;
  }
`;

export const MoveUpButtonWrapper = styled.div`
  transform: rotateZ(90deg);
  margin-right: 0.5rem;
`;

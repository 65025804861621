import 'regenerator-runtime/runtime';
import { call, put, select, take } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions, types } from '../../../reducers/slicer/slicer';

import { SlicerUtils, TreeUtils } from '../../../utils';

const getNewColorRGBA = (newColorHex, alpha) => {
  const [r, g, b] = SlicerUtils.hexStringToRGBA(newColorHex);
  const newColorRGBA = [r, g, b, alpha];
  return newColorRGBA;
};

const getNewProjectColors = (project, newColorHex, alpha, index) => {
  const { colors } = project;
  const newColorRGBA = getNewColorRGBA(newColorHex, alpha);
  colors[index] = newColorRGBA;
  return colors;
};

const updateProjectWithNewColor = (project, colors) => ({ ...project, colors });

export default function* updateProjectColors(action) {
  try {
    const { currentProject: projectId } = yield select(getSlicerState);
    const { index, color, alpha, project, updateRLE } = action.payload;

    const newProjectColors = getNewProjectColors(project, color, alpha, index);

    if (updateRLE) {
      const IN_PLACE = true;
      // dispatch this action that triggers a job in worker
      yield put(actions.composeColorsRLERequest(IN_PLACE));

      // wait for job to finish, then the model RLE to be updated in store
      yield take(types.COMPOSE_COLORS_RLE_SUCCESS);
    }

    // get model tree with updated RLE
    const { models } = yield select(getSlicerState);

    // update model colors
    const newModelTree = TreeUtils.updateModelColors(models, newProjectColors);

    const response = yield call(API, {
      method: methods.POST,
      path: `projects/${project.id}`,
      body: {
        colors: newProjectColors,
      },
    });
    if (response === null) return;

    const updatedProject = updateProjectWithNewColor(project, newProjectColors);
    yield put(actions.updateProjectColorsSuccess(updatedProject, newModelTree));

    // update thumbnail
    yield put(actions.updateProjectThumbnailRequest(projectId));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

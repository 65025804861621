import 'regenerator-runtime/runtime';
import { all, call, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';
import { deleteAllStamps } from './helpers/stamps';
import { deleteTexture } from './helpers/texture';
import { deleteColorsRLE } from './helpers/colors-rle';

export default function* deleteModelPaintData(action) {
  const { currentProject: projectId } = yield select(getSlicerState);
  const {
    modelId,
    deleteRLE: colorsNeedsDelete,
    deleteTexture: textureNeedsDelete,
  } = action.payload;

  try {
    const calls = [];

    if (textureNeedsDelete) {
      // delete stamps and texture
      calls.push(call(deleteAllStamps, projectId, modelId));
      calls.push(call(deleteTexture, projectId, modelId));
    }

    if (colorsNeedsDelete) {
      // delete colors rle
      calls.push(call(deleteColorsRLE, projectId, modelId));
    }

    if (calls.length > 0) {
      const responses = yield all(calls);
      if (_.some(responses, (response) => response === null)) return;
    }

    yield put(actions.deleteModelPaintDataSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

import React, { useEffect, useState, useCallback } from 'react';
import { useTheme } from 'styled-components';

import AbstractDropdown from '../dropdown/dropdown.jsx';
import Icon from '../icon/icon.jsx';
import { Body1, Caption } from '../typography/typography';
import Toggle from '../toggle/toggle.jsx';

import {
  GridOptions,
  StyledOption,
  StyledOptionWrapper,
  StyledSelectedOptionText,
  StyledSelect,
  ToggleContainer,
  PaletteOptionWrapper,
  DisabledDevice,
  SwitchAccessoryModeContainer,
} from './deviceDropdown.styles';

import Icons from '../../themes/icons';

const connectedDeviceOptions = (theme) => [
  {
    label: '',
    value: 'p-plus',
    isGroupLabel: true,
    customOptionLabel: (
      <DisabledDevice>
        <Icon color={theme.colors.textTertiary} src={Icons.mosaic.pPlus} />
        <Caption noSpacing>+</Caption>
      </DisabledDevice>
    ),
  },
  {
    label: '',
    value: '',
    isGroupLabel: true,
  },
  {
    label: '2',
    value: 'p2',
    icon: Icons.mosaic.p2,
  },

  {
    label: '2 Pro',
    value: 'p2-pro',
    icon: Icons.mosaic.p2,
  },

  {
    label: '2S',
    value: 'p2s',
    icon: Icons.mosaic.p2,
  },

  {
    label: '2S Pro',
    value: 'p2s-pro',
    icon: Icons.mosaic.p2,
  },

  {
    label: '3',
    value: 'p3',
    icon: Icons.mosaic.p3,
  },

  {
    label: '3 Pro',
    value: 'p3-pro',
    icon: Icons.mosaic.p3,
  },
];

const accessoryDeviceOptions = [
  {
    label: '+',
    value: 'p-plus-accessory',
    icon: Icons.mosaic.pPlus,
  },
  {
    label: '',
    value: '',
    isGroupLabel: true,
  },
  {
    label: '2',
    value: 'p2-accessory',
    icon: Icons.mosaic.p2,
  },
  {
    label: '2 Pro',
    value: 'p2-pro-accessory',
    icon: Icons.mosaic.p2,
  },
  {
    label: '2S',
    value: 'p2s-accessory',
    icon: Icons.mosaic.p2,
  },
  {
    label: '2S Pro',
    value: 'p2s-pro-accessory',
    icon: Icons.mosaic.p2,
  },
  {
    label: '3',
    value: 'p3-accessory',
    icon: Icons.mosaic.p3,
  },
  {
    label: '3 Pro',
    value: 'p3-pro-accessory',
    icon: Icons.mosaic.p3,
  },
];

const deviceMap = {
  none: null,
  'p-plus-accessory': {
    type: 'palette',
    model: 'p-plus',
    accessoryMode: true,
  },
  p2: {
    type: 'palette-2',
    model: 'p2',
    accessoryMode: false,
  },
  'p2-accessory': {
    type: 'palette-2',
    model: 'p2',
    accessoryMode: true,
  },
  'p2-pro': {
    type: 'palette-2',
    model: 'p2-pro',
    accessoryMode: false,
  },
  'p2-pro-accessory': {
    type: 'palette-2',
    model: 'p2-pro',
    accessoryMode: true,
  },
  p2s: {
    type: 'palette-2',
    model: 'p2s',
    accessoryMode: false,
  },
  'p2s-accessory': {
    type: 'palette-2',
    model: 'p2s',
    accessoryMode: true,
  },
  'p2s-pro': {
    type: 'palette-2',
    model: 'p2s-pro',
    accessoryMode: false,
  },
  'p2s-pro-accessory': {
    type: 'palette-2',
    model: 'p2s-pro',
    accessoryMode: true,
  },
  p3: {
    type: 'palette-3',
    model: 'p3',
    accessoryMode: false,
  },
  'p3-accessory': {
    type: 'palette-3',
    model: 'p3',
    accessoryMode: true,
  },
  'p3-pro': {
    type: 'palette-3',
    model: 'p3-pro',
    accessoryMode: false,
  },
  'p3-pro-accessory': {
    type: 'palette-3',
    model: 'p3-pro',
    accessoryMode: true,
  },
};

const DeviceDropdown = (props) => {
  const {
    deviceConfig,
    onChange,
    disabled,
    disabledDeviceConfigs,
    hideLabel = false,
  } = props;

  const theme = useTheme();

  const label = hideLabel ? '' : 'Device';
  const getDeviceMapKey = () => {
    let setupDeviceMapKey;
    if (deviceConfig) {
      setupDeviceMapKey = deviceConfig.model;
      if (deviceConfig.accessoryMode) {
        setupDeviceMapKey += '-accessory';
      }
      if (disabled) {
        setupDeviceMapKey = 'none';
      }
    } else {
      setupDeviceMapKey = 'none';
    }
    return setupDeviceMapKey;
  };

  const deviceMapKey = getDeviceMapKey();
  const [accessoryMode, setAccessoryMode] = useState(
    deviceConfig?.accessoryMode || false
  );

  const updateCurrSelectedOption = useCallback(
    (icon) => {
      if (deviceMapKey === 'none') {
        if (icon) return null;
        return 'None';
      }
      const currPaletteList = deviceConfig?.accessoryMode
        ? accessoryDeviceOptions
        : connectedDeviceOptions(theme);

      const currPalette = currPaletteList.find((palette) => {
        return palette.value === deviceMapKey;
      });
      if (icon) return currPalette?.icon;
      if (currPalette?.value === 'p-plus-accessory') {
        return 'Palette+';
      }
      const currPaletteName = currPalette?.label;
      return `Palette ${currPaletteName} ${
        deviceConfig?.accessoryMode ? 'Accessory' : 'Connected'
      }`;
    },
    [theme, deviceConfig?.accessoryMode, deviceMapKey]
  );

  const [currSelectedOptionText, setCurrSelectedOptionText] = useState(
    updateCurrSelectedOption()
  );
  const [currSelectedOptionIcon, setCurrSelectedOptionIcon] = useState(
    updateCurrSelectedOption(true)
  );

  useEffect(() => {
    // update selected text on options change
    setCurrSelectedOptionText(updateCurrSelectedOption());
    setCurrSelectedOptionIcon(updateCurrSelectedOption(true));
  }, [deviceMapKey, updateCurrSelectedOption]);

  const handleAccessoryModeToggle = () => {
    setAccessoryMode(!accessoryMode);
    // handle auto switching palette from accessory to connected mode vice versa
    let newDeviceKey = deviceMapKey;
    if (deviceMapKey === 'none' || deviceMapKey === 'p-plus-accessory') return;
    if (accessoryMode && deviceMapKey.includes('-accessory')) {
      newDeviceKey = newDeviceKey.replace('-accessory', '');
    } else if (!accessoryMode) {
      newDeviceKey += '-accessory';
    }
    const newDeviceConfig = deviceMap[newDeviceKey];
    onChange(newDeviceConfig);
  };

  function renderAccessoryToggle() {
    return (
      <ToggleContainer>
        <Body1 grey noSpacing>
          Accessory
        </Body1>
        <Toggle
          on={accessoryMode}
          onChange={() => handleAccessoryModeToggle()}
        />
      </ToggleContainer>
    );
  }

  // add shared options for both lists
  const sharedOptions = [
    {
      label: 'None',
      value: 'none',
    },
    {
      label: '',
      value: 'deviceName',
      isGroupLabel: true,
      customOptionLabel: (
        <SwitchAccessoryModeContainer>
          <Body1 noSpacing grey>
            Palette
          </Body1>
          {renderAccessoryToggle()}
        </SwitchAccessoryModeContainer>
      ),
    },
  ];

  let updatedOptions = sharedOptions.concat(
    accessoryMode ? accessoryDeviceOptions : connectedDeviceOptions(theme)
  );

  // render device option icons
  updatedOptions = updatedOptions.map((option) => {
    if (option.icon) {
      return {
        ...option,
        label: '',
        customOptionLabel: (
          <PaletteOptionWrapper>
            {option.icon && (
              <Icon
                src={option.icon}
                color={
                  deviceMapKey === option.value ? theme.colors.whiteDefault : ''
                }
              />
            )}
            <Caption noSpacing white={deviceMapKey === option.value}>
              {option.label}
            </Caption>
          </PaletteOptionWrapper>
        ),
      };
    }
    return {
      ...option,
    };
  });

  if (disabledDeviceConfigs) {
    updatedOptions = updatedOptions.map((option) => {
      return {
        ...option,
        disabled: disabledDeviceConfigs[option.value] !== undefined,
      };
    });
  }

  return (
    <AbstractDropdown
      label={label}
      hideLabel={hideLabel}
      options={updatedOptions}
      value={deviceMapKey}
      customLabel={currSelectedOptionText}
      descriptionIcon={currSelectedOptionIcon}
      descriptionIconSize={hideLabel ? Icon.sizes.SMALL : 0} // todo revisit hacky fix
      disabled={disabled}
      StyledOptionsList={GridOptions}
      StyledOption={StyledOption}
      StyledOptionWrapper={StyledOptionWrapper}
      StyledSelect={StyledSelect}
      StyledSelectedOptionText={StyledSelectedOptionText}
      onChange={(key) => {
        const newDeviceConfig = deviceMap[key];
        onChange(newDeviceConfig);
      }}
    />
  );
};

export default DeviceDropdown;

import styled from 'styled-components';

import { Breakpoints } from '../../../themes';

export const OverrideSettings = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextWrapper = styled.div`
  width: fit-content;
`;

export const ContainerCheckbox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 3rem;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  border: 1px solid
    ${(props) =>
      props.isInvalid
        ? props.theme.colors.redDefault
        : props.theme.colors.grey3};
  border-radius: 0.2rem;
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.grey4
      : props.theme.colors.elevatedBackgroundPrimary};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  transition: all 0.2s;
  transition-property: border-color, background-color, opacity, color;

  & input {
    font-family: inherit;
    color: ${(props) =>
      props.disabled
        ? props.theme.colors.textTertiary
        : props.theme.colors.textPrimary};
  }
`;

export const CheckboxLabelStylesWrapper = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  pointer-events: none;
  user-select: none;
  z-index: 1;
  max-width: 100%;
`;

export const CheckboxWrapper = styled.div`
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  width: 100%;
  border: none;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 0.9rem;
  font-weight: ${(props) => props.theme.fonts.weightRegular};
`;

export const AddOverrideWrapper = styled.div`
  margin-top: 1rem;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    display: flex;
    width: 100%;
    justify-content: center;
    z-index: 1;
  }
`;

export const OverrideFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const OverrideDropdownWrapper = styled.div`
  width: fit-content;
  margin-bottom: 1rem;
`;

export const FieldRow = styled.div`
  display: grid;
  grid-column: span 1;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-row-gap: 0.2rem;
  align-items: start;
  padding: 0.2rem 0;

  @media (max-width: ${Breakpoints.mediumWidth}) {
    grid-column: span 2;
  }
`;

export const FieldWarningWrapper = styled.div`
  grid-column: 1 / -1;
  margin-bottom: 0.5rem;
`;

export const FieldRemoveButtonWrapper = styled.div`
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  justify-self: center;
  padding: 0.2rem;
  margin: 0.3rem 0.3rem 0 0.3rem;
  cursor: pointer;
`;

export const OverridePlaceholderStylesWrapper = styled.div`
  grid-column: span 2;

  @media (max-width: ${Breakpoints.extraSmallWidth}) {
    display: flex;
    width: 100%;
  }
`;

import types from './types';

const actions = {
  // request-response actions

  initClientRequest: (endpoint, clientId, region, identityId, token) => ({
    type: types.INIT_CLIENT_REQUEST,
    payload: {
      endpoint,
      clientId,
      region,
      identityId,
      token,
    },
  }),
  initClientResponse: (error = null) => ({
    type: types.INIT_CLIENT_RESPONSE,
    payload: { error },
  }),

  updateCredentialsRequest: (region, identityId, token) => ({
    type: types.UPDATE_CREDENTIALS_REQUEST,
    payload: { region, identityId, token },
  }),
  updateCredentialsResponse: (error = null) => ({
    type: types.UPDATE_CREDENTIALS_RESPONSE,
    payload: { error },
  }),

  subscribeRequest: (topicFilters) => ({
    type: types.SUBSCRIBE_REQUEST,
    payload: { topicFilters },
  }),
  subscribeResponse: (error = null) => ({
    type: types.SUBSCRIBE_RESPONSE,
    payload: { error },
  }),

  unsubscribeRequest: (topicFilters) => ({
    type: types.UNSUBSCRIBE_REQUEST,
    payload: { topicFilters },
  }),
  unsubscribeResponse: (error = null) => ({
    type: types.UNSUBSCRIBE_RESPONSE,
    payload: { error },
  }),

  horizonRequest: (requestId, deviceTopicRoot, path, payload) => ({
    type: types.HORIZON_REQUEST,
    payload: {
      requestId,
      deviceTopicRoot,
      path,
      payload,
    },
  }),
  horizonResponse: (requestId, error = null, response = null) => ({
    type: types.HORIZON_RESPONSE,
    payload: { requestId, error, response },
  }),

  // worker-initiated events

  status: (online) => ({
    // connectivity status change
    type: types.STATUS,
    payload: { online },
  }),
  reinitialize: () => ({
    // fatal error; client needs re-initialization
    type: types.REINITIALIZE,
    payload: {},
  }),
  refresh: (clientId) => ({
    // credential refresh required
    type: types.REFRESH,
    payload: { clientId },
  }),
  message: (topic, message) => ({
    // all messages other than Horizon responses
    type: types.MESSAGE,
    payload: { topic, message },
  }),
};

export default actions;

import _ from 'lodash';

import { SlicerUtils, TreeUtils } from '../../../utils';

const Utils = {
  canToggleGroup: (models) => {
    const selectedModels = SlicerUtils.getSelectedModels(models);
    let individualsSelected = false;
    let fullGroupsSelected = false;
    let groupNames = [];
    if (selectedModels.length > 1) {
      const parents = [];
      _.forEach(selectedModels, (model) => {
        const parent = TreeUtils.getParent(models, model);
        parents.push(parent);
      });
      individualsSelected = parents.every((item) => item === null);
      fullGroupsSelected = parents.every((item) => {
        if (item === null) return false;
        const isGroup = item.type === 'group';
        const allChildrenActive = item.children.every((child) => child.active);
        return isGroup && allChildrenActive;
      });
      if (individualsSelected)
        groupNames.push(Utils.makeNameUnique(models, 'Group'));
      if (fullGroupsSelected) groupNames = _.map(parents, (item) => item.name);
    }
    return {
      selectedModels,
      canToggle: individualsSelected || fullGroupsSelected,
      isGrouping: individualsSelected,
      groupNames,
    };
  },
  makeNameUnique: (models, name) => {
    // eslint-disable-next-line no-param-reassign
    name = name.trim();
    const groups = _.filter(models, (model) => model.type === 'group');
    const existingModelNames = TreeUtils.flattenDeep(models).map(
      (model) => model.name
    );
    const existingGroupNames = _.map(groups, (model) => model.name);
    const existingNames = _.map(
      [...existingModelNames, ...existingGroupNames],
      (modelName) => modelName.trim().toLowerCase()
    );
    let nameIsUnique = !existingNames.includes(name.toLowerCase());
    if (nameIsUnique) return name;
    let counter = 2;
    const alreadyMadeUnique = name.match(/\((\d+)\)\s*$/);
    let updatedName = name;
    if (alreadyMadeUnique) {
      const nameCounter = alreadyMadeUnique[1];
      counter = parseInt(nameCounter, 10) + 1;
      const lastIndex = name.lastIndexOf('(');
      updatedName = name.slice(0, lastIndex).trim();
    }
    while (!nameIsUnique) {
      updatedName = `${name} (${counter})`;
      nameIsUnique = !existingNames.includes(updatedName.toLowerCase());
      counter++;
    }
    return updatedName;
  },
};

export default Utils;

import React from 'react';
import AbstractCollapsiblePanel from '../abstractCollapsiblePanel/abstractCollapsiblePanel.jsx';

import {
  Container,
  Header,
  Content,
  Footer,
} from './toolCollapsiblePanel.styles';

function ToolCollapsiblePanel(props) {
  return (
    <AbstractCollapsiblePanel
      {...props}
      Container={Container}
      Header={Header}
      Content={Content}
      Footer={Footer}
    />
  );
}

export default ToolCollapsiblePanel;

import { connect } from 'react-redux';

import { actions } from '../../reducers/auth/auth';
import UserAuthorization from './userAuthorization.jsx';

const mapStateToProps = (state) => ({
  allIntegrations: state.auth.allIntegrations,
  getIntegrationsPending: state.auth.status.getIntegrationsPending,
  getIntegrationsSuccess: state.auth.status.getIntegrationsSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  authorizeIntegration: (
    clientId,
    redirectUri,
    responseType,
    state,
    userAuthorization
  ) =>
    dispatch(
      actions.authorizeIntegrationRequest(
        clientId,
        redirectUri,
        responseType,
        state,
        userAuthorization
      )
    ),
  getIntegrations: () => dispatch(actions.getIntegrationsRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAuthorization);

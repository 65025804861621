import React, { Component } from 'react';

import { Button, Codearea, Page } from '../../../shared';
import Routes from '../../../router/routes';
import {
  Container,
  EditorAndOutput,
  EditorWrapper,
  Output,
  Header,
} from './styles';

import { StyledOutputCode, StyledPre } from '../docs/styles';

import { evaluate as evaluateScript } from '../../../utils/printerscript';

class PrinterScriptPlayground extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: '',
      output: '',
      error: false,
    };
  }

  componentDidMount() {
    document.title = 'PrinterScript Playground • Canvas';
    this.props.updateNavStack([
      { text: 'PrinterScript', route: Routes.toPrinterScript() },
      { text: 'Playground', route: Routes.toPrinterScriptPlayground() },
    ]);
  }

  reset() {
    this.setState({
      input: '',
      output: '',
      error: false,
    });
  }

  evaluate() {
    try {
      let input = `${this.state.input}\n`;
      if (input.startsWith('@')) {
        input = input.slice(input.indexOf('\n'));
      }
      if (!input.trim()) {
        this.setState({
          error: true,
          output: 'No program to run!',
        });
        return;
      }
      const visitor = evaluateScript(input);
      this.setState({
        error: false,
        output: visitor.result,
      });
    } catch (err) {
      this.setState({
        error: true,
        output: err.message,
      });
    }
  }

  onKeyDown(e) {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      this.evaluate();
    }
  }

  renderButtons() {
    return (
      <>
        <Button onClick={() => this.reset()}>Clear</Button>
        <Button primary onClick={() => this.evaluate()}>
          Run
        </Button>
      </>
    );
  }

  renderEditor() {
    return (
      <EditorWrapper>
        <Codearea
          label='Playground'
          mode='printerscript'
          value={this.state.input}
          onChange={(value) => this.setState({ input: value })}
        />
      </EditorWrapper>
    );
  }

  renderOutput() {
    const { output, error } = this.state;
    return (
      <Output>
        <StyledPre>
          <StyledOutputCode error={error}>{output}</StyledOutputCode>
        </StyledPre>
      </Output>
    );
  }

  render() {
    return (
      <Page>
        <Container>
          <Header>{this.renderButtons()}</Header>
          <EditorAndOutput onKeyDown={(e) => this.onKeyDown(e)}>
            {this.renderEditor()}
            {this.renderOutput()}
          </EditorAndOutput>
        </Container>
      </Page>
    );
  }
}

export default PrinterScriptPlayground;

import styled from 'styled-components';

import { Breakpoints } from '../../../../themes';

export const LegendContainer = styled.table`
  font-size: 0.8em;
  margin: 1em 0 0;
  color: ${(props) => props.theme.colors.textPrimary};

  @media (max-width: ${Breakpoints.smallWidth}),
    (max-height: ${Breakpoints.smallHeight}) {
    margin: 0;
    margin-top: 0.54rem;
    width: 100%;
  }
`;

export const LegendBody = styled.tbody``;

export const LegendEntry = styled.tr``;

export const LegendColor = styled.td`
  width: 1.2em;
  background-color: ${(props) => props.color};
  border: solid 1px ${(props) => props.theme.colors.grey4};
`;

export const LegendLabel = styled.td`
  padding-left: 0.5em;
`;

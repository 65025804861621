/* eslint-disable */
// Generated from SequenceParser.g4 by ANTLR 4.10.1
// jshint ignore: start
import antlr4 from 'antlr4';
import SequenceParserListener from './SequenceParserListener.js';
import SequenceParserVisitor from './SequenceParserVisitor.js';

const serializedATN = [4,1,49,143,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,7,
4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,1,0,1,0,1,0,1,1,1,1,1,1,1,1,1,
1,1,1,1,1,1,1,1,1,1,1,3,1,34,8,1,1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,3,
1,3,1,3,1,3,1,3,1,3,1,3,1,3,3,3,53,8,3,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,
5,1,5,3,5,65,8,5,1,6,1,6,1,6,1,6,1,7,1,7,5,7,73,8,7,10,7,12,7,76,9,7,1,7,
1,7,1,7,5,7,81,8,7,10,7,12,7,84,9,7,1,7,3,7,87,8,7,1,8,1,8,1,8,1,8,1,8,1,
8,3,8,95,8,8,1,9,1,9,1,9,1,9,1,9,1,9,5,9,103,8,9,10,9,12,9,106,9,9,3,9,108,
8,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,3,9,124,8,9,
1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,5,9,138,8,9,10,9,12,9,141,
9,9,1,9,0,1,18,10,0,2,4,6,8,10,12,14,16,18,0,7,2,0,45,45,49,49,2,0,44,44,
48,48,2,0,43,43,47,47,1,0,21,23,1,0,19,20,2,0,13,16,24,25,1,0,11,12,157,
0,20,1,0,0,0,2,33,1,0,0,0,4,35,1,0,0,0,6,52,1,0,0,0,8,54,1,0,0,0,10,64,1,
0,0,0,12,66,1,0,0,0,14,86,1,0,0,0,16,94,1,0,0,0,18,123,1,0,0,0,20,21,3,2,
1,0,21,22,5,0,0,1,22,1,1,0,0,0,23,34,1,0,0,0,24,25,3,4,2,0,25,26,3,2,1,0,
26,34,1,0,0,0,27,28,3,8,4,0,28,29,3,2,1,0,29,34,1,0,0,0,30,31,3,10,5,0,31,
32,3,2,1,0,32,34,1,0,0,0,33,23,1,0,0,0,33,24,1,0,0,0,33,27,1,0,0,0,33,30,
1,0,0,0,34,3,1,0,0,0,35,36,5,1,0,0,36,37,5,6,0,0,37,38,3,18,9,0,38,39,5,
7,0,0,39,40,5,9,0,0,40,41,3,2,1,0,41,42,5,10,0,0,42,43,3,6,3,0,43,5,1,0,
0,0,44,53,1,0,0,0,45,46,5,2,0,0,46,53,3,4,2,0,47,48,5,2,0,0,48,49,5,9,0,
0,49,50,3,2,1,0,50,51,5,10,0,0,51,53,1,0,0,0,52,44,1,0,0,0,52,45,1,0,0,0,
52,47,1,0,0,0,53,7,1,0,0,0,54,55,5,3,0,0,55,56,5,6,0,0,56,57,3,18,9,0,57,
58,5,7,0,0,58,59,5,9,0,0,59,60,3,2,1,0,60,61,5,10,0,0,61,9,1,0,0,0,62,65,
3,12,6,0,63,65,3,14,7,0,64,62,1,0,0,0,64,63,1,0,0,0,65,11,1,0,0,0,66,67,
5,31,0,0,67,68,5,17,0,0,68,69,3,18,9,0,69,13,1,0,0,0,70,74,5,28,0,0,71,73,
3,16,8,0,72,71,1,0,0,0,73,76,1,0,0,0,74,72,1,0,0,0,74,75,1,0,0,0,75,77,1,
0,0,0,76,74,1,0,0,0,77,87,5,42,0,0,78,82,5,29,0,0,79,81,3,16,8,0,80,79,1,
0,0,0,81,84,1,0,0,0,82,80,1,0,0,0,82,83,1,0,0,0,83,85,1,0,0,0,84,82,1,0,
0,0,85,87,5,46,0,0,86,70,1,0,0,0,86,78,1,0,0,0,87,15,1,0,0,0,88,95,7,0,0,
0,89,95,7,1,0,0,90,91,7,2,0,0,91,92,3,18,9,0,92,93,5,30,0,0,93,95,1,0,0,
0,94,88,1,0,0,0,94,89,1,0,0,0,94,90,1,0,0,0,95,17,1,0,0,0,96,97,6,9,-1,0,
97,98,5,31,0,0,98,107,5,6,0,0,99,104,3,18,9,0,100,101,5,26,0,0,101,103,3,
18,9,0,102,100,1,0,0,0,103,106,1,0,0,0,104,102,1,0,0,0,104,105,1,0,0,0,105,
108,1,0,0,0,106,104,1,0,0,0,107,99,1,0,0,0,107,108,1,0,0,0,108,109,1,0,0,
0,109,124,5,7,0,0,110,124,5,31,0,0,111,124,5,32,0,0,112,124,5,33,0,0,113,
124,5,4,0,0,114,124,5,5,0,0,115,116,5,6,0,0,116,117,3,18,9,0,117,118,5,7,
0,0,118,124,1,0,0,0,119,120,5,18,0,0,120,124,3,18,9,6,121,122,5,19,0,0,122,
124,3,18,9,5,123,96,1,0,0,0,123,110,1,0,0,0,123,111,1,0,0,0,123,112,1,0,
0,0,123,113,1,0,0,0,123,114,1,0,0,0,123,115,1,0,0,0,123,119,1,0,0,0,123,
121,1,0,0,0,124,139,1,0,0,0,125,126,10,4,0,0,126,127,7,3,0,0,127,138,3,18,
9,5,128,129,10,3,0,0,129,130,7,4,0,0,130,138,3,18,9,4,131,132,10,2,0,0,132,
133,7,5,0,0,133,138,3,18,9,3,134,135,10,1,0,0,135,136,7,6,0,0,136,138,3,
18,9,2,137,125,1,0,0,0,137,128,1,0,0,0,137,131,1,0,0,0,137,134,1,0,0,0,138,
141,1,0,0,0,139,137,1,0,0,0,139,140,1,0,0,0,140,19,1,0,0,0,141,139,1,0,0,
0,12,33,52,64,74,82,86,94,104,107,123,137,139];


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

const sharedContextCache = new antlr4.PredictionContextCache();

export default class SequenceParser extends antlr4.Parser {

    static grammarFileName = "SequenceParser.g4";
    static literalNames = [ null, "'if'", "'else'", "'while'", "'true'",
                            "'false'", "'('", "')'", "'{{'", "'{'", "'}'",
                            "'&&'", "'||'", "'=='", "'!='", "'<='", "'>='",
                            "'='", "'!'", "'-'", "'+'", "'*'", "'/'", "'%'",
                            "'<'", "'>'", "','", null, null, null, "'}}'",
                            null, null, null, null, "'//'", "'/*'", "'\\n'",
                            null, "'*/'" ];
    static symbolicNames = [ null, "IF", "ELSE", "WHILE", "TRUE", "FALSE",
                             "L_PAREN", "R_PAREN", "LL_BRACE", "L_BRACE",
                             "R_BRACE", "AND", "OR", "EQ", "N_EQ", "LT_EQ",
                             "GT_EQ", "ASSIGN", "NOT", "MINUS", "PLUS",
                             "TIMES", "DIV", "MOD", "LT", "GT", "COMMA",
                             "GCODE_ESCAPE", "OPEN_GCODE_SQ", "OPEN_GCODE_DQ",
                             "EXIT_EXPR", "IDENTIFIER", "INT", "FLOAT",
                             "WS", "OPEN_LINE_COMMENT", "OPEN_BLOCK_COMMENT",
                             "CLOSE_LINE_COMMENT", "LINE_COMMENT_TEXT",
                             "CLOSE_BLOCK_COMMENT", "ESCAPED_CLOSE_BLOCK_COMMENT",
                             "BLOCK_COMMENT_TEXT", "CLOSE_GCODE_SQ", "ENTER_EXPR_SQ",
                             "ESCAPE_SEQUENCE_SQ", "TEXT_SQ", "CLOSE_GCODE_DQ",
                             "ENTER_EXPR_DQ", "ESCAPE_SEQUENCE_DQ", "TEXT_DQ" ];
    static ruleNames = [ "sequence", "statements", "ifBlock", "optionalElseBlock",
                         "whileBlock", "statement", "assignment", "gCode",
                         "gCodePart", "expression" ];

    constructor(input) {
        super(input);
        this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
        this.ruleNames = SequenceParser.ruleNames;
        this.literalNames = SequenceParser.literalNames;
        this.symbolicNames = SequenceParser.symbolicNames;
    }

    get atn() {
        return atn;
    }

    sempred(localctx, ruleIndex, predIndex) {
    	switch(ruleIndex) {
    	case 9:
    	    		return this.expression_sempred(localctx, predIndex);
        default:
            throw "No predicate with index:" + ruleIndex;
       }
    }

    expression_sempred(localctx, predIndex) {
    	switch(predIndex) {
    		case 0:
    			return this.precpred(this._ctx, 4);
    		case 1:
    			return this.precpred(this._ctx, 3);
    		case 2:
    			return this.precpred(this._ctx, 2);
    		case 3:
    			return this.precpred(this._ctx, 1);
    		default:
    			throw "No predicate with index:" + predIndex;
    	}
    };




	sequence() {
	    let localctx = new SequenceContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 0, SequenceParser.RULE_sequence);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 20;
	        this.statements();
	        this.state = 21;
	        this.match(SequenceParser.EOF);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	statements() {
	    let localctx = new StatementsContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 2, SequenceParser.RULE_statements);
	    try {
	        this.state = 33;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case SequenceParser.EOF:
	        case SequenceParser.R_BRACE:
	            this.enterOuterAlt(localctx, 1);

	            break;
	        case SequenceParser.IF:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 24;
	            this.ifBlock();
	            this.state = 25;
	            this.statements();
	            break;
	        case SequenceParser.WHILE:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 27;
	            this.whileBlock();
	            this.state = 28;
	            this.statements();
	            break;
	        case SequenceParser.OPEN_GCODE_SQ:
	        case SequenceParser.OPEN_GCODE_DQ:
	        case SequenceParser.IDENTIFIER:
	            this.enterOuterAlt(localctx, 4);
	            this.state = 30;
	            this.statement();
	            this.state = 31;
	            this.statements();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	ifBlock() {
	    let localctx = new IfBlockContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 4, SequenceParser.RULE_ifBlock);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 35;
	        this.match(SequenceParser.IF);
	        this.state = 36;
	        this.match(SequenceParser.L_PAREN);
	        this.state = 37;
	        this.expression(0);
	        this.state = 38;
	        this.match(SequenceParser.R_PAREN);
	        this.state = 39;
	        this.match(SequenceParser.L_BRACE);
	        this.state = 40;
	        this.statements();
	        this.state = 41;
	        this.match(SequenceParser.R_BRACE);
	        this.state = 42;
	        this.optionalElseBlock();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	optionalElseBlock() {
	    let localctx = new OptionalElseBlockContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 6, SequenceParser.RULE_optionalElseBlock);
	    try {
	        this.state = 52;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,1,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);

	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 45;
	            this.match(SequenceParser.ELSE);
	            this.state = 46;
	            this.ifBlock();
	            break;

	        case 3:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 47;
	            this.match(SequenceParser.ELSE);
	            this.state = 48;
	            this.match(SequenceParser.L_BRACE);
	            this.state = 49;
	            this.statements();
	            this.state = 50;
	            this.match(SequenceParser.R_BRACE);
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	whileBlock() {
	    let localctx = new WhileBlockContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 8, SequenceParser.RULE_whileBlock);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 54;
	        this.match(SequenceParser.WHILE);
	        this.state = 55;
	        this.match(SequenceParser.L_PAREN);
	        this.state = 56;
	        this.expression(0);
	        this.state = 57;
	        this.match(SequenceParser.R_PAREN);
	        this.state = 58;
	        this.match(SequenceParser.L_BRACE);
	        this.state = 59;
	        this.statements();
	        this.state = 60;
	        this.match(SequenceParser.R_BRACE);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	statement() {
	    let localctx = new StatementContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 10, SequenceParser.RULE_statement);
	    try {
	        this.state = 64;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case SequenceParser.IDENTIFIER:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 62;
	            this.assignment();
	            break;
	        case SequenceParser.OPEN_GCODE_SQ:
	        case SequenceParser.OPEN_GCODE_DQ:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 63;
	            this.gCode();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	assignment() {
	    let localctx = new AssignmentContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 12, SequenceParser.RULE_assignment);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 66;
	        localctx.ident = this.match(SequenceParser.IDENTIFIER);
	        this.state = 67;
	        this.match(SequenceParser.ASSIGN);
	        this.state = 68;
	        localctx.expr = this.expression(0);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	gCode() {
	    let localctx = new GCodeContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 14, SequenceParser.RULE_gCode);
	    var _la = 0; // Token type
	    try {
	        this.state = 86;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case SequenceParser.OPEN_GCODE_SQ:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 70;
	            this.match(SequenceParser.OPEN_GCODE_SQ);
	            this.state = 74;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(((((_la - 43)) & ~0x1f) == 0 && ((1 << (_la - 43)) & ((1 << (SequenceParser.ENTER_EXPR_SQ - 43)) | (1 << (SequenceParser.ESCAPE_SEQUENCE_SQ - 43)) | (1 << (SequenceParser.TEXT_SQ - 43)) | (1 << (SequenceParser.ENTER_EXPR_DQ - 43)) | (1 << (SequenceParser.ESCAPE_SEQUENCE_DQ - 43)) | (1 << (SequenceParser.TEXT_DQ - 43)))) !== 0)) {
	                this.state = 71;
	                this.gCodePart();
	                this.state = 76;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }
	            this.state = 77;
	            this.match(SequenceParser.CLOSE_GCODE_SQ);
	            break;
	        case SequenceParser.OPEN_GCODE_DQ:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 78;
	            this.match(SequenceParser.OPEN_GCODE_DQ);
	            this.state = 82;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(((((_la - 43)) & ~0x1f) == 0 && ((1 << (_la - 43)) & ((1 << (SequenceParser.ENTER_EXPR_SQ - 43)) | (1 << (SequenceParser.ESCAPE_SEQUENCE_SQ - 43)) | (1 << (SequenceParser.TEXT_SQ - 43)) | (1 << (SequenceParser.ENTER_EXPR_DQ - 43)) | (1 << (SequenceParser.ESCAPE_SEQUENCE_DQ - 43)) | (1 << (SequenceParser.TEXT_DQ - 43)))) !== 0)) {
	                this.state = 79;
	                this.gCodePart();
	                this.state = 84;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }
	            this.state = 85;
	            this.match(SequenceParser.CLOSE_GCODE_DQ);
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	gCodePart() {
	    let localctx = new GCodePartContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 16, SequenceParser.RULE_gCodePart);
	    var _la = 0; // Token type
	    try {
	        this.state = 94;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case SequenceParser.TEXT_SQ:
	        case SequenceParser.TEXT_DQ:
	            localctx = new GCodeTextContext(this, localctx);
	            this.enterOuterAlt(localctx, 1);
	            this.state = 88;
	            _la = this._input.LA(1);
	            if(!(_la===SequenceParser.TEXT_SQ || _la===SequenceParser.TEXT_DQ)) {
	            this._errHandler.recoverInline(this);
	            }
	            else {
	            	this._errHandler.reportMatch(this);
	                this.consume();
	            }
	            break;
	        case SequenceParser.ESCAPE_SEQUENCE_SQ:
	        case SequenceParser.ESCAPE_SEQUENCE_DQ:
	            localctx = new GCodeEscapedTextContext(this, localctx);
	            this.enterOuterAlt(localctx, 2);
	            this.state = 89;
	            _la = this._input.LA(1);
	            if(!(_la===SequenceParser.ESCAPE_SEQUENCE_SQ || _la===SequenceParser.ESCAPE_SEQUENCE_DQ)) {
	            this._errHandler.recoverInline(this);
	            }
	            else {
	            	this._errHandler.reportMatch(this);
	                this.consume();
	            }
	            break;
	        case SequenceParser.ENTER_EXPR_SQ:
	        case SequenceParser.ENTER_EXPR_DQ:
	            localctx = new GCodeSubExpressionContext(this, localctx);
	            this.enterOuterAlt(localctx, 3);
	            this.state = 90;
	            _la = this._input.LA(1);
	            if(!(_la===SequenceParser.ENTER_EXPR_SQ || _la===SequenceParser.ENTER_EXPR_DQ)) {
	            this._errHandler.recoverInline(this);
	            }
	            else {
	            	this._errHandler.reportMatch(this);
	                this.consume();
	            }
	            this.state = 91;
	            this.expression(0);
	            this.state = 92;
	            this.match(SequenceParser.EXIT_EXPR);
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}


	expression(_p) {
		if(_p===undefined) {
		    _p = 0;
		}
	    const _parentctx = this._ctx;
	    const _parentState = this.state;
	    let localctx = new ExpressionContext(this, this._ctx, _parentState);
	    let _prevctx = localctx;
	    const _startState = 18;
	    this.enterRecursionRule(localctx, 18, SequenceParser.RULE_expression, _p);
	    var _la = 0; // Token type
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 123;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,9,this._ctx);
	        switch(la_) {
	        case 1:
	            localctx = new FunctionCallContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;

	            this.state = 97;
	            this.match(SequenceParser.IDENTIFIER);
	            this.state = 98;
	            this.match(SequenceParser.L_PAREN);
	            this.state = 107;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(((((_la - 4)) & ~0x1f) == 0 && ((1 << (_la - 4)) & ((1 << (SequenceParser.TRUE - 4)) | (1 << (SequenceParser.FALSE - 4)) | (1 << (SequenceParser.L_PAREN - 4)) | (1 << (SequenceParser.NOT - 4)) | (1 << (SequenceParser.MINUS - 4)) | (1 << (SequenceParser.IDENTIFIER - 4)) | (1 << (SequenceParser.INT - 4)) | (1 << (SequenceParser.FLOAT - 4)))) !== 0)) {
	                this.state = 99;
	                this.expression(0);
	                this.state = 104;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	                while(_la===SequenceParser.COMMA) {
	                    this.state = 100;
	                    this.match(SequenceParser.COMMA);
	                    this.state = 101;
	                    this.expression(0);
	                    this.state = 106;
	                    this._errHandler.sync(this);
	                    _la = this._input.LA(1);
	                }
	            }

	            this.state = 109;
	            this.match(SequenceParser.R_PAREN);
	            break;

	        case 2:
	            localctx = new IdentExprContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 110;
	            this.match(SequenceParser.IDENTIFIER);
	            break;

	        case 3:
	            localctx = new IntExprContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 111;
	            this.match(SequenceParser.INT);
	            break;

	        case 4:
	            localctx = new FloatExprContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 112;
	            this.match(SequenceParser.FLOAT);
	            break;

	        case 5:
	            localctx = new BoolExprContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 113;
	            this.match(SequenceParser.TRUE);
	            break;

	        case 6:
	            localctx = new BoolExprContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 114;
	            this.match(SequenceParser.FALSE);
	            break;

	        case 7:
	            localctx = new ParenExprContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 115;
	            this.match(SequenceParser.L_PAREN);
	            this.state = 116;
	            this.expression(0);
	            this.state = 117;
	            this.match(SequenceParser.R_PAREN);
	            break;

	        case 8:
	            localctx = new UnaryOpExprContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 119;
	            this.match(SequenceParser.NOT);
	            this.state = 120;
	            this.expression(6);
	            break;

	        case 9:
	            localctx = new UnaryOpExprContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 121;
	            this.match(SequenceParser.MINUS);
	            this.state = 122;
	            this.expression(5);
	            break;

	        }
	        this._ctx.stop = this._input.LT(-1);
	        this.state = 139;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,11,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                if(this._parseListeners!==null) {
	                    this.triggerExitRuleEvent();
	                }
	                _prevctx = localctx;
	                this.state = 137;
	                this._errHandler.sync(this);
	                var la_ = this._interp.adaptivePredict(this._input,10,this._ctx);
	                switch(la_) {
	                case 1:
	                    localctx = new BinaryOpExprContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    this.pushNewRecursionContext(localctx, _startState, SequenceParser.RULE_expression);
	                    this.state = 125;
	                    if (!( this.precpred(this._ctx, 4))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 4)");
	                    }
	                    this.state = 126;
	                    _la = this._input.LA(1);
	                    if(!((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << SequenceParser.TIMES) | (1 << SequenceParser.DIV) | (1 << SequenceParser.MOD))) !== 0))) {
	                    this._errHandler.recoverInline(this);
	                    }
	                    else {
	                    	this._errHandler.reportMatch(this);
	                        this.consume();
	                    }
	                    this.state = 127;
	                    this.expression(5);
	                    break;

	                case 2:
	                    localctx = new BinaryOpExprContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    this.pushNewRecursionContext(localctx, _startState, SequenceParser.RULE_expression);
	                    this.state = 128;
	                    if (!( this.precpred(this._ctx, 3))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 3)");
	                    }
	                    this.state = 129;
	                    _la = this._input.LA(1);
	                    if(!(_la===SequenceParser.MINUS || _la===SequenceParser.PLUS)) {
	                    this._errHandler.recoverInline(this);
	                    }
	                    else {
	                    	this._errHandler.reportMatch(this);
	                        this.consume();
	                    }
	                    this.state = 130;
	                    this.expression(4);
	                    break;

	                case 3:
	                    localctx = new BinaryOpExprContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    this.pushNewRecursionContext(localctx, _startState, SequenceParser.RULE_expression);
	                    this.state = 131;
	                    if (!( this.precpred(this._ctx, 2))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 2)");
	                    }
	                    this.state = 132;
	                    _la = this._input.LA(1);
	                    if(!((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << SequenceParser.EQ) | (1 << SequenceParser.N_EQ) | (1 << SequenceParser.LT_EQ) | (1 << SequenceParser.GT_EQ) | (1 << SequenceParser.LT) | (1 << SequenceParser.GT))) !== 0))) {
	                    this._errHandler.recoverInline(this);
	                    }
	                    else {
	                    	this._errHandler.reportMatch(this);
	                        this.consume();
	                    }
	                    this.state = 133;
	                    this.expression(3);
	                    break;

	                case 4:
	                    localctx = new BinaryOpExprContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    this.pushNewRecursionContext(localctx, _startState, SequenceParser.RULE_expression);
	                    this.state = 134;
	                    if (!( this.precpred(this._ctx, 1))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 1)");
	                    }
	                    this.state = 135;
	                    _la = this._input.LA(1);
	                    if(!(_la===SequenceParser.AND || _la===SequenceParser.OR)) {
	                    this._errHandler.recoverInline(this);
	                    }
	                    else {
	                    	this._errHandler.reportMatch(this);
	                        this.consume();
	                    }
	                    this.state = 136;
	                    this.expression(2);
	                    break;

	                }
	            }
	            this.state = 141;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,11,this._ctx);
	        }

	    } catch( error) {
	        if(error instanceof antlr4.error.RecognitionException) {
		        localctx.exception = error;
		        this._errHandler.reportError(this, error);
		        this._errHandler.recover(this, error);
		    } else {
		    	throw error;
		    }
	    } finally {
	        this.unrollRecursionContexts(_parentctx)
	    }
	    return localctx;
	}


}

SequenceParser.EOF = antlr4.Token.EOF;
SequenceParser.IF = 1;
SequenceParser.ELSE = 2;
SequenceParser.WHILE = 3;
SequenceParser.TRUE = 4;
SequenceParser.FALSE = 5;
SequenceParser.L_PAREN = 6;
SequenceParser.R_PAREN = 7;
SequenceParser.LL_BRACE = 8;
SequenceParser.L_BRACE = 9;
SequenceParser.R_BRACE = 10;
SequenceParser.AND = 11;
SequenceParser.OR = 12;
SequenceParser.EQ = 13;
SequenceParser.N_EQ = 14;
SequenceParser.LT_EQ = 15;
SequenceParser.GT_EQ = 16;
SequenceParser.ASSIGN = 17;
SequenceParser.NOT = 18;
SequenceParser.MINUS = 19;
SequenceParser.PLUS = 20;
SequenceParser.TIMES = 21;
SequenceParser.DIV = 22;
SequenceParser.MOD = 23;
SequenceParser.LT = 24;
SequenceParser.GT = 25;
SequenceParser.COMMA = 26;
SequenceParser.GCODE_ESCAPE = 27;
SequenceParser.OPEN_GCODE_SQ = 28;
SequenceParser.OPEN_GCODE_DQ = 29;
SequenceParser.EXIT_EXPR = 30;
SequenceParser.IDENTIFIER = 31;
SequenceParser.INT = 32;
SequenceParser.FLOAT = 33;
SequenceParser.WS = 34;
SequenceParser.OPEN_LINE_COMMENT = 35;
SequenceParser.OPEN_BLOCK_COMMENT = 36;
SequenceParser.CLOSE_LINE_COMMENT = 37;
SequenceParser.LINE_COMMENT_TEXT = 38;
SequenceParser.CLOSE_BLOCK_COMMENT = 39;
SequenceParser.ESCAPED_CLOSE_BLOCK_COMMENT = 40;
SequenceParser.BLOCK_COMMENT_TEXT = 41;
SequenceParser.CLOSE_GCODE_SQ = 42;
SequenceParser.ENTER_EXPR_SQ = 43;
SequenceParser.ESCAPE_SEQUENCE_SQ = 44;
SequenceParser.TEXT_SQ = 45;
SequenceParser.CLOSE_GCODE_DQ = 46;
SequenceParser.ENTER_EXPR_DQ = 47;
SequenceParser.ESCAPE_SEQUENCE_DQ = 48;
SequenceParser.TEXT_DQ = 49;

SequenceParser.RULE_sequence = 0;
SequenceParser.RULE_statements = 1;
SequenceParser.RULE_ifBlock = 2;
SequenceParser.RULE_optionalElseBlock = 3;
SequenceParser.RULE_whileBlock = 4;
SequenceParser.RULE_statement = 5;
SequenceParser.RULE_assignment = 6;
SequenceParser.RULE_gCode = 7;
SequenceParser.RULE_gCodePart = 8;
SequenceParser.RULE_expression = 9;

class SequenceContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = SequenceParser.RULE_sequence;
    }

	statements() {
	    return this.getTypedRuleContext(StatementsContext,0);
	};

	EOF() {
	    return this.getToken(SequenceParser.EOF, 0);
	};

	enterRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.enterSequence(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.exitSequence(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof SequenceParserVisitor ) {
	        return visitor.visitSequence(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class StatementsContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = SequenceParser.RULE_statements;
    }

	ifBlock() {
	    return this.getTypedRuleContext(IfBlockContext,0);
	};

	statements() {
	    return this.getTypedRuleContext(StatementsContext,0);
	};

	whileBlock() {
	    return this.getTypedRuleContext(WhileBlockContext,0);
	};

	statement() {
	    return this.getTypedRuleContext(StatementContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.enterStatements(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.exitStatements(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof SequenceParserVisitor ) {
	        return visitor.visitStatements(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class IfBlockContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = SequenceParser.RULE_ifBlock;
    }

	IF() {
	    return this.getToken(SequenceParser.IF, 0);
	};

	L_PAREN() {
	    return this.getToken(SequenceParser.L_PAREN, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	R_PAREN() {
	    return this.getToken(SequenceParser.R_PAREN, 0);
	};

	L_BRACE() {
	    return this.getToken(SequenceParser.L_BRACE, 0);
	};

	statements() {
	    return this.getTypedRuleContext(StatementsContext,0);
	};

	R_BRACE() {
	    return this.getToken(SequenceParser.R_BRACE, 0);
	};

	optionalElseBlock() {
	    return this.getTypedRuleContext(OptionalElseBlockContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.enterIfBlock(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.exitIfBlock(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof SequenceParserVisitor ) {
	        return visitor.visitIfBlock(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class OptionalElseBlockContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = SequenceParser.RULE_optionalElseBlock;
    }

	ELSE() {
	    return this.getToken(SequenceParser.ELSE, 0);
	};

	ifBlock() {
	    return this.getTypedRuleContext(IfBlockContext,0);
	};

	L_BRACE() {
	    return this.getToken(SequenceParser.L_BRACE, 0);
	};

	statements() {
	    return this.getTypedRuleContext(StatementsContext,0);
	};

	R_BRACE() {
	    return this.getToken(SequenceParser.R_BRACE, 0);
	};

	enterRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.enterOptionalElseBlock(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.exitOptionalElseBlock(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof SequenceParserVisitor ) {
	        return visitor.visitOptionalElseBlock(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class WhileBlockContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = SequenceParser.RULE_whileBlock;
    }

	WHILE() {
	    return this.getToken(SequenceParser.WHILE, 0);
	};

	L_PAREN() {
	    return this.getToken(SequenceParser.L_PAREN, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	R_PAREN() {
	    return this.getToken(SequenceParser.R_PAREN, 0);
	};

	L_BRACE() {
	    return this.getToken(SequenceParser.L_BRACE, 0);
	};

	statements() {
	    return this.getTypedRuleContext(StatementsContext,0);
	};

	R_BRACE() {
	    return this.getToken(SequenceParser.R_BRACE, 0);
	};

	enterRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.enterWhileBlock(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.exitWhileBlock(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof SequenceParserVisitor ) {
	        return visitor.visitWhileBlock(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class StatementContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = SequenceParser.RULE_statement;
    }

	assignment() {
	    return this.getTypedRuleContext(AssignmentContext,0);
	};

	gCode() {
	    return this.getTypedRuleContext(GCodeContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.enterStatement(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.exitStatement(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof SequenceParserVisitor ) {
	        return visitor.visitStatement(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class AssignmentContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = SequenceParser.RULE_assignment;
        this.ident = null; // Token
        this.expr = null; // ExpressionContext
    }

	ASSIGN() {
	    return this.getToken(SequenceParser.ASSIGN, 0);
	};

	IDENTIFIER() {
	    return this.getToken(SequenceParser.IDENTIFIER, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.enterAssignment(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.exitAssignment(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof SequenceParserVisitor ) {
	        return visitor.visitAssignment(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class GCodeContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = SequenceParser.RULE_gCode;
    }

	OPEN_GCODE_SQ() {
	    return this.getToken(SequenceParser.OPEN_GCODE_SQ, 0);
	};

	CLOSE_GCODE_SQ() {
	    return this.getToken(SequenceParser.CLOSE_GCODE_SQ, 0);
	};

	gCodePart = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(GCodePartContext);
	    } else {
	        return this.getTypedRuleContext(GCodePartContext,i);
	    }
	};

	OPEN_GCODE_DQ() {
	    return this.getToken(SequenceParser.OPEN_GCODE_DQ, 0);
	};

	CLOSE_GCODE_DQ() {
	    return this.getToken(SequenceParser.CLOSE_GCODE_DQ, 0);
	};

	enterRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.enterGCode(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.exitGCode(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof SequenceParserVisitor ) {
	        return visitor.visitGCode(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class GCodePartContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = SequenceParser.RULE_gCodePart;
    }



		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class GCodeSubExpressionContext extends GCodePartContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	EXIT_EXPR() {
	    return this.getToken(SequenceParser.EXIT_EXPR, 0);
	};

	ENTER_EXPR_SQ() {
	    return this.getToken(SequenceParser.ENTER_EXPR_SQ, 0);
	};

	ENTER_EXPR_DQ() {
	    return this.getToken(SequenceParser.ENTER_EXPR_DQ, 0);
	};

	enterRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.enterGCodeSubExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.exitGCodeSubExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof SequenceParserVisitor ) {
	        return visitor.visitGCodeSubExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

SequenceParser.GCodeSubExpressionContext = GCodeSubExpressionContext;

class GCodeTextContext extends GCodePartContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	TEXT_SQ() {
	    return this.getToken(SequenceParser.TEXT_SQ, 0);
	};

	TEXT_DQ() {
	    return this.getToken(SequenceParser.TEXT_DQ, 0);
	};

	enterRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.enterGCodeText(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.exitGCodeText(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof SequenceParserVisitor ) {
	        return visitor.visitGCodeText(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

SequenceParser.GCodeTextContext = GCodeTextContext;

class GCodeEscapedTextContext extends GCodePartContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	ESCAPE_SEQUENCE_SQ() {
	    return this.getToken(SequenceParser.ESCAPE_SEQUENCE_SQ, 0);
	};

	ESCAPE_SEQUENCE_DQ() {
	    return this.getToken(SequenceParser.ESCAPE_SEQUENCE_DQ, 0);
	};

	enterRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.enterGCodeEscapedText(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.exitGCodeEscapedText(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof SequenceParserVisitor ) {
	        return visitor.visitGCodeEscapedText(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

SequenceParser.GCodeEscapedTextContext = GCodeEscapedTextContext;

class ExpressionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = SequenceParser.RULE_expression;
    }



		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class IdentExprContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	IDENTIFIER() {
	    return this.getToken(SequenceParser.IDENTIFIER, 0);
	};

	enterRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.enterIdentExpr(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.exitIdentExpr(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof SequenceParserVisitor ) {
	        return visitor.visitIdentExpr(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

SequenceParser.IdentExprContext = IdentExprContext;

class FloatExprContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	FLOAT() {
	    return this.getToken(SequenceParser.FLOAT, 0);
	};

	enterRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.enterFloatExpr(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.exitFloatExpr(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof SequenceParserVisitor ) {
	        return visitor.visitFloatExpr(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

SequenceParser.FloatExprContext = FloatExprContext;

class UnaryOpExprContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	NOT() {
	    return this.getToken(SequenceParser.NOT, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	MINUS() {
	    return this.getToken(SequenceParser.MINUS, 0);
	};

	enterRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.enterUnaryOpExpr(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.exitUnaryOpExpr(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof SequenceParserVisitor ) {
	        return visitor.visitUnaryOpExpr(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

SequenceParser.UnaryOpExprContext = UnaryOpExprContext;

class IntExprContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	INT() {
	    return this.getToken(SequenceParser.INT, 0);
	};

	enterRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.enterIntExpr(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.exitIntExpr(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof SequenceParserVisitor ) {
	        return visitor.visitIntExpr(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

SequenceParser.IntExprContext = IntExprContext;

class FunctionCallContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	IDENTIFIER() {
	    return this.getToken(SequenceParser.IDENTIFIER, 0);
	};

	L_PAREN() {
	    return this.getToken(SequenceParser.L_PAREN, 0);
	};

	R_PAREN() {
	    return this.getToken(SequenceParser.R_PAREN, 0);
	};

	expression = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ExpressionContext);
	    } else {
	        return this.getTypedRuleContext(ExpressionContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(SequenceParser.COMMA);
	    } else {
	        return this.getToken(SequenceParser.COMMA, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.enterFunctionCall(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.exitFunctionCall(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof SequenceParserVisitor ) {
	        return visitor.visitFunctionCall(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

SequenceParser.FunctionCallContext = FunctionCallContext;

class BinaryOpExprContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	expression = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ExpressionContext);
	    } else {
	        return this.getTypedRuleContext(ExpressionContext,i);
	    }
	};

	TIMES() {
	    return this.getToken(SequenceParser.TIMES, 0);
	};

	DIV() {
	    return this.getToken(SequenceParser.DIV, 0);
	};

	MOD() {
	    return this.getToken(SequenceParser.MOD, 0);
	};

	PLUS() {
	    return this.getToken(SequenceParser.PLUS, 0);
	};

	MINUS() {
	    return this.getToken(SequenceParser.MINUS, 0);
	};

	EQ() {
	    return this.getToken(SequenceParser.EQ, 0);
	};

	N_EQ() {
	    return this.getToken(SequenceParser.N_EQ, 0);
	};

	LT() {
	    return this.getToken(SequenceParser.LT, 0);
	};

	LT_EQ() {
	    return this.getToken(SequenceParser.LT_EQ, 0);
	};

	GT() {
	    return this.getToken(SequenceParser.GT, 0);
	};

	GT_EQ() {
	    return this.getToken(SequenceParser.GT_EQ, 0);
	};

	OR() {
	    return this.getToken(SequenceParser.OR, 0);
	};

	AND() {
	    return this.getToken(SequenceParser.AND, 0);
	};

	enterRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.enterBinaryOpExpr(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.exitBinaryOpExpr(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof SequenceParserVisitor ) {
	        return visitor.visitBinaryOpExpr(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

SequenceParser.BinaryOpExprContext = BinaryOpExprContext;

class BoolExprContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	TRUE() {
	    return this.getToken(SequenceParser.TRUE, 0);
	};

	FALSE() {
	    return this.getToken(SequenceParser.FALSE, 0);
	};

	enterRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.enterBoolExpr(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.exitBoolExpr(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof SequenceParserVisitor ) {
	        return visitor.visitBoolExpr(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

SequenceParser.BoolExprContext = BoolExprContext;

class ParenExprContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	L_PAREN() {
	    return this.getToken(SequenceParser.L_PAREN, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	R_PAREN() {
	    return this.getToken(SequenceParser.R_PAREN, 0);
	};

	enterRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.enterParenExpr(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof SequenceParserListener ) {
	        listener.exitParenExpr(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof SequenceParserVisitor ) {
	        return visitor.visitParenExpr(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

SequenceParser.ParenExprContext = ParenExprContext;


SequenceParser.SequenceContext = SequenceContext;
SequenceParser.StatementsContext = StatementsContext;
SequenceParser.IfBlockContext = IfBlockContext;
SequenceParser.OptionalElseBlockContext = OptionalElseBlockContext;
SequenceParser.WhileBlockContext = WhileBlockContext;
SequenceParser.StatementContext = StatementContext;
SequenceParser.AssignmentContext = AssignmentContext;
SequenceParser.GCodeContext = GCodeContext;
SequenceParser.GCodePartContext = GCodePartContext;
SequenceParser.ExpressionContext = ExpressionContext;

import { connect } from 'react-redux';

import SupportView from './supportView.jsx';
import actions from '../../../reducers/slicer/actions';
import THREEactions from '../../../reducers/three/actions';

const mapStateToProps = (state) => ({
  scene: state.three.scene,
  brush: state.slicer.brush,
  models: state.slicer.models,
  transitionTower: state.slicer.transitionTower,
  isPainting: state.slicer.status.isPainting,
  saveModelCustomSupportsPending:
    state.slicer.status.saveModelCustomSupportsPending,
  saveModelCustomSupportsSuccess:
    state.slicer.status.saveModelCustomSupportsSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  addBrush: (brush, dot) => dispatch(THREEactions.addBrush(brush, dot)),
  removeBrush: (brush, dot) => dispatch(THREEactions.removeBrush(brush, dot)),
  addTowerToScene: (tower) => dispatch(THREEactions.addTowerToScene(tower)),
  enableControls: () => dispatch(THREEactions.enableControls()),
  disableControls: () => dispatch(THREEactions.disableControls()),
  registerBrush: (brush) => dispatch(actions.registerBrush(brush)),
  togglePainting: (onOrOff) => dispatch(actions.togglePainting(onOrOff)),
  saveModelCustomSupports: (supportsDataPerModel) =>
    dispatch(actions.saveModelCustomSupportsRequest(supportsDataPerModel)),
  selectBrushColor: (color) => dispatch(actions.selectBrushColor(color)),
  selectBrushType: (type) => dispatch(actions.selectBrushType(type)),
  selectBrushSize: (size) => dispatch(actions.selectBrushSize(size)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportView);

const types = {
  BED_MESH_NAME: 'THREE/BED_MESH_NAME',
  BED_GRID_NAME: 'THREE/BED_GRID_NAME',
  BED_BOX_NAME: 'THREE/BED_BOX_NAME',
  TOWER_MESH_NAME: 'THREE/TOWER_MESH_NAME',
  AXES_OBJ_NAME: 'THREE/AXES_OBJ_NAME',

  TOOLPATH_BY_TOOL: 'THREE/TOOLPATH_BY_TOOL',
  TOOLPATH_BY_SPEED: 'THREE/TOOLPATH_BY_SPEED',
  TOOLPATH_BY_PATH_TYPE: 'THREE/TOOLPATH_BY_PATH_TYPE',
  TOOLPATH_BY_FAN: 'THREE/TOOLPATH_BY_FAN',
  TOOLPATH_BY_TEMPERATURE: 'THREE/TOOLPATH_BY_TEMPERATURE',
  TOOLPATH_BY_LAYER_HEIGHT: 'THREE/TOOLPATH_BY_LAYER_HEIGHT',

  INIT_SCENE_REQUEST: 'THREE/INIT_SCENE_REQUEST',
  INIT_SCENE_SUCCESS: 'THREE/INIT_SCENE_SUCCESS',
  INIT_SCENE_FAILURE: 'THREE/INIT_SCENE_FAILURE',
  DESTROY_SCENE_REQUEST: 'THREE/DESTROY_SCENE_REQUEST',
  DESTROY_SCENE_SUCCESS: 'THREE/DESTROY_SCENE_SUCCESS',
  DESTROY_SCENE_FAILURE: 'THREE/DESTROY_SCENE_FAILURE',

  ENABLE_CONTROLS: 'THREE/ENABLE_CONTROLS',
  DISABLE_CONTROLS: 'THREE/DISABLE_CONTROLS',

  UPDATE_PRINT_BED_REQUEST: 'THREE/UPDATE_PRINT_BED_REQUEST',
  UPDATE_PRINT_BED_SUCCESS: 'THREE/UPDATE_PRINT_BED_SUCCESS',
  UPDATE_PRINT_BED_FAILURE: 'THREE/UPDATE_PRINT_BED_FAILURE',
  SHOW_PRINT_BED: 'THREE/SHOW_PRINT_BED',
  HIDE_PRINT_BED: 'THREE/HIDE_PRINT_BED',

  RESET_CAMERA: 'THREE/RESET_CAMERA',
  TWEEN_CAMERA: 'THREE/TWEEN_CAMERA',

  REPLACE_CAMERA_REQUEST: 'THREE/REPLACE_CAMERA_REQUEST',
  REPLACE_CAMERA_SUCCESS: 'THREE/REPLACE_CAMERA_SUCCESS',
  REPLACE_CAMERA_FAILURE: 'THREE/REPLACE_CAMERA_FAILURE',

  ADD_MODELS_TO_SCENE: 'THREE/ADD_MODELS_TO_SCENE',
  REMOVE_MODELS_FROM_SCENE: 'THREE/REMOVE_MODELS_FROM_SCENE',
  ADD_MODEL_TO_SCENE: 'THREE/ADD_MODEL_TO_SCENE',
  REMOVE_MODEL_FROM_SCENE: 'THREE/REMOVE_MODEL_FROM_SCENE',
  ADD_TOWER_TO_SCENE: 'THREE/ADD_TOWER_TO_SCENE',
  REMOVE_TOWER_FROM_SCENE: 'THREE/REMOVE_TOWER_FROM_SCENE',

  HIGHLIGHT_MODELS: 'THREE/HIGHLIGHT_MODELS',
  REMOVE_MODEL_HIGHLIGHTS: 'THREE/REMOVE_MODEL_HIGHLIGHTS',
  HOVER_MODELS: 'THREE/HOVER_MODELS',

  ATTACH_GIZMO_REQUEST: 'THREE/ATTACH_GIZMO_REQUEST',
  ATTACH_GIZMO_SUCCESS: 'THREE/ATTACH_GIZMO_SUCCESS',
  ATTACH_GIZMO_FAILURE: 'THREE/ATTACH_GIZMO_FAILURE',
  UPDATE_GIZMO_REQUEST: 'THREE/UPDATE_GIZMO_REQUEST',
  UPDATE_GIZMO_SUCCESS: 'THREE/UPDATE_GIZMO_SUCCESS',
  UPDATE_GIZMO_FAILURE: 'THREE/UPDATE_GIZMO_FAILURE',
  DETACH_GIZMO_REQUEST: 'THREE/DETACH_GIZMO_REQUEST',
  DETACH_GIZMO_SUCCESS: 'THREE/DETACH_GIZMO_SUCCESS',
  DETACH_GIZMO_FAILURE: 'THREE/DETACH_GIZMO_FAILURE',

  ADD_TOOLPATH: 'THREE/ADD_TOOLPATH',
  UPDATE_TOOLPATH: 'THREE/UPDATE_TOOLPATH',
  REMOVE_TOOLPATH: 'THREE/REMOVE_TOOLPATH',

  ADD_BRUSH: 'THREE/ADD_BRUSH',
  REMOVE_BRUSH: 'THREE/REMOVE_BRUSH',
  ADD_BOUNDARIES_REQUEST: 'THREE/ADD_BOUNDARIES_REQUEST',
  ADD_BOUNDARIES_SUCCESS: 'THREE/ADD_BOUNDARIES_SUCCESS',
  ADD_BOUNDARIES_FAILURE: 'THREE/ADD_BOUNDARIES_FAILURE',
  REMOVE_BOUNDARIES_REQUEST: 'THREE/REMOVE_BOUNDARIES_REQUEST',
  REMOVE_BOUNDARIES_SUCCESS: 'THREE/REMOVE_BOUNDARIES_SUCCESS',
  REMOVE_BOUNDARIES_FAILURE: 'THREE/REMOVE_BOUNDARIES_FAILURE',

  TOGGLE_MOVING_SWATCH: 'THREE/TOGGLE_MOVING_SWATCH',
};

export default types;

import styled from 'styled-components';
import { StyledContainerBase } from '../../../../../shared/abstractInput/abstractInput.styles';

export const Container = styled.div`
  height: 16rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
`;

export const TerminalLogs = styled.div`
  flex: 1;
  background: ${(props) => props.theme.colors.elevatedBackgroundSecondary};
  border-radius: 0.25rem;
  padding: 0.5rem;
  overflow-y: scroll;
`;

export const LogItem = styled.span`
  display: block;
  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.textPrimary};
`;

export const TerminalControls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

export const TerminalButtons = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 0.5rem;
`;

export const TerminalInputContainer = styled(StyledContainerBase)`
  width: 100%;
  min-height: 2.5rem;
  padding: 0;
  margin: 0;
`;

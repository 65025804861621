import React from 'react';

const bowdenTubeLength = (
  <>
    The last piece of filament that Palette creates will be extended by this
    amount. This ensures that the filament is still gripped by the printer’s
    drive gear at the end of the print. For printers with Bowden tubes or long
    extruders, this should be the distance from your printer’s drive gear to the
    nozzle tip. If you are unsure of the exact measurement, overestimating is
    better.
  </>
);

const originOffset = (
  <>
    Specify the location of your printer’s origin (0,0). Measured in mm from the
    bottom-left corner.
  </>
);

const extension = (
  <>Select the file extension that your printer uses for print files.</>
);

const addComments = (
  <>
    Comments provide useful diagnostic information and markers for
    postprocessing scripts, but increase file size and can slow down parsing on
    some printer hardware/firmware. Note that disabling this setting will not
    remove comments from your custom sequences.
  </>
);

const gpxProfile = (
  <>Choose the profile that will be used for X3G generation.</>
);

const aStepsPerMM = (
  <>
    Input the steps/mm for extruder A. This is used during X3G generation.
    Setting this to 0 will use the default specified by the GPX profile.
  </>
);

const bStepsPerMM = (
  <>
    Input the steps/mm for extruder B. This is used during X3G generation.
    Setting this to 0 will use the default specified by the GPX profile.
  </>
);

const firmwarePurge = (
  <>
    Some printers have start sequences that are performed independent of G-code
    supplied. If these sequences include any extrusion, total that amount and
    input it here. This setting will account for this filament usage in
    Palette’s color calibration.
  </>
);

const firmwareRetraction = (
  <>
    Some printers allow retraction to be configured via the firmware and
    initiated by a special G-code command. If you would like to use G10/G11
    commands for retraction instead of G1 commands, enable this setting.
  </>
);

const jogPauses = (
  <>
    In accessory mode, Palette calibrates with the printer by pausing
    occasionally on the transition tower. However, some printers are unable to
    pause for the required length of time. Enabling movement pauses will move
    the extruder back and forth instead of pausing.
  </>
);

const clearBufferCommand = (
  <>
    During connected mode pings, Palette uses this G-code command to accurately
    synchronize itself with the printer without pausing the print. Most firmware
    supports G4 P0, but certain firmwares require M400 to be used instead in
    order to avoid a pause.
  </>
);

const chromaExtruder = (
  <>
    Select the extruder that Palette is connected to. Currently, the other
    extruder(s) cannot be used together while a Palette is being used.
  </>
);

const pingOffTower = (
  <>
    When enabled, the printer will move away from the tower during ping pauses.
    This setting only affects behavior in accessory mode. Consider enabling this
    setting if your printer oozes significantly during ping pauses.
  </>
);

const sideTransitionPurgeInPlace = (
  <>
    When using side transitions, your printer can either be set to extrude along
    an edge of the bed or in one specific place. For printers that extrude too
    quickly while not moving, select ‘Along Edge’.
  </>
);

const sideTransitionEdge = (
  <>
    Select the edge along which the side transitions will take place. North
    represents the back of the printer.
  </>
);

const sideTransitionEdgeOffset = (
  <>
    This value specifies how far away from the edge of the printer’s bed that
    the side transitions will occur. The edge coordinates are defined by your
    bed size and origin offset.
  </>
);

export default {
  bowdenTubeLength,
  originOffset,
  extension,
  addComments,
  gpxProfile,
  aStepsPerMM,
  bStepsPerMM,
  firmwarePurge,
  firmwareRetraction,
  jogPauses,
  clearBufferCommand,
  chromaExtruder,
  pingOffTower,
  sideTransitionPurgeInPlace,
  sideTransitionEdge,
  sideTransitionEdgeOffset,
};

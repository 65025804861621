import types from './types';

const actions = {
  updateNavStack: (navStack) => ({
    type: types.UPDATE_NAV_STACK,
    payload: { navStack },
  }),
};

export default actions;

import styled, { css } from 'styled-components';

export const StyledSearchBar = styled.div`
  width: 100%;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 0.25rem;
  transition: border-color 0.2s, background 0.2s;
  background: ${(props) => props.theme.colors.elevatedBackgroundPrimary};

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      background: ${props.theme.colors.elevatedBackgroundTertiary};
    `};
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 2.5rem;
  border: none;
  background: transparent;
  outline: none;
  font-family: inherit;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${(props) => props.theme.colors.textPrimary};

  ${(props) =>
    props.disabled &&
    css`
      ::placeholder {
        color: ${props.theme.colors.textTertiary};
      }
    `}
`;

export const IconWrapper = styled.div`
  margin-left: 0.625rem;
  cursor: pointer;
`;

import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  OptionsContainer,
  ToolpathOptionCheckboxWrapper,
  ToolpathPlaceholderWrapper,
  PlaceholderContainer,
  BabySpinnerWrapper,
  ContentWrapper,
  DisplayWrapper,
  DisplayWrapperMobile,
} from './toolpathOptions.styles';

import {
  Dropdown,
  Checkbox,
  Progress,
  ToolCollapsiblePanel,
} from '../../../../shared';
import THREEtypes from '../../../../reducers/three/types';
import { Body1 } from '../../../../shared/typography/typography';
import { ProjectUtils } from '../../../../utils';

const ToolpathOptions = (props) => {
  const { projectId } = useParams();
  const { dispatchSliceJobPending } = useSelector(
    (state) => state.slicer.status
  );
  const { sliceJobs } = useSelector((state) => state.slicer);

  const isProjectSlicing =
    dispatchSliceJobPending ||
    ProjectUtils.isProjectSlicing(sliceJobs, projectId);

  const onToolpathTypeChange = (viewType) => {
    props.setToolpathViewType(viewType);
  };

  const onToolpathTravelToggle = (e) => {
    const { showTravel } = props.toolpath;
    e.target.blur();
    props.setToolpathShowTravel(!showTravel);
  };

  const onToolpathRetractToggle = (e) => {
    const { showRetracts } = props.toolpath;
    e.target.blur();
    props.setToolpathShowRetracts(!showRetracts);
  };

  const onToolpathRestartToggle = (e) => {
    const { showRestarts } = props.toolpath;
    e.target.blur();
    props.setToolpathShowRestarts(!showRestarts);
  };

  const renderLoadingSpinner = () => {
    return (
      <>
        <BabySpinnerWrapper>
          <Progress circular />
        </BabySpinnerWrapper>
        <ToolpathPlaceholderWrapper>
          <Body1>Loading print preview...</Body1>
        </ToolpathPlaceholderWrapper>
      </>
    );
  };

  const renderPlaceholder = () => {
    return (
      <DisplayWrapper>
        <ToolCollapsiblePanel isCollapsed={false} label='Print Preview'>
          {renderLoadingSpinner()}
        </ToolCollapsiblePanel>
      </DisplayWrapper>
    );
  };

  const renderPlaceholderMobile = () => {
    return (
      <DisplayWrapperMobile>{renderLoadingSpinner()}</DisplayWrapperMobile>
    );
  };

  const renderToolpathPlaceholder = () => {
    return (
      <OptionsContainer>
        {renderPlaceholder()}
        {renderPlaceholderMobile()}
      </OptionsContainer>
    );
  };

  const { toolpath, loading, isVersionValid } = props;

  const viewTypes = [
    { value: THREEtypes.TOOLPATH_BY_TOOL, label: 'Tool index' },
    { value: THREEtypes.TOOLPATH_BY_PATH_TYPE, label: 'Path type' },
    { value: THREEtypes.TOOLPATH_BY_LAYER_HEIGHT, label: 'Layer height' },
    { value: THREEtypes.TOOLPATH_BY_SPEED, label: 'Print speed' },
    { value: THREEtypes.TOOLPATH_BY_FAN, label: 'Fan speed' },
    { value: THREEtypes.TOOLPATH_BY_TEMPERATURE, label: 'Print temperature' },
  ];

  const renderOptions = () => (
    <ContentWrapper>
      <Dropdown
        minHeight='0'
        options={viewTypes}
        value={toolpath.viewType}
        onChange={(value) => onToolpathTypeChange(value)}
      />
      <ToolpathOptionCheckboxWrapper>
        <Checkbox
          label='Show travel movement'
          value={props.toolpath.showTravel}
          onChange={(e) => onToolpathTravelToggle(e)}
        />
      </ToolpathOptionCheckboxWrapper>
      <ToolpathOptionCheckboxWrapper>
        <Checkbox
          label='Show retract points'
          value={toolpath.showRetracts}
          onChange={(e) => onToolpathRetractToggle(e)}
        />
      </ToolpathOptionCheckboxWrapper>
      <ToolpathOptionCheckboxWrapper>
        <Checkbox
          label='Show restart points'
          value={toolpath.showRestarts}
          onChange={(e) => onToolpathRestartToggle(e)}
        />
      </ToolpathOptionCheckboxWrapper>
    </ContentWrapper>
  );

  const renderProjectIsSlicing = () => (
    <PlaceholderContainer>
      <Body1 grey>Finish slicing to load print preview</Body1>
    </PlaceholderContainer>
  );

  const renderPrinterPreviewUnableToLoad = () => (
    <PlaceholderContainer>
      <Body1 grey>Finish slicing to load print preview</Body1>
    </PlaceholderContainer>
  );

  const renderToolPathUnavailable = () => (
    <PlaceholderContainer>
      <Body1 grey>
        Print previews have been updated. Re-slice this project to preview it.
      </Body1>
    </PlaceholderContainer>
  );

  const renderContent = () => {
    if (isProjectSlicing) return renderProjectIsSlicing();

    if (!toolpath.extrusions) return renderPrinterPreviewUnableToLoad();

    if (!isVersionValid) return renderToolPathUnavailable();

    return renderOptions();
  };

  const renderViewSettings = () => (
    <DisplayWrapper>
      <ToolCollapsiblePanel isCollapsed={false} label='Print Preview'>
        {renderContent()}
      </ToolCollapsiblePanel>
    </DisplayWrapper>
  );

  const renderViewSettingsMobile = () => (
    <DisplayWrapperMobile>{renderContent()}</DisplayWrapperMobile>
  );

  if (loading) return renderToolpathPlaceholder();
  return (
    <OptionsContainer>
      {renderViewSettings()}
      {renderViewSettingsMobile()}
    </OptionsContainer>
  );
};

export default ToolpathOptions;

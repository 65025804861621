import styled, { css } from 'styled-components';
import { Body1 } from '../typography/typography';

export const ToastBody1 = styled(Body1)`
  ${(props) => props.type === 'error' && `color:white;`}
  margin-bottom: 0;
`;

export const UL = styled.ul`
  margin: 0.25rem;
  padding-left: 1.5rem;
  list-style-type: disc;
  color: ${(props) => props.theme.colors.textPrimary};
`;

export const ToastMessageContainer = styled.div`
  font-family: ${(props) => props.theme.fonts.familyBody};
  display: grid;
  grid-template-columns: ${(props) => props.type !== '' && 'min-content'} 1fr auto;
  ${(props) =>
    props.type === 'error' &&
    `background-color: ${props.theme.colors.redDark};`}
  ${(props) => props.type === 'error' && `color: white;`}
`;

export const IconWrapper = styled.div`
  height: 100%;
  grid-column: 1 / 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
`;

export const ToastTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.6875rem 1rem;
  ${(props) =>
    !props.buttonName &&
    css`
      margin-right: 1.25rem;
    `}
`;

export const ToastButtonWrapper = styled.div`
  height: 100%;
  border-left: 1px solid
    ${(props) =>
      props.type === 'error'
        ? props.theme.colors.whiteDefault
        : props.theme.colors.grey3};

  &:hover {
    background-color: ${(props) =>
      props.theme.colors.elevatedBackgroundSecondary};
  }
`;

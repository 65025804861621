import React from 'react';
import Metadata from './layerHeight.metadata';
import StyleSettingsFormSection from '../styleSettingsFormSection.jsx';

import { Container, Col } from '../forms.styles';

class Content extends StyleSettingsFormSection {
  static isSectionMatched(props, filterBy) {
    return (
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.layerHeight,
        filterBy
      ) ||
      StyleSettingsFormSection.isFieldGroupMatched(
        props,
        Metadata.stepover,
        filterBy
      )
    );
  }

  render() {
    return (
      <Container>
        <Col>{this.renderFieldGroup(Metadata.layerHeight)}</Col>
        <Col>{this.renderFieldGroup(Metadata.stepover)}</Col>
      </Container>
    );
  }
}

export default Content;

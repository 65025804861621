import styled from 'styled-components';

import { Shadows } from '../../../../themes';

import { TaskStatus } from '../../../../reducers/onboarding/constants';

export const Container = styled.div`
  width: 18.875rem;
  margin-top: 0.5rem;
  background-color: ${(props) => props.theme.colors.elevatedBackgroundPrimary};
  border-radius: 0.25rem;
  ${Shadows.d3};
`;

export const TaskListHeader = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
`;

export const TaskStatuses = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-basis: 0;
  flex-grow: 1;
`;

export const TaskListTitle = styled.div`
  margin-left: 1rem;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const StatusBubble = styled.div`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: ${(props) => {
    if (props.status === TaskStatus.SKIPPED) return props.theme.colors.grey3;
    if (props.status === TaskStatus.COMPLETE)
      return props.theme.colors.greenDefault;
    return props.theme.colors.elevatedBackgroundPrimary;
  }};
  border: solid 2px
    ${(props) => {
      if (props.active) return props.theme.colors.greenDefault;
      if (props.status === TaskStatus.COMPLETE) return 'transparent';
      return props.theme.colors.grey3;
    }};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) =>
    props.status === TaskStatus.INCOMPLETE ? 'default' : 'pointer'};
`;

export const TaskContainer = styled.div``;

export const TaskTitleWrapper = styled.div`
  margin-bottom: 0.75rem;
`;

export const TaskContent = styled.div`
  box-sizing: border-box;
  height: 15rem;
  overflow-y: auto;
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
`;

export const TaskButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 1rem;
  gap: 0.5rem;
`;

export const SkipButtonWrapper = styled.div`
  flex-grow: 1;
`;

export const IconWrapper = styled.div``;

import styled from 'styled-components';

export const OnlineContainer = styled.div`
  position: relative;
  margin-top: ${(props) => (props.short ? '1rem' : '3.5rem')};
  border-top: 2px solid ${(props) => props.theme.colors.grey3};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

export const OfflineContainer = styled.div`
  position: relative;
  margin-top: ${(props) => (props.short ? '1rem' : '3.5rem')};
  border-top: 2px solid ${(props) => props.theme.colors.grey3};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.25rem;
`;

export const TabButtons = styled.div`
  position: absolute;
  height: 3rem;
  top: -3rem;
  display: flex;
`;

export const TabContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

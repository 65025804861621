import 'regenerator-runtime/runtime';
import { takeLatest, takeEvery, all } from 'redux-saga/effects';

import { types } from '../../reducers/iot/iot';

// import sagas
import initConnection, { closeConnection } from './initConnection';
import receiveMessage from './receiveMessage';
import getDevices from './getDevices';
import activateDevice from './activateDevice';
import updateDevice from './updateDevice';
import unlinkDevices from './unlinkDevices';
import checkDeviceHeartbeat from './checkDeviceHeartbeat';
import getDeviceState from './getDeviceState';
import updateDeviceState from './updateDeviceState';
import connectPort from './connectPort';
import disconnectPort from './disconnectPort';
import scanPorts from './scanPorts';
import startPrint from './startPrint';
import pausePrint from './pausePrint';
import resumePrint from './resumePrint';
import cancelPrint from './cancelPrint';
import getStorageDrives from './getStorageDrives';
import getDrivePathFiles from './getDrivePathFiles';
import editFilePath from './editFilePath';
import forgetDeviceFiles from './forgetDeviceFiles';
import moveExtruder from './moveExtruder';
import homeExtruder from './homeExtruder';
import feedFilament from './feedFilament';
import controlFan from './controlFan';
import controlMotor from './controlMotor';
import sendCommand from './sendCommand';
import setTargetTemperature from './setTargetTemperature';
import initWebRtcConnection from './initWebRtcConnection';
import establishWebRtcConnection from './establishWebRtcConnection';
import webRtcKeepAlive from './webRtcKeepAlive';

export default function* iotSaga() {
  yield all([
    takeLatest(types.INIT_CONNECTION_REQUEST, initConnection),
    takeLatest(types.CLOSE_CONNECTION, closeConnection),
    takeEvery(types.RECEIVE_MESSAGE, receiveMessage),
    takeEvery(types.CHECK_DEVICE_HEARTBEAT_REQUEST, checkDeviceHeartbeat),
    takeEvery(types.GET_DEVICE_STATE_REQUEST, getDeviceState),
    takeEvery(types.UPDATE_DEVICE_STATE_REQUEST, updateDeviceState),
    takeLatest(types.GET_DEVICES_REQUEST, getDevices),
    takeLatest(types.ACTIVATE_DEVICE_REQUEST, activateDevice),
    takeLatest(types.UPDATE_DEVICE_REQUEST, updateDevice),
    takeLatest(types.UNLINK_DEVICES_REQUEST, unlinkDevices),
    takeLatest(types.CONNECT_PORT_REQUEST, connectPort),
    takeLatest(types.DISCONNECT_PORT_REQUEST, disconnectPort),
    takeLatest(types.SCAN_PORTS_REQUEST, scanPorts),
    takeLatest(types.START_PRINT_REQUEST, startPrint),
    takeLatest(types.PAUSE_PRINT_REQUEST, pausePrint),
    takeLatest(types.RESUME_PRINT_REQUEST, resumePrint),
    takeLatest(types.CANCEL_PRINT_REQUEST, cancelPrint),
    takeLatest(types.GET_STORAGE_DRIVES_REQUEST, getStorageDrives),
    takeLatest(types.GET_DRIVE_PATH_FILES_REQUEST, getDrivePathFiles),
    takeLatest(types.EDIT_DEVICE_FILE_PATH_REQUEST, editFilePath),
    takeLatest(types.FORGET_DEVICE_FILES, forgetDeviceFiles),
    takeLatest(types.MOVE_EXTRUDER_REQUEST, moveExtruder),
    takeLatest(types.HOME_EXTRUDER_REQUEST, homeExtruder),
    takeLatest(types.FEED_FILAMENT_REQUEST, feedFilament),
    takeLatest(types.CONTROL_FAN_REQUEST, controlFan),
    takeLatest(types.CONTROL_MOTOR_REQUEST, controlMotor),
    takeLatest(types.SEND_COMMAND_REQUEST, sendCommand),
    takeLatest(types.SET_TARGET_TEMPERATURE_REQUEST, setTargetTemperature),
    takeEvery(types.INIT_WEBRTC_CONNECTION_REQUEST, initWebRtcConnection),
    takeEvery(
      types.ESTABLISH_WEBRTC_CONNECTION_REQUEST,
      establishWebRtcConnection
    ),
    takeEvery(types.WEBRTC_KEEP_ALIVE_REQUEST, webRtcKeepAlive),
  ]);
}

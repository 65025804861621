import React, { useContext, useState } from 'react';

import {
  StampImageContainers,
  ColorPalette,
  ColorSwatch,
  ProjectColorSwatch,
  ProjectColorsContainer,
  ProjectColorsPalette,
  ImageContainer,
  ImageBackground,
  StampColorPickerDescriptionSection,
  StampColorPickerImageSection,
  StampPickerModalContent,
  SubtitleWrapper,
  ImageToggleButtonWrapper,
} from './colorPicker.styles';

import { ModalButtonWrapper } from '../stampPrompt.styles';

import { StampingContext } from '../stamping.context';

import {
  Body1,
  Button,
  ColorPickerDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Subtitle1,
  Subtitle2,
} from '../../../../../../shared';

const StampColorPicker = ({
  onConfirmColors = () => {},
  onCancel = () => {},
  onSelectColorMap = () => {},
  onClickProjectColor = () => {},
}) => {
  const {
    project,
    posterizedPalette,
    posterizedCanvas,
    quantizedCanvas,
    customColorMap,
  } = useContext(StampingContext);

  const [showPosterizedImage, setShowPosterizedImage] = useState(false);

  const numAvailableColors = project.colors.length;

  const renderImageToggleButton = () => {
    const label = showPosterizedImage ? 'Show final' : 'Show original';
    return (
      <ImageToggleButtonWrapper>
        <Button onClick={() => setShowPosterizedImage(!showPosterizedImage)}>
          {label}
        </Button>
      </ImageToggleButtonWrapper>
    );
  };

  const renderPosterizedColors = () => {
    const posterizedColors = posterizedPalette.map((color, index) => (
      <ColorSwatch key={index} color={color} />
    ));

    return <ColorPalette>{posterizedColors}</ColorPalette>;
  };

  const renderCustomColorMap = () => {
    const colorMapOptions = project.colors.map((projectColor) => ({
      label: '',
      optionLabel: '',
      value: projectColor,
    }));

    // add transparent color as option
    colorMapOptions.push({
      label: '',
      optionLabel: '',
      value: [0, 0, 0, 0],
    });

    const colorMap = posterizedPalette.map((value, index) => (
      <ColorPickerDropdown
        key={index}
        options={colorMapOptions}
        value={
          customColorMap[index] < 0
            ? [0, 0, 0, 0]
            : project.colors[customColorMap[index]]
        }
        onChange={(color) => onSelectColorMap(color, index)}
      />
    ));

    return <ColorPalette>{colorMap}</ColorPalette>;
  };

  const renderPosterizedImage = () => (
    <ImageContainer show={showPosterizedImage}>
      <SubtitleWrapper>
        <Subtitle2>Color groups</Subtitle2>
      </SubtitleWrapper>
      <ImageBackground>
        <img alt='Posterized' src={posterizedCanvas.toDataURL()} />
      </ImageBackground>
      {renderPosterizedColors()}
    </ImageContainer>
  );

  const renderQuantizedImage = () => (
    <ImageContainer show={!showPosterizedImage}>
      <SubtitleWrapper>
        <Subtitle2>Final image</Subtitle2>
      </SubtitleWrapper>
      {renderCustomColorMap()}
      <ImageBackground>
        <img alt='Quantized' src={quantizedCanvas.toDataURL()} />
      </ImageBackground>
    </ImageContainer>
  );

  const renderProjectColors = () => {
    const projectColors = project.colors.map((color, index) => (
      <ProjectColorSwatch
        key={index}
        color={color}
        onClick={() => onClickProjectColor(index)}
      />
    ));

    return (
      <ProjectColorsContainer>
        <SubtitleWrapper>
          <Subtitle2>Project colors</Subtitle2>
        </SubtitleWrapper>
        <ProjectColorsPalette>{projectColors}</ProjectColorsPalette>
      </ProjectColorsContainer>
    );
  };

  return (
    <Modal width='48rem'>
      <StampPickerModalContent>
        <StampColorPickerDescriptionSection>
          <ModalHeader>
            <Subtitle1>Select colors for your stamp</Subtitle1>
          </ModalHeader>
          <ModalBody>
            <Body1>
              Since your project supports up to {numAvailableColors} colors, the
              number of colors on your stamp image may need to be reduced.
              Canvas pre-processed the uploaded image and flattened the original
              colors into a smaller set.
            </Body1>
            <Body1>
              Each distinct color has automatically been matched to one of your
              existing project colors. You can specify your own color mapping by
              clicking on each color.
            </Body1>
          </ModalBody>
          {renderProjectColors()}
          {renderImageToggleButton()}
        </StampColorPickerDescriptionSection>
        <StampColorPickerImageSection>
          <StampImageContainers>
            {renderPosterizedImage()}
            {renderQuantizedImage()}
          </StampImageContainers>
          <ModalFooter>
            <ModalButtonWrapper>
              <Button minWidth='6em' onClick={onCancel}>
                Cancel
              </Button>
            </ModalButtonWrapper>
            <ModalButtonWrapper>
              <Button primary minWidth='6em' onClick={onConfirmColors}>
                Confirm
              </Button>
            </ModalButtonWrapper>
          </ModalFooter>
        </StampColorPickerImageSection>
      </StampPickerModalContent>
    </Modal>
  );
};

export default StampColorPicker;

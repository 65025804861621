import React, { Component } from 'react';

import { Container, MaterialInfo } from './uploadContent.styles';
import MosaicLogo from './MosaicLogo';
import { Body1, Caption } from '../../../shared/typography/typography';

class UploadContent extends Component {
  render() {
    return (
      <Container>
        <MaterialInfo
          className={this.props.hover === 'left' ? 'focused' : null}>
          <Body1>Single material upload</Body1>
          <Caption>Canvas will arrange the models as normal</Caption>
        </MaterialInfo>
        <div
          id='svg-wrapper'
          className={this.props.hover === 'left' ? 'single' : 'multi'}>
          <MosaicLogo />
        </div>
        <MaterialInfo
          className={this.props.hover === 'right' ? 'focused' : null}>
          <Body1>Multi-material upload</Body1>
          <Caption>
            Canvas will group and align the models for multi-material printing
          </Caption>
        </MaterialInfo>
      </Container>
    );
  }
}

export default UploadContent;

import 'regenerator-runtime/runtime';
import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';

import API, { methods } from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

import { SlicerUtils, TreeUtils } from '../../../utils';

const formatBody = (transformData) => ({
  models: _.map(transformData, (modelData) => {
    const formatted = {
      id: modelData.id,
      transforms: {},
    };
    _.forEach(['translate', 'rotate', 'scale'], (type) => {
      const hasProperty = Object.prototype.hasOwnProperty.call(
        modelData.transforms,
        type
      );
      if (hasProperty) {
        formatted.transforms[type] = SlicerUtils.vector3ObjToAr(
          modelData.transforms[type]
        );
      }
    });
    return formatted;
  }),
});

function* update(action) {
  try {
    const { currentProject: projectId, models } = yield select(getSlicerState);
    const { transformData } = action.payload;
    const modelIds = transformData.map((item) => item.id);
    const flattenedModels = TreeUtils.flattenDeep(models);
    const modelsWithStamps = flattenedModels.filter(
      (model) => modelIds.includes(model.id) && model.textured
    );

    // at least one model has stamps
    if (!_.isEmpty(modelsWithStamps)) {
      // update stamp position and direction
      yield put(actions.updateStampTransformsRequest(modelsWithStamps));
    }

    const body = formatBody(transformData);
    const response = yield call(API, {
      method: methods.POST,
      path: `projects/${projectId}/models/transforms`,
      body,
    });
    if (response === null) return;

    yield put(actions.updateModelTransformsSuccess());
    yield put(actions.invalidateSlice(projectId));
    yield put(actions.updateProjectThumbnailRequest(projectId));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

let callInProgress = false;
let queuedModelData = {};

const mergeQueuedData = (currentQueue, transformData) => {
  const mergedQueue = { ...currentQueue };
  transformData.forEach((modelData) => {
    mergedQueue[modelData.id] = modelData;
  });
  return mergedQueue;
};

export default function* updateModelTransforms(action) {
  const { transformData } = action.payload;
  if (callInProgress) {
    queuedModelData = mergeQueuedData(queuedModelData, transformData);
  } else {
    callInProgress = true;
    yield call(update, action);
    while (!_.isEmpty(queuedModelData)) {
      const queuedAction = actions.updateModelTransformsRequest(
        _.values(queuedModelData)
      );
      queuedModelData = {};
      yield call(update, queuedAction);
    }
    callInProgress = false;
  }
}

import * as THREE from 'three';

import {
  getMeshLineGeometry,
  MeshLineMaterial,
  MeshLineMaterialLerp,
} from './MeshLine';
import makeTravelGeometry from './TravelLine';
import InstancedSphere from './InstancedSphere';

import types from '../../reducers/three/types';

const isViewTypeLerped = (viewType) =>
  viewType === types.TOOLPATH_BY_SPEED ||
  viewType === types.TOOLPATH_BY_FAN ||
  viewType === types.TOOLPATH_BY_TEMPERATURE ||
  viewType === types.TOOLPATH_BY_LAYER_HEIGHT;

const getMinColor = (legend, viewType) => {
  switch (viewType) {
    case types.TOOLPATH_BY_SPEED:
      return new THREE.Vector3(...legend.colors.minFeedrateColor);
    case types.TOOLPATH_BY_FAN:
      return new THREE.Vector3(...legend.colors.minFanSpeedColor);
    case types.TOOLPATH_BY_TEMPERATURE:
      return new THREE.Vector3(...legend.colors.minTemperatureColor);
    case types.TOOLPATH_BY_LAYER_HEIGHT:
      return new THREE.Vector3(...legend.colors.minLayerHeightColor);
    default:
      return new THREE.Vector3(0, 0, 0);
  }
};

const getMaxColor = (legend, viewType) => {
  switch (viewType) {
    case types.TOOLPATH_BY_SPEED:
      return new THREE.Vector3(...legend.colors.maxFeedrateColor);
    case types.TOOLPATH_BY_FAN:
      return new THREE.Vector3(...legend.colors.maxFanSpeedColor);
    case types.TOOLPATH_BY_TEMPERATURE:
      return new THREE.Vector3(...legend.colors.maxTemperatureColor);
    case types.TOOLPATH_BY_LAYER_HEIGHT:
      return new THREE.Vector3(...legend.colors.maxLayerHeightColor);
    default:
      return new THREE.Vector3(1, 1, 1);
  }
};

// e.g. { label: 'Start Sequence', color: '#333333' }
const formatDisplayLegend = (legend) =>
  legend.map((entry) => ({ label: entry[0], color: entry[1] }));

export const getDisplayLegend = (toolpath, viewType) => {
  const { legend } = toolpath.ptp;
  switch (viewType) {
    case types.TOOLPATH_BY_TOOL:
      return formatDisplayLegend(legend.tool);
    case types.TOOLPATH_BY_PATH_TYPE:
      return formatDisplayLegend(legend.pathType);
    case types.TOOLPATH_BY_SPEED:
      return formatDisplayLegend(legend.feedrate);
    case types.TOOLPATH_BY_FAN:
      return formatDisplayLegend(legend.fanSpeed);
    case types.TOOLPATH_BY_TEMPERATURE:
      return formatDisplayLegend(legend.temperature);
    case types.TOOLPATH_BY_LAYER_HEIGHT:
      return formatDisplayLegend(legend.layerHeight);
    default:
      throw new Error('Invalid view type');
  }
};

const getColorBuffer = (buffers, viewType) => {
  switch (viewType) {
    case types.TOOLPATH_BY_TOOL:
      return buffers.toolColor;
    case types.TOOLPATH_BY_PATH_TYPE:
      return buffers.pathTypeColor;
    case types.TOOLPATH_BY_SPEED:
      return buffers.feedrateColor;
    case types.TOOLPATH_BY_FAN:
      return buffers.fanSpeedColor;
    case types.TOOLPATH_BY_TEMPERATURE:
      return buffers.temperatureColor;
    case types.TOOLPATH_BY_LAYER_HEIGHT:
      return buffers.layerHeightColor;
    default:
      throw new Error('Invalid view type');
  }
};

export const getSortedLayerHeights = (toolpath) => toolpath.ptp.legend.zValues;

export const createExtrusionMesh = (toolpath, viewType) => {
  const { legend, buffers } = toolpath.ptp;
  const { position, normal, index, extrusionWidth, layerHeight } = buffers;

  // get the correct color buffer
  const colors = getColorBuffer(buffers, viewType);

  // build the print object
  const isLerped = isViewTypeLerped(viewType);
  const lineGeometry = getMeshLineGeometry(
    new Float32Array(position),
    new Float32Array(colors),
    new Float32Array(extrusionWidth),
    new Float32Array(layerHeight),
    new Float32Array(normal),
    new Uint32Array(index),
    isLerped
  );
  let toolpathMaterialMeshLine;
  if (isLerped) {
    toolpathMaterialMeshLine = new MeshLineMaterialLerp();
    toolpathMaterialMeshLine.uniforms.minColor.value = getMinColor(
      legend,
      viewType
    );
    toolpathMaterialMeshLine.uniforms.maxColor.value = getMaxColor(
      legend,
      viewType
    );
  } else {
    toolpathMaterialMeshLine = new MeshLineMaterial();
  }
  const toolpathMesh = new THREE.Mesh(lineGeometry, toolpathMaterialMeshLine);
  toolpathMesh.name = 'toolpath_Mesh';

  return toolpathMesh;
};

export const createTravelMesh = (toolpath) =>
  makeTravelGeometry(new Float32Array(toolpath.ptp.buffers.travelPosition));

export const createRetractMesh = (toolpath, diameter) =>
  InstancedSphere(toolpath.ptp.buffers.retractPosition, diameter, true);

export const createRestartMesh = (toolpath, diameter) =>
  InstancedSphere(toolpath.ptp.buffers.restartPosition, diameter, false);

export default {
  mosaicHomepage: 'http://mosaicmfg.com',
  canvasHomepage: 'http://mosaicmfg.com/pages/canvas',
  zendesk: 'http://mm3d.co/canvas-support',
  issueTracker: 'https://github.com/MosaicManufacturing/canvas-issues',
  ossLicenses: '/licenses.txt',
  legacySpliceTuning: 'http://mm3d.co/splice-tuning',
  p2SpliceTuning: 'http://mm3d.co/p2splice-tuning',
  p2pSpliceTuning: 'http://mm3d.co/p2prosplice-tuning',
  hubSetup: 'http://mm3d.co/canvashub-setup',
  diyHubSetup: 'http://mm3d.co/canvashub-plugin',
  firmware: 'http://mm3d.co/firmware',
  accessoryVideo: 'https://youtu.be/9us8VPmdOuA',
  setupHubVideo: 'https://youtu.be/1UTpBTfQ03E',
  connectedVideo: 'https://youtu.be/v4Ad4AZaP7o',
  setupsGuide: 'http://mm3d.co/setups-intro',
  slic3rBlogPost:
    'https://www.mosaicmfg.com/blogs/technology/slic3r-a-new-engine-option-for-canvas',
  hubSupport: 'http://mm3d.co/ch-support',
  p3Support: 'http://mm3d.co/p3-support',
  support: 'http://mm3d.co/support',
  connectDevice: 'http://mm3d.co/connect-device',
  octoprintGpxPlugin: 'https://plugins.octoprint.org/plugins/gpx/',
  calibrationModels: {
    'Mosaic Keychain 1.stl':
      'https://mosaic-resources.s3.amazonaws.com/canvas/Mosaic+Keychain+1.stl',
    'Mosaic Keychain 2.stl':
      'https://mosaic-resources.s3.amazonaws.com/canvas/Mosaic+Keychain+2.stl',
  },
  variableTransitionLengths: 'http://mm3d.co/p2variable-transitions',
};

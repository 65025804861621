import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import HeaderBarLeft from './headerBarLeft.jsx';

const mapStateToProps = (state) => ({
  createProjectPending: state.slicer.status.createProjectPending,
  createProjectSuccess: state.slicer.status.createProjectSuccess,
  navStack: state.nav.navStack,
});

const mapDispatchToProps = () => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderBarLeft)
);

import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

import {
  Container,
  TextWrapper,
  StyledProgressRing,
  Rings,
  EmptyRing,
  ColorRing,
  StyledProgressLine,
  ColorLine,
} from './progress.styles';

import { Body1 } from '../typography/typography';

function Progress(props) {
  const {
    circular = false,
    determinate = false,
    label = '',
    progress = 25,
    // stroke of circular progress, height of linear progress, in px
    stroke = 4,
    // radius of circular progress, only applicable to circular, in px
    radius = 20,
    children,
  } = props;

  const theme = useTheme();

  const primaryColorProp = props.primaryColor;
  const secondaryColorProp = props.secondaryColor;
  const labelColorProp = props.labelColor;

  const primaryColor = useMemo(() => {
    // pass a function that could render various colors conditionally e.g. based on ranges
    if (typeof primaryColorProp === 'function') {
      return primaryColorProp(progress, theme);
    }
    if (primaryColorProp) {
      return primaryColorProp;
    }
    return theme.colors.grey5;
  }, [primaryColorProp, progress, theme]);

  const secondaryColor = useMemo(() => {
    if (typeof secondaryColorProp === 'function') {
      return secondaryColorProp(progress, theme);
    }
    if (secondaryColorProp) {
      return secondaryColorProp;
    }
    return theme.colors.grey3;
  }, [secondaryColorProp, progress, theme]);

  const labelColor = useMemo(() => {
    if (typeof labelColorProp === 'function') {
      return labelColorProp(progress, theme);
    }
    if (labelColorProp) return labelColorProp;
    return theme.colors.textPrimary;
  }, [labelColorProp, progress, theme]);

  const renderLabel = () => {
    if (label) {
      return (
        <TextWrapper color={labelColor}>
          <Body1 noSpacing>{label}</Body1>
        </TextWrapper>
      );
    }
    return null;
  };

  const renderRing = () => {
    const normalizedRadius = radius - stroke;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (progress / 100) * circumference;
    return (
      <StyledProgressRing radius={radius}>
        <Rings radius={radius} determinate={determinate}>
          <EmptyRing
            color={secondaryColor}
            strokeWidth={stroke}
            strokeDasharray={`${circumference} ${circumference}`}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <ColorRing
            color={primaryColor}
            strokeWidth={stroke}
            strokeDasharray={`${circumference} ${circumference}`}
            style={{ strokeDashoffset }}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </Rings>
        {children}
      </StyledProgressRing>
    );
  };

  const renderLine = () => {
    return (
      <StyledProgressLine color={secondaryColor} stroke={stroke}>
        <ColorLine
          progress={progress}
          determinate={determinate}
          color={primaryColor}
          stroke={stroke}></ColorLine>
      </StyledProgressLine>
    );
  };

  return (
    <Container circular={circular}>
      {circular ? renderRing() : renderLine()}
      {renderLabel()}
    </Container>
  );
}

export default Progress;

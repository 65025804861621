import React from 'react';

import { StyledLink } from './link.styles';

const Link = ({ href, external = false, children, greenDefault }) => {
  if (external) {
    return (
      <StyledLink
        href={href}
        rel='noopener noreferrer'
        target='_blank'
        greenDefault={greenDefault}>
        {children}
      </StyledLink>
    );
  }
  return <StyledLink href={href}>{children}</StyledLink>;
};

export default Link;

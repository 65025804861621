/* eslint-disable max-len, no-undef, spaced-comment */

// modified from
// https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
// Opera 8.0+
const isOpera =
  (!!window.opr && !!opr.addons) ||
  !!window.opera ||
  navigator.userAgent.indexOf(' OPR/') >= 0;

// Firefox 1.0+
export const isFirefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]"
const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  ((p) => p.toString() === '[object SafariRemoteNotification]')(
    !window.safari || (typeof safari !== 'undefined' && safari.pushNotification)
  );

// iOS Safari
const isIosSafari =
  navigator &&
  !!navigator.platform &&
  /iPad|iPhone|iPod/.test(navigator.platform);

const isIpadOSSafari =
  navigator.maxTouchPoints &&
  navigator.maxTouchPoints > 2 &&
  /MacIntel/.test(navigator.platform);

// Desktop + iOS Safari
export const isAnySafari = isSafari || isIosSafari || isIpadOSSafari;

// Internet Explorer 6-11
// const isIE = /*@cc_on!@*/false || !!document.documentMode;

// Edge 20+
// const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 71
const isChrome = !!window.chrome && !isOpera;

// Android/Chrome
const isAndroid =
  navigator &&
  navigator.userAgent &&
  navigator.userAgent.toLowerCase().includes('android');

// Desktop + Android Chrome
export const isAnyChrome = isChrome || isAndroid;

// Blink engine detection
// const isBlink = (isChrome || isOpera) && !!window.CSS;

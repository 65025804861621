import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as authActions } from '../reducers/auth/auth';
import { actions as iotActions } from '../reducers/iot/iot';
import { actions as notificationsActions } from '../reducers/notifications/notifications';
import { actions as printerActions } from '../reducers/printers/printers';
import { actions as slicerActions } from '../reducers/slicer/slicer';

const Loader = () => {
  const isAuthenticated = useSelector((state) => !!state.auth.refreshToken);

  const dispatch = useDispatch();
  const [triggered, setTriggered] = useState(false);

  useEffect(() => {
    if (isAuthenticated && !triggered) {
      // session started: one of
      // - user logged in after loading the app
      // - user was already logged in and loaded the app
      dispatch(authActions.getAccountRequest());
      dispatch(notificationsActions.getNotificationsRequest());
      dispatch(slicerActions.getSliceJobsRequest());
      dispatch(iotActions.getDevicesRequest());
      dispatch(slicerActions.getMaterialsRequest());
      dispatch(slicerActions.loadProjectsRequest());
      dispatch(printerActions.getPrintersRequest());
      dispatch(iotActions.initConnectionRequest());
      setTriggered(true);
    } else if (!isAuthenticated && triggered) {
      // session ended: user logged out
      setTriggered(false);
    }
  }, [dispatch, isAuthenticated, triggered]);

  return null;
};

export default Loader;

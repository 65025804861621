import React from 'react';
import Portal from '../portal/portal.jsx';
import { Container, Content, ModalMargin } from './modal.styles';

const Modal = ({
  height = 'auto',
  width = '21rem',
  noPadding = false,
  noScroll = false,
  onMarginClick = () => {},
  onPanelClick = () => {},
  children,
}) => {
  const handleMarginClick = (e) => {
    // clicking the margin should "consume" the event, stopping it
    // from proceeding further up the DOM tree
    e.stopPropagation();
    onMarginClick(e);
  };

  const handlePanelClick = (e) => {
    e.stopPropagation();
    onPanelClick(e);
  };

  return (
    <Portal>
      <Container data-test-id='modal'>
        <ModalMargin onClick={handleMarginClick} />
        <Content
          cssHeight={height}
          cssWidth={width}
          noPadding={noPadding}
          noScroll={noScroll}
          onClick={handlePanelClick}>
          {children}
        </Content>
      </Container>
    </Portal>
  );
};

export default Modal;

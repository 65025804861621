import React, { Component } from 'react';
import { withTheme } from 'styled-components';

import Icon from '../icon/icon.jsx';
import { Icons } from '../../themes';
import {
  Container,
  Box,
  InvisibleInput,
  ToggleLabelWrapper,
} from './toggle.styles';

import { Body1 } from '../typography/typography';

class Toggle extends Component {
  static defaultProps = {
    label: '',
    on: false,
    onChange: () => {},
    disabled: false,
  };

  renderToggle() {
    const { on, disabled } = this.props;
    const iconSrc = on ? Icons.basic.toggleOn : Icons.basic.toggleOff;
    let iconColor = this.props.theme.colors.grey4;
    if (!disabled) {
      iconColor = on
        ? this.props.theme.colors.greenDefault
        : this.props.theme.colors.grey5;
    }

    return (
      <Box value={on}>
        <Icon src={iconSrc} color={iconColor} />
        <InvisibleInput
          type='checkbox'
          checked={on}
          disabled={disabled}
          onChange={(e) => this.props.onChange(e)}
        />
      </Box>
    );
  }

  renderLabel() {
    if (!this.props.label) return null;
    return (
      <ToggleLabelWrapper>
        <Body1 disabled={this.props.disabled} noSpacing>
          {this.props.label}
        </Body1>
      </ToggleLabelWrapper>
    );
  }

  render() {
    return (
      <Container disabled={this.props.disabled}>
        {this.renderToggle()}
        {this.renderLabel()}
      </Container>
    );
  }
}

export default withTheme(Toggle);

import 'regenerator-runtime/runtime';
import _ from 'lodash';
import { call, put, select, all } from 'redux-saga/effects';

import API, {
  methods,
  handleConflictResponses,
  handleBatchCallErrorResponses,
} from '../../canvas-api';
import { handleGenericError, getSlicerState } from '../../common';
import { actions } from '../../../reducers/slicer/slicer';

const removeMaterialsFromMap = (ids, materials) => _.omit(materials, ids);

function* deleteMaterialCall(materialId) {
  try {
    const response = yield call(API, {
      method: methods.DELETE,
      path: `materials/${materialId}`,
    });
    if (response === null) return null;
    return { status: 200, deletedId: materialId };
  } catch (error) {
    return error;
  }
}

export default function* deleteMaterials(action) {
  const { materials: existingMaterials } = yield select(getSlicerState);
  const { materialIds } = action.payload;
  try {
    const responses = yield all(
      _.map(materialIds, (materialId) => call(deleteMaterialCall, materialId))
    );
    if (_.some(responses, (response) => response === null)) return;

    const deleteResponses = _.filter(
      responses,
      (response) => response.status === 200
    );
    const deletedIds = _.map(deleteResponses, (response) => response.deletedId);

    const updatedMaterials = removeMaterialsFromMap(
      deletedIds,
      existingMaterials
    );
    yield put(actions.deleteMaterialsSuccess(updatedMaterials));

    handleConflictResponses(responses);

    handleBatchCallErrorResponses(responses);
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}

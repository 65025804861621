import React from 'react';

import { LoginRecordContainer } from './accountManager.styles';

import { Body1, Caption } from '../../shared';

import { FormatUtils } from '../../utils';

const LoginRecord = (props) => {
  const { browser, device, os, timestamp } = props.details;
  const osAndDevice = device === 'Unknown' ? os : `${os} (${device})`;

  return (
    <LoginRecordContainer>
      <Body1 noSpacing>
        {browser} on {osAndDevice}
      </Body1>
      <Caption grey noSpacing>
        {FormatUtils.isoToDateTimeString(timestamp)} (
        {FormatUtils.isoToRelativeTime(timestamp)} ago)
      </Caption>
    </LoginRecordContainer>
  );
};

export default LoginRecord;
